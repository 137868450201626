import React from "react";
import { Table } from "semantic-ui-react";
import { getdistance } from "../../functions/distance";
import { formatDateToParts, formatToAMPM } from "../../functions/date-time";
class LocationCheckins extends React.Component {
	constructor(props) {
		super(props);

		this.state = { ...props };
		// console.log(this.state);
	}

	render() {
		let sortedEvents = this.state.events.sort(function (a, b) {
			return new Date(b.At * 1000) - new Date(a.At * 1000);
		});

		return (
			<Table fixed compact>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Date</Table.HeaderCell>
						<Table.HeaderCell>Time</Table.HeaderCell>
						<Table.HeaderCell>Location</Table.HeaderCell>
						<Table.HeaderCell>Distance from Quarantine base</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{sortedEvents.map((v, i) => {
						if (v.Type >= 10000) {
							const time = v.At;
							const date = new Date(time * 1000);
							const formatDate = formatDateToParts(date); // format to dd-MM-yyyy format
							const formatTime = formatToAMPM(date); // format time to AM-PM format

							const distance = getdistance(
								this.state.studentinfo.QuarantineLat,
								this.state.studentinfo.QuarantineLng,
								v.Latitude,
								v.Longitude
							);
							return (
								<Table.Row key={i}>
									<Table.Cell>{formatDate}</Table.Cell>
									<Table.Cell>{formatTime}</Table.Cell>
									<Table.Cell>
										{"lat: " + v.Latitude}
										<br /> {"long: " + v.Longitude}
									</Table.Cell>
									<Table.Cell>
										{distance > 0 ? Math.round(distance * 1000) + "m" : null}
									</Table.Cell>
								</Table.Row>
							);
						}
					})}
				</Table.Body>
			</Table>
		);
	}
}

export default LocationCheckins;
