import React from "react";
import { Button, Form, Grid, Segment, Header, Image } from "semantic-ui-react";
import { connect } from "react-redux";
import {UPDATE_SCHOOL} from '../../SchoolsRedux/school.js'
import {get,post} from "../../functions/http.js"
import "./login.css";
import NuiLogo from './logo.svg';
import Bg from './download.jpeg';

class Login extends React.Component {

      
  async getSchool(schoolID) {
		let result = await get("getSchool?school=" + schoolID);		
		if (result){
      result.ID = schoolID;
      this.props.dispatch({type:"GET_SCHOOL",payload:result});      
		} else {
			console.log("data not received");
		}		
  }

  async loginCheck(email,password) {
    let result = await post("loginUser",{email: email, password: password});    
		if (result){
      //todo: handle multiple users - if user.Schools includes school id then ok etc 
      this.getSchool(result[0]);        
      ///this.loginUserCheck();  		 
		} else {
      //TODO: TRIGGER MESSAGE 
			console.log(result);
    }		
    

  }
 
  login = (id,password) => {  
    if(id !== "" && password !== ""){ 
      //TODO Better password validation
      this.loginCheck(id,password)      
      this.props.dispatch({type:"GET_LOGIN",payload:id});	
      //this.getSchool(this.state.id);   	       
    } 
  }
	
  constructor(props){
    super(props);
    this.state={id:"", password:""}
    this.wrapperRef = React.createRef();
    this.wrapperRef2 = React.createRef();
    this.wrapperRef3 = React.createRef();
   
  }

  componentDidMount(){
    const wrapper = this.wrapperRef.current;  
    wrapper.classList.toggle("show-messages");
    const wrapper2 = this.wrapperRef2.current;  
    wrapper2.classList.toggle("show-messages2");
    const wrapper3 = this.wrapperRef3.current;  
    wrapper3.classList.toggle("show-messages3");
    console.log(wrapper);
  }


  render() {

    //console.log(this.props.dispatch({type:UPDATE_SCHOOL, payload: "Hello"}));
    
    return (
      <div className='login-page'>
        <Grid columns={2}                
          stackable
          textAlign="center"
          style={{ height: "100vh",width:"100%"}}
          verticalAlign="middle"
        >                 
          <Grid.Column centered="true">
            <Header as="h2" color="blue" textAlign="center" >            
             <span className="headtitle">{"QTrack Login"}</span>
            </Header>
            <Form className="login"
              size="large" 
              style={{ width: "50%",margin:"auto"}}
            >
              {/* <Segment stacked> */}
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="School-ID"
                  onChange={(e)=>{
                    this.setState({id:e.currentTarget.value})
                  }}               
                />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password"
                  type="password"
                  onChange={(e)=>{
                    this.setState({password:e.currentTarget.value})
                  }}
                />

                <Button color="blue" fluid size="large"
                 onClick={(e)=>this.login(this.state.id,this.state.password)} 
                >
                  Login
                </Button>           
            </Form>
            <Image style={{width:"50px", margin:"auto",paddingTop:"40px"}} src={NuiLogo} alt="Nui Logo" /> 
          </Grid.Column>  
          <Grid.Column>
          <div className="panel-one" ref={this.wrapperRef}>Help New Zealand</div>  
          <div className="panel-two" ref={this.wrapperRef2}>International Students</div>  
          <div className="panel-three" ref={this.wrapperRef3}>Stay Covid Free</div>  
          <Image src={Bg} alt="background" style={{width:"100%", height:"100%"}}/> 

          </Grid.Column> 
        
        </Grid>
      </div>
    );
  }
}

// const mapStateToProps = (state, ownProps) => ({
//   school: ownProps.school === state.school
// })

// const mapDispatchToProps = (dispatch, ownProps) => ({
//   //onClick: () => dispatch(setVisibilityFilter(ownProps.filter))
// })

export default connect(state=>({school:state.school,login:state.login}))(Login);
