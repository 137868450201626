import React from 'react';
import { Container, Tab } from 'semantic-ui-react'
import SchoolInfoForm from './schoolinfoform.jsx';
import SurveyQuestions from './survey-questions.jsx';
import MessageSetUp from './message-setup.jsx';
import { connect } from "react-redux";

class Config  extends React.Component {

  constructor (props){
    super(props);
   
  }
  
  render(){
   
    const panes = [
      { menuItem: 'Details', render: () => <Tab.Pane><SchoolInfoForm/></Tab.Pane>},
      { menuItem: 'Message Setup', render: () => <Tab.Pane><MessageSetUp /></Tab.Pane> },
      { menuItem: 'Survey Questions', render: () => <Tab.Pane><SurveyQuestions surveyQuestions={this.props.school.Surveys} /></Tab.Pane> },
    ]

    return (
      <Container>
        <div>
          <Tab panes={panes}/>
        </div>
      </Container>
    );
  }
}

export default connect(state=>({school:state.school}))(Config);

let surveyQuestionsData = {
  "Surveys":[
    {	
      "Title": "Survey",
      "Days": 3,
      "Questions": [{
        "Text": "What do you rate the food?",
        "Type": 0
      },{
        "Text": "Did is it quiet?",
        "Type": 1
      },{
        "Text": "How many hours sleep did you have?",
        "Type": 2
      }]
    },{	
      "Title": "Survey",
      "Days": 3,
      "Questions": [{
        "Text": "What do you rate the food?",
        "Type": 0
      },{
        "Text": "Did is it quiet?",
        "Type": 1
      },{
        "Text": "How many hours?",
        "Type": 2
      }]
    }
  ]
}
