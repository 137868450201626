export function formatToAMPM(date) {
	var hours = date.getHours();
	var minutes = date.getMinutes();
	var ampm = hours >= 12 ? "PM" : "AM";
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? "0" + minutes : minutes;
	var strTime = hours + ":" + minutes + " " + ampm;
	return strTime;
}

export function formatDateToParts(date) {
	const dateTimeFormat = new Intl.DateTimeFormat("en", {
		year: "numeric",
		month: "short",
		day: "2-digit",
	});
	const [
		{ value: month },
		,
		{ value: day },
		,
		{ value: year },
	] = dateTimeFormat.formatToParts(date);
	return `${day}-${month}-${year}`;
}
export function stringtoDateParts(date) {
	const dt = date.split("/");
	let months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sept",
		"Oct",
		"Nov",
		"Dec",
	];

	return dt[0] + "-" + months[dt[1] - 1] + "-" + dt[2];
}
