import React from 'react';
import { Container, Grid,Table, Form, Button, Message,Input,Dropdown, Menu} from 'semantic-ui-react'
import { connect } from "react-redux";
import {post} from "../../functions/http.js";

class SurveyQuestions  extends React.Component {
 
 constructor(props){
   super(props);
  ///this.state = {surveyQuestions:this.props.schools.surveyQuestions ? this.props.schools.surveyQuestions : [] };
   
 } 

 async setSchool(data) {
  data.ID = parseInt(data.ID)
  let result = await post("setSchool",data);		
  if (result){
     result.ID = data.ID;
     console.log("School Data Updated");
    //this.props.dispatch({type:"SET_SCHOOL",payload:result});
  } else {
    console.log("changes not saved");
  }		
};

valueChanged = (surveyIndex,questionIndex,key,value) =>{ 
  this.props.dispatch({type:"UPDATE_SURVEYS",payload:[this.props.school,surveyIndex,questionIndex, key, value]});   
}

dropDownValueChanged = (surveyIndex,questionIndex,key,value) =>{  
  let val;
  if (value === "sad/happy"){
    val = 1; 
  }
  if (value === "bad/good"){
    val = 2;
  }    
  if (value === "no/yes"){
    val= 3;
  }        
 
   this.props.dispatch({type:"UPDATE_SURVEYS",payload:[this.props.school,surveyIndex,questionIndex, key, val]}); 
  //this.props.dispatch({type:"UPDATE_SCHOOL",payload:[this.props.school,e.currentTarget.name,e.currentTarget.value]}); 
  //this.props.dispatch({type:"UPDATE_SCHOOL",payload:[this.props.school,"Surveys",surveytestdata]});    
}

saveSurveyQuestions = () =>{
  let valid = [];
   //validation function
   this.props.school.Surveys.map((s,i)=>{  
    console.log(s.Questions); 
     s.Questions.map((q,z)=>{
       if (!q["Text"] || 0 === q["Text"].length){
         valid.push(q["Text"])
       }
    });
  })

  if (valid.length === 0){ //valid must not be empty
    this.setSchool(this.props.school);            
  }
}
  
getTypeText = (typenum) =>{
    if (typenum === 1){
      return "sad/happy";
    }
    if (typenum === 2){
      return "bad/good";
    }    
    if (typenum === 3){
      return "no/yes";
    }        
   
   
}

  render(){
      console.log(this.props.school);
      if (this.props.school.Surveys){
        return (         
          <Container>
            <Form>
             <Grid columns={2}>
               {this.props && this.props.school.Surveys.map((s,i)=>{                  
                return(
                <Grid.Column key={i}>
                  <h3>{s.Title}</h3>                   
                  <Table celled>
                  <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Question</Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>                    
                  </Table.Row>
                  </Table.Header>                 
                    <Table.Body>
                      {s.Questions.map((q,z)=>{                 
                        console.log(q);
                        return(
                        <Table.Row key={z}>
                          <Table.Cell>
                            <Input 
                              className={"question"} 
                              value={q.Text} 
                              onChange={(e)=>this.valueChanged(i,z,"Text",e.currentTarget.value)}
                              />                            
                          </Table.Cell>
                          <Table.Cell>
                             {/*q["Type"]*/}       
                            <Menu compact>
                              <Dropdown text={this.getTypeText(q["Type"])} options={options} simple item 
                               onChange={(e)=>this.dropDownValueChanged(i,z,"Type",e.currentTarget.textContent)}
                              />      
                                                                              
                              {/* <Dropdown 
                                text={typ} 
                                options={options} 
                                simple item 
                                onChange={(e)=>this.dropDownValueChanged(i,z,"Type",Number(e.currentTarget.value))}
                                /> */}

                            </Menu>         
                          </Table.Cell>
                        </Table.Row> 
                        );
                      })}                   
                    </Table.Body>                 
                </Table>
                </Grid.Column>
                );
                
              })}        
            </Grid>
            <Button 
              onClick={this.saveSurveyQuestions}
              className={"save"} 
              >{"Save Your Answers"}</Button>
            </Form>       
          </Container>
          
        ); 
     }  else {
       return (
        <React.Fragment> 
          <Message>{"No data received by this view"}</Message>         
        </React.Fragment> 
       );
     }
  }

}
export default connect(state=>({school:state.school}))(SurveyQuestions);


let options = [
  { key: 1, text: 'sad/happy', value: 'sad/happy' },
  { key: 2, text: 'bad/good', value: 'bad/good' },
  { key: 3, text: 'no/yes', value: 'no/yes' }
] 


// let options = [
//   { key: 0, text: 'sad/happy', value: 0 },
//   { key: 1, text: 'good/bad', value: 1 },
//   { key: 2, text: 'no/yes', value: 2 },
  
// ] 






