import React from "react";
import { withGoogleMap, GoogleMap, withScriptjs } from "react-google-maps";
import { compose, withProps, withHandlers, lifecycle } from "recompose";
import MarkerWithInfoWindow from "./map-marker-with-infowindow";

const {
	MarkerClusterer,
} = require("react-google-maps/lib/components/addons/MarkerClusterer");
const googleApiKey = "AIzaSyAWBHmHJPXFBxm9gioFXB7CCjULB4LSVlk";
const MapWithAMarkerClusterer = compose(
	withProps({
		googleMapURL:
			"https://maps.googleapis.com/maps/api/js?key=" +
			googleApiKey +
			"&v=3.exp&libraries=geometry,drawing,places",
		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: <div style={{ height: "75vh", width: "100%" }} />,
		mapElement: <div style={{ height: "100%" }} />,
	}),
	// es-lint ignore
	withHandlers({
		onMarkerClustererClick: () => (markerClusterer) => {
			const clickedMarkers = markerClusterer.getMarkers();
			// console.log(`Current clicked markers length: ${clickedMarkers.length}`);
			// console.log(clickedMarkers);
		},
	}),
	lifecycle({
		componentDidMount() {
			this.setState({
				zoomToMarkers: (map) => {
					if (map == null) return;
					let bounds = new window.google.maps.LatLngBounds();

					this.props.places.map((v) => {
						return bounds.extend(
							new window.google.maps.LatLng(
								parseFloat(v.LastLatitude),
								parseFloat(v.LastLongitude)
							)
						);
					});

					map.fitBounds(bounds);
				},
			});
		},
	}),

	withScriptjs,
	withGoogleMap
)((props) => (
	<GoogleMap
		defaultZoom={props.zoom}
		defaultCenter={props.center}
		// ref={props.zoomToMarkers}
	>
		<MarkerClusterer
			onClick={props.onMarkerClustererClick}
			averageCenter
			enableRetinaIcons
			gridSize={60}
		>
			{props.places &&
				props.places.map((place, i) => {
					console.log([place]);
					let lat = parseFloat(place.LastLatitude, 10);
					let lng = parseFloat(place.LastLongitude, 10);

					return (
						<MarkerWithInfoWindow
							id={i}
							key={i}
							position={{ lat: lat, lng: lng }}
							label={place.Name}
							onClick={props.onToggleOpen}
							content={Object.values(place)}
						></MarkerWithInfoWindow>
					);
				})}
		</MarkerClusterer>
	</GoogleMap>
));

export default MapWithAMarkerClusterer;
