import React from "react";
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Marker,
	Circle,
} from "react-google-maps";

import { get } from "../../../functions/http";
import { compose, withProps } from "recompose";

import { Container, Image, Button, Segment } from "semantic-ui-react";

const googleApiKey = "AIzaSyAWBHmHJPXFBxm9gioFXB7CCjULB4LSVlk";
const MapWithAMarker = compose(
	withProps({
		googleMapURL:
			"https://maps.googleapis.com/maps/api/js?key=" +
			googleApiKey +
			"&v=3.exp&libraries=geometry,drawing,places",
		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: <div style={{ height: "75vh", width: "100%" }} />,
		mapElement: <div style={{ height: "100%" }} />,
	}),

	withScriptjs,
	withGoogleMap
)((props) => {
	// const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
	// let labelIndex = 0;
	let lastevent = props.lastevent;
	// let lat = parseFloat(lastevent.Latitude);
	// let lng = parseFloat(v.Longitude);
	// let markercolor = "";
	// // from custom markers: tutorial from https://medium.com/free-code-camp/how-to-change-javascript-google-map-marker-color-8a72131d1207
	// // and icons from https://sites.google.com/site/gmapsdevelopment/

	// if (v.circle && i === 0) {
	// 	// v.circle is on the last location co-ords. blue for last location
	// 	markercolor = "http://maps.google.com/mapfiles/ms/icons/blue-dot.png";
	// } else if (i === 1) {
	// 	// all other locations - orange
	// 	markercolor = "http://maps.google.com/mapfiles/ms/icons/orange-dot.png ";
	// } else if (i === 2) {
	// 	markercolor = "http://maps.google.com/mapfiles/ms/icons/red-dot.png ";
	// }

	// let customMarker = (
	// 	<Image
	// 		src="https://react.semantic-ui.com/images/wireframe/image.png"
	// 		size="small"
	// 	/>
	// );
	return (
		<GoogleMap defaultZoom={props.zoom} defaultCenter={props.center}>
			{lastevent.Type >= 10000 && (
				<Marker
					position={{
						lat: parseFloat(lastevent.Latitude),
						lng: parseFloat(lastevent.Longitude),
					}}
					center={props.center}
					// icon={customMarker}
					onClick={props.onToggleOpen}
					// animation={window.google.maps.Animation.BOUNCE}
					// label={labels[labelIndex++ % labels.length]}
				>
					{/* // Quarantine location circle
							<Circle
								defaultCenter={props.QLoc}
								radius={props.QuarantineRad} //
								options={{
									strokeColor: "#0000FF",
									// strokeOpacity: 0.8,
									strokeWeight: 1,
									// fillColor: "#0000FF",
									// fillOpacity: 0.5,
								}}
							/> */}
					{/* Last Location circle
					{lastevent.circle && (
						<Circle
							defaultCenter={props.center}
							radius={lastevent.circle.radius}
							options={lastevent.circle.options}
							// options={{
							// 	strokeColor: "#0000FF",
							// 	strokeOpacity: 0.8,
							// 	strokeWeight: 1,
							// 	fillColor: "#0000FF",
							// 	fillOpacity: 0.5,
							// }}
						/>
					)} */}
					{/* <LocationWithInfo
								id={i}
								key={i}
								position={{ lat: lat, lng: lng }}
								label={v.Text}
								onClick={props.onToggleOpen}
								content={Object.values(v)}
								QRad={props.QuarantineRad && props.QuarantineRad}
								LastAccuracy={props.LastAccuracy && props.LastAccuracy}
								icon_color={markercolor}
							/> */}
				</Marker>
			)}
		</GoogleMap>
	);
});

export default class LocationBreach extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			studentEvents: this.props.location.state.events,
		};
	}

	render() {
		//sort the events descending by last event date
		let sortedEvents = this.state.studentEvents.sort(function (a, b) {
			return new Date(b.At * 1000) - new Date(a.At * 1000);
		});
		let lastLocation = sortedEvents[0];
		console.log("Last Loc", lastLocation);
		return (
			<Container textAlign="center">
				<Segment>
					<MapWithAMarker
						center={{
							lat: lastLocation.Latitude,
							lng: lastLocation.Longitude,
						}}
						zoom={14}
						lastevent={lastLocation}
						// QLoc={{
						// 	lat: this.state.studentinfo.QuarantineLat,
						// 	lng: this.state.studentinfo.QuarantineLng,
						// }}
						// QuarantineRad={this.state.studentinfo.QuarantineRad}
						// LastAccuracy={lastLocation.Accuracy}
					/>
				</Segment>
				<Button type="submit" onClick={() => this.props.history.goBack()}>
					Go Back{" "}
				</Button>
			</Container>
		);
	}
}

const data = [
	{
		At: 1593396782,
		Text: "Lost in carpark",
		Latitude: -36.8875028,
		Longitude: 174.7147572,
		Type: 10000,
		Accuracy: 65,
	},
	{
		At: 1592436584,
		Text: "Somewhere here",
		Latitude: -36.8843168,
		Longitude: 174.7012937,
		Type: 10000,
		Accuracy: 85,
	},
	{
		At: 1592436585,
		Text: "Somewhere here44",
		Latitude: -36.8843168,
		Longitude: 174.7147272,
		Type: 10000,
		Accuracy: 75,
	},
	{
		At: 1592436235,
		Text: "Somewhere here44",
		Latitude: -36.8875028,
		Longitude: 174.7147372,
		Type: 10000,
		Accuracy: 95,
	},
];
