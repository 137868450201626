import React from "react";
import { Container } from "semantic-ui-react";

import { Chart } from "../Reports/chart.js";

export default class StudentDistribution extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
		};
	}

	render() {
		let chartData = [["Event", "Count"]]; // array to store piechart values
		let eventcount = {};
		// generate frequency table ie, count of events
		this.state.allStudents.map((v, i) => {
			var event = v.LastEventText;
			eventcount[event] = eventcount[event] ? eventcount[event] + 1 : 1;
			return eventcount[event];
		});
		// push values to chartData
		for (const [key, value] of Object.entries(eventcount)) {
			chartData.push([key, value]);
		}
		console.log("Chart Data", chartData);
		return (
			<Container textAlign="center">
				<Chart
					chartType="PieChart"
					data={chartData}
					options={{
						title: "Student Status Distribution",
						backgroundColor: { fillOpacity: 0.4, fill: "white" },
						titleTextStyle: {
							fontSize: 14,
							fontName: "Arial",
						},

						tooltip: { trigger: "selection" },
						// pieHole: 0.3,
						// width: 650,
						height: 400,
						chartArea: { width: "90%", height: "80%", left: 230 },
						legend: { textStyle: { fontSize: 12 } },
						is3D: true,
					}}
				/>
			</Container>
		);
	}
}
const data = [
	{
		ID: 1,
		Name: "Student 1",
		Email: "Student 1@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 0,
		LastEventTime: 1592524800,
		LastEventText: "Installed Software",
		LastLatitude: -31.56391,
		LastLongitude: 147.154312,
		QuarantineLat: 18,
		QuarantineLng: 19,
	},
	{
		ID: 2,
		Name: "Student 2",
		Email: "Student 2@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 10,
		LastEventTime: 1593129600,
		LastEventText: "At Home Airport",
		LastLatitude: -33.718234,
		LastLongitude: 150.363181,
		QuarantineLat: 18,
		QuarantineLng: 19,
	},
	{
		ID: 3,
		Name: "Student 3",
		Email: "Student 3@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 20,
		LastEventTime: 1593129600,
		LastEventText: "Boarding Plane",
		LastLatitude: -33.727111,
		LastLongitude: 150.371124,
		QuarantineLat: 18,
		QuarantineLng: 19,
	},
	{
		ID: 4,
		Name: "Student 4",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 110,
		LastEventTime: 1593216000,
		LastEventText: "See Orientation Materials",
		LastLatitude: -8.0090935,
		LastLongitude: 113.8436013,
		QuarantineLat: 18,
		QuarantineLng: 19,
	},
	{
		ID: 5,
		Name: "Student 5",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 40,
		LastEventTime: 1593475200,
		LastEventText: "Cleared Customs",
		LastLatitude: 12.0806213,
		LastLongitude: -86.0198103,
		QuarantineLat: 18,
		QuarantineLng: 19,
	},
	{
		ID: 6,
		Name: "Student 6",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 110,
		LastEventTime: 1593129600,
		LastEventText: "See Orientation Materials",
		LastLatitude: 52.2820161,
		LastLongitude: 36.0199456,
	},
	{
		ID: 7,
		Name: "Student 7",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 22,
		LastEventTime: 1593475200,
		LastEventText: "Flight Cancelled",
		LastLatitude: 37.8334351,
		LastLongitude: 23.802873,
	},
	{
		ID: 8,
		Name: "Student 8",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 160,
		LastEventTime: 1593475200,
		LastEventText: "Day 13 Health Survey",
		LastLatitude: 36.5503029,
		LastLongitude: 136.888602,
	},
	{
		ID: 9,
		Name: "Student 9",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 0,
		LastEventTime: 1592956800,
		LastEventText: "Installed Software",
		LastLatitude: 52.6057865,
		LastLongitude: 21.8949682,
	},
	{
		ID: 14,
		Name: "Student 14",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 170,
		LastEventTime: 1593129600,
		LastEventText: "Check out of Quarantine",
		LastLatitude: 55.888915,
		LastLongitude: 37.5584016,
	},
	{
		ID: 24,
		Name: "Student 24",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 41,
		LastEventTime: 1593043200,
		LastEventText: "Delayed in Customs",
		LastLatitude: 22.183206,
		LastLongitude: 112.305145,
	},
	{
		ID: 34,
		Name: "Student 34",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 50,
		LastEventTime: 1593216000,
		LastEventText: "In transit to Accommodation",
		LastLatitude: 51.0888487,
		LastLongitude: 24.526718,
	},
	{
		ID: 43,
		Name: "Student 43",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 150,
		LastEventTime: 1593216000,
		LastEventText: "Day 11 Health Survey",
		LastLatitude: 51.0888487,
		LastLongitude: 24.526718,
	},
	{
		ID: 42,
		Name: "Student 43",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 20,
		LastEventTime: 1593216000,
		LastEventText: "Boarding Plane",
		LastLatitude: 51.0888487,
		LastLongitude: 24.526718,
	},
	{
		ID: 52,
		Name: "Student 43",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 0,
		LastEventTime: 1593216000,
		LastEventText: "Installed Software",
		LastLatitude: 51.0888487,
		LastLongitude: 24.526718,
	},
];
