import React from "react";
import {
	Container,
	Header,
	Table,
	Grid,
	Input,
	Pagination,
	Dropdown,
} from "semantic-ui-react";
import { Link } from "react-router-dom";

import {
	formatDateToParts,
	stringtoDateParts,
} from "../../functions/date-time";

import { connect } from "react-redux";

const pageOptions = [
	{ key: "20", value: "10", text: "10" },
	{ key: "25", value: "25", text: "25" },
	{ key: "50", value: "50", text: "50" },
	{ key: "100", value: "100", text: "100" },
	{ key: "all", value: "All", text: "All" },
];

class StudentList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,

			sortedList: [],
			activePage: 1,
			itemsPerPage: 10,
		};
		console.log(this.state.allStudents);
		// this.getStudents(this.props.school.ID);
	}
	componentDidMount() {
		let sortedByName = this.state.allStudents.sort(function (a, b) {
			if (a.Name.toUpperCase() < b.Name.toUpperCase()) {
				return -1;
			}
		});
		this.setState({ sortedList: sortedByName });
	}

	handleSearch = (e) => {
		let newList = [];
		let searchterm = e.target.value;
		this.setState({ searchterm: e.target.value });

		if (searchterm !== "") {
			newList = this.state.allStudents.filter((item) => {
				// change name to lowercase
				const name = item.Name.toLowerCase();
				// change search term to lowercase
				const input = searchterm.toLowerCase();

				return name.indexOf(input) > -1;
			});
		} else {
			// If the search bar is empty, set newList to original list
			newList = this.state.allStudents;
		}

		this.setState({
			sortedList: newList,
		});
	};
	//pagination
	pageChange = (e, pageProps) => {
		this.setState({
			activePage: pageProps.activePage,
		});
	};
	itemsPerPageChange = (e, { value }) => {
		let items = value === "All" ? this.state.allStudents.length : value;

		this.setState({ itemsPerPage: items });
	};
	render() {
		const sortedList = this.state.sortedList.slice(
			(this.state.activePage - 1) * this.state.itemsPerPage,
			(this.state.activePage - 1) * this.state.itemsPerPage +
				this.state.itemsPerPage
		);

		return (
			<Container textAlign="left">
				<Grid columns={4}>
					<Grid.Column>
						<Header as="h4" className="student-list-header">
							Student Information
						</Header>
					</Grid.Column>
					<Grid.Column>
						{this.state.sortedList.length > 10 && (
							<Pagination
								defaultActivePage={1}
								totalPages={Math.ceil(
									this.state.sortedList.length / this.state.itemsPerPage
								)}
								onPageChange={this.pageChange}
								boundaryRange={1}
								size="mini"
								className={"pagination"}
							/>
						)}
					</Grid.Column>
					<Grid.Column textAlign="center">
						{this.state.sortedList.length > 10 && (
							<span className={"student-list-dropdown"}>
								{" Show  "}
								<Dropdown
									text={this.state.itemsPerPage.toString()}
									selection
									value={this.state.itemsPerPage}
									options={pageOptions}
									onChange={this.itemsPerPageChange}
									className={"student-list-dropdown"}
								/>
							</span>
						)}
					</Grid.Column>
					<Grid.Column textAlign="right">
						<Input
							icon="search"
							iconPosition="left"
							placeholder="Name Search"
							value={this.state.searchterm || ""}
							onChange={this.handleSearch}
						/>
					</Grid.Column>
				</Grid>

				<Table basic compact>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>{"ID"}</Table.HeaderCell>
							<Table.HeaderCell>{"Contact"}</Table.HeaderCell>
							<Table.HeaderCell>{"Phone"}</Table.HeaderCell>
							<Table.HeaderCell>{"Email"}</Table.HeaderCell>
							<Table.HeaderCell>{"Status"}</Table.HeaderCell>
							<Table.HeaderCell>{"Arrival Date"}</Table.HeaderCell>
							{/* <Table.HeaderCell>{"Location"}</Table.HeaderCell> */}
							<Table.HeaderCell>{"Last Update"}</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{sortedList.map((v, i) => {
							const time = v.LastEventTime && v.LastEventTime;
							const date = new Date(time * 1000);
							const formatDate = formatDateToParts(date);
							let flightdate = v.FlightDate && stringtoDateParts(v.FlightDate);

							// console.log();
							return (
								<Table.Row key={i}>
									<Table.Cell>{v.ID}</Table.Cell>
									<Table.Cell>
										<Link to={`/student-info/${v.ID}`}>{v.Name}</Link>
									</Table.Cell>
									<Table.Cell>{v.Mobile}</Table.Cell>
									<Table.Cell>{v.Email}</Table.Cell>
									<Table.Cell>{v.LastEventText}</Table.Cell>
									<Table.Cell>{flightdate ? flightdate : null}</Table.Cell>
									{/* <Table.Cell>
										{"lat: " + v.LastLatitude}
										<br /> {"long: " + v.LastLongitude}
									</Table.Cell> */}
									<Table.Cell>{formatDate ? formatDate : null}</Table.Cell>
								</Table.Row>
							);
						})}
					</Table.Body>
				</Table>
			</Container>
		);
	}
}

export default connect((state) => ({ school: state.school }))(StudentList);
