import React from "react";
import { Container, Table, Header } from "semantic-ui-react";
import {
	formatDateToParts,
	stringtoDateParts,
} from "../../functions/date-time";
import { Link } from "react-router-dom";

export default class QuarantinedStudents extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			days: props.match.params.noofdays,
			students: props.location.state.students,
		};
	}
	render() {
		// console.log(this.state.students);
		let sortedByName =
			this.state.students &&
			this.state.students.sort(function (a, b) {
				if (!a) {
					return;
				} else {
					if (
						a[1] &&
						a[1].Name.toUpperCase() < b[1].Name &&
						b[1].Name.toUpperCase()
					) {
						return -1;
					}
				}
			});

		return (
			<Container textAlign="left" className="qstudents_days">
				<Header as="h3" className="student-list-header">
					{"Student Information - Day " + this.state.days}
				</Header>

				{
					<Table basic compact>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>{"ID"}</Table.HeaderCell>
								<Table.HeaderCell>{"Contact"}</Table.HeaderCell>
								<Table.HeaderCell>{"Phone"}</Table.HeaderCell>
								<Table.HeaderCell>{"Email"}</Table.HeaderCell>
								<Table.HeaderCell>{"Status"}</Table.HeaderCell>
								<Table.HeaderCell>{"Arrival Date"}</Table.HeaderCell>
								{/* <Table.HeaderCell>{"Location"}</Table.HeaderCell> */}
								<Table.HeaderCell>{"Last Update"}</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{sortedByName.map((v, i) => {
								if (parseInt(this.state.days) !== v[0]) {
									return;
								} else {
									const time = v[1].LastEventTime != null && v[1].LastEventTime;
									const date = new Date(time * 1000);
									const formatDate = formatDateToParts(date);
									let flightdate =
										v[1].FlightDate && stringtoDateParts(v[1].FlightDate);

									// if (parseInt(this.state.days) == v[0]) {
									return (
										<Table.Row key={i}>
											<Table.Cell>{v[1].ID}</Table.Cell>
											<Table.Cell>
												<Link to={`/student-info/${v[1].ID}`}>{v[1].Name}</Link>
											</Table.Cell>
											<Table.Cell>{v[1].Mobile}</Table.Cell>
											<Table.Cell>{v[1].Email}</Table.Cell>
											<Table.Cell>{v[1].LastEventText}</Table.Cell>
											<Table.Cell>{flightdate ? flightdate : null}</Table.Cell>

											<Table.Cell>{formatDate ? formatDate : null}</Table.Cell>
										</Table.Row>
									);
									// }
								}
							})}
						</Table.Body>
					</Table>
				}
			</Container>
		);
	}
}
