import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
//import thunkMiddleware from 'redux-thunk'
import { createStore/*,applyMiddleware*/ } from 'redux'
import { combineReducers } from 'redux'
import school from './SchoolsRedux/school.js'
import login from './LoginRedux/login.js'

const store = createStore(
  combineReducers({
    school,
    login
  }),   
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );


ReactDOM.render(
  // <React.StrictMode>  
    <Provider store={store}>
      <App /> 
    </Provider>  
    ,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
