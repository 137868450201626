import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import logo from "../nui.svg";
import { connect } from "react-redux";
import { post } from "../functions/http.js";

class Nav extends React.Component {
	constructor(props) {
		super(props);
	}

	async logOut(email) {
		let result = await post("logoutUser", { email: email });
		if (result) {
			console.log(result);
			this.props.dispatch({ type: "SET_SCHOOL", payload: [] });
			//this.props.dispatch({ type: "UPDATE_LOGIN", payload: false });
		} else {
			console.log("Log out failed");
		}
	}

	render() {
		return (
			<React.Fragment>
				{this.props.location.pathname !== "/login" && (
					<div className="ui stackable menu mainmenu">
						<Menu.Item>
							<img src={logo} alt="logo" />
							<span className="itemtitle">QTrack</span>
						</Menu.Item>
						<Link className="item" to="/">
							Home
						</Link>
						<Link className="item" to="/reports">
							Reports
						</Link>
						<Link className="item" to="/config">
							Config
						</Link>
						<a
							className="item"
							onClick={(e) => this.logOut(this.props.school.ID)}
						>
							Logout
						</a>
					</div>
				)}
			</React.Fragment>
		);
	}
}

export default connect((state) => ({
	school: state.school,
	login: state.login,
}))(withRouter(Nav));
