import React from "react";
import { Marker, InfoWindow } from "react-google-maps";
import { Container } from "semantic-ui-react";

export default class LocationWithInfo extends React.Component {
	constructor() {
		super();
		this.state = {
			isOpen: false,
		};
		this.onToggleOpen = this.onToggleOpen.bind(this);
	}

	onToggleOpen() {
		this.setState({
			isOpen: !this.state.isOpen,
		});
	}

	render() {
		let lastevent = this.props.content && this.props.content;
		console.log(lastevent);
		let lastevtdate = new Date(lastevent[0] * 1000);

		let eventText = lastevent[2];
		let lastlatitude = lastevent[3];
		let lastlongitude = lastevent[4];

		return (
			<Marker
				position={this.props.position}
				onClick={this.onToggleOpen}
				icon={this.props.icon_color}
			>
				{this.state.isOpen && (
					<InfoWindow onCloseClick={this.onToggleOpen}>
						<Container style={{ height: "fit-content", width: "fit-content" }}>
							<label className={"map_student_info"}>{"Date"}</label>
							<p>{lastevtdate.toDateString()}</p>
							<label className={"map_student_info"}>{"Time"}</label>
							<p>{lastevtdate.toTimeString()}</p>
							<br />
							<label className={"map_student_info"}>{"Event"}</label>
							<p>{eventText}</p>
							<label className={"map_student_info"}>{"Last Latitude"}</label>
							<p>{lastlatitude}</p>
							<label className={"map_student_info"}>{"Last Longitude"}</label>
							<p>{lastlongitude}</p>
							<label className={"map_student_info"}>{"Last Accuracy"}</label>
							<p>{this.props.LastAccuracy}</p>
							<label className={"map_student_info"}>
								{"Quarantine Radius"}
							</label>
							<p>{this.props.QRad}</p>
						</Container>
					</InfoWindow>
				)}
			</Marker>
		);
	}
}
