export const Airports = [
  {
      "Airport Code iATA,FAA": "POM",
      "Airport Name": "Port Moresby Jacksons Int'l Airport",
      "Country": "Papua New Guinea",
      "GMT Offset": "10",
      "Latitude": "147.22000122070312",
      "Location": "  - Port Moresby",
      "Longitude": "-9.443380355834961"
  },
  {
      "Airport Code iATA,FAA": "WWK",
      "Airport Name": "Wewak Int'l Airport",
      "Country": "Papua New Guinea",
      "GMT Offset": "10",
      "Latitude": "143.669006348",
      "Location": "  - Wewak",
      "Longitude": "-3.58383011818"
  },
  {
      "Airport Code iATA,FAA": "KEF",
      "Airport Name": "Keflavik Int'l Airport",
      "Country": "Iceland",
      "GMT Offset": "0",
      "Latitude": "-22.605600357056",
      "Location": "  - Keflavik",
      "Longitude": "63.985000610352"
  },
  {
      "Airport Code iATA,FAA": "YEG",
      "Airport Name": "Edmonton Int'l Airport",
      "Country": "Canada",
      "GMT Offset": "-7",
      "Latitude": "-113.580001831",
      "Location": "  - Edmonton",
      "Longitude": "53.309700012200004"
  },
  {
      "Airport Code iATA,FAA": "YHM",
      "Airport Name": "John C. Munro Hamilton Int'l Airport",
      "Country": "Canada",
      "GMT Offset": "-5",
      "Latitude": "-79.93499755859999",
      "Location": "  - Hamilton",
      "Longitude": "43.173599243199995"
  },
  {
      "Airport Code iATA,FAA": "YHZ",
      "Airport Name": "Halifax / Stanfield Int'l Airport",
      "Country": "Canada",
      "GMT Offset": "-4",
      "Latitude": "-63.5085983276",
      "Location": "  - Halifax",
      "Longitude": "44.8807983398"
  },
  {
      "Airport Code iATA,FAA": "YLW",
      "Airport Name": "Kelowna Int'l Airport",
      "Country": "Canada",
      "GMT Offset": "-8",
      "Latitude": "-119.377998352",
      "Location": "  - Kelowna",
      "Longitude": "49.9561004639"
  },
  {
      "Airport Code iATA,FAA": "YMX",
      "Airport Name": "Montreal Int'l (Mirabel) Airport",
      "Country": "Canada",
      "GMT Offset": "-5",
      "Latitude": "-74.038696",
      "Location": "  - Montreal",
      "Longitude": "45.679501"
  },
  {
      "Airport Code iATA,FAA": "YOW",
      "Airport Name": "Ottawa Macdonald-Cartier Int'l Airport",
      "Country": "Canada",
      "GMT Offset": "-5",
      "Latitude": "-75.66919708251953",
      "Location": "  - Ottawa",
      "Longitude": "45.3224983215332"
  },
  {
      "Airport Code iATA,FAA": "YQB",
      "Airport Name": "Quebec Jean Lesage Int'l Airport",
      "Country": "Canada",
      "GMT Offset": "-5",
      "Latitude": "-71.393303",
      "Location": "  - Quebec",
      "Longitude": "46.7911"
  },
  {
      "Airport Code iATA,FAA": "YQM",
      "Airport Name": "Greater Moncton Int'l Airport",
      "Country": "Canada",
      "GMT Offset": "-4",
      "Latitude": "-64.67859649658203",
      "Location": "  - Moncton",
      "Longitude": "46.11220169067383"
  },
  {
      "Airport Code iATA,FAA": "YQR",
      "Airport Name": "Regina Int'l Airport",
      "Country": "Canada",
      "GMT Offset": "-6",
      "Latitude": "-104.66600036621094",
      "Location": "  - Regina",
      "Longitude": "50.43190002441406"
  },
  {
      "Airport Code iATA,FAA": "YQX",
      "Airport Name": "Gander Int'l Airport",
      "Country": "Canada",
      "GMT Offset": "-3.5",
      "Latitude": "-54.56809997558594",
      "Location": "  - Gander",
      "Longitude": "48.9369010925293"
  },
  {
      "Airport Code iATA,FAA": "YUL",
      "Airport Name": "Montreal / Pierre Elliott Trudeau Int'l Airport",
      "Country": "Canada",
      "GMT Offset": "-5",
      "Latitude": "-73.7407989502",
      "Location": "  - Montreal",
      "Longitude": "45.4706001282"
  },
  {
      "Airport Code iATA,FAA": "YVR",
      "Airport Name": "Vancouver Int'l Airport",
      "Country": "Canada",
      "GMT Offset": "-8",
      "Latitude": "-123.183998108",
      "Location": "  - Vancouver",
      "Longitude": "49.193901062"
  },
  {
      "Airport Code iATA,FAA": "YWG",
      "Airport Name": "Winnipeg / James Armstrong Richardson Int'l Airport",
      "Country": "Canada",
      "GMT Offset": "-6",
      "Latitude": "-97.2398986816",
      "Location": "  - Winnipeg",
      "Longitude": "49.909999847399995"
  },
  {
      "Airport Code iATA,FAA": "YXC",
      "Airport Name": "Cranbrook/Canadian Rockies Int'l Airport",
      "Country": "Canada",
      "GMT Offset": "-7",
      "Latitude": "-115.78199768066",
      "Location": "  - Cranbrook",
      "Longitude": "49.610801696777"
  },
  {
      "Airport Code iATA,FAA": "YXE",
      "Airport Name": "Saskatoon John G. Diefenbaker Int'l Airport",
      "Country": "Canada",
      "GMT Offset": "-6",
      "Latitude": "-106.69999694824219",
      "Location": "  - Saskatoon",
      "Longitude": "52.170799255371094"
  },
  {
      "Airport Code iATA,FAA": "YXY",
      "Airport Name": "Whitehorse / Erik Nielsen Int'l Airport",
      "Country": "Canada",
      "GMT Offset": "-8",
      "Latitude": "-135.067001343",
      "Location": "  - Whitehorse",
      "Longitude": "60.7095985413"
  },
  {
      "Airport Code iATA,FAA": "YYC",
      "Airport Name": "Calgary Int'l Airport",
      "Country": "Canada",
      "GMT Offset": "-7",
      "Latitude": "-114.019996643",
      "Location": "  - Calgary",
      "Longitude": "51.113899231"
  },
  {
      "Airport Code iATA,FAA": "YYJ",
      "Airport Name": "Victoria Int'l Airport",
      "Country": "Canada",
      "GMT Offset": "-8",
      "Latitude": "-123.426002502",
      "Location": "  - Victoria",
      "Longitude": "48.646900177"
  },
  {
      "Airport Code iATA,FAA": "YYT",
      "Airport Name": "St. John's Int'l Airport",
      "Country": "Canada",
      "GMT Offset": "-3.5",
      "Latitude": "-52.7518997192",
      "Location": "  - St. John's",
      "Longitude": "47.618598938"
  },
  {
      "Airport Code iATA,FAA": "YYZ",
      "Airport Name": "Lester B. Pearson Int'l Airport",
      "Country": "Canada",
      "GMT Offset": "-5",
      "Latitude": "-79.63059997559999",
      "Location": "  - Toronto",
      "Longitude": "43.6772003174"
  },
  {
      "Airport Code iATA,FAA": "CZL",
      "Airport Name": "Mohamed Boudiaf Int'l Airport",
      "Country": "Algeria",
      "GMT Offset": "1",
      "Latitude": "6.620389938354492",
      "Location": "  - Constantine",
      "Longitude": "36.2760009765625"
  },
  {
      "Airport Code iATA,FAA": "ACC",
      "Airport Name": "Kotoka Int'l Airport",
      "Country": "Ghana",
      "GMT Offset": "0",
      "Latitude": "-0.16678600013256073",
      "Location": "  - Accra",
      "Longitude": "5.605189800262451"
  },
  {
      "Airport Code iATA,FAA": "ABV",
      "Airport Name": "Nnamdi Azikiwe Int'l Airport",
      "Country": "Nigeria",
      "GMT Offset": "1",
      "Latitude": "7.263169765472412",
      "Location": "  - Abuja",
      "Longitude": "9.006790161132812"
  },
  {
      "Airport Code iATA,FAA": "CBQ",
      "Airport Name": "Margaret Ekpo Int'l Airport",
      "Country": "Nigeria",
      "GMT Offset": "1",
      "Latitude": "8.347200393676758",
      "Location": "  - Calabar",
      "Longitude": "4.976019859313965"
  },
  {
      "Airport Code iATA,FAA": "ENU",
      "Airport Name": "Akanu Ibiam Int'l Airport",
      "Country": "Nigeria",
      "GMT Offset": "1",
      "Latitude": "7.561960220336914",
      "Location": "  - Enugu",
      "Longitude": "6.474269866943359"
  },
  {
      "Airport Code iATA,FAA": "ILR",
      "Airport Name": "Ilorin Int'l Airport",
      "Country": "Nigeria",
      "GMT Offset": "1",
      "Latitude": "4.493919849395752",
      "Location": "  - Ilorin",
      "Longitude": "8.440210342407227"
  },
  {
      "Airport Code iATA,FAA": "KAN",
      "Airport Name": "Mallam Aminu Int'l Airport",
      "Country": "Nigeria",
      "GMT Offset": "1",
      "Latitude": "8.52462",
      "Location": "  - Kano",
      "Longitude": "12.0476"
  },
  {
      "Airport Code iATA,FAA": "MIU",
      "Airport Name": "Maiduguri Int'l Airport",
      "Country": "Nigeria",
      "GMT Offset": "1",
      "Latitude": "13.080900192260742",
      "Location": "  - Maiduguri",
      "Longitude": "11.855299949645996"
  },
  {
      "Airport Code iATA,FAA": "LOS",
      "Airport Name": "Murtala Muhammed Int'l Airport",
      "Country": "Nigeria",
      "GMT Offset": "1",
      "Latitude": "3.321160078048706",
      "Location": "  - Lagos",
      "Longitude": "6.5773701667785645"
  },
  {
      "Airport Code iATA,FAA": "PHC",
      "Airport Name": "Port Harcourt Int'l Airport",
      "Country": "Nigeria",
      "GMT Offset": "1",
      "Latitude": "6.94959020614624",
      "Location": "  - Port Hartcourt",
      "Longitude": "5.0154900550842285"
  },
  {
      "Airport Code iATA,FAA": "SKO",
      "Airport Name": "Sadiq Abubakar III Int'l Airport",
      "Country": "Nigeria",
      "GMT Offset": "1",
      "Latitude": "5.207190036773682",
      "Location": "  - Sokoto",
      "Longitude": "12.916299819946289"
  },
  {
      "Airport Code iATA,FAA": "NIM",
      "Airport Name": "Diori Hamani Int'l Airport",
      "Country": "Niger",
      "GMT Offset": "1",
      "Latitude": "2.18361",
      "Location": "  - Niamey",
      "Longitude": "13.4815"
  },
  {
      "Airport Code iATA,FAA": "AJY",
      "Airport Name": "Mano Dayak Int'l Airport",
      "Country": "Niger",
      "GMT Offset": "1",
      "Latitude": "8.000109672546387",
      "Location": "  - Agadez",
      "Longitude": "16.965999603271484"
  },
  {
      "Airport Code iATA,FAA": "MIR",
      "Airport Name": "Monastir Habib Bourguiba Int'l Airport",
      "Country": "Tunisia",
      "GMT Offset": "1",
      "Latitude": "10.75469970703125",
      "Location": "  - Monastir",
      "Longitude": "35.75809860229492"
  },
  {
      "Airport Code iATA,FAA": "TUN",
      "Airport Name": "Tunis Carthage Int'l Airport",
      "Country": "Tunisia",
      "GMT Offset": "1",
      "Latitude": "10.22719955444336",
      "Location": "  - Tunis",
      "Longitude": "36.85100173950195"
  },
  {
      "Airport Code iATA,FAA": "GAF",
      "Airport Name": "Gafsa Ksar Int'l Airport",
      "Country": "Tunisia",
      "GMT Offset": "1",
      "Latitude": "8.822500228881836",
      "Location": "  - Gafsa",
      "Longitude": "34.422000885009766"
  },
  {
      "Airport Code iATA,FAA": "GAE",
      "Airport Name": "Gabès Matmata Int'l Airport",
      "Country": "Tunisia",
      "GMT Offset": "1",
      "Latitude": "10.103300094604492",
      "Location": "  - Gabes",
      "Longitude": "33.87689971923828"
  },
  {
      "Airport Code iATA,FAA": "DJE",
      "Airport Name": "Djerba Zarzis Int'l Airport",
      "Country": "Tunisia",
      "GMT Offset": "1",
      "Latitude": "10.775500297546387",
      "Location": "  - Djerba",
      "Longitude": "33.875"
  },
  {
      "Airport Code iATA,FAA": "SFA",
      "Airport Name": "Sfax Thyna Int'l Airport",
      "Country": "Tunisia",
      "GMT Offset": "1",
      "Latitude": "10.690999984741211",
      "Location": "  - Sfax",
      "Longitude": "34.71799850463867"
  },
  {
      "Airport Code iATA,FAA": "TOE",
      "Airport Name": "Tozeur Nefta Int'l Airport",
      "Country": "Tunisia",
      "GMT Offset": "1",
      "Latitude": "8.110560417175293",
      "Location": "  - Tozeur",
      "Longitude": "33.939701080322266"
  },
  {
      "Airport Code iATA,FAA": "LRL",
      "Airport Name": "Niamtougou Int'l Airport",
      "Country": "Togo",
      "GMT Offset": "0",
      "Latitude": "1.091249942779541",
      "Location": "  - Niatougou",
      "Longitude": "9.767330169677734"
  },
  {
      "Airport Code iATA,FAA": "ANR",
      "Airport Name": "Antwerp Int'l Airport (Deurne)",
      "Country": "Belgium",
      "GMT Offset": "1",
      "Latitude": "4.46027994156",
      "Location": "  - Antwerp",
      "Longitude": "51.1893997192"
  },
  {
      "Airport Code iATA,FAA": "OST",
      "Airport Name": "Ostend-Bruges Int'l Airport",
      "Country": "Belgium",
      "GMT Offset": "1",
      "Latitude": "2.8622200489",
      "Location": "  - Ostend",
      "Longitude": "51.198898315399994"
  },
  {
      "Airport Code iATA,FAA": "THF",
      "Airport Name": "Berlin-Tempelhof Int'l Airport",
      "Country": "Germany",
      "GMT Offset": "1",
      "Latitude": "13.403900146484375",
      "Location": "  - Berlin",
      "Longitude": "52.472999572753906"
  },
  {
      "Airport Code iATA,FAA": "BFS",
      "Airport Name": "Belfast Int'l Airport",
      "Country": "United Kingdom",
      "GMT Offset": "0",
      "Latitude": "-6.2158298492399995",
      "Location": "  - Belfast",
      "Longitude": "54.6575012207"
  },
  {
      "Airport Code iATA,FAA": "BHX",
      "Airport Name": "Birmingham Int'l Airport",
      "Country": "United Kingdom",
      "GMT Offset": "0",
      "Latitude": "-1.74802994728",
      "Location": "  - Birmingham",
      "Longitude": "52.453899383499994"
  },
  {
      "Airport Code iATA,FAA": "CWL",
      "Airport Name": "Cardiff Int'l Airport",
      "Country": "United Kingdom",
      "GMT Offset": "0",
      "Latitude": "-3.343329906463623",
      "Location": "  - Cardiff",
      "Longitude": "51.39670181274414"
  },
  {
      "Airport Code iATA,FAA": "MSE",
      "Airport Name": "Kent Int'l Airport",
      "Country": "United Kingdom",
      "GMT Offset": "0",
      "Latitude": "1.34611",
      "Location": "  - Manston",
      "Longitude": "51.342201"
  },
  {
      "Airport Code iATA,FAA": "BLK",
      "Airport Name": "Blackpool Int'l Airport",
      "Country": "United Kingdom",
      "GMT Offset": "0",
      "Latitude": "-3.0286099910736084",
      "Location": "  - Blackpool",
      "Longitude": "53.77170181274414"
  },
  {
      "Airport Code iATA,FAA": "GLA",
      "Airport Name": "Glasgow Int'l Airport",
      "Country": "United Kingdom",
      "GMT Offset": "0",
      "Latitude": "-4.43306016922",
      "Location": "  - Glasgow",
      "Longitude": "55.8718986511"
  },
  {
      "Airport Code iATA,FAA": "NWI",
      "Airport Name": "Norwich Int'l Airport",
      "Country": "United Kingdom",
      "GMT Offset": "0",
      "Latitude": "1.28278005123",
      "Location": "  - Norwich",
      "Longitude": "52.6758003235"
  },
  {
      "Airport Code iATA,FAA": "EXT",
      "Airport Name": "Exeter Int'l Airport",
      "Country": "United Kingdom",
      "GMT Offset": "0",
      "Latitude": "-3.4138898849487305",
      "Location": "  - Exeter",
      "Longitude": "50.73440170288086"
  },
  {
      "Airport Code iATA,FAA": "LUX",
      "Airport Name": "Luxembourg-Findel Int'l Airport",
      "Country": "Luxembourg",
      "GMT Offset": "1",
      "Latitude": "6.2044444",
      "Location": "  - Luxemburg",
      "Longitude": "49.6233333"
  },
  {
      "Airport Code iATA,FAA": "KRK",
      "Airport Name": "Kraków John Paul II Int'l Airport",
      "Country": "Poland",
      "GMT Offset": "1",
      "Latitude": "19.7848",
      "Location": "  - Krakow",
      "Longitude": "50.077702"
  },
  {
      "Airport Code iATA,FAA": "KTW",
      "Airport Name": "Katowice Int'l Airport",
      "Country": "Poland",
      "GMT Offset": "1",
      "Latitude": "19.08",
      "Location": "  - Katowice",
      "Longitude": "50.4743"
  },
  {
      "Airport Code iATA,FAA": "BFN",
      "Airport Name": "Bram Fischer Int'l Airport",
      "Country": "South Africa",
      "GMT Offset": "2",
      "Latitude": "26.302400589",
      "Location": "  - Bloemfontein",
      "Longitude": "-29.092699050900002"
  },
  {
      "Airport Code iATA,FAA": "CPT",
      "Airport Name": "Cape Town Int'l Airport",
      "Country": "South Africa",
      "GMT Offset": "2",
      "Latitude": "18.6016998291",
      "Location": "  - Cape Town",
      "Longitude": "-33.9648017883"
  },
  {
      "Airport Code iATA,FAA": "DUR",
      "Airport Name": "King Shaka Int'l Airport",
      "Country": "South Africa",
      "GMT Offset": "2",
      "Latitude": "31.1197222222",
      "Location": "  - Durban",
      "Longitude": "-29.6144444444"
  },
  {
      "Airport Code iATA,FAA": "JNB",
      "Airport Name": "OR Tambo Int'l Airport",
      "Country": "South Africa",
      "GMT Offset": "2",
      "Latitude": "28.246",
      "Location": "  - Johannesburg",
      "Longitude": "-26.1392"
  },
  {
      "Airport Code iATA,FAA": "NTY",
      "Airport Name": "Pilanesberg Int'l Airport",
      "Country": "South Africa",
      "GMT Offset": "2",
      "Latitude": "27.173400878900004",
      "Location": "  - Pilanesberg",
      "Longitude": "-25.333799362199997"
  },
  {
      "Airport Code iATA,FAA": "PTG",
      "Airport Name": "Polokwane Int'l Airport",
      "Country": "South Africa",
      "GMT Offset": "2",
      "Latitude": "29.458615",
      "Location": "  - Potgietersrus",
      "Longitude": "-23.845269"
  },
  {
      "Airport Code iATA,FAA": "GBE",
      "Airport Name": "Sir Seretse Khama Int'l Airport",
      "Country": "Botswana",
      "GMT Offset": "2",
      "Latitude": "25.9182",
      "Location": "  - Gaberone",
      "Longitude": "-24.555201"
  },
  {
      "Airport Code iATA,FAA": "BGF",
      "Airport Name": "Bangui M'Poko Int'l Airport",
      "Country": "Central African Republic",
      "GMT Offset": "1",
      "Latitude": "18.518800735473633",
      "Location": "  - Bangui",
      "Longitude": "4.39847993850708"
  },
  {
      "Airport Code iATA,FAA": "MRU",
      "Airport Name": "Sir Seewoosagur Ramgoolam Int'l Airport",
      "Country": "Mauritius",
      "GMT Offset": "4",
      "Latitude": "57.683601",
      "Location": "  - Plaisance",
      "Longitude": "-20.430201"
  },
  {
      "Airport Code iATA,FAA": "DLA",
      "Airport Name": "Douala Int'l Airport",
      "Country": "Cameroon",
      "GMT Offset": "1",
      "Latitude": "9.719479560849999",
      "Location": "  - Douala",
      "Longitude": "4.0060801506"
  },
  {
      "Airport Code iATA,FAA": "GOU",
      "Airport Name": "Garoua Int'l Airport",
      "Country": "Cameroon",
      "GMT Offset": "1",
      "Latitude": "13.370100021362305",
      "Location": "  - Garoua",
      "Longitude": "9.33588981628418"
  },
  {
      "Airport Code iATA,FAA": "LUN",
      "Airport Name": "Kenneth Kaunda Int'l Airport Lusaka",
      "Country": "Zambia",
      "GMT Offset": "2",
      "Latitude": "28.4526004791",
      "Location": "  - Lusaka",
      "Longitude": "-15.3308000565"
  },
  {
      "Airport Code iATA,FAA": "NLA",
      "Airport Name": "Simon Mwansa Kapwepwe Int'l Airport",
      "Country": "Zambia",
      "GMT Offset": "2",
      "Latitude": "28.66489982605",
      "Location": "  - Ndola",
      "Longitude": "-12.998100280762"
  },
  {
      "Airport Code iATA,FAA": "HAH",
      "Airport Name": "Prince Said Ibrahim Int'l Airport",
      "Country": "Comoros",
      "GMT Offset": "3",
      "Latitude": "43.2719",
      "Location": "  - Moroni",
      "Longitude": "-11.5337"
  },
  {
      "Airport Code iATA,FAA": "DZA",
      "Airport Name": "Dzaoudzi Pamandzi Int'l Airport",
      "Country": "Mayotte",
      "GMT Offset": "3",
      "Latitude": "45.28110122680664",
      "Location": "  - Dzaoudzi",
      "Longitude": "-12.804699897766113"
  },
  {
      "Airport Code iATA,FAA": "LBV",
      "Airport Name": "Libreville Leon M'ba Int'l Airport",
      "Country": "Gabon",
      "GMT Offset": "1",
      "Latitude": "9.412280082699999",
      "Location": "  - Libreville",
      "Longitude": "0.458600014448"
  },
  {
      "Airport Code iATA,FAA": "MVB",
      "Airport Name": "M'Vengue El Hadj Omar Bongo Ondimba Int'l Airport",
      "Country": "Gabon",
      "GMT Offset": "1",
      "Latitude": "13.437999725341797",
      "Location": "  - Franceville",
      "Longitude": "-1.6561599969863892"
  },
  {
      "Airport Code iATA,FAA": "TMS",
      "Airport Name": "São Tomé Int'l Airport",
      "Country": "Sao Tome and Principe",
      "GMT Offset": "0",
      "Latitude": "6.7121500968933105",
      "Location": "  - Sao Tome",
      "Longitude": "0.3781749904155731"
  },
  {
      "Airport Code iATA,FAA": "SEZ",
      "Airport Name": "Seychelles Int'l Airport",
      "Country": "Seychelles",
      "GMT Offset": "4",
      "Latitude": "55.521801",
      "Location": "  - Mahe",
      "Longitude": "-4.67434"
  },
  {
      "Airport Code iATA,FAA": "NDJ",
      "Airport Name": "N'Djamena Int'l Airport",
      "Country": "Chad",
      "GMT Offset": "1",
      "Latitude": "15.034",
      "Location": "  - N'djamena",
      "Longitude": "12.1337"
  },
  {
      "Airport Code iATA,FAA": "BUQ",
      "Airport Name": "Joshua Mqabuko Nkomo Int'l Airport",
      "Country": "Zimbabwe",
      "GMT Offset": "2",
      "Latitude": "28.617901",
      "Location": "  - Bulawayo",
      "Longitude": "-20.017401"
  },
  {
      "Airport Code iATA,FAA": "VFA",
      "Airport Name": "Victoria Falls Int'l Airport",
      "Country": "Zimbabwe",
      "GMT Offset": "2",
      "Latitude": "25.839000701904297",
      "Location": "  - Victoria Falls",
      "Longitude": "-18.09589958190918"
  },
  {
      "Airport Code iATA,FAA": "HRE",
      "Airport Name": "Robert Gabriel Mugabe Int'l Airport",
      "Country": "Zimbabwe",
      "GMT Offset": "2",
      "Latitude": "31.0928",
      "Location": "  - Harare",
      "Longitude": "-17.931801"
  },
  {
      "Airport Code iATA,FAA": "KAB",
      "Airport Name": "Kariba Int'l Airport",
      "Country": "Zimbabwe",
      "GMT Offset": "2",
      "Latitude": "28.885000228881836",
      "Location": "  - Kariba",
      "Longitude": "-16.519800186157227"
  },
  {
      "Airport Code iATA,FAA": "MVZ",
      "Airport Name": "Masvingo Int'l Airport",
      "Country": "Zimbabwe",
      "GMT Offset": "2",
      "Latitude": "30.859100341796875",
      "Location": "  - Masvingo",
      "Longitude": "-20.055299758911133"
  },
  {
      "Airport Code iATA,FAA": "BLZ",
      "Airport Name": "Chileka Int'l Airport",
      "Country": "Malawi",
      "GMT Offset": "2",
      "Latitude": "34.9739990234375",
      "Location": "  - Blantyre",
      "Longitude": "-15.679100036621094"
  },
  {
      "Airport Code iATA,FAA": "LLW",
      "Airport Name": "Lilongwe Int'l Airport",
      "Country": "Malawi",
      "GMT Offset": "2",
      "Latitude": "33.78099823",
      "Location": "  - Lilongwe",
      "Longitude": "-13.7894001007"
  },
  {
      "Airport Code iATA,FAA": "MSU",
      "Airport Name": "Moshoeshoe I Int'l Airport",
      "Country": "Lesotho",
      "GMT Offset": "2",
      "Latitude": "27.552499771118164",
      "Location": "  - Maseru",
      "Longitude": "-29.462299346923828"
  },
  {
      "Airport Code iATA,FAA": "FIH",
      "Airport Name": "Ndjili Int'l Airport",
      "Country": "Congo (Kinshasa)",
      "GMT Offset": "1",
      "Latitude": "15.4446",
      "Location": "  - Kinshasa",
      "Longitude": "-4.38575"
  },
  {
      "Airport Code iATA,FAA": "FKI",
      "Airport Name": "Bangoka Int'l Airport",
      "Country": "Congo (Kinshasa)",
      "GMT Offset": "2",
      "Latitude": "25.3379993439",
      "Location": "  - Kisangani",
      "Longitude": "0.481638997793"
  },
  {
      "Airport Code iATA,FAA": "GOM",
      "Airport Name": "Goma Int'l Airport",
      "Country": "Congo (Kinshasa)",
      "GMT Offset": "2",
      "Latitude": "29.238500595092773",
      "Location": "  - Goma",
      "Longitude": "-1.6708099842071533"
  },
  {
      "Airport Code iATA,FAA": "FBM",
      "Airport Name": "Lubumbashi Int'l Airport",
      "Country": "Congo (Kinshasa)",
      "GMT Offset": "2",
      "Latitude": "27.5308990479",
      "Location": "  - Lubumashi",
      "Longitude": "-11.5913000107"
  },
  {
      "Airport Code iATA,FAA": "BKO",
      "Airport Name": "Modibo Keita Int'l Airport",
      "Country": "Mali",
      "GMT Offset": "0",
      "Latitude": "-7.94994",
      "Location": "  - Bamako",
      "Longitude": "12.5335"
  },
  {
      "Airport Code iATA,FAA": "BJL",
      "Airport Name": "Banjul Int'l Airport",
      "Country": "Gambia",
      "GMT Offset": "0",
      "Latitude": "-16.65220069885254",
      "Location": "  - Banjul",
      "Longitude": "13.338000297546387"
  },
  {
      "Airport Code iATA,FAA": "FNA",
      "Airport Name": "Lungi Int'l Airport",
      "Country": "Sierra Leone",
      "GMT Offset": "0",
      "Latitude": "-13.1955",
      "Location": "  - Freetown",
      "Longitude": "8.61644"
  },
  {
      "Airport Code iATA,FAA": "ROB",
      "Airport Name": "Roberts Int'l Airport",
      "Country": "Liberia",
      "GMT Offset": "0",
      "Latitude": "-10.3623",
      "Location": "  - Monrovia",
      "Longitude": "6.23379"
  },
  {
      "Airport Code iATA,FAA": "CMN",
      "Airport Name": "Mohammed V Int'l Airport",
      "Country": "Morocco",
      "GMT Offset": "0",
      "Latitude": "-7.589970111846924",
      "Location": "  - Casablanca",
      "Longitude": "33.36750030517578"
  },
  {
      "Airport Code iATA,FAA": "DKR",
      "Airport Name": "Léopold Sédar Senghor Int'l Airport",
      "Country": "Senegal",
      "GMT Offset": "0",
      "Latitude": "-17.49020004272461",
      "Location": "  - Dakar",
      "Longitude": "14.739700317382812"
  },
  {
      "Airport Code iATA,FAA": "NKC",
      "Airport Name": "Nouakchott–Oumtounsy Int'l Airport",
      "Country": "Mauritania",
      "GMT Offset": "0",
      "Latitude": "-15.9697222",
      "Location": "  - Nouakschott",
      "Longitude": "18.31"
  },
  {
      "Airport Code iATA,FAA": "ATR",
      "Airport Name": "Atar Int'l Airport",
      "Country": "Mauritania",
      "GMT Offset": "0",
      "Latitude": "-13.04319953918457",
      "Location": "  - Atar",
      "Longitude": "20.506799697875977"
  },
  {
      "Airport Code iATA,FAA": "NDB",
      "Airport Name": "Nouadhibou Int'l Airport",
      "Country": "Mauritania",
      "GMT Offset": "0",
      "Latitude": "-17.030000686645508",
      "Location": "  - Nouadhibou",
      "Longitude": "20.9330997467041"
  },
  {
      "Airport Code iATA,FAA": "SID",
      "Airport Name": "Amílcar Cabral Int'l Airport",
      "Country": "Cape Verde",
      "GMT Offset": "-1",
      "Latitude": "-22.9494",
      "Location": "  - Amilcar Cabral",
      "Longitude": "16.7414"
  },
  {
      "Airport Code iATA,FAA": "ADD",
      "Airport Name": "Addis Ababa Bole Int'l Airport",
      "Country": "Ethiopia",
      "GMT Offset": "3",
      "Latitude": "38.799301147499996",
      "Location": "  - Addis Ababa",
      "Longitude": "8.97789001465"
  },
  {
      "Airport Code iATA,FAA": "DIR",
      "Airport Name": "Aba Tenna Dejazmach Yilma Int'l Airport",
      "Country": "Ethiopia",
      "GMT Offset": "3",
      "Latitude": "41.85419845581055",
      "Location": "  - Dire Dawa",
      "Longitude": "9.624699592590332"
  },
  {
      "Airport Code iATA,FAA": "BJM",
      "Airport Name": "Bujumbura Int'l Airport",
      "Country": "Burundi",
      "GMT Offset": "2",
      "Latitude": "29.318500518798828",
      "Location": "  - Bujumbura",
      "Longitude": "-3.3240199089050293"
  },
  {
      "Airport Code iATA,FAA": "HGA",
      "Airport Name": "Egal Int'l Airport",
      "Country": "Somalia",
      "GMT Offset": "3",
      "Latitude": "44.08879852294922",
      "Location": "  - Hargeisa",
      "Longitude": "9.518170356750488"
  },
  {
      "Airport Code iATA,FAA": "CAI",
      "Airport Name": "Cairo Int'l Airport",
      "Country": "Egypt",
      "GMT Offset": "2",
      "Latitude": "31.40559959411621",
      "Location": "  - Cairo",
      "Longitude": "30.12190055847168"
  },
  {
      "Airport Code iATA,FAA": "HRG",
      "Airport Name": "Hurghada Int'l Airport",
      "Country": "Egypt",
      "GMT Offset": "2",
      "Latitude": "33.799400329589844",
      "Location": "  - Hurghada",
      "Longitude": "27.178300857543945"
  },
  {
      "Airport Code iATA,FAA": "LXR",
      "Airport Name": "Luxor Int'l Airport",
      "Country": "Egypt",
      "GMT Offset": "2",
      "Latitude": "32.7066",
      "Location": "  - Luxor",
      "Longitude": "25.671"
  },
  {
      "Airport Code iATA,FAA": "SKV",
      "Airport Name": "St Catherine Int'l Airport",
      "Country": "Egypt",
      "GMT Offset": "2",
      "Latitude": "34.0625",
      "Location": "  - St. Catherine",
      "Longitude": "28.685300827"
  },
  {
      "Airport Code iATA,FAA": "ASW",
      "Airport Name": "Aswan Int'l Airport",
      "Country": "Egypt",
      "GMT Offset": "2",
      "Latitude": "32.8199996948",
      "Location": "  - Aswan",
      "Longitude": "23.9643993378"
  },
  {
      "Airport Code iATA,FAA": "EDL",
      "Airport Name": "Eldoret Int'l Airport",
      "Country": "Kenya",
      "GMT Offset": "3",
      "Latitude": "35.23889923095703",
      "Location": "  - Eldoret",
      "Longitude": "0.4044579863548279"
  },
  {
      "Airport Code iATA,FAA": "MBA",
      "Airport Name": "Mombasa Moi Int'l Airport",
      "Country": "Kenya",
      "GMT Offset": "3",
      "Latitude": "39.594200134277344",
      "Location": "  - Mombasa",
      "Longitude": "-4.034830093383789"
  },
  {
      "Airport Code iATA,FAA": "BEN",
      "Airport Name": "Benina Int'l Airport",
      "Country": "Libya",
      "GMT Offset": "2",
      "Latitude": "20.2695007324",
      "Location": "  - Benghazi",
      "Longitude": "32.096801757799994"
  },
  {
      "Airport Code iATA,FAA": "TIP",
      "Airport Name": "Tripoli Int'l Airport",
      "Country": "Libya",
      "GMT Offset": "2",
      "Latitude": "13.1590003967",
      "Location": "  - Tripoli",
      "Longitude": "32.6635017395"
  },
  {
      "Airport Code iATA,FAA": "KGL",
      "Airport Name": "Kigali Int'l Airport",
      "Country": "Rwanda",
      "GMT Offset": "2",
      "Latitude": "30.1395",
      "Location": "  - Kigali",
      "Longitude": "-1.96863"
  },
  {
      "Airport Code iATA,FAA": "JUB",
      "Airport Name": "Juba Int'l Airport",
      "Country": "South Sudan",
      "GMT Offset": "3",
      "Latitude": "31.6011009216",
      "Location": "  - Juba",
      "Longitude": "4.87201023102"
  },
  {
      "Airport Code iATA,FAA": "KRT",
      "Airport Name": "Khartoum Int'l Airport",
      "Country": "Sudan",
      "GMT Offset": "3",
      "Latitude": "32.553199768066406",
      "Location": "  - Khartoum",
      "Longitude": "15.589500427246094"
  },
  {
      "Airport Code iATA,FAA": "DAR",
      "Airport Name": "Julius Nyerere Int'l Airport",
      "Country": "Tanzania",
      "GMT Offset": "3",
      "Latitude": "39.202599",
      "Location": "  - Dar Es Salaam",
      "Longitude": "-6.87811"
  },
  {
      "Airport Code iATA,FAA": "JRO",
      "Airport Name": "Kilimanjaro Int'l Airport",
      "Country": "Tanzania",
      "GMT Offset": "3",
      "Latitude": "37.0745010376",
      "Location": "  - Kilimanjaro",
      "Longitude": "-3.42940998077"
  },
  {
      "Airport Code iATA,FAA": "ZNZ",
      "Airport Name": "Abeid Amani Karume Int'l Airport",
      "Country": "Tanzania",
      "GMT Offset": "3",
      "Latitude": "39.224899",
      "Location": "  - Zanzibar",
      "Longitude": "-6.22202"
  },
  {
      "Airport Code iATA,FAA": "EBB",
      "Airport Name": "Entebbe Int'l Airport",
      "Country": "Uganda",
      "GMT Offset": "3",
      "Latitude": "32.443501",
      "Location": "  - Entebbe",
      "Longitude": "0.042386"
  },
  {
      "Airport Code iATA,FAA": "TIA",
      "Airport Name": "Tirana Int'l Airport Mother Teresa",
      "Country": "Albania",
      "GMT Offset": "1",
      "Latitude": "19.7206001282",
      "Location": "  - Tirana",
      "Longitude": "41.4146995544"
  },
  {
      "Airport Code iATA,FAA": "PDV",
      "Airport Name": "Plovdiv Int'l Airport",
      "Country": "Bulgaria",
      "GMT Offset": "2",
      "Latitude": "24.8508",
      "Location": "  - Plovdiv",
      "Longitude": "42.067799"
  },
  {
      "Airport Code iATA,FAA": "LCA",
      "Airport Name": "Larnaca Int'l Airport",
      "Country": "Cyprus",
      "GMT Offset": "2",
      "Latitude": "33.624900817871094",
      "Location": "  - Larnaca",
      "Longitude": "34.875099182128906"
  },
  {
      "Airport Code iATA,FAA": "PFO",
      "Airport Name": "Paphos Int'l Airport",
      "Country": "Cyprus",
      "GMT Offset": "2",
      "Latitude": "32.48569869995117",
      "Location": "  - Paphos",
      "Longitude": "34.71799850463867"
  },
  {
      "Airport Code iATA,FAA": "ALC",
      "Airport Name": "Alicante Int'l Airport",
      "Country": "Spain",
      "GMT Offset": "1",
      "Latitude": "-0.5581560134887695",
      "Location": "  - Alicante",
      "Longitude": "38.28219985961914"
  },
  {
      "Airport Code iATA,FAA": "LEI",
      "Airport Name": "Almería Int'l Airport",
      "Country": "Spain",
      "GMT Offset": "1",
      "Latitude": "-2.3701000213623047",
      "Location": "  - Almeria",
      "Longitude": "36.84389877319336"
  },
  {
      "Airport Code iATA,FAA": "BCN",
      "Airport Name": "Barcelona Int'l Airport",
      "Country": "Spain",
      "GMT Offset": "1",
      "Latitude": "2.07846",
      "Location": "  - Barcelona",
      "Longitude": "41.2971"
  },
  {
      "Airport Code iATA,FAA": "CDG",
      "Airport Name": "Charles de Gaulle Int'l Airport",
      "Country": "France",
      "GMT Offset": "1",
      "Latitude": "2.55",
      "Location": "  - Paris",
      "Longitude": "49.012798"
  },
  {
      "Airport Code iATA,FAA": "HER",
      "Airport Name": "Heraklion Int'l Nikos Kazantzakis Airport",
      "Country": "Greece",
      "GMT Offset": "2",
      "Latitude": "25.180299758900002",
      "Location": "  - Heraklion",
      "Longitude": "35.3396987915"
  },
  {
      "Airport Code iATA,FAA": "CFU",
      "Airport Name": "Ioannis Kapodistrias Int'l Airport",
      "Country": "Greece",
      "GMT Offset": "2",
      "Latitude": "19.911699295043945",
      "Location": "  - Kerkyra/corfu",
      "Longitude": "39.601898193359375"
  },
  {
      "Airport Code iATA,FAA": "KVA",
      "Airport Name": "Alexander the Great Int'l Airport",
      "Country": "Greece",
      "GMT Offset": "2",
      "Latitude": "24.619199752807617",
      "Location": "  - Kavala",
      "Longitude": "40.913299560546875"
  },
  {
      "Airport Code iATA,FAA": "MJT",
      "Airport Name": "Mytilene Int'l Airport",
      "Country": "Greece",
      "GMT Offset": "2",
      "Latitude": "26.5983009338",
      "Location": "  - Mytilini",
      "Longitude": "39.0567016602"
  },
  {
      "Airport Code iATA,FAA": "CHQ",
      "Airport Name": "Chania Int'l Airport",
      "Country": "Greece",
      "GMT Offset": "2",
      "Latitude": "24.149700164794922",
      "Location": "  - Chania",
      "Longitude": "35.531700134277344"
  },
  {
      "Airport Code iATA,FAA": "SKG",
      "Airport Name": "Thessaloniki Macedonia Int'l Airport",
      "Country": "Greece",
      "GMT Offset": "2",
      "Latitude": "22.97089958190918",
      "Location": "  - Thessaloniki",
      "Longitude": "40.51969909667969"
  },
  {
      "Airport Code iATA,FAA": "ZTH",
      "Airport Name": "Zakynthos Int'l Airport \"Dionysios Solomos\"",
      "Country": "Greece",
      "GMT Offset": "2",
      "Latitude": "20.8843",
      "Location": "  - Zakynthos",
      "Longitude": "37.7509"
  },
  {
      "Airport Code iATA,FAA": "BUD",
      "Airport Name": "Budapest Liszt Ferenc Int'l Airport",
      "Country": "Hungary",
      "GMT Offset": "1",
      "Latitude": "19.261093",
      "Location": "  - Budapest",
      "Longitude": "47.42976"
  },
  {
      "Airport Code iATA,FAA": "DEB",
      "Airport Name": "Debrecen Int'l Airport",
      "Country": "Hungary",
      "GMT Offset": "1",
      "Latitude": "21.615299224853516",
      "Location": "  - Debrecen",
      "Longitude": "47.48889923095703"
  },
  {
      "Airport Code iATA,FAA": "PSR",
      "Airport Name": "Pescara Int'l Airport",
      "Country": "Italy",
      "GMT Offset": "1",
      "Latitude": "14.1811",
      "Location": "  - Pescara",
      "Longitude": "42.431702"
  },
  {
      "Airport Code iATA,FAA": "MXP",
      "Airport Name": "Malpensa Int'l Airport",
      "Country": "Italy",
      "GMT Offset": "1",
      "Latitude": "8.72811",
      "Location": "  - Milano",
      "Longitude": "45.6306"
  },
  {
      "Airport Code iATA,FAA": "BGY",
      "Airport Name": "Il Caravaggio Int'l Airport",
      "Country": "Italy",
      "GMT Offset": "1",
      "Latitude": "9.70417",
      "Location": "  - Bergamo",
      "Longitude": "45.673901"
  },
  {
      "Airport Code iATA,FAA": "ALL",
      "Airport Name": "Villanova D'Albenga Int'l Airport",
      "Country": "Italy",
      "GMT Offset": "1",
      "Latitude": "8.12743",
      "Location": "  - Albenga",
      "Longitude": "44.050598"
  },
  {
      "Airport Code iATA,FAA": "CUF",
      "Airport Name": "Cuneo Int'l Airport",
      "Country": "Italy",
      "GMT Offset": "1",
      "Latitude": "7.62322",
      "Location": "  - Cuneo",
      "Longitude": "44.547001"
  },
  {
      "Airport Code iATA,FAA": "RMI",
      "Airport Name": "Federico Fellini Int'l Airport",
      "Country": "Italy",
      "GMT Offset": "1",
      "Latitude": "12.6117",
      "Location": "  - Rimini",
      "Longitude": "44.020302"
  },
  {
      "Airport Code iATA,FAA": "CIA",
      "Airport Name": "Ciampino–G. B. Pastine Int'l Airport",
      "Country": "Italy",
      "GMT Offset": "1",
      "Latitude": "12.5949",
      "Location": "  - Rome",
      "Longitude": "41.7994"
  },
  {
      "Airport Code iATA,FAA": "NAP",
      "Airport Name": "Naples Int'l Airport",
      "Country": "Italy",
      "GMT Offset": "1",
      "Latitude": "14.2908",
      "Location": "  - Naples",
      "Longitude": "40.886002"
  },
  {
      "Airport Code iATA,FAA": "PSA",
      "Airport Name": "Pisa Int'l Airport",
      "Country": "Italy",
      "GMT Offset": "1",
      "Latitude": "10.3927",
      "Location": "  - Pisa",
      "Longitude": "43.683899"
  },
  {
      "Airport Code iATA,FAA": "PEG",
      "Airport Name": "Perugia San Francesco d'Assisi – Umbria Int'l Airport",
      "Country": "Italy",
      "GMT Offset": "1",
      "Latitude": "12.5132",
      "Location": "  - Perugia",
      "Longitude": "43.095901"
  },
  {
      "Airport Code iATA,FAA": "KLV",
      "Airport Name": "Karlovy Vary Int'l Airport",
      "Country": "Czech Republic",
      "GMT Offset": "1",
      "Latitude": "12.914999961853027",
      "Location": "  - Karlovy Vary",
      "Longitude": "50.202999114990234"
  },
  {
      "Airport Code iATA,FAA": "TLV",
      "Airport Name": "Ben Gurion Int'l Airport",
      "Country": "Israel",
      "GMT Offset": "2",
      "Latitude": "34.88669967651367",
      "Location": "  - Tel-aviv",
      "Longitude": "32.01139831542969"
  },
  {
      "Airport Code iATA,FAA": "HFA",
      "Airport Name": "Haifa Int'l Airport",
      "Country": "Israel",
      "GMT Offset": "2",
      "Latitude": "35.04309844970703",
      "Location": "  - Haifa",
      "Longitude": "32.80939865112305"
  },
  {
      "Airport Code iATA,FAA": "VDA",
      "Airport Name": "Ovda Int'l Airport",
      "Country": "Israel",
      "GMT Offset": "2",
      "Latitude": "34.93579864501953",
      "Location": "  - Ovda",
      "Longitude": "29.94029998779297"
  },
  {
      "Airport Code iATA,FAA": "MLA",
      "Airport Name": "Malta Int'l Airport",
      "Country": "Malta",
      "GMT Offset": "1",
      "Latitude": "14.4775",
      "Location": "  - Malta",
      "Longitude": "35.857498"
  },
  {
      "Airport Code iATA,FAA": "VIE",
      "Airport Name": "Vienna Int'l Airport",
      "Country": "Austria",
      "GMT Offset": "1",
      "Latitude": "16.569700241089",
      "Location": "  - Vienna",
      "Longitude": "48.110298156738"
  },
  {
      "Airport Code iATA,FAA": "OMO",
      "Airport Name": "Mostar Int'l Airport",
      "Country": "Bosnia and Herzegovina",
      "GMT Offset": "1",
      "Latitude": "17.84589958190918",
      "Location": "  - Mostar",
      "Longitude": "43.282901763916016"
  },
  {
      "Airport Code iATA,FAA": "SJJ",
      "Airport Name": "Sarajevo Int'l Airport",
      "Country": "Bosnia and Herzegovina",
      "GMT Offset": "1",
      "Latitude": "18.331499099731445",
      "Location": "  - Sarajevo",
      "Longitude": "43.82460021972656"
  },
  {
      "Airport Code iATA,FAA": "ARW",
      "Airport Name": "Arad Int'l Airport",
      "Country": "Romania",
      "GMT Offset": "2",
      "Latitude": "21.261999130249023",
      "Location": "  - Arad",
      "Longitude": "46.17660140991211"
  },
  {
      "Airport Code iATA,FAA": "BBU",
      "Airport Name": "Băneasa Int'l Airport",
      "Country": "Romania",
      "GMT Offset": "2",
      "Latitude": "26.102100372314453",
      "Location": "  - Bucharest",
      "Longitude": "44.50320053100586"
  },
  {
      "Airport Code iATA,FAA": "CND",
      "Airport Name": "Mihail Kogălniceanu Int'l Airport",
      "Country": "Romania",
      "GMT Offset": "2",
      "Latitude": "28.488300323486328",
      "Location": "  - Constanta",
      "Longitude": "44.36220169067383"
  },
  {
      "Airport Code iATA,FAA": "CLJ",
      "Airport Name": "Cluj-Napoca Int'l Airport",
      "Country": "Romania",
      "GMT Offset": "2",
      "Latitude": "23.686199188232422",
      "Location": "  - Cluj-napoca",
      "Longitude": "46.78519821166992"
  },
  {
      "Airport Code iATA,FAA": "OMR",
      "Airport Name": "Oradea Int'l Airport",
      "Country": "Romania",
      "GMT Offset": "2",
      "Latitude": "21.90250015258789",
      "Location": "  - Oradea",
      "Longitude": "47.025299072265625"
  },
  {
      "Airport Code iATA,FAA": "OTP",
      "Airport Name": "Henri Coandă Int'l Airport",
      "Country": "Romania",
      "GMT Offset": "2",
      "Latitude": "26.085",
      "Location": "  - Bucharest",
      "Longitude": "44.5711111"
  },
  {
      "Airport Code iATA,FAA": "SBZ",
      "Airport Name": "Sibiu Int'l Airport",
      "Country": "Romania",
      "GMT Offset": "2",
      "Latitude": "24.091299057006836",
      "Location": "  - Sibiu",
      "Longitude": "45.78559875488281"
  },
  {
      "Airport Code iATA,FAA": "TGM",
      "Airport Name": "Transilvania Târgu Mureş Int'l Airport",
      "Country": "Romania",
      "GMT Offset": "2",
      "Latitude": "24.412500381469727",
      "Location": "  - Tirgu Mures",
      "Longitude": "46.46770095825195"
  },
  {
      "Airport Code iATA,FAA": "GVA",
      "Airport Name": "Geneva Cointrin Int'l Airport",
      "Country": "Switzerland",
      "GMT Offset": "1",
      "Latitude": "6.108950138092041",
      "Location": "  - Geneva",
      "Longitude": "46.23809814453125"
  },
  {
      "Airport Code iATA,FAA": "ESB",
      "Airport Name": "Esenboğa Int'l Airport",
      "Country": "Turkey",
      "GMT Offset": "3",
      "Latitude": "32.995098114",
      "Location": "  - Ankara",
      "Longitude": "40.128101348899996"
  },
  {
      "Airport Code iATA,FAA": "AYT",
      "Airport Name": "Antalya Int'l Airport",
      "Country": "Turkey",
      "GMT Offset": "3",
      "Latitude": "30.800501",
      "Location": "  - Antalya",
      "Longitude": "36.898701"
  },
  {
      "Airport Code iATA,FAA": "GZT",
      "Airport Name": "Gaziantep Int'l Airport",
      "Country": "Turkey",
      "GMT Offset": "3",
      "Latitude": "37.4786987305",
      "Location": "  - Gaziantep",
      "Longitude": "36.9472007751"
  },
  {
      "Airport Code iATA,FAA": "ISL",
      "Airport Name": "Atatürk Int'l Airport",
      "Country": "Turkey",
      "GMT Offset": "3",
      "Latitude": "28.8146",
      "Location": "  - Istanbul",
      "Longitude": "40.976898"
  },
  {
      "Airport Code iATA,FAA": "ADB",
      "Airport Name": "Adnan Menderes Int'l Airport",
      "Country": "Turkey",
      "GMT Offset": "3",
      "Latitude": "27.156999588",
      "Location": "  - Izmir",
      "Longitude": "38.2924003601"
  },
  {
      "Airport Code iATA,FAA": "DLM",
      "Airport Name": "Dalaman Int'l Airport",
      "Country": "Turkey",
      "GMT Offset": "3",
      "Latitude": "28.7924995422",
      "Location": "  - Dalaman",
      "Longitude": "36.7131004333"
  },
  {
      "Airport Code iATA,FAA": "ERZ",
      "Airport Name": "Erzurum Int'l Airport",
      "Country": "Turkey",
      "GMT Offset": "3",
      "Latitude": "41.17020034789999",
      "Location": "  - Erzurum",
      "Longitude": "39.9565010071"
  },
  {
      "Airport Code iATA,FAA": "TZX",
      "Airport Name": "Trabzon Int'l Airport",
      "Country": "Turkey",
      "GMT Offset": "3",
      "Latitude": "39.78969955444336",
      "Location": "  - Trabzon",
      "Longitude": "40.99509811401367"
  },
  {
      "Airport Code iATA,FAA": "BZY",
      "Airport Name": "Bălți Int'l Airport",
      "Country": "Moldova",
      "GMT Offset": "2",
      "Latitude": "27.777222",
      "Location": "  - Saltsy",
      "Longitude": "47.843056"
  },
  {
      "Airport Code iATA,FAA": "KIV",
      "Airport Name": "Chişinău Int'l Airport",
      "Country": "Moldova",
      "GMT Offset": "2",
      "Latitude": "28.930999755859375",
      "Location": "  - Chisinau",
      "Longitude": "46.92770004272461"
  },
  {
      "Airport Code iATA,FAA": "PRN",
      "Airport Name": "Priština Int'l Airport",
      "Country": "Serbia",
      "GMT Offset": "1",
      "Latitude": "21.035801",
      "Location": "  - Pristina",
      "Longitude": "42.5728"
  },
  {
      "Airport Code iATA,FAA": "\\N",
      "Airport Name": "Vršac Int'l Airport",
      "Country": "Serbia",
      "GMT Offset": "1",
      "Latitude": "21.3099",
      "Location": "  - Vrsac",
      "Longitude": "45.1469"
  },
  {
      "Airport Code iATA,FAA": "BRX",
      "Airport Name": "Maria Montez Int'l Airport",
      "Country": "Dominican Republic",
      "GMT Offset": "-4",
      "Latitude": "-71.12039947509766",
      "Location": "  - Barahona",
      "Longitude": "18.25149917602539"
  },
  {
      "Airport Code iATA,FAA": "LRM",
      "Airport Name": "Casa De Campo Int'l Airport",
      "Country": "Dominican Republic",
      "GMT Offset": "-4",
      "Latitude": "-68.91179656982422",
      "Location": "  - La Romana",
      "Longitude": "18.450700759887695"
  },
  {
      "Airport Code iATA,FAA": "PUJ",
      "Airport Name": "Punta Cana Int'l Airport",
      "Country": "Dominican Republic",
      "GMT Offset": "-4",
      "Latitude": "-68.36340332030001",
      "Location": "  - Punta Cana",
      "Longitude": "18.567399978599997"
  },
  {
      "Airport Code iATA,FAA": "POP",
      "Airport Name": "Gregorio Luperon Int'l Airport",
      "Country": "Dominican Republic",
      "GMT Offset": "-4",
      "Latitude": "-70.56999969482422",
      "Location": "  - Puerto Plata",
      "Longitude": "19.75790023803711"
  },
  {
      "Airport Code iATA,FAA": "SDQ",
      "Airport Name": "Las Américas Int'l Airport",
      "Country": "Dominican Republic",
      "GMT Offset": "-4",
      "Latitude": "-69.668899536133",
      "Location": "  - Santo Domingo",
      "Longitude": "18.42970085144"
  },
  {
      "Airport Code iATA,FAA": "STI",
      "Airport Name": "Cibao Int'l Airport",
      "Country": "Dominican Republic",
      "GMT Offset": "-4",
      "Latitude": "-70.60469818115234",
      "Location": "  - Santiago",
      "Longitude": "19.406099319458008"
  },
  {
      "Airport Code iATA,FAA": "LCE",
      "Airport Name": "Goloson Int'l Airport",
      "Country": "Honduras",
      "GMT Offset": "-6",
      "Latitude": "-86.852997",
      "Location": "  - La Ceiba",
      "Longitude": "15.7425"
  },
  {
      "Airport Code iATA,FAA": "SAP",
      "Airport Name": "Ramón Villeda Morales Int'l Airport",
      "Country": "Honduras",
      "GMT Offset": "-6",
      "Latitude": "-87.923599",
      "Location": "  - San Pedro Sula",
      "Longitude": "15.4526"
  },
  {
      "Airport Code iATA,FAA": "RTB",
      "Airport Name": "Juan Manuel Galvez Int'l Airport",
      "Country": "Honduras",
      "GMT Offset": "-6",
      "Latitude": "-86.523003",
      "Location": "  - Roatan",
      "Longitude": "16.316799"
  },
  {
      "Airport Code iATA,FAA": "TGU",
      "Airport Name": "Toncontín Int'l Airport",
      "Country": "Honduras",
      "GMT Offset": "-6",
      "Latitude": "-87.21720123291016",
      "Location": "  - Tegucigalpa",
      "Longitude": "14.06089973449707"
  },
  {
      "Airport Code iATA,FAA": "KIN",
      "Airport Name": "Norman Manley Int'l Airport",
      "Country": "Jamaica",
      "GMT Offset": "-5",
      "Latitude": "-76.7874984741211",
      "Location": "  - Kingston",
      "Longitude": "17.935699462890625"
  },
  {
      "Airport Code iATA,FAA": "MBJ",
      "Airport Name": "Sangster Int'l Airport",
      "Country": "Jamaica",
      "GMT Offset": "-5",
      "Latitude": "-77.91339874267578",
      "Location": "  - Montego Bay",
      "Longitude": "18.503700256347656"
  },
  {
      "Airport Code iATA,FAA": "ACA",
      "Airport Name": "General Juan N Alvarez Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-99.75399780273438",
      "Location": "  - Acapulco",
      "Longitude": "16.757099151611328"
  },
  {
      "Airport Code iATA,FAA": "NTR",
      "Airport Name": "Del Norte Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-100.237",
      "Location": "  - Monterrey",
      "Longitude": "25.865601"
  },
  {
      "Airport Code iATA,FAA": "AGU",
      "Airport Name": "Jesús Terán Paredo Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-102.318001",
      "Location": "  - Aguascalientes",
      "Longitude": "21.705601"
  },
  {
      "Airport Code iATA,FAA": "HUX",
      "Airport Name": "Bahías de Huatulco Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-96.262604",
      "Location": "  - Huatulco",
      "Longitude": "15.7753"
  },
  {
      "Airport Code iATA,FAA": "ACN",
      "Airport Name": "Ciudad Acuña New Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-101.098998",
      "Location": "  - Ciudad Acuna",
      "Longitude": "29.332899"
  },
  {
      "Airport Code iATA,FAA": "CME",
      "Airport Name": "Ciudad del Carmen Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-91.79900360107422",
      "Location": "  - Ciudad Del Carmen",
      "Longitude": "18.65369987487793"
  },
  {
      "Airport Code iATA,FAA": "CUL",
      "Airport Name": "Bachigualato Federal Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-7",
      "Latitude": "-107.474998474",
      "Location": "  - Culiacan",
      "Longitude": "24.7644996643"
  },
  {
      "Airport Code iATA,FAA": "CTM",
      "Airport Name": "Chetumal Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-5",
      "Latitude": "-88.32679748535156",
      "Location": "  - Chetumal",
      "Longitude": "18.50469970703125"
  },
  {
      "Airport Code iATA,FAA": "CEN",
      "Airport Name": "Ciudad Obregón Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-7",
      "Latitude": "-109.83300018310547",
      "Location": "  - Ciudad Obregon",
      "Longitude": "27.39259910583496"
  },
  {
      "Airport Code iATA,FAA": "CPE",
      "Airport Name": "Ingeniero Alberto Acuña Ongay Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-90.5002975464",
      "Location": "  - Campeche",
      "Longitude": "19.816799163800003"
  },
  {
      "Airport Code iATA,FAA": "CJS",
      "Airport Name": "Abraham González Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-7",
      "Latitude": "-106.42900085449219",
      "Location": "  - Ciudad Juarez",
      "Longitude": "31.63610076904297"
  },
  {
      "Airport Code iATA,FAA": "CUU",
      "Airport Name": "General Roberto Fierro Villalobos Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-7",
      "Latitude": "-105.964996338",
      "Location": "  - Chihuahua",
      "Longitude": "28.702899932900003"
  },
  {
      "Airport Code iATA,FAA": "CVM",
      "Airport Name": "General Pedro Jose Mendez Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-98.9564971924",
      "Location": "  - Ciudad Victoria",
      "Longitude": "23.7033004761"
  },
  {
      "Airport Code iATA,FAA": "CZM",
      "Airport Name": "Cozumel Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-5",
      "Latitude": "-86.92559814453125",
      "Location": "  - Cozumel",
      "Longitude": "20.52239990234375"
  },
  {
      "Airport Code iATA,FAA": "DGO",
      "Airport Name": "General Guadalupe Victoria Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-104.527999878",
      "Location": "  - Durango",
      "Longitude": "24.1242008209"
  },
  {
      "Airport Code iATA,FAA": "ESE",
      "Airport Name": "Ensenada Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-8",
      "Latitude": "-116.602997",
      "Location": "  - Ensenada",
      "Longitude": "31.7953"
  },
  {
      "Airport Code iATA,FAA": "GDL",
      "Airport Name": "Don Miguel Hidalgo Y Costilla Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-103.31099700927734",
      "Location": "  - Guadalajara",
      "Longitude": "20.521799087524414"
  },
  {
      "Airport Code iATA,FAA": "GYM",
      "Airport Name": "General José María Yáñez Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-7",
      "Latitude": "-110.92500305175781",
      "Location": "  - Guaymas",
      "Longitude": "27.9689998626709"
  },
  {
      "Airport Code iATA,FAA": "HMO",
      "Airport Name": "General Ignacio P. Garcia Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-7",
      "Latitude": "-111.047996521",
      "Location": "  - Hermosillo",
      "Longitude": "29.095899581900003"
  },
  {
      "Airport Code iATA,FAA": "SLW",
      "Airport Name": "Plan De Guadalupe Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-100.92900085449219",
      "Location": "  - Saltillo",
      "Longitude": "25.54949951171875"
  },
  {
      "Airport Code iATA,FAA": "LMM",
      "Airport Name": "Valle del Fuerte Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-7",
      "Latitude": "-109.081001282",
      "Location": "  - Los Mochis",
      "Longitude": "25.6851997375"
  },
  {
      "Airport Code iATA,FAA": "BJX",
      "Airport Name": "Del Bajío Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-101.481003",
      "Location": "  - Del Bajio",
      "Longitude": "20.9935"
  },
  {
      "Airport Code iATA,FAA": "LAP",
      "Airport Name": "Manuel Márquez de León Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-7",
      "Latitude": "-110.361999512",
      "Location": "  - La Paz",
      "Longitude": "24.072700500499998"
  },
  {
      "Airport Code iATA,FAA": "LTO",
      "Airport Name": "Loreto Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-7",
      "Latitude": "-111.3479995727539",
      "Location": "  - Loreto",
      "Longitude": "25.989200592041016"
  },
  {
      "Airport Code iATA,FAA": "MAM",
      "Airport Name": "General Servando Canales Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-97.5252990723",
      "Location": "  - Matamoros",
      "Longitude": "25.7698993683"
  },
  {
      "Airport Code iATA,FAA": "MXL",
      "Airport Name": "General Rodolfo Sánchez Taboada Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-8",
      "Latitude": "-115.241997",
      "Location": "  - Mexicali",
      "Longitude": "32.6306"
  },
  {
      "Airport Code iATA,FAA": "MLM",
      "Airport Name": "General Francisco J. Mujica Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-101.025001526",
      "Location": "  - Morelia",
      "Longitude": "19.849899292"
  },
  {
      "Airport Code iATA,FAA": "LOV",
      "Airport Name": "Monclova Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-101.470001",
      "Location": "  - Monclova",
      "Longitude": "26.9557"
  },
  {
      "Airport Code iATA,FAA": "MEX",
      "Airport Name": "Licenciado Benito Juarez Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-99.072098",
      "Location": "  - Mexico City",
      "Longitude": "19.4363"
  },
  {
      "Airport Code iATA,FAA": "MTY",
      "Airport Name": "General Mariano Escobedo Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-100.107002258",
      "Location": "  - Monterrey",
      "Longitude": "25.7784996033"
  },
  {
      "Airport Code iATA,FAA": "MZT",
      "Airport Name": "General Rafael Buelna Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-7",
      "Latitude": "-106.26599884",
      "Location": "  - Mazatlan",
      "Longitude": "23.1613998413"
  },
  {
      "Airport Code iATA,FAA": "NOG",
      "Airport Name": "Nogales Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-7",
      "Latitude": "-110.97599792480469",
      "Location": "  - Nogales",
      "Longitude": "31.22610092163086"
  },
  {
      "Airport Code iATA,FAA": "NLD",
      "Airport Name": "Quetzalcóatl Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-99.5705032349",
      "Location": "  - Nuevo Laredo",
      "Longitude": "27.4438991547"
  },
  {
      "Airport Code iATA,FAA": "OAX",
      "Airport Name": "Xoxocotlán Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-96.726600647",
      "Location": "  - Oaxaca",
      "Longitude": "16.9999008179"
  },
  {
      "Airport Code iATA,FAA": "PBC",
      "Airport Name": "Hermanos Serdán Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-98.3713989258",
      "Location": "  - Puebla",
      "Longitude": "19.1581001282"
  },
  {
      "Airport Code iATA,FAA": "PPE",
      "Airport Name": "Mar de Cortés Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-7",
      "Latitude": "-113.305177",
      "Location": "  - Punta Penasco",
      "Longitude": "31.351987"
  },
  {
      "Airport Code iATA,FAA": "PDS",
      "Airport Name": "Piedras Negras Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-100.535004",
      "Location": "  - Piedras Negras",
      "Longitude": "28.627399"
  },
  {
      "Airport Code iATA,FAA": "PVR",
      "Airport Name": "Licenciado Gustavo Díaz Ordaz Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-105.25399780273438",
      "Location": "  - Puerto Vallarta",
      "Longitude": "20.680099487304688"
  },
  {
      "Airport Code iATA,FAA": "PXM",
      "Airport Name": "Puerto Escondido Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-97.089103",
      "Location": "  - Puerto Escondido",
      "Longitude": "15.8769"
  },
  {
      "Airport Code iATA,FAA": "REX",
      "Airport Name": "General Lucio Blanco Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-98.2285",
      "Location": "  - Reynosa",
      "Longitude": "26.0089"
  },
  {
      "Airport Code iATA,FAA": "SJD",
      "Airport Name": "Los Cabos Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-7",
      "Latitude": "-109.72100067138672",
      "Location": "  - San Jose Del Cabo",
      "Longitude": "23.15180015563965"
  },
  {
      "Airport Code iATA,FAA": "SFH",
      "Airport Name": "San Felipe Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-8",
      "Latitude": "-114.80899810791",
      "Location": "  - San Filipe",
      "Longitude": "30.930200576782"
  },
  {
      "Airport Code iATA,FAA": "SLP",
      "Airport Name": "Ponciano Arriaga Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-100.930999756",
      "Location": "  - San Luis Potosi",
      "Longitude": "22.254299163800003"
  },
  {
      "Airport Code iATA,FAA": "TRC",
      "Airport Name": "Francisco Sarabia Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-103.411003113",
      "Location": "  - Torreon",
      "Longitude": "25.568300247199996"
  },
  {
      "Airport Code iATA,FAA": "TGZ",
      "Airport Name": "Angel Albino Corzo Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-93.02249908450001",
      "Location": "  - Tuxtla Gutierrez",
      "Longitude": "16.5636005402"
  },
  {
      "Airport Code iATA,FAA": "TIJ",
      "Airport Name": "General Abelardo L. Rodríguez Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-8",
      "Latitude": "-116.97000122070312",
      "Location": "  - Tijuana",
      "Longitude": "32.541099548339844"
  },
  {
      "Airport Code iATA,FAA": "TAM",
      "Airport Name": "General Francisco Javier Mina Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-97.8658981323",
      "Location": "  - Tampico",
      "Longitude": "22.2964000702"
  },
  {
      "Airport Code iATA,FAA": "TLC",
      "Airport Name": "Licenciado Adolfo Lopez Mateos Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-99.56600189210002",
      "Location": "  - Toluca",
      "Longitude": "19.3370990753"
  },
  {
      "Airport Code iATA,FAA": "TAP",
      "Airport Name": "Tapachula Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-92.3700027466",
      "Location": "  - Tapachula",
      "Longitude": "14.7943000793"
  },
  {
      "Airport Code iATA,FAA": "CUN",
      "Airport Name": "Cancún Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-5",
      "Latitude": "-86.8770980835",
      "Location": "  - Cancun",
      "Longitude": "21.036500930800003"
  },
  {
      "Airport Code iATA,FAA": "VSA",
      "Airport Name": "Carlos Rovirosa Pérez Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-92.81739807128906",
      "Location": "  - Villahermosa",
      "Longitude": "17.996999740600586"
  },
  {
      "Airport Code iATA,FAA": "VER",
      "Airport Name": "General Heriberto Jara Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-96.1873016357",
      "Location": "  - Vera Cruz",
      "Longitude": "19.1459007263"
  },
  {
      "Airport Code iATA,FAA": "ZCL",
      "Airport Name": "General Leobardo C. Ruiz Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-102.68699646",
      "Location": "  - Zacatecas",
      "Longitude": "22.8971004486"
  },
  {
      "Airport Code iATA,FAA": "ZIH",
      "Airport Name": "Ixtapa Zihuatanejo Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-101.460998535",
      "Location": "  - Zihuatanejo",
      "Longitude": "17.601600647"
  },
  {
      "Airport Code iATA,FAA": "ZLO",
      "Airport Name": "Playa De Oro Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-104.558998108",
      "Location": "  - Manzanillo",
      "Longitude": "19.144800186199998"
  },
  {
      "Airport Code iATA,FAA": "MGA",
      "Airport Name": "Augusto C. Sandino (Managua) Int'l Airport",
      "Country": "Nicaragua",
      "GMT Offset": "-6",
      "Latitude": "-86.16819763183594",
      "Location": "  - Managua",
      "Longitude": "12.141500473022461"
  },
  {
      "Airport Code iATA,FAA": "BOC",
      "Airport Name": "Bocas Del Toro Int'l Airport",
      "Country": "Panama",
      "GMT Offset": "-5",
      "Latitude": "-82.25080108642578",
      "Location": "  - Bocas Del Toro",
      "Longitude": "9.340849876403809"
  },
  {
      "Airport Code iATA,FAA": "CHX",
      "Airport Name": "Cap Manuel Niño Int'l Airport",
      "Country": "Panama",
      "GMT Offset": "-5",
      "Latitude": "-82.515062",
      "Location": "  - Changuinola",
      "Longitude": "9.458962"
  },
  {
      "Airport Code iATA,FAA": "DAV",
      "Airport Name": "Enrique Malek Int'l Airport",
      "Country": "Panama",
      "GMT Offset": "-5",
      "Latitude": "-82.43499755859375",
      "Location": "  - David",
      "Longitude": "8.390999794006348"
  },
  {
      "Airport Code iATA,FAA": "BLB",
      "Airport Name": "Panama Pacific Int'l Airport",
      "Country": "Panama",
      "GMT Offset": "-5",
      "Latitude": "-79.599602",
      "Location": "  - Howard",
      "Longitude": "8.91479"
  },
  {
      "Airport Code iATA,FAA": "PAC",
      "Airport Name": "Marcos A. Gelabert Int'l Airport",
      "Country": "Panama",
      "GMT Offset": "-5",
      "Latitude": "-79.55560302734375",
      "Location": "  - Panama",
      "Longitude": "8.973340034484863"
  },
  {
      "Airport Code iATA,FAA": "PTY",
      "Airport Name": "Tocumen Int'l Airport",
      "Country": "Panama",
      "GMT Offset": "-5",
      "Latitude": "-79.3834991455",
      "Location": "  - Panama City",
      "Longitude": "9.0713596344"
  },
  {
      "Airport Code iATA,FAA": "LIR",
      "Airport Name": "Daniel Oduber Quiros Int'l Airport",
      "Country": "Costa Rica",
      "GMT Offset": "-6",
      "Latitude": "-85.544403",
      "Location": "  - Liberia",
      "Longitude": "10.5933"
  },
  {
      "Airport Code iATA,FAA": "LIO",
      "Airport Name": "Limon Int'l Airport",
      "Country": "Costa Rica",
      "GMT Offset": "-6",
      "Latitude": "-83.02200317382812",
      "Location": "  - Limon",
      "Longitude": "9.95796012878418"
  },
  {
      "Airport Code iATA,FAA": "SJO",
      "Airport Name": "Juan Santamaria Int'l Airport",
      "Country": "Costa Rica",
      "GMT Offset": "-6",
      "Latitude": "-84.20880126953125",
      "Location": "  - San Jose",
      "Longitude": "9.993860244750977"
  },
  {
      "Airport Code iATA,FAA": "SAL",
      "Airport Name": "Monseñor Óscar Arnulfo Romero Int'l Airport",
      "Country": "El Salvador",
      "GMT Offset": "-6",
      "Latitude": "-89.055702",
      "Location": "  - San Salvador",
      "Longitude": "13.4409"
  },
  {
      "Airport Code iATA,FAA": "\\N",
      "Airport Name": "Ilopango Int'l Airport",
      "Country": "El Salvador",
      "GMT Offset": "-6",
      "Latitude": "-89.11990356445312",
      "Location": "  - San Salvador",
      "Longitude": "13.69950008392334"
  },
  {
      "Airport Code iATA,FAA": "CAP",
      "Airport Name": "Cap Haitien Int'l Airport",
      "Country": "Haiti",
      "GMT Offset": "-5",
      "Latitude": "-72.194702",
      "Location": "  - Cap Haitien",
      "Longitude": "19.733"
  },
  {
      "Airport Code iATA,FAA": "PAP",
      "Airport Name": "Toussaint Louverture Int'l Airport",
      "Country": "Haiti",
      "GMT Offset": "-5",
      "Latitude": "-72.2925033569336",
      "Location": "  - Port-au-prince",
      "Longitude": "18.579999923706055"
  },
  {
      "Airport Code iATA,FAA": "CYO",
      "Airport Name": "Vilo Acuña Int'l Airport",
      "Country": "Cuba",
      "GMT Offset": "-5",
      "Latitude": "-81.5459976196",
      "Location": "  - Cayo Largo del Sur",
      "Longitude": "21.6165008545"
  },
  {
      "Airport Code iATA,FAA": "CMW",
      "Airport Name": "Ignacio Agramonte Int'l Airport",
      "Country": "Cuba",
      "GMT Offset": "-5",
      "Latitude": "-77.84750366210938",
      "Location": "  - Camaguey",
      "Longitude": "21.420299530029297"
  },
  {
      "Airport Code iATA,FAA": "SCU",
      "Airport Name": "Antonio Maceo Int'l Airport",
      "Country": "Cuba",
      "GMT Offset": "-5",
      "Latitude": "-75.83540344238281",
      "Location": "  - Santiago De Cuba",
      "Longitude": "19.96980094909668"
  },
  {
      "Airport Code iATA,FAA": "HAV",
      "Airport Name": "José Martí Int'l Airport",
      "Country": "Cuba",
      "GMT Offset": "-5",
      "Latitude": "-82.40910339355469",
      "Location": "  - Havana",
      "Longitude": "22.989200592041016"
  },
  {
      "Airport Code iATA,FAA": "HOG",
      "Airport Name": "Frank Pais Int'l Airport",
      "Country": "Cuba",
      "GMT Offset": "-5",
      "Latitude": "-76.31510162353516",
      "Location": "  - Holguin",
      "Longitude": "20.785600662231445"
  },
  {
      "Airport Code iATA,FAA": "VRA",
      "Airport Name": "Juan Gualberto Gomez Int'l Airport",
      "Country": "Cuba",
      "GMT Offset": "-5",
      "Latitude": "-81.435302734375",
      "Location": "  - Varadero",
      "Longitude": "23.034400939941406"
  },
  {
      "Airport Code iATA,FAA": "CYB",
      "Airport Name": "Gerrard Smith Int'l Airport",
      "Country": "Cayman Islands",
      "GMT Offset": "-5",
      "Latitude": "-79.88279724121094",
      "Location": "  - Cayman Brac",
      "Longitude": "19.687000274658203"
  },
  {
      "Airport Code iATA,FAA": "GCM",
      "Airport Name": "Owen Roberts Int'l Airport",
      "Country": "Cayman Islands",
      "GMT Offset": "-5",
      "Latitude": "-81.3576965332",
      "Location": "  - Georgetown",
      "Longitude": "19.292800903299998"
  },
  {
      "Airport Code iATA,FAA": "MHH",
      "Airport Name": "Leonard M Thompson Int'l Airport",
      "Country": "Bahamas",
      "GMT Offset": "-5",
      "Latitude": "-77.083503",
      "Location": "  - Marsh Harbor",
      "Longitude": "26.5114"
  },
  {
      "Airport Code iATA,FAA": "GGT",
      "Airport Name": "Exuma Int'l Airport",
      "Country": "Bahamas",
      "GMT Offset": "-5",
      "Latitude": "-75.8779983521",
      "Location": "  - Great Exuma",
      "Longitude": "23.5625991821"
  },
  {
      "Airport Code iATA,FAA": "FPO",
      "Airport Name": "Grand Bahama Int'l Airport",
      "Country": "Bahamas",
      "GMT Offset": "-5",
      "Latitude": "-78.695602417",
      "Location": "  - Freeport",
      "Longitude": "26.5587005615"
  },
  {
      "Airport Code iATA,FAA": "NAS",
      "Airport Name": "Lynden Pindling Int'l Airport",
      "Country": "Bahamas",
      "GMT Offset": "-5",
      "Latitude": "-77.46620178219999",
      "Location": "  - Nassau",
      "Longitude": "25.0389995575"
  },
  {
      "Airport Code iATA,FAA": "BZE",
      "Airport Name": "Philip S. W. Goldson Int'l Airport",
      "Country": "Belize",
      "GMT Offset": "-6",
      "Latitude": "-88.30819702148438",
      "Location": "  - Belize City",
      "Longitude": "17.539100646972656"
  },
  {
      "Airport Code iATA,FAA": "RAR",
      "Airport Name": "Rarotonga Int'l Airport",
      "Country": "Cook Islands",
      "GMT Offset": "-10",
      "Latitude": "-159.805999756",
      "Location": "  - Avarua",
      "Longitude": "-21.2026996613"
  },
  {
      "Airport Code iATA,FAA": "NAN",
      "Airport Name": "Nadi Int'l Airport",
      "Country": "Fiji",
      "GMT Offset": "12",
      "Latitude": "177.4429931640625",
      "Location": "  - Nandi",
      "Longitude": "-17.755399703979492"
  },
  {
      "Airport Code iATA,FAA": "SUV",
      "Airport Name": "Nausori Int'l Airport",
      "Country": "Fiji",
      "GMT Offset": "12",
      "Latitude": "178.5590057373047",
      "Location": "  - Nausori",
      "Longitude": "-18.04330062866211"
  },
  {
      "Airport Code iATA,FAA": "TBU",
      "Airport Name": "Fua'amotu Int'l Airport",
      "Country": "Tonga",
      "GMT Offset": "13",
      "Latitude": "-175.14999389648438",
      "Location": "  - Tongatapu",
      "Longitude": "-21.241199493408203"
  },
  {
      "Airport Code iATA,FAA": "VAV",
      "Airport Name": "Vava'u Int'l Airport",
      "Country": "Tonga",
      "GMT Offset": "13",
      "Latitude": "-173.96200561523438",
      "Location": "  - Vava'u",
      "Longitude": "-18.58530044555664"
  },
  {
      "Airport Code iATA,FAA": "TRW",
      "Airport Name": "Bonriki Int'l Airport",
      "Country": "Kiribati",
      "GMT Offset": "12",
      "Latitude": "173.14700317382812",
      "Location": "  - Tarawa",
      "Longitude": "1.3816399574279785"
  },
  {
      "Airport Code iATA,FAA": "APW",
      "Airport Name": "Faleolo Int'l Airport",
      "Country": "Samoa",
      "GMT Offset": "13",
      "Latitude": "-172.00799560546875",
      "Location": "  - Faleolo",
      "Longitude": "-13.829999923706055"
  },
  {
      "Airport Code iATA,FAA": "PPG",
      "Airport Name": "Pago Pago Int'l Airport",
      "Country": "American Samoa",
      "GMT Offset": "-11",
      "Latitude": "-170.710006714",
      "Location": "  - Pago Pago",
      "Longitude": "-14.3310003281"
  },
  {
      "Airport Code iATA,FAA": "VLI",
      "Airport Name": "Bauerfield Int'l Airport",
      "Country": "Vanuatu",
      "GMT Offset": "11",
      "Latitude": "168.32000732422",
      "Location": "  - Port-vila",
      "Longitude": "-17.699300765991"
  },
  {
      "Airport Code iATA,FAA": "NOU",
      "Airport Name": "La Tontouta Int'l Airport",
      "Country": "New Caledonia",
      "GMT Offset": "11",
      "Latitude": "166.21299743652344",
      "Location": "  - Noumea",
      "Longitude": "-22.01460075378418"
  },
  {
      "Airport Code iATA,FAA": "AKL",
      "Airport Name": "Auckland Int'l Airport",
      "Country": "New Zealand",
      "GMT Offset": "12",
      "Latitude": "174.792007446",
      "Location": "  - Auckland",
      "Longitude": "-37.008098602299995"
  },
  {
      "Airport Code iATA,FAA": "CHC",
      "Airport Name": "Christchurch Int'l Airport",
      "Country": "New Zealand",
      "GMT Offset": "12",
      "Latitude": "172.53199768066406",
      "Location": "  - Christchurch",
      "Longitude": "-43.48939895629883"
  },
  {
      "Airport Code iATA,FAA": "HLZ",
      "Airport Name": "Hamilton Int'l Airport",
      "Country": "New Zealand",
      "GMT Offset": "12",
      "Latitude": "175.332000732",
      "Location": "  - Hamilton",
      "Longitude": "-37.8666992188"
  },
  {
      "Airport Code iATA,FAA": "ZQN",
      "Airport Name": "Queenstown Int'l Airport",
      "Country": "New Zealand",
      "GMT Offset": "12",
      "Latitude": "168.738998413",
      "Location": "  - Queenstown International",
      "Longitude": "-45.0210990906"
  },
  {
      "Airport Code iATA,FAA": "WLG",
      "Airport Name": "Wellington Int'l Airport",
      "Country": "New Zealand",
      "GMT Offset": "12",
      "Latitude": "174.804992676",
      "Location": "  - Wellington",
      "Longitude": "-41.3272018433"
  },
  {
      "Airport Code iATA,FAA": "KBL",
      "Airport Name": "Hamid Karzai Int'l Airport",
      "Country": "Afghanistan",
      "GMT Offset": "4.5",
      "Latitude": "69.212303",
      "Location": "  - Kabul",
      "Longitude": "34.565899"
  },
  {
      "Airport Code iATA,FAA": "BAH",
      "Airport Name": "Bahrain Int'l Airport",
      "Country": "Bahrain",
      "GMT Offset": "3",
      "Latitude": "50.63359832763672",
      "Location": "  - Bahrain",
      "Longitude": "26.27079963684082"
  },
  {
      "Airport Code iATA,FAA": "DMM",
      "Airport Name": "King Fahd Int'l Airport",
      "Country": "Saudi Arabia",
      "GMT Offset": "3",
      "Latitude": "49.79790115356445",
      "Location": "  - Dammam",
      "Longitude": "26.471200942993164"
  },
  {
      "Airport Code iATA,FAA": "JED",
      "Airport Name": "King Abdulaziz Int'l Airport",
      "Country": "Saudi Arabia",
      "GMT Offset": "3",
      "Latitude": "39.156502",
      "Location": "  - Jeddah",
      "Longitude": "21.6796"
  },
  {
      "Airport Code iATA,FAA": "RUH",
      "Airport Name": "King Khaled Int'l Airport",
      "Country": "Saudi Arabia",
      "GMT Offset": "3",
      "Latitude": "46.69879913330078",
      "Location": "  - Riyadh",
      "Longitude": "24.957599639892578"
  },
  {
      "Airport Code iATA,FAA": "KIH",
      "Airport Name": "Kish Int'l Airport",
      "Country": "Iran",
      "GMT Offset": "3.5",
      "Latitude": "53.980201721200004",
      "Location": "  - Kish Island",
      "Longitude": "26.5261993408"
  },
  {
      "Airport Code iATA,FAA": "IFN",
      "Airport Name": "Esfahan Shahid Beheshti Int'l Airport",
      "Country": "Iran",
      "GMT Offset": "3.5",
      "Latitude": "51.86130142211914",
      "Location": "  - Esfahan",
      "Longitude": "32.75080108642578"
  },
  {
      "Airport Code iATA,FAA": "THR",
      "Airport Name": "Mehrabad Int'l Airport",
      "Country": "Iran",
      "GMT Offset": "3.5",
      "Latitude": "51.31340026855469",
      "Location": "  - Teheran",
      "Longitude": "35.68920135498047"
  },
  {
      "Airport Code iATA,FAA": "BND",
      "Airport Name": "Bandar Abbas Int'l Airport",
      "Country": "Iran",
      "GMT Offset": "3.5",
      "Latitude": "56.37779998779297",
      "Location": "  - Bandar Abbas",
      "Longitude": "27.218299865722656"
  },
  {
      "Airport Code iATA,FAA": "SYZ",
      "Airport Name": "Shiraz Shahid Dastghaib Int'l Airport",
      "Country": "Iran",
      "GMT Offset": "3.5",
      "Latitude": "52.58980178833008",
      "Location": "  - Shiraz",
      "Longitude": "29.539199829101562"
  },
  {
      "Airport Code iATA,FAA": "TBZ",
      "Airport Name": "Tabriz Int'l Airport",
      "Country": "Iran",
      "GMT Offset": "3.5",
      "Latitude": "46.23500061035156",
      "Location": "  - Tabriz",
      "Longitude": "38.1338996887207"
  },
  {
      "Airport Code iATA,FAA": "ZAH",
      "Airport Name": "Zahedan Int'l Airport",
      "Country": "Iran",
      "GMT Offset": "3.5",
      "Latitude": "60.90620040893555",
      "Location": "  - Zahedan",
      "Longitude": "29.47570037841797"
  },
  {
      "Airport Code iATA,FAA": "AMM",
      "Airport Name": "Queen Alia Int'l Airport",
      "Country": "Jordan",
      "GMT Offset": "2",
      "Latitude": "35.9931983948",
      "Location": "  - Amman",
      "Longitude": "31.7226009369"
  },
  {
      "Airport Code iATA,FAA": "ADJ",
      "Airport Name": "Amman-Marka Int'l Airport",
      "Country": "Jordan",
      "GMT Offset": "2",
      "Latitude": "35.991600036621094",
      "Location": "  - Amman",
      "Longitude": "31.972700119018555"
  },
  {
      "Airport Code iATA,FAA": "AQJ",
      "Airport Name": "Aqaba King Hussein Int'l Airport",
      "Country": "Jordan",
      "GMT Offset": "2",
      "Latitude": "35.01810073852539",
      "Location": "  - Aqaba",
      "Longitude": "29.611600875854492"
  },
  {
      "Airport Code iATA,FAA": "KWI",
      "Airport Name": "Kuwait Int'l Airport",
      "Country": "Kuwait",
      "GMT Offset": "3",
      "Latitude": "47.96889877319336",
      "Location": "  - Kuwait",
      "Longitude": "29.226600646972656"
  },
  {
      "Airport Code iATA,FAA": "BEY",
      "Airport Name": "Beirut Rafic Hariri Int'l Airport",
      "Country": "Lebanon",
      "GMT Offset": "2",
      "Latitude": "35.488399505615234",
      "Location": "  - Beirut",
      "Longitude": "33.820899963378906"
  },
  {
      "Airport Code iATA,FAA": "AUH",
      "Airport Name": "Abu Dhabi Int'l Airport",
      "Country": "United Arab Emirates",
      "GMT Offset": "4",
      "Latitude": "54.651100158691406",
      "Location": "  - Abu Dhabi",
      "Longitude": "24.433000564575195"
  },
  {
      "Airport Code iATA,FAA": "DXB",
      "Airport Name": "Dubai Int'l Airport",
      "Country": "United Arab Emirates",
      "GMT Offset": "4",
      "Latitude": "55.3643989563",
      "Location": "  - Dubai",
      "Longitude": "25.2527999878"
  },
  {
      "Airport Code iATA,FAA": "FJR",
      "Airport Name": "Fujairah Int'l Airport",
      "Country": "United Arab Emirates",
      "GMT Offset": "4",
      "Latitude": "56.32400131225586",
      "Location": "  - Fujeirah",
      "Longitude": "25.112199783325195"
  },
  {
      "Airport Code iATA,FAA": "RKT",
      "Airport Name": "Ras Al Khaimah Int'l Airport",
      "Country": "United Arab Emirates",
      "GMT Offset": "4",
      "Latitude": "55.93880081176758",
      "Location": "  - Ras Al Khaimah",
      "Longitude": "25.613500595092773"
  },
  {
      "Airport Code iATA,FAA": "SHJ",
      "Airport Name": "Sharjah Int'l Airport",
      "Country": "United Arab Emirates",
      "GMT Offset": "4",
      "Latitude": "55.5172004699707",
      "Location": "  - Sharjah",
      "Longitude": "25.32859992980957"
  },
  {
      "Airport Code iATA,FAA": "MCT",
      "Airport Name": "Muscat Int'l Airport",
      "Country": "Oman",
      "GMT Offset": "4",
      "Latitude": "58.284400939941406",
      "Location": "  - Muscat",
      "Longitude": "23.593299865722656"
  },
  {
      "Airport Code iATA,FAA": "LYP",
      "Airport Name": "Faisalabad Int'l Airport",
      "Country": "Pakistan",
      "GMT Offset": "5",
      "Latitude": "72.99479675292969",
      "Location": "  - Faisalabad",
      "Longitude": "31.364999771118164"
  },
  {
      "Airport Code iATA,FAA": "GWD",
      "Airport Name": "Gwadar Int'l Airport",
      "Country": "Pakistan",
      "GMT Offset": "5",
      "Latitude": "62.329498291015625",
      "Location": "  - Gwadar",
      "Longitude": "25.233299255371094"
  },
  {
      "Airport Code iATA,FAA": "KHI",
      "Airport Name": "Jinnah Int'l Airport",
      "Country": "Pakistan",
      "GMT Offset": "5",
      "Latitude": "67.160797",
      "Location": "  - Karachi",
      "Longitude": "24.9065"
  },
  {
      "Airport Code iATA,FAA": "LHE",
      "Airport Name": "Alama Iqbal Int'l Airport",
      "Country": "Pakistan",
      "GMT Offset": "5",
      "Latitude": "74.40360260009766",
      "Location": "  - Lahore",
      "Longitude": "31.5216007232666"
  },
  {
      "Airport Code iATA,FAA": "MUX",
      "Airport Name": "Multan Int'l Airport",
      "Country": "Pakistan",
      "GMT Offset": "5",
      "Latitude": "71.41909790039062",
      "Location": "  - Multan",
      "Longitude": "30.20319938659668"
  },
  {
      "Airport Code iATA,FAA": "PEW",
      "Airport Name": "Peshawar Int'l Airport",
      "Country": "Pakistan",
      "GMT Offset": "5",
      "Latitude": "71.51460266113281",
      "Location": "  - Peshawar",
      "Longitude": "33.993900299072266"
  },
  {
      "Airport Code iATA,FAA": "UET",
      "Airport Name": "Quetta Int'l Airport",
      "Country": "Pakistan",
      "GMT Offset": "5",
      "Latitude": "66.93779754638672",
      "Location": "  - Quetta",
      "Longitude": "30.251399993896484"
  },
  {
      "Airport Code iATA,FAA": "\\N",
      "Airport Name": "Benazir Bhutto Int'l Airport",
      "Country": "Pakistan",
      "GMT Offset": "5",
      "Latitude": "73.099197",
      "Location": "  - Islamabad",
      "Longitude": "33.616699"
  },
  {
      "Airport Code iATA,FAA": "BSR",
      "Airport Name": "Basrah Int'l Airport",
      "Country": "Iraq",
      "GMT Offset": "3",
      "Latitude": "47.66210174560547",
      "Location": "  - Basrah",
      "Longitude": "30.549100875854492"
  },
  {
      "Airport Code iATA,FAA": "ALP",
      "Airport Name": "Aleppo Int'l Airport",
      "Country": "Syria",
      "GMT Offset": "2",
      "Latitude": "37.22439956665039",
      "Location": "  - Aleppo",
      "Longitude": "36.18069839477539"
  },
  {
      "Airport Code iATA,FAA": "DAM",
      "Airport Name": "Damascus Int'l Airport",
      "Country": "Syria",
      "GMT Offset": "2",
      "Latitude": "36.51559829711914",
      "Location": "  - Damascus",
      "Longitude": "33.4114990234375"
  },
  {
      "Airport Code iATA,FAA": "LTK",
      "Airport Name": "Bassel Al-Assad Int'l Airport",
      "Country": "Syria",
      "GMT Offset": "2",
      "Latitude": "35.948699951171875",
      "Location": "  - Latakia",
      "Longitude": "35.401100158691406"
  },
  {
      "Airport Code iATA,FAA": "DIA",
      "Airport Name": "Doha Int'l Airport",
      "Country": "Qatar",
      "GMT Offset": "3",
      "Latitude": "51.565102",
      "Location": "  - Doha",
      "Longitude": "25.261101"
  },
  {
      "Airport Code iATA,FAA": "ROP",
      "Airport Name": "Rota Int'l Airport",
      "Country": "Northern Mariana Islands",
      "GMT Offset": "10",
      "Latitude": "145.2429962158203",
      "Location": "  - Rota",
      "Longitude": "14.174300193786621"
  },
  {
      "Airport Code iATA,FAA": "SPN",
      "Airport Name": "Saipan Int'l Airport",
      "Country": "Northern Mariana Islands",
      "GMT Offset": "10",
      "Latitude": "145.729004",
      "Location": "  - Saipan",
      "Longitude": "15.119"
  },
  {
      "Airport Code iATA,FAA": "GUM",
      "Airport Name": "Antonio B. Won Pat Int'l Airport",
      "Country": "Guam",
      "GMT Offset": "10",
      "Latitude": "144.796005249",
      "Location": "  - Agana",
      "Longitude": "13.4834003448"
  },
  {
      "Airport Code iATA,FAA": "TIQ",
      "Airport Name": "Tinian Int'l Airport",
      "Country": "Northern Mariana Islands",
      "GMT Offset": "10",
      "Latitude": "145.61900329589844",
      "Location": "  - West Tinian",
      "Longitude": "14.999199867248535"
  },
  {
      "Airport Code iATA,FAA": "MAJ",
      "Airport Name": "Marshall Islands Int'l Airport",
      "Country": "Marshall Islands",
      "GMT Offset": "12",
      "Latitude": "171.27200317382812",
      "Location": "  - Majuro",
      "Longitude": "7.064760208129883"
  },
  {
      "Airport Code iATA,FAA": "CXI",
      "Airport Name": "Cassidy Int'l Airport",
      "Country": "Kiribati",
      "GMT Offset": "-12",
      "Latitude": "-157.35000610351562",
      "Location": "  - Kiritimati",
      "Longitude": "1.9861600399017334"
  },
  {
      "Airport Code iATA,FAA": "TKK",
      "Airport Name": "Chuuk Int'l Airport",
      "Country": "Micronesia",
      "GMT Offset": "10",
      "Latitude": "151.84300231933594",
      "Location": "  - Chuuk",
      "Longitude": "7.461870193481445"
  },
  {
      "Airport Code iATA,FAA": "PNI",
      "Airport Name": "Pohnpei Int'l Airport",
      "Country": "Micronesia",
      "GMT Offset": "11",
      "Latitude": "158.20899963378906",
      "Location": "  - Pohnpei",
      "Longitude": "6.985099792480469"
  },
  {
      "Airport Code iATA,FAA": "KSA",
      "Airport Name": "Kosrae Int'l Airport",
      "Country": "Micronesia",
      "GMT Offset": "11",
      "Latitude": "162.957993",
      "Location": "  - Kosrae",
      "Longitude": "5.35698"
  },
  {
      "Airport Code iATA,FAA": "YAP",
      "Airport Name": "Yap Int'l Airport",
      "Country": "Micronesia",
      "GMT Offset": "10",
      "Latitude": "138.082993",
      "Location": "  - Yap",
      "Longitude": "9.49891"
  },
  {
      "Airport Code iATA,FAA": "KHH",
      "Airport Name": "Kaohsiung Int'l Airport",
      "Country": "Taiwan",
      "GMT Offset": "8",
      "Latitude": "120.3499984741211",
      "Location": "  - Kaohsiung",
      "Longitude": "22.57710075378418"
  },
  {
      "Airport Code iATA,FAA": "TPE",
      "Airport Name": "Taiwan Taoyuan Int'l Airport",
      "Country": "Taiwan",
      "GMT Offset": "8",
      "Latitude": "121.233002",
      "Location": "  - Taipei",
      "Longitude": "25.0777"
  },
  {
      "Airport Code iATA,FAA": "NRT",
      "Airport Name": "Narita Int'l Airport",
      "Country": "Japan",
      "GMT Offset": "9",
      "Latitude": "140.386001587",
      "Location": "  - Tokyo",
      "Longitude": "35.7647018433"
  },
  {
      "Airport Code iATA,FAA": "ITM",
      "Airport Name": "Osaka Int'l Airport",
      "Country": "Japan",
      "GMT Offset": "9",
      "Latitude": "135.43800354003906",
      "Location": "  - Osaka",
      "Longitude": "34.785499572753906"
  },
  {
      "Airport Code iATA,FAA": "HND",
      "Airport Name": "Tokyo Haneda Int'l Airport",
      "Country": "Japan",
      "GMT Offset": "9",
      "Latitude": "139.779999",
      "Location": "  - Tokyo",
      "Longitude": "35.552299"
  },
  {
      "Airport Code iATA,FAA": "CJU",
      "Airport Name": "Jeju Int'l Airport",
      "Country": "South Korea",
      "GMT Offset": "9",
      "Latitude": "126.49299621582031",
      "Location": "  - Cheju",
      "Longitude": "33.51129913330078"
  },
  {
      "Airport Code iATA,FAA": "PUS",
      "Airport Name": "Gimhae Int'l Airport",
      "Country": "South Korea",
      "GMT Offset": "9",
      "Latitude": "128.93800354",
      "Location": "  - Busan",
      "Longitude": "35.1795005798"
  },
  {
      "Airport Code iATA,FAA": "GMP",
      "Airport Name": "Gimpo Int'l Airport",
      "Country": "South Korea",
      "GMT Offset": "9",
      "Latitude": "126.791",
      "Location": "  - Seoul",
      "Longitude": "37.5583"
  },
  {
      "Airport Code iATA,FAA": "MNL",
      "Airport Name": "Ninoy Aquino Int'l Airport",
      "Country": "Philippines",
      "GMT Offset": "8",
      "Latitude": "121.019997",
      "Location": "  - Manila",
      "Longitude": "14.5086"
  },
  {
      "Airport Code iATA,FAA": "GES",
      "Airport Name": "General Santos Int'l Airport",
      "Country": "Philippines",
      "GMT Offset": "8",
      "Latitude": "125.096000671",
      "Location": "  - Romblon",
      "Longitude": "6.05800008774"
  },
  {
      "Airport Code iATA,FAA": "ZAM",
      "Airport Name": "Zamboanga Int'l Airport",
      "Country": "Philippines",
      "GMT Offset": "8",
      "Latitude": "122.05999755859375",
      "Location": "  - Zamboanga",
      "Longitude": "6.922420024871826"
  },
  {
      "Airport Code iATA,FAA": "ILO",
      "Airport Name": "Iloilo Int'l Airport",
      "Country": "Philippines",
      "GMT Offset": "8",
      "Latitude": "122.493358",
      "Location": "  - Iloilo",
      "Longitude": "10.833017"
  },
  {
      "Airport Code iATA,FAA": "KLO",
      "Airport Name": "Kalibo Int'l Airport",
      "Country": "Philippines",
      "GMT Offset": "8",
      "Latitude": "122.375999451",
      "Location": "  - Kalibo",
      "Longitude": "11.679400444"
  },
  {
      "Airport Code iATA,FAA": "RES",
      "Airport Name": "Resistencia Int'l Airport",
      "Country": "Argentina",
      "GMT Offset": "-3",
      "Latitude": "-59.0561",
      "Location": "  - Resistencia",
      "Longitude": "-27.45"
  },
  {
      "Airport Code iATA,FAA": "IGR",
      "Airport Name": "Cataratas Del Iguazú Int'l Airport",
      "Country": "Argentina",
      "GMT Offset": "-3",
      "Latitude": "-54.4734",
      "Location": "  - Iguazu Falls",
      "Longitude": "-25.737301"
  },
  {
      "Airport Code iATA,FAA": "SLA",
      "Airport Name": "Martin Miguel De Guemes Int'l Airport",
      "Country": "Argentina",
      "GMT Offset": "-3",
      "Latitude": "-65.4861984253",
      "Location": "  - Salta",
      "Longitude": "-24.856000900299996"
  },
  {
      "Airport Code iATA,FAA": "JUJ",
      "Airport Name": "Gobernador Horacio Guzman Int'l Airport",
      "Country": "Argentina",
      "GMT Offset": "-3",
      "Latitude": "-65.097801",
      "Location": "  - Jujuy",
      "Longitude": "-24.392799"
  },
  {
      "Airport Code iATA,FAA": "RGA",
      "Airport Name": "Hermes Quijada Int'l Airport",
      "Country": "Argentina",
      "GMT Offset": "-3",
      "Latitude": "-67.7494",
      "Location": "  - Rio Grande",
      "Longitude": "-53.7777"
  },
  {
      "Airport Code iATA,FAA": "MDQ",
      "Airport Name": "Ástor Piazzola Int'l Airport",
      "Country": "Argentina",
      "GMT Offset": "-3",
      "Latitude": "-57.5733",
      "Location": "  - Mar Del Plata",
      "Longitude": "-37.9342"
  },
  {
      "Airport Code iATA,FAA": "BEL",
      "Airport Name": "Val de Cans/Júlio Cezar Ribeiro Int'l Airport",
      "Country": "Brazil",
      "GMT Offset": "-3",
      "Latitude": "-48.4762992859",
      "Location": "  - Belem",
      "Longitude": "-1.3792500495900002"
  },
  {
      "Airport Code iATA,FAA": "BSB",
      "Airport Name": "Presidente Juscelino Kubistschek Int'l Airport",
      "Country": "Brazil",
      "GMT Offset": "-3",
      "Latitude": "-47.920833587646484",
      "Location": "  - Brasilia",
      "Longitude": "-15.86916732788086"
  },
  {
      "Airport Code iATA,FAA": "CNF",
      "Airport Name": "Tancredo Neves Int'l Airport",
      "Country": "Brazil",
      "GMT Offset": "-3",
      "Latitude": "-43.97194290161133",
      "Location": "  - Belo Horizonte",
      "Longitude": "-19.62444305419922"
  },
  {
      "Airport Code iATA,FAA": "CMG",
      "Airport Name": "Corumbá Int'l Airport",
      "Country": "Brazil",
      "GMT Offset": "-4",
      "Latitude": "-57.6713905334",
      "Location": "  - Corumba",
      "Longitude": "-19.0119438171"
  },
  {
      "Airport Code iATA,FAA": "MAO",
      "Airport Name": "Eduardo Gomes Int'l Airport",
      "Country": "Brazil",
      "GMT Offset": "-4",
      "Latitude": "-60.04970169067383",
      "Location": "  - Manaus",
      "Longitude": "-3.0386099815368652"
  },
  {
      "Airport Code iATA,FAA": "IGU",
      "Airport Name": "Cataratas Int'l Airport",
      "Country": "Brazil",
      "GMT Offset": "-3",
      "Latitude": "-54.48500061035156",
      "Location": "  - Foz Do Iguacu",
      "Longitude": "-25.600278854370117"
  },
  {
      "Airport Code iATA,FAA": "FLN",
      "Airport Name": "Hercílio Luz Int'l Airport",
      "Country": "Brazil",
      "GMT Offset": "-3",
      "Latitude": "-48.5525016784668",
      "Location": "  - Florianopolis",
      "Longitude": "-27.670278549194336"
  },
  {
      "Airport Code iATA,FAA": "FOR",
      "Airport Name": "Pinto Martins Int'l Airport",
      "Country": "Brazil",
      "GMT Offset": "-3",
      "Latitude": "-38.53260040283203",
      "Location": "  - Fortaleza",
      "Longitude": "-3.776279926300049"
  },
  {
      "Airport Code iATA,FAA": "GIG",
      "Airport Name": "Rio Galeão – Tom Jobim Int'l Airport",
      "Country": "Brazil",
      "GMT Offset": "-3",
      "Latitude": "-43.2505569458",
      "Location": "  - Rio De Janeiro",
      "Longitude": "-22.8099994659"
  },
  {
      "Airport Code iATA,FAA": "GRU",
      "Airport Name": "Guarulhos - Governador André Franco Montoro Int'l Airport",
      "Country": "Brazil",
      "GMT Offset": "-3",
      "Latitude": "-46.47305679321289",
      "Location": "  - Sao Paulo",
      "Longitude": "-23.435556411743164"
  },
  {
      "Airport Code iATA,FAA": "JPA",
      "Airport Name": "Presidente Castro Pinto Int'l Airport",
      "Country": "Brazil",
      "GMT Offset": "-3",
      "Latitude": "-34.9486122131",
      "Location": "  - Joao Pessoa",
      "Longitude": "-7.145833015440001"
  },
  {
      "Airport Code iATA,FAA": "VCP",
      "Airport Name": "Viracopos Int'l Airport",
      "Country": "Brazil",
      "GMT Offset": "-3",
      "Latitude": "-47.1344985962",
      "Location": "  - Campinas",
      "Longitude": "-23.0074005127"
  },
  {
      "Airport Code iATA,FAA": "NVT",
      "Airport Name": "Ministro Victor Konder Int'l Airport",
      "Country": "Brazil",
      "GMT Offset": "-3",
      "Latitude": "-48.651402",
      "Location": "  - Navegantes",
      "Longitude": "-26.879999"
  },
  {
      "Airport Code iATA,FAA": "NAT",
      "Airport Name": "Governador Aluízio Alves Int'l Airport",
      "Country": "Brazil",
      "GMT Offset": "-3",
      "Latitude": "-35.376111",
      "Location": "  - Natal",
      "Longitude": "-5.768056"
  },
  {
      "Airport Code iATA,FAA": "PET",
      "Airport Name": "João Simões Lopes Neto Int'l Airport",
      "Country": "Brazil",
      "GMT Offset": "-3",
      "Latitude": "-52.327702",
      "Location": "  - Pelotas",
      "Longitude": "-31.718399"
  },
  {
      "Airport Code iATA,FAA": "REC",
      "Airport Name": "Guararapes - Gilberto Freyre Int'l Airport",
      "Country": "Brazil",
      "GMT Offset": "-3",
      "Latitude": "-34.92359924316406",
      "Location": "  - Recife",
      "Longitude": "-8.126489639282227"
  },
  {
      "Airport Code iATA,FAA": "SLZ",
      "Airport Name": "Marechal Cunha Machado Int'l Airport",
      "Country": "Brazil",
      "GMT Offset": "-3",
      "Latitude": "-44.234100341796875",
      "Location": "  - Sao Luis",
      "Longitude": "-2.585360050201416"
  },
  {
      "Airport Code iATA,FAA": "SSA",
      "Airport Name": "Deputado Luiz Eduardo Magalhães Int'l Airport",
      "Country": "Brazil",
      "GMT Offset": "-3",
      "Latitude": "-38.3224983215",
      "Location": "  - Salvador",
      "Longitude": "-12.9086112976"
  },
  {
      "Airport Code iATA,FAA": "SCL",
      "Airport Name": "Comodoro Arturo Merino Benítez Int'l Airport",
      "Country": "Chile",
      "GMT Offset": "-4",
      "Latitude": "-70.78579711914062",
      "Location": "  - Santiago",
      "Longitude": "-33.393001556396484"
  },
  {
      "Airport Code iATA,FAA": "ANF",
      "Airport Name": "Andrés Sabella Gálvez Int'l Airport",
      "Country": "Chile",
      "GMT Offset": "-4",
      "Latitude": "-70.445099",
      "Location": "  - Antofagasta",
      "Longitude": "-23.444501"
  },
  {
      "Airport Code iATA,FAA": "GYE",
      "Airport Name": "José Joaquín de Olmedo Int'l Airport",
      "Country": "Ecuador",
      "GMT Offset": "-5",
      "Latitude": "-79.88359832760001",
      "Location": "  - Guayaquil",
      "Longitude": "-2.1574199199699997"
  },
  {
      "Airport Code iATA,FAA": "LTX",
      "Airport Name": "Cotopaxi Int'l Airport",
      "Country": "Ecuador",
      "GMT Offset": "-5",
      "Latitude": "-78.615799",
      "Location": "  - Latacunga",
      "Longitude": "-0.906833"
  },
  {
      "Airport Code iATA,FAA": "MEC",
      "Airport Name": "Eloy Alfaro Int'l Airport",
      "Country": "Ecuador",
      "GMT Offset": "-5",
      "Latitude": "-80.67880249023438",
      "Location": "  - Manta",
      "Longitude": "-0.9460780024528503"
  },
  {
      "Airport Code iATA,FAA": "UIO",
      "Airport Name": "Mariscal Sucre Int'l Airport",
      "Country": "Ecuador",
      "GMT Offset": "-5",
      "Latitude": "-78.3575",
      "Location": "  - Quito",
      "Longitude": "-0.129166666667"
  },
  {
      "Airport Code iATA,FAA": "ETR",
      "Airport Name": "Santa Rosa Int'l Airport",
      "Country": "Ecuador",
      "GMT Offset": "-5",
      "Latitude": "-79.996957",
      "Location": "  - Santa Rosa",
      "Longitude": "-3.441986"
  },
  {
      "Airport Code iATA,FAA": "ASU",
      "Airport Name": "Silvio Pettirossi Int'l Airport",
      "Country": "Paraguay",
      "GMT Offset": "-4",
      "Latitude": "-57.52000045776367",
      "Location": "  - Asuncion",
      "Longitude": "-25.239999771118164"
  },
  {
      "Airport Code iATA,FAA": "ESG",
      "Airport Name": "Dr. Luis Maria Argaña Int'l Airport",
      "Country": "Paraguay",
      "GMT Offset": "-4",
      "Latitude": "-60.619998931884766",
      "Location": "  - Mariscal Estigarribia",
      "Longitude": "-22.049999237060547"
  },
  {
      "Airport Code iATA,FAA": "BOG",
      "Airport Name": "El Dorado Int'l Airport",
      "Country": "Colombia",
      "GMT Offset": "-5",
      "Latitude": "-74.1469",
      "Location": "  - Bogota",
      "Longitude": "4.70159"
  },
  {
      "Airport Code iATA,FAA": "BAQ",
      "Airport Name": "Ernesto Cortissoz Int'l Airport",
      "Country": "Colombia",
      "GMT Offset": "-5",
      "Latitude": "-74.7808",
      "Location": "  - Barranquilla",
      "Longitude": "10.8896"
  },
  {
      "Airport Code iATA,FAA": "CUC",
      "Airport Name": "Camilo Daza Int'l Airport",
      "Country": "Colombia",
      "GMT Offset": "-5",
      "Latitude": "-72.5115",
      "Location": "  - Cucuta",
      "Longitude": "7.92757"
  },
  {
      "Airport Code iATA,FAA": "CTG",
      "Airport Name": "Rafael Nuñez Int'l Airport",
      "Country": "Colombia",
      "GMT Offset": "-5",
      "Latitude": "-75.513",
      "Location": "  - Cartagena",
      "Longitude": "10.4424"
  },
  {
      "Airport Code iATA,FAA": "CLO",
      "Airport Name": "Alfonso Bonilla Aragon Int'l Airport",
      "Country": "Colombia",
      "GMT Offset": "-5",
      "Latitude": "-76.3816",
      "Location": "  - Cali",
      "Longitude": "3.54322"
  },
  {
      "Airport Code iATA,FAA": "LET",
      "Airport Name": "Alfredo Vásquez Cobo Int'l Airport",
      "Country": "Colombia",
      "GMT Offset": "-5",
      "Latitude": "-69.9432",
      "Location": "  - Leticia",
      "Longitude": "-4.19355"
  },
  {
      "Airport Code iATA,FAA": "PEI",
      "Airport Name": "Matecaña Int'l Airport",
      "Country": "Colombia",
      "GMT Offset": "-5",
      "Latitude": "-75.7395",
      "Location": "  - Pereira",
      "Longitude": "4.81267"
  },
  {
      "Airport Code iATA,FAA": "MDE",
      "Airport Name": "Jose Maria Córdova Int'l Airport",
      "Country": "Colombia",
      "GMT Offset": "-5",
      "Latitude": "-75.4231",
      "Location": "  - Rio Negro",
      "Longitude": "6.16454"
  },
  {
      "Airport Code iATA,FAA": "SMR",
      "Airport Name": "Simón Bolívar Int'l Airport",
      "Country": "Colombia",
      "GMT Offset": "-5",
      "Latitude": "-74.2306",
      "Location": "  - Santa Marta",
      "Longitude": "11.1196"
  },
  {
      "Airport Code iATA,FAA": "ADZ",
      "Airport Name": "Gustavo Rojas Pinilla Int'l Airport",
      "Country": "Colombia",
      "GMT Offset": "-5",
      "Latitude": "-81.7112",
      "Location": "  - San Andres Island",
      "Longitude": "12.5836"
  },
  {
      "Airport Code iATA,FAA": "CBB",
      "Airport Name": "Jorge Wilsterman Int'l Airport",
      "Country": "Bolivia",
      "GMT Offset": "-4",
      "Latitude": "-66.1771011352539",
      "Location": "  - Cochabamba",
      "Longitude": "-17.421100616455078"
  },
  {
      "Airport Code iATA,FAA": "LPB",
      "Airport Name": "El Alto Int'l Airport",
      "Country": "Bolivia",
      "GMT Offset": "-4",
      "Latitude": "-68.19229888916016",
      "Location": "  - La Paz",
      "Longitude": "-16.5132999420166"
  },
  {
      "Airport Code iATA,FAA": "VVI",
      "Airport Name": "Viru Viru Int'l Airport",
      "Country": "Bolivia",
      "GMT Offset": "-4",
      "Latitude": "-63.135399",
      "Location": "  - Santa Cruz",
      "Longitude": "-17.6448"
  },
  {
      "Airport Code iATA,FAA": "PBM",
      "Airport Name": "Johan Adolf Pengel Int'l Airport",
      "Country": "Suriname",
      "GMT Offset": "-3",
      "Latitude": "-55.1878013611",
      "Location": "  - Zandery",
      "Longitude": "5.4528298377999995"
  },
  {
      "Airport Code iATA,FAA": "PCL",
      "Airport Name": "Cap FAP David Abenzur Rengifo Int'l Airport",
      "Country": "Peru",
      "GMT Offset": "-5",
      "Latitude": "-74.57430267333984",
      "Location": "  - Pucallpa",
      "Longitude": "-8.37794017791748"
  },
  {
      "Airport Code iATA,FAA": "CIX",
      "Airport Name": "Capitan FAP Jose A Quinones Gonzales Int'l Airport",
      "Country": "Peru",
      "GMT Offset": "-5",
      "Latitude": "-79.8281021118164",
      "Location": "  - Chiclayo",
      "Longitude": "-6.787479877471924"
  },
  {
      "Airport Code iATA,FAA": "LIM",
      "Airport Name": "Jorge Chávez Int'l Airport",
      "Country": "Peru",
      "GMT Offset": "-5",
      "Latitude": "-77.114305",
      "Location": "  - Lima",
      "Longitude": "-12.0219"
  },
  {
      "Airport Code iATA,FAA": "JUL",
      "Airport Name": "Inca Manco Capac Int'l Airport",
      "Country": "Peru",
      "GMT Offset": "-5",
      "Latitude": "-70.158203125",
      "Location": "  - Juliaca",
      "Longitude": "-15.467100143432617"
  },
  {
      "Airport Code iATA,FAA": "IQT",
      "Airport Name": "Coronel FAP Francisco Secada Vignetta Int'l Airport",
      "Country": "Peru",
      "GMT Offset": "-5",
      "Latitude": "-73.30879974365234",
      "Location": "  - Iquitos",
      "Longitude": "-3.7847399711608887"
  },
  {
      "Airport Code iATA,FAA": "AQP",
      "Airport Name": "Rodríguez Ballón Int'l Airport",
      "Country": "Peru",
      "GMT Offset": "-5",
      "Latitude": "-71.5830993652",
      "Location": "  - Arequipa",
      "Longitude": "-16.3411006927"
  },
  {
      "Airport Code iATA,FAA": "TRU",
      "Airport Name": "Capitan FAP Carlos Martinez De Pinillos Int'l Airport",
      "Country": "Peru",
      "GMT Offset": "-5",
      "Latitude": "-79.10880279541016",
      "Location": "  - Trujillo",
      "Longitude": "-8.08141040802002"
  },
  {
      "Airport Code iATA,FAA": "PIO",
      "Airport Name": "Capitán FAP Renán Elías Olivera Int'l Airport",
      "Country": "Peru",
      "GMT Offset": "-5",
      "Latitude": "-76.22029876708984",
      "Location": "  - Pisco",
      "Longitude": "-13.74489974975586"
  },
  {
      "Airport Code iATA,FAA": "TCQ",
      "Airport Name": "Coronel FAP Carlos Ciriani Santa Rosa Int'l Airport",
      "Country": "Peru",
      "GMT Offset": "-5",
      "Latitude": "-70.2758026123",
      "Location": "  - Tacna",
      "Longitude": "-18.053300857500002"
  },
  {
      "Airport Code iATA,FAA": "PEM",
      "Airport Name": "Padre Aldamiz Int'l Airport",
      "Country": "Peru",
      "GMT Offset": "-5",
      "Latitude": "-69.2285995483",
      "Location": "  - Puerto Maldonado",
      "Longitude": "-12.6135997772"
  },
  {
      "Airport Code iATA,FAA": "PIU",
      "Airport Name": "Capitán FAP Guillermo Concha Iberico Int'l Airport",
      "Country": "Peru",
      "GMT Offset": "-5",
      "Latitude": "-80.61640167239999",
      "Location": "  - Piura",
      "Longitude": "-5.20574998856"
  },
  {
      "Airport Code iATA,FAA": "CUZ",
      "Airport Name": "Alejandro Velasco Astete Int'l Airport",
      "Country": "Peru",
      "GMT Offset": "-5",
      "Latitude": "-71.9387969971",
      "Location": "  - Cuzco",
      "Longitude": "-13.535699844400002"
  },
  {
      "Airport Code iATA,FAA": "DZO",
      "Airport Name": "Santa Bernardina Int'l Airport",
      "Country": "Uruguay",
      "GMT Offset": "-3",
      "Latitude": "-56.49919891357422",
      "Location": "  - Durazno",
      "Longitude": "-33.3588981628418"
  },
  {
      "Airport Code iATA,FAA": "MVD",
      "Airport Name": "Carrasco Int'l /General C L Berisso Airport",
      "Country": "Uruguay",
      "GMT Offset": "-3",
      "Latitude": "-56.0308",
      "Location": "  - Montevideo",
      "Longitude": "-34.838402"
  },
  {
      "Airport Code iATA,FAA": "STY",
      "Airport Name": "Nueva Hesperides Int'l Airport",
      "Country": "Uruguay",
      "GMT Offset": "-3",
      "Latitude": "-57.98529815673828",
      "Location": "  - Salto",
      "Longitude": "-31.438499450683594"
  },
  {
      "Airport Code iATA,FAA": "BLA",
      "Airport Name": "General José Antonio Anzoategui Int'l Airport",
      "Country": "Venezuela",
      "GMT Offset": "-4",
      "Latitude": "-64.692222",
      "Location": "  - Barcelona",
      "Longitude": "10.111111"
  },
  {
      "Airport Code iATA,FAA": "BRM",
      "Airport Name": "Barquisimeto Int'l Airport",
      "Country": "Venezuela",
      "GMT Offset": "-4",
      "Latitude": "-69.3586196899414",
      "Location": "  - Barquisimeto",
      "Longitude": "10.042746543884277"
  },
  {
      "Airport Code iATA,FAA": "LSP",
      "Airport Name": "Josefa Camejo Int'l Airport",
      "Country": "Venezuela",
      "GMT Offset": "-4",
      "Latitude": "-70.15149688720703",
      "Location": "  - Paraguana",
      "Longitude": "11.78077507019043"
  },
  {
      "Airport Code iATA,FAA": "MAR",
      "Airport Name": "La Chinita Int'l Airport",
      "Country": "Venezuela",
      "GMT Offset": "-4",
      "Latitude": "-71.7278594971",
      "Location": "  - Maracaibo",
      "Longitude": "10.5582084656"
  },
  {
      "Airport Code iATA,FAA": "PMV",
      "Airport Name": "Del Caribe Santiago Mariño Int'l Airport",
      "Country": "Venezuela",
      "GMT Offset": "-4",
      "Latitude": "-63.96659851074219",
      "Location": "  - Porlamar",
      "Longitude": "10.912603378295898"
  },
  {
      "Airport Code iATA,FAA": "CCS",
      "Airport Name": "Simón Bolívar Int'l Airport",
      "Country": "Venezuela",
      "GMT Offset": "-4",
      "Latitude": "-66.991222",
      "Location": "  - Caracas",
      "Longitude": "10.601194"
  },
  {
      "Airport Code iATA,FAA": "PBL",
      "Airport Name": "General Bartolome Salom Int'l Airport",
      "Country": "Venezuela",
      "GMT Offset": "-4",
      "Latitude": "-68.072998046875",
      "Location": "  - Puerto Cabello",
      "Longitude": "10.480500221252441"
  },
  {
      "Airport Code iATA,FAA": "PZO",
      "Airport Name": "General Manuel Carlos Piar Int'l Airport",
      "Country": "Venezuela",
      "GMT Offset": "-4",
      "Latitude": "-62.760398864746094",
      "Location": "  - Guayana",
      "Longitude": "8.288530349731445"
  },
  {
      "Airport Code iATA,FAA": "STD",
      "Airport Name": "Mayor Buenaventura Vivas Int'l Airport",
      "Country": "Venezuela",
      "GMT Offset": "-4",
      "Latitude": "-72.035103",
      "Location": "  - Santo Domingo",
      "Longitude": "7.56538"
  },
  {
      "Airport Code iATA,FAA": "VLN",
      "Airport Name": "Arturo Michelena Int'l Airport",
      "Country": "Venezuela",
      "GMT Offset": "-4",
      "Latitude": "-67.92839813232422",
      "Location": "  - Valencia",
      "Longitude": "10.14973258972168"
  },
  {
      "Airport Code iATA,FAA": "ANU",
      "Airport Name": "V.C. Bird Int'l Airport",
      "Country": "Antigua and Barbuda",
      "GMT Offset": "-4",
      "Latitude": "-61.792702",
      "Location": "  - Antigua",
      "Longitude": "17.1367"
  },
  {
      "Airport Code iATA,FAA": "BGI",
      "Airport Name": "Sir Grantley Adams Int'l Airport",
      "Country": "Barbados",
      "GMT Offset": "-4",
      "Latitude": "-59.4925003052",
      "Location": "  - Bridgetown",
      "Longitude": "13.0746002197"
  },
  {
      "Airport Code iATA,FAA": "FDF",
      "Airport Name": "Martinique Aimé Césaire Int'l Airport",
      "Country": "Martinique",
      "GMT Offset": "-4",
      "Latitude": "-61.00320053100586",
      "Location": "  - Fort-de-france",
      "Longitude": "14.590999603271484"
  },
  {
      "Airport Code iATA,FAA": "GND",
      "Airport Name": "Point Salines Int'l Airport",
      "Country": "Grenada",
      "GMT Offset": "-4",
      "Latitude": "-61.78620147705078",
      "Location": "  - Point Salines",
      "Longitude": "12.004199981689453"
  },
  {
      "Airport Code iATA,FAA": "SJU",
      "Airport Name": "Luis Munoz Marin Int'l Airport",
      "Country": "Puerto Rico",
      "GMT Offset": "-4",
      "Latitude": "-66.0018005371",
      "Location": "  - San Juan",
      "Longitude": "18.4393997192"
  },
  {
      "Airport Code iATA,FAA": "SKB",
      "Airport Name": "Robert L. Bradshaw Int'l Airport",
      "Country": "Saint Kitts and Nevis",
      "GMT Offset": "-4",
      "Latitude": "-62.71870040893555",
      "Location": "  - Basse Terre",
      "Longitude": "17.311199188232422"
  },
  {
      "Airport Code iATA,FAA": "UVF",
      "Airport Name": "Hewanorra Int'l Airport",
      "Country": "Saint Lucia",
      "GMT Offset": "-4",
      "Latitude": "-60.952599",
      "Location": "  - Hewandorra",
      "Longitude": "13.7332"
  },
  {
      "Airport Code iATA,FAA": "AUA",
      "Airport Name": "Queen Beatrix Int'l Airport",
      "Country": "Aruba",
      "GMT Offset": "-4",
      "Latitude": "-70.015198",
      "Location": "  - Oranjestad",
      "Longitude": "12.5014"
  },
  {
      "Airport Code iATA,FAA": "BON",
      "Airport Name": "Flamingo Int'l Airport",
      "Country": "Netherlands Antilles",
      "GMT Offset": "-4",
      "Latitude": "-68.26850128173828",
      "Location": "  - Kralendijk",
      "Longitude": "12.130999565124512"
  },
  {
      "Airport Code iATA,FAA": "CUR",
      "Airport Name": "Hato Int'l Airport",
      "Country": "Netherlands Antilles",
      "GMT Offset": "-4",
      "Latitude": "-68.959801",
      "Location": "  - Willemstad",
      "Longitude": "12.1889"
  },
  {
      "Airport Code iATA,FAA": "SXM",
      "Airport Name": "Princess Juliana Int'l Airport",
      "Country": "Netherlands Antilles",
      "GMT Offset": "-4",
      "Latitude": "-63.1088981628",
      "Location": "  - Philipsburg",
      "Longitude": "18.041000366200002"
  },
  {
      "Airport Code iATA,FAA": "AXA",
      "Airport Name": "Clayton J Lloyd Int'l Airport",
      "Country": "Anguilla",
      "GMT Offset": "-4",
      "Latitude": "-63.055099",
      "Location": "  - The Valley",
      "Longitude": "18.2048"
  },
  {
      "Airport Code iATA,FAA": "POS",
      "Airport Name": "Piarco Int'l Airport",
      "Country": "Trinidad and Tobago",
      "GMT Offset": "-4",
      "Latitude": "-61.33720016479492",
      "Location": "  - Port-of-spain",
      "Longitude": "10.595399856567383"
  },
  {
      "Airport Code iATA,FAA": "EIS",
      "Airport Name": "Terrance B. Lettsome Int'l Airport",
      "Country": "British Virgin Islands",
      "GMT Offset": "-4",
      "Latitude": "-64.54299926757812",
      "Location": "  - Tortola",
      "Longitude": "18.444799423217773"
  },
  {
      "Airport Code iATA,FAA": "SVD",
      "Airport Name": "Argyle Int'l Airport",
      "Country": "Saint Vincent and the Grenadines",
      "GMT Offset": "-4",
      "Latitude": "-61.149945",
      "Location": "  - Kingstown",
      "Longitude": "13.156695"
  },
  {
      "Airport Code iATA,FAA": "TSE",
      "Airport Name": "Astana Int'l Airport",
      "Country": "Kazakhstan",
      "GMT Offset": "6",
      "Latitude": "71.46690368652344",
      "Location": "  - Tselinograd",
      "Longitude": "51.02220153808594"
  },
  {
      "Airport Code iATA,FAA": "FRU",
      "Airport Name": "Manas Int'l Airport",
      "Country": "Kyrgyzstan",
      "GMT Offset": "6",
      "Latitude": "74.4776000977",
      "Location": "  - Bishkek",
      "Longitude": "43.0612983704"
  },
  {
      "Airport Code iATA,FAA": "GYD",
      "Airport Name": "Heydar Aliyev Int'l Airport",
      "Country": "Azerbaijan",
      "GMT Offset": "4",
      "Latitude": "50.04669952392578",
      "Location": "  - Baku",
      "Longitude": "40.467498779296875"
  },
  {
      "Airport Code iATA,FAA": "VVO",
      "Airport Name": "Vladivostok Int'l Airport",
      "Country": "Russia",
      "GMT Offset": "10",
      "Latitude": "132.1479949951172",
      "Location": "  - Vladivostok",
      "Longitude": "43.39899826049805"
  },
  {
      "Airport Code iATA,FAA": "KBP",
      "Airport Name": "Boryspil Int'l Airport",
      "Country": "Ukraine",
      "GMT Offset": "2",
      "Latitude": "30.894699096679688",
      "Location": "  - Kiev",
      "Longitude": "50.345001220703125"
  },
  {
      "Airport Code iATA,FAA": "DOK",
      "Airport Name": "Donetsk Int'l Airport",
      "Country": "Ukraine",
      "GMT Offset": "2",
      "Latitude": "37.73970031738281",
      "Location": "  - Donetsk",
      "Longitude": "48.07360076904297"
  },
  {
      "Airport Code iATA,FAA": "DNK",
      "Airport Name": "Dnipropetrovsk Int'l Airport",
      "Country": "Ukraine",
      "GMT Offset": "2",
      "Latitude": "35.10060119628906",
      "Location": "  - Dnepropetrovsk",
      "Longitude": "48.357200622558594"
  },
  {
      "Airport Code iATA,FAA": "SIP",
      "Airport Name": "Simferopol Int'l Airport",
      "Country": "Ukraine",
      "GMT Offset": "3",
      "Latitude": "33.975101470947266",
      "Location": "  - Simferopol",
      "Longitude": "45.05220031738281"
  },
  {
      "Airport Code iATA,FAA": "IEV",
      "Airport Name": "Kiev Zhuliany Int'l Airport",
      "Country": "Ukraine",
      "GMT Offset": "2",
      "Latitude": "30.45194",
      "Location": "  - Kiev",
      "Longitude": "50.40194"
  },
  {
      "Airport Code iATA,FAA": "LWO",
      "Airport Name": "Lviv Int'l Airport",
      "Country": "Ukraine",
      "GMT Offset": "2",
      "Latitude": "23.956100463867188",
      "Location": "  - Lvov",
      "Longitude": "49.8125"
  },
  {
      "Airport Code iATA,FAA": "ODS",
      "Airport Name": "Odessa Int'l Airport",
      "Country": "Ukraine",
      "GMT Offset": "2",
      "Latitude": "30.67650032043457",
      "Location": "  - Odessa",
      "Longitude": "46.42679977416992"
  },
  {
      "Airport Code iATA,FAA": "KRR",
      "Airport Name": "Krasnodar Pashkovsky Int'l Airport",
      "Country": "Russia",
      "GMT Offset": "3",
      "Latitude": "39.170501708984",
      "Location": "  - Krasnodar",
      "Longitude": "45.034698486328"
  },
  {
      "Airport Code iATA,FAA": "ROV",
      "Airport Name": "Platov Int'l Airport",
      "Country": "Russia",
      "GMT Offset": "3",
      "Latitude": "39.924722",
      "Location": "  - Rostov",
      "Longitude": "47.493888"
  },
  {
      "Airport Code iATA,FAA": "AER",
      "Airport Name": "Sochi Int'l Airport",
      "Country": "Russia",
      "GMT Offset": "3",
      "Latitude": "39.9566",
      "Location": "  - Sochi",
      "Longitude": "43.449902"
  },
  {
      "Airport Code iATA,FAA": "VOG",
      "Airport Name": "Volgograd Int'l Airport",
      "Country": "Russia",
      "GMT Offset": "3",
      "Latitude": "44.34550094604492",
      "Location": "  - Volgograd",
      "Longitude": "48.782501220703125"
  },
  {
      "Airport Code iATA,FAA": "MQF",
      "Airport Name": "Magnitogorsk Int'l Airport",
      "Country": "Russia",
      "GMT Offset": "5",
      "Latitude": "58.755699157714844",
      "Location": "  - Magnetiogorsk",
      "Longitude": "53.39310073852539"
  },
  {
      "Airport Code iATA,FAA": "ASB",
      "Airport Name": "Ashgabat Int'l Airport",
      "Country": "Turkmenistan",
      "GMT Offset": "5",
      "Latitude": "58.361",
      "Location": "  - Ashkhabad",
      "Longitude": "37.986801"
  },
  {
      "Airport Code iATA,FAA": "TAS",
      "Airport Name": "Tashkent Int'l Airport",
      "Country": "Uzbekistan",
      "GMT Offset": "5",
      "Latitude": "69.2811965942",
      "Location": "  - Tashkent",
      "Longitude": "41.257900238"
  },
  {
      "Airport Code iATA,FAA": "SVO",
      "Airport Name": "Sheremetyevo Int'l Airport",
      "Country": "Russia",
      "GMT Offset": "3",
      "Latitude": "37.4146",
      "Location": "  - Moscow",
      "Longitude": "55.972599"
  },
  {
      "Airport Code iATA,FAA": "VOZ",
      "Airport Name": "Voronezh Int'l Airport",
      "Country": "Russia",
      "GMT Offset": "3",
      "Latitude": "39.22959899902344",
      "Location": "  - Voronezh",
      "Longitude": "51.81420135498047"
  },
  {
      "Airport Code iATA,FAA": "VKO",
      "Airport Name": "Vnukovo Int'l Airport",
      "Country": "Russia",
      "GMT Offset": "3",
      "Latitude": "37.2615013123",
      "Location": "  - Moscow",
      "Longitude": "55.5914993286"
  },
  {
      "Airport Code iATA,FAA": "KZN",
      "Airport Name": "Kazan Int'l Airport",
      "Country": "Russia",
      "GMT Offset": "3",
      "Latitude": "49.278701782227",
      "Location": "  - Kazan",
      "Longitude": "55.606201171875"
  },
  {
      "Airport Code iATA,FAA": "UFA",
      "Airport Name": "Ufa Int'l Airport",
      "Country": "Russia",
      "GMT Offset": "5",
      "Latitude": "55.874401092529",
      "Location": "  - Ufa",
      "Longitude": "54.557498931885"
  },
  {
      "Airport Code iATA,FAA": "KUF",
      "Airport Name": "Kurumoch Int'l Airport",
      "Country": "Russia",
      "GMT Offset": "4",
      "Latitude": "50.16429901123",
      "Location": "  - Samara",
      "Longitude": "53.504901885986"
  },
  {
      "Airport Code iATA,FAA": "AMD",
      "Airport Name": "Sardar Vallabhbhai Patel Int'l Airport",
      "Country": "India",
      "GMT Offset": "5.5",
      "Latitude": "72.63469696039999",
      "Location": "  - Ahmedabad",
      "Longitude": "23.0771999359"
  },
  {
      "Airport Code iATA,FAA": "BOM",
      "Airport Name": "Chhatrapati Shivaji Int'l Airport",
      "Country": "India",
      "GMT Offset": "5.5",
      "Latitude": "72.8678970337",
      "Location": "  - Mumbai",
      "Longitude": "19.0886993408"
  },
  {
      "Airport Code iATA,FAA": "BHO",
      "Airport Name": "Raja Bhoj Int'l Airport",
      "Country": "India",
      "GMT Offset": "5.5",
      "Latitude": "77.3374023438",
      "Location": "  - Bhopal",
      "Longitude": "23.2875003815"
  },
  {
      "Airport Code iATA,FAA": "NAG",
      "Airport Name": "Dr. Babasaheb Ambedkar Int'l Airport",
      "Country": "India",
      "GMT Offset": "5.5",
      "Latitude": "79.04720306396484",
      "Location": "  - Nagpur",
      "Longitude": "21.092199325561523"
  },
  {
      "Airport Code iATA,FAA": "CMB",
      "Airport Name": "Bandaranaike Int'l Colombo Airport",
      "Country": "Sri Lanka",
      "GMT Offset": "5.5",
      "Latitude": "79.88410186767578",
      "Location": "  - Colombo",
      "Longitude": "7.180759906768799"
  },
  {
      "Airport Code iATA,FAA": "PNH",
      "Airport Name": "Phnom Penh Int'l Airport",
      "Country": "Cambodia",
      "GMT Offset": "7",
      "Latitude": "104.84400177001953",
      "Location": "  - Phnom-penh",
      "Longitude": "11.546600341796875"
  },
  {
      "Airport Code iATA,FAA": "REP",
      "Airport Name": "Siem Reap Int'l Airport",
      "Country": "Cambodia",
      "GMT Offset": "7",
      "Latitude": "103.81300354",
      "Location": "  - Siem-reap",
      "Longitude": "13.410699844400002"
  },
  {
      "Airport Code iATA,FAA": "CCU",
      "Airport Name": "Netaji Subhash Chandra Bose Int'l Airport",
      "Country": "India",
      "GMT Offset": "5.5",
      "Latitude": "88.44670104980469",
      "Location": "  - Kolkata",
      "Longitude": "22.654699325561523"
  },
  {
      "Airport Code iATA,FAA": "CGP",
      "Airport Name": "Shah Amanat Int'l Airport",
      "Country": "Bangladesh",
      "GMT Offset": "6",
      "Latitude": "91.81330108642578",
      "Location": "  - Chittagong",
      "Longitude": "22.24959945678711"
  },
  {
      "Airport Code iATA,FAA": "ZYL",
      "Airport Name": "Osmany Int'l Airport",
      "Country": "Bangladesh",
      "GMT Offset": "6",
      "Latitude": "91.8667984008789",
      "Location": "  - Sylhet Osmani",
      "Longitude": "24.963199615478516"
  },
  {
      "Airport Code iATA,FAA": "DAC",
      "Airport Name": "Hazrat Shahjalal Int'l Airport",
      "Country": "Bangladesh",
      "GMT Offset": "6",
      "Latitude": "90.397783",
      "Location": "  - Dhaka",
      "Longitude": "23.843347"
  },
  {
      "Airport Code iATA,FAA": "HKG",
      "Airport Name": "Hong Kong Int'l Airport",
      "Country": "Hong Kong",
      "GMT Offset": "8",
      "Latitude": "113.915001",
      "Location": "  - Hong Kong",
      "Longitude": "22.308901"
  },
  {
      "Airport Code iATA,FAA": "ATQ",
      "Airport Name": "Sri Guru Ram Dass Jee Int'l Airport",
      "Country": "India",
      "GMT Offset": "5.5",
      "Latitude": "74.7973022461",
      "Location": "  - Amritsar",
      "Longitude": "31.7096004486"
  },
  {
      "Airport Code iATA,FAA": "DEL",
      "Airport Name": "Indira Gandhi Int'l Airport",
      "Country": "India",
      "GMT Offset": "5.5",
      "Latitude": "77.103104",
      "Location": "  - Delhi",
      "Longitude": "28.5665"
  },
  {
      "Airport Code iATA,FAA": "JAI",
      "Airport Name": "Jaipur Int'l Airport",
      "Country": "India",
      "GMT Offset": "5.5",
      "Latitude": "75.812202",
      "Location": "  - Jaipur",
      "Longitude": "26.8242"
  },
  {
      "Airport Code iATA,FAA": "LKO",
      "Airport Name": "Chaudhary Charan Singh Int'l Airport",
      "Country": "India",
      "GMT Offset": "5.5",
      "Latitude": "80.8892974854",
      "Location": "  - Lucknow",
      "Longitude": "26.7605991364"
  },
  {
      "Airport Code iATA,FAA": "LPQ",
      "Airport Name": "Luang Phabang Int'l Airport",
      "Country": "Laos",
      "GMT Offset": "7",
      "Latitude": "102.16100311279297",
      "Location": "  - Luang Prabang",
      "Longitude": "19.897300720214844"
  },
  {
      "Airport Code iATA,FAA": "PKZ",
      "Airport Name": "Pakse Int'l Airport",
      "Country": "Laos",
      "GMT Offset": "7",
      "Latitude": "105.78099822998047",
      "Location": "  - Pakse",
      "Longitude": "15.132100105285645"
  },
  {
      "Airport Code iATA,FAA": "VTE",
      "Airport Name": "Wattay Int'l Airport",
      "Country": "Laos",
      "GMT Offset": "7",
      "Latitude": "102.56300354",
      "Location": "  - Vientiane",
      "Longitude": "17.988300323500003"
  },
  {
      "Airport Code iATA,FAA": "MFM",
      "Airport Name": "Macau Int'l Airport",
      "Country": "Macau",
      "GMT Offset": "8",
      "Latitude": "113.592003",
      "Location": "  - Macau",
      "Longitude": "22.149599"
  },
  {
      "Airport Code iATA,FAA": "KTM",
      "Airport Name": "Tribhuvan Int'l Airport",
      "Country": "Nepal",
      "GMT Offset": "5.75",
      "Latitude": "85.3591",
      "Location": "  - Kathmandu",
      "Longitude": "27.6966"
  },
  {
      "Airport Code iATA,FAA": "BLR",
      "Airport Name": "Kempegowda Int'l Airport",
      "Country": "India",
      "GMT Offset": "5.5",
      "Latitude": "77.706299",
      "Location": "  - Bangalore",
      "Longitude": "13.1979"
  },
  {
      "Airport Code iATA,FAA": "CJB",
      "Airport Name": "Coimbatore Int'l Airport",
      "Country": "India",
      "GMT Offset": "5.5",
      "Latitude": "77.0434036255",
      "Location": "  - Coimbatore",
      "Longitude": "11.029999733"
  },
  {
      "Airport Code iATA,FAA": "COK",
      "Airport Name": "Cochin Int'l Airport",
      "Country": "India",
      "GMT Offset": "5.5",
      "Latitude": "76.401901",
      "Location": "  - Kochi",
      "Longitude": "10.152"
  },
  {
      "Airport Code iATA,FAA": "CCJ",
      "Airport Name": "Calicut Int'l Airport",
      "Country": "India",
      "GMT Offset": "5.5",
      "Latitude": "75.95529937740001",
      "Location": "  - Calicut",
      "Longitude": "11.1367998123"
  },
  {
      "Airport Code iATA,FAA": "IXE",
      "Airport Name": "Mangalore Int'l Airport",
      "Country": "India",
      "GMT Offset": "5.5",
      "Latitude": "74.8900985718",
      "Location": "  - Mangalore",
      "Longitude": "12.9612998962"
  },
  {
      "Airport Code iATA,FAA": "MAA",
      "Airport Name": "Chennai Int'l Airport",
      "Country": "India",
      "GMT Offset": "5.5",
      "Latitude": "80.16929626464844",
      "Location": "  - Madras",
      "Longitude": "12.990005493164062"
  },
  {
      "Airport Code iATA,FAA": "IXZ",
      "Airport Name": "Vir Savarkar Int'l Airport",
      "Country": "India",
      "GMT Offset": "5.5",
      "Latitude": "92.72969818115234",
      "Location": "  - Port Blair",
      "Longitude": "11.641200065612793"
  },
  {
      "Airport Code iATA,FAA": "TRV",
      "Airport Name": "Trivandrum Int'l Airport",
      "Country": "India",
      "GMT Offset": "5.5",
      "Latitude": "76.9200973511",
      "Location": "  - Trivandrum",
      "Longitude": "8.48211956024"
  },
  {
      "Airport Code iATA,FAA": "MLE",
      "Airport Name": "Malé Int'l Airport",
      "Country": "Maldives",
      "GMT Offset": "5",
      "Latitude": "73.52909851074219",
      "Location": "  - Male",
      "Longitude": "4.191830158233643"
  },
  {
      "Airport Code iATA,FAA": "DMK",
      "Airport Name": "Don Mueang Int'l Airport",
      "Country": "Thailand",
      "GMT Offset": "7",
      "Latitude": "100.607002258",
      "Location": "  - Bangkok",
      "Longitude": "13.9125995636"
  },
  {
      "Airport Code iATA,FAA": "UTP",
      "Airport Name": "U-Tapao Int'l Airport",
      "Country": "Thailand",
      "GMT Offset": "7",
      "Latitude": "101.00499725341797",
      "Location": "  - Pattaya",
      "Longitude": "12.679900169372559"
  },
  {
      "Airport Code iATA,FAA": "HKT",
      "Airport Name": "Phuket Int'l Airport",
      "Country": "Thailand",
      "GMT Offset": "7",
      "Latitude": "98.316902",
      "Location": "  - Phuket",
      "Longitude": "8.1132"
  },
  {
      "Airport Code iATA,FAA": "HDY",
      "Airport Name": "Hat Yai Int'l Airport",
      "Country": "Thailand",
      "GMT Offset": "7",
      "Latitude": "100.392997742",
      "Location": "  - Hat Yai",
      "Longitude": "6.93320989609"
  },
  {
      "Airport Code iATA,FAA": "DAD",
      "Airport Name": "Da Nang Int'l Airport",
      "Country": "Vietnam",
      "GMT Offset": "7",
      "Latitude": "108.1989974975586",
      "Location": "  - Danang",
      "Longitude": "16.043899536132812"
  },
  {
      "Airport Code iATA,FAA": "HAN",
      "Airport Name": "Noi Bai Int'l Airport",
      "Country": "Vietnam",
      "GMT Offset": "7",
      "Latitude": "105.80699920654297",
      "Location": "  - Hanoi",
      "Longitude": "21.221200942993164"
  },
  {
      "Airport Code iATA,FAA": "PQC",
      "Airport Name": "Phu Quoc Int'l Airport",
      "Country": "Vietnam",
      "GMT Offset": "7",
      "Latitude": "103.9931",
      "Location": "  - Phuquoc",
      "Longitude": "10.1698"
  },
  {
      "Airport Code iATA,FAA": "SGN",
      "Airport Name": "Tan Son Nhat Int'l Airport",
      "Country": "Vietnam",
      "GMT Offset": "7",
      "Latitude": "106.652000427",
      "Location": "  - Ho Chi Minh City",
      "Longitude": "10.8187999725"
  },
  {
      "Airport Code iATA,FAA": "MDL",
      "Airport Name": "Mandalay Int'l Airport",
      "Country": "Burma",
      "GMT Offset": "6.5",
      "Latitude": "95.97789764404297",
      "Location": "  - Mandalay",
      "Longitude": "21.702199935913086"
  },
  {
      "Airport Code iATA,FAA": "RGN",
      "Airport Name": "Yangon Int'l Airport",
      "Country": "Burma",
      "GMT Offset": "6.5",
      "Latitude": "96.1332015991",
      "Location": "  - Yangon",
      "Longitude": "16.907300949099998"
  },
  {
      "Airport Code iATA,FAA": "UPG",
      "Airport Name": "Hasanuddin Int'l Airport",
      "Country": "Indonesia",
      "GMT Offset": "8",
      "Latitude": "119.55400085449219",
      "Location": "  - Ujung Pandang",
      "Longitude": "-5.061629772186279"
  },
  {
      "Airport Code iATA,FAA": "DJJ",
      "Airport Name": "Sentani Int'l Airport",
      "Country": "Indonesia",
      "GMT Offset": "9",
      "Latitude": "140.5160064698",
      "Location": "  - Jayapura",
      "Longitude": "-2.5769500733"
  },
  {
      "Airport Code iATA,FAA": "KCH",
      "Airport Name": "Kuching Int'l Airport",
      "Country": "Malaysia",
      "GMT Offset": "8",
      "Latitude": "110.34700012207031",
      "Location": "  - Kuching",
      "Longitude": "1.4846999645233154"
  },
  {
      "Airport Code iATA,FAA": "BKI",
      "Airport Name": "Kota Kinabalu Int'l Airport",
      "Country": "Malaysia",
      "GMT Offset": "8",
      "Latitude": "116.0510025024414",
      "Location": "  - Kota Kinabalu",
      "Longitude": "5.9372100830078125"
  },
  {
      "Airport Code iATA,FAA": "BWN",
      "Airport Name": "Brunei Int'l Airport",
      "Country": "Brunei",
      "GMT Offset": "8",
      "Latitude": "114.9280014038086",
      "Location": "  - Bandar Seri Begawan",
      "Longitude": "4.944200038909912"
  },
  {
      "Airport Code iATA,FAA": "CGK",
      "Airport Name": "Soekarno-Hatta Int'l Airport",
      "Country": "Indonesia",
      "GMT Offset": "7",
      "Latitude": "106.65599823",
      "Location": "  - Jakarta",
      "Longitude": "-6.1255698204"
  },
  {
      "Airport Code iATA,FAA": "PDG",
      "Airport Name": "Minangkabau Int'l Airport",
      "Country": "Indonesia",
      "GMT Offset": "7",
      "Latitude": "100.280998",
      "Location": "  - Padang",
      "Longitude": "-0.786917"
  },
  {
      "Airport Code iATA,FAA": "BTJ",
      "Airport Name": "Sultan Iskandar Muda Int'l Airport",
      "Country": "Indonesia",
      "GMT Offset": "7",
      "Latitude": "95.42063713070002",
      "Location": "  - Banda Aceh",
      "Longitude": "5.522872024010001"
  },
  {
      "Airport Code iATA,FAA": "JHB",
      "Airport Name": "Senai Int'l Airport",
      "Country": "Malaysia",
      "GMT Offset": "8",
      "Latitude": "103.669998",
      "Location": "  - Johor Bahru",
      "Longitude": "1.64131"
  },
  {
      "Airport Code iATA,FAA": "KUL",
      "Airport Name": "Kuala Lumpur Int'l Airport",
      "Country": "Malaysia",
      "GMT Offset": "8",
      "Latitude": "101.70999908447",
      "Location": "  - Kuala Lumpur",
      "Longitude": "2.745579957962"
  },
  {
      "Airport Code iATA,FAA": "LGK",
      "Airport Name": "Langkawi Int'l Airport",
      "Country": "Malaysia",
      "GMT Offset": "8",
      "Latitude": "99.72869873046875",
      "Location": "  - Langkawi",
      "Longitude": "6.329730033874512"
  },
  {
      "Airport Code iATA,FAA": "PEN",
      "Airport Name": "Penang Int'l Airport",
      "Country": "Malaysia",
      "GMT Offset": "8",
      "Latitude": "100.2770004272461",
      "Location": "  - Penang",
      "Longitude": "5.297140121459961"
  },
  {
      "Airport Code iATA,FAA": "DIL",
      "Airport Name": "Presidente Nicolau Lobato Int'l Airport",
      "Country": "East Timor",
      "GMT Offset": "9",
      "Latitude": "125.526000977",
      "Location": "  - Dili",
      "Longitude": "-8.54640007019"
  },
  {
      "Airport Code iATA,FAA": "BNE",
      "Airport Name": "Brisbane Int'l Airport",
      "Country": "Australia",
      "GMT Offset": "10",
      "Latitude": "153.11700439453125",
      "Location": "  - Brisbane",
      "Longitude": "-27.384199142456055"
  },
  {
      "Airport Code iATA,FAA": "CNS",
      "Airport Name": "Cairns Int'l Airport",
      "Country": "Australia",
      "GMT Offset": "10",
      "Latitude": "145.755004883",
      "Location": "  - Cairns",
      "Longitude": "-16.885799408"
  },
  {
      "Airport Code iATA,FAA": "HBA",
      "Airport Name": "Hobart Int'l Airport",
      "Country": "Australia",
      "GMT Offset": "10",
      "Latitude": "147.509994507",
      "Location": "  - Hobart",
      "Longitude": "-42.836101532"
  },
  {
      "Airport Code iATA,FAA": "MEL",
      "Airport Name": "Melbourne Int'l Airport",
      "Country": "Australia",
      "GMT Offset": "10",
      "Latitude": "144.843002",
      "Location": "  - Melbourne",
      "Longitude": "-37.673302"
  },
  {
      "Airport Code iATA,FAA": "ADL",
      "Airport Name": "Adelaide Int'l Airport",
      "Country": "Australia",
      "GMT Offset": "9.5",
      "Latitude": "138.531006",
      "Location": "  - Adelaide",
      "Longitude": "-34.945"
  },
  {
      "Airport Code iATA,FAA": "PHE",
      "Airport Name": "Port Hedland Int'l Airport",
      "Country": "Australia",
      "GMT Offset": "8",
      "Latitude": "118.625999451",
      "Location": "  - Port Hedland",
      "Longitude": "-20.3777999878"
  },
  {
      "Airport Code iATA,FAA": "PER",
      "Airport Name": "Perth Int'l Airport",
      "Country": "Australia",
      "GMT Offset": "8",
      "Latitude": "115.96700286865234",
      "Location": "  - Perth",
      "Longitude": "-31.94029998779297"
  },
  {
      "Airport Code iATA,FAA": "CBR",
      "Airport Name": "Canberra Int'l Airport",
      "Country": "Australia",
      "GMT Offset": "10",
      "Latitude": "149.19500732421875",
      "Location": "  - Canberra",
      "Longitude": "-35.30690002441406"
  },
  {
      "Airport Code iATA,FAA": "NLK",
      "Airport Name": "Norfolk Island Int'l Airport",
      "Country": "Norfolk Island",
      "GMT Offset": "11",
      "Latitude": "167.93899536132812",
      "Location": "  - Norfolk Island",
      "Longitude": "-29.04159927368164"
  },
  {
      "Airport Code iATA,FAA": "SYD",
      "Airport Name": "Sydney Kingsford Smith Int'l Airport",
      "Country": "Australia",
      "GMT Offset": "10",
      "Latitude": "151.177001953125",
      "Location": "  - Sydney",
      "Longitude": "-33.94609832763672"
  },
  {
      "Airport Code iATA,FAA": "PEK",
      "Airport Name": "Beijing Capital Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "116.58499908447266",
      "Location": "  - Beijing",
      "Longitude": "40.080101013183594"
  },
  {
      "Airport Code iATA,FAA": "TSN",
      "Airport Name": "Tianjin Binhai Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "117.346000671",
      "Location": "  - Tianjin",
      "Longitude": "39.124401092499994"
  },
  {
      "Airport Code iATA,FAA": "CAN",
      "Airport Name": "Guangzhou Baiyun Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "113.29900360107422",
      "Location": "  - Guangzhou",
      "Longitude": "23.39240074157715"
  },
  {
      "Airport Code iATA,FAA": "CSX",
      "Airport Name": "Changsha Huanghua Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "113.220001221",
      "Location": "  - Changcha",
      "Longitude": "28.189199447599997"
  },
  {
      "Airport Code iATA,FAA": "KWL",
      "Airport Name": "Guilin Liangjiang Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "110.03900146484375",
      "Location": "  - Guilin",
      "Longitude": "25.21809959411621"
  },
  {
      "Airport Code iATA,FAA": "SZX",
      "Airport Name": "Shenzhen Bao'an Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "113.81099700927734",
      "Location": "  - Shenzhen",
      "Longitude": "22.639299392700195"
  },
  {
      "Airport Code iATA,FAA": "CGO",
      "Airport Name": "Zhengzhou Xinzheng Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "113.841003418",
      "Location": "  - Zhengzhou",
      "Longitude": "34.519699096699995"
  },
  {
      "Airport Code iATA,FAA": "WUH",
      "Airport Name": "Wuhan Tianhe Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "114.208",
      "Location": "  - Wuhan",
      "Longitude": "30.7838"
  },
  {
      "Airport Code iATA,FAA": "FNJ",
      "Airport Name": "Pyongyang Sunan Int'l Airport",
      "Country": "North Korea",
      "GMT Offset": "8.5",
      "Latitude": "125.669998",
      "Location": "  - Pyongyang",
      "Longitude": "39.224098"
  },
  {
      "Airport Code iATA,FAA": "XIY",
      "Airport Name": "Xi'an Xianyang Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "108.751999",
      "Location": "  - Xi'an",
      "Longitude": "34.447102"
  },
  {
      "Airport Code iATA,FAA": "ULN",
      "Airport Name": "Chinggis Khaan Int'l Airport",
      "Country": "Mongolia",
      "GMT Offset": "8",
      "Latitude": "106.766998",
      "Location": "  - Ulan Bator",
      "Longitude": "47.843102"
  },
  {
      "Airport Code iATA,FAA": "KMG",
      "Airport Name": "Kunming Changshui Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "102.9291667",
      "Location": "  - Kunming",
      "Longitude": "25.1019444"
  },
  {
      "Airport Code iATA,FAA": "XMN",
      "Airport Name": "Xiamen Gaoqi Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "118.12799835205078",
      "Location": "  - Xiamen",
      "Longitude": "24.54400062561035"
  },
  {
      "Airport Code iATA,FAA": "KHN",
      "Airport Name": "Nanchang Changbei Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "115.9000015258789",
      "Location": "  - Nanchang",
      "Longitude": "28.864999771118164"
  },
  {
      "Airport Code iATA,FAA": "FOC",
      "Airport Name": "Fuzhou Changle Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "119.66300201416016",
      "Location": "  - Fuzhou",
      "Longitude": "25.935100555419922"
  },
  {
      "Airport Code iATA,FAA": "HGH",
      "Airport Name": "Hangzhou Xiaoshan Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "120.43399810791016",
      "Location": "  - Hangzhou",
      "Longitude": "30.22949981689453"
  },
  {
      "Airport Code iATA,FAA": "NGB",
      "Airport Name": "Ningbo Lishe Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "121.46199798583984",
      "Location": "  - Ninbo",
      "Longitude": "29.82670021057129"
  },
  {
      "Airport Code iATA,FAA": "HFE",
      "Airport Name": "Hefei Luogang Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "117.2979965209961",
      "Location": "  - Hefei",
      "Longitude": "31.780000686645508"
  },
  {
      "Airport Code iATA,FAA": "SHA",
      "Airport Name": "Shanghai Hongqiao Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "121.33599853515625",
      "Location": "  - Shanghai",
      "Longitude": "31.197900772094727"
  },
  {
      "Airport Code iATA,FAA": "CKG",
      "Airport Name": "Chongqing Jiangbei Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "106.64199829101562",
      "Location": "  - Chongqing",
      "Longitude": "29.719200134277344"
  },
  {
      "Airport Code iATA,FAA": "CTU",
      "Airport Name": "Chengdu Shuangliu Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "103.9469985961914",
      "Location": "  - Chengdu",
      "Longitude": "30.578500747680664"
  },
  {
      "Airport Code iATA,FAA": "URC",
      "Airport Name": "Ürümqi Diwopu Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "87.47419738769531",
      "Location": "  - Urumqi",
      "Longitude": "43.907100677490234"
  },
  {
      "Airport Code iATA,FAA": "MDG",
      "Airport Name": "Mudanjiang Hailang Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "129.569000244",
      "Location": "  - Mudanjiang",
      "Longitude": "44.5241012573"
  },
  {
      "Airport Code iATA,FAA": "PVG",
      "Airport Name": "Shanghai Pudong Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "121.80500030517578",
      "Location": "  - Shanghai",
      "Longitude": "31.143400192260742"
  },
  {
      "Airport Code iATA,FAA": "SZB",
      "Airport Name": "Sultan Abdul Aziz Shah Int'l Airport",
      "Country": "Malaysia",
      "GMT Offset": "8",
      "Latitude": "101.54900360107422",
      "Location": "  - Kuala Lumpur",
      "Longitude": "3.130579948425293"
  },
  {
      "Airport Code iATA,FAA": "HBE",
      "Airport Name": "Borg El Arab Int'l Airport",
      "Country": "Egypt",
      "GMT Offset": "2",
      "Latitude": "29.696399688720703",
      "Location": "  - Alexandria",
      "Longitude": "30.917699813842773"
  },
  {
      "Airport Code iATA,FAA": "ITO",
      "Airport Name": "Hilo Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-10",
      "Latitude": "-155.04800415039062",
      "Location": "  - Hilo",
      "Longitude": "19.721399307250977"
  },
  {
      "Airport Code iATA,FAA": "GFK",
      "Airport Name": "Grand Forks Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-97.176102",
      "Location": "  - Grand Forks",
      "Longitude": "47.949299"
  },
  {
      "Airport Code iATA,FAA": "BOS",
      "Airport Name": "General Edward Lawrence Logan Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-71.00520325",
      "Location": "  - Boston",
      "Longitude": "42.36429977"
  },
  {
      "Airport Code iATA,FAA": "RME",
      "Airport Name": "Griffiss Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-75.40699768",
      "Location": "  - Rome",
      "Longitude": "43.23379898"
  },
  {
      "Airport Code iATA,FAA": "OAK",
      "Airport Name": "Metropolitan Oakland Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-8",
      "Latitude": "-122.221001",
      "Location": "  - Oakland",
      "Longitude": "37.721298"
  },
  {
      "Airport Code iATA,FAA": "MCI",
      "Airport Name": "Kansas City Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-94.713898",
      "Location": "  - Kansas City",
      "Longitude": "39.2976"
  },
  {
      "Airport Code iATA,FAA": "PHX",
      "Airport Name": "Phoenix Sky Harbor Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-7",
      "Latitude": "-112.01200103759766",
      "Location": "  - Phoenix",
      "Longitude": "33.43429946899414"
  },
  {
      "Airport Code iATA,FAA": "BGR",
      "Airport Name": "Bangor Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-68.8281021118164",
      "Location": "  - Bangor",
      "Longitude": "44.80739974975586"
  },
  {
      "Airport Code iATA,FAA": "GEG",
      "Airport Name": "Spokane Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-8",
      "Latitude": "-117.53399658203125",
      "Location": "  - Spokane",
      "Longitude": "47.61989974975586"
  },
  {
      "Airport Code iATA,FAA": "SFO",
      "Airport Name": "San Francisco Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-8",
      "Latitude": "-122.375",
      "Location": "  - San Francisco",
      "Longitude": "37.61899948120117"
  },
  {
      "Airport Code iATA,FAA": "CTB",
      "Airport Name": "Cut Bank Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-7",
      "Latitude": "-112.375999451",
      "Location": "  - Cutbank",
      "Longitude": "48.6083984375"
  },
  {
      "Airport Code iATA,FAA": "MEM",
      "Airport Name": "Memphis Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-89.97669982910156",
      "Location": "  - Memphis",
      "Longitude": "35.04240036010742"
  },
  {
      "Airport Code iATA,FAA": "DUG",
      "Airport Name": "Bisbee Douglas Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-7",
      "Latitude": "-109.603996277",
      "Location": "  - Douglas",
      "Longitude": "31.4689998627"
  },
  {
      "Airport Code iATA,FAA": "LAX",
      "Airport Name": "Los Angeles Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-8",
      "Latitude": "-118.4079971",
      "Location": "  - Los Angeles",
      "Longitude": "33.94250107"
  },
  {
      "Airport Code iATA,FAA": "CLE",
      "Airport Name": "Cleveland Hopkins Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-81.8498001099",
      "Location": "  - Cleveland",
      "Longitude": "41.4117012024"
  },
  {
      "Airport Code iATA,FAA": "CVG",
      "Airport Name": "Cincinnati Northern Kentucky Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-84.6678009033",
      "Location": "  - Cincinnati",
      "Longitude": "39.0488014221"
  },
  {
      "Airport Code iATA,FAA": "JNU",
      "Airport Name": "Juneau Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-9",
      "Latitude": "-134.5760040283203",
      "Location": "  - Juneau",
      "Longitude": "58.35499954223633"
  },
  {
      "Airport Code iATA,FAA": "EWR",
      "Airport Name": "Newark Liberty Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-74.168701171875",
      "Location": "  - Newark",
      "Longitude": "40.692501068115234"
  },
  {
      "Airport Code iATA,FAA": "MOT",
      "Airport Name": "Minot Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-101.27999877929688",
      "Location": "  - Minot",
      "Longitude": "48.2593994140625"
  },
  {
      "Airport Code iATA,FAA": "HUL",
      "Airport Name": "Houlton Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-67.792098999",
      "Location": "  - Houlton",
      "Longitude": "46.1231002808"
  },
  {
      "Airport Code iATA,FAA": "KOA",
      "Airport Name": "Ellison Onizuka Kona Int'l At Keahole Airport",
      "Country": "United States",
      "GMT Offset": "-10",
      "Latitude": "-156.045603",
      "Location": "  - Kona",
      "Longitude": "19.738783"
  },
  {
      "Airport Code iATA,FAA": "MYR",
      "Airport Name": "Myrtle Beach Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-78.9282989502",
      "Location": "  - Myrtle Beach",
      "Longitude": "33.6796989441"
  },
  {
      "Airport Code iATA,FAA": "BYH",
      "Airport Name": "Arkansas Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-89.94400024410001",
      "Location": "  - Blytheville",
      "Longitude": "35.9642982483"
  },
  {
      "Airport Code iATA,FAA": "ACY",
      "Airport Name": "Atlantic City Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-74.57720184326172",
      "Location": "  - Atlantic City",
      "Longitude": "39.45759963989258"
  },
  {
      "Airport Code iATA,FAA": "FLL",
      "Airport Name": "Fort Lauderdale Hollywood Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-80.152702",
      "Location": "  - Fort Lauderdale",
      "Longitude": "26.072599"
  },
  {
      "Airport Code iATA,FAA": "INL",
      "Airport Name": "Falls Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-93.4030990600586",
      "Location": "  - International Falls",
      "Longitude": "48.566200256347656"
  },
  {
      "Airport Code iATA,FAA": "SLC",
      "Airport Name": "Salt Lake City Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-7",
      "Latitude": "-111.97799682617188",
      "Location": "  - Salt Lake City",
      "Longitude": "40.78839874267578"
  },
  {
      "Airport Code iATA,FAA": "MDT",
      "Airport Name": "Harrisburg Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-76.7633972168",
      "Location": "  - Harrisburg",
      "Longitude": "40.1935005188"
  },
  {
      "Airport Code iATA,FAA": "MSS",
      "Airport Name": "Massena Int'l Richards Field",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-74.84559631347656",
      "Location": "  - Massena",
      "Longitude": "44.93579864501953"
  },
  {
      "Airport Code iATA,FAA": "PHN",
      "Airport Name": "St Clair County Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-82.52890015",
      "Location": "  - Port Huron",
      "Longitude": "42.9109993"
  },
  {
      "Airport Code iATA,FAA": "ELP",
      "Airport Name": "El Paso Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-7",
      "Latitude": "-106.3779984",
      "Location": "  - El Paso",
      "Longitude": "31.80719948"
  },
  {
      "Airport Code iATA,FAA": "HRL",
      "Airport Name": "Valley Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-97.65440368652344",
      "Location": "  - Harlingen",
      "Longitude": "26.228500366210938"
  },
  {
      "Airport Code iATA,FAA": "PIT",
      "Airport Name": "Pittsburgh Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-80.23290253",
      "Location": "  - Pittsburgh",
      "Longitude": "40.49150085"
  },
  {
      "Airport Code iATA,FAA": "ALI",
      "Airport Name": "Alice Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-98.02690124510002",
      "Location": "  - Alice",
      "Longitude": "27.740900039699998"
  },
  {
      "Airport Code iATA,FAA": "MIA",
      "Airport Name": "Miami Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-80.29060363769531",
      "Location": "  - Miami",
      "Longitude": "25.79319953918457"
  },
  {
      "Airport Code iATA,FAA": "SEA",
      "Airport Name": "Seattle Tacoma Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-8",
      "Latitude": "-122.308998",
      "Location": "  - Seattle",
      "Longitude": "47.449001"
  },
  {
      "Airport Code iATA,FAA": "JAN",
      "Airport Name": "Jackson-Medgar Wiley Evers Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-90.0758972168",
      "Location": "  - Jackson",
      "Longitude": "32.3111991882"
  },
  {
      "Airport Code iATA,FAA": "GLS",
      "Airport Name": "Scholes Int'l At Galveston Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-94.86039733886719",
      "Location": "  - Galveston",
      "Longitude": "29.265300750732422"
  },
  {
      "Airport Code iATA,FAA": "IND",
      "Airport Name": "Indianapolis Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-86.294403",
      "Location": "  - Indianapolis",
      "Longitude": "39.7173"
  },
  {
      "Airport Code iATA,FAA": "AKC",
      "Airport Name": "Akron Fulton Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-81.4669036865",
      "Location": "  - Akron",
      "Longitude": "41.0374984741"
  },
  {
      "Airport Code iATA,FAA": "YUM",
      "Airport Name": "Yuma MCAS/Yuma Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-7",
      "Latitude": "-114.6060028",
      "Location": "  - Yuma",
      "Longitude": "32.65660095"
  },
  {
      "Airport Code iATA,FAA": "DLH",
      "Airport Name": "Duluth Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-92.19360351559999",
      "Location": "  - Duluth",
      "Longitude": "46.8420982361"
  },
  {
      "Airport Code iATA,FAA": "MWH",
      "Airport Name": "Grant County Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-8",
      "Latitude": "-119.3199997",
      "Location": "  - Grant County Airport",
      "Longitude": "47.20769882"
  },
  {
      "Airport Code iATA,FAA": "RIC",
      "Airport Name": "Richmond Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-77.3197021484375",
      "Location": "  - Richmond",
      "Longitude": "37.50519943237305"
  },
  {
      "Airport Code iATA,FAA": "ORF",
      "Airport Name": "Norfolk Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-76.20120239257812",
      "Location": "  - Norfolk",
      "Longitude": "36.89459991455078"
  },
  {
      "Airport Code iATA,FAA": "SAV",
      "Airport Name": "Savannah Hilton Head Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-81.20210266",
      "Location": "  - Savannah",
      "Longitude": "32.12760162"
  },
  {
      "Airport Code iATA,FAA": "PIE",
      "Airport Name": "St Petersburg Clearwater Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-82.68740082",
      "Location": "  - St. Petersburg",
      "Longitude": "27.91020012"
  },
  {
      "Airport Code iATA,FAA": "SAT",
      "Airport Name": "San Antonio Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-98.46980285644531",
      "Location": "  - San Antonio",
      "Longitude": "29.533700942993164"
  },
  {
      "Airport Code iATA,FAA": "ROC",
      "Airport Name": "Greater Rochester Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-77.67240142822266",
      "Location": "  - Rochester",
      "Longitude": "43.118900299072266"
  },
  {
      "Airport Code iATA,FAA": "RDU",
      "Airport Name": "Raleigh Durham Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-78.7874984741211",
      "Location": "  - Raleigh-durham",
      "Longitude": "35.877601623535156"
  },
  {
      "Airport Code iATA,FAA": "DAY",
      "Airport Name": "James M Cox Dayton Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-84.21939849853516",
      "Location": "  - Dayton",
      "Longitude": "39.902400970458984"
  },
  {
      "Airport Code iATA,FAA": "IAG",
      "Airport Name": "Niagara Falls Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-78.94619750976562",
      "Location": "  - Niagara Falls",
      "Longitude": "43.1072998046875"
  },
  {
      "Airport Code iATA,FAA": "PHF",
      "Airport Name": "Newport News Williamsburg Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-76.49299622",
      "Location": "  - Newport News",
      "Longitude": "37.13190079"
  },
  {
      "Airport Code iATA,FAA": "TUS",
      "Airport Name": "Tucson Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-7",
      "Latitude": "-110.94100189208984",
      "Location": "  - Tucson",
      "Longitude": "32.1161003112793"
  },
  {
      "Airport Code iATA,FAA": "TPA",
      "Airport Name": "Tampa Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-82.533203125",
      "Location": "  - Tampa",
      "Longitude": "27.975500106811523"
  },
  {
      "Airport Code iATA,FAA": "MAF",
      "Airport Name": "Midland Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-102.2020034790039",
      "Location": "  - Midland",
      "Longitude": "31.9424991607666"
  },
  {
      "Airport Code iATA,FAA": "GRB",
      "Airport Name": "Austin Straubel Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-88.12960052490234",
      "Location": "  - Green Bay",
      "Longitude": "44.48509979248047"
  },
  {
      "Airport Code iATA,FAA": "ISN",
      "Airport Name": "Sloulin Field Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-103.641998291",
      "Location": "  - Williston",
      "Longitude": "48.177898407"
  },
  {
      "Airport Code iATA,FAA": "SWF",
      "Airport Name": "Stewart Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-74.10479736328125",
      "Location": "  - Newburgh",
      "Longitude": "41.50410079956055"
  },
  {
      "Airport Code iATA,FAA": "BDE",
      "Airport Name": "Baudette Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-94.612197876",
      "Location": "  - Baudette",
      "Longitude": "48.7284011841"
  },
  {
      "Airport Code iATA,FAA": "DFW",
      "Airport Name": "Dallas Fort Worth Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-97.038002",
      "Location": "  - Dallas-Fort Worth",
      "Longitude": "32.896801"
  },
  {
      "Airport Code iATA,FAA": "MLB",
      "Airport Name": "Melbourne Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-80.64530181884766",
      "Location": "  - Melbourne",
      "Longitude": "28.102800369262695"
  },
  {
      "Airport Code iATA,FAA": "AUS",
      "Airport Name": "Austin Bergstrom Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-97.6698989868164",
      "Location": "  - Austin",
      "Longitude": "30.194499969482422"
  },
  {
      "Airport Code iATA,FAA": "LCK",
      "Airport Name": "Rickenbacker Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-82.927803",
      "Location": "  - Columbus",
      "Longitude": "39.813801"
  },
  {
      "Airport Code iATA,FAA": "MQT",
      "Airport Name": "Sawyer Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-87.395401001",
      "Location": "  - Gwinn",
      "Longitude": "46.353599548300004"
  },
  {
      "Airport Code iATA,FAA": "STL",
      "Airport Name": "St Louis Lambert Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-90.370003",
      "Location": "  - St. Louis",
      "Longitude": "38.748697"
  },
  {
      "Airport Code iATA,FAA": "ATL",
      "Airport Name": "Hartsfield Jackson Atlanta Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-84.428101",
      "Location": "  - Atlanta",
      "Longitude": "33.6367"
  },
  {
      "Airport Code iATA,FAA": "GRR",
      "Airport Name": "Gerald R. Ford Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-85.52279663",
      "Location": "  - Grand Rapids",
      "Longitude": "42.88079834"
  },
  {
      "Airport Code iATA,FAA": "FAT",
      "Airport Name": "Fresno Yosemite Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-8",
      "Latitude": "-119.71800231933594",
      "Location": "  - Fresno",
      "Longitude": "36.77619934082031"
  },
  {
      "Airport Code iATA,FAA": "BNA",
      "Airport Name": "Nashville Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-86.6781997680664",
      "Location": "  - Nashville",
      "Longitude": "36.1245002746582"
  },
  {
      "Airport Code iATA,FAA": "LRD",
      "Airport Name": "Laredo Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-99.46160125732422",
      "Location": "  - Laredo",
      "Longitude": "27.543800354003906"
  },
  {
      "Airport Code iATA,FAA": "CXL",
      "Airport Name": "Calexico Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-8",
      "Latitude": "-115.513000488",
      "Location": "  - Calexico",
      "Longitude": "32.6694984436"
  },
  {
      "Airport Code iATA,FAA": "BTV",
      "Airport Name": "Burlington Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-73.15329742429999",
      "Location": "  - Burlington",
      "Longitude": "44.471900939899996"
  },
  {
      "Airport Code iATA,FAA": "JAX",
      "Airport Name": "Jacksonville Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-81.68789672851562",
      "Location": "  - Jacksonville",
      "Longitude": "30.49410057067871"
  },
  {
      "Airport Code iATA,FAA": "IAD",
      "Airport Name": "Washington Dulles Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-77.45580292",
      "Location": "  - Washington",
      "Longitude": "38.94449997"
  },
  {
      "Airport Code iATA,FAA": "MKE",
      "Airport Name": "General Mitchell Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-87.89659881591797",
      "Location": "  - Milwaukee",
      "Longitude": "42.947200775146484"
  },
  {
      "Airport Code iATA,FAA": "PDX",
      "Airport Name": "Portland Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-8",
      "Latitude": "-122.5979996",
      "Location": "  - Portland",
      "Longitude": "45.58869934"
  },
  {
      "Airport Code iATA,FAA": "PBI",
      "Airport Name": "Palm Beach Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-80.09559631347656",
      "Location": "  - West Palm Beach",
      "Longitude": "26.68320083618164"
  },
  {
      "Airport Code iATA,FAA": "FTW",
      "Airport Name": "Fort Worth Meacham Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-97.362396",
      "Location": "  - Fort Worth",
      "Longitude": "32.819801"
  },
  {
      "Airport Code iATA,FAA": "OGS",
      "Airport Name": "Ogdensburg Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-75.46549987790002",
      "Location": "  - Ogdensburg",
      "Longitude": "44.6819000244"
  },
  {
      "Airport Code iATA,FAA": "BFI",
      "Airport Name": "Boeing Field King County Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-8",
      "Latitude": "-122.302001953125",
      "Location": "  - Seattle",
      "Longitude": "47.529998779296875"
  },
  {
      "Airport Code iATA,FAA": "HNL",
      "Airport Name": "Daniel K Inouye Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-10",
      "Latitude": "-157.924228",
      "Location": "  - Honolulu",
      "Longitude": "21.32062"
  },
  {
      "Airport Code iATA,FAA": "DSM",
      "Airport Name": "Des Moines Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-93.66310119628906",
      "Location": "  - Des Moines",
      "Longitude": "41.534000396728516"
  },
  {
      "Airport Code iATA,FAA": "SAN",
      "Airport Name": "San Diego Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-8",
      "Latitude": "-117.190002441",
      "Location": "  - San Diego",
      "Longitude": "32.7336006165"
  },
  {
      "Airport Code iATA,FAA": "ONT",
      "Airport Name": "Ontario Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-8",
      "Latitude": "-117.60099792480469",
      "Location": "  - Ontario",
      "Longitude": "34.055999755859375"
  },
  {
      "Airport Code iATA,FAA": "ROW",
      "Airport Name": "Roswell Int'l Air Center Airport",
      "Country": "United States",
      "GMT Offset": "-7",
      "Latitude": "-104.53099822998047",
      "Location": "  - Roswell",
      "Longitude": "33.30160140991211"
  },
  {
      "Airport Code iATA,FAA": "BRO",
      "Airport Name": "Brownsville South Padre Island Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-97.4259033203125",
      "Location": "  - Brownsville",
      "Longitude": "25.90679931640625"
  },
  {
      "Airport Code iATA,FAA": "CRP",
      "Airport Name": "Corpus Christi Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-97.5011978149414",
      "Location": "  - Corpus Christi",
      "Longitude": "27.77039909362793"
  },
  {
      "Airport Code iATA,FAA": "SYR",
      "Airport Name": "Syracuse Hancock Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-76.1063003540039",
      "Location": "  - Syracuse",
      "Longitude": "43.11119842529297"
  },
  {
      "Airport Code iATA,FAA": "MDW",
      "Airport Name": "Chicago Midway Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-87.752403",
      "Location": "  - Chicago",
      "Longitude": "41.785999"
  },
  {
      "Airport Code iATA,FAA": "SJC",
      "Airport Name": "Norman Y. Mineta San Jose Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-8",
      "Latitude": "-121.929001",
      "Location": "  - San Jose",
      "Longitude": "37.362598"
  },
  {
      "Airport Code iATA,FAA": "DEN",
      "Airport Name": "Denver Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-7",
      "Latitude": "-104.672996521",
      "Location": "  - Denver",
      "Longitude": "39.861698150635"
  },
  {
      "Airport Code iATA,FAA": "PHL",
      "Airport Name": "Philadelphia Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-75.24109649658203",
      "Location": "  - Philadelphia",
      "Longitude": "39.87189865112305"
  },
  {
      "Airport Code iATA,FAA": "CMH",
      "Airport Name": "John Glenn Columbus Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-82.891899",
      "Location": "  - Columbus",
      "Longitude": "39.998001"
  },
  {
      "Airport Code iATA,FAA": "PBG",
      "Airport Name": "Plattsburgh Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-73.46810150146484",
      "Location": "  - Plattsburgh",
      "Longitude": "44.650901794433594"
  },
  {
      "Airport Code iATA,FAA": "ANC",
      "Airport Name": "Ted Stevens Anchorage Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-9",
      "Latitude": "-149.99600219726562",
      "Location": "  - Anchorage",
      "Longitude": "61.174400329589844"
  },
  {
      "Airport Code iATA,FAA": "BLI",
      "Airport Name": "Bellingham Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-8",
      "Latitude": "-122.53800201416016",
      "Location": "  - Bellingham",
      "Longitude": "48.79280090332031"
  },
  {
      "Airport Code iATA,FAA": "OLS",
      "Airport Name": "Nogales Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-7",
      "Latitude": "-110.848",
      "Location": "  - Nogales",
      "Longitude": "31.4177"
  },
  {
      "Airport Code iATA,FAA": "DRT",
      "Airport Name": "Del Rio Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-100.927001953",
      "Location": "  - Del Rio",
      "Longitude": "29.3742008209"
  },
  {
      "Airport Code iATA,FAA": "RSW",
      "Airport Name": "Southwest Florida Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-81.75520324707031",
      "Location": "  - Fort Myers",
      "Longitude": "26.53619956970215"
  },
  {
      "Airport Code iATA,FAA": "JFK",
      "Airport Name": "John F Kennedy Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-73.77890015",
      "Location": "  - New York",
      "Longitude": "40.63980103"
  },
  {
      "Airport Code iATA,FAA": "RNO",
      "Airport Name": "Reno Tahoe Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-8",
      "Latitude": "-119.76799774169922",
      "Location": "  - Reno",
      "Longitude": "39.49909973144531"
  },
  {
      "Airport Code iATA,FAA": "KTN",
      "Airport Name": "Ketchikan Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-9",
      "Latitude": "-131.7140045",
      "Location": "  - Ketchikan",
      "Longitude": "55.35559845"
  },
  {
      "Airport Code iATA,FAA": "BHM",
      "Airport Name": "Birmingham-Shuttlesworth Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-86.75350189",
      "Location": "  - Birmingham",
      "Longitude": "33.56290054"
  },
  {
      "Airport Code iATA,FAA": "SMF",
      "Airport Name": "Sacramento Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-8",
      "Latitude": "-121.59100341796875",
      "Location": "  - Sacramento",
      "Longitude": "38.69540023803711"
  },
  {
      "Airport Code iATA,FAA": "BUF",
      "Airport Name": "Buffalo Niagara Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-78.73220062",
      "Location": "  - Buffalo",
      "Longitude": "42.94049835"
  },
  {
      "Airport Code iATA,FAA": "BDL",
      "Airport Name": "Bradley Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-72.68319702149999",
      "Location": "  - Windsor Locks",
      "Longitude": "41.9388999939"
  },
  {
      "Airport Code iATA,FAA": "MFE",
      "Airport Name": "Mc Allen Miller Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-98.23860168",
      "Location": "  - Mcallen",
      "Longitude": "26.17580032"
  },
  {
      "Airport Code iATA,FAA": "LBB",
      "Airport Name": "Lubbock Preston Smith Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-101.822998",
      "Location": "  - Lubbock",
      "Longitude": "33.663601"
  },
  {
      "Airport Code iATA,FAA": "ORD",
      "Airport Name": "Chicago O'Hare Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-87.9048",
      "Location": "  - Chicago",
      "Longitude": "41.9786"
  },
  {
      "Airport Code iATA,FAA": "FAI",
      "Airport Name": "Fairbanks Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-9",
      "Latitude": "-147.8560028",
      "Location": "  - Fairbanks",
      "Longitude": "64.81510162"
  },
  {
      "Airport Code iATA,FAA": "ART",
      "Airport Name": "Watertown Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-76.02169799804688",
      "Location": "  - Watertown",
      "Longitude": "43.99190139770508"
  },
  {
      "Airport Code iATA,FAA": "PSP",
      "Airport Name": "Palm Springs Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-8",
      "Latitude": "-116.50700378417969",
      "Location": "  - Palm Springs",
      "Longitude": "33.8297004699707"
  },
  {
      "Airport Code iATA,FAA": "AMA",
      "Airport Name": "Rick Husband Amarillo Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-101.706001",
      "Location": "  - Amarillo",
      "Longitude": "35.219398"
  },
  {
      "Airport Code iATA,FAA": "ILM",
      "Airport Name": "Wilmington Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-77.90260314941406",
      "Location": "  - Wilmington",
      "Longitude": "34.270599365234375"
  },
  {
      "Airport Code iATA,FAA": "BWI",
      "Airport Name": "Baltimore/Washington Int'l Thurgood Marshall Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-76.668297",
      "Location": "  - Baltimore",
      "Longitude": "39.1754"
  },
  {
      "Airport Code iATA,FAA": "AEX",
      "Airport Name": "Alexandria Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-92.54979705810547",
      "Location": "  - Alexandria",
      "Longitude": "31.32740020751953"
  },
  {
      "Airport Code iATA,FAA": "TUL",
      "Airport Name": "Tulsa Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-95.88809967041016",
      "Location": "  - Tulsa",
      "Longitude": "36.19839859008789"
  },
  {
      "Airport Code iATA,FAA": "MSP",
      "Airport Name": "Minneapolis-St Paul Int'l/Wold-Chamberlain Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-93.221802",
      "Location": "  - Minneapolis",
      "Longitude": "44.882"
  },
  {
      "Airport Code iATA,FAA": "MSY",
      "Airport Name": "Louis Armstrong New Orleans Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-90.25800323486328",
      "Location": "  - New Orleans",
      "Longitude": "29.99340057373047"
  },
  {
      "Airport Code iATA,FAA": "PWM",
      "Airport Name": "Portland Int'l Jetport Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-70.30930328",
      "Location": "  - Portland",
      "Longitude": "43.64619827"
  },
  {
      "Airport Code iATA,FAA": "ALB",
      "Airport Name": "Albany Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-73.80169677734375",
      "Location": "  - Albany",
      "Longitude": "42.74829864501953"
  },
  {
      "Airport Code iATA,FAA": "CPR",
      "Airport Name": "Casper-Natrona County Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-7",
      "Latitude": "-106.463997",
      "Location": "  - Casper",
      "Longitude": "42.908001"
  },
  {
      "Airport Code iATA,FAA": "EYW",
      "Airport Name": "Key West Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-81.75959777832031",
      "Location": "  - Key West",
      "Longitude": "24.556100845336914"
  },
  {
      "Airport Code iATA,FAA": "CLT",
      "Airport Name": "Charlotte Douglas Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-80.94309997558594",
      "Location": "  - Charlotte",
      "Longitude": "35.2140007019043"
  },
  {
      "Airport Code iATA,FAA": "LAS",
      "Airport Name": "McCarran Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-8",
      "Latitude": "-115.1520004",
      "Location": "  - Las Vegas",
      "Longitude": "36.08010101"
  },
  {
      "Airport Code iATA,FAA": "MCO",
      "Airport Name": "Orlando Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-81.30899810791016",
      "Location": "  - Orlando",
      "Longitude": "28.429399490356445"
  },
  {
      "Airport Code iATA,FAA": "GTF",
      "Airport Name": "Great Falls Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-7",
      "Latitude": "-111.3710022",
      "Location": "  - Great Falls",
      "Longitude": "47.48199844"
  },
  {
      "Airport Code iATA,FAA": "BDO",
      "Airport Name": "Husein Sastranegara Int'l Airport",
      "Country": "Indonesia",
      "GMT Offset": "7",
      "Latitude": "107.57599639892578",
      "Location": "  - Bandung",
      "Longitude": "-6.900629997253418"
  },
  {
      "Airport Code iATA,FAA": "JOG",
      "Airport Name": "Adi Sutjipto Int'l Airport",
      "Country": "Indonesia",
      "GMT Offset": "7",
      "Latitude": "110.43199920654297",
      "Location": "  - Yogyakarta",
      "Longitude": "-7.788179874420166"
  },
  {
      "Airport Code iATA,FAA": "BTH",
      "Airport Name": "Hang Nadim Int'l Airport",
      "Country": "Indonesia",
      "GMT Offset": "7",
      "Latitude": "104.119003296",
      "Location": "  - Batam",
      "Longitude": "1.12102997303"
  },
  {
      "Airport Code iATA,FAA": "TNJ",
      "Airport Name": "Raja Haji Fisabilillah Int'l Airport",
      "Country": "Indonesia",
      "GMT Offset": "7",
      "Latitude": "104.531997681",
      "Location": "  - Tanjung Pinang",
      "Longitude": "0.922683000565"
  },
  {
      "Airport Code iATA,FAA": "SUB",
      "Airport Name": "Juanda Int'l Airport",
      "Country": "Indonesia",
      "GMT Offset": "7",
      "Latitude": "112.78700256347656",
      "Location": "  - Surabaya",
      "Longitude": "-7.3798298835754395"
  },
  {
      "Airport Code iATA,FAA": "ICN",
      "Airport Name": "Incheon Int'l Airport",
      "Country": "South Korea",
      "GMT Offset": "9",
      "Latitude": "126.45099639892578",
      "Location": "  - Seoul",
      "Longitude": "37.46910095214844"
  },
  {
      "Airport Code iATA,FAA": "CNX",
      "Airport Name": "Chiang Mai Int'l Airport",
      "Country": "Thailand",
      "GMT Offset": "7",
      "Latitude": "98.962600708",
      "Location": "  - Chiang Mai",
      "Longitude": "18.766799926799997"
  },
  {
      "Airport Code iATA,FAA": "CEI",
      "Airport Name": "Chiang Rai Int'l Airport",
      "Country": "Thailand",
      "GMT Offset": "7",
      "Latitude": "99.88289642330001",
      "Location": "  - Chiang Rai",
      "Longitude": "19.952299118"
  },
  {
      "Airport Code iATA,FAA": "DPS",
      "Airport Name": "Ngurah Rai (Bali) Int'l Airport",
      "Country": "Indonesia",
      "GMT Offset": "8",
      "Latitude": "115.16699981689",
      "Location": "  - Denpasar",
      "Longitude": "-8.7481698989868"
  },
  {
      "Airport Code iATA,FAA": "ATH",
      "Airport Name": "Eleftherios Venizelos Int'l Airport",
      "Country": "Greece",
      "GMT Offset": "2",
      "Latitude": "23.9444999695",
      "Location": "  - Athens",
      "Longitude": "37.9364013672"
  },
  {
      "Airport Code iATA,FAA": "NGO",
      "Airport Name": "Chubu Centrair Int'l Airport",
      "Country": "Japan",
      "GMT Offset": "9",
      "Latitude": "136.80499267578125",
      "Location": "  - Nagoya",
      "Longitude": "34.8583984375"
  },
  {
      "Airport Code iATA,FAA": "DAB",
      "Airport Name": "Daytona Beach Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-81.058098",
      "Location": "  - Daytona Beach",
      "Longitude": "29.179899"
  },
  {
      "Airport Code iATA,FAA": "LPX",
      "Airport Name": "Liepāja Int'l Airport",
      "Country": "Latvia",
      "GMT Offset": "2",
      "Latitude": "21.096900939941406",
      "Location": "  - Liepaja",
      "Longitude": "56.51750183105469"
  },
  {
      "Airport Code iATA,FAA": "RIX",
      "Airport Name": "Riga Int'l Airport",
      "Country": "Latvia",
      "GMT Offset": "2",
      "Latitude": "23.971099853515625",
      "Location": "  - Riga",
      "Longitude": "56.92359924316406"
  },
  {
      "Airport Code iATA,FAA": "SQQ",
      "Airport Name": "Šiauliai Int'l Airport",
      "Country": "Lithuania",
      "GMT Offset": "2",
      "Latitude": "23.395000457763672",
      "Location": "  - Siauliai",
      "Longitude": "55.89390182495117"
  },
  {
      "Airport Code iATA,FAA": "KUN",
      "Airport Name": "Kaunas Int'l Airport",
      "Country": "Lithuania",
      "GMT Offset": "2",
      "Latitude": "24.084800720214844",
      "Location": "  - Kaunas",
      "Longitude": "54.96390151977539"
  },
  {
      "Airport Code iATA,FAA": "PLQ",
      "Airport Name": "Palanga Int'l Airport",
      "Country": "Lithuania",
      "GMT Offset": "2",
      "Latitude": "21.093900680541992",
      "Location": "  - Palanga",
      "Longitude": "55.973201751708984"
  },
  {
      "Airport Code iATA,FAA": "VNO",
      "Airport Name": "Vilnius Int'l Airport",
      "Country": "Lithuania",
      "GMT Offset": "2",
      "Latitude": "25.285801",
      "Location": "  - Vilnius",
      "Longitude": "54.634102"
  },
  {
      "Airport Code iATA,FAA": "EVN",
      "Airport Name": "Zvartnots Int'l Airport",
      "Country": "Armenia",
      "GMT Offset": "4",
      "Latitude": "44.3959007263",
      "Location": "  - Yerevan",
      "Longitude": "40.1473007202"
  },
  {
      "Airport Code iATA,FAA": "ASA",
      "Airport Name": "Assab Int'l Airport",
      "Country": "Eritrea",
      "GMT Offset": "3",
      "Latitude": "42.64500045776367",
      "Location": "  - Assab",
      "Longitude": "13.071800231933594"
  },
  {
      "Airport Code iATA,FAA": "ASM",
      "Airport Name": "Asmara Int'l Airport",
      "Country": "Eritrea",
      "GMT Offset": "3",
      "Latitude": "38.910701751708984",
      "Location": "  - Asmara",
      "Longitude": "15.291899681091309"
  },
  {
      "Airport Code iATA,FAA": "MSW",
      "Airport Name": "Massawa Int'l Airport",
      "Country": "Eritrea",
      "GMT Offset": "3",
      "Latitude": "39.37009811401367",
      "Location": "  - Massawa",
      "Longitude": "15.670000076293945"
  },
  {
      "Airport Code iATA,FAA": "GZA",
      "Airport Name": "Yasser Arafat Int'l Airport",
      "Country": "Palestine",
      "GMT Offset": "2",
      "Latitude": "34.276100158691",
      "Location": "  - Gaza",
      "Longitude": "31.24640083313"
  },
  {
      "Airport Code iATA,FAA": "BUS",
      "Airport Name": "Batumi Int'l Airport",
      "Country": "Georgia",
      "GMT Offset": "4",
      "Latitude": "41.5997009277",
      "Location": "  - Batumi",
      "Longitude": "41.6102981567"
  },
  {
      "Airport Code iATA,FAA": "TBS",
      "Airport Name": "Tbilisi Int'l Airport",
      "Country": "Georgia",
      "GMT Offset": "4",
      "Latitude": "44.95470047",
      "Location": "  - Tbilisi",
      "Longitude": "41.6692008972"
  },
  {
      "Airport Code iATA,FAA": "RIY",
      "Airport Name": "Mukalla Int'l Airport",
      "Country": "Yemen",
      "GMT Offset": "3",
      "Latitude": "49.375",
      "Location": "  - Mukalla",
      "Longitude": "14.662599563598633"
  },
  {
      "Airport Code iATA,FAA": "TAI",
      "Airport Name": "Ta'izz Int'l Airport",
      "Country": "Yemen",
      "GMT Offset": "3",
      "Latitude": "44.139099121099996",
      "Location": "  - Taiz",
      "Longitude": "13.6859998703"
  },
  {
      "Airport Code iATA,FAA": "HOD",
      "Airport Name": "Hodeidah Int'l Airport",
      "Country": "Yemen",
      "GMT Offset": "3",
      "Latitude": "42.97629928588867",
      "Location": "  - Hodeidah",
      "Longitude": "14.753000259399414"
  },
  {
      "Airport Code iATA,FAA": "ADE",
      "Airport Name": "Aden Int'l Airport",
      "Country": "Yemen",
      "GMT Offset": "3",
      "Latitude": "45.02880096435547",
      "Location": "  - Aden",
      "Longitude": "12.829500198364258"
  },
  {
      "Airport Code iATA,FAA": "AAY",
      "Airport Name": "Al Ghaidah Int'l Airport",
      "Country": "Yemen",
      "GMT Offset": "3",
      "Latitude": "52.17499923706055",
      "Location": "  - Al Ghaidah Intl",
      "Longitude": "16.191699981689453"
  },
  {
      "Airport Code iATA,FAA": "SAH",
      "Airport Name": "Sana'a Int'l Airport",
      "Country": "Yemen",
      "GMT Offset": "3",
      "Latitude": "44.21969985961914",
      "Location": "  - Sanaa",
      "Longitude": "15.476300239562988"
  },
  {
      "Airport Code iATA,FAA": "SCT",
      "Airport Name": "Socotra Int'l Airport",
      "Country": "Yemen",
      "GMT Offset": "3",
      "Latitude": "53.905799865722656",
      "Location": "  - Socotra",
      "Longitude": "12.63070011138916"
  },
  {
      "Airport Code iATA,FAA": "EZE",
      "Airport Name": "Ministro Pistarini Int'l Airport",
      "Country": "Argentina",
      "GMT Offset": "-3",
      "Latitude": "-58.5358",
      "Location": "  - Buenos Aires",
      "Longitude": "-34.8222"
  },
  {
      "Airport Code iATA,FAA": "EBL",
      "Airport Name": "Erbil Int'l Airport",
      "Country": "Iraq",
      "GMT Offset": "3",
      "Latitude": "43.963199615478516",
      "Location": "  - Erbil",
      "Longitude": "36.23759841918945"
  },
  {
      "Airport Code iATA,FAA": "KIX",
      "Airport Name": "Kansai Int'l Airport",
      "Country": "Japan",
      "GMT Offset": "9",
      "Latitude": "135.24400329589844",
      "Location": "  - Osaka",
      "Longitude": "34.42729949951172"
  },
  {
      "Airport Code iATA,FAA": "DRW",
      "Airport Name": "Darwin Int'l Airport",
      "Country": "Australia",
      "GMT Offset": "9.5",
      "Latitude": "130.8769989013672",
      "Location": "  - Darwin",
      "Longitude": "-12.41469955444336"
  },
  {
      "Airport Code iATA,FAA": "GSO",
      "Airport Name": "Piedmont Triad Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-79.93730163574219",
      "Location": "  - Greensboro",
      "Longitude": "36.097801208496094"
  },
  {
      "Airport Code iATA,FAA": "SDF",
      "Airport Name": "Louisville Int'l Standiford Field",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-85.736",
      "Location": "  - Louisville",
      "Longitude": "38.1744"
  },
  {
      "Airport Code iATA,FAA": "ABQ",
      "Airport Name": "Albuquerque Int'l Sunport",
      "Country": "United States",
      "GMT Offset": "-7",
      "Latitude": "-106.609001",
      "Location": "  - Albuquerque",
      "Longitude": "35.040199"
  },
  {
      "Airport Code iATA,FAA": "BIL",
      "Airport Name": "Billings Logan Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-7",
      "Latitude": "-108.54299926757812",
      "Location": "  - Billings",
      "Longitude": "45.807701110839844"
  },
  {
      "Airport Code iATA,FAA": "FRS",
      "Airport Name": "Mundo Maya Int'l Airport",
      "Country": "Guatemala",
      "GMT Offset": "-6",
      "Latitude": "-89.86640167239999",
      "Location": "  - Flores",
      "Longitude": "16.913799285899998"
  },
  {
      "Airport Code iATA,FAA": "RFD",
      "Airport Name": "Chicago Rockford Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-89.09719848632812",
      "Location": "  - Rockford",
      "Longitude": "42.19540023803711"
  },
  {
      "Airport Code iATA,FAA": "DME",
      "Airport Name": "Domodedovo Int'l Airport",
      "Country": "Russia",
      "GMT Offset": "3",
      "Latitude": "37.90629959106445",
      "Location": "  - Moscow",
      "Longitude": "55.40879821777344"
  },
  {
      "Airport Code iATA,FAA": "SYX",
      "Airport Name": "Sanya Phoenix Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "109.41200256347656",
      "Location": "  - Sanya",
      "Longitude": "18.302900314331055"
  },
  {
      "Airport Code iATA,FAA": "GSP",
      "Airport Name": "Greenville Spartanburg Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-82.2189025879",
      "Location": "  - Greenville",
      "Longitude": "34.8956985474"
  },
  {
      "Airport Code iATA,FAA": "GPT",
      "Airport Name": "Gulfport Biloxi Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-89.07009887695312",
      "Location": "  - Gulfport",
      "Longitude": "30.40730094909668"
  },
  {
      "Airport Code iATA,FAA": "AZO",
      "Airport Name": "Kalamazoo Battle Creek Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-85.5521011352539",
      "Location": "  - Kalamazoo",
      "Longitude": "42.234901428222656"
  },
  {
      "Airport Code iATA,FAA": "FWA",
      "Airport Name": "Fort Wayne Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-85.19509888",
      "Location": "  - Fort Wayne",
      "Longitude": "40.97850037"
  },
  {
      "Airport Code iATA,FAA": "PIA",
      "Airport Name": "General Wayne A. Downing Peoria Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-89.6932983398",
      "Location": "  - Peoria",
      "Longitude": "40.664199829100006"
  },
  {
      "Airport Code iATA,FAA": "ATW",
      "Airport Name": "Appleton Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-88.5190963745",
      "Location": "  - Appleton",
      "Longitude": "44.258098602299995"
  },
  {
      "Airport Code iATA,FAA": "RST",
      "Airport Name": "Rochester Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-92.5",
      "Location": "  - Rochester",
      "Longitude": "43.90829849243164"
  },
  {
      "Airport Code iATA,FAA": "SSH",
      "Airport Name": "Sharm El Sheikh Int'l Airport",
      "Country": "Egypt",
      "GMT Offset": "2",
      "Latitude": "34.3950004578",
      "Location": "  - Sharm El Sheikh",
      "Longitude": "27.9773006439"
  },
  {
      "Airport Code iATA,FAA": "NBO",
      "Airport Name": "Jomo Kenyatta Int'l Airport",
      "Country": "Kenya",
      "GMT Offset": "3",
      "Latitude": "36.9277992249",
      "Location": "  - Nairobi",
      "Longitude": "-1.31923997402"
  },
  {
      "Airport Code iATA,FAA": "SRQ",
      "Airport Name": "Sarasota Bradenton Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-82.55439758300781",
      "Location": "  - Sarasota",
      "Longitude": "27.39539909362793"
  },
  {
      "Airport Code iATA,FAA": "BDA",
      "Airport Name": "L.F. Wade Int'l Int'l Airport",
      "Country": "Bermuda",
      "GMT Offset": "-4",
      "Latitude": "-64.67870330810547",
      "Location": "  - Bermuda",
      "Longitude": "32.36399841308594"
  },
  {
      "Airport Code iATA,FAA": "MLI",
      "Airport Name": "Quad City Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-90.50749969482422",
      "Location": "  - Moline",
      "Longitude": "41.44850158691406"
  },
  {
      "Airport Code iATA,FAA": "PFN",
      "Airport Name": "Panama City-Bay Co Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-85.6828",
      "Location": "  - Panama City",
      "Longitude": "30.212099"
  },
  {
      "Airport Code iATA,FAA": "HIR",
      "Airport Name": "Honiara Int'l Airport",
      "Country": "Solomon Islands",
      "GMT Offset": "11",
      "Latitude": "160.05499267578",
      "Location": "  - Honiara",
      "Longitude": "-9.4280004501343"
  },
  {
      "Airport Code iATA,FAA": "PPT",
      "Airport Name": "Faa'a Int'l Airport",
      "Country": "French Polynesia",
      "GMT Offset": "-10",
      "Latitude": "-149.606995",
      "Location": "  - Papeete",
      "Longitude": "-17.553699"
  },
  {
      "Airport Code iATA,FAA": "INU",
      "Airport Name": "Nauru Int'l Airport",
      "Country": "Nauru",
      "GMT Offset": "12",
      "Latitude": "166.919006",
      "Location": "  - Nauru",
      "Longitude": "-0.547458"
  },
  {
      "Airport Code iATA,FAA": "FUN",
      "Airport Name": "Funafuti Int'l Airport",
      "Country": "Tuvalu",
      "GMT Offset": "12",
      "Latitude": "179.195999",
      "Location": "  - Funafuti",
      "Longitude": "-8.525"
  },
  {
      "Airport Code iATA,FAA": "FNT",
      "Airport Name": "Bishop Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-83.74359893798828",
      "Location": "  - Flint",
      "Longitude": "42.96540069580078"
  },
  {
      "Airport Code iATA,FAA": "DVO",
      "Airport Name": "Francisco Bangoy Int'l Airport",
      "Country": "Philippines",
      "GMT Offset": "8",
      "Latitude": "125.64600372314453",
      "Location": "  - Davao",
      "Longitude": "7.1255202293396"
  },
  {
      "Airport Code iATA,FAA": "KOS",
      "Airport Name": "Sihanoukville Int'l Airport",
      "Country": "Cambodia",
      "GMT Offset": "7",
      "Latitude": "103.637001038",
      "Location": "  - Sihanoukville",
      "Longitude": "10.57970047"
  },
  {
      "Airport Code iATA,FAA": "MFR",
      "Airport Name": "Rogue Valley Int'l Medford Airport",
      "Country": "United States",
      "GMT Offset": "-8",
      "Latitude": "-122.87300109863281",
      "Location": "  - Medford",
      "Longitude": "42.37419891357422"
  },
  {
      "Airport Code iATA,FAA": "WDH",
      "Airport Name": "Hosea Kutako Int'l Airport",
      "Country": "Namibia",
      "GMT Offset": "1",
      "Latitude": "17.4709",
      "Location": "  - Windhoek",
      "Longitude": "-22.4799"
  },
  {
      "Airport Code iATA,FAA": "TJM",
      "Airport Name": "Roshchino Int'l Airport",
      "Country": "Russia",
      "GMT Offset": "5",
      "Latitude": "65.3243026733",
      "Location": "  - Tyumen",
      "Longitude": "57.189601898199996"
  },
  {
      "Airport Code iATA,FAA": "HSV",
      "Airport Name": "Huntsville Int'l Carl T Jones Field",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-86.775100708008",
      "Location": "  - Huntsville",
      "Longitude": "34.637199401855"
  },
  {
      "Airport Code iATA,FAA": "HAK",
      "Airport Name": "Haikou Meilan Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "110.45899963378906",
      "Location": "  - Haikou",
      "Longitude": "19.934900283813477"
  },
  {
      "Airport Code iATA,FAA": "FCA",
      "Airport Name": "Glacier Park Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-7",
      "Latitude": "-114.25599670410156",
      "Location": "  - Kalispell",
      "Longitude": "48.31050109863281"
  },
  {
      "Airport Code iATA,FAA": "MBS",
      "Airport Name": "MBS Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-84.07959747314453",
      "Location": "  - Saginaw",
      "Longitude": "43.532901763916016"
  },
  {
      "Airport Code iATA,FAA": "BGW",
      "Airport Name": "Baghdad Int'l Airport",
      "Country": "Iraq",
      "GMT Offset": "3",
      "Latitude": "44.2346000671",
      "Location": "  - Baghdad",
      "Longitude": "33.262500762900004"
  },
  {
      "Airport Code iATA,FAA": "CRK",
      "Airport Name": "Diosdado Macapagal Int'l Airport",
      "Country": "Philippines",
      "GMT Offset": "8",
      "Latitude": "120.559998",
      "Location": "  - Angeles City",
      "Longitude": "15.186"
  },
  {
      "Airport Code iATA,FAA": "VCL",
      "Airport Name": "Chu Lai Int'l Airport",
      "Country": "Vietnam",
      "GMT Offset": "7",
      "Latitude": "108.706001282",
      "Location": "  - Chu Lai",
      "Longitude": "15.403300285299999"
  },
  {
      "Airport Code iATA,FAA": "NSI",
      "Airport Name": "Yaoundé Nsimalen Int'l Airport",
      "Country": "Cameroon",
      "GMT Offset": "1",
      "Latitude": "11.553299903869629",
      "Location": "  - Yaounde",
      "Longitude": "3.722559928894043"
  },
  {
      "Airport Code iATA,FAA": "CKY",
      "Airport Name": "Conakry Int'l Airport",
      "Country": "Guinea",
      "GMT Offset": "0",
      "Latitude": "-13.612",
      "Location": "  - Conakry",
      "Longitude": "9.57689"
  },
  {
      "Airport Code iATA,FAA": "SFB",
      "Airport Name": "Orlando Sanford Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-81.23750305175781",
      "Location": "  - Sanford",
      "Longitude": "28.777599334716797"
  },
  {
      "Airport Code iATA,FAA": "MWX",
      "Airport Name": "Muan Int'l Airport",
      "Country": "South Korea",
      "GMT Offset": "9",
      "Latitude": "126.382814",
      "Location": "  - Muan",
      "Longitude": "34.991406"
  },
  {
      "Airport Code iATA,FAA": "RMF",
      "Airport Name": "Marsa Alam Int'l Airport",
      "Country": "Egypt",
      "GMT Offset": "2",
      "Latitude": "34.5836982727",
      "Location": "  - Marsa Alam",
      "Longitude": "25.557100296"
  },
  {
      "Airport Code iATA,FAA": "LAO",
      "Airport Name": "Laoag Int'l Airport",
      "Country": "Philippines",
      "GMT Offset": "8",
      "Latitude": "120.53199768066406",
      "Location": "  - Laoag",
      "Longitude": "18.1781005859375"
  },
  {
      "Airport Code iATA,FAA": "LGP",
      "Airport Name": "Legazpi City Int'l Airport",
      "Country": "Philippines",
      "GMT Offset": "8",
      "Latitude": "123.735",
      "Location": "  - Legazpi",
      "Longitude": "13.1575"
  },
  {
      "Airport Code iATA,FAA": "CEB",
      "Airport Name": "Mactan Cebu Int'l Airport",
      "Country": "Philippines",
      "GMT Offset": "8",
      "Latitude": "123.97899627686",
      "Location": "  - Cebu",
      "Longitude": "10.307499885559"
  },
  {
      "Airport Code iATA,FAA": "MSO",
      "Airport Name": "Missoula Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-7",
      "Latitude": "-114.0910034",
      "Location": "  - Missoula",
      "Longitude": "46.91630173"
  },
  {
      "Airport Code iATA,FAA": "LRU",
      "Airport Name": "Las Cruces Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-7",
      "Latitude": "-106.9219970703125",
      "Location": "  - Las Cruces",
      "Longitude": "32.289398193359375"
  },
  {
      "Airport Code iATA,FAA": "GOJ",
      "Airport Name": "Nizhny Novgorod Strigino Int'l Airport",
      "Country": "Russia",
      "GMT Offset": "3",
      "Latitude": "43.784000396729",
      "Location": "  - Nizhniy Novgorod",
      "Longitude": "56.230098724365"
  },
  {
      "Airport Code iATA,FAA": "ERI",
      "Airport Name": "Erie Int'l Tom Ridge Field",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-80.1738667488",
      "Location": "  - Erie",
      "Longitude": "42.0831270134"
  },
  {
      "Airport Code iATA,FAA": "AVP",
      "Airport Name": "Wilkes Barre Scranton Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-75.72339630130001",
      "Location": "  - Scranton",
      "Longitude": "41.338500976599995"
  },
  {
      "Airport Code iATA,FAA": "FAR",
      "Airport Name": "Hector Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-96.81580352783203",
      "Location": "  - Fargo",
      "Longitude": "46.92070007324219"
  },
  {
      "Airport Code iATA,FAA": "SWA",
      "Airport Name": "Jieyang Chaoshan Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "116.5033",
      "Location": "  - Shantou",
      "Longitude": "23.552"
  },
  {
      "Airport Code iATA,FAA": "GEO",
      "Airport Name": "Cheddi Jagan Int'l Airport",
      "Country": "Guyana",
      "GMT Offset": "-4",
      "Latitude": "-58.25410079956055",
      "Location": "  - Georgetown",
      "Longitude": "6.498549938201904"
  },
  {
      "Airport Code iATA,FAA": "AGT",
      "Airport Name": "Guarani Int'l Airport",
      "Country": "Paraguay",
      "GMT Offset": "-4",
      "Latitude": "-54.842682",
      "Location": "  - Ciudad del Este",
      "Longitude": "-25.454516"
  },
  {
      "Airport Code iATA,FAA": "OGL",
      "Airport Name": "Eugene F. Correira Int'l Airport",
      "Country": "Guyana",
      "GMT Offset": "-4",
      "Latitude": "-58.1059",
      "Location": "  - Georgetown",
      "Longitude": "6.80628"
  },
  {
      "Airport Code iATA,FAA": "KAI",
      "Airport Name": "Kaieteur Int'l Airport",
      "Country": "Guyana",
      "GMT Offset": "-4",
      "Latitude": "-59.491481781",
      "Location": "  - Kaieteur",
      "Longitude": "5.17275476456"
  },
  {
      "Airport Code iATA,FAA": "TCP",
      "Airport Name": "Taba Int'l Airport",
      "Country": "Egypt",
      "GMT Offset": "2",
      "Latitude": "34.7780990601",
      "Location": "  - Taba",
      "Longitude": "29.587799072299998"
  },
  {
      "Airport Code iATA,FAA": "BJV",
      "Airport Name": "Milas Bodrum Int'l Airport",
      "Country": "Turkey",
      "GMT Offset": "3",
      "Latitude": "27.6643009186",
      "Location": "  - Bodrum",
      "Longitude": "37.25059890749999"
  },
  {
      "Airport Code iATA,FAA": "SAW",
      "Airport Name": "Sabiha Gökçen Int'l Airport",
      "Country": "Turkey",
      "GMT Offset": "3",
      "Latitude": "29.3092002869",
      "Location": "  - Istanbul",
      "Longitude": "40.898601532"
  },
  {
      "Airport Code iATA,FAA": "BME",
      "Airport Name": "Broome Int'l Airport",
      "Country": "Australia",
      "GMT Offset": "8",
      "Latitude": "122.23200225830078",
      "Location": "  - Broome",
      "Longitude": "-17.944700241088867"
  },
  {
      "Airport Code iATA,FAA": "IKA",
      "Airport Name": "Imam Khomeini Int'l Airport",
      "Country": "Iran",
      "GMT Offset": "3.5",
      "Latitude": "51.152198791503906",
      "Location": "  - Tehran",
      "Longitude": "35.416099548339844"
  },
  {
      "Airport Code iATA,FAA": "MHD",
      "Airport Name": "Mashhad Int'l Airport",
      "Country": "Iran",
      "GMT Offset": "3.5",
      "Latitude": "59.64099884033203",
      "Location": "  - Mashhad",
      "Longitude": "36.235198974609375"
  },
  {
      "Airport Code iATA,FAA": "ABE",
      "Airport Name": "Lehigh Valley Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-75.44080352783203",
      "Location": "  - Allentown",
      "Longitude": "40.652099609375"
  },
  {
      "Airport Code iATA,FAA": "KGP",
      "Airport Name": "Kogalym Int'l Airport",
      "Country": "Russia",
      "GMT Offset": "5",
      "Latitude": "74.53379821777344",
      "Location": "  - Kogalym",
      "Longitude": "62.190399169921875"
  },
  {
      "Airport Code iATA,FAA": "VNT",
      "Airport Name": "Ventspils Int'l Airport",
      "Country": "Latvia",
      "GMT Offset": "2",
      "Latitude": "21.5442008972",
      "Location": "  - Ventspils",
      "Longitude": "57.35779953"
  },
  {
      "Airport Code iATA,FAA": "MQP",
      "Airport Name": "Kruger Mpumalanga Int'l Airport",
      "Country": "South Africa",
      "GMT Offset": "2",
      "Latitude": "31.1056003571",
      "Location": "  - Mpumalanga",
      "Longitude": "-25.3831996918"
  },
  {
      "Airport Code iATA,FAA": "MBD",
      "Airport Name": "Mmabatho Int'l Airport",
      "Country": "South Africa",
      "GMT Offset": "2",
      "Latitude": "25.548000335699996",
      "Location": "  - Mafeking",
      "Longitude": "-25.798400878900004"
  },
  {
      "Airport Code iATA,FAA": "BTE",
      "Airport Name": "Sherbro Int'l Airport",
      "Country": "Sierra Leone",
      "GMT Offset": "0",
      "Latitude": "-12.518899917602539",
      "Location": "  - Bonthe",
      "Longitude": "7.5324201583862305"
  },
  {
      "Airport Code iATA,FAA": "OXB",
      "Airport Name": "Osvaldo Vieira Int'l Airport",
      "Country": "Guinea-Bissau",
      "GMT Offset": "0",
      "Latitude": "-15.65369987487793",
      "Location": "  - Bissau",
      "Longitude": "11.894800186157227"
  },
  {
      "Airport Code iATA,FAA": "NDR",
      "Airport Name": "Nador Int'l Airport",
      "Country": "Morocco",
      "GMT Offset": "0",
      "Latitude": "-3.0282099247",
      "Location": "  - El Aroui",
      "Longitude": "34.988800048799995"
  },
  {
      "Airport Code iATA,FAA": "RAI",
      "Airport Name": "Praia Int'l Airport",
      "Country": "Cape Verde",
      "GMT Offset": "-1",
      "Latitude": "-23.493499755859375",
      "Location": "  - Praia, Santiago Island",
      "Longitude": "14.924500465393066"
  },
  {
      "Airport Code iATA,FAA": "MGQ",
      "Airport Name": "Aden Adde Int'l Airport",
      "Country": "Somalia",
      "GMT Offset": "3",
      "Latitude": "45.3046989440918",
      "Location": "  - Mogadishu",
      "Longitude": "2.0144400596618652"
  },
  {
      "Airport Code iATA,FAA": "AAC",
      "Airport Name": "El Arish Int'l Airport",
      "Country": "Egypt",
      "GMT Offset": "2",
      "Latitude": "33.8358001709",
      "Location": "  - El Arish",
      "Longitude": "31.073299408"
  },
  {
      "Airport Code iATA,FAA": "ATZ",
      "Airport Name": "Assiut Int'l Airport",
      "Country": "Egypt",
      "GMT Offset": "2",
      "Latitude": "31.0119991302",
      "Location": "  - Asyut",
      "Longitude": "27.0464992523"
  },
  {
      "Airport Code iATA,FAA": "PZU",
      "Airport Name": "Port Sudan New Int'l Airport",
      "Country": "Sudan",
      "GMT Offset": "3",
      "Latitude": "37.234100341796875",
      "Location": "  - Port Sudan",
      "Longitude": "19.4335994720459"
  },
  {
      "Airport Code iATA,FAA": "CIU",
      "Airport Name": "Chippewa County Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-84.47239685058594",
      "Location": "  - Sault Ste Marie",
      "Longitude": "46.25080108642578"
  },
  {
      "Airport Code iATA,FAA": "CLM",
      "Airport Name": "William R Fairchild Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-8",
      "Latitude": "-123.5",
      "Location": "  - Port Angeles",
      "Longitude": "48.120201110839844"
  },
  {
      "Airport Code iATA,FAA": "GGW",
      "Airport Name": "Wokal Field Glasgow Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-7",
      "Latitude": "-106.614998",
      "Location": "  - Glasgow",
      "Longitude": "48.212502"
  },
  {
      "Airport Code iATA,FAA": "PSM",
      "Airport Name": "Portsmouth Int'l at Pease Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-70.8233032227",
      "Location": "  - Portsmouth",
      "Longitude": "43.0778999329"
  },
  {
      "Airport Code iATA,FAA": "ECN",
      "Airport Name": "Ercan Int'l Airport",
      "Country": "Cyprus",
      "GMT Offset": "2",
      "Latitude": "33.49610137939453",
      "Location": "  - Nicosia",
      "Longitude": "35.154701232910156"
  },
  {
      "Airport Code iATA,FAA": "\\N",
      "Airport Name": "Győr-Pér Int'l Airport",
      "Country": "Hungary",
      "GMT Offset": "1",
      "Latitude": "17.813601",
      "Location": "  - Győr",
      "Longitude": "47.624401"
  },
  {
      "Airport Code iATA,FAA": "SOB",
      "Airport Name": "Sármellék Int'l Airport",
      "Country": "Hungary",
      "GMT Offset": "1",
      "Latitude": "17.159084",
      "Location": "  - Sármellék",
      "Longitude": "46.686391"
  },
  {
      "Airport Code iATA,FAA": "BNX",
      "Airport Name": "Banja Luka Int'l Airport",
      "Country": "Bosnia and Herzegovina",
      "GMT Offset": "1",
      "Latitude": "17.297500610351562",
      "Location": "  - Banja Luka",
      "Longitude": "44.94139862060547"
  },
  {
      "Airport Code iATA,FAA": "ISE",
      "Airport Name": "Süleyman Demirel Int'l Airport",
      "Country": "Turkey",
      "GMT Offset": "3",
      "Latitude": "30.368400573699997",
      "Location": "  - Isparta",
      "Longitude": "37.8554000854"
  },
  {
      "Airport Code iATA,FAA": "GDT",
      "Airport Name": "JAGS McCartney Int'l Airport",
      "Country": "Turks and Caicos Islands",
      "GMT Offset": "-4",
      "Latitude": "-71.14230346679688",
      "Location": "  - Cockburn Town",
      "Longitude": "21.444499969482422"
  },
  {
      "Airport Code iATA,FAA": "AZS",
      "Airport Name": "Samaná El Catey Int'l Airport",
      "Country": "Dominican Republic",
      "GMT Offset": "-4",
      "Latitude": "-69.7419967651",
      "Location": "  - Samana",
      "Longitude": "19.2670001984"
  },
  {
      "Airport Code iATA,FAA": "JBQ",
      "Airport Name": "La Isabela Int'l Airport",
      "Country": "Dominican Republic",
      "GMT Offset": "-4",
      "Latitude": "-69.98560333251953",
      "Location": "  - La Isabela",
      "Longitude": "18.572500228881836"
  },
  {
      "Airport Code iATA,FAA": "SYQ",
      "Airport Name": "Tobias Bolanos Int'l Airport",
      "Country": "Costa Rica",
      "GMT Offset": "-6",
      "Latitude": "-84.13980102539062",
      "Location": "  - San Jose",
      "Longitude": "9.957050323486328"
  },
  {
      "Airport Code iATA,FAA": "IUE",
      "Airport Name": "Niue Int'l Airport",
      "Country": "Niue",
      "GMT Offset": "-11",
      "Latitude": "-169.92559814453125",
      "Location": "  - Alofi",
      "Longitude": "-19.079030990600586"
  },
  {
      "Airport Code iATA,FAA": "SON",
      "Airport Name": "Santo Pekoa Int'l Airport",
      "Country": "Vanuatu",
      "GMT Offset": "11",
      "Latitude": "167.220001221",
      "Location": "  - Santo",
      "Longitude": "-15.505000114399998"
  },
  {
      "Airport Code iATA,FAA": "AAN",
      "Airport Name": "Al Ain Int'l Airport",
      "Country": "United Arab Emirates",
      "GMT Offset": "4",
      "Latitude": "55.60919952392578",
      "Location": "  - Al Ain",
      "Longitude": "24.261699676513672"
  },
  {
      "Airport Code iATA,FAA": "TUK",
      "Airport Name": "Turbat Int'l Airport",
      "Country": "Pakistan",
      "GMT Offset": "5",
      "Latitude": "63.03020095825195",
      "Location": "  - Turbat",
      "Longitude": "25.986400604248047"
  },
  {
      "Airport Code iATA,FAA": "ISU",
      "Airport Name": "Sulaymaniyah Int'l Airport",
      "Country": "Iraq",
      "GMT Offset": "3",
      "Latitude": "45.316738128699996",
      "Location": "  - Sulaymaniyah",
      "Longitude": "35.5617485046"
  },
  {
      "Airport Code iATA,FAA": "GXF",
      "Airport Name": "Sayun Int'l Airport",
      "Country": "Yemen",
      "GMT Offset": "3",
      "Latitude": "48.78829956049999",
      "Location": "  - Sayun Intl",
      "Longitude": "15.9660997391"
  },
  {
      "Airport Code iATA,FAA": "YNY",
      "Airport Name": "Yangyang Int'l Airport",
      "Country": "South Korea",
      "GMT Offset": "9",
      "Latitude": "128.669006",
      "Location": "  - Sokcho / Gangneung",
      "Longitude": "38.061298"
  },
  {
      "Airport Code iATA,FAA": "SFS",
      "Airport Name": "Subic Bay Int'l Airport",
      "Country": "Philippines",
      "GMT Offset": "8",
      "Latitude": "120.27100372314453",
      "Location": "  - Olongapo City",
      "Longitude": "14.794400215148926"
  },
  {
      "Airport Code iATA,FAA": "NEV",
      "Airport Name": "Vance W. Amory Int'l Airport",
      "Country": "Saint Kitts and Nevis",
      "GMT Offset": "-4",
      "Latitude": "-62.589900970458984",
      "Location": "  - Charlestown",
      "Longitude": "17.205699920654297"
  },
  {
      "Airport Code iATA,FAA": "UNI",
      "Airport Name": "Union Island Int'l Airport",
      "Country": "Saint Vincent and the Grenadines",
      "GMT Offset": "-4",
      "Latitude": "-61.41194534301758",
      "Location": "  - Union Island",
      "Longitude": "12.60013484954834"
  },
  {
      "Airport Code iATA,FAA": "MPW",
      "Airport Name": "Mariupol Int'l Airport",
      "Country": "Ukraine",
      "GMT Offset": "2",
      "Latitude": "37.44960021972656",
      "Location": "  - Mariupol International",
      "Longitude": "47.07609939575195"
  },
  {
      "Airport Code iATA,FAA": "VSG",
      "Airport Name": "Luhansk Int'l Airport",
      "Country": "Ukraine",
      "GMT Offset": "2",
      "Latitude": "39.3740997314",
      "Location": "  - Lugansk",
      "Longitude": "48.4174003601"
  },
  {
      "Airport Code iATA,FAA": "OZH",
      "Airport Name": "Zaporizhzhia Int'l Airport",
      "Country": "Ukraine",
      "GMT Offset": "2",
      "Latitude": "35.31570053100586",
      "Location": "  - Zaporozhye",
      "Longitude": "47.867000579833984"
  },
  {
      "Airport Code iATA,FAA": "KWG",
      "Airport Name": "Kryvyi Rih Int'l Airport",
      "Country": "Ukraine",
      "GMT Offset": "2",
      "Latitude": "33.209999084472656",
      "Location": "  - Krivoy Rog",
      "Longitude": "48.04330062866211"
  },
  {
      "Airport Code iATA,FAA": "HRK",
      "Airport Name": "Kharkiv Int'l Airport",
      "Country": "Ukraine",
      "GMT Offset": "2",
      "Latitude": "36.290000915527344",
      "Location": "  - Kharkov",
      "Longitude": "49.924800872802734"
  },
  {
      "Airport Code iATA,FAA": "IFO",
      "Airport Name": "Ivano-Frankivsk Int'l Airport",
      "Country": "Ukraine",
      "GMT Offset": "2",
      "Latitude": "24.686100006103516",
      "Location": "  - Ivano-Frankivsk",
      "Longitude": "48.88420104980469"
  },
  {
      "Airport Code iATA,FAA": "CWC",
      "Airport Name": "Chernivtsi Int'l Airport",
      "Country": "Ukraine",
      "GMT Offset": "2",
      "Latitude": "25.98080062866211",
      "Location": "  - Chernovtsk",
      "Longitude": "48.259300231933594"
  },
  {
      "Airport Code iATA,FAA": "RWN",
      "Airport Name": "Rivne Int'l Airport",
      "Country": "Ukraine",
      "GMT Offset": "2",
      "Latitude": "26.141599655151367",
      "Location": "  - Rivne",
      "Longitude": "50.60710144042969"
  },
  {
      "Airport Code iATA,FAA": "UDJ",
      "Airport Name": "Uzhhorod Int'l Airport",
      "Country": "Ukraine",
      "GMT Offset": "2",
      "Latitude": "22.263399124145508",
      "Location": "  - Uzhgorod",
      "Longitude": "48.634300231933594"
  },
  {
      "Airport Code iATA,FAA": "FEG",
      "Airport Name": "Fergana Int'l Airport",
      "Country": "Uzbekistan",
      "GMT Offset": "5",
      "Latitude": "71.7450027466",
      "Location": "  - Fergana",
      "Longitude": "40.358798980699994"
  },
  {
      "Airport Code iATA,FAA": "EGO",
      "Airport Name": "Belgorod Int'l Airport",
      "Country": "Russia",
      "GMT Offset": "3",
      "Latitude": "36.5900993347168",
      "Location": "  - Belgorod",
      "Longitude": "50.643798828125"
  },
  {
      "Airport Code iATA,FAA": "GAU",
      "Airport Name": "Lokpriya Gopinath Bordoloi Int'l Airport",
      "Country": "India",
      "GMT Offset": "5.5",
      "Latitude": "91.58589935302734",
      "Location": "  - Guwahati",
      "Longitude": "26.10610008239746"
  },
  {
      "Airport Code iATA,FAA": "GAN",
      "Airport Name": "Gan Int'l Airport",
      "Country": "Maldives",
      "GMT Offset": "5",
      "Latitude": "73.155602",
      "Location": "  - Gan Island",
      "Longitude": "-0.693342"
  },
  {
      "Airport Code iATA,FAA": "HPH",
      "Airport Name": "Cat Bi Int'l Airport",
      "Country": "Vietnam",
      "GMT Offset": "7",
      "Latitude": "106.7249984741211",
      "Location": "  - Haiphong",
      "Longitude": "20.819400787353516"
  },
  {
      "Airport Code iATA,FAA": "VCA",
      "Airport Name": "Can Tho Int'l Airport",
      "Country": "Vietnam",
      "GMT Offset": "7",
      "Latitude": "105.711997986",
      "Location": "  - Can Tho",
      "Longitude": "10.085100174"
  },
  {
      "Airport Code iATA,FAA": "HLP",
      "Airport Name": "Halim Perdanakusuma Int'l Airport",
      "Country": "Indonesia",
      "GMT Offset": "7",
      "Latitude": "106.89099884033203",
      "Location": "  - Jakarta",
      "Longitude": "-6.266610145568848"
  },
  {
      "Airport Code iATA,FAA": "HET",
      "Airport Name": "Baita Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "111.823997",
      "Location": "  - Hohhot",
      "Longitude": "40.851398"
  },
  {
      "Airport Code iATA,FAA": "SJW",
      "Airport Name": "Shijiazhuang Daguocun Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "114.6969985961914",
      "Location": "  - Shijiazhuang",
      "Longitude": "38.28070068359375"
  },
  {
      "Airport Code iATA,FAA": "JJN",
      "Airport Name": "Quanzhou Jinjiang Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "118.589996",
      "Location": "  - Quanzhou",
      "Longitude": "24.7964"
  },
  {
      "Airport Code iATA,FAA": "TXN",
      "Airport Name": "Tunxi Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "118.25599670410156",
      "Location": "  - Huangshan",
      "Longitude": "29.733299255371094"
  },
  {
      "Airport Code iATA,FAA": "WUX",
      "Airport Name": "Sunan Shuofang Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "120.429000854",
      "Location": "  - Wuxi",
      "Longitude": "31.494400024399997"
  },
  {
      "Airport Code iATA,FAA": "WNZ",
      "Airport Name": "Wenzhou Longwan Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "120.851997",
      "Location": "  - Wenzhou",
      "Longitude": "27.912201"
  },
  {
      "Airport Code iATA,FAA": "QOW",
      "Airport Name": "Sam Mbakwe Int'l Airport",
      "Country": "Nigeria",
      "GMT Offset": "1",
      "Latitude": "7.206029891967773",
      "Location": "  - Imo",
      "Longitude": "5.427060127258301"
  },
  {
      "Airport Code iATA,FAA": "PDP",
      "Airport Name": "Capitan Corbeta CA Curbelo Int'l Airport",
      "Country": "Uruguay",
      "GMT Offset": "-3",
      "Latitude": "-55.09429931640625",
      "Location": "  - Punta del Este",
      "Longitude": "-34.855098724365234"
  },
  {
      "Airport Code iATA,FAA": "\\N",
      "Airport Name": "Ternopil Int'l Airport",
      "Country": "Ukraine",
      "GMT Offset": "2",
      "Latitude": "25.7001",
      "Location": "  - Ternopol",
      "Longitude": "49.5242"
  },
  {
      "Airport Code iATA,FAA": "ZIA",
      "Airport Name": "Zhukovsky Int'l Airport",
      "Country": "Russia",
      "GMT Offset": "3",
      "Latitude": "38.150002",
      "Location": "  - Ramenskoe",
      "Longitude": "55.553299"
  },
  {
      "Airport Code iATA,FAA": "TZL",
      "Airport Name": "Tuzla Int'l Airport",
      "Country": "Bosnia and Herzegovina",
      "GMT Offset": "1",
      "Latitude": "18.72480010986328",
      "Location": "  - Null",
      "Longitude": "44.45869827270508"
  },
  {
      "Airport Code iATA,FAA": "GYY",
      "Airport Name": "Gary Chicago Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-87.41280364990234",
      "Location": "  - Gary",
      "Longitude": "41.61629867553711"
  },
  {
      "Airport Code iATA,FAA": "CKC",
      "Airport Name": "Cherkasy Int'l Airport",
      "Country": "Ukraine",
      "GMT Offset": "2",
      "Latitude": "31.99530029296875",
      "Location": "  - Cherkassy",
      "Longitude": "49.41559982299805"
  },
  {
      "Airport Code iATA,FAA": "NLV",
      "Airport Name": "Mykolaiv Int'l Airport",
      "Country": "Ukraine",
      "GMT Offset": "2",
      "Latitude": "31.9197998046875",
      "Location": "  - Nikolayev",
      "Longitude": "47.057899475097656"
  },
  {
      "Airport Code iATA,FAA": "YTM",
      "Airport Name": "La Macaza / Mont-Tremblant Int'l Inc Airport",
      "Country": "Canada",
      "GMT Offset": "-5",
      "Latitude": "-74.7799987793",
      "Location": "  - Mont-Tremblant",
      "Longitude": "46.409400939899996"
  },
  {
      "Airport Code iATA,FAA": "CYR",
      "Airport Name": "Laguna de Los Patos Int'l Airport",
      "Country": "Uruguay",
      "GMT Offset": "-3",
      "Latitude": "-57.770599365234",
      "Location": "  - Colonia",
      "Longitude": "-34.456401824951"
  },
  {
      "Airport Code iATA,FAA": "\\N",
      "Airport Name": "Cabo San Lucas Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-7",
      "Latitude": "-109.936996",
      "Location": "  - Cabo San Lucas",
      "Longitude": "22.947701"
  },
  {
      "Airport Code iATA,FAA": "PJC",
      "Airport Name": "Dr Augusto Roberto Fuster Int'l Airport",
      "Country": "Paraguay",
      "GMT Offset": "-4",
      "Latitude": "-55.83000183105469",
      "Location": "  - Pedro Juan Caballero",
      "Longitude": "-22.639999389648438"
  },
  {
      "Airport Code iATA,FAA": "GSQ",
      "Airport Name": "Shark El Oweinat Int'l Airport",
      "Country": "Egypt",
      "GMT Offset": "2",
      "Latitude": "28.71660041809082",
      "Location": "  - Sharq Al-Owainat",
      "Longitude": "22.5856990814209"
  },
  {
      "Airport Code iATA,FAA": "JIJ",
      "Airport Name": "Wilwal Int'l Airport",
      "Country": "Ethiopia",
      "GMT Offset": "3",
      "Latitude": "42.9121",
      "Location": "  - Jijiga",
      "Longitude": "9.3325"
  },
  {
      "Airport Code iATA,FAA": "NBE",
      "Airport Name": "Enfidha - Hammamet Int'l Airport",
      "Country": "Tunisia",
      "GMT Offset": "1",
      "Latitude": "10.438611",
      "Location": "  - Enfidha",
      "Longitude": "36.075833"
  },
  {
      "Airport Code iATA,FAA": "KQT",
      "Airport Name": "Qurghonteppa Int'l Airport",
      "Country": "Tajikistan",
      "GMT Offset": "5",
      "Latitude": "68.86470031738281",
      "Location": "  - Kurgan Tyube",
      "Longitude": "37.86640167236328"
  },
  {
      "Airport Code iATA,FAA": "NJF",
      "Airport Name": "Al Najaf Int'l Airport",
      "Country": "Iraq",
      "GMT Offset": "3",
      "Latitude": "44.404317",
      "Location": "  - Najaf",
      "Longitude": "31.989853"
  },
  {
      "Airport Code iATA,FAA": "KHE",
      "Airport Name": "Kherson Int'l Airport",
      "Country": "Ukraine",
      "GMT Offset": "2",
      "Latitude": "32.506401",
      "Location": "  - Kherson",
      "Longitude": "46.6758"
  },
  {
      "Airport Code iATA,FAA": "PGU",
      "Airport Name": "Persian Gulf Int'l Airport",
      "Country": "Iran",
      "GMT Offset": "3.5",
      "Latitude": "52.737701",
      "Location": "  - Khalije Fars",
      "Longitude": "27.379601"
  },
  {
      "Airport Code iATA,FAA": "OSM",
      "Airport Name": "Mosul Int'l Airport",
      "Country": "Iraq",
      "GMT Offset": "3",
      "Latitude": "43.1474",
      "Location": "  - Mosul",
      "Longitude": "36.305801"
  },
  {
      "Airport Code iATA,FAA": "ECP",
      "Airport Name": "Northwest Florida Beaches Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-6",
      "Latitude": "-85.795414",
      "Location": "  - Panama City",
      "Longitude": "30.357106"
  },
  {
      "Airport Code iATA,FAA": "SBD",
      "Airport Name": "San Bernardino Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-8",
      "Latitude": "-117.23500061",
      "Location": "  - San Bernardino",
      "Longitude": "34.0954017639"
  },
  {
      "Airport Code iATA,FAA": "LAL",
      "Airport Name": "Lakeland Linder Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-82.018602",
      "Location": "  - Lakeland",
      "Longitude": "27.988899"
  },
  {
      "Airport Code iATA,FAA": "DBB",
      "Airport Name": "El Alamein Int'l Airport",
      "Country": "Egypt",
      "GMT Offset": "2",
      "Latitude": "28.46139907836914",
      "Location": "  - Dabaa City",
      "Longitude": "30.92449951171875"
  },
  {
      "Airport Code iATA,FAA": "DWC",
      "Airport Name": "Al Maktoum Int'l Airport",
      "Country": "United Arab Emirates",
      "GMT Offset": "4",
      "Latitude": "55.161389",
      "Location": "  - Dubai",
      "Longitude": "24.896356"
  },
  {
      "Airport Code iATA,FAA": "HMB",
      "Airport Name": "Sohag Int'l Airport",
      "Country": "Egypt",
      "GMT Offset": "2",
      "Latitude": "31.742778",
      "Location": "  - Sohag",
      "Longitude": "26.342778"
  },
  {
      "Airport Code iATA,FAA": "RVY",
      "Airport Name": "Presidente General Don Oscar D. Gestido Int'l Airport",
      "Country": "Uruguay",
      "GMT Offset": "-3",
      "Latitude": "-55.476200103759766",
      "Location": "  - Rivera",
      "Longitude": "-30.974599838256836"
  },
  {
      "Airport Code iATA,FAA": "FPR",
      "Airport Name": "St Lucie County Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-80.36830139",
      "Location": "  - Fort Pierce",
      "Longitude": "27.49510002"
  },
  {
      "Airport Code iATA,FAA": "LOP",
      "Airport Name": "Lombok Int'l Airport",
      "Country": "Indonesia",
      "GMT Offset": "8",
      "Latitude": "116.276675",
      "Location": "  - Praya",
      "Longitude": "-8.757322"
  },
  {
      "Airport Code iATA,FAA": "OCF",
      "Airport Name": "Ocala Int'l Airport - Jim Taylor Field",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-82.22419739",
      "Location": "  - Ocala",
      "Longitude": "29.17259979"
  },
  {
      "Airport Code iATA,FAA": "OSF",
      "Airport Name": "Ostafyevo Int'l Airport",
      "Country": "Russia",
      "GMT Offset": "3",
      "Latitude": "37.507222",
      "Location": "  - Moscow",
      "Longitude": "55.511667"
  },
  {
      "Airport Code iATA,FAA": "LLK",
      "Airport Name": "Lankaran Int'l Airport",
      "Country": "Azerbaijan",
      "GMT Offset": "4",
      "Latitude": "48.8180007935",
      "Location": "  - Lankaran",
      "Longitude": "38.746398925799994"
  },
  {
      "Airport Code iATA,FAA": "GBB",
      "Airport Name": "Gabala Int'l Airport",
      "Country": "Azerbaijan",
      "GMT Offset": "4",
      "Latitude": "47.7125",
      "Location": "  - Qabala",
      "Longitude": "40.826667"
  },
  {
      "Airport Code iATA,FAA": "ZTU",
      "Airport Name": "Zaqatala Int'l Airport",
      "Country": "Azerbaijan",
      "GMT Offset": "4",
      "Latitude": "46.667221",
      "Location": "  - Zaqatala",
      "Longitude": "41.562222"
  },
  {
      "Airport Code iATA,FAA": "PTK",
      "Airport Name": "Oakland County Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-83.420097351074",
      "Location": "  - Pontiac",
      "Longitude": "42.665500640869"
  },
  {
      "Airport Code iATA,FAA": "RHD",
      "Airport Name": "Termas de Río Hondo international Airport",
      "Country": "Argentina",
      "GMT Offset": "-3",
      "Latitude": "-64.93595",
      "Location": "  - Rio Hondo",
      "Longitude": "-27.4966"
  },
  {
      "Airport Code iATA,FAA": "HRI",
      "Airport Name": "Mattala Rajapaksa Int'l Airport",
      "Country": "Sri Lanka",
      "GMT Offset": "5.5",
      "Latitude": "81.124128",
      "Location": "  - Mattala",
      "Longitude": "6.284467"
  },
  {
      "Airport Code iATA,FAA": "ABB",
      "Airport Name": "Asaba Int'l Airport",
      "Country": "Nigeria",
      "GMT Offset": "1",
      "Latitude": "6.665278",
      "Location": "  - Asaba",
      "Longitude": "6.204167"
  },
  {
      "Airport Code iATA,FAA": "QUO",
      "Airport Name": "Akwa Ibom Int'l Airport",
      "Country": "Nigeria",
      "GMT Offset": "1",
      "Latitude": "8.093",
      "Location": "  - Uyo",
      "Longitude": "4.8725"
  },
  {
      "Airport Code iATA,FAA": "KNO",
      "Airport Name": "Kualanamu Int'l Airport",
      "Country": "Indonesia",
      "GMT Offset": "7",
      "Latitude": "98.885278",
      "Location": "  - Medan",
      "Longitude": "3.642222"
  },
  {
      "Airport Code iATA,FAA": "CZA",
      "Airport Name": "Chichen Itza Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-6",
      "Latitude": "-88.4461975098",
      "Location": "  - Chichen Itza",
      "Longitude": "20.6413002014"
  },
  {
      "Airport Code iATA,FAA": "RIH",
      "Airport Name": "Scarlett Martinez Int'l Airport",
      "Country": "Panama",
      "GMT Offset": "-5",
      "Latitude": "-80.127899169922",
      "Location": "  - Rio Hato",
      "Longitude": "8.375880241394"
  },
  {
      "Airport Code iATA,FAA": "LEE",
      "Airport Name": "Leesburg Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-81.80870056",
      "Location": "  - Leesburg",
      "Longitude": "28.82309914"
  },
  {
      "Airport Code iATA,FAA": "ERL",
      "Airport Name": "Erenhot Saiwusu Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "112.096667",
      "Location": "  - Erenhot",
      "Longitude": "43.4225"
  },
  {
      "Airport Code iATA,FAA": "PKX",
      "Airport Name": "Beijing Daxing Int'l Airport",
      "Country": "China",
      "GMT Offset": "8",
      "Latitude": "116.41092",
      "Location": "  - Beijing",
      "Longitude": "39.509945"
  },
  {
      "Airport Code iATA,FAA": "WOS",
      "Airport Name": "Wonsan Kalma Int'l Airport",
      "Country": "North Korea",
      "GMT Offset": "8.5",
      "Latitude": "127.486",
      "Location": "  - Wonsan",
      "Longitude": "39.166801"
  },
  {
      "Airport Code iATA,FAA": "DOH",
      "Airport Name": "Hamad Int'l Airport",
      "Country": "Qatar",
      "GMT Offset": "3",
      "Latitude": "51.608056",
      "Location": "  - Doha",
      "Longitude": "25.273056"
  },
  {
      "Airport Code iATA,FAA": "MLZ",
      "Airport Name": "Cerro Largo Int'l Airport",
      "Country": "Uruguay",
      "GMT Offset": "-3",
      "Latitude": "-54.21670150756836",
      "Location": "  - Melo",
      "Longitude": "-32.33789825439453"
  },
  {
      "Airport Code iATA,FAA": "ATI",
      "Airport Name": "Artigas Int'l Airport",
      "Country": "Uruguay",
      "GMT Offset": "-3",
      "Latitude": "-56.50790023803711",
      "Location": "  - Artigas ",
      "Longitude": "-30.400699615478516"
  },
  {
      "Airport Code iATA,FAA": "\\N",
      "Airport Name": "Mărculeşti Int'l Airport",
      "Country": "Moldova",
      "GMT Offset": "\\N",
      "Latitude": "28.212799072265625",
      "Location": "  - Mărculeşti",
      "Longitude": "47.862701416015625"
  },
  {
      "Airport Code iATA,FAA": "\\N",
      "Airport Name": "Cahul Int'l Airport",
      "Country": "Moldova",
      "GMT Offset": "\\N",
      "Latitude": "28.263700485199998",
      "Location": "  - Cahul",
      "Longitude": "45.8437995911"
  },
  {
      "Airport Code iATA,FAA": "PYK",
      "Airport Name": "Payam Int'l Airport",
      "Country": "Iran",
      "GMT Offset": "\\N",
      "Latitude": "50.826698303223",
      "Location": "  - Karaj",
      "Longitude": "35.776100158691"
  },
  {
      "Airport Code iATA,FAA": "IKU",
      "Airport Name": "Issyk-Kul Int'l Airport",
      "Country": "Kyrgyzstan",
      "GMT Offset": "\\N",
      "Latitude": "76.713046",
      "Location": "  - Tamchy",
      "Longitude": "42.58792"
  },
  {
      "Airport Code iATA,FAA": "HYD",
      "Airport Name": "Rajiv Gandhi Int'l Airport",
      "Country": "India",
      "GMT Offset": "5.5",
      "Latitude": "78.4298553467",
      "Location": "  - Hyderabad",
      "Longitude": "17.2313175201"
  },
  {
      "Airport Code iATA,FAA": "SBT",
      "Airport Name": "Sabetta Int'l Airport",
      "Country": "Russia",
      "GMT Offset": "5",
      "Latitude": "72.052222",
      "Location": "  - Sabetta",
      "Longitude": "71.219167"
  },
  {
      "Airport Code iATA,FAA": "DSS",
      "Airport Name": "Blaise Diagne Int'l Airport",
      "Country": "Senegal",
      "GMT Offset": "0",
      "Latitude": "-17.073333",
      "Location": "  - Diass",
      "Longitude": "14.67"
  },
  {
      "Airport Code iATA,FAA": "PQM",
      "Airport Name": "Palenque Int'l Airport",
      "Country": "Mexico",
      "GMT Offset": "-5",
      "Latitude": "-92.015484",
      "Location": "  - Palenque",
      "Longitude": "17.533153"
  },
  {
      "Airport Code iATA,FAA": "\\N",
      "Airport Name": "Srr Ahmadu Bello Int'l Airport",
      "Country": "Nigeria",
      "GMT Offset": "1",
      "Latitude": "4.369445",
      "Location": "  - Birnin Kebbi",
      "Longitude": "12.480556"
  },
  {
      "Airport Code iATA,FAA": "BCU",
      "Airport Name": "Sir Abubakar Tafawa Balewa Int'l Airport",
      "Country": "Nigeria",
      "GMT Offset": "1",
      "Latitude": "9.744",
      "Location": "  - Bauchi",
      "Longitude": "10.482833"
  },
  {
      "Airport Code iATA,FAA": "GMO",
      "Airport Name": "Gombe Lawanti Int'l Airport",
      "Country": "Nigeria",
      "GMT Offset": "1",
      "Latitude": "10.896388888899999",
      "Location": "  - Gombe",
      "Longitude": "10.298333333299999"
  },
  {
      "Airport Code iATA,FAA": "\\N",
      "Airport Name": "Dutse Int'l Airport",
      "Country": "Nigeria",
      "GMT Offset": "1",
      "Latitude": "9.311667",
      "Location": "  - Dutse",
      "Longitude": "11.795168"
  },
  {
      "Airport Code iATA,FAA": "SHO",
      "Airport Name": "King Mswati III Int'l Airport",
      "Country": "Swaziland",
      "GMT Offset": "2",
      "Latitude": "31.716944",
      "Location": "  - Manzini",
      "Longitude": "-26.358611"
  },
  {
      "Airport Code iATA,FAA": "DQM",
      "Airport Name": "Duqm Int'l Airport",
      "Country": "Oman",
      "GMT Offset": "4",
      "Latitude": "57.634167",
      "Location": "  - Duqm",
      "Longitude": "19.501944"
  },
  {
      "Airport Code iATA,FAA": "\\N",
      "Airport Name": "Stennis Int'l Airport",
      "Country": "United States",
      "GMT Offset": "-5",
      "Latitude": "-89.45459747314453",
      "Location": "  - Bay St. Louis",
      "Longitude": "30.367799758911133"
  },
  {
      "Airport Code iATA,FAA": "CNN",
      "Airport Name": "Kannur Int'l Airport",
      "Country": "India",
      "GMT Offset": "\\N",
      "Latitude": "75.547211",
      "Location": "  - Kannur",
      "Longitude": "11.918614"
  },
  {
      "Airport Code iATA,FAA": "ISB",
      "Airport Name": "New Islamabad Int'l Airport",
      "Country": "Pakistan",
      "GMT Offset": "5",
      "Latitude": "72.851613",
      "Location": "  - Islamabad",
      "Longitude": "33.560713"
  },
  {
      "Airport Code iATA,FAA": "RMU",
      "Airport Name": "Región de Murcia Int'l Airport",
      "Country": "Spain",
      "GMT Offset": "1",
      "Latitude": "-1.125",
      "Location": "  - Murcia",
      "Longitude": "37.803"
  }
];






// export const Airports = [
//     {
//       "ID": 1,
//       "Airport Code [ICAO]": "AYGA",
//       "Airport Code iATA,FAA": "GKA",
//       "Airport Name": "Goroka Airport",
//       "Altitude (Ft)": 5282,
//       "Country": "Papua New Guinea",
//       "Latitude": 145.391998291,
//       "Location": "Goroka",
//       "Longitude": -6.081689834590001,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 2,
//       "Airport Code [ICAO]": "AYMD",
//       "Airport Code iATA,FAA": "MAG",
//       "Airport Name": "Madang Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Papua New Guinea",
//       "Latitude": 145.789001465,
//       "Location": "Madang",
//       "Longitude": -5.20707988739,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 3,
//       "Airport Code [ICAO]": "AYMH",
//       "Airport Code iATA,FAA": "HGU",
//       "Airport Name": "Mount Hagen Kagamuga Airport",
//       "Altitude (Ft)": 5388,
//       "Country": "Papua New Guinea",
//       "Latitude": 144.29600524902344,
//       "Location": "Mount Hagen",
//       "Longitude": -5.826789855957031,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 4,
//       "Airport Code [ICAO]": "AYNZ",
//       "Airport Code iATA,FAA": "LAE",
//       "Airport Name": "Nadzab Airport",
//       "Altitude (Ft)": 239,
//       "Country": "Papua New Guinea",
//       "Latitude": 146.725977,
//       "Location": "Nadzab",
//       "Longitude": -6.569803,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 5,
//       "Airport Code [ICAO]": "AYPY",
//       "Airport Code iATA,FAA": "POM",
//       "Airport Name": "Port Moresby Jacksons International Airport",
//       "Altitude (Ft)": 146,
//       "Country": "Papua New Guinea",
//       "Latitude": 147.22000122070312,
//       "Location": "Port Moresby",
//       "Longitude": -9.443380355834961,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 6,
//       "Airport Code [ICAO]": "AYWK",
//       "Airport Code iATA,FAA": "WWK",
//       "Airport Name": "Wewak International Airport",
//       "Altitude (Ft)": 19,
//       "Country": "Papua New Guinea",
//       "Latitude": 143.669006348,
//       "Location": "Wewak",
//       "Longitude": -3.58383011818,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 7,
//       "Airport Code [ICAO]": "BGBW",
//       "Airport Code iATA,FAA": "UAK",
//       "Airport Name": "Narsarsuaq Airport",
//       "Altitude (Ft)": 112,
//       "Country": "Greenland",
//       "Latitude": -45.4259986877,
//       "Location": "Narssarssuaq",
//       "Longitude": 61.1604995728,
//       "GMT Offset": "-3",
//       "Daylight Savings": "E",
//       "Region": "America/Godthab",
//       "Type": "airport"
//     },
//     {
//       "ID": 8,
//       "Airport Code [ICAO]": "BGGH",
//       "Airport Code iATA,FAA": "GOH",
//       "Airport Name": "Godthaab / Nuuk Airport",
//       "Altitude (Ft)": 283,
//       "Country": "Greenland",
//       "Latitude": -51.6781005859,
//       "Location": "Godthaab",
//       "Longitude": 64.19090271,
//       "GMT Offset": "-3",
//       "Daylight Savings": "E",
//       "Region": "America/Godthab",
//       "Type": "airport"
//     },
//     {
//       "ID": 9,
//       "Airport Code [ICAO]": "BGSF",
//       "Airport Code iATA,FAA": "SFJ",
//       "Airport Name": "Kangerlussuaq Airport",
//       "Altitude (Ft)": 165,
//       "Country": "Greenland",
//       "Latitude": -50.7116031647,
//       "Location": "Sondrestrom",
//       "Longitude": 67.0122218992,
//       "GMT Offset": "-3",
//       "Daylight Savings": "E",
//       "Region": "America/Godthab",
//       "Type": "airport"
//     },
//     {
//       "ID": 10,
//       "Airport Code [ICAO]": "BGTL",
//       "Airport Code iATA,FAA": "THU",
//       "Airport Name": "Thule Air Base",
//       "Altitude (Ft)": 251,
//       "Country": "Greenland",
//       "Latitude": -68.7032012939,
//       "Location": "Thule",
//       "Longitude": 76.5311965942,
//       "GMT Offset": "-4",
//       "Daylight Savings": "E",
//       "Region": "America/Thule",
//       "Type": "airport"
//     },
//     {
//       "ID": 11,
//       "Airport Code [ICAO]": "BIAR",
//       "Airport Code iATA,FAA": "AEY",
//       "Airport Name": "Akureyri Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Iceland",
//       "Latitude": -18.07270050048828,
//       "Location": "Akureyri",
//       "Longitude": 65.66000366210938,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Atlantic/Reykjavik",
//       "Type": "airport"
//     },
//     {
//       "ID": 12,
//       "Airport Code [ICAO]": "BIEG",
//       "Airport Code iATA,FAA": "EGS",
//       "Airport Name": "Egilsstaðir Airport",
//       "Altitude (Ft)": 76,
//       "Country": "Iceland",
//       "Latitude": -14.401399612426758,
//       "Location": "Egilsstadir",
//       "Longitude": 65.2833023071289,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Atlantic/Reykjavik",
//       "Type": "airport"
//     },
//     {
//       "ID": 13,
//       "Airport Code [ICAO]": "BIHN",
//       "Airport Code iATA,FAA": "HFN",
//       "Airport Name": "Hornafjörður Airport",
//       "Altitude (Ft)": 24,
//       "Country": "Iceland",
//       "Latitude": -15.2272,
//       "Location": "Hofn",
//       "Longitude": 64.295601,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Atlantic/Reykjavik",
//       "Type": "airport"
//     },
//     {
//       "ID": 14,
//       "Airport Code [ICAO]": "BIHU",
//       "Airport Code iATA,FAA": "HZK",
//       "Airport Name": "Húsavík Airport",
//       "Altitude (Ft)": 48,
//       "Country": "Iceland",
//       "Latitude": -17.426001,
//       "Location": "Husavik",
//       "Longitude": 65.952301,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Atlantic/Reykjavik",
//       "Type": "airport"
//     },
//     {
//       "ID": 15,
//       "Airport Code [ICAO]": "BIIS",
//       "Airport Code iATA,FAA": "IFJ",
//       "Airport Name": "Ísafjörður Airport",
//       "Altitude (Ft)": 8,
//       "Country": "Iceland",
//       "Latitude": -23.135299682617188,
//       "Location": "Isafjordur",
//       "Longitude": 66.05809783935547,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Atlantic/Reykjavik",
//       "Type": "airport"
//     },
//     {
//       "ID": 16,
//       "Airport Code [ICAO]": "BIKF",
//       "Airport Code iATA,FAA": "KEF",
//       "Airport Name": "Keflavik International Airport",
//       "Altitude (Ft)": 171,
//       "Country": "Iceland",
//       "Latitude": -22.605600357056,
//       "Location": "Keflavik",
//       "Longitude": 63.985000610352,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Atlantic/Reykjavik",
//       "Type": "airport"
//     },
//     {
//       "ID": 17,
//       "Airport Code [ICAO]": "BIPA",
//       "Airport Code iATA,FAA": "PFJ",
//       "Airport Name": "Patreksfjörður Airport",
//       "Altitude (Ft)": 11,
//       "Country": "Iceland",
//       "Latitude": -23.965,
//       "Location": "Patreksfjordur",
//       "Longitude": 65.555801,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Atlantic/Reykjavik",
//       "Type": "airport"
//     },
//     {
//       "ID": 18,
//       "Airport Code [ICAO]": "BIRK",
//       "Airport Code iATA,FAA": "RKV",
//       "Airport Name": "Reykjavik Airport",
//       "Altitude (Ft)": 48,
//       "Country": "Iceland",
//       "Latitude": -21.9405994415,
//       "Location": "Reykjavik",
//       "Longitude": 64.1299972534,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Atlantic/Reykjavik",
//       "Type": "airport"
//     },
//     {
//       "ID": 19,
//       "Airport Code [ICAO]": "BISI",
//       "Airport Code iATA,FAA": "SIJ",
//       "Airport Name": "Siglufjörður Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Iceland",
//       "Latitude": -18.9167,
//       "Location": "Siglufjordur",
//       "Longitude": 66.133301,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Atlantic/Reykjavik",
//       "Type": "airport"
//     },
//     {
//       "ID": 20,
//       "Airport Code [ICAO]": "BIVM",
//       "Airport Code iATA,FAA": "VEY",
//       "Airport Name": "Vestmannaeyjar Airport",
//       "Altitude (Ft)": 326,
//       "Country": "Iceland",
//       "Latitude": -20.278900146484375,
//       "Location": "Vestmannaeyjar",
//       "Longitude": 63.42430114746094,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Atlantic/Reykjavik",
//       "Type": "airport"
//     },
//     {
//       "ID": 21,
//       "Airport Code [ICAO]": "CYAM",
//       "Airport Code iATA,FAA": "YAM",
//       "Airport Name": "Sault Ste Marie Airport",
//       "Altitude (Ft)": 630,
//       "Country": "Canada",
//       "Latitude": -84.5093994140625,
//       "Location": "Sault Sainte Marie",
//       "Longitude": 46.48500061035156,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 22,
//       "Airport Code [ICAO]": "CYAV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Winnipeg / St. Andrews Airport",
//       "Altitude (Ft)": 760,
//       "Country": "Canada",
//       "Latitude": -97.03250122070001,
//       "Location": "Winnipeg",
//       "Longitude": 50.0564002991,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 23,
//       "Airport Code [ICAO]": "CYAW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Halifax / CFB Shearwater Heliport",
//       "Altitude (Ft)": 144,
//       "Country": "Canada",
//       "Latitude": -63.499401,
//       "Location": "Halifax",
//       "Longitude": 44.639702,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Halifax",
//       "Type": "airport"
//     },
//     {
//       "ID": 24,
//       "Airport Code [ICAO]": "CYAY",
//       "Airport Code iATA,FAA": "YAY",
//       "Airport Name": "St. Anthony Airport",
//       "Altitude (Ft)": 108,
//       "Country": "Canada",
//       "Latitude": -56.083099365200006,
//       "Location": "St. Anthony",
//       "Longitude": 51.3918991089,
//       "GMT Offset": "-3.5",
//       "Daylight Savings": "A",
//       "Region": "America/St_Johns",
//       "Type": "airport"
//     },
//     {
//       "ID": 25,
//       "Airport Code [ICAO]": "CYAZ",
//       "Airport Code iATA,FAA": "YAZ",
//       "Airport Name": "Tofino / Long Beach Airport",
//       "Altitude (Ft)": 80,
//       "Country": "Canada",
//       "Latitude": -125.775583,
//       "Location": "Tofino",
//       "Longitude": 49.079833,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 26,
//       "Airport Code [ICAO]": "CYBB",
//       "Airport Code iATA,FAA": "YBB",
//       "Airport Name": "Kugaaruk Airport",
//       "Altitude (Ft)": 56,
//       "Country": "Canada",
//       "Latitude": -89.808098,
//       "Location": "Pelly Bay",
//       "Longitude": 68.534401,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 27,
//       "Airport Code [ICAO]": "CYBC",
//       "Airport Code iATA,FAA": "YBC",
//       "Airport Name": "Baie Comeau Airport",
//       "Altitude (Ft)": 71,
//       "Country": "Canada",
//       "Latitude": -68.20439910888672,
//       "Location": "Baie Comeau",
//       "Longitude": 49.13249969482422,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 28,
//       "Airport Code [ICAO]": "CYBG",
//       "Airport Code iATA,FAA": "YBG",
//       "Airport Name": "CFB Bagotville",
//       "Altitude (Ft)": 522,
//       "Country": "Canada",
//       "Latitude": -70.99639892578125,
//       "Location": "Bagotville",
//       "Longitude": 48.33060073852539,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 29,
//       "Airport Code [ICAO]": "CYBK",
//       "Airport Code iATA,FAA": "YBK",
//       "Airport Name": "Baker Lake Airport",
//       "Altitude (Ft)": 59,
//       "Country": "Canada",
//       "Latitude": -96.077796936,
//       "Location": "Baker Lake",
//       "Longitude": 64.29889678960001,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 30,
//       "Airport Code [ICAO]": "CYBL",
//       "Airport Code iATA,FAA": "YBL",
//       "Airport Name": "Campbell River Airport",
//       "Altitude (Ft)": 346,
//       "Country": "Canada",
//       "Latitude": -125.27100372314453,
//       "Location": "Campbell River",
//       "Longitude": 49.950801849365234,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 31,
//       "Airport Code [ICAO]": "CYBR",
//       "Airport Code iATA,FAA": "YBR",
//       "Airport Name": "Brandon Municipal Airport",
//       "Altitude (Ft)": 1343,
//       "Country": "Canada",
//       "Latitude": -99.951897,
//       "Location": "Brandon",
//       "Longitude": 49.91,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 32,
//       "Airport Code [ICAO]": "CYCB",
//       "Airport Code iATA,FAA": "YCB",
//       "Airport Name": "Cambridge Bay Airport",
//       "Altitude (Ft)": 90,
//       "Country": "Canada",
//       "Latitude": -105.138000488,
//       "Location": "Cambridge Bay",
//       "Longitude": 69.1081008911,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 33,
//       "Airport Code [ICAO]": "CYCD",
//       "Airport Code iATA,FAA": "YCD",
//       "Airport Name": "Nanaimo Airport",
//       "Altitude (Ft)": 92,
//       "Country": "Canada",
//       "Latitude": -123.869862556,
//       "Location": "Nanaimo",
//       "Longitude": 49.054970224899996,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 34,
//       "Airport Code [ICAO]": "CYCG",
//       "Airport Code iATA,FAA": "YCG",
//       "Airport Name": "Castlegar/West Kootenay Regional Airport",
//       "Altitude (Ft)": 1624,
//       "Country": "Canada",
//       "Latitude": -117.632003784,
//       "Location": "Castlegar",
//       "Longitude": 49.2963981628,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 35,
//       "Airport Code [ICAO]": "CYCH",
//       "Airport Code iATA,FAA": "YCH",
//       "Airport Name": "Miramichi Airport",
//       "Altitude (Ft)": 108,
//       "Country": "Canada",
//       "Latitude": -65.449203,
//       "Location": "Chatham",
//       "Longitude": 47.007801,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Halifax",
//       "Type": "airport"
//     },
//     {
//       "ID": 36,
//       "Airport Code [ICAO]": "CYCL",
//       "Airport Code iATA,FAA": "YCL",
//       "Airport Name": "Charlo Airport",
//       "Altitude (Ft)": 132,
//       "Country": "Canada",
//       "Latitude": -66.330299,
//       "Location": "Charlo",
//       "Longitude": 47.990799,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Halifax",
//       "Type": "airport"
//     },
//     {
//       "ID": 37,
//       "Airport Code [ICAO]": "CYCO",
//       "Airport Code iATA,FAA": "YCO",
//       "Airport Name": "Kugluktuk Airport",
//       "Altitude (Ft)": 74,
//       "Country": "Canada",
//       "Latitude": -115.143997,
//       "Location": "Coppermine",
//       "Longitude": 67.816704,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 38,
//       "Airport Code [ICAO]": "CYCT",
//       "Airport Code iATA,FAA": "YCT",
//       "Airport Name": "Coronation Airport",
//       "Altitude (Ft)": 2595,
//       "Country": "Canada",
//       "Latitude": -111.444999695,
//       "Location": "Coronation",
//       "Longitude": 52.0750007629,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 39,
//       "Airport Code [ICAO]": "CYCW",
//       "Airport Code iATA,FAA": "YCW",
//       "Airport Name": "Chilliwack Airport",
//       "Altitude (Ft)": 32,
//       "Country": "Canada",
//       "Latitude": -121.939002991,
//       "Location": "Chilliwack",
//       "Longitude": 49.1528015137,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 40,
//       "Airport Code [ICAO]": "CYCY",
//       "Airport Code iATA,FAA": "YCY",
//       "Airport Name": "Clyde River Airport",
//       "Altitude (Ft)": 87,
//       "Country": "Canada",
//       "Latitude": -68.5167007446,
//       "Location": "Clyde River",
//       "Longitude": 70.4860992432,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 41,
//       "Airport Code [ICAO]": "CYZS",
//       "Airport Code iATA,FAA": "YZS",
//       "Airport Name": "Coral Harbour Airport",
//       "Altitude (Ft)": 210,
//       "Country": "Canada",
//       "Latitude": -83.3593978882,
//       "Location": "Coral Harbour",
//       "Longitude": 64.1932983398,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Coral_Harbour",
//       "Type": "airport"
//     },
//     {
//       "ID": 42,
//       "Airport Code [ICAO]": "CYDA",
//       "Airport Code iATA,FAA": "YDA",
//       "Airport Name": "Dawson City Airport",
//       "Altitude (Ft)": 1215,
//       "Country": "Canada",
//       "Latitude": -139.1280059814453,
//       "Location": "Dawson",
//       "Longitude": 64.04309844970703,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 43,
//       "Airport Code [ICAO]": "CYDB",
//       "Airport Code iATA,FAA": "YDB",
//       "Airport Name": "Burwash Airport",
//       "Altitude (Ft)": 2647,
//       "Country": "Canada",
//       "Latitude": -139.04100036621094,
//       "Location": "Burwash",
//       "Longitude": 61.37110137939453,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 44,
//       "Airport Code [ICAO]": "CYDC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Princeton Airport",
//       "Altitude (Ft)": 2298,
//       "Country": "Canada",
//       "Latitude": -120.511001587,
//       "Location": "Princeton",
//       "Longitude": 49.4681015015,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 45,
//       "Airport Code [ICAO]": "CYDF",
//       "Airport Code iATA,FAA": "YDF",
//       "Airport Name": "Deer Lake Airport",
//       "Altitude (Ft)": 72,
//       "Country": "Canada",
//       "Latitude": -57.39139938354492,
//       "Location": "Deer Lake",
//       "Longitude": 49.21080017089844,
//       "GMT Offset": "-3.5",
//       "Daylight Savings": "A",
//       "Region": "America/St_Johns",
//       "Type": "airport"
//     },
//     {
//       "ID": 46,
//       "Airport Code [ICAO]": "CYDL",
//       "Airport Code iATA,FAA": "YDL",
//       "Airport Name": "Dease Lake Airport",
//       "Altitude (Ft)": 2600,
//       "Country": "Canada",
//       "Latitude": -130.031997681,
//       "Location": "Dease Lake",
//       "Longitude": 58.4221992493,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 47,
//       "Airport Code [ICAO]": "CYDN",
//       "Airport Code iATA,FAA": "YDN",
//       "Airport Name": "Dauphin Barker Airport",
//       "Altitude (Ft)": 999,
//       "Country": "Canada",
//       "Latitude": -100.052001953125,
//       "Location": "Dauphin",
//       "Longitude": 51.100799560546875,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 48,
//       "Airport Code [ICAO]": "CYDQ",
//       "Airport Code iATA,FAA": "YDQ",
//       "Airport Name": "Dawson Creek Airport",
//       "Altitude (Ft)": 2148,
//       "Country": "Canada",
//       "Latitude": -120.18299865722656,
//       "Location": "Dawson Creek",
//       "Longitude": 55.7422981262207,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Dawson_Creek",
//       "Type": "airport"
//     },
//     {
//       "ID": 49,
//       "Airport Code [ICAO]": "CYEG",
//       "Airport Code iATA,FAA": "YEG",
//       "Airport Name": "Edmonton International Airport",
//       "Altitude (Ft)": 2373,
//       "Country": "Canada",
//       "Latitude": -113.580001831,
//       "Location": "Edmonton",
//       "Longitude": 53.309700012200004,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 50,
//       "Airport Code [ICAO]": "CYEK",
//       "Airport Code iATA,FAA": "YEK",
//       "Airport Name": "Arviat Airport",
//       "Altitude (Ft)": 32,
//       "Country": "Canada",
//       "Latitude": -94.07080078119999,
//       "Location": "Eskimo Point",
//       "Longitude": 61.0942001343,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 51,
//       "Airport Code [ICAO]": "CYEN",
//       "Airport Code iATA,FAA": "YEN",
//       "Airport Name": "Estevan Airport",
//       "Altitude (Ft)": 1905,
//       "Country": "Canada",
//       "Latitude": -102.966003418,
//       "Location": "Estevan",
//       "Longitude": 49.2103004456,
//       "GMT Offset": "-6",
//       "Daylight Savings": "N",
//       "Region": "America/Regina",
//       "Type": "airport"
//     },
//     {
//       "ID": 52,
//       "Airport Code [ICAO]": "CYET",
//       "Airport Code iATA,FAA": "YET",
//       "Airport Name": "Edson Airport",
//       "Altitude (Ft)": 3043,
//       "Country": "Canada",
//       "Latitude": -116.464996338,
//       "Location": "Edson",
//       "Longitude": 53.578899383499994,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 53,
//       "Airport Code [ICAO]": "CYEU",
//       "Airport Code iATA,FAA": "YEU",
//       "Airport Name": "Eureka Airport",
//       "Altitude (Ft)": 256,
//       "Country": "Canada",
//       "Latitude": -85.814201355,
//       "Location": "Eureka",
//       "Longitude": 79.9946975708,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 54,
//       "Airport Code [ICAO]": "CYEV",
//       "Airport Code iATA,FAA": "YEV",
//       "Airport Name": "Inuvik Mike Zubko Airport",
//       "Altitude (Ft)": 224,
//       "Country": "Canada",
//       "Latitude": -133.483001709,
//       "Location": "Inuvik",
//       "Longitude": 68.30419921880001,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 55,
//       "Airport Code [ICAO]": "CYFB",
//       "Airport Code iATA,FAA": "YFB",
//       "Airport Name": "Iqaluit Airport",
//       "Altitude (Ft)": 110,
//       "Country": "Canada",
//       "Latitude": -68.555801,
//       "Location": "Iqaluit",
//       "Longitude": 63.756402,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 56,
//       "Airport Code [ICAO]": "CYFC",
//       "Airport Code iATA,FAA": "YFC",
//       "Airport Name": "Fredericton Airport",
//       "Altitude (Ft)": 68,
//       "Country": "Canada",
//       "Latitude": -66.53720092773438,
//       "Location": "Fredericton",
//       "Longitude": 45.868900299072266,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Halifax",
//       "Type": "airport"
//     },
//     {
//       "ID": 57,
//       "Airport Code [ICAO]": "CYFE",
//       "Airport Code iATA,FAA": "YFE",
//       "Airport Name": "Forestville Airport",
//       "Altitude (Ft)": 293,
//       "Country": "Canada",
//       "Latitude": -69.09719848632812,
//       "Location": "Forestville",
//       "Longitude": 48.74610137939453,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 58,
//       "Airport Code [ICAO]": "CYFO",
//       "Airport Code iATA,FAA": "YFO",
//       "Airport Name": "Flin Flon Airport",
//       "Altitude (Ft)": 997,
//       "Country": "Canada",
//       "Latitude": -101.68199920654297,
//       "Location": "Flin Flon",
//       "Longitude": 54.6781005859375,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 59,
//       "Airport Code [ICAO]": "CYFR",
//       "Airport Code iATA,FAA": "YFR",
//       "Airport Name": "Fort Resolution Airport",
//       "Altitude (Ft)": 526,
//       "Country": "Canada",
//       "Latitude": -113.690002441,
//       "Location": "Fort Resolution",
//       "Longitude": 61.1808013916,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 60,
//       "Airport Code [ICAO]": "CYFS",
//       "Airport Code iATA,FAA": "YFS",
//       "Airport Name": "Fort Simpson Airport",
//       "Altitude (Ft)": 555,
//       "Country": "Canada",
//       "Latitude": -121.23699951171875,
//       "Location": "Fort Simpson",
//       "Longitude": 61.76020050048828,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 61,
//       "Airport Code [ICAO]": "CYGK",
//       "Airport Code iATA,FAA": "YGK",
//       "Airport Name": "Kingston Norman Rogers Airport",
//       "Altitude (Ft)": 305,
//       "Country": "Canada",
//       "Latitude": -76.5969009399414,
//       "Location": "Kingston",
//       "Longitude": 44.22529983520508,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 62,
//       "Airport Code [ICAO]": "CYGL",
//       "Airport Code iATA,FAA": "YGL",
//       "Airport Name": "La Grande Rivière Airport",
//       "Altitude (Ft)": 639,
//       "Country": "Canada",
//       "Latitude": -77.7042007446289,
//       "Location": "La Grande Riviere",
//       "Longitude": 53.625301361083984,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 63,
//       "Airport Code [ICAO]": "CYGP",
//       "Airport Code iATA,FAA": "YGP",
//       "Airport Name": "Gaspé (Michel-Pouliot) Airport",
//       "Altitude (Ft)": 112,
//       "Country": "Canada",
//       "Latitude": -64.4785995483,
//       "Location": "Gaspe",
//       "Longitude": 48.7752990723,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 64,
//       "Airport Code [ICAO]": "CYGQ",
//       "Airport Code iATA,FAA": "YGQ",
//       "Airport Name": "Geraldton Greenstone Regional Airport",
//       "Altitude (Ft)": 1144,
//       "Country": "Canada",
//       "Latitude": -86.93939971923828,
//       "Location": "Geraldton",
//       "Longitude": 49.77830123901367,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 65,
//       "Airport Code [ICAO]": "CYGR",
//       "Airport Code iATA,FAA": "YGR",
//       "Airport Name": "Îles-de-la-Madeleine Airport",
//       "Altitude (Ft)": 35,
//       "Country": "Canada",
//       "Latitude": -61.778099060058594,
//       "Location": "Iles De La Madeleine",
//       "Longitude": 47.42470169067383,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 66,
//       "Airport Code [ICAO]": "CYHB",
//       "Airport Code iATA,FAA": "YHB",
//       "Airport Name": "Hudson Bay Airport",
//       "Altitude (Ft)": 1175,
//       "Country": "Canada",
//       "Latitude": -102.310997009,
//       "Location": "Hudson Bay",
//       "Longitude": 52.8166999817,
//       "GMT Offset": "-6",
//       "Daylight Savings": "N",
//       "Region": "America/Regina",
//       "Type": "airport"
//     },
//     {
//       "ID": 67,
//       "Airport Code [ICAO]": "CYHD",
//       "Airport Code iATA,FAA": "YHD",
//       "Airport Name": "Dryden Regional Airport",
//       "Altitude (Ft)": 1354,
//       "Country": "Canada",
//       "Latitude": -92.744202,
//       "Location": "Dryden",
//       "Longitude": 49.831699,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 68,
//       "Airport Code [ICAO]": "CYHI",
//       "Airport Code iATA,FAA": "YHI",
//       "Airport Name": "Ulukhaktok Holman Airport",
//       "Altitude (Ft)": 117,
//       "Country": "Canada",
//       "Latitude": -117.80599975585938,
//       "Location": "Holman Island",
//       "Longitude": 70.76280212402344,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 69,
//       "Airport Code [ICAO]": "CYHK",
//       "Airport Code iATA,FAA": "YHK",
//       "Airport Name": "Gjoa Haven Airport",
//       "Altitude (Ft)": 152,
//       "Country": "Canada",
//       "Latitude": -95.84970092770001,
//       "Location": "Gjoa Haven",
//       "Longitude": 68.635597229,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 70,
//       "Airport Code [ICAO]": "CYHM",
//       "Airport Code iATA,FAA": "YHM",
//       "Airport Name": "John C. Munro Hamilton International Airport",
//       "Altitude (Ft)": 780,
//       "Country": "Canada",
//       "Latitude": -79.93499755859999,
//       "Location": "Hamilton",
//       "Longitude": 43.173599243199995,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 71,
//       "Airport Code [ICAO]": "CYHU",
//       "Airport Code iATA,FAA": "YHU",
//       "Airport Name": "Montréal / Saint-Hubert Airport",
//       "Altitude (Ft)": 90,
//       "Country": "Canada",
//       "Latitude": -73.4169006348,
//       "Location": "Montreal",
//       "Longitude": 45.5175018311,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 72,
//       "Airport Code [ICAO]": "CYHY",
//       "Airport Code iATA,FAA": "YHY",
//       "Airport Name": "Hay River / Merlyn Carter Airport",
//       "Altitude (Ft)": 541,
//       "Country": "Canada",
//       "Latitude": -115.782997131,
//       "Location": "Hay River",
//       "Longitude": 60.8396987915,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 73,
//       "Airport Code [ICAO]": "CYHZ",
//       "Airport Code iATA,FAA": "YHZ",
//       "Airport Name": "Halifax / Stanfield International Airport",
//       "Altitude (Ft)": 477,
//       "Country": "Canada",
//       "Latitude": -63.5085983276,
//       "Location": "Halifax",
//       "Longitude": 44.8807983398,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Halifax",
//       "Type": "airport"
//     },
//     {
//       "ID": 74,
//       "Airport Code [ICAO]": "CYIB",
//       "Airport Code iATA,FAA": "YIB",
//       "Airport Name": "Atikokan Municipal Airport",
//       "Altitude (Ft)": 1408,
//       "Country": "Canada",
//       "Latitude": -91.6386032104,
//       "Location": "Atikokan",
//       "Longitude": 48.7738990784,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Coral_Harbour",
//       "Type": "airport"
//     },
//     {
//       "ID": 75,
//       "Airport Code [ICAO]": "CYIO",
//       "Airport Code iATA,FAA": "YIO",
//       "Airport Name": "Pond Inlet Airport",
//       "Altitude (Ft)": 181,
//       "Country": "Canada",
//       "Latitude": -77.9666976929,
//       "Location": "Pond Inlet",
//       "Longitude": 72.6832962036,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 76,
//       "Airport Code [ICAO]": "CYJN",
//       "Airport Code iATA,FAA": "YJN",
//       "Airport Name": "St Jean Airport",
//       "Altitude (Ft)": 136,
//       "Country": "Canada",
//       "Latitude": -73.28109741210938,
//       "Location": "St. Jean",
//       "Longitude": 45.29439926147461,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 77,
//       "Airport Code [ICAO]": "CYJT",
//       "Airport Code iATA,FAA": "YJT",
//       "Airport Name": "Stephenville Airport",
//       "Altitude (Ft)": 84,
//       "Country": "Canada",
//       "Latitude": -58.54999923706055,
//       "Location": "Stephenville",
//       "Longitude": 48.5442008972168,
//       "GMT Offset": "-3.5",
//       "Daylight Savings": "A",
//       "Region": "America/St_Johns",
//       "Type": "airport"
//     },
//     {
//       "ID": 78,
//       "Airport Code [ICAO]": "CYKA",
//       "Airport Code iATA,FAA": "YKA",
//       "Airport Name": "Kamloops Airport",
//       "Altitude (Ft)": 1133,
//       "Country": "Canada",
//       "Latitude": -120.444000244,
//       "Location": "Kamloops",
//       "Longitude": 50.7022018433,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 79,
//       "Airport Code [ICAO]": "CYKF",
//       "Airport Code iATA,FAA": "YKF",
//       "Airport Name": "Waterloo Airport",
//       "Altitude (Ft)": 1055,
//       "Country": "Canada",
//       "Latitude": -80.3786010742,
//       "Location": "Waterloo",
//       "Longitude": 43.460800170899994,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 80,
//       "Airport Code [ICAO]": "CYKL",
//       "Airport Code iATA,FAA": "YKL",
//       "Airport Name": "Schefferville Airport",
//       "Altitude (Ft)": 1709,
//       "Country": "Canada",
//       "Latitude": -66.8052978515625,
//       "Location": "Schefferville",
//       "Longitude": 54.805301666259766,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 81,
//       "Airport Code [ICAO]": "CYKY",
//       "Airport Code iATA,FAA": "YKY",
//       "Airport Name": "Kindersley Airport",
//       "Altitude (Ft)": 2277,
//       "Country": "Canada",
//       "Latitude": -109.180999756,
//       "Location": "Kindersley",
//       "Longitude": 51.5175018311,
//       "GMT Offset": "-6",
//       "Daylight Savings": "N",
//       "Region": "America/Regina",
//       "Type": "airport"
//     },
//     {
//       "ID": 82,
//       "Airport Code [ICAO]": "CYKZ",
//       "Airport Code iATA,FAA": "YKZ",
//       "Airport Name": "Buttonville Municipal Airport",
//       "Altitude (Ft)": 650,
//       "Country": "Canada",
//       "Latitude": -79.37000274658203,
//       "Location": "Toronto",
//       "Longitude": 43.86220169067383,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 83,
//       "Airport Code [ICAO]": "CYLD",
//       "Airport Code iATA,FAA": "YLD",
//       "Airport Name": "Chapleau Airport",
//       "Altitude (Ft)": 1470,
//       "Country": "Canada",
//       "Latitude": -83.3467025756836,
//       "Location": "Chapleau",
//       "Longitude": 47.81999969482422,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 84,
//       "Airport Code [ICAO]": "CYLJ",
//       "Airport Code iATA,FAA": "YLJ",
//       "Airport Name": "Meadow Lake Airport",
//       "Altitude (Ft)": 1576,
//       "Country": "Canada",
//       "Latitude": -108.52300262451172,
//       "Location": "Meadow Lake",
//       "Longitude": 54.125301361083984,
//       "GMT Offset": "-6",
//       "Daylight Savings": "N",
//       "Region": "America/Regina",
//       "Type": "airport"
//     },
//     {
//       "ID": 85,
//       "Airport Code [ICAO]": "CYLL",
//       "Airport Code iATA,FAA": "YLL",
//       "Airport Name": "Lloydminster Airport",
//       "Altitude (Ft)": 2193,
//       "Country": "Canada",
//       "Latitude": -110.072998046875,
//       "Location": "Lloydminster",
//       "Longitude": 53.309200286865234,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 86,
//       "Airport Code [ICAO]": "CYLT",
//       "Airport Code iATA,FAA": "YLT",
//       "Airport Name": "Alert Airport",
//       "Altitude (Ft)": 100,
//       "Country": "Canada",
//       "Latitude": -62.2806015015,
//       "Location": "Alert",
//       "Longitude": 82.51779937740001,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 87,
//       "Airport Code [ICAO]": "CYLW",
//       "Airport Code iATA,FAA": "YLW",
//       "Airport Name": "Kelowna International Airport",
//       "Altitude (Ft)": 1421,
//       "Country": "Canada",
//       "Latitude": -119.377998352,
//       "Location": "Kelowna",
//       "Longitude": 49.9561004639,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 88,
//       "Airport Code [ICAO]": "CYMA",
//       "Airport Code iATA,FAA": "YMA",
//       "Airport Name": "Mayo Airport",
//       "Altitude (Ft)": 1653,
//       "Country": "Canada",
//       "Latitude": -135.8679962158203,
//       "Location": "Mayo",
//       "Longitude": 63.61640167236328,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 89,
//       "Airport Code [ICAO]": "CYMJ",
//       "Airport Code iATA,FAA": "YMJ",
//       "Airport Name": "Moose Jaw Air Vice Marshal C. M. McEwen Airport",
//       "Altitude (Ft)": 1892,
//       "Country": "Canada",
//       "Latitude": -105.55899810791016,
//       "Location": "Moose Jaw",
//       "Longitude": 50.330299377441406,
//       "GMT Offset": "-6",
//       "Daylight Savings": "N",
//       "Region": "America/Regina",
//       "Type": "airport"
//     },
//     {
//       "ID": 90,
//       "Airport Code [ICAO]": "CYMM",
//       "Airport Code iATA,FAA": "YMM",
//       "Airport Name": "Fort McMurray Airport",
//       "Altitude (Ft)": 1211,
//       "Country": "Canada",
//       "Latitude": -111.222000122,
//       "Location": "Fort Mcmurray",
//       "Longitude": 56.653301239,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 91,
//       "Airport Code [ICAO]": "CYMO",
//       "Airport Code iATA,FAA": "YMO",
//       "Airport Name": "Moosonee Airport",
//       "Altitude (Ft)": 30,
//       "Country": "Canada",
//       "Latitude": -80.60780334472656,
//       "Location": "Moosonee",
//       "Longitude": 51.291099548339844,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 92,
//       "Airport Code [ICAO]": "CYMW",
//       "Airport Code iATA,FAA": "YMW",
//       "Airport Name": "Maniwaki Airport",
//       "Altitude (Ft)": 656,
//       "Country": "Canada",
//       "Latitude": -75.9906005859,
//       "Location": "Maniwaki",
//       "Longitude": 46.2728004456,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 93,
//       "Airport Code [ICAO]": "CYMX",
//       "Airport Code iATA,FAA": "YMX",
//       "Airport Name": "Montreal International (Mirabel) Airport",
//       "Altitude (Ft)": 270,
//       "Country": "Canada",
//       "Latitude": -74.038696,
//       "Location": "Montreal",
//       "Longitude": 45.679501,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 94,
//       "Airport Code [ICAO]": "CYNA",
//       "Airport Code iATA,FAA": "YNA",
//       "Airport Name": "Natashquan Airport",
//       "Altitude (Ft)": 39,
//       "Country": "Canada",
//       "Latitude": -61.78919982910156,
//       "Location": "Natashquan",
//       "Longitude": 50.189998626708984,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 95,
//       "Airport Code [ICAO]": "CYND",
//       "Airport Code iATA,FAA": "YND",
//       "Airport Name": "Ottawa / Gatineau Airport",
//       "Altitude (Ft)": 211,
//       "Country": "Canada",
//       "Latitude": -75.5635986328,
//       "Location": "Gatineau",
//       "Longitude": 45.521701812699995,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 96,
//       "Airport Code [ICAO]": "CYNM",
//       "Airport Code iATA,FAA": "YNM",
//       "Airport Name": "Matagami Airport",
//       "Altitude (Ft)": 918,
//       "Country": "Canada",
//       "Latitude": -77.80280303955078,
//       "Location": "Matagami",
//       "Longitude": 49.76169967651367,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 97,
//       "Airport Code [ICAO]": "CYOC",
//       "Airport Code iATA,FAA": "YOC",
//       "Airport Name": "Old Crow Airport",
//       "Altitude (Ft)": 824,
//       "Country": "Canada",
//       "Latitude": -139.83900451660156,
//       "Location": "Old Crow",
//       "Longitude": 67.57060241699219,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 98,
//       "Airport Code [ICAO]": "CYOD",
//       "Airport Code iATA,FAA": "YOD",
//       "Airport Name": "CFB Cold Lake",
//       "Altitude (Ft)": 1775,
//       "Country": "Canada",
//       "Latitude": -110.27899932861328,
//       "Location": "Cold Lake",
//       "Longitude": 54.404998779296875,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 99,
//       "Airport Code [ICAO]": "CYOJ",
//       "Airport Code iATA,FAA": "YOJ",
//       "Airport Name": "High Level Airport",
//       "Altitude (Ft)": 1110,
//       "Country": "Canada",
//       "Latitude": -117.16500091552734,
//       "Location": "High Level",
//       "Longitude": 58.62139892578125,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 100,
//       "Airport Code [ICAO]": "CYOW",
//       "Airport Code iATA,FAA": "YOW",
//       "Airport Name": "Ottawa Macdonald-Cartier International Airport",
//       "Altitude (Ft)": 374,
//       "Country": "Canada",
//       "Latitude": -75.66919708251953,
//       "Location": "Ottawa",
//       "Longitude": 45.3224983215332,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 101,
//       "Airport Code [ICAO]": "CYPA",
//       "Airport Code iATA,FAA": "YPA",
//       "Airport Name": "Prince Albert Glass Field",
//       "Altitude (Ft)": 1405,
//       "Country": "Canada",
//       "Latitude": -105.672996521,
//       "Location": "Prince Albert",
//       "Longitude": 53.214199066199996,
//       "GMT Offset": "-6",
//       "Daylight Savings": "N",
//       "Region": "America/Regina",
//       "Type": "airport"
//     },
//     {
//       "ID": 102,
//       "Airport Code [ICAO]": "CYPE",
//       "Airport Code iATA,FAA": "YPE",
//       "Airport Name": "Peace River Airport",
//       "Altitude (Ft)": 1873,
//       "Country": "Canada",
//       "Latitude": -117.446999,
//       "Location": "Peace River",
//       "Longitude": 56.226898,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 103,
//       "Airport Code [ICAO]": "CYPG",
//       "Airport Code iATA,FAA": "YPG",
//       "Airport Name": "Southport Airport",
//       "Altitude (Ft)": 885,
//       "Country": "Canada",
//       "Latitude": -98.273817,
//       "Location": "Portage-la-prairie",
//       "Longitude": 49.903099,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 104,
//       "Airport Code [ICAO]": "CYPK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Pitt Meadows Airport",
//       "Altitude (Ft)": 11,
//       "Country": "Canada",
//       "Latitude": -122.70999908447266,
//       "Location": "Pitt Meadows",
//       "Longitude": 49.21609878540039,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 105,
//       "Airport Code [ICAO]": "CYPL",
//       "Airport Code iATA,FAA": "YPL",
//       "Airport Name": "Pickle Lake Airport",
//       "Altitude (Ft)": 1267,
//       "Country": "Canada",
//       "Latitude": -90.21420288085938,
//       "Location": "Pickle Lake",
//       "Longitude": 51.4463996887207,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Coral_Harbour",
//       "Type": "airport"
//     },
//     {
//       "ID": 106,
//       "Airport Code [ICAO]": "CYPN",
//       "Airport Code iATA,FAA": "YPN",
//       "Airport Name": "Port Menier Airport",
//       "Altitude (Ft)": 167,
//       "Country": "Canada",
//       "Latitude": -64.2885971069336,
//       "Location": "Port Menier",
//       "Longitude": 49.83639907836914,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 107,
//       "Airport Code [ICAO]": "CYPQ",
//       "Airport Code iATA,FAA": "YPQ",
//       "Airport Name": "Peterborough Airport",
//       "Altitude (Ft)": 628,
//       "Country": "Canada",
//       "Latitude": -78.36329650878906,
//       "Location": "Peterborough",
//       "Longitude": 44.22999954223633,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 108,
//       "Airport Code [ICAO]": "CYPR",
//       "Airport Code iATA,FAA": "YPR",
//       "Airport Name": "Prince Rupert Airport",
//       "Altitude (Ft)": 116,
//       "Country": "Canada",
//       "Latitude": -130.445007324,
//       "Location": "Prince Pupert",
//       "Longitude": 54.286098480199996,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 109,
//       "Airport Code [ICAO]": "CYPY",
//       "Airport Code iATA,FAA": "YPY",
//       "Airport Name": "Fort Chipewyan Airport",
//       "Altitude (Ft)": 761,
//       "Country": "Canada",
//       "Latitude": -111.11699676513672,
//       "Location": "Fort Chipewyan",
//       "Longitude": 58.7672004699707,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 110,
//       "Airport Code [ICAO]": "CYQA",
//       "Airport Code iATA,FAA": "YQA",
//       "Airport Name": "Muskoka Airport",
//       "Altitude (Ft)": 925,
//       "Country": "Canada",
//       "Latitude": -79.30329895019531,
//       "Location": "Muskoka",
//       "Longitude": 44.974700927734375,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 111,
//       "Airport Code [ICAO]": "CYQB",
//       "Airport Code iATA,FAA": "YQB",
//       "Airport Name": "Quebec Jean Lesage International Airport",
//       "Altitude (Ft)": 244,
//       "Country": "Canada",
//       "Latitude": -71.393303,
//       "Location": "Quebec",
//       "Longitude": 46.7911,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 112,
//       "Airport Code [ICAO]": "CYQF",
//       "Airport Code iATA,FAA": "YQF",
//       "Airport Name": "Red Deer Regional Airport",
//       "Altitude (Ft)": 2968,
//       "Country": "Canada",
//       "Latitude": -113.89399719238281,
//       "Location": "Red Deer Industrial",
//       "Longitude": 52.18220138549805,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 113,
//       "Airport Code [ICAO]": "CYQG",
//       "Airport Code iATA,FAA": "YQG",
//       "Airport Name": "Windsor Airport",
//       "Altitude (Ft)": 622,
//       "Country": "Canada",
//       "Latitude": -82.95559692382812,
//       "Location": "Windsor",
//       "Longitude": 42.27560043334961,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 114,
//       "Airport Code [ICAO]": "CYQH",
//       "Airport Code iATA,FAA": "YQH",
//       "Airport Name": "Watson Lake Airport",
//       "Altitude (Ft)": 2255,
//       "Country": "Canada",
//       "Latitude": -128.82200622558594,
//       "Location": "Watson Lake",
//       "Longitude": 60.11640167236328,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 115,
//       "Airport Code [ICAO]": "CYQK",
//       "Airport Code iATA,FAA": "YQK",
//       "Airport Name": "Kenora Airport",
//       "Altitude (Ft)": 1332,
//       "Country": "Canada",
//       "Latitude": -94.36309814453125,
//       "Location": "Kenora",
//       "Longitude": 49.788299560546875,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 116,
//       "Airport Code [ICAO]": "CYQL",
//       "Airport Code iATA,FAA": "YQL",
//       "Airport Name": "Lethbridge County Airport",
//       "Altitude (Ft)": 3048,
//       "Country": "Canada",
//       "Latitude": -112.800003052,
//       "Location": "Lethbridge",
//       "Longitude": 49.6302986145,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 117,
//       "Airport Code [ICAO]": "CYQM",
//       "Airport Code iATA,FAA": "YQM",
//       "Airport Name": "Greater Moncton International Airport",
//       "Altitude (Ft)": 232,
//       "Country": "Canada",
//       "Latitude": -64.67859649658203,
//       "Location": "Moncton",
//       "Longitude": 46.11220169067383,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Halifax",
//       "Type": "airport"
//     },
//     {
//       "ID": 119,
//       "Airport Code [ICAO]": "CYQQ",
//       "Airport Code iATA,FAA": "YQQ",
//       "Airport Name": "Comox Airport",
//       "Altitude (Ft)": 84,
//       "Country": "Canada",
//       "Latitude": -124.88700103759766,
//       "Location": "Comox",
//       "Longitude": 49.71080017089844,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 120,
//       "Airport Code [ICAO]": "CYQR",
//       "Airport Code iATA,FAA": "YQR",
//       "Airport Name": "Regina International Airport",
//       "Altitude (Ft)": 1894,
//       "Country": "Canada",
//       "Latitude": -104.66600036621094,
//       "Location": "Regina",
//       "Longitude": 50.43190002441406,
//       "GMT Offset": "-6",
//       "Daylight Savings": "N",
//       "Region": "America/Regina",
//       "Type": "airport"
//     },
//     {
//       "ID": 121,
//       "Airport Code [ICAO]": "CYQT",
//       "Airport Code iATA,FAA": "YQT",
//       "Airport Name": "Thunder Bay Airport",
//       "Altitude (Ft)": 653,
//       "Country": "Canada",
//       "Latitude": -89.32389831542969,
//       "Location": "Thunder Bay",
//       "Longitude": 48.37189865112305,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 122,
//       "Airport Code [ICAO]": "CYQU",
//       "Airport Code iATA,FAA": "YQU",
//       "Airport Name": "Grande Prairie Airport",
//       "Altitude (Ft)": 2195,
//       "Country": "Canada",
//       "Latitude": -118.885002136,
//       "Location": "Grande Prairie",
//       "Longitude": 55.1796989441,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 123,
//       "Airport Code [ICAO]": "CYQV",
//       "Airport Code iATA,FAA": "YQV",
//       "Airport Name": "Yorkton Municipal Airport",
//       "Altitude (Ft)": 1635,
//       "Country": "Canada",
//       "Latitude": -102.46199798583984,
//       "Location": "Yorkton",
//       "Longitude": 51.26470184326172,
//       "GMT Offset": "-6",
//       "Daylight Savings": "N",
//       "Region": "America/Regina",
//       "Type": "airport"
//     },
//     {
//       "ID": 124,
//       "Airport Code [ICAO]": "CYQW",
//       "Airport Code iATA,FAA": "YQW",
//       "Airport Name": "North Battleford Airport",
//       "Altitude (Ft)": 1799,
//       "Country": "Canada",
//       "Latitude": -108.24400329589844,
//       "Location": "North Battleford",
//       "Longitude": 52.76919937133789,
//       "GMT Offset": "-6",
//       "Daylight Savings": "N",
//       "Region": "America/Regina",
//       "Type": "airport"
//     },
//     {
//       "ID": 125,
//       "Airport Code [ICAO]": "CYQX",
//       "Airport Code iATA,FAA": "YQX",
//       "Airport Name": "Gander International Airport",
//       "Altitude (Ft)": 496,
//       "Country": "Canada",
//       "Latitude": -54.56809997558594,
//       "Location": "Gander",
//       "Longitude": 48.9369010925293,
//       "GMT Offset": "-3.5",
//       "Daylight Savings": "A",
//       "Region": "America/St_Johns",
//       "Type": "airport"
//     },
//     {
//       "ID": 126,
//       "Airport Code [ICAO]": "CYQY",
//       "Airport Code iATA,FAA": "YQY",
//       "Airport Name": "Sydney / J.A. Douglas McCurdy Airport",
//       "Altitude (Ft)": 203,
//       "Country": "Canada",
//       "Latitude": -60.047798,
//       "Location": "Sydney",
//       "Longitude": 46.1614,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Halifax",
//       "Type": "airport"
//     },
//     {
//       "ID": 127,
//       "Airport Code [ICAO]": "CYQZ",
//       "Airport Code iATA,FAA": "YQZ",
//       "Airport Name": "Quesnel Airport",
//       "Altitude (Ft)": 1789,
//       "Country": "Canada",
//       "Latitude": -122.51000213623047,
//       "Location": "Quesnel",
//       "Longitude": 53.026100158691406,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 128,
//       "Airport Code [ICAO]": "CYRB",
//       "Airport Code iATA,FAA": "YRB",
//       "Airport Name": "Resolute Bay Airport",
//       "Altitude (Ft)": 215,
//       "Country": "Canada",
//       "Latitude": -94.9693984985,
//       "Location": "Resolute",
//       "Longitude": 74.7169036865,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 129,
//       "Airport Code [ICAO]": "CYRI",
//       "Airport Code iATA,FAA": "YRI",
//       "Airport Name": "Rivière-du-Loup Airport",
//       "Altitude (Ft)": 427,
//       "Country": "Canada",
//       "Latitude": -69.58470153808594,
//       "Location": "Riviere Du Loup",
//       "Longitude": 47.764400482177734,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 130,
//       "Airport Code [ICAO]": "CYRJ",
//       "Airport Code iATA,FAA": "YRJ",
//       "Airport Name": "Roberval Airport",
//       "Altitude (Ft)": 586,
//       "Country": "Canada",
//       "Latitude": -72.2656021118164,
//       "Location": "Roberval",
//       "Longitude": 48.52000045776367,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 131,
//       "Airport Code [ICAO]": "CYRM",
//       "Airport Code iATA,FAA": "YRM",
//       "Airport Name": "Rocky Mountain House Airport",
//       "Altitude (Ft)": 3244,
//       "Country": "Canada",
//       "Latitude": -114.903999329,
//       "Location": "Rocky Mountain House",
//       "Longitude": 52.4296989441,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 132,
//       "Airport Code [ICAO]": "CYRT",
//       "Airport Code iATA,FAA": "YRT",
//       "Airport Name": "Rankin Inlet Airport",
//       "Altitude (Ft)": 94,
//       "Country": "Canada",
//       "Latitude": -92.1157989502,
//       "Location": "Rankin Inlet",
//       "Longitude": 62.8114013672,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 133,
//       "Airport Code [ICAO]": "CYSB",
//       "Airport Code iATA,FAA": "YSB",
//       "Airport Name": "Sudbury Airport",
//       "Altitude (Ft)": 1141,
//       "Country": "Canada",
//       "Latitude": -80.79889678955078,
//       "Location": "Sudbury",
//       "Longitude": 46.625,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 134,
//       "Airport Code [ICAO]": "CYSC",
//       "Airport Code iATA,FAA": "YSC",
//       "Airport Name": "Sherbrooke Airport",
//       "Altitude (Ft)": 792,
//       "Country": "Canada",
//       "Latitude": -71.69139862060547,
//       "Location": "Sherbrooke",
//       "Longitude": 45.4385986328125,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 135,
//       "Airport Code [ICAO]": "CYSJ",
//       "Airport Code iATA,FAA": "YSJ",
//       "Airport Name": "Saint John Airport",
//       "Altitude (Ft)": 357,
//       "Country": "Canada",
//       "Latitude": -65.89029693603516,
//       "Location": "St. John",
//       "Longitude": 45.31610107421875,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Halifax",
//       "Type": "airport"
//     },
//     {
//       "ID": 136,
//       "Airport Code [ICAO]": "CYSM",
//       "Airport Code iATA,FAA": "YSM",
//       "Airport Name": "Fort Smith Airport",
//       "Altitude (Ft)": 671,
//       "Country": "Canada",
//       "Latitude": -111.96199798583984,
//       "Location": "Fort Smith",
//       "Longitude": 60.020301818847656,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 137,
//       "Airport Code [ICAO]": "CYSR",
//       "Airport Code iATA,FAA": "YSR",
//       "Airport Name": "Nanisivik Airport",
//       "Altitude (Ft)": 2106,
//       "Country": "Canada",
//       "Latitude": -84.613602,
//       "Location": "Nanisivik",
//       "Longitude": 72.982201,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 138,
//       "Airport Code [ICAO]": "CYSU",
//       "Airport Code iATA,FAA": "YSU",
//       "Airport Name": "Summerside Airport",
//       "Altitude (Ft)": 56,
//       "Country": "Canada",
//       "Latitude": -63.83359909057617,
//       "Location": "Summerside",
//       "Longitude": 46.44060134887695,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Halifax",
//       "Type": "airport"
//     },
//     {
//       "ID": 139,
//       "Airport Code [ICAO]": "CYSY",
//       "Airport Code iATA,FAA": "YSY",
//       "Airport Name": "Sachs Harbour (David Nasogaluak Jr. Saaryuaq) Airport",
//       "Altitude (Ft)": 282,
//       "Country": "Canada",
//       "Latitude": -125.242996216,
//       "Location": "Sachs Harbour",
//       "Longitude": 71.9938964844,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 140,
//       "Airport Code [ICAO]": "CYTE",
//       "Airport Code iATA,FAA": "YTE",
//       "Airport Name": "Cape Dorset Airport",
//       "Altitude (Ft)": 164,
//       "Country": "Canada",
//       "Latitude": -76.5267028809,
//       "Location": "Cape Dorset",
//       "Longitude": 64.2300033569,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 141,
//       "Airport Code [ICAO]": "CYTH",
//       "Airport Code iATA,FAA": "YTH",
//       "Airport Name": "Thompson Airport",
//       "Altitude (Ft)": 729,
//       "Country": "Canada",
//       "Latitude": -97.86419677734375,
//       "Location": "Thompson",
//       "Longitude": 55.80110168457031,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 142,
//       "Airport Code [ICAO]": "CYTR",
//       "Airport Code iATA,FAA": "YTR",
//       "Airport Name": "CFB Trenton",
//       "Altitude (Ft)": 283,
//       "Country": "Canada",
//       "Latitude": -77.5280990600586,
//       "Location": "Trenton",
//       "Longitude": 44.118900299072266,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 143,
//       "Airport Code [ICAO]": "CYTS",
//       "Airport Code iATA,FAA": "YTS",
//       "Airport Name": "Timmins/Victor M. Power",
//       "Altitude (Ft)": 967,
//       "Country": "Canada",
//       "Latitude": -81.376701355,
//       "Location": "Timmins",
//       "Longitude": 48.569698333699996,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 144,
//       "Airport Code [ICAO]": "CYTZ",
//       "Airport Code iATA,FAA": "YTZ",
//       "Airport Name": "Billy Bishop Toronto City Centre Airport",
//       "Altitude (Ft)": 252,
//       "Country": "Canada",
//       "Latitude": -79.396202,
//       "Location": "Toronto",
//       "Longitude": 43.627499,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 145,
//       "Airport Code [ICAO]": "CYUB",
//       "Airport Code iATA,FAA": "YUB",
//       "Airport Name": "Tuktoyaktuk Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Canada",
//       "Latitude": -133.0260009765625,
//       "Location": "Tuktoyaktuk",
//       "Longitude": 69.43329620361328,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 146,
//       "Airport Code [ICAO]": "CYUL",
//       "Airport Code iATA,FAA": "YUL",
//       "Airport Name": "Montreal / Pierre Elliott Trudeau International Airport",
//       "Altitude (Ft)": 118,
//       "Country": "Canada",
//       "Latitude": -73.7407989502,
//       "Location": "Montreal",
//       "Longitude": 45.4706001282,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 147,
//       "Airport Code [ICAO]": "CYUT",
//       "Airport Code iATA,FAA": "YUT",
//       "Airport Name": "Repulse Bay Airport",
//       "Altitude (Ft)": 80,
//       "Country": "Canada",
//       "Latitude": -86.22470092770001,
//       "Location": "Repulse Bay",
//       "Longitude": 66.5214004517,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 148,
//       "Airport Code [ICAO]": "CYUX",
//       "Airport Code iATA,FAA": "YUX",
//       "Airport Name": "Hall Beach Airport",
//       "Altitude (Ft)": 30,
//       "Country": "Canada",
//       "Latitude": -81.2425,
//       "Location": "Hall Beach",
//       "Longitude": 68.77610015869999,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 149,
//       "Airport Code [ICAO]": "CYUY",
//       "Airport Code iATA,FAA": "YUY",
//       "Airport Name": "Rouyn Noranda Airport",
//       "Altitude (Ft)": 988,
//       "Country": "Canada",
//       "Latitude": -78.83560180664062,
//       "Location": "Rouyn",
//       "Longitude": 48.20610046386719,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 150,
//       "Airport Code [ICAO]": "CYVC",
//       "Airport Code iATA,FAA": "YVC",
//       "Airport Name": "La Ronge Airport",
//       "Altitude (Ft)": 1242,
//       "Country": "Canada",
//       "Latitude": -105.262001038,
//       "Location": "La Ronge",
//       "Longitude": 55.151401519800004,
//       "GMT Offset": "-6",
//       "Daylight Savings": "N",
//       "Region": "America/Regina",
//       "Type": "airport"
//     },
//     {
//       "ID": 151,
//       "Airport Code [ICAO]": "CYVG",
//       "Airport Code iATA,FAA": "YVG",
//       "Airport Name": "Vermilion Airport",
//       "Altitude (Ft)": 2025,
//       "Country": "Canada",
//       "Latitude": -110.823997498,
//       "Location": "Vermillion",
//       "Longitude": 53.355800628699996,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 152,
//       "Airport Code [ICAO]": "CYVM",
//       "Airport Code iATA,FAA": "YVM",
//       "Airport Name": "Qikiqtarjuaq Airport",
//       "Altitude (Ft)": 21,
//       "Country": "Canada",
//       "Latitude": -64.03140258789999,
//       "Location": "Broughton Island",
//       "Longitude": 67.5457992554,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 153,
//       "Airport Code [ICAO]": "CYVO",
//       "Airport Code iATA,FAA": "YVO",
//       "Airport Name": "Val-d'Or Airport",
//       "Altitude (Ft)": 1107,
//       "Country": "Canada",
//       "Latitude": -77.7827987671,
//       "Location": "Val D'or",
//       "Longitude": 48.0532989502,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 154,
//       "Airport Code [ICAO]": "CYVP",
//       "Airport Code iATA,FAA": "YVP",
//       "Airport Name": "Kuujjuaq Airport",
//       "Altitude (Ft)": 129,
//       "Country": "Canada",
//       "Latitude": -68.4269027709961,
//       "Location": "Quujjuaq",
//       "Longitude": 58.096099853515625,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 155,
//       "Airport Code [ICAO]": "CYVQ",
//       "Airport Code iATA,FAA": "YVQ",
//       "Airport Name": "Norman Wells Airport",
//       "Altitude (Ft)": 238,
//       "Country": "Canada",
//       "Latitude": -126.7979965209961,
//       "Location": "Norman Wells",
//       "Longitude": 65.28160095214844,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 156,
//       "Airport Code [ICAO]": "CYVR",
//       "Airport Code iATA,FAA": "YVR",
//       "Airport Name": "Vancouver International Airport",
//       "Altitude (Ft)": 14,
//       "Country": "Canada",
//       "Latitude": -123.183998108,
//       "Location": "Vancouver",
//       "Longitude": 49.193901062,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 157,
//       "Airport Code [ICAO]": "CYVT",
//       "Airport Code iATA,FAA": "YVT",
//       "Airport Name": "Buffalo Narrows Airport",
//       "Altitude (Ft)": 1423,
//       "Country": "Canada",
//       "Latitude": -108.417999268,
//       "Location": "Buffalo Narrows",
//       "Longitude": 55.8418998718,
//       "GMT Offset": "-6",
//       "Daylight Savings": "N",
//       "Region": "America/Regina",
//       "Type": "airport"
//     },
//     {
//       "ID": 158,
//       "Airport Code [ICAO]": "CYVV",
//       "Airport Code iATA,FAA": "YVV",
//       "Airport Name": "Wiarton Airport",
//       "Altitude (Ft)": 729,
//       "Country": "Canada",
//       "Latitude": -81.107201,
//       "Location": "Wiarton",
//       "Longitude": 44.7458,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 159,
//       "Airport Code [ICAO]": "CYWA",
//       "Airport Code iATA,FAA": "YWA",
//       "Airport Name": "Petawawa Airport",
//       "Altitude (Ft)": 427,
//       "Country": "Canada",
//       "Latitude": -77.31919860839844,
//       "Location": "Petawawa",
//       "Longitude": 45.95220184326172,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 160,
//       "Airport Code [ICAO]": "CYWG",
//       "Airport Code iATA,FAA": "YWG",
//       "Airport Name": "Winnipeg / James Armstrong Richardson International Airport",
//       "Altitude (Ft)": 783,
//       "Country": "Canada",
//       "Latitude": -97.2398986816,
//       "Location": "Winnipeg",
//       "Longitude": 49.909999847399995,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 161,
//       "Airport Code [ICAO]": "CYWK",
//       "Airport Code iATA,FAA": "YWK",
//       "Airport Name": "Wabush Airport",
//       "Altitude (Ft)": 1808,
//       "Country": "Canada",
//       "Latitude": -66.8644027709961,
//       "Location": "Wabush",
//       "Longitude": 52.92190170288086,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Halifax",
//       "Type": "airport"
//     },
//     {
//       "ID": 162,
//       "Airport Code [ICAO]": "CYWL",
//       "Airport Code iATA,FAA": "YWL",
//       "Airport Name": "Williams Lake Airport",
//       "Altitude (Ft)": 3085,
//       "Country": "Canada",
//       "Latitude": -122.054000854,
//       "Location": "Williams Lake",
//       "Longitude": 52.1831016541,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 163,
//       "Airport Code [ICAO]": "CYWY",
//       "Airport Code iATA,FAA": "YWY",
//       "Airport Name": "Wrigley Airport",
//       "Altitude (Ft)": 489,
//       "Country": "Canada",
//       "Latitude": -123.43699645996094,
//       "Location": "Wrigley",
//       "Longitude": 63.20940017700195,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 164,
//       "Airport Code [ICAO]": "CYXC",
//       "Airport Code iATA,FAA": "YXC",
//       "Airport Name": "Cranbrook/Canadian Rockies International Airport",
//       "Altitude (Ft)": 3082,
//       "Country": "Canada",
//       "Latitude": -115.78199768066,
//       "Location": "Cranbrook",
//       "Longitude": 49.610801696777,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 165,
//       "Airport Code [ICAO]": "CYXD",
//       "Airport Code iATA,FAA": "YXD",
//       "Airport Name": "Edmonton City Centre (Blatchford Field) Airport",
//       "Altitude (Ft)": 2202,
//       "Country": "Canada",
//       "Latitude": -113.521003723,
//       "Location": "Edmonton",
//       "Longitude": 53.5724983215,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 166,
//       "Airport Code [ICAO]": "CYXE",
//       "Airport Code iATA,FAA": "YXE",
//       "Airport Name": "Saskatoon John G. Diefenbaker International Airport",
//       "Altitude (Ft)": 1653,
//       "Country": "Canada",
//       "Latitude": -106.69999694824219,
//       "Location": "Saskatoon",
//       "Longitude": 52.170799255371094,
//       "GMT Offset": "-6",
//       "Daylight Savings": "N",
//       "Region": "America/Regina",
//       "Type": "airport"
//     },
//     {
//       "ID": 167,
//       "Airport Code [ICAO]": "CYXH",
//       "Airport Code iATA,FAA": "YXH",
//       "Airport Name": "Medicine Hat Airport",
//       "Altitude (Ft)": 2352,
//       "Country": "Canada",
//       "Latitude": -110.72100067138672,
//       "Location": "Medicine Hat",
//       "Longitude": 50.01890182495117,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 168,
//       "Airport Code [ICAO]": "CYXJ",
//       "Airport Code iATA,FAA": "YXJ",
//       "Airport Name": "Fort St John Airport",
//       "Altitude (Ft)": 2280,
//       "Country": "Canada",
//       "Latitude": -120.73999786376953,
//       "Location": "Fort Saint John",
//       "Longitude": 56.23809814453125,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Dawson_Creek",
//       "Type": "airport"
//     },
//     {
//       "ID": 169,
//       "Airport Code [ICAO]": "CYXL",
//       "Airport Code iATA,FAA": "YXL",
//       "Airport Name": "Sioux Lookout Airport",
//       "Altitude (Ft)": 1258,
//       "Country": "Canada",
//       "Latitude": -91.9052963256836,
//       "Location": "Sioux Lookout",
//       "Longitude": 50.11389923095703,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 170,
//       "Airport Code [ICAO]": "CYXP",
//       "Airport Code iATA,FAA": "YXP",
//       "Airport Name": "Pangnirtung Airport",
//       "Altitude (Ft)": 75,
//       "Country": "Canada",
//       "Latitude": -65.71360015869999,
//       "Location": "Pangnirtung",
//       "Longitude": 66.1449966431,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 171,
//       "Airport Code [ICAO]": "CYXR",
//       "Airport Code iATA,FAA": "YXR",
//       "Airport Name": "Earlton (Timiskaming Regional) Airport",
//       "Altitude (Ft)": 800,
//       "Country": "Canada",
//       "Latitude": -79.8473453522,
//       "Location": "Earlton",
//       "Longitude": 47.697400654599996,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 172,
//       "Airport Code [ICAO]": "CYXS",
//       "Airport Code iATA,FAA": "YXS",
//       "Airport Name": "Prince George Airport",
//       "Altitude (Ft)": 2267,
//       "Country": "Canada",
//       "Latitude": -122.679000854,
//       "Location": "Prince George",
//       "Longitude": 53.8894004822,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 173,
//       "Airport Code [ICAO]": "CYXT",
//       "Airport Code iATA,FAA": "YXT",
//       "Airport Name": "Northwest Regional Airport Terrace-Kitimat",
//       "Altitude (Ft)": 713,
//       "Country": "Canada",
//       "Latitude": -128.576009,
//       "Location": "Terrace",
//       "Longitude": 54.468498,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 174,
//       "Airport Code [ICAO]": "CYXU",
//       "Airport Code iATA,FAA": "YXU",
//       "Airport Name": "London Airport",
//       "Altitude (Ft)": 912,
//       "Country": "Canada",
//       "Latitude": -81.1539,
//       "Location": "London",
//       "Longitude": 43.035599,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 175,
//       "Airport Code [ICAO]": "CYXX",
//       "Airport Code iATA,FAA": "YXX",
//       "Airport Name": "Abbotsford Airport",
//       "Altitude (Ft)": 195,
//       "Country": "Canada",
//       "Latitude": -122.36100006103516,
//       "Location": "Abbotsford",
//       "Longitude": 49.025299072265625,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 176,
//       "Airport Code [ICAO]": "CYXY",
//       "Airport Code iATA,FAA": "YXY",
//       "Airport Name": "Whitehorse / Erik Nielsen International Airport",
//       "Altitude (Ft)": 2317,
//       "Country": "Canada",
//       "Latitude": -135.067001343,
//       "Location": "Whitehorse",
//       "Longitude": 60.7095985413,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 177,
//       "Airport Code [ICAO]": "CYYB",
//       "Airport Code iATA,FAA": "YYB",
//       "Airport Name": "North Bay Jack Garland Airport",
//       "Altitude (Ft)": 1215,
//       "Country": "Canada",
//       "Latitude": -79.422798,
//       "Location": "North Bay",
//       "Longitude": 46.363602,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 178,
//       "Airport Code [ICAO]": "CYYC",
//       "Airport Code iATA,FAA": "YYC",
//       "Airport Name": "Calgary International Airport",
//       "Altitude (Ft)": 3557,
//       "Country": "Canada",
//       "Latitude": -114.019996643,
//       "Location": "Calgary",
//       "Longitude": 51.113899231,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 179,
//       "Airport Code [ICAO]": "CYYD",
//       "Airport Code iATA,FAA": "YYD",
//       "Airport Name": "Smithers Airport",
//       "Altitude (Ft)": 1712,
//       "Country": "Canada",
//       "Latitude": -127.18299865722656,
//       "Location": "Smithers",
//       "Longitude": 54.82469940185547,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 180,
//       "Airport Code [ICAO]": "CYYE",
//       "Airport Code iATA,FAA": "YYE",
//       "Airport Name": "Fort Nelson Airport",
//       "Altitude (Ft)": 1253,
//       "Country": "Canada",
//       "Latitude": -122.597000122,
//       "Location": "Fort Nelson",
//       "Longitude": 58.8363990784,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 181,
//       "Airport Code [ICAO]": "CYYF",
//       "Airport Code iATA,FAA": "YYF",
//       "Airport Name": "Penticton Airport",
//       "Altitude (Ft)": 1129,
//       "Country": "Canada",
//       "Latitude": -119.60199737548828,
//       "Location": "Penticton",
//       "Longitude": 49.46310043334961,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 182,
//       "Airport Code [ICAO]": "CYYG",
//       "Airport Code iATA,FAA": "YYG",
//       "Airport Name": "Charlottetown Airport",
//       "Altitude (Ft)": 160,
//       "Country": "Canada",
//       "Latitude": -63.12110137939453,
//       "Location": "Charlottetown",
//       "Longitude": 46.290000915527344,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Halifax",
//       "Type": "airport"
//     },
//     {
//       "ID": 183,
//       "Airport Code [ICAO]": "CYYH",
//       "Airport Code iATA,FAA": "YYH",
//       "Airport Name": "Taloyoak Airport",
//       "Altitude (Ft)": 92,
//       "Country": "Canada",
//       "Latitude": -93.5766983032,
//       "Location": "Spence Bay",
//       "Longitude": 69.5466995239,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 184,
//       "Airport Code [ICAO]": "CYYJ",
//       "Airport Code iATA,FAA": "YYJ",
//       "Airport Name": "Victoria International Airport",
//       "Altitude (Ft)": 63,
//       "Country": "Canada",
//       "Latitude": -123.426002502,
//       "Location": "Victoria",
//       "Longitude": 48.646900177,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 185,
//       "Airport Code [ICAO]": "CYYL",
//       "Airport Code iATA,FAA": "YYL",
//       "Airport Name": "Lynn Lake Airport",
//       "Altitude (Ft)": 1170,
//       "Country": "Canada",
//       "Latitude": -101.07599639892578,
//       "Location": "Lynn Lake",
//       "Longitude": 56.86389923095703,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 186,
//       "Airport Code [ICAO]": "CYYN",
//       "Airport Code iATA,FAA": "YYN",
//       "Airport Name": "Swift Current Airport",
//       "Altitude (Ft)": 2680,
//       "Country": "Canada",
//       "Latitude": -107.691001892,
//       "Location": "Swift Current",
//       "Longitude": 50.291900634799994,
//       "GMT Offset": "-6",
//       "Daylight Savings": "N",
//       "Region": "America/Regina",
//       "Type": "airport"
//     },
//     {
//       "ID": 187,
//       "Airport Code [ICAO]": "CYYQ",
//       "Airport Code iATA,FAA": "YYQ",
//       "Airport Name": "Churchill Airport",
//       "Altitude (Ft)": 94,
//       "Country": "Canada",
//       "Latitude": -94.06500244140625,
//       "Location": "Churchill",
//       "Longitude": 58.739200592041016,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 188,
//       "Airport Code [ICAO]": "CYYR",
//       "Airport Code iATA,FAA": "YYR",
//       "Airport Name": "Goose Bay Airport",
//       "Altitude (Ft)": 160,
//       "Country": "Canada",
//       "Latitude": -60.4258003235,
//       "Location": "Goose Bay",
//       "Longitude": 53.3191986084,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Halifax",
//       "Type": "airport"
//     },
//     {
//       "ID": 189,
//       "Airport Code [ICAO]": "CYYT",
//       "Airport Code iATA,FAA": "YYT",
//       "Airport Name": "St. John's International Airport",
//       "Altitude (Ft)": 461,
//       "Country": "Canada",
//       "Latitude": -52.7518997192,
//       "Location": "St. John's",
//       "Longitude": 47.618598938,
//       "GMT Offset": "-3.5",
//       "Daylight Savings": "A",
//       "Region": "America/St_Johns",
//       "Type": "airport"
//     },
//     {
//       "ID": 190,
//       "Airport Code [ICAO]": "CYYU",
//       "Airport Code iATA,FAA": "YYU",
//       "Airport Name": "Kapuskasing Airport",
//       "Altitude (Ft)": 743,
//       "Country": "Canada",
//       "Latitude": -82.46749877929688,
//       "Location": "Kapuskasing",
//       "Longitude": 49.41389846801758,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 191,
//       "Airport Code [ICAO]": "CYYW",
//       "Airport Code iATA,FAA": "YYW",
//       "Airport Name": "Armstrong Airport",
//       "Altitude (Ft)": 1058,
//       "Country": "Canada",
//       "Latitude": -88.90969848632812,
//       "Location": "Armstrong",
//       "Longitude": 50.29029846191406,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 192,
//       "Airport Code [ICAO]": "CYYY",
//       "Airport Code iATA,FAA": "YYY",
//       "Airport Name": "Mont Joli Airport",
//       "Altitude (Ft)": 172,
//       "Country": "Canada",
//       "Latitude": -68.20809936523438,
//       "Location": "Mont Joli",
//       "Longitude": 48.60860061645508,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 193,
//       "Airport Code [ICAO]": "CYYZ",
//       "Airport Code iATA,FAA": "YYZ",
//       "Airport Name": "Lester B. Pearson International Airport",
//       "Altitude (Ft)": 569,
//       "Country": "Canada",
//       "Latitude": -79.63059997559999,
//       "Location": "Toronto",
//       "Longitude": 43.6772003174,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 194,
//       "Airport Code [ICAO]": "CYZD",
//       "Airport Code iATA,FAA": "YZD",
//       "Airport Name": "Downsview Airport",
//       "Altitude (Ft)": 652,
//       "Country": "Canada",
//       "Latitude": -79.4655990600586,
//       "Location": "Toronto",
//       "Longitude": 43.74250030517578,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 195,
//       "Airport Code [ICAO]": "CYZE",
//       "Airport Code iATA,FAA": "YZE",
//       "Airport Name": "Gore Bay Manitoulin Airport",
//       "Altitude (Ft)": 635,
//       "Country": "Canada",
//       "Latitude": -82.56780242919922,
//       "Location": "Gore Bay",
//       "Longitude": 45.88529968261719,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 196,
//       "Airport Code [ICAO]": "CYZF",
//       "Airport Code iATA,FAA": "YZF",
//       "Airport Name": "Yellowknife Airport",
//       "Altitude (Ft)": 675,
//       "Country": "Canada",
//       "Latitude": -114.44000244140625,
//       "Location": "Yellowknife",
//       "Longitude": 62.462799072265625,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 197,
//       "Airport Code [ICAO]": "CYZH",
//       "Airport Code iATA,FAA": "YZH",
//       "Airport Name": "Slave Lake Airport",
//       "Altitude (Ft)": 1912,
//       "Country": "Canada",
//       "Latitude": -114.777000427,
//       "Location": "Slave Lake",
//       "Longitude": 55.2930984497,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 198,
//       "Airport Code [ICAO]": "CYZP",
//       "Airport Code iATA,FAA": "YZP",
//       "Airport Name": "Sandspit Airport",
//       "Altitude (Ft)": 21,
//       "Country": "Canada",
//       "Latitude": -131.813995361,
//       "Location": "Sandspit",
//       "Longitude": 53.25429916379999,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 199,
//       "Airport Code [ICAO]": "CYZR",
//       "Airport Code iATA,FAA": "YZR",
//       "Airport Name": "Chris Hadfield Airport",
//       "Altitude (Ft)": 594,
//       "Country": "Canada",
//       "Latitude": -82.30889892578125,
//       "Location": "Sarnia",
//       "Longitude": 42.9994010925293,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 200,
//       "Airport Code [ICAO]": "CYZT",
//       "Airport Code iATA,FAA": "YZT",
//       "Airport Name": "Port Hardy Airport",
//       "Altitude (Ft)": 71,
//       "Country": "Canada",
//       "Latitude": -127.36699676513672,
//       "Location": "Port Hardy",
//       "Longitude": 50.680599212646484,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 201,
//       "Airport Code [ICAO]": "CYZU",
//       "Airport Code iATA,FAA": "YZU",
//       "Airport Name": "Whitecourt Airport",
//       "Altitude (Ft)": 2567,
//       "Country": "Canada",
//       "Latitude": -115.78700256347656,
//       "Location": "Whitecourt",
//       "Longitude": 54.14390182495117,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 202,
//       "Airport Code [ICAO]": "CYZV",
//       "Airport Code iATA,FAA": "YZV",
//       "Airport Name": "Sept-Îles Airport",
//       "Altitude (Ft)": 180,
//       "Country": "Canada",
//       "Latitude": -66.2656021118164,
//       "Location": "Sept-iles",
//       "Longitude": 50.22330093383789,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 203,
//       "Airport Code [ICAO]": "CYZW",
//       "Airport Code iATA,FAA": "YZW",
//       "Airport Name": "Teslin Airport",
//       "Altitude (Ft)": 2313,
//       "Country": "Canada",
//       "Latitude": -132.7429962158203,
//       "Location": "Teslin",
//       "Longitude": 60.17279815673828,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 204,
//       "Airport Code [ICAO]": "CYZX",
//       "Airport Code iATA,FAA": "YZX",
//       "Airport Name": "CFB Greenwood",
//       "Altitude (Ft)": 92,
//       "Country": "Canada",
//       "Latitude": -64.91690063476562,
//       "Location": "Greenwood",
//       "Longitude": 44.98440170288086,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Halifax",
//       "Type": "airport"
//     },
//     {
//       "ID": 205,
//       "Airport Code [ICAO]": "CZFA",
//       "Airport Code iATA,FAA": "ZFA",
//       "Airport Name": "Faro Airport",
//       "Altitude (Ft)": 2351,
//       "Country": "Canada",
//       "Latitude": -133.37600708007812,
//       "Location": "Faro",
//       "Longitude": 62.20750045776367,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 206,
//       "Airport Code [ICAO]": "CZFM",
//       "Airport Code iATA,FAA": "ZFM",
//       "Airport Name": "Fort Mcpherson Airport",
//       "Altitude (Ft)": 116,
//       "Country": "Canada",
//       "Latitude": -134.86099243164062,
//       "Location": "Fort Mcpherson",
//       "Longitude": 67.40750122070312,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 207,
//       "Airport Code [ICAO]": "DAAB",
//       "Airport Code iATA,FAA": "QLD",
//       "Airport Name": "Blida Airport",
//       "Altitude (Ft)": 535,
//       "Country": "Algeria",
//       "Latitude": 2.8141698837280273,
//       "Location": "Blida",
//       "Longitude": 36.50360107421875,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 208,
//       "Airport Code [ICAO]": "DAAD",
//       "Airport Code iATA,FAA": "BUJ",
//       "Airport Name": "Bou Saada Airport",
//       "Altitude (Ft)": 1506,
//       "Country": "Algeria",
//       "Latitude": 4.206389904022217,
//       "Location": "Bou Saada",
//       "Longitude": 35.33250045776367,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 209,
//       "Airport Code [ICAO]": "DAAE",
//       "Airport Code iATA,FAA": "BJA",
//       "Airport Name": "Soummam Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Algeria",
//       "Latitude": 5.0699200630200005,
//       "Location": "Bejaja",
//       "Longitude": 36.7120018005,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 210,
//       "Airport Code [ICAO]": "DAAG",
//       "Airport Code iATA,FAA": "ALG",
//       "Airport Name": "Houari Boumediene Airport",
//       "Altitude (Ft)": 82,
//       "Country": "Algeria",
//       "Latitude": 3.215409994125366,
//       "Location": "Algier",
//       "Longitude": 36.691001892089844,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 211,
//       "Airport Code [ICAO]": "DAAJ",
//       "Airport Code iATA,FAA": "DJG",
//       "Airport Name": "Djanet Inedbirene Airport",
//       "Altitude (Ft)": 3176,
//       "Country": "Algeria",
//       "Latitude": 9.45244,
//       "Location": "Djanet",
//       "Longitude": 24.292801,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 212,
//       "Airport Code [ICAO]": "DAAK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Boufarik Airport",
//       "Altitude (Ft)": 335,
//       "Country": "Algeria",
//       "Latitude": 2.87611,
//       "Location": "Boufarik",
//       "Longitude": 36.545799,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 213,
//       "Airport Code [ICAO]": "DAAN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Reggane Airport",
//       "Altitude (Ft)": 955,
//       "Country": "Algeria",
//       "Latitude": 0.285647,
//       "Location": "Reggan",
//       "Longitude": 26.7101,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 214,
//       "Airport Code [ICAO]": "DAAP",
//       "Airport Code iATA,FAA": "VVZ",
//       "Airport Name": "Illizi Takhamalt Airport",
//       "Altitude (Ft)": 1778,
//       "Country": "Algeria",
//       "Latitude": 8.62265014648,
//       "Location": "Illizi",
//       "Longitude": 26.7234992981,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 215,
//       "Airport Code [ICAO]": "DAAQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ain Oussera Airport",
//       "Altitude (Ft)": 2132,
//       "Country": "Algeria",
//       "Latitude": 2.8787100315093994,
//       "Location": "Ain Oussera",
//       "Longitude": 35.52539825439453,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 216,
//       "Airport Code [ICAO]": "DAAT",
//       "Airport Code iATA,FAA": "TMR",
//       "Airport Name": "Aguenar – Hadj Bey Akhamok Airport",
//       "Altitude (Ft)": 4518,
//       "Country": "Algeria",
//       "Latitude": 5.45107984543,
//       "Location": "Tamanrasset",
//       "Longitude": 22.8115005493,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 217,
//       "Airport Code [ICAO]": "DAAV",
//       "Airport Code iATA,FAA": "GJL",
//       "Airport Name": "Jijel Ferhat Abbas Airport",
//       "Altitude (Ft)": 36,
//       "Country": "Algeria",
//       "Latitude": 5.87361001968,
//       "Location": "Jijel",
//       "Longitude": 36.7951011658,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 218,
//       "Airport Code [ICAO]": "DAAY",
//       "Airport Code iATA,FAA": "MZW",
//       "Airport Name": "Mecheria Airport",
//       "Altitude (Ft)": 3855,
//       "Country": "Algeria",
//       "Latitude": -0.242353007197,
//       "Location": "Mecheria",
//       "Longitude": 33.535900116,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 219,
//       "Airport Code [ICAO]": "DAAZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Relizane Airport",
//       "Altitude (Ft)": 282,
//       "Country": "Algeria",
//       "Latitude": 0.626272,
//       "Location": "Relizane",
//       "Longitude": 35.752201,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 220,
//       "Airport Code [ICAO]": "DABB",
//       "Airport Code iATA,FAA": "AAE",
//       "Airport Name": "Rabah Bitat Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Algeria",
//       "Latitude": 7.809174,
//       "Location": "Annaba",
//       "Longitude": 36.822201,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 221,
//       "Airport Code [ICAO]": "DABC",
//       "Airport Code iATA,FAA": "CZL",
//       "Airport Name": "Mohamed Boudiaf International Airport",
//       "Altitude (Ft)": 2265,
//       "Country": "Algeria",
//       "Latitude": 6.620389938354492,
//       "Location": "Constantine",
//       "Longitude": 36.2760009765625,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 222,
//       "Airport Code [ICAO]": "DABS",
//       "Airport Code iATA,FAA": "TEE",
//       "Airport Name": "Cheikh Larbi Tébessi Airport",
//       "Altitude (Ft)": 2661,
//       "Country": "Algeria",
//       "Latitude": 8.12071990967,
//       "Location": "Tebessa",
//       "Longitude": 35.4315986633,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 224,
//       "Airport Code [ICAO]": "DAFH",
//       "Airport Code iATA,FAA": "HRM",
//       "Airport Name": "Hassi R'Mel Airport",
//       "Altitude (Ft)": 2540,
//       "Country": "Algeria",
//       "Latitude": 3.311539888381958,
//       "Location": "Tilrempt",
//       "Longitude": 32.93040084838867,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 225,
//       "Airport Code [ICAO]": "DAOB",
//       "Airport Code iATA,FAA": "TID",
//       "Airport Name": "Bou Chekif Airport",
//       "Altitude (Ft)": 3245,
//       "Country": "Algeria",
//       "Latitude": 1.46315002441,
//       "Location": "Tiaret",
//       "Longitude": 35.3410987854,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 226,
//       "Airport Code [ICAO]": "DAOE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bou Sfer Airport",
//       "Altitude (Ft)": 187,
//       "Country": "Algeria",
//       "Latitude": -0.8053889870643616,
//       "Location": "Bou Sfer",
//       "Longitude": 35.73540115356445,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 227,
//       "Airport Code [ICAO]": "DAOF",
//       "Airport Code iATA,FAA": "TIN",
//       "Airport Name": "Tindouf Airport",
//       "Altitude (Ft)": 1453,
//       "Country": "Algeria",
//       "Latitude": -8.167099952700001,
//       "Location": "Tindouf",
//       "Longitude": 27.7003993988,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 228,
//       "Airport Code [ICAO]": "DAOI",
//       "Airport Code iATA,FAA": "CFK",
//       "Airport Name": "Ech Cheliff Airport",
//       "Altitude (Ft)": 463,
//       "Country": "Algeria",
//       "Latitude": 1.33176994324,
//       "Location": "Ech-cheliff",
//       "Longitude": 36.2126998901,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 229,
//       "Airport Code [ICAO]": "DAOL",
//       "Airport Code iATA,FAA": "TAF",
//       "Airport Name": "Tafaraoui Airport",
//       "Altitude (Ft)": 367,
//       "Country": "Algeria",
//       "Latitude": -0.5322780013084412,
//       "Location": "Oran",
//       "Longitude": 35.54240036010742,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 230,
//       "Airport Code [ICAO]": "DAON",
//       "Airport Code iATA,FAA": "TLM",
//       "Airport Name": "Zenata – Messali El Hadj Airport",
//       "Altitude (Ft)": 814,
//       "Country": "Algeria",
//       "Latitude": -1.45000004768,
//       "Location": "Tlemcen",
//       "Longitude": 35.0167007446,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 231,
//       "Airport Code [ICAO]": "DAOO",
//       "Airport Code iATA,FAA": "ORN",
//       "Airport Name": "Es Senia Airport",
//       "Altitude (Ft)": 295,
//       "Country": "Algeria",
//       "Latitude": -0.6211829781529999,
//       "Location": "Oran",
//       "Longitude": 35.6239013672,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 232,
//       "Airport Code [ICAO]": "DAOS",
//       "Airport Code iATA,FAA": "BFW",
//       "Airport Name": "Sidi Bel Abbes Airport",
//       "Altitude (Ft)": 1614,
//       "Country": "Algeria",
//       "Latitude": -0.593275010586,
//       "Location": "Sidi Bel Abbes",
//       "Longitude": 35.1717987061,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 233,
//       "Airport Code [ICAO]": "DAOV",
//       "Airport Code iATA,FAA": "MUW",
//       "Airport Name": "Ghriss Airport",
//       "Altitude (Ft)": 1686,
//       "Country": "Algeria",
//       "Latitude": 0.14714199304580688,
//       "Location": "Ghriss",
//       "Longitude": 35.207698822021484,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 234,
//       "Airport Code [ICAO]": "DAUA",
//       "Airport Code iATA,FAA": "AZR",
//       "Airport Name": "Touat Cheikh Sidi Mohamed Belkebir Airport",
//       "Altitude (Ft)": 919,
//       "Country": "Algeria",
//       "Latitude": -0.18641400337219238,
//       "Location": "Adrar",
//       "Longitude": 27.837600708007812,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 235,
//       "Airport Code [ICAO]": "DAUB",
//       "Airport Code iATA,FAA": "BSK",
//       "Airport Name": "Biskra Airport",
//       "Altitude (Ft)": 289,
//       "Country": "Algeria",
//       "Latitude": 5.73823022842,
//       "Location": "Biskra",
//       "Longitude": 34.793300628699996,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 236,
//       "Airport Code [ICAO]": "DAUE",
//       "Airport Code iATA,FAA": "ELG",
//       "Airport Name": "El Golea Airport",
//       "Altitude (Ft)": 1306,
//       "Country": "Algeria",
//       "Latitude": 2.8595900535583496,
//       "Location": "El Golea",
//       "Longitude": 30.571300506591797,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 237,
//       "Airport Code [ICAO]": "DAUG",
//       "Airport Code iATA,FAA": "GHA",
//       "Airport Name": "Noumérat - Moufdi Zakaria Airport",
//       "Altitude (Ft)": 1512,
//       "Country": "Algeria",
//       "Latitude": 3.794110059738159,
//       "Location": "Ghardaia",
//       "Longitude": 32.38410186767578,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 238,
//       "Airport Code [ICAO]": "DAUH",
//       "Airport Code iATA,FAA": "HME",
//       "Airport Name": "Oued Irara Airport",
//       "Altitude (Ft)": 463,
//       "Country": "Algeria",
//       "Latitude": 6.140439987180001,
//       "Location": "Hassi Messaoud",
//       "Longitude": 31.673000335699996,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 239,
//       "Airport Code [ICAO]": "DAUI",
//       "Airport Code iATA,FAA": "INZ",
//       "Airport Name": "In Salah Airport",
//       "Altitude (Ft)": 896,
//       "Country": "Algeria",
//       "Latitude": 2.51202011108,
//       "Location": "In Salah",
//       "Longitude": 27.250999450699997,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 240,
//       "Airport Code [ICAO]": "DAUK",
//       "Airport Code iATA,FAA": "TGR",
//       "Airport Name": "Touggourt Sidi Madhi Airport",
//       "Altitude (Ft)": 279,
//       "Country": "Algeria",
//       "Latitude": 6.088669776916504,
//       "Location": "Touggourt",
//       "Longitude": 33.06779861450195,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 241,
//       "Airport Code [ICAO]": "DAUL",
//       "Airport Code iATA,FAA": "LOO",
//       "Airport Name": "Laghouat Airport",
//       "Altitude (Ft)": 2510,
//       "Country": "Algeria",
//       "Latitude": 2.92833995819,
//       "Location": "Laghouat",
//       "Longitude": 33.764400482199996,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 242,
//       "Airport Code [ICAO]": "DAUT",
//       "Airport Code iATA,FAA": "TMX",
//       "Airport Name": "Timimoun Airport",
//       "Altitude (Ft)": 1027,
//       "Country": "Algeria",
//       "Latitude": 0.276033014059,
//       "Location": "Timimoun",
//       "Longitude": 29.2371006012,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 243,
//       "Airport Code [ICAO]": "DAUU",
//       "Airport Code iATA,FAA": "OGX",
//       "Airport Name": "Ain el Beida Airport",
//       "Altitude (Ft)": 492,
//       "Country": "Algeria",
//       "Latitude": 5.412779808044434,
//       "Location": "Ouargla",
//       "Longitude": 31.917200088500977,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 244,
//       "Airport Code [ICAO]": "DAUZ",
//       "Airport Code iATA,FAA": "IAM",
//       "Airport Name": "In Aménas Airport",
//       "Altitude (Ft)": 1847,
//       "Country": "Algeria",
//       "Latitude": 9.64291,
//       "Location": "Zarzaitine",
//       "Longitude": 28.0515,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 245,
//       "Airport Code [ICAO]": "DBBB",
//       "Airport Code iATA,FAA": "COO",
//       "Airport Name": "Cadjehoun Airport",
//       "Altitude (Ft)": 19,
//       "Country": "Benin",
//       "Latitude": 2.384350061416626,
//       "Location": "Cotonou",
//       "Longitude": 6.357230186462402,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Porto-Novo",
//       "Type": "airport"
//     },
//     {
//       "ID": 246,
//       "Airport Code [ICAO]": "DFFD",
//       "Airport Code iATA,FAA": "OUA",
//       "Airport Name": "Ouagadougou Airport",
//       "Altitude (Ft)": 1037,
//       "Country": "Burkina Faso",
//       "Latitude": -1.51242,
//       "Location": "Ouagadougou",
//       "Longitude": 12.3532,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Ouagadougou",
//       "Type": "airport"
//     },
//     {
//       "ID": 247,
//       "Airport Code [ICAO]": "DFOO",
//       "Airport Code iATA,FAA": "BOY",
//       "Airport Name": "Bobo Dioulasso Airport",
//       "Altitude (Ft)": 1511,
//       "Country": "Burkina Faso",
//       "Latitude": -4.33096981048584,
//       "Location": "Bobo-dioulasso",
//       "Longitude": 11.160099983215332,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Ouagadougou",
//       "Type": "airport"
//     },
//     {
//       "ID": 248,
//       "Airport Code [ICAO]": "DGAA",
//       "Airport Code iATA,FAA": "ACC",
//       "Airport Name": "Kotoka International Airport",
//       "Altitude (Ft)": 205,
//       "Country": "Ghana",
//       "Latitude": -0.16678600013256073,
//       "Location": "Accra",
//       "Longitude": 5.605189800262451,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Accra",
//       "Type": "airport"
//     },
//     {
//       "ID": 249,
//       "Airport Code [ICAO]": "DGLE",
//       "Airport Code iATA,FAA": "TML",
//       "Airport Name": "Tamale Airport",
//       "Altitude (Ft)": 553,
//       "Country": "Ghana",
//       "Latitude": -0.8632140159606934,
//       "Location": "Tamale",
//       "Longitude": 9.55718994140625,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Accra",
//       "Type": "airport"
//     },
//     {
//       "ID": 250,
//       "Airport Code [ICAO]": "DGLW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Wa Airport",
//       "Altitude (Ft)": 1060,
//       "Country": "Ghana",
//       "Latitude": -2.507689952850342,
//       "Location": "Wa",
//       "Longitude": 10.0826997756958,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Accra",
//       "Type": "airport"
//     },
//     {
//       "ID": 251,
//       "Airport Code [ICAO]": "DGSN",
//       "Airport Code iATA,FAA": "NYI",
//       "Airport Name": "Sunyani Airport",
//       "Altitude (Ft)": 1014,
//       "Country": "Ghana",
//       "Latitude": -2.3287599086761475,
//       "Location": "Sunyani",
//       "Longitude": 7.361830234527588,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Accra",
//       "Type": "airport"
//     },
//     {
//       "ID": 252,
//       "Airport Code [ICAO]": "DGTK",
//       "Airport Code iATA,FAA": "TKD",
//       "Airport Name": "Takoradi Airport",
//       "Altitude (Ft)": 21,
//       "Country": "Ghana",
//       "Latitude": -1.7747600078582764,
//       "Location": "Takoradi",
//       "Longitude": 4.896059989929199,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Accra",
//       "Type": "airport"
//     },
//     {
//       "ID": 253,
//       "Airport Code [ICAO]": "DIAP",
//       "Airport Code iATA,FAA": "ABJ",
//       "Airport Name": "Port Bouet Airport",
//       "Altitude (Ft)": 21,
//       "Country": "Cote d'Ivoire",
//       "Latitude": -3.9262900352478027,
//       "Location": "Abidjan",
//       "Longitude": 5.261390209197998,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Abidjan",
//       "Type": "airport"
//     },
//     {
//       "ID": 254,
//       "Airport Code [ICAO]": "DIBK",
//       "Airport Code iATA,FAA": "BYK",
//       "Airport Name": "Bouaké Airport",
//       "Altitude (Ft)": 1230,
//       "Country": "Cote d'Ivoire",
//       "Latitude": -5.073669910430908,
//       "Location": "Bouake",
//       "Longitude": 7.738800048828125,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Abidjan",
//       "Type": "airport"
//     },
//     {
//       "ID": 255,
//       "Airport Code [ICAO]": "DIDL",
//       "Airport Code iATA,FAA": "DJO",
//       "Airport Name": "Daloa Airport",
//       "Altitude (Ft)": 823,
//       "Country": "Cote d'Ivoire",
//       "Latitude": -6.473189830780029,
//       "Location": "Daloa",
//       "Longitude": 6.792809963226318,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Abidjan",
//       "Type": "airport"
//     },
//     {
//       "ID": 256,
//       "Airport Code [ICAO]": "DIKO",
//       "Airport Code iATA,FAA": "HGO",
//       "Airport Name": "Korhogo Airport",
//       "Altitude (Ft)": 1214,
//       "Country": "Cote d'Ivoire",
//       "Latitude": -5.55666017532,
//       "Location": "Korhogo",
//       "Longitude": 9.38718032837,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Abidjan",
//       "Type": "airport"
//     },
//     {
//       "ID": 257,
//       "Airport Code [ICAO]": "DIMN",
//       "Airport Code iATA,FAA": "MJC",
//       "Airport Name": "Man Airport",
//       "Altitude (Ft)": 1089,
//       "Country": "Cote d'Ivoire",
//       "Latitude": -7.58735990524292,
//       "Location": "Man",
//       "Longitude": 7.272069931030273,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Abidjan",
//       "Type": "airport"
//     },
//     {
//       "ID": 258,
//       "Airport Code [ICAO]": "DISP",
//       "Airport Code iATA,FAA": "SPY",
//       "Airport Name": "San Pedro Airport",
//       "Altitude (Ft)": 26,
//       "Country": "Cote d'Ivoire",
//       "Latitude": -6.660820007324219,
//       "Location": "San Pedro",
//       "Longitude": 4.746719837188721,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Abidjan",
//       "Type": "airport"
//     },
//     {
//       "ID": 259,
//       "Airport Code [ICAO]": "DIYO",
//       "Airport Code iATA,FAA": "ASK",
//       "Airport Name": "Yamoussoukro Airport",
//       "Altitude (Ft)": 699,
//       "Country": "Cote d'Ivoire",
//       "Latitude": -5.36558008194,
//       "Location": "Yamoussoukro",
//       "Longitude": 6.9031701088,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Abidjan",
//       "Type": "airport"
//     },
//     {
//       "ID": 260,
//       "Airport Code [ICAO]": "DNAA",
//       "Airport Code iATA,FAA": "ABV",
//       "Airport Name": "Nnamdi Azikiwe International Airport",
//       "Altitude (Ft)": 1123,
//       "Country": "Nigeria",
//       "Latitude": 7.263169765472412,
//       "Location": "Abuja",
//       "Longitude": 9.006790161132812,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 261,
//       "Airport Code [ICAO]": "DNAK",
//       "Airport Code iATA,FAA": "AKR",
//       "Airport Name": "Akure Airport",
//       "Altitude (Ft)": 1100,
//       "Country": "Nigeria",
//       "Latitude": 5.3010101318359375,
//       "Location": "Akure",
//       "Longitude": 7.246739864349365,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 262,
//       "Airport Code [ICAO]": "DNBE",
//       "Airport Code iATA,FAA": "BNI",
//       "Airport Name": "Benin Airport",
//       "Altitude (Ft)": 258,
//       "Country": "Nigeria",
//       "Latitude": 5.5995001792907715,
//       "Location": "Benin",
//       "Longitude": 6.316979885101318,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 263,
//       "Airport Code [ICAO]": "DNCA",
//       "Airport Code iATA,FAA": "CBQ",
//       "Airport Name": "Margaret Ekpo International Airport",
//       "Altitude (Ft)": 210,
//       "Country": "Nigeria",
//       "Latitude": 8.347200393676758,
//       "Location": "Calabar",
//       "Longitude": 4.976019859313965,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 264,
//       "Airport Code [ICAO]": "DNEN",
//       "Airport Code iATA,FAA": "ENU",
//       "Airport Name": "Akanu Ibiam International Airport",
//       "Altitude (Ft)": 466,
//       "Country": "Nigeria",
//       "Latitude": 7.561960220336914,
//       "Location": "Enugu",
//       "Longitude": 6.474269866943359,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 265,
//       "Airport Code [ICAO]": "DNGU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Gusau Airport",
//       "Altitude (Ft)": 1520,
//       "Country": "Nigeria",
//       "Latitude": 6.69611,
//       "Location": "Gusau",
//       "Longitude": 12.1717,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 266,
//       "Airport Code [ICAO]": "DNIB",
//       "Airport Code iATA,FAA": "IBA",
//       "Airport Name": "Ibadan Airport",
//       "Altitude (Ft)": 725,
//       "Country": "Nigeria",
//       "Latitude": 3.97832989692688,
//       "Location": "Ibadan",
//       "Longitude": 7.362460136413574,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 267,
//       "Airport Code [ICAO]": "DNIL",
//       "Airport Code iATA,FAA": "ILR",
//       "Airport Name": "Ilorin International Airport",
//       "Altitude (Ft)": 1126,
//       "Country": "Nigeria",
//       "Latitude": 4.493919849395752,
//       "Location": "Ilorin",
//       "Longitude": 8.440210342407227,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 268,
//       "Airport Code [ICAO]": "DNJO",
//       "Airport Code iATA,FAA": "JOS",
//       "Airport Name": "Yakubu Gowon Airport",
//       "Altitude (Ft)": 4232,
//       "Country": "Nigeria",
//       "Latitude": 8.869050025939941,
//       "Location": "Jos",
//       "Longitude": 9.639829635620117,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 269,
//       "Airport Code [ICAO]": "DNKA",
//       "Airport Code iATA,FAA": "KAD",
//       "Airport Name": "Kaduna Airport",
//       "Altitude (Ft)": 2073,
//       "Country": "Nigeria",
//       "Latitude": 7.320109844207764,
//       "Location": "Kaduna",
//       "Longitude": 10.696000099182129,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 270,
//       "Airport Code [ICAO]": "DNKN",
//       "Airport Code iATA,FAA": "KAN",
//       "Airport Name": "Mallam Aminu International Airport",
//       "Altitude (Ft)": 1562,
//       "Country": "Nigeria",
//       "Latitude": 8.52462,
//       "Location": "Kano",
//       "Longitude": 12.0476,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 271,
//       "Airport Code [ICAO]": "DNMA",
//       "Airport Code iATA,FAA": "MIU",
//       "Airport Name": "Maiduguri International Airport",
//       "Altitude (Ft)": 1099,
//       "Country": "Nigeria",
//       "Latitude": 13.080900192260742,
//       "Location": "Maiduguri",
//       "Longitude": 11.855299949645996,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 272,
//       "Airport Code [ICAO]": "DNMK",
//       "Airport Code iATA,FAA": "MDI",
//       "Airport Name": "Makurdi Airport",
//       "Altitude (Ft)": 371,
//       "Country": "Nigeria",
//       "Latitude": 8.61394,
//       "Location": "Makurdi",
//       "Longitude": 7.70388,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 273,
//       "Airport Code [ICAO]": "DNMM",
//       "Airport Code iATA,FAA": "LOS",
//       "Airport Name": "Murtala Muhammed International Airport",
//       "Altitude (Ft)": 135,
//       "Country": "Nigeria",
//       "Latitude": 3.321160078048706,
//       "Location": "Lagos",
//       "Longitude": 6.5773701667785645,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 274,
//       "Airport Code [ICAO]": "DNMN",
//       "Airport Code iATA,FAA": "MXJ",
//       "Airport Name": "Minna Airport",
//       "Altitude (Ft)": 834,
//       "Country": "Nigeria",
//       "Latitude": 6.462259769439697,
//       "Location": "Minna",
//       "Longitude": 9.652170181274414,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 275,
//       "Airport Code [ICAO]": "DNPO",
//       "Airport Code iATA,FAA": "PHC",
//       "Airport Name": "Port Harcourt International Airport",
//       "Altitude (Ft)": 87,
//       "Country": "Nigeria",
//       "Latitude": 6.94959020614624,
//       "Location": "Port Hartcourt",
//       "Longitude": 5.0154900550842285,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 276,
//       "Airport Code [ICAO]": "DNSO",
//       "Airport Code iATA,FAA": "SKO",
//       "Airport Name": "Sadiq Abubakar III International Airport",
//       "Altitude (Ft)": 1010,
//       "Country": "Nigeria",
//       "Latitude": 5.207190036773682,
//       "Location": "Sokoto",
//       "Longitude": 12.916299819946289,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 277,
//       "Airport Code [ICAO]": "DNYO",
//       "Airport Code iATA,FAA": "YOL",
//       "Airport Name": "Yola Airport",
//       "Altitude (Ft)": 599,
//       "Country": "Nigeria",
//       "Latitude": 12.430399894714355,
//       "Location": "Yola",
//       "Longitude": 9.257550239562988,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 278,
//       "Airport Code [ICAO]": "DNZA",
//       "Airport Code iATA,FAA": "ZAR",
//       "Airport Name": "Zaria Airport",
//       "Altitude (Ft)": 2170,
//       "Country": "Nigeria",
//       "Latitude": 7.68581,
//       "Location": "Zaria",
//       "Longitude": 11.1302,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 279,
//       "Airport Code [ICAO]": "DRRM",
//       "Airport Code iATA,FAA": "MFQ",
//       "Airport Name": "Maradi Airport",
//       "Altitude (Ft)": 1240,
//       "Country": "Niger",
//       "Latitude": 7.1267499923706055,
//       "Location": "Maradi",
//       "Longitude": 13.5024995803833,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Niamey",
//       "Type": "airport"
//     },
//     {
//       "ID": 280,
//       "Airport Code [ICAO]": "DRRN",
//       "Airport Code iATA,FAA": "NIM",
//       "Airport Name": "Diori Hamani International Airport",
//       "Altitude (Ft)": 732,
//       "Country": "Niger",
//       "Latitude": 2.18361,
//       "Location": "Niamey",
//       "Longitude": 13.4815,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Niamey",
//       "Type": "airport"
//     },
//     {
//       "ID": 281,
//       "Airport Code [ICAO]": "DRRT",
//       "Airport Code iATA,FAA": "THZ",
//       "Airport Name": "Tahoua Airport",
//       "Altitude (Ft)": 1266,
//       "Country": "Niger",
//       "Latitude": 5.265359878540039,
//       "Location": "Tahoua",
//       "Longitude": 14.875699996948242,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Niamey",
//       "Type": "airport"
//     },
//     {
//       "ID": 282,
//       "Airport Code [ICAO]": "DRZA",
//       "Airport Code iATA,FAA": "AJY",
//       "Airport Name": "Mano Dayak International Airport",
//       "Altitude (Ft)": 1657,
//       "Country": "Niger",
//       "Latitude": 8.000109672546387,
//       "Location": "Agadez",
//       "Longitude": 16.965999603271484,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Niamey",
//       "Type": "airport"
//     },
//     {
//       "ID": 283,
//       "Airport Code [ICAO]": "DRZD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Dirkou Airport",
//       "Altitude (Ft)": 1273,
//       "Country": "Niger",
//       "Latitude": 12.86870002746582,
//       "Location": "Dirkou",
//       "Longitude": 18.968700408935547,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Niamey",
//       "Type": "airport"
//     },
//     {
//       "ID": 284,
//       "Airport Code [ICAO]": "DRZF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Diffa Airport",
//       "Altitude (Ft)": 994,
//       "Country": "Niger",
//       "Latitude": 12.626700401306152,
//       "Location": "Diffa",
//       "Longitude": 13.372900009155273,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Niamey",
//       "Type": "airport"
//     },
//     {
//       "ID": 285,
//       "Airport Code [ICAO]": "DRZR",
//       "Airport Code iATA,FAA": "ZND",
//       "Airport Name": "Zinder Airport",
//       "Altitude (Ft)": 1516,
//       "Country": "Niger",
//       "Latitude": 8.983759880065918,
//       "Location": "Zinder",
//       "Longitude": 13.779000282287598,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Niamey",
//       "Type": "airport"
//     },
//     {
//       "ID": 286,
//       "Airport Code [ICAO]": "DTMB",
//       "Airport Code iATA,FAA": "MIR",
//       "Airport Name": "Monastir Habib Bourguiba International Airport",
//       "Altitude (Ft)": 9,
//       "Country": "Tunisia",
//       "Latitude": 10.75469970703125,
//       "Location": "Monastir",
//       "Longitude": 35.75809860229492,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Africa/Tunis",
//       "Type": "airport"
//     },
//     {
//       "ID": 287,
//       "Airport Code [ICAO]": "DTTA",
//       "Airport Code iATA,FAA": "TUN",
//       "Airport Name": "Tunis Carthage International Airport",
//       "Altitude (Ft)": 22,
//       "Country": "Tunisia",
//       "Latitude": 10.22719955444336,
//       "Location": "Tunis",
//       "Longitude": 36.85100173950195,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Africa/Tunis",
//       "Type": "airport"
//     },
//     {
//       "ID": 288,
//       "Airport Code [ICAO]": "DTTB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sidi Ahmed Air Base",
//       "Altitude (Ft)": 20,
//       "Country": "Tunisia",
//       "Latitude": 9.79145,
//       "Location": "Bizerte",
//       "Longitude": 37.245398,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Africa/Tunis",
//       "Type": "airport"
//     },
//     {
//       "ID": 289,
//       "Airport Code [ICAO]": "DTTD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Remada Air Base",
//       "Altitude (Ft)": 1004,
//       "Country": "Tunisia",
//       "Latitude": 10.382100105285645,
//       "Location": "Remada",
//       "Longitude": 32.30619812011719,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Africa/Tunis",
//       "Type": "airport"
//     },
//     {
//       "ID": 290,
//       "Airport Code [ICAO]": "DTTF",
//       "Airport Code iATA,FAA": "GAF",
//       "Airport Name": "Gafsa Ksar International Airport",
//       "Altitude (Ft)": 1060,
//       "Country": "Tunisia",
//       "Latitude": 8.822500228881836,
//       "Location": "Gafsa",
//       "Longitude": 34.422000885009766,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Africa/Tunis",
//       "Type": "airport"
//     },
//     {
//       "ID": 291,
//       "Airport Code [ICAO]": "DTTG",
//       "Airport Code iATA,FAA": "GAE",
//       "Airport Name": "Gabès Matmata International Airport",
//       "Altitude (Ft)": 26,
//       "Country": "Tunisia",
//       "Latitude": 10.103300094604492,
//       "Location": "Gabes",
//       "Longitude": 33.87689971923828,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Africa/Tunis",
//       "Type": "airport"
//     },
//     {
//       "ID": 292,
//       "Airport Code [ICAO]": "DTTI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Borj El Amri Airport",
//       "Altitude (Ft)": 110,
//       "Country": "Tunisia",
//       "Latitude": 9.94314956665039,
//       "Location": "Bordj El Amri",
//       "Longitude": 36.72129821777344,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Africa/Tunis",
//       "Type": "airport"
//     },
//     {
//       "ID": 293,
//       "Airport Code [ICAO]": "DTTJ",
//       "Airport Code iATA,FAA": "DJE",
//       "Airport Name": "Djerba Zarzis International Airport",
//       "Altitude (Ft)": 19,
//       "Country": "Tunisia",
//       "Latitude": 10.775500297546387,
//       "Location": "Djerba",
//       "Longitude": 33.875,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Africa/Tunis",
//       "Type": "airport"
//     },
//     {
//       "ID": 294,
//       "Airport Code [ICAO]": "DTTR",
//       "Airport Code iATA,FAA": "EBM",
//       "Airport Name": "El Borma Airport",
//       "Altitude (Ft)": 827,
//       "Country": "Tunisia",
//       "Latitude": 9.254619598388672,
//       "Location": "El Borma",
//       "Longitude": 31.704299926757812,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Africa/Tunis",
//       "Type": "airport"
//     },
//     {
//       "ID": 295,
//       "Airport Code [ICAO]": "DTTX",
//       "Airport Code iATA,FAA": "SFA",
//       "Airport Name": "Sfax Thyna International Airport",
//       "Altitude (Ft)": 85,
//       "Country": "Tunisia",
//       "Latitude": 10.690999984741211,
//       "Location": "Sfax",
//       "Longitude": 34.71799850463867,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Africa/Tunis",
//       "Type": "airport"
//     },
//     {
//       "ID": 296,
//       "Airport Code [ICAO]": "DTTZ",
//       "Airport Code iATA,FAA": "TOE",
//       "Airport Name": "Tozeur Nefta International Airport",
//       "Altitude (Ft)": 287,
//       "Country": "Tunisia",
//       "Latitude": 8.110560417175293,
//       "Location": "Tozeur",
//       "Longitude": 33.939701080322266,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Africa/Tunis",
//       "Type": "airport"
//     },
//     {
//       "ID": 297,
//       "Airport Code [ICAO]": "DXNG",
//       "Airport Code iATA,FAA": "LRL",
//       "Airport Name": "Niamtougou International Airport",
//       "Altitude (Ft)": 1515,
//       "Country": "Togo",
//       "Latitude": 1.091249942779541,
//       "Location": "Niatougou",
//       "Longitude": 9.767330169677734,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lome",
//       "Type": "airport"
//     },
//     {
//       "ID": 298,
//       "Airport Code [ICAO]": "DXXX",
//       "Airport Code iATA,FAA": "LFW",
//       "Airport Name": "Lomé-Tokoin Airport",
//       "Altitude (Ft)": 72,
//       "Country": "Togo",
//       "Latitude": 1.2545100450515747,
//       "Location": "Lome",
//       "Longitude": 6.165609836578369,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lome",
//       "Type": "airport"
//     },
//     {
//       "ID": 299,
//       "Airport Code [ICAO]": "EBAW",
//       "Airport Code iATA,FAA": "ANR",
//       "Airport Name": "Antwerp International Airport (Deurne)",
//       "Altitude (Ft)": 39,
//       "Country": "Belgium",
//       "Latitude": 4.46027994156,
//       "Location": "Antwerp",
//       "Longitude": 51.1893997192,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Brussels",
//       "Type": "airport"
//     },
//     {
//       "ID": 300,
//       "Airport Code [ICAO]": "EBBE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Beauvechain Air Base",
//       "Altitude (Ft)": 370,
//       "Country": "Belgium",
//       "Latitude": 4.768330097198486,
//       "Location": "Beauvechain",
//       "Longitude": 50.75859832763672,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Brussels",
//       "Type": "airport"
//     },
//     {
//       "ID": 301,
//       "Airport Code [ICAO]": "EBBL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kleine Brogel Air Base",
//       "Altitude (Ft)": 200,
//       "Country": "Belgium",
//       "Latitude": 5.47,
//       "Location": "Kleine Brogel",
//       "Longitude": 51.168301,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Brussels",
//       "Type": "airport"
//     },
//     {
//       "ID": 302,
//       "Airport Code [ICAO]": "EBBR",
//       "Airport Code iATA,FAA": "BRU",
//       "Airport Name": "Brussels Airport",
//       "Altitude (Ft)": 184,
//       "Country": "Belgium",
//       "Latitude": 4.48443984985,
//       "Location": "Brussels",
//       "Longitude": 50.901401519800004,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Brussels",
//       "Type": "airport"
//     },
//     {
//       "ID": 303,
//       "Airport Code [ICAO]": "EBBX",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Jehonville Air Base",
//       "Altitude (Ft)": 1514,
//       "Country": "Belgium",
//       "Latitude": 5.2238898277282715,
//       "Location": "Bertrix",
//       "Longitude": 49.891700744628906,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Brussels",
//       "Type": "airport"
//     },
//     {
//       "ID": 304,
//       "Airport Code [ICAO]": "EBCI",
//       "Airport Code iATA,FAA": "CRL",
//       "Airport Name": "Brussels South Charleroi Airport",
//       "Altitude (Ft)": 614,
//       "Country": "Belgium",
//       "Latitude": 4.45382,
//       "Location": "Charleroi",
//       "Longitude": 50.459202,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Brussels",
//       "Type": "airport"
//     },
//     {
//       "ID": 305,
//       "Airport Code [ICAO]": "EBCV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Chièvres Air Base",
//       "Altitude (Ft)": 194,
//       "Country": "Belgium",
//       "Latitude": 3.8310000896453857,
//       "Location": "Chievres",
//       "Longitude": 50.575801849365234,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Brussels",
//       "Type": "airport"
//     },
//     {
//       "ID": 306,
//       "Airport Code [ICAO]": "EBFN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Koksijde Air Base",
//       "Altitude (Ft)": 20,
//       "Country": "Belgium",
//       "Latitude": 2.652780055999756,
//       "Location": "Koksijde",
//       "Longitude": 51.090301513671875,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Brussels",
//       "Type": "airport"
//     },
//     {
//       "ID": 307,
//       "Airport Code [ICAO]": "EBFS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Florennes Air Base",
//       "Altitude (Ft)": 935,
//       "Country": "Belgium",
//       "Latitude": 4.64583015442,
//       "Location": "Florennes",
//       "Longitude": 50.2433013916,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Brussels",
//       "Type": "airport"
//     },
//     {
//       "ID": 308,
//       "Airport Code [ICAO]": "EBKT",
//       "Airport Code iATA,FAA": "KJK",
//       "Airport Name": "Wevelgem Airport",
//       "Altitude (Ft)": 64,
//       "Country": "Belgium",
//       "Latitude": 3.20472002029,
//       "Location": "Kortrijk-vevelgem",
//       "Longitude": 50.817199707,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Brussels",
//       "Type": "airport"
//     },
//     {
//       "ID": 309,
//       "Airport Code [ICAO]": "EBLG",
//       "Airport Code iATA,FAA": "LGG",
//       "Airport Name": "Liège Airport",
//       "Altitude (Ft)": 659,
//       "Country": "Belgium",
//       "Latitude": 5.443220138549805,
//       "Location": "Liege",
//       "Longitude": 50.63740158081055,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Brussels",
//       "Type": "airport"
//     },
//     {
//       "ID": 310,
//       "Airport Code [ICAO]": "EBOS",
//       "Airport Code iATA,FAA": "OST",
//       "Airport Name": "Ostend-Bruges International Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Belgium",
//       "Latitude": 2.8622200489,
//       "Location": "Ostend",
//       "Longitude": 51.198898315399994,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Brussels",
//       "Type": "airport"
//     },
//     {
//       "ID": 311,
//       "Airport Code [ICAO]": "EBSL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Zutendaal Air Base",
//       "Altitude (Ft)": 312,
//       "Country": "Belgium",
//       "Latitude": 5.590559959411621,
//       "Location": "Zutendaal",
//       "Longitude": 50.9474983215332,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Brussels",
//       "Type": "airport"
//     },
//     {
//       "ID": 312,
//       "Airport Code [ICAO]": "EBST",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Brustem Airfield Sint Truiden",
//       "Altitude (Ft)": 246,
//       "Country": "Belgium",
//       "Latitude": 5.20167,
//       "Location": "Sint-truiden",
//       "Longitude": 50.791901,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Brussels",
//       "Type": "airport"
//     },
//     {
//       "ID": 313,
//       "Airport Code [ICAO]": "EBSU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Saint Hubert Air Base",
//       "Altitude (Ft)": 1930,
//       "Country": "Belgium",
//       "Latitude": 5.440810203552246,
//       "Location": "St.-hubert",
//       "Longitude": 50.034400939941406,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Brussels",
//       "Type": "airport"
//     },
//     {
//       "ID": 314,
//       "Airport Code [ICAO]": "EBUL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ursel Air Base",
//       "Altitude (Ft)": 95,
//       "Country": "Belgium",
//       "Latitude": 3.475559949874878,
//       "Location": "Ursel",
//       "Longitude": 51.14419937133789,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Brussels",
//       "Type": "airport"
//     },
//     {
//       "ID": 315,
//       "Airport Code [ICAO]": "EBWE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Weelde Air Base",
//       "Altitude (Ft)": 105,
//       "Country": "Belgium",
//       "Latitude": 4.9601898193359375,
//       "Location": "Weelde",
//       "Longitude": 51.394798278808594,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Brussels",
//       "Type": "airport"
//     },
//     {
//       "ID": 316,
//       "Airport Code [ICAO]": "EBZR",
//       "Airport Code iATA,FAA": "OBL",
//       "Airport Name": "Zoersel (Oostmalle) Airfield",
//       "Altitude (Ft)": 53,
//       "Country": "Belgium",
//       "Latitude": 4.75333,
//       "Location": "Zoersel",
//       "Longitude": 51.264702,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Brussels",
//       "Type": "airport"
//     },
//     {
//       "ID": 317,
//       "Airport Code [ICAO]": "EDAB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Flugplatz Bautzen",
//       "Altitude (Ft)": 568,
//       "Country": "Germany",
//       "Latitude": 14.519722,
//       "Location": "Bautzen",
//       "Longitude": 51.193611,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 318,
//       "Airport Code [ICAO]": "EDAC",
//       "Airport Code iATA,FAA": "AOC",
//       "Airport Name": "Altenburg-Nobitz Airport",
//       "Altitude (Ft)": 640,
//       "Country": "Germany",
//       "Latitude": 12.506389,
//       "Location": "Altenburg",
//       "Longitude": 50.981945,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 319,
//       "Airport Code [ICAO]": "EDAD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Dessau Airfield",
//       "Altitude (Ft)": 187,
//       "Country": "Germany",
//       "Latitude": 12.190962,
//       "Location": "Dessau",
//       "Longitude": 51.831694,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 320,
//       "Airport Code [ICAO]": "EDAE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Eisenhüttenstadt Airfield",
//       "Altitude (Ft)": 144,
//       "Country": "Germany",
//       "Latitude": 14.58753,
//       "Location": "Eisenhuettenstadt",
//       "Longitude": 52.195856,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 322,
//       "Airport Code [ICAO]": "EDAK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Großenhain Airport",
//       "Altitude (Ft)": 417,
//       "Country": "Germany",
//       "Latitude": 13.555556297302246,
//       "Location": "Suhl",
//       "Longitude": 51.30805587768555,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 323,
//       "Airport Code [ICAO]": "EDAM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Merseburg Airport",
//       "Altitude (Ft)": 341,
//       "Country": "Germany",
//       "Latitude": 11.9408333,
//       "Location": "Muehlhausen",
//       "Longitude": 51.3630556,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 324,
//       "Airport Code [ICAO]": "EDAQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Halle-Oppin Airport",
//       "Altitude (Ft)": 348,
//       "Country": "Germany",
//       "Latitude": 12.053889,
//       "Location": "Halle",
//       "Longitude": 51.552223,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 325,
//       "Airport Code [ICAO]": "EDAU",
//       "Airport Code iATA,FAA": "IES",
//       "Airport Name": "Riesa-Göhlis Airport",
//       "Altitude (Ft)": 322,
//       "Country": "Germany",
//       "Latitude": 13.3561105728,
//       "Location": "Riesa",
//       "Longitude": 51.2936096191,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 326,
//       "Airport Code [ICAO]": "EDAX",
//       "Airport Code iATA,FAA": "REB",
//       "Airport Name": "Rechlin-Lärz Airport",
//       "Altitude (Ft)": 220,
//       "Country": "Germany",
//       "Latitude": 12.7522220612,
//       "Location": "Rechlin-laerz",
//       "Longitude": 53.306388855,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 327,
//       "Airport Code [ICAO]": "EDAY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Strausberg Airport",
//       "Altitude (Ft)": 262,
//       "Country": "Germany",
//       "Latitude": 13.916666984558105,
//       "Location": "Strausberg",
//       "Longitude": 52.5805549621582,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 328,
//       "Airport Code [ICAO]": "EDAZ",
//       "Airport Code iATA,FAA": "QXH",
//       "Airport Name": "Schönhagen Airport",
//       "Altitude (Ft)": 152,
//       "Country": "Germany",
//       "Latitude": 13.156389,
//       "Location": "Schoenhagen",
//       "Longitude": 52.203609,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 329,
//       "Airport Code [ICAO]": "EDBH",
//       "Airport Code iATA,FAA": "BBH",
//       "Airport Name": "Barth Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Germany",
//       "Latitude": 12.710515,
//       "Location": "Barth",
//       "Longitude": 54.338253,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 330,
//       "Airport Code [ICAO]": "EDBJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Jena-Schöngleina Airfield",
//       "Altitude (Ft)": 1247,
//       "Country": "Germany",
//       "Latitude": 11.714444,
//       "Location": "Jena",
//       "Longitude": 50.915279,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 331,
//       "Airport Code [ICAO]": "EDBK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kyritz Airport",
//       "Altitude (Ft)": 131,
//       "Country": "Germany",
//       "Latitude": 12.425277709960938,
//       "Location": "Kyritz",
//       "Longitude": 52.918888092041016,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 332,
//       "Airport Code [ICAO]": "EDBM",
//       "Airport Code iATA,FAA": "ZMG",
//       "Airport Name": "Magdeburg \"City\" Airport",
//       "Altitude (Ft)": 259,
//       "Country": "Germany",
//       "Latitude": 11.626389,
//       "Location": "Magdeburg",
//       "Longitude": 52.073612,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 333,
//       "Airport Code [ICAO]": "EDBR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Rothenburg/Görlitz Airport",
//       "Altitude (Ft)": 518,
//       "Country": "Germany",
//       "Latitude": 14.949999809265137,
//       "Location": "Rothenburg/ol",
//       "Longitude": 51.36333465576172,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 334,
//       "Airport Code [ICAO]": "EDCA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Anklam Airfield",
//       "Altitude (Ft)": 16,
//       "Country": "Germany",
//       "Latitude": 13.668611,
//       "Location": "Anklam",
//       "Longitude": 53.832779,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 335,
//       "Airport Code [ICAO]": "EDCD",
//       "Airport Code iATA,FAA": "CBU",
//       "Airport Name": "Cottbus-Drewitz Airport",
//       "Altitude (Ft)": 272,
//       "Country": "Germany",
//       "Latitude": 14.531944,
//       "Location": "Cottbus",
//       "Longitude": 51.889442,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 336,
//       "Airport Code [ICAO]": "EDCM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kamenz Airport",
//       "Altitude (Ft)": 495,
//       "Country": "Germany",
//       "Latitude": 14.1274995803833,
//       "Location": "Kamenz",
//       "Longitude": 51.29694366455078,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 337,
//       "Airport Code [ICAO]": "EDDB",
//       "Airport Code iATA,FAA": "SXF",
//       "Airport Name": "Berlin-Schönefeld Airport",
//       "Altitude (Ft)": 157,
//       "Country": "Germany",
//       "Latitude": 13.5225,
//       "Location": "Berlin",
//       "Longitude": 52.380001,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 338,
//       "Airport Code [ICAO]": "EDDC",
//       "Airport Code iATA,FAA": "DRS",
//       "Airport Name": "Dresden Airport",
//       "Altitude (Ft)": 755,
//       "Country": "Germany",
//       "Latitude": 13.767200469970703,
//       "Location": "Dresden",
//       "Longitude": 51.1328010559082,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 339,
//       "Airport Code [ICAO]": "EDDE",
//       "Airport Code iATA,FAA": "ERF",
//       "Airport Name": "Erfurt Airport",
//       "Altitude (Ft)": 1036,
//       "Country": "Germany",
//       "Latitude": 10.958100318908691,
//       "Location": "Erfurt",
//       "Longitude": 50.979801177978516,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 340,
//       "Airport Code [ICAO]": "EDDF",
//       "Airport Code iATA,FAA": "FRA",
//       "Airport Name": "Frankfurt am Main Airport",
//       "Altitude (Ft)": 364,
//       "Country": "Germany",
//       "Latitude": 8.570556,
//       "Location": "Frankfurt",
//       "Longitude": 50.033333,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 341,
//       "Airport Code [ICAO]": "EDDG",
//       "Airport Code iATA,FAA": "FMO",
//       "Airport Name": "Münster Osnabrück Airport",
//       "Altitude (Ft)": 160,
//       "Country": "Germany",
//       "Latitude": 7.68483018875,
//       "Location": "Munster",
//       "Longitude": 52.134601593,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 342,
//       "Airport Code [ICAO]": "EDDH",
//       "Airport Code iATA,FAA": "HAM",
//       "Airport Name": "Hamburg Airport",
//       "Altitude (Ft)": 53,
//       "Country": "Germany",
//       "Latitude": 9.9882297515869,
//       "Location": "Hamburg",
//       "Longitude": 53.630401611328,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 343,
//       "Airport Code [ICAO]": "EDDI",
//       "Airport Code iATA,FAA": "THF",
//       "Airport Name": "Berlin-Tempelhof International Airport",
//       "Altitude (Ft)": 167,
//       "Country": "Germany",
//       "Latitude": 13.403900146484375,
//       "Location": "Berlin",
//       "Longitude": 52.472999572753906,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 344,
//       "Airport Code [ICAO]": "EDDK",
//       "Airport Code iATA,FAA": "CGN",
//       "Airport Name": "Cologne Bonn Airport",
//       "Altitude (Ft)": 302,
//       "Country": "Germany",
//       "Latitude": 7.1427397728,
//       "Location": "Cologne",
//       "Longitude": 50.8658981323,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 345,
//       "Airport Code [ICAO]": "EDDL",
//       "Airport Code iATA,FAA": "DUS",
//       "Airport Name": "Düsseldorf Airport",
//       "Altitude (Ft)": 147,
//       "Country": "Germany",
//       "Latitude": 6.76678,
//       "Location": "Duesseldorf",
//       "Longitude": 51.289501,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 346,
//       "Airport Code [ICAO]": "EDDM",
//       "Airport Code iATA,FAA": "MUC",
//       "Airport Name": "Munich Airport",
//       "Altitude (Ft)": 1487,
//       "Country": "Germany",
//       "Latitude": 11.7861,
//       "Location": "Munich",
//       "Longitude": 48.353802,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 347,
//       "Airport Code [ICAO]": "EDDN",
//       "Airport Code iATA,FAA": "NUE",
//       "Airport Name": "Nuremberg Airport",
//       "Altitude (Ft)": 1046,
//       "Country": "Germany",
//       "Latitude": 11.078056,
//       "Location": "Nuernberg",
//       "Longitude": 49.498699,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 348,
//       "Airport Code [ICAO]": "EDDP",
//       "Airport Code iATA,FAA": "LEJ",
//       "Airport Name": "Leipzig/Halle Airport",
//       "Altitude (Ft)": 465,
//       "Country": "Germany",
//       "Latitude": 12.236389,
//       "Location": "Leipzig",
//       "Longitude": 51.423889,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 349,
//       "Airport Code [ICAO]": "EDDR",
//       "Airport Code iATA,FAA": "SCN",
//       "Airport Name": "Saarbrücken Airport",
//       "Altitude (Ft)": 1058,
//       "Country": "Germany",
//       "Latitude": 7.10950994492,
//       "Location": "Saarbruecken",
//       "Longitude": 49.214599609400004,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 350,
//       "Airport Code [ICAO]": "EDDS",
//       "Airport Code iATA,FAA": "STR",
//       "Airport Name": "Stuttgart Airport",
//       "Altitude (Ft)": 1276,
//       "Country": "Germany",
//       "Latitude": 9.22196006775,
//       "Location": "Stuttgart",
//       "Longitude": 48.689899444599995,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 351,
//       "Airport Code [ICAO]": "EDDT",
//       "Airport Code iATA,FAA": "TXL",
//       "Airport Name": "Berlin-Tegel Airport",
//       "Altitude (Ft)": 122,
//       "Country": "Germany",
//       "Latitude": 13.2877,
//       "Location": "Berlin",
//       "Longitude": 52.5597,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 352,
//       "Airport Code [ICAO]": "EDDV",
//       "Airport Code iATA,FAA": "HAJ",
//       "Airport Name": "Hannover Airport",
//       "Altitude (Ft)": 183,
//       "Country": "Germany",
//       "Latitude": 9.685079574580001,
//       "Location": "Hannover",
//       "Longitude": 52.461101532,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 353,
//       "Airport Code [ICAO]": "EDDW",
//       "Airport Code iATA,FAA": "BRE",
//       "Airport Name": "Bremen Airport",
//       "Altitude (Ft)": 14,
//       "Country": "Germany",
//       "Latitude": 8.78666973114,
//       "Location": "Bremen",
//       "Longitude": 53.0475006104,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 354,
//       "Airport Code [ICAO]": "EDFE",
//       "Airport Code iATA,FAA": "QEF",
//       "Airport Name": "Frankfurt-Egelsbach Airport",
//       "Altitude (Ft)": 384,
//       "Country": "Germany",
//       "Latitude": 8.645833,
//       "Location": "Egelsbach",
//       "Longitude": 49.959999,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 355,
//       "Airport Code [ICAO]": "EDFH",
//       "Airport Code iATA,FAA": "HHN",
//       "Airport Name": "Frankfurt-Hahn Airport",
//       "Altitude (Ft)": 1649,
//       "Country": "Germany",
//       "Latitude": 7.26389,
//       "Location": "Hahn",
//       "Longitude": 49.9487,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 356,
//       "Airport Code [ICAO]": "EDFM",
//       "Airport Code iATA,FAA": "MHG",
//       "Airport Name": "Mannheim-City Airport",
//       "Altitude (Ft)": 308,
//       "Country": "Germany",
//       "Latitude": 8.514167,
//       "Location": "Mannheim",
//       "Longitude": 49.473057,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 357,
//       "Airport Code [ICAO]": "EDFQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Allendorf/Eder Airport",
//       "Altitude (Ft)": 1158,
//       "Country": "Germany",
//       "Latitude": 8.680832862854004,
//       "Location": "Allendorf",
//       "Longitude": 51.03499984741211,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 358,
//       "Airport Code [ICAO]": "EDFV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Worms Airport",
//       "Altitude (Ft)": 295,
//       "Country": "Germany",
//       "Latitude": 8.368333,
//       "Location": "Worms",
//       "Longitude": 49.606945,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 359,
//       "Airport Code [ICAO]": "EDFZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mainz-Finthen Airport",
//       "Altitude (Ft)": 525,
//       "Country": "Germany",
//       "Latitude": 8.147222,
//       "Location": "Mainz",
//       "Longitude": 49.967499,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 360,
//       "Airport Code [ICAO]": "EDGE",
//       "Airport Code iATA,FAA": "EIB",
//       "Airport Name": "Eisenach-Kindel Airport",
//       "Altitude (Ft)": 1112,
//       "Country": "Germany",
//       "Latitude": 10.47973,
//       "Location": "Eisenach",
//       "Longitude": 50.991604,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 361,
//       "Airport Code [ICAO]": "EDGS",
//       "Airport Code iATA,FAA": "SGE",
//       "Airport Name": "Siegerland Airport",
//       "Altitude (Ft)": 1966,
//       "Country": "Germany",
//       "Latitude": 8.082969665527344,
//       "Location": "Siegerland",
//       "Longitude": 50.707698822021484,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 362,
//       "Airport Code [ICAO]": "EDHI",
//       "Airport Code iATA,FAA": "XFW",
//       "Airport Name": "Hamburg-Finkenwerder Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Germany",
//       "Latitude": 9.835556030273438,
//       "Location": "Hamburg",
//       "Longitude": 53.5352783203125,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 363,
//       "Airport Code [ICAO]": "EDHK",
//       "Airport Code iATA,FAA": "KEL",
//       "Airport Name": "Kiel-Holtenau Airport",
//       "Altitude (Ft)": 102,
//       "Country": "Germany",
//       "Latitude": 10.145277976989746,
//       "Location": "Kiel",
//       "Longitude": 54.37944412231445,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 364,
//       "Airport Code [ICAO]": "EDHL",
//       "Airport Code iATA,FAA": "LBC",
//       "Airport Name": "Lübeck Blankensee Airport",
//       "Altitude (Ft)": 53,
//       "Country": "Germany",
//       "Latitude": 10.7192001343,
//       "Location": "Luebeck",
//       "Longitude": 53.8054008484,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 365,
//       "Airport Code [ICAO]": "EDKV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Flugplatz Dahlemer Binz",
//       "Altitude (Ft)": 1896,
//       "Country": "Germany",
//       "Latitude": 6.5288891792,
//       "Location": "Dahlemer Binz",
//       "Longitude": 50.405555725,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 366,
//       "Airport Code [ICAO]": "EDKZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Meinerzhagen Airport",
//       "Altitude (Ft)": 1549,
//       "Country": "Germany",
//       "Latitude": 7.599999904632568,
//       "Location": "Meinerzhagen",
//       "Longitude": 51.099998474121094,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 367,
//       "Airport Code [ICAO]": "EDLA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Arnsberg-Menden Airport",
//       "Altitude (Ft)": 794,
//       "Country": "Germany",
//       "Latitude": 7.8983330726623535,
//       "Location": "Arnsberg",
//       "Longitude": 51.483890533447266,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 368,
//       "Airport Code [ICAO]": "EDLE",
//       "Airport Code iATA,FAA": "ESS",
//       "Airport Name": "Essen Mulheim Airport",
//       "Altitude (Ft)": 424,
//       "Country": "Germany",
//       "Latitude": 6.9373297691345215,
//       "Location": "Essen",
//       "Longitude": 51.40230178833008,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 369,
//       "Airport Code [ICAO]": "EDLI",
//       "Airport Code iATA,FAA": "BFE",
//       "Airport Name": "Bielefeld Airport",
//       "Altitude (Ft)": 454,
//       "Country": "Germany",
//       "Latitude": 8.544444,
//       "Location": "Bielefeld",
//       "Longitude": 51.964722,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 370,
//       "Airport Code [ICAO]": "EDLN",
//       "Airport Code iATA,FAA": "MGL",
//       "Airport Name": "Mönchengladbach Airport",
//       "Altitude (Ft)": 125,
//       "Country": "Germany",
//       "Latitude": 6.504444,
//       "Location": "Moenchengladbach",
//       "Longitude": 51.230278,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 371,
//       "Airport Code [ICAO]": "EDLP",
//       "Airport Code iATA,FAA": "PAD",
//       "Airport Name": "Paderborn Lippstadt Airport",
//       "Altitude (Ft)": 699,
//       "Country": "Germany",
//       "Latitude": 8.616319656369999,
//       "Location": "Paderborn",
//       "Longitude": 51.614101409899995,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 372,
//       "Airport Code [ICAO]": "EDLS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Stadtlohn-Vreden Airport",
//       "Altitude (Ft)": 157,
//       "Country": "Germany",
//       "Latitude": 6.8405561447143555,
//       "Location": "Stadtlohn",
//       "Longitude": 51.99583435058594,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 373,
//       "Airport Code [ICAO]": "EDLW",
//       "Airport Code iATA,FAA": "DTM",
//       "Airport Name": "Dortmund Airport",
//       "Altitude (Ft)": 425,
//       "Country": "Germany",
//       "Latitude": 7.61223983765,
//       "Location": "Dortmund",
//       "Longitude": 51.51829910279999,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 374,
//       "Airport Code [ICAO]": "EDMA",
//       "Airport Code iATA,FAA": "AGB",
//       "Airport Name": "Augsburg Airport",
//       "Altitude (Ft)": 1516,
//       "Country": "Germany",
//       "Latitude": 10.931667,
//       "Location": "Augsburg",
//       "Longitude": 48.425278,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 375,
//       "Airport Code [ICAO]": "EDMB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Biberach a.d. Riß Airfield",
//       "Altitude (Ft)": 1903,
//       "Country": "Germany",
//       "Latitude": 9.762778,
//       "Location": "Biberach",
//       "Longitude": 48.111111,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 376,
//       "Airport Code [ICAO]": "EDME",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Eggenfelden Airport",
//       "Altitude (Ft)": 1342,
//       "Country": "Germany",
//       "Latitude": 12.723610877990723,
//       "Location": "Eggenfelden",
//       "Longitude": 48.39611053466797,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 377,
//       "Airport Code [ICAO]": "EDMN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mindelheim-Mattsies Airfield",
//       "Altitude (Ft)": 1857,
//       "Country": "Germany",
//       "Latitude": 10.525,
//       "Location": "Mindelheim",
//       "Longitude": 48.106945,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 378,
//       "Airport Code [ICAO]": "EDMO",
//       "Airport Code iATA,FAA": "OBF",
//       "Airport Name": "Oberpfaffenhofen Airport",
//       "Altitude (Ft)": 1947,
//       "Country": "Germany",
//       "Latitude": 11.2831,
//       "Location": "Oberpfaffenhofen",
//       "Longitude": 48.081402,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 379,
//       "Airport Code [ICAO]": "EDMS",
//       "Airport Code iATA,FAA": "RBM",
//       "Airport Name": "Straubing Airport",
//       "Altitude (Ft)": 1047,
//       "Country": "Germany",
//       "Latitude": 12.516667366027832,
//       "Location": "Straubing",
//       "Longitude": 48.90083312988281,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 380,
//       "Airport Code [ICAO]": "EDMV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Vilshofen Airport",
//       "Altitude (Ft)": 991,
//       "Country": "Germany",
//       "Latitude": 13.195555686950684,
//       "Location": "Vilshofen",
//       "Longitude": 48.6349983215332,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 381,
//       "Airport Code [ICAO]": "EDNL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Leutkirch-Unterzeil Airport",
//       "Altitude (Ft)": 2100,
//       "Country": "Germany",
//       "Latitude": 10.014166831970215,
//       "Location": "Leutkirch",
//       "Longitude": 47.858890533447266,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 382,
//       "Airport Code [ICAO]": "EDNY",
//       "Airport Code iATA,FAA": "FDH",
//       "Airport Name": "Friedrichshafen Airport",
//       "Altitude (Ft)": 1367,
//       "Country": "Germany",
//       "Latitude": 9.51148986816,
//       "Location": "Friedrichshafen",
//       "Longitude": 47.671298980699994,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 383,
//       "Airport Code [ICAO]": "EDOP",
//       "Airport Code iATA,FAA": "SZW",
//       "Airport Name": "Schwerin Parchim Airport",
//       "Altitude (Ft)": 166,
//       "Country": "Germany",
//       "Latitude": 11.7834,
//       "Location": "Parchim",
//       "Longitude": 53.426998,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 384,
//       "Airport Code [ICAO]": "EDOV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Stendal-Borstel Airport",
//       "Altitude (Ft)": 184,
//       "Country": "Germany",
//       "Latitude": 11.818611145019531,
//       "Location": "Stendal",
//       "Longitude": 52.62888717651367,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 385,
//       "Airport Code [ICAO]": "EDPA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Aalen-Heidenheim/Elchingen Airport",
//       "Altitude (Ft)": 1916,
//       "Country": "Germany",
//       "Latitude": 10.264721870422363,
//       "Location": "Aalen-heidenheim",
//       "Longitude": 48.77777862548828,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 386,
//       "Airport Code [ICAO]": "EDQD",
//       "Airport Code iATA,FAA": "BYU",
//       "Airport Name": "Bayreuth Airport",
//       "Altitude (Ft)": 1601,
//       "Country": "Germany",
//       "Latitude": 11.64,
//       "Location": "Bayreuth",
//       "Longitude": 49.985001,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 387,
//       "Airport Code [ICAO]": "EDQE",
//       "Airport Code iATA,FAA": "URD",
//       "Airport Name": "Burg Feuerstein Airport",
//       "Altitude (Ft)": 1673,
//       "Country": "Germany",
//       "Latitude": 11.133610725403,
//       "Location": "Burg Feuerstein",
//       "Longitude": 49.794166564941,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 388,
//       "Airport Code [ICAO]": "EDQM",
//       "Airport Code iATA,FAA": "HOQ",
//       "Airport Name": "Hof-Plauen Airport",
//       "Altitude (Ft)": 1959,
//       "Country": "Germany",
//       "Latitude": 11.856389045715332,
//       "Location": "Hof",
//       "Longitude": 50.288612365722656,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 389,
//       "Airport Code [ICAO]": "EDQT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Haßfurt-Schweinfurt Airport",
//       "Altitude (Ft)": 719,
//       "Country": "Germany",
//       "Latitude": 10.529443740844727,
//       "Location": "Hassfurt",
//       "Longitude": 50.0180549621582,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 390,
//       "Airport Code [ICAO]": "EDRK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Koblenz-Winningen Airfield",
//       "Altitude (Ft)": 640,
//       "Country": "Germany",
//       "Latitude": 7.528611,
//       "Location": "Koblenz",
//       "Longitude": 50.325558,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 391,
//       "Airport Code [ICAO]": "EDRT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Trier-Föhren Airport",
//       "Altitude (Ft)": 666,
//       "Country": "Germany",
//       "Latitude": 6.7875,
//       "Location": "Trier",
//       "Longitude": 49.863888,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 392,
//       "Airport Code [ICAO]": "EDRY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Speyer Airfield",
//       "Altitude (Ft)": 312,
//       "Country": "Germany",
//       "Latitude": 8.451389,
//       "Location": "Speyer",
//       "Longitude": 49.304722,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 393,
//       "Airport Code [ICAO]": "EDRZ",
//       "Airport Code iATA,FAA": "ZQW",
//       "Airport Name": "Zweibrücken Airport",
//       "Altitude (Ft)": 1132,
//       "Country": "Germany",
//       "Latitude": 7.40056,
//       "Location": "Zweibruecken",
//       "Longitude": 49.2094,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 394,
//       "Airport Code [ICAO]": "EDTD",
//       "Airport Code iATA,FAA": "ZQL",
//       "Airport Name": "Donaueschingen-Villingen Airport",
//       "Altitude (Ft)": 2231,
//       "Country": "Germany",
//       "Latitude": 8.52222156525,
//       "Location": "Donaueschingen",
//       "Longitude": 47.97333145139999,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 395,
//       "Airport Code [ICAO]": "EDTF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Freiburg i. Br. Airport",
//       "Altitude (Ft)": 801,
//       "Country": "Germany",
//       "Latitude": 7.83249998093,
//       "Location": "Freiburg",
//       "Longitude": 48.022777557400005,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 396,
//       "Airport Code [ICAO]": "EDTM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mengen-Hohentengen Airport",
//       "Altitude (Ft)": 1818,
//       "Country": "Germany",
//       "Latitude": 9.372777938842773,
//       "Location": "Mengen",
//       "Longitude": 48.053890228271484,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 397,
//       "Airport Code [ICAO]": "EDTY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Adolf Würth Airport",
//       "Altitude (Ft)": 1299,
//       "Country": "Germany",
//       "Latitude": 9.783888816829998,
//       "Location": "Schwaebisch Hall",
//       "Longitude": 49.1183319092,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 398,
//       "Airport Code [ICAO]": "EDUS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Finsterwalde/Schacksdorf Airport",
//       "Altitude (Ft)": 384,
//       "Country": "Germany",
//       "Latitude": 13.743611,
//       "Location": "Soest",
//       "Longitude": 51.607498,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 399,
//       "Airport Code [ICAO]": "EDVE",
//       "Airport Code iATA,FAA": "BWE",
//       "Airport Name": "Braunschweig-Wolfsburg Airport",
//       "Altitude (Ft)": 295,
//       "Country": "Germany",
//       "Latitude": 10.5561,
//       "Location": "Braunschweig",
//       "Longitude": 52.319199,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 400,
//       "Airport Code [ICAO]": "EDVK",
//       "Airport Code iATA,FAA": "KSF",
//       "Airport Name": "Kassel-Calden Airport",
//       "Altitude (Ft)": 820,
//       "Country": "Germany",
//       "Latitude": 9.384967,
//       "Location": "Kassel",
//       "Longitude": 51.417273,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 401,
//       "Airport Code [ICAO]": "EDVM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hildesheim Airport",
//       "Altitude (Ft)": 292,
//       "Country": "Germany",
//       "Latitude": 9.946389198303223,
//       "Location": "Hildesheim",
//       "Longitude": 52.18138885498047,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 402,
//       "Airport Code [ICAO]": "EDWB",
//       "Airport Code iATA,FAA": "BRV",
//       "Airport Name": "Bremerhaven Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Germany",
//       "Latitude": 8.572778,
//       "Location": "Bremerhaven",
//       "Longitude": 53.506943,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 403,
//       "Airport Code [ICAO]": "EDWE",
//       "Airport Code iATA,FAA": "EME",
//       "Airport Name": "Emden Airport",
//       "Altitude (Ft)": 3,
//       "Country": "Germany",
//       "Latitude": 7.2275,
//       "Location": "Emden",
//       "Longitude": 53.391109,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 404,
//       "Airport Code [ICAO]": "EDWF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Leer-Papenburg Airport",
//       "Altitude (Ft)": 3,
//       "Country": "Germany",
//       "Latitude": 7.441667079925537,
//       "Location": "Leer",
//       "Longitude": 53.271942138671875,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 405,
//       "Airport Code [ICAO]": "EDWI",
//       "Airport Code iATA,FAA": "WVN",
//       "Airport Name": "Wilhelmshaven-Mariensiel Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Germany",
//       "Latitude": 8.05222225189209,
//       "Location": "Wilhelmshaven",
//       "Longitude": 53.502220153808594,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 406,
//       "Airport Code [ICAO]": "EDWR",
//       "Airport Code iATA,FAA": "BMK",
//       "Airport Name": "Borkum Airport",
//       "Altitude (Ft)": 3,
//       "Country": "Germany",
//       "Latitude": 6.709167,
//       "Location": "Borkum",
//       "Longitude": 53.59639,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 407,
//       "Airport Code [ICAO]": "EDWY",
//       "Airport Code iATA,FAA": "NRD",
//       "Airport Name": "Norderney Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Germany",
//       "Latitude": 7.230000019073486,
//       "Location": "Norderney",
//       "Longitude": 53.70694351196289,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 408,
//       "Airport Code [ICAO]": "EDXF",
//       "Airport Code iATA,FAA": "FLF",
//       "Airport Name": "Flensburg-Schäferhaus Airport",
//       "Altitude (Ft)": 131,
//       "Country": "Germany",
//       "Latitude": 9.378889083862305,
//       "Location": "Flensburg",
//       "Longitude": 54.77333450317383,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 409,
//       "Airport Code [ICAO]": "EDXR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Rendsburg-Schachtholm Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Germany",
//       "Latitude": 9.599444389343262,
//       "Location": "Rendsburg",
//       "Longitude": 54.220001220703125,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 410,
//       "Airport Code [ICAO]": "EDXW",
//       "Airport Code iATA,FAA": "GWT",
//       "Airport Name": "Westerland Sylt Airport",
//       "Altitude (Ft)": 51,
//       "Country": "Germany",
//       "Latitude": 8.34047031403,
//       "Location": "Westerland",
//       "Longitude": 54.9132003784,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 411,
//       "Airport Code [ICAO]": "EEEI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ämari Air Base",
//       "Altitude (Ft)": 65,
//       "Country": "Estonia",
//       "Latitude": 24.208499908447266,
//       "Location": "Armari Air Force Base",
//       "Longitude": 59.26029968261719,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Tallinn",
//       "Type": "airport"
//     },
//     {
//       "ID": 412,
//       "Airport Code [ICAO]": "EEKA",
//       "Airport Code iATA,FAA": "KDL",
//       "Airport Name": "Kärdla Airport",
//       "Altitude (Ft)": 18,
//       "Country": "Estonia",
//       "Latitude": 22.830699920654297,
//       "Location": "Kardla",
//       "Longitude": 58.99079895019531,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Tallinn",
//       "Type": "airport"
//     },
//     {
//       "ID": 413,
//       "Airport Code [ICAO]": "EEKE",
//       "Airport Code iATA,FAA": "URE",
//       "Airport Name": "Kuressaare Airport",
//       "Altitude (Ft)": 14,
//       "Country": "Estonia",
//       "Latitude": 22.50950050354004,
//       "Location": "Kuressaare",
//       "Longitude": 58.22990036010742,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Tallinn",
//       "Type": "airport"
//     },
//     {
//       "ID": 414,
//       "Airport Code [ICAO]": "EEPU",
//       "Airport Code iATA,FAA": "EPU",
//       "Airport Name": "Pärnu Airport",
//       "Altitude (Ft)": 47,
//       "Country": "Estonia",
//       "Latitude": 24.47279930114746,
//       "Location": "Parnu",
//       "Longitude": 58.41899871826172,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Tallinn",
//       "Type": "airport"
//     },
//     {
//       "ID": 415,
//       "Airport Code [ICAO]": "EETN",
//       "Airport Code iATA,FAA": "TLL",
//       "Airport Name": "Lennart Meri Tallinn Airport",
//       "Altitude (Ft)": 131,
//       "Country": "Estonia",
//       "Latitude": 24.832799911499997,
//       "Location": "Tallinn-ulemiste International",
//       "Longitude": 59.41329956049999,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Tallinn",
//       "Type": "airport"
//     },
//     {
//       "ID": 416,
//       "Airport Code [ICAO]": "EETU",
//       "Airport Code iATA,FAA": "TAY",
//       "Airport Name": "Tartu Airport",
//       "Altitude (Ft)": 219,
//       "Country": "Estonia",
//       "Latitude": 26.690399169900004,
//       "Location": "Tartu",
//       "Longitude": 58.3074989319,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Tallinn",
//       "Type": "airport"
//     },
//     {
//       "ID": 417,
//       "Airport Code [ICAO]": "EFET",
//       "Airport Code iATA,FAA": "ENF",
//       "Airport Name": "Enontekio Airport",
//       "Altitude (Ft)": 1005,
//       "Country": "Finland",
//       "Latitude": 23.424299240112,
//       "Location": "Enontekio",
//       "Longitude": 68.362602233887,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 418,
//       "Airport Code [ICAO]": "EFEU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Eura Airport",
//       "Altitude (Ft)": 259,
//       "Country": "Finland",
//       "Latitude": 22.201400756835938,
//       "Location": "Eura",
//       "Longitude": 61.1161003112793,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 419,
//       "Airport Code [ICAO]": "EFHA",
//       "Airport Code iATA,FAA": "KEV",
//       "Airport Name": "Halli Airport",
//       "Altitude (Ft)": 479,
//       "Country": "Finland",
//       "Latitude": 24.786686,
//       "Location": "Halli",
//       "Longitude": 61.856039,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 420,
//       "Airport Code [ICAO]": "EFHF",
//       "Airport Code iATA,FAA": "HEM",
//       "Airport Name": "Helsinki Malmi Airport",
//       "Altitude (Ft)": 57,
//       "Country": "Finland",
//       "Latitude": 25.042800903320312,
//       "Location": "Helsinki",
//       "Longitude": 60.254600524902344,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 421,
//       "Airport Code [ICAO]": "EFHK",
//       "Airport Code iATA,FAA": "HEL",
//       "Airport Name": "Helsinki Vantaa Airport",
//       "Altitude (Ft)": 179,
//       "Country": "Finland",
//       "Latitude": 24.963300704956,
//       "Location": "Helsinki",
//       "Longitude": 60.317199707031,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 422,
//       "Airport Code [ICAO]": "EFHM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hameenkyro Airport",
//       "Altitude (Ft)": 449,
//       "Country": "Finland",
//       "Latitude": 23.073699951171875,
//       "Location": "Hameenkyro",
//       "Longitude": 61.689701080322266,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 423,
//       "Airport Code [ICAO]": "EFHN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hanko Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Finland",
//       "Latitude": 23.083599090576172,
//       "Location": "Hanko",
//       "Longitude": 59.848899841308594,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 424,
//       "Airport Code [ICAO]": "EFHV",
//       "Airport Code iATA,FAA": "HYV",
//       "Airport Name": "Hyvinkää Airfield",
//       "Altitude (Ft)": 430,
//       "Country": "Finland",
//       "Latitude": 24.8810997009,
//       "Location": "Hyvinkaa",
//       "Longitude": 60.6543998718,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 425,
//       "Airport Code [ICAO]": "EFIK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kiikala Airport",
//       "Altitude (Ft)": 381,
//       "Country": "Finland",
//       "Latitude": 23.6525001526,
//       "Location": "Kikala",
//       "Longitude": 60.4625015259,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 426,
//       "Airport Code [ICAO]": "EFIM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Immola Airport",
//       "Altitude (Ft)": 338,
//       "Country": "Finland",
//       "Latitude": 28.90369987487793,
//       "Location": "Immola",
//       "Longitude": 61.24919891357422,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 427,
//       "Airport Code [ICAO]": "EFIT",
//       "Airport Code iATA,FAA": "KTQ",
//       "Airport Name": "Kitee Airport",
//       "Altitude (Ft)": 364,
//       "Country": "Finland",
//       "Latitude": 30.073601,
//       "Location": "Kitee",
//       "Longitude": 62.1661,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 428,
//       "Airport Code [ICAO]": "EFIV",
//       "Airport Code iATA,FAA": "IVL",
//       "Airport Name": "Ivalo Airport",
//       "Altitude (Ft)": 481,
//       "Country": "Finland",
//       "Latitude": 27.405300140381,
//       "Location": "Ivalo",
//       "Longitude": 68.607299804688,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 429,
//       "Airport Code [ICAO]": "EFJO",
//       "Airport Code iATA,FAA": "JOE",
//       "Airport Name": "Joensuu Airport",
//       "Altitude (Ft)": 398,
//       "Country": "Finland",
//       "Latitude": 29.607500076294,
//       "Location": "Joensuu",
//       "Longitude": 62.662899017334,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 430,
//       "Airport Code [ICAO]": "EFJY",
//       "Airport Code iATA,FAA": "JYV",
//       "Airport Name": "Jyvaskyla Airport",
//       "Altitude (Ft)": 459,
//       "Country": "Finland",
//       "Latitude": 25.678300857544,
//       "Location": "Jyvaskyla",
//       "Longitude": 62.399501800537,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 431,
//       "Airport Code [ICAO]": "EFKA",
//       "Airport Code iATA,FAA": "KAU",
//       "Airport Name": "Kauhava Airport",
//       "Altitude (Ft)": 151,
//       "Country": "Finland",
//       "Latitude": 23.051399,
//       "Location": "Kauhava",
//       "Longitude": 63.127102,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 432,
//       "Airport Code [ICAO]": "EFKE",
//       "Airport Code iATA,FAA": "KEM",
//       "Airport Name": "Kemi-Tornio Airport",
//       "Altitude (Ft)": 61,
//       "Country": "Finland",
//       "Latitude": 24.582099914551,
//       "Location": "Kemi",
//       "Longitude": 65.778701782227,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 433,
//       "Airport Code [ICAO]": "EFKI",
//       "Airport Code iATA,FAA": "KAJ",
//       "Airport Name": "Kajaani Airport",
//       "Altitude (Ft)": 483,
//       "Country": "Finland",
//       "Latitude": 27.692399978638,
//       "Location": "Kajaani",
//       "Longitude": 64.285499572754,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 434,
//       "Airport Code [ICAO]": "EFKJ",
//       "Airport Code iATA,FAA": "KHJ",
//       "Airport Name": "Kauhajoki Airport",
//       "Altitude (Ft)": 407,
//       "Country": "Finland",
//       "Latitude": 22.3931007385,
//       "Location": "Kauhajoki",
//       "Longitude": 62.4625015259,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 435,
//       "Airport Code [ICAO]": "EFKK",
//       "Airport Code iATA,FAA": "KOK",
//       "Airport Name": "Kokkola-Pietarsaari Airport",
//       "Altitude (Ft)": 84,
//       "Country": "Finland",
//       "Latitude": 23.143100738525,
//       "Location": "Kruunupyy",
//       "Longitude": 63.721199035645,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 436,
//       "Airport Code [ICAO]": "EFKM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kemijarvi Airport",
//       "Altitude (Ft)": 692,
//       "Country": "Finland",
//       "Latitude": 27.156799,
//       "Location": "Kemijarvi",
//       "Longitude": 66.712898,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 437,
//       "Airport Code [ICAO]": "EFKS",
//       "Airport Code iATA,FAA": "KAO",
//       "Airport Name": "Kuusamo Airport",
//       "Altitude (Ft)": 866,
//       "Country": "Finland",
//       "Latitude": 29.239400863647,
//       "Location": "Kuusamo",
//       "Longitude": 65.987602233887,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 438,
//       "Airport Code [ICAO]": "EFKT",
//       "Airport Code iATA,FAA": "KTT",
//       "Airport Name": "Kittilä Airport",
//       "Altitude (Ft)": 644,
//       "Country": "Finland",
//       "Latitude": 24.846799850464,
//       "Location": "Kittila",
//       "Longitude": 67.700996398926,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 439,
//       "Airport Code [ICAO]": "EFKU",
//       "Airport Code iATA,FAA": "KUO",
//       "Airport Name": "Kuopio Airport",
//       "Altitude (Ft)": 323,
//       "Country": "Finland",
//       "Latitude": 27.797800064087,
//       "Location": "Kuopio",
//       "Longitude": 63.007099151611,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 440,
//       "Airport Code [ICAO]": "EFLA",
//       "Airport Code iATA,FAA": "QLF",
//       "Airport Name": "Lahti Vesivehmaa Airport",
//       "Altitude (Ft)": 502,
//       "Country": "Finland",
//       "Latitude": 25.693501,
//       "Location": "Vesivehmaa",
//       "Longitude": 61.144199,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 441,
//       "Airport Code [ICAO]": "EFLP",
//       "Airport Code iATA,FAA": "LPP",
//       "Airport Name": "Lappeenranta Airport",
//       "Altitude (Ft)": 349,
//       "Country": "Finland",
//       "Latitude": 28.144743,
//       "Location": "Lappeenranta",
//       "Longitude": 61.044601,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 442,
//       "Airport Code [ICAO]": "EFMA",
//       "Airport Code iATA,FAA": "MHQ",
//       "Airport Name": "Mariehamn Airport",
//       "Altitude (Ft)": 17,
//       "Country": "Finland",
//       "Latitude": 19.898199081421,
//       "Location": "Mariehamn",
//       "Longitude": 60.122200012207,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Mariehamn",
//       "Type": "airport"
//     },
//     {
//       "ID": 443,
//       "Airport Code [ICAO]": "EFME",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Menkijarvi Airport",
//       "Altitude (Ft)": 331,
//       "Country": "Finland",
//       "Latitude": 23.51889991760254,
//       "Location": "Menkijarvi",
//       "Longitude": 62.94670104980469,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 444,
//       "Airport Code [ICAO]": "EFMI",
//       "Airport Code iATA,FAA": "MIK",
//       "Airport Name": "Mikkeli Airport",
//       "Altitude (Ft)": 329,
//       "Country": "Finland",
//       "Latitude": 27.201799,
//       "Location": "Mikkeli",
//       "Longitude": 61.6866,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 445,
//       "Airport Code [ICAO]": "EFNU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nummela Airport",
//       "Altitude (Ft)": 367,
//       "Country": "Finland",
//       "Latitude": 24.2964000702,
//       "Location": "Nummela",
//       "Longitude": 60.333900451699996,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 446,
//       "Airport Code [ICAO]": "EFOU",
//       "Airport Code iATA,FAA": "OUL",
//       "Airport Name": "Oulu Airport",
//       "Altitude (Ft)": 47,
//       "Country": "Finland",
//       "Latitude": 25.354600906372,
//       "Location": "Oulu",
//       "Longitude": 64.930099487305,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 447,
//       "Airport Code [ICAO]": "EFPI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Piikajarvi Airport",
//       "Altitude (Ft)": 148,
//       "Country": "Finland",
//       "Latitude": 22.19339942932129,
//       "Location": "Piikajarvi",
//       "Longitude": 61.245601654052734,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 448,
//       "Airport Code [ICAO]": "EFPO",
//       "Airport Code iATA,FAA": "POR",
//       "Airport Name": "Pori Airport",
//       "Altitude (Ft)": 44,
//       "Country": "Finland",
//       "Latitude": 21.799999237061,
//       "Location": "Pori",
//       "Longitude": 61.461700439453,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 449,
//       "Airport Code [ICAO]": "EFPU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Pudasjärvi Airport",
//       "Altitude (Ft)": 397,
//       "Country": "Finland",
//       "Latitude": 26.946899414100002,
//       "Location": "Pudasjarvi",
//       "Longitude": 65.4021987915,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 450,
//       "Airport Code [ICAO]": "EFPY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Pyhäsalmi Airport",
//       "Altitude (Ft)": 528,
//       "Country": "Finland",
//       "Latitude": 25.926300048799998,
//       "Location": "Pyhasalmi",
//       "Longitude": 63.7318992615,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 451,
//       "Airport Code [ICAO]": "EFRH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Raahe Pattijoki Airport",
//       "Altitude (Ft)": 118,
//       "Country": "Finland",
//       "Latitude": 24.6958007812,
//       "Location": "Pattijoki",
//       "Longitude": 64.6881027222,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 452,
//       "Airport Code [ICAO]": "EFRN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Rantasalmi Airport",
//       "Altitude (Ft)": 292,
//       "Country": "Finland",
//       "Latitude": 28.3565006256,
//       "Location": "Rantasalmi",
//       "Longitude": 62.0654983521,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 453,
//       "Airport Code [ICAO]": "EFRO",
//       "Airport Code iATA,FAA": "RVN",
//       "Airport Name": "Rovaniemi Airport",
//       "Altitude (Ft)": 642,
//       "Country": "Finland",
//       "Latitude": 25.830400466919,
//       "Location": "Rovaniemi",
//       "Longitude": 66.564796447754,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 454,
//       "Airport Code [ICAO]": "EFRY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Rayskala Airport",
//       "Altitude (Ft)": 407,
//       "Country": "Finland",
//       "Latitude": 24.107799530029297,
//       "Location": "Rayskala",
//       "Longitude": 60.74470138549805,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 455,
//       "Airport Code [ICAO]": "EFSA",
//       "Airport Code iATA,FAA": "SVL",
//       "Airport Name": "Savonlinna Airport",
//       "Altitude (Ft)": 311,
//       "Country": "Finland",
//       "Latitude": 28.945100784302,
//       "Location": "Savonlinna",
//       "Longitude": 61.943099975586,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 456,
//       "Airport Code [ICAO]": "EFSE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Selanpaa Airport",
//       "Altitude (Ft)": 417,
//       "Country": "Finland",
//       "Latitude": 26.798900604248047,
//       "Location": "Selanpaa",
//       "Longitude": 61.062400817871094,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 457,
//       "Airport Code [ICAO]": "EFSO",
//       "Airport Code iATA,FAA": "SOT",
//       "Airport Name": "Sodankyla Airport",
//       "Altitude (Ft)": 602,
//       "Country": "Finland",
//       "Latitude": 26.6191005707,
//       "Location": "Sodankyla",
//       "Longitude": 67.3949966431,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 458,
//       "Airport Code [ICAO]": "EFTP",
//       "Airport Code iATA,FAA": "TMP",
//       "Airport Name": "Tampere-Pirkkala Airport",
//       "Altitude (Ft)": 390,
//       "Country": "Finland",
//       "Latitude": 23.604400634766,
//       "Location": "Tampere",
//       "Longitude": 61.414100646973,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 459,
//       "Airport Code [ICAO]": "EFTS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Teisko Airport",
//       "Altitude (Ft)": 515,
//       "Country": "Finland",
//       "Latitude": 24.027,
//       "Location": "Teisko",
//       "Longitude": 61.7733,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 460,
//       "Airport Code [ICAO]": "EFTU",
//       "Airport Code iATA,FAA": "TKU",
//       "Airport Name": "Turku Airport",
//       "Altitude (Ft)": 161,
//       "Country": "Finland",
//       "Latitude": 22.262800216675,
//       "Location": "Turku",
//       "Longitude": 60.514099121094,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 461,
//       "Airport Code [ICAO]": "EFUT",
//       "Airport Code iATA,FAA": "UTI",
//       "Airport Name": "Utti Air Base",
//       "Altitude (Ft)": 339,
//       "Country": "Finland",
//       "Latitude": 26.938400268555,
//       "Location": "Utti",
//       "Longitude": 60.89640045166,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 462,
//       "Airport Code [ICAO]": "EFVA",
//       "Airport Code iATA,FAA": "VAA",
//       "Airport Name": "Vaasa Airport",
//       "Altitude (Ft)": 19,
//       "Country": "Finland",
//       "Latitude": 21.762199401855,
//       "Location": "Vaasa",
//       "Longitude": 63.050701141357,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 463,
//       "Airport Code [ICAO]": "EFVR",
//       "Airport Code iATA,FAA": "VRK",
//       "Airport Name": "Varkaus Airport",
//       "Altitude (Ft)": 286,
//       "Country": "Finland",
//       "Latitude": 27.868600845337,
//       "Location": "Varkaus",
//       "Longitude": 62.171100616455,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 464,
//       "Airport Code [ICAO]": "EFYL",
//       "Airport Code iATA,FAA": "YLI",
//       "Airport Name": "Ylivieska Airfield",
//       "Altitude (Ft)": 252,
//       "Country": "Finland",
//       "Latitude": 24.7252778,
//       "Location": "Ylivieska-raudaskyla",
//       "Longitude": 64.0547222,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 465,
//       "Airport Code [ICAO]": "EGAA",
//       "Airport Code iATA,FAA": "BFS",
//       "Airport Name": "Belfast International Airport",
//       "Altitude (Ft)": 268,
//       "Country": "United Kingdom",
//       "Latitude": -6.2158298492399995,
//       "Location": "Belfast",
//       "Longitude": 54.6575012207,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 466,
//       "Airport Code [ICAO]": "EGAB",
//       "Airport Code iATA,FAA": "ENK",
//       "Airport Name": "St Angelo Airport",
//       "Altitude (Ft)": 155,
//       "Country": "United Kingdom",
//       "Latitude": -7.651669979095459,
//       "Location": "Enniskillen",
//       "Longitude": 54.39889907836914,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 467,
//       "Airport Code [ICAO]": "EGAC",
//       "Airport Code iATA,FAA": "BHD",
//       "Airport Name": "George Best Belfast City Airport",
//       "Altitude (Ft)": 15,
//       "Country": "United Kingdom",
//       "Latitude": -5.872499942779541,
//       "Location": "Belfast",
//       "Longitude": 54.618099212646484,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 468,
//       "Airport Code [ICAO]": "EGAE",
//       "Airport Code iATA,FAA": "LDY",
//       "Airport Name": "City of Derry Airport",
//       "Altitude (Ft)": 22,
//       "Country": "United Kingdom",
//       "Latitude": -7.161109924316406,
//       "Location": "Londonderry",
//       "Longitude": 55.04280090332031,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 469,
//       "Airport Code [ICAO]": "EGBB",
//       "Airport Code iATA,FAA": "BHX",
//       "Airport Name": "Birmingham International Airport",
//       "Altitude (Ft)": 327,
//       "Country": "United Kingdom",
//       "Latitude": -1.74802994728,
//       "Location": "Birmingham",
//       "Longitude": 52.453899383499994,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 470,
//       "Airport Code [ICAO]": "EGBE",
//       "Airport Code iATA,FAA": "CVT",
//       "Airport Name": "Coventry Airport",
//       "Altitude (Ft)": 267,
//       "Country": "United Kingdom",
//       "Latitude": -1.4797199964499999,
//       "Location": "Coventry",
//       "Longitude": 52.3697013855,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 471,
//       "Airport Code [ICAO]": "EGBG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Leicester Airport",
//       "Altitude (Ft)": 469,
//       "Country": "United Kingdom",
//       "Latitude": -1.03193998336792,
//       "Location": "Leicester",
//       "Longitude": 52.6077995300293,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 472,
//       "Airport Code [ICAO]": "EGBJ",
//       "Airport Code iATA,FAA": "GLO",
//       "Airport Name": "Gloucestershire Airport",
//       "Altitude (Ft)": 101,
//       "Country": "United Kingdom",
//       "Latitude": -2.167220115661621,
//       "Location": "Golouchestershire",
//       "Longitude": 51.89419937133789,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 474,
//       "Airport Code [ICAO]": "EGBO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Wolverhampton Halfpenny Green Airport",
//       "Altitude (Ft)": 283,
//       "Country": "United Kingdom",
//       "Latitude": -2.2594399452209473,
//       "Location": "Halfpenny Green",
//       "Longitude": 52.51750183105469,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 475,
//       "Airport Code [ICAO]": "EGBP",
//       "Airport Code iATA,FAA": "GBA",
//       "Airport Name": "Cotswold Airport",
//       "Altitude (Ft)": 433,
//       "Country": "United Kingdom",
//       "Latitude": -2.05694,
//       "Location": "Pailton",
//       "Longitude": 51.668095,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 476,
//       "Airport Code [ICAO]": "EGBT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Turweston Airport",
//       "Altitude (Ft)": 448,
//       "Country": "United Kingdom",
//       "Latitude": -1.09555995464,
//       "Location": "Turweston",
//       "Longitude": 52.0407981873,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 477,
//       "Airport Code [ICAO]": "EGBW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Wellesbourne Mountford Airport",
//       "Altitude (Ft)": 159,
//       "Country": "United Kingdom",
//       "Latitude": -1.6144399642944336,
//       "Location": "Wellesbourne",
//       "Longitude": 52.19219970703125,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 478,
//       "Airport Code [ICAO]": "EGCC",
//       "Airport Code iATA,FAA": "MAN",
//       "Airport Name": "Manchester Airport",
//       "Altitude (Ft)": 257,
//       "Country": "United Kingdom",
//       "Latitude": -2.2749500274658203,
//       "Location": "Manchester",
//       "Longitude": 53.35369873046875,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 479,
//       "Airport Code [ICAO]": "EGCD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Manchester Woodford Airport",
//       "Altitude (Ft)": 295,
//       "Country": "United Kingdom",
//       "Latitude": -2.14889,
//       "Location": "Woodfort",
//       "Longitude": 53.3381,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 480,
//       "Airport Code [ICAO]": "EGDC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Royal Marines Base Chivenor Airport",
//       "Altitude (Ft)": 27,
//       "Country": "United Kingdom",
//       "Latitude": -4.1503400802612305,
//       "Location": "Chivenor",
//       "Longitude": 51.08720016479492,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 481,
//       "Airport Code [ICAO]": "EGHQ",
//       "Airport Code iATA,FAA": "NQY",
//       "Airport Name": "Newquay Cornwall Airport",
//       "Altitude (Ft)": 390,
//       "Country": "United Kingdom",
//       "Latitude": -4.995409965515137,
//       "Location": "Newquai",
//       "Longitude": 50.44060134887695,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 482,
//       "Airport Code [ICAO]": "EGDL",
//       "Airport Code iATA,FAA": "LYE",
//       "Airport Name": "RAF Lyneham",
//       "Altitude (Ft)": 513,
//       "Country": "United Kingdom",
//       "Latitude": -1.99343,
//       "Location": "Lyneham",
//       "Longitude": 51.5051,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 483,
//       "Airport Code [ICAO]": "EGDM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "MoD Boscombe Down Airport",
//       "Altitude (Ft)": 407,
//       "Country": "United Kingdom",
//       "Latitude": -1.7474100589752197,
//       "Location": "Boscombe Down",
//       "Longitude": 51.152198791503906,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 484,
//       "Airport Code [ICAO]": "EGDR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "RNAS Culdrose",
//       "Altitude (Ft)": 267,
//       "Country": "United Kingdom",
//       "Latitude": -5.255710124969482,
//       "Location": "Culdrose",
//       "Longitude": 50.08610153198242,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 485,
//       "Airport Code [ICAO]": "EGDX",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "MoD St. Athan",
//       "Altitude (Ft)": 163,
//       "Country": "United Kingdom",
//       "Latitude": -3.43575,
//       "Location": "St. Athan",
//       "Longitude": 51.4048,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 486,
//       "Airport Code [ICAO]": "EGDY",
//       "Airport Code iATA,FAA": "YEO",
//       "Airport Name": "RNAS Yeovilton",
//       "Altitude (Ft)": 75,
//       "Country": "United Kingdom",
//       "Latitude": -2.638819932937622,
//       "Location": "Yeovilton",
//       "Longitude": 51.0093994140625,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 487,
//       "Airport Code [ICAO]": "EGFE",
//       "Airport Code iATA,FAA": "HAW",
//       "Airport Name": "Haverfordwest Airport",
//       "Altitude (Ft)": 159,
//       "Country": "United Kingdom",
//       "Latitude": -4.9611101150512695,
//       "Location": "Haverfordwest",
//       "Longitude": 51.833099365234375,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 488,
//       "Airport Code [ICAO]": "EGFF",
//       "Airport Code iATA,FAA": "CWL",
//       "Airport Name": "Cardiff International Airport",
//       "Altitude (Ft)": 220,
//       "Country": "United Kingdom",
//       "Latitude": -3.343329906463623,
//       "Location": "Cardiff",
//       "Longitude": 51.39670181274414,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 489,
//       "Airport Code [ICAO]": "EGFH",
//       "Airport Code iATA,FAA": "SWS",
//       "Airport Name": "Swansea Airport",
//       "Altitude (Ft)": 299,
//       "Country": "United Kingdom",
//       "Latitude": -4.0678300857543945,
//       "Location": "Swansea",
//       "Longitude": 51.60530090332031,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 490,
//       "Airport Code [ICAO]": "EGGD",
//       "Airport Code iATA,FAA": "BRS",
//       "Airport Name": "Bristol Airport",
//       "Altitude (Ft)": 622,
//       "Country": "United Kingdom",
//       "Latitude": -2.71909,
//       "Location": "Bristol",
//       "Longitude": 51.382702,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 491,
//       "Airport Code [ICAO]": "EGGP",
//       "Airport Code iATA,FAA": "LPL",
//       "Airport Name": "Liverpool John Lennon Airport",
//       "Altitude (Ft)": 80,
//       "Country": "United Kingdom",
//       "Latitude": -2.849720001220703,
//       "Location": "Liverpool",
//       "Longitude": 53.33359909057617,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 492,
//       "Airport Code [ICAO]": "EGGW",
//       "Airport Code iATA,FAA": "LTN",
//       "Airport Name": "London Luton Airport",
//       "Altitude (Ft)": 526,
//       "Country": "United Kingdom",
//       "Latitude": -0.36833301186561584,
//       "Location": "London",
//       "Longitude": 51.874698638916016,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 493,
//       "Airport Code [ICAO]": "EGHD",
//       "Airport Code iATA,FAA": "PLH",
//       "Airport Name": "Plymouth City Airport",
//       "Altitude (Ft)": 476,
//       "Country": "United Kingdom",
//       "Latitude": -4.10583,
//       "Location": "Plymouth",
//       "Longitude": 50.422798,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 494,
//       "Airport Code [ICAO]": "EGHH",
//       "Airport Code iATA,FAA": "BOH",
//       "Airport Name": "Bournemouth Airport",
//       "Altitude (Ft)": 38,
//       "Country": "United Kingdom",
//       "Latitude": -1.8424999713897705,
//       "Location": "Bournemouth",
//       "Longitude": 50.779998779296875,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 495,
//       "Airport Code [ICAO]": "EGHI",
//       "Airport Code iATA,FAA": "SOU",
//       "Airport Name": "Southampton Airport",
//       "Altitude (Ft)": 44,
//       "Country": "United Kingdom",
//       "Latitude": -1.3567999601364136,
//       "Location": "Southampton",
//       "Longitude": 50.95029830932617,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 496,
//       "Airport Code [ICAO]": "EGHL",
//       "Airport Code iATA,FAA": "QLA",
//       "Airport Name": "Lasham Airport",
//       "Altitude (Ft)": 618,
//       "Country": "United Kingdom",
//       "Latitude": -1.0334999561309814,
//       "Location": "Lasham",
//       "Longitude": 51.187198638916016,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 497,
//       "Airport Code [ICAO]": "EGJA",
//       "Airport Code iATA,FAA": "ACI",
//       "Airport Name": "Alderney Airport",
//       "Altitude (Ft)": 290,
//       "Country": "Guernsey",
//       "Latitude": -2.21472,
//       "Location": "Alderney",
//       "Longitude": 49.706104,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Guernsey",
//       "Type": "airport"
//     },
//     {
//       "ID": 498,
//       "Airport Code [ICAO]": "EGJB",
//       "Airport Code iATA,FAA": "GCI",
//       "Airport Name": "Guernsey Airport",
//       "Altitude (Ft)": 336,
//       "Country": "Guernsey",
//       "Latitude": -2.6019699573516846,
//       "Location": "Guernsey",
//       "Longitude": 49.435001373291016,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Guernsey",
//       "Type": "airport"
//     },
//     {
//       "ID": 499,
//       "Airport Code [ICAO]": "EGJJ",
//       "Airport Code iATA,FAA": "JER",
//       "Airport Name": "Jersey Airport",
//       "Altitude (Ft)": 277,
//       "Country": "Jersey",
//       "Latitude": -2.195509910583496,
//       "Location": "Jersey",
//       "Longitude": 49.20790100097656,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Jersey",
//       "Type": "airport"
//     },
//     {
//       "ID": 500,
//       "Airport Code [ICAO]": "EGKA",
//       "Airport Code iATA,FAA": "ESH",
//       "Airport Name": "Shoreham Airport",
//       "Altitude (Ft)": 7,
//       "Country": "United Kingdom",
//       "Latitude": -0.297222,
//       "Location": "Shoreham By Sea",
//       "Longitude": 50.835602,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 501,
//       "Airport Code [ICAO]": "EGKB",
//       "Airport Code iATA,FAA": "BQH",
//       "Airport Name": "London Biggin Hill Airport",
//       "Altitude (Ft)": 598,
//       "Country": "United Kingdom",
//       "Latitude": 0.0324999988079,
//       "Location": "Biggin Hill",
//       "Longitude": 51.33079910279999,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 502,
//       "Airport Code [ICAO]": "EGKK",
//       "Airport Code iATA,FAA": "LGW",
//       "Airport Name": "London Gatwick Airport",
//       "Altitude (Ft)": 202,
//       "Country": "United Kingdom",
//       "Latitude": -0.190278,
//       "Location": "London",
//       "Longitude": 51.148102,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 503,
//       "Airport Code [ICAO]": "EGLC",
//       "Airport Code iATA,FAA": "LCY",
//       "Airport Name": "London City Airport",
//       "Altitude (Ft)": 19,
//       "Country": "United Kingdom",
//       "Latitude": 0.055278,
//       "Location": "London",
//       "Longitude": 51.505299,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 504,
//       "Airport Code [ICAO]": "EGLF",
//       "Airport Code iATA,FAA": "FAB",
//       "Airport Name": "Farnborough Airport",
//       "Altitude (Ft)": 238,
//       "Country": "United Kingdom",
//       "Latitude": -0.776332974434,
//       "Location": "Farnborough",
//       "Longitude": 51.2757987976,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 505,
//       "Airport Code [ICAO]": "EGLJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Chalgrove Airport",
//       "Altitude (Ft)": 240,
//       "Country": "United Kingdom",
//       "Latitude": -1.0808299779891968,
//       "Location": "Chalsgrove",
//       "Longitude": 51.67610168457031,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 506,
//       "Airport Code [ICAO]": "EGLK",
//       "Airport Code iATA,FAA": "BBS",
//       "Airport Name": "Blackbushe Airport",
//       "Altitude (Ft)": 325,
//       "Country": "United Kingdom",
//       "Latitude": -0.8475000262260437,
//       "Location": "Blackbushe",
//       "Longitude": 51.32389831542969,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 507,
//       "Airport Code [ICAO]": "EGLL",
//       "Airport Code iATA,FAA": "LHR",
//       "Airport Name": "London Heathrow Airport",
//       "Altitude (Ft)": 83,
//       "Country": "United Kingdom",
//       "Latitude": -0.461941,
//       "Location": "London",
//       "Longitude": 51.4706,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 508,
//       "Airport Code [ICAO]": "EGMC",
//       "Airport Code iATA,FAA": "SEN",
//       "Airport Name": "Southend Airport",
//       "Altitude (Ft)": 49,
//       "Country": "United Kingdom",
//       "Latitude": 0.6955559849739075,
//       "Location": "Southend",
//       "Longitude": 51.5713996887207,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 509,
//       "Airport Code [ICAO]": "EGMD",
//       "Airport Code iATA,FAA": "LYX",
//       "Airport Name": "Lydd Airport",
//       "Altitude (Ft)": 13,
//       "Country": "United Kingdom",
//       "Latitude": 0.9391670227050781,
//       "Location": "Lydd",
//       "Longitude": 50.95610046386719,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 510,
//       "Airport Code [ICAO]": "EGMH",
//       "Airport Code iATA,FAA": "MSE",
//       "Airport Name": "Kent International Airport",
//       "Altitude (Ft)": 178,
//       "Country": "United Kingdom",
//       "Latitude": 1.34611,
//       "Location": "Manston",
//       "Longitude": 51.342201,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 511,
//       "Airport Code [ICAO]": "EGNB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Brough Airport",
//       "Altitude (Ft)": 12,
//       "Country": "United Kingdom",
//       "Latitude": -0.566333,
//       "Location": "Brough",
//       "Longitude": 53.7197,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 512,
//       "Airport Code [ICAO]": "EGNC",
//       "Airport Code iATA,FAA": "CAX",
//       "Airport Name": "Carlisle Airport",
//       "Altitude (Ft)": 190,
//       "Country": "United Kingdom",
//       "Latitude": -2.8091700077056885,
//       "Location": "Carlisle",
//       "Longitude": 54.9375,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 513,
//       "Airport Code [ICAO]": "EGNE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Retford Gamston Airport",
//       "Altitude (Ft)": 91,
//       "Country": "United Kingdom",
//       "Latitude": -0.9513890147209167,
//       "Location": "Repton",
//       "Longitude": 53.280601501464844,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 514,
//       "Airport Code [ICAO]": "EGNH",
//       "Airport Code iATA,FAA": "BLK",
//       "Airport Name": "Blackpool International Airport",
//       "Altitude (Ft)": 34,
//       "Country": "United Kingdom",
//       "Latitude": -3.0286099910736084,
//       "Location": "Blackpool",
//       "Longitude": 53.77170181274414,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 515,
//       "Airport Code [ICAO]": "EGNJ",
//       "Airport Code iATA,FAA": "HUY",
//       "Airport Name": "Humberside Airport",
//       "Altitude (Ft)": 121,
//       "Country": "United Kingdom",
//       "Latitude": -0.350832998752594,
//       "Location": "Humberside",
//       "Longitude": 53.57440185546875,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 516,
//       "Airport Code [ICAO]": "EGNL",
//       "Airport Code iATA,FAA": "BWF",
//       "Airport Name": "Barrow Walney Island Airport",
//       "Altitude (Ft)": 173,
//       "Country": "United Kingdom",
//       "Latitude": -3.2675,
//       "Location": "Barrow Island",
//       "Longitude": 54.1286111,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 517,
//       "Airport Code [ICAO]": "EGNM",
//       "Airport Code iATA,FAA": "LBA",
//       "Airport Name": "Leeds Bradford Airport",
//       "Altitude (Ft)": 681,
//       "Country": "United Kingdom",
//       "Latitude": -1.6605700254440308,
//       "Location": "Leeds",
//       "Longitude": 53.86589813232422,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 518,
//       "Airport Code [ICAO]": "EGNO",
//       "Airport Code iATA,FAA": "WRT",
//       "Airport Name": "Warton Airport",
//       "Altitude (Ft)": 55,
//       "Country": "United Kingdom",
//       "Latitude": -2.88306,
//       "Location": "Warton",
//       "Longitude": 53.745098,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 519,
//       "Airport Code [ICAO]": "EGNR",
//       "Airport Code iATA,FAA": "CEG",
//       "Airport Name": "Hawarden Airport",
//       "Altitude (Ft)": 45,
//       "Country": "United Kingdom",
//       "Latitude": -2.9777801036834717,
//       "Location": "Hawarden",
//       "Longitude": 53.1781005859375,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 520,
//       "Airport Code [ICAO]": "EGNS",
//       "Airport Code iATA,FAA": "IOM",
//       "Airport Name": "Isle of Man Airport",
//       "Altitude (Ft)": 52,
//       "Country": "Isle of Man",
//       "Latitude": -4.623889923095703,
//       "Location": "Isle Of Man",
//       "Longitude": 54.08330154418945,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Isle_of_Man",
//       "Type": "airport"
//     },
//     {
//       "ID": 521,
//       "Airport Code [ICAO]": "EGNT",
//       "Airport Code iATA,FAA": "NCL",
//       "Airport Name": "Newcastle Airport",
//       "Altitude (Ft)": 266,
//       "Country": "United Kingdom",
//       "Latitude": -1.6916699409484863,
//       "Location": "Newcastle",
//       "Longitude": 55.037498474121094,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 522,
//       "Airport Code [ICAO]": "EGNV",
//       "Airport Code iATA,FAA": "MME",
//       "Airport Name": "Durham Tees Valley Airport",
//       "Altitude (Ft)": 120,
//       "Country": "United Kingdom",
//       "Latitude": -1.4294099807739258,
//       "Location": "Teesside",
//       "Longitude": 54.50920104980469,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 523,
//       "Airport Code [ICAO]": "EGNX",
//       "Airport Code iATA,FAA": "EMA",
//       "Airport Name": "East Midlands Airport",
//       "Altitude (Ft)": 306,
//       "Country": "United Kingdom",
//       "Latitude": -1.32806003094,
//       "Location": "East Midlands",
//       "Longitude": 52.8311004639,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 524,
//       "Airport Code [ICAO]": "EGOD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Llanbedr Airport",
//       "Altitude (Ft)": 30,
//       "Country": "United Kingdom",
//       "Latitude": -4.1235799789429,
//       "Location": "Llanbedr",
//       "Longitude": 52.811698913574,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 525,
//       "Airport Code [ICAO]": "EGOE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "RAF Ternhill",
//       "Altitude (Ft)": 272,
//       "Country": "United Kingdom",
//       "Latitude": -2.533560037612915,
//       "Location": "Ternhill",
//       "Longitude": 52.87120056152344,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 526,
//       "Airport Code [ICAO]": "EGOS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "RAF Shawbury",
//       "Altitude (Ft)": 249,
//       "Country": "United Kingdom",
//       "Latitude": -2.6680400371551514,
//       "Location": "Shawbury",
//       "Longitude": 52.79819869995117,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 528,
//       "Airport Code [ICAO]": "EGOW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "RAF Woodvale",
//       "Altitude (Ft)": 37,
//       "Country": "United Kingdom",
//       "Latitude": -3.05552,
//       "Location": "Woodvale",
//       "Longitude": 53.5816,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 529,
//       "Airport Code [ICAO]": "EGPA",
//       "Airport Code iATA,FAA": "KOI",
//       "Airport Name": "Kirkwall Airport",
//       "Altitude (Ft)": 50,
//       "Country": "United Kingdom",
//       "Latitude": -2.9049999713897705,
//       "Location": "Kirkwall",
//       "Longitude": 58.957801818847656,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 530,
//       "Airport Code [ICAO]": "EGPB",
//       "Airport Code iATA,FAA": "LSI",
//       "Airport Name": "Sumburgh Airport",
//       "Altitude (Ft)": 20,
//       "Country": "United Kingdom",
//       "Latitude": -1.2955600023269653,
//       "Location": "Sumburgh",
//       "Longitude": 59.87889862060547,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 531,
//       "Airport Code [ICAO]": "EGPC",
//       "Airport Code iATA,FAA": "WIC",
//       "Airport Name": "Wick Airport",
//       "Altitude (Ft)": 126,
//       "Country": "United Kingdom",
//       "Latitude": -3.09306001663208,
//       "Location": "Wick",
//       "Longitude": 58.458900451660156,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 532,
//       "Airport Code [ICAO]": "EGPD",
//       "Airport Code iATA,FAA": "ABZ",
//       "Airport Name": "Aberdeen Dyce Airport",
//       "Altitude (Ft)": 215,
//       "Country": "United Kingdom",
//       "Latitude": -2.197779893875122,
//       "Location": "Aberdeen",
//       "Longitude": 57.201900482177734,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 533,
//       "Airport Code [ICAO]": "EGPE",
//       "Airport Code iATA,FAA": "INV",
//       "Airport Name": "Inverness Airport",
//       "Altitude (Ft)": 31,
//       "Country": "United Kingdom",
//       "Latitude": -4.047500133514404,
//       "Location": "Inverness",
//       "Longitude": 57.54249954223633,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 534,
//       "Airport Code [ICAO]": "EGPF",
//       "Airport Code iATA,FAA": "GLA",
//       "Airport Name": "Glasgow International Airport",
//       "Altitude (Ft)": 26,
//       "Country": "United Kingdom",
//       "Latitude": -4.43306016922,
//       "Location": "Glasgow",
//       "Longitude": 55.8718986511,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 535,
//       "Airport Code [ICAO]": "EGPH",
//       "Airport Code iATA,FAA": "EDI",
//       "Airport Name": "Edinburgh Airport",
//       "Altitude (Ft)": 135,
//       "Country": "United Kingdom",
//       "Latitude": -3.372499942779541,
//       "Location": "Edinburgh",
//       "Longitude": 55.95000076293945,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 536,
//       "Airport Code [ICAO]": "EGPI",
//       "Airport Code iATA,FAA": "ILY",
//       "Airport Name": "Islay Airport",
//       "Altitude (Ft)": 56,
//       "Country": "United Kingdom",
//       "Latitude": -6.256669998168945,
//       "Location": "Islay",
//       "Longitude": 55.68190002441406,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 537,
//       "Airport Code [ICAO]": "EGPK",
//       "Airport Code iATA,FAA": "PIK",
//       "Airport Name": "Glasgow Prestwick Airport",
//       "Altitude (Ft)": 65,
//       "Country": "United Kingdom",
//       "Latitude": -4.586669921875,
//       "Location": "Prestwick",
//       "Longitude": 55.5093994140625,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 538,
//       "Airport Code [ICAO]": "EGPL",
//       "Airport Code iATA,FAA": "BEB",
//       "Airport Name": "Benbecula Airport",
//       "Altitude (Ft)": 19,
//       "Country": "United Kingdom",
//       "Latitude": -7.3627800941467285,
//       "Location": "Benbecula",
//       "Longitude": 57.48109817504883,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 539,
//       "Airport Code [ICAO]": "EGPM",
//       "Airport Code iATA,FAA": "SCS",
//       "Airport Name": "Scatsta Airport",
//       "Altitude (Ft)": 81,
//       "Country": "United Kingdom",
//       "Latitude": -1.2961100339889526,
//       "Location": "Scatsta",
//       "Longitude": 60.43280029296875,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 540,
//       "Airport Code [ICAO]": "EGPN",
//       "Airport Code iATA,FAA": "DND",
//       "Airport Name": "Dundee Airport",
//       "Altitude (Ft)": 17,
//       "Country": "United Kingdom",
//       "Latitude": -3.025830030441284,
//       "Location": "Dundee",
//       "Longitude": 56.45249938964844,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 541,
//       "Airport Code [ICAO]": "EGPO",
//       "Airport Code iATA,FAA": "SYY",
//       "Airport Name": "Stornoway Airport",
//       "Altitude (Ft)": 26,
//       "Country": "United Kingdom",
//       "Latitude": -6.331110000610352,
//       "Location": "Stornoway",
//       "Longitude": 58.215599060058594,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 542,
//       "Airport Code [ICAO]": "EGPU",
//       "Airport Code iATA,FAA": "TRE",
//       "Airport Name": "Tiree Airport",
//       "Altitude (Ft)": 38,
//       "Country": "United Kingdom",
//       "Latitude": -6.869170188903809,
//       "Location": "Tiree",
//       "Longitude": 56.49919891357422,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 543,
//       "Airport Code [ICAO]": "EGQL",
//       "Airport Code iATA,FAA": "ADX",
//       "Airport Name": "RAF Leuchars",
//       "Altitude (Ft)": 38,
//       "Country": "United Kingdom",
//       "Latitude": -2.8684399127960205,
//       "Location": "Leuchars",
//       "Longitude": 56.37289810180664,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 544,
//       "Airport Code [ICAO]": "EGQS",
//       "Airport Code iATA,FAA": "LMO",
//       "Airport Name": "RAF Lossiemouth",
//       "Altitude (Ft)": 42,
//       "Country": "United Kingdom",
//       "Latitude": -3.339169979095459,
//       "Location": "Lossiemouth",
//       "Longitude": 57.7052001953125,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 545,
//       "Airport Code [ICAO]": "EGSC",
//       "Airport Code iATA,FAA": "CBG",
//       "Airport Name": "Cambridge Airport",
//       "Altitude (Ft)": 47,
//       "Country": "United Kingdom",
//       "Latitude": 0.175,
//       "Location": "Cambridge",
//       "Longitude": 52.205002,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 546,
//       "Airport Code [ICAO]": "EGSF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Peterborough Business Airport",
//       "Altitude (Ft)": 26,
//       "Country": "United Kingdom",
//       "Latitude": -0.2511110007762909,
//       "Location": "Peterborough",
//       "Longitude": 52.468101501464844,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 547,
//       "Airport Code [ICAO]": "EGSH",
//       "Airport Code iATA,FAA": "NWI",
//       "Airport Name": "Norwich International Airport",
//       "Altitude (Ft)": 117,
//       "Country": "United Kingdom",
//       "Latitude": 1.28278005123,
//       "Location": "Norwich",
//       "Longitude": 52.6758003235,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 548,
//       "Airport Code [ICAO]": "EGSS",
//       "Airport Code iATA,FAA": "STN",
//       "Airport Name": "London Stansted Airport",
//       "Altitude (Ft)": 348,
//       "Country": "United Kingdom",
//       "Latitude": 0.234999999404,
//       "Location": "London",
//       "Longitude": 51.8849983215,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 549,
//       "Airport Code [ICAO]": "EGSX",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "North Weald Airport",
//       "Altitude (Ft)": 321,
//       "Country": "United Kingdom",
//       "Latitude": 0.154166996479,
//       "Location": "North Weald",
//       "Longitude": 51.721698761,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 550,
//       "Airport Code [ICAO]": "EGSY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sheffield City Heliport",
//       "Altitude (Ft)": 231,
//       "Country": "United Kingdom",
//       "Latitude": -1.38849,
//       "Location": "Fowlmere",
//       "Longitude": 53.394299,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 551,
//       "Airport Code [ICAO]": "EGTC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cranfield Airport",
//       "Altitude (Ft)": 358,
//       "Country": "United Kingdom",
//       "Latitude": -0.616666972637,
//       "Location": "Cranfield",
//       "Longitude": 52.0722007751,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 552,
//       "Airport Code [ICAO]": "EGTE",
//       "Airport Code iATA,FAA": "EXT",
//       "Airport Name": "Exeter International Airport",
//       "Altitude (Ft)": 102,
//       "Country": "United Kingdom",
//       "Latitude": -3.4138898849487305,
//       "Location": "Exeter",
//       "Longitude": 50.73440170288086,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 553,
//       "Airport Code [ICAO]": "EGTG",
//       "Airport Code iATA,FAA": "FZO",
//       "Airport Name": "Bristol Filton Airport",
//       "Altitude (Ft)": 226,
//       "Country": "United Kingdom",
//       "Latitude": -2.59083008766,
//       "Location": "Bristol",
//       "Longitude": 51.5194015503,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 554,
//       "Airport Code [ICAO]": "EGTK",
//       "Airport Code iATA,FAA": "OXF",
//       "Airport Name": "Oxford (Kidlington) Airport",
//       "Altitude (Ft)": 270,
//       "Country": "United Kingdom",
//       "Latitude": -1.32000005245,
//       "Location": "Oxford",
//       "Longitude": 51.8368988037,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 555,
//       "Airport Code [ICAO]": "EGUB",
//       "Airport Code iATA,FAA": "BEX",
//       "Airport Name": "RAF Benson",
//       "Altitude (Ft)": 226,
//       "Country": "United Kingdom",
//       "Latitude": -1.09582996368,
//       "Location": "Benson",
//       "Longitude": 51.616401672399995,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 556,
//       "Airport Code [ICAO]": "EGUL",
//       "Airport Code iATA,FAA": "LKZ",
//       "Airport Name": "RAF Lakenheath",
//       "Altitude (Ft)": 32,
//       "Country": "United Kingdom",
//       "Latitude": 0.56099998951,
//       "Location": "Lakenheath",
//       "Longitude": 52.409301757799994,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 557,
//       "Airport Code [ICAO]": "EGUN",
//       "Airport Code iATA,FAA": "MHZ",
//       "Airport Name": "RAF Mildenhall",
//       "Altitude (Ft)": 33,
//       "Country": "United Kingdom",
//       "Latitude": 0.48640599846839905,
//       "Location": "Mildenhall",
//       "Longitude": 52.361900329589844,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 558,
//       "Airport Code [ICAO]": "EGUW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "RAF Wattisham",
//       "Altitude (Ft)": 284,
//       "Country": "United Kingdom",
//       "Latitude": 0.956264019012,
//       "Location": "Wattisham",
//       "Longitude": 52.1273002625,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 559,
//       "Airport Code [ICAO]": "EGUY",
//       "Airport Code iATA,FAA": "QUY",
//       "Airport Name": "RAF Wyton",
//       "Altitude (Ft)": 135,
//       "Country": "United Kingdom",
//       "Latitude": -0.107832998037,
//       "Location": "Wyton",
//       "Longitude": 52.3572006226,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 560,
//       "Airport Code [ICAO]": "EGVA",
//       "Airport Code iATA,FAA": "FFD",
//       "Airport Name": "RAF Fairford",
//       "Altitude (Ft)": 286,
//       "Country": "United Kingdom",
//       "Latitude": -1.7900300025900002,
//       "Location": "Fairford",
//       "Longitude": 51.6822013855,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 561,
//       "Airport Code [ICAO]": "EGVN",
//       "Airport Code iATA,FAA": "BZZ",
//       "Airport Name": "RAF Brize Norton",
//       "Altitude (Ft)": 288,
//       "Country": "United Kingdom",
//       "Latitude": -1.58362,
//       "Location": "Brize Norton",
//       "Longitude": 51.75,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 562,
//       "Airport Code [ICAO]": "EGVO",
//       "Airport Code iATA,FAA": "ODH",
//       "Airport Name": "RAF Odiham",
//       "Altitude (Ft)": 405,
//       "Country": "United Kingdom",
//       "Latitude": -0.94282501936,
//       "Location": "Odiham",
//       "Longitude": 51.2341003418,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 563,
//       "Airport Code [ICAO]": "EGWC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "DCAE Cosford Air Base",
//       "Altitude (Ft)": 272,
//       "Country": "United Kingdom",
//       "Latitude": -2.30558,
//       "Location": "Cosford",
//       "Longitude": 52.639999,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 564,
//       "Airport Code [ICAO]": "EGWU",
//       "Airport Code iATA,FAA": "NHT",
//       "Airport Name": "RAF Northolt",
//       "Altitude (Ft)": 124,
//       "Country": "United Kingdom",
//       "Latitude": -0.418166995049,
//       "Location": "Northolt",
//       "Longitude": 51.553001403799996,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 565,
//       "Airport Code [ICAO]": "EGXC",
//       "Airport Code iATA,FAA": "QCY",
//       "Airport Name": "RAF Coningsby",
//       "Altitude (Ft)": 25,
//       "Country": "United Kingdom",
//       "Latitude": -0.166014000773,
//       "Location": "Coningsby",
//       "Longitude": 53.0929985046,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 566,
//       "Airport Code [ICAO]": "EGXD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "RAF Dishforth",
//       "Altitude (Ft)": 117,
//       "Country": "United Kingdom",
//       "Latitude": -1.42025005817,
//       "Location": "Dishforth",
//       "Longitude": 54.1371994019,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 567,
//       "Airport Code [ICAO]": "EGXE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Leeming Airport",
//       "Altitude (Ft)": 132,
//       "Country": "United Kingdom",
//       "Latitude": -1.5354,
//       "Location": "Leeming",
//       "Longitude": 54.2924,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 568,
//       "Airport Code [ICAO]": "EGXG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Leeds East Airport",
//       "Altitude (Ft)": 29,
//       "Country": "United Kingdom",
//       "Latitude": -1.1955,
//       "Location": "Church Fenton",
//       "Longitude": 53.834301,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 569,
//       "Airport Code [ICAO]": "EGXH",
//       "Airport Code iATA,FAA": "BEQ",
//       "Airport Name": "RAF Honington",
//       "Altitude (Ft)": 174,
//       "Country": "United Kingdom",
//       "Latitude": 0.7729390263557434,
//       "Location": "Honington",
//       "Longitude": 52.34260177612305,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 570,
//       "Airport Code [ICAO]": "EGXJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "RAF Cottesmore",
//       "Altitude (Ft)": 461,
//       "Country": "United Kingdom",
//       "Latitude": -0.648769,
//       "Location": "Cottesmore",
//       "Longitude": 52.735699,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 571,
//       "Airport Code [ICAO]": "EGXP",
//       "Airport Code iATA,FAA": "SQZ",
//       "Airport Name": "RAF Scampton",
//       "Altitude (Ft)": 202,
//       "Country": "United Kingdom",
//       "Latitude": -0.550832986832,
//       "Location": "Scampton",
//       "Longitude": 53.307800293,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 572,
//       "Airport Code [ICAO]": "EGXT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "RAF Wittering",
//       "Altitude (Ft)": 273,
//       "Country": "United Kingdom",
//       "Latitude": -0.476453,
//       "Location": "Wittering",
//       "Longitude": 52.612598,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 573,
//       "Airport Code [ICAO]": "EGXU",
//       "Airport Code iATA,FAA": "HRT",
//       "Airport Name": "RAF Linton-On-Ouse",
//       "Altitude (Ft)": 53,
//       "Country": "United Kingdom",
//       "Latitude": -1.2527500391,
//       "Location": "Linton-on-ouse",
//       "Longitude": 54.0489006042,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 574,
//       "Airport Code [ICAO]": "EGXW",
//       "Airport Code iATA,FAA": "WTN",
//       "Airport Name": "RAF Waddington",
//       "Altitude (Ft)": 231,
//       "Country": "United Kingdom",
//       "Latitude": -0.523810982704,
//       "Location": "Waddington",
//       "Longitude": 53.1661987305,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 575,
//       "Airport Code [ICAO]": "EGXZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "RAF Topcliffe",
//       "Altitude (Ft)": 92,
//       "Country": "United Kingdom",
//       "Latitude": -1.3820899725,
//       "Location": "Topcliffe",
//       "Longitude": 54.205501556399994,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 576,
//       "Airport Code [ICAO]": "EGYD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "RAF Cranwell",
//       "Altitude (Ft)": 218,
//       "Country": "United Kingdom",
//       "Latitude": -0.48324200511,
//       "Location": "Cranwell",
//       "Longitude": 53.0303001404,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 577,
//       "Airport Code [ICAO]": "EGYE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "RAF Barkston Heath",
//       "Altitude (Ft)": 367,
//       "Country": "United Kingdom",
//       "Latitude": -0.561625003815,
//       "Location": "Barkston Heath",
//       "Longitude": 52.962200164799995,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 578,
//       "Airport Code [ICAO]": "EGYM",
//       "Airport Code iATA,FAA": "KNF",
//       "Airport Name": "RAF Marham",
//       "Altitude (Ft)": 75,
//       "Country": "United Kingdom",
//       "Latitude": 0.550692,
//       "Location": "Marham",
//       "Longitude": 52.648395,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 579,
//       "Airport Code [ICAO]": "EGYP",
//       "Airport Code iATA,FAA": "MPN",
//       "Airport Name": "Mount Pleasant Airport",
//       "Altitude (Ft)": 244,
//       "Country": "Falkland Islands",
//       "Latitude": -58.447200775146484,
//       "Location": "Mount Pleasant",
//       "Longitude": -51.82279968261719,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "Atlantic/Stanley",
//       "Type": "airport"
//     },
//     {
//       "ID": 580,
//       "Airport Code [ICAO]": "EHAM",
//       "Airport Code iATA,FAA": "AMS",
//       "Airport Name": "Amsterdam Airport Schiphol",
//       "Altitude (Ft)": -11,
//       "Country": "Netherlands",
//       "Latitude": 4.76389,
//       "Location": "Amsterdam",
//       "Longitude": 52.308601,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Amsterdam",
//       "Type": "airport"
//     },
//     {
//       "ID": 581,
//       "Airport Code [ICAO]": "EHBD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Budel Airfield Kempen",
//       "Altitude (Ft)": 114,
//       "Country": "Netherlands",
//       "Latitude": 5.60139,
//       "Location": "Weert",
//       "Longitude": 51.255299,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Amsterdam",
//       "Type": "airport"
//     },
//     {
//       "ID": 582,
//       "Airport Code [ICAO]": "EHBK",
//       "Airport Code iATA,FAA": "MST",
//       "Airport Name": "Maastricht Aachen Airport",
//       "Altitude (Ft)": 375,
//       "Country": "Netherlands",
//       "Latitude": 5.77014,
//       "Location": "Maastricht",
//       "Longitude": 50.911701,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Amsterdam",
//       "Type": "airport"
//     },
//     {
//       "ID": 583,
//       "Airport Code [ICAO]": "EHDL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Deelen Air Base",
//       "Altitude (Ft)": 158,
//       "Country": "Netherlands",
//       "Latitude": 5.87306,
//       "Location": "Deelen",
//       "Longitude": 52.0606,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Amsterdam",
//       "Type": "airport"
//     },
//     {
//       "ID": 584,
//       "Airport Code [ICAO]": "EHDR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Drachten Airport",
//       "Altitude (Ft)": 14,
//       "Country": "Netherlands",
//       "Latitude": 6.12972021103,
//       "Location": "Drachten",
//       "Longitude": 53.1192016602,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Amsterdam",
//       "Type": "airport"
//     },
//     {
//       "ID": 585,
//       "Airport Code [ICAO]": "EHEH",
//       "Airport Code iATA,FAA": "EIN",
//       "Airport Name": "Eindhoven Airport",
//       "Altitude (Ft)": 74,
//       "Country": "Netherlands",
//       "Latitude": 5.37452983856,
//       "Location": "Eindhoven",
//       "Longitude": 51.4500999451,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Amsterdam",
//       "Type": "airport"
//     },
//     {
//       "ID": 586,
//       "Airport Code [ICAO]": "EHGG",
//       "Airport Code iATA,FAA": "GRQ",
//       "Airport Name": "Eelde Airport",
//       "Altitude (Ft)": 17,
//       "Country": "Netherlands",
//       "Latitude": 6.57944011688,
//       "Location": "Groningen",
//       "Longitude": 53.1197013855,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Amsterdam",
//       "Type": "airport"
//     },
//     {
//       "ID": 587,
//       "Airport Code [ICAO]": "EHGR",
//       "Airport Code iATA,FAA": "GLZ",
//       "Airport Name": "Gilze Rijen Air Base",
//       "Altitude (Ft)": 49,
//       "Country": "Netherlands",
//       "Latitude": 4.931829929351807,
//       "Location": "Gilze-rijen",
//       "Longitude": 51.56740188598633,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Amsterdam",
//       "Type": "airport"
//     },
//     {
//       "ID": 588,
//       "Airport Code [ICAO]": "EHKD",
//       "Airport Code iATA,FAA": "DHR",
//       "Airport Name": "De Kooy Airport",
//       "Altitude (Ft)": 3,
//       "Country": "Netherlands",
//       "Latitude": 4.780620098114014,
//       "Location": "De Kooy",
//       "Longitude": 52.92340087890625,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Amsterdam",
//       "Type": "airport"
//     },
//     {
//       "ID": 589,
//       "Airport Code [ICAO]": "EHLE",
//       "Airport Code iATA,FAA": "LEY",
//       "Airport Name": "Lelystad Airport",
//       "Altitude (Ft)": -13,
//       "Country": "Netherlands",
//       "Latitude": 5.52722,
//       "Location": "Lelystad",
//       "Longitude": 52.4603,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Amsterdam",
//       "Type": "airport"
//     },
//     {
//       "ID": 590,
//       "Airport Code [ICAO]": "EHLW",
//       "Airport Code iATA,FAA": "LWR",
//       "Airport Name": "Leeuwarden Air Base",
//       "Altitude (Ft)": 3,
//       "Country": "Netherlands",
//       "Latitude": 5.760560035705566,
//       "Location": "Leeuwarden",
//       "Longitude": 53.228599548339844,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Amsterdam",
//       "Type": "airport"
//     },
//     {
//       "ID": 591,
//       "Airport Code [ICAO]": "EHRD",
//       "Airport Code iATA,FAA": "RTM",
//       "Airport Name": "Rotterdam The Hague Airport",
//       "Altitude (Ft)": -15,
//       "Country": "Netherlands",
//       "Latitude": 4.43722,
//       "Location": "Rotterdam",
//       "Longitude": 51.956902,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Amsterdam",
//       "Type": "airport"
//     },
//     {
//       "ID": 592,
//       "Airport Code [ICAO]": "EHSB",
//       "Airport Code iATA,FAA": "UTC",
//       "Airport Name": "Soesterberg Air Base",
//       "Altitude (Ft)": 66,
//       "Country": "Netherlands",
//       "Latitude": 5.27618980408,
//       "Location": "Soesterberg",
//       "Longitude": 52.1273002625,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Amsterdam",
//       "Type": "airport"
//     },
//     {
//       "ID": 593,
//       "Airport Code [ICAO]": "EHTW",
//       "Airport Code iATA,FAA": "ENS",
//       "Airport Name": "Twente Airport",
//       "Altitude (Ft)": 114,
//       "Country": "Netherlands",
//       "Latitude": 6.889167,
//       "Location": "Enschede",
//       "Longitude": 52.275833,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Amsterdam",
//       "Type": "airport"
//     },
//     {
//       "ID": 594,
//       "Airport Code [ICAO]": "EHVB",
//       "Airport Code iATA,FAA": "LID",
//       "Airport Name": "Valkenburg Naval Air Base",
//       "Altitude (Ft)": 1,
//       "Country": "Netherlands",
//       "Latitude": 4.41794013977,
//       "Location": "Valkenburg",
//       "Longitude": 52.166099548300004,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Amsterdam",
//       "Type": "airport"
//     },
//     {
//       "ID": 595,
//       "Airport Code [ICAO]": "EHWO",
//       "Airport Code iATA,FAA": "WOE",
//       "Airport Name": "Woensdrecht Air Base",
//       "Altitude (Ft)": 63,
//       "Country": "Netherlands",
//       "Latitude": 4.34203,
//       "Location": "Woensdrecht",
//       "Longitude": 51.4491,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Amsterdam",
//       "Type": "airport"
//     },
//     {
//       "ID": 596,
//       "Airport Code [ICAO]": "EICK",
//       "Airport Code iATA,FAA": "ORK",
//       "Airport Name": "Cork Airport",
//       "Altitude (Ft)": 502,
//       "Country": "Ireland",
//       "Latitude": -8.491109848022461,
//       "Location": "Cork",
//       "Longitude": 51.84130096435547,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Dublin",
//       "Type": "airport"
//     },
//     {
//       "ID": 597,
//       "Airport Code [ICAO]": "EICM",
//       "Airport Code iATA,FAA": "GWY",
//       "Airport Name": "Galway Airport",
//       "Altitude (Ft)": 81,
//       "Country": "Ireland",
//       "Latitude": -8.941590309143066,
//       "Location": "Galway",
//       "Longitude": 53.300201416015625,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Dublin",
//       "Type": "airport"
//     },
//     {
//       "ID": 599,
//       "Airport Code [ICAO]": "EIDW",
//       "Airport Code iATA,FAA": "DUB",
//       "Airport Name": "Dublin Airport",
//       "Altitude (Ft)": 242,
//       "Country": "Ireland",
//       "Latitude": -6.27007,
//       "Location": "Dublin",
//       "Longitude": 53.421299,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Dublin",
//       "Type": "airport"
//     },
//     {
//       "ID": 600,
//       "Airport Code [ICAO]": "EIKN",
//       "Airport Code iATA,FAA": "NOC",
//       "Airport Name": "Ireland West Knock Airport",
//       "Altitude (Ft)": 665,
//       "Country": "Ireland",
//       "Latitude": -8.818490028381348,
//       "Location": "Connaught",
//       "Longitude": 53.910301208496094,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Dublin",
//       "Type": "airport"
//     },
//     {
//       "ID": 601,
//       "Airport Code [ICAO]": "EIKY",
//       "Airport Code iATA,FAA": "KIR",
//       "Airport Name": "Kerry Airport",
//       "Altitude (Ft)": 112,
//       "Country": "Ireland",
//       "Latitude": -9.52377986907959,
//       "Location": "Kerry",
//       "Longitude": 52.18090057373047,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Dublin",
//       "Type": "airport"
//     },
//     {
//       "ID": 602,
//       "Airport Code [ICAO]": "EIME",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Casement Air Base",
//       "Altitude (Ft)": 319,
//       "Country": "Ireland",
//       "Latitude": -6.451330184940001,
//       "Location": "Casement",
//       "Longitude": 53.301700592,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Dublin",
//       "Type": "airport"
//     },
//     {
//       "ID": 603,
//       "Airport Code [ICAO]": "EINN",
//       "Airport Code iATA,FAA": "SNN",
//       "Airport Name": "Shannon Airport",
//       "Altitude (Ft)": 46,
//       "Country": "Ireland",
//       "Latitude": -8.92482,
//       "Location": "Shannon",
//       "Longitude": 52.702,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Dublin",
//       "Type": "airport"
//     },
//     {
//       "ID": 604,
//       "Airport Code [ICAO]": "EISG",
//       "Airport Code iATA,FAA": "SXL",
//       "Airport Name": "Sligo Airport",
//       "Altitude (Ft)": 11,
//       "Country": "Ireland",
//       "Latitude": -8.5992097854614,
//       "Location": "Sligo",
//       "Longitude": 54.280200958252,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Dublin",
//       "Type": "airport"
//     },
//     {
//       "ID": 605,
//       "Airport Code [ICAO]": "EIWF",
//       "Airport Code iATA,FAA": "WAT",
//       "Airport Name": "Waterford Airport",
//       "Altitude (Ft)": 119,
//       "Country": "Ireland",
//       "Latitude": -7.0869598388671875,
//       "Location": "Waterford",
//       "Longitude": 52.187198638916016,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Dublin",
//       "Type": "airport"
//     },
//     {
//       "ID": 607,
//       "Airport Code [ICAO]": "EKAH",
//       "Airport Code iATA,FAA": "AAR",
//       "Airport Name": "Aarhus Airport",
//       "Altitude (Ft)": 82,
//       "Country": "Denmark",
//       "Latitude": 10.619000434899998,
//       "Location": "Aarhus",
//       "Longitude": 56.2999992371,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Copenhagen",
//       "Type": "airport"
//     },
//     {
//       "ID": 608,
//       "Airport Code [ICAO]": "EKBI",
//       "Airport Code iATA,FAA": "BLL",
//       "Airport Name": "Billund Airport",
//       "Altitude (Ft)": 247,
//       "Country": "Denmark",
//       "Latitude": 9.15178012848,
//       "Location": "Billund",
//       "Longitude": 55.7402992249,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Copenhagen",
//       "Type": "airport"
//     },
//     {
//       "ID": 609,
//       "Airport Code [ICAO]": "EKCH",
//       "Airport Code iATA,FAA": "CPH",
//       "Airport Name": "Copenhagen Kastrup Airport",
//       "Altitude (Ft)": 17,
//       "Country": "Denmark",
//       "Latitude": 12.656000137329,
//       "Location": "Copenhagen",
//       "Longitude": 55.617900848389,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Copenhagen",
//       "Type": "airport"
//     },
//     {
//       "ID": 610,
//       "Airport Code [ICAO]": "EKEB",
//       "Airport Code iATA,FAA": "EBJ",
//       "Airport Name": "Esbjerg Airport",
//       "Altitude (Ft)": 97,
//       "Country": "Denmark",
//       "Latitude": 8.5534,
//       "Location": "Esbjerg",
//       "Longitude": 55.525902,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Copenhagen",
//       "Type": "airport"
//     },
//     {
//       "ID": 611,
//       "Airport Code [ICAO]": "EKGH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Grønholt Hillerød Airport",
//       "Altitude (Ft)": 97,
//       "Country": "Denmark",
//       "Latitude": 12.382200241088867,
//       "Location": "Gronholt",
//       "Longitude": 55.94139862060547,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Copenhagen",
//       "Type": "airport"
//     },
//     {
//       "ID": 612,
//       "Airport Code [ICAO]": "EKKA",
//       "Airport Code iATA,FAA": "KRP",
//       "Airport Name": "Karup Airport",
//       "Altitude (Ft)": 170,
//       "Country": "Denmark",
//       "Latitude": 9.124629974365234,
//       "Location": "Karup",
//       "Longitude": 56.29750061035156,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Copenhagen",
//       "Type": "airport"
//     },
//     {
//       "ID": 613,
//       "Airport Code [ICAO]": "EKLS",
//       "Airport Code iATA,FAA": "BYR",
//       "Airport Name": "Læsø Airport",
//       "Altitude (Ft)": 25,
//       "Country": "Denmark",
//       "Latitude": 11.000100135803223,
//       "Location": "Laeso",
//       "Longitude": 57.277198791503906,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Copenhagen",
//       "Type": "airport"
//     },
//     {
//       "ID": 614,
//       "Airport Code [ICAO]": "EKMB",
//       "Airport Code iATA,FAA": "MRW",
//       "Airport Name": "Lolland Falster Maribo Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Denmark",
//       "Latitude": 11.4401,
//       "Location": "Maribo",
//       "Longitude": 54.699299,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Copenhagen",
//       "Type": "airport"
//     },
//     {
//       "ID": 615,
//       "Airport Code [ICAO]": "EKOD",
//       "Airport Code iATA,FAA": "ODE",
//       "Airport Name": "Odense Airport",
//       "Altitude (Ft)": 56,
//       "Country": "Denmark",
//       "Latitude": 10.330900192260742,
//       "Location": "Odense",
//       "Longitude": 55.47669982910156,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Copenhagen",
//       "Type": "airport"
//     },
//     {
//       "ID": 616,
//       "Airport Code [ICAO]": "EKPB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kruså-Padborg Airport",
//       "Altitude (Ft)": 88,
//       "Country": "Denmark",
//       "Latitude": 9.279009819030762,
//       "Location": "Krusa-padborg",
//       "Longitude": 54.87030029296875,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Copenhagen",
//       "Type": "airport"
//     },
//     {
//       "ID": 617,
//       "Airport Code [ICAO]": "EKRK",
//       "Airport Code iATA,FAA": "RKE",
//       "Airport Name": "Copenhagen Roskilde Airport",
//       "Altitude (Ft)": 146,
//       "Country": "Denmark",
//       "Latitude": 12.131400108337402,
//       "Location": "Copenhagen",
//       "Longitude": 55.585601806640625,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Copenhagen",
//       "Type": "airport"
//     },
//     {
//       "ID": 618,
//       "Airport Code [ICAO]": "EKRN",
//       "Airport Code iATA,FAA": "RNN",
//       "Airport Name": "Bornholm Airport",
//       "Altitude (Ft)": 52,
//       "Country": "Denmark",
//       "Latitude": 14.759599685668945,
//       "Location": "Ronne",
//       "Longitude": 55.06330108642578,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Copenhagen",
//       "Type": "airport"
//     },
//     {
//       "ID": 619,
//       "Airport Code [ICAO]": "EKSB",
//       "Airport Code iATA,FAA": "SGD",
//       "Airport Name": "Sønderborg Airport",
//       "Altitude (Ft)": 24,
//       "Country": "Denmark",
//       "Latitude": 9.791729927062988,
//       "Location": "Soenderborg",
//       "Longitude": 54.96440124511719,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Copenhagen",
//       "Type": "airport"
//     },
//     {
//       "ID": 621,
//       "Airport Code [ICAO]": "EKSP",
//       "Airport Code iATA,FAA": "SKS",
//       "Airport Name": "Skrydstrup Air Base",
//       "Altitude (Ft)": 141,
//       "Country": "Denmark",
//       "Latitude": 9.26702,
//       "Location": "Skrydstrup",
//       "Longitude": 55.221048,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Copenhagen",
//       "Type": "airport"
//     },
//     {
//       "ID": 622,
//       "Airport Code [ICAO]": "EKSV",
//       "Airport Code iATA,FAA": "SQW",
//       "Airport Name": "Skive Airport",
//       "Altitude (Ft)": 74,
//       "Country": "Denmark",
//       "Latitude": 9.172980308532715,
//       "Location": "Skive",
//       "Longitude": 56.550201416015625,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Copenhagen",
//       "Type": "airport"
//     },
//     {
//       "ID": 623,
//       "Airport Code [ICAO]": "EKTS",
//       "Airport Code iATA,FAA": "TED",
//       "Airport Name": "Thisted Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Denmark",
//       "Latitude": 8.705220222473145,
//       "Location": "Thisted",
//       "Longitude": 57.06880187988281,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Copenhagen",
//       "Type": "airport"
//     },
//     {
//       "ID": 624,
//       "Airport Code [ICAO]": "EKVD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kolding Vamdrup Airfield",
//       "Altitude (Ft)": 143,
//       "Country": "Denmark",
//       "Latitude": 9.33092,
//       "Location": "Kolding",
//       "Longitude": 55.436298,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Copenhagen",
//       "Type": "airport"
//     },
//     {
//       "ID": 625,
//       "Airport Code [ICAO]": "EKVG",
//       "Airport Code iATA,FAA": "FAE",
//       "Airport Name": "Vagar Airport",
//       "Altitude (Ft)": 280,
//       "Country": "Faroe Islands",
//       "Latitude": -7.277219772338867,
//       "Location": "Vagar",
//       "Longitude": 62.0635986328125,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Atlantic/Faeroe",
//       "Type": "airport"
//     },
//     {
//       "ID": 626,
//       "Airport Code [ICAO]": "EKVH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Vesthimmerlands Flyveplads",
//       "Altitude (Ft)": 119,
//       "Country": "Denmark",
//       "Latitude": 9.45861,
//       "Location": "Vesthimmerland",
//       "Longitude": 56.846901,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Copenhagen",
//       "Type": "airport"
//     },
//     {
//       "ID": 627,
//       "Airport Code [ICAO]": "EKVJ",
//       "Airport Code iATA,FAA": "STA",
//       "Airport Name": "Stauning Airport",
//       "Altitude (Ft)": 17,
//       "Country": "Denmark",
//       "Latitude": 8.353910446166992,
//       "Location": "Stauning",
//       "Longitude": 55.9901008605957,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Copenhagen",
//       "Type": "airport"
//     },
//     {
//       "ID": 628,
//       "Airport Code [ICAO]": "EKYT",
//       "Airport Code iATA,FAA": "AAL",
//       "Airport Name": "Aalborg Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Denmark",
//       "Latitude": 9.84924316406,
//       "Location": "Aalborg",
//       "Longitude": 57.0927589138,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Copenhagen",
//       "Type": "airport"
//     },
//     {
//       "ID": 629,
//       "Airport Code [ICAO]": "ELLX",
//       "Airport Code iATA,FAA": "LUX",
//       "Airport Name": "Luxembourg-Findel International Airport",
//       "Altitude (Ft)": 1234,
//       "Country": "Luxembourg",
//       "Latitude": 6.2044444,
//       "Location": "Luxemburg",
//       "Longitude": 49.6233333,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Luxembourg",
//       "Type": "airport"
//     },
//     {
//       "ID": 630,
//       "Airport Code [ICAO]": "ENAL",
//       "Airport Code iATA,FAA": "AES",
//       "Airport Name": "Ålesund Airport",
//       "Altitude (Ft)": 69,
//       "Country": "Norway",
//       "Latitude": 6.119699954986572,
//       "Location": "Alesund",
//       "Longitude": 62.5625,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 631,
//       "Airport Code [ICAO]": "ENAN",
//       "Airport Code iATA,FAA": "ANX",
//       "Airport Name": "Andøya Airport",
//       "Altitude (Ft)": 43,
//       "Country": "Norway",
//       "Latitude": 16.144199371338,
//       "Location": "Andoya",
//       "Longitude": 69.292503356934,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 632,
//       "Airport Code [ICAO]": "ENAT",
//       "Airport Code iATA,FAA": "ALF",
//       "Airport Name": "Alta Airport",
//       "Altitude (Ft)": 9,
//       "Country": "Norway",
//       "Latitude": 23.371700286865,
//       "Location": "Alta",
//       "Longitude": 69.976097106934,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 633,
//       "Airport Code [ICAO]": "ENBM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bømoen Airport",
//       "Altitude (Ft)": 300,
//       "Country": "Norway",
//       "Latitude": 6.501500129699707,
//       "Location": "Voss",
//       "Longitude": 60.63890075683594,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 634,
//       "Airport Code [ICAO]": "ENBN",
//       "Airport Code iATA,FAA": "BNN",
//       "Airport Name": "Brønnøysund Airport",
//       "Altitude (Ft)": 25,
//       "Country": "Norway",
//       "Latitude": 12.217499732971,
//       "Location": "Bronnoysund",
//       "Longitude": 65.461097717285,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 635,
//       "Airport Code [ICAO]": "ENBO",
//       "Airport Code iATA,FAA": "BOO",
//       "Airport Name": "Bodø Airport",
//       "Altitude (Ft)": 42,
//       "Country": "Norway",
//       "Latitude": 14.365300178527832,
//       "Location": "Bodo",
//       "Longitude": 67.26920318603516,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 636,
//       "Airport Code [ICAO]": "ENBR",
//       "Airport Code iATA,FAA": "BGO",
//       "Airport Name": "Bergen Airport Flesland",
//       "Altitude (Ft)": 170,
//       "Country": "Norway",
//       "Latitude": 5.218140125,
//       "Location": "Bergen",
//       "Longitude": 60.29339981,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 637,
//       "Airport Code [ICAO]": "ENBS",
//       "Airport Code iATA,FAA": "BJF",
//       "Airport Name": "Båtsfjord Airport",
//       "Altitude (Ft)": 490,
//       "Country": "Norway",
//       "Latitude": 29.691400527954,
//       "Location": "Batsfjord",
//       "Longitude": 70.60050201416,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 638,
//       "Airport Code [ICAO]": "ENCN",
//       "Airport Code iATA,FAA": "KRS",
//       "Airport Name": "Kristiansand Airport",
//       "Altitude (Ft)": 57,
//       "Country": "Norway",
//       "Latitude": 8.08537,
//       "Location": "Kristiansand",
//       "Longitude": 58.204201,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 639,
//       "Airport Code [ICAO]": "ENDI",
//       "Airport Code iATA,FAA": "DLD",
//       "Airport Name": "Geilo Airport Dagali",
//       "Altitude (Ft)": 2618,
//       "Country": "Norway",
//       "Latitude": 8.518349647521973,
//       "Location": "Geilo",
//       "Longitude": 60.417301177978516,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 640,
//       "Airport Code [ICAO]": "ENDU",
//       "Airport Code iATA,FAA": "BDU",
//       "Airport Name": "Bardufoss Airport",
//       "Altitude (Ft)": 252,
//       "Country": "Norway",
//       "Latitude": 18.540399551392,
//       "Location": "Bardufoss",
//       "Longitude": 69.055801391602,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 641,
//       "Airport Code [ICAO]": "ENEV",
//       "Airport Code iATA,FAA": "EVE",
//       "Airport Name": "Harstad/Narvik Airport, Evenes",
//       "Altitude (Ft)": 84,
//       "Country": "Norway",
//       "Latitude": 16.678100585938,
//       "Location": "Harstad/Narvik",
//       "Longitude": 68.491302490234,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 642,
//       "Airport Code [ICAO]": "ENFG",
//       "Airport Code iATA,FAA": "VDB",
//       "Airport Name": "Leirin Airport",
//       "Altitude (Ft)": 2697,
//       "Country": "Norway",
//       "Latitude": 9.2880601882935,
//       "Location": "Fagernes",
//       "Longitude": 61.015598297119,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 643,
//       "Airport Code [ICAO]": "ENFL",
//       "Airport Code iATA,FAA": "FRO",
//       "Airport Name": "Florø Airport",
//       "Altitude (Ft)": 37,
//       "Country": "Norway",
//       "Latitude": 5.0247201919556,
//       "Location": "Floro",
//       "Longitude": 61.583599090576,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 644,
//       "Airport Code [ICAO]": "ENGM",
//       "Airport Code iATA,FAA": "OSL",
//       "Airport Name": "Oslo Lufthavn",
//       "Altitude (Ft)": 681,
//       "Country": "Norway",
//       "Latitude": 11.0502,
//       "Location": "Oslo",
//       "Longitude": 60.121,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 645,
//       "Airport Code [ICAO]": "ENHD",
//       "Airport Code iATA,FAA": "HAU",
//       "Airport Name": "Haugesund Airport",
//       "Altitude (Ft)": 86,
//       "Country": "Norway",
//       "Latitude": 5.2083601951599,
//       "Location": "Haugesund",
//       "Longitude": 59.34529876709,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 646,
//       "Airport Code [ICAO]": "ENHK",
//       "Airport Code iATA,FAA": "HAA",
//       "Airport Name": "Hasvik Airport",
//       "Altitude (Ft)": 21,
//       "Country": "Norway",
//       "Latitude": 22.139699935913,
//       "Location": "Hasvik",
//       "Longitude": 70.486701965332,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 647,
//       "Airport Code [ICAO]": "ENKB",
//       "Airport Code iATA,FAA": "KSU",
//       "Airport Name": "Kristiansund Airport (Kvernberget)",
//       "Altitude (Ft)": 204,
//       "Country": "Norway",
//       "Latitude": 7.824520111084,
//       "Location": "Kristiansund",
//       "Longitude": 63.111801147461,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 648,
//       "Airport Code [ICAO]": "ENKJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kjeller Airport",
//       "Altitude (Ft)": 354,
//       "Country": "Norway",
//       "Latitude": 11.036100387600001,
//       "Location": "Kjeller",
//       "Longitude": 59.9692993164,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 649,
//       "Airport Code [ICAO]": "ENKR",
//       "Airport Code iATA,FAA": "KKN",
//       "Airport Name": "Kirkenes Airport (Høybuktmoen)",
//       "Altitude (Ft)": 283,
//       "Country": "Norway",
//       "Latitude": 29.891300201416,
//       "Location": "Kirkenes",
//       "Longitude": 69.725799560547,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 650,
//       "Airport Code [ICAO]": "ENLI",
//       "Airport Code iATA,FAA": "FAN",
//       "Airport Name": "Lista Airport",
//       "Altitude (Ft)": 29,
//       "Country": "Norway",
//       "Latitude": 6.626049995422363,
//       "Location": "Farsund",
//       "Longitude": 58.0994987487793,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 651,
//       "Airport Code [ICAO]": "ENML",
//       "Airport Code iATA,FAA": "MOL",
//       "Airport Name": "Molde Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Norway",
//       "Latitude": 7.2624998092651,
//       "Location": "Molde",
//       "Longitude": 62.744701385498,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 652,
//       "Airport Code [ICAO]": "ENMS",
//       "Airport Code iATA,FAA": "MJF",
//       "Airport Name": "Mosjøen Airport (Kjærstad)",
//       "Altitude (Ft)": 237,
//       "Country": "Norway",
//       "Latitude": 13.214900016785,
//       "Location": "Mosjoen",
//       "Longitude": 65.783996582031,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 653,
//       "Airport Code [ICAO]": "ENNA",
//       "Airport Code iATA,FAA": "LKL",
//       "Airport Name": "Banak Airport",
//       "Altitude (Ft)": 25,
//       "Country": "Norway",
//       "Latitude": 24.973499298096,
//       "Location": "Lakselv",
//       "Longitude": 70.068801879883,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 654,
//       "Airport Code [ICAO]": "ENNO",
//       "Airport Code iATA,FAA": "NTB",
//       "Airport Name": "Notodden Airport",
//       "Altitude (Ft)": 63,
//       "Country": "Norway",
//       "Latitude": 9.21222,
//       "Location": "Notodden",
//       "Longitude": 59.565701,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 655,
//       "Airport Code [ICAO]": "ENOL",
//       "Airport Code iATA,FAA": "OLA",
//       "Airport Name": "Ørland Airport",
//       "Altitude (Ft)": 28,
//       "Country": "Norway",
//       "Latitude": 9.604000091552734,
//       "Location": "Orland",
//       "Longitude": 63.69889831542969,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 656,
//       "Airport Code [ICAO]": "ENRO",
//       "Airport Code iATA,FAA": "RRS",
//       "Airport Name": "Røros Airport",
//       "Altitude (Ft)": 2054,
//       "Country": "Norway",
//       "Latitude": 11.342300415039,
//       "Location": "Roros",
//       "Longitude": 62.578399658203,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 657,
//       "Airport Code [ICAO]": "ENRY",
//       "Airport Code iATA,FAA": "RYG",
//       "Airport Name": "Moss Airport, Rygge",
//       "Altitude (Ft)": 174,
//       "Country": "Norway",
//       "Latitude": 10.785439,
//       "Location": "Rygge",
//       "Longitude": 59.378817,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 658,
//       "Airport Code [ICAO]": "ENSB",
//       "Airport Code iATA,FAA": "LYR",
//       "Airport Name": "Svalbard Airport, Longyear",
//       "Altitude (Ft)": 88,
//       "Country": "Norway",
//       "Latitude": 15.465600013733,
//       "Location": "Svalbard",
//       "Longitude": 78.246101379395,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Arctic/Longyearbyen",
//       "Type": "airport"
//     },
//     {
//       "ID": 659,
//       "Airport Code [ICAO]": "ENSN",
//       "Airport Code iATA,FAA": "SKE",
//       "Airport Name": "Skien Airport",
//       "Altitude (Ft)": 463,
//       "Country": "Norway",
//       "Latitude": 9.566940307617188,
//       "Location": "Skien",
//       "Longitude": 59.185001373291016,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 660,
//       "Airport Code [ICAO]": "ENSO",
//       "Airport Code iATA,FAA": "SRP",
//       "Airport Name": "Stord Airport",
//       "Altitude (Ft)": 160,
//       "Country": "Norway",
//       "Latitude": 5.340849876403809,
//       "Location": "Stord",
//       "Longitude": 59.791900634765625,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 662,
//       "Airport Code [ICAO]": "ENST",
//       "Airport Code iATA,FAA": "SSJ",
//       "Airport Name": "Sandnessjøen Airport (Stokka)",
//       "Altitude (Ft)": 56,
//       "Country": "Norway",
//       "Latitude": 12.468899726868,
//       "Location": "Sandnessjoen",
//       "Longitude": 65.956802368164,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 663,
//       "Airport Code [ICAO]": "ENTC",
//       "Airport Code iATA,FAA": "TOS",
//       "Airport Name": "Tromsø Airport,",
//       "Altitude (Ft)": 31,
//       "Country": "Norway",
//       "Latitude": 18.918899536132812,
//       "Location": "Tromso",
//       "Longitude": 69.68329620361328,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 664,
//       "Airport Code [ICAO]": "ENTO",
//       "Airport Code iATA,FAA": "TRF",
//       "Airport Name": "Sandefjord Airport, Torp",
//       "Altitude (Ft)": 286,
//       "Country": "Norway",
//       "Latitude": 10.258600235,
//       "Location": "Sandefjord",
//       "Longitude": 59.1866989136,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 665,
//       "Airport Code [ICAO]": "ENVA",
//       "Airport Code iATA,FAA": "TRD",
//       "Airport Name": "Trondheim Airport Værnes",
//       "Altitude (Ft)": 56,
//       "Country": "Norway",
//       "Latitude": 10.9239998,
//       "Location": "Trondheim",
//       "Longitude": 63.4578018,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 666,
//       "Airport Code [ICAO]": "ENZV",
//       "Airport Code iATA,FAA": "SVG",
//       "Airport Name": "Stavanger Airport Sola",
//       "Altitude (Ft)": 29,
//       "Country": "Norway",
//       "Latitude": 5.6377801895,
//       "Location": "Stavanger",
//       "Longitude": 58.876701354,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 667,
//       "Airport Code [ICAO]": "EPBC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Babice Airport",
//       "Altitude (Ft)": 352,
//       "Country": "Poland",
//       "Latitude": 20.910999298095703,
//       "Location": "Warsaw",
//       "Longitude": 52.26850128173828,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 668,
//       "Airport Code [ICAO]": "EPGD",
//       "Airport Code iATA,FAA": "GDN",
//       "Airport Name": "Gdańsk Lech Wałęsa Airport",
//       "Altitude (Ft)": 489,
//       "Country": "Poland",
//       "Latitude": 18.46619987487793,
//       "Location": "Gdansk",
//       "Longitude": 54.377601623535156,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 669,
//       "Airport Code [ICAO]": "EPKK",
//       "Airport Code iATA,FAA": "KRK",
//       "Airport Name": "Kraków John Paul II International Airport",
//       "Altitude (Ft)": 791,
//       "Country": "Poland",
//       "Latitude": 19.7848,
//       "Location": "Krakow",
//       "Longitude": 50.077702,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 670,
//       "Airport Code [ICAO]": "EPKM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Muchowiec Airport",
//       "Altitude (Ft)": 909,
//       "Country": "Poland",
//       "Latitude": 19.03420066833496,
//       "Location": "Katowice",
//       "Longitude": 50.23809814453125,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 671,
//       "Airport Code [ICAO]": "EPKT",
//       "Airport Code iATA,FAA": "KTW",
//       "Airport Name": "Katowice International Airport",
//       "Altitude (Ft)": 995,
//       "Country": "Poland",
//       "Latitude": 19.08,
//       "Location": "Katowice",
//       "Longitude": 50.4743,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 673,
//       "Airport Code [ICAO]": "EPML",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mielec Airport",
//       "Altitude (Ft)": 548,
//       "Country": "Poland",
//       "Latitude": 21.462099075317383,
//       "Location": "Mielec",
//       "Longitude": 50.32229995727539,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 674,
//       "Airport Code [ICAO]": "EPPO",
//       "Airport Code iATA,FAA": "POZ",
//       "Airport Name": "Poznań-Ławica Airport",
//       "Altitude (Ft)": 308,
//       "Country": "Poland",
//       "Latitude": 16.8262996674,
//       "Location": "Poznan",
//       "Longitude": 52.421001434299995,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 675,
//       "Airport Code [ICAO]": "EPRZ",
//       "Airport Code iATA,FAA": "RZE",
//       "Airport Name": "Rzeszów-Jasionka Airport",
//       "Altitude (Ft)": 675,
//       "Country": "Poland",
//       "Latitude": 22.0189990997,
//       "Location": "Rzeszow",
//       "Longitude": 50.1100006104,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 676,
//       "Airport Code [ICAO]": "EPSC",
//       "Airport Code iATA,FAA": "SZZ",
//       "Airport Name": "Szczecin-Goleniów \"Solidarność\" Airport",
//       "Altitude (Ft)": 154,
//       "Country": "Poland",
//       "Latitude": 14.902199745199999,
//       "Location": "Szczecin",
//       "Longitude": 53.584701538100006,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 677,
//       "Airport Code [ICAO]": "EPSK",
//       "Airport Code iATA,FAA": "OSP",
//       "Airport Name": "Redzikowo Air Base",
//       "Altitude (Ft)": 217,
//       "Country": "Poland",
//       "Latitude": 17.107500076293945,
//       "Location": "Slupsk",
//       "Longitude": 54.47890090942383,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 678,
//       "Airport Code [ICAO]": "EPSN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Swidwin Military Air Base",
//       "Altitude (Ft)": 385,
//       "Country": "Poland",
//       "Latitude": 15.826299667399999,
//       "Location": "Shapaja",
//       "Longitude": 53.790599823,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 679,
//       "Airport Code [ICAO]": "EPWA",
//       "Airport Code iATA,FAA": "WAW",
//       "Airport Name": "Warsaw Chopin Airport",
//       "Altitude (Ft)": 362,
//       "Country": "Poland",
//       "Latitude": 20.967100143399996,
//       "Location": "Warsaw",
//       "Longitude": 52.1656990051,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 680,
//       "Airport Code [ICAO]": "EPWR",
//       "Airport Code iATA,FAA": "WRO",
//       "Airport Name": "Copernicus Wrocław Airport",
//       "Altitude (Ft)": 404,
//       "Country": "Poland",
//       "Latitude": 16.885799408,
//       "Location": "Wroclaw",
//       "Longitude": 51.1026992798,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 681,
//       "Airport Code [ICAO]": "EPZG",
//       "Airport Code iATA,FAA": "IEG",
//       "Airport Name": "Zielona Góra-Babimost Airport",
//       "Altitude (Ft)": 194,
//       "Country": "Poland",
//       "Latitude": 15.7986001968,
//       "Location": "Zielona Gora",
//       "Longitude": 52.138500213600004,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 682,
//       "Airport Code [ICAO]": "ESCF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Malmen Air Base",
//       "Altitude (Ft)": 308,
//       "Country": "Sweden",
//       "Latitude": 15.525699615478516,
//       "Location": "Linkoeping",
//       "Longitude": 58.40230178833008,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 683,
//       "Airport Code [ICAO]": "ESCK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bråvalla Air Base",
//       "Altitude (Ft)": 90,
//       "Country": "Sweden",
//       "Latitude": 16.103599548339844,
//       "Location": "Norrkoeping",
//       "Longitude": 58.61090087890625,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 684,
//       "Airport Code [ICAO]": "ESCM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Uppsala Airport",
//       "Altitude (Ft)": 68,
//       "Country": "Sweden",
//       "Latitude": 17.588600158691406,
//       "Location": "Uppsala",
//       "Longitude": 59.897300720214844,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 685,
//       "Airport Code [ICAO]": "ESDF",
//       "Airport Code iATA,FAA": "RNB",
//       "Airport Name": "Ronneby Airport",
//       "Altitude (Ft)": 191,
//       "Country": "Sweden",
//       "Latitude": 15.265000343323,
//       "Location": "Ronneby",
//       "Longitude": 56.266700744629,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 686,
//       "Airport Code [ICAO]": "ESFR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Råda Air Base",
//       "Altitude (Ft)": 230,
//       "Country": "Sweden",
//       "Latitude": 13.053199768066406,
//       "Location": "Rada",
//       "Longitude": 58.49810028076172,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 687,
//       "Airport Code [ICAO]": "ESGG",
//       "Airport Code iATA,FAA": "GOT",
//       "Airport Name": "Gothenburg-Landvetter Airport",
//       "Altitude (Ft)": 506,
//       "Country": "Sweden",
//       "Latitude": 12.279800415039,
//       "Location": "Gothenborg",
//       "Longitude": 57.662799835205,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 688,
//       "Airport Code [ICAO]": "ESGJ",
//       "Airport Code iATA,FAA": "JKG",
//       "Airport Name": "Jönköping Airport",
//       "Altitude (Ft)": 741,
//       "Country": "Sweden",
//       "Latitude": 14.068699836730957,
//       "Location": "Joenkoeping",
//       "Longitude": 57.757598876953125,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 689,
//       "Airport Code [ICAO]": "ESGK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Falköping Airport",
//       "Altitude (Ft)": 785,
//       "Country": "Sweden",
//       "Latitude": 13.587800025939941,
//       "Location": "Falkoping",
//       "Longitude": 58.1697998046875,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 690,
//       "Airport Code [ICAO]": "ESGL",
//       "Airport Code iATA,FAA": "LDK",
//       "Airport Name": "Lidköping-Hovby Airport",
//       "Altitude (Ft)": 200,
//       "Country": "Sweden",
//       "Latitude": 13.1744,
//       "Location": "Lidkoping",
//       "Longitude": 58.4655,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 691,
//       "Airport Code [ICAO]": "ESGP",
//       "Airport Code iATA,FAA": "GSE",
//       "Airport Name": "Gothenburg City Airport",
//       "Altitude (Ft)": 59,
//       "Country": "Sweden",
//       "Latitude": 11.8704,
//       "Location": "Gothenborg",
//       "Longitude": 57.7747,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 692,
//       "Airport Code [ICAO]": "ESGR",
//       "Airport Code iATA,FAA": "KVB",
//       "Airport Name": "Skövde Airport",
//       "Altitude (Ft)": 324,
//       "Country": "Sweden",
//       "Latitude": 13.972700119018555,
//       "Location": "Skovde",
//       "Longitude": 58.45640182495117,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 693,
//       "Airport Code [ICAO]": "ESGT",
//       "Airport Code iATA,FAA": "THN",
//       "Airport Name": "Trollhättan-Vänersborg Airport",
//       "Altitude (Ft)": 137,
//       "Country": "Sweden",
//       "Latitude": 12.345000267028809,
//       "Location": "Trollhattan",
//       "Longitude": 58.31809997558594,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 694,
//       "Airport Code [ICAO]": "ESIA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Karlsborg Air Base",
//       "Altitude (Ft)": 308,
//       "Country": "Sweden",
//       "Latitude": 14.507100105285645,
//       "Location": "Karlsborg",
//       "Longitude": 58.51380157470703,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 695,
//       "Airport Code [ICAO]": "ESIB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Såtenäs Air Base",
//       "Altitude (Ft)": 181,
//       "Country": "Sweden",
//       "Latitude": 12.714400291442871,
//       "Location": "Satenas",
//       "Longitude": 58.42639923095703,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 696,
//       "Airport Code [ICAO]": "ESKB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Barkarby Airport",
//       "Altitude (Ft)": 50,
//       "Country": "Sweden",
//       "Latitude": 17.8821372986,
//       "Location": "Stockholm",
//       "Longitude": 59.4144743644,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 697,
//       "Airport Code [ICAO]": "ESKK",
//       "Airport Code iATA,FAA": "KSK",
//       "Airport Name": "Karlskoga Airport",
//       "Altitude (Ft)": 400,
//       "Country": "Sweden",
//       "Latitude": 14.49590015411377,
//       "Location": "Karlskoga",
//       "Longitude": 59.34590148925781,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 698,
//       "Airport Code [ICAO]": "ESKM",
//       "Airport Code iATA,FAA": "MXX",
//       "Airport Name": "Mora Airport",
//       "Altitude (Ft)": 634,
//       "Country": "Sweden",
//       "Latitude": 14.5114,
//       "Location": "Mora",
//       "Longitude": 60.957901,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 699,
//       "Airport Code [ICAO]": "ESKN",
//       "Airport Code iATA,FAA": "NYO",
//       "Airport Name": "Stockholm Skavsta Airport",
//       "Altitude (Ft)": 140,
//       "Country": "Sweden",
//       "Latitude": 16.912200927734375,
//       "Location": "Stockholm",
//       "Longitude": 58.78860092163086,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 700,
//       "Airport Code [ICAO]": "ESKV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Arvika Airport",
//       "Altitude (Ft)": 237,
//       "Country": "Sweden",
//       "Latitude": 12.6394,
//       "Location": "Arvika",
//       "Longitude": 59.6759,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 701,
//       "Airport Code [ICAO]": "ESMA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Emmaboda Airfield",
//       "Altitude (Ft)": 442,
//       "Country": "Sweden",
//       "Latitude": 15.6048,
//       "Location": "Emmaboda",
//       "Longitude": 56.610802,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 702,
//       "Airport Code [ICAO]": "ESMG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Feringe Airport",
//       "Altitude (Ft)": 538,
//       "Country": "Sweden",
//       "Latitude": 13.921699523900001,
//       "Location": "Ljungby",
//       "Longitude": 56.9502983093,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 703,
//       "Airport Code [ICAO]": "ESMK",
//       "Airport Code iATA,FAA": "KID",
//       "Airport Name": "Kristianstad Airport",
//       "Altitude (Ft)": 76,
//       "Country": "Sweden",
//       "Latitude": 14.08549976348877,
//       "Location": "Kristianstad",
//       "Longitude": 55.92169952392578,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 704,
//       "Airport Code [ICAO]": "ESML",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Landskrona Airport",
//       "Altitude (Ft)": 194,
//       "Country": "Sweden",
//       "Latitude": 12.869999885559082,
//       "Location": "Landskrona",
//       "Longitude": 55.94599914550781,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 705,
//       "Airport Code [ICAO]": "ESMO",
//       "Airport Code iATA,FAA": "OSK",
//       "Airport Name": "Oskarshamn Airport",
//       "Altitude (Ft)": 96,
//       "Country": "Sweden",
//       "Latitude": 16.497999,
//       "Location": "Oskarshamn",
//       "Longitude": 57.350498,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 706,
//       "Airport Code [ICAO]": "ESMP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Anderstorp Airport",
//       "Altitude (Ft)": 507,
//       "Country": "Sweden",
//       "Latitude": 13.59939956665039,
//       "Location": "Anderstorp",
//       "Longitude": 57.264198303222656,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 707,
//       "Airport Code [ICAO]": "ESMQ",
//       "Airport Code iATA,FAA": "KLR",
//       "Airport Name": "Kalmar Airport",
//       "Altitude (Ft)": 17,
//       "Country": "Sweden",
//       "Latitude": 16.287599563598633,
//       "Location": "Kalkmar",
//       "Longitude": 56.68550109863281,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 708,
//       "Airport Code [ICAO]": "ESMS",
//       "Airport Code iATA,FAA": "MMX",
//       "Airport Name": "Malmö Sturup Airport",
//       "Altitude (Ft)": 236,
//       "Country": "Sweden",
//       "Latitude": 13.376197814900001,
//       "Location": "Malmoe",
//       "Longitude": 55.536305364,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 709,
//       "Airport Code [ICAO]": "ESMT",
//       "Airport Code iATA,FAA": "HAD",
//       "Airport Name": "Halmstad Airport",
//       "Altitude (Ft)": 101,
//       "Country": "Sweden",
//       "Latitude": 12.820199966430664,
//       "Location": "Halmstad",
//       "Longitude": 56.69110107421875,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 710,
//       "Airport Code [ICAO]": "ESMV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hagshult Air Base",
//       "Altitude (Ft)": 556,
//       "Country": "Sweden",
//       "Latitude": 14.1372,
//       "Location": "Hagshult",
//       "Longitude": 57.292198,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 711,
//       "Airport Code [ICAO]": "ESMX",
//       "Airport Code iATA,FAA": "VXO",
//       "Airport Name": "Växjö Kronoberg Airport",
//       "Altitude (Ft)": 610,
//       "Country": "Sweden",
//       "Latitude": 14.727999687194824,
//       "Location": "Vaxjo",
//       "Longitude": 56.929100036621094,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 712,
//       "Airport Code [ICAO]": "ESNA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hallviken Airport",
//       "Altitude (Ft)": 1119,
//       "Country": "Sweden",
//       "Latitude": 15.45829963684082,
//       "Location": "Hallviken",
//       "Longitude": 63.73830032348633,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 713,
//       "Airport Code [ICAO]": "ESNC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hedlanda Airport",
//       "Altitude (Ft)": 1460,
//       "Country": "Sweden",
//       "Latitude": 13.747200012199999,
//       "Location": "Hede",
//       "Longitude": 62.40890121459999,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 714,
//       "Airport Code [ICAO]": "ESND",
//       "Airport Code iATA,FAA": "EVG",
//       "Airport Name": "Sveg Airport",
//       "Altitude (Ft)": 1178,
//       "Country": "Sweden",
//       "Latitude": 14.422900199890137,
//       "Location": "Sveg",
//       "Longitude": 62.04779815673828,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 715,
//       "Airport Code [ICAO]": "ESNG",
//       "Airport Code iATA,FAA": "GEV",
//       "Airport Name": "Gällivare Airport",
//       "Altitude (Ft)": 1027,
//       "Country": "Sweden",
//       "Latitude": 20.814599990844727,
//       "Location": "Gallivare",
//       "Longitude": 67.13240051269531,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 716,
//       "Airport Code [ICAO]": "ESNH",
//       "Airport Code iATA,FAA": "HUV",
//       "Airport Name": "Hudiksvall Airport",
//       "Altitude (Ft)": 95,
//       "Country": "Sweden",
//       "Latitude": 17.0806999207,
//       "Location": "Hudiksvall",
//       "Longitude": 61.7681007385,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 717,
//       "Airport Code [ICAO]": "ESNJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Jokkmokk Airport",
//       "Altitude (Ft)": 904,
//       "Country": "Sweden",
//       "Latitude": 20.147199630737305,
//       "Location": "Jokkmokk",
//       "Longitude": 66.49620056152344,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 718,
//       "Airport Code [ICAO]": "ESNK",
//       "Airport Code iATA,FAA": "KRF",
//       "Airport Name": "Kramfors Sollefteå Airport",
//       "Altitude (Ft)": 34,
//       "Country": "Sweden",
//       "Latitude": 17.76889991760254,
//       "Location": "Kramfors",
//       "Longitude": 63.04859924316406,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 719,
//       "Airport Code [ICAO]": "ESNL",
//       "Airport Code iATA,FAA": "LYC",
//       "Airport Name": "Lycksele Airport",
//       "Altitude (Ft)": 705,
//       "Country": "Sweden",
//       "Latitude": 18.71619987487793,
//       "Location": "Lycksele",
//       "Longitude": 64.54830169677734,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 720,
//       "Airport Code [ICAO]": "ESNM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Optand Airport",
//       "Altitude (Ft)": 1236,
//       "Country": "Sweden",
//       "Latitude": 14.802800178527832,
//       "Location": "Optand",
//       "Longitude": 63.12860107421875,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 721,
//       "Airport Code [ICAO]": "ESNN",
//       "Airport Code iATA,FAA": "SDL",
//       "Airport Name": "Sundsvall-Härnösand Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Sweden",
//       "Latitude": 17.443899154663086,
//       "Location": "Sundsvall",
//       "Longitude": 62.528099060058594,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 722,
//       "Airport Code [ICAO]": "ESNO",
//       "Airport Code iATA,FAA": "OER",
//       "Airport Name": "Örnsköldsvik Airport",
//       "Altitude (Ft)": 354,
//       "Country": "Sweden",
//       "Latitude": 18.989999771118164,
//       "Location": "Ornskoldsvik",
//       "Longitude": 63.40829849243164,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 723,
//       "Airport Code [ICAO]": "ESNP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Piteå Airport",
//       "Altitude (Ft)": 43,
//       "Country": "Sweden",
//       "Latitude": 21.260799407958984,
//       "Location": "Pitea",
//       "Longitude": 65.39830017089844,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 724,
//       "Airport Code [ICAO]": "ESNQ",
//       "Airport Code iATA,FAA": "KRN",
//       "Airport Name": "Kiruna Airport",
//       "Altitude (Ft)": 1508,
//       "Country": "Sweden",
//       "Latitude": 20.336799621582,
//       "Location": "Kiruna",
//       "Longitude": 67.821998596191,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 725,
//       "Airport Code [ICAO]": "ESNR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Orsa Airport",
//       "Altitude (Ft)": 683,
//       "Country": "Sweden",
//       "Latitude": 14.712599754333496,
//       "Location": "Orsa",
//       "Longitude": 61.189998626708984,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 726,
//       "Airport Code [ICAO]": "ESNS",
//       "Airport Code iATA,FAA": "SFT",
//       "Airport Name": "Skellefteå Airport",
//       "Altitude (Ft)": 157,
//       "Country": "Sweden",
//       "Latitude": 21.076900482177734,
//       "Location": "Skelleftea",
//       "Longitude": 64.62480163574219,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 727,
//       "Airport Code [ICAO]": "ESNT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sättna Airport",
//       "Altitude (Ft)": 886,
//       "Country": "Sweden",
//       "Latitude": 17.002899,
//       "Location": "Sattna",
//       "Longitude": 62.4814,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 728,
//       "Airport Code [ICAO]": "ESNU",
//       "Airport Code iATA,FAA": "UME",
//       "Airport Name": "Umeå Airport",
//       "Altitude (Ft)": 24,
//       "Country": "Sweden",
//       "Latitude": 20.282800674438,
//       "Location": "Umea",
//       "Longitude": 63.791801452637,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 729,
//       "Airport Code [ICAO]": "ESNV",
//       "Airport Code iATA,FAA": "VHM",
//       "Airport Name": "Vilhelmina Airport",
//       "Altitude (Ft)": 1140,
//       "Country": "Sweden",
//       "Latitude": 16.833599090576172,
//       "Location": "Vilhelmina",
//       "Longitude": 64.5791015625,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 730,
//       "Airport Code [ICAO]": "ESNX",
//       "Airport Code iATA,FAA": "AJR",
//       "Airport Name": "Arvidsjaur Airport",
//       "Altitude (Ft)": 1245,
//       "Country": "Sweden",
//       "Latitude": 19.28190040588379,
//       "Location": "Arvidsjaur",
//       "Longitude": 65.59030151367188,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 731,
//       "Airport Code [ICAO]": "ESOE",
//       "Airport Code iATA,FAA": "ORB",
//       "Airport Name": "Örebro Airport",
//       "Altitude (Ft)": 188,
//       "Country": "Sweden",
//       "Latitude": 15.038000106811523,
//       "Location": "Orebro",
//       "Longitude": 59.22370147705078,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 733,
//       "Airport Code [ICAO]": "ESOW",
//       "Airport Code iATA,FAA": "VST",
//       "Airport Name": "Stockholm Västerås Airport",
//       "Altitude (Ft)": 21,
//       "Country": "Sweden",
//       "Latitude": 16.63360023498535,
//       "Location": "Vasteras",
//       "Longitude": 59.58940124511719,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 734,
//       "Airport Code [ICAO]": "ESPA",
//       "Airport Code iATA,FAA": "LLA",
//       "Airport Name": "Luleå Airport",
//       "Altitude (Ft)": 65,
//       "Country": "Sweden",
//       "Latitude": 22.121999740601,
//       "Location": "Lulea",
//       "Longitude": 65.543800354004,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 735,
//       "Airport Code [ICAO]": "ESPE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Vidsel Air Base",
//       "Altitude (Ft)": 597,
//       "Country": "Sweden",
//       "Latitude": 20.149900436401367,
//       "Location": "Vidsel",
//       "Longitude": 65.87529754638672,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 736,
//       "Airport Code [ICAO]": "ESQO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Arboga Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Sweden",
//       "Latitude": 15.924099922180176,
//       "Location": "Arboga",
//       "Longitude": 59.386600494384766,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 737,
//       "Airport Code [ICAO]": "ESSA",
//       "Airport Code iATA,FAA": "ARN",
//       "Airport Name": "Stockholm-Arlanda Airport",
//       "Altitude (Ft)": 137,
//       "Country": "Sweden",
//       "Latitude": 17.918600082397,
//       "Location": "Stockholm",
//       "Longitude": 59.651901245117,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 738,
//       "Airport Code [ICAO]": "ESSB",
//       "Airport Code iATA,FAA": "BMA",
//       "Airport Name": "Stockholm-Bromma Airport",
//       "Altitude (Ft)": 47,
//       "Country": "Sweden",
//       "Latitude": 17.941699981689453,
//       "Location": "Stockholm",
//       "Longitude": 59.354400634765625,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 739,
//       "Airport Code [ICAO]": "ESSD",
//       "Airport Code iATA,FAA": "BLE",
//       "Airport Name": "Borlange Airport",
//       "Altitude (Ft)": 503,
//       "Country": "Sweden",
//       "Latitude": 15.515199661254883,
//       "Location": "Borlange",
//       "Longitude": 60.422000885009766,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 740,
//       "Airport Code [ICAO]": "ESSF",
//       "Airport Code iATA,FAA": "HLF",
//       "Airport Name": "Hultsfred Airport",
//       "Altitude (Ft)": 366,
//       "Country": "Sweden",
//       "Latitude": 15.8233,
//       "Location": "Hultsfred",
//       "Longitude": 57.525799,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 741,
//       "Airport Code [ICAO]": "ESSK",
//       "Airport Code iATA,FAA": "GVX",
//       "Airport Name": "Gävle Sandviken Airport",
//       "Altitude (Ft)": 224,
//       "Country": "Sweden",
//       "Latitude": 16.951400756835938,
//       "Location": "Gavle",
//       "Longitude": 60.593299865722656,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 742,
//       "Airport Code [ICAO]": "ESSL",
//       "Airport Code iATA,FAA": "LPI",
//       "Airport Name": "Linköping City Airport",
//       "Altitude (Ft)": 172,
//       "Country": "Sweden",
//       "Latitude": 15.680500030500001,
//       "Location": "Linkoeping",
//       "Longitude": 58.4062004089,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 743,
//       "Airport Code [ICAO]": "ESSP",
//       "Airport Code iATA,FAA": "NRK",
//       "Airport Name": "Norrköping Airport",
//       "Altitude (Ft)": 32,
//       "Country": "Sweden",
//       "Latitude": 16.250600814819336,
//       "Location": "Norrkoeping",
//       "Longitude": 58.586299896240234,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 745,
//       "Airport Code [ICAO]": "ESSU",
//       "Airport Code iATA,FAA": "EKT",
//       "Airport Name": "Eskilstuna Airport",
//       "Altitude (Ft)": 139,
//       "Country": "Sweden",
//       "Latitude": 16.70840072631836,
//       "Location": "Eskilstuna",
//       "Longitude": 59.35110092163086,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 746,
//       "Airport Code [ICAO]": "ESSV",
//       "Airport Code iATA,FAA": "VBY",
//       "Airport Name": "Visby Airport",
//       "Altitude (Ft)": 164,
//       "Country": "Sweden",
//       "Latitude": 18.346200942993,
//       "Location": "Visby",
//       "Longitude": 57.662799835205,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 748,
//       "Airport Code [ICAO]": "ESUK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kalixfors Airport",
//       "Altitude (Ft)": 1549,
//       "Country": "Sweden",
//       "Latitude": 20.257200241088867,
//       "Location": "Kalixfors",
//       "Longitude": 67.76480102539062,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 750,
//       "Airport Code [ICAO]": "ETAD",
//       "Airport Code iATA,FAA": "SPM",
//       "Airport Name": "Spangdahlem Air Base",
//       "Altitude (Ft)": 1197,
//       "Country": "Germany",
//       "Latitude": 6.69250011444,
//       "Location": "Spangdahlem",
//       "Longitude": 49.9726982117,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 751,
//       "Airport Code [ICAO]": "ETAR",
//       "Airport Code iATA,FAA": "RMS",
//       "Airport Name": "Ramstein Air Base",
//       "Altitude (Ft)": 776,
//       "Country": "Germany",
//       "Latitude": 7.600279808044434,
//       "Location": "Ramstein",
//       "Longitude": 49.4369010925293,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 753,
//       "Airport Code [ICAO]": "ETEU",
//       "Airport Code iATA,FAA": "GHF",
//       "Airport Name": "[Duplicate] Giebelstadt Army Air Field",
//       "Altitude (Ft)": 980,
//       "Country": "Germany",
//       "Latitude": 9.966489791870002,
//       "Location": "Giebelstadt",
//       "Longitude": 49.648101806599996,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 754,
//       "Airport Code [ICAO]": "ETHB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bückeburg Air Base",
//       "Altitude (Ft)": 230,
//       "Country": "Germany",
//       "Latitude": 9.08217,
//       "Location": "Brueckeburg",
//       "Longitude": 52.2785,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 755,
//       "Airport Code [ICAO]": "ETHC",
//       "Airport Code iATA,FAA": "ZCN",
//       "Airport Name": "Celle Airport",
//       "Altitude (Ft)": 129,
//       "Country": "Germany",
//       "Latitude": 10.022100448608398,
//       "Location": "Celle",
//       "Longitude": 52.59120178222656,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 756,
//       "Airport Code [ICAO]": "ETHE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Rheine Bentlage Air Base",
//       "Altitude (Ft)": 129,
//       "Country": "Germany",
//       "Latitude": 7.387,
//       "Location": "Rheine-brentlange",
//       "Longitude": 52.291199,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 757,
//       "Airport Code [ICAO]": "ETHF",
//       "Airport Code iATA,FAA": "FRZ",
//       "Airport Name": "Fritzlar Airport",
//       "Altitude (Ft)": 1345,
//       "Country": "Germany",
//       "Latitude": 9.286,
//       "Location": "Fritzlar",
//       "Longitude": 51.1146,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 758,
//       "Airport Code [ICAO]": "ETHL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Laupheim Air Base",
//       "Altitude (Ft)": 1766,
//       "Country": "Germany",
//       "Latitude": 9.91002,
//       "Location": "Laupheim",
//       "Longitude": 48.220299,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 759,
//       "Airport Code [ICAO]": "ETHM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mendig Airfield",
//       "Altitude (Ft)": 597,
//       "Country": "Germany",
//       "Latitude": 7.31533,
//       "Location": "Mendig",
//       "Longitude": 50.366001,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 760,
//       "Airport Code [ICAO]": "ETHN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Niederstetten Army Air Base",
//       "Altitude (Ft)": 1339,
//       "Country": "Germany",
//       "Latitude": 9.958889,
//       "Location": "Niederstetten",
//       "Longitude": 49.391945,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 761,
//       "Airport Code [ICAO]": "ETHR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Roth Airport",
//       "Altitude (Ft)": 1268,
//       "Country": "Germany",
//       "Latitude": 11.100199699401855,
//       "Location": "Roth",
//       "Longitude": 49.217498779296875,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 762,
//       "Airport Code [ICAO]": "ETHS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Fassberg Air Base",
//       "Altitude (Ft)": 245,
//       "Country": "Germany",
//       "Latitude": 10.185827,
//       "Location": "Fassberg",
//       "Longitude": 52.919399,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 763,
//       "Airport Code [ICAO]": "ETIC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Grafenwohr Army Air Field",
//       "Altitude (Ft)": 1363,
//       "Country": "Germany",
//       "Latitude": 11.940199851989746,
//       "Location": "Grafenwoehr",
//       "Longitude": 49.698699951171875,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 764,
//       "Airport Code [ICAO]": "ETID",
//       "Airport Code iATA,FAA": "ZNF",
//       "Airport Name": "Hanau Army Air Field",
//       "Altitude (Ft)": 368,
//       "Country": "Germany",
//       "Latitude": 8.96159,
//       "Location": "Hanau",
//       "Longitude": 50.169201,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 765,
//       "Airport Code [ICAO]": "ETIH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hohenfels Army Air Field",
//       "Altitude (Ft)": 1455,
//       "Country": "Germany",
//       "Latitude": 11.836099624633789,
//       "Location": "Hohenfels",
//       "Longitude": 49.218101501464844,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 766,
//       "Airport Code [ICAO]": "ETIN",
//       "Airport Code iATA,FAA": "KZG",
//       "Airport Name": "Flugplatz Kitzingen",
//       "Altitude (Ft)": 689,
//       "Country": "Germany",
//       "Latitude": 10.2006,
//       "Location": "Kitzingen",
//       "Longitude": 49.743099,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 767,
//       "Airport Code [ICAO]": "ETMN",
//       "Airport Code iATA,FAA": "FCN",
//       "Airport Name": "Nordholz Naval Airbase",
//       "Altitude (Ft)": 74,
//       "Country": "Germany",
//       "Latitude": 8.658499717709999,
//       "Location": "Nordholz",
//       "Longitude": 53.7677001953,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 768,
//       "Airport Code [ICAO]": "ETND",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Diepholz Air Base",
//       "Altitude (Ft)": 128,
//       "Country": "Germany",
//       "Latitude": 8.342222,
//       "Location": "Diepholz",
//       "Longitude": 52.585556,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 769,
//       "Airport Code [ICAO]": "ETNG",
//       "Airport Code iATA,FAA": "GKE",
//       "Airport Name": "Geilenkirchen Air Base",
//       "Altitude (Ft)": 296,
//       "Country": "Germany",
//       "Latitude": 6.04242,
//       "Location": "Geilenkirchen",
//       "Longitude": 50.9608,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 770,
//       "Airport Code [ICAO]": "ETNH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hohn Air Base",
//       "Altitude (Ft)": 39,
//       "Country": "Germany",
//       "Latitude": 9.53817,
//       "Location": "Hohn",
//       "Longitude": 54.312199,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 771,
//       "Airport Code [ICAO]": "ETNJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Jever Air Base",
//       "Altitude (Ft)": 24,
//       "Country": "Germany",
//       "Latitude": 7.88867,
//       "Location": "Jever",
//       "Longitude": 53.533501,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 772,
//       "Airport Code [ICAO]": "ETNL",
//       "Airport Code iATA,FAA": "RLG",
//       "Airport Name": "Rostock-Laage Airport",
//       "Altitude (Ft)": 138,
//       "Country": "Germany",
//       "Latitude": 12.278300285299999,
//       "Location": "Laage",
//       "Longitude": 53.9182014465,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 773,
//       "Airport Code [ICAO]": "ETNN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nörvenich Air Base",
//       "Altitude (Ft)": 386,
//       "Country": "Germany",
//       "Latitude": 6.65817,
//       "Location": "Noervenich",
//       "Longitude": 50.8312,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 774,
//       "Airport Code [ICAO]": "ETNS",
//       "Airport Code iATA,FAA": "WBG",
//       "Airport Name": "Schleswig Air Base",
//       "Altitude (Ft)": 70,
//       "Country": "Germany",
//       "Latitude": 9.51633,
//       "Location": "Schleswig",
//       "Longitude": 54.459301,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 775,
//       "Airport Code [ICAO]": "ETNT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Wittmundhafen Airport",
//       "Altitude (Ft)": 26,
//       "Country": "Germany",
//       "Latitude": 7.667329788208008,
//       "Location": "Wittmundhafen",
//       "Longitude": 53.54779815673828,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 777,
//       "Airport Code [ICAO]": "ETNW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Wunstorf Air Base",
//       "Altitude (Ft)": 187,
//       "Country": "Germany",
//       "Latitude": 9.42717,
//       "Location": "Wunstorf",
//       "Longitude": 52.457298,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 778,
//       "Airport Code [ICAO]": "ETOI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Vilseck Army Air Field",
//       "Altitude (Ft)": 1353,
//       "Country": "Germany",
//       "Latitude": 11.767200469970703,
//       "Location": "Vilseck",
//       "Longitude": 49.63359832763672,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 779,
//       "Airport Code [ICAO]": "ETOR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Coleman Army Air Field",
//       "Altitude (Ft)": 309,
//       "Country": "Germany",
//       "Latitude": 8.463390350341797,
//       "Location": "Coleman",
//       "Longitude": 49.5635986328125,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 780,
//       "Airport Code [ICAO]": "ETOU",
//       "Airport Code iATA,FAA": "WIE",
//       "Airport Name": "Wiesbaden Army Airfield",
//       "Altitude (Ft)": 461,
//       "Country": "Germany",
//       "Latitude": 8.325400352478027,
//       "Location": "Wiesbaden",
//       "Longitude": 50.049800872802734,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 781,
//       "Airport Code [ICAO]": "ETSA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Landsberg Lech Air Base",
//       "Altitude (Ft)": 2044,
//       "Country": "Germany",
//       "Latitude": 10.906,
//       "Location": "Landsberg",
//       "Longitude": 48.070599,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 782,
//       "Airport Code [ICAO]": "ETSB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Büchel Air Base",
//       "Altitude (Ft)": 1568,
//       "Country": "Germany",
//       "Latitude": 7.06333017349,
//       "Location": "Buechel",
//       "Longitude": 50.1738014221,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 783,
//       "Airport Code [ICAO]": "ETSE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Erding Airport",
//       "Altitude (Ft)": 1515,
//       "Country": "Germany",
//       "Latitude": 11.948699951171875,
//       "Location": "Erding",
//       "Longitude": 48.32229995727539,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 784,
//       "Airport Code [ICAO]": "ETSF",
//       "Airport Code iATA,FAA": "FEL",
//       "Airport Name": "Fürstenfeldbruck Air Base",
//       "Altitude (Ft)": 1703,
//       "Country": "Germany",
//       "Latitude": 11.266944,
//       "Location": "Fuerstenfeldbruck",
//       "Longitude": 48.205555,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 785,
//       "Airport Code [ICAO]": "ETSH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Holzdorf Air Base",
//       "Altitude (Ft)": 265,
//       "Country": "Germany",
//       "Latitude": 13.1677,
//       "Location": "Holzdorf",
//       "Longitude": 51.767799,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 786,
//       "Airport Code [ICAO]": "ETSI",
//       "Airport Code iATA,FAA": "IGS",
//       "Airport Name": "Ingolstadt Manching Airport",
//       "Altitude (Ft)": 1202,
//       "Country": "Germany",
//       "Latitude": 11.534000396728516,
//       "Location": "Ingolstadt",
//       "Longitude": 48.7156982421875,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 787,
//       "Airport Code [ICAO]": "ETSL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lechfeld Air Base",
//       "Altitude (Ft)": 1822,
//       "Country": "Germany",
//       "Latitude": 10.8612,
//       "Location": "Lechfeld",
//       "Longitude": 48.185504,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 788,
//       "Airport Code [ICAO]": "ETSN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Neuburg AFB",
//       "Altitude (Ft)": 1249,
//       "Country": "Germany",
//       "Latitude": 11.2115,
//       "Location": "Neuburg",
//       "Longitude": 48.710999,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 789,
//       "Airport Code [ICAO]": "ETUO",
//       "Airport Code iATA,FAA": "GUT",
//       "Airport Name": "Gütersloh Air Base",
//       "Altitude (Ft)": 236,
//       "Country": "Germany",
//       "Latitude": 8.30633,
//       "Location": "Guetersloh",
//       "Longitude": 51.922798,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 790,
//       "Airport Code [ICAO]": "FAAB",
//       "Airport Code iATA,FAA": "ALJ",
//       "Airport Name": "Alexander Bay Airport",
//       "Altitude (Ft)": 98,
//       "Country": "South Africa",
//       "Latitude": 16.5333,
//       "Location": "Alexander Bay",
//       "Longitude": -28.575001,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 791,
//       "Airport Code [ICAO]": "FAAG",
//       "Airport Code iATA,FAA": "AGZ",
//       "Airport Name": "Aggeneys Airport",
//       "Altitude (Ft)": 2648,
//       "Country": "South Africa",
//       "Latitude": 18.813899993896484,
//       "Location": "Aggeneys",
//       "Longitude": -29.28179931640625,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 792,
//       "Airport Code [ICAO]": "FABB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Brakpan Airport",
//       "Altitude (Ft)": 5300,
//       "Country": "South Africa",
//       "Latitude": 28.301799774169922,
//       "Location": "Brakpan",
//       "Longitude": -26.23859977722168,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 793,
//       "Airport Code [ICAO]": "FABE",
//       "Airport Code iATA,FAA": "BIY",
//       "Airport Name": "Bisho Airport",
//       "Altitude (Ft)": 1950,
//       "Country": "South Africa",
//       "Latitude": 27.279100418099997,
//       "Location": "Bisho",
//       "Longitude": -32.8970985413,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 794,
//       "Airport Code [ICAO]": "FABL",
//       "Airport Code iATA,FAA": "BFN",
//       "Airport Name": "Bram Fischer International Airport",
//       "Altitude (Ft)": 4458,
//       "Country": "South Africa",
//       "Latitude": 26.302400589,
//       "Location": "Bloemfontein",
//       "Longitude": -29.092699050900002,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 795,
//       "Airport Code [ICAO]": "FABM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bethlehem Airport",
//       "Altitude (Ft)": 5561,
//       "Country": "South Africa",
//       "Latitude": 28.3360996246,
//       "Location": "Bethlehem",
//       "Longitude": -28.2483997345,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 796,
//       "Airport Code [ICAO]": "FABO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hendrik Potgieter Airport",
//       "Altitude (Ft)": 4236,
//       "Country": "South Africa",
//       "Latitude": 26.629199981689453,
//       "Location": "Bothaville",
//       "Longitude": -27.36680030822754,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 797,
//       "Airport Code [ICAO]": "FACT",
//       "Airport Code iATA,FAA": "CPT",
//       "Airport Name": "Cape Town International Airport",
//       "Altitude (Ft)": 151,
//       "Country": "South Africa",
//       "Latitude": 18.6016998291,
//       "Location": "Cape Town",
//       "Longitude": -33.9648017883,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 798,
//       "Airport Code [ICAO]": "FACV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Calvinia Airport",
//       "Altitude (Ft)": 3250,
//       "Country": "South Africa",
//       "Latitude": 19.725900650024414,
//       "Location": "Calvinia",
//       "Longitude": -31.50029945373535,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 799,
//       "Airport Code [ICAO]": "FALE",
//       "Airport Code iATA,FAA": "DUR",
//       "Airport Name": "King Shaka International Airport",
//       "Altitude (Ft)": 295,
//       "Country": "South Africa",
//       "Latitude": 31.1197222222,
//       "Location": "Durban",
//       "Longitude": -29.6144444444,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 800,
//       "Airport Code [ICAO]": "FAEL",
//       "Airport Code iATA,FAA": "ELS",
//       "Airport Name": "Ben Schoeman Airport",
//       "Altitude (Ft)": 435,
//       "Country": "South Africa",
//       "Latitude": 27.825899124099998,
//       "Location": "East London",
//       "Longitude": -33.0355987549,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 801,
//       "Airport Code [ICAO]": "FAEO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ermelo Airport",
//       "Altitude (Ft)": 5700,
//       "Country": "South Africa",
//       "Latitude": 29.979799270629883,
//       "Location": "Ermelo",
//       "Longitude": -26.4955997467041,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 802,
//       "Airport Code [ICAO]": "FAFB",
//       "Airport Code iATA,FAA": "FCB",
//       "Airport Name": "Ficksburg Sentraoes Airport",
//       "Altitude (Ft)": 5315,
//       "Country": "South Africa",
//       "Latitude": 27.908899307250977,
//       "Location": "Ficksburg",
//       "Longitude": -28.82309913635254,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 803,
//       "Airport Code [ICAO]": "FAGC",
//       "Airport Code iATA,FAA": "GCJ",
//       "Airport Name": "Grand Central Airport",
//       "Altitude (Ft)": 5325,
//       "Country": "South Africa",
//       "Latitude": 28.1401004791,
//       "Location": "Johannesburg",
//       "Longitude": -25.986299514799995,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 804,
//       "Airport Code [ICAO]": "FAGG",
//       "Airport Code iATA,FAA": "GRJ",
//       "Airport Name": "George Airport",
//       "Altitude (Ft)": 648,
//       "Country": "South Africa",
//       "Latitude": 22.378902,
//       "Location": "George",
//       "Longitude": -34.0056,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 806,
//       "Airport Code [ICAO]": "FAGR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Graaff Reinet Airport",
//       "Altitude (Ft)": 2604,
//       "Country": "South Africa",
//       "Latitude": 24.541400909423828,
//       "Location": "Graaff Reinet",
//       "Longitude": -32.193599700927734,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 807,
//       "Airport Code [ICAO]": "FAGT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Grahamstown Airport",
//       "Altitude (Ft)": 2135,
//       "Country": "South Africa",
//       "Latitude": 26.49810028076172,
//       "Location": "Grahamstown",
//       "Longitude": -33.284698486328125,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 808,
//       "Airport Code [ICAO]": "FAGY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Greytown Airport",
//       "Altitude (Ft)": 3531,
//       "Country": "South Africa",
//       "Latitude": 30.586700439499996,
//       "Location": "Greytown",
//       "Longitude": -29.1219997406,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 809,
//       "Airport Code [ICAO]": "FAHA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Harmony Airport",
//       "Altitude (Ft)": 4399,
//       "Country": "South Africa",
//       "Latitude": 26.8612003326416,
//       "Location": "Harmony",
//       "Longitude": -28.078699111938477,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 810,
//       "Airport Code [ICAO]": "FAHR",
//       "Airport Code iATA,FAA": "HRS",
//       "Airport Name": "Harrismith Airport",
//       "Altitude (Ft)": 5585,
//       "Country": "South Africa",
//       "Latitude": 29.106199264526367,
//       "Location": "Harrismith",
//       "Longitude": -28.23509979248047,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 811,
//       "Airport Code [ICAO]": "FAHS",
//       "Airport Code iATA,FAA": "HDS",
//       "Airport Name": "Hoedspruit Air Force Base Airport",
//       "Altitude (Ft)": 1743,
//       "Country": "South Africa",
//       "Latitude": 31.0487003326,
//       "Location": "Hoedspruit",
//       "Longitude": -24.368600845299998,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 812,
//       "Airport Code [ICAO]": "FAHV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Gariep Dam Airport",
//       "Altitude (Ft)": 4176,
//       "Country": "South Africa",
//       "Latitude": 25.52829933166504,
//       "Location": "Hendrik Verwoerddam",
//       "Longitude": -30.56220054626465,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 813,
//       "Airport Code [ICAO]": "FAOR",
//       "Airport Code iATA,FAA": "JNB",
//       "Airport Name": "OR Tambo International Airport",
//       "Altitude (Ft)": 5558,
//       "Country": "South Africa",
//       "Latitude": 28.246,
//       "Location": "Johannesburg",
//       "Longitude": -26.1392,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 814,
//       "Airport Code [ICAO]": "FAKD",
//       "Airport Code iATA,FAA": "KXE",
//       "Airport Name": "P C Pelser Airport",
//       "Altitude (Ft)": 4444,
//       "Country": "South Africa",
//       "Latitude": 26.718000411987305,
//       "Location": "Klerksdorp",
//       "Longitude": -26.8710994720459,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 815,
//       "Airport Code [ICAO]": "FAKM",
//       "Airport Code iATA,FAA": "KIM",
//       "Airport Name": "Kimberley Airport",
//       "Altitude (Ft)": 3950,
//       "Country": "South Africa",
//       "Latitude": 24.7651996613,
//       "Location": "Kimberley",
//       "Longitude": -28.802799224900003,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 816,
//       "Airport Code [ICAO]": "FAKR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Krugersdorp Airport",
//       "Altitude (Ft)": 5499,
//       "Country": "South Africa",
//       "Latitude": 27.7259149551,
//       "Location": "Krugersdorp",
//       "Longitude": -26.0808259199,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 817,
//       "Airport Code [ICAO]": "FAKS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kroonstad Airport",
//       "Altitude (Ft)": 4700,
//       "Country": "South Africa",
//       "Latitude": 27.315799713134766,
//       "Location": "Kroonstad",
//       "Longitude": -27.660600662231445,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 818,
//       "Airport Code [ICAO]": "FAKU",
//       "Airport Code iATA,FAA": "KMH",
//       "Airport Name": "Johan Pienaar Airport",
//       "Altitude (Ft)": 4382,
//       "Country": "South Africa",
//       "Latitude": 23.411399841308594,
//       "Location": "Kuruman",
//       "Longitude": -27.45669937133789,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 819,
//       "Airport Code [ICAO]": "FAKZ",
//       "Airport Code iATA,FAA": "KLZ",
//       "Airport Name": "Kleinsee Airport",
//       "Altitude (Ft)": 270,
//       "Country": "South Africa",
//       "Latitude": 17.093999862700002,
//       "Location": "Kleinsee",
//       "Longitude": -29.6884002686,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 820,
//       "Airport Code [ICAO]": "FALA",
//       "Airport Code iATA,FAA": "HLA",
//       "Airport Name": "Lanseria Airport",
//       "Altitude (Ft)": 4517,
//       "Country": "South Africa",
//       "Latitude": 27.9260997772,
//       "Location": "Johannesburg",
//       "Longitude": -25.938499450699997,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 821,
//       "Airport Code [ICAO]": "FALI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lichtenburg Airport",
//       "Altitude (Ft)": 4875,
//       "Country": "South Africa",
//       "Latitude": 26.184600830078125,
//       "Location": "Lichtenburg",
//       "Longitude": -26.17569923400879,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 822,
//       "Airport Code [ICAO]": "FALM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Makhado Air Force Base Airport",
//       "Altitude (Ft)": 3069,
//       "Country": "South Africa",
//       "Latitude": 29.696500778198242,
//       "Location": "Lambertsbaai",
//       "Longitude": -23.159900665283203,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 823,
//       "Airport Code [ICAO]": "FALW",
//       "Airport Code iATA,FAA": "SDB",
//       "Airport Name": "Langebaanweg Airport",
//       "Altitude (Ft)": 108,
//       "Country": "South Africa",
//       "Latitude": 18.1602993011,
//       "Location": "Langebaanweg",
//       "Longitude": -32.968898773199996,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 824,
//       "Airport Code [ICAO]": "FALY",
//       "Airport Code iATA,FAA": "LAY",
//       "Airport Name": "Ladysmith Airport",
//       "Altitude (Ft)": 3548,
//       "Country": "South Africa",
//       "Latitude": 29.749700546299998,
//       "Location": "Ladysmith",
//       "Longitude": -28.5816993713,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 825,
//       "Airport Code [ICAO]": "FAMB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Middelburg Airport",
//       "Altitude (Ft)": 4886,
//       "Country": "South Africa",
//       "Latitude": 29.440200805664062,
//       "Location": "Middelburg",
//       "Longitude": -25.684799194335938,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 827,
//       "Airport Code [ICAO]": "FAMG",
//       "Airport Code iATA,FAA": "MGH",
//       "Airport Name": "Margate Airport",
//       "Altitude (Ft)": 495,
//       "Country": "South Africa",
//       "Latitude": 30.343000412,
//       "Location": "Margate",
//       "Longitude": -30.8574008942,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 828,
//       "Airport Code [ICAO]": "FAMI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Marble Hall Airport",
//       "Altitude (Ft)": 2980,
//       "Country": "South Africa",
//       "Latitude": 29.283100128173828,
//       "Location": "Marble Hall",
//       "Longitude": -24.989099502563477,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 829,
//       "Airport Code [ICAO]": "FAMJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Majuba Power Station Airport",
//       "Altitude (Ft)": 5600,
//       "Country": "South Africa",
//       "Latitude": 29.7784996033,
//       "Location": "Majuba Power Station",
//       "Longitude": -27.079299926799997,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 831,
//       "Airport Code [ICAO]": "FAMN",
//       "Airport Code iATA,FAA": "LLE",
//       "Airport Name": "Riverside Airport",
//       "Altitude (Ft)": 1024,
//       "Country": "South Africa",
//       "Latitude": 31.5767002106,
//       "Location": "Malalane",
//       "Longitude": -25.4300003052,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 832,
//       "Airport Code [ICAO]": "FAMS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Morningside Farm Airport",
//       "Altitude (Ft)": 4251,
//       "Country": "South Africa",
//       "Latitude": 26.9090003967,
//       "Location": "Musina",
//       "Longitude": -25.7045001984,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 833,
//       "Airport Code [ICAO]": "FAMU",
//       "Airport Code iATA,FAA": "MZQ",
//       "Airport Name": "Mkuze Airport",
//       "Altitude (Ft)": 400,
//       "Country": "South Africa",
//       "Latitude": 32.0443000793457,
//       "Location": "Mkuze",
//       "Longitude": -27.626100540161133,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 834,
//       "Airport Code [ICAO]": "FANC",
//       "Airport Code iATA,FAA": "NCS",
//       "Airport Name": "Newcastle Airport",
//       "Altitude (Ft)": 4074,
//       "Country": "South Africa",
//       "Latitude": 29.976900100699996,
//       "Location": "Newcastle",
//       "Longitude": -27.7705993652,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 835,
//       "Airport Code [ICAO]": "FANY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nylstroom Airfield",
//       "Altitude (Ft)": 3900,
//       "Country": "South Africa",
//       "Latitude": 28.434900283813477,
//       "Location": "Nylstroom",
//       "Longitude": -24.686100006103516,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 836,
//       "Airport Code [ICAO]": "FAOB",
//       "Airport Code iATA,FAA": "OVG",
//       "Airport Name": "Overberg Airport",
//       "Altitude (Ft)": 52,
//       "Country": "South Africa",
//       "Latitude": 20.250699996900003,
//       "Location": "Overberg",
//       "Longitude": -34.554901123,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 837,
//       "Airport Code [ICAO]": "FAOH",
//       "Airport Code iATA,FAA": "OUH",
//       "Airport Name": "Oudtshoorn Airport",
//       "Altitude (Ft)": 1063,
//       "Country": "South Africa",
//       "Latitude": 22.188999176,
//       "Location": "Oudtshoorn",
//       "Longitude": -33.6069984436,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 838,
//       "Airport Code [ICAO]": "FAPE",
//       "Airport Code iATA,FAA": "PLZ",
//       "Airport Name": "Port Elizabeth Airport",
//       "Altitude (Ft)": 226,
//       "Country": "South Africa",
//       "Latitude": 25.6173000336,
//       "Location": "Port Elizabeth",
//       "Longitude": -33.9849014282,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 839,
//       "Airport Code [ICAO]": "FAPG",
//       "Airport Code iATA,FAA": "PBZ",
//       "Airport Name": "Plettenberg Bay Airport",
//       "Altitude (Ft)": 465,
//       "Country": "South Africa",
//       "Latitude": 23.3287234306,
//       "Location": "Plettenberg Bay",
//       "Longitude": -34.0881601675,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 840,
//       "Airport Code [ICAO]": "FAPH",
//       "Airport Code iATA,FAA": "PHW",
//       "Airport Name": "Hendrik Van Eck Airport",
//       "Altitude (Ft)": 1432,
//       "Country": "South Africa",
//       "Latitude": 31.1553993225,
//       "Location": "Phalaborwa",
//       "Longitude": -23.937200546299998,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 841,
//       "Airport Code [ICAO]": "FAPI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Pietersburg Municipal Airport",
//       "Altitude (Ft)": 4354,
//       "Country": "South Africa",
//       "Latitude": 29.4843997955,
//       "Location": "Polokwane",
//       "Longitude": -23.9260997772,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 842,
//       "Airport Code [ICAO]": "FAPJ",
//       "Airport Code iATA,FAA": "JOH",
//       "Airport Name": "Port St Johns Airport",
//       "Altitude (Ft)": 1227,
//       "Country": "South Africa",
//       "Latitude": 29.519800186157227,
//       "Location": "Port Saint Johns",
//       "Longitude": -31.605899810791016,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 843,
//       "Airport Code [ICAO]": "FAPM",
//       "Airport Code iATA,FAA": "PZB",
//       "Airport Name": "Pietermaritzburg Airport",
//       "Altitude (Ft)": 2423,
//       "Country": "South Africa",
//       "Latitude": 30.3987007141,
//       "Location": "Pietermaritzburg",
//       "Longitude": -29.649000167799997,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 844,
//       "Airport Code [ICAO]": "FAPN",
//       "Airport Code iATA,FAA": "NTY",
//       "Airport Name": "Pilanesberg International Airport",
//       "Altitude (Ft)": 3412,
//       "Country": "South Africa",
//       "Latitude": 27.173400878900004,
//       "Location": "Pilanesberg",
//       "Longitude": -25.333799362199997,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 845,
//       "Airport Code [ICAO]": "FAPP",
//       "Airport Code iATA,FAA": "PTG",
//       "Airport Name": "Polokwane International Airport",
//       "Altitude (Ft)": 4076,
//       "Country": "South Africa",
//       "Latitude": 29.458615,
//       "Location": "Potgietersrus",
//       "Longitude": -23.845269,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 846,
//       "Airport Code [ICAO]": "FAPS",
//       "Airport Code iATA,FAA": "PCF",
//       "Airport Name": "Potchefstroom Airport",
//       "Altitude (Ft)": 4520,
//       "Country": "South Africa",
//       "Latitude": 27.0818996429,
//       "Location": "Potchefstroom",
//       "Longitude": -26.670999527,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 847,
//       "Airport Code [ICAO]": "FAPY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Parys Airport",
//       "Altitude (Ft)": 4740,
//       "Country": "South Africa",
//       "Latitude": 27.503400802612305,
//       "Location": "Parys",
//       "Longitude": -26.889299392700195,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 848,
//       "Airport Code [ICAO]": "FAQT",
//       "Airport Code iATA,FAA": "UTW",
//       "Airport Name": "Queenstown Airport",
//       "Altitude (Ft)": 3637,
//       "Country": "South Africa",
//       "Latitude": 26.882200241088867,
//       "Location": "Queenstown",
//       "Longitude": -31.92020034790039,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 849,
//       "Airport Code [ICAO]": "FARB",
//       "Airport Code iATA,FAA": "RCB",
//       "Airport Name": "Richards Bay Airport",
//       "Altitude (Ft)": 109,
//       "Country": "South Africa",
//       "Latitude": 32.0920982361,
//       "Location": "Richard's Bay",
//       "Longitude": -28.740999221800003,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 850,
//       "Airport Code [ICAO]": "FARG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Rustenburg Airport",
//       "Altitude (Ft)": 3700,
//       "Country": "South Africa",
//       "Latitude": 27.271099090599996,
//       "Location": "Rustenburg",
//       "Longitude": -25.6443004608,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 851,
//       "Airport Code [ICAO]": "FARS",
//       "Airport Code iATA,FAA": "ROD",
//       "Airport Name": "Robertson Airport",
//       "Altitude (Ft)": 640,
//       "Country": "South Africa",
//       "Latitude": 19.902799606323242,
//       "Location": "Robertson",
//       "Longitude": -33.812198638916016,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 852,
//       "Airport Code [ICAO]": "FASB",
//       "Airport Code iATA,FAA": "SBU",
//       "Airport Name": "Springbok Airport",
//       "Altitude (Ft)": 2690,
//       "Country": "South Africa",
//       "Latitude": 17.939599990844727,
//       "Location": "Springbok",
//       "Longitude": -29.689300537109375,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 853,
//       "Airport Code [ICAO]": "FASC",
//       "Airport Code iATA,FAA": "ZEC",
//       "Airport Name": "Secunda Airport",
//       "Altitude (Ft)": 5250,
//       "Country": "South Africa",
//       "Latitude": 29.170099258399997,
//       "Location": "Secunda",
//       "Longitude": -26.52409935,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 854,
//       "Airport Code [ICAO]": "FASD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Saldanha /Vredenburg Airport",
//       "Altitude (Ft)": 50,
//       "Country": "South Africa",
//       "Latitude": 17.9692993164,
//       "Location": "Saldanha",
//       "Longitude": -32.964099884,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 855,
//       "Airport Code [ICAO]": "FASI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Springs Airfield",
//       "Altitude (Ft)": 5340,
//       "Country": "South Africa",
//       "Latitude": 28.3982715607,
//       "Location": "Springs",
//       "Longitude": -26.2494004736,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 856,
//       "Airport Code [ICAO]": "FASK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Swartkop Air Force Base",
//       "Altitude (Ft)": 4780,
//       "Country": "South Africa",
//       "Latitude": 28.164600372299997,
//       "Location": "Swartkop",
//       "Longitude": -25.8097000122,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 857,
//       "Airport Code [ICAO]": "FASS",
//       "Airport Code iATA,FAA": "SIS",
//       "Airport Name": "Sishen Airport",
//       "Altitude (Ft)": 3848,
//       "Country": "South Africa",
//       "Latitude": 22.9993000031,
//       "Location": "Sishen",
//       "Longitude": -27.6485996246,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 858,
//       "Airport Code [ICAO]": "FASX",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hendrik Swellengrebel Airport",
//       "Altitude (Ft)": 407,
//       "Country": "South Africa",
//       "Latitude": 20.474599838256836,
//       "Location": "Swellendam",
//       "Longitude": -34.04819869995117,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 859,
//       "Airport Code [ICAO]": "FASZ",
//       "Airport Code iATA,FAA": "SZK",
//       "Airport Name": "Skukuza Airport",
//       "Altitude (Ft)": 1020,
//       "Country": "South Africa",
//       "Latitude": 31.5886993408,
//       "Location": "Skukuza",
//       "Longitude": -24.960899353,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 860,
//       "Airport Code [ICAO]": "FATF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tommys Field Airport",
//       "Altitude (Ft)": 4360,
//       "Country": "South Africa",
//       "Latitude": 22.993200302124023,
//       "Location": "Tommy's Field",
//       "Longitude": -28.260000228881836,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 861,
//       "Airport Code [ICAO]": "FATP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "New Tempe Airport",
//       "Altitude (Ft)": 4547,
//       "Country": "South Africa",
//       "Latitude": 26.15760040283203,
//       "Location": "Bloemfontein",
//       "Longitude": -29.032899856567383,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 862,
//       "Airport Code [ICAO]": "FATT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tutuka Power Station Airport",
//       "Altitude (Ft)": 5313,
//       "Country": "South Africa",
//       "Latitude": 29.3388004303,
//       "Location": "Tutuka",
//       "Longitude": -26.776599884,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 863,
//       "Airport Code [ICAO]": "FATZ",
//       "Airport Code iATA,FAA": "LTA",
//       "Airport Name": "Tzaneen Airport",
//       "Altitude (Ft)": 1914,
//       "Country": "South Africa",
//       "Latitude": 30.329299926799997,
//       "Location": "Tzaneen",
//       "Longitude": -23.8243999481,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 864,
//       "Airport Code [ICAO]": "FAUL",
//       "Airport Code iATA,FAA": "ULD",
//       "Airport Name": "Prince Mangosuthu Buthelezi Airport",
//       "Altitude (Ft)": 1720,
//       "Country": "South Africa",
//       "Latitude": 31.4165000916,
//       "Location": "Ulundi",
//       "Longitude": -28.3206005096,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 865,
//       "Airport Code [ICAO]": "FAUP",
//       "Airport Code iATA,FAA": "UTN",
//       "Airport Name": "Pierre Van Ryneveld Airport",
//       "Altitude (Ft)": 2782,
//       "Country": "South Africa",
//       "Latitude": 21.260200500499998,
//       "Location": "Upington",
//       "Longitude": -28.39909935,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 866,
//       "Airport Code [ICAO]": "FAUT",
//       "Airport Code iATA,FAA": "UTT",
//       "Airport Name": "K. D. Matanzima Airport",
//       "Altitude (Ft)": 2400,
//       "Country": "South Africa",
//       "Latitude": 28.6733551025,
//       "Location": "Umtata",
//       "Longitude": -31.546363184900002,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 867,
//       "Airport Code [ICAO]": "FAVB",
//       "Airport Code iATA,FAA": "VRU",
//       "Airport Name": "Vryburg Airport",
//       "Altitude (Ft)": 3920,
//       "Country": "South Africa",
//       "Latitude": 24.7287998199,
//       "Location": "Vryburg",
//       "Longitude": -26.9824008942,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 868,
//       "Airport Code [ICAO]": "FAVG",
//       "Airport Code iATA,FAA": "VIR",
//       "Airport Name": "Virginia Airport",
//       "Altitude (Ft)": 20,
//       "Country": "South Africa",
//       "Latitude": 31.058399200439453,
//       "Location": "Durban",
//       "Longitude": -29.770599365234375,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 869,
//       "Airport Code [ICAO]": "FAVR",
//       "Airport Code iATA,FAA": "VRE",
//       "Airport Name": "Vredendal Airport",
//       "Altitude (Ft)": 330,
//       "Country": "South Africa",
//       "Latitude": 18.5447998046875,
//       "Location": "Vredendal",
//       "Longitude": -31.641000747680664,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 870,
//       "Airport Code [ICAO]": "FAVV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Vereeniging Airport",
//       "Altitude (Ft)": 4846,
//       "Country": "South Africa",
//       "Latitude": 27.9608001709,
//       "Location": "Vereeniging",
//       "Longitude": -26.566400528,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 871,
//       "Airport Code [ICAO]": "FAWB",
//       "Airport Code iATA,FAA": "PRY",
//       "Airport Name": "Wonderboom Airport",
//       "Altitude (Ft)": 4095,
//       "Country": "South Africa",
//       "Latitude": 28.224199,
//       "Location": "Pretoria",
//       "Longitude": -25.6539,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 872,
//       "Airport Code [ICAO]": "FAWI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Witbank Airport",
//       "Altitude (Ft)": 5078,
//       "Country": "South Africa",
//       "Latitude": 29.1919994354,
//       "Location": "Witbank",
//       "Longitude": -25.832300186199998,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 873,
//       "Airport Code [ICAO]": "FAWK",
//       "Airport Code iATA,FAA": "WKF",
//       "Airport Name": "Waterkloof Air Force Base",
//       "Altitude (Ft)": 4940,
//       "Country": "South Africa",
//       "Latitude": 28.222499847399998,
//       "Location": "Waterkloof",
//       "Longitude": -25.829999923699997,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 874,
//       "Airport Code [ICAO]": "FAWM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Welkom Airport",
//       "Altitude (Ft)": 4421,
//       "Country": "South Africa",
//       "Latitude": 26.663333892799997,
//       "Location": "Welkom",
//       "Longitude": -27.996824511099998,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 875,
//       "Airport Code [ICAO]": "FAYP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ysterplaat Air Force Base",
//       "Altitude (Ft)": 52,
//       "Country": "South Africa",
//       "Latitude": 18.498300552368164,
//       "Location": "Ysterplaat",
//       "Longitude": -33.90019989013672,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 876,
//       "Airport Code [ICAO]": "FAZR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Zeerust Airport",
//       "Altitude (Ft)": 4258,
//       "Country": "South Africa",
//       "Latitude": 26.042299270629883,
//       "Location": "Zeerust",
//       "Longitude": -25.599000930786133,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 877,
//       "Airport Code [ICAO]": "FBFT",
//       "Airport Code iATA,FAA": "FRW",
//       "Airport Name": "Francistown Airport",
//       "Altitude (Ft)": 3283,
//       "Country": "Botswana",
//       "Latitude": 27.47450065612793,
//       "Location": "Francistown",
//       "Longitude": -21.15959930419922,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Gaborone",
//       "Type": "airport"
//     },
//     {
//       "ID": 878,
//       "Airport Code [ICAO]": "FBJW",
//       "Airport Code iATA,FAA": "JWA",
//       "Airport Name": "Jwaneng Airport",
//       "Altitude (Ft)": 3900,
//       "Country": "Botswana",
//       "Latitude": 24.69099998474121,
//       "Location": "Jwaneng",
//       "Longitude": -24.6023006439209,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Gaborone",
//       "Type": "airport"
//     },
//     {
//       "ID": 879,
//       "Airport Code [ICAO]": "FBKE",
//       "Airport Code iATA,FAA": "BBK",
//       "Airport Name": "Kasane Airport",
//       "Altitude (Ft)": 3289,
//       "Country": "Botswana",
//       "Latitude": 25.162399291992188,
//       "Location": "Kasane",
//       "Longitude": -17.83289909362793,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Gaborone",
//       "Type": "airport"
//     },
//     {
//       "ID": 880,
//       "Airport Code [ICAO]": "FBMN",
//       "Airport Code iATA,FAA": "MUB",
//       "Airport Name": "Maun Airport",
//       "Altitude (Ft)": 3093,
//       "Country": "Botswana",
//       "Latitude": 23.431100845336914,
//       "Location": "Maun",
//       "Longitude": -19.97260093688965,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Gaborone",
//       "Type": "airport"
//     },
//     {
//       "ID": 881,
//       "Airport Code [ICAO]": "FBSK",
//       "Airport Code iATA,FAA": "GBE",
//       "Airport Name": "Sir Seretse Khama International Airport",
//       "Altitude (Ft)": 3299,
//       "Country": "Botswana",
//       "Latitude": 25.9182,
//       "Location": "Gaberone",
//       "Longitude": -24.555201,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Gaborone",
//       "Type": "airport"
//     },
//     {
//       "ID": 882,
//       "Airport Code [ICAO]": "FBSP",
//       "Airport Code iATA,FAA": "PKW",
//       "Airport Name": "Selebi Phikwe Airport",
//       "Altitude (Ft)": 2925,
//       "Country": "Botswana",
//       "Latitude": 27.8288,
//       "Location": "Selebi-phikwe",
//       "Longitude": -22.0583,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Gaborone",
//       "Type": "airport"
//     },
//     {
//       "ID": 883,
//       "Airport Code [ICAO]": "FCBB",
//       "Airport Code iATA,FAA": "BZV",
//       "Airport Name": "Maya-Maya Airport",
//       "Altitude (Ft)": 1048,
//       "Country": "Congo (Brazzaville)",
//       "Latitude": 15.253000259399414,
//       "Location": "Brazzaville",
//       "Longitude": -4.251699924468994,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Brazzaville",
//       "Type": "airport"
//     },
//     {
//       "ID": 884,
//       "Airport Code [ICAO]": "FCOO",
//       "Airport Code iATA,FAA": "FTX",
//       "Airport Name": "Owando Airport",
//       "Altitude (Ft)": 1214,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 15.95009994506836,
//       "Location": "Owando",
//       "Longitude": -0.5313500165939331,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Brazzaville",
//       "Type": "airport"
//     },
//     {
//       "ID": 885,
//       "Airport Code [ICAO]": "FCOU",
//       "Airport Code iATA,FAA": "OUE",
//       "Airport Name": "Ouesso Airport",
//       "Altitude (Ft)": 1158,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 16.0379009247,
//       "Location": "Ouesso",
//       "Longitude": 1.6159900426899998,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Brazzaville",
//       "Type": "airport"
//     },
//     {
//       "ID": 886,
//       "Airport Code [ICAO]": "FCPP",
//       "Airport Code iATA,FAA": "PNR",
//       "Airport Name": "Pointe Noire Airport",
//       "Altitude (Ft)": 55,
//       "Country": "Congo (Brazzaville)",
//       "Latitude": 11.88659954071045,
//       "Location": "Pointe-noire",
//       "Longitude": -4.816030025482178,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Brazzaville",
//       "Type": "airport"
//     },
//     {
//       "ID": 887,
//       "Airport Code [ICAO]": "FDMS",
//       "Airport Code iATA,FAA": "MTS",
//       "Airport Name": "Matsapha Airport",
//       "Altitude (Ft)": 2075,
//       "Country": "Swaziland",
//       "Latitude": 31.3075008392334,
//       "Location": "Manzini",
//       "Longitude": -26.52899932861328,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Mbabane",
//       "Type": "airport"
//     },
//     {
//       "ID": 888,
//       "Airport Code [ICAO]": "FEFF",
//       "Airport Code iATA,FAA": "BGF",
//       "Airport Name": "Bangui M'Poko International Airport",
//       "Altitude (Ft)": 1208,
//       "Country": "Central African Republic",
//       "Latitude": 18.518800735473633,
//       "Location": "Bangui",
//       "Longitude": 4.39847993850708,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Bangui",
//       "Type": "airport"
//     },
//     {
//       "ID": 889,
//       "Airport Code [ICAO]": "FEFT",
//       "Airport Code iATA,FAA": "BBT",
//       "Airport Name": "Berbérati Airport",
//       "Altitude (Ft)": 1929,
//       "Country": "Central African Republic",
//       "Latitude": 15.786399841308594,
//       "Location": "Berberati",
//       "Longitude": 4.2215800285339355,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Bangui",
//       "Type": "airport"
//     },
//     {
//       "ID": 890,
//       "Airport Code [ICAO]": "FGBT",
//       "Airport Code iATA,FAA": "BSG",
//       "Airport Name": "Bata Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Equatorial Guinea",
//       "Latitude": 9.805680274963379,
//       "Location": "Bata",
//       "Longitude": 1.9054700136184692,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Malabo",
//       "Type": "airport"
//     },
//     {
//       "ID": 891,
//       "Airport Code [ICAO]": "FGSL",
//       "Airport Code iATA,FAA": "SSG",
//       "Airport Name": "Malabo Airport",
//       "Altitude (Ft)": 76,
//       "Country": "Equatorial Guinea",
//       "Latitude": 8.708720207214355,
//       "Location": "Malabo",
//       "Longitude": 3.755270004272461,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Malabo",
//       "Type": "airport"
//     },
//     {
//       "ID": 892,
//       "Airport Code [ICAO]": "FHAW",
//       "Airport Code iATA,FAA": "ASI",
//       "Airport Name": "RAF Ascension Island",
//       "Altitude (Ft)": 278,
//       "Country": "Saint Helena",
//       "Latitude": -14.3937,
//       "Location": "Wide Awake",
//       "Longitude": -7.9696,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Atlantic/St_Helena",
//       "Type": "airport"
//     },
//     {
//       "ID": 893,
//       "Airport Code [ICAO]": "FIMP",
//       "Airport Code iATA,FAA": "MRU",
//       "Airport Name": "Sir Seewoosagur Ramgoolam International Airport",
//       "Altitude (Ft)": 186,
//       "Country": "Mauritius",
//       "Latitude": 57.683601,
//       "Location": "Plaisance",
//       "Longitude": -20.430201,
//       "GMT Offset": "4",
//       "Daylight Savings": "N",
//       "Region": "Indian/Mauritius",
//       "Type": "airport"
//     },
//     {
//       "ID": 894,
//       "Airport Code [ICAO]": "FIMR",
//       "Airport Code iATA,FAA": "RRG",
//       "Airport Name": "Sir Charles Gaetan Duval Airport",
//       "Altitude (Ft)": 95,
//       "Country": "Mauritius",
//       "Latitude": 63.361,
//       "Location": "Rodriguez Island",
//       "Longitude": -19.7577,
//       "GMT Offset": "4",
//       "Daylight Savings": "N",
//       "Region": "Indian/Mauritius",
//       "Type": "airport"
//     },
//     {
//       "ID": 895,
//       "Airport Code [ICAO]": "FJDG",
//       "Airport Code iATA,FAA": "NKW",
//       "Airport Name": "Diego Garcia Naval Support Facility",
//       "Altitude (Ft)": 9,
//       "Country": "British Indian Ocean Territory",
//       "Latitude": 72.411102,
//       "Location": "Diego Garcia Island",
//       "Longitude": -7.31327,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Indian/Chagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 896,
//       "Airport Code [ICAO]": "FKKC",
//       "Airport Code iATA,FAA": "TKC",
//       "Airport Name": "Tiko Airport",
//       "Altitude (Ft)": 151,
//       "Country": "Cameroon",
//       "Latitude": 9.360529899600001,
//       "Location": "Tiko",
//       "Longitude": 4.08919000626,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Douala",
//       "Type": "airport"
//     },
//     {
//       "ID": 897,
//       "Airport Code [ICAO]": "FKKD",
//       "Airport Code iATA,FAA": "DLA",
//       "Airport Name": "Douala International Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Cameroon",
//       "Latitude": 9.719479560849999,
//       "Location": "Douala",
//       "Longitude": 4.0060801506,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Douala",
//       "Type": "airport"
//     },
//     {
//       "ID": 898,
//       "Airport Code [ICAO]": "FKKL",
//       "Airport Code iATA,FAA": "MVR",
//       "Airport Name": "Salak Airport",
//       "Altitude (Ft)": 1390,
//       "Country": "Cameroon",
//       "Latitude": 14.257399559020996,
//       "Location": "Maroua",
//       "Longitude": 10.451399803161621,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Douala",
//       "Type": "airport"
//     },
//     {
//       "ID": 899,
//       "Airport Code [ICAO]": "FKKM",
//       "Airport Code iATA,FAA": "FOM",
//       "Airport Name": "Foumban Nkounja Airport",
//       "Altitude (Ft)": 3963,
//       "Country": "Cameroon",
//       "Latitude": 10.750800132751465,
//       "Location": "Foumban",
//       "Longitude": 5.636919975280762,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Douala",
//       "Type": "airport"
//     },
//     {
//       "ID": 900,
//       "Airport Code [ICAO]": "FKKN",
//       "Airport Code iATA,FAA": "NGE",
//       "Airport Name": "N'Gaoundéré Airport",
//       "Altitude (Ft)": 3655,
//       "Country": "Cameroon",
//       "Latitude": 13.559200286865234,
//       "Location": "N'gaoundere",
//       "Longitude": 7.3570098876953125,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Douala",
//       "Type": "airport"
//     },
//     {
//       "ID": 901,
//       "Airport Code [ICAO]": "FKKR",
//       "Airport Code iATA,FAA": "GOU",
//       "Airport Name": "Garoua International Airport",
//       "Altitude (Ft)": 794,
//       "Country": "Cameroon",
//       "Latitude": 13.370100021362305,
//       "Location": "Garoua",
//       "Longitude": 9.33588981628418,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Douala",
//       "Type": "airport"
//     },
//     {
//       "ID": 902,
//       "Airport Code [ICAO]": "FKKU",
//       "Airport Code iATA,FAA": "BFX",
//       "Airport Name": "Bafoussam Airport",
//       "Altitude (Ft)": 4347,
//       "Country": "Cameroon",
//       "Latitude": 10.354599952700001,
//       "Location": "Bafoussam",
//       "Longitude": 5.536920070650001,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Douala",
//       "Type": "airport"
//     },
//     {
//       "ID": 903,
//       "Airport Code [ICAO]": "FKKV",
//       "Airport Code iATA,FAA": "BPC",
//       "Airport Name": "Bamenda Airport",
//       "Altitude (Ft)": 4065,
//       "Country": "Cameroon",
//       "Latitude": 10.122599601745605,
//       "Location": "Bamenda",
//       "Longitude": 6.039239883422852,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Douala",
//       "Type": "airport"
//     },
//     {
//       "ID": 904,
//       "Airport Code [ICAO]": "FKKY",
//       "Airport Code iATA,FAA": "YAO",
//       "Airport Name": "Yaoundé Airport",
//       "Altitude (Ft)": 2464,
//       "Country": "Cameroon",
//       "Latitude": 11.523500442504883,
//       "Location": "Yaounde",
//       "Longitude": 3.8360400199890137,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Douala",
//       "Type": "airport"
//     },
//     {
//       "ID": 905,
//       "Airport Code [ICAO]": "FLKE",
//       "Airport Code iATA,FAA": "CGJ",
//       "Airport Name": "Kasompe Airport",
//       "Altitude (Ft)": 4636,
//       "Country": "Zambia",
//       "Latitude": 27.893899917603,
//       "Location": "Kasompe",
//       "Longitude": -12.572799682617,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Lusaka",
//       "Type": "airport"
//     },
//     {
//       "ID": 906,
//       "Airport Code [ICAO]": "FLLI",
//       "Airport Code iATA,FAA": "LVI",
//       "Airport Name": "Livingstone Airport",
//       "Altitude (Ft)": 3302,
//       "Country": "Zambia",
//       "Latitude": 25.82270050048828,
//       "Location": "Livingstone",
//       "Longitude": -17.821800231933594,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Lusaka",
//       "Type": "airport"
//     },
//     {
//       "ID": 907,
//       "Airport Code [ICAO]": "FLLS",
//       "Airport Code iATA,FAA": "LUN",
//       "Airport Name": "Kenneth Kaunda International Airport Lusaka",
//       "Altitude (Ft)": 3779,
//       "Country": "Zambia",
//       "Latitude": 28.4526004791,
//       "Location": "Lusaka",
//       "Longitude": -15.3308000565,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Lusaka",
//       "Type": "airport"
//     },
//     {
//       "ID": 908,
//       "Airport Code [ICAO]": "FLMF",
//       "Airport Code iATA,FAA": "MFU",
//       "Airport Name": "Mfuwe Airport",
//       "Altitude (Ft)": 1853,
//       "Country": "Zambia",
//       "Latitude": 31.936599731445312,
//       "Location": "Mfuwe",
//       "Longitude": -13.258899688720703,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Lusaka",
//       "Type": "airport"
//     },
//     {
//       "ID": 909,
//       "Airport Code [ICAO]": "FLMG",
//       "Airport Code iATA,FAA": "MNR",
//       "Airport Name": "Mongu Airport",
//       "Altitude (Ft)": 3488,
//       "Country": "Zambia",
//       "Latitude": 23.16230010986328,
//       "Location": "Mongu",
//       "Longitude": -15.254500389099121,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Lusaka",
//       "Type": "airport"
//     },
//     {
//       "ID": 910,
//       "Airport Code [ICAO]": "FLND",
//       "Airport Code iATA,FAA": "NLA",
//       "Airport Name": "Simon Mwansa Kapwepwe International Airport",
//       "Altitude (Ft)": 4167,
//       "Country": "Zambia",
//       "Latitude": 28.66489982605,
//       "Location": "Ndola",
//       "Longitude": -12.998100280762,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Lusaka",
//       "Type": "airport"
//     },
//     {
//       "ID": 911,
//       "Airport Code [ICAO]": "FLSO",
//       "Airport Code iATA,FAA": "KIW",
//       "Airport Name": "Southdowns Airport",
//       "Altitude (Ft)": 4145,
//       "Country": "Zambia",
//       "Latitude": 28.149900436401367,
//       "Location": "Southdowns",
//       "Longitude": -12.900500297546387,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Lusaka",
//       "Type": "airport"
//     },
//     {
//       "ID": 912,
//       "Airport Code [ICAO]": "FMCH",
//       "Airport Code iATA,FAA": "HAH",
//       "Airport Name": "Prince Said Ibrahim International Airport",
//       "Altitude (Ft)": 93,
//       "Country": "Comoros",
//       "Latitude": 43.2719,
//       "Location": "Moroni",
//       "Longitude": -11.5337,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Comoro",
//       "Type": "airport"
//     },
//     {
//       "ID": 913,
//       "Airport Code [ICAO]": "FMCI",
//       "Airport Code iATA,FAA": "NWA",
//       "Airport Name": "Mohéli Bandar Es Eslam Airport",
//       "Altitude (Ft)": 46,
//       "Country": "Comoros",
//       "Latitude": 43.76639938354492,
//       "Location": "Moheli",
//       "Longitude": -12.298100471496582,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Comoro",
//       "Type": "airport"
//     },
//     {
//       "ID": 914,
//       "Airport Code [ICAO]": "FMCV",
//       "Airport Code iATA,FAA": "AJN",
//       "Airport Name": "Ouani Airport",
//       "Altitude (Ft)": 62,
//       "Country": "Comoros",
//       "Latitude": 44.430301666259766,
//       "Location": "Anjouan",
//       "Longitude": -12.131699562072754,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Comoro",
//       "Type": "airport"
//     },
//     {
//       "ID": 915,
//       "Airport Code [ICAO]": "FMCZ",
//       "Airport Code iATA,FAA": "DZA",
//       "Airport Name": "Dzaoudzi Pamandzi International Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Mayotte",
//       "Latitude": 45.28110122680664,
//       "Location": "Dzaoudzi",
//       "Longitude": -12.804699897766113,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Mayotte",
//       "Type": "airport"
//     },
//     {
//       "ID": 916,
//       "Airport Code [ICAO]": "FMEE",
//       "Airport Code iATA,FAA": "RUN",
//       "Airport Name": "Roland Garros Airport",
//       "Altitude (Ft)": 66,
//       "Country": "Reunion",
//       "Latitude": 55.51029968261719,
//       "Location": "St.-denis",
//       "Longitude": -20.887100219726562,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Indian/Reunion",
//       "Type": "airport"
//     },
//     {
//       "ID": 917,
//       "Airport Code [ICAO]": "FMEP",
//       "Airport Code iATA,FAA": "ZSE",
//       "Airport Name": "Pierrefonds Airport",
//       "Altitude (Ft)": 59,
//       "Country": "Reunion",
//       "Latitude": 55.42499923706055,
//       "Location": "St.-pierre",
//       "Longitude": -21.320899963378906,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Indian/Reunion",
//       "Type": "airport"
//     },
//     {
//       "ID": 918,
//       "Airport Code [ICAO]": "FMMI",
//       "Airport Code iATA,FAA": "TNR",
//       "Airport Name": "Ivato Airport",
//       "Altitude (Ft)": 4198,
//       "Country": "Madagascar",
//       "Latitude": 47.478802,
//       "Location": "Antananarivo",
//       "Longitude": -18.7969,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 919,
//       "Airport Code [ICAO]": "FMMN",
//       "Airport Code iATA,FAA": "ZVA",
//       "Airport Name": "Miandrivazo Airport",
//       "Altitude (Ft)": 203,
//       "Country": "Madagascar",
//       "Latitude": 45.450801849365234,
//       "Location": "Miandrivazo",
//       "Longitude": -19.56279945373535,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 920,
//       "Airport Code [ICAO]": "FMMS",
//       "Airport Code iATA,FAA": "SMS",
//       "Airport Name": "Sainte Marie Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Madagascar",
//       "Latitude": 49.815799713134766,
//       "Location": "Sainte Marie",
//       "Longitude": -17.093900680541992,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 921,
//       "Airport Code [ICAO]": "FMMT",
//       "Airport Code iATA,FAA": "TMM",
//       "Airport Name": "Toamasina Airport",
//       "Altitude (Ft)": 22,
//       "Country": "Madagascar",
//       "Latitude": 49.39250183105469,
//       "Location": "Toamasina",
//       "Longitude": -18.109500885009766,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 922,
//       "Airport Code [ICAO]": "FMMV",
//       "Airport Code iATA,FAA": "MOQ",
//       "Airport Name": "Morondava Airport",
//       "Altitude (Ft)": 30,
//       "Country": "Madagascar",
//       "Latitude": 44.31760025024414,
//       "Location": "Morondava",
//       "Longitude": -20.284700393676758,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 923,
//       "Airport Code [ICAO]": "FMNA",
//       "Airport Code iATA,FAA": "DIE",
//       "Airport Name": "Arrachart Airport",
//       "Altitude (Ft)": 374,
//       "Country": "Madagascar",
//       "Latitude": 49.29169845581055,
//       "Location": "Antsiranana",
//       "Longitude": -12.34939956665039,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 924,
//       "Airport Code [ICAO]": "FMNC",
//       "Airport Code iATA,FAA": "WMR",
//       "Airport Name": "Mananara Nord Airport",
//       "Altitude (Ft)": 9,
//       "Country": "Madagascar",
//       "Latitude": 49.773799896240234,
//       "Location": "Mananara",
//       "Longitude": -16.16390037536621,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 925,
//       "Airport Code [ICAO]": "FMND",
//       "Airport Code iATA,FAA": "ZWA",
//       "Airport Name": "Andapa Airport",
//       "Altitude (Ft)": 1552,
//       "Country": "Madagascar",
//       "Latitude": 49.620601654052734,
//       "Location": "Andapa",
//       "Longitude": -14.651700019836426,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 926,
//       "Airport Code [ICAO]": "FMNE",
//       "Airport Code iATA,FAA": "AMB",
//       "Airport Name": "Ambilobe Airport",
//       "Altitude (Ft)": 72,
//       "Country": "Madagascar",
//       "Latitude": 48.987998962402344,
//       "Location": "Ambilobe",
//       "Longitude": -13.188400268554688,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 927,
//       "Airport Code [ICAO]": "FMNH",
//       "Airport Code iATA,FAA": "ANM",
//       "Airport Name": "Antsirabato Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Madagascar",
//       "Latitude": 50.3202018737793,
//       "Location": "Antalaha",
//       "Longitude": -14.99940013885498,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 928,
//       "Airport Code [ICAO]": "FMNL",
//       "Airport Code iATA,FAA": "HVA",
//       "Airport Name": "Analalava Airport",
//       "Altitude (Ft)": 345,
//       "Country": "Madagascar",
//       "Latitude": 47.76380157470703,
//       "Location": "Analalava",
//       "Longitude": -14.62969970703125,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 929,
//       "Airport Code [ICAO]": "FMNM",
//       "Airport Code iATA,FAA": "MJN",
//       "Airport Name": "Amborovy Airport",
//       "Altitude (Ft)": 87,
//       "Country": "Madagascar",
//       "Latitude": 46.351232528699995,
//       "Location": "Mahajanga",
//       "Longitude": -15.6668417421,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 930,
//       "Airport Code [ICAO]": "FMNN",
//       "Airport Code iATA,FAA": "NOS",
//       "Airport Name": "Fascene Airport",
//       "Altitude (Ft)": 36,
//       "Country": "Madagascar",
//       "Latitude": 48.3148002625,
//       "Location": "Nosy-be",
//       "Longitude": -13.3121004105,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 931,
//       "Airport Code [ICAO]": "FMNQ",
//       "Airport Code iATA,FAA": "BPY",
//       "Airport Name": "Besalampy Airport",
//       "Altitude (Ft)": 125,
//       "Country": "Madagascar",
//       "Latitude": 44.4824838638,
//       "Location": "Besalampy",
//       "Longitude": -16.744530296500002,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 932,
//       "Airport Code [ICAO]": "FMNR",
//       "Airport Code iATA,FAA": "WMN",
//       "Airport Name": "Maroantsetra Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Madagascar",
//       "Latitude": 49.68830108642578,
//       "Location": "Maroantsetra",
//       "Longitude": -15.436699867248535,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 933,
//       "Airport Code [ICAO]": "FMNS",
//       "Airport Code iATA,FAA": "SVB",
//       "Airport Name": "Sambava Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Madagascar",
//       "Latitude": 50.17470169067383,
//       "Location": "Sambava",
//       "Longitude": -14.278599739074707,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 934,
//       "Airport Code [ICAO]": "FMNV",
//       "Airport Code iATA,FAA": "VOH",
//       "Airport Name": "Vohimarina Airport",
//       "Altitude (Ft)": 19,
//       "Country": "Madagascar",
//       "Latitude": 50.00279998779297,
//       "Location": "Vohemar",
//       "Longitude": -13.375800132751465,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 935,
//       "Airport Code [ICAO]": "FMNW",
//       "Airport Code iATA,FAA": "WAI",
//       "Airport Name": "Ambalabe Airport",
//       "Altitude (Ft)": 92,
//       "Country": "Madagascar",
//       "Latitude": 47.993900299072266,
//       "Location": "Antsohihy",
//       "Longitude": -14.898799896240234,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 936,
//       "Airport Code [ICAO]": "FMNZ",
//       "Airport Code iATA,FAA": "IVA",
//       "Airport Name": "Ampampamena Airport",
//       "Altitude (Ft)": 49,
//       "Country": "Madagascar",
//       "Latitude": 48.632702,
//       "Location": "Ampampamena",
//       "Longitude": -13.484816,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 937,
//       "Airport Code [ICAO]": "FMSD",
//       "Airport Code iATA,FAA": "FTU",
//       "Airport Name": "Tôlanaro Airport",
//       "Altitude (Ft)": 29,
//       "Country": "Madagascar",
//       "Latitude": 46.95610046386719,
//       "Location": "Tolagnaro",
//       "Longitude": -25.03809928894043,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 938,
//       "Airport Code [ICAO]": "FMSF",
//       "Airport Code iATA,FAA": "WFI",
//       "Airport Name": "Fianarantsoa Airport",
//       "Altitude (Ft)": 3658,
//       "Country": "Madagascar",
//       "Latitude": 47.111698150634766,
//       "Location": "Fianarantsoa",
//       "Longitude": -21.441600799560547,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 939,
//       "Airport Code [ICAO]": "FMSG",
//       "Airport Code iATA,FAA": "RVA",
//       "Airport Name": "Farafangana Airport",
//       "Altitude (Ft)": 26,
//       "Country": "Madagascar",
//       "Latitude": 47.82059860229492,
//       "Location": "Farafangana",
//       "Longitude": -22.805299758911133,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 940,
//       "Airport Code [ICAO]": "FMSK",
//       "Airport Code iATA,FAA": "WVK",
//       "Airport Name": "Manakara Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Madagascar",
//       "Latitude": 48.02170181274414,
//       "Location": "Manakara",
//       "Longitude": -22.119699478149414,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 941,
//       "Airport Code [ICAO]": "FMSM",
//       "Airport Code iATA,FAA": "MNJ",
//       "Airport Name": "Mananjary Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Madagascar",
//       "Latitude": 48.358299255371094,
//       "Location": "Mananjary",
//       "Longitude": -21.201799392700195,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 942,
//       "Airport Code [ICAO]": "FMSR",
//       "Airport Code iATA,FAA": "MXM",
//       "Airport Name": "Morombe Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Madagascar",
//       "Latitude": 43.3754997253418,
//       "Location": "Morombe",
//       "Longitude": -21.7539005279541,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 943,
//       "Airport Code [ICAO]": "FMST",
//       "Airport Code iATA,FAA": "TLE",
//       "Airport Name": "Toliara Airport",
//       "Altitude (Ft)": 29,
//       "Country": "Madagascar",
//       "Latitude": 43.72850036621094,
//       "Location": "Toliara",
//       "Longitude": -23.383399963378906,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 944,
//       "Airport Code [ICAO]": "FNBC",
//       "Airport Code iATA,FAA": "SSY",
//       "Airport Name": "Mbanza Congo Airport",
//       "Altitude (Ft)": 1860,
//       "Country": "Angola",
//       "Latitude": 14.246999740600586,
//       "Location": "M'banza-congo",
//       "Longitude": -6.269899845123291,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Luanda",
//       "Type": "airport"
//     },
//     {
//       "ID": 945,
//       "Airport Code [ICAO]": "FNBG",
//       "Airport Code iATA,FAA": "BUG",
//       "Airport Name": "Benguela Airport",
//       "Altitude (Ft)": 118,
//       "Country": "Angola",
//       "Latitude": 13.4036998749,
//       "Location": "Benguela",
//       "Longitude": -12.609000206,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Luanda",
//       "Type": "airport"
//     },
//     {
//       "ID": 946,
//       "Airport Code [ICAO]": "FNCA",
//       "Airport Code iATA,FAA": "CAB",
//       "Airport Name": "Cabinda Airport",
//       "Altitude (Ft)": 66,
//       "Country": "Angola",
//       "Latitude": 12.188400268554688,
//       "Location": "Cabinda",
//       "Longitude": -5.59699010848999,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Luanda",
//       "Type": "airport"
//     },
//     {
//       "ID": 948,
//       "Airport Code [ICAO]": "FNHU",
//       "Airport Code iATA,FAA": "NOV",
//       "Airport Name": "Nova Lisboa Airport",
//       "Altitude (Ft)": 5587,
//       "Country": "Angola",
//       "Latitude": 15.760499954223633,
//       "Location": "Huambo",
//       "Longitude": -12.808899879455566,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Luanda",
//       "Type": "airport"
//     },
//     {
//       "ID": 949,
//       "Airport Code [ICAO]": "FNKU",
//       "Airport Code iATA,FAA": "SVP",
//       "Airport Name": "Kuito Airport",
//       "Altitude (Ft)": 5618,
//       "Country": "Angola",
//       "Latitude": 16.947399139404,
//       "Location": "Kuito",
//       "Longitude": -12.404600143433,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Luanda",
//       "Type": "airport"
//     },
//     {
//       "ID": 950,
//       "Airport Code [ICAO]": "FNLB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lobito Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Angola",
//       "Latitude": 13.536600112915039,
//       "Location": "Lobito",
//       "Longitude": -12.371199607849121,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Luanda",
//       "Type": "airport"
//     },
//     {
//       "ID": 951,
//       "Airport Code [ICAO]": "FNLU",
//       "Airport Code iATA,FAA": "LAD",
//       "Airport Name": "Quatro de Fevereiro Airport",
//       "Altitude (Ft)": 243,
//       "Country": "Angola",
//       "Latitude": 13.2312,
//       "Location": "Luanda",
//       "Longitude": -8.85837,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Luanda",
//       "Type": "airport"
//     },
//     {
//       "ID": 952,
//       "Airport Code [ICAO]": "FNMA",
//       "Airport Code iATA,FAA": "MEG",
//       "Airport Name": "Malanje Airport",
//       "Altitude (Ft)": 3868,
//       "Country": "Angola",
//       "Latitude": 16.312400817871094,
//       "Location": "Malanje",
//       "Longitude": -9.525090217590332,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Luanda",
//       "Type": "airport"
//     },
//     {
//       "ID": 953,
//       "Airport Code [ICAO]": "FNME",
//       "Airport Code iATA,FAA": "SPP",
//       "Airport Name": "Menongue Airport",
//       "Altitude (Ft)": 4469,
//       "Country": "Angola",
//       "Latitude": 17.71980094909668,
//       "Location": "Menongue",
//       "Longitude": -14.657600402832031,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Luanda",
//       "Type": "airport"
//     },
//     {
//       "ID": 955,
//       "Airport Code [ICAO]": "FNNG",
//       "Airport Code iATA,FAA": "GXG",
//       "Airport Name": "Negage Airport",
//       "Altitude (Ft)": 4105,
//       "Country": "Angola",
//       "Latitude": 15.287699699401855,
//       "Location": "Negage",
//       "Longitude": -7.754509925842285,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Luanda",
//       "Type": "airport"
//     },
//     {
//       "ID": 956,
//       "Airport Code [ICAO]": "FNPA",
//       "Airport Code iATA,FAA": "PBN",
//       "Airport Name": "Porto Amboim Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Angola",
//       "Latitude": 13.76550006866455,
//       "Location": "Porto Amboim",
//       "Longitude": -10.722000122070312,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Luanda",
//       "Type": "airport"
//     },
//     {
//       "ID": 957,
//       "Airport Code [ICAO]": "FNSA",
//       "Airport Code iATA,FAA": "VHC",
//       "Airport Name": "Saurimo Airport",
//       "Altitude (Ft)": 3584,
//       "Country": "Angola",
//       "Latitude": 20.431900024414062,
//       "Location": "Saurimo",
//       "Longitude": -9.689069747924805,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Luanda",
//       "Type": "airport"
//     },
//     {
//       "ID": 958,
//       "Airport Code [ICAO]": "FNSO",
//       "Airport Code iATA,FAA": "SZA",
//       "Airport Name": "Soyo Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Angola",
//       "Latitude": 12.371800422668457,
//       "Location": "Soyo",
//       "Longitude": -6.141089916229248,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Luanda",
//       "Type": "airport"
//     },
//     {
//       "ID": 959,
//       "Airport Code [ICAO]": "FNUB",
//       "Airport Code iATA,FAA": "SDD",
//       "Airport Name": "Lubango Airport",
//       "Altitude (Ft)": 5778,
//       "Country": "Angola",
//       "Latitude": 13.574999809265137,
//       "Location": "Lubango",
//       "Longitude": -14.924699783325195,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Luanda",
//       "Type": "airport"
//     },
//     {
//       "ID": 960,
//       "Airport Code [ICAO]": "FNUE",
//       "Airport Code iATA,FAA": "LUO",
//       "Airport Name": "Luena Airport",
//       "Altitude (Ft)": 4360,
//       "Country": "Angola",
//       "Latitude": 19.8976993560791,
//       "Location": "Luena",
//       "Longitude": -11.768099784851074,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Luanda",
//       "Type": "airport"
//     },
//     {
//       "ID": 961,
//       "Airport Code [ICAO]": "FNUG",
//       "Airport Code iATA,FAA": "UGO",
//       "Airport Name": "Uige Airport",
//       "Altitude (Ft)": 2720,
//       "Country": "Angola",
//       "Latitude": 15.027799606323242,
//       "Location": "Uige",
//       "Longitude": -7.60306978225708,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Luanda",
//       "Type": "airport"
//     },
//     {
//       "ID": 962,
//       "Airport Code [ICAO]": "FNXA",
//       "Airport Code iATA,FAA": "XGN",
//       "Airport Name": "Xangongo Airport",
//       "Altitude (Ft)": 3635,
//       "Country": "Angola",
//       "Latitude": 14.965299606323242,
//       "Location": "Xangongo",
//       "Longitude": -16.755399703979492,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Luanda",
//       "Type": "airport"
//     },
//     {
//       "ID": 963,
//       "Airport Code [ICAO]": "FOGO",
//       "Airport Code iATA,FAA": "OYE",
//       "Airport Name": "Oyem Airport",
//       "Altitude (Ft)": 2158,
//       "Country": "Gabon",
//       "Latitude": 11.581399917602539,
//       "Location": "Oyem",
//       "Longitude": 1.5431100130081177,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Libreville",
//       "Type": "airport"
//     },
//     {
//       "ID": 964,
//       "Airport Code [ICAO]": "FOGQ",
//       "Airport Code iATA,FAA": "OKN",
//       "Airport Name": "Okondja Airport",
//       "Altitude (Ft)": 1325,
//       "Country": "Gabon",
//       "Latitude": 13.673100471496582,
//       "Location": "Okondja",
//       "Longitude": -0.6652140021324158,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Libreville",
//       "Type": "airport"
//     },
//     {
//       "ID": 965,
//       "Airport Code [ICAO]": "FOGR",
//       "Airport Code iATA,FAA": "LBQ",
//       "Airport Name": "Lambarene Airport",
//       "Altitude (Ft)": 82,
//       "Country": "Gabon",
//       "Latitude": 10.245699882507324,
//       "Location": "Lambarene",
//       "Longitude": -0.7043889760971069,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Libreville",
//       "Type": "airport"
//     },
//     {
//       "ID": 966,
//       "Airport Code [ICAO]": "FOOB",
//       "Airport Code iATA,FAA": "BMM",
//       "Airport Name": "Bitam Airport",
//       "Altitude (Ft)": 1969,
//       "Country": "Gabon",
//       "Latitude": 11.493200302124023,
//       "Location": "Bitam",
//       "Longitude": 2.0756399631500244,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Libreville",
//       "Type": "airport"
//     },
//     {
//       "ID": 967,
//       "Airport Code [ICAO]": "FOOG",
//       "Airport Code iATA,FAA": "POG",
//       "Airport Name": "Port Gentil Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Gabon",
//       "Latitude": 8.754380226135254,
//       "Location": "Port Gentil",
//       "Longitude": -0.7117390036582947,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Libreville",
//       "Type": "airport"
//     },
//     {
//       "ID": 968,
//       "Airport Code [ICAO]": "FOOH",
//       "Airport Code iATA,FAA": "OMB",
//       "Airport Name": "Omboue Hopital Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Gabon",
//       "Latitude": 9.262689590454102,
//       "Location": "Omboue Hospial",
//       "Longitude": -1.5747300386428833,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Libreville",
//       "Type": "airport"
//     },
//     {
//       "ID": 969,
//       "Airport Code [ICAO]": "FOOK",
//       "Airport Code iATA,FAA": "MKU",
//       "Airport Name": "Makokou Airport",
//       "Altitude (Ft)": 1726,
//       "Country": "Gabon",
//       "Latitude": 12.890899658203125,
//       "Location": "Makokou",
//       "Longitude": 0.5792109966278076,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Libreville",
//       "Type": "airport"
//     },
//     {
//       "ID": 970,
//       "Airport Code [ICAO]": "FOOL",
//       "Airport Code iATA,FAA": "LBV",
//       "Airport Name": "Libreville Leon M'ba International Airport",
//       "Altitude (Ft)": 39,
//       "Country": "Gabon",
//       "Latitude": 9.412280082699999,
//       "Location": "Libreville",
//       "Longitude": 0.458600014448,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Libreville",
//       "Type": "airport"
//     },
//     {
//       "ID": 971,
//       "Airport Code [ICAO]": "FOON",
//       "Airport Code iATA,FAA": "MVB",
//       "Airport Name": "M'Vengue El Hadj Omar Bongo Ondimba International Airport",
//       "Altitude (Ft)": 1450,
//       "Country": "Gabon",
//       "Latitude": 13.437999725341797,
//       "Location": "Franceville",
//       "Longitude": -1.6561599969863892,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Libreville",
//       "Type": "airport"
//     },
//     {
//       "ID": 972,
//       "Airport Code [ICAO]": "FPPR",
//       "Airport Code iATA,FAA": "PCP",
//       "Airport Name": "Principe Airport",
//       "Altitude (Ft)": 591,
//       "Country": "Sao Tome and Principe",
//       "Latitude": 7.411739826202393,
//       "Location": "Principe",
//       "Longitude": 1.6629400253295898,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Sao_Tome",
//       "Type": "airport"
//     },
//     {
//       "ID": 973,
//       "Airport Code [ICAO]": "FPST",
//       "Airport Code iATA,FAA": "TMS",
//       "Airport Name": "São Tomé International Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Sao Tome and Principe",
//       "Latitude": 6.7121500968933105,
//       "Location": "Sao Tome",
//       "Longitude": 0.3781749904155731,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Sao_Tome",
//       "Type": "airport"
//     },
//     {
//       "ID": 974,
//       "Airport Code [ICAO]": "FQBR",
//       "Airport Code iATA,FAA": "BEW",
//       "Airport Name": "Beira Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Mozambique",
//       "Latitude": 34.90760040283203,
//       "Location": "Beira",
//       "Longitude": -19.79640007019043,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Maputo",
//       "Type": "airport"
//     },
//     {
//       "ID": 976,
//       "Airport Code [ICAO]": "FQIN",
//       "Airport Code iATA,FAA": "INH",
//       "Airport Name": "Inhambane Airport",
//       "Altitude (Ft)": 30,
//       "Country": "Mozambique",
//       "Latitude": 35.40850067138672,
//       "Location": "Inhambane",
//       "Longitude": -23.876399993896484,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Maputo",
//       "Type": "airport"
//     },
//     {
//       "ID": 977,
//       "Airport Code [ICAO]": "FQLC",
//       "Airport Code iATA,FAA": "VXC",
//       "Airport Name": "Lichinga Airport",
//       "Altitude (Ft)": 4505,
//       "Country": "Mozambique",
//       "Latitude": 35.2663,
//       "Location": "Lichinga",
//       "Longitude": -13.274,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Maputo",
//       "Type": "airport"
//     },
//     {
//       "ID": 978,
//       "Airport Code [ICAO]": "FQLU",
//       "Airport Code iATA,FAA": "LFB",
//       "Airport Name": "Lumbo Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Mozambique",
//       "Latitude": 40.671699523899996,
//       "Location": "Lumbo",
//       "Longitude": -15.0331001282,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Maputo",
//       "Type": "airport"
//     },
//     {
//       "ID": 979,
//       "Airport Code [ICAO]": "FQMA",
//       "Airport Code iATA,FAA": "MPM",
//       "Airport Name": "Maputo Airport",
//       "Altitude (Ft)": 145,
//       "Country": "Mozambique",
//       "Latitude": 32.572601,
//       "Location": "Maputo",
//       "Longitude": -25.920799,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Maputo",
//       "Type": "airport"
//     },
//     {
//       "ID": 980,
//       "Airport Code [ICAO]": "FQMD",
//       "Airport Code iATA,FAA": "MUD",
//       "Airport Name": "Mueda Airport",
//       "Altitude (Ft)": 2789,
//       "Country": "Mozambique",
//       "Latitude": 39.5630989074707,
//       "Location": "Mueda",
//       "Longitude": -11.672900199890137,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Maputo",
//       "Type": "airport"
//     },
//     {
//       "ID": 981,
//       "Airport Code [ICAO]": "FQMP",
//       "Airport Code iATA,FAA": "MZB",
//       "Airport Name": "Mocímboa da Praia Airport",
//       "Altitude (Ft)": 89,
//       "Country": "Mozambique",
//       "Latitude": 40.35490036010742,
//       "Location": "Mocimboa Da Praia",
//       "Longitude": -11.361800193786621,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Maputo",
//       "Type": "airport"
//     },
//     {
//       "ID": 982,
//       "Airport Code [ICAO]": "FQMR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Marrupa Airport",
//       "Altitude (Ft)": 2480,
//       "Country": "Mozambique",
//       "Latitude": 37.552101135253906,
//       "Location": "Marrupa",
//       "Longitude": -13.225099563598633,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Maputo",
//       "Type": "airport"
//     },
//     {
//       "ID": 983,
//       "Airport Code [ICAO]": "FQNC",
//       "Airport Code iATA,FAA": "MNC",
//       "Airport Name": "Nacala Airport",
//       "Altitude (Ft)": 410,
//       "Country": "Mozambique",
//       "Latitude": 40.71220016479492,
//       "Location": "Nacala",
//       "Longitude": -14.488200187683105,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Maputo",
//       "Type": "airport"
//     },
//     {
//       "ID": 984,
//       "Airport Code [ICAO]": "FQNP",
//       "Airport Code iATA,FAA": "APL",
//       "Airport Name": "Nampula Airport",
//       "Altitude (Ft)": 1444,
//       "Country": "Mozambique",
//       "Latitude": 39.28179931640625,
//       "Location": "Nampula",
//       "Longitude": -15.105600357055664,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Maputo",
//       "Type": "airport"
//     },
//     {
//       "ID": 985,
//       "Airport Code [ICAO]": "FQPB",
//       "Airport Code iATA,FAA": "POL",
//       "Airport Name": "Pemba Airport",
//       "Altitude (Ft)": 331,
//       "Country": "Mozambique",
//       "Latitude": 40.52401351928711,
//       "Location": "Pemba",
//       "Longitude": -12.991762161254883,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Maputo",
//       "Type": "airport"
//     },
//     {
//       "ID": 986,
//       "Airport Code [ICAO]": "FQQL",
//       "Airport Code iATA,FAA": "UEL",
//       "Airport Name": "Quelimane Airport",
//       "Altitude (Ft)": 36,
//       "Country": "Mozambique",
//       "Latitude": 36.86909866333008,
//       "Location": "Quelimane",
//       "Longitude": -17.855499267578125,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Maputo",
//       "Type": "airport"
//     },
//     {
//       "ID": 987,
//       "Airport Code [ICAO]": "FQSG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Songo Airport",
//       "Altitude (Ft)": 2904,
//       "Country": "Mozambique",
//       "Latitude": 32.77320098876953,
//       "Location": "Songo",
//       "Longitude": -15.602700233459473,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Maputo",
//       "Type": "airport"
//     },
//     {
//       "ID": 988,
//       "Airport Code [ICAO]": "FQTT",
//       "Airport Code iATA,FAA": "TET",
//       "Airport Name": "Chingozi Airport",
//       "Altitude (Ft)": 525,
//       "Country": "Mozambique",
//       "Latitude": 33.640201568603516,
//       "Location": "Tete",
//       "Longitude": -16.104799270629883,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Maputo",
//       "Type": "airport"
//     },
//     {
//       "ID": 989,
//       "Airport Code [ICAO]": "FQUG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ulongwe Airport",
//       "Altitude (Ft)": 4265,
//       "Country": "Mozambique",
//       "Latitude": 34.35240173339844,
//       "Location": "Ulongwe",
//       "Longitude": -14.70460033416748,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Maputo",
//       "Type": "airport"
//     },
//     {
//       "ID": 990,
//       "Airport Code [ICAO]": "FQVL",
//       "Airport Code iATA,FAA": "VNX",
//       "Airport Name": "Vilankulo Airport",
//       "Altitude (Ft)": 46,
//       "Country": "Mozambique",
//       "Latitude": 35.31330108642578,
//       "Location": "Vilankulu",
//       "Longitude": -22.018400192260742,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Maputo",
//       "Type": "airport"
//     },
//     {
//       "ID": 991,
//       "Airport Code [ICAO]": "FSAL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Alphonse Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Seychelles",
//       "Latitude": 52.7262,
//       "Location": "Alphonse",
//       "Longitude": -7.00478,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Indian/Mahe",
//       "Type": "airport"
//     },
//     {
//       "ID": 992,
//       "Airport Code [ICAO]": "FSDR",
//       "Airport Code iATA,FAA": "DES",
//       "Airport Name": "Desroches Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Seychelles",
//       "Latitude": 53.6558,
//       "Location": "Desroches",
//       "Longitude": -5.6967,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Indian/Mahe",
//       "Type": "airport"
//     },
//     {
//       "ID": 993,
//       "Airport Code [ICAO]": "FSFA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Farquhar Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Seychelles",
//       "Latitude": 51.176102,
//       "Location": "Farquhar",
//       "Longitude": -10.1096,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Indian/Mahe",
//       "Type": "airport"
//     },
//     {
//       "ID": 994,
//       "Airport Code [ICAO]": "FSIA",
//       "Airport Code iATA,FAA": "SEZ",
//       "Airport Name": "Seychelles International Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Seychelles",
//       "Latitude": 55.521801,
//       "Location": "Mahe",
//       "Longitude": -4.67434,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Indian/Mahe",
//       "Type": "airport"
//     },
//     {
//       "ID": 995,
//       "Airport Code [ICAO]": "FSPP",
//       "Airport Code iATA,FAA": "PRI",
//       "Airport Name": "Praslin Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Seychelles",
//       "Latitude": 55.69139862060547,
//       "Location": "Praslin",
//       "Longitude": -4.3192901611328125,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Indian/Mahe",
//       "Type": "airport"
//     },
//     {
//       "ID": 996,
//       "Airport Code [ICAO]": "FSSC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Coetivy Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Seychelles",
//       "Latitude": 56.278198,
//       "Location": "Coetivy",
//       "Longitude": -7.13457,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Indian/Mahe",
//       "Type": "airport"
//     },
//     {
//       "ID": 997,
//       "Airport Code [ICAO]": "FTTC",
//       "Airport Code iATA,FAA": "AEH",
//       "Airport Name": "Abeche Airport",
//       "Altitude (Ft)": 1788,
//       "Country": "Chad",
//       "Latitude": 20.84429931640625,
//       "Location": "Abeche",
//       "Longitude": 13.847000122070312,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Ndjamena",
//       "Type": "airport"
//     },
//     {
//       "ID": 998,
//       "Airport Code [ICAO]": "FTTD",
//       "Airport Code iATA,FAA": "MQQ",
//       "Airport Name": "Moundou Airport",
//       "Altitude (Ft)": 1407,
//       "Country": "Chad",
//       "Latitude": 16.071399688720703,
//       "Location": "Moundou",
//       "Longitude": 8.624409675598145,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Ndjamena",
//       "Type": "airport"
//     },
//     {
//       "ID": 999,
//       "Airport Code [ICAO]": "FTTJ",
//       "Airport Code iATA,FAA": "NDJ",
//       "Airport Name": "N'Djamena International Airport",
//       "Altitude (Ft)": 968,
//       "Country": "Chad",
//       "Latitude": 15.034,
//       "Location": "N'djamena",
//       "Longitude": 12.1337,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Ndjamena",
//       "Type": "airport"
//     },
//     {
//       "ID": 1000,
//       "Airport Code [ICAO]": "FTTY",
//       "Airport Code iATA,FAA": "FYT",
//       "Airport Name": "Faya Largeau Airport",
//       "Altitude (Ft)": 771,
//       "Country": "Chad",
//       "Latitude": 19.111099243164062,
//       "Location": "Faya-largeau",
//       "Longitude": 17.91710090637207,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Ndjamena",
//       "Type": "airport"
//     },
//     {
//       "ID": 1001,
//       "Airport Code [ICAO]": "FVBU",
//       "Airport Code iATA,FAA": "BUQ",
//       "Airport Name": "Joshua Mqabuko Nkomo International Airport",
//       "Altitude (Ft)": 4359,
//       "Country": "Zimbabwe",
//       "Latitude": 28.617901,
//       "Location": "Bulawayo",
//       "Longitude": -20.017401,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Harare",
//       "Type": "airport"
//     },
//     {
//       "ID": 1002,
//       "Airport Code [ICAO]": "FVCP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Charles Prince Airport",
//       "Altitude (Ft)": 4845,
//       "Country": "Zimbabwe",
//       "Latitude": 30.924699783325195,
//       "Location": "Harare",
//       "Longitude": -17.75160026550293,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Harare",
//       "Type": "airport"
//     },
//     {
//       "ID": 1003,
//       "Airport Code [ICAO]": "FVCZ",
//       "Airport Code iATA,FAA": "BFO",
//       "Airport Name": "Buffalo Range Airport",
//       "Altitude (Ft)": 1421,
//       "Country": "Zimbabwe",
//       "Latitude": 31.5786,
//       "Location": "Chiredzi",
//       "Longitude": -21.008101,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Harare",
//       "Type": "airport"
//     },
//     {
//       "ID": 1004,
//       "Airport Code [ICAO]": "FVFA",
//       "Airport Code iATA,FAA": "VFA",
//       "Airport Name": "Victoria Falls International Airport",
//       "Altitude (Ft)": 3490,
//       "Country": "Zimbabwe",
//       "Latitude": 25.839000701904297,
//       "Location": "Victoria Falls",
//       "Longitude": -18.09589958190918,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Harare",
//       "Type": "airport"
//     },
//     {
//       "ID": 1005,
//       "Airport Code [ICAO]": "FVHA",
//       "Airport Code iATA,FAA": "HRE",
//       "Airport Name": "Robert Gabriel Mugabe International Airport",
//       "Altitude (Ft)": 4887,
//       "Country": "Zimbabwe",
//       "Latitude": 31.0928,
//       "Location": "Harare",
//       "Longitude": -17.931801,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Harare",
//       "Type": "airport"
//     },
//     {
//       "ID": 1006,
//       "Airport Code [ICAO]": "FVKB",
//       "Airport Code iATA,FAA": "KAB",
//       "Airport Name": "Kariba International Airport",
//       "Altitude (Ft)": 1706,
//       "Country": "Zimbabwe",
//       "Latitude": 28.885000228881836,
//       "Location": "Kariba",
//       "Longitude": -16.519800186157227,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Harare",
//       "Type": "airport"
//     },
//     {
//       "ID": 1007,
//       "Airport Code [ICAO]": "FVMT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mutoko Airport",
//       "Altitude (Ft)": 3950,
//       "Country": "Zimbabwe",
//       "Latitude": 32.18450164794922,
//       "Location": "Mutoko",
//       "Longitude": -17.431900024414062,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Harare",
//       "Type": "airport"
//     },
//     {
//       "ID": 1008,
//       "Airport Code [ICAO]": "FVMU",
//       "Airport Code iATA,FAA": "UTA",
//       "Airport Name": "Mutare Airport",
//       "Altitude (Ft)": 3410,
//       "Country": "Zimbabwe",
//       "Latitude": 32.627201080322,
//       "Location": "Mutare",
//       "Longitude": -18.997499465942,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Harare",
//       "Type": "airport"
//     },
//     {
//       "ID": 1009,
//       "Airport Code [ICAO]": "FVMV",
//       "Airport Code iATA,FAA": "MVZ",
//       "Airport Name": "Masvingo International Airport",
//       "Altitude (Ft)": 3595,
//       "Country": "Zimbabwe",
//       "Latitude": 30.859100341796875,
//       "Location": "Masvingo",
//       "Longitude": -20.055299758911133,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Harare",
//       "Type": "airport"
//     },
//     {
//       "ID": 1010,
//       "Airport Code [ICAO]": "FVSH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Zvishavane Airport",
//       "Altitude (Ft)": 3012,
//       "Country": "Zimbabwe",
//       "Latitude": 30.088199615478516,
//       "Location": "Zvishavane",
//       "Longitude": -20.289499282836914,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Harare",
//       "Type": "airport"
//     },
//     {
//       "ID": 1011,
//       "Airport Code [ICAO]": "FVTL",
//       "Airport Code iATA,FAA": "GWE",
//       "Airport Name": "Thornhill Air Base",
//       "Altitude (Ft)": 4680,
//       "Country": "Zimbabwe",
//       "Latitude": 29.861900329589844,
//       "Location": "Gwert",
//       "Longitude": -19.436399459838867,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Harare",
//       "Type": "airport"
//     },
//     {
//       "ID": 1012,
//       "Airport Code [ICAO]": "FVWN",
//       "Airport Code iATA,FAA": "HWN",
//       "Airport Name": "Hwange National Park Airport",
//       "Altitude (Ft)": 3543,
//       "Country": "Zimbabwe",
//       "Latitude": 27.020999908447266,
//       "Location": "Hwange National Park",
//       "Longitude": -18.629899978637695,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Harare",
//       "Type": "airport"
//     },
//     {
//       "ID": 1013,
//       "Airport Code [ICAO]": "FWCL",
//       "Airport Code iATA,FAA": "BLZ",
//       "Airport Name": "Chileka International Airport",
//       "Altitude (Ft)": 2555,
//       "Country": "Malawi",
//       "Latitude": 34.9739990234375,
//       "Location": "Blantyre",
//       "Longitude": -15.679100036621094,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Blantyre",
//       "Type": "airport"
//     },
//     {
//       "ID": 1014,
//       "Airport Code [ICAO]": "FWKA",
//       "Airport Code iATA,FAA": "KGJ",
//       "Airport Name": "Karonga Airport",
//       "Altitude (Ft)": 1765,
//       "Country": "Malawi",
//       "Latitude": 33.893001556396484,
//       "Location": "Karonga",
//       "Longitude": -9.953570365905762,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Blantyre",
//       "Type": "airport"
//     },
//     {
//       "ID": 1015,
//       "Airport Code [ICAO]": "FWKG",
//       "Airport Code iATA,FAA": "KBQ",
//       "Airport Name": "Kasungu Airport",
//       "Altitude (Ft)": 3470,
//       "Country": "Malawi",
//       "Latitude": 33.46860122680664,
//       "Location": "Kasungu",
//       "Longitude": -13.014599800109863,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Blantyre",
//       "Type": "airport"
//     },
//     {
//       "ID": 1016,
//       "Airport Code [ICAO]": "FWKI",
//       "Airport Code iATA,FAA": "LLW",
//       "Airport Name": "Lilongwe International Airport",
//       "Altitude (Ft)": 4035,
//       "Country": "Malawi",
//       "Latitude": 33.78099823,
//       "Location": "Lilongwe",
//       "Longitude": -13.7894001007,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Blantyre",
//       "Type": "airport"
//     },
//     {
//       "ID": 1017,
//       "Airport Code [ICAO]": "FWUU",
//       "Airport Code iATA,FAA": "ZZU",
//       "Airport Name": "Mzuzu Airport",
//       "Altitude (Ft)": 4115,
//       "Country": "Malawi",
//       "Latitude": 34.01179885864258,
//       "Location": "Mzuzu",
//       "Longitude": -11.444700241088867,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Blantyre",
//       "Type": "airport"
//     },
//     {
//       "ID": 1018,
//       "Airport Code [ICAO]": "FXMM",
//       "Airport Code iATA,FAA": "MSU",
//       "Airport Name": "Moshoeshoe I International Airport",
//       "Altitude (Ft)": 5348,
//       "Country": "Lesotho",
//       "Latitude": 27.552499771118164,
//       "Location": "Maseru",
//       "Longitude": -29.462299346923828,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Maseru",
//       "Type": "airport"
//     },
//     {
//       "ID": 1019,
//       "Airport Code [ICAO]": "FXMU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mejametalana Airbase",
//       "Altitude (Ft)": 5105,
//       "Country": "Lesotho",
//       "Latitude": 27.50349998474121,
//       "Location": "Maseru",
//       "Longitude": -29.304100036621094,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Maseru",
//       "Type": "airport"
//     },
//     {
//       "ID": 1020,
//       "Airport Code [ICAO]": "FZAA",
//       "Airport Code iATA,FAA": "FIH",
//       "Airport Name": "Ndjili International Airport",
//       "Altitude (Ft)": 1027,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 15.4446,
//       "Location": "Kinshasa",
//       "Longitude": -4.38575,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Kinshasa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1021,
//       "Airport Code [ICAO]": "FZAB",
//       "Airport Code iATA,FAA": "NLO",
//       "Airport Name": "Ndolo Airport",
//       "Altitude (Ft)": 915,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 15.327500343323,
//       "Location": "Kinshasa",
//       "Longitude": -4.32666015625,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Kinshasa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1022,
//       "Airport Code [ICAO]": "FZAG",
//       "Airport Code iATA,FAA": "MNB",
//       "Airport Name": "Muanda Airport",
//       "Altitude (Ft)": 89,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 12.351799964904785,
//       "Location": "Muanda",
//       "Longitude": -5.9308600425720215,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Kinshasa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1023,
//       "Airport Code [ICAO]": "FZAI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kitona Base Airport",
//       "Altitude (Ft)": 394,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 12.447699546813965,
//       "Location": "Kitona Base",
//       "Longitude": -5.918059825897217,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Kinshasa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1024,
//       "Airport Code [ICAO]": "FZBO",
//       "Airport Code iATA,FAA": "FDU",
//       "Airport Name": "Bandundu Airport",
//       "Altitude (Ft)": 1063,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 17.38170051574707,
//       "Location": "Bandoundu",
//       "Longitude": -3.3113200664520264,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Kinshasa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1025,
//       "Airport Code [ICAO]": "FZCA",
//       "Airport Code iATA,FAA": "KKW",
//       "Airport Name": "Kikwit Airport",
//       "Altitude (Ft)": 1572,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 18.785600662231445,
//       "Location": "Kikwit",
//       "Longitude": -5.035769939422607,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Kinshasa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1026,
//       "Airport Code [ICAO]": "FZEA",
//       "Airport Code iATA,FAA": "MDK",
//       "Airport Name": "Mbandaka Airport",
//       "Altitude (Ft)": 1040,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 18.2887001038,
//       "Location": "Mbandaka",
//       "Longitude": 0.0226000007242,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Kinshasa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1027,
//       "Airport Code [ICAO]": "FZFD",
//       "Airport Code iATA,FAA": "BDT",
//       "Airport Name": "Gbadolite Airport",
//       "Altitude (Ft)": 1509,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 20.975299835205078,
//       "Location": "Gbadolite",
//       "Longitude": 4.253210067749023,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Kinshasa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1028,
//       "Airport Code [ICAO]": "FZFK",
//       "Airport Code iATA,FAA": "GMA",
//       "Airport Name": "Gemena Airport",
//       "Altitude (Ft)": 1378,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 19.771299362199997,
//       "Location": "Gemena",
//       "Longitude": 3.2353699207299997,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Kinshasa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1029,
//       "Airport Code [ICAO]": "FZFP",
//       "Airport Code iATA,FAA": "KLI",
//       "Airport Name": "Kotakoli Airport",
//       "Altitude (Ft)": 1801,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 21.65089988708496,
//       "Location": "Kotakoli",
//       "Longitude": 4.157639980316162,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Kinshasa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1030,
//       "Airport Code [ICAO]": "FZGA",
//       "Airport Code iATA,FAA": "LIQ",
//       "Airport Name": "Lisala Airport",
//       "Altitude (Ft)": 1509,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 21.496901,
//       "Location": "Lisala",
//       "Longitude": 2.17066,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Kinshasa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1031,
//       "Airport Code [ICAO]": "FZIC",
//       "Airport Code iATA,FAA": "FKI",
//       "Airport Name": "Bangoka International Airport",
//       "Altitude (Ft)": 1417,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 25.3379993439,
//       "Location": "Kisangani",
//       "Longitude": 0.481638997793,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Lubumbashi",
//       "Type": "airport"
//     },
//     {
//       "ID": 1032,
//       "Airport Code [ICAO]": "FZJH",
//       "Airport Code iATA,FAA": "IRP",
//       "Airport Name": "Matari Airport",
//       "Altitude (Ft)": 2438,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 27.588300704956055,
//       "Location": "Isiro",
//       "Longitude": 2.8276100158691406,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Lubumbashi",
//       "Type": "airport"
//     },
//     {
//       "ID": 1033,
//       "Airport Code [ICAO]": "FZKA",
//       "Airport Code iATA,FAA": "BUX",
//       "Airport Name": "Bunia Airport",
//       "Altitude (Ft)": 4045,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 30.220800399780273,
//       "Location": "Bunia",
//       "Longitude": 1.5657199621200562,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Lubumbashi",
//       "Type": "airport"
//     },
//     {
//       "ID": 1034,
//       "Airport Code [ICAO]": "FZKJ",
//       "Airport Code iATA,FAA": "BZU",
//       "Airport Name": "Buta Zega Airport",
//       "Altitude (Ft)": 1378,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 24.793699264526367,
//       "Location": "Buta Zega",
//       "Longitude": 2.818350076675415,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Lubumbashi",
//       "Type": "airport"
//     },
//     {
//       "ID": 1035,
//       "Airport Code [ICAO]": "FZMA",
//       "Airport Code iATA,FAA": "BKY",
//       "Airport Name": "Bukavu Kavumu Airport",
//       "Altitude (Ft)": 5643,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 28.808799743652344,
//       "Location": "Bukavu/kavumu",
//       "Longitude": -2.3089799880981445,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Lubumbashi",
//       "Type": "airport"
//     },
//     {
//       "ID": 1036,
//       "Airport Code [ICAO]": "FZNA",
//       "Airport Code iATA,FAA": "GOM",
//       "Airport Name": "Goma International Airport",
//       "Altitude (Ft)": 5089,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 29.238500595092773,
//       "Location": "Goma",
//       "Longitude": -1.6708099842071533,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Kigali",
//       "Type": "airport"
//     },
//     {
//       "ID": 1037,
//       "Airport Code [ICAO]": "FZOA",
//       "Airport Code iATA,FAA": "KND",
//       "Airport Name": "Kindu Airport",
//       "Altitude (Ft)": 1630,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 25.915399551399997,
//       "Location": "Kindu",
//       "Longitude": -2.91917991638,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Lubumbashi",
//       "Type": "airport"
//     },
//     {
//       "ID": 1038,
//       "Airport Code [ICAO]": "FZQA",
//       "Airport Code iATA,FAA": "FBM",
//       "Airport Name": "Lubumbashi International Airport",
//       "Altitude (Ft)": 4295,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 27.5308990479,
//       "Location": "Lubumashi",
//       "Longitude": -11.5913000107,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Lubumbashi",
//       "Type": "airport"
//     },
//     {
//       "ID": 1039,
//       "Airport Code [ICAO]": "FZQM",
//       "Airport Code iATA,FAA": "KWZ",
//       "Airport Name": "Kolwezi Airport",
//       "Altitude (Ft)": 5007,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 25.505699157714844,
//       "Location": "Kolwezi",
//       "Longitude": -10.765899658203125,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Lubumbashi",
//       "Type": "airport"
//     },
//     {
//       "ID": 1040,
//       "Airport Code [ICAO]": "FZRF",
//       "Airport Code iATA,FAA": "FMI",
//       "Airport Name": "Kalemie Airport",
//       "Altitude (Ft)": 2569,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 29.25,
//       "Location": "Kalemie",
//       "Longitude": -5.8755598068237305,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Lubumbashi",
//       "Type": "airport"
//     },
//     {
//       "ID": 1041,
//       "Airport Code [ICAO]": "FZSA",
//       "Airport Code iATA,FAA": "KMN",
//       "Airport Name": "Kamina Base Airport",
//       "Altitude (Ft)": 3543,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 25.252899169921875,
//       "Location": "Kamina Base",
//       "Longitude": -8.642020225524902,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Lubumbashi",
//       "Type": "airport"
//     },
//     {
//       "ID": 1042,
//       "Airport Code [ICAO]": "FZUA",
//       "Airport Code iATA,FAA": "KGA",
//       "Airport Name": "Kananga Airport",
//       "Altitude (Ft)": 2139,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 22.4692001343,
//       "Location": "Kananga",
//       "Longitude": -5.90005016327,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Lubumbashi",
//       "Type": "airport"
//     },
//     {
//       "ID": 1043,
//       "Airport Code [ICAO]": "FZWA",
//       "Airport Code iATA,FAA": "MJM",
//       "Airport Name": "Mbuji Mayi Airport",
//       "Altitude (Ft)": 2221,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 23.569000244099996,
//       "Location": "Mbuji-mayi",
//       "Longitude": -6.121240139010001,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Lubumbashi",
//       "Type": "airport"
//     },
//     {
//       "ID": 1044,
//       "Airport Code [ICAO]": "GABS",
//       "Airport Code iATA,FAA": "BKO",
//       "Airport Name": "Modibo Keita International Airport",
//       "Altitude (Ft)": 1247,
//       "Country": "Mali",
//       "Latitude": -7.94994,
//       "Location": "Bamako",
//       "Longitude": 12.5335,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Bamako",
//       "Type": "airport"
//     },
//     {
//       "ID": 1045,
//       "Airport Code [ICAO]": "GAGO",
//       "Airport Code iATA,FAA": "GAQ",
//       "Airport Name": "Gao Airport",
//       "Altitude (Ft)": 870,
//       "Country": "Mali",
//       "Latitude": -0.005456000100821257,
//       "Location": "Gao",
//       "Longitude": 16.24839973449707,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Bamako",
//       "Type": "airport"
//     },
//     {
//       "ID": 1046,
//       "Airport Code [ICAO]": "GAKY",
//       "Airport Code iATA,FAA": "KYS",
//       "Airport Name": "Kayes Dag Dag Airport",
//       "Altitude (Ft)": 164,
//       "Country": "Mali",
//       "Latitude": -11.404399871826172,
//       "Location": "Kayes",
//       "Longitude": 14.481200218200684,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Bamako",
//       "Type": "airport"
//     },
//     {
//       "ID": 1047,
//       "Airport Code [ICAO]": "GAMB",
//       "Airport Code iATA,FAA": "MZI",
//       "Airport Name": "Mopti Airport",
//       "Altitude (Ft)": 906,
//       "Country": "Mali",
//       "Latitude": -4.0795598030099995,
//       "Location": "Mopti",
//       "Longitude": 14.5128002167,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Bamako",
//       "Type": "airport"
//     },
//     {
//       "ID": 1048,
//       "Airport Code [ICAO]": "GATB",
//       "Airport Code iATA,FAA": "TOM",
//       "Airport Name": "Timbuktu Airport",
//       "Altitude (Ft)": 863,
//       "Country": "Mali",
//       "Latitude": -3.007580041885376,
//       "Location": "Tombouctou",
//       "Longitude": 16.730499267578125,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Bamako",
//       "Type": "airport"
//     },
//     {
//       "ID": 1049,
//       "Airport Code [ICAO]": "GATS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tessalit Airport",
//       "Altitude (Ft)": 1621,
//       "Country": "Mali",
//       "Latitude": 0.9773079752922058,
//       "Location": "Tessalit",
//       "Longitude": 20.243000030517578,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Bamako",
//       "Type": "airport"
//     },
//     {
//       "ID": 1050,
//       "Airport Code [ICAO]": "GBYD",
//       "Airport Code iATA,FAA": "BJL",
//       "Airport Name": "Banjul International Airport",
//       "Altitude (Ft)": 95,
//       "Country": "Gambia",
//       "Latitude": -16.65220069885254,
//       "Location": "Banjul",
//       "Longitude": 13.338000297546387,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Banjul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1051,
//       "Airport Code [ICAO]": "GCFV",
//       "Airport Code iATA,FAA": "FUE",
//       "Airport Name": "Fuerteventura Airport",
//       "Altitude (Ft)": 85,
//       "Country": "Spain",
//       "Latitude": -13.863800048828125,
//       "Location": "Fuerteventura",
//       "Longitude": 28.452699661254883,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Atlantic/Canary",
//       "Type": "airport"
//     },
//     {
//       "ID": 1052,
//       "Airport Code [ICAO]": "GCHI",
//       "Airport Code iATA,FAA": "VDE",
//       "Airport Name": "Hierro Airport",
//       "Altitude (Ft)": 103,
//       "Country": "Spain",
//       "Latitude": -17.887100219726562,
//       "Location": "Hierro",
//       "Longitude": 27.814800262451172,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Atlantic/Canary",
//       "Type": "airport"
//     },
//     {
//       "ID": 1053,
//       "Airport Code [ICAO]": "GCLA",
//       "Airport Code iATA,FAA": "SPC",
//       "Airport Name": "La Palma Airport",
//       "Altitude (Ft)": 107,
//       "Country": "Spain",
//       "Latitude": -17.7556,
//       "Location": "Santa Cruz De La Palma",
//       "Longitude": 28.626499,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Atlantic/Canary",
//       "Type": "airport"
//     },
//     {
//       "ID": 1054,
//       "Airport Code [ICAO]": "GCLP",
//       "Airport Code iATA,FAA": "LPA",
//       "Airport Name": "Gran Canaria Airport",
//       "Altitude (Ft)": 78,
//       "Country": "Spain",
//       "Latitude": -15.38659954071045,
//       "Location": "Gran Canaria",
//       "Longitude": 27.931900024414062,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Atlantic/Canary",
//       "Type": "airport"
//     },
//     {
//       "ID": 1055,
//       "Airport Code [ICAO]": "GCRR",
//       "Airport Code iATA,FAA": "ACE",
//       "Airport Name": "Lanzarote Airport",
//       "Altitude (Ft)": 46,
//       "Country": "Spain",
//       "Latitude": -13.6052,
//       "Location": "Arrecife",
//       "Longitude": 28.945499,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Atlantic/Canary",
//       "Type": "airport"
//     },
//     {
//       "ID": 1056,
//       "Airport Code [ICAO]": "GCTS",
//       "Airport Code iATA,FAA": "TFS",
//       "Airport Name": "Tenerife South Airport",
//       "Altitude (Ft)": 209,
//       "Country": "Spain",
//       "Latitude": -16.5725002289,
//       "Location": "Tenerife",
//       "Longitude": 28.044500351,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Atlantic/Canary",
//       "Type": "airport"
//     },
//     {
//       "ID": 1057,
//       "Airport Code [ICAO]": "GCXO",
//       "Airport Code iATA,FAA": "TFN",
//       "Airport Name": "Tenerife Norte Airport",
//       "Altitude (Ft)": 2076,
//       "Country": "Spain",
//       "Latitude": -16.3414993286,
//       "Location": "Tenerife",
//       "Longitude": 28.4827003479,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Atlantic/Canary",
//       "Type": "airport"
//     },
//     {
//       "ID": 1058,
//       "Airport Code [ICAO]": "GEML",
//       "Airport Code iATA,FAA": "MLN",
//       "Airport Name": "Melilla Airport",
//       "Altitude (Ft)": 156,
//       "Country": "Spain",
//       "Latitude": -2.9562599659,
//       "Location": "Melilla",
//       "Longitude": 35.279800415,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1059,
//       "Airport Code [ICAO]": "GFLL",
//       "Airport Code iATA,FAA": "FNA",
//       "Airport Name": "Lungi International Airport",
//       "Altitude (Ft)": 84,
//       "Country": "Sierra Leone",
//       "Latitude": -13.1955,
//       "Location": "Freetown",
//       "Longitude": 8.61644,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Freetown",
//       "Type": "airport"
//     },
//     {
//       "ID": 1060,
//       "Airport Code [ICAO]": "GGCF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cufar Airport",
//       "Altitude (Ft)": 65,
//       "Country": "Guinea-Bissau",
//       "Latitude": -15.180500030517578,
//       "Location": "Cufar",
//       "Longitude": 11.2878999710083,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Bissau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1062,
//       "Airport Code [ICAO]": "GLMR",
//       "Airport Code iATA,FAA": "MLW",
//       "Airport Name": "Spriggs Payne Airport",
//       "Altitude (Ft)": 25,
//       "Country": "Liberia",
//       "Latitude": -10.758700370788574,
//       "Location": "Monrovia",
//       "Longitude": 6.289060115814209,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Monrovia",
//       "Type": "airport"
//     },
//     {
//       "ID": 1063,
//       "Airport Code [ICAO]": "GLRB",
//       "Airport Code iATA,FAA": "ROB",
//       "Airport Name": "Roberts International Airport",
//       "Altitude (Ft)": 31,
//       "Country": "Liberia",
//       "Latitude": -10.3623,
//       "Location": "Monrovia",
//       "Longitude": 6.23379,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Monrovia",
//       "Type": "airport"
//     },
//     {
//       "ID": 1064,
//       "Airport Code [ICAO]": "GMAD",
//       "Airport Code iATA,FAA": "AGA",
//       "Airport Name": "Al Massira Airport",
//       "Altitude (Ft)": 250,
//       "Country": "Morocco",
//       "Latitude": -9.413069725036621,
//       "Location": "Agadir",
//       "Longitude": 30.325000762939453,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Casablanca",
//       "Type": "airport"
//     },
//     {
//       "ID": 1065,
//       "Airport Code [ICAO]": "GMAT",
//       "Airport Code iATA,FAA": "TTA",
//       "Airport Name": "Tan Tan Airport",
//       "Altitude (Ft)": 653,
//       "Country": "Morocco",
//       "Latitude": -11.161299705505371,
//       "Location": "Tan Tan",
//       "Longitude": 28.448200225830078,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Casablanca",
//       "Type": "airport"
//     },
//     {
//       "ID": 1066,
//       "Airport Code [ICAO]": "GMFF",
//       "Airport Code iATA,FAA": "FEZ",
//       "Airport Name": "Saïss Airport",
//       "Altitude (Ft)": 1900,
//       "Country": "Morocco",
//       "Latitude": -4.977960109709999,
//       "Location": "Fes",
//       "Longitude": 33.9272994995,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Casablanca",
//       "Type": "airport"
//     },
//     {
//       "ID": 1067,
//       "Airport Code [ICAO]": "GMFI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ifrane Airport",
//       "Altitude (Ft)": 5459,
//       "Country": "Morocco",
//       "Latitude": -5.152900218963623,
//       "Location": "Ifrane",
//       "Longitude": 33.50529861450195,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Casablanca",
//       "Type": "airport"
//     },
//     {
//       "ID": 1068,
//       "Airport Code [ICAO]": "GMFK",
//       "Airport Code iATA,FAA": "ERH",
//       "Airport Name": "Moulay Ali Cherif Airport",
//       "Altitude (Ft)": 3428,
//       "Country": "Morocco",
//       "Latitude": -4.39833021164,
//       "Location": "Er-rachidia",
//       "Longitude": 31.9475002289,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Casablanca",
//       "Type": "airport"
//     },
//     {
//       "ID": 1069,
//       "Airport Code [ICAO]": "GMFM",
//       "Airport Code iATA,FAA": "MEK",
//       "Airport Name": "Bassatine Airport",
//       "Altitude (Ft)": 1890,
//       "Country": "Morocco",
//       "Latitude": -5.515120029449463,
//       "Location": "Meknes",
//       "Longitude": 33.87910079956055,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Casablanca",
//       "Type": "airport"
//     },
//     {
//       "ID": 1070,
//       "Airport Code [ICAO]": "GMFO",
//       "Airport Code iATA,FAA": "OUD",
//       "Airport Name": "Angads Airport",
//       "Altitude (Ft)": 1535,
//       "Country": "Morocco",
//       "Latitude": -1.92399001121521,
//       "Location": "Oujda",
//       "Longitude": 34.787200927734375,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Casablanca",
//       "Type": "airport"
//     },
//     {
//       "ID": 1071,
//       "Airport Code [ICAO]": "GMMB",
//       "Airport Code iATA,FAA": "GMD",
//       "Airport Name": "Ben Slimane Airport",
//       "Altitude (Ft)": 627,
//       "Country": "Morocco",
//       "Latitude": -7.22145,
//       "Location": "Ben Slimane",
//       "Longitude": 33.655399,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Casablanca",
//       "Type": "airport"
//     },
//     {
//       "ID": 1072,
//       "Airport Code [ICAO]": "GMME",
//       "Airport Code iATA,FAA": "RBA",
//       "Airport Name": "Rabat-Salé Airport",
//       "Altitude (Ft)": 276,
//       "Country": "Morocco",
//       "Latitude": -6.75152,
//       "Location": "Rabat",
//       "Longitude": 34.051498,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Casablanca",
//       "Type": "airport"
//     },
//     {
//       "ID": 1074,
//       "Airport Code [ICAO]": "GMMN",
//       "Airport Code iATA,FAA": "CMN",
//       "Airport Name": "Mohammed V International Airport",
//       "Altitude (Ft)": 656,
//       "Country": "Morocco",
//       "Latitude": -7.589970111846924,
//       "Location": "Casablanca",
//       "Longitude": 33.36750030517578,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Casablanca",
//       "Type": "airport"
//     },
//     {
//       "ID": 1075,
//       "Airport Code [ICAO]": "GMMX",
//       "Airport Code iATA,FAA": "RAK",
//       "Airport Name": "Menara Airport",
//       "Altitude (Ft)": 1545,
//       "Country": "Morocco",
//       "Latitude": -8.03629970551,
//       "Location": "Marrakech",
//       "Longitude": 31.606899261499997,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Casablanca",
//       "Type": "airport"
//     },
//     {
//       "ID": 1076,
//       "Airport Code [ICAO]": "GMMY",
//       "Airport Code iATA,FAA": "NNA",
//       "Airport Name": "Kenitra Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Morocco",
//       "Latitude": -6.595880031585693,
//       "Location": "Kentira",
//       "Longitude": 34.29890060424805,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Casablanca",
//       "Type": "airport"
//     },
//     {
//       "ID": 1077,
//       "Airport Code [ICAO]": "GMMZ",
//       "Airport Code iATA,FAA": "OZZ",
//       "Airport Name": "Ouarzazate Airport",
//       "Altitude (Ft)": 3782,
//       "Country": "Morocco",
//       "Latitude": -6.909430027010001,
//       "Location": "Ouarzazate",
//       "Longitude": 30.9391002655,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Casablanca",
//       "Type": "airport"
//     },
//     {
//       "ID": 1078,
//       "Airport Code [ICAO]": "GMTA",
//       "Airport Code iATA,FAA": "AHU",
//       "Airport Name": "Cherif Al Idrissi Airport",
//       "Altitude (Ft)": 95,
//       "Country": "Morocco",
//       "Latitude": -3.83951997756958,
//       "Location": "Al Hociema",
//       "Longitude": 35.177101135253906,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Casablanca",
//       "Type": "airport"
//     },
//     {
//       "ID": 1079,
//       "Airport Code [ICAO]": "GMTN",
//       "Airport Code iATA,FAA": "TTU",
//       "Airport Name": "Saniat R'mel Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Morocco",
//       "Latitude": -5.320020198822,
//       "Location": "Tetouan",
//       "Longitude": 35.594299316406,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Casablanca",
//       "Type": "airport"
//     },
//     {
//       "ID": 1080,
//       "Airport Code [ICAO]": "GMTT",
//       "Airport Code iATA,FAA": "TNG",
//       "Airport Name": "Ibn Batouta Airport",
//       "Altitude (Ft)": 62,
//       "Country": "Morocco",
//       "Latitude": -5.91689014435,
//       "Location": "Tanger",
//       "Longitude": 35.726898193400004,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Casablanca",
//       "Type": "airport"
//     },
//     {
//       "ID": 1081,
//       "Airport Code [ICAO]": "GOGG",
//       "Airport Code iATA,FAA": "ZIG",
//       "Airport Name": "Ziguinchor Airport",
//       "Altitude (Ft)": 75,
//       "Country": "Senegal",
//       "Latitude": -16.281799,
//       "Location": "Ziguinchor",
//       "Longitude": 12.5556,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Dakar",
//       "Type": "airport"
//     },
//     {
//       "ID": 1082,
//       "Airport Code [ICAO]": "GOGS",
//       "Airport Code iATA,FAA": "CSK",
//       "Airport Name": "Cap Skirring Airport",
//       "Altitude (Ft)": 52,
//       "Country": "Senegal",
//       "Latitude": -16.748,
//       "Location": "Cap Skiring",
//       "Longitude": 12.39533,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Dakar",
//       "Type": "airport"
//     },
//     {
//       "ID": 1083,
//       "Airport Code [ICAO]": "GOOK",
//       "Airport Code iATA,FAA": "KLC",
//       "Airport Name": "Kaolack Airport",
//       "Altitude (Ft)": 26,
//       "Country": "Senegal",
//       "Latitude": -16.051300048828125,
//       "Location": "Kaolack",
//       "Longitude": 14.146900177001953,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Dakar",
//       "Type": "airport"
//     },
//     {
//       "ID": 1084,
//       "Airport Code [ICAO]": "GOOY",
//       "Airport Code iATA,FAA": "DKR",
//       "Airport Name": "Léopold Sédar Senghor International Airport",
//       "Altitude (Ft)": 85,
//       "Country": "Senegal",
//       "Latitude": -17.49020004272461,
//       "Location": "Dakar",
//       "Longitude": 14.739700317382812,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Dakar",
//       "Type": "airport"
//     },
//     {
//       "ID": 1085,
//       "Airport Code [ICAO]": "GOSS",
//       "Airport Code iATA,FAA": "XLS",
//       "Airport Name": "Saint Louis Airport",
//       "Altitude (Ft)": 9,
//       "Country": "Senegal",
//       "Latitude": -16.463199615478516,
//       "Location": "St. Louis",
//       "Longitude": 16.050800323486328,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Dakar",
//       "Type": "airport"
//     },
//     {
//       "ID": 1086,
//       "Airport Code [ICAO]": "GOTB",
//       "Airport Code iATA,FAA": "BXE",
//       "Airport Name": "Bakel Airport",
//       "Altitude (Ft)": 98,
//       "Country": "Senegal",
//       "Latitude": -12.468299865722656,
//       "Location": "Bakel",
//       "Longitude": 14.847299575805664,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Dakar",
//       "Type": "airport"
//     },
//     {
//       "ID": 1087,
//       "Airport Code [ICAO]": "GOTK",
//       "Airport Code iATA,FAA": "KGG",
//       "Airport Name": "Kédougou Airport",
//       "Altitude (Ft)": 584,
//       "Country": "Senegal",
//       "Latitude": -12.22029972076416,
//       "Location": "Kedougou",
//       "Longitude": 12.57229995727539,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Dakar",
//       "Type": "airport"
//     },
//     {
//       "ID": 1088,
//       "Airport Code [ICAO]": "GOTT",
//       "Airport Code iATA,FAA": "TUD",
//       "Airport Name": "Tambacounda Airport",
//       "Altitude (Ft)": 161,
//       "Country": "Senegal",
//       "Latitude": -13.65310001373291,
//       "Location": "Tambacounda",
//       "Longitude": 13.736800193786621,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Dakar",
//       "Type": "airport"
//     },
//     {
//       "ID": 1089,
//       "Airport Code [ICAO]": "GQNA",
//       "Airport Code iATA,FAA": "AEO",
//       "Airport Name": "Aioun el Atrouss Airport",
//       "Altitude (Ft)": 951,
//       "Country": "Mauritania",
//       "Latitude": -9.637880325317383,
//       "Location": "Aioun El Atrouss",
//       "Longitude": 16.711299896240234,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Nouakchott",
//       "Type": "airport"
//     },
//     {
//       "ID": 1090,
//       "Airport Code [ICAO]": "GQND",
//       "Airport Code iATA,FAA": "TIY",
//       "Airport Name": "Tidjikja Airport",
//       "Altitude (Ft)": 1363,
//       "Country": "Mauritania",
//       "Latitude": -11.423500061035156,
//       "Location": "Tidjikja",
//       "Longitude": 18.570100784301758,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Nouakchott",
//       "Type": "airport"
//     },
//     {
//       "ID": 1091,
//       "Airport Code [ICAO]": "GQNF",
//       "Airport Code iATA,FAA": "KFA",
//       "Airport Name": "Kiffa Airport",
//       "Altitude (Ft)": 424,
//       "Country": "Mauritania",
//       "Latitude": -11.406200408935547,
//       "Location": "Kiffa",
//       "Longitude": 16.59000015258789,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Nouakchott",
//       "Type": "airport"
//     },
//     {
//       "ID": 1092,
//       "Airport Code [ICAO]": "GQNI",
//       "Airport Code iATA,FAA": "EMN",
//       "Airport Name": "Néma Airport",
//       "Altitude (Ft)": 751,
//       "Country": "Mauritania",
//       "Latitude": -7.3165998458862305,
//       "Location": "Nema",
//       "Longitude": 16.621999740600586,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Nouakchott",
//       "Type": "airport"
//     },
//     {
//       "ID": 1093,
//       "Airport Code [ICAO]": "GQNK",
//       "Airport Code iATA,FAA": "KED",
//       "Airport Name": "Kaédi Airport",
//       "Altitude (Ft)": 66,
//       "Country": "Mauritania",
//       "Latitude": -13.507599830627441,
//       "Location": "Kaedi",
//       "Longitude": 16.159500122070312,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Nouakchott",
//       "Type": "airport"
//     },
//     {
//       "ID": 1094,
//       "Airport Code [ICAO]": "GQNO",
//       "Airport Code iATA,FAA": "NKC",
//       "Airport Name": "Nouakchott–Oumtounsy International Airport",
//       "Altitude (Ft)": 9,
//       "Country": "Mauritania",
//       "Latitude": -15.9697222,
//       "Location": "Nouakschott",
//       "Longitude": 18.31,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Nouakchott",
//       "Type": "airport"
//     },
//     {
//       "ID": 1095,
//       "Airport Code [ICAO]": "GQNS",
//       "Airport Code iATA,FAA": "SEY",
//       "Airport Name": "Sélibaby Airport",
//       "Altitude (Ft)": 219,
//       "Country": "Mauritania",
//       "Latitude": -12.207300186157227,
//       "Location": "Selibabi",
//       "Longitude": 15.179699897766113,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Nouakchott",
//       "Type": "airport"
//     },
//     {
//       "ID": 1096,
//       "Airport Code [ICAO]": "GQPA",
//       "Airport Code iATA,FAA": "ATR",
//       "Airport Name": "Atar International Airport",
//       "Altitude (Ft)": 734,
//       "Country": "Mauritania",
//       "Latitude": -13.04319953918457,
//       "Location": "Atar",
//       "Longitude": 20.506799697875977,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Nouakchott",
//       "Type": "airport"
//     },
//     {
//       "ID": 1097,
//       "Airport Code [ICAO]": "GQPP",
//       "Airport Code iATA,FAA": "NDB",
//       "Airport Name": "Nouadhibou International Airport",
//       "Altitude (Ft)": 24,
//       "Country": "Mauritania",
//       "Latitude": -17.030000686645508,
//       "Location": "Nouadhibou",
//       "Longitude": 20.9330997467041,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Nouakchott",
//       "Type": "airport"
//     },
//     {
//       "ID": 1098,
//       "Airport Code [ICAO]": "GQPT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bir Moghrein Airport",
//       "Altitude (Ft)": 1206,
//       "Country": "Mauritania",
//       "Latitude": -11.588700294494629,
//       "Location": "Bir Moghrein",
//       "Longitude": 25.2367000579834,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Nouakchott",
//       "Type": "airport"
//     },
//     {
//       "ID": 1099,
//       "Airport Code [ICAO]": "GUFA",
//       "Airport Code iATA,FAA": "FIG",
//       "Airport Name": "Fria Airport",
//       "Altitude (Ft)": 499,
//       "Country": "Guinea",
//       "Latitude": -13.569199562073,
//       "Location": "Fira",
//       "Longitude": 10.350600242615,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Conakry",
//       "Type": "airport"
//     },
//     {
//       "ID": 1100,
//       "Airport Code [ICAO]": "GUFH",
//       "Airport Code iATA,FAA": "FAA",
//       "Airport Name": "Faranah Airport",
//       "Altitude (Ft)": 1476,
//       "Country": "Guinea",
//       "Latitude": -10.7698001862,
//       "Location": "Faranah",
//       "Longitude": 10.0354995728,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Conakry",
//       "Type": "airport"
//     },
//     {
//       "ID": 1101,
//       "Airport Code [ICAO]": "GULB",
//       "Airport Code iATA,FAA": "LEK",
//       "Airport Name": "Tata Airport",
//       "Altitude (Ft)": 3396,
//       "Country": "Guinea",
//       "Latitude": -12.286800384521,
//       "Location": "Labe",
//       "Longitude": 11.326100349426,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Conakry",
//       "Type": "airport"
//     },
//     {
//       "ID": 1102,
//       "Airport Code [ICAO]": "GVAC",
//       "Airport Code iATA,FAA": "SID",
//       "Airport Name": "Amílcar Cabral International Airport",
//       "Altitude (Ft)": 177,
//       "Country": "Cape Verde",
//       "Latitude": -22.9494,
//       "Location": "Amilcar Cabral",
//       "Longitude": 16.7414,
//       "GMT Offset": "-1",
//       "Daylight Savings": "U",
//       "Region": "Atlantic/Cape_Verde",
//       "Type": "airport"
//     },
//     {
//       "ID": 1103,
//       "Airport Code [ICAO]": "GVBA",
//       "Airport Code iATA,FAA": "BVC",
//       "Airport Name": "Rabil Airport",
//       "Altitude (Ft)": 69,
//       "Country": "Cape Verde",
//       "Latitude": -22.888900756835938,
//       "Location": "Boa Vista",
//       "Longitude": 16.136499404907227,
//       "GMT Offset": "-1",
//       "Daylight Savings": "U",
//       "Region": "Atlantic/Cape_Verde",
//       "Type": "airport"
//     },
//     {
//       "ID": 1104,
//       "Airport Code [ICAO]": "GVMA",
//       "Airport Code iATA,FAA": "MMO",
//       "Airport Name": "Maio Airport",
//       "Altitude (Ft)": 36,
//       "Country": "Cape Verde",
//       "Latitude": -23.213699340820312,
//       "Location": "Maio",
//       "Longitude": 15.155900001525879,
//       "GMT Offset": "-1",
//       "Daylight Savings": "U",
//       "Region": "Atlantic/Cape_Verde",
//       "Type": "airport"
//     },
//     {
//       "ID": 1105,
//       "Airport Code [ICAO]": "GVSN",
//       "Airport Code iATA,FAA": "SNE",
//       "Airport Name": "Preguiça Airport",
//       "Altitude (Ft)": 669,
//       "Country": "Cape Verde",
//       "Latitude": -24.284700393676758,
//       "Location": "Sao Nocolau Island",
//       "Longitude": 16.58839988708496,
//       "GMT Offset": "-1",
//       "Daylight Savings": "U",
//       "Region": "Atlantic/Cape_Verde",
//       "Type": "airport"
//     },
//     {
//       "ID": 1106,
//       "Airport Code [ICAO]": "GVSV",
//       "Airport Code iATA,FAA": "VXE",
//       "Airport Name": "São Pedro Airport",
//       "Altitude (Ft)": 66,
//       "Country": "Cape Verde",
//       "Latitude": -25.055299758911133,
//       "Location": "Sao Vicente Island",
//       "Longitude": 16.833200454711914,
//       "GMT Offset": "-1",
//       "Daylight Savings": "U",
//       "Region": "Atlantic/Cape_Verde",
//       "Type": "airport"
//     },
//     {
//       "ID": 1107,
//       "Airport Code [ICAO]": "HAAB",
//       "Airport Code iATA,FAA": "ADD",
//       "Airport Name": "Addis Ababa Bole International Airport",
//       "Altitude (Ft)": 7630,
//       "Country": "Ethiopia",
//       "Latitude": 38.799301147499996,
//       "Location": "Addis Ababa",
//       "Longitude": 8.97789001465,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1108,
//       "Airport Code [ICAO]": "HAAL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lideta Army Airport",
//       "Altitude (Ft)": 7749,
//       "Country": "Ethiopia",
//       "Latitude": 38.725498,
//       "Location": "Addis Ababa",
//       "Longitude": 9.003685,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1109,
//       "Airport Code [ICAO]": "HAAM",
//       "Airport Code iATA,FAA": "AMH",
//       "Airport Name": "Arba Minch Airport",
//       "Altitude (Ft)": 3901,
//       "Country": "Ethiopia",
//       "Latitude": 37.59049987792969,
//       "Location": "Arba Minch",
//       "Longitude": 6.0393900871276855,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1110,
//       "Airport Code [ICAO]": "HAAX",
//       "Airport Code iATA,FAA": "AXU",
//       "Airport Name": "Axum Airport",
//       "Altitude (Ft)": 6959,
//       "Country": "Ethiopia",
//       "Latitude": 38.77280044555664,
//       "Location": "Axum",
//       "Longitude": 14.14680004119873,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1111,
//       "Airport Code [ICAO]": "HABD",
//       "Airport Code iATA,FAA": "BJR",
//       "Airport Name": "Bahir Dar Airport",
//       "Altitude (Ft)": 5978,
//       "Country": "Ethiopia",
//       "Latitude": 37.32160186767578,
//       "Location": "Bahar Dar",
//       "Longitude": 11.608099937438965,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1112,
//       "Airport Code [ICAO]": "HADR",
//       "Airport Code iATA,FAA": "DIR",
//       "Airport Name": "Aba Tenna Dejazmach Yilma International Airport",
//       "Altitude (Ft)": 3827,
//       "Country": "Ethiopia",
//       "Latitude": 41.85419845581055,
//       "Location": "Dire Dawa",
//       "Longitude": 9.624699592590332,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1113,
//       "Airport Code [ICAO]": "HAGM",
//       "Airport Code iATA,FAA": "GMB",
//       "Airport Name": "Gambella Airport",
//       "Altitude (Ft)": 1614,
//       "Country": "Ethiopia",
//       "Latitude": 34.5630989074707,
//       "Location": "Gambella",
//       "Longitude": 8.12876033782959,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1114,
//       "Airport Code [ICAO]": "HAGN",
//       "Airport Code iATA,FAA": "GDQ",
//       "Airport Name": "Gonder Airport",
//       "Altitude (Ft)": 6449,
//       "Country": "Ethiopia",
//       "Latitude": 37.433998107910156,
//       "Location": "Gondar",
//       "Longitude": 12.51990032196045,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1116,
//       "Airport Code [ICAO]": "HAJM",
//       "Airport Code iATA,FAA": "JIM",
//       "Airport Name": "Jimma Airport",
//       "Altitude (Ft)": 5500,
//       "Country": "Ethiopia",
//       "Latitude": 36.81660079956055,
//       "Location": "Jimma",
//       "Longitude": 7.66609001159668,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1117,
//       "Airport Code [ICAO]": "HALL",
//       "Airport Code iATA,FAA": "LLI",
//       "Airport Name": "Lalibella Airport",
//       "Altitude (Ft)": 6506,
//       "Country": "Ethiopia",
//       "Latitude": 38.97999954223633,
//       "Location": "Lalibella",
//       "Longitude": 11.975000381469727,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1118,
//       "Airport Code [ICAO]": "HAMK",
//       "Airport Code iATA,FAA": "MQX",
//       "Airport Name": "Mekele Airport",
//       "Altitude (Ft)": 7396,
//       "Country": "Ethiopia",
//       "Latitude": 39.53350067138672,
//       "Location": "Makale",
//       "Longitude": 13.467399597167969,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1119,
//       "Airport Code [ICAO]": "HASO",
//       "Airport Code iATA,FAA": "ASO",
//       "Airport Name": "Asosa Airport",
//       "Altitude (Ft)": 5100,
//       "Country": "Ethiopia",
//       "Latitude": 34.586299896240234,
//       "Location": "Asosa",
//       "Longitude": 10.018500328063965,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1120,
//       "Airport Code [ICAO]": "HBBA",
//       "Airport Code iATA,FAA": "BJM",
//       "Airport Name": "Bujumbura International Airport",
//       "Altitude (Ft)": 2582,
//       "Country": "Burundi",
//       "Latitude": 29.318500518798828,
//       "Location": "Bujumbura",
//       "Longitude": -3.3240199089050293,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Bujumbura",
//       "Type": "airport"
//     },
//     {
//       "ID": 1121,
//       "Airport Code [ICAO]": "HCMH",
//       "Airport Code iATA,FAA": "HGA",
//       "Airport Name": "Egal International Airport",
//       "Altitude (Ft)": 4423,
//       "Country": "Somalia",
//       "Latitude": 44.08879852294922,
//       "Location": "Hargeisa",
//       "Longitude": 9.518170356750488,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Mogadishu",
//       "Type": "airport"
//     },
//     {
//       "ID": 1122,
//       "Airport Code [ICAO]": "HCMI",
//       "Airport Code iATA,FAA": "BBO",
//       "Airport Name": "Berbera Airport",
//       "Altitude (Ft)": 30,
//       "Country": "Somalia",
//       "Latitude": 44.94110107421875,
//       "Location": "Berbera",
//       "Longitude": 10.389200210571289,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Mogadishu",
//       "Type": "airport"
//     },
//     {
//       "ID": 1123,
//       "Airport Code [ICAO]": "HCMK",
//       "Airport Code iATA,FAA": "KMU",
//       "Airport Name": "Kisimayu Airport",
//       "Altitude (Ft)": 49,
//       "Country": "Somalia",
//       "Latitude": 42.45920181274414,
//       "Location": "Kismayu",
//       "Longitude": -0.3773530125617981,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Mogadishu",
//       "Type": "airport"
//     },
//     {
//       "ID": 1126,
//       "Airport Code [ICAO]": "HEAX",
//       "Airport Code iATA,FAA": "ALY",
//       "Airport Name": "El Nouzha Airport",
//       "Altitude (Ft)": -6,
//       "Country": "Egypt",
//       "Latitude": 29.94890022277832,
//       "Location": "Alexandria",
//       "Longitude": 31.183900833129883,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Cairo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1127,
//       "Airport Code [ICAO]": "HEBL",
//       "Airport Code iATA,FAA": "ABS",
//       "Airport Name": "Abu Simbel Airport",
//       "Altitude (Ft)": 616,
//       "Country": "Egypt",
//       "Latitude": 31.611700058,
//       "Location": "Abu Simbel",
//       "Longitude": 22.375999450699997,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Cairo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1128,
//       "Airport Code [ICAO]": "HECA",
//       "Airport Code iATA,FAA": "CAI",
//       "Airport Name": "Cairo International Airport",
//       "Altitude (Ft)": 382,
//       "Country": "Egypt",
//       "Latitude": 31.40559959411621,
//       "Location": "Cairo",
//       "Longitude": 30.12190055847168,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Cairo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1129,
//       "Airport Code [ICAO]": "HECW",
//       "Airport Code iATA,FAA": "CWE",
//       "Airport Name": "Cairo West Airport",
//       "Altitude (Ft)": 550,
//       "Country": "Egypt",
//       "Latitude": 30.915399551399997,
//       "Location": "Cairo",
//       "Longitude": 30.116399765,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Cairo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1130,
//       "Airport Code [ICAO]": "HEGN",
//       "Airport Code iATA,FAA": "HRG",
//       "Airport Name": "Hurghada International Airport",
//       "Altitude (Ft)": 52,
//       "Country": "Egypt",
//       "Latitude": 33.799400329589844,
//       "Location": "Hurghada",
//       "Longitude": 27.178300857543945,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Cairo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1131,
//       "Airport Code [ICAO]": "HEGR",
//       "Airport Code iATA,FAA": "EGH",
//       "Airport Name": "El Gora Airport",
//       "Altitude (Ft)": 324,
//       "Country": "Egypt",
//       "Latitude": 34.129629,
//       "Location": "El Gorah",
//       "Longitude": 31.068559,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Cairo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1132,
//       "Airport Code [ICAO]": "HELX",
//       "Airport Code iATA,FAA": "LXR",
//       "Airport Name": "Luxor International Airport",
//       "Altitude (Ft)": 294,
//       "Country": "Egypt",
//       "Latitude": 32.7066,
//       "Location": "Luxor",
//       "Longitude": 25.671,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Cairo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1133,
//       "Airport Code [ICAO]": "HEMM",
//       "Airport Code iATA,FAA": "MUH",
//       "Airport Name": "Mersa Matruh Airport",
//       "Altitude (Ft)": 94,
//       "Country": "Egypt",
//       "Latitude": 27.221700668300002,
//       "Location": "Mersa-matruh",
//       "Longitude": 31.3253993988,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Cairo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1134,
//       "Airport Code [ICAO]": "HEPS",
//       "Airport Code iATA,FAA": "PSD",
//       "Airport Name": "Port Said Airport",
//       "Altitude (Ft)": 8,
//       "Country": "Egypt",
//       "Latitude": 32.2400016784668,
//       "Location": "Port Said",
//       "Longitude": 31.279399871826172,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Cairo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1135,
//       "Airport Code [ICAO]": "HESC",
//       "Airport Code iATA,FAA": "SKV",
//       "Airport Name": "St Catherine International Airport",
//       "Altitude (Ft)": 4368,
//       "Country": "Egypt",
//       "Latitude": 34.0625,
//       "Location": "St. Catherine",
//       "Longitude": 28.685300827,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Cairo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1136,
//       "Airport Code [ICAO]": "HESN",
//       "Airport Code iATA,FAA": "ASW",
//       "Airport Name": "Aswan International Airport",
//       "Altitude (Ft)": 662,
//       "Country": "Egypt",
//       "Latitude": 32.8199996948,
//       "Location": "Aswan",
//       "Longitude": 23.9643993378,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Cairo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1137,
//       "Airport Code [ICAO]": "HETR",
//       "Airport Code iATA,FAA": "ELT",
//       "Airport Name": "El Tor Airport",
//       "Altitude (Ft)": 115,
//       "Country": "Egypt",
//       "Latitude": 33.64550018310547,
//       "Location": "El-tor",
//       "Longitude": 28.208999633789062,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Cairo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1138,
//       "Airport Code [ICAO]": "HKEL",
//       "Airport Code iATA,FAA": "EDL",
//       "Airport Name": "Eldoret International Airport",
//       "Altitude (Ft)": 6941,
//       "Country": "Kenya",
//       "Latitude": 35.23889923095703,
//       "Location": "Eldoret",
//       "Longitude": 0.4044579863548279,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 1139,
//       "Airport Code [ICAO]": "HKKG",
//       "Airport Code iATA,FAA": "GGM",
//       "Airport Name": "Kakamega Airport",
//       "Altitude (Ft)": 5020,
//       "Country": "Kenya",
//       "Latitude": 34.7873001099,
//       "Location": "Kakamega",
//       "Longitude": 0.271342009306,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 1140,
//       "Airport Code [ICAO]": "HKKI",
//       "Airport Code iATA,FAA": "KIS",
//       "Airport Name": "Kisumu Airport",
//       "Altitude (Ft)": 3734,
//       "Country": "Kenya",
//       "Latitude": 34.72890090942383,
//       "Location": "Kisumu",
//       "Longitude": -0.0861390009522438,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 1141,
//       "Airport Code [ICAO]": "HKKT",
//       "Airport Code iATA,FAA": "KTL",
//       "Airport Name": "Kitale Airport",
//       "Altitude (Ft)": 6070,
//       "Country": "Kenya",
//       "Latitude": 34.95859909057617,
//       "Location": "Kitale",
//       "Longitude": 0.9719889760017395,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 1143,
//       "Airport Code [ICAO]": "HKLO",
//       "Airport Code iATA,FAA": "LOK",
//       "Airport Name": "Lodwar Airport",
//       "Altitude (Ft)": 1715,
//       "Country": "Kenya",
//       "Latitude": 35.608699798583984,
//       "Location": "Lodwar",
//       "Longitude": 3.1219699382781982,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 1144,
//       "Airport Code [ICAO]": "HKLU",
//       "Airport Code iATA,FAA": "LAU",
//       "Airport Name": "Manda Airstrip",
//       "Altitude (Ft)": 20,
//       "Country": "Kenya",
//       "Latitude": 40.91310119628906,
//       "Location": "Lamu",
//       "Longitude": -2.252419948577881,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 1145,
//       "Airport Code [ICAO]": "HKMO",
//       "Airport Code iATA,FAA": "MBA",
//       "Airport Name": "Mombasa Moi International Airport",
//       "Altitude (Ft)": 200,
//       "Country": "Kenya",
//       "Latitude": 39.594200134277344,
//       "Location": "Mombasa",
//       "Longitude": -4.034830093383789,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 1146,
//       "Airport Code [ICAO]": "HKNV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Naivasha Airport",
//       "Altitude (Ft)": 6380,
//       "Country": "Kenya",
//       "Latitude": 36.433494,
//       "Location": "Naivasha",
//       "Longitude": -0.787953,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 1147,
//       "Airport Code [ICAO]": "HKNW",
//       "Airport Code iATA,FAA": "WIL",
//       "Airport Name": "Nairobi Wilson Airport",
//       "Altitude (Ft)": 5536,
//       "Country": "Kenya",
//       "Latitude": 36.81480026245117,
//       "Location": "Nairobi",
//       "Longitude": -1.321720004081726,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 1148,
//       "Airport Code [ICAO]": "HKRE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Moi Air Base",
//       "Altitude (Ft)": 5336,
//       "Country": "Kenya",
//       "Latitude": 36.8623008728,
//       "Location": "Nairobi",
//       "Longitude": -1.2772699594499999,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 1149,
//       "Airport Code [ICAO]": "HKWJ",
//       "Airport Code iATA,FAA": "WJR",
//       "Airport Name": "Wajir Airport",
//       "Altitude (Ft)": 770,
//       "Country": "Kenya",
//       "Latitude": 40.091599,
//       "Location": "Wajir",
//       "Longitude": 1.73324,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 1150,
//       "Airport Code [ICAO]": "HLFL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bu Attifel Airport",
//       "Altitude (Ft)": 161,
//       "Country": "Libya",
//       "Latitude": 22.080900192260742,
//       "Location": "Buattifel",
//       "Longitude": 28.795400619506836,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Tripoli",
//       "Type": "airport"
//     },
//     {
//       "ID": 1151,
//       "Airport Code [ICAO]": "HLGL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Warehouse 59e Airport",
//       "Altitude (Ft)": 325,
//       "Country": "Libya",
//       "Latitude": 21.437999725341797,
//       "Location": "Giallo",
//       "Longitude": 28.638500213623047,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Tripoli",
//       "Type": "airport"
//     },
//     {
//       "ID": 1152,
//       "Airport Code [ICAO]": "HLGT",
//       "Airport Code iATA,FAA": "GHT",
//       "Airport Name": "Ghat Airport",
//       "Altitude (Ft)": 2296,
//       "Country": "Libya",
//       "Latitude": 10.142600059500001,
//       "Location": "Ghat",
//       "Longitude": 25.1455993652,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Tripoli",
//       "Type": "airport"
//     },
//     {
//       "ID": 1153,
//       "Airport Code [ICAO]": "HLKF",
//       "Airport Code iATA,FAA": "AKF",
//       "Airport Name": "Kufra Airport",
//       "Altitude (Ft)": 1367,
//       "Country": "Libya",
//       "Latitude": 23.31399917602539,
//       "Location": "Kufra",
//       "Longitude": 24.178699493408203,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Tripoli",
//       "Type": "airport"
//     },
//     {
//       "ID": 1154,
//       "Airport Code [ICAO]": "HLLB",
//       "Airport Code iATA,FAA": "BEN",
//       "Airport Name": "Benina International Airport",
//       "Altitude (Ft)": 433,
//       "Country": "Libya",
//       "Latitude": 20.2695007324,
//       "Location": "Benghazi",
//       "Longitude": 32.096801757799994,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Tripoli",
//       "Type": "airport"
//     },
//     {
//       "ID": 1156,
//       "Airport Code [ICAO]": "HLLS",
//       "Airport Code iATA,FAA": "SEB",
//       "Airport Name": "Sabha Airport",
//       "Altitude (Ft)": 1427,
//       "Country": "Libya",
//       "Latitude": 14.47249984741211,
//       "Location": "Sebha",
//       "Longitude": 26.98699951171875,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Tripoli",
//       "Type": "airport"
//     },
//     {
//       "ID": 1157,
//       "Airport Code [ICAO]": "HLLT",
//       "Airport Code iATA,FAA": "TIP",
//       "Airport Name": "Tripoli International Airport",
//       "Altitude (Ft)": 263,
//       "Country": "Libya",
//       "Latitude": 13.1590003967,
//       "Location": "Tripoli",
//       "Longitude": 32.6635017395,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Tripoli",
//       "Type": "airport"
//     },
//     {
//       "ID": 1158,
//       "Airport Code [ICAO]": "HLMB",
//       "Airport Code iATA,FAA": "LMQ",
//       "Airport Name": "Marsa Brega Airport",
//       "Altitude (Ft)": 50,
//       "Country": "Libya",
//       "Latitude": 19.576400756835938,
//       "Location": "Marsa Brega",
//       "Longitude": 30.37809944152832,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Tripoli",
//       "Type": "airport"
//     },
//     {
//       "ID": 1159,
//       "Airport Code [ICAO]": "HLNF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ras Lanuf Oil Airport",
//       "Altitude (Ft)": 42,
//       "Country": "Libya",
//       "Latitude": 18.52720069885254,
//       "Location": "Ras Lanouf V 40",
//       "Longitude": 30.5,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Tripoli",
//       "Type": "airport"
//     },
//     {
//       "ID": 1160,
//       "Airport Code [ICAO]": "HLON",
//       "Airport Code iATA,FAA": "HUQ",
//       "Airport Name": "Hon Airport",
//       "Altitude (Ft)": 919,
//       "Country": "Libya",
//       "Latitude": 15.96560001373291,
//       "Location": "Hon",
//       "Longitude": 29.11009979248047,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Tripoli",
//       "Type": "airport"
//     },
//     {
//       "ID": 1161,
//       "Airport Code [ICAO]": "HLRA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Dahra Airport",
//       "Altitude (Ft)": 1050,
//       "Country": "Libya",
//       "Latitude": 17.934900283813477,
//       "Location": "Dahra",
//       "Longitude": 29.47260093688965,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Tripoli",
//       "Type": "airport"
//     },
//     {
//       "ID": 1162,
//       "Airport Code [ICAO]": "HLTD",
//       "Airport Code iATA,FAA": "LTD",
//       "Airport Name": "Ghadames East Airport",
//       "Altitude (Ft)": 1122,
//       "Country": "Libya",
//       "Latitude": 9.715310096740723,
//       "Location": "Ghadames",
//       "Longitude": 30.15169906616211,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Tripoli",
//       "Type": "airport"
//     },
//     {
//       "ID": 1163,
//       "Airport Code [ICAO]": "HLZA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Zella 74 Airport",
//       "Altitude (Ft)": 1085,
//       "Country": "Libya",
//       "Latitude": 17.293899536132812,
//       "Location": "Zella 74",
//       "Longitude": 28.58989906311035,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Tripoli",
//       "Type": "airport"
//     },
//     {
//       "ID": 1164,
//       "Airport Code [ICAO]": "HRYG",
//       "Airport Code iATA,FAA": "GYI",
//       "Airport Name": "Gisenyi Airport",
//       "Altitude (Ft)": 5082,
//       "Country": "Rwanda",
//       "Latitude": 29.258899688720703,
//       "Location": "Gisenyi",
//       "Longitude": -1.6771999597549438,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Kigali",
//       "Type": "airport"
//     },
//     {
//       "ID": 1165,
//       "Airport Code [ICAO]": "HRYR",
//       "Airport Code iATA,FAA": "KGL",
//       "Airport Name": "Kigali International Airport",
//       "Altitude (Ft)": 4859,
//       "Country": "Rwanda",
//       "Latitude": 30.1395,
//       "Location": "Kigali",
//       "Longitude": -1.96863,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Kigali",
//       "Type": "airport"
//     },
//     {
//       "ID": 1166,
//       "Airport Code [ICAO]": "HRZA",
//       "Airport Code iATA,FAA": "KME",
//       "Airport Name": "Kamembe Airport",
//       "Altitude (Ft)": 5192,
//       "Country": "Rwanda",
//       "Latitude": 28.907899856567383,
//       "Location": "Kamembe",
//       "Longitude": -2.462239980697632,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Kigali",
//       "Type": "airport"
//     },
//     {
//       "ID": 1167,
//       "Airport Code [ICAO]": "HSDN",
//       "Airport Code iATA,FAA": "DOG",
//       "Airport Name": "Dongola Airport",
//       "Altitude (Ft)": 772,
//       "Country": "Sudan",
//       "Latitude": 30.430099487299998,
//       "Location": "Dongola",
//       "Longitude": 19.153900146499996,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Khartoum",
//       "Type": "airport"
//     },
//     {
//       "ID": 1168,
//       "Airport Code [ICAO]": "HSDZ",
//       "Airport Code iATA,FAA": "RSS",
//       "Airport Name": "Damazin Airport",
//       "Altitude (Ft)": 1582,
//       "Country": "Sudan",
//       "Latitude": 34.3367,
//       "Location": "Damazin",
//       "Longitude": 11.7859,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Khartoum",
//       "Type": "airport"
//     },
//     {
//       "ID": 1169,
//       "Airport Code [ICAO]": "HSFS",
//       "Airport Code iATA,FAA": "ELF",
//       "Airport Name": "El Fasher Airport",
//       "Altitude (Ft)": 2393,
//       "Country": "Sudan",
//       "Latitude": 25.324600219726562,
//       "Location": "El Fasher",
//       "Longitude": 13.614899635314941,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Khartoum",
//       "Type": "airport"
//     },
//     {
//       "ID": 1170,
//       "Airport Code [ICAO]": "HSKA",
//       "Airport Code iATA,FAA": "KSL",
//       "Airport Name": "Kassala Airport",
//       "Altitude (Ft)": 1671,
//       "Country": "Sudan",
//       "Latitude": 36.328800201416016,
//       "Location": "Kassala",
//       "Longitude": 15.387499809265137,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Khartoum",
//       "Type": "airport"
//     },
//     {
//       "ID": 1171,
//       "Airport Code [ICAO]": "HSLI",
//       "Airport Code iATA,FAA": "KDX",
//       "Airport Name": "Kadugli Airport",
//       "Altitude (Ft)": 1848,
//       "Country": "Sudan",
//       "Latitude": 29.7010993958,
//       "Location": "Kadugli",
//       "Longitude": 11.137999534600002,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Khartoum",
//       "Type": "airport"
//     },
//     {
//       "ID": 1172,
//       "Airport Code [ICAO]": "HSOB",
//       "Airport Code iATA,FAA": "EBD",
//       "Airport Name": "El Obeid Airport",
//       "Altitude (Ft)": 1927,
//       "Country": "Sudan",
//       "Latitude": 30.23270034790039,
//       "Location": "El Obeid",
//       "Longitude": 13.153200149536133,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Khartoum",
//       "Type": "airport"
//     },
//     {
//       "ID": 1173,
//       "Airport Code [ICAO]": "HSSJ",
//       "Airport Code iATA,FAA": "JUB",
//       "Airport Name": "Juba International Airport",
//       "Altitude (Ft)": 1513,
//       "Country": "South Sudan",
//       "Latitude": 31.6011009216,
//       "Location": "Juba",
//       "Longitude": 4.87201023102,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Juba",
//       "Type": "airport"
//     },
//     {
//       "ID": 1174,
//       "Airport Code [ICAO]": "HSSM",
//       "Airport Code iATA,FAA": "MAK",
//       "Airport Name": "Malakal Airport",
//       "Altitude (Ft)": 1291,
//       "Country": "Sudan",
//       "Latitude": 31.65220069885254,
//       "Location": "Malakal",
//       "Longitude": 9.55897045135498,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Juba",
//       "Type": "airport"
//     },
//     {
//       "ID": 1175,
//       "Airport Code [ICAO]": "HSSS",
//       "Airport Code iATA,FAA": "KRT",
//       "Airport Name": "Khartoum International Airport",
//       "Altitude (Ft)": 1265,
//       "Country": "Sudan",
//       "Latitude": 32.553199768066406,
//       "Location": "Khartoum",
//       "Longitude": 15.589500427246094,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Khartoum",
//       "Type": "airport"
//     },
//     {
//       "ID": 1176,
//       "Airport Code [ICAO]": "HTAR",
//       "Airport Code iATA,FAA": "ARK",
//       "Airport Name": "Arusha Airport",
//       "Altitude (Ft)": 4550,
//       "Country": "Tanzania",
//       "Latitude": 36.63330078125,
//       "Location": "Arusha",
//       "Longitude": -3.3677899837493896,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Dar_es_Salaam",
//       "Type": "airport"
//     },
//     {
//       "ID": 1177,
//       "Airport Code [ICAO]": "HTDA",
//       "Airport Code iATA,FAA": "DAR",
//       "Airport Name": "Julius Nyerere International Airport",
//       "Altitude (Ft)": 182,
//       "Country": "Tanzania",
//       "Latitude": 39.202599,
//       "Location": "Dar Es Salaam",
//       "Longitude": -6.87811,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Dar_es_Salaam",
//       "Type": "airport"
//     },
//     {
//       "ID": 1178,
//       "Airport Code [ICAO]": "HTDO",
//       "Airport Code iATA,FAA": "DOD",
//       "Airport Name": "Dodoma Airport",
//       "Altitude (Ft)": 3673,
//       "Country": "Tanzania",
//       "Latitude": 35.752601623535156,
//       "Location": "Dodoma",
//       "Longitude": -6.170440196990967,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Dar_es_Salaam",
//       "Type": "airport"
//     },
//     {
//       "ID": 1179,
//       "Airport Code [ICAO]": "HTIR",
//       "Airport Code iATA,FAA": "IRI",
//       "Airport Name": "Iringa Airport",
//       "Altitude (Ft)": 4678,
//       "Country": "Tanzania",
//       "Latitude": 35.75210189819336,
//       "Location": "Iringa",
//       "Longitude": -7.668630123138428,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Dar_es_Salaam",
//       "Type": "airport"
//     },
//     {
//       "ID": 1180,
//       "Airport Code [ICAO]": "HTKJ",
//       "Airport Code iATA,FAA": "JRO",
//       "Airport Name": "Kilimanjaro International Airport",
//       "Altitude (Ft)": 2932,
//       "Country": "Tanzania",
//       "Latitude": 37.0745010376,
//       "Location": "Kilimanjaro",
//       "Longitude": -3.42940998077,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Dar_es_Salaam",
//       "Type": "airport"
//     },
//     {
//       "ID": 1181,
//       "Airport Code [ICAO]": "HTLM",
//       "Airport Code iATA,FAA": "LKY",
//       "Airport Name": "Lake Manyara Airport",
//       "Altitude (Ft)": 4150,
//       "Country": "Tanzania",
//       "Latitude": 35.81829833984375,
//       "Location": "Lake Manyara",
//       "Longitude": -3.376310110092163,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Africa/Dar_es_Salaam",
//       "Type": "airport"
//     },
//     {
//       "ID": 1182,
//       "Airport Code [ICAO]": "HTMT",
//       "Airport Code iATA,FAA": "MYW",
//       "Airport Name": "Mtwara Airport",
//       "Altitude (Ft)": 371,
//       "Country": "Tanzania",
//       "Latitude": 40.181800842285156,
//       "Location": "Mtwara",
//       "Longitude": -10.339099884033203,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Dar_es_Salaam",
//       "Type": "airport"
//     },
//     {
//       "ID": 1183,
//       "Airport Code [ICAO]": "HTMW",
//       "Airport Code iATA,FAA": "MWZ",
//       "Airport Name": "Mwanza Airport",
//       "Altitude (Ft)": 3763,
//       "Country": "Tanzania",
//       "Latitude": 32.932701110839844,
//       "Location": "Mwanza",
//       "Longitude": -2.4444899559020996,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Dar_es_Salaam",
//       "Type": "airport"
//     },
//     {
//       "ID": 1184,
//       "Airport Code [ICAO]": "HTPE",
//       "Airport Code iATA,FAA": "PMA",
//       "Airport Name": "Pemba Airport",
//       "Altitude (Ft)": 80,
//       "Country": "Tanzania",
//       "Latitude": 39.8114013671875,
//       "Location": "Pemba",
//       "Longitude": -5.257259845733643,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Dar_es_Salaam",
//       "Type": "airport"
//     },
//     {
//       "ID": 1185,
//       "Airport Code [ICAO]": "HTTG",
//       "Airport Code iATA,FAA": "TGT",
//       "Airport Name": "Tanga Airport",
//       "Altitude (Ft)": 129,
//       "Country": "Tanzania",
//       "Latitude": 39.07120132446289,
//       "Location": "Tanga",
//       "Longitude": -5.092360019683838,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Dar_es_Salaam",
//       "Type": "airport"
//     },
//     {
//       "ID": 1186,
//       "Airport Code [ICAO]": "HTZA",
//       "Airport Code iATA,FAA": "ZNZ",
//       "Airport Name": "Abeid Amani Karume International Airport",
//       "Altitude (Ft)": 54,
//       "Country": "Tanzania",
//       "Latitude": 39.224899,
//       "Location": "Zanzibar",
//       "Longitude": -6.22202,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Dar_es_Salaam",
//       "Type": "airport"
//     },
//     {
//       "ID": 1187,
//       "Airport Code [ICAO]": "HUEN",
//       "Airport Code iATA,FAA": "EBB",
//       "Airport Name": "Entebbe International Airport",
//       "Altitude (Ft)": 3782,
//       "Country": "Uganda",
//       "Latitude": 32.443501,
//       "Location": "Entebbe",
//       "Longitude": 0.042386,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Kampala",
//       "Type": "airport"
//     },
//     {
//       "ID": 1189,
//       "Airport Code [ICAO]": "HUSO",
//       "Airport Code iATA,FAA": "SRT",
//       "Airport Name": "Soroti Airport",
//       "Altitude (Ft)": 3697,
//       "Country": "Uganda",
//       "Latitude": 33.622798919677734,
//       "Location": "Soroti",
//       "Longitude": 1.7276899814605713,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Kampala",
//       "Type": "airport"
//     },
//     {
//       "ID": 1190,
//       "Airport Code [ICAO]": "LATI",
//       "Airport Code iATA,FAA": "TIA",
//       "Airport Name": "Tirana International Airport Mother Teresa",
//       "Altitude (Ft)": 126,
//       "Country": "Albania",
//       "Latitude": 19.7206001282,
//       "Location": "Tirana",
//       "Longitude": 41.4146995544,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Tirane",
//       "Type": "airport"
//     },
//     {
//       "ID": 1191,
//       "Airport Code [ICAO]": "LBBG",
//       "Airport Code iATA,FAA": "BOJ",
//       "Airport Name": "Burgas Airport",
//       "Altitude (Ft)": 135,
//       "Country": "Bulgaria",
//       "Latitude": 27.515199661254883,
//       "Location": "Bourgas",
//       "Longitude": 42.56959915161133,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Sofia",
//       "Type": "airport"
//     },
//     {
//       "ID": 1192,
//       "Airport Code [ICAO]": "LBGO",
//       "Airport Code iATA,FAA": "GOZ",
//       "Airport Name": "Gorna Oryahovitsa Airport",
//       "Altitude (Ft)": 285,
//       "Country": "Bulgaria",
//       "Latitude": 25.712900161743164,
//       "Location": "Gorna Orechovica",
//       "Longitude": 43.15140151977539,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Sofia",
//       "Type": "airport"
//     },
//     {
//       "ID": 1193,
//       "Airport Code [ICAO]": "LBPD",
//       "Airport Code iATA,FAA": "PDV",
//       "Airport Name": "Plovdiv International Airport",
//       "Altitude (Ft)": 597,
//       "Country": "Bulgaria",
//       "Latitude": 24.8508,
//       "Location": "Plovdiv",
//       "Longitude": 42.067799,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Sofia",
//       "Type": "airport"
//     },
//     {
//       "ID": 1194,
//       "Airport Code [ICAO]": "LBSF",
//       "Airport Code iATA,FAA": "SOF",
//       "Airport Name": "Sofia Airport",
//       "Altitude (Ft)": 1742,
//       "Country": "Bulgaria",
//       "Latitude": 23.411436080932617,
//       "Location": "Sofia",
//       "Longitude": 42.696693420410156,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Sofia",
//       "Type": "airport"
//     },
//     {
//       "ID": 1195,
//       "Airport Code [ICAO]": "LBSZ",
//       "Airport Code iATA,FAA": "SZR",
//       "Airport Name": "Stara Zagora Airport",
//       "Altitude (Ft)": 558,
//       "Country": "Bulgaria",
//       "Latitude": 25.655,
//       "Location": "Stara Zagora",
//       "Longitude": 42.3766667,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Sofia",
//       "Type": "airport"
//     },
//     {
//       "ID": 1196,
//       "Airport Code [ICAO]": "LBWN",
//       "Airport Code iATA,FAA": "VAR",
//       "Airport Name": "Varna Airport",
//       "Altitude (Ft)": 230,
//       "Country": "Bulgaria",
//       "Latitude": 27.8251,
//       "Location": "Varna",
//       "Longitude": 43.232101,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Sofia",
//       "Type": "airport"
//     },
//     {
//       "ID": 1197,
//       "Airport Code [ICAO]": "LCLK",
//       "Airport Code iATA,FAA": "LCA",
//       "Airport Name": "Larnaca International Airport",
//       "Altitude (Ft)": 8,
//       "Country": "Cyprus",
//       "Latitude": 33.624900817871094,
//       "Location": "Larnaca",
//       "Longitude": 34.875099182128906,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Nicosia",
//       "Type": "airport"
//     },
//     {
//       "ID": 1198,
//       "Airport Code [ICAO]": "LCPH",
//       "Airport Code iATA,FAA": "PFO",
//       "Airport Name": "Paphos International Airport",
//       "Altitude (Ft)": 41,
//       "Country": "Cyprus",
//       "Latitude": 32.48569869995117,
//       "Location": "Paphos",
//       "Longitude": 34.71799850463867,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Nicosia",
//       "Type": "airport"
//     },
//     {
//       "ID": 1199,
//       "Airport Code [ICAO]": "LCRA",
//       "Airport Code iATA,FAA": "AKT",
//       "Airport Name": "RAF Akrotiri",
//       "Altitude (Ft)": 76,
//       "Country": "Cyprus",
//       "Latitude": 32.9879,
//       "Location": "Akrotiri",
//       "Longitude": 34.590401,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 1200,
//       "Airport Code [ICAO]": "LDDU",
//       "Airport Code iATA,FAA": "DBV",
//       "Airport Name": "Dubrovnik Airport",
//       "Altitude (Ft)": 527,
//       "Country": "Croatia",
//       "Latitude": 18.268199920654297,
//       "Location": "Dubrovnik",
//       "Longitude": 42.5614013671875,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zagreb",
//       "Type": "airport"
//     },
//     {
//       "ID": 1201,
//       "Airport Code [ICAO]": "LDOC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Osijek-Čepin Airfield",
//       "Altitude (Ft)": 299,
//       "Country": "Croatia",
//       "Latitude": 18.6319444,
//       "Location": "Cepin",
//       "Longitude": 45.5427778,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zagreb",
//       "Type": "airport"
//     },
//     {
//       "ID": 1202,
//       "Airport Code [ICAO]": "LDOS",
//       "Airport Code iATA,FAA": "OSI",
//       "Airport Name": "Osijek Airport",
//       "Altitude (Ft)": 290,
//       "Country": "Croatia",
//       "Latitude": 18.810199737548828,
//       "Location": "Osijek",
//       "Longitude": 45.46269989013672,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zagreb",
//       "Type": "airport"
//     },
//     {
//       "ID": 1203,
//       "Airport Code [ICAO]": "LDPL",
//       "Airport Code iATA,FAA": "PUY",
//       "Airport Name": "Pula Airport",
//       "Altitude (Ft)": 274,
//       "Country": "Croatia",
//       "Latitude": 13.922200202941895,
//       "Location": "Pula",
//       "Longitude": 44.89350128173828,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zagreb",
//       "Type": "airport"
//     },
//     {
//       "ID": 1204,
//       "Airport Code [ICAO]": "LDRG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Grobnicko Polje Airport",
//       "Altitude (Ft)": 951,
//       "Country": "Croatia",
//       "Latitude": 14.503800392150879,
//       "Location": "Grobnik",
//       "Longitude": 45.37950134277344,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zagreb",
//       "Type": "airport"
//     },
//     {
//       "ID": 1205,
//       "Airport Code [ICAO]": "LDRI",
//       "Airport Code iATA,FAA": "RJK",
//       "Airport Name": "Rijeka Airport",
//       "Altitude (Ft)": 278,
//       "Country": "Croatia",
//       "Latitude": 14.570300102233887,
//       "Location": "Rijeka",
//       "Longitude": 45.21689987182617,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zagreb",
//       "Type": "airport"
//     },
//     {
//       "ID": 1206,
//       "Airport Code [ICAO]": "LDSP",
//       "Airport Code iATA,FAA": "SPU",
//       "Airport Name": "Split Airport",
//       "Altitude (Ft)": 79,
//       "Country": "Croatia",
//       "Latitude": 16.29800033569336,
//       "Location": "Split",
//       "Longitude": 43.53889846801758,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zagreb",
//       "Type": "airport"
//     },
//     {
//       "ID": 1207,
//       "Airport Code [ICAO]": "LDVA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Varaždin Airport",
//       "Altitude (Ft)": 548,
//       "Country": "Croatia",
//       "Latitude": 16.382932662963867,
//       "Location": "Varazdin",
//       "Longitude": 46.294647216796875,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zagreb",
//       "Type": "airport"
//     },
//     {
//       "ID": 1208,
//       "Airport Code [ICAO]": "LDZA",
//       "Airport Code iATA,FAA": "ZAG",
//       "Airport Name": "Zagreb Airport",
//       "Altitude (Ft)": 353,
//       "Country": "Croatia",
//       "Latitude": 16.0687999725,
//       "Location": "Zagreb",
//       "Longitude": 45.7429008484,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zagreb",
//       "Type": "airport"
//     },
//     {
//       "ID": 1209,
//       "Airport Code [ICAO]": "LDZD",
//       "Airport Code iATA,FAA": "ZAD",
//       "Airport Name": "Zadar Airport",
//       "Altitude (Ft)": 289,
//       "Country": "Croatia",
//       "Latitude": 15.3467,
//       "Location": "Zadar",
//       "Longitude": 44.108299,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zagreb",
//       "Type": "airport"
//     },
//     {
//       "ID": 1210,
//       "Airport Code [ICAO]": "LDZU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Udbina Air Base",
//       "Altitude (Ft)": 2462,
//       "Country": "Croatia",
//       "Latitude": 15.774399757385254,
//       "Location": "Udbina",
//       "Longitude": 44.55759811401367,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zagreb",
//       "Type": "airport"
//     },
//     {
//       "ID": 1211,
//       "Airport Code [ICAO]": "LEAB",
//       "Airport Code iATA,FAA": "ABC",
//       "Airport Name": "Albacete-Los Llanos Airport",
//       "Altitude (Ft)": 2302,
//       "Country": "Spain",
//       "Latitude": -1.8635200262099998,
//       "Location": "Albacete",
//       "Longitude": 38.9485015869,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1212,
//       "Airport Code [ICAO]": "LEAL",
//       "Airport Code iATA,FAA": "ALC",
//       "Airport Name": "Alicante International Airport",
//       "Altitude (Ft)": 142,
//       "Country": "Spain",
//       "Latitude": -0.5581560134887695,
//       "Location": "Alicante",
//       "Longitude": 38.28219985961914,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1213,
//       "Airport Code [ICAO]": "LEAM",
//       "Airport Code iATA,FAA": "LEI",
//       "Airport Name": "Almería International Airport",
//       "Altitude (Ft)": 70,
//       "Country": "Spain",
//       "Latitude": -2.3701000213623047,
//       "Location": "Almeria",
//       "Longitude": 36.84389877319336,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1214,
//       "Airport Code [ICAO]": "LEAS",
//       "Airport Code iATA,FAA": "OVD",
//       "Airport Name": "Asturias Airport",
//       "Altitude (Ft)": 416,
//       "Country": "Spain",
//       "Latitude": -6.0346198081970215,
//       "Location": "Aviles",
//       "Longitude": 43.5635986328125,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1215,
//       "Airport Code [ICAO]": "LEBA",
//       "Airport Code iATA,FAA": "ODB",
//       "Airport Name": "Córdoba Airport",
//       "Altitude (Ft)": 297,
//       "Country": "Spain",
//       "Latitude": -4.848879814147949,
//       "Location": "Cordoba",
//       "Longitude": 37.84199905395508,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1216,
//       "Airport Code [ICAO]": "LEBB",
//       "Airport Code iATA,FAA": "BIO",
//       "Airport Name": "Bilbao Airport",
//       "Altitude (Ft)": 138,
//       "Country": "Spain",
//       "Latitude": -2.9106099605560303,
//       "Location": "Bilbao",
//       "Longitude": 43.30110168457031,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1218,
//       "Airport Code [ICAO]": "LEBL",
//       "Airport Code iATA,FAA": "BCN",
//       "Airport Name": "Barcelona International Airport",
//       "Altitude (Ft)": 12,
//       "Country": "Spain",
//       "Latitude": 2.07846,
//       "Location": "Barcelona",
//       "Longitude": 41.2971,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1219,
//       "Airport Code [ICAO]": "LEBZ",
//       "Airport Code iATA,FAA": "BJZ",
//       "Airport Name": "Badajoz Airport",
//       "Altitude (Ft)": 609,
//       "Country": "Spain",
//       "Latitude": -6.8213300704956055,
//       "Location": "Badajoz",
//       "Longitude": 38.891300201416016,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1220,
//       "Airport Code [ICAO]": "LECO",
//       "Airport Code iATA,FAA": "LCG",
//       "Airport Name": "A Coruña Airport",
//       "Altitude (Ft)": 326,
//       "Country": "Spain",
//       "Latitude": -8.37726,
//       "Location": "La Coruna",
//       "Longitude": 43.302101,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1221,
//       "Airport Code [ICAO]": "LEGA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Armilla Air Base",
//       "Altitude (Ft)": 2297,
//       "Country": "Spain",
//       "Latitude": -3.63568997,
//       "Location": "Granada",
//       "Longitude": 37.1332016,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1222,
//       "Airport Code [ICAO]": "LEGE",
//       "Airport Code iATA,FAA": "GRO",
//       "Airport Name": "Girona Airport",
//       "Altitude (Ft)": 468,
//       "Country": "Spain",
//       "Latitude": 2.7605500221,
//       "Location": "Gerona",
//       "Longitude": 41.901000977,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1223,
//       "Airport Code [ICAO]": "LEGR",
//       "Airport Code iATA,FAA": "GRX",
//       "Airport Name": "Federico Garcia Lorca Airport",
//       "Altitude (Ft)": 1860,
//       "Country": "Spain",
//       "Latitude": -3.777359962463379,
//       "Location": "Granada",
//       "Longitude": 37.18870162963867,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1224,
//       "Airport Code [ICAO]": "LEGT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Getafe Air Base",
//       "Altitude (Ft)": 2031,
//       "Country": "Spain",
//       "Latitude": -3.723829984664917,
//       "Location": "Madrid",
//       "Longitude": 40.29410171508789,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1225,
//       "Airport Code [ICAO]": "LEIB",
//       "Airport Code iATA,FAA": "IBZ",
//       "Airport Name": "Ibiza Airport",
//       "Altitude (Ft)": 24,
//       "Country": "Spain",
//       "Latitude": 1.3731199502899998,
//       "Location": "Ibiza",
//       "Longitude": 38.872898101800004,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1226,
//       "Airport Code [ICAO]": "LEJR",
//       "Airport Code iATA,FAA": "XRY",
//       "Airport Name": "Jerez Airport",
//       "Altitude (Ft)": 93,
//       "Country": "Spain",
//       "Latitude": -6.060110092163086,
//       "Location": "Jerez",
//       "Longitude": 36.744598388671875,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1227,
//       "Airport Code [ICAO]": "LELC",
//       "Airport Code iATA,FAA": "MJV",
//       "Airport Name": "San Javier Airport",
//       "Altitude (Ft)": 11,
//       "Country": "Spain",
//       "Latitude": -0.8123890161514282,
//       "Location": "Murcia",
//       "Longitude": 37.775001525878906,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1229,
//       "Airport Code [ICAO]": "LEMD",
//       "Airport Code iATA,FAA": "MAD",
//       "Airport Name": "Adolfo Suárez Madrid–Barajas Airport",
//       "Altitude (Ft)": 1998,
//       "Country": "Spain",
//       "Latitude": -3.56264,
//       "Location": "Madrid",
//       "Longitude": 40.471926,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1230,
//       "Airport Code [ICAO]": "LEMG",
//       "Airport Code iATA,FAA": "AGP",
//       "Airport Name": "Málaga Airport",
//       "Altitude (Ft)": 53,
//       "Country": "Spain",
//       "Latitude": -4.499110221862793,
//       "Location": "Malaga",
//       "Longitude": 36.67490005493164,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1231,
//       "Airport Code [ICAO]": "LEMH",
//       "Airport Code iATA,FAA": "MAH",
//       "Airport Name": "Menorca Airport",
//       "Altitude (Ft)": 302,
//       "Country": "Spain",
//       "Latitude": 4.218649864196777,
//       "Location": "Menorca",
//       "Longitude": 39.86259841918945,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1232,
//       "Airport Code [ICAO]": "LEMO",
//       "Airport Code iATA,FAA": "OZP",
//       "Airport Name": "Moron Air Base",
//       "Altitude (Ft)": 285,
//       "Country": "Spain",
//       "Latitude": -5.615940093994141,
//       "Location": "Sevilla",
//       "Longitude": 37.17490005493164,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1233,
//       "Airport Code [ICAO]": "LEOC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ocaña Airport",
//       "Altitude (Ft)": 2405,
//       "Country": "Spain",
//       "Latitude": -3.5033299922943115,
//       "Location": "Ocana",
//       "Longitude": 39.9375,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1234,
//       "Airport Code [ICAO]": "LEPP",
//       "Airport Code iATA,FAA": "PNA",
//       "Airport Name": "Pamplona Airport",
//       "Altitude (Ft)": 1504,
//       "Country": "Spain",
//       "Latitude": -1.6463299989700317,
//       "Location": "Pamplona",
//       "Longitude": 42.77000045776367,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1235,
//       "Airport Code [ICAO]": "LERI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Alcantarilla Air Base",
//       "Altitude (Ft)": 250,
//       "Country": "Spain",
//       "Latitude": -1.23032,
//       "Location": "Murcia",
//       "Longitude": 37.951099,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1236,
//       "Airport Code [ICAO]": "LERS",
//       "Airport Code iATA,FAA": "REU",
//       "Airport Name": "Reus Air Base",
//       "Altitude (Ft)": 233,
//       "Country": "Spain",
//       "Latitude": 1.1671700477600098,
//       "Location": "Reus",
//       "Longitude": 41.14739990234375,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1237,
//       "Airport Code [ICAO]": "LERT",
//       "Airport Code iATA,FAA": "ROZ",
//       "Airport Name": "Rota Naval Station Airport",
//       "Altitude (Ft)": 86,
//       "Country": "Spain",
//       "Latitude": -6.34946012497,
//       "Location": "Rota",
//       "Longitude": 36.645198822,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1238,
//       "Airport Code [ICAO]": "LESA",
//       "Airport Code iATA,FAA": "SLM",
//       "Airport Name": "Salamanca Airport",
//       "Altitude (Ft)": 2595,
//       "Country": "Spain",
//       "Latitude": -5.501989841461182,
//       "Location": "Salamanca",
//       "Longitude": 40.95209884643555,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1239,
//       "Airport Code [ICAO]": "LESB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Son Bonet Airport",
//       "Altitude (Ft)": 157,
//       "Country": "Spain",
//       "Latitude": 2.70278000831604,
//       "Location": "Son Bonet",
//       "Longitude": 39.598899841308594,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1241,
//       "Airport Code [ICAO]": "LESL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "San Luis Airport",
//       "Altitude (Ft)": 197,
//       "Country": "Spain",
//       "Latitude": 4.25832986831665,
//       "Location": "San Luis",
//       "Longitude": 39.86220169067383,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1242,
//       "Airport Code [ICAO]": "LESO",
//       "Airport Code iATA,FAA": "EAS",
//       "Airport Name": "San Sebastian Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Spain",
//       "Latitude": -1.7906099557876587,
//       "Location": "San Sebastian",
//       "Longitude": 43.35649871826172,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1243,
//       "Airport Code [ICAO]": "LEST",
//       "Airport Code iATA,FAA": "SCQ",
//       "Airport Name": "Santiago de Compostela Airport",
//       "Altitude (Ft)": 1213,
//       "Country": "Spain",
//       "Latitude": -8.415140151977539,
//       "Location": "Santiago",
//       "Longitude": 42.89630126953125,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1244,
//       "Airport Code [ICAO]": "LESU",
//       "Airport Code iATA,FAA": "LEU",
//       "Airport Name": "Pirineus - la Seu d'Urgel Airport",
//       "Altitude (Ft)": 2625,
//       "Country": "Spain",
//       "Latitude": 1.40917,
//       "Location": "Seo De Urgel",
//       "Longitude": 42.3386,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1245,
//       "Airport Code [ICAO]": "LETO",
//       "Airport Code iATA,FAA": "TOJ",
//       "Airport Name": "Torrejón Airport",
//       "Altitude (Ft)": 2026,
//       "Country": "Spain",
//       "Latitude": -3.4458699226379395,
//       "Location": "Madrid",
//       "Longitude": 40.496700286865234,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1246,
//       "Airport Code [ICAO]": "LEVC",
//       "Airport Code iATA,FAA": "VLC",
//       "Airport Name": "Valencia Airport",
//       "Altitude (Ft)": 240,
//       "Country": "Spain",
//       "Latitude": -0.481625,
//       "Location": "Valencia",
//       "Longitude": 39.4893,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1247,
//       "Airport Code [ICAO]": "LEVD",
//       "Airport Code iATA,FAA": "VLL",
//       "Airport Name": "Valladolid Airport",
//       "Altitude (Ft)": 2776,
//       "Country": "Spain",
//       "Latitude": -4.85194015503,
//       "Location": "Valladolid",
//       "Longitude": 41.7061004639,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1249,
//       "Airport Code [ICAO]": "LEVT",
//       "Airport Code iATA,FAA": "VIT",
//       "Airport Name": "Vitoria/Foronda Airport",
//       "Altitude (Ft)": 1682,
//       "Country": "Spain",
//       "Latitude": -2.7244699001312256,
//       "Location": "Vitoria",
//       "Longitude": 42.8828010559082,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1250,
//       "Airport Code [ICAO]": "LEVX",
//       "Airport Code iATA,FAA": "VGO",
//       "Airport Name": "Vigo Airport",
//       "Altitude (Ft)": 856,
//       "Country": "Spain",
//       "Latitude": -8.62677001953125,
//       "Location": "Vigo",
//       "Longitude": 42.2318000793457,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1251,
//       "Airport Code [ICAO]": "LEXJ",
//       "Airport Code iATA,FAA": "SDR",
//       "Airport Name": "Santander Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Spain",
//       "Latitude": -3.82000994682312,
//       "Location": "Santander",
//       "Longitude": 43.427101135253906,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1252,
//       "Airport Code [ICAO]": "LEZG",
//       "Airport Code iATA,FAA": "ZAZ",
//       "Airport Name": "Zaragoza Air Base",
//       "Altitude (Ft)": 863,
//       "Country": "Spain",
//       "Latitude": -1.0415500402450562,
//       "Location": "Zaragoza",
//       "Longitude": 41.66619873046875,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1253,
//       "Airport Code [ICAO]": "LEZL",
//       "Airport Code iATA,FAA": "SVQ",
//       "Airport Name": "Sevilla Airport",
//       "Altitude (Ft)": 112,
//       "Country": "Spain",
//       "Latitude": -5.8931097984313965,
//       "Location": "Sevilla",
//       "Longitude": 37.417999267578125,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 1254,
//       "Airport Code [ICAO]": "LFAC",
//       "Airport Code iATA,FAA": "CQF",
//       "Airport Name": "Calais-Dunkerque Airport",
//       "Altitude (Ft)": 12,
//       "Country": "France",
//       "Latitude": 1.954759955406189,
//       "Location": "Calais",
//       "Longitude": 50.962100982666016,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1255,
//       "Airport Code [ICAO]": "LFAG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Péronne-Saint-Quentin Airport",
//       "Altitude (Ft)": 295,
//       "Country": "France",
//       "Latitude": 3.02958,
//       "Location": "Peronne",
//       "Longitude": 49.8685,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1256,
//       "Airport Code [ICAO]": "LFAI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nangis-Les Loges Airport",
//       "Altitude (Ft)": 428,
//       "Country": "France",
//       "Latitude": 3.0067899227142334,
//       "Location": "Nangis",
//       "Longitude": 48.59619903564453,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1257,
//       "Airport Code [ICAO]": "LFAO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bagnoles-de-l'Orne-Couterne Airport",
//       "Altitude (Ft)": 718,
//       "Country": "France",
//       "Latitude": -0.38744398951530457,
//       "Location": "Bagnole-de-l'orne",
//       "Longitude": 48.545799255371094,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1258,
//       "Airport Code [ICAO]": "LFAQ",
//       "Airport Code iATA,FAA": "BYF",
//       "Airport Name": "Albert-Bray Airport",
//       "Altitude (Ft)": 364,
//       "Country": "France",
//       "Latitude": 2.69765996933,
//       "Location": "Albert",
//       "Longitude": 49.9715003967,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1259,
//       "Airport Code [ICAO]": "LFAT",
//       "Airport Code iATA,FAA": "LTQ",
//       "Airport Name": "Le Touquet-Côte d'Opale Airport",
//       "Altitude (Ft)": 36,
//       "Country": "France",
//       "Latitude": 1.6205899715423584,
//       "Location": "Le Tourquet",
//       "Longitude": 50.517398834228516,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1260,
//       "Airport Code [ICAO]": "LFAV",
//       "Airport Code iATA,FAA": "XVS",
//       "Airport Name": "Valenciennes-Denain Airport",
//       "Altitude (Ft)": 177,
//       "Country": "France",
//       "Latitude": 3.4612600803375244,
//       "Location": "Valenciennes",
//       "Longitude": 50.325801849365234,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1261,
//       "Airport Code [ICAO]": "LFAY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Amiens-Glisy Airport",
//       "Altitude (Ft)": 208,
//       "Country": "France",
//       "Latitude": 2.387074,
//       "Location": "Amiens",
//       "Longitude": 49.873004,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1262,
//       "Airport Code [ICAO]": "LFBA",
//       "Airport Code iATA,FAA": "AGF",
//       "Airport Name": "Agen-La Garenne Airport",
//       "Altitude (Ft)": 204,
//       "Country": "France",
//       "Latitude": 0.5905560255050659,
//       "Location": "Agen",
//       "Longitude": 44.17470169067383,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1263,
//       "Airport Code [ICAO]": "LFBC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cazaux (BA 120) Air Base",
//       "Altitude (Ft)": 84,
//       "Country": "France",
//       "Latitude": -1.125,
//       "Location": "Cazaux",
//       "Longitude": 44.53329849243164,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1264,
//       "Airport Code [ICAO]": "LFBD",
//       "Airport Code iATA,FAA": "BOD",
//       "Airport Name": "Bordeaux-Mérignac Airport",
//       "Altitude (Ft)": 162,
//       "Country": "France",
//       "Latitude": -0.715556025505,
//       "Location": "Bordeaux",
//       "Longitude": 44.828300476100004,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1265,
//       "Airport Code [ICAO]": "LFBE",
//       "Airport Code iATA,FAA": "EGC",
//       "Airport Name": "Bergerac-Roumanière Airport",
//       "Altitude (Ft)": 171,
//       "Country": "France",
//       "Latitude": 0.5186110138893127,
//       "Location": "Bergerac",
//       "Longitude": 44.82529830932617,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1266,
//       "Airport Code [ICAO]": "LFBF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Toulouse-Francazal (BA 101) Air Base",
//       "Altitude (Ft)": 535,
//       "Country": "France",
//       "Latitude": 1.3674999475479126,
//       "Location": "Toulouse",
//       "Longitude": 43.54560089111328,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1267,
//       "Airport Code [ICAO]": "LFBG",
//       "Airport Code iATA,FAA": "CNG",
//       "Airport Name": "Cognac-Châteaubernard (BA 709) Air Base",
//       "Altitude (Ft)": 102,
//       "Country": "France",
//       "Latitude": -0.3174999952316284,
//       "Location": "Cognac",
//       "Longitude": 45.65829849243164,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1268,
//       "Airport Code [ICAO]": "LFBI",
//       "Airport Code iATA,FAA": "PIS",
//       "Airport Name": "Poitiers-Biard Airport",
//       "Altitude (Ft)": 423,
//       "Country": "France",
//       "Latitude": 0.30666598677635193,
//       "Location": "Poitiers",
//       "Longitude": 46.58769989013672,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1269,
//       "Airport Code [ICAO]": "LFBK",
//       "Airport Code iATA,FAA": "MCU",
//       "Airport Name": "Montluçon-Guéret Airport",
//       "Altitude (Ft)": 1497,
//       "Country": "France",
//       "Latitude": 2.363960027694702,
//       "Location": "Montlucon-gueret",
//       "Longitude": 46.222599029541016,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1270,
//       "Airport Code [ICAO]": "LFBL",
//       "Airport Code iATA,FAA": "LIG",
//       "Airport Name": "Limoges Airport",
//       "Altitude (Ft)": 1300,
//       "Country": "France",
//       "Latitude": 1.1794400215148926,
//       "Location": "Limoges",
//       "Longitude": 45.86280059814453,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1271,
//       "Airport Code [ICAO]": "LFBM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mont-de-Marsan (BA 118) Air Base",
//       "Altitude (Ft)": 203,
//       "Country": "France",
//       "Latitude": -0.5075,
//       "Location": "Mont-de-marsan",
//       "Longitude": 43.911701,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1272,
//       "Airport Code [ICAO]": "LFBN",
//       "Airport Code iATA,FAA": "NIT",
//       "Airport Name": "Niort-Souché Airport",
//       "Altitude (Ft)": 203,
//       "Country": "France",
//       "Latitude": -0.394529,
//       "Location": "Niort",
//       "Longitude": 46.313477,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1273,
//       "Airport Code [ICAO]": "LFBO",
//       "Airport Code iATA,FAA": "TLS",
//       "Airport Name": "Toulouse-Blagnac Airport",
//       "Altitude (Ft)": 499,
//       "Country": "France",
//       "Latitude": 1.36382,
//       "Location": "Toulouse",
//       "Longitude": 43.629101,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1274,
//       "Airport Code [ICAO]": "LFBP",
//       "Airport Code iATA,FAA": "PUF",
//       "Airport Name": "Pau Pyrénées Airport",
//       "Altitude (Ft)": 616,
//       "Country": "France",
//       "Latitude": -0.41861099004745483,
//       "Location": "Pau",
//       "Longitude": 43.380001068115234,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1275,
//       "Airport Code [ICAO]": "LFBR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Muret-Lherm Airport",
//       "Altitude (Ft)": 622,
//       "Country": "France",
//       "Latitude": 1.2633299827575684,
//       "Location": "La Rochelle",
//       "Longitude": 43.44889831542969,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1276,
//       "Airport Code [ICAO]": "LFBT",
//       "Airport Code iATA,FAA": "LDE",
//       "Airport Name": "Tarbes-Lourdes-Pyrénées Airport",
//       "Altitude (Ft)": 1260,
//       "Country": "France",
//       "Latitude": -0.006438999902456999,
//       "Location": "Tarbes",
//       "Longitude": 43.1786994934082,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1277,
//       "Airport Code [ICAO]": "LFBU",
//       "Airport Code iATA,FAA": "ANG",
//       "Airport Name": "Angoulême-Brie-Champniers Airport",
//       "Altitude (Ft)": 436,
//       "Country": "France",
//       "Latitude": 0.22145600616931915,
//       "Location": "Angouleme",
//       "Longitude": 45.72919845581055,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1278,
//       "Airport Code [ICAO]": "LFSL",
//       "Airport Code iATA,FAA": "BVE",
//       "Airport Name": "Brive Souillac Airport",
//       "Altitude (Ft)": 1016,
//       "Country": "France",
//       "Latitude": 1.485556,
//       "Location": "Brive",
//       "Longitude": 45.039722,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1279,
//       "Airport Code [ICAO]": "LFBX",
//       "Airport Code iATA,FAA": "PGX",
//       "Airport Name": "Périgueux-Bassillac Airport",
//       "Altitude (Ft)": 328,
//       "Country": "France",
//       "Latitude": 0.815555989742279,
//       "Location": "Perigueux",
//       "Longitude": 45.19810104370117,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1280,
//       "Airport Code [ICAO]": "LFBZ",
//       "Airport Code iATA,FAA": "BIQ",
//       "Airport Name": "Biarritz-Anglet-Bayonne Airport",
//       "Altitude (Ft)": 245,
//       "Country": "France",
//       "Latitude": -1.5311111,
//       "Location": "Biarritz-bayonne",
//       "Longitude": 43.4683333,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1281,
//       "Airport Code [ICAO]": "LFCC",
//       "Airport Code iATA,FAA": "ZAO",
//       "Airport Name": "Cahors-Lalbenque Airport",
//       "Altitude (Ft)": 912,
//       "Country": "France",
//       "Latitude": 1.4752800464630127,
//       "Location": "Cahors",
//       "Longitude": 44.35139846801758,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1282,
//       "Airport Code [ICAO]": "LFCG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Saint-Girons-Antichan Airport",
//       "Altitude (Ft)": 1368,
//       "Country": "France",
//       "Latitude": 1.1031500101089478,
//       "Location": "St.-girons",
//       "Longitude": 43.0078010559082,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1283,
//       "Airport Code [ICAO]": "LFCH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Arcachon-La Teste-de-Buch Airport",
//       "Altitude (Ft)": 49,
//       "Country": "France",
//       "Latitude": -1.11083,
//       "Location": "Arcachon",
//       "Longitude": 44.596401,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1284,
//       "Airport Code [ICAO]": "LFCI",
//       "Airport Code iATA,FAA": "LBI",
//       "Airport Name": "Albi-Le Séquestre Airport",
//       "Altitude (Ft)": 564,
//       "Country": "France",
//       "Latitude": 2.1130599975585938,
//       "Location": "Albi",
//       "Longitude": 43.91389846801758,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1285,
//       "Airport Code [ICAO]": "LFCK",
//       "Airport Code iATA,FAA": "DCM",
//       "Airport Name": "Castres-Mazamet Airport",
//       "Altitude (Ft)": 788,
//       "Country": "France",
//       "Latitude": 2.289180040359497,
//       "Location": "Castres",
//       "Longitude": 43.55630111694336,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1286,
//       "Airport Code [ICAO]": "LFCL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Toulouse-Lasbordes Airport",
//       "Altitude (Ft)": 459,
//       "Country": "France",
//       "Latitude": 1.499169945716858,
//       "Location": "Toulouse",
//       "Longitude": 43.58610153198242,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1287,
//       "Airport Code [ICAO]": "LFCM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Millau-Larzac Airfield",
//       "Altitude (Ft)": 2606,
//       "Country": "France",
//       "Latitude": 3.183,
//       "Location": "Millau",
//       "Longitude": 43.9893,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1288,
//       "Airport Code [ICAO]": "LFCQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Graulhet-Montdragon Airport",
//       "Altitude (Ft)": 581,
//       "Country": "France",
//       "Latitude": 2.0108299255371094,
//       "Location": "Graulhet",
//       "Longitude": 43.77109909057617,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1289,
//       "Airport Code [ICAO]": "LFCR",
//       "Airport Code iATA,FAA": "RDZ",
//       "Airport Name": "Rodez-Marcillac Airport",
//       "Altitude (Ft)": 1910,
//       "Country": "France",
//       "Latitude": 2.4826700687408447,
//       "Location": "Rodez",
//       "Longitude": 44.407901763916016,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1290,
//       "Airport Code [ICAO]": "LFCU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ussel-Thalamy Airport",
//       "Altitude (Ft)": 2428,
//       "Country": "France",
//       "Latitude": 2.4238901138305664,
//       "Location": "Ussel",
//       "Longitude": 45.534698486328125,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1291,
//       "Airport Code [ICAO]": "LFCW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Villeneuve-sur-Lot Airport",
//       "Altitude (Ft)": 190,
//       "Country": "France",
//       "Latitude": 0.7588890194892883,
//       "Location": "Villeneuve-sur-lot",
//       "Longitude": 44.39690017700195,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1292,
//       "Airport Code [ICAO]": "LFCY",
//       "Airport Code iATA,FAA": "RYN",
//       "Airport Name": "Royan-Médis Airport",
//       "Altitude (Ft)": 72,
//       "Country": "France",
//       "Latitude": -0.9725000262260437,
//       "Location": "Royan",
//       "Longitude": 45.62810134887695,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1293,
//       "Airport Code [ICAO]": "LFCZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mimizan Airport",
//       "Altitude (Ft)": 164,
//       "Country": "France",
//       "Latitude": -1.16432,
//       "Location": "Mimizan",
//       "Longitude": 44.145928,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1294,
//       "Airport Code [ICAO]": "LFDA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Aire-sur-l'Adour Airport",
//       "Altitude (Ft)": 259,
//       "Country": "France",
//       "Latitude": -0.245278000831604,
//       "Location": "Aire-sur-l'adour",
//       "Longitude": 43.70940017700195,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1295,
//       "Airport Code [ICAO]": "LFDB",
//       "Airport Code iATA,FAA": "XMW",
//       "Airport Name": "Montauban Airport",
//       "Altitude (Ft)": 351,
//       "Country": "France",
//       "Latitude": 1.3780399560928345,
//       "Location": "Montauban",
//       "Longitude": 44.025699615478516,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1296,
//       "Airport Code [ICAO]": "LFDH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Auch-Lamothe Airport",
//       "Altitude (Ft)": 411,
//       "Country": "France",
//       "Latitude": 0.601667,
//       "Location": "Auch",
//       "Longitude": 43.687801,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1297,
//       "Airport Code [ICAO]": "LFDI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Libourne-Artigues-de-Lussac Airport",
//       "Altitude (Ft)": 157,
//       "Country": "France",
//       "Latitude": -0.134722,
//       "Location": "Libourne",
//       "Longitude": 44.982498,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1298,
//       "Airport Code [ICAO]": "LFDJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Pamiers-Les Pujols Airport",
//       "Altitude (Ft)": 1115,
//       "Country": "France",
//       "Latitude": 1.69582998752594,
//       "Location": "Pamiers",
//       "Longitude": 43.090599060058594,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1299,
//       "Airport Code [ICAO]": "LFDM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Marmande-Virazeil Airport",
//       "Altitude (Ft)": 105,
//       "Country": "France",
//       "Latitude": 0.20051400363445282,
//       "Location": "Marmande",
//       "Longitude": 44.4989013671875,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1300,
//       "Airport Code [ICAO]": "LFDN",
//       "Airport Code iATA,FAA": "RCO",
//       "Airport Name": "Rochefort-Saint-Agnant (BA 721) Airport",
//       "Altitude (Ft)": 60,
//       "Country": "France",
//       "Latitude": -0.9830560088157654,
//       "Location": "Rochefort",
//       "Longitude": 45.88779830932617,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1301,
//       "Airport Code [ICAO]": "LFED",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Pontivy Airport",
//       "Altitude (Ft)": 407,
//       "Country": "France",
//       "Latitude": -2.92182993888855,
//       "Location": "Pontivy",
//       "Longitude": 48.05849838256836,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1302,
//       "Airport Code [ICAO]": "LFEH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Aubigny-sur-Nère Airport",
//       "Altitude (Ft)": 630,
//       "Country": "France",
//       "Latitude": 2.394167,
//       "Location": "Aubigny-sur-nere",
//       "Longitude": 47.480556,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1303,
//       "Airport Code [ICAO]": "LFES",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Guiscriff Scaer Airport",
//       "Altitude (Ft)": 574,
//       "Country": "France",
//       "Latitude": -3.664720058441162,
//       "Location": "Guiscriff-scaer",
//       "Longitude": 48.0525016784668,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1305,
//       "Airport Code [ICAO]": "LFFI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ancenis Airport",
//       "Altitude (Ft)": 111,
//       "Country": "France",
//       "Latitude": -1.1775000095367432,
//       "Location": "Ancenis",
//       "Longitude": 47.40810012817383,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1306,
//       "Airport Code [ICAO]": "LFFN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Brienne-le-Château Airport",
//       "Altitude (Ft)": 381,
//       "Country": "France",
//       "Latitude": 4.48222017288208,
//       "Location": "Brienne-le Chateau",
//       "Longitude": 48.4297981262207,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1307,
//       "Airport Code [ICAO]": "LFGA",
//       "Airport Code iATA,FAA": "CMR",
//       "Airport Name": "Colmar-Houssen Airport",
//       "Altitude (Ft)": 628,
//       "Country": "France",
//       "Latitude": 7.359010219573975,
//       "Location": "Colmar",
//       "Longitude": 48.109901428222656,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1308,
//       "Airport Code [ICAO]": "LFGF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Beaune-Challanges Airport",
//       "Altitude (Ft)": 656,
//       "Country": "France",
//       "Latitude": 4.89342,
//       "Location": "Beaune",
//       "Longitude": 47.005901,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1309,
//       "Airport Code [ICAO]": "LFGJ",
//       "Airport Code iATA,FAA": "DLE",
//       "Airport Name": "Dole-Tavaux Airport",
//       "Altitude (Ft)": 645,
//       "Country": "France",
//       "Latitude": 5.435063,
//       "Location": "Dole",
//       "Longitude": 47.042686,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1310,
//       "Airport Code [ICAO]": "LFGK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Joigny Airport",
//       "Altitude (Ft)": 732,
//       "Country": "France",
//       "Latitude": 3.3922200202941895,
//       "Location": "Joigny",
//       "Longitude": 47.9921989440918,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1311,
//       "Airport Code [ICAO]": "LFGW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Verdun-Le Rozelier Airfield",
//       "Altitude (Ft)": 1230,
//       "Country": "France",
//       "Latitude": 5.46905,
//       "Location": "Verdun",
//       "Longitude": 49.122398,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1312,
//       "Airport Code [ICAO]": "LFHO",
//       "Airport Code iATA,FAA": "OBS",
//       "Airport Name": "Aubenas-Ardèche Méridional Airport",
//       "Altitude (Ft)": 923,
//       "Country": "France",
//       "Latitude": 4.372192,
//       "Location": "Aubenas-vals-lanas",
//       "Longitude": 44.544203,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1313,
//       "Airport Code [ICAO]": "LFHP",
//       "Airport Code iATA,FAA": "LPY",
//       "Airport Name": "Le Puy-Loudes Airport",
//       "Altitude (Ft)": 2731,
//       "Country": "France",
//       "Latitude": 3.762890100479126,
//       "Location": "Le Puy",
//       "Longitude": 45.0806999206543,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1314,
//       "Airport Code [ICAO]": "LFHQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Saint-Flour-Coltines Airport",
//       "Altitude (Ft)": 3218,
//       "Country": "France",
//       "Latitude": 2.99360990524292,
//       "Location": "St.-flour",
//       "Longitude": 45.07640075683594,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1315,
//       "Airport Code [ICAO]": "LFHS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bourg-Ceyzériat Airport",
//       "Altitude (Ft)": 857,
//       "Country": "France",
//       "Latitude": 5.292029857635498,
//       "Location": "Bourg",
//       "Longitude": 46.20090103149414,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1316,
//       "Airport Code [ICAO]": "LFHV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Villefranche-Tarare Airport",
//       "Altitude (Ft)": 1076,
//       "Country": "France",
//       "Latitude": 4.634931,
//       "Location": "Vilefrance",
//       "Longitude": 45.919983,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1317,
//       "Airport Code [ICAO]": "LFHY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Moulins-Montbeugny Airport",
//       "Altitude (Ft)": 915,
//       "Country": "France",
//       "Latitude": 3.423719882965088,
//       "Location": "Moulins",
//       "Longitude": 46.53459930419922,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1318,
//       "Airport Code [ICAO]": "LFIF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Saint-Affrique-Belmont Airport",
//       "Altitude (Ft)": 1686,
//       "Country": "France",
//       "Latitude": 2.7452800273895264,
//       "Location": "St.-afrique-belmont",
//       "Longitude": 43.823299407958984,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1319,
//       "Airport Code [ICAO]": "LFIG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cassagnes-Bégonhès Airport",
//       "Altitude (Ft)": 2024,
//       "Country": "France",
//       "Latitude": 2.515000104904175,
//       "Location": "Cassagnes-beghones",
//       "Longitude": 44.177799224853516,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1320,
//       "Airport Code [ICAO]": "LFJL",
//       "Airport Code iATA,FAA": "ETZ",
//       "Airport Name": "Metz-Nancy-Lorraine Airport",
//       "Altitude (Ft)": 870,
//       "Country": "France",
//       "Latitude": 6.25131988525,
//       "Location": "Metz",
//       "Longitude": 48.9821014404,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1321,
//       "Airport Code [ICAO]": "LFKB",
//       "Airport Code iATA,FAA": "BIA",
//       "Airport Name": "Bastia-Poretta Airport",
//       "Altitude (Ft)": 26,
//       "Country": "France",
//       "Latitude": 9.48373031616211,
//       "Location": "Bastia",
//       "Longitude": 42.55270004272461,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1322,
//       "Airport Code [ICAO]": "LFKC",
//       "Airport Code iATA,FAA": "CLY",
//       "Airport Name": "Calvi-Sainte-Catherine Airport",
//       "Altitude (Ft)": 209,
//       "Country": "France",
//       "Latitude": 8.7930556,
//       "Location": "Calvi",
//       "Longitude": 42.5244444,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1323,
//       "Airport Code [ICAO]": "LFKF",
//       "Airport Code iATA,FAA": "FSC",
//       "Airport Name": "Figari Sud-Corse Airport",
//       "Altitude (Ft)": 87,
//       "Country": "France",
//       "Latitude": 9.097780227661133,
//       "Location": "Figari",
//       "Longitude": 41.5005989074707,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1324,
//       "Airport Code [ICAO]": "LFKJ",
//       "Airport Code iATA,FAA": "AJA",
//       "Airport Name": "Ajaccio-Napoléon Bonaparte Airport",
//       "Altitude (Ft)": 18,
//       "Country": "France",
//       "Latitude": 8.8029203414917,
//       "Location": "Ajaccio",
//       "Longitude": 41.92359924316406,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1325,
//       "Airport Code [ICAO]": "LFKO",
//       "Airport Code iATA,FAA": "PRP",
//       "Airport Name": "Propriano Airport",
//       "Altitude (Ft)": 13,
//       "Country": "France",
//       "Latitude": 8.889749526977539,
//       "Location": "Propriano",
//       "Longitude": 41.66059875488281,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1326,
//       "Airport Code [ICAO]": "LFKS",
//       "Airport Code iATA,FAA": "SOZ",
//       "Airport Name": "Solenzara (BA 126) Air Base",
//       "Altitude (Ft)": 28,
//       "Country": "France",
//       "Latitude": 9.406000137329102,
//       "Location": "Solenzara",
//       "Longitude": 41.924400329589844,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1327,
//       "Airport Code [ICAO]": "LFKT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Corte Airport",
//       "Altitude (Ft)": 1132,
//       "Country": "France",
//       "Latitude": 9.193059921264648,
//       "Location": "Corte",
//       "Longitude": 42.29359817504883,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1328,
//       "Airport Code [ICAO]": "LFLA",
//       "Airport Code iATA,FAA": "AUF",
//       "Airport Name": "Auxerre-Branches Airport",
//       "Altitude (Ft)": 523,
//       "Country": "France",
//       "Latitude": 3.497109889984131,
//       "Location": "Auxerre",
//       "Longitude": 47.85020065307617,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1329,
//       "Airport Code [ICAO]": "LFLB",
//       "Airport Code iATA,FAA": "CMF",
//       "Airport Name": "Chambéry-Savoie Airport",
//       "Altitude (Ft)": 779,
//       "Country": "France",
//       "Latitude": 5.880229949951172,
//       "Location": "Chambery",
//       "Longitude": 45.638099670410156,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1330,
//       "Airport Code [ICAO]": "LFLC",
//       "Airport Code iATA,FAA": "CFE",
//       "Airport Name": "Clermont-Ferrand Auvergne Airport",
//       "Altitude (Ft)": 1090,
//       "Country": "France",
//       "Latitude": 3.1691699028,
//       "Location": "Clermont-Ferrand",
//       "Longitude": 45.7867012024,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1331,
//       "Airport Code [ICAO]": "LFLD",
//       "Airport Code iATA,FAA": "BOU",
//       "Airport Name": "Bourges Airport",
//       "Altitude (Ft)": 529,
//       "Country": "France",
//       "Latitude": 2.3702800273895264,
//       "Location": "Bourges",
//       "Longitude": 47.058101654052734,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1332,
//       "Airport Code [ICAO]": "LFLE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Chambéry-Challes-les-Eaux Airport",
//       "Altitude (Ft)": 973,
//       "Country": "France",
//       "Latitude": 5.975759983060001,
//       "Location": "Chambery",
//       "Longitude": 45.5611000061,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1333,
//       "Airport Code [ICAO]": "LFLH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Chalon-Champforgeuil Airport",
//       "Altitude (Ft)": 623,
//       "Country": "France",
//       "Latitude": 4.817629814147949,
//       "Location": "Chalon",
//       "Longitude": 46.82609939575195,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1334,
//       "Airport Code [ICAO]": "LFLI",
//       "Airport Code iATA,FAA": "QNJ",
//       "Airport Name": "Annemasse Airport",
//       "Altitude (Ft)": 1620,
//       "Country": "France",
//       "Latitude": 6.268390178680001,
//       "Location": "Annemasse",
//       "Longitude": 46.1920013428,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1335,
//       "Airport Code [ICAO]": "LFLL",
//       "Airport Code iATA,FAA": "LYS",
//       "Airport Name": "Lyon Saint-Exupéry Airport",
//       "Altitude (Ft)": 821,
//       "Country": "France",
//       "Latitude": 5.081111,
//       "Location": "Lyon",
//       "Longitude": 45.725556,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1336,
//       "Airport Code [ICAO]": "LFLM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mâcon-Charnay Airport",
//       "Altitude (Ft)": 728,
//       "Country": "France",
//       "Latitude": 4.79577,
//       "Location": "Macon",
//       "Longitude": 46.295101,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1337,
//       "Airport Code [ICAO]": "LFLN",
//       "Airport Code iATA,FAA": "SYT",
//       "Airport Name": "Saint-Yan Airport",
//       "Altitude (Ft)": 796,
//       "Country": "France",
//       "Latitude": 4.0132598876953125,
//       "Location": "St.-yan",
//       "Longitude": 46.412498474121094,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1338,
//       "Airport Code [ICAO]": "LFLO",
//       "Airport Code iATA,FAA": "RNE",
//       "Airport Name": "Roanne-Renaison Airport",
//       "Altitude (Ft)": 1106,
//       "Country": "France",
//       "Latitude": 4.001389980316162,
//       "Location": "Roanne",
//       "Longitude": 46.05830001831055,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1339,
//       "Airport Code [ICAO]": "LFLP",
//       "Airport Code iATA,FAA": "NCY",
//       "Airport Name": "Annecy-Haute-Savoie-Mont Blanc Airport",
//       "Altitude (Ft)": 1521,
//       "Country": "France",
//       "Latitude": 6.1063889,
//       "Location": "Annecy",
//       "Longitude": 45.9308333,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1340,
//       "Airport Code [ICAO]": "LFLS",
//       "Airport Code iATA,FAA": "GNB",
//       "Airport Name": "Grenoble-Isère Airport",
//       "Altitude (Ft)": 1302,
//       "Country": "France",
//       "Latitude": 5.329370021820068,
//       "Location": "Grenoble",
//       "Longitude": 45.36289978027344,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1341,
//       "Airport Code [ICAO]": "LFLT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Montluçon-Domérat Airport",
//       "Altitude (Ft)": 771,
//       "Country": "France",
//       "Latitude": 2.57049,
//       "Location": "Montlucon",
//       "Longitude": 46.352501,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1342,
//       "Airport Code [ICAO]": "LFLU",
//       "Airport Code iATA,FAA": "VAF",
//       "Airport Name": "Valence-Chabeuil Airport",
//       "Altitude (Ft)": 525,
//       "Country": "France",
//       "Latitude": 4.9699,
//       "Location": "Valence",
//       "Longitude": 44.9216,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1343,
//       "Airport Code [ICAO]": "LFLV",
//       "Airport Code iATA,FAA": "VHY",
//       "Airport Name": "Vichy-Charmeil Airport",
//       "Altitude (Ft)": 817,
//       "Country": "France",
//       "Latitude": 3.4037399291992188,
//       "Location": "Vichy",
//       "Longitude": 46.169700622558594,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1344,
//       "Airport Code [ICAO]": "LFLW",
//       "Airport Code iATA,FAA": "AUR",
//       "Airport Name": "Aurillac Airport",
//       "Altitude (Ft)": 2096,
//       "Country": "France",
//       "Latitude": 2.4219400882720947,
//       "Location": "Aurillac",
//       "Longitude": 44.89139938354492,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1345,
//       "Airport Code [ICAO]": "LFLX",
//       "Airport Code iATA,FAA": "CHR",
//       "Airport Name": "Châteauroux-Déols \"Marcel Dassault\" Airport",
//       "Altitude (Ft)": 529,
//       "Country": "France",
//       "Latitude": 1.721111,
//       "Location": "Chateauroux",
//       "Longitude": 46.860278,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1346,
//       "Airport Code [ICAO]": "LFLY",
//       "Airport Code iATA,FAA": "LYN",
//       "Airport Name": "Lyon-Bron Airport",
//       "Altitude (Ft)": 659,
//       "Country": "France",
//       "Latitude": 4.944270133972168,
//       "Location": "Lyon",
//       "Longitude": 45.72719955444336,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1347,
//       "Airport Code [ICAO]": "LFMA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Aix-en-Provence (BA 114) Airport",
//       "Altitude (Ft)": 367,
//       "Country": "France",
//       "Latitude": 5.36778,
//       "Location": "Aix-les-milles",
//       "Longitude": 43.5056,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1348,
//       "Airport Code [ICAO]": "LFMC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Le Luc-Le Cannet Airport",
//       "Altitude (Ft)": 265,
//       "Country": "France",
//       "Latitude": 6.387139797210693,
//       "Location": "Le Luc",
//       "Longitude": 43.384700775146484,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1349,
//       "Airport Code [ICAO]": "LFMD",
//       "Airport Code iATA,FAA": "CEQ",
//       "Airport Name": "Cannes-Mandelieu Airport",
//       "Altitude (Ft)": 13,
//       "Country": "France",
//       "Latitude": 6.95348,
//       "Location": "Cannes",
//       "Longitude": 43.542,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1350,
//       "Airport Code [ICAO]": "LFMH",
//       "Airport Code iATA,FAA": "EBU",
//       "Airport Name": "Saint-Étienne-Bouthéon Airport",
//       "Altitude (Ft)": 1325,
//       "Country": "France",
//       "Latitude": 4.296390056610107,
//       "Location": "St-Etienne",
//       "Longitude": 45.54059982299805,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1351,
//       "Airport Code [ICAO]": "LFMI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Istres Le Tubé/Istres Air Base (BA 125) Airport",
//       "Altitude (Ft)": 82,
//       "Country": "France",
//       "Latitude": 4.92384,
//       "Location": "Istres",
//       "Longitude": 43.522701,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1352,
//       "Airport Code [ICAO]": "LFMK",
//       "Airport Code iATA,FAA": "CCF",
//       "Airport Name": "Carcassonne Airport",
//       "Altitude (Ft)": 433,
//       "Country": "France",
//       "Latitude": 2.3063199520111084,
//       "Location": "Carcassonne",
//       "Longitude": 43.215999603271484,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1353,
//       "Airport Code [ICAO]": "LFML",
//       "Airport Code iATA,FAA": "MRS",
//       "Airport Name": "Marseille Provence Airport",
//       "Altitude (Ft)": 74,
//       "Country": "France",
//       "Latitude": 5.22142410278,
//       "Location": "Marseille",
//       "Longitude": 43.439271922,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1354,
//       "Airport Code [ICAO]": "LFMN",
//       "Airport Code iATA,FAA": "NCE",
//       "Airport Name": "Nice-Côte d'Azur Airport",
//       "Altitude (Ft)": 12,
//       "Country": "France",
//       "Latitude": 7.215869903560001,
//       "Location": "Nice",
//       "Longitude": 43.6584014893,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1355,
//       "Airport Code [ICAO]": "LFMO",
//       "Airport Code iATA,FAA": "XOG",
//       "Airport Name": "Orange-Caritat (BA 115) Air Base",
//       "Altitude (Ft)": 197,
//       "Country": "France",
//       "Latitude": 4.866720199584961,
//       "Location": "Orange",
//       "Longitude": 44.140499114990234,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1356,
//       "Airport Code [ICAO]": "LFMP",
//       "Airport Code iATA,FAA": "PGF",
//       "Airport Name": "Perpignan-Rivesaltes (Llabanère) Airport",
//       "Altitude (Ft)": 144,
//       "Country": "France",
//       "Latitude": 2.8706700801849365,
//       "Location": "Perpignan",
//       "Longitude": 42.74039840698242,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1357,
//       "Airport Code [ICAO]": "LFMQ",
//       "Airport Code iATA,FAA": "CTT",
//       "Airport Name": "Le Castellet Airport",
//       "Altitude (Ft)": 1391,
//       "Country": "France",
//       "Latitude": 5.785190105438232,
//       "Location": "Le Castellet",
//       "Longitude": 43.252498626708984,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1358,
//       "Airport Code [ICAO]": "LFMS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Alès-Deaux Airport",
//       "Altitude (Ft)": 668,
//       "Country": "France",
//       "Latitude": 4.14212,
//       "Location": "Ales",
//       "Longitude": 44.069698,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1359,
//       "Airport Code [ICAO]": "LFMT",
//       "Airport Code iATA,FAA": "MPL",
//       "Airport Name": "Montpellier-Méditerranée Airport",
//       "Altitude (Ft)": 17,
//       "Country": "France",
//       "Latitude": 3.96301007270813,
//       "Location": "Montpellier",
//       "Longitude": 43.57619857788086,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1360,
//       "Airport Code [ICAO]": "LFMU",
//       "Airport Code iATA,FAA": "BZR",
//       "Airport Name": "Béziers-Vias Airport",
//       "Altitude (Ft)": 56,
//       "Country": "France",
//       "Latitude": 3.3538999557495117,
//       "Location": "Beziers",
//       "Longitude": 43.32350158691406,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1361,
//       "Airport Code [ICAO]": "LFMV",
//       "Airport Code iATA,FAA": "AVN",
//       "Airport Name": "Avignon-Caumont Airport",
//       "Altitude (Ft)": 124,
//       "Country": "France",
//       "Latitude": 4.901830196380615,
//       "Location": "Avignon",
//       "Longitude": 43.90729904174805,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1362,
//       "Airport Code [ICAO]": "LFMY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Salon-de-Provence (BA 701) Air Base",
//       "Altitude (Ft)": 195,
//       "Country": "France",
//       "Latitude": 5.109250068664551,
//       "Location": "Salon",
//       "Longitude": 43.60639953613281,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1363,
//       "Airport Code [ICAO]": "LFMZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lézignan-Corbières Airfield",
//       "Altitude (Ft)": 207,
//       "Country": "France",
//       "Latitude": 2.73417,
//       "Location": "Lezignan-corbieres",
//       "Longitude": 43.1758,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1364,
//       "Airport Code [ICAO]": "LFNB",
//       "Airport Code iATA,FAA": "MEN",
//       "Airport Name": "Mende-Brenoux Airfield",
//       "Altitude (Ft)": 3362,
//       "Country": "France",
//       "Latitude": 3.53282,
//       "Location": "Mende",
//       "Longitude": 44.502102,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1365,
//       "Airport Code [ICAO]": "LFNH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Carpentras Airport",
//       "Altitude (Ft)": 394,
//       "Country": "France",
//       "Latitude": 5.078060150146484,
//       "Location": "Carpentras",
//       "Longitude": 44.02980041503906,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1366,
//       "Airport Code [ICAO]": "LFOA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Avord (BA 702) Air Base",
//       "Altitude (Ft)": 580,
//       "Country": "France",
//       "Latitude": 2.6325,
//       "Location": "Avord",
//       "Longitude": 47.053299,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1367,
//       "Airport Code [ICAO]": "LFOB",
//       "Airport Code iATA,FAA": "BVA",
//       "Airport Name": "Paris Beauvais Tillé Airport",
//       "Altitude (Ft)": 359,
//       "Country": "France",
//       "Latitude": 2.1127800941467285,
//       "Location": "Beauvais",
//       "Longitude": 49.45439910888672,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1368,
//       "Airport Code [ICAO]": "LFOC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Châteaudun (BA 279) Air Base",
//       "Altitude (Ft)": 433,
//       "Country": "France",
//       "Latitude": 1.37662,
//       "Location": "Chateaudun",
//       "Longitude": 48.058102,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1369,
//       "Airport Code [ICAO]": "LFOD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Saumur-Saint-Florent Airport",
//       "Altitude (Ft)": 269,
//       "Country": "France",
//       "Latitude": -0.115142,
//       "Location": "Saumur",
//       "Longitude": 47.256802,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1370,
//       "Airport Code [ICAO]": "LFOE",
//       "Airport Code iATA,FAA": "EVX",
//       "Airport Name": "Évreux-Fauville (BA 105) Air Base",
//       "Altitude (Ft)": 464,
//       "Country": "France",
//       "Latitude": 1.2198599576950073,
//       "Location": "Evreux",
//       "Longitude": 49.02870178222656,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1371,
//       "Airport Code [ICAO]": "LFOH",
//       "Airport Code iATA,FAA": "LEH",
//       "Airport Name": "Le Havre Octeville Airport",
//       "Altitude (Ft)": 313,
//       "Country": "France",
//       "Latitude": 0.08805599808692932,
//       "Location": "Le Havre",
//       "Longitude": 49.53390121459961,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1372,
//       "Airport Code [ICAO]": "LFOI",
//       "Airport Code iATA,FAA": "XAB",
//       "Airport Name": "Abbeville",
//       "Altitude (Ft)": 220,
//       "Country": "France",
//       "Latitude": 1.831891,
//       "Location": "Abbeville",
//       "Longitude": 50.143501,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1373,
//       "Airport Code [ICAO]": "LFOJ",
//       "Airport Code iATA,FAA": "ORE",
//       "Airport Name": "Orléans-Bricy (BA 123) Air Base",
//       "Altitude (Ft)": 412,
//       "Country": "France",
//       "Latitude": 1.7605600357100002,
//       "Location": "Orleans",
//       "Longitude": 47.9878005981,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1374,
//       "Airport Code [ICAO]": "LFOK",
//       "Airport Code iATA,FAA": "XCR",
//       "Airport Name": "Châlons-Vatry Airport",
//       "Altitude (Ft)": 587,
//       "Country": "France",
//       "Latitude": 4.206111111,
//       "Location": "Chalons",
//       "Longitude": 48.7733333333,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1375,
//       "Airport Code [ICAO]": "LFOP",
//       "Airport Code iATA,FAA": "URO",
//       "Airport Name": "Rouen Airport",
//       "Altitude (Ft)": 512,
//       "Country": "France",
//       "Latitude": 1.1748000383377075,
//       "Location": "Rouen",
//       "Longitude": 49.38420104980469,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1376,
//       "Airport Code [ICAO]": "LFOT",
//       "Airport Code iATA,FAA": "TUF",
//       "Airport Name": "Tours-Val-de-Loire Airport",
//       "Altitude (Ft)": 357,
//       "Country": "France",
//       "Latitude": 0.727605998516,
//       "Location": "Tours",
//       "Longitude": 47.4322013855,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1377,
//       "Airport Code [ICAO]": "LFOU",
//       "Airport Code iATA,FAA": "CET",
//       "Airport Name": "Cholet Le Pontreau Airport",
//       "Altitude (Ft)": 443,
//       "Country": "France",
//       "Latitude": -0.8770639896392822,
//       "Location": "Cholet",
//       "Longitude": 47.08209991455078,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1378,
//       "Airport Code [ICAO]": "LFOV",
//       "Airport Code iATA,FAA": "LVA",
//       "Airport Name": "Laval-Entrammes Airport",
//       "Altitude (Ft)": 330,
//       "Country": "France",
//       "Latitude": -0.7429860234260559,
//       "Location": "Laval",
//       "Longitude": 48.03139877319336,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1379,
//       "Airport Code [ICAO]": "LFOZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Orléans-Saint-Denis-de-l'Hôtel Airport",
//       "Altitude (Ft)": 396,
//       "Country": "France",
//       "Latitude": 2.16333,
//       "Location": "Orleans",
//       "Longitude": 47.8969,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1380,
//       "Airport Code [ICAO]": "LFPB",
//       "Airport Code iATA,FAA": "LBG",
//       "Airport Name": "Paris-Le Bourget Airport",
//       "Altitude (Ft)": 218,
//       "Country": "France",
//       "Latitude": 2.441390037536621,
//       "Location": "Paris",
//       "Longitude": 48.969398498535156,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1381,
//       "Airport Code [ICAO]": "LFPC",
//       "Airport Code iATA,FAA": "CSF",
//       "Airport Name": "Creil Air Base",
//       "Altitude (Ft)": 291,
//       "Country": "France",
//       "Latitude": 2.5191400051116943,
//       "Location": "Creil",
//       "Longitude": 49.253501892089844,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1382,
//       "Airport Code [ICAO]": "LFPG",
//       "Airport Code iATA,FAA": "CDG",
//       "Airport Name": "Charles de Gaulle International Airport",
//       "Altitude (Ft)": 392,
//       "Country": "France",
//       "Latitude": 2.55,
//       "Location": "Paris",
//       "Longitude": 49.012798,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1383,
//       "Airport Code [ICAO]": "LFPK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Coulommiers-Voisins Airport",
//       "Altitude (Ft)": 470,
//       "Country": "France",
//       "Latitude": 3.016119956970215,
//       "Location": "Coulommiers",
//       "Longitude": 48.83769989013672,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1384,
//       "Airport Code [ICAO]": "LFPM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Melun-Villaroche Air Base",
//       "Altitude (Ft)": 302,
//       "Country": "France",
//       "Latitude": 2.6711199283599854,
//       "Location": "Melun",
//       "Longitude": 48.604698181152344,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1385,
//       "Airport Code [ICAO]": "LFPN",
//       "Airport Code iATA,FAA": "TNF",
//       "Airport Name": "Toussus-le-Noble Airport",
//       "Altitude (Ft)": 538,
//       "Country": "France",
//       "Latitude": 2.1061899662017822,
//       "Location": "Toussous-le-noble",
//       "Longitude": 48.75189971923828,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1386,
//       "Airport Code [ICAO]": "LFPO",
//       "Airport Code iATA,FAA": "ORY",
//       "Airport Name": "Paris-Orly Airport",
//       "Altitude (Ft)": 291,
//       "Country": "France",
//       "Latitude": 2.3794444,
//       "Location": "Paris",
//       "Longitude": 48.7233333,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1387,
//       "Airport Code [ICAO]": "LFPT",
//       "Airport Code iATA,FAA": "POX",
//       "Airport Name": "Pontoise - Cormeilles-en-Vexin Airport",
//       "Altitude (Ft)": 325,
//       "Country": "France",
//       "Latitude": 2.040833,
//       "Location": "Pontoise",
//       "Longitude": 49.096667,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1388,
//       "Airport Code [ICAO]": "LFPV",
//       "Airport Code iATA,FAA": "VIY",
//       "Airport Name": "Villacoublay-Vélizy (BA 107) Air Base",
//       "Altitude (Ft)": 584,
//       "Country": "France",
//       "Latitude": 2.1916667,
//       "Location": "Villacoublay",
//       "Longitude": 48.7741667,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1389,
//       "Airport Code [ICAO]": "LFQA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Reims-Prunay Airport",
//       "Altitude (Ft)": 313,
//       "Country": "France",
//       "Latitude": 4.156579971313477,
//       "Location": "Reims",
//       "Longitude": 49.20869827270508,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1390,
//       "Airport Code [ICAO]": "LFQB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Troyes-Barberey Airport",
//       "Altitude (Ft)": 388,
//       "Country": "France",
//       "Latitude": 4.0167,
//       "Location": "Troyes",
//       "Longitude": 48.322102,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1391,
//       "Airport Code [ICAO]": "LFQC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lunéville-Croismare Airport",
//       "Altitude (Ft)": 790,
//       "Country": "France",
//       "Latitude": 6.543459892272949,
//       "Location": "Luneville",
//       "Longitude": 48.593299865722656,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1392,
//       "Airport Code [ICAO]": "LFQE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Étain-Rouvres Air Base",
//       "Altitude (Ft)": 770,
//       "Country": "France",
//       "Latitude": 5.672220230102539,
//       "Location": "Etain",
//       "Longitude": 49.226898193359375,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1393,
//       "Airport Code [ICAO]": "LFQF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Autun-Bellevue Airport",
//       "Altitude (Ft)": 997,
//       "Country": "France",
//       "Latitude": 4.260570049285889,
//       "Location": "Autun",
//       "Longitude": 46.96730041503906,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1394,
//       "Airport Code [ICAO]": "LFQG",
//       "Airport Code iATA,FAA": "NVS",
//       "Airport Name": "Nevers-Fourchambault Airport",
//       "Altitude (Ft)": 602,
//       "Country": "France",
//       "Latitude": 3.1133298873901367,
//       "Location": "Nevers",
//       "Longitude": 47.002601623535156,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1395,
//       "Airport Code [ICAO]": "LFQI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cambrai-Épinoy (BA 103) Air Base",
//       "Altitude (Ft)": 257,
//       "Country": "France",
//       "Latitude": 3.15424,
//       "Location": "Cambrai",
//       "Longitude": 50.221802,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1396,
//       "Airport Code [ICAO]": "LFQJ",
//       "Airport Code iATA,FAA": "XME",
//       "Airport Name": "Maubeuge-Élesmes Airport",
//       "Altitude (Ft)": 452,
//       "Country": "France",
//       "Latitude": 4.033120155334473,
//       "Location": "Maubeuge",
//       "Longitude": 50.31050109863281,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1397,
//       "Airport Code [ICAO]": "LFQM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Besançon-La Vèze Airport",
//       "Altitude (Ft)": 1271,
//       "Country": "France",
//       "Latitude": 6.083681,
//       "Location": "Besancon-la-veze",
//       "Longitude": 47.2066,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1398,
//       "Airport Code [ICAO]": "LFQP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Phalsbourg-Bourscheid Air Base",
//       "Altitude (Ft)": 1017,
//       "Country": "France",
//       "Latitude": 7.205,
//       "Location": "Phalsbourg",
//       "Longitude": 48.7680556,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1399,
//       "Airport Code [ICAO]": "LFQQ",
//       "Airport Code iATA,FAA": "LIL",
//       "Airport Name": "Lille-Lesquin Airport",
//       "Altitude (Ft)": 157,
//       "Country": "France",
//       "Latitude": 3.086886,
//       "Location": "Lille",
//       "Longitude": 50.563332,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1400,
//       "Airport Code [ICAO]": "LFQT",
//       "Airport Code iATA,FAA": "HZB",
//       "Airport Name": "Merville-Calonne Airport",
//       "Altitude (Ft)": 61,
//       "Country": "France",
//       "Latitude": 2.642240047454834,
//       "Location": "Merville",
//       "Longitude": 50.61840057373047,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1401,
//       "Airport Code [ICAO]": "LFQV",
//       "Airport Code iATA,FAA": "XCZ",
//       "Airport Name": "Charleville-Mézières Airport",
//       "Altitude (Ft)": 492,
//       "Country": "France",
//       "Latitude": 4.647079944610596,
//       "Location": "Charleville",
//       "Longitude": 49.78390121459961,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1402,
//       "Airport Code [ICAO]": "LFQW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Vesoul-Frotey Airport",
//       "Altitude (Ft)": 1249,
//       "Country": "France",
//       "Latitude": 6.20392,
//       "Location": "Vesoul-frotey",
//       "Longitude": 47.6376,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1403,
//       "Airport Code [ICAO]": "LFRB",
//       "Airport Code iATA,FAA": "BES",
//       "Airport Name": "Brest Bretagne Airport",
//       "Altitude (Ft)": 325,
//       "Country": "France",
//       "Latitude": -4.418540000915527,
//       "Location": "Brest",
//       "Longitude": 48.447898864746094,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1404,
//       "Airport Code [ICAO]": "LFRC",
//       "Airport Code iATA,FAA": "CER",
//       "Airport Name": "Cherbourg-Maupertus Airport",
//       "Altitude (Ft)": 459,
//       "Country": "France",
//       "Latitude": -1.4702800512313843,
//       "Location": "Cherbourg",
//       "Longitude": 49.65010070800781,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1405,
//       "Airport Code [ICAO]": "LFRD",
//       "Airport Code iATA,FAA": "DNR",
//       "Airport Name": "Dinard-Pleurtuit-Saint-Malo Airport",
//       "Altitude (Ft)": 219,
//       "Country": "France",
//       "Latitude": -2.0799601078033447,
//       "Location": "Dinard",
//       "Longitude": 48.58769989013672,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1406,
//       "Airport Code [ICAO]": "LFRE",
//       "Airport Code iATA,FAA": "LBY",
//       "Airport Name": "La Baule-Escoublac Airport",
//       "Altitude (Ft)": 105,
//       "Country": "France",
//       "Latitude": -2.3463900089263916,
//       "Location": "La Baule",
//       "Longitude": 47.289398193359375,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1407,
//       "Airport Code [ICAO]": "LFRF",
//       "Airport Code iATA,FAA": "GFR",
//       "Airport Name": "Granville Airport",
//       "Altitude (Ft)": 45,
//       "Country": "France",
//       "Latitude": -1.564170002937317,
//       "Location": "Granville",
//       "Longitude": 48.88309860229492,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1408,
//       "Airport Code [ICAO]": "LFRG",
//       "Airport Code iATA,FAA": "DOL",
//       "Airport Name": "Deauville-Saint-Gatien Airport",
//       "Altitude (Ft)": 479,
//       "Country": "France",
//       "Latitude": 0.154305994511,
//       "Location": "Deauville",
//       "Longitude": 49.3652992249,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1409,
//       "Airport Code [ICAO]": "LFRH",
//       "Airport Code iATA,FAA": "LRT",
//       "Airport Name": "Lorient South Brittany (Bretagne Sud) Airport",
//       "Altitude (Ft)": 160,
//       "Country": "France",
//       "Latitude": -3.440000057220459,
//       "Location": "Lorient",
//       "Longitude": 47.76060104370117,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1410,
//       "Airport Code [ICAO]": "LFRI",
//       "Airport Code iATA,FAA": "EDM",
//       "Airport Name": "La Roche-sur-Yon Airport",
//       "Altitude (Ft)": 299,
//       "Country": "France",
//       "Latitude": -1.3786300420761108,
//       "Location": "La Roche-sur-yon",
//       "Longitude": 46.701900482177734,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1411,
//       "Airport Code [ICAO]": "LFRJ",
//       "Airport Code iATA,FAA": "LDV",
//       "Airport Name": "Landivisiau Air Base",
//       "Altitude (Ft)": 348,
//       "Country": "France",
//       "Latitude": -4.151639938354492,
//       "Location": "Landivisiau",
//       "Longitude": 48.53030014038086,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1412,
//       "Airport Code [ICAO]": "LFRK",
//       "Airport Code iATA,FAA": "CFR",
//       "Airport Name": "Caen-Carpiquet Airport",
//       "Altitude (Ft)": 256,
//       "Country": "France",
//       "Latitude": -0.44999998807907104,
//       "Location": "Caen",
//       "Longitude": 49.173301696777344,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1413,
//       "Airport Code [ICAO]": "LFRL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lanvéoc-Poulmic Air Base",
//       "Altitude (Ft)": 287,
//       "Country": "France",
//       "Latitude": -4.4450201988220215,
//       "Location": "Lanvedoc",
//       "Longitude": 48.281700134277344,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1414,
//       "Airport Code [ICAO]": "LFRM",
//       "Airport Code iATA,FAA": "LME",
//       "Airport Name": "Le Mans-Arnage Airport",
//       "Altitude (Ft)": 194,
//       "Country": "France",
//       "Latitude": 0.20166699588298798,
//       "Location": "Le Mans",
//       "Longitude": 47.94860076904297,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1415,
//       "Airport Code [ICAO]": "LFRN",
//       "Airport Code iATA,FAA": "RNS",
//       "Airport Name": "Rennes-Saint-Jacques Airport",
//       "Altitude (Ft)": 124,
//       "Country": "France",
//       "Latitude": -1.73478996754,
//       "Location": "Rennes",
//       "Longitude": 48.069499969499994,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1416,
//       "Airport Code [ICAO]": "LFRO",
//       "Airport Code iATA,FAA": "LAI",
//       "Airport Name": "Lannion-Côte de Granit Airport",
//       "Altitude (Ft)": 290,
//       "Country": "France",
//       "Latitude": -3.47166,
//       "Location": "Lannion",
//       "Longitude": 48.754398,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1417,
//       "Airport Code [ICAO]": "LFRQ",
//       "Airport Code iATA,FAA": "UIP",
//       "Airport Name": "Quimper-Cornouaille Airport",
//       "Altitude (Ft)": 297,
//       "Country": "France",
//       "Latitude": -4.167789936065674,
//       "Location": "Quimper",
//       "Longitude": 47.974998474121094,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1418,
//       "Airport Code [ICAO]": "LFRS",
//       "Airport Code iATA,FAA": "NTE",
//       "Airport Name": "Nantes Atlantique Airport",
//       "Altitude (Ft)": 90,
//       "Country": "France",
//       "Latitude": -1.61073005199,
//       "Location": "Nantes",
//       "Longitude": 47.153198242200006,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1419,
//       "Airport Code [ICAO]": "LFRT",
//       "Airport Code iATA,FAA": "SBK",
//       "Airport Name": "Saint-Brieuc-Armor Airport",
//       "Altitude (Ft)": 453,
//       "Country": "France",
//       "Latitude": -2.85444,
//       "Location": "St.-brieuc Armor",
//       "Longitude": 48.5378,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1420,
//       "Airport Code [ICAO]": "LFRU",
//       "Airport Code iATA,FAA": "MXN",
//       "Airport Name": "Morlaix-Ploujean Airport",
//       "Altitude (Ft)": 272,
//       "Country": "France",
//       "Latitude": -3.81577992439,
//       "Location": "Morlaix",
//       "Longitude": 48.6031990051,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1421,
//       "Airport Code [ICAO]": "LFRV",
//       "Airport Code iATA,FAA": "VNE",
//       "Airport Name": "Vannes-Meucon Airport",
//       "Altitude (Ft)": 446,
//       "Country": "France",
//       "Latitude": -2.718559980392456,
//       "Location": "Vannes",
//       "Longitude": 47.72330093383789,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1422,
//       "Airport Code [ICAO]": "LFRZ",
//       "Airport Code iATA,FAA": "SNR",
//       "Airport Name": "Saint-Nazaire-Montoir Airport",
//       "Altitude (Ft)": 13,
//       "Country": "France",
//       "Latitude": -2.1566667,
//       "Location": "St.-nazaire",
//       "Longitude": 47.3105556,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1423,
//       "Airport Code [ICAO]": "LFSB",
//       "Airport Code iATA,FAA": "BSL",
//       "Airport Name": "EuroAirport Basel-Mulhouse-Freiburg Airport",
//       "Altitude (Ft)": 885,
//       "Country": "France",
//       "Latitude": 7.5291667,
//       "Location": "Mulhouse",
//       "Longitude": 47.59,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1424,
//       "Airport Code [ICAO]": "LFSC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Colmar-Meyenheim Air Base",
//       "Altitude (Ft)": 693,
//       "Country": "France",
//       "Latitude": 7.39967012405,
//       "Location": "Colmar",
//       "Longitude": 47.922000885,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1425,
//       "Airport Code [ICAO]": "LFSD",
//       "Airport Code iATA,FAA": "DIJ",
//       "Airport Name": "Dijon-Bourgogne Airport",
//       "Altitude (Ft)": 726,
//       "Country": "France",
//       "Latitude": 5.09,
//       "Location": "Dijon",
//       "Longitude": 47.268902,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1426,
//       "Airport Code [ICAO]": "LFSF",
//       "Airport Code iATA,FAA": "MZM",
//       "Airport Name": "Metz-Frescaty (BA 128) Air Base",
//       "Altitude (Ft)": 629,
//       "Country": "France",
//       "Latitude": 6.131669998168945,
//       "Location": "Metz",
//       "Longitude": 49.07170104980469,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1427,
//       "Airport Code [ICAO]": "LFSG",
//       "Airport Code iATA,FAA": "EPL",
//       "Airport Name": "Épinal-Mirecourt Airport",
//       "Altitude (Ft)": 1084,
//       "Country": "France",
//       "Latitude": 6.06998,
//       "Location": "Epinal",
//       "Longitude": 48.325001,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1428,
//       "Airport Code [ICAO]": "LFSH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Haguenau Airport",
//       "Altitude (Ft)": 491,
//       "Country": "France",
//       "Latitude": 7.81760978699,
//       "Location": "Haguenau",
//       "Longitude": 48.7943000793,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1429,
//       "Airport Code [ICAO]": "LFSI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Saint-Dizier-Robinson (BA 113) Air Base",
//       "Altitude (Ft)": 458,
//       "Country": "France",
//       "Latitude": 4.899419784545898,
//       "Location": "St.-dizier",
//       "Longitude": 48.63600158691406,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1430,
//       "Airport Code [ICAO]": "LFSM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Montbéliard-Courcelles Airfield",
//       "Altitude (Ft)": 1041,
//       "Country": "France",
//       "Latitude": 6.79054,
//       "Location": "Montbeliard",
//       "Longitude": 47.487,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1431,
//       "Airport Code [ICAO]": "LFSN",
//       "Airport Code iATA,FAA": "ENC",
//       "Airport Name": "Nancy-Essey Airport",
//       "Altitude (Ft)": 751,
//       "Country": "France",
//       "Latitude": 6.230460166931152,
//       "Location": "Nancy",
//       "Longitude": 48.692100524902344,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1432,
//       "Airport Code [ICAO]": "LFSO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nancy-Ochey (BA 133) Air Base",
//       "Altitude (Ft)": 1106,
//       "Country": "France",
//       "Latitude": 5.954999923706055,
//       "Location": "Nancy",
//       "Longitude": 48.583099365234375,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1433,
//       "Airport Code [ICAO]": "LFSP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Pontarlier Airport",
//       "Altitude (Ft)": 2683,
//       "Country": "France",
//       "Latitude": 6.3273701667785645,
//       "Location": "Pontarlier",
//       "Longitude": 46.90399932861328,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1434,
//       "Airport Code [ICAO]": "LFSR",
//       "Airport Code iATA,FAA": "RHE",
//       "Airport Name": "Reims-Champagne (BA 112) Air Base",
//       "Altitude (Ft)": 312,
//       "Country": "France",
//       "Latitude": 4.05,
//       "Location": "Reims",
//       "Longitude": 49.310001,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1435,
//       "Airport Code [ICAO]": "LFST",
//       "Airport Code iATA,FAA": "SXB",
//       "Airport Name": "Strasbourg Airport",
//       "Altitude (Ft)": 505,
//       "Country": "France",
//       "Latitude": 7.628230094909668,
//       "Location": "Strasbourg",
//       "Longitude": 48.538299560546875,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1436,
//       "Airport Code [ICAO]": "LFSX",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Luxeuil-Saint-Sauveur (BA 116) Air Base",
//       "Altitude (Ft)": 913,
//       "Country": "France",
//       "Latitude": 6.36416667,
//       "Location": "Luxeuil",
//       "Longitude": 47.7830556,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1437,
//       "Airport Code [ICAO]": "LFTF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cuers-Pierrefeu Airport",
//       "Altitude (Ft)": 266,
//       "Country": "France",
//       "Latitude": 6.126699924468994,
//       "Location": "Cuers",
//       "Longitude": 43.247798919677734,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1438,
//       "Airport Code [ICAO]": "LFTH",
//       "Airport Code iATA,FAA": "TLN",
//       "Airport Name": "Toulon-Hyères Airport",
//       "Altitude (Ft)": 7,
//       "Country": "France",
//       "Latitude": 6.14602994919,
//       "Location": "Hyeres",
//       "Longitude": 43.0973014832,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1439,
//       "Airport Code [ICAO]": "LFTW",
//       "Airport Code iATA,FAA": "FNI",
//       "Airport Name": "Nîmes-Arles-Camargue Airport",
//       "Altitude (Ft)": 309,
//       "Country": "France",
//       "Latitude": 4.4163498878479,
//       "Location": "Nimes",
//       "Longitude": 43.75740051269531,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1440,
//       "Airport Code [ICAO]": "LFVM",
//       "Airport Code iATA,FAA": "MQC",
//       "Airport Name": "Miquelon Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Saint Pierre and Miquelon",
//       "Latitude": -56.3802986145,
//       "Location": "Miquelon",
//       "Longitude": 47.095500946,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "America/Miquelon",
//       "Type": "airport"
//     },
//     {
//       "ID": 1441,
//       "Airport Code [ICAO]": "LFVP",
//       "Airport Code iATA,FAA": "FSP",
//       "Airport Name": "St Pierre Airport",
//       "Altitude (Ft)": 27,
//       "Country": "Saint Pierre and Miquelon",
//       "Latitude": -56.173099517822266,
//       "Location": "St.-pierre",
//       "Longitude": 46.762901306152344,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "America/Miquelon",
//       "Type": "airport"
//     },
//     {
//       "ID": 1442,
//       "Airport Code [ICAO]": "LFXA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ambérieu Air Base (BA 278)",
//       "Altitude (Ft)": 823,
//       "Country": "France",
//       "Latitude": 5.32844,
//       "Location": "Amberieu",
//       "Longitude": 45.987301,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1443,
//       "Airport Code [ICAO]": "LFYD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Damblain Airport",
//       "Altitude (Ft)": 1280,
//       "Country": "France",
//       "Latitude": 5.664060115814209,
//       "Location": "Damblain",
//       "Longitude": 48.086299896240234,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1444,
//       "Airport Code [ICAO]": "LGAD",
//       "Airport Code iATA,FAA": "PYR",
//       "Airport Name": "Andravida Air Base",
//       "Altitude (Ft)": 55,
//       "Country": "Greece",
//       "Latitude": 21.292601,
//       "Location": "Andravida",
//       "Longitude": 37.9207,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1445,
//       "Airport Code [ICAO]": "LGAG",
//       "Airport Code iATA,FAA": "AGQ",
//       "Airport Name": "Agrinion Air Base",
//       "Altitude (Ft)": 154,
//       "Country": "Greece",
//       "Latitude": 21.3512001,
//       "Location": "Agrinion",
//       "Longitude": 38.602001,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1446,
//       "Airport Code [ICAO]": "LGAL",
//       "Airport Code iATA,FAA": "AXD",
//       "Airport Name": "Dimokritos Airport",
//       "Altitude (Ft)": 24,
//       "Country": "Greece",
//       "Latitude": 25.956300735473633,
//       "Location": "Alexandroupolis",
//       "Longitude": 40.855899810791016,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1447,
//       "Airport Code [ICAO]": "LGAX",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Alexandria Airport",
//       "Altitude (Ft)": 27,
//       "Country": "Greece",
//       "Latitude": 22.48870086669922,
//       "Location": "Alexandria",
//       "Longitude": 40.651100158691406,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1448,
//       "Airport Code [ICAO]": "LGBL",
//       "Airport Code iATA,FAA": "VOL",
//       "Airport Name": "Nea Anchialos Airport",
//       "Altitude (Ft)": 83,
//       "Country": "Greece",
//       "Latitude": 22.794300079345703,
//       "Location": "Nea Anghialos",
//       "Longitude": 39.219600677490234,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1449,
//       "Airport Code [ICAO]": "LGEL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Elefsis Airport",
//       "Altitude (Ft)": 143,
//       "Country": "Greece",
//       "Latitude": 23.555999755859375,
//       "Location": "Elefsis",
//       "Longitude": 38.06380081176758,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1450,
//       "Airport Code [ICAO]": "LGHI",
//       "Airport Code iATA,FAA": "JKH",
//       "Airport Name": "Chios Island National Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Greece",
//       "Latitude": 26.140600204467773,
//       "Location": "Chios",
//       "Longitude": 38.34320068359375,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1451,
//       "Airport Code [ICAO]": "LGIO",
//       "Airport Code iATA,FAA": "IOA",
//       "Airport Name": "Ioannina Airport",
//       "Altitude (Ft)": 1558,
//       "Country": "Greece",
//       "Latitude": 20.822500228881836,
//       "Location": "Ioannina",
//       "Longitude": 39.6963996887207,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1452,
//       "Airport Code [ICAO]": "LGIR",
//       "Airport Code iATA,FAA": "HER",
//       "Airport Name": "Heraklion International Nikos Kazantzakis Airport",
//       "Altitude (Ft)": 115,
//       "Country": "Greece",
//       "Latitude": 25.180299758900002,
//       "Location": "Heraklion",
//       "Longitude": 35.3396987915,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1453,
//       "Airport Code [ICAO]": "LGKA",
//       "Airport Code iATA,FAA": "KSO",
//       "Airport Name": "Kastoria National Airport",
//       "Altitude (Ft)": 2167,
//       "Country": "Greece",
//       "Latitude": 21.2821998596,
//       "Location": "Kastoria",
//       "Longitude": 40.4463005066,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1454,
//       "Airport Code [ICAO]": "LGKC",
//       "Airport Code iATA,FAA": "KIT",
//       "Airport Name": "Kithira Airport",
//       "Altitude (Ft)": 1045,
//       "Country": "Greece",
//       "Latitude": 23.0170001984,
//       "Location": "Kithira",
//       "Longitude": 36.2742996216,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1455,
//       "Airport Code [ICAO]": "LGKF",
//       "Airport Code iATA,FAA": "EFL",
//       "Airport Name": "Kefallinia Airport",
//       "Altitude (Ft)": 59,
//       "Country": "Greece",
//       "Latitude": 20.500499725341797,
//       "Location": "Keffallinia",
//       "Longitude": 38.12009811401367,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1456,
//       "Airport Code [ICAO]": "LGKL",
//       "Airport Code iATA,FAA": "KLX",
//       "Airport Name": "Kalamata Airport",
//       "Altitude (Ft)": 26,
//       "Country": "Greece",
//       "Latitude": 22.02549934387207,
//       "Location": "Kalamata",
//       "Longitude": 37.06829833984375,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1457,
//       "Airport Code [ICAO]": "LGKM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Amigdhaleon Airport",
//       "Altitude (Ft)": 203,
//       "Country": "Greece",
//       "Latitude": 24.341400146484375,
//       "Location": "Kavala",
//       "Longitude": 40.972801208496094,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1458,
//       "Airport Code [ICAO]": "LGKO",
//       "Airport Code iATA,FAA": "KGS",
//       "Airport Name": "Kos Airport",
//       "Altitude (Ft)": 412,
//       "Country": "Greece",
//       "Latitude": 27.091699600219727,
//       "Location": "Kos",
//       "Longitude": 36.79330062866211,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1459,
//       "Airport Code [ICAO]": "LGKP",
//       "Airport Code iATA,FAA": "AOK",
//       "Airport Name": "Karpathos Airport",
//       "Altitude (Ft)": 66,
//       "Country": "Greece",
//       "Latitude": 27.145999908447266,
//       "Location": "Karpathos",
//       "Longitude": 35.4213981628418,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1460,
//       "Airport Code [ICAO]": "LGKR",
//       "Airport Code iATA,FAA": "CFU",
//       "Airport Name": "Ioannis Kapodistrias International Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Greece",
//       "Latitude": 19.911699295043945,
//       "Location": "Kerkyra/corfu",
//       "Longitude": 39.601898193359375,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1461,
//       "Airport Code [ICAO]": "LGKS",
//       "Airport Code iATA,FAA": "KSJ",
//       "Airport Name": "Kasos Airport",
//       "Altitude (Ft)": 35,
//       "Country": "Greece",
//       "Latitude": 26.909999847399998,
//       "Location": "Kasos",
//       "Longitude": 35.4213981628,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1462,
//       "Airport Code [ICAO]": "LGKV",
//       "Airport Code iATA,FAA": "KVA",
//       "Airport Name": "Alexander the Great International Airport",
//       "Altitude (Ft)": 18,
//       "Country": "Greece",
//       "Latitude": 24.619199752807617,
//       "Location": "Kavala",
//       "Longitude": 40.913299560546875,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1463,
//       "Airport Code [ICAO]": "LGKZ",
//       "Airport Code iATA,FAA": "KZI",
//       "Airport Name": "Filippos Airport",
//       "Altitude (Ft)": 2059,
//       "Country": "Greece",
//       "Latitude": 21.84079933166504,
//       "Location": "Kozani",
//       "Longitude": 40.28609848022461,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1464,
//       "Airport Code [ICAO]": "LGLE",
//       "Airport Code iATA,FAA": "LRS",
//       "Airport Name": "Leros Airport",
//       "Altitude (Ft)": 39,
//       "Country": "Greece",
//       "Latitude": 26.800301,
//       "Location": "Leros",
//       "Longitude": 37.184898,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1465,
//       "Airport Code [ICAO]": "LGLM",
//       "Airport Code iATA,FAA": "LXS",
//       "Airport Name": "Limnos Airport",
//       "Altitude (Ft)": 14,
//       "Country": "Greece",
//       "Latitude": 25.236299514799995,
//       "Location": "Limnos",
//       "Longitude": 39.917098999,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1466,
//       "Airport Code [ICAO]": "LGLR",
//       "Airport Code iATA,FAA": "LRA",
//       "Airport Name": "Larisa Airport",
//       "Altitude (Ft)": 241,
//       "Country": "Greece",
//       "Latitude": 22.4655,
//       "Location": "Larissa",
//       "Longitude": 39.650253,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1467,
//       "Airport Code [ICAO]": "LGMG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Megara Airport",
//       "Altitude (Ft)": 12,
//       "Country": "Greece",
//       "Latitude": 23.365400314331055,
//       "Location": "Megara",
//       "Longitude": 37.98109817504883,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1468,
//       "Airport Code [ICAO]": "LGMK",
//       "Airport Code iATA,FAA": "JMK",
//       "Airport Name": "Mikonos Airport",
//       "Altitude (Ft)": 405,
//       "Country": "Greece",
//       "Latitude": 25.348100662231445,
//       "Location": "Mykonos",
//       "Longitude": 37.43510055541992,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1469,
//       "Airport Code [ICAO]": "LGMT",
//       "Airport Code iATA,FAA": "MJT",
//       "Airport Name": "Mytilene International Airport",
//       "Altitude (Ft)": 60,
//       "Country": "Greece",
//       "Latitude": 26.5983009338,
//       "Location": "Mytilini",
//       "Longitude": 39.0567016602,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1470,
//       "Airport Code [ICAO]": "LGPZ",
//       "Airport Code iATA,FAA": "PVK",
//       "Airport Name": "Aktion National Airport",
//       "Altitude (Ft)": 11,
//       "Country": "Greece",
//       "Latitude": 20.765300750732422,
//       "Location": "Preveza",
//       "Longitude": 38.925498962402344,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1471,
//       "Airport Code [ICAO]": "LGRD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Maritsa Airport",
//       "Altitude (Ft)": 204,
//       "Country": "Greece",
//       "Latitude": 28.10890007019043,
//       "Location": "Rhodos",
//       "Longitude": 36.38309860229492,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1472,
//       "Airport Code [ICAO]": "LGRP",
//       "Airport Code iATA,FAA": "RHO",
//       "Airport Name": "Diagoras Airport",
//       "Altitude (Ft)": 17,
//       "Country": "Greece",
//       "Latitude": 28.086200714111328,
//       "Location": "Rhodos",
//       "Longitude": 36.405399322509766,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1473,
//       "Airport Code [ICAO]": "LGRX",
//       "Airport Code iATA,FAA": "GPA",
//       "Airport Name": "Araxos Airport",
//       "Altitude (Ft)": 46,
//       "Country": "Greece",
//       "Latitude": 21.4256,
//       "Location": "Patras",
//       "Longitude": 38.1511,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1474,
//       "Airport Code [ICAO]": "LGSA",
//       "Airport Code iATA,FAA": "CHQ",
//       "Airport Name": "Chania International Airport",
//       "Altitude (Ft)": 490,
//       "Country": "Greece",
//       "Latitude": 24.149700164794922,
//       "Location": "Chania",
//       "Longitude": 35.531700134277344,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1475,
//       "Airport Code [ICAO]": "LGSK",
//       "Airport Code iATA,FAA": "JSI",
//       "Airport Name": "Skiathos Island National Airport",
//       "Altitude (Ft)": 54,
//       "Country": "Greece",
//       "Latitude": 23.503700256347656,
//       "Location": "Skiathos",
//       "Longitude": 39.177101135253906,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1476,
//       "Airport Code [ICAO]": "LGSM",
//       "Airport Code iATA,FAA": "SMI",
//       "Airport Name": "Samos Airport",
//       "Altitude (Ft)": 19,
//       "Country": "Greece",
//       "Latitude": 26.911699295043945,
//       "Location": "Samos",
//       "Longitude": 37.689998626708984,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1478,
//       "Airport Code [ICAO]": "LGSP",
//       "Airport Code iATA,FAA": "SPJ",
//       "Airport Name": "Sparti Airport",
//       "Altitude (Ft)": 500,
//       "Country": "Greece",
//       "Latitude": 22.52630043029785,
//       "Location": "Sparti",
//       "Longitude": 36.973899841308594,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1479,
//       "Airport Code [ICAO]": "LGSR",
//       "Airport Code iATA,FAA": "JTR",
//       "Airport Name": "Santorini Airport",
//       "Altitude (Ft)": 127,
//       "Country": "Greece",
//       "Latitude": 25.479299545288086,
//       "Location": "Thira",
//       "Longitude": 36.399200439453125,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1480,
//       "Airport Code [ICAO]": "LGST",
//       "Airport Code iATA,FAA": "JSH",
//       "Airport Name": "Sitia Airport",
//       "Altitude (Ft)": 376,
//       "Country": "Greece",
//       "Latitude": 26.101299285888672,
//       "Location": "Sitia",
//       "Longitude": 35.21609878540039,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1481,
//       "Airport Code [ICAO]": "LGSV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Stefanovikion Air Base",
//       "Altitude (Ft)": 146,
//       "Country": "Greece",
//       "Latitude": 22.7672,
//       "Location": "Stefanovikion",
//       "Longitude": 39.48,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1482,
//       "Airport Code [ICAO]": "LGSY",
//       "Airport Code iATA,FAA": "SKU",
//       "Airport Name": "Skiros Airport",
//       "Altitude (Ft)": 44,
//       "Country": "Greece",
//       "Latitude": 24.4871997833,
//       "Location": "Skiros",
//       "Longitude": 38.9676017761,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1483,
//       "Airport Code [ICAO]": "LGTG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tanagra Air Base",
//       "Altitude (Ft)": 485,
//       "Country": "Greece",
//       "Latitude": 23.565001,
//       "Location": "Tanagra",
//       "Longitude": 38.339802,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1484,
//       "Airport Code [ICAO]": "LGTL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kasteli Airport",
//       "Altitude (Ft)": 1180,
//       "Country": "Greece",
//       "Latitude": 25.327,
//       "Location": "Kasteli",
//       "Longitude": 35.192001,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1485,
//       "Airport Code [ICAO]": "LGTP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tripolis Airport",
//       "Altitude (Ft)": 2113,
//       "Country": "Greece",
//       "Latitude": 22.403600692749023,
//       "Location": "Tripolis",
//       "Longitude": 37.530601501464844,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1486,
//       "Airport Code [ICAO]": "LGTS",
//       "Airport Code iATA,FAA": "SKG",
//       "Airport Name": "Thessaloniki Macedonia International Airport",
//       "Altitude (Ft)": 22,
//       "Country": "Greece",
//       "Latitude": 22.97089958190918,
//       "Location": "Thessaloniki",
//       "Longitude": 40.51969909667969,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1487,
//       "Airport Code [ICAO]": "LGTT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tatoi Airport",
//       "Altitude (Ft)": 785,
//       "Country": "Greece",
//       "Latitude": 23.78380012512207,
//       "Location": "Dekelia",
//       "Longitude": 38.1088981628418,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1488,
//       "Airport Code [ICAO]": "LGZA",
//       "Airport Code iATA,FAA": "ZTH",
//       "Airport Name": "Zakynthos International Airport \"Dionysios Solomos\"",
//       "Altitude (Ft)": 15,
//       "Country": "Greece",
//       "Latitude": 20.8843,
//       "Location": "Zakynthos",
//       "Longitude": 37.7509,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 1489,
//       "Airport Code [ICAO]": "LHBP",
//       "Airport Code iATA,FAA": "BUD",
//       "Airport Name": "Budapest Liszt Ferenc International Airport",
//       "Altitude (Ft)": 495,
//       "Country": "Hungary",
//       "Latitude": 19.261093,
//       "Location": "Budapest",
//       "Longitude": 47.42976,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Budapest",
//       "Type": "airport"
//     },
//     {
//       "ID": 1490,
//       "Airport Code [ICAO]": "LHDC",
//       "Airport Code iATA,FAA": "DEB",
//       "Airport Name": "Debrecen International Airport",
//       "Altitude (Ft)": 359,
//       "Country": "Hungary",
//       "Latitude": 21.615299224853516,
//       "Location": "Debrecen",
//       "Longitude": 47.48889923095703,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Budapest",
//       "Type": "airport"
//     },
//     {
//       "ID": 1491,
//       "Airport Code [ICAO]": "LHKE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kecskemét Airport",
//       "Altitude (Ft)": 376,
//       "Country": "Hungary",
//       "Latitude": 19.74920082092285,
//       "Location": "Kecskemet",
//       "Longitude": 46.91749954223633,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Budapest",
//       "Type": "airport"
//     },
//     {
//       "ID": 1492,
//       "Airport Code [ICAO]": "LHNY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nyíregyháza Airport",
//       "Altitude (Ft)": 338,
//       "Country": "Hungary",
//       "Latitude": 21.69230079650879,
//       "Location": "Nyirregyhaza",
//       "Longitude": 47.9838981628418,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Budapest",
//       "Type": "airport"
//     },
//     {
//       "ID": 1493,
//       "Airport Code [ICAO]": "LHOY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Őcsény Airport",
//       "Altitude (Ft)": 295,
//       "Country": "Hungary",
//       "Latitude": 18.76919937133789,
//       "Location": "Ocseny",
//       "Longitude": 46.30390167236328,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Budapest",
//       "Type": "airport"
//     },
//     {
//       "ID": 1496,
//       "Airport Code [ICAO]": "LHSA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Szentkirályszabadja Airport",
//       "Altitude (Ft)": 919,
//       "Country": "Hungary",
//       "Latitude": 17.968399047851562,
//       "Location": "Azentkilyszabadja",
//       "Longitude": 47.07789993286133,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Budapest",
//       "Type": "airport"
//     },
//     {
//       "ID": 1498,
//       "Airport Code [ICAO]": "LHSN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Szolnok Air Base",
//       "Altitude (Ft)": 322,
//       "Country": "Hungary",
//       "Latitude": 20.2355,
//       "Location": "Szolnok",
//       "Longitude": 47.122897,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Budapest",
//       "Type": "airport"
//     },
//     {
//       "ID": 1499,
//       "Airport Code [ICAO]": "LIBA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Amendola Air Base",
//       "Altitude (Ft)": 183,
//       "Country": "Italy",
//       "Latitude": 15.7181,
//       "Location": "Amendola",
//       "Longitude": 41.541401,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1500,
//       "Airport Code [ICAO]": "LIBC",
//       "Airport Code iATA,FAA": "CRV",
//       "Airport Name": "Crotone Airport",
//       "Altitude (Ft)": 522,
//       "Country": "Italy",
//       "Latitude": 17.0802,
//       "Location": "Crotone",
//       "Longitude": 38.9972,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1501,
//       "Airport Code [ICAO]": "LIBD",
//       "Airport Code iATA,FAA": "BRI",
//       "Airport Name": "Bari Karol Wojtyła Airport",
//       "Altitude (Ft)": 177,
//       "Country": "Italy",
//       "Latitude": 16.760599,
//       "Location": "Bari",
//       "Longitude": 41.138901,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1502,
//       "Airport Code [ICAO]": "LIBF",
//       "Airport Code iATA,FAA": "FOG",
//       "Airport Name": "Foggia \"Gino Lisa\" Airport",
//       "Altitude (Ft)": 265,
//       "Country": "Italy",
//       "Latitude": 15.535,
//       "Location": "Foggia",
//       "Longitude": 41.432899,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1503,
//       "Airport Code [ICAO]": "LIBG",
//       "Airport Code iATA,FAA": "TAR",
//       "Airport Name": "Taranto-Grottaglie \"Marcello Arlotta\" Airport",
//       "Altitude (Ft)": 215,
//       "Country": "Italy",
//       "Latitude": 17.4032,
//       "Location": "Grottaglie",
//       "Longitude": 40.517502,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1504,
//       "Airport Code [ICAO]": "LIBN",
//       "Airport Code iATA,FAA": "LCC",
//       "Airport Name": "Lecce Galatina Air Base",
//       "Altitude (Ft)": 156,
//       "Country": "Italy",
//       "Latitude": 18.133301,
//       "Location": "Lecce",
//       "Longitude": 40.239201,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1505,
//       "Airport Code [ICAO]": "LIBP",
//       "Airport Code iATA,FAA": "PSR",
//       "Airport Name": "Pescara International Airport",
//       "Altitude (Ft)": 48,
//       "Country": "Italy",
//       "Latitude": 14.1811,
//       "Location": "Pescara",
//       "Longitude": 42.431702,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1506,
//       "Airport Code [ICAO]": "LIBR",
//       "Airport Code iATA,FAA": "BDS",
//       "Airport Name": "Brindisi – Salento Airport",
//       "Altitude (Ft)": 47,
//       "Country": "Italy",
//       "Latitude": 17.947001,
//       "Location": "Brindisi",
//       "Longitude": 40.6576,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1507,
//       "Airport Code [ICAO]": "LIBV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Gioia Del Colle Air Base",
//       "Altitude (Ft)": 1187,
//       "Country": "Italy",
//       "Latitude": 16.9333,
//       "Location": "Gioia Del Colle",
//       "Longitude": 40.767799,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1508,
//       "Airport Code [ICAO]": "LICA",
//       "Airport Code iATA,FAA": "SUF",
//       "Airport Name": "Lamezia Terme Airport",
//       "Altitude (Ft)": 39,
//       "Country": "Italy",
//       "Latitude": 16.2423,
//       "Location": "Lamezia",
//       "Longitude": 38.905399,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1509,
//       "Airport Code [ICAO]": "LICC",
//       "Airport Code iATA,FAA": "CTA",
//       "Airport Name": "Catania-Fontanarossa Airport",
//       "Altitude (Ft)": 39,
//       "Country": "Italy",
//       "Latitude": 15.0664,
//       "Location": "Catania",
//       "Longitude": 37.466801,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1510,
//       "Airport Code [ICAO]": "LICD",
//       "Airport Code iATA,FAA": "LMP",
//       "Airport Name": "Lampedusa Airport",
//       "Altitude (Ft)": 70,
//       "Country": "Italy",
//       "Latitude": 12.6181,
//       "Location": "Lampedusa",
//       "Longitude": 35.497898,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1511,
//       "Airport Code [ICAO]": "LICG",
//       "Airport Code iATA,FAA": "PNL",
//       "Airport Name": "Pantelleria Airport",
//       "Altitude (Ft)": 628,
//       "Country": "Italy",
//       "Latitude": 11.9689,
//       "Location": "Pantelleria",
//       "Longitude": 36.816502,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1512,
//       "Airport Code [ICAO]": "LICJ",
//       "Airport Code iATA,FAA": "PMO",
//       "Airport Name": "Falcone–Borsellino Airport",
//       "Altitude (Ft)": 65,
//       "Country": "Italy",
//       "Latitude": 13.091,
//       "Location": "Palermo",
//       "Longitude": 38.175999,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1513,
//       "Airport Code [ICAO]": "LICP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Palermo-Boccadifalco Airport",
//       "Altitude (Ft)": 345,
//       "Country": "Italy",
//       "Latitude": 13.3133,
//       "Location": "Palermo",
//       "Longitude": 38.110802,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1514,
//       "Airport Code [ICAO]": "LICR",
//       "Airport Code iATA,FAA": "REG",
//       "Airport Name": "Reggio Calabria Airport",
//       "Altitude (Ft)": 96,
//       "Country": "Italy",
//       "Latitude": 15.6516,
//       "Location": "Reggio Calabria",
//       "Longitude": 38.071201,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1515,
//       "Airport Code [ICAO]": "LICT",
//       "Airport Code iATA,FAA": "TPS",
//       "Airport Name": "Vincenzo Florio Airport Trapani-Birgi",
//       "Altitude (Ft)": 25,
//       "Country": "Italy",
//       "Latitude": 12.488,
//       "Location": "Trapani",
//       "Longitude": 37.9114,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1516,
//       "Airport Code [ICAO]": "LICZ",
//       "Airport Code iATA,FAA": "NSY",
//       "Airport Name": "Sigonella Navy Air Base",
//       "Altitude (Ft)": 79,
//       "Country": "Italy",
//       "Latitude": 14.9224,
//       "Location": "Sigonella",
//       "Longitude": 37.401699,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1517,
//       "Airport Code [ICAO]": "LIEA",
//       "Airport Code iATA,FAA": "AHO",
//       "Airport Name": "Alghero-Fertilia Airport",
//       "Altitude (Ft)": 87,
//       "Country": "Italy",
//       "Latitude": 8.29077,
//       "Location": "Alghero",
//       "Longitude": 40.632099,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1518,
//       "Airport Code [ICAO]": "LIED",
//       "Airport Code iATA,FAA": "DCI",
//       "Airport Name": "Decimomannu Air Base",
//       "Altitude (Ft)": 100,
//       "Country": "Italy",
//       "Latitude": 8.97248,
//       "Location": "Decimomannu",
//       "Longitude": 39.354198,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1519,
//       "Airport Code [ICAO]": "LIEE",
//       "Airport Code iATA,FAA": "CAG",
//       "Airport Name": "Cagliari Elmas Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Italy",
//       "Latitude": 9.05428,
//       "Location": "Cagliari",
//       "Longitude": 39.251499,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1520,
//       "Airport Code [ICAO]": "LIEO",
//       "Airport Code iATA,FAA": "OLB",
//       "Airport Name": "Olbia Costa Smeralda Airport",
//       "Altitude (Ft)": 37,
//       "Country": "Italy",
//       "Latitude": 9.51763,
//       "Location": "Olbia",
//       "Longitude": 40.898701,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1521,
//       "Airport Code [ICAO]": "LIET",
//       "Airport Code iATA,FAA": "TTB",
//       "Airport Name": "Tortolì Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Italy",
//       "Latitude": 9.68298,
//       "Location": "Tortoli",
//       "Longitude": 39.9188,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1522,
//       "Airport Code [ICAO]": "LIMA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Torino-Aeritalia Airport",
//       "Altitude (Ft)": 943,
//       "Country": "Italy",
//       "Latitude": 7.60337,
//       "Location": "Turin",
//       "Longitude": 45.086399,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1523,
//       "Airport Code [ICAO]": "LIMB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Milano-Bresso Airfield",
//       "Altitude (Ft)": 484,
//       "Country": "Italy",
//       "Latitude": 9.20333,
//       "Location": "Milano",
//       "Longitude": 45.542198,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1524,
//       "Airport Code [ICAO]": "LIMC",
//       "Airport Code iATA,FAA": "MXP",
//       "Airport Name": "Malpensa International Airport",
//       "Altitude (Ft)": 768,
//       "Country": "Italy",
//       "Latitude": 8.72811,
//       "Location": "Milano",
//       "Longitude": 45.6306,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1525,
//       "Airport Code [ICAO]": "LIME",
//       "Airport Code iATA,FAA": "BGY",
//       "Airport Name": "Il Caravaggio International Airport",
//       "Altitude (Ft)": 782,
//       "Country": "Italy",
//       "Latitude": 9.70417,
//       "Location": "Bergamo",
//       "Longitude": 45.673901,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1526,
//       "Airport Code [ICAO]": "LIMF",
//       "Airport Code iATA,FAA": "TRN",
//       "Airport Name": "Turin Airport",
//       "Altitude (Ft)": 989,
//       "Country": "Italy",
//       "Latitude": 7.64963,
//       "Location": "Torino",
//       "Longitude": 45.200802,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1527,
//       "Airport Code [ICAO]": "LIMG",
//       "Airport Code iATA,FAA": "ALL",
//       "Airport Name": "Villanova D'Albenga International Airport",
//       "Altitude (Ft)": 148,
//       "Country": "Italy",
//       "Latitude": 8.12743,
//       "Location": "Albenga",
//       "Longitude": 44.050598,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1528,
//       "Airport Code [ICAO]": "LIMJ",
//       "Airport Code iATA,FAA": "GOA",
//       "Airport Name": "Genoa Cristoforo Colombo Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Italy",
//       "Latitude": 8.8375,
//       "Location": "Genoa",
//       "Longitude": 44.4133,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1529,
//       "Airport Code [ICAO]": "LIML",
//       "Airport Code iATA,FAA": "LIN",
//       "Airport Name": "Milano Linate Airport",
//       "Altitude (Ft)": 353,
//       "Country": "Italy",
//       "Latitude": 9.27674,
//       "Location": "Milan",
//       "Longitude": 45.445099,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1530,
//       "Airport Code [ICAO]": "LIMN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cameri Air Base [MIL]",
//       "Altitude (Ft)": 586,
//       "Country": "Italy",
//       "Latitude": 8.66922,
//       "Location": "Cameri",
//       "Longitude": 45.529598,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1531,
//       "Airport Code [ICAO]": "LIMP",
//       "Airport Code iATA,FAA": "PMF",
//       "Airport Name": "Parma Airport",
//       "Altitude (Ft)": 161,
//       "Country": "Italy",
//       "Latitude": 10.2964,
//       "Location": "Parma",
//       "Longitude": 44.824501,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1532,
//       "Airport Code [ICAO]": "LIMS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Piacenza San Damiano Air Base",
//       "Altitude (Ft)": 456,
//       "Country": "Italy",
//       "Latitude": 9.723322,
//       "Location": "Piacenza",
//       "Longitude": 44.913102,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1534,
//       "Airport Code [ICAO]": "LIMZ",
//       "Airport Code iATA,FAA": "CUF",
//       "Airport Name": "Cuneo International Airport",
//       "Altitude (Ft)": 1267,
//       "Country": "Italy",
//       "Latitude": 7.62322,
//       "Location": "Cuneo",
//       "Longitude": 44.547001,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1535,
//       "Airport Code [ICAO]": "LIPA",
//       "Airport Code iATA,FAA": "AVB",
//       "Airport Name": "Aviano Air Base",
//       "Altitude (Ft)": 410,
//       "Country": "Italy",
//       "Latitude": 12.596503,
//       "Location": "Aviano",
//       "Longitude": 46.031898,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1536,
//       "Airport Code [ICAO]": "LIPB",
//       "Airport Code iATA,FAA": "BZO",
//       "Airport Name": "Bolzano Airport",
//       "Altitude (Ft)": 789,
//       "Country": "Italy",
//       "Latitude": 11.3264,
//       "Location": "Bolzano",
//       "Longitude": 46.460201,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1537,
//       "Airport Code [ICAO]": "LIPC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cervia Air Base",
//       "Altitude (Ft)": 18,
//       "Country": "Italy",
//       "Latitude": 12.3072,
//       "Location": "Cervia",
//       "Longitude": 44.224201,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1538,
//       "Airport Code [ICAO]": "LIPE",
//       "Airport Code iATA,FAA": "BLQ",
//       "Airport Name": "Bologna Guglielmo Marconi Airport",
//       "Altitude (Ft)": 123,
//       "Country": "Italy",
//       "Latitude": 11.2887,
//       "Location": "Bologna",
//       "Longitude": 44.5354,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1539,
//       "Airport Code [ICAO]": "LIPH",
//       "Airport Code iATA,FAA": "TSF",
//       "Airport Name": "Treviso-Sant'Angelo Airport",
//       "Altitude (Ft)": 59,
//       "Country": "Italy",
//       "Latitude": 12.1944,
//       "Location": "Treviso",
//       "Longitude": 45.648399,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1540,
//       "Airport Code [ICAO]": "LIPI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Rivolto Air Base",
//       "Altitude (Ft)": 179,
//       "Country": "Italy",
//       "Latitude": 13.0493,
//       "Location": "Rivolto",
//       "Longitude": 45.978699,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1541,
//       "Airport Code [ICAO]": "LIPK",
//       "Airport Code iATA,FAA": "FRL",
//       "Airport Name": "Forlì Airport",
//       "Altitude (Ft)": 97,
//       "Country": "Italy",
//       "Latitude": 12.0701,
//       "Location": "Forli",
//       "Longitude": 44.194801,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1542,
//       "Airport Code [ICAO]": "LIPL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ghedi Air Base",
//       "Altitude (Ft)": 333,
//       "Country": "Italy",
//       "Latitude": 10.2677,
//       "Location": "Ghedi",
//       "Longitude": 45.432201,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1543,
//       "Airport Code [ICAO]": "LIPN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Verona-Boscomantico Airport",
//       "Altitude (Ft)": 286,
//       "Country": "Italy",
//       "Latitude": 10.9279,
//       "Location": "Verona",
//       "Longitude": 45.472,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1544,
//       "Airport Code [ICAO]": "LIPO",
//       "Airport Code iATA,FAA": "VBS",
//       "Airport Name": "Brescia Airport",
//       "Altitude (Ft)": 355,
//       "Country": "Italy",
//       "Latitude": 10.3306,
//       "Location": "Brescia",
//       "Longitude": 45.428902,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1545,
//       "Airport Code [ICAO]": "LIPQ",
//       "Airport Code iATA,FAA": "TRS",
//       "Airport Name": "Trieste–Friuli Venezia Giulia Airport",
//       "Altitude (Ft)": 39,
//       "Country": "Italy",
//       "Latitude": 13.4722,
//       "Location": "Ronchi De Legionari",
//       "Longitude": 45.827499,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1546,
//       "Airport Code [ICAO]": "LIPR",
//       "Airport Code iATA,FAA": "RMI",
//       "Airport Name": "Federico Fellini International Airport",
//       "Altitude (Ft)": 40,
//       "Country": "Italy",
//       "Latitude": 12.6117,
//       "Location": "Rimini",
//       "Longitude": 44.020302,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1547,
//       "Airport Code [ICAO]": "LIPS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Istrana Air Base",
//       "Altitude (Ft)": 137,
//       "Country": "Italy",
//       "Latitude": 12.0829,
//       "Location": "Treviso",
//       "Longitude": 45.684898,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1548,
//       "Airport Code [ICAO]": "LIPT",
//       "Airport Code iATA,FAA": "VIC",
//       "Airport Name": "Vicenza Airport",
//       "Altitude (Ft)": 128,
//       "Country": "Italy",
//       "Latitude": 11.5295,
//       "Location": "Vicenza",
//       "Longitude": 45.573399,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1549,
//       "Airport Code [ICAO]": "LIPU",
//       "Airport Code iATA,FAA": "QPA",
//       "Airport Name": "Padova Airport",
//       "Altitude (Ft)": 44,
//       "Country": "Italy",
//       "Latitude": 11.8479,
//       "Location": "Padova",
//       "Longitude": 45.395802,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1550,
//       "Airport Code [ICAO]": "LIPX",
//       "Airport Code iATA,FAA": "VRN",
//       "Airport Name": "Verona Villafranca Airport",
//       "Altitude (Ft)": 239,
//       "Country": "Italy",
//       "Latitude": 10.8885,
//       "Location": "Villafranca",
//       "Longitude": 45.395699,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1551,
//       "Airport Code [ICAO]": "LIPZ",
//       "Airport Code iATA,FAA": "VCE",
//       "Airport Name": "Venice Marco Polo Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Italy",
//       "Latitude": 12.3519,
//       "Location": "Venice",
//       "Longitude": 45.505299,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1552,
//       "Airport Code [ICAO]": "LIQS",
//       "Airport Code iATA,FAA": "SAY",
//       "Airport Name": "Siena-Ampugnano Airport",
//       "Altitude (Ft)": 634,
//       "Country": "Italy",
//       "Latitude": 11.255,
//       "Location": "Siena",
//       "Longitude": 43.256302,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1553,
//       "Airport Code [ICAO]": "LIRA",
//       "Airport Code iATA,FAA": "CIA",
//       "Airport Name": "Ciampino–G. B. Pastine International Airport",
//       "Altitude (Ft)": 427,
//       "Country": "Italy",
//       "Latitude": 12.5949,
//       "Location": "Rome",
//       "Longitude": 41.7994,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1554,
//       "Airport Code [ICAO]": "LIRE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Pratica Di Mare Air Base",
//       "Altitude (Ft)": 41,
//       "Country": "Italy",
//       "Latitude": 12.4452,
//       "Location": "Pratica Di Mare",
//       "Longitude": 41.654499,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1555,
//       "Airport Code [ICAO]": "LIRF",
//       "Airport Code iATA,FAA": "FCO",
//       "Airport Name": "Leonardo da Vinci–Fiumicino Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Italy",
//       "Latitude": 12.2388889,
//       "Location": "Rome",
//       "Longitude": 41.8002778,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1556,
//       "Airport Code [ICAO]": "LIRG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Guidonia Air Base",
//       "Altitude (Ft)": 289,
//       "Country": "Italy",
//       "Latitude": 12.7408,
//       "Location": "Guidonia",
//       "Longitude": 41.990299,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1558,
//       "Airport Code [ICAO]": "LIRJ",
//       "Airport Code iATA,FAA": "EBA",
//       "Airport Name": "Marina Di Campo Airport",
//       "Altitude (Ft)": 31,
//       "Country": "Italy",
//       "Latitude": 10.2394,
//       "Location": "Marina Di Campo",
//       "Longitude": 42.7603,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1559,
//       "Airport Code [ICAO]": "LIRL",
//       "Airport Code iATA,FAA": "QLT",
//       "Airport Name": "Latina Air Base",
//       "Altitude (Ft)": 94,
//       "Country": "Italy",
//       "Latitude": 12.909,
//       "Location": "Latina",
//       "Longitude": 41.5424,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1560,
//       "Airport Code [ICAO]": "LIRM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Grazzanise Air Base",
//       "Altitude (Ft)": 29,
//       "Country": "Italy",
//       "Latitude": 14.0819,
//       "Location": "Grazzanise",
//       "Longitude": 41.060799,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1561,
//       "Airport Code [ICAO]": "LIRN",
//       "Airport Code iATA,FAA": "NAP",
//       "Airport Name": "Naples International Airport",
//       "Altitude (Ft)": 294,
//       "Country": "Italy",
//       "Latitude": 14.2908,
//       "Location": "Naples",
//       "Longitude": 40.886002,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1562,
//       "Airport Code [ICAO]": "LIRP",
//       "Airport Code iATA,FAA": "PSA",
//       "Airport Name": "Pisa International Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Italy",
//       "Latitude": 10.3927,
//       "Location": "Pisa",
//       "Longitude": 43.683899,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1563,
//       "Airport Code [ICAO]": "LIRQ",
//       "Airport Code iATA,FAA": "FLR",
//       "Airport Name": "Peretola Airport",
//       "Altitude (Ft)": 142,
//       "Country": "Italy",
//       "Latitude": 11.2051,
//       "Location": "Florence",
//       "Longitude": 43.810001,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1564,
//       "Airport Code [ICAO]": "LIRS",
//       "Airport Code iATA,FAA": "GRS",
//       "Airport Name": "Grosseto Air Base",
//       "Altitude (Ft)": 17,
//       "Country": "Italy",
//       "Latitude": 11.0719,
//       "Location": "Grosseto",
//       "Longitude": 42.759701,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1565,
//       "Airport Code [ICAO]": "LIRU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Urbe Airport",
//       "Altitude (Ft)": 55,
//       "Country": "Italy",
//       "Latitude": 12.4989,
//       "Location": "Rome",
//       "Longitude": 41.9519,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1566,
//       "Airport Code [ICAO]": "LIRV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Viterbo Airport",
//       "Altitude (Ft)": 992,
//       "Country": "Italy",
//       "Latitude": 12.0642,
//       "Location": "Viterbo",
//       "Longitude": 42.430199,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1567,
//       "Airport Code [ICAO]": "LIRZ",
//       "Airport Code iATA,FAA": "PEG",
//       "Airport Name": "Perugia San Francesco d'Assisi – Umbria International Airport",
//       "Altitude (Ft)": 697,
//       "Country": "Italy",
//       "Latitude": 12.5132,
//       "Location": "Perugia",
//       "Longitude": 43.095901,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 1568,
//       "Airport Code [ICAO]": "LJCE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cerklje Airport",
//       "Altitude (Ft)": 510,
//       "Country": "Slovenia",
//       "Latitude": 15.530200004578,
//       "Location": "Cerklje",
//       "Longitude": 45.900001525879,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Ljubljana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1569,
//       "Airport Code [ICAO]": "LJLJ",
//       "Airport Code iATA,FAA": "LJU",
//       "Airport Name": "Ljubljana Jože Pučnik Airport",
//       "Altitude (Ft)": 1273,
//       "Country": "Slovenia",
//       "Latitude": 14.4576,
//       "Location": "Ljubljana",
//       "Longitude": 46.223701,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Ljubljana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1570,
//       "Airport Code [ICAO]": "LJMB",
//       "Airport Code iATA,FAA": "MBX",
//       "Airport Name": "Maribor Airport",
//       "Altitude (Ft)": 876,
//       "Country": "Slovenia",
//       "Latitude": 15.686100006103516,
//       "Location": "Maribor",
//       "Longitude": 46.47990036010742,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Ljubljana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1571,
//       "Airport Code [ICAO]": "LJPZ",
//       "Airport Code iATA,FAA": "POW",
//       "Airport Name": "Portoroz Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Slovenia",
//       "Latitude": 13.614999771118164,
//       "Location": "Portoroz",
//       "Longitude": 45.4734001159668,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Ljubljana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1572,
//       "Airport Code [ICAO]": "LJSG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Slovenj Gradec Airport",
//       "Altitude (Ft)": 1645,
//       "Country": "Slovenia",
//       "Latitude": 15.116999626159668,
//       "Location": "Slovenj Gradec",
//       "Longitude": 46.47200012207031,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Ljubljana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1573,
//       "Airport Code [ICAO]": "LKCS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "České Budějovice Airport",
//       "Altitude (Ft)": 1417,
//       "Country": "Czech Republic",
//       "Latitude": 14.427499771118164,
//       "Location": "Ceske Budejovice",
//       "Longitude": 48.9463996887207,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Prague",
//       "Type": "airport"
//     },
//     {
//       "ID": 1574,
//       "Airport Code [ICAO]": "LKCV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Čáslav Air Base",
//       "Altitude (Ft)": 794,
//       "Country": "Czech Republic",
//       "Latitude": 15.381799697875977,
//       "Location": "Caslav",
//       "Longitude": 49.939701080322266,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Prague",
//       "Type": "airport"
//     },
//     {
//       "ID": 1575,
//       "Airport Code [ICAO]": "LKHK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hradec Králové Airport",
//       "Altitude (Ft)": 791,
//       "Country": "Czech Republic",
//       "Latitude": 15.8452,
//       "Location": "Hradec Kralove",
//       "Longitude": 50.253201,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Prague",
//       "Type": "airport"
//     },
//     {
//       "ID": 1576,
//       "Airport Code [ICAO]": "LKHV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hořovice Airport",
//       "Altitude (Ft)": 1214,
//       "Country": "Czech Republic",
//       "Latitude": 13.893500328063965,
//       "Location": "Horovice",
//       "Longitude": 49.84809875488281,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Prague",
//       "Type": "airport"
//     },
//     {
//       "ID": 1577,
//       "Airport Code [ICAO]": "LKKB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kbely Air Base",
//       "Altitude (Ft)": 939,
//       "Country": "Czech Republic",
//       "Latitude": 14.543600082397461,
//       "Location": "Praha",
//       "Longitude": 50.12139892578125,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Prague",
//       "Type": "airport"
//     },
//     {
//       "ID": 1578,
//       "Airport Code [ICAO]": "LKKU",
//       "Airport Code iATA,FAA": "UHE",
//       "Airport Name": "Kunovice Airport",
//       "Altitude (Ft)": 581,
//       "Country": "Czech Republic",
//       "Latitude": 17.439699172973633,
//       "Location": "Kunovice",
//       "Longitude": 49.02939987182617,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Prague",
//       "Type": "airport"
//     },
//     {
//       "ID": 1579,
//       "Airport Code [ICAO]": "LKKV",
//       "Airport Code iATA,FAA": "KLV",
//       "Airport Name": "Karlovy Vary International Airport",
//       "Altitude (Ft)": 1989,
//       "Country": "Czech Republic",
//       "Latitude": 12.914999961853027,
//       "Location": "Karlovy Vary",
//       "Longitude": 50.202999114990234,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Prague",
//       "Type": "airport"
//     },
//     {
//       "ID": 1580,
//       "Airport Code [ICAO]": "LKLN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Plzeň-Líně Airport",
//       "Altitude (Ft)": 1188,
//       "Country": "Czech Republic",
//       "Latitude": 13.274600028992,
//       "Location": "Line",
//       "Longitude": 49.675201416016,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Prague",
//       "Type": "airport"
//     },
//     {
//       "ID": 1581,
//       "Airport Code [ICAO]": "LKMH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mnichovo Hradiště Airport",
//       "Altitude (Ft)": 800,
//       "Country": "Czech Republic",
//       "Latitude": 15.006600379943848,
//       "Location": "Mnichovo Hradiste",
//       "Longitude": 50.540199279785156,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Prague",
//       "Type": "airport"
//     },
//     {
//       "ID": 1582,
//       "Airport Code [ICAO]": "LKMT",
//       "Airport Code iATA,FAA": "OSR",
//       "Airport Name": "Ostrava Leos Janáček Airport",
//       "Altitude (Ft)": 844,
//       "Country": "Czech Republic",
//       "Latitude": 18.111099243164062,
//       "Location": "Ostrava",
//       "Longitude": 49.6963005065918,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Prague",
//       "Type": "airport"
//     },
//     {
//       "ID": 1583,
//       "Airport Code [ICAO]": "LKNA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Náměšť Air Base",
//       "Altitude (Ft)": 1548,
//       "Country": "Czech Republic",
//       "Latitude": 16.124900817871094,
//       "Location": "Namest",
//       "Longitude": 49.16579818725586,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Prague",
//       "Type": "airport"
//     },
//     {
//       "ID": 1584,
//       "Airport Code [ICAO]": "LKPD",
//       "Airport Code iATA,FAA": "PED",
//       "Airport Name": "Pardubice Airport",
//       "Altitude (Ft)": 741,
//       "Country": "Czech Republic",
//       "Latitude": 15.73859977722168,
//       "Location": "Pardubice",
//       "Longitude": 50.01340103149414,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Prague",
//       "Type": "airport"
//     },
//     {
//       "ID": 1585,
//       "Airport Code [ICAO]": "LKPM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Pribram Airport",
//       "Altitude (Ft)": 1529,
//       "Country": "Czech Republic",
//       "Latitude": 14.1006,
//       "Location": "Pribram",
//       "Longitude": 49.7201,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Prague",
//       "Type": "airport"
//     },
//     {
//       "ID": 1586,
//       "Airport Code [ICAO]": "LKPO",
//       "Airport Code iATA,FAA": "PRV",
//       "Airport Name": "Přerov Air Base",
//       "Altitude (Ft)": 676,
//       "Country": "Czech Republic",
//       "Latitude": 17.404699325561523,
//       "Location": "Prerov",
//       "Longitude": 49.42580032348633,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Prague",
//       "Type": "airport"
//     },
//     {
//       "ID": 1587,
//       "Airport Code [ICAO]": "LKPR",
//       "Airport Code iATA,FAA": "PRG",
//       "Airport Name": "Václav Havel Airport Prague",
//       "Altitude (Ft)": 1247,
//       "Country": "Czech Republic",
//       "Latitude": 14.26,
//       "Location": "Prague",
//       "Longitude": 50.1008,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Prague",
//       "Type": "airport"
//     },
//     {
//       "ID": 1588,
//       "Airport Code [ICAO]": "LKTB",
//       "Airport Code iATA,FAA": "BRQ",
//       "Airport Name": "Brno-Tuřany Airport",
//       "Altitude (Ft)": 778,
//       "Country": "Czech Republic",
//       "Latitude": 16.694400787353516,
//       "Location": "Brno",
//       "Longitude": 49.15129852294922,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Prague",
//       "Type": "airport"
//     },
//     {
//       "ID": 1589,
//       "Airport Code [ICAO]": "LKVO",
//       "Airport Code iATA,FAA": "VOD",
//       "Airport Name": "Vodochody Airport",
//       "Altitude (Ft)": 919,
//       "Country": "Czech Republic",
//       "Latitude": 14.3958,
//       "Location": "Vodochody",
//       "Longitude": 50.216599,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Prague",
//       "Type": "airport"
//     },
//     {
//       "ID": 1590,
//       "Airport Code [ICAO]": "LLBG",
//       "Airport Code iATA,FAA": "TLV",
//       "Airport Name": "Ben Gurion International Airport",
//       "Altitude (Ft)": 135,
//       "Country": "Israel",
//       "Latitude": 34.88669967651367,
//       "Location": "Tel-aviv",
//       "Longitude": 32.01139831542969,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Jerusalem",
//       "Type": "airport"
//     },
//     {
//       "ID": 1591,
//       "Airport Code [ICAO]": "LLBS",
//       "Airport Code iATA,FAA": "BEV",
//       "Airport Name": "Beersheba (Teyman) Airport",
//       "Altitude (Ft)": 656,
//       "Country": "Israel",
//       "Latitude": 34.722999572754,
//       "Location": "Beer-sheba",
//       "Longitude": 31.287000656128,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Jerusalem",
//       "Type": "airport"
//     },
//     {
//       "ID": 1592,
//       "Airport Code [ICAO]": "LLEK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tel Nof Air Base",
//       "Altitude (Ft)": 193,
//       "Country": "Israel",
//       "Latitude": 34.8218002319,
//       "Location": "Tel-nof",
//       "Longitude": 31.8395004272,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Jerusalem",
//       "Type": "airport"
//     },
//     {
//       "ID": 1593,
//       "Airport Code [ICAO]": "LLES",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ein Shemer Airfield",
//       "Altitude (Ft)": 95,
//       "Country": "Israel",
//       "Latitude": 35.0077018737793,
//       "Location": "Eyn-shemer",
//       "Longitude": 32.440799713134766,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Jerusalem",
//       "Type": "airport"
//     },
//     {
//       "ID": 1594,
//       "Airport Code [ICAO]": "LLET",
//       "Airport Code iATA,FAA": "ETH",
//       "Airport Name": "Eilat Airport",
//       "Altitude (Ft)": 42,
//       "Country": "Israel",
//       "Latitude": 34.96009826660156,
//       "Location": "Elat",
//       "Longitude": 29.56130027770996,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Jerusalem",
//       "Type": "airport"
//     },
//     {
//       "ID": 1595,
//       "Airport Code [ICAO]": "LLEY",
//       "Airport Code iATA,FAA": "EIY",
//       "Airport Name": "Ein Yahav Airfield",
//       "Altitude (Ft)": -164,
//       "Country": "Israel",
//       "Latitude": 35.20330047607422,
//       "Location": "Eyn-yahav",
//       "Longitude": 30.621700286865234,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Jerusalem",
//       "Type": "airport"
//     },
//     {
//       "ID": 1596,
//       "Airport Code [ICAO]": "LLHA",
//       "Airport Code iATA,FAA": "HFA",
//       "Airport Name": "Haifa International Airport",
//       "Altitude (Ft)": 28,
//       "Country": "Israel",
//       "Latitude": 35.04309844970703,
//       "Location": "Haifa",
//       "Longitude": 32.80939865112305,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Jerusalem",
//       "Type": "airport"
//     },
//     {
//       "ID": 1597,
//       "Airport Code [ICAO]": "LLHS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hatzor Air Base",
//       "Altitude (Ft)": 148,
//       "Country": "Israel",
//       "Latitude": 34.727199554399995,
//       "Location": "Haztor",
//       "Longitude": 31.7625007629,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Jerusalem",
//       "Type": "airport"
//     },
//     {
//       "ID": 1598,
//       "Airport Code [ICAO]": "LLIB",
//       "Airport Code iATA,FAA": "RPN",
//       "Airport Name": "Ben Ya'akov Airport",
//       "Altitude (Ft)": 922,
//       "Country": "Israel",
//       "Latitude": 35.5718994140625,
//       "Location": "Rosh Pina",
//       "Longitude": 32.98099899291992,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Jerusalem",
//       "Type": "airport"
//     },
//     {
//       "ID": 1599,
//       "Airport Code [ICAO]": "LLMG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Megiddo Airport",
//       "Altitude (Ft)": 200,
//       "Country": "Israel",
//       "Latitude": 35.22880172729492,
//       "Location": "Megido Airstrip",
//       "Longitude": 32.5973014831543,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Jerusalem",
//       "Type": "airport"
//     },
//     {
//       "ID": 1600,
//       "Airport Code [ICAO]": "LLMZ",
//       "Airport Code iATA,FAA": "MTZ",
//       "Airport Name": "Bar Yehuda Airfield",
//       "Altitude (Ft)": -1266,
//       "Country": "Israel",
//       "Latitude": 35.38859939575195,
//       "Location": "Metzada",
//       "Longitude": 31.32819938659668,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Jerusalem",
//       "Type": "airport"
//     },
//     {
//       "ID": 1601,
//       "Airport Code [ICAO]": "LLNV",
//       "Airport Code iATA,FAA": "VTM",
//       "Airport Name": "Nevatim Air Base",
//       "Altitude (Ft)": 1330,
//       "Country": "Israel",
//       "Latitude": 35.012298584,
//       "Location": "Nevatim",
//       "Longitude": 31.208299636799996,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Jerusalem",
//       "Type": "airport"
//     },
//     {
//       "ID": 1602,
//       "Airport Code [ICAO]": "LLOV",
//       "Airport Code iATA,FAA": "VDA",
//       "Airport Name": "Ovda International Airport",
//       "Altitude (Ft)": 1492,
//       "Country": "Israel",
//       "Latitude": 34.93579864501953,
//       "Location": "Ovda",
//       "Longitude": 29.94029998779297,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Jerusalem",
//       "Type": "airport"
//     },
//     {
//       "ID": 1603,
//       "Airport Code [ICAO]": "LLRD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ramat David Air Base",
//       "Altitude (Ft)": 185,
//       "Country": "Israel",
//       "Latitude": 35.179500579833984,
//       "Location": "Ramat David",
//       "Longitude": 32.66510009765625,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Jerusalem",
//       "Type": "airport"
//     },
//     {
//       "ID": 1604,
//       "Airport Code [ICAO]": "LLRM",
//       "Airport Code iATA,FAA": "MIP",
//       "Airport Name": "Ramon Air Base",
//       "Altitude (Ft)": 2126,
//       "Country": "Israel",
//       "Latitude": 34.66669845581055,
//       "Location": "Ramon",
//       "Longitude": 30.776100158691406,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Jerusalem",
//       "Type": "airport"
//     },
//     {
//       "ID": 1605,
//       "Airport Code [ICAO]": "LLSD",
//       "Airport Code iATA,FAA": "SDV",
//       "Airport Name": "Sde Dov Airport",
//       "Altitude (Ft)": 43,
//       "Country": "Israel",
//       "Latitude": 34.78219985961914,
//       "Location": "Tel-aviv",
//       "Longitude": 32.11470031738281,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Jerusalem",
//       "Type": "airport"
//     },
//     {
//       "ID": 1606,
//       "Airport Code [ICAO]": "LMML",
//       "Airport Code iATA,FAA": "MLA",
//       "Airport Name": "Malta International Airport",
//       "Altitude (Ft)": 300,
//       "Country": "Malta",
//       "Latitude": 14.4775,
//       "Location": "Malta",
//       "Longitude": 35.857498,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Malta",
//       "Type": "airport"
//     },
//     {
//       "ID": 1607,
//       "Airport Code [ICAO]": "LOAN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Wiener Neustadt East Airport",
//       "Altitude (Ft)": 896,
//       "Country": "Austria",
//       "Latitude": 16.260099411010742,
//       "Location": "Wiener Neustadt Ost",
//       "Longitude": 47.843299865722656,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vienna",
//       "Type": "airport"
//     },
//     {
//       "ID": 1608,
//       "Airport Code [ICAO]": "LOLW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Wels Airport",
//       "Altitude (Ft)": 1043,
//       "Country": "Austria",
//       "Latitude": 14.040900230407715,
//       "Location": "Wels",
//       "Longitude": 48.18330001831055,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vienna",
//       "Type": "airport"
//     },
//     {
//       "ID": 1609,
//       "Airport Code [ICAO]": "LOWG",
//       "Airport Code iATA,FAA": "GRZ",
//       "Airport Name": "Graz Airport",
//       "Altitude (Ft)": 1115,
//       "Country": "Austria",
//       "Latitude": 15.439599990844727,
//       "Location": "Graz",
//       "Longitude": 46.9911003112793,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vienna",
//       "Type": "airport"
//     },
//     {
//       "ID": 1610,
//       "Airport Code [ICAO]": "LOWI",
//       "Airport Code iATA,FAA": "INN",
//       "Airport Name": "Innsbruck Airport",
//       "Altitude (Ft)": 1907,
//       "Country": "Austria",
//       "Latitude": 11.344,
//       "Location": "Innsbruck",
//       "Longitude": 47.260201,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vienna",
//       "Type": "airport"
//     },
//     {
//       "ID": 1611,
//       "Airport Code [ICAO]": "LOWL",
//       "Airport Code iATA,FAA": "LNZ",
//       "Airport Name": "Linz Hörsching Airport",
//       "Altitude (Ft)": 980,
//       "Country": "Austria",
//       "Latitude": 14.1875,
//       "Location": "Linz",
//       "Longitude": 48.2332,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vienna",
//       "Type": "airport"
//     },
//     {
//       "ID": 1612,
//       "Airport Code [ICAO]": "LOWS",
//       "Airport Code iATA,FAA": "SZG",
//       "Airport Name": "Salzburg Airport",
//       "Altitude (Ft)": 1411,
//       "Country": "Austria",
//       "Latitude": 13.0043001175,
//       "Location": "Salzburg",
//       "Longitude": 47.793300628699996,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vienna",
//       "Type": "airport"
//     },
//     {
//       "ID": 1613,
//       "Airport Code [ICAO]": "LOWW",
//       "Airport Code iATA,FAA": "VIE",
//       "Airport Name": "Vienna International Airport",
//       "Altitude (Ft)": 600,
//       "Country": "Austria",
//       "Latitude": 16.569700241089,
//       "Location": "Vienna",
//       "Longitude": 48.110298156738,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vienna",
//       "Type": "airport"
//     },
//     {
//       "ID": 1615,
//       "Airport Code [ICAO]": "LOXZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hinterstoisser Air Base",
//       "Altitude (Ft)": 2264,
//       "Country": "Austria",
//       "Latitude": 14.7442,
//       "Location": "Zeltweg",
//       "Longitude": 47.202801,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vienna",
//       "Type": "airport"
//     },
//     {
//       "ID": 1616,
//       "Airport Code [ICAO]": "LPAR",
//       "Airport Code iATA,FAA": "AVR",
//       "Airport Name": "Alverca Air Base",
//       "Altitude (Ft)": 11,
//       "Country": "Portugal",
//       "Latitude": -9.0301,
//       "Location": "Alverca",
//       "Longitude": 38.883301,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Lisbon",
//       "Type": "airport"
//     },
//     {
//       "ID": 1617,
//       "Airport Code [ICAO]": "LPAZ",
//       "Airport Code iATA,FAA": "SMA",
//       "Airport Name": "Santa Maria Airport",
//       "Altitude (Ft)": 308,
//       "Country": "Portugal",
//       "Latitude": -25.17060089111328,
//       "Location": "Santa Maria (island)",
//       "Longitude": 36.97140121459961,
//       "GMT Offset": "-1",
//       "Daylight Savings": "E",
//       "Region": "Atlantic/Azores",
//       "Type": "airport"
//     },
//     {
//       "ID": 1618,
//       "Airport Code [ICAO]": "LPBG",
//       "Airport Code iATA,FAA": "BGC",
//       "Airport Name": "Bragança Airport",
//       "Altitude (Ft)": 2241,
//       "Country": "Portugal",
//       "Latitude": -6.70713,
//       "Location": "Braganca",
//       "Longitude": 41.8578,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Lisbon",
//       "Type": "airport"
//     },
//     {
//       "ID": 1619,
//       "Airport Code [ICAO]": "LPBJ",
//       "Airport Code iATA,FAA": "BYJ",
//       "Airport Name": "Beja Airport / Airbase",
//       "Altitude (Ft)": 636,
//       "Country": "Portugal",
//       "Latitude": -7.9324,
//       "Location": "Beja (madeira)",
//       "Longitude": 38.078899,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Lisbon",
//       "Type": "airport"
//     },
//     {
//       "ID": 1620,
//       "Airport Code [ICAO]": "LPBR",
//       "Airport Code iATA,FAA": "BGZ",
//       "Airport Name": "Braga Municipal Aerodrome",
//       "Altitude (Ft)": 247,
//       "Country": "Portugal",
//       "Latitude": -8.445139884949999,
//       "Location": "Braga",
//       "Longitude": 41.5871009827,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Lisbon",
//       "Type": "airport"
//     },
//     {
//       "ID": 1621,
//       "Airport Code [ICAO]": "LPCO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Aerodromo Municipal de Coimbra",
//       "Altitude (Ft)": 587,
//       "Country": "Portugal",
//       "Latitude": -8.470815,
//       "Location": "Coimba",
//       "Longitude": 40.158758,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Lisbon",
//       "Type": "airport"
//     },
//     {
//       "ID": 1622,
//       "Airport Code [ICAO]": "LPCS",
//       "Airport Code iATA,FAA": "CAT",
//       "Airport Name": "Cascais Airport",
//       "Altitude (Ft)": 325,
//       "Country": "Portugal",
//       "Latitude": -9.35523,
//       "Location": "Cascais",
//       "Longitude": 38.724998,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Lisbon",
//       "Type": "airport"
//     },
//     {
//       "ID": 1623,
//       "Airport Code [ICAO]": "LPCV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Coimbra Hospital Covões Heliport",
//       "Altitude (Ft)": 265,
//       "Country": "Portugal",
//       "Latitude": -8.460207,
//       "Location": "Covilha",
//       "Longitude": 40.194812,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Lisbon",
//       "Type": "airport"
//     },
//     {
//       "ID": 1624,
//       "Airport Code [ICAO]": "LPEV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Évora Airport",
//       "Altitude (Ft)": 807,
//       "Country": "Portugal",
//       "Latitude": -7.88964,
//       "Location": "Evora",
//       "Longitude": 38.533501,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Lisbon",
//       "Type": "airport"
//     },
//     {
//       "ID": 1625,
//       "Airport Code [ICAO]": "LPFL",
//       "Airport Code iATA,FAA": "FLW",
//       "Airport Name": "Flores Airport",
//       "Altitude (Ft)": 112,
//       "Country": "Portugal",
//       "Latitude": -31.131399154663086,
//       "Location": "Flores",
//       "Longitude": 39.455299377441406,
//       "GMT Offset": "-1",
//       "Daylight Savings": "E",
//       "Region": "Atlantic/Azores",
//       "Type": "airport"
//     },
//     {
//       "ID": 1626,
//       "Airport Code [ICAO]": "LPFR",
//       "Airport Code iATA,FAA": "FAO",
//       "Airport Name": "Faro Airport",
//       "Altitude (Ft)": 24,
//       "Country": "Portugal",
//       "Latitude": -7.96590995789,
//       "Location": "Faro",
//       "Longitude": 37.0144004822,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Lisbon",
//       "Type": "airport"
//     },
//     {
//       "ID": 1627,
//       "Airport Code [ICAO]": "LPGR",
//       "Airport Code iATA,FAA": "GRW",
//       "Airport Name": "Graciosa Airport",
//       "Altitude (Ft)": 86,
//       "Country": "Portugal",
//       "Latitude": -28.029800415039062,
//       "Location": "Graciosa Island",
//       "Longitude": 39.092201232910156,
//       "GMT Offset": "-1",
//       "Daylight Savings": "E",
//       "Region": "Atlantic/Azores",
//       "Type": "airport"
//     },
//     {
//       "ID": 1628,
//       "Airport Code [ICAO]": "LPHR",
//       "Airport Code iATA,FAA": "HOR",
//       "Airport Name": "Horta Airport",
//       "Altitude (Ft)": 118,
//       "Country": "Portugal",
//       "Latitude": -28.715900421142578,
//       "Location": "Horta",
//       "Longitude": 38.519901275634766,
//       "GMT Offset": "-1",
//       "Daylight Savings": "E",
//       "Region": "Atlantic/Azores",
//       "Type": "airport"
//     },
//     {
//       "ID": 1629,
//       "Airport Code [ICAO]": "LPLA",
//       "Airport Code iATA,FAA": "TER",
//       "Airport Name": "Lajes Airport",
//       "Altitude (Ft)": 180,
//       "Country": "Portugal",
//       "Latitude": -27.090799,
//       "Location": "Lajes (terceira Island)",
//       "Longitude": 38.761799,
//       "GMT Offset": "-1",
//       "Daylight Savings": "E",
//       "Region": "Atlantic/Azores",
//       "Type": "airport"
//     },
//     {
//       "ID": 1630,
//       "Airport Code [ICAO]": "LPMR",
//       "Airport Code iATA,FAA": "QLR",
//       "Airport Name": "Monte Real Air Base",
//       "Altitude (Ft)": 187,
//       "Country": "Portugal",
//       "Latitude": -8.8875,
//       "Location": "Monte Real",
//       "Longitude": 39.828335,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Lisbon",
//       "Type": "airport"
//     },
//     {
//       "ID": 1631,
//       "Airport Code [ICAO]": "LPMT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Montijo Air Base",
//       "Altitude (Ft)": 46,
//       "Country": "Portugal",
//       "Latitude": -9.03592,
//       "Location": "Montijo",
//       "Longitude": 38.703899,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Lisbon",
//       "Type": "airport"
//     },
//     {
//       "ID": 1632,
//       "Airport Code [ICAO]": "LPOV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ovar Air Base",
//       "Altitude (Ft)": 56,
//       "Country": "Portugal",
//       "Latitude": -8.64592,
//       "Location": "Ovar",
//       "Longitude": 40.915901,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Lisbon",
//       "Type": "airport"
//     },
//     {
//       "ID": 1633,
//       "Airport Code [ICAO]": "LPPD",
//       "Airport Code iATA,FAA": "PDL",
//       "Airport Name": "João Paulo II Airport",
//       "Altitude (Ft)": 259,
//       "Country": "Portugal",
//       "Latitude": -25.6979007721,
//       "Location": "Ponta Delgada",
//       "Longitude": 37.7411994934,
//       "GMT Offset": "-1",
//       "Daylight Savings": "E",
//       "Region": "Atlantic/Azores",
//       "Type": "airport"
//     },
//     {
//       "ID": 1634,
//       "Airport Code [ICAO]": "LPPI",
//       "Airport Code iATA,FAA": "PIX",
//       "Airport Name": "Pico Airport",
//       "Altitude (Ft)": 109,
//       "Country": "Portugal",
//       "Latitude": -28.441299,
//       "Location": "Pico",
//       "Longitude": 38.554298,
//       "GMT Offset": "-1",
//       "Daylight Savings": "E",
//       "Region": "Atlantic/Azores",
//       "Type": "airport"
//     },
//     {
//       "ID": 1635,
//       "Airport Code [ICAO]": "LPPM",
//       "Airport Code iATA,FAA": "PRM",
//       "Airport Name": "Portimão Airport",
//       "Altitude (Ft)": 5,
//       "Country": "Portugal",
//       "Latitude": -8.58396,
//       "Location": "Portimao",
//       "Longitude": 37.1493,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Lisbon",
//       "Type": "airport"
//     },
//     {
//       "ID": 1636,
//       "Airport Code [ICAO]": "LPPR",
//       "Airport Code iATA,FAA": "OPO",
//       "Airport Name": "Francisco de Sá Carneiro Airport",
//       "Altitude (Ft)": 228,
//       "Country": "Portugal",
//       "Latitude": -8.68138980865,
//       "Location": "Porto",
//       "Longitude": 41.2481002808,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Lisbon",
//       "Type": "airport"
//     },
//     {
//       "ID": 1637,
//       "Airport Code [ICAO]": "LPPS",
//       "Airport Code iATA,FAA": "PXO",
//       "Airport Name": "Porto Santo Airport",
//       "Altitude (Ft)": 341,
//       "Country": "Portugal",
//       "Latitude": -16.3500003815,
//       "Location": "Porto Santo",
//       "Longitude": 33.0733985901,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Lisbon",
//       "Type": "airport"
//     },
//     {
//       "ID": 1638,
//       "Airport Code [ICAO]": "LPPT",
//       "Airport Code iATA,FAA": "LIS",
//       "Airport Name": "Humberto Delgado Airport (Lisbon Portela Airport)",
//       "Altitude (Ft)": 374,
//       "Country": "Portugal",
//       "Latitude": -9.13592,
//       "Location": "Lisbon",
//       "Longitude": 38.7813,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Lisbon",
//       "Type": "airport"
//     },
//     {
//       "ID": 1639,
//       "Airport Code [ICAO]": "LPSJ",
//       "Airport Code iATA,FAA": "SJZ",
//       "Airport Name": "São Jorge Airport",
//       "Altitude (Ft)": 311,
//       "Country": "Portugal",
//       "Latitude": -28.175800323486328,
//       "Location": "Sao Jorge Island",
//       "Longitude": 38.66550064086914,
//       "GMT Offset": "-1",
//       "Daylight Savings": "E",
//       "Region": "Atlantic/Azores",
//       "Type": "airport"
//     },
//     {
//       "ID": 1640,
//       "Airport Code [ICAO]": "LPST",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sintra Air Base",
//       "Altitude (Ft)": 440,
//       "Country": "Portugal",
//       "Latitude": -9.33955,
//       "Location": "Sintra",
//       "Longitude": 38.8311,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Lisbon",
//       "Type": "airport"
//     },
//     {
//       "ID": 1641,
//       "Airport Code [ICAO]": "LPTN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tancos Airbase",
//       "Altitude (Ft)": 266,
//       "Country": "Portugal",
//       "Latitude": -8.36458,
//       "Location": "Tancos",
//       "Longitude": 39.475101,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Lisbon",
//       "Type": "airport"
//     },
//     {
//       "ID": 1642,
//       "Airport Code [ICAO]": "LPVR",
//       "Airport Code iATA,FAA": "VRL",
//       "Airport Name": "Vila Real Airport",
//       "Altitude (Ft)": 1805,
//       "Country": "Portugal",
//       "Latitude": -7.72047,
//       "Location": "Vila Real",
//       "Longitude": 41.2743,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Lisbon",
//       "Type": "airport"
//     },
//     {
//       "ID": 1643,
//       "Airport Code [ICAO]": "LPVZ",
//       "Airport Code iATA,FAA": "VSE",
//       "Airport Name": "Aerodromo Goncalves Lobato (Viseu Airport)",
//       "Altitude (Ft)": 2060,
//       "Country": "Portugal",
//       "Latitude": -7.88899,
//       "Location": "Viseu",
//       "Longitude": 40.725498,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Lisbon",
//       "Type": "airport"
//     },
//     {
//       "ID": 1645,
//       "Airport Code [ICAO]": "LQMO",
//       "Airport Code iATA,FAA": "OMO",
//       "Airport Name": "Mostar International Airport",
//       "Altitude (Ft)": 156,
//       "Country": "Bosnia and Herzegovina",
//       "Latitude": 17.84589958190918,
//       "Location": "Mostar",
//       "Longitude": 43.282901763916016,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Sarajevo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1646,
//       "Airport Code [ICAO]": "LQSA",
//       "Airport Code iATA,FAA": "SJJ",
//       "Airport Name": "Sarajevo International Airport",
//       "Altitude (Ft)": 1708,
//       "Country": "Bosnia and Herzegovina",
//       "Latitude": 18.331499099731445,
//       "Location": "Sarajevo",
//       "Longitude": 43.82460021972656,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Sarajevo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1647,
//       "Airport Code [ICAO]": "LRAR",
//       "Airport Code iATA,FAA": "ARW",
//       "Airport Name": "Arad International Airport",
//       "Altitude (Ft)": 352,
//       "Country": "Romania",
//       "Latitude": 21.261999130249023,
//       "Location": "Arad",
//       "Longitude": 46.17660140991211,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Bucharest",
//       "Type": "airport"
//     },
//     {
//       "ID": 1648,
//       "Airport Code [ICAO]": "LRBC",
//       "Airport Code iATA,FAA": "BCM",
//       "Airport Name": "Bacău Airport",
//       "Altitude (Ft)": 607,
//       "Country": "Romania",
//       "Latitude": 26.91029930114746,
//       "Location": "Bacau",
//       "Longitude": 46.52190017700195,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Bucharest",
//       "Type": "airport"
//     },
//     {
//       "ID": 1649,
//       "Airport Code [ICAO]": "LRBM",
//       "Airport Code iATA,FAA": "BAY",
//       "Airport Name": "Tautii Magheraus Airport",
//       "Altitude (Ft)": 605,
//       "Country": "Romania",
//       "Latitude": 23.469999313354492,
//       "Location": "Baia Mare",
//       "Longitude": 47.65840148925781,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Bucharest",
//       "Type": "airport"
//     },
//     {
//       "ID": 1650,
//       "Airport Code [ICAO]": "LRBS",
//       "Airport Code iATA,FAA": "BBU",
//       "Airport Name": "Băneasa International Airport",
//       "Altitude (Ft)": 297,
//       "Country": "Romania",
//       "Latitude": 26.102100372314453,
//       "Location": "Bucharest",
//       "Longitude": 44.50320053100586,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Bucharest",
//       "Type": "airport"
//     },
//     {
//       "ID": 1651,
//       "Airport Code [ICAO]": "LRCK",
//       "Airport Code iATA,FAA": "CND",
//       "Airport Name": "Mihail Kogălniceanu International Airport",
//       "Altitude (Ft)": 353,
//       "Country": "Romania",
//       "Latitude": 28.488300323486328,
//       "Location": "Constanta",
//       "Longitude": 44.36220169067383,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Bucharest",
//       "Type": "airport"
//     },
//     {
//       "ID": 1652,
//       "Airport Code [ICAO]": "LRCL",
//       "Airport Code iATA,FAA": "CLJ",
//       "Airport Name": "Cluj-Napoca International Airport",
//       "Altitude (Ft)": 1036,
//       "Country": "Romania",
//       "Latitude": 23.686199188232422,
//       "Location": "Cluj-napoca",
//       "Longitude": 46.78519821166992,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Bucharest",
//       "Type": "airport"
//     },
//     {
//       "ID": 1653,
//       "Airport Code [ICAO]": "LRCS",
//       "Airport Code iATA,FAA": "CSB",
//       "Airport Name": "Caransebeş Airport",
//       "Altitude (Ft)": 866,
//       "Country": "Romania",
//       "Latitude": 22.253299713134766,
//       "Location": "Caransebes",
//       "Longitude": 45.41999816894531,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Bucharest",
//       "Type": "airport"
//     },
//     {
//       "ID": 1654,
//       "Airport Code [ICAO]": "LRCV",
//       "Airport Code iATA,FAA": "CRA",
//       "Airport Name": "Craiova Airport",
//       "Altitude (Ft)": 626,
//       "Country": "Romania",
//       "Latitude": 23.888599395751953,
//       "Location": "Craiova",
//       "Longitude": 44.31809997558594,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Bucharest",
//       "Type": "airport"
//     },
//     {
//       "ID": 1655,
//       "Airport Code [ICAO]": "LRIA",
//       "Airport Code iATA,FAA": "IAS",
//       "Airport Name": "Iaşi Airport",
//       "Altitude (Ft)": 397,
//       "Country": "Romania",
//       "Latitude": 27.6205997467041,
//       "Location": "Iasi",
//       "Longitude": 47.17850112915039,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Bucharest",
//       "Type": "airport"
//     },
//     {
//       "ID": 1656,
//       "Airport Code [ICAO]": "LROD",
//       "Airport Code iATA,FAA": "OMR",
//       "Airport Name": "Oradea International Airport",
//       "Altitude (Ft)": 465,
//       "Country": "Romania",
//       "Latitude": 21.90250015258789,
//       "Location": "Oradea",
//       "Longitude": 47.025299072265625,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Bucharest",
//       "Type": "airport"
//     },
//     {
//       "ID": 1657,
//       "Airport Code [ICAO]": "LROP",
//       "Airport Code iATA,FAA": "OTP",
//       "Airport Name": "Henri Coandă International Airport",
//       "Altitude (Ft)": 314,
//       "Country": "Romania",
//       "Latitude": 26.085,
//       "Location": "Bucharest",
//       "Longitude": 44.5711111,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Bucharest",
//       "Type": "airport"
//     },
//     {
//       "ID": 1658,
//       "Airport Code [ICAO]": "LRSB",
//       "Airport Code iATA,FAA": "SBZ",
//       "Airport Name": "Sibiu International Airport",
//       "Altitude (Ft)": 1496,
//       "Country": "Romania",
//       "Latitude": 24.091299057006836,
//       "Location": "Sibiu",
//       "Longitude": 45.78559875488281,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Bucharest",
//       "Type": "airport"
//     },
//     {
//       "ID": 1659,
//       "Airport Code [ICAO]": "LRSM",
//       "Airport Code iATA,FAA": "SUJ",
//       "Airport Name": "Satu Mare Airport",
//       "Altitude (Ft)": 405,
//       "Country": "Romania",
//       "Latitude": 22.885700225830078,
//       "Location": "Satu Mare",
//       "Longitude": 47.70330047607422,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Bucharest",
//       "Type": "airport"
//     },
//     {
//       "ID": 1660,
//       "Airport Code [ICAO]": "LRSV",
//       "Airport Code iATA,FAA": "SCV",
//       "Airport Name": "Suceava Stefan cel Mare Airport",
//       "Altitude (Ft)": 1375,
//       "Country": "Romania",
//       "Latitude": 26.35409927368164,
//       "Location": "Suceava",
//       "Longitude": 47.6875,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Bucharest",
//       "Type": "airport"
//     },
//     {
//       "ID": 1661,
//       "Airport Code [ICAO]": "LRTC",
//       "Airport Code iATA,FAA": "TCE",
//       "Airport Name": "Tulcea Airport",
//       "Altitude (Ft)": 200,
//       "Country": "Romania",
//       "Latitude": 28.71430015563965,
//       "Location": "Tulcea",
//       "Longitude": 45.0625,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Bucharest",
//       "Type": "airport"
//     },
//     {
//       "ID": 1662,
//       "Airport Code [ICAO]": "LRTM",
//       "Airport Code iATA,FAA": "TGM",
//       "Airport Name": "Transilvania Târgu Mureş International Airport",
//       "Altitude (Ft)": 963,
//       "Country": "Romania",
//       "Latitude": 24.412500381469727,
//       "Location": "Tirgu Mures",
//       "Longitude": 46.46770095825195,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Bucharest",
//       "Type": "airport"
//     },
//     {
//       "ID": 1663,
//       "Airport Code [ICAO]": "LRTR",
//       "Airport Code iATA,FAA": "TSR",
//       "Airport Name": "Timişoara Traian Vuia Airport",
//       "Altitude (Ft)": 348,
//       "Country": "Romania",
//       "Latitude": 21.337900161743164,
//       "Location": "Timisoara",
//       "Longitude": 45.809898376464844,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Bucharest",
//       "Type": "airport"
//     },
//     {
//       "ID": 1664,
//       "Airport Code [ICAO]": "LSGC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Les Eplatures Airport",
//       "Altitude (Ft)": 3368,
//       "Country": "Switzerland",
//       "Latitude": 6.792840003970001,
//       "Location": "Les Eplatures",
//       "Longitude": 47.083900451699996,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 1665,
//       "Airport Code [ICAO]": "LSGG",
//       "Airport Code iATA,FAA": "GVA",
//       "Airport Name": "Geneva Cointrin International Airport",
//       "Altitude (Ft)": 1411,
//       "Country": "Switzerland",
//       "Latitude": 6.108950138092041,
//       "Location": "Geneva",
//       "Longitude": 46.23809814453125,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 1666,
//       "Airport Code [ICAO]": "LSGK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Saanen Airport",
//       "Altitude (Ft)": 3307,
//       "Country": "Switzerland",
//       "Latitude": 7.25083017349,
//       "Location": "Saanen",
//       "Longitude": 46.4874992371,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 1667,
//       "Airport Code [ICAO]": "LSGS",
//       "Airport Code iATA,FAA": "SIR",
//       "Airport Name": "Sion Airport",
//       "Altitude (Ft)": 1585,
//       "Country": "Switzerland",
//       "Latitude": 7.326759815220001,
//       "Location": "Sion",
//       "Longitude": 46.219600677500004,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 1668,
//       "Airport Code [ICAO]": "LSMA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Alpnach Air Base",
//       "Altitude (Ft)": 1460,
//       "Country": "Switzerland",
//       "Latitude": 8.28417,
//       "Location": "Alpnach",
//       "Longitude": 46.943901,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 1669,
//       "Airport Code [ICAO]": "LSMD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Dübendorf Air Base",
//       "Altitude (Ft)": 1470,
//       "Country": "Switzerland",
//       "Latitude": 8.648229599,
//       "Location": "Dubendorf",
//       "Longitude": 47.398601532,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 1670,
//       "Airport Code [ICAO]": "LSME",
//       "Airport Code iATA,FAA": "EML",
//       "Airport Name": "Emmen Air Base",
//       "Altitude (Ft)": 1400,
//       "Country": "Switzerland",
//       "Latitude": 8.305184,
//       "Location": "Emmen",
//       "Longitude": 47.092444,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 1671,
//       "Airport Code [ICAO]": "LSMF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mollis Airport",
//       "Altitude (Ft)": 1485,
//       "Country": "Switzerland",
//       "Latitude": 9.06482982635498,
//       "Location": "Mollis",
//       "Longitude": 47.07889938354492,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 1672,
//       "Airport Code [ICAO]": "LSMM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Meiringen Airport",
//       "Altitude (Ft)": 1895,
//       "Country": "Switzerland",
//       "Latitude": 8.109999656677246,
//       "Location": "Meiringen",
//       "Longitude": 46.74330139160156,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 1673,
//       "Airport Code [ICAO]": "LSMP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Payerne Air Base",
//       "Altitude (Ft)": 1465,
//       "Country": "Switzerland",
//       "Latitude": 6.91506004333,
//       "Location": "Payerne",
//       "Longitude": 46.8432,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 1675,
//       "Airport Code [ICAO]": "LSZA",
//       "Airport Code iATA,FAA": "LUG",
//       "Airport Name": "Lugano Airport",
//       "Altitude (Ft)": 915,
//       "Country": "Switzerland",
//       "Latitude": 8.9105796814,
//       "Location": "Lugano",
//       "Longitude": 46.00429916379999,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 1676,
//       "Airport Code [ICAO]": "LSZB",
//       "Airport Code iATA,FAA": "BRN",
//       "Airport Name": "Bern Belp Airport",
//       "Altitude (Ft)": 1674,
//       "Country": "Switzerland",
//       "Latitude": 7.497149944309999,
//       "Location": "Bern",
//       "Longitude": 46.914100647,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 1677,
//       "Airport Code [ICAO]": "LSZG",
//       "Airport Code iATA,FAA": "ZHI",
//       "Airport Name": "Grenchen Airport",
//       "Altitude (Ft)": 1411,
//       "Country": "Switzerland",
//       "Latitude": 7.41719,
//       "Location": "Grenchen",
//       "Longitude": 47.181599,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 1678,
//       "Airport Code [ICAO]": "LSZH",
//       "Airport Code iATA,FAA": "ZRH",
//       "Airport Name": "Zürich Airport",
//       "Altitude (Ft)": 1416,
//       "Country": "Switzerland",
//       "Latitude": 8.54917,
//       "Location": "Zurich",
//       "Longitude": 47.464699,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 1679,
//       "Airport Code [ICAO]": "LSZR",
//       "Airport Code iATA,FAA": "ACH",
//       "Airport Name": "St Gallen Altenrhein Airport",
//       "Altitude (Ft)": 1306,
//       "Country": "Switzerland",
//       "Latitude": 9.560770034789998,
//       "Location": "Altenrhein",
//       "Longitude": 47.4850006104,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 1680,
//       "Airport Code [ICAO]": "LSZS",
//       "Airport Code iATA,FAA": "SMV",
//       "Airport Name": "Samedan Airport",
//       "Altitude (Ft)": 5600,
//       "Country": "Switzerland",
//       "Latitude": 9.884110450744629,
//       "Location": "Samedan",
//       "Longitude": 46.53409957885742,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 1681,
//       "Airport Code [ICAO]": "LTAB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Güvercinlik Airport",
//       "Altitude (Ft)": 2687,
//       "Country": "Turkey",
//       "Latitude": 32.7407989502,
//       "Location": "Ankara",
//       "Longitude": 39.9350013733,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1682,
//       "Airport Code [ICAO]": "LTAC",
//       "Airport Code iATA,FAA": "ESB",
//       "Airport Name": "Esenboğa International Airport",
//       "Altitude (Ft)": 3125,
//       "Country": "Turkey",
//       "Latitude": 32.995098114,
//       "Location": "Ankara",
//       "Longitude": 40.128101348899996,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1683,
//       "Airport Code [ICAO]": "LTAD",
//       "Airport Code iATA,FAA": "ANK",
//       "Airport Name": "Etimesgut Air Base",
//       "Altitude (Ft)": 2653,
//       "Country": "Turkey",
//       "Latitude": 32.6885986328,
//       "Location": "Ankara",
//       "Longitude": 39.949798584,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1684,
//       "Airport Code [ICAO]": "LTAE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Akıncı Air Base",
//       "Altitude (Ft)": 2767,
//       "Country": "Turkey",
//       "Latitude": 32.5656013489,
//       "Location": "Ankara",
//       "Longitude": 40.078899383499994,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1685,
//       "Airport Code [ICAO]": "LTAF",
//       "Airport Code iATA,FAA": "ADA",
//       "Airport Name": "Adana Airport",
//       "Altitude (Ft)": 65,
//       "Country": "Turkey",
//       "Latitude": 35.280399322499996,
//       "Location": "Adana",
//       "Longitude": 36.9822006226,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1686,
//       "Airport Code [ICAO]": "LTAG",
//       "Airport Code iATA,FAA": "UAB",
//       "Airport Name": "İncirlik Air Base",
//       "Altitude (Ft)": 238,
//       "Country": "Turkey",
//       "Latitude": 35.4258995056,
//       "Location": "Adana",
//       "Longitude": 37.002101898199996,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1687,
//       "Airport Code [ICAO]": "LTAH",
//       "Airport Code iATA,FAA": "AFY",
//       "Airport Name": "Afyon Airport",
//       "Altitude (Ft)": 3310,
//       "Country": "Turkey",
//       "Latitude": 30.6011009216,
//       "Location": "Afyon",
//       "Longitude": 38.726398468,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1688,
//       "Airport Code [ICAO]": "LTAI",
//       "Airport Code iATA,FAA": "AYT",
//       "Airport Name": "Antalya International Airport",
//       "Altitude (Ft)": 177,
//       "Country": "Turkey",
//       "Latitude": 30.800501,
//       "Location": "Antalya",
//       "Longitude": 36.898701,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1689,
//       "Airport Code [ICAO]": "LTAJ",
//       "Airport Code iATA,FAA": "GZT",
//       "Airport Name": "Gaziantep International Airport",
//       "Altitude (Ft)": 2315,
//       "Country": "Turkey",
//       "Latitude": 37.4786987305,
//       "Location": "Gaziantep",
//       "Longitude": 36.9472007751,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1690,
//       "Airport Code [ICAO]": "LTAK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "İskenderun Airport",
//       "Altitude (Ft)": 25,
//       "Country": "Turkey",
//       "Latitude": 36.1534194946,
//       "Location": "Iskenderun",
//       "Longitude": 36.5744552612,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1691,
//       "Airport Code [ICAO]": "LTAN",
//       "Airport Code iATA,FAA": "KYA",
//       "Airport Name": "Konya Airport",
//       "Altitude (Ft)": 3392,
//       "Country": "Turkey",
//       "Latitude": 32.561901,
//       "Location": "Konya",
//       "Longitude": 37.979,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1692,
//       "Airport Code [ICAO]": "LTAO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Malatya Tulga Airport",
//       "Altitude (Ft)": 3016,
//       "Country": "Turkey",
//       "Latitude": 38.253899,
//       "Location": "Malatya",
//       "Longitude": 38.353699,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1693,
//       "Airport Code [ICAO]": "LTAP",
//       "Airport Code iATA,FAA": "MZH",
//       "Airport Name": "Amasya Merzifon Airport",
//       "Altitude (Ft)": 1758,
//       "Country": "Turkey",
//       "Latitude": 35.521999,
//       "Location": "Merzifon",
//       "Longitude": 40.829399,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1694,
//       "Airport Code [ICAO]": "LTAR",
//       "Airport Code iATA,FAA": "VAS",
//       "Airport Name": "Sivas Nuri Demirağ Airport",
//       "Altitude (Ft)": 5239,
//       "Country": "Turkey",
//       "Latitude": 36.9035,
//       "Location": "Sivas",
//       "Longitude": 39.813801,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1695,
//       "Airport Code [ICAO]": "LTAT",
//       "Airport Code iATA,FAA": "MLX",
//       "Airport Name": "Malatya Erhaç Airport",
//       "Altitude (Ft)": 2828,
//       "Country": "Turkey",
//       "Latitude": 38.0909996033,
//       "Location": "Malatya",
//       "Longitude": 38.435298919699996,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1696,
//       "Airport Code [ICAO]": "LTAU",
//       "Airport Code iATA,FAA": "ASR",
//       "Airport Name": "Kayseri Erkilet Airport",
//       "Altitude (Ft)": 3463,
//       "Country": "Turkey",
//       "Latitude": 35.4953994751,
//       "Location": "Kayseri",
//       "Longitude": 38.770401001,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1697,
//       "Airport Code [ICAO]": "LTAV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sivrihisar Airport",
//       "Altitude (Ft)": 3185,
//       "Country": "Turkey",
//       "Latitude": 31.365299224853516,
//       "Location": "Sivrihisar",
//       "Longitude": 39.451499938964844,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1698,
//       "Airport Code [ICAO]": "LTAW",
//       "Airport Code iATA,FAA": "TJK",
//       "Airport Name": "Tokat Airport",
//       "Altitude (Ft)": 1831,
//       "Country": "Turkey",
//       "Latitude": 36.367408752441406,
//       "Location": "Tokat",
//       "Longitude": 40.307430267333984,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1699,
//       "Airport Code [ICAO]": "LTAY",
//       "Airport Code iATA,FAA": "DNZ",
//       "Airport Name": "Çardak Airport",
//       "Altitude (Ft)": 2795,
//       "Country": "Turkey",
//       "Latitude": 29.7012996674,
//       "Location": "Denizli",
//       "Longitude": 37.7855987549,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1701,
//       "Airport Code [ICAO]": "LTBA",
//       "Airport Code iATA,FAA": "ISL",
//       "Airport Name": "Atatürk International Airport",
//       "Altitude (Ft)": 163,
//       "Country": "Turkey",
//       "Latitude": 28.8146,
//       "Location": "Istanbul",
//       "Longitude": 40.976898,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1702,
//       "Airport Code [ICAO]": "LTBF",
//       "Airport Code iATA,FAA": "BZI",
//       "Airport Name": "Balıkesir Merkez Airport",
//       "Altitude (Ft)": 340,
//       "Country": "Turkey",
//       "Latitude": 27.926000595092773,
//       "Location": "Balikesir",
//       "Longitude": 39.619300842285156,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1703,
//       "Airport Code [ICAO]": "LTBG",
//       "Airport Code iATA,FAA": "BDM",
//       "Airport Name": "Bandırma Airport",
//       "Altitude (Ft)": 170,
//       "Country": "Turkey",
//       "Latitude": 27.977699279785156,
//       "Location": "Bandirma",
//       "Longitude": 40.31800079345703,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1705,
//       "Airport Code [ICAO]": "LTBI",
//       "Airport Code iATA,FAA": "ESK",
//       "Airport Name": "Eskişehir Air Base",
//       "Altitude (Ft)": 2581,
//       "Country": "Turkey",
//       "Latitude": 30.582099914599997,
//       "Location": "Eskisehir",
//       "Longitude": 39.7840995789,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1706,
//       "Airport Code [ICAO]": "LTBJ",
//       "Airport Code iATA,FAA": "ADB",
//       "Airport Name": "Adnan Menderes International Airport",
//       "Altitude (Ft)": 412,
//       "Country": "Turkey",
//       "Latitude": 27.156999588,
//       "Location": "Izmir",
//       "Longitude": 38.2924003601,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1707,
//       "Airport Code [ICAO]": "LTBK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Gaziemir Airport",
//       "Altitude (Ft)": 433,
//       "Country": "Turkey",
//       "Latitude": 27.159400939941406,
//       "Location": "Izmir",
//       "Longitude": 38.31909942626953,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1708,
//       "Airport Code [ICAO]": "LTBL",
//       "Airport Code iATA,FAA": "IGL",
//       "Airport Name": "Çiğli Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Turkey",
//       "Latitude": 27.010099411,
//       "Location": "Izmir",
//       "Longitude": 38.513000488299994,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1709,
//       "Airport Code [ICAO]": "LTBM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Isparta Airport",
//       "Altitude (Ft)": 3250,
//       "Country": "Turkey",
//       "Latitude": 30.59001922607422,
//       "Location": "Isparta",
//       "Longitude": 37.78512191772461,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1710,
//       "Airport Code [ICAO]": "LTBN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kütahya Airport",
//       "Altitude (Ft)": 3026,
//       "Country": "Turkey",
//       "Latitude": 30.01689910888672,
//       "Location": "Kutahya",
//       "Longitude": 39.426700592041016,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1712,
//       "Airport Code [ICAO]": "LTBP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Yalova Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Turkey",
//       "Latitude": 29.375699996948242,
//       "Location": "Yalova",
//       "Longitude": 40.68439865112305,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1713,
//       "Airport Code [ICAO]": "LTBQ",
//       "Airport Code iATA,FAA": "KCO",
//       "Airport Name": "Cengiz Topel Airport",
//       "Altitude (Ft)": 182,
//       "Country": "Turkey",
//       "Latitude": 30.08329963684082,
//       "Location": "Topel",
//       "Longitude": 40.73500061035156,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1715,
//       "Airport Code [ICAO]": "LTBS",
//       "Airport Code iATA,FAA": "DLM",
//       "Airport Name": "Dalaman International Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Turkey",
//       "Latitude": 28.7924995422,
//       "Location": "Dalaman",
//       "Longitude": 36.7131004333,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1716,
//       "Airport Code [ICAO]": "LTBT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Akhisar Airport",
//       "Altitude (Ft)": 263,
//       "Country": "Turkey",
//       "Latitude": 27.833900451660156,
//       "Location": "Akhisar",
//       "Longitude": 38.80889892578125,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1718,
//       "Airport Code [ICAO]": "LTBV",
//       "Airport Code iATA,FAA": "BXN",
//       "Airport Name": "Imsık Airport",
//       "Altitude (Ft)": 202,
//       "Country": "Turkey",
//       "Latitude": 27.669700622558594,
//       "Location": "Bodrum",
//       "Longitude": 37.140098571777344,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1719,
//       "Airport Code [ICAO]": "LTBX",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Samandıra Air Base",
//       "Altitude (Ft)": 400,
//       "Country": "Turkey",
//       "Latitude": 29.21649932861328,
//       "Location": "Istanbul",
//       "Longitude": 40.99300003051758,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1721,
//       "Airport Code [ICAO]": "LTCA",
//       "Airport Code iATA,FAA": "EZS",
//       "Airport Name": "Elazığ Airport",
//       "Altitude (Ft)": 2927,
//       "Country": "Turkey",
//       "Latitude": 39.2914009094,
//       "Location": "Elazig",
//       "Longitude": 38.6068992615,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1722,
//       "Airport Code [ICAO]": "LTCC",
//       "Airport Code iATA,FAA": "DIY",
//       "Airport Name": "Diyarbakir Airport",
//       "Altitude (Ft)": 2251,
//       "Country": "Turkey",
//       "Latitude": 40.201000213600004,
//       "Location": "Diyabakir",
//       "Longitude": 37.893901825,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1723,
//       "Airport Code [ICAO]": "LTCD",
//       "Airport Code iATA,FAA": "ERC",
//       "Airport Name": "Erzincan Airport",
//       "Altitude (Ft)": 3783,
//       "Country": "Turkey",
//       "Latitude": 39.527000427199994,
//       "Location": "Erzincan",
//       "Longitude": 39.7102012634,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1724,
//       "Airport Code [ICAO]": "LTCE",
//       "Airport Code iATA,FAA": "ERZ",
//       "Airport Name": "Erzurum International Airport",
//       "Altitude (Ft)": 5763,
//       "Country": "Turkey",
//       "Latitude": 41.17020034789999,
//       "Location": "Erzurum",
//       "Longitude": 39.9565010071,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1726,
//       "Airport Code [ICAO]": "LTCG",
//       "Airport Code iATA,FAA": "TZX",
//       "Airport Name": "Trabzon International Airport",
//       "Altitude (Ft)": 104,
//       "Country": "Turkey",
//       "Latitude": 39.78969955444336,
//       "Location": "Trabzon",
//       "Longitude": 40.99509811401367,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1728,
//       "Airport Code [ICAO]": "LTCI",
//       "Airport Code iATA,FAA": "VAN",
//       "Airport Name": "Van Ferit Melen Airport",
//       "Altitude (Ft)": 5480,
//       "Country": "Turkey",
//       "Latitude": 43.332298278808594,
//       "Location": "Van",
//       "Longitude": 38.46820068359375,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1729,
//       "Airport Code [ICAO]": "LTCJ",
//       "Airport Code iATA,FAA": "BAL",
//       "Airport Name": "Batman Airport",
//       "Altitude (Ft)": 1822,
//       "Country": "Turkey",
//       "Latitude": 41.1166000366,
//       "Location": "Batman",
//       "Longitude": 37.929000854499996,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1731,
//       "Airport Code [ICAO]": "LTCL",
//       "Airport Code iATA,FAA": "SXZ",
//       "Airport Name": "Siirt Airport",
//       "Altitude (Ft)": 2001,
//       "Country": "Turkey",
//       "Latitude": 41.84040069580078,
//       "Location": "Siirt",
//       "Longitude": 37.97890090942383,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1732,
//       "Airport Code [ICAO]": "LTFA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kaklıç Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Turkey",
//       "Latitude": 26.977399826049805,
//       "Location": "Izmir",
//       "Longitude": 38.517601013183594,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1733,
//       "Airport Code [ICAO]": "LTFB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Selçuk Efes Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Turkey",
//       "Latitude": 27.32900047302246,
//       "Location": "Izmir",
//       "Longitude": 37.95069885253906,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 1734,
//       "Airport Code [ICAO]": "LUBL",
//       "Airport Code iATA,FAA": "BZY",
//       "Airport Name": "Bălți International Airport",
//       "Altitude (Ft)": 758,
//       "Country": "Moldova",
//       "Latitude": 27.777222,
//       "Location": "Saltsy",
//       "Longitude": 47.843056,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Chisinau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1735,
//       "Airport Code [ICAO]": "LUKK",
//       "Airport Code iATA,FAA": "KIV",
//       "Airport Name": "Chişinău International Airport",
//       "Altitude (Ft)": 399,
//       "Country": "Moldova",
//       "Latitude": 28.930999755859375,
//       "Location": "Chisinau",
//       "Longitude": 46.92770004272461,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Chisinau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1736,
//       "Airport Code [ICAO]": "LWOH",
//       "Airport Code iATA,FAA": "OHD",
//       "Airport Name": "Ohrid St. Paul the Apostle Airport",
//       "Altitude (Ft)": 2313,
//       "Country": "Macedonia",
//       "Latitude": 20.7423,
//       "Location": "Ohrid",
//       "Longitude": 41.18,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Skopje",
//       "Type": "airport"
//     },
//     {
//       "ID": 1737,
//       "Airport Code [ICAO]": "LWSK",
//       "Airport Code iATA,FAA": "SKP",
//       "Airport Name": "Skopje Alexander the Great Airport",
//       "Altitude (Ft)": 781,
//       "Country": "Macedonia",
//       "Latitude": 21.621401,
//       "Location": "Skopje",
//       "Longitude": 41.961601,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Skopje",
//       "Type": "airport"
//     },
//     {
//       "ID": 1738,
//       "Airport Code [ICAO]": "LXGB",
//       "Airport Code iATA,FAA": "GIB",
//       "Airport Name": "Gibraltar Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Gibraltar",
//       "Latitude": -5.3496599197400005,
//       "Location": "Gibraltar",
//       "Longitude": 36.1511993408,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Europe/Gibraltar",
//       "Type": "airport"
//     },
//     {
//       "ID": 1739,
//       "Airport Code [ICAO]": "LYBE",
//       "Airport Code iATA,FAA": "BEG",
//       "Airport Name": "Belgrade Nikola Tesla Airport",
//       "Altitude (Ft)": 335,
//       "Country": "Serbia",
//       "Latitude": 20.3090991974,
//       "Location": "Belgrade",
//       "Longitude": 44.8184013367,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Belgrade",
//       "Type": "airport"
//     },
//     {
//       "ID": 1740,
//       "Airport Code [ICAO]": "LYNI",
//       "Airport Code iATA,FAA": "INI",
//       "Airport Name": "Nis Airport",
//       "Altitude (Ft)": 648,
//       "Country": "Serbia",
//       "Latitude": 21.853701,
//       "Location": "Nis",
//       "Longitude": 43.337299,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Belgrade",
//       "Type": "airport"
//     },
//     {
//       "ID": 1741,
//       "Airport Code [ICAO]": "LYPG",
//       "Airport Code iATA,FAA": "TGD",
//       "Airport Name": "Podgorica Airport",
//       "Altitude (Ft)": 141,
//       "Country": "Montenegro",
//       "Latitude": 19.2519,
//       "Location": "Podgorica",
//       "Longitude": 42.359402,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Podgorica",
//       "Type": "airport"
//     },
//     {
//       "ID": 1742,
//       "Airport Code [ICAO]": "BKPR",
//       "Airport Code iATA,FAA": "PRN",
//       "Airport Name": "Priština International Airport",
//       "Altitude (Ft)": 1789,
//       "Country": "Serbia",
//       "Latitude": 21.035801,
//       "Location": "Pristina",
//       "Longitude": 42.5728,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Belgrade",
//       "Type": "airport"
//     },
//     {
//       "ID": 1743,
//       "Airport Code [ICAO]": "LYTV",
//       "Airport Code iATA,FAA": "TIV",
//       "Airport Name": "Tivat Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Montenegro",
//       "Latitude": 18.72330093383789,
//       "Location": "Tivat",
//       "Longitude": 42.404701232910156,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Podgorica",
//       "Type": "airport"
//     },
//     {
//       "ID": 1744,
//       "Airport Code [ICAO]": "LYVR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Vršac International Airport",
//       "Altitude (Ft)": 276,
//       "Country": "Serbia",
//       "Latitude": 21.3099,
//       "Location": "Vrsac",
//       "Longitude": 45.1469,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Belgrade",
//       "Type": "airport"
//     },
//     {
//       "ID": 1745,
//       "Airport Code [ICAO]": "LZIB",
//       "Airport Code iATA,FAA": "BTS",
//       "Airport Name": "M. R. Štefánik Airport",
//       "Altitude (Ft)": 436,
//       "Country": "Slovakia",
//       "Latitude": 17.21269989013672,
//       "Location": "Bratislava",
//       "Longitude": 48.17020034790039,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Bratislava",
//       "Type": "airport"
//     },
//     {
//       "ID": 1746,
//       "Airport Code [ICAO]": "LZKZ",
//       "Airport Code iATA,FAA": "KSC",
//       "Airport Name": "Košice Airport",
//       "Altitude (Ft)": 755,
//       "Country": "Slovakia",
//       "Latitude": 21.241100311279297,
//       "Location": "Kosice",
//       "Longitude": 48.66310119628906,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Bratislava",
//       "Type": "airport"
//     },
//     {
//       "ID": 1747,
//       "Airport Code [ICAO]": "LZMC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kuchyňa Air Base",
//       "Altitude (Ft)": 679,
//       "Country": "Slovakia",
//       "Latitude": 17.11840057373047,
//       "Location": "Malacky",
//       "Longitude": 48.402000427246094,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Bratislava",
//       "Type": "airport"
//     },
//     {
//       "ID": 1748,
//       "Airport Code [ICAO]": "LZPP",
//       "Airport Code iATA,FAA": "PZY",
//       "Airport Name": "Piešťany Airport",
//       "Altitude (Ft)": 545,
//       "Country": "Slovakia",
//       "Latitude": 17.828399658203125,
//       "Location": "Piestany",
//       "Longitude": 48.62519836425781,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Bratislava",
//       "Type": "airport"
//     },
//     {
//       "ID": 1749,
//       "Airport Code [ICAO]": "LZSL",
//       "Airport Code iATA,FAA": "SLD",
//       "Airport Name": "Sliač Airport",
//       "Altitude (Ft)": 1043,
//       "Country": "Slovakia",
//       "Latitude": 19.13409996032715,
//       "Location": "Sliac",
//       "Longitude": 48.63779830932617,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Bratislava",
//       "Type": "airport"
//     },
//     {
//       "ID": 1750,
//       "Airport Code [ICAO]": "LZTN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Trenčín Airport",
//       "Altitude (Ft)": 676,
//       "Country": "Slovakia",
//       "Latitude": 17.9923000336,
//       "Location": "Trencin",
//       "Longitude": 48.8650016785,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Bratislava",
//       "Type": "airport"
//     },
//     {
//       "ID": 1751,
//       "Airport Code [ICAO]": "LZTT",
//       "Airport Code iATA,FAA": "TAT",
//       "Airport Name": "Poprad-Tatry Airport",
//       "Altitude (Ft)": 2356,
//       "Country": "Slovakia",
//       "Latitude": 20.2411003113,
//       "Location": "Poprad",
//       "Longitude": 49.073600769,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Bratislava",
//       "Type": "airport"
//     },
//     {
//       "ID": 1753,
//       "Airport Code [ICAO]": "MBNC",
//       "Airport Code iATA,FAA": "NCA",
//       "Airport Name": "North Caicos Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Turks and Caicos Islands",
//       "Latitude": -71.939598,
//       "Location": "North Caicos",
//       "Longitude": 21.9175,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Grand_Turk",
//       "Type": "airport"
//     },
//     {
//       "ID": 1754,
//       "Airport Code [ICAO]": "MBPV",
//       "Airport Code iATA,FAA": "PLS",
//       "Airport Name": "Providenciales Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Turks and Caicos Islands",
//       "Latitude": -72.26589965820312,
//       "Location": "Providenciales",
//       "Longitude": 21.77359962463379,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Grand_Turk",
//       "Type": "airport"
//     },
//     {
//       "ID": 1755,
//       "Airport Code [ICAO]": "MBSC",
//       "Airport Code iATA,FAA": "XSC",
//       "Airport Name": "South Caicos Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Turks and Caicos Islands",
//       "Latitude": -71.528503418,
//       "Location": "South Caicos",
//       "Longitude": 21.515699386599998,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Grand_Turk",
//       "Type": "airport"
//     },
//     {
//       "ID": 1756,
//       "Airport Code [ICAO]": "MDAB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Arroyo Barril Airport",
//       "Altitude (Ft)": 57,
//       "Country": "Dominican Republic",
//       "Latitude": -69.42980194089999,
//       "Location": "Samana",
//       "Longitude": 19.198600769,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Santo_Domingo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1757,
//       "Airport Code [ICAO]": "MDBH",
//       "Airport Code iATA,FAA": "BRX",
//       "Airport Name": "Maria Montez International Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Dominican Republic",
//       "Latitude": -71.12039947509766,
//       "Location": "Barahona",
//       "Longitude": 18.25149917602539,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Santo_Domingo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1758,
//       "Airport Code [ICAO]": "MDCR",
//       "Airport Code iATA,FAA": "CBJ",
//       "Airport Name": "Cabo Rojo Airport",
//       "Altitude (Ft)": 262,
//       "Country": "Dominican Republic",
//       "Latitude": -71.6447982788086,
//       "Location": "Cabo Rojo",
//       "Longitude": 17.929000854492188,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Santo_Domingo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1759,
//       "Airport Code [ICAO]": "MDLR",
//       "Airport Code iATA,FAA": "LRM",
//       "Airport Name": "Casa De Campo International Airport",
//       "Altitude (Ft)": 240,
//       "Country": "Dominican Republic",
//       "Latitude": -68.91179656982422,
//       "Location": "La Romana",
//       "Longitude": 18.450700759887695,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Santo_Domingo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1760,
//       "Airport Code [ICAO]": "MDPC",
//       "Airport Code iATA,FAA": "PUJ",
//       "Airport Name": "Punta Cana International Airport",
//       "Altitude (Ft)": 47,
//       "Country": "Dominican Republic",
//       "Latitude": -68.36340332030001,
//       "Location": "Punta Cana",
//       "Longitude": 18.567399978599997,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Santo_Domingo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1761,
//       "Airport Code [ICAO]": "MDPP",
//       "Airport Code iATA,FAA": "POP",
//       "Airport Name": "Gregorio Luperon International Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Dominican Republic",
//       "Latitude": -70.56999969482422,
//       "Location": "Puerto Plata",
//       "Longitude": 19.75790023803711,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Santo_Domingo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1762,
//       "Airport Code [ICAO]": "MDSD",
//       "Airport Code iATA,FAA": "SDQ",
//       "Airport Name": "Las Américas International Airport",
//       "Altitude (Ft)": 59,
//       "Country": "Dominican Republic",
//       "Latitude": -69.668899536133,
//       "Location": "Santo Domingo",
//       "Longitude": 18.42970085144,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Santo_Domingo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1763,
//       "Airport Code [ICAO]": "MDSI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "San Isidro Air Base",
//       "Altitude (Ft)": 111,
//       "Country": "Dominican Republic",
//       "Latitude": -69.76170349121094,
//       "Location": "San Isidoro",
//       "Longitude": 18.503700256347656,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Santo_Domingo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1764,
//       "Airport Code [ICAO]": "MDST",
//       "Airport Code iATA,FAA": "STI",
//       "Airport Name": "Cibao International Airport",
//       "Altitude (Ft)": 565,
//       "Country": "Dominican Republic",
//       "Latitude": -70.60469818115234,
//       "Location": "Santiago",
//       "Longitude": 19.406099319458008,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Santo_Domingo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1765,
//       "Airport Code [ICAO]": "MGBN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bananera Airport",
//       "Altitude (Ft)": 130,
//       "Country": "Guatemala",
//       "Latitude": -88.83719635009766,
//       "Location": "Bananera",
//       "Longitude": 15.47350025177002,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Guatemala",
//       "Type": "airport"
//     },
//     {
//       "ID": 1766,
//       "Airport Code [ICAO]": "MGCB",
//       "Airport Code iATA,FAA": "CBV",
//       "Airport Name": "Coban Airport",
//       "Altitude (Ft)": 4339,
//       "Country": "Guatemala",
//       "Latitude": -90.40670013427734,
//       "Location": "Coban",
//       "Longitude": 15.468999862670898,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Guatemala",
//       "Type": "airport"
//     },
//     {
//       "ID": 1767,
//       "Airport Code [ICAO]": "MGGT",
//       "Airport Code iATA,FAA": "GUA",
//       "Airport Name": "La Aurora Airport",
//       "Altitude (Ft)": 4952,
//       "Country": "Guatemala",
//       "Latitude": -90.527496,
//       "Location": "Guatemala City",
//       "Longitude": 14.5833,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Guatemala",
//       "Type": "airport"
//     },
//     {
//       "ID": 1769,
//       "Airport Code [ICAO]": "MGRT",
//       "Airport Code iATA,FAA": "RER",
//       "Airport Name": "Retalhuleu Airport",
//       "Altitude (Ft)": 656,
//       "Country": "Guatemala",
//       "Latitude": -91.69730377197266,
//       "Location": "Retalhuleu",
//       "Longitude": 14.520999908447266,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Guatemala",
//       "Type": "airport"
//     },
//     {
//       "ID": 1770,
//       "Airport Code [ICAO]": "MGSJ",
//       "Airport Code iATA,FAA": "GSJ",
//       "Airport Name": "San José Airport",
//       "Altitude (Ft)": 29,
//       "Country": "Guatemala",
//       "Latitude": -90.83580017090001,
//       "Location": "San Jose",
//       "Longitude": 13.936200141899999,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Guatemala",
//       "Type": "airport"
//     },
//     {
//       "ID": 1771,
//       "Airport Code [ICAO]": "MHLC",
//       "Airport Code iATA,FAA": "LCE",
//       "Airport Name": "Goloson International Airport",
//       "Altitude (Ft)": 39,
//       "Country": "Honduras",
//       "Latitude": -86.852997,
//       "Location": "La Ceiba",
//       "Longitude": 15.7425,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Tegucigalpa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1772,
//       "Airport Code [ICAO]": "MHLM",
//       "Airport Code iATA,FAA": "SAP",
//       "Airport Name": "Ramón Villeda Morales International Airport",
//       "Altitude (Ft)": 91,
//       "Country": "Honduras",
//       "Latitude": -87.923599,
//       "Location": "San Pedro Sula",
//       "Longitude": 15.4526,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Tegucigalpa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1773,
//       "Airport Code [ICAO]": "MHNJ",
//       "Airport Code iATA,FAA": "GJA",
//       "Airport Name": "La Laguna Airport",
//       "Altitude (Ft)": 49,
//       "Country": "Honduras",
//       "Latitude": -85.906601,
//       "Location": "Guanaja",
//       "Longitude": 16.4454,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Tegucigalpa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1774,
//       "Airport Code [ICAO]": "MHRO",
//       "Airport Code iATA,FAA": "RTB",
//       "Airport Name": "Juan Manuel Galvez International Airport",
//       "Altitude (Ft)": 39,
//       "Country": "Honduras",
//       "Latitude": -86.523003,
//       "Location": "Roatan",
//       "Longitude": 16.316799,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Tegucigalpa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1775,
//       "Airport Code [ICAO]": "MHTE",
//       "Airport Code iATA,FAA": "TEA",
//       "Airport Name": "Tela Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Honduras",
//       "Latitude": -87.4758,
//       "Location": "Tela",
//       "Longitude": 15.7759,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Tegucigalpa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1776,
//       "Airport Code [ICAO]": "MHTG",
//       "Airport Code iATA,FAA": "TGU",
//       "Airport Name": "Toncontín International Airport",
//       "Altitude (Ft)": 3294,
//       "Country": "Honduras",
//       "Latitude": -87.21720123291016,
//       "Location": "Tegucigalpa",
//       "Longitude": 14.06089973449707,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Tegucigalpa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1777,
//       "Airport Code [ICAO]": "MHTJ",
//       "Airport Code iATA,FAA": "TJI",
//       "Airport Name": "Trujillo Airport",
//       "Altitude (Ft)": 3,
//       "Country": "Honduras",
//       "Latitude": -85.938202,
//       "Location": "Trujillo",
//       "Longitude": 15.9268,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Tegucigalpa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1778,
//       "Airport Code [ICAO]": "MKBS",
//       "Airport Code iATA,FAA": "OCJ",
//       "Airport Name": "Boscobel Aerodrome",
//       "Altitude (Ft)": 90,
//       "Country": "Jamaica",
//       "Latitude": -76.96900177001953,
//       "Location": "Ocho Rios",
//       "Longitude": 18.404199600219727,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Jamaica",
//       "Type": "airport"
//     },
//     {
//       "ID": 1779,
//       "Airport Code [ICAO]": "MKJP",
//       "Airport Code iATA,FAA": "KIN",
//       "Airport Name": "Norman Manley International Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Jamaica",
//       "Latitude": -76.7874984741211,
//       "Location": "Kingston",
//       "Longitude": 17.935699462890625,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Jamaica",
//       "Type": "airport"
//     },
//     {
//       "ID": 1780,
//       "Airport Code [ICAO]": "MKJS",
//       "Airport Code iATA,FAA": "MBJ",
//       "Airport Name": "Sangster International Airport",
//       "Altitude (Ft)": 4,
//       "Country": "Jamaica",
//       "Latitude": -77.91339874267578,
//       "Location": "Montego Bay",
//       "Longitude": 18.503700256347656,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Jamaica",
//       "Type": "airport"
//     },
//     {
//       "ID": 1781,
//       "Airport Code [ICAO]": "MKKJ",
//       "Airport Code iATA,FAA": "POT",
//       "Airport Name": "Ken Jones Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Jamaica",
//       "Latitude": -76.53450012210001,
//       "Location": "Port Antonio",
//       "Longitude": 18.1987991333,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Jamaica",
//       "Type": "airport"
//     },
//     {
//       "ID": 1782,
//       "Airport Code [ICAO]": "MKTP",
//       "Airport Code iATA,FAA": "KTP",
//       "Airport Name": "Tinson Pen Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Jamaica",
//       "Latitude": -76.82379913330078,
//       "Location": "Kingston",
//       "Longitude": 17.98859977722168,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Jamaica",
//       "Type": "airport"
//     },
//     {
//       "ID": 1783,
//       "Airport Code [ICAO]": "MMAA",
//       "Airport Code iATA,FAA": "ACA",
//       "Airport Name": "General Juan N Alvarez International Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Mexico",
//       "Latitude": -99.75399780273438,
//       "Location": "Acapulco",
//       "Longitude": 16.757099151611328,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1784,
//       "Airport Code [ICAO]": "MMAN",
//       "Airport Code iATA,FAA": "NTR",
//       "Airport Name": "Del Norte International Airport",
//       "Altitude (Ft)": 1476,
//       "Country": "Mexico",
//       "Latitude": -100.237,
//       "Location": "Monterrey",
//       "Longitude": 25.865601,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1785,
//       "Airport Code [ICAO]": "MMAS",
//       "Airport Code iATA,FAA": "AGU",
//       "Airport Name": "Jesús Terán Paredo International Airport",
//       "Altitude (Ft)": 6112,
//       "Country": "Mexico",
//       "Latitude": -102.318001,
//       "Location": "Aguascalientes",
//       "Longitude": 21.705601,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1786,
//       "Airport Code [ICAO]": "MMBT",
//       "Airport Code iATA,FAA": "HUX",
//       "Airport Name": "Bahías de Huatulco International Airport",
//       "Altitude (Ft)": 464,
//       "Country": "Mexico",
//       "Latitude": -96.262604,
//       "Location": "Huatulco",
//       "Longitude": 15.7753,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1787,
//       "Airport Code [ICAO]": "MMCB",
//       "Airport Code iATA,FAA": "CVJ",
//       "Airport Name": "General Mariano Matamoros Airport",
//       "Altitude (Ft)": 4277,
//       "Country": "Mexico",
//       "Latitude": -99.26129913330078,
//       "Location": "Cuernavaca",
//       "Longitude": 18.834800720214844,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1788,
//       "Airport Code [ICAO]": "MMCC",
//       "Airport Code iATA,FAA": "ACN",
//       "Airport Name": "Ciudad Acuña New International Airport",
//       "Altitude (Ft)": 1410,
//       "Country": "Mexico",
//       "Latitude": -101.098998,
//       "Location": "Ciudad Acuna",
//       "Longitude": 29.332899,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1789,
//       "Airport Code [ICAO]": "MMCE",
//       "Airport Code iATA,FAA": "CME",
//       "Airport Name": "Ciudad del Carmen International Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Mexico",
//       "Latitude": -91.79900360107422,
//       "Location": "Ciudad Del Carmen",
//       "Longitude": 18.65369987487793,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1790,
//       "Airport Code [ICAO]": "MMCG",
//       "Airport Code iATA,FAA": "NCG",
//       "Airport Name": "Nuevo Casas Grandes Airport",
//       "Altitude (Ft)": 4850,
//       "Country": "Mexico",
//       "Latitude": -107.875,
//       "Location": "Nuevo Casas Grandes",
//       "Longitude": 30.3974,
//       "GMT Offset": "-7",
//       "Daylight Savings": "S",
//       "Region": "America/Mazatlan",
//       "Type": "airport"
//     },
//     {
//       "ID": 1791,
//       "Airport Code [ICAO]": "MMCH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Chilpancingo Airport",
//       "Altitude (Ft)": 4199,
//       "Country": "Mexico",
//       "Latitude": -99.515424,
//       "Location": "Chilpancingo",
//       "Longitude": 17.574239,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1792,
//       "Airport Code [ICAO]": "MMCL",
//       "Airport Code iATA,FAA": "CUL",
//       "Airport Name": "Bachigualato Federal International Airport",
//       "Altitude (Ft)": 108,
//       "Country": "Mexico",
//       "Latitude": -107.474998474,
//       "Location": "Culiacan",
//       "Longitude": 24.7644996643,
//       "GMT Offset": "-7",
//       "Daylight Savings": "S",
//       "Region": "America/Mazatlan",
//       "Type": "airport"
//     },
//     {
//       "ID": 1793,
//       "Airport Code [ICAO]": "MMCM",
//       "Airport Code iATA,FAA": "CTM",
//       "Airport Name": "Chetumal International Airport",
//       "Altitude (Ft)": 39,
//       "Country": "Mexico",
//       "Latitude": -88.32679748535156,
//       "Location": "Chetumal",
//       "Longitude": 18.50469970703125,
//       "GMT Offset": "-5",
//       "Daylight Savings": "S",
//       "Region": "America/Cancun",
//       "Type": "airport"
//     },
//     {
//       "ID": 1794,
//       "Airport Code [ICAO]": "MMCN",
//       "Airport Code iATA,FAA": "CEN",
//       "Airport Name": "Ciudad Obregón International Airport",
//       "Altitude (Ft)": 243,
//       "Country": "Mexico",
//       "Latitude": -109.83300018310547,
//       "Location": "Ciudad Obregon",
//       "Longitude": 27.39259910583496,
//       "GMT Offset": "-7",
//       "Daylight Savings": "S",
//       "Region": "America/Hermosillo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1795,
//       "Airport Code [ICAO]": "MMCP",
//       "Airport Code iATA,FAA": "CPE",
//       "Airport Name": "Ingeniero Alberto Acuña Ongay International Airport",
//       "Altitude (Ft)": 34,
//       "Country": "Mexico",
//       "Latitude": -90.5002975464,
//       "Location": "Campeche",
//       "Longitude": 19.816799163800003,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1796,
//       "Airport Code [ICAO]": "MMCS",
//       "Airport Code iATA,FAA": "CJS",
//       "Airport Name": "Abraham González International Airport",
//       "Altitude (Ft)": 3904,
//       "Country": "Mexico",
//       "Latitude": -106.42900085449219,
//       "Location": "Ciudad Juarez",
//       "Longitude": 31.63610076904297,
//       "GMT Offset": "-7",
//       "Daylight Savings": "S",
//       "Region": "America/Mazatlan",
//       "Type": "airport"
//     },
//     {
//       "ID": 1797,
//       "Airport Code [ICAO]": "MMCU",
//       "Airport Code iATA,FAA": "CUU",
//       "Airport Name": "General Roberto Fierro Villalobos International Airport",
//       "Altitude (Ft)": 4462,
//       "Country": "Mexico",
//       "Latitude": -105.964996338,
//       "Location": "Chihuahua",
//       "Longitude": 28.702899932900003,
//       "GMT Offset": "-7",
//       "Daylight Savings": "S",
//       "Region": "America/Mazatlan",
//       "Type": "airport"
//     },
//     {
//       "ID": 1798,
//       "Airport Code [ICAO]": "MMCV",
//       "Airport Code iATA,FAA": "CVM",
//       "Airport Name": "General Pedro Jose Mendez International Airport",
//       "Altitude (Ft)": 761,
//       "Country": "Mexico",
//       "Latitude": -98.9564971924,
//       "Location": "Ciudad Victoria",
//       "Longitude": 23.7033004761,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1800,
//       "Airport Code [ICAO]": "MMCZ",
//       "Airport Code iATA,FAA": "CZM",
//       "Airport Name": "Cozumel International Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Mexico",
//       "Latitude": -86.92559814453125,
//       "Location": "Cozumel",
//       "Longitude": 20.52239990234375,
//       "GMT Offset": "-5",
//       "Daylight Savings": "S",
//       "Region": "America/Cancun",
//       "Type": "airport"
//     },
//     {
//       "ID": 1801,
//       "Airport Code [ICAO]": "MMDO",
//       "Airport Code iATA,FAA": "DGO",
//       "Airport Name": "General Guadalupe Victoria International Airport",
//       "Altitude (Ft)": 6104,
//       "Country": "Mexico",
//       "Latitude": -104.527999878,
//       "Location": "Durango",
//       "Longitude": 24.1242008209,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1802,
//       "Airport Code [ICAO]": "MMEP",
//       "Airport Code iATA,FAA": "TPQ",
//       "Airport Name": "Amado Nervo National Airport",
//       "Altitude (Ft)": 3020,
//       "Country": "Mexico",
//       "Latitude": -104.843002,
//       "Location": "Tepic",
//       "Longitude": 21.4195,
//       "GMT Offset": "-7",
//       "Daylight Savings": "S",
//       "Region": "America/Mazatlan",
//       "Type": "airport"
//     },
//     {
//       "ID": 1803,
//       "Airport Code [ICAO]": "MMES",
//       "Airport Code iATA,FAA": "ESE",
//       "Airport Name": "Ensenada International Airport",
//       "Altitude (Ft)": 66,
//       "Country": "Mexico",
//       "Latitude": -116.602997,
//       "Location": "Ensenada",
//       "Longitude": 31.7953,
//       "GMT Offset": "-8",
//       "Daylight Savings": "S",
//       "Region": "America/Tijuana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1804,
//       "Airport Code [ICAO]": "MMGL",
//       "Airport Code iATA,FAA": "GDL",
//       "Airport Name": "Don Miguel Hidalgo Y Costilla International Airport",
//       "Altitude (Ft)": 5016,
//       "Country": "Mexico",
//       "Latitude": -103.31099700927734,
//       "Location": "Guadalajara",
//       "Longitude": 20.521799087524414,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1805,
//       "Airport Code [ICAO]": "MMGM",
//       "Airport Code iATA,FAA": "GYM",
//       "Airport Name": "General José María Yáñez International Airport",
//       "Altitude (Ft)": 59,
//       "Country": "Mexico",
//       "Latitude": -110.92500305175781,
//       "Location": "Guaymas",
//       "Longitude": 27.9689998626709,
//       "GMT Offset": "-7",
//       "Daylight Savings": "S",
//       "Region": "America/Hermosillo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1806,
//       "Airport Code [ICAO]": "MMHC",
//       "Airport Code iATA,FAA": "TCN",
//       "Airport Name": "Tehuacan Airport",
//       "Altitude (Ft)": 5509,
//       "Country": "Mexico",
//       "Latitude": -97.4198989868164,
//       "Location": "Tehuacan",
//       "Longitude": 18.49720001220703,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1807,
//       "Airport Code [ICAO]": "MMHO",
//       "Airport Code iATA,FAA": "HMO",
//       "Airport Name": "General Ignacio P. Garcia International Airport",
//       "Altitude (Ft)": 627,
//       "Country": "Mexico",
//       "Latitude": -111.047996521,
//       "Location": "Hermosillo",
//       "Longitude": 29.095899581900003,
//       "GMT Offset": "-7",
//       "Daylight Savings": "S",
//       "Region": "America/Hermosillo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1808,
//       "Airport Code [ICAO]": "MMIA",
//       "Airport Code iATA,FAA": "CLQ",
//       "Airport Name": "Licenciado Miguel de la Madrid Airport",
//       "Altitude (Ft)": 2467,
//       "Country": "Mexico",
//       "Latitude": -103.577002,
//       "Location": "Colima",
//       "Longitude": 19.277,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1809,
//       "Airport Code [ICAO]": "MMIM",
//       "Airport Code iATA,FAA": "ISJ",
//       "Airport Name": "Isla Mujeres Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Mexico",
//       "Latitude": -86.73999786376953,
//       "Location": "Isla Mujeres",
//       "Longitude": 21.2450008392334,
//       "GMT Offset": "-5",
//       "Daylight Savings": "S",
//       "Region": "America/Cancun",
//       "Type": "airport"
//     },
//     {
//       "ID": 1810,
//       "Airport Code [ICAO]": "MMIO",
//       "Airport Code iATA,FAA": "SLW",
//       "Airport Name": "Plan De Guadalupe International Airport",
//       "Altitude (Ft)": 4778,
//       "Country": "Mexico",
//       "Latitude": -100.92900085449219,
//       "Location": "Saltillo",
//       "Longitude": 25.54949951171875,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1811,
//       "Airport Code [ICAO]": "MMIT",
//       "Airport Code iATA,FAA": "IZT",
//       "Airport Name": "Ixtepec Airport",
//       "Altitude (Ft)": 164,
//       "Country": "Mexico",
//       "Latitude": -95.093697,
//       "Location": "Iztepec",
//       "Longitude": 16.449301,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1813,
//       "Airport Code [ICAO]": "MMLC",
//       "Airport Code iATA,FAA": "LZC",
//       "Airport Name": "Lázaro Cárdenas Airport",
//       "Altitude (Ft)": 39,
//       "Country": "Mexico",
//       "Latitude": -102.221000671,
//       "Location": "Lazard Cardenas",
//       "Longitude": 18.0016994476,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1814,
//       "Airport Code [ICAO]": "MMLM",
//       "Airport Code iATA,FAA": "LMM",
//       "Airport Name": "Valle del Fuerte International Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Mexico",
//       "Latitude": -109.081001282,
//       "Location": "Los Mochis",
//       "Longitude": 25.6851997375,
//       "GMT Offset": "-7",
//       "Daylight Savings": "S",
//       "Region": "America/Mazatlan",
//       "Type": "airport"
//     },
//     {
//       "ID": 1815,
//       "Airport Code [ICAO]": "MMLO",
//       "Airport Code iATA,FAA": "BJX",
//       "Airport Name": "Del Bajío International Airport",
//       "Altitude (Ft)": 5956,
//       "Country": "Mexico",
//       "Latitude": -101.481003,
//       "Location": "Del Bajio",
//       "Longitude": 20.9935,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1816,
//       "Airport Code [ICAO]": "MMLP",
//       "Airport Code iATA,FAA": "LAP",
//       "Airport Name": "Manuel Márquez de León International Airport",
//       "Altitude (Ft)": 69,
//       "Country": "Mexico",
//       "Latitude": -110.361999512,
//       "Location": "La Paz",
//       "Longitude": 24.072700500499998,
//       "GMT Offset": "-7",
//       "Daylight Savings": "S",
//       "Region": "America/Mazatlan",
//       "Type": "airport"
//     },
//     {
//       "ID": 1817,
//       "Airport Code [ICAO]": "MMLT",
//       "Airport Code iATA,FAA": "LTO",
//       "Airport Name": "Loreto International Airport",
//       "Altitude (Ft)": 34,
//       "Country": "Mexico",
//       "Latitude": -111.3479995727539,
//       "Location": "Loreto",
//       "Longitude": 25.989200592041016,
//       "GMT Offset": "-7",
//       "Daylight Savings": "S",
//       "Region": "America/Mazatlan",
//       "Type": "airport"
//     },
//     {
//       "ID": 1818,
//       "Airport Code [ICAO]": "MMMA",
//       "Airport Code iATA,FAA": "MAM",
//       "Airport Name": "General Servando Canales International Airport",
//       "Altitude (Ft)": 25,
//       "Country": "Mexico",
//       "Latitude": -97.5252990723,
//       "Location": "Matamoros",
//       "Longitude": 25.7698993683,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1819,
//       "Airport Code [ICAO]": "MMMD",
//       "Airport Code iATA,FAA": "MID",
//       "Airport Name": "Licenciado Manuel Crescencio Rejon Int Airport",
//       "Altitude (Ft)": 38,
//       "Country": "Mexico",
//       "Latitude": -89.657699585,
//       "Location": "Merida",
//       "Longitude": 20.937000274699997,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1820,
//       "Airport Code [ICAO]": "MMML",
//       "Airport Code iATA,FAA": "MXL",
//       "Airport Name": "General Rodolfo Sánchez Taboada International Airport",
//       "Altitude (Ft)": 74,
//       "Country": "Mexico",
//       "Latitude": -115.241997,
//       "Location": "Mexicali",
//       "Longitude": 32.6306,
//       "GMT Offset": "-8",
//       "Daylight Savings": "S",
//       "Region": "America/Tijuana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1821,
//       "Airport Code [ICAO]": "MMMM",
//       "Airport Code iATA,FAA": "MLM",
//       "Airport Name": "General Francisco J. Mujica International Airport",
//       "Altitude (Ft)": 6033,
//       "Country": "Mexico",
//       "Latitude": -101.025001526,
//       "Location": "Morelia",
//       "Longitude": 19.849899292,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1822,
//       "Airport Code [ICAO]": "MMMT",
//       "Airport Code iATA,FAA": "MTT",
//       "Airport Name": "Minatitlán/Coatzacoalcos National Airport",
//       "Altitude (Ft)": 36,
//       "Country": "Mexico",
//       "Latitude": -94.58070373540001,
//       "Location": "Minatitlan",
//       "Longitude": 18.1033992767,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1823,
//       "Airport Code [ICAO]": "MMMV",
//       "Airport Code iATA,FAA": "LOV",
//       "Airport Name": "Monclova International Airport",
//       "Altitude (Ft)": 1864,
//       "Country": "Mexico",
//       "Latitude": -101.470001,
//       "Location": "Monclova",
//       "Longitude": 26.9557,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1824,
//       "Airport Code [ICAO]": "MMMX",
//       "Airport Code iATA,FAA": "MEX",
//       "Airport Name": "Licenciado Benito Juarez International Airport",
//       "Altitude (Ft)": 7316,
//       "Country": "Mexico",
//       "Latitude": -99.072098,
//       "Location": "Mexico City",
//       "Longitude": 19.4363,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1825,
//       "Airport Code [ICAO]": "MMMY",
//       "Airport Code iATA,FAA": "MTY",
//       "Airport Name": "General Mariano Escobedo International Airport",
//       "Altitude (Ft)": 1278,
//       "Country": "Mexico",
//       "Latitude": -100.107002258,
//       "Location": "Monterrey",
//       "Longitude": 25.7784996033,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1826,
//       "Airport Code [ICAO]": "MMMZ",
//       "Airport Code iATA,FAA": "MZT",
//       "Airport Name": "General Rafael Buelna International Airport",
//       "Altitude (Ft)": 38,
//       "Country": "Mexico",
//       "Latitude": -106.26599884,
//       "Location": "Mazatlan",
//       "Longitude": 23.1613998413,
//       "GMT Offset": "-7",
//       "Daylight Savings": "S",
//       "Region": "America/Mazatlan",
//       "Type": "airport"
//     },
//     {
//       "ID": 1827,
//       "Airport Code [ICAO]": "MMNG",
//       "Airport Code iATA,FAA": "NOG",
//       "Airport Name": "Nogales International Airport",
//       "Altitude (Ft)": 3990,
//       "Country": "Mexico",
//       "Latitude": -110.97599792480469,
//       "Location": "Nogales",
//       "Longitude": 31.22610092163086,
//       "GMT Offset": "-7",
//       "Daylight Savings": "S",
//       "Region": "America/Hermosillo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1828,
//       "Airport Code [ICAO]": "MMNL",
//       "Airport Code iATA,FAA": "NLD",
//       "Airport Name": "Quetzalcóatl International Airport",
//       "Altitude (Ft)": 484,
//       "Country": "Mexico",
//       "Latitude": -99.5705032349,
//       "Location": "Nuevo Laredo",
//       "Longitude": 27.4438991547,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1829,
//       "Airport Code [ICAO]": "MMOX",
//       "Airport Code iATA,FAA": "OAX",
//       "Airport Name": "Xoxocotlán International Airport",
//       "Altitude (Ft)": 4989,
//       "Country": "Mexico",
//       "Latitude": -96.726600647,
//       "Location": "Oaxaca",
//       "Longitude": 16.9999008179,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1830,
//       "Airport Code [ICAO]": "MMPA",
//       "Airport Code iATA,FAA": "PAZ",
//       "Airport Name": "El Tajín National Airport",
//       "Altitude (Ft)": 497,
//       "Country": "Mexico",
//       "Latitude": -97.46080017090001,
//       "Location": "Poza Rico",
//       "Longitude": 20.6026992798,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1831,
//       "Airport Code [ICAO]": "MMPB",
//       "Airport Code iATA,FAA": "PBC",
//       "Airport Name": "Hermanos Serdán International Airport",
//       "Altitude (Ft)": 7361,
//       "Country": "Mexico",
//       "Latitude": -98.3713989258,
//       "Location": "Puebla",
//       "Longitude": 19.1581001282,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1832,
//       "Airport Code [ICAO]": "MMPC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ingeniero Juan Guillermo Villasana Airport",
//       "Altitude (Ft)": 7600,
//       "Country": "Mexico",
//       "Latitude": -98.782501,
//       "Location": "Pachuca",
//       "Longitude": 20.0774,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1833,
//       "Airport Code [ICAO]": "MMPE",
//       "Airport Code iATA,FAA": "PPE",
//       "Airport Name": "Mar de Cortés International Airport",
//       "Altitude (Ft)": 71,
//       "Country": "Mexico",
//       "Latitude": -113.305177,
//       "Location": "Punta Penasco",
//       "Longitude": 31.351987,
//       "GMT Offset": "-7",
//       "Daylight Savings": "S",
//       "Region": "America/Hermosillo",
//       "Type": "airport"
//     },
//     {
//       "ID": 1834,
//       "Airport Code [ICAO]": "MMPG",
//       "Airport Code iATA,FAA": "PDS",
//       "Airport Name": "Piedras Negras International Airport",
//       "Altitude (Ft)": 901,
//       "Country": "Mexico",
//       "Latitude": -100.535004,
//       "Location": "Piedras Negras",
//       "Longitude": 28.627399,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1835,
//       "Airport Code [ICAO]": "MMPN",
//       "Airport Code iATA,FAA": "UPN",
//       "Airport Name": "Licenciado y General Ignacio Lopez Rayon Airport",
//       "Altitude (Ft)": 5258,
//       "Country": "Mexico",
//       "Latitude": -102.03900146484375,
//       "Location": "Uruapan",
//       "Longitude": 19.396699905395508,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1836,
//       "Airport Code [ICAO]": "MMPR",
//       "Airport Code iATA,FAA": "PVR",
//       "Airport Name": "Licenciado Gustavo Díaz Ordaz International Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Mexico",
//       "Latitude": -105.25399780273438,
//       "Location": "Puerto Vallarta",
//       "Longitude": 20.680099487304688,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1837,
//       "Airport Code [ICAO]": "MMPS",
//       "Airport Code iATA,FAA": "PXM",
//       "Airport Name": "Puerto Escondido International Airport",
//       "Altitude (Ft)": 294,
//       "Country": "Mexico",
//       "Latitude": -97.089103,
//       "Location": "Puerto Escondido",
//       "Longitude": 15.8769,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1838,
//       "Airport Code [ICAO]": "MMQT",
//       "Airport Code iATA,FAA": "QRO",
//       "Airport Name": "Querétaro Intercontinental Airport",
//       "Altitude (Ft)": 6296,
//       "Country": "Mexico",
//       "Latitude": -100.185997,
//       "Location": "Queretaro",
//       "Longitude": 20.6173,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1839,
//       "Airport Code [ICAO]": "MMRX",
//       "Airport Code iATA,FAA": "REX",
//       "Airport Name": "General Lucio Blanco International Airport",
//       "Altitude (Ft)": 139,
//       "Country": "Mexico",
//       "Latitude": -98.2285,
//       "Location": "Reynosa",
//       "Longitude": 26.0089,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1840,
//       "Airport Code [ICAO]": "MMSD",
//       "Airport Code iATA,FAA": "SJD",
//       "Airport Name": "Los Cabos International Airport",
//       "Altitude (Ft)": 374,
//       "Country": "Mexico",
//       "Latitude": -109.72100067138672,
//       "Location": "San Jose Del Cabo",
//       "Longitude": 23.15180015563965,
//       "GMT Offset": "-7",
//       "Daylight Savings": "S",
//       "Region": "America/Mazatlan",
//       "Type": "airport"
//     },
//     {
//       "ID": 1841,
//       "Airport Code [ICAO]": "MMSF",
//       "Airport Code iATA,FAA": "SFH",
//       "Airport Name": "San Felipe International Airport",
//       "Altitude (Ft)": 148,
//       "Country": "Mexico",
//       "Latitude": -114.80899810791,
//       "Location": "San Filipe",
//       "Longitude": 30.930200576782,
//       "GMT Offset": "-8",
//       "Daylight Savings": "S",
//       "Region": "America/Tijuana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1842,
//       "Airport Code [ICAO]": "MMSP",
//       "Airport Code iATA,FAA": "SLP",
//       "Airport Name": "Ponciano Arriaga International Airport",
//       "Altitude (Ft)": 6035,
//       "Country": "Mexico",
//       "Latitude": -100.930999756,
//       "Location": "San Luis Potosi",
//       "Longitude": 22.254299163800003,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1843,
//       "Airport Code [ICAO]": "MMTA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tlaxcala Airport",
//       "Altitude (Ft)": 8229,
//       "Country": "Mexico",
//       "Latitude": -98.173492,
//       "Location": "Tlaxcala",
//       "Longitude": 19.537992,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1844,
//       "Airport Code [ICAO]": "MMTB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Terán Air Base",
//       "Altitude (Ft)": 1909,
//       "Country": "Mexico",
//       "Latitude": -93.173301,
//       "Location": "Tuxtla Gutierrez",
//       "Longitude": 16.739901,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1845,
//       "Airport Code [ICAO]": "MMTC",
//       "Airport Code iATA,FAA": "TRC",
//       "Airport Name": "Francisco Sarabia International Airport",
//       "Altitude (Ft)": 3688,
//       "Country": "Mexico",
//       "Latitude": -103.411003113,
//       "Location": "Torreon",
//       "Longitude": 25.568300247199996,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1846,
//       "Airport Code [ICAO]": "MMTG",
//       "Airport Code iATA,FAA": "TGZ",
//       "Airport Name": "Angel Albino Corzo International Airport",
//       "Altitude (Ft)": 1499,
//       "Country": "Mexico",
//       "Latitude": -93.02249908450001,
//       "Location": "Tuxtla Gutierrez",
//       "Longitude": 16.5636005402,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1847,
//       "Airport Code [ICAO]": "MMTJ",
//       "Airport Code iATA,FAA": "TIJ",
//       "Airport Name": "General Abelardo L. Rodríguez International Airport",
//       "Altitude (Ft)": 489,
//       "Country": "Mexico",
//       "Latitude": -116.97000122070312,
//       "Location": "Tijuana",
//       "Longitude": 32.541099548339844,
//       "GMT Offset": "-8",
//       "Daylight Savings": "S",
//       "Region": "America/Tijuana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1848,
//       "Airport Code [ICAO]": "MMTM",
//       "Airport Code iATA,FAA": "TAM",
//       "Airport Name": "General Francisco Javier Mina International Airport",
//       "Altitude (Ft)": 80,
//       "Country": "Mexico",
//       "Latitude": -97.8658981323,
//       "Location": "Tampico",
//       "Longitude": 22.2964000702,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1849,
//       "Airport Code [ICAO]": "MMTN",
//       "Airport Code iATA,FAA": "TSL",
//       "Airport Name": "Tamuin Airport",
//       "Altitude (Ft)": 164,
//       "Country": "Mexico",
//       "Latitude": -98.806502,
//       "Location": "Tamuin",
//       "Longitude": 22.0383,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1850,
//       "Airport Code [ICAO]": "MMTO",
//       "Airport Code iATA,FAA": "TLC",
//       "Airport Name": "Licenciado Adolfo Lopez Mateos International Airport",
//       "Altitude (Ft)": 8466,
//       "Country": "Mexico",
//       "Latitude": -99.56600189210002,
//       "Location": "Toluca",
//       "Longitude": 19.3370990753,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1851,
//       "Airport Code [ICAO]": "MMTP",
//       "Airport Code iATA,FAA": "TAP",
//       "Airport Name": "Tapachula International Airport",
//       "Altitude (Ft)": 97,
//       "Country": "Mexico",
//       "Latitude": -92.3700027466,
//       "Location": "Tapachula",
//       "Longitude": 14.7943000793,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1852,
//       "Airport Code [ICAO]": "MMUN",
//       "Airport Code iATA,FAA": "CUN",
//       "Airport Name": "Cancún International Airport",
//       "Altitude (Ft)": 22,
//       "Country": "Mexico",
//       "Latitude": -86.8770980835,
//       "Location": "Cancun",
//       "Longitude": 21.036500930800003,
//       "GMT Offset": "-5",
//       "Daylight Savings": "S",
//       "Region": "America/Cancun",
//       "Type": "airport"
//     },
//     {
//       "ID": 1853,
//       "Airport Code [ICAO]": "MMVA",
//       "Airport Code iATA,FAA": "VSA",
//       "Airport Name": "Carlos Rovirosa Pérez International Airport",
//       "Altitude (Ft)": 46,
//       "Country": "Mexico",
//       "Latitude": -92.81739807128906,
//       "Location": "Villahermosa",
//       "Longitude": 17.996999740600586,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1854,
//       "Airport Code [ICAO]": "MMVR",
//       "Airport Code iATA,FAA": "VER",
//       "Airport Name": "General Heriberto Jara International Airport",
//       "Altitude (Ft)": 90,
//       "Country": "Mexico",
//       "Latitude": -96.1873016357,
//       "Location": "Vera Cruz",
//       "Longitude": 19.1459007263,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1855,
//       "Airport Code [ICAO]": "MMZC",
//       "Airport Code iATA,FAA": "ZCL",
//       "Airport Name": "General Leobardo C. Ruiz International Airport",
//       "Altitude (Ft)": 7141,
//       "Country": "Mexico",
//       "Latitude": -102.68699646,
//       "Location": "Zacatecas",
//       "Longitude": 22.8971004486,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1856,
//       "Airport Code [ICAO]": "MMZH",
//       "Airport Code iATA,FAA": "ZIH",
//       "Airport Name": "Ixtapa Zihuatanejo International Airport",
//       "Altitude (Ft)": 26,
//       "Country": "Mexico",
//       "Latitude": -101.460998535,
//       "Location": "Zihuatanejo",
//       "Longitude": 17.601600647,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1857,
//       "Airport Code [ICAO]": "MMZM",
//       "Airport Code iATA,FAA": "ZMM",
//       "Airport Name": "Zamora Airport",
//       "Altitude (Ft)": 5141,
//       "Country": "Mexico",
//       "Latitude": -102.276001,
//       "Location": "Zamora",
//       "Longitude": 20.045,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1858,
//       "Airport Code [ICAO]": "MMZO",
//       "Airport Code iATA,FAA": "ZLO",
//       "Airport Name": "Playa De Oro International Airport",
//       "Altitude (Ft)": 30,
//       "Country": "Mexico",
//       "Latitude": -104.558998108,
//       "Location": "Manzanillo",
//       "Longitude": 19.144800186199998,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1859,
//       "Airport Code [ICAO]": "MMZP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Zapopan Airport",
//       "Altitude (Ft)": 5333,
//       "Country": "Mexico",
//       "Latitude": -103.461709,
//       "Location": "Zapopan",
//       "Longitude": 20.747922,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 1860,
//       "Airport Code [ICAO]": "MNBL",
//       "Airport Code iATA,FAA": "BEF",
//       "Airport Name": "Bluefields Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Nicaragua",
//       "Latitude": -83.77410125732422,
//       "Location": "Bluefields",
//       "Longitude": 11.991000175476074,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Managua",
//       "Type": "airport"
//     },
//     {
//       "ID": 1861,
//       "Airport Code [ICAO]": "MNBR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Los Brasiles Airport",
//       "Altitude (Ft)": 262,
//       "Country": "Nicaragua",
//       "Latitude": -86.35389709472656,
//       "Location": "Los Brasiles",
//       "Longitude": 12.1899995803833,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Managua",
//       "Type": "airport"
//     },
//     {
//       "ID": 1862,
//       "Airport Code [ICAO]": "MNLN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Leon (Fanor Urroz) Airport",
//       "Altitude (Ft)": 328,
//       "Country": "Nicaragua",
//       "Latitude": -86.90280151367188,
//       "Location": "Leon",
//       "Longitude": 12.429200172424316,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Managua",
//       "Type": "airport"
//     },
//     {
//       "ID": 1863,
//       "Airport Code [ICAO]": "MNMG",
//       "Airport Code iATA,FAA": "MGA",
//       "Airport Name": "Augusto C. Sandino (Managua) International Airport",
//       "Altitude (Ft)": 194,
//       "Country": "Nicaragua",
//       "Latitude": -86.16819763183594,
//       "Location": "Managua",
//       "Longitude": 12.141500473022461,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Managua",
//       "Type": "airport"
//     },
//     {
//       "ID": 1864,
//       "Airport Code [ICAO]": "MNPC",
//       "Airport Code iATA,FAA": "PUZ",
//       "Airport Name": "Puerto Cabezas Airport",
//       "Altitude (Ft)": 52,
//       "Country": "Nicaragua",
//       "Latitude": -83.38670349121094,
//       "Location": "Puerto Cabezas",
//       "Longitude": 14.047200202941895,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Managua",
//       "Type": "airport"
//     },
//     {
//       "ID": 1865,
//       "Airport Code [ICAO]": "MPBO",
//       "Airport Code iATA,FAA": "BOC",
//       "Airport Name": "Bocas Del Toro International Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Panama",
//       "Latitude": -82.25080108642578,
//       "Location": "Bocas Del Toro",
//       "Longitude": 9.340849876403809,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Panama",
//       "Type": "airport"
//     },
//     {
//       "ID": 1866,
//       "Airport Code [ICAO]": "MPCH",
//       "Airport Code iATA,FAA": "CHX",
//       "Airport Name": "Cap Manuel Niño International Airport",
//       "Altitude (Ft)": 19,
//       "Country": "Panama",
//       "Latitude": -82.515062,
//       "Location": "Changuinola",
//       "Longitude": 9.458962,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Panama",
//       "Type": "airport"
//     },
//     {
//       "ID": 1867,
//       "Airport Code [ICAO]": "MPDA",
//       "Airport Code iATA,FAA": "DAV",
//       "Airport Name": "Enrique Malek International Airport",
//       "Altitude (Ft)": 89,
//       "Country": "Panama",
//       "Latitude": -82.43499755859375,
//       "Location": "David",
//       "Longitude": 8.390999794006348,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Panama",
//       "Type": "airport"
//     },
//     {
//       "ID": 1868,
//       "Airport Code [ICAO]": "MPHO",
//       "Airport Code iATA,FAA": "BLB",
//       "Airport Name": "Panama Pacific International Airport",
//       "Altitude (Ft)": 52,
//       "Country": "Panama",
//       "Latitude": -79.599602,
//       "Location": "Howard",
//       "Longitude": 8.91479,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Panama",
//       "Type": "airport"
//     },
//     {
//       "ID": 1869,
//       "Airport Code [ICAO]": "MPMG",
//       "Airport Code iATA,FAA": "PAC",
//       "Airport Name": "Marcos A. Gelabert International Airport",
//       "Altitude (Ft)": 31,
//       "Country": "Panama",
//       "Latitude": -79.55560302734375,
//       "Location": "Panama",
//       "Longitude": 8.973340034484863,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Panama",
//       "Type": "airport"
//     },
//     {
//       "ID": 1870,
//       "Airport Code [ICAO]": "MPSA",
//       "Airport Code iATA,FAA": "SYP",
//       "Airport Name": "Ruben Cantu Airport",
//       "Altitude (Ft)": 272,
//       "Country": "Panama",
//       "Latitude": -80.94529724121094,
//       "Location": "Santiago",
//       "Longitude": 8.085599899291992,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Panama",
//       "Type": "airport"
//     },
//     {
//       "ID": 1871,
//       "Airport Code [ICAO]": "MPTO",
//       "Airport Code iATA,FAA": "PTY",
//       "Airport Name": "Tocumen International Airport",
//       "Altitude (Ft)": 135,
//       "Country": "Panama",
//       "Latitude": -79.3834991455,
//       "Location": "Panama City",
//       "Longitude": 9.0713596344,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Panama",
//       "Type": "airport"
//     },
//     {
//       "ID": 1872,
//       "Airport Code [ICAO]": "MRBA",
//       "Airport Code iATA,FAA": "BAI",
//       "Airport Name": "Buenos Aires Airport",
//       "Altitude (Ft)": 1214,
//       "Country": "Costa Rica",
//       "Latitude": -83.330171,
//       "Location": "Buenos Aires",
//       "Longitude": 9.163949,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 1874,
//       "Airport Code [ICAO]": "MRCC",
//       "Airport Code iATA,FAA": "OTR",
//       "Airport Name": "Coto 47 Airport",
//       "Altitude (Ft)": 26,
//       "Country": "Costa Rica",
//       "Latitude": -82.96859741210938,
//       "Location": "Coto 47",
//       "Longitude": 8.60155963897705,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 1875,
//       "Airport Code [ICAO]": "MRCH",
//       "Airport Code iATA,FAA": "JAP",
//       "Airport Name": "Chacarita Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Costa Rica",
//       "Latitude": -84.7726974487,
//       "Location": "Chacarita",
//       "Longitude": 9.98141002655,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 1877,
//       "Airport Code [ICAO]": "MREC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "El Carmen de Siquirres Airport",
//       "Altitude (Ft)": 56,
//       "Country": "Costa Rica",
//       "Latitude": -83.47219848632812,
//       "Location": "El Carmen",
//       "Longitude": 10.20199966430664,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 1878,
//       "Airport Code [ICAO]": "MRFI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Finca 10 / Nuevo Palmar Sur Airport",
//       "Altitude (Ft)": 25,
//       "Country": "Costa Rica",
//       "Latitude": -83.5073013305664,
//       "Location": "Finca 10",
//       "Longitude": 8.916350364685059,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 1879,
//       "Airport Code [ICAO]": "MRGF",
//       "Airport Code iATA,FAA": "GLF",
//       "Airport Name": "Golfito Airport",
//       "Altitude (Ft)": 49,
//       "Country": "Costa Rica",
//       "Latitude": -83.18219757080078,
//       "Location": "Golfito",
//       "Longitude": 8.654009819030762,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 1880,
//       "Airport Code [ICAO]": "MRGP",
//       "Airport Code iATA,FAA": "GPL",
//       "Airport Name": "Guapiles Airport",
//       "Altitude (Ft)": 883,
//       "Country": "Costa Rica",
//       "Latitude": -83.79699707030001,
//       "Location": "Guapiles",
//       "Longitude": 10.2172002792,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 1881,
//       "Airport Code [ICAO]": "MRLB",
//       "Airport Code iATA,FAA": "LIR",
//       "Airport Name": "Daniel Oduber Quiros International Airport",
//       "Altitude (Ft)": 270,
//       "Country": "Costa Rica",
//       "Latitude": -85.544403,
//       "Location": "Liberia",
//       "Longitude": 10.5933,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 1882,
//       "Airport Code [ICAO]": "MRLC",
//       "Airport Code iATA,FAA": "LSL",
//       "Airport Name": "Los Chiles Airport",
//       "Altitude (Ft)": 131,
//       "Country": "Costa Rica",
//       "Latitude": -84.70610046386719,
//       "Location": "Los Chiles",
//       "Longitude": 11.035300254821777,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 1883,
//       "Airport Code [ICAO]": "MRLM",
//       "Airport Code iATA,FAA": "LIO",
//       "Airport Name": "Limon International Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Costa Rica",
//       "Latitude": -83.02200317382812,
//       "Location": "Limon",
//       "Longitude": 9.95796012878418,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 1884,
//       "Airport Code [ICAO]": "MRNS",
//       "Airport Code iATA,FAA": "NOB",
//       "Airport Name": "Nosara Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Costa Rica",
//       "Latitude": -85.65299987790002,
//       "Location": "Nosara Beach",
//       "Longitude": 9.976490020750001,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 1885,
//       "Airport Code [ICAO]": "MROC",
//       "Airport Code iATA,FAA": "SJO",
//       "Airport Name": "Juan Santamaria International Airport",
//       "Altitude (Ft)": 3021,
//       "Country": "Costa Rica",
//       "Latitude": -84.20880126953125,
//       "Location": "San Jose",
//       "Longitude": 9.993860244750977,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 1886,
//       "Airport Code [ICAO]": "MRPD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Pandora Airport",
//       "Altitude (Ft)": 98,
//       "Country": "Costa Rica",
//       "Latitude": -82.98320007324219,
//       "Location": "Pandora",
//       "Longitude": 9.732170104980469,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 1887,
//       "Airport Code [ICAO]": "MRPM",
//       "Airport Code iATA,FAA": "PMZ",
//       "Airport Name": "Palmar Sur Airport",
//       "Altitude (Ft)": 49,
//       "Country": "Costa Rica",
//       "Latitude": -83.46859741210938,
//       "Location": "Palmar Sur",
//       "Longitude": 8.951029777526855,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 1889,
//       "Airport Code [ICAO]": "MRQP",
//       "Airport Code iATA,FAA": "XQP",
//       "Airport Name": "Quepos Managua Airport",
//       "Altitude (Ft)": 85,
//       "Country": "Costa Rica",
//       "Latitude": -84.12979888916016,
//       "Location": "Quepos",
//       "Longitude": 9.443160057067871,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 1890,
//       "Airport Code [ICAO]": "MRSG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Santa Clara De Guapiles Airport",
//       "Altitude (Ft)": 262,
//       "Country": "Costa Rica",
//       "Latitude": -83.7135009765625,
//       "Location": "Santa Clara",
//       "Longitude": 10.288299560546875,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 1891,
//       "Airport Code [ICAO]": "MRSV",
//       "Airport Code iATA,FAA": "TOO",
//       "Airport Name": "San Vito De Java Airport",
//       "Altitude (Ft)": 3228,
//       "Country": "Costa Rica",
//       "Latitude": -82.95890045166016,
//       "Location": "San Vito De Jaba",
//       "Longitude": 8.826109886169434,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 1892,
//       "Airport Code [ICAO]": "MSLP",
//       "Airport Code iATA,FAA": "SAL",
//       "Airport Name": "Monseñor Óscar Arnulfo Romero International Airport",
//       "Altitude (Ft)": 101,
//       "Country": "El Salvador",
//       "Latitude": -89.055702,
//       "Location": "San Salvador",
//       "Longitude": 13.4409,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/El_Salvador",
//       "Type": "airport"
//     },
//     {
//       "ID": 1893,
//       "Airport Code [ICAO]": "MSSS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ilopango International Airport",
//       "Altitude (Ft)": 2021,
//       "Country": "El Salvador",
//       "Latitude": -89.11990356445312,
//       "Location": "San Salvador",
//       "Longitude": 13.69950008392334,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/El_Salvador",
//       "Type": "airport"
//     },
//     {
//       "ID": 1894,
//       "Airport Code [ICAO]": "MTCA",
//       "Airport Code iATA,FAA": "CYA",
//       "Airport Name": "Les Cayes Airport",
//       "Altitude (Ft)": 203,
//       "Country": "Haiti",
//       "Latitude": -73.78829956054688,
//       "Location": "Cayes",
//       "Longitude": 18.271099090576172,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Port-au-Prince",
//       "Type": "airport"
//     },
//     {
//       "ID": 1895,
//       "Airport Code [ICAO]": "MTCH",
//       "Airport Code iATA,FAA": "CAP",
//       "Airport Name": "Cap Haitien International Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Haiti",
//       "Latitude": -72.194702,
//       "Location": "Cap Haitien",
//       "Longitude": 19.733,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Port-au-Prince",
//       "Type": "airport"
//     },
//     {
//       "ID": 1896,
//       "Airport Code [ICAO]": "MTJA",
//       "Airport Code iATA,FAA": "JAK",
//       "Airport Name": "Jacmel Airport",
//       "Altitude (Ft)": 167,
//       "Country": "Haiti",
//       "Latitude": -72.51850128173828,
//       "Location": "Jacmel",
//       "Longitude": 18.241100311279297,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Port-au-Prince",
//       "Type": "airport"
//     },
//     {
//       "ID": 1897,
//       "Airport Code [ICAO]": "MTPP",
//       "Airport Code iATA,FAA": "PAP",
//       "Airport Name": "Toussaint Louverture International Airport",
//       "Altitude (Ft)": 122,
//       "Country": "Haiti",
//       "Latitude": -72.2925033569336,
//       "Location": "Port-au-prince",
//       "Longitude": 18.579999923706055,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Port-au-Prince",
//       "Type": "airport"
//     },
//     {
//       "ID": 1898,
//       "Airport Code [ICAO]": "MUBA",
//       "Airport Code iATA,FAA": "BCA",
//       "Airport Name": "Gustavo Rizo Airport",
//       "Altitude (Ft)": 26,
//       "Country": "Cuba",
//       "Latitude": -74.5062026977539,
//       "Location": "Baracoa Playa",
//       "Longitude": 20.365299224853516,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1899,
//       "Airport Code [ICAO]": "MUBY",
//       "Airport Code iATA,FAA": "BYM",
//       "Airport Name": "Carlos Manuel de Cespedes Airport",
//       "Altitude (Ft)": 203,
//       "Country": "Cuba",
//       "Latitude": -76.62139892578125,
//       "Location": "Bayamo",
//       "Longitude": 20.396400451660156,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1900,
//       "Airport Code [ICAO]": "MUCA",
//       "Airport Code iATA,FAA": "AVI",
//       "Airport Name": "Maximo Gomez Airport",
//       "Altitude (Ft)": 335,
//       "Country": "Cuba",
//       "Latitude": -78.78959655761719,
//       "Location": "Ciego De Avila",
//       "Longitude": 22.027099609375,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1901,
//       "Airport Code [ICAO]": "MUCC",
//       "Airport Code iATA,FAA": "CCC",
//       "Airport Name": "Jardines Del Rey Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Cuba",
//       "Latitude": -78.32839965820001,
//       "Location": "Cunagua",
//       "Longitude": 22.461000442499998,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1902,
//       "Airport Code [ICAO]": "MUCF",
//       "Airport Code iATA,FAA": "CFG",
//       "Airport Name": "Jaime Gonzalez Airport",
//       "Altitude (Ft)": 102,
//       "Country": "Cuba",
//       "Latitude": -80.41419982910156,
//       "Location": "Cienfuegos",
//       "Longitude": 22.149999618530273,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1903,
//       "Airport Code [ICAO]": "MUCL",
//       "Airport Code iATA,FAA": "CYO",
//       "Airport Name": "Vilo Acuña International Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Cuba",
//       "Latitude": -81.5459976196,
//       "Location": "Cayo Largo del Sur",
//       "Longitude": 21.6165008545,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1904,
//       "Airport Code [ICAO]": "MUCM",
//       "Airport Code iATA,FAA": "CMW",
//       "Airport Name": "Ignacio Agramonte International Airport",
//       "Altitude (Ft)": 413,
//       "Country": "Cuba",
//       "Latitude": -77.84750366210938,
//       "Location": "Camaguey",
//       "Longitude": 21.420299530029297,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1905,
//       "Airport Code [ICAO]": "MUCU",
//       "Airport Code iATA,FAA": "SCU",
//       "Airport Name": "Antonio Maceo International Airport",
//       "Altitude (Ft)": 249,
//       "Country": "Cuba",
//       "Latitude": -75.83540344238281,
//       "Location": "Santiago De Cuba",
//       "Longitude": 19.96980094909668,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1906,
//       "Airport Code [ICAO]": "MUFL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Florida Airport",
//       "Altitude (Ft)": 197,
//       "Country": "Cuba",
//       "Latitude": -78.20279693603516,
//       "Location": "Florida",
//       "Longitude": 21.49970054626465,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1907,
//       "Airport Code [ICAO]": "MUGM",
//       "Airport Code iATA,FAA": "NBW",
//       "Airport Name": "Leeward Point Field",
//       "Altitude (Ft)": 56,
//       "Country": "Cuba",
//       "Latitude": -75.207099914551,
//       "Location": "Guantanamo",
//       "Longitude": 19.906499862671,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 1908,
//       "Airport Code [ICAO]": "MUGT",
//       "Airport Code iATA,FAA": "GAO",
//       "Airport Name": "Mariana Grajales Airport",
//       "Altitude (Ft)": 56,
//       "Country": "Cuba",
//       "Latitude": -75.1583023071289,
//       "Location": "Guantanamo",
//       "Longitude": 20.08530044555664,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1909,
//       "Airport Code [ICAO]": "MUHA",
//       "Airport Code iATA,FAA": "HAV",
//       "Airport Name": "José Martí International Airport",
//       "Altitude (Ft)": 210,
//       "Country": "Cuba",
//       "Latitude": -82.40910339355469,
//       "Location": "Havana",
//       "Longitude": 22.989200592041016,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1910,
//       "Airport Code [ICAO]": "MUHG",
//       "Airport Code iATA,FAA": "HOG",
//       "Airport Name": "Frank Pais International Airport",
//       "Altitude (Ft)": 361,
//       "Country": "Cuba",
//       "Latitude": -76.31510162353516,
//       "Location": "Holguin",
//       "Longitude": 20.785600662231445,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1911,
//       "Airport Code [ICAO]": "MULM",
//       "Airport Code iATA,FAA": "LCL",
//       "Airport Name": "La Coloma Airport",
//       "Altitude (Ft)": 131,
//       "Country": "Cuba",
//       "Latitude": -83.64189910888672,
//       "Location": "La Coloma",
//       "Longitude": 22.33609962463379,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1912,
//       "Airport Code [ICAO]": "MUMO",
//       "Airport Code iATA,FAA": "MOA",
//       "Airport Name": "Orestes Acosta Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Cuba",
//       "Latitude": -74.92220306396484,
//       "Location": "Moa",
//       "Longitude": 20.653900146484375,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1913,
//       "Airport Code [ICAO]": "MUMZ",
//       "Airport Code iATA,FAA": "MZO",
//       "Airport Name": "Sierra Maestra Airport",
//       "Altitude (Ft)": 112,
//       "Country": "Cuba",
//       "Latitude": -77.08920288085938,
//       "Location": "Manzanillo",
//       "Longitude": 20.28809928894043,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1914,
//       "Airport Code [ICAO]": "MUNG",
//       "Airport Code iATA,FAA": "GER",
//       "Airport Name": "Rafael Cabrera Airport",
//       "Altitude (Ft)": 79,
//       "Country": "Cuba",
//       "Latitude": -82.78379821777344,
//       "Location": "Nueva Gerona",
//       "Longitude": 21.834699630737305,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1915,
//       "Airport Code [ICAO]": "MUPB",
//       "Airport Code iATA,FAA": "UPB",
//       "Airport Name": "Playa Baracoa Airport",
//       "Altitude (Ft)": 102,
//       "Country": "Cuba",
//       "Latitude": -82.5793991089,
//       "Location": "Baracoa Playa",
//       "Longitude": 23.032800674399997,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1916,
//       "Airport Code [ICAO]": "MUPR",
//       "Airport Code iATA,FAA": "QPD",
//       "Airport Name": "Pinar Del Rio Airport",
//       "Altitude (Ft)": 131,
//       "Country": "Cuba",
//       "Latitude": -83.67839813232422,
//       "Location": "Pinar Del Rio Norte",
//       "Longitude": 22.42140007019043,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1917,
//       "Airport Code [ICAO]": "MUSA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "San Antonio De Los Banos Airport",
//       "Altitude (Ft)": 164,
//       "Country": "Cuba",
//       "Latitude": -82.5093002319336,
//       "Location": "San Antonio De Banos",
//       "Longitude": 22.87150001525879,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1918,
//       "Airport Code [ICAO]": "MUSC",
//       "Airport Code iATA,FAA": "SNU",
//       "Airport Name": "Abel Santamaria Airport",
//       "Altitude (Ft)": 338,
//       "Country": "Cuba",
//       "Latitude": -79.943603515625,
//       "Location": "Santa Clara",
//       "Longitude": 22.49220085144043,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1919,
//       "Airport Code [ICAO]": "MUSL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Joaquín de Agüero Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Cuba",
//       "Latitude": -77.02059745790001,
//       "Location": "Santa Lucia",
//       "Longitude": 21.509455906699998,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1920,
//       "Airport Code [ICAO]": "MUSN",
//       "Airport Code iATA,FAA": "SZJ",
//       "Airport Name": "Siguanea Airport",
//       "Altitude (Ft)": 39,
//       "Country": "Cuba",
//       "Latitude": -82.9551010131836,
//       "Location": "Siguanea",
//       "Longitude": 21.642499923706055,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1921,
//       "Airport Code [ICAO]": "MUSS",
//       "Airport Code iATA,FAA": "USS",
//       "Airport Name": "Sancti Spiritus Airport",
//       "Altitude (Ft)": 295,
//       "Country": "Cuba",
//       "Latitude": -79.442703,
//       "Location": "Sancti Spiritus",
//       "Longitude": 21.9704,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1923,
//       "Airport Code [ICAO]": "MUVR",
//       "Airport Code iATA,FAA": "VRA",
//       "Airport Name": "Juan Gualberto Gomez International Airport",
//       "Altitude (Ft)": 210,
//       "Country": "Cuba",
//       "Latitude": -81.435302734375,
//       "Location": "Varadero",
//       "Longitude": 23.034400939941406,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1924,
//       "Airport Code [ICAO]": "MUVT",
//       "Airport Code iATA,FAA": "VTU",
//       "Airport Name": "Hermanos Ameijeiras Airport",
//       "Altitude (Ft)": 328,
//       "Country": "Cuba",
//       "Latitude": -76.93579864501953,
//       "Location": "Las Tunas",
//       "Longitude": 20.987600326538086,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 1925,
//       "Airport Code [ICAO]": "MWCB",
//       "Airport Code iATA,FAA": "CYB",
//       "Airport Name": "Gerrard Smith International Airport",
//       "Altitude (Ft)": 8,
//       "Country": "Cayman Islands",
//       "Latitude": -79.88279724121094,
//       "Location": "Cayman Brac",
//       "Longitude": 19.687000274658203,
//       "GMT Offset": "-5",
//       "Daylight Savings": "N",
//       "Region": "America/Cayman",
//       "Type": "airport"
//     },
//     {
//       "ID": 1926,
//       "Airport Code [ICAO]": "MWCR",
//       "Airport Code iATA,FAA": "GCM",
//       "Airport Name": "Owen Roberts International Airport",
//       "Altitude (Ft)": 8,
//       "Country": "Cayman Islands",
//       "Latitude": -81.3576965332,
//       "Location": "Georgetown",
//       "Longitude": 19.292800903299998,
//       "GMT Offset": "-5",
//       "Daylight Savings": "N",
//       "Region": "America/Cayman",
//       "Type": "airport"
//     },
//     {
//       "ID": 1927,
//       "Airport Code [ICAO]": "MYAB",
//       "Airport Code iATA,FAA": "MAY",
//       "Airport Name": "Clarence A. Bain Airport",
//       "Altitude (Ft)": 19,
//       "Country": "Bahamas",
//       "Latitude": -77.68460083007812,
//       "Location": "Clarence Bain",
//       "Longitude": 24.287700653076172,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1928,
//       "Airport Code [ICAO]": "MYAF",
//       "Airport Code iATA,FAA": "ASD",
//       "Airport Name": "Andros Town Airport",
//       "Altitude (Ft)": 5,
//       "Country": "Bahamas",
//       "Latitude": -77.79560089111328,
//       "Location": "Andros Town",
//       "Longitude": 24.697900772094727,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1930,
//       "Airport Code [ICAO]": "MYAM",
//       "Airport Code iATA,FAA": "MHH",
//       "Airport Name": "Leonard M Thompson International Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Bahamas",
//       "Latitude": -77.083503,
//       "Location": "Marsh Harbor",
//       "Longitude": 26.5114,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1931,
//       "Airport Code [ICAO]": "MYAN",
//       "Airport Code iATA,FAA": "SAQ",
//       "Airport Name": "San Andros Airport",
//       "Altitude (Ft)": 5,
//       "Country": "Bahamas",
//       "Latitude": -78.04900360107422,
//       "Location": "San Andros",
//       "Longitude": 25.053800582885742,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1932,
//       "Airport Code [ICAO]": "MYAP",
//       "Airport Code iATA,FAA": "AXP",
//       "Airport Name": "Spring Point Airport",
//       "Altitude (Ft)": 11,
//       "Country": "Bahamas",
//       "Latitude": -73.97090148930002,
//       "Location": "Spring Point",
//       "Longitude": 22.441799163800003,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1933,
//       "Airport Code [ICAO]": "MYAS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sandy Point Airport",
//       "Altitude (Ft)": 8,
//       "Country": "Bahamas",
//       "Latitude": -77.39550018310547,
//       "Location": "Sandy Point",
//       "Longitude": 26.004600524902344,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1934,
//       "Airport Code [ICAO]": "MYAT",
//       "Airport Code iATA,FAA": "TCB",
//       "Airport Name": "Treasure Cay Airport",
//       "Altitude (Ft)": 8,
//       "Country": "Bahamas",
//       "Latitude": -77.3912963867,
//       "Location": "Treasure Cay",
//       "Longitude": 26.745300293,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1935,
//       "Airport Code [ICAO]": "MYBC",
//       "Airport Code iATA,FAA": "CCZ",
//       "Airport Name": "Chub Cay Airport",
//       "Altitude (Ft)": 5,
//       "Country": "Bahamas",
//       "Latitude": -77.88089752197266,
//       "Location": "Chub Cay",
//       "Longitude": 25.41710090637207,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1936,
//       "Airport Code [ICAO]": "MYBG",
//       "Airport Code iATA,FAA": "GHC",
//       "Airport Name": "Great Harbour Cay Airport",
//       "Altitude (Ft)": 18,
//       "Country": "Bahamas",
//       "Latitude": -77.840103,
//       "Location": "Bullocks Harbour",
//       "Longitude": 25.7383,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1937,
//       "Airport Code [ICAO]": "MYBS",
//       "Airport Code iATA,FAA": "BIM",
//       "Airport Name": "South Bimini Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Bahamas",
//       "Latitude": -79.2647018433,
//       "Location": "Alice Town",
//       "Longitude": 25.6998996735,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1941,
//       "Airport Code [ICAO]": "MYEF",
//       "Airport Code iATA,FAA": "GGT",
//       "Airport Name": "Exuma International Airport",
//       "Altitude (Ft)": 9,
//       "Country": "Bahamas",
//       "Latitude": -75.8779983521,
//       "Location": "Great Exuma",
//       "Longitude": 23.5625991821,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1942,
//       "Airport Code [ICAO]": "MYEG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "George Town Airport",
//       "Altitude (Ft)": 5,
//       "Country": "Bahamas",
//       "Latitude": -75.78170013430001,
//       "Location": "George Town",
//       "Longitude": 23.4666996002,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1943,
//       "Airport Code [ICAO]": "MYEH",
//       "Airport Code iATA,FAA": "ELH",
//       "Airport Name": "North Eleuthera Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Bahamas",
//       "Latitude": -76.6835021973,
//       "Location": "North Eleuthera",
//       "Longitude": 25.474899292,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1944,
//       "Airport Code [ICAO]": "MYEM",
//       "Airport Code iATA,FAA": "GHB",
//       "Airport Name": "Governor's Harbour Airport",
//       "Altitude (Ft)": 26,
//       "Country": "Bahamas",
//       "Latitude": -76.3310012817,
//       "Location": "Governor's Harbor",
//       "Longitude": 25.2847003937,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1945,
//       "Airport Code [ICAO]": "MYEN",
//       "Airport Code iATA,FAA": "NMC",
//       "Airport Name": "Normans Cay Airport",
//       "Altitude (Ft)": 8,
//       "Country": "Bahamas",
//       "Latitude": -76.82019805908203,
//       "Location": "Norman's Cay",
//       "Longitude": 24.59429931640625,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1946,
//       "Airport Code [ICAO]": "MYER",
//       "Airport Code iATA,FAA": "RSD",
//       "Airport Name": "Rock Sound Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Bahamas",
//       "Latitude": -76.1768817902,
//       "Location": "Rock Sound",
//       "Longitude": 24.8950787333,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1947,
//       "Airport Code [ICAO]": "MYES",
//       "Airport Code iATA,FAA": "TYM",
//       "Airport Name": "Staniel Cay Airport",
//       "Altitude (Ft)": 5,
//       "Country": "Bahamas",
//       "Latitude": -76.43910217285156,
//       "Location": "Staniel Cay",
//       "Longitude": 24.169099807739258,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1948,
//       "Airport Code [ICAO]": "MYGF",
//       "Airport Code iATA,FAA": "FPO",
//       "Airport Name": "Grand Bahama International Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Bahamas",
//       "Latitude": -78.695602417,
//       "Location": "Freeport",
//       "Longitude": 26.5587005615,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1949,
//       "Airport Code [ICAO]": "MYIG",
//       "Airport Code iATA,FAA": "IGA",
//       "Airport Name": "Inagua Airport",
//       "Altitude (Ft)": 8,
//       "Country": "Bahamas",
//       "Latitude": -73.66690063476562,
//       "Location": "Matthew Town",
//       "Longitude": 20.975000381469727,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1950,
//       "Airport Code [ICAO]": "MYLD",
//       "Airport Code iATA,FAA": "LGI",
//       "Airport Name": "Deadman's Cay Airport",
//       "Altitude (Ft)": 9,
//       "Country": "Bahamas",
//       "Latitude": -75.09359741210001,
//       "Location": "Dead Man's Cay",
//       "Longitude": 23.1790008545,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1951,
//       "Airport Code [ICAO]": "MYLS",
//       "Airport Code iATA,FAA": "SML",
//       "Airport Name": "Stella Maris Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Bahamas",
//       "Latitude": -75.268621,
//       "Location": "Stella Maris",
//       "Longitude": 23.582317,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1952,
//       "Airport Code [ICAO]": "MYMM",
//       "Airport Code iATA,FAA": "MYG",
//       "Airport Name": "Mayaguana Airport",
//       "Altitude (Ft)": 11,
//       "Country": "Bahamas",
//       "Latitude": -73.013494,
//       "Location": "Mayaguana",
//       "Longitude": 22.379499,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1953,
//       "Airport Code [ICAO]": "MYNN",
//       "Airport Code iATA,FAA": "NAS",
//       "Airport Name": "Lynden Pindling International Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Bahamas",
//       "Latitude": -77.46620178219999,
//       "Location": "Nassau",
//       "Longitude": 25.0389995575,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1954,
//       "Airport Code [ICAO]": "MYRD",
//       "Airport Code iATA,FAA": "DCT",
//       "Airport Name": "Duncan Town Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Bahamas",
//       "Latitude": -75.72949981689453,
//       "Location": "Duncan Town",
//       "Longitude": 22.181800842285156,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1955,
//       "Airport Code [ICAO]": "MYRP",
//       "Airport Code iATA,FAA": "RCY",
//       "Airport Name": "Rum Cay Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Bahamas",
//       "Latitude": -74.83619689941406,
//       "Location": "Port Nelson",
//       "Longitude": 23.68440055847168,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1956,
//       "Airport Code [ICAO]": "MYSM",
//       "Airport Code iATA,FAA": "ZSA",
//       "Airport Name": "San Salvador Airport",
//       "Altitude (Ft)": 24,
//       "Country": "Bahamas",
//       "Latitude": -74.52400207519531,
//       "Location": "Cockburn Town",
//       "Longitude": 24.06329917907715,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 1957,
//       "Airport Code [ICAO]": "MZBZ",
//       "Airport Code iATA,FAA": "BZE",
//       "Airport Name": "Philip S. W. Goldson International Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Belize",
//       "Latitude": -88.30819702148438,
//       "Location": "Belize City",
//       "Longitude": 17.539100646972656,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Belize",
//       "Type": "airport"
//     },
//     {
//       "ID": 1958,
//       "Airport Code [ICAO]": "NCAI",
//       "Airport Code iATA,FAA": "AIT",
//       "Airport Name": "Aitutaki Airport",
//       "Altitude (Ft)": 14,
//       "Country": "Cook Islands",
//       "Latitude": -159.76400756835938,
//       "Location": "Aitutaki",
//       "Longitude": -18.830900192260742,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Rarotonga",
//       "Type": "airport"
//     },
//     {
//       "ID": 1959,
//       "Airport Code [ICAO]": "NCRG",
//       "Airport Code iATA,FAA": "RAR",
//       "Airport Name": "Rarotonga International Airport",
//       "Altitude (Ft)": 19,
//       "Country": "Cook Islands",
//       "Latitude": -159.805999756,
//       "Location": "Avarua",
//       "Longitude": -21.2026996613,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Rarotonga",
//       "Type": "airport"
//     },
//     {
//       "ID": 1960,
//       "Airport Code [ICAO]": "NFFN",
//       "Airport Code iATA,FAA": "NAN",
//       "Airport Name": "Nadi International Airport",
//       "Altitude (Ft)": 59,
//       "Country": "Fiji",
//       "Latitude": 177.4429931640625,
//       "Location": "Nandi",
//       "Longitude": -17.755399703979492,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Fiji",
//       "Type": "airport"
//     },
//     {
//       "ID": 1961,
//       "Airport Code [ICAO]": "NFNA",
//       "Airport Code iATA,FAA": "SUV",
//       "Airport Name": "Nausori International Airport",
//       "Altitude (Ft)": 17,
//       "Country": "Fiji",
//       "Latitude": 178.5590057373047,
//       "Location": "Nausori",
//       "Longitude": -18.04330062866211,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Fiji",
//       "Type": "airport"
//     },
//     {
//       "ID": 1963,
//       "Airport Code [ICAO]": "NFTF",
//       "Airport Code iATA,FAA": "TBU",
//       "Airport Name": "Fua'amotu International Airport",
//       "Altitude (Ft)": 126,
//       "Country": "Tonga",
//       "Latitude": -175.14999389648438,
//       "Location": "Tongatapu",
//       "Longitude": -21.241199493408203,
//       "GMT Offset": "13",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tongatapu",
//       "Type": "airport"
//     },
//     {
//       "ID": 1964,
//       "Airport Code [ICAO]": "NFTV",
//       "Airport Code iATA,FAA": "VAV",
//       "Airport Name": "Vava'u International Airport",
//       "Altitude (Ft)": 236,
//       "Country": "Tonga",
//       "Latitude": -173.96200561523438,
//       "Location": "Vava'u",
//       "Longitude": -18.58530044555664,
//       "GMT Offset": "13",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tongatapu",
//       "Type": "airport"
//     },
//     {
//       "ID": 1965,
//       "Airport Code [ICAO]": "NGTA",
//       "Airport Code iATA,FAA": "TRW",
//       "Airport Name": "Bonriki International Airport",
//       "Altitude (Ft)": 9,
//       "Country": "Kiribati",
//       "Latitude": 173.14700317382812,
//       "Location": "Tarawa",
//       "Longitude": 1.3816399574279785,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tarawa",
//       "Type": "airport"
//     },
//     {
//       "ID": 1966,
//       "Airport Code [ICAO]": "NGTE",
//       "Airport Code iATA,FAA": "TBF",
//       "Airport Name": "Tabiteuea North Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Kiribati",
//       "Latitude": 174.7760009765625,
//       "Location": "Tabiteuea North",
//       "Longitude": -1.2244700193405151,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 1968,
//       "Airport Code [ICAO]": "NLWW",
//       "Airport Code iATA,FAA": "WLS",
//       "Airport Name": "Hihifo Airport",
//       "Altitude (Ft)": 79,
//       "Country": "Wallis and Futuna",
//       "Latitude": -176.199005127,
//       "Location": "Wallis",
//       "Longitude": -13.2383003235,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Wallis",
//       "Type": "airport"
//     },
//     {
//       "ID": 1969,
//       "Airport Code [ICAO]": "NSFA",
//       "Airport Code iATA,FAA": "APW",
//       "Airport Name": "Faleolo International Airport",
//       "Altitude (Ft)": 58,
//       "Country": "Samoa",
//       "Latitude": -172.00799560546875,
//       "Location": "Faleolo",
//       "Longitude": -13.829999923706055,
//       "GMT Offset": "13",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Apia",
//       "Type": "airport"
//     },
//     {
//       "ID": 1970,
//       "Airport Code [ICAO]": "NSTU",
//       "Airport Code iATA,FAA": "PPG",
//       "Airport Name": "Pago Pago International Airport",
//       "Altitude (Ft)": 32,
//       "Country": "American Samoa",
//       "Latitude": -170.710006714,
//       "Location": "Pago Pago",
//       "Longitude": -14.3310003281,
//       "GMT Offset": "-11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Pago_Pago",
//       "Type": "airport"
//     },
//     {
//       "ID": 1971,
//       "Airport Code [ICAO]": "NTAR",
//       "Airport Code iATA,FAA": "RUR",
//       "Airport Name": "Rurutu Airport",
//       "Altitude (Ft)": 18,
//       "Country": "French Polynesia",
//       "Latitude": -151.36099243164062,
//       "Location": "Rurutu",
//       "Longitude": -22.434099197387695,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 1972,
//       "Airport Code [ICAO]": "NTAT",
//       "Airport Code iATA,FAA": "TUB",
//       "Airport Name": "Tubuai Airport",
//       "Altitude (Ft)": 7,
//       "Country": "French Polynesia",
//       "Latitude": -149.5240020751953,
//       "Location": "Tubuai",
//       "Longitude": -23.365400314331055,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tahiti",
//       "Type": "airport"
//     },
//     {
//       "ID": 1973,
//       "Airport Code [ICAO]": "NTGA",
//       "Airport Code iATA,FAA": "AAA",
//       "Airport Name": "Anaa Airport",
//       "Altitude (Ft)": 10,
//       "Country": "French Polynesia",
//       "Latitude": -145.50999450683594,
//       "Location": "Anaa",
//       "Longitude": -17.35260009765625,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tahiti",
//       "Type": "airport"
//     },
//     {
//       "ID": 1974,
//       "Airport Code [ICAO]": "NTGB",
//       "Airport Code iATA,FAA": "FGU",
//       "Airport Name": "Fangatau Airport",
//       "Altitude (Ft)": 9,
//       "Country": "French Polynesia",
//       "Latitude": -140.88699340820312,
//       "Location": "Fangatau",
//       "Longitude": -15.819899559020996,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tahiti",
//       "Type": "airport"
//     },
//     {
//       "ID": 1975,
//       "Airport Code [ICAO]": "NTGC",
//       "Airport Code iATA,FAA": "TIH",
//       "Airport Name": "Tikehau Airport",
//       "Altitude (Ft)": 6,
//       "Country": "French Polynesia",
//       "Latitude": -148.2310028076172,
//       "Location": "Tikehau",
//       "Longitude": -15.119600296020508,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tahiti",
//       "Type": "airport"
//     },
//     {
//       "ID": 1976,
//       "Airport Code [ICAO]": "NTGE",
//       "Airport Code iATA,FAA": "REA",
//       "Airport Name": "Reao Airport",
//       "Altitude (Ft)": 12,
//       "Country": "French Polynesia",
//       "Latitude": -136.44000244140625,
//       "Location": "Reao",
//       "Longitude": -18.465900421142578,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 1977,
//       "Airport Code [ICAO]": "NTGF",
//       "Airport Code iATA,FAA": "FAV",
//       "Airport Name": "Fakarava Airport",
//       "Altitude (Ft)": 13,
//       "Country": "French Polynesia",
//       "Latitude": -145.65699768066406,
//       "Location": "Fakarava",
//       "Longitude": -16.054100036621094,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tahiti",
//       "Type": "airport"
//     },
//     {
//       "ID": 1978,
//       "Airport Code [ICAO]": "NTGI",
//       "Airport Code iATA,FAA": "XMH",
//       "Airport Name": "Manihi Airport",
//       "Altitude (Ft)": 14,
//       "Country": "French Polynesia",
//       "Latitude": -146.07000732421875,
//       "Location": "Manihi",
//       "Longitude": -14.436800003051758,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tahiti",
//       "Type": "airport"
//     },
//     {
//       "ID": 1979,
//       "Airport Code [ICAO]": "NTGJ",
//       "Airport Code iATA,FAA": "GMR",
//       "Airport Name": "Totegegie Airport",
//       "Altitude (Ft)": 7,
//       "Country": "French Polynesia",
//       "Latitude": -134.88999938964844,
//       "Location": "Totegegie",
//       "Longitude": -23.07990074157715,
//       "GMT Offset": "-9",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Gambier",
//       "Type": "airport"
//     },
//     {
//       "ID": 1980,
//       "Airport Code [ICAO]": "NTGK",
//       "Airport Code iATA,FAA": "KKR",
//       "Airport Name": "Kaukura Airport",
//       "Altitude (Ft)": 11,
//       "Country": "French Polynesia",
//       "Latitude": -146.88499450683594,
//       "Location": "Kaukura Atoll",
//       "Longitude": -15.663299560546875,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tahiti",
//       "Type": "airport"
//     },
//     {
//       "ID": 1981,
//       "Airport Code [ICAO]": "NTGM",
//       "Airport Code iATA,FAA": "MKP",
//       "Airport Name": "Makemo Airport",
//       "Altitude (Ft)": 3,
//       "Country": "French Polynesia",
//       "Latitude": -143.6580047607422,
//       "Location": "Makemo",
//       "Longitude": -16.583900451660156,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tahiti",
//       "Type": "airport"
//     },
//     {
//       "ID": 1982,
//       "Airport Code [ICAO]": "NTGP",
//       "Airport Code iATA,FAA": "PKP",
//       "Airport Name": "Puka Puka Airport",
//       "Altitude (Ft)": 5,
//       "Country": "French Polynesia",
//       "Latitude": -138.81300354003906,
//       "Location": "Puka Puka",
//       "Longitude": -14.809499740600586,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tahiti",
//       "Type": "airport"
//     },
//     {
//       "ID": 1983,
//       "Airport Code [ICAO]": "NTGT",
//       "Airport Code iATA,FAA": "TKP",
//       "Airport Name": "Takapoto Airport",
//       "Altitude (Ft)": 12,
//       "Country": "French Polynesia",
//       "Latitude": -145.24600219726562,
//       "Location": "Takapoto",
//       "Longitude": -14.709500312805176,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tahiti",
//       "Type": "airport"
//     },
//     {
//       "ID": 1984,
//       "Airport Code [ICAO]": "NTGU",
//       "Airport Code iATA,FAA": "AXR",
//       "Airport Name": "Arutua Airport",
//       "Altitude (Ft)": 9,
//       "Country": "French Polynesia",
//       "Latitude": -146.61700439453125,
//       "Location": "Arutua",
//       "Longitude": -15.248299598693848,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tahiti",
//       "Type": "airport"
//     },
//     {
//       "ID": 1985,
//       "Airport Code [ICAO]": "NTGV",
//       "Airport Code iATA,FAA": "MVT",
//       "Airport Name": "Mataiva Airport",
//       "Altitude (Ft)": 11,
//       "Country": "French Polynesia",
//       "Latitude": -148.7169952392578,
//       "Location": "Mataiva",
//       "Longitude": -14.8681001663208,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tahiti",
//       "Type": "airport"
//     },
//     {
//       "ID": 1986,
//       "Airport Code [ICAO]": "NTKR",
//       "Airport Code iATA,FAA": "TKX",
//       "Airport Name": "Takaroa Airport",
//       "Altitude (Ft)": 13,
//       "Country": "French Polynesia",
//       "Latitude": -145.02499389648438,
//       "Location": "Takaroa",
//       "Longitude": -14.45580005645752,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tahiti",
//       "Type": "airport"
//     },
//     {
//       "ID": 1987,
//       "Airport Code [ICAO]": "NTMD",
//       "Airport Code iATA,FAA": "NHV",
//       "Airport Name": "Nuku Hiva Airport",
//       "Altitude (Ft)": 220,
//       "Country": "French Polynesia",
//       "Latitude": -140.22900390625,
//       "Location": "Nuku Hiva",
//       "Longitude": -8.795599937438965,
//       "GMT Offset": "-9.5",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Marquesas",
//       "Type": "airport"
//     },
//     {
//       "ID": 1989,
//       "Airport Code [ICAO]": "NTTB",
//       "Airport Code iATA,FAA": "BOB",
//       "Airport Name": "Bora Bora Airport",
//       "Altitude (Ft)": 10,
//       "Country": "French Polynesia",
//       "Latitude": -151.75100708007812,
//       "Location": "Bora Bora",
//       "Longitude": -16.444400787353516,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tahiti",
//       "Type": "airport"
//     },
//     {
//       "ID": 1990,
//       "Airport Code [ICAO]": "NTTG",
//       "Airport Code iATA,FAA": "RGI",
//       "Airport Name": "Rangiroa Airport",
//       "Altitude (Ft)": 10,
//       "Country": "French Polynesia",
//       "Latitude": -147.66099548339844,
//       "Location": "Rangiroa",
//       "Longitude": -14.954299926757812,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tahiti",
//       "Type": "airport"
//     },
//     {
//       "ID": 1991,
//       "Airport Code [ICAO]": "NTTH",
//       "Airport Code iATA,FAA": "HUH",
//       "Airport Name": "Huahine-Fare Airport",
//       "Altitude (Ft)": 7,
//       "Country": "French Polynesia",
//       "Latitude": -151.02200317382812,
//       "Location": "Huahine Island",
//       "Longitude": -16.68720054626465,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tahiti",
//       "Type": "airport"
//     },
//     {
//       "ID": 1992,
//       "Airport Code [ICAO]": "NTTM",
//       "Airport Code iATA,FAA": "MOZ",
//       "Airport Name": "Moorea Airport",
//       "Altitude (Ft)": 9,
//       "Country": "French Polynesia",
//       "Latitude": -149.761993,
//       "Location": "Moorea",
//       "Longitude": -17.49,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tahiti",
//       "Type": "airport"
//     },
//     {
//       "ID": 1993,
//       "Airport Code [ICAO]": "NTTO",
//       "Airport Code iATA,FAA": "HOI",
//       "Airport Name": "Hao Airport",
//       "Altitude (Ft)": 10,
//       "Country": "French Polynesia",
//       "Latitude": -140.9459991455078,
//       "Location": "Hao Island",
//       "Longitude": -18.074800491333008,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 1994,
//       "Airport Code [ICAO]": "NTTP",
//       "Airport Code iATA,FAA": "MAU",
//       "Airport Name": "Maupiti Airport",
//       "Altitude (Ft)": 15,
//       "Country": "French Polynesia",
//       "Latitude": -152.24400329589844,
//       "Location": "Maupiti",
//       "Longitude": -16.42650032043457,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tahiti",
//       "Type": "airport"
//     },
//     {
//       "ID": 1995,
//       "Airport Code [ICAO]": "NTTR",
//       "Airport Code iATA,FAA": "RFP",
//       "Airport Name": "Raiatea Airport",
//       "Altitude (Ft)": 3,
//       "Country": "French Polynesia",
//       "Latitude": -151.466003,
//       "Location": "Raiatea Island",
//       "Longitude": -16.7229,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tahiti",
//       "Type": "airport"
//     },
//     {
//       "ID": 1997,
//       "Airport Code [ICAO]": "NVVV",
//       "Airport Code iATA,FAA": "VLI",
//       "Airport Name": "Bauerfield International Airport",
//       "Altitude (Ft)": 70,
//       "Country": "Vanuatu",
//       "Latitude": 168.32000732422,
//       "Location": "Port-vila",
//       "Longitude": -17.699300765991,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 1998,
//       "Airport Code [ICAO]": "NWWD",
//       "Airport Code iATA,FAA": "KNQ",
//       "Airport Name": "Koné Airport",
//       "Altitude (Ft)": 23,
//       "Country": "New Caledonia",
//       "Latitude": 164.83700561523438,
//       "Location": "Kone",
//       "Longitude": -21.05430030822754,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Noumea",
//       "Type": "airport"
//     },
//     {
//       "ID": 1999,
//       "Airport Code [ICAO]": "NWWK",
//       "Airport Code iATA,FAA": "KOC",
//       "Airport Name": "Koumac Airport",
//       "Altitude (Ft)": 42,
//       "Country": "New Caledonia",
//       "Latitude": 164.25599670410156,
//       "Location": "Koumac",
//       "Longitude": -20.546300888061523,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Noumea",
//       "Type": "airport"
//     },
//     {
//       "ID": 2000,
//       "Airport Code [ICAO]": "NWWL",
//       "Airport Code iATA,FAA": "LIF",
//       "Airport Name": "Lifou Airport",
//       "Altitude (Ft)": 92,
//       "Country": "New Caledonia",
//       "Latitude": 167.24000549316406,
//       "Location": "Lifou",
//       "Longitude": -20.774799346923828,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Noumea",
//       "Type": "airport"
//     },
//     {
//       "ID": 2001,
//       "Airport Code [ICAO]": "NWWM",
//       "Airport Code iATA,FAA": "GEA",
//       "Airport Name": "Nouméa Magenta Airport",
//       "Altitude (Ft)": 10,
//       "Country": "New Caledonia",
//       "Latitude": 166.47300720214844,
//       "Location": "Noumea",
//       "Longitude": -22.25830078125,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Noumea",
//       "Type": "airport"
//     },
//     {
//       "ID": 2002,
//       "Airport Code [ICAO]": "NWWR",
//       "Airport Code iATA,FAA": "MEE",
//       "Airport Name": "Maré Airport",
//       "Altitude (Ft)": 141,
//       "Country": "New Caledonia",
//       "Latitude": 168.03799438476562,
//       "Location": "Mare",
//       "Longitude": -21.481700897216797,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Noumea",
//       "Type": "airport"
//     },
//     {
//       "ID": 2003,
//       "Airport Code [ICAO]": "NWWU",
//       "Airport Code iATA,FAA": "TOU",
//       "Airport Name": "Touho Airport",
//       "Altitude (Ft)": 10,
//       "Country": "New Caledonia",
//       "Latitude": 165.25900268554688,
//       "Location": "Touho",
//       "Longitude": -20.790000915527344,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Noumea",
//       "Type": "airport"
//     },
//     {
//       "ID": 2004,
//       "Airport Code [ICAO]": "NWWV",
//       "Airport Code iATA,FAA": "UVE",
//       "Airport Name": "Ouvéa Airport",
//       "Altitude (Ft)": 23,
//       "Country": "New Caledonia",
//       "Latitude": 166.572998046875,
//       "Location": "Ouvea",
//       "Longitude": -20.640600204467773,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Noumea",
//       "Type": "airport"
//     },
//     {
//       "ID": 2005,
//       "Airport Code [ICAO]": "NWWW",
//       "Airport Code iATA,FAA": "NOU",
//       "Airport Name": "La Tontouta International Airport",
//       "Altitude (Ft)": 52,
//       "Country": "New Caledonia",
//       "Latitude": 166.21299743652344,
//       "Location": "Noumea",
//       "Longitude": -22.01460075378418,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Noumea",
//       "Type": "airport"
//     },
//     {
//       "ID": 2006,
//       "Airport Code [ICAO]": "NZAA",
//       "Airport Code iATA,FAA": "AKL",
//       "Airport Name": "Auckland International Airport",
//       "Altitude (Ft)": 23,
//       "Country": "New Zealand",
//       "Latitude": 174.792007446,
//       "Location": "Auckland",
//       "Longitude": -37.008098602299995,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2007,
//       "Airport Code [ICAO]": "NZAP",
//       "Airport Code iATA,FAA": "TUO",
//       "Airport Name": "Taupo Airport",
//       "Altitude (Ft)": 1335,
//       "Country": "New Zealand",
//       "Latitude": 176.08399963378906,
//       "Location": "Taupo",
//       "Longitude": -38.73970031738281,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2008,
//       "Airport Code [ICAO]": "NZAR",
//       "Airport Code iATA,FAA": "AMZ",
//       "Airport Name": "Ardmore Airport",
//       "Altitude (Ft)": 111,
//       "Country": "New Zealand",
//       "Latitude": 174.97300720214844,
//       "Location": "Ardmore",
//       "Longitude": -37.029701232910156,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2009,
//       "Airport Code [ICAO]": "NZCH",
//       "Airport Code iATA,FAA": "CHC",
//       "Airport Name": "Christchurch International Airport",
//       "Altitude (Ft)": 123,
//       "Country": "New Zealand",
//       "Latitude": 172.53199768066406,
//       "Location": "Christchurch",
//       "Longitude": -43.48939895629883,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2010,
//       "Airport Code [ICAO]": "NZCI",
//       "Airport Code iATA,FAA": "CHT",
//       "Airport Name": "Chatham Islands-Tuuta Airport",
//       "Altitude (Ft)": 43,
//       "Country": "New Zealand",
//       "Latitude": -176.45700073242188,
//       "Location": "Chatham Island",
//       "Longitude": -43.810001373291016,
//       "GMT Offset": "12.75",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Chatham",
//       "Type": "airport"
//     },
//     {
//       "ID": 2011,
//       "Airport Code [ICAO]": "NZDN",
//       "Airport Code iATA,FAA": "DUD",
//       "Airport Name": "Dunedin Airport",
//       "Altitude (Ft)": 4,
//       "Country": "New Zealand",
//       "Latitude": 170.197998046875,
//       "Location": "Dunedin",
//       "Longitude": -45.9281005859375,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2012,
//       "Airport Code [ICAO]": "NZGS",
//       "Airport Code iATA,FAA": "GIS",
//       "Airport Name": "Gisborne Airport",
//       "Altitude (Ft)": 15,
//       "Country": "New Zealand",
//       "Latitude": 177.97799682617188,
//       "Location": "Gisborne",
//       "Longitude": -38.663299560546875,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2013,
//       "Airport Code [ICAO]": "NZGT",
//       "Airport Code iATA,FAA": "GTN",
//       "Airport Name": "Glentanner Airport",
//       "Altitude (Ft)": 1824,
//       "Country": "New Zealand",
//       "Latitude": 170.1280059814453,
//       "Location": "Glentanner",
//       "Longitude": -43.906700134277344,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2014,
//       "Airport Code [ICAO]": "NZHK",
//       "Airport Code iATA,FAA": "HKK",
//       "Airport Name": "Hokitika Airfield",
//       "Altitude (Ft)": 146,
//       "Country": "New Zealand",
//       "Latitude": 170.98500061035156,
//       "Location": "Hokitika",
//       "Longitude": -42.713600158691406,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2015,
//       "Airport Code [ICAO]": "NZHN",
//       "Airport Code iATA,FAA": "HLZ",
//       "Airport Name": "Hamilton International Airport",
//       "Altitude (Ft)": 172,
//       "Country": "New Zealand",
//       "Latitude": 175.332000732,
//       "Location": "Hamilton",
//       "Longitude": -37.8666992188,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2016,
//       "Airport Code [ICAO]": "NZHS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hastings Aerodrome",
//       "Altitude (Ft)": 72,
//       "Country": "New Zealand",
//       "Latitude": 176.766998,
//       "Location": "Hastings",
//       "Longitude": -39.646702,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2017,
//       "Airport Code [ICAO]": "NZKK",
//       "Airport Code iATA,FAA": "KKE",
//       "Airport Name": "Kerikeri Airport",
//       "Altitude (Ft)": 492,
//       "Country": "New Zealand",
//       "Latitude": 173.91200256347656,
//       "Location": "Kerikeri",
//       "Longitude": -35.26279830932617,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2018,
//       "Airport Code [ICAO]": "NZKT",
//       "Airport Code iATA,FAA": "KAT",
//       "Airport Name": "Kaitaia Airport",
//       "Altitude (Ft)": 270,
//       "Country": "New Zealand",
//       "Latitude": 173.28500366210938,
//       "Location": "Kaitaia",
//       "Longitude": -35.06999969482422,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2019,
//       "Airport Code [ICAO]": "NZLX",
//       "Airport Code iATA,FAA": "ALR",
//       "Airport Name": "Alexandra Airport",
//       "Altitude (Ft)": 752,
//       "Country": "New Zealand",
//       "Latitude": 169.3730010986328,
//       "Location": "Alexandra",
//       "Longitude": -45.211700439453125,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2020,
//       "Airport Code [ICAO]": "NZMC",
//       "Airport Code iATA,FAA": "MON",
//       "Airport Name": "Mount Cook Airport",
//       "Altitude (Ft)": 2153,
//       "Country": "New Zealand",
//       "Latitude": 170.13299560546875,
//       "Location": "Mount Cook",
//       "Longitude": -43.76499938964844,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2021,
//       "Airport Code [ICAO]": "NZMO",
//       "Airport Code iATA,FAA": "TEU",
//       "Airport Name": "Manapouri Airport",
//       "Altitude (Ft)": 687,
//       "Country": "New Zealand",
//       "Latitude": 167.64999389648438,
//       "Location": "Manapouri",
//       "Longitude": -45.53310012817383,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2022,
//       "Airport Code [ICAO]": "NZMS",
//       "Airport Code iATA,FAA": "MRO",
//       "Airport Name": "Hood Airport",
//       "Altitude (Ft)": 364,
//       "Country": "New Zealand",
//       "Latitude": 175.63400268554688,
//       "Location": "Masterton",
//       "Longitude": -40.97330093383789,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2023,
//       "Airport Code [ICAO]": "NZNP",
//       "Airport Code iATA,FAA": "NPL",
//       "Airport Name": "New Plymouth Airport",
//       "Altitude (Ft)": 97,
//       "Country": "New Zealand",
//       "Latitude": 174.1790008544922,
//       "Location": "New Plymouth",
//       "Longitude": -39.00859832763672,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2024,
//       "Airport Code [ICAO]": "NZNS",
//       "Airport Code iATA,FAA": "NSN",
//       "Airport Name": "Nelson Airport",
//       "Altitude (Ft)": 17,
//       "Country": "New Zealand",
//       "Latitude": 173.2209930419922,
//       "Location": "Nelson",
//       "Longitude": -41.298301696777344,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2025,
//       "Airport Code [ICAO]": "NZNV",
//       "Airport Code iATA,FAA": "IVC",
//       "Airport Name": "Invercargill Airport",
//       "Altitude (Ft)": 5,
//       "Country": "New Zealand",
//       "Latitude": 168.31300354003906,
//       "Location": "Invercargill",
//       "Longitude": -46.41239929199219,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2026,
//       "Airport Code [ICAO]": "NZOH",
//       "Airport Code iATA,FAA": "OHA",
//       "Airport Name": "RNZAF Base Ohakea",
//       "Altitude (Ft)": 164,
//       "Country": "New Zealand",
//       "Latitude": 175.38800048828125,
//       "Location": "Ohakea",
//       "Longitude": -40.20600128173828,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2027,
//       "Airport Code [ICAO]": "NZOU",
//       "Airport Code iATA,FAA": "OAM",
//       "Airport Name": "Oamaru Airport",
//       "Altitude (Ft)": 99,
//       "Country": "New Zealand",
//       "Latitude": 171.08200073242188,
//       "Location": "Oamaru",
//       "Longitude": -44.970001220703125,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2028,
//       "Airport Code [ICAO]": "NZPM",
//       "Airport Code iATA,FAA": "PMR",
//       "Airport Name": "Palmerston North Airport",
//       "Altitude (Ft)": 151,
//       "Country": "New Zealand",
//       "Latitude": 175.61700439453125,
//       "Location": "Palmerston North",
//       "Longitude": -40.32059860229492,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2029,
//       "Airport Code [ICAO]": "NZPP",
//       "Airport Code iATA,FAA": "PPQ",
//       "Airport Name": "Paraparaumu Airport",
//       "Altitude (Ft)": 22,
//       "Country": "New Zealand",
//       "Latitude": 174.98899841308594,
//       "Location": "Paraparaumu",
//       "Longitude": -40.904701232910156,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2030,
//       "Airport Code [ICAO]": "NZQN",
//       "Airport Code iATA,FAA": "ZQN",
//       "Airport Name": "Queenstown International Airport",
//       "Altitude (Ft)": 1171,
//       "Country": "New Zealand",
//       "Latitude": 168.738998413,
//       "Location": "Queenstown International",
//       "Longitude": -45.0210990906,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2031,
//       "Airport Code [ICAO]": "NZRO",
//       "Airport Code iATA,FAA": "ROT",
//       "Airport Name": "Rotorua Regional Airport",
//       "Altitude (Ft)": 935,
//       "Country": "New Zealand",
//       "Latitude": 176.31700134277344,
//       "Location": "Rotorua",
//       "Longitude": -38.10919952392578,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2032,
//       "Airport Code [ICAO]": "NZRU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Waiouru Airport",
//       "Altitude (Ft)": 2686,
//       "Country": "New Zealand",
//       "Latitude": 175.6580047607422,
//       "Location": "Waiouru",
//       "Longitude": -39.4463996887207,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2033,
//       "Airport Code [ICAO]": "NZSP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "South Pole Station Airport",
//       "Altitude (Ft)": 9300,
//       "Country": "Antarctica",
//       "Latitude": 0,
//       "Location": "Stephen's Island",
//       "Longitude": -90,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Antarctica/South_Pole",
//       "Type": "airport"
//     },
//     {
//       "ID": 2034,
//       "Airport Code [ICAO]": "NZTG",
//       "Airport Code iATA,FAA": "TRG",
//       "Airport Name": "Tauranga Airport",
//       "Altitude (Ft)": 13,
//       "Country": "New Zealand",
//       "Latitude": 176.1959991455078,
//       "Location": "Tauranga",
//       "Longitude": -37.67190170288086,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2035,
//       "Airport Code [ICAO]": "NZTU",
//       "Airport Code iATA,FAA": "TIU",
//       "Airport Name": "Timaru Airport",
//       "Altitude (Ft)": 89,
//       "Country": "New Zealand",
//       "Latitude": 171.22500610351562,
//       "Location": "Timaru",
//       "Longitude": -44.302799224853516,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2036,
//       "Airport Code [ICAO]": "NZUK",
//       "Airport Code iATA,FAA": "TWZ",
//       "Airport Name": "Pukaki Airport",
//       "Altitude (Ft)": 1575,
//       "Country": "New Zealand",
//       "Latitude": 170.117996216,
//       "Location": "Pukaki",
//       "Longitude": -44.2350006104,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2037,
//       "Airport Code [ICAO]": "NZWB",
//       "Airport Code iATA,FAA": "BHE",
//       "Airport Name": "Woodbourne Airport",
//       "Altitude (Ft)": 109,
//       "Country": "New Zealand",
//       "Latitude": 173.8699951171875,
//       "Location": "Woodbourne",
//       "Longitude": -41.5182991027832,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2038,
//       "Airport Code [ICAO]": "NZWD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Williams Field",
//       "Altitude (Ft)": 68,
//       "Country": "Antarctica",
//       "Latitude": 167.0570068359375,
//       "Location": "Weydon",
//       "Longitude": -77.86740112304688,
//       "GMT Offset": "12",
//       "Daylight Savings": "N",
//       "Region": "Antarctica/South_Pole",
//       "Type": "airport"
//     },
//     {
//       "ID": 2039,
//       "Airport Code [ICAO]": "NZWF",
//       "Airport Code iATA,FAA": "WKA",
//       "Airport Name": "Wanaka Airport",
//       "Altitude (Ft)": 1142,
//       "Country": "New Zealand",
//       "Latitude": 169.24600219727,
//       "Location": "Wanaka",
//       "Longitude": -44.722198486328,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2040,
//       "Airport Code [ICAO]": "NZWG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Wigram Airport",
//       "Altitude (Ft)": 74,
//       "Country": "New Zealand",
//       "Latitude": 172.552993774,
//       "Location": "Wigram",
//       "Longitude": -43.5511016846,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2041,
//       "Airport Code [ICAO]": "NZWK",
//       "Airport Code iATA,FAA": "WHK",
//       "Airport Name": "Whakatane Airport",
//       "Altitude (Ft)": 20,
//       "Country": "New Zealand",
//       "Latitude": 176.91400146484375,
//       "Location": "Whakatane",
//       "Longitude": -37.92060089111328,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2042,
//       "Airport Code [ICAO]": "NZWN",
//       "Airport Code iATA,FAA": "WLG",
//       "Airport Name": "Wellington International Airport",
//       "Altitude (Ft)": 41,
//       "Country": "New Zealand",
//       "Latitude": 174.804992676,
//       "Location": "Wellington",
//       "Longitude": -41.3272018433,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2043,
//       "Airport Code [ICAO]": "NZWO",
//       "Airport Code iATA,FAA": "WIR",
//       "Airport Name": "Wairoa Airport",
//       "Altitude (Ft)": 42,
//       "Country": "New Zealand",
//       "Latitude": 177.40699768066406,
//       "Location": "Wairoa",
//       "Longitude": -39.006900787353516,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2044,
//       "Airport Code [ICAO]": "NZWP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "RNZAF Base Auckland-Whenuapai",
//       "Altitude (Ft)": 100,
//       "Country": "New Zealand",
//       "Latitude": 174.6300048828125,
//       "Location": "Whenuapai",
//       "Longitude": -36.78779983520508,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2045,
//       "Airport Code [ICAO]": "NZWR",
//       "Airport Code iATA,FAA": "WRE",
//       "Airport Name": "Whangarei Airport",
//       "Altitude (Ft)": 133,
//       "Country": "New Zealand",
//       "Latitude": 174.36500549316406,
//       "Location": "Whangarei",
//       "Longitude": -35.7682991027832,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2046,
//       "Airport Code [ICAO]": "NZWS",
//       "Airport Code iATA,FAA": "WSZ",
//       "Airport Name": "Westport Airport",
//       "Altitude (Ft)": 13,
//       "Country": "New Zealand",
//       "Latitude": 171.58099365234375,
//       "Location": "Westport",
//       "Longitude": -41.73809814453125,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2047,
//       "Airport Code [ICAO]": "NZWU",
//       "Airport Code iATA,FAA": "WAG",
//       "Airport Name": "Wanganui Airport",
//       "Altitude (Ft)": 27,
//       "Country": "New Zealand",
//       "Latitude": 175.02499389648438,
//       "Location": "Wanganui",
//       "Longitude": -39.96220016479492,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 2048,
//       "Airport Code [ICAO]": "OAHR",
//       "Airport Code iATA,FAA": "HEA",
//       "Airport Name": "Herat Airport",
//       "Altitude (Ft)": 3206,
//       "Country": "Afghanistan",
//       "Latitude": 62.22829818725586,
//       "Location": "Herat",
//       "Longitude": 34.209999084472656,
//       "GMT Offset": "4.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Kabul",
//       "Type": "airport"
//     },
//     {
//       "ID": 2049,
//       "Airport Code [ICAO]": "OAJL",
//       "Airport Code iATA,FAA": "JAA",
//       "Airport Name": "Jalalabad Airport",
//       "Altitude (Ft)": 1814,
//       "Country": "Afghanistan",
//       "Latitude": 70.49859619140625,
//       "Location": "Jalalabad",
//       "Longitude": 34.39979934692383,
//       "GMT Offset": "4.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Kabul",
//       "Type": "airport"
//     },
//     {
//       "ID": 2050,
//       "Airport Code [ICAO]": "OAKB",
//       "Airport Code iATA,FAA": "KBL",
//       "Airport Name": "Hamid Karzai International Airport",
//       "Altitude (Ft)": 5877,
//       "Country": "Afghanistan",
//       "Latitude": 69.212303,
//       "Location": "Kabul",
//       "Longitude": 34.565899,
//       "GMT Offset": "4.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Kabul",
//       "Type": "airport"
//     },
//     {
//       "ID": 2051,
//       "Airport Code [ICAO]": "OAKN",
//       "Airport Code iATA,FAA": "KDH",
//       "Airport Name": "Kandahar Airport",
//       "Altitude (Ft)": 3337,
//       "Country": "Afghanistan",
//       "Latitude": 65.8478012084961,
//       "Location": "Kandahar",
//       "Longitude": 31.505800247192383,
//       "GMT Offset": "4.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Kabul",
//       "Type": "airport"
//     },
//     {
//       "ID": 2052,
//       "Airport Code [ICAO]": "OAMN",
//       "Airport Code iATA,FAA": "MMZ",
//       "Airport Name": "Maimana Airport",
//       "Altitude (Ft)": 2743,
//       "Country": "Afghanistan",
//       "Latitude": 64.76090240478516,
//       "Location": "Maimama",
//       "Longitude": 35.93080139160156,
//       "GMT Offset": "4.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Kabul",
//       "Type": "airport"
//     },
//     {
//       "ID": 2053,
//       "Airport Code [ICAO]": "OAMS",
//       "Airport Code iATA,FAA": "MZR",
//       "Airport Name": "Mazar I Sharif Airport",
//       "Altitude (Ft)": 1284,
//       "Country": "Afghanistan",
//       "Latitude": 67.20970153808594,
//       "Location": "Mazar-i-sharif",
//       "Longitude": 36.70690155029297,
//       "GMT Offset": "4.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Kabul",
//       "Type": "airport"
//     },
//     {
//       "ID": 2054,
//       "Airport Code [ICAO]": "OASD",
//       "Airport Code iATA,FAA": "OAH",
//       "Airport Name": "Shindand Airport",
//       "Altitude (Ft)": 3773,
//       "Country": "Afghanistan",
//       "Latitude": 62.2610015869,
//       "Location": "Shindand",
//       "Longitude": 33.3913002014,
//       "GMT Offset": "4.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Kabul",
//       "Type": "airport"
//     },
//     {
//       "ID": 2055,
//       "Airport Code [ICAO]": "OASG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sheberghan Airport",
//       "Altitude (Ft)": 1053,
//       "Country": "Afghanistan",
//       "Latitude": 65.91320037841797,
//       "Location": "Sheberghan",
//       "Longitude": 36.75080108642578,
//       "GMT Offset": "4.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Kabul",
//       "Type": "airport"
//     },
//     {
//       "ID": 2056,
//       "Airport Code [ICAO]": "OAUZ",
//       "Airport Code iATA,FAA": "UND",
//       "Airport Name": "Konduz Airport",
//       "Altitude (Ft)": 1457,
//       "Country": "Afghanistan",
//       "Latitude": 68.91079711914062,
//       "Location": "Kunduz",
//       "Longitude": 36.66510009765625,
//       "GMT Offset": "4.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Kabul",
//       "Type": "airport"
//     },
//     {
//       "ID": 2057,
//       "Airport Code [ICAO]": "OBBI",
//       "Airport Code iATA,FAA": "BAH",
//       "Airport Name": "Bahrain International Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Bahrain",
//       "Latitude": 50.63359832763672,
//       "Location": "Bahrain",
//       "Longitude": 26.27079963684082,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bahrain",
//       "Type": "airport"
//     },
//     {
//       "ID": 2058,
//       "Airport Code [ICAO]": "OBBS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sheik Isa Air Base",
//       "Altitude (Ft)": 136,
//       "Country": "Bahrain",
//       "Latitude": 50.590599060058594,
//       "Location": "Bahrain",
//       "Longitude": 25.918399810791016,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bahrain",
//       "Type": "airport"
//     },
//     {
//       "ID": 2059,
//       "Airport Code [ICAO]": "OEAB",
//       "Airport Code iATA,FAA": "AHB",
//       "Airport Name": "Abha Regional Airport",
//       "Altitude (Ft)": 6858,
//       "Country": "Saudi Arabia",
//       "Latitude": 42.65660095210001,
//       "Location": "Abha",
//       "Longitude": 18.240400314299997,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2060,
//       "Airport Code [ICAO]": "OEAH",
//       "Airport Code iATA,FAA": "HOF",
//       "Airport Name": "Al Ahsa Airport",
//       "Altitude (Ft)": 588,
//       "Country": "Saudi Arabia",
//       "Latitude": 49.485198974609375,
//       "Location": "Al-ahsa",
//       "Longitude": 25.28529930114746,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2061,
//       "Airport Code [ICAO]": "OEBA",
//       "Airport Code iATA,FAA": "ABT",
//       "Airport Name": "Al Baha Airport",
//       "Altitude (Ft)": 5486,
//       "Country": "Saudi Arabia",
//       "Latitude": 41.6343002319,
//       "Location": "El-baha",
//       "Longitude": 20.2961006165,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2062,
//       "Airport Code [ICAO]": "OEBH",
//       "Airport Code iATA,FAA": "BHH",
//       "Airport Name": "Bisha Airport",
//       "Altitude (Ft)": 3887,
//       "Country": "Saudi Arabia",
//       "Latitude": 42.62089920043945,
//       "Location": "Bisha",
//       "Longitude": 19.984399795532227,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2063,
//       "Airport Code [ICAO]": "OEBQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Abqaiq Airport",
//       "Altitude (Ft)": 229,
//       "Country": "Saudi Arabia",
//       "Latitude": 49.59120178222656,
//       "Location": "Abqaiq",
//       "Longitude": 25.911300659179688,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2064,
//       "Airport Code [ICAO]": "OEDF",
//       "Airport Code iATA,FAA": "DMM",
//       "Airport Name": "King Fahd International Airport",
//       "Altitude (Ft)": 72,
//       "Country": "Saudi Arabia",
//       "Latitude": 49.79790115356445,
//       "Location": "Dammam",
//       "Longitude": 26.471200942993164,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2065,
//       "Airport Code [ICAO]": "OEDR",
//       "Airport Code iATA,FAA": "DHA",
//       "Airport Name": "King Abdulaziz Air Base",
//       "Altitude (Ft)": 84,
//       "Country": "Saudi Arabia",
//       "Latitude": 50.152000427199994,
//       "Location": "Dhahran",
//       "Longitude": 26.265399932900003,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2066,
//       "Airport Code [ICAO]": "OEGN",
//       "Airport Code iATA,FAA": "GIZ",
//       "Airport Name": "Jizan Regional Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Saudi Arabia",
//       "Latitude": 42.58580017089844,
//       "Location": "Gizan",
//       "Longitude": 16.901100158691406,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2067,
//       "Airport Code [ICAO]": "OEGS",
//       "Airport Code iATA,FAA": "ELQ",
//       "Airport Name": "Gassim Airport",
//       "Altitude (Ft)": 2126,
//       "Country": "Saudi Arabia",
//       "Latitude": 43.77439880371094,
//       "Location": "Gassim",
//       "Longitude": 26.302799224853516,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2068,
//       "Airport Code [ICAO]": "OEGT",
//       "Airport Code iATA,FAA": "URY",
//       "Airport Name": "Gurayat Domestic Airport",
//       "Altitude (Ft)": 1672,
//       "Country": "Saudi Arabia",
//       "Latitude": 37.278898,
//       "Location": "Guriat",
//       "Longitude": 31.412413,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2069,
//       "Airport Code [ICAO]": "OEHL",
//       "Airport Code iATA,FAA": "HAS",
//       "Airport Name": "Ha'il Airport",
//       "Altitude (Ft)": 3331,
//       "Country": "Saudi Arabia",
//       "Latitude": 41.686298,
//       "Location": "Hail",
//       "Longitude": 27.437901,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2070,
//       "Airport Code [ICAO]": "OEJB",
//       "Airport Code iATA,FAA": "QJB",
//       "Airport Name": "Jubail Airport",
//       "Altitude (Ft)": 26,
//       "Country": "Saudi Arabia",
//       "Latitude": 49.40510177612305,
//       "Location": "Jubail",
//       "Longitude": 27.038999557495117,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2071,
//       "Airport Code [ICAO]": "OEJF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "King Faisal Naval Base",
//       "Altitude (Ft)": 7,
//       "Country": "Saudi Arabia",
//       "Latitude": 39.17300033569336,
//       "Location": "Jeddah",
//       "Longitude": 21.348100662231445,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2072,
//       "Airport Code [ICAO]": "OEJN",
//       "Airport Code iATA,FAA": "JED",
//       "Airport Name": "King Abdulaziz International Airport",
//       "Altitude (Ft)": 48,
//       "Country": "Saudi Arabia",
//       "Latitude": 39.156502,
//       "Location": "Jeddah",
//       "Longitude": 21.6796,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2073,
//       "Airport Code [ICAO]": "OEKK",
//       "Airport Code iATA,FAA": "KMC",
//       "Airport Name": "King Khaled Military City Airport",
//       "Altitude (Ft)": 1352,
//       "Country": "Saudi Arabia",
//       "Latitude": 45.528198,
//       "Location": "King Khalid Mil.city",
//       "Longitude": 27.9009,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2074,
//       "Airport Code [ICAO]": "OEMA",
//       "Airport Code iATA,FAA": "MED",
//       "Airport Name": "Prince Mohammad Bin Abdulaziz Airport",
//       "Altitude (Ft)": 2151,
//       "Country": "Saudi Arabia",
//       "Latitude": 39.705101,
//       "Location": "Madinah",
//       "Longitude": 24.5534,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2075,
//       "Airport Code [ICAO]": "OENG",
//       "Airport Code iATA,FAA": "EAM",
//       "Airport Name": "Nejran Airport",
//       "Altitude (Ft)": 3982,
//       "Country": "Saudi Arabia",
//       "Latitude": 44.4192008972168,
//       "Location": "Nejran",
//       "Longitude": 17.611400604248047,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2076,
//       "Airport Code [ICAO]": "OEPA",
//       "Airport Code iATA,FAA": "AQI",
//       "Airport Name": "Al Qaisumah/Hafr Al Batin Airport",
//       "Altitude (Ft)": 1174,
//       "Country": "Saudi Arabia",
//       "Latitude": 46.125099,
//       "Location": "Hafr Al-batin",
//       "Longitude": 28.335199,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2077,
//       "Airport Code [ICAO]": "OEPC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Pump Station 3 Airport",
//       "Altitude (Ft)": 1740,
//       "Country": "Saudi Arabia",
//       "Latitude": 47.488399505615234,
//       "Location": "Petroline 3",
//       "Longitude": 25.17449951171875,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2078,
//       "Airport Code [ICAO]": "OEPF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Pump Station 6 Airport",
//       "Altitude (Ft)": 2530,
//       "Country": "Saudi Arabia",
//       "Latitude": 44.964500427246094,
//       "Location": "Petroline 6",
//       "Longitude": 24.71030044555664,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2079,
//       "Airport Code [ICAO]": "OEPJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Pump Station 10 Airport",
//       "Altitude (Ft)": 2840,
//       "Country": "Saudi Arabia",
//       "Latitude": 41.0359992980957,
//       "Location": "Petroline 10",
//       "Longitude": 24.1072998046875,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2080,
//       "Airport Code [ICAO]": "OERB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Rabigh Airport",
//       "Altitude (Ft)": 22,
//       "Country": "Saudi Arabia",
//       "Latitude": 39.069801330566406,
//       "Location": "Rabigh",
//       "Longitude": 22.702600479125977,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2081,
//       "Airport Code [ICAO]": "OERF",
//       "Airport Code iATA,FAA": "RAH",
//       "Airport Name": "Rafha Domestic Airport",
//       "Altitude (Ft)": 1474,
//       "Country": "Saudi Arabia",
//       "Latitude": 43.4906005859375,
//       "Location": "Rafha",
//       "Longitude": 29.626399993896484,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2082,
//       "Airport Code [ICAO]": "OERK",
//       "Airport Code iATA,FAA": "RUH",
//       "Airport Name": "King Khaled International Airport",
//       "Altitude (Ft)": 2049,
//       "Country": "Saudi Arabia",
//       "Latitude": 46.69879913330078,
//       "Location": "Riyadh",
//       "Longitude": 24.957599639892578,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2083,
//       "Airport Code [ICAO]": "OERM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ras Mishab Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Saudi Arabia",
//       "Latitude": 48.611000061035156,
//       "Location": "Rash Mishab",
//       "Longitude": 28.079599380493164,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2084,
//       "Airport Code [ICAO]": "OERR",
//       "Airport Code iATA,FAA": "RAE",
//       "Airport Name": "Arar Domestic Airport",
//       "Altitude (Ft)": 1813,
//       "Country": "Saudi Arabia",
//       "Latitude": 41.13819885253906,
//       "Location": "Arar",
//       "Longitude": 30.906600952148438,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2085,
//       "Airport Code [ICAO]": "OERT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ras Tanura Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Saudi Arabia",
//       "Latitude": 50.030799865722656,
//       "Location": "Ras Tanura",
//       "Longitude": 26.723100662231445,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2086,
//       "Airport Code [ICAO]": "OESH",
//       "Airport Code iATA,FAA": "SHW",
//       "Airport Name": "Sharurah Airport",
//       "Altitude (Ft)": 2363,
//       "Country": "Saudi Arabia",
//       "Latitude": 47.12139892578125,
//       "Location": "Sharurah",
//       "Longitude": 17.466899871826172,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2088,
//       "Airport Code [ICAO]": "OESL",
//       "Airport Code iATA,FAA": "SLF",
//       "Airport Name": "Sulayel Airport",
//       "Altitude (Ft)": 2021,
//       "Country": "Saudi Arabia",
//       "Latitude": 45.619598388671875,
//       "Location": "Sulayel",
//       "Longitude": 20.46470069885254,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2089,
//       "Airport Code [ICAO]": "OETB",
//       "Airport Code iATA,FAA": "TUU",
//       "Airport Name": "Tabuk Airport",
//       "Altitude (Ft)": 2551,
//       "Country": "Saudi Arabia",
//       "Latitude": 36.6189,
//       "Location": "Tabuk",
//       "Longitude": 28.3654,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2090,
//       "Airport Code [ICAO]": "OETF",
//       "Airport Code iATA,FAA": "TIF",
//       "Airport Name": "Ta’if Regional Airport",
//       "Altitude (Ft)": 4848,
//       "Country": "Saudi Arabia",
//       "Latitude": 40.543442,
//       "Location": "Taif",
//       "Longitude": 21.483001,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2091,
//       "Airport Code [ICAO]": "OETH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Thumamah Airport",
//       "Altitude (Ft)": 1870,
//       "Country": "Saudi Arabia",
//       "Latitude": 46.64099884033203,
//       "Location": "Thumamah",
//       "Longitude": 25.21299934387207,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2092,
//       "Airport Code [ICAO]": "OETN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ras Tanajib Airport",
//       "Altitude (Ft)": 30,
//       "Country": "Saudi Arabia",
//       "Latitude": 48.769100189208984,
//       "Location": "Ras Tanajib",
//       "Longitude": 27.867799758911133,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2093,
//       "Airport Code [ICAO]": "OETR",
//       "Airport Code iATA,FAA": "TUI",
//       "Airport Name": "Turaif Domestic Airport",
//       "Altitude (Ft)": 2803,
//       "Country": "Saudi Arabia",
//       "Latitude": 38.731544,
//       "Location": "Turaif",
//       "Longitude": 31.692188,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2095,
//       "Airport Code [ICAO]": "OEWJ",
//       "Airport Code iATA,FAA": "EJH",
//       "Airport Name": "Al Wajh Domestic Airport",
//       "Altitude (Ft)": 66,
//       "Country": "Saudi Arabia",
//       "Latitude": 36.47639846801758,
//       "Location": "Wejh",
//       "Longitude": 26.19860076904297,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2096,
//       "Airport Code [ICAO]": "OEYN",
//       "Airport Code iATA,FAA": "YNB",
//       "Airport Name": "Prince Abdulmohsin Bin Abdulaziz Airport",
//       "Altitude (Ft)": 26,
//       "Country": "Saudi Arabia",
//       "Latitude": 38.0634,
//       "Location": "Yenbo",
//       "Longitude": 24.144199,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 2097,
//       "Airport Code [ICAO]": "OIAA",
//       "Airport Code iATA,FAA": "ABD",
//       "Airport Name": "Abadan Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Iran",
//       "Latitude": 48.2282981873,
//       "Location": "Abadan",
//       "Longitude": 30.371099472,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2098,
//       "Airport Code [ICAO]": "OIAD",
//       "Airport Code iATA,FAA": "DEF",
//       "Airport Name": "Dezful Airport",
//       "Altitude (Ft)": 474,
//       "Country": "Iran",
//       "Latitude": 48.397598,
//       "Location": "Dezful",
//       "Longitude": 32.434399,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2099,
//       "Airport Code [ICAO]": "OIAG",
//       "Airport Code iATA,FAA": "AKW",
//       "Airport Name": "Aghajari Airport",
//       "Altitude (Ft)": 88,
//       "Country": "Iran",
//       "Latitude": 49.6772,
//       "Location": "Aghajari",
//       "Longitude": 30.7444,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2100,
//       "Airport Code [ICAO]": "OIAH",
//       "Airport Code iATA,FAA": "GCH",
//       "Airport Name": "Gachsaran Airport",
//       "Altitude (Ft)": 2414,
//       "Country": "Iran",
//       "Latitude": 50.827999115,
//       "Location": "Gachsaran",
//       "Longitude": 30.337600708,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2101,
//       "Airport Code [ICAO]": "OIAI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Shahid Asyaee Airport",
//       "Altitude (Ft)": 1206,
//       "Country": "Iran",
//       "Latitude": 49.27040100097656,
//       "Location": "Masjed Soleiman",
//       "Longitude": 32.00239944458008,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2102,
//       "Airport Code [ICAO]": "OIAJ",
//       "Airport Code iATA,FAA": "OMI",
//       "Airport Name": "Omidiyeh Airport",
//       "Altitude (Ft)": 85,
//       "Country": "Iran",
//       "Latitude": 49.5349006652832,
//       "Location": "Omidyeh",
//       "Longitude": 30.8351993560791,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2103,
//       "Airport Code [ICAO]": "OIAM",
//       "Airport Code iATA,FAA": "MRX",
//       "Airport Name": "Mahshahr Airport",
//       "Altitude (Ft)": 8,
//       "Country": "Iran",
//       "Latitude": 49.15190124511719,
//       "Location": "Bandar Mahshahr",
//       "Longitude": 30.55620002746582,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2104,
//       "Airport Code [ICAO]": "OIAW",
//       "Airport Code iATA,FAA": "AWZ",
//       "Airport Name": "Ahwaz Airport",
//       "Altitude (Ft)": 66,
//       "Country": "Iran",
//       "Latitude": 48.7620010376,
//       "Location": "Ahwaz",
//       "Longitude": 31.337400436399996,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2105,
//       "Airport Code [ICAO]": "OIBA",
//       "Airport Code iATA,FAA": "AEU",
//       "Airport Name": "Abu Musa Island Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Iran",
//       "Latitude": 55.033001,
//       "Location": "Abumusa I.",
//       "Longitude": 25.8757,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2106,
//       "Airport Code [ICAO]": "OIBB",
//       "Airport Code iATA,FAA": "BUZ",
//       "Airport Name": "Bushehr Airport",
//       "Altitude (Ft)": 68,
//       "Country": "Iran",
//       "Latitude": 50.8345985413,
//       "Location": "Bushehr",
//       "Longitude": 28.9447994232,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2107,
//       "Airport Code [ICAO]": "OIBH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bastak Airport",
//       "Altitude (Ft)": 1350,
//       "Country": "Iran",
//       "Latitude": 54.318599700927734,
//       "Location": "Bastak",
//       "Longitude": 27.21269989013672,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2108,
//       "Airport Code [ICAO]": "OIBI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Asaloyeh Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Iran",
//       "Latitude": 52.615501,
//       "Location": "Golbandi",
//       "Longitude": 27.4814,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2109,
//       "Airport Code [ICAO]": "OIBK",
//       "Airport Code iATA,FAA": "KIH",
//       "Airport Name": "Kish International Airport",
//       "Altitude (Ft)": 101,
//       "Country": "Iran",
//       "Latitude": 53.980201721200004,
//       "Location": "Kish Island",
//       "Longitude": 26.5261993408,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2110,
//       "Airport Code [ICAO]": "OIBL",
//       "Airport Code iATA,FAA": "BDH",
//       "Airport Name": "Bandar Lengeh Airport",
//       "Altitude (Ft)": 67,
//       "Country": "Iran",
//       "Latitude": 54.824798584,
//       "Location": "Bandar Lengeh",
//       "Longitude": 26.531999588,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2111,
//       "Airport Code [ICAO]": "OIBQ",
//       "Airport Code iATA,FAA": "KHK",
//       "Airport Name": "Khark Island Airport",
//       "Altitude (Ft)": 17,
//       "Country": "Iran",
//       "Latitude": 50.32389831542969,
//       "Location": "Khark Island",
//       "Longitude": 29.260299682617188,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2112,
//       "Airport Code [ICAO]": "OIBS",
//       "Airport Code iATA,FAA": "SXI",
//       "Airport Name": "Sirri Island Airport",
//       "Altitude (Ft)": 43,
//       "Country": "Iran",
//       "Latitude": 54.539398193359375,
//       "Location": "Siri Island",
//       "Longitude": 25.908899307250977,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2113,
//       "Airport Code [ICAO]": "OIBV",
//       "Airport Code iATA,FAA": "LVP",
//       "Airport Name": "Lavan Island Airport",
//       "Altitude (Ft)": 76,
//       "Country": "Iran",
//       "Latitude": 53.356300354003906,
//       "Location": "Lavan Island",
//       "Longitude": 26.810300827026367,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2114,
//       "Airport Code [ICAO]": "OICC",
//       "Airport Code iATA,FAA": "KSH",
//       "Airport Name": "Shahid Ashrafi Esfahani Airport",
//       "Altitude (Ft)": 4307,
//       "Country": "Iran",
//       "Latitude": 47.1581001282,
//       "Location": "Bakhtaran",
//       "Longitude": 34.3459014893,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2117,
//       "Airport Code [ICAO]": "OICS",
//       "Airport Code iATA,FAA": "SDG",
//       "Airport Name": "Sanandaj Airport",
//       "Altitude (Ft)": 4522,
//       "Country": "Iran",
//       "Latitude": 47.00920104980469,
//       "Location": "Sanandaj",
//       "Longitude": 35.24589920043945,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2118,
//       "Airport Code [ICAO]": "OIFE",
//       "Airport Code iATA,FAA": "IFH",
//       "Airport Name": "Hesa Airport",
//       "Altitude (Ft)": 5256,
//       "Country": "Iran",
//       "Latitude": 51.561100006103516,
//       "Location": "Daran",
//       "Longitude": 32.92890167236328,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2119,
//       "Airport Code [ICAO]": "OIFH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Shahid Vatan Pour Air Base",
//       "Altitude (Ft)": 5310,
//       "Country": "Iran",
//       "Latitude": 51.69160079956055,
//       "Location": "Esfahan",
//       "Longitude": 32.56700134277344,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2120,
//       "Airport Code [ICAO]": "OIFK",
//       "Airport Code iATA,FAA": "KKS",
//       "Airport Name": "Kashan Airport",
//       "Altitude (Ft)": 3465,
//       "Country": "Iran",
//       "Latitude": 51.577,
//       "Location": "Kashan",
//       "Longitude": 33.895302,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2121,
//       "Airport Code [ICAO]": "OIFM",
//       "Airport Code iATA,FAA": "IFN",
//       "Airport Name": "Esfahan Shahid Beheshti International Airport",
//       "Altitude (Ft)": 5059,
//       "Country": "Iran",
//       "Latitude": 51.86130142211914,
//       "Location": "Esfahan",
//       "Longitude": 32.75080108642578,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2122,
//       "Airport Code [ICAO]": "OIFP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Badr Air Base",
//       "Altitude (Ft)": 5242,
//       "Country": "Iran",
//       "Latitude": 51.696998596191406,
//       "Location": "Sepah",
//       "Longitude": 32.62110137939453,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2123,
//       "Airport Code [ICAO]": "OIGG",
//       "Airport Code iATA,FAA": "RAS",
//       "Airport Name": "Sardar-e-Jangal Airport",
//       "Altitude (Ft)": -40,
//       "Country": "Iran",
//       "Latitude": 49.617778,
//       "Location": "Rasht",
//       "Longitude": 37.323333,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2125,
//       "Airport Code [ICAO]": "OIHR",
//       "Airport Code iATA,FAA": "AJK",
//       "Airport Name": "Arak Airport",
//       "Altitude (Ft)": 5440,
//       "Country": "Iran",
//       "Latitude": 49.8473014831543,
//       "Location": "Arak",
//       "Longitude": 34.138099670410156,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2126,
//       "Airport Code [ICAO]": "OIIA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ghazvin Azadi Airport",
//       "Altitude (Ft)": 3769,
//       "Country": "Iran",
//       "Latitude": 50.450801849365234,
//       "Location": "Abe-ali",
//       "Longitude": 35.95209884643555,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2127,
//       "Airport Code [ICAO]": "OIIC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kushke Nosrat Airport",
//       "Altitude (Ft)": 3008,
//       "Country": "Iran",
//       "Latitude": 50.80619812011719,
//       "Location": "Kushke Nosrat",
//       "Longitude": 34.98400115966797,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2128,
//       "Airport Code [ICAO]": "OIID",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Doshan Tappeh Air Base",
//       "Altitude (Ft)": 4046,
//       "Country": "Iran",
//       "Latitude": 51.475101470947266,
//       "Location": "Teheran",
//       "Longitude": 35.702999114990234,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2130,
//       "Airport Code [ICAO]": "OIIG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ghale Morghi Airport",
//       "Altitude (Ft)": 3627,
//       "Country": "Iran",
//       "Latitude": 51.380699157714844,
//       "Location": "Teheran",
//       "Longitude": 35.644798278808594,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2131,
//       "Airport Code [ICAO]": "OIII",
//       "Airport Code iATA,FAA": "THR",
//       "Airport Name": "Mehrabad International Airport",
//       "Altitude (Ft)": 3962,
//       "Country": "Iran",
//       "Latitude": 51.31340026855469,
//       "Location": "Teheran",
//       "Longitude": 35.68920135498047,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2132,
//       "Airport Code [ICAO]": "OIIK",
//       "Airport Code iATA,FAA": "GZW",
//       "Airport Name": "Qazvin Airport",
//       "Altitude (Ft)": 4184,
//       "Country": "Iran",
//       "Latitude": 50.0471,
//       "Location": "Ghazvin",
//       "Longitude": 36.240101,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2133,
//       "Airport Code [ICAO]": "OIIM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Naja Airport",
//       "Altitude (Ft)": 4040,
//       "Country": "Iran",
//       "Latitude": 50.88100051879883,
//       "Location": "Khoram Dareh",
//       "Longitude": 35.77629852294922,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2134,
//       "Airport Code [ICAO]": "OIKB",
//       "Airport Code iATA,FAA": "BND",
//       "Airport Name": "Bandar Abbas International Airport",
//       "Altitude (Ft)": 22,
//       "Country": "Iran",
//       "Latitude": 56.37779998779297,
//       "Location": "Bandar Abbas",
//       "Longitude": 27.218299865722656,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2135,
//       "Airport Code [ICAO]": "OIKJ",
//       "Airport Code iATA,FAA": "JYR",
//       "Airport Name": "Jiroft Airport",
//       "Altitude (Ft)": 2663,
//       "Country": "Iran",
//       "Latitude": 57.67029953,
//       "Location": "Jiroft",
//       "Longitude": 28.726900100699996,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2136,
//       "Airport Code [ICAO]": "OIKK",
//       "Airport Code iATA,FAA": "KER",
//       "Airport Name": "Kerman Airport",
//       "Altitude (Ft)": 5741,
//       "Country": "Iran",
//       "Latitude": 56.9510993958,
//       "Location": "Kerman",
//       "Longitude": 30.274400711099997,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2138,
//       "Airport Code [ICAO]": "OIKP",
//       "Airport Code iATA,FAA": "HDR",
//       "Airport Name": "Havadarya Airport",
//       "Altitude (Ft)": 19,
//       "Country": "Iran",
//       "Latitude": 56.17250061035156,
//       "Location": "Bandar Abbas",
//       "Longitude": 27.158300399780273,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2139,
//       "Airport Code [ICAO]": "OIKQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Dayrestan Airport",
//       "Altitude (Ft)": 45,
//       "Country": "Iran",
//       "Latitude": 55.902400970458984,
//       "Location": "Gheshm I.",
//       "Longitude": 26.754600524902344,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2141,
//       "Airport Code [ICAO]": "OIKY",
//       "Airport Code iATA,FAA": "SYJ",
//       "Airport Name": "Sirjan Airport",
//       "Altitude (Ft)": 5846,
//       "Country": "Iran",
//       "Latitude": 55.672698974609375,
//       "Location": "Sirjan",
//       "Longitude": 29.550899505615234,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2142,
//       "Airport Code [ICAO]": "OIMB",
//       "Airport Code iATA,FAA": "XBJ",
//       "Airport Name": "Birjand Airport",
//       "Altitude (Ft)": 4952,
//       "Country": "Iran",
//       "Latitude": 59.2661018371582,
//       "Location": "Birjand",
//       "Longitude": 32.898101806640625,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2143,
//       "Airport Code [ICAO]": "OIMC",
//       "Airport Code iATA,FAA": "CKT",
//       "Airport Name": "Sarakhs Airport",
//       "Altitude (Ft)": 945,
//       "Country": "Iran",
//       "Latitude": 61.06489944458008,
//       "Location": "Sarakhs",
//       "Longitude": 36.50120162963867,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2144,
//       "Airport Code [ICAO]": "OIMJ",
//       "Airport Code iATA,FAA": "RUD",
//       "Airport Name": "Shahroud Airport",
//       "Altitude (Ft)": 4215,
//       "Country": "Iran",
//       "Latitude": 55.104198455799995,
//       "Location": "Emam Shahr",
//       "Longitude": 36.4253005981,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2147,
//       "Airport Code [ICAO]": "OIMT",
//       "Airport Code iATA,FAA": "TCX",
//       "Airport Name": "Tabas Airport",
//       "Altitude (Ft)": 2312,
//       "Country": "Iran",
//       "Latitude": 56.8927001953,
//       "Location": "Tabas",
//       "Longitude": 33.6678009033,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2148,
//       "Airport Code [ICAO]": "OINE",
//       "Airport Code iATA,FAA": "KLM",
//       "Airport Name": "Kalaleh Airport",
//       "Altitude (Ft)": 425,
//       "Country": "Iran",
//       "Latitude": 55.4519996643,
//       "Location": "Kalaleh",
//       "Longitude": 37.3833007812,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2151,
//       "Airport Code [ICAO]": "OINR",
//       "Airport Code iATA,FAA": "RZR",
//       "Airport Name": "Ramsar Airport",
//       "Altitude (Ft)": -70,
//       "Country": "Iran",
//       "Latitude": 50.67959976196289,
//       "Location": "Ramsar",
//       "Longitude": 36.9099006652832,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2153,
//       "Airport Code [ICAO]": "OISF",
//       "Airport Code iATA,FAA": "FAZ",
//       "Airport Name": "Fasa Airport",
//       "Altitude (Ft)": 4261,
//       "Country": "Iran",
//       "Latitude": 53.72330093383789,
//       "Location": "Fasa",
//       "Longitude": 28.891799926757812,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2154,
//       "Airport Code [ICAO]": "OISJ",
//       "Airport Code iATA,FAA": "JAR",
//       "Airport Name": "Jahrom Airport",
//       "Altitude (Ft)": 3358,
//       "Country": "Iran",
//       "Latitude": 53.5791015625,
//       "Location": "Jahrom",
//       "Longitude": 28.586700439499996,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2156,
//       "Airport Code [ICAO]": "OISR",
//       "Airport Code iATA,FAA": "LFM",
//       "Airport Name": "Lamerd Airport",
//       "Altitude (Ft)": 1337,
//       "Country": "Iran",
//       "Latitude": 53.18880081179999,
//       "Location": "Lamerd",
//       "Longitude": 27.3726997375,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2157,
//       "Airport Code [ICAO]": "OISS",
//       "Airport Code iATA,FAA": "SYZ",
//       "Airport Name": "Shiraz Shahid Dastghaib International Airport",
//       "Altitude (Ft)": 4920,
//       "Country": "Iran",
//       "Latitude": 52.58980178833008,
//       "Location": "Shiraz",
//       "Longitude": 29.539199829101562,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2158,
//       "Airport Code [ICAO]": "OITK",
//       "Airport Code iATA,FAA": "KHY",
//       "Airport Name": "Khoy Airport",
//       "Altitude (Ft)": 3981,
//       "Country": "Iran",
//       "Latitude": 44.97359848022461,
//       "Location": "Khoy",
//       "Longitude": 38.4275016784668,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2162,
//       "Airport Code [ICAO]": "OITT",
//       "Airport Code iATA,FAA": "TBZ",
//       "Airport Name": "Tabriz International Airport",
//       "Altitude (Ft)": 4459,
//       "Country": "Iran",
//       "Latitude": 46.23500061035156,
//       "Location": "Tabriz",
//       "Longitude": 38.1338996887207,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2163,
//       "Airport Code [ICAO]": "OITZ",
//       "Airport Code iATA,FAA": "JWN",
//       "Airport Name": "Zanjan Airport",
//       "Altitude (Ft)": 5382,
//       "Country": "Iran",
//       "Latitude": 48.3594017029,
//       "Location": "Zanjan",
//       "Longitude": 36.7737007141,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2164,
//       "Airport Code [ICAO]": "OIYY",
//       "Airport Code iATA,FAA": "AZD",
//       "Airport Name": "Shahid Sadooghi Airport",
//       "Altitude (Ft)": 4054,
//       "Country": "Iran",
//       "Latitude": 54.2765007019,
//       "Location": "Yazd",
//       "Longitude": 31.9048995972,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2165,
//       "Airport Code [ICAO]": "OIZB",
//       "Airport Code iATA,FAA": "ACZ",
//       "Airport Name": "Zabol Airport",
//       "Altitude (Ft)": 1628,
//       "Country": "Iran",
//       "Latitude": 61.54389953613281,
//       "Location": "Zabol",
//       "Longitude": 31.09830093383789,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2166,
//       "Airport Code [ICAO]": "OIZC",
//       "Airport Code iATA,FAA": "ZBR",
//       "Airport Name": "Konarak Airport",
//       "Altitude (Ft)": 43,
//       "Country": "Iran",
//       "Latitude": 60.3820991516,
//       "Location": "Chah Bahar",
//       "Longitude": 25.443300247199996,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2167,
//       "Airport Code [ICAO]": "OIZH",
//       "Airport Code iATA,FAA": "ZAH",
//       "Airport Name": "Zahedan International Airport",
//       "Altitude (Ft)": 4564,
//       "Country": "Iran",
//       "Latitude": 60.90620040893555,
//       "Location": "Zahedan",
//       "Longitude": 29.47570037841797,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2168,
//       "Airport Code [ICAO]": "OIZI",
//       "Airport Code iATA,FAA": "IHR",
//       "Airport Name": "Iran Shahr Airport",
//       "Altitude (Ft)": 2040,
//       "Country": "Iran",
//       "Latitude": 60.7200012207,
//       "Location": "Iran Shahr",
//       "Longitude": 27.2360992432,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2169,
//       "Airport Code [ICAO]": "OIZS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Saravan Airport",
//       "Altitude (Ft)": 3930,
//       "Country": "Iran",
//       "Latitude": 62.315799713134766,
//       "Location": "Saravan",
//       "Longitude": 27.419300079345703,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 2170,
//       "Airport Code [ICAO]": "OJAI",
//       "Airport Code iATA,FAA": "AMM",
//       "Airport Name": "Queen Alia International Airport",
//       "Altitude (Ft)": 2395,
//       "Country": "Jordan",
//       "Latitude": 35.9931983948,
//       "Location": "Amman",
//       "Longitude": 31.7226009369,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Amman",
//       "Type": "airport"
//     },
//     {
//       "ID": 2171,
//       "Airport Code [ICAO]": "OJAM",
//       "Airport Code iATA,FAA": "ADJ",
//       "Airport Name": "Amman-Marka International Airport",
//       "Altitude (Ft)": 2555,
//       "Country": "Jordan",
//       "Latitude": 35.991600036621094,
//       "Location": "Amman",
//       "Longitude": 31.972700119018555,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Amman",
//       "Type": "airport"
//     },
//     {
//       "ID": 2172,
//       "Airport Code [ICAO]": "OJAQ",
//       "Airport Code iATA,FAA": "AQJ",
//       "Airport Name": "Aqaba King Hussein International Airport",
//       "Altitude (Ft)": 175,
//       "Country": "Jordan",
//       "Latitude": 35.01810073852539,
//       "Location": "Aqaba",
//       "Longitude": 29.611600875854492,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Amman",
//       "Type": "airport"
//     },
//     {
//       "ID": 2173,
//       "Airport Code [ICAO]": "OJHF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Prince Hassan Air Base",
//       "Altitude (Ft)": 2220,
//       "Country": "Jordan",
//       "Latitude": 37.149399,
//       "Location": "Hotel Four",
//       "Longitude": 32.160702,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Amman",
//       "Type": "airport"
//     },
//     {
//       "ID": 2174,
//       "Airport Code [ICAO]": "OJJR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Jerusalem Airport",
//       "Altitude (Ft)": 2485,
//       "Country": "West Bank",
//       "Latitude": 35.219200134277,
//       "Location": "Jerusalem",
//       "Longitude": 31.864700317383,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Asia/Jerusalem",
//       "Type": "airport"
//     },
//     {
//       "ID": 2175,
//       "Airport Code [ICAO]": "OJMF",
//       "Airport Code iATA,FAA": "OMF",
//       "Airport Name": "King Hussein Air College",
//       "Altitude (Ft)": 2240,
//       "Country": "Jordan",
//       "Latitude": 36.259201,
//       "Location": "Mafraq",
//       "Longitude": 32.3564,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Amman",
//       "Type": "airport"
//     },
//     {
//       "ID": 2176,
//       "Airport Code [ICAO]": "OKBK",
//       "Airport Code iATA,FAA": "KWI",
//       "Airport Name": "Kuwait International Airport",
//       "Altitude (Ft)": 206,
//       "Country": "Kuwait",
//       "Latitude": 47.96889877319336,
//       "Location": "Kuwait",
//       "Longitude": 29.226600646972656,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Kuwait",
//       "Type": "airport"
//     },
//     {
//       "ID": 2177,
//       "Airport Code [ICAO]": "OLBA",
//       "Airport Code iATA,FAA": "BEY",
//       "Airport Name": "Beirut Rafic Hariri International Airport",
//       "Altitude (Ft)": 87,
//       "Country": "Lebanon",
//       "Latitude": 35.488399505615234,
//       "Location": "Beirut",
//       "Longitude": 33.820899963378906,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Beirut",
//       "Type": "airport"
//     },
//     {
//       "ID": 2178,
//       "Airport Code [ICAO]": "OLKA",
//       "Airport Code iATA,FAA": "KYE",
//       "Airport Name": "Rene Mouawad Air Base",
//       "Altitude (Ft)": 75,
//       "Country": "Lebanon",
//       "Latitude": 36.01129913330078,
//       "Location": "Kleiat",
//       "Longitude": 34.589298248291016,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Beirut",
//       "Type": "airport"
//     },
//     {
//       "ID": 2179,
//       "Airport Code [ICAO]": "OMAA",
//       "Airport Code iATA,FAA": "AUH",
//       "Airport Name": "Abu Dhabi International Airport",
//       "Altitude (Ft)": 88,
//       "Country": "United Arab Emirates",
//       "Latitude": 54.651100158691406,
//       "Location": "Abu Dhabi",
//       "Longitude": 24.433000564575195,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dubai",
//       "Type": "airport"
//     },
//     {
//       "ID": 2180,
//       "Airport Code [ICAO]": "OMAD",
//       "Airport Code iATA,FAA": "AZI",
//       "Airport Name": "Bateen Airport",
//       "Altitude (Ft)": 16,
//       "Country": "United Arab Emirates",
//       "Latitude": 54.458099365234375,
//       "Location": "Abu Dhabi",
//       "Longitude": 24.428300857543945,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dubai",
//       "Type": "airport"
//     },
//     {
//       "ID": 2181,
//       "Airport Code [ICAO]": "OMAH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Al Hamra Aux Airport",
//       "Altitude (Ft)": 50,
//       "Country": "United Arab Emirates",
//       "Latitude": 52.463600158691406,
//       "Location": "Al Hamra",
//       "Longitude": 24.073999404907227,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dubai",
//       "Type": "airport"
//     },
//     {
//       "ID": 2182,
//       "Airport Code [ICAO]": "OMAJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Jebel Dhana Airport",
//       "Altitude (Ft)": 43,
//       "Country": "United Arab Emirates",
//       "Latitude": 52.61399841308594,
//       "Location": "Jebel Dhana",
//       "Longitude": 24.187400817871094,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dubai",
//       "Type": "airport"
//     },
//     {
//       "ID": 2184,
//       "Airport Code [ICAO]": "OMAM",
//       "Airport Code iATA,FAA": "DHF",
//       "Airport Name": "Al Dhafra Air Base",
//       "Altitude (Ft)": 77,
//       "Country": "United Arab Emirates",
//       "Latitude": 54.547698974599996,
//       "Location": "Abu Dhabi",
//       "Longitude": 24.248199462900004,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dubai",
//       "Type": "airport"
//     },
//     {
//       "ID": 2185,
//       "Airport Code [ICAO]": "OMAR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Arzanah Airport",
//       "Altitude (Ft)": 15,
//       "Country": "United Arab Emirates",
//       "Latitude": 52.559898376464844,
//       "Location": "Arzana",
//       "Longitude": 24.780500411987305,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dubai",
//       "Type": "airport"
//     },
//     {
//       "ID": 2186,
//       "Airport Code [ICAO]": "OMAS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Das Island Airport",
//       "Altitude (Ft)": 12,
//       "Country": "United Arab Emirates",
//       "Latitude": 52.87369918823242,
//       "Location": "Das Island",
//       "Longitude": 25.14620018005371,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dubai",
//       "Type": "airport"
//     },
//     {
//       "ID": 2187,
//       "Airport Code [ICAO]": "OMAZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Zirku Airport",
//       "Altitude (Ft)": 14,
//       "Country": "United Arab Emirates",
//       "Latitude": 53.07714080810547,
//       "Location": "Zirku",
//       "Longitude": 24.862491607666016,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dubai",
//       "Type": "airport"
//     },
//     {
//       "ID": 2188,
//       "Airport Code [ICAO]": "OMDB",
//       "Airport Code iATA,FAA": "DXB",
//       "Airport Name": "Dubai International Airport",
//       "Altitude (Ft)": 62,
//       "Country": "United Arab Emirates",
//       "Latitude": 55.3643989563,
//       "Location": "Dubai",
//       "Longitude": 25.2527999878,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dubai",
//       "Type": "airport"
//     },
//     {
//       "ID": 2189,
//       "Airport Code [ICAO]": "OMFJ",
//       "Airport Code iATA,FAA": "FJR",
//       "Airport Name": "Fujairah International Airport",
//       "Altitude (Ft)": 152,
//       "Country": "United Arab Emirates",
//       "Latitude": 56.32400131225586,
//       "Location": "Fujeirah",
//       "Longitude": 25.112199783325195,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dubai",
//       "Type": "airport"
//     },
//     {
//       "ID": 2190,
//       "Airport Code [ICAO]": "OMRK",
//       "Airport Code iATA,FAA": "RKT",
//       "Airport Name": "Ras Al Khaimah International Airport",
//       "Altitude (Ft)": 102,
//       "Country": "United Arab Emirates",
//       "Latitude": 55.93880081176758,
//       "Location": "Ras Al Khaimah",
//       "Longitude": 25.613500595092773,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dubai",
//       "Type": "airport"
//     },
//     {
//       "ID": 2191,
//       "Airport Code [ICAO]": "OMSJ",
//       "Airport Code iATA,FAA": "SHJ",
//       "Airport Name": "Sharjah International Airport",
//       "Altitude (Ft)": 111,
//       "Country": "United Arab Emirates",
//       "Latitude": 55.5172004699707,
//       "Location": "Sharjah",
//       "Longitude": 25.32859992980957,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dubai",
//       "Type": "airport"
//     },
//     {
//       "ID": 2192,
//       "Airport Code [ICAO]": "OOKB",
//       "Airport Code iATA,FAA": "KHS",
//       "Airport Name": "Khasab Air Base",
//       "Altitude (Ft)": 100,
//       "Country": "Oman",
//       "Latitude": 56.2406005859375,
//       "Location": "Khasab",
//       "Longitude": 26.17099952697754,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Asia/Muscat",
//       "Type": "airport"
//     },
//     {
//       "ID": 2193,
//       "Airport Code [ICAO]": "OOMA",
//       "Airport Code iATA,FAA": "MSH",
//       "Airport Name": "Masirah Air Base",
//       "Altitude (Ft)": 64,
//       "Country": "Oman",
//       "Latitude": 58.890499114990234,
//       "Location": "Masirah",
//       "Longitude": 20.675399780273438,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Asia/Muscat",
//       "Type": "airport"
//     },
//     {
//       "ID": 2194,
//       "Airport Code [ICAO]": "OOMS",
//       "Airport Code iATA,FAA": "MCT",
//       "Airport Name": "Muscat International Airport",
//       "Altitude (Ft)": 48,
//       "Country": "Oman",
//       "Latitude": 58.284400939941406,
//       "Location": "Muscat",
//       "Longitude": 23.593299865722656,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Asia/Muscat",
//       "Type": "airport"
//     },
//     {
//       "ID": 2195,
//       "Airport Code [ICAO]": "OOSA",
//       "Airport Code iATA,FAA": "SLL",
//       "Airport Name": "Salalah Airport",
//       "Altitude (Ft)": 73,
//       "Country": "Oman",
//       "Latitude": 54.09130096435547,
//       "Location": "Salalah",
//       "Longitude": 17.038700103759766,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Asia/Muscat",
//       "Type": "airport"
//     },
//     {
//       "ID": 2196,
//       "Airport Code [ICAO]": "OOTH",
//       "Airport Code iATA,FAA": "TTH",
//       "Airport Name": "Thumrait Air Base",
//       "Altitude (Ft)": 1570,
//       "Country": "Oman",
//       "Latitude": 54.024600982666016,
//       "Location": "Thumrait",
//       "Longitude": 17.666000366210938,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Asia/Muscat",
//       "Type": "airport"
//     },
//     {
//       "ID": 2197,
//       "Airport Code [ICAO]": "OPBG",
//       "Airport Code iATA,FAA": "BHW",
//       "Airport Name": "Bhagatanwala Airport",
//       "Altitude (Ft)": 600,
//       "Country": "Pakistan",
//       "Latitude": 72.94840240479999,
//       "Location": "Bhagtanwala",
//       "Longitude": 32.056098938,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2202,
//       "Airport Code [ICAO]": "OPFA",
//       "Airport Code iATA,FAA": "LYP",
//       "Airport Name": "Faisalabad International Airport",
//       "Altitude (Ft)": 591,
//       "Country": "Pakistan",
//       "Latitude": 72.99479675292969,
//       "Location": "Faisalabad",
//       "Longitude": 31.364999771118164,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2203,
//       "Airport Code [ICAO]": "OPGD",
//       "Airport Code iATA,FAA": "GWD",
//       "Airport Name": "Gwadar International Airport",
//       "Altitude (Ft)": 36,
//       "Country": "Pakistan",
//       "Latitude": 62.329498291015625,
//       "Location": "Gwadar",
//       "Longitude": 25.233299255371094,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2204,
//       "Airport Code [ICAO]": "OPGT",
//       "Airport Code iATA,FAA": "GIL",
//       "Airport Name": "Gilgit Airport",
//       "Altitude (Ft)": 4796,
//       "Country": "Pakistan",
//       "Latitude": 74.33360290527344,
//       "Location": "Gilgit",
//       "Longitude": 35.918800354003906,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2205,
//       "Airport Code [ICAO]": "OPJA",
//       "Airport Code iATA,FAA": "JAG",
//       "Airport Name": "Shahbaz Air Base",
//       "Altitude (Ft)": 185,
//       "Country": "Pakistan",
//       "Latitude": 68.44969940185547,
//       "Location": "Jacobsbad",
//       "Longitude": 28.28420066833496,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2206,
//       "Airport Code [ICAO]": "OPKC",
//       "Airport Code iATA,FAA": "KHI",
//       "Airport Name": "Jinnah International Airport",
//       "Altitude (Ft)": 100,
//       "Country": "Pakistan",
//       "Latitude": 67.160797,
//       "Location": "Karachi",
//       "Longitude": 24.9065,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2207,
//       "Airport Code [ICAO]": "OPLA",
//       "Airport Code iATA,FAA": "LHE",
//       "Airport Name": "Alama Iqbal International Airport",
//       "Altitude (Ft)": 712,
//       "Country": "Pakistan",
//       "Latitude": 74.40360260009766,
//       "Location": "Lahore",
//       "Longitude": 31.5216007232666,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2208,
//       "Airport Code [ICAO]": "OPLH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Walton Airport",
//       "Altitude (Ft)": 679,
//       "Country": "Pakistan",
//       "Latitude": 74.34619903564453,
//       "Location": "Lahore",
//       "Longitude": 31.494800567626953,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2209,
//       "Airport Code [ICAO]": "OPMA",
//       "Airport Code iATA,FAA": "XJM",
//       "Airport Name": "Mangla Airport",
//       "Altitude (Ft)": 902,
//       "Country": "Pakistan",
//       "Latitude": 73.63839721679688,
//       "Location": "Mangla",
//       "Longitude": 33.05009841918945,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2210,
//       "Airport Code [ICAO]": "OPMF",
//       "Airport Code iATA,FAA": "MFG",
//       "Airport Name": "Muzaffarabad Airport",
//       "Altitude (Ft)": 2691,
//       "Country": "Pakistan",
//       "Latitude": 73.50859832763672,
//       "Location": "Muzaffarabad",
//       "Longitude": 34.3390007019043,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2211,
//       "Airport Code [ICAO]": "OPMI",
//       "Airport Code iATA,FAA": "MWD",
//       "Airport Name": "Mianwali Air Base",
//       "Altitude (Ft)": 690,
//       "Country": "Pakistan",
//       "Latitude": 71.5707015991211,
//       "Location": "Mianwali",
//       "Longitude": 32.5630989074707,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2212,
//       "Airport Code [ICAO]": "OPMJ",
//       "Airport Code iATA,FAA": "MJD",
//       "Airport Name": "Moenjodaro Airport",
//       "Altitude (Ft)": 154,
//       "Country": "Pakistan",
//       "Latitude": 68.14309692382812,
//       "Location": "Moenjodaro",
//       "Longitude": 27.3351993560791,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2213,
//       "Airport Code [ICAO]": "OPMR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Masroor Air Base",
//       "Altitude (Ft)": 35,
//       "Country": "Pakistan",
//       "Latitude": 66.93879699707031,
//       "Location": "Karachi",
//       "Longitude": 24.893600463867188,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2214,
//       "Airport Code [ICAO]": "OPMT",
//       "Airport Code iATA,FAA": "MUX",
//       "Airport Name": "Multan International Airport",
//       "Altitude (Ft)": 403,
//       "Country": "Pakistan",
//       "Latitude": 71.41909790039062,
//       "Location": "Multan",
//       "Longitude": 30.20319938659668,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2215,
//       "Airport Code [ICAO]": "OPNH",
//       "Airport Code iATA,FAA": "WNS",
//       "Airport Name": "Shaheed Benazirabad Airport",
//       "Altitude (Ft)": 95,
//       "Country": "Pakistan",
//       "Latitude": 68.390099,
//       "Location": "Nawabshah",
//       "Longitude": 26.2194,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2216,
//       "Airport Code [ICAO]": "OPOK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Okara Cantonment Airstrip",
//       "Altitude (Ft)": 568,
//       "Country": "Pakistan",
//       "Latitude": 73.3576965332,
//       "Location": "Okara",
//       "Longitude": 30.740999221800003,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2217,
//       "Airport Code [ICAO]": "OPPG",
//       "Airport Code iATA,FAA": "PJG",
//       "Airport Name": "Panjgur Airport",
//       "Altitude (Ft)": 3289,
//       "Country": "Pakistan",
//       "Latitude": 64.13249969482422,
//       "Location": "Panjgur",
//       "Longitude": 26.954500198364258,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2218,
//       "Airport Code [ICAO]": "OPPI",
//       "Airport Code iATA,FAA": "PSI",
//       "Airport Name": "Pasni Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Pakistan",
//       "Latitude": 63.34510040283203,
//       "Location": "Pasni",
//       "Longitude": 25.29050064086914,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2219,
//       "Airport Code [ICAO]": "OPPS",
//       "Airport Code iATA,FAA": "PEW",
//       "Airport Name": "Peshawar International Airport",
//       "Altitude (Ft)": 1158,
//       "Country": "Pakistan",
//       "Latitude": 71.51460266113281,
//       "Location": "Peshawar",
//       "Longitude": 33.993900299072266,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2220,
//       "Airport Code [ICAO]": "OPQS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Qasim Airport",
//       "Altitude (Ft)": 1581,
//       "Country": "Pakistan",
//       "Latitude": 73.033203125,
//       "Location": "Qasim",
//       "Longitude": 33.56019973754883,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2221,
//       "Airport Code [ICAO]": "OPQT",
//       "Airport Code iATA,FAA": "UET",
//       "Airport Name": "Quetta International Airport",
//       "Altitude (Ft)": 5267,
//       "Country": "Pakistan",
//       "Latitude": 66.93779754638672,
//       "Location": "Quetta",
//       "Longitude": 30.251399993896484,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2222,
//       "Airport Code [ICAO]": "OPRK",
//       "Airport Code iATA,FAA": "RYK",
//       "Airport Name": "Shaikh Zaid Airport",
//       "Altitude (Ft)": 271,
//       "Country": "Pakistan",
//       "Latitude": 70.27960205078125,
//       "Location": "Rahim Yar Khan",
//       "Longitude": 28.383899688720703,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2223,
//       "Airport Code [ICAO]": "OPRN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Benazir Bhutto International Airport",
//       "Altitude (Ft)": 1668,
//       "Country": "Pakistan",
//       "Latitude": 73.099197,
//       "Location": "Islamabad",
//       "Longitude": 33.616699,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2224,
//       "Airport Code [ICAO]": "OPRS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Risalpur Air Base",
//       "Altitude (Ft)": 1050,
//       "Country": "Pakistan",
//       "Latitude": 71.97260284423828,
//       "Location": "Risalpur",
//       "Longitude": 34.08110046386719,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2225,
//       "Airport Code [ICAO]": "OPRT",
//       "Airport Code iATA,FAA": "RAZ",
//       "Airport Name": "Rawalakot Airport",
//       "Altitude (Ft)": 5479,
//       "Country": "Pakistan",
//       "Latitude": 73.79810333251953,
//       "Location": "Rawala Kot",
//       "Longitude": 33.849700927734375,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2227,
//       "Airport Code [ICAO]": "OPSK",
//       "Airport Code iATA,FAA": "SKZ",
//       "Airport Name": "Sukkur Airport",
//       "Altitude (Ft)": 196,
//       "Country": "Pakistan",
//       "Latitude": 68.79170227050781,
//       "Location": "Sukkur",
//       "Longitude": 27.722000122070312,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2228,
//       "Airport Code [ICAO]": "OPSS",
//       "Airport Code iATA,FAA": "SDT",
//       "Airport Name": "Saidu Sharif Airport",
//       "Altitude (Ft)": 3183,
//       "Country": "Pakistan",
//       "Latitude": 72.35279846191406,
//       "Location": "Saidu Sharif",
//       "Longitude": 34.8135986328125,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2229,
//       "Airport Code [ICAO]": "OPSU",
//       "Airport Code iATA,FAA": "SUL",
//       "Airport Name": "Sui Airport",
//       "Altitude (Ft)": 763,
//       "Country": "Pakistan",
//       "Latitude": 69.1769027709961,
//       "Location": "Sui",
//       "Longitude": 28.645099639892578,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2230,
//       "Airport Code [ICAO]": "OPTH",
//       "Airport Code iATA,FAA": "BDN",
//       "Airport Name": "Talhar Airport",
//       "Altitude (Ft)": 28,
//       "Country": "Pakistan",
//       "Latitude": 68.8384017944336,
//       "Location": "Talhar",
//       "Longitude": 24.84149932861328,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2232,
//       "Airport Code [ICAO]": "OPWN",
//       "Airport Code iATA,FAA": "WAF",
//       "Airport Name": "Wana Airport",
//       "Altitude (Ft)": 4550,
//       "Country": "Pakistan",
//       "Latitude": 69.57039642333984,
//       "Location": "Wana",
//       "Longitude": 32.3046989440918,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2233,
//       "Airport Code [ICAO]": "OPZB",
//       "Airport Code iATA,FAA": "PZH",
//       "Airport Name": "Zhob Airport",
//       "Altitude (Ft)": 4728,
//       "Country": "Pakistan",
//       "Latitude": 69.4636001586914,
//       "Location": "Zhob",
//       "Longitude": 31.358400344848633,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 2234,
//       "Airport Code [ICAO]": "ORMM",
//       "Airport Code iATA,FAA": "BSR",
//       "Airport Name": "Basrah International Airport",
//       "Altitude (Ft)": 11,
//       "Country": "Iraq",
//       "Latitude": 47.66210174560547,
//       "Location": "Basrah",
//       "Longitude": 30.549100875854492,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Baghdad",
//       "Type": "airport"
//     },
//     {
//       "ID": 2235,
//       "Airport Code [ICAO]": "OSAP",
//       "Airport Code iATA,FAA": "ALP",
//       "Airport Name": "Aleppo International Airport",
//       "Altitude (Ft)": 1276,
//       "Country": "Syria",
//       "Latitude": 37.22439956665039,
//       "Location": "Aleppo",
//       "Longitude": 36.18069839477539,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Damascus",
//       "Type": "airport"
//     },
//     {
//       "ID": 2236,
//       "Airport Code [ICAO]": "OSDI",
//       "Airport Code iATA,FAA": "DAM",
//       "Airport Name": "Damascus International Airport",
//       "Altitude (Ft)": 2020,
//       "Country": "Syria",
//       "Latitude": 36.51559829711914,
//       "Location": "Damascus",
//       "Longitude": 33.4114990234375,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Damascus",
//       "Type": "airport"
//     },
//     {
//       "ID": 2237,
//       "Airport Code [ICAO]": "OSDZ",
//       "Airport Code iATA,FAA": "DEZ",
//       "Airport Name": "Deir ez-Zor Air Base",
//       "Altitude (Ft)": 700,
//       "Country": "Syria",
//       "Latitude": 40.175999,
//       "Location": "Deire Zor",
//       "Longitude": 35.2854,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Damascus",
//       "Type": "airport"
//     },
//     {
//       "ID": 2239,
//       "Airport Code [ICAO]": "OSLK",
//       "Airport Code iATA,FAA": "LTK",
//       "Airport Name": "Bassel Al-Assad International Airport",
//       "Altitude (Ft)": 157,
//       "Country": "Syria",
//       "Latitude": 35.948699951171875,
//       "Location": "Latakia",
//       "Longitude": 35.401100158691406,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Damascus",
//       "Type": "airport"
//     },
//     {
//       "ID": 2240,
//       "Airport Code [ICAO]": "OSPR",
//       "Airport Code iATA,FAA": "PMS",
//       "Airport Name": "Palmyra Airport",
//       "Altitude (Ft)": 1322,
//       "Country": "Syria",
//       "Latitude": 38.316898,
//       "Location": "Palmyra",
//       "Longitude": 34.5574,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Damascus",
//       "Type": "airport"
//     },
//     {
//       "ID": 2241,
//       "Airport Code [ICAO]": "OTBD",
//       "Airport Code iATA,FAA": "DIA",
//       "Airport Name": "Doha International Airport",
//       "Altitude (Ft)": 35,
//       "Country": "Qatar",
//       "Latitude": 51.565102,
//       "Location": "Doha",
//       "Longitude": 25.261101,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Qatar",
//       "Type": "airport"
//     },
//     {
//       "ID": 2242,
//       "Airport Code [ICAO]": "PCIS",
//       "Airport Code iATA,FAA": "CIS",
//       "Airport Name": "Canton Island Airport",
//       "Altitude (Ft)": 9,
//       "Country": "Kiribati",
//       "Latitude": -171.710006714,
//       "Location": "Canton Island",
//       "Longitude": -2.7681200504300003,
//       "GMT Offset": "13",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Enderbury",
//       "Type": "airport"
//     },
//     {
//       "ID": 2243,
//       "Airport Code [ICAO]": "PGRO",
//       "Airport Code iATA,FAA": "ROP",
//       "Airport Name": "Rota International Airport",
//       "Altitude (Ft)": 607,
//       "Country": "Northern Mariana Islands",
//       "Latitude": 145.2429962158203,
//       "Location": "Rota",
//       "Longitude": 14.174300193786621,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Saipan",
//       "Type": "airport"
//     },
//     {
//       "ID": 2244,
//       "Airport Code [ICAO]": "PGSN",
//       "Airport Code iATA,FAA": "SPN",
//       "Airport Name": "Saipan International Airport",
//       "Altitude (Ft)": 215,
//       "Country": "Northern Mariana Islands",
//       "Latitude": 145.729004,
//       "Location": "Saipan",
//       "Longitude": 15.119,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Saipan",
//       "Type": "airport"
//     },
//     {
//       "ID": 2245,
//       "Airport Code [ICAO]": "PGUA",
//       "Airport Code iATA,FAA": "UAM",
//       "Airport Name": "Andersen Air Force Base",
//       "Altitude (Ft)": 627,
//       "Country": "Guam",
//       "Latitude": 144.929998,
//       "Location": "Andersen",
//       "Longitude": 13.584,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Guam",
//       "Type": "airport"
//     },
//     {
//       "ID": 2246,
//       "Airport Code [ICAO]": "PGUM",
//       "Airport Code iATA,FAA": "GUM",
//       "Airport Name": "Antonio B. Won Pat International Airport",
//       "Altitude (Ft)": 298,
//       "Country": "Guam",
//       "Latitude": 144.796005249,
//       "Location": "Agana",
//       "Longitude": 13.4834003448,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Guam",
//       "Type": "airport"
//     },
//     {
//       "ID": 2247,
//       "Airport Code [ICAO]": "PGWT",
//       "Airport Code iATA,FAA": "TIQ",
//       "Airport Name": "Tinian International Airport",
//       "Altitude (Ft)": 271,
//       "Country": "Northern Mariana Islands",
//       "Latitude": 145.61900329589844,
//       "Location": "West Tinian",
//       "Longitude": 14.999199867248535,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Saipan",
//       "Type": "airport"
//     },
//     {
//       "ID": 2249,
//       "Airport Code [ICAO]": "PKMJ",
//       "Airport Code iATA,FAA": "MAJ",
//       "Airport Name": "Marshall Islands International Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Marshall Islands",
//       "Latitude": 171.27200317382812,
//       "Location": "Majuro",
//       "Longitude": 7.064760208129883,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Majuro",
//       "Type": "airport"
//     },
//     {
//       "ID": 2250,
//       "Airport Code [ICAO]": "PKRO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Dyess Army Air Field",
//       "Altitude (Ft)": 9,
//       "Country": "Marshall Islands",
//       "Latitude": 167.470993042,
//       "Location": "Kwajalein",
//       "Longitude": 9.396889686580002,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Majuro",
//       "Type": "airport"
//     },
//     {
//       "ID": 2251,
//       "Airport Code [ICAO]": "PKWA",
//       "Airport Code iATA,FAA": "KWA",
//       "Airport Name": "Bucholz Army Air Field",
//       "Altitude (Ft)": 9,
//       "Country": "Marshall Islands",
//       "Latitude": 167.73199462890625,
//       "Location": "Kwajalein",
//       "Longitude": 8.720120429992676,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Majuro",
//       "Type": "airport"
//     },
//     {
//       "ID": 2252,
//       "Airport Code [ICAO]": "PLCH",
//       "Airport Code iATA,FAA": "CXI",
//       "Airport Name": "Cassidy International Airport",
//       "Altitude (Ft)": 5,
//       "Country": "Kiribati",
//       "Latitude": -157.35000610351562,
//       "Location": "Kiritimati",
//       "Longitude": 1.9861600399017334,
//       "GMT Offset": "-12",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 2253,
//       "Airport Code [ICAO]": "PMDY",
//       "Airport Code iATA,FAA": "MDY",
//       "Airport Name": "Henderson Field",
//       "Altitude (Ft)": 13,
//       "Country": "Midway Islands",
//       "Latitude": -177.38099670410156,
//       "Location": "Midway",
//       "Longitude": 28.20170021057129,
//       "GMT Offset": "-11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Midway",
//       "Type": "airport"
//     },
//     {
//       "ID": 2254,
//       "Airport Code [ICAO]": "PTKK",
//       "Airport Code iATA,FAA": "TKK",
//       "Airport Name": "Chuuk International Airport",
//       "Altitude (Ft)": 11,
//       "Country": "Micronesia",
//       "Latitude": 151.84300231933594,
//       "Location": "Chuuk",
//       "Longitude": 7.461870193481445,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Truk",
//       "Type": "airport"
//     },
//     {
//       "ID": 2255,
//       "Airport Code [ICAO]": "PTPN",
//       "Airport Code iATA,FAA": "PNI",
//       "Airport Name": "Pohnpei International Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Micronesia",
//       "Latitude": 158.20899963378906,
//       "Location": "Pohnpei",
//       "Longitude": 6.985099792480469,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Ponape",
//       "Type": "airport"
//     },
//     {
//       "ID": 2256,
//       "Airport Code [ICAO]": "PTRO",
//       "Airport Code iATA,FAA": "ROR",
//       "Airport Name": "Babelthuap Airport",
//       "Altitude (Ft)": 176,
//       "Country": "Palau",
//       "Latitude": 134.544236,
//       "Location": "Babelthuap",
//       "Longitude": 7.36731,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Palau",
//       "Type": "airport"
//     },
//     {
//       "ID": 2257,
//       "Airport Code [ICAO]": "PTSA",
//       "Airport Code iATA,FAA": "KSA",
//       "Airport Name": "Kosrae International Airport",
//       "Altitude (Ft)": 11,
//       "Country": "Micronesia",
//       "Latitude": 162.957993,
//       "Location": "Kosrae",
//       "Longitude": 5.35698,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Kosrae",
//       "Type": "airport"
//     },
//     {
//       "ID": 2258,
//       "Airport Code [ICAO]": "PTYA",
//       "Airport Code iATA,FAA": "YAP",
//       "Airport Name": "Yap International Airport",
//       "Altitude (Ft)": 91,
//       "Country": "Micronesia",
//       "Latitude": 138.082993,
//       "Location": "Yap",
//       "Longitude": 9.49891,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Truk",
//       "Type": "airport"
//     },
//     {
//       "ID": 2259,
//       "Airport Code [ICAO]": "RCBS",
//       "Airport Code iATA,FAA": "KNH",
//       "Airport Name": "Kinmen Airport",
//       "Altitude (Ft)": 93,
//       "Country": "Taiwan",
//       "Latitude": 118.359001,
//       "Location": "Kinmen",
//       "Longitude": 24.4279,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Taipei",
//       "Type": "airport"
//     },
//     {
//       "ID": 2260,
//       "Airport Code [ICAO]": "RCDC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Pingtung South Airport",
//       "Altitude (Ft)": 78,
//       "Country": "Taiwan",
//       "Latitude": 120.46199798583984,
//       "Location": "Pingtung",
//       "Longitude": 22.672399520874023,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Taipei",
//       "Type": "airport"
//     },
//     {
//       "ID": 2261,
//       "Airport Code [ICAO]": "RCDI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Longtan Air Base",
//       "Altitude (Ft)": 790,
//       "Country": "Taiwan",
//       "Latitude": 121.23799896240234,
//       "Location": "Longtang",
//       "Longitude": 24.855100631713867,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Taipei",
//       "Type": "airport"
//     },
//     {
//       "ID": 2262,
//       "Airport Code [ICAO]": "RCFN",
//       "Airport Code iATA,FAA": "TTT",
//       "Airport Name": "Taitung Airport",
//       "Altitude (Ft)": 143,
//       "Country": "Taiwan",
//       "Latitude": 121.10199737548828,
//       "Location": "Fengnin",
//       "Longitude": 22.7549991607666,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Taipei",
//       "Type": "airport"
//     },
//     {
//       "ID": 2263,
//       "Airport Code [ICAO]": "RCGI",
//       "Airport Code iATA,FAA": "GNI",
//       "Airport Name": "Lyudao Airport",
//       "Altitude (Ft)": 28,
//       "Country": "Taiwan",
//       "Latitude": 121.46600341796875,
//       "Location": "Green Island",
//       "Longitude": 22.673900604248047,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Taipei",
//       "Type": "airport"
//     },
//     {
//       "ID": 2264,
//       "Airport Code [ICAO]": "RCKH",
//       "Airport Code iATA,FAA": "KHH",
//       "Airport Name": "Kaohsiung International Airport",
//       "Altitude (Ft)": 31,
//       "Country": "Taiwan",
//       "Latitude": 120.3499984741211,
//       "Location": "Kaohsiung",
//       "Longitude": 22.57710075378418,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Taipei",
//       "Type": "airport"
//     },
//     {
//       "ID": 2265,
//       "Airport Code [ICAO]": "RCKU",
//       "Airport Code iATA,FAA": "CYI",
//       "Airport Name": "Chiayi Airport",
//       "Altitude (Ft)": 85,
//       "Country": "Taiwan",
//       "Latitude": 120.39299774169922,
//       "Location": "Chiayi",
//       "Longitude": 23.46179962158203,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Taipei",
//       "Type": "airport"
//     },
//     {
//       "ID": 2267,
//       "Airport Code [ICAO]": "RCLY",
//       "Airport Code iATA,FAA": "KYD",
//       "Airport Name": "Lanyu Airport",
//       "Altitude (Ft)": 44,
//       "Country": "Taiwan",
//       "Latitude": 121.53500366210938,
//       "Location": "Lanyu",
//       "Longitude": 22.027000427246094,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Taipei",
//       "Type": "airport"
//     },
//     {
//       "ID": 2268,
//       "Airport Code [ICAO]": "RCMQ",
//       "Airport Code iATA,FAA": "RMQ",
//       "Airport Name": "Taichung Ching Chuang Kang Airport",
//       "Altitude (Ft)": 663,
//       "Country": "Taiwan",
//       "Latitude": 120.62100219726562,
//       "Location": "Taichung",
//       "Longitude": 24.264699935913086,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Taipei",
//       "Type": "airport"
//     },
//     {
//       "ID": 2270,
//       "Airport Code [ICAO]": "RCNN",
//       "Airport Code iATA,FAA": "TNN",
//       "Airport Name": "Tainan Airport",
//       "Altitude (Ft)": 63,
//       "Country": "Taiwan",
//       "Latitude": 120.20600128173828,
//       "Location": "Tainan",
//       "Longitude": 22.95039939880371,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Taipei",
//       "Type": "airport"
//     },
//     {
//       "ID": 2271,
//       "Airport Code [ICAO]": "RCPO",
//       "Airport Code iATA,FAA": "HSZ",
//       "Airport Name": "Hsinchu Air Base",
//       "Altitude (Ft)": 26,
//       "Country": "Taiwan",
//       "Latitude": 120.939002991,
//       "Location": "Hsinchu",
//       "Longitude": 24.8180007935,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Taipei",
//       "Type": "airport"
//     },
//     {
//       "ID": 2272,
//       "Airport Code [ICAO]": "RCQC",
//       "Airport Code iATA,FAA": "MZG",
//       "Airport Name": "Makung Airport",
//       "Altitude (Ft)": 103,
//       "Country": "Taiwan",
//       "Latitude": 119.62799835205078,
//       "Location": "Makung",
//       "Longitude": 23.568700790405273,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Taipei",
//       "Type": "airport"
//     },
//     {
//       "ID": 2273,
//       "Airport Code [ICAO]": "RCQS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Chihhang Air Base",
//       "Altitude (Ft)": 121,
//       "Country": "Taiwan",
//       "Latitude": 121.18199920654297,
//       "Location": "Taitung",
//       "Longitude": 22.793100357055664,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Taipei",
//       "Type": "airport"
//     },
//     {
//       "ID": 2274,
//       "Airport Code [ICAO]": "RCSQ",
//       "Airport Code iATA,FAA": "PIF",
//       "Airport Name": "Pingtung North Airport",
//       "Altitude (Ft)": 97,
//       "Country": "Taiwan",
//       "Latitude": 120.48200225830078,
//       "Location": "Pingtung",
//       "Longitude": 22.700199127197266,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Taipei",
//       "Type": "airport"
//     },
//     {
//       "ID": 2275,
//       "Airport Code [ICAO]": "RCSS",
//       "Airport Code iATA,FAA": "TSA",
//       "Airport Name": "Taipei Songshan Airport",
//       "Altitude (Ft)": 18,
//       "Country": "Taiwan",
//       "Latitude": 121.552001953125,
//       "Location": "Taipei",
//       "Longitude": 25.069400787353516,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Taipei",
//       "Type": "airport"
//     },
//     {
//       "ID": 2276,
//       "Airport Code [ICAO]": "RCTP",
//       "Airport Code iATA,FAA": "TPE",
//       "Airport Name": "Taiwan Taoyuan International Airport",
//       "Altitude (Ft)": 106,
//       "Country": "Taiwan",
//       "Latitude": 121.233002,
//       "Location": "Taipei",
//       "Longitude": 25.0777,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Taipei",
//       "Type": "airport"
//     },
//     {
//       "ID": 2277,
//       "Airport Code [ICAO]": "RCWA",
//       "Airport Code iATA,FAA": "WOT",
//       "Airport Name": "Wang-an Airport",
//       "Altitude (Ft)": 115,
//       "Country": "Taiwan",
//       "Latitude": 119.50277709960938,
//       "Location": "Wang An",
//       "Longitude": 23.367372512817383,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Taipei",
//       "Type": "airport"
//     },
//     {
//       "ID": 2278,
//       "Airport Code [ICAO]": "RCYU",
//       "Airport Code iATA,FAA": "HUN",
//       "Airport Name": "Hualien Airport",
//       "Altitude (Ft)": 52,
//       "Country": "Taiwan",
//       "Latitude": 121.61799621582031,
//       "Location": "Hualien",
//       "Longitude": 24.023099899291992,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Taipei",
//       "Type": "airport"
//     },
//     {
//       "ID": 2279,
//       "Airport Code [ICAO]": "RJAA",
//       "Airport Code iATA,FAA": "NRT",
//       "Airport Name": "Narita International Airport",
//       "Altitude (Ft)": 141,
//       "Country": "Japan",
//       "Latitude": 140.386001587,
//       "Location": "Tokyo",
//       "Longitude": 35.7647018433,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2280,
//       "Airport Code [ICAO]": "RJAF",
//       "Airport Code iATA,FAA": "MMJ",
//       "Airport Name": "Matsumoto Airport",
//       "Altitude (Ft)": 2182,
//       "Country": "Japan",
//       "Latitude": 137.92300415039062,
//       "Location": "Matsumoto",
//       "Longitude": 36.16680145263672,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2281,
//       "Airport Code [ICAO]": "RJAH",
//       "Airport Code iATA,FAA": "IBR",
//       "Airport Name": "Hyakuri Airport",
//       "Altitude (Ft)": 105,
//       "Country": "Japan",
//       "Latitude": 140.414993286,
//       "Location": "Ibaraki",
//       "Longitude": 36.181098938,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2282,
//       "Airport Code [ICAO]": "RJAM",
//       "Airport Code iATA,FAA": "MUS",
//       "Airport Name": "Minami Torishima Airport",
//       "Altitude (Ft)": 22,
//       "Country": "Japan",
//       "Latitude": 153.979003906,
//       "Location": "Minami Tori Shima",
//       "Longitude": 24.2896995544,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2283,
//       "Airport Code [ICAO]": "RJAW",
//       "Airport Code iATA,FAA": "IWO",
//       "Airport Name": "Iwo Jima Airport",
//       "Altitude (Ft)": 384,
//       "Country": "Japan",
//       "Latitude": 141.322998046875,
//       "Location": "Iwojima",
//       "Longitude": 24.784000396728516,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2284,
//       "Airport Code [ICAO]": "RJBD",
//       "Airport Code iATA,FAA": "SHM",
//       "Airport Name": "Nanki Shirahama Airport",
//       "Altitude (Ft)": 298,
//       "Country": "Japan",
//       "Latitude": 135.363998413,
//       "Location": "Nanki-shirahama",
//       "Longitude": 33.6622009277,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2285,
//       "Airport Code [ICAO]": "RJBK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kohnan Airport",
//       "Altitude (Ft)": 3,
//       "Country": "Japan",
//       "Latitude": 133.93299865722656,
//       "Location": "Kohnan",
//       "Longitude": 34.59080123901367,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2286,
//       "Airport Code [ICAO]": "RJCB",
//       "Airport Code iATA,FAA": "OBO",
//       "Airport Name": "Tokachi-Obihiro Airport",
//       "Altitude (Ft)": 505,
//       "Country": "Japan",
//       "Latitude": 143.216995239,
//       "Location": "Obihiro",
//       "Longitude": 42.7332992554,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2287,
//       "Airport Code [ICAO]": "RJCC",
//       "Airport Code iATA,FAA": "CTS",
//       "Airport Name": "New Chitose Airport",
//       "Altitude (Ft)": 82,
//       "Country": "Japan",
//       "Latitude": 141.69200134277344,
//       "Location": "Sapporo",
//       "Longitude": 42.77519989013672,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2288,
//       "Airport Code [ICAO]": "RJCH",
//       "Airport Code iATA,FAA": "HKD",
//       "Airport Name": "Hakodate Airport",
//       "Altitude (Ft)": 151,
//       "Country": "Japan",
//       "Latitude": 140.822006226,
//       "Location": "Hakodate",
//       "Longitude": 41.7700004578,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2289,
//       "Airport Code [ICAO]": "RJCJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Chitose Air Base",
//       "Altitude (Ft)": 87,
//       "Country": "Japan",
//       "Latitude": 141.666000366,
//       "Location": "Chitose",
//       "Longitude": 42.79449844359999,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2290,
//       "Airport Code [ICAO]": "RJCM",
//       "Airport Code iATA,FAA": "MMB",
//       "Airport Name": "Memanbetsu Airport",
//       "Altitude (Ft)": 135,
//       "Country": "Japan",
//       "Latitude": 144.164001465,
//       "Location": "Memanbetsu",
//       "Longitude": 43.8805999756,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2291,
//       "Airport Code [ICAO]": "RJCN",
//       "Airport Code iATA,FAA": "SHB",
//       "Airport Name": "Nakashibetsu Airport",
//       "Altitude (Ft)": 234,
//       "Country": "Japan",
//       "Latitude": 144.960006714,
//       "Location": "Nakashibetsu",
//       "Longitude": 43.5774993896,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2293,
//       "Airport Code [ICAO]": "RJCT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tokachi Airport",
//       "Altitude (Ft)": 281,
//       "Country": "Japan",
//       "Latitude": 143.1580047607422,
//       "Location": "Tokachi",
//       "Longitude": 42.890499114990234,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2294,
//       "Airport Code [ICAO]": "RJCW",
//       "Airport Code iATA,FAA": "WKJ",
//       "Airport Name": "Wakkanai Airport",
//       "Altitude (Ft)": 30,
//       "Country": "Japan",
//       "Latitude": 141.800994873,
//       "Location": "Wakkanai",
//       "Longitude": 45.4042015076,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2295,
//       "Airport Code [ICAO]": "RJDB",
//       "Airport Code iATA,FAA": "IKI",
//       "Airport Name": "Iki Airport",
//       "Altitude (Ft)": 41,
//       "Country": "Japan",
//       "Latitude": 129.785003662,
//       "Location": "Iki",
//       "Longitude": 33.7490005493,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2296,
//       "Airport Code [ICAO]": "RJDC",
//       "Airport Code iATA,FAA": "UBJ",
//       "Airport Name": "Yamaguchi Ube Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Japan",
//       "Latitude": 131.279006958,
//       "Location": "Yamaguchi",
//       "Longitude": 33.930000305200004,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2297,
//       "Airport Code [ICAO]": "RJDT",
//       "Airport Code iATA,FAA": "TSJ",
//       "Airport Name": "Tsushima Airport",
//       "Altitude (Ft)": 213,
//       "Country": "Japan",
//       "Latitude": 129.330993652,
//       "Location": "Tsushima",
//       "Longitude": 34.2849006653,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2298,
//       "Airport Code [ICAO]": "RJEB",
//       "Airport Code iATA,FAA": "MBE",
//       "Airport Name": "Monbetsu Airport",
//       "Altitude (Ft)": 80,
//       "Country": "Japan",
//       "Latitude": 143.404006958,
//       "Location": "Monbetsu",
//       "Longitude": 44.303901672399995,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2299,
//       "Airport Code [ICAO]": "RJEC",
//       "Airport Code iATA,FAA": "AKJ",
//       "Airport Name": "Asahikawa Airport",
//       "Altitude (Ft)": 721,
//       "Country": "Japan",
//       "Latitude": 142.44700622558594,
//       "Location": "Asahikawa",
//       "Longitude": 43.670799255371094,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2300,
//       "Airport Code [ICAO]": "RJEO",
//       "Airport Code iATA,FAA": "OIR",
//       "Airport Name": "Okushiri Airport",
//       "Altitude (Ft)": 161,
//       "Country": "Japan",
//       "Latitude": 139.432998657,
//       "Location": "Okushiri",
//       "Longitude": 42.0717010498,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2301,
//       "Airport Code [ICAO]": "RJER",
//       "Airport Code iATA,FAA": "RIS",
//       "Airport Name": "Rishiri Airport",
//       "Altitude (Ft)": 112,
//       "Country": "Japan",
//       "Latitude": 141.186004639,
//       "Location": "Rishiri Island",
//       "Longitude": 45.2420005798,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2302,
//       "Airport Code [ICAO]": "RJFA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ashiya Airport",
//       "Altitude (Ft)": 98,
//       "Country": "Japan",
//       "Latitude": 130.6529998779297,
//       "Location": "Ashiya",
//       "Longitude": 33.88309860229492,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2303,
//       "Airport Code [ICAO]": "RJFC",
//       "Airport Code iATA,FAA": "KUM",
//       "Airport Name": "Yakushima Airport",
//       "Altitude (Ft)": 124,
//       "Country": "Japan",
//       "Latitude": 130.658996582,
//       "Location": "Yakushima",
//       "Longitude": 30.3855991364,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2304,
//       "Airport Code [ICAO]": "RJFE",
//       "Airport Code iATA,FAA": "FUJ",
//       "Airport Name": "Fukue Airport",
//       "Altitude (Ft)": 273,
//       "Country": "Japan",
//       "Latitude": 128.83299255371094,
//       "Location": "Fukue",
//       "Longitude": 32.66630172729492,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2305,
//       "Airport Code [ICAO]": "RJFF",
//       "Airport Code iATA,FAA": "FUK",
//       "Airport Name": "Fukuoka Airport",
//       "Altitude (Ft)": 32,
//       "Country": "Japan",
//       "Latitude": 130.4510040283203,
//       "Location": "Fukuoka",
//       "Longitude": 33.585899353027344,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2306,
//       "Airport Code [ICAO]": "RJFG",
//       "Airport Code iATA,FAA": "TNE",
//       "Airport Name": "New Tanegashima Airport",
//       "Altitude (Ft)": 768,
//       "Country": "Japan",
//       "Latitude": 130.990997314,
//       "Location": "Tanegashima",
//       "Longitude": 30.605100631699997,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2307,
//       "Airport Code [ICAO]": "RJFK",
//       "Airport Code iATA,FAA": "KOJ",
//       "Airport Name": "Kagoshima Airport",
//       "Altitude (Ft)": 906,
//       "Country": "Japan",
//       "Latitude": 130.718994140625,
//       "Location": "Kagoshima",
//       "Longitude": 31.80340003967285,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2308,
//       "Airport Code [ICAO]": "RJFM",
//       "Airport Code iATA,FAA": "KMI",
//       "Airport Name": "Miyazaki Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Japan",
//       "Latitude": 131.449005127,
//       "Location": "Miyazaki",
//       "Longitude": 31.877199173,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2309,
//       "Airport Code [ICAO]": "RJFN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nyutabaru Airport",
//       "Altitude (Ft)": 259,
//       "Country": "Japan",
//       "Latitude": 131.4510040283203,
//       "Location": "Nyutabaru",
//       "Longitude": 32.08359909057617,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2310,
//       "Airport Code [ICAO]": "RJFO",
//       "Airport Code iATA,FAA": "OIT",
//       "Airport Name": "Oita Airport",
//       "Altitude (Ft)": 19,
//       "Country": "Japan",
//       "Latitude": 131.736999512,
//       "Location": "Oita",
//       "Longitude": 33.479400634799994,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2311,
//       "Airport Code [ICAO]": "RJFR",
//       "Airport Code iATA,FAA": "KKJ",
//       "Airport Name": "Kitakyūshū Airport",
//       "Altitude (Ft)": 21,
//       "Country": "Japan",
//       "Latitude": 131.035003662,
//       "Location": "Kitakyushu",
//       "Longitude": 33.8459014893,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2312,
//       "Airport Code [ICAO]": "RJFT",
//       "Airport Code iATA,FAA": "KMJ",
//       "Airport Name": "Kumamoto Airport",
//       "Altitude (Ft)": 642,
//       "Country": "Japan",
//       "Latitude": 130.85499572753906,
//       "Location": "Kumamoto",
//       "Longitude": 32.83729934692383,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2313,
//       "Airport Code [ICAO]": "RJFU",
//       "Airport Code iATA,FAA": "NGS",
//       "Airport Name": "Nagasaki Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Japan",
//       "Latitude": 129.914001465,
//       "Location": "Nagasaki",
//       "Longitude": 32.916900634799994,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2314,
//       "Airport Code [ICAO]": "RJFY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kanoya Airport",
//       "Altitude (Ft)": 214,
//       "Country": "Japan",
//       "Latitude": 130.84500122070312,
//       "Location": "Kanoya",
//       "Longitude": 31.367599487304688,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2315,
//       "Airport Code [ICAO]": "RJFZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tsuiki Air Field",
//       "Altitude (Ft)": 55,
//       "Country": "Japan",
//       "Latitude": 131.039993286,
//       "Location": "Tsuiki",
//       "Longitude": 33.6850013733,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2316,
//       "Airport Code [ICAO]": "RJKA",
//       "Airport Code iATA,FAA": "ASJ",
//       "Airport Name": "Amami Airport",
//       "Altitude (Ft)": 27,
//       "Country": "Japan",
//       "Latitude": 129.71299743652344,
//       "Location": "Amami",
//       "Longitude": 28.430599212646484,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2317,
//       "Airport Code [ICAO]": "RJKB",
//       "Airport Code iATA,FAA": "OKE",
//       "Airport Name": "Okierabu Airport",
//       "Altitude (Ft)": 101,
//       "Country": "Japan",
//       "Latitude": 128.701004028,
//       "Location": "Okierabu",
//       "Longitude": 27.4255008698,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2318,
//       "Airport Code [ICAO]": "RJKN",
//       "Airport Code iATA,FAA": "TKN",
//       "Airport Name": "Tokunoshima Airport",
//       "Altitude (Ft)": 17,
//       "Country": "Japan",
//       "Latitude": 128.88099670410156,
//       "Location": "Tokunoshima",
//       "Longitude": 27.83639907836914,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2319,
//       "Airport Code [ICAO]": "RJNF",
//       "Airport Code iATA,FAA": "FKJ",
//       "Airport Name": "Fukui Airport",
//       "Altitude (Ft)": 19,
//       "Country": "Japan",
//       "Latitude": 136.223999023,
//       "Location": "Fukui",
//       "Longitude": 36.1427993774,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2320,
//       "Airport Code [ICAO]": "RJNG",
//       "Airport Code iATA,FAA": "QGU",
//       "Airport Name": "Gifu Airport",
//       "Altitude (Ft)": 128,
//       "Country": "Japan",
//       "Latitude": 136.8699951171875,
//       "Location": "Gifu",
//       "Longitude": 35.394100189208984,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2321,
//       "Airport Code [ICAO]": "RJNH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hamamatsu Airport",
//       "Altitude (Ft)": 150,
//       "Country": "Japan",
//       "Latitude": 137.7030029296875,
//       "Location": "Hamamatsu",
//       "Longitude": 34.75019836425781,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2322,
//       "Airport Code [ICAO]": "RJNK",
//       "Airport Code iATA,FAA": "KMQ",
//       "Airport Name": "Komatsu Airport",
//       "Altitude (Ft)": 36,
//       "Country": "Japan",
//       "Latitude": 136.40699768066406,
//       "Location": "Kanazawa",
//       "Longitude": 36.39459991455078,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2323,
//       "Airport Code [ICAO]": "RJNO",
//       "Airport Code iATA,FAA": "OKI",
//       "Airport Name": "Oki Airport",
//       "Altitude (Ft)": 311,
//       "Country": "Japan",
//       "Latitude": 133.3249969482422,
//       "Location": "Oki Island",
//       "Longitude": 36.18109893798828,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2324,
//       "Airport Code [ICAO]": "RJNT",
//       "Airport Code iATA,FAA": "TOY",
//       "Airport Name": "Toyama Airport",
//       "Altitude (Ft)": 95,
//       "Country": "Japan",
//       "Latitude": 137.18800354003906,
//       "Location": "Toyama",
//       "Longitude": 36.64830017089844,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2325,
//       "Airport Code [ICAO]": "RJNY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Shizuhama Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Japan",
//       "Latitude": 138.29800415039062,
//       "Location": "Yaizu",
//       "Longitude": 34.812801361083984,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2326,
//       "Airport Code [ICAO]": "RJOA",
//       "Airport Code iATA,FAA": "HIJ",
//       "Airport Name": "Hiroshima Airport",
//       "Altitude (Ft)": 1088,
//       "Country": "Japan",
//       "Latitude": 132.919006348,
//       "Location": "Hiroshima",
//       "Longitude": 34.4361000061,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2327,
//       "Airport Code [ICAO]": "RJOB",
//       "Airport Code iATA,FAA": "OKJ",
//       "Airport Name": "Okayama Airport",
//       "Altitude (Ft)": 806,
//       "Country": "Japan",
//       "Latitude": 133.854995728,
//       "Location": "Okayama",
//       "Longitude": 34.7569007874,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2328,
//       "Airport Code [ICAO]": "RJOC",
//       "Airport Code iATA,FAA": "IZO",
//       "Airport Name": "Izumo Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Japan",
//       "Latitude": 132.88999939,
//       "Location": "Izumo",
//       "Longitude": 35.4136009216,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2329,
//       "Airport Code [ICAO]": "RJOF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hofu Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Japan",
//       "Latitude": 131.5489959716797,
//       "Location": "Hofu",
//       "Longitude": 34.034698486328125,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2330,
//       "Airport Code [ICAO]": "RJOH",
//       "Airport Code iATA,FAA": "YGJ",
//       "Airport Name": "Miho Yonago Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Japan",
//       "Latitude": 133.23599243164062,
//       "Location": "Miho",
//       "Longitude": 35.4921989440918,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2332,
//       "Airport Code [ICAO]": "RJOK",
//       "Airport Code iATA,FAA": "KCZ",
//       "Airport Name": "Kōchi Ryōma Airport",
//       "Altitude (Ft)": 42,
//       "Country": "Japan",
//       "Latitude": 133.669006,
//       "Location": "Kochi",
//       "Longitude": 33.546101,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2333,
//       "Airport Code [ICAO]": "RJOM",
//       "Airport Code iATA,FAA": "MYJ",
//       "Airport Name": "Matsuyama Airport",
//       "Altitude (Ft)": 25,
//       "Country": "Japan",
//       "Latitude": 132.6999969482422,
//       "Location": "Matsuyama",
//       "Longitude": 33.82720184326172,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2334,
//       "Airport Code [ICAO]": "RJOO",
//       "Airport Code iATA,FAA": "ITM",
//       "Airport Name": "Osaka International Airport",
//       "Altitude (Ft)": 50,
//       "Country": "Japan",
//       "Latitude": 135.43800354003906,
//       "Location": "Osaka",
//       "Longitude": 34.785499572753906,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2335,
//       "Airport Code [ICAO]": "RJOR",
//       "Airport Code iATA,FAA": "TTJ",
//       "Airport Name": "Tottori Airport",
//       "Altitude (Ft)": 65,
//       "Country": "Japan",
//       "Latitude": 134.167007446,
//       "Location": "Tottori",
//       "Longitude": 35.5301017761,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2336,
//       "Airport Code [ICAO]": "RJOS",
//       "Airport Code iATA,FAA": "TKS",
//       "Airport Name": "Tokushima Airport/JMSDF Air Base",
//       "Altitude (Ft)": 26,
//       "Country": "Japan",
//       "Latitude": 134.606995,
//       "Location": "Tokushima",
//       "Longitude": 34.132801,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2337,
//       "Airport Code [ICAO]": "RJOT",
//       "Airport Code iATA,FAA": "TAK",
//       "Airport Name": "Takamatsu Airport",
//       "Altitude (Ft)": 607,
//       "Country": "Japan",
//       "Latitude": 134.01600647,
//       "Location": "Takamatsu",
//       "Longitude": 34.214199066199996,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2338,
//       "Airport Code [ICAO]": "RJOY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Yao Airport",
//       "Altitude (Ft)": 39,
//       "Country": "Japan",
//       "Latitude": 135.60299682617188,
//       "Location": "Osaka",
//       "Longitude": 34.59629821777344,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2339,
//       "Airport Code [ICAO]": "RJOZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ozuki Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Japan",
//       "Latitude": 131.052001953125,
//       "Location": "Ozuki",
//       "Longitude": 34.0452995300293,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2340,
//       "Airport Code [ICAO]": "RJSA",
//       "Airport Code iATA,FAA": "AOJ",
//       "Airport Name": "Aomori Airport",
//       "Altitude (Ft)": 664,
//       "Country": "Japan",
//       "Latitude": 140.6909942626953,
//       "Location": "Aomori",
//       "Longitude": 40.73469924926758,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2341,
//       "Airport Code [ICAO]": "RJSC",
//       "Airport Code iATA,FAA": "GAJ",
//       "Airport Name": "Yamagata Airport",
//       "Altitude (Ft)": 353,
//       "Country": "Japan",
//       "Latitude": 140.371002197,
//       "Location": "Yamagata",
//       "Longitude": 38.411899566699994,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2342,
//       "Airport Code [ICAO]": "RJSD",
//       "Airport Code iATA,FAA": "SDS",
//       "Airport Name": "Sado Airport",
//       "Altitude (Ft)": 88,
//       "Country": "Japan",
//       "Latitude": 138.414001465,
//       "Location": "Sado",
//       "Longitude": 38.0601997375,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2343,
//       "Airport Code [ICAO]": "RJSH",
//       "Airport Code iATA,FAA": "HHE",
//       "Airport Name": "Hachinohe Airport",
//       "Altitude (Ft)": 152,
//       "Country": "Japan",
//       "Latitude": 141.46600341796875,
//       "Location": "Hachinoe",
//       "Longitude": 40.556400299072266,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2344,
//       "Airport Code [ICAO]": "RJSI",
//       "Airport Code iATA,FAA": "HNA",
//       "Airport Name": "Hanamaki Airport",
//       "Altitude (Ft)": 297,
//       "Country": "Japan",
//       "Latitude": 141.13499450683594,
//       "Location": "Hanamaki",
//       "Longitude": 39.4286003112793,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2345,
//       "Airport Code [ICAO]": "RJSK",
//       "Airport Code iATA,FAA": "AXT",
//       "Airport Name": "Akita Airport",
//       "Altitude (Ft)": 313,
//       "Country": "Japan",
//       "Latitude": 140.218994140625,
//       "Location": "Akita",
//       "Longitude": 39.6156005859375,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2346,
//       "Airport Code [ICAO]": "RJSM",
//       "Airport Code iATA,FAA": "MSJ",
//       "Airport Name": "Misawa Air Base",
//       "Altitude (Ft)": 119,
//       "Country": "Japan",
//       "Latitude": 141.367996216,
//       "Location": "Misawa",
//       "Longitude": 40.703201293899994,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2347,
//       "Airport Code [ICAO]": "RJSS",
//       "Airport Code iATA,FAA": "SDJ",
//       "Airport Name": "Sendai Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Japan",
//       "Latitude": 140.917007446,
//       "Location": "Sendai",
//       "Longitude": 38.1397018433,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2348,
//       "Airport Code [ICAO]": "RJST",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Matsushima Air Base",
//       "Altitude (Ft)": 7,
//       "Country": "Japan",
//       "Latitude": 141.220001221,
//       "Location": "Matsushima",
//       "Longitude": 38.4048995972,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2350,
//       "Airport Code [ICAO]": "RJTA",
//       "Airport Code iATA,FAA": "NJA",
//       "Airport Name": "Atsugi Naval Air Facility",
//       "Altitude (Ft)": 205,
//       "Country": "Japan",
//       "Latitude": 139.4499969482422,
//       "Location": "Atsugi",
//       "Longitude": 35.4546012878418,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2351,
//       "Airport Code [ICAO]": "RJTE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tateyama Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Japan",
//       "Latitude": 139.828995,
//       "Location": "Tateyama",
//       "Longitude": 34.987099,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2352,
//       "Airport Code [ICAO]": "RJTH",
//       "Airport Code iATA,FAA": "HAC",
//       "Airport Name": "Hachijojima Airport",
//       "Altitude (Ft)": 303,
//       "Country": "Japan",
//       "Latitude": 139.785995483,
//       "Location": "Hachijojima",
//       "Longitude": 33.1150016785,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2353,
//       "Airport Code [ICAO]": "RJTJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Iruma Air Base",
//       "Altitude (Ft)": 295,
//       "Country": "Japan",
//       "Latitude": 139.41099548339844,
//       "Location": "Iruma",
//       "Longitude": 35.84189987182617,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2354,
//       "Airport Code [ICAO]": "RJTK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kisarazu Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Japan",
//       "Latitude": 139.91000366210938,
//       "Location": "Kisarazu",
//       "Longitude": 35.39830017089844,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2355,
//       "Airport Code [ICAO]": "RJTL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Shimofusa Airport",
//       "Altitude (Ft)": 98,
//       "Country": "Japan",
//       "Latitude": 140.01100158691406,
//       "Location": "Shimofusa",
//       "Longitude": 35.79890060424805,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2356,
//       "Airport Code [ICAO]": "RJTO",
//       "Airport Code iATA,FAA": "OIM",
//       "Airport Name": "Oshima Airport",
//       "Altitude (Ft)": 130,
//       "Country": "Japan",
//       "Latitude": 139.36000061,
//       "Location": "Oshima",
//       "Longitude": 34.782001495399996,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2358,
//       "Airport Code [ICAO]": "RJTR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kastner Army Heliport",
//       "Altitude (Ft)": 360,
//       "Country": "Japan",
//       "Latitude": 139.393997192,
//       "Location": "Zama",
//       "Longitude": 35.5138015747,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2359,
//       "Airport Code [ICAO]": "RJTT",
//       "Airport Code iATA,FAA": "HND",
//       "Airport Name": "Tokyo Haneda International Airport",
//       "Altitude (Ft)": 35,
//       "Country": "Japan",
//       "Latitude": 139.779999,
//       "Location": "Tokyo",
//       "Longitude": 35.552299,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2360,
//       "Airport Code [ICAO]": "RJTY",
//       "Airport Code iATA,FAA": "OKO",
//       "Airport Name": "Yokota Air Base",
//       "Altitude (Ft)": 463,
//       "Country": "Japan",
//       "Latitude": 139.34800720214844,
//       "Location": "Yokota",
//       "Longitude": 35.74850082397461,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2361,
//       "Airport Code [ICAO]": "RKJJ",
//       "Airport Code iATA,FAA": "KWJ",
//       "Airport Name": "Gwangju Airport",
//       "Altitude (Ft)": 39,
//       "Country": "South Korea",
//       "Latitude": 126.805444,
//       "Location": "Kwangju",
//       "Longitude": 35.123173,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Seoul",
//       "Type": "airport"
//     },
//     {
//       "ID": 2364,
//       "Airport Code [ICAO]": "RKJU",
//       "Airport Code iATA,FAA": "CHN",
//       "Airport Name": "Jeon Ju Airport (G-703)",
//       "Altitude (Ft)": 96,
//       "Country": "South Korea",
//       "Latitude": 127.119112,
//       "Location": "Jhunju",
//       "Longitude": 35.87808,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Seoul",
//       "Type": "airport"
//     },
//     {
//       "ID": 2365,
//       "Airport Code [ICAO]": "RKJY",
//       "Airport Code iATA,FAA": "RSU",
//       "Airport Name": "Yeosu Airport",
//       "Altitude (Ft)": 53,
//       "Country": "South Korea",
//       "Latitude": 127.61699676513672,
//       "Location": "Yeosu",
//       "Longitude": 34.84230041503906,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Seoul",
//       "Type": "airport"
//     },
//     {
//       "ID": 2366,
//       "Airport Code [ICAO]": "RKND",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sokcho Airport",
//       "Altitude (Ft)": 92,
//       "Country": "South Korea",
//       "Latitude": 128.5989990234375,
//       "Location": "Sokch'o",
//       "Longitude": 38.142601013183594,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Seoul",
//       "Type": "airport"
//     },
//     {
//       "ID": 2367,
//       "Airport Code [ICAO]": "RKNN",
//       "Airport Code iATA,FAA": "KAG",
//       "Airport Name": "Gangneung Airport (K-18)",
//       "Altitude (Ft)": 35,
//       "Country": "South Korea",
//       "Latitude": 128.943915,
//       "Location": "Kangnung",
//       "Longitude": 37.753601,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Seoul",
//       "Type": "airport"
//     },
//     {
//       "ID": 2370,
//       "Airport Code [ICAO]": "RKPC",
//       "Airport Code iATA,FAA": "CJU",
//       "Airport Name": "Jeju International Airport",
//       "Altitude (Ft)": 118,
//       "Country": "South Korea",
//       "Latitude": 126.49299621582031,
//       "Location": "Cheju",
//       "Longitude": 33.51129913330078,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Seoul",
//       "Type": "airport"
//     },
//     {
//       "ID": 2371,
//       "Airport Code [ICAO]": "RKPE",
//       "Airport Code iATA,FAA": "CHF",
//       "Airport Name": "Jinhae Airbase/Airport (G-813/K-10)",
//       "Altitude (Ft)": 8,
//       "Country": "South Korea",
//       "Latitude": 128.696229,
//       "Location": "Chinhae",
//       "Longitude": 35.140248,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Seoul",
//       "Type": "airport"
//     },
//     {
//       "ID": 2372,
//       "Airport Code [ICAO]": "RKPK",
//       "Airport Code iATA,FAA": "PUS",
//       "Airport Name": "Gimhae International Airport",
//       "Altitude (Ft)": 6,
//       "Country": "South Korea",
//       "Latitude": 128.93800354,
//       "Location": "Busan",
//       "Longitude": 35.1795005798,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Seoul",
//       "Type": "airport"
//     },
//     {
//       "ID": 2374,
//       "Airport Code [ICAO]": "RKPU",
//       "Airport Code iATA,FAA": "USN",
//       "Airport Name": "Ulsan Airport",
//       "Altitude (Ft)": 45,
//       "Country": "South Korea",
//       "Latitude": 129.352005005,
//       "Location": "Ulsan",
//       "Longitude": 35.59349823,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Seoul",
//       "Type": "airport"
//     },
//     {
//       "ID": 2375,
//       "Airport Code [ICAO]": "RKSG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "A 511 Airport",
//       "Altitude (Ft)": 51,
//       "Country": "South Korea",
//       "Latitude": 127.03099822998047,
//       "Location": "Pyongtaek",
//       "Longitude": 36.96220016479492,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Seoul",
//       "Type": "airport"
//     },
//     {
//       "ID": 2376,
//       "Airport Code [ICAO]": "RKSM",
//       "Airport Code iATA,FAA": "SSN",
//       "Airport Name": "Seoul Air Base (K-16)",
//       "Altitude (Ft)": 92,
//       "Country": "South Korea",
//       "Latitude": 127.112718,
//       "Location": "Seoul East",
//       "Longitude": 37.444744,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Seoul",
//       "Type": "airport"
//     },
//     {
//       "ID": 2377,
//       "Airport Code [ICAO]": "RKSO",
//       "Airport Code iATA,FAA": "OSN",
//       "Airport Name": "Osan Air Base",
//       "Altitude (Ft)": 38,
//       "Country": "South Korea",
//       "Latitude": 127.029999,
//       "Location": "Osan",
//       "Longitude": 37.090599,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Seoul",
//       "Type": "airport"
//     },
//     {
//       "ID": 2378,
//       "Airport Code [ICAO]": "RKSS",
//       "Airport Code iATA,FAA": "GMP",
//       "Airport Name": "Gimpo International Airport",
//       "Altitude (Ft)": 59,
//       "Country": "South Korea",
//       "Latitude": 126.791,
//       "Location": "Seoul",
//       "Longitude": 37.5583,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Seoul",
//       "Type": "airport"
//     },
//     {
//       "ID": 2379,
//       "Airport Code [ICAO]": "RKSW",
//       "Airport Code iATA,FAA": "SWU",
//       "Airport Name": "Suwon Airport",
//       "Altitude (Ft)": 88,
//       "Country": "South Korea",
//       "Latitude": 127.00700378417969,
//       "Location": "Suwon",
//       "Longitude": 37.23939895629883,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Seoul",
//       "Type": "airport"
//     },
//     {
//       "ID": 2380,
//       "Airport Code [ICAO]": "RKTH",
//       "Airport Code iATA,FAA": "KPO",
//       "Airport Name": "Pohang Airport (G-815/K-3)",
//       "Altitude (Ft)": 70,
//       "Country": "South Korea",
//       "Latitude": 129.420383,
//       "Location": "Pohang",
//       "Longitude": 35.987955,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Seoul",
//       "Type": "airport"
//     },
//     {
//       "ID": 2381,
//       "Airport Code [ICAO]": "RKTN",
//       "Airport Code iATA,FAA": "TAE",
//       "Airport Name": "Daegu Airport",
//       "Altitude (Ft)": 116,
//       "Country": "South Korea",
//       "Latitude": 128.65531,
//       "Location": "Taegu",
//       "Longitude": 35.896872,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Seoul",
//       "Type": "airport"
//     },
//     {
//       "ID": 2383,
//       "Airport Code [ICAO]": "RKTY",
//       "Airport Code iATA,FAA": "YEC",
//       "Airport Name": "Yecheon Airbase",
//       "Altitude (Ft)": 354,
//       "Country": "South Korea",
//       "Latitude": 128.34971,
//       "Location": "Yechon",
//       "Longitude": 36.630373,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Seoul",
//       "Type": "airport"
//     },
//     {
//       "ID": 2384,
//       "Airport Code [ICAO]": "ROAH",
//       "Airport Code iATA,FAA": "OKA",
//       "Airport Name": "Naha Airport",
//       "Altitude (Ft)": 12,
//       "Country": "Japan",
//       "Latitude": 127.646003723,
//       "Location": "Okinawa",
//       "Longitude": 26.1958007812,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2385,
//       "Airport Code [ICAO]": "RODE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ie Shima Auxiliary Air Base",
//       "Altitude (Ft)": 184,
//       "Country": "Japan",
//       "Latitude": 127.76490020751953,
//       "Location": "Iejima",
//       "Longitude": 26.725757598876953,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2386,
//       "Airport Code [ICAO]": "RODN",
//       "Airport Code iATA,FAA": "DNA",
//       "Airport Name": "Kadena Air Base",
//       "Altitude (Ft)": 143,
//       "Country": "Japan",
//       "Latitude": 127.767998,
//       "Location": "Kadena",
//       "Longitude": 26.3556,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2387,
//       "Airport Code [ICAO]": "ROIG",
//       "Airport Code iATA,FAA": "ISG",
//       "Airport Name": "New Ishigaki Airport",
//       "Altitude (Ft)": 102,
//       "Country": "Japan",
//       "Latitude": 124.245,
//       "Location": "Ishigaki",
//       "Longitude": 24.396389,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2388,
//       "Airport Code [ICAO]": "ROKJ",
//       "Airport Code iATA,FAA": "UEO",
//       "Airport Name": "Kumejima Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Japan",
//       "Latitude": 126.71399688720703,
//       "Location": "Kumejima",
//       "Longitude": 26.363500595092773,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2389,
//       "Airport Code [ICAO]": "ROMD",
//       "Airport Code iATA,FAA": "MMD",
//       "Airport Name": "Minami-Daito Airport",
//       "Altitude (Ft)": 167,
//       "Country": "Japan",
//       "Latitude": 131.263000488,
//       "Location": "Minami Daito",
//       "Longitude": 25.8465003967,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2390,
//       "Airport Code [ICAO]": "ROMY",
//       "Airport Code iATA,FAA": "MMY",
//       "Airport Name": "Miyako Airport",
//       "Altitude (Ft)": 150,
//       "Country": "Japan",
//       "Latitude": 125.294998169,
//       "Location": "Miyako",
//       "Longitude": 24.782800674399997,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2391,
//       "Airport Code [ICAO]": "RORK",
//       "Airport Code iATA,FAA": "KTD",
//       "Airport Name": "Kitadaito Airport",
//       "Altitude (Ft)": 80,
//       "Country": "Japan",
//       "Latitude": 131.32699585,
//       "Location": "Kitadaito",
//       "Longitude": 25.9447002411,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2392,
//       "Airport Code [ICAO]": "RORS",
//       "Airport Code iATA,FAA": "SHI",
//       "Airport Name": "Shimojishima Airport",
//       "Altitude (Ft)": 54,
//       "Country": "Japan",
//       "Latitude": 125.144996643,
//       "Location": "Shimojishima",
//       "Longitude": 24.8267002106,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2393,
//       "Airport Code [ICAO]": "RORT",
//       "Airport Code iATA,FAA": "TRA",
//       "Airport Name": "Tarama Airport",
//       "Altitude (Ft)": 36,
//       "Country": "Japan",
//       "Latitude": 124.675003052,
//       "Location": "Tarama",
//       "Longitude": 24.653900146499996,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2394,
//       "Airport Code [ICAO]": "RORY",
//       "Airport Code iATA,FAA": "RNJ",
//       "Airport Name": "Yoron Airport",
//       "Altitude (Ft)": 52,
//       "Country": "Japan",
//       "Latitude": 128.401992798,
//       "Location": "Yoron",
//       "Longitude": 27.0440006256,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2395,
//       "Airport Code [ICAO]": "ROTM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Futenma Marine Corps Air Station",
//       "Altitude (Ft)": 247,
//       "Country": "Japan",
//       "Latitude": 127.75599670410156,
//       "Location": "Futema",
//       "Longitude": 26.27429962158203,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2396,
//       "Airport Code [ICAO]": "ROYN",
//       "Airport Code iATA,FAA": "OGN",
//       "Airport Name": "Yonaguni Airport",
//       "Altitude (Ft)": 70,
//       "Country": "Japan",
//       "Latitude": 122.97799682617188,
//       "Location": "Yonaguni Jima",
//       "Longitude": 24.466899871826172,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2397,
//       "Airport Code [ICAO]": "RPLL",
//       "Airport Code iATA,FAA": "MNL",
//       "Airport Name": "Ninoy Aquino International Airport",
//       "Altitude (Ft)": 75,
//       "Country": "Philippines",
//       "Latitude": 121.019997,
//       "Location": "Manila",
//       "Longitude": 14.5086,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 2399,
//       "Airport Code [ICAO]": "RPMC",
//       "Airport Code iATA,FAA": "CBO",
//       "Airport Name": "Awang Airport",
//       "Altitude (Ft)": 189,
//       "Country": "Philippines",
//       "Latitude": 124.20999908447266,
//       "Location": "Cotabato",
//       "Longitude": 7.1652398109436035,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 2400,
//       "Airport Code [ICAO]": "RPML",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cagayan De Oro Airport",
//       "Altitude (Ft)": 601,
//       "Country": "Philippines",
//       "Latitude": 124.611,
//       "Location": "Ladag",
//       "Longitude": 8.41562,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 2401,
//       "Airport Code [ICAO]": "RPMP",
//       "Airport Code iATA,FAA": "PAG",
//       "Airport Name": "Pagadian Airport",
//       "Altitude (Ft)": 5,
//       "Country": "Philippines",
//       "Latitude": 123.461179733,
//       "Location": "Pagadian",
//       "Longitude": 7.83073144787,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 2402,
//       "Airport Code [ICAO]": "RPMR",
//       "Airport Code iATA,FAA": "GES",
//       "Airport Name": "General Santos International Airport",
//       "Altitude (Ft)": 505,
//       "Country": "Philippines",
//       "Latitude": 125.096000671,
//       "Location": "Romblon",
//       "Longitude": 6.05800008774,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 2404,
//       "Airport Code [ICAO]": "RPMZ",
//       "Airport Code iATA,FAA": "ZAM",
//       "Airport Name": "Zamboanga International Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Philippines",
//       "Latitude": 122.05999755859375,
//       "Location": "Zamboanga",
//       "Longitude": 6.922420024871826,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 2405,
//       "Airport Code [ICAO]": "RPUB",
//       "Airport Code iATA,FAA": "BAG",
//       "Airport Name": "Loakan Airport",
//       "Altitude (Ft)": 4251,
//       "Country": "Philippines",
//       "Latitude": 120.62000274658203,
//       "Location": "Baguio",
//       "Longitude": 16.375099182128906,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 2406,
//       "Airport Code [ICAO]": "RPUD",
//       "Airport Code iATA,FAA": "DTE",
//       "Airport Name": "Daet Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Philippines",
//       "Latitude": 122.9800033569336,
//       "Location": "Daet",
//       "Longitude": 14.129199981689453,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 2407,
//       "Airport Code [ICAO]": "RPUF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Basa Air Base",
//       "Altitude (Ft)": 151,
//       "Country": "Philippines",
//       "Latitude": 120.492554,
//       "Location": "Floridablanca",
//       "Longitude": 14.98724,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 2408,
//       "Airport Code [ICAO]": "RPUG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lingayen Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Philippines",
//       "Latitude": 120.24099731445312,
//       "Location": "Lingayen",
//       "Longitude": 16.034799575805664,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 2409,
//       "Airport Code [ICAO]": "RPUH",
//       "Airport Code iATA,FAA": "SJI",
//       "Airport Name": "San Jose Airport",
//       "Altitude (Ft)": 14,
//       "Country": "Philippines",
//       "Latitude": 121.04699707,
//       "Location": "San Jose",
//       "Longitude": 12.361499786399998,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 2410,
//       "Airport Code [ICAO]": "RPUL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Fernando Air Base",
//       "Altitude (Ft)": 1220,
//       "Country": "Philippines",
//       "Latitude": 121.125,
//       "Location": "Lipa",
//       "Longitude": 13.954999923706055,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 2411,
//       "Airport Code [ICAO]": "RPUM",
//       "Airport Code iATA,FAA": "MBO",
//       "Airport Name": "Mamburao Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Philippines",
//       "Latitude": 120.6050033569336,
//       "Location": "Mamburao",
//       "Longitude": 13.208100318908691,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 2414,
//       "Airport Code [ICAO]": "RPUQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Vigan Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Philippines",
//       "Latitude": 120.35600280761719,
//       "Location": "Vigan",
//       "Longitude": 17.555299758911133,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 2415,
//       "Airport Code [ICAO]": "RPUR",
//       "Airport Code iATA,FAA": "BQA",
//       "Airport Name": "Dr.Juan C. Angara Airport",
//       "Altitude (Ft)": 108,
//       "Country": "Philippines",
//       "Latitude": 121.500034,
//       "Location": "Baler",
//       "Longitude": 15.729309,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 2421,
//       "Airport Code [ICAO]": "RPUZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bagabag Airport",
//       "Altitude (Ft)": 820,
//       "Country": "Philippines",
//       "Latitude": 121.251999,
//       "Location": "Bagabag",
//       "Longitude": 16.6192,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 2422,
//       "Airport Code [ICAO]": "RPVA",
//       "Airport Code iATA,FAA": "TAC",
//       "Airport Name": "Daniel Z. Romualdez Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Philippines",
//       "Latitude": 125.027761,
//       "Location": "Tacloban",
//       "Longitude": 11.228035,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 2423,
//       "Airport Code [ICAO]": "RPVB",
//       "Airport Code iATA,FAA": "BCD",
//       "Airport Name": "Bacolod-Silay Airport",
//       "Altitude (Ft)": 82,
//       "Country": "Philippines",
//       "Latitude": 123.014999,
//       "Location": "Bacolod",
//       "Longitude": 10.7764,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 2425,
//       "Airport Code [ICAO]": "RPVD",
//       "Airport Code iATA,FAA": "DGT",
//       "Airport Name": "Sibulan Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Philippines",
//       "Latitude": 123.300003052,
//       "Location": "Dumaguete",
//       "Longitude": 9.3337097168,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 2426,
//       "Airport Code [ICAO]": "RPVE",
//       "Airport Code iATA,FAA": "MPH",
//       "Airport Name": "Godofredo P. Ramos Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Philippines",
//       "Latitude": 121.954002,
//       "Location": "Caticlan",
//       "Longitude": 11.9245,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 2428,
//       "Airport Code [ICAO]": "RPVG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Guiuan Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Philippines",
//       "Latitude": 125.741996765,
//       "Location": "Guiuan",
//       "Longitude": 11.0354995728,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 2429,
//       "Airport Code [ICAO]": "RPVI",
//       "Airport Code iATA,FAA": "ILO",
//       "Airport Name": "Iloilo International Airport",
//       "Altitude (Ft)": 27,
//       "Country": "Philippines",
//       "Latitude": 122.493358,
//       "Location": "Iloilo",
//       "Longitude": 10.833017,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 2430,
//       "Airport Code [ICAO]": "RPVK",
//       "Airport Code iATA,FAA": "KLO",
//       "Airport Name": "Kalibo International Airport",
//       "Altitude (Ft)": 14,
//       "Country": "Philippines",
//       "Latitude": 122.375999451,
//       "Location": "Kalibo",
//       "Longitude": 11.679400444,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 2433,
//       "Airport Code [ICAO]": "RPVP",
//       "Airport Code iATA,FAA": "PPS",
//       "Airport Name": "Puerto Princesa Airport",
//       "Altitude (Ft)": 71,
//       "Country": "Philippines",
//       "Latitude": 118.75900268554688,
//       "Location": "Puerto Princesa",
//       "Longitude": 9.742119789123535,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 2435,
//       "Airport Code [ICAO]": "RPVS",
//       "Airport Code iATA,FAA": "EUQ",
//       "Airport Name": "Evelio Javier Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Philippines",
//       "Latitude": 121.932998657,
//       "Location": "San Jose",
//       "Longitude": 10.765999794,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 2436,
//       "Airport Code [ICAO]": "SAAC",
//       "Airport Code iATA,FAA": "COC",
//       "Airport Name": "Comodoro Pierrestegui Airport",
//       "Altitude (Ft)": 112,
//       "Country": "Argentina",
//       "Latitude": -57.9966,
//       "Location": "Concordia",
//       "Longitude": -31.2969,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Cordoba",
//       "Type": "airport"
//     },
//     {
//       "ID": 2437,
//       "Airport Code [ICAO]": "SAAG",
//       "Airport Code iATA,FAA": "GHU",
//       "Airport Name": "Gualeguaychu Airport",
//       "Altitude (Ft)": 75,
//       "Country": "Argentina",
//       "Latitude": -58.6131,
//       "Location": "Gualeguaychu",
//       "Longitude": -33.0103,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Cordoba",
//       "Type": "airport"
//     },
//     {
//       "ID": 2438,
//       "Airport Code [ICAO]": "SAAJ",
//       "Airport Code iATA,FAA": "JNI",
//       "Airport Name": "Junin Airport",
//       "Altitude (Ft)": 262,
//       "Country": "Argentina",
//       "Latitude": -60.9306,
//       "Location": "Junin",
//       "Longitude": -34.5459,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Buenos_Aires",
//       "Type": "airport"
//     },
//     {
//       "ID": 2439,
//       "Airport Code [ICAO]": "SAAP",
//       "Airport Code iATA,FAA": "PRA",
//       "Airport Name": "General Urquiza Airport",
//       "Altitude (Ft)": 242,
//       "Country": "Argentina",
//       "Latitude": -60.4804,
//       "Location": "Parana",
//       "Longitude": -31.7948,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Cordoba",
//       "Type": "airport"
//     },
//     {
//       "ID": 2440,
//       "Airport Code [ICAO]": "SAAR",
//       "Airport Code iATA,FAA": "ROS",
//       "Airport Name": "Islas Malvinas Airport",
//       "Altitude (Ft)": 85,
//       "Country": "Argentina",
//       "Latitude": -60.785,
//       "Location": "Rosario",
//       "Longitude": -32.9036,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Cordoba",
//       "Type": "airport"
//     },
//     {
//       "ID": 2441,
//       "Airport Code [ICAO]": "SAAV",
//       "Airport Code iATA,FAA": "SFN",
//       "Airport Name": "Sauce Viejo Airport",
//       "Altitude (Ft)": 55,
//       "Country": "Argentina",
//       "Latitude": -60.8117,
//       "Location": "Santa Fe",
//       "Longitude": -31.7117,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Cordoba",
//       "Type": "airport"
//     },
//     {
//       "ID": 2442,
//       "Airport Code [ICAO]": "SABE",
//       "Airport Code iATA,FAA": "AEP",
//       "Airport Name": "Jorge Newbery Airpark",
//       "Altitude (Ft)": 18,
//       "Country": "Argentina",
//       "Latitude": -58.4156,
//       "Location": "Buenos Aires",
//       "Longitude": -34.5592,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Buenos_Aires",
//       "Type": "airport"
//     },
//     {
//       "ID": 2443,
//       "Airport Code [ICAO]": "SACO",
//       "Airport Code iATA,FAA": "COR",
//       "Airport Name": "Ingeniero Ambrosio Taravella Airport",
//       "Altitude (Ft)": 1604,
//       "Country": "Argentina",
//       "Latitude": -64.208,
//       "Location": "Cordoba",
//       "Longitude": -31.323601,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Cordoba",
//       "Type": "airport"
//     },
//     {
//       "ID": 2444,
//       "Airport Code [ICAO]": "SACT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Chamical Airport",
//       "Altitude (Ft)": 1502,
//       "Country": "Argentina",
//       "Latitude": -66.29360198,
//       "Location": "Gobernador Gordillo",
//       "Longitude": -30.34530067,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/La_Rioja",
//       "Type": "airport"
//     },
//     {
//       "ID": 2445,
//       "Airport Code [ICAO]": "SADF",
//       "Airport Code iATA,FAA": "FDO",
//       "Airport Name": "San Fernando Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Argentina",
//       "Latitude": -58.5896,
//       "Location": "San Fernando",
//       "Longitude": -34.4532,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Buenos_Aires",
//       "Type": "airport"
//     },
//     {
//       "ID": 2446,
//       "Airport Code [ICAO]": "SADJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mariano Moreno Airport",
//       "Altitude (Ft)": 105,
//       "Country": "Argentina",
//       "Latitude": -58.7896,
//       "Location": "Jose C. Paz",
//       "Longitude": -34.5606,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Buenos_Aires",
//       "Type": "airport"
//     },
//     {
//       "ID": 2447,
//       "Airport Code [ICAO]": "SADL",
//       "Airport Code iATA,FAA": "LPG",
//       "Airport Name": "La Plata Airport",
//       "Altitude (Ft)": 72,
//       "Country": "Argentina",
//       "Latitude": -57.8947,
//       "Location": "La Plata",
//       "Longitude": -34.9722,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Buenos_Aires",
//       "Type": "airport"
//     },
//     {
//       "ID": 2448,
//       "Airport Code [ICAO]": "SADM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Moron Airport",
//       "Altitude (Ft)": 95,
//       "Country": "Argentina",
//       "Latitude": -58.6428,
//       "Location": "Moron",
//       "Longitude": -34.6763,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Buenos_Aires",
//       "Type": "airport"
//     },
//     {
//       "ID": 2449,
//       "Airport Code [ICAO]": "SADP",
//       "Airport Code iATA,FAA": "EPA",
//       "Airport Name": "El Palomar Airport",
//       "Altitude (Ft)": 59,
//       "Country": "Argentina",
//       "Latitude": -58.6126,
//       "Location": "El Palomar",
//       "Longitude": -34.6099,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Buenos_Aires",
//       "Type": "airport"
//     },
//     {
//       "ID": 2450,
//       "Airport Code [ICAO]": "SAHC",
//       "Airport Code iATA,FAA": "HOS",
//       "Airport Name": "Chos Malal Airport",
//       "Altitude (Ft)": 2788,
//       "Country": "Argentina",
//       "Latitude": -70.2225036621,
//       "Location": "Chosmadal",
//       "Longitude": -37.444698333699996,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Salta",
//       "Type": "airport"
//     },
//     {
//       "ID": 2451,
//       "Airport Code [ICAO]": "SAHR",
//       "Airport Code iATA,FAA": "GNR",
//       "Airport Name": "Dr. Arturo H. Illia Airport",
//       "Altitude (Ft)": 852,
//       "Country": "Argentina",
//       "Latitude": -67.6204986572,
//       "Location": "Fuerte Gral Roca",
//       "Longitude": -39.0007019043,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Salta",
//       "Type": "airport"
//     },
//     {
//       "ID": 2452,
//       "Airport Code [ICAO]": "SAME",
//       "Airport Code iATA,FAA": "MDZ",
//       "Airport Name": "El Plumerillo Airport",
//       "Altitude (Ft)": 2310,
//       "Country": "Argentina",
//       "Latitude": -68.7929000854,
//       "Location": "Mendoza",
//       "Longitude": -32.8316993713,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Mendoza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2453,
//       "Airport Code [ICAO]": "SAMM",
//       "Airport Code iATA,FAA": "LGS",
//       "Airport Name": "Comodoro D.R. Salomón Airport",
//       "Altitude (Ft)": 4685,
//       "Country": "Argentina",
//       "Latitude": -69.5743026733,
//       "Location": "Malargue",
//       "Longitude": -35.493598938,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Mendoza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2454,
//       "Airport Code [ICAO]": "SAMR",
//       "Airport Code iATA,FAA": "AFA",
//       "Airport Name": "Suboficial Ay Santiago Germano Airport",
//       "Altitude (Ft)": 2470,
//       "Country": "Argentina",
//       "Latitude": -68.4039,
//       "Location": "San Rafael",
//       "Longitude": -34.588299,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Mendoza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2455,
//       "Airport Code [ICAO]": "SANC",
//       "Airport Code iATA,FAA": "CTC",
//       "Airport Name": "Catamarca Airport",
//       "Altitude (Ft)": 1522,
//       "Country": "Argentina",
//       "Latitude": -65.751701355,
//       "Location": "Catamarca",
//       "Longitude": -28.5956001282,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Catamarca",
//       "Type": "airport"
//     },
//     {
//       "ID": 2456,
//       "Airport Code [ICAO]": "SANE",
//       "Airport Code iATA,FAA": "SDE",
//       "Airport Name": "Vicecomodoro Angel D. La Paz Aragonés Airport",
//       "Altitude (Ft)": 656,
//       "Country": "Argentina",
//       "Latitude": -64.3099975586,
//       "Location": "Santiago Del Estero",
//       "Longitude": -27.765556335399996,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Cordoba",
//       "Type": "airport"
//     },
//     {
//       "ID": 2457,
//       "Airport Code [ICAO]": "SANI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tinogasta Airport",
//       "Altitude (Ft)": 3968,
//       "Country": "Argentina",
//       "Latitude": -67.5802993774,
//       "Location": "Tinogasta",
//       "Longitude": -28.0377998352,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Catamarca",
//       "Type": "airport"
//     },
//     {
//       "ID": 2458,
//       "Airport Code [ICAO]": "SANL",
//       "Airport Code iATA,FAA": "IRJ",
//       "Airport Name": "Capitan V A Almonacid Airport",
//       "Altitude (Ft)": 1437,
//       "Country": "Argentina",
//       "Latitude": -66.7957992554,
//       "Location": "La Rioja",
//       "Longitude": -29.3815994263,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/La_Rioja",
//       "Type": "airport"
//     },
//     {
//       "ID": 2459,
//       "Airport Code [ICAO]": "SANO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Chilecito Airport",
//       "Altitude (Ft)": 3099,
//       "Country": "Argentina",
//       "Latitude": -67.4389038086,
//       "Location": "Chilecito",
//       "Longitude": -29.2238998413,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/La_Rioja",
//       "Type": "airport"
//     },
//     {
//       "ID": 2460,
//       "Airport Code [ICAO]": "SANT",
//       "Airport Code iATA,FAA": "TUC",
//       "Airport Name": "Teniente Benjamin Matienzo Airport",
//       "Altitude (Ft)": 1493,
//       "Country": "Argentina",
//       "Latitude": -65.104897,
//       "Location": "Tucuman",
//       "Longitude": -26.8409,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Tucuman",
//       "Type": "airport"
//     },
//     {
//       "ID": 2461,
//       "Airport Code [ICAO]": "SANU",
//       "Airport Code iATA,FAA": "UAQ",
//       "Airport Name": "Domingo Faustino Sarmiento Airport",
//       "Altitude (Ft)": 1958,
//       "Country": "Argentina",
//       "Latitude": -68.418198,
//       "Location": "San Juan",
//       "Longitude": -31.571501,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/San_Juan",
//       "Type": "airport"
//     },
//     {
//       "ID": 2462,
//       "Airport Code [ICAO]": "SAOC",
//       "Airport Code iATA,FAA": "RCU",
//       "Airport Name": "Area De Material Airport",
//       "Altitude (Ft)": 1380,
//       "Country": "Argentina",
//       "Latitude": -64.2612991333,
//       "Location": "Rio Cuarto",
//       "Longitude": -33.0850982666,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Cordoba",
//       "Type": "airport"
//     },
//     {
//       "ID": 2463,
//       "Airport Code [ICAO]": "SAOD",
//       "Airport Code iATA,FAA": "VDR",
//       "Airport Name": "Villa Dolores Airport",
//       "Altitude (Ft)": 1847,
//       "Country": "Argentina",
//       "Latitude": -65.1463012695,
//       "Location": "Villa Dolores",
//       "Longitude": -31.9451999664,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Cordoba",
//       "Type": "airport"
//     },
//     {
//       "ID": 2464,
//       "Airport Code [ICAO]": "SAOL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "La Quiaca Airport",
//       "Altitude (Ft)": 11414,
//       "Country": "Argentina",
//       "Latitude": -65.57749938959999,
//       "Location": "Laboulaye",
//       "Longitude": -22.1506004333,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Jujuy",
//       "Type": "airport"
//     },
//     {
//       "ID": 2465,
//       "Airport Code [ICAO]": "SAOM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Marcos Juarez Airport",
//       "Altitude (Ft)": 360,
//       "Country": "Argentina",
//       "Latitude": -62.157801,
//       "Location": "Marcos Juarez",
//       "Longitude": -32.6836,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Cordoba",
//       "Type": "airport"
//     },
//     {
//       "ID": 2466,
//       "Airport Code [ICAO]": "SAOR",
//       "Airport Code iATA,FAA": "VME",
//       "Airport Name": "Villa Reynolds Airport",
//       "Altitude (Ft)": 1591,
//       "Country": "Argentina",
//       "Latitude": -65.3873977661,
//       "Location": "Villa Reynolds",
//       "Longitude": -33.7299003601,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/San_Luis",
//       "Type": "airport"
//     },
//     {
//       "ID": 2467,
//       "Airport Code [ICAO]": "SAOU",
//       "Airport Code iATA,FAA": "LUQ",
//       "Airport Name": "Brigadier Mayor D Cesar Raul Ojeda Airport",
//       "Altitude (Ft)": 2328,
//       "Country": "Argentina",
//       "Latitude": -66.3563995361,
//       "Location": "San Luis",
//       "Longitude": -33.2732009888,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/San_Luis",
//       "Type": "airport"
//     },
//     {
//       "ID": 2468,
//       "Airport Code [ICAO]": "SARC",
//       "Airport Code iATA,FAA": "CNQ",
//       "Airport Name": "Corrientes Airport",
//       "Altitude (Ft)": 202,
//       "Country": "Argentina",
//       "Latitude": -58.7619,
//       "Location": "Corrientes",
//       "Longitude": -27.4455,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Cordoba",
//       "Type": "airport"
//     },
//     {
//       "ID": 2469,
//       "Airport Code [ICAO]": "SARE",
//       "Airport Code iATA,FAA": "RES",
//       "Airport Name": "Resistencia International Airport",
//       "Altitude (Ft)": 173,
//       "Country": "Argentina",
//       "Latitude": -59.0561,
//       "Location": "Resistencia",
//       "Longitude": -27.45,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Cordoba",
//       "Type": "airport"
//     },
//     {
//       "ID": 2470,
//       "Airport Code [ICAO]": "SARF",
//       "Airport Code iATA,FAA": "FMA",
//       "Airport Name": "Formosa Airport",
//       "Altitude (Ft)": 193,
//       "Country": "Argentina",
//       "Latitude": -58.2281,
//       "Location": "Formosa",
//       "Longitude": -26.2127,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Cordoba",
//       "Type": "airport"
//     },
//     {
//       "ID": 2471,
//       "Airport Code [ICAO]": "SARI",
//       "Airport Code iATA,FAA": "IGR",
//       "Airport Name": "Cataratas Del Iguazú International Airport",
//       "Altitude (Ft)": 916,
//       "Country": "Argentina",
//       "Latitude": -54.4734,
//       "Location": "Iguazu Falls",
//       "Longitude": -25.737301,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Cordoba",
//       "Type": "airport"
//     },
//     {
//       "ID": 2472,
//       "Airport Code [ICAO]": "SARL",
//       "Airport Code iATA,FAA": "AOL",
//       "Airport Name": "Paso De Los Libres Airport",
//       "Altitude (Ft)": 230,
//       "Country": "Argentina",
//       "Latitude": -57.1521,
//       "Location": "Paso De Los Libres",
//       "Longitude": -29.6894,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Cordoba",
//       "Type": "airport"
//     },
//     {
//       "ID": 2473,
//       "Airport Code [ICAO]": "SARM",
//       "Airport Code iATA,FAA": "MCS",
//       "Airport Name": "Monte Caseros Airport",
//       "Altitude (Ft)": 170,
//       "Country": "Argentina",
//       "Latitude": -57.6402,
//       "Location": "Monte Caseros",
//       "Longitude": -30.2719,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Cordoba",
//       "Type": "airport"
//     },
//     {
//       "ID": 2474,
//       "Airport Code [ICAO]": "SARP",
//       "Airport Code iATA,FAA": "PSS",
//       "Airport Name": "Libertador Gral D Jose De San Martin Airport",
//       "Altitude (Ft)": 430,
//       "Country": "Argentina",
//       "Latitude": -55.9707,
//       "Location": "Posadas",
//       "Longitude": -27.3858,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Cordoba",
//       "Type": "airport"
//     },
//     {
//       "ID": 2475,
//       "Airport Code [ICAO]": "SARS",
//       "Airport Code iATA,FAA": "PRQ",
//       "Airport Name": "Termal Airport",
//       "Altitude (Ft)": 308,
//       "Country": "Argentina",
//       "Latitude": -60.492222,
//       "Location": "Presidencia R.s.pena",
//       "Longitude": -26.753611,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Cordoba",
//       "Type": "airport"
//     },
//     {
//       "ID": 2476,
//       "Airport Code [ICAO]": "SASA",
//       "Airport Code iATA,FAA": "SLA",
//       "Airport Name": "Martin Miguel De Guemes International Airport",
//       "Altitude (Ft)": 4088,
//       "Country": "Argentina",
//       "Latitude": -65.4861984253,
//       "Location": "Salta",
//       "Longitude": -24.856000900299996,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Salta",
//       "Type": "airport"
//     },
//     {
//       "ID": 2477,
//       "Airport Code [ICAO]": "SASJ",
//       "Airport Code iATA,FAA": "JUJ",
//       "Airport Name": "Gobernador Horacio Guzman International Airport",
//       "Altitude (Ft)": 3019,
//       "Country": "Argentina",
//       "Latitude": -65.097801,
//       "Location": "Jujuy",
//       "Longitude": -24.392799,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Jujuy",
//       "Type": "airport"
//     },
//     {
//       "ID": 2478,
//       "Airport Code [ICAO]": "SASO",
//       "Airport Code iATA,FAA": "ORA",
//       "Airport Name": "Orán Airport",
//       "Altitude (Ft)": 1171,
//       "Country": "Argentina",
//       "Latitude": -64.3292007446,
//       "Location": "Oran",
//       "Longitude": -23.1527996063,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Salta",
//       "Type": "airport"
//     },
//     {
//       "ID": 2479,
//       "Airport Code [ICAO]": "SASQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Laboulaye Airport",
//       "Altitude (Ft)": 449,
//       "Country": "Argentina",
//       "Latitude": -63.36230087279999,
//       "Location": "La Quiaca",
//       "Longitude": -34.1353988647,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Cordoba",
//       "Type": "airport"
//     },
//     {
//       "ID": 2481,
//       "Airport Code [ICAO]": "SATD",
//       "Airport Code iATA,FAA": "ELO",
//       "Airport Name": "El Dorado Airport",
//       "Altitude (Ft)": 685,
//       "Country": "Argentina",
//       "Latitude": -54.5746994019,
//       "Location": "El Dorado",
//       "Longitude": -26.3974990845,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Cordoba",
//       "Type": "airport"
//     },
//     {
//       "ID": 2482,
//       "Airport Code [ICAO]": "SATG",
//       "Airport Code iATA,FAA": "OYA",
//       "Airport Name": "Goya Airport",
//       "Altitude (Ft)": 128,
//       "Country": "Argentina",
//       "Latitude": -59.2189,
//       "Location": "Goya",
//       "Longitude": -29.1058,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Cordoba",
//       "Type": "airport"
//     },
//     {
//       "ID": 2483,
//       "Airport Code [ICAO]": "SATO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Oberá Airport",
//       "Altitude (Ft)": 1125,
//       "Country": "Argentina",
//       "Latitude": -55.1241989136,
//       "Location": "Obera",
//       "Longitude": -27.5181999207,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Cordoba",
//       "Type": "airport"
//     },
//     {
//       "ID": 2484,
//       "Airport Code [ICAO]": "SATR",
//       "Airport Code iATA,FAA": "RCQ",
//       "Airport Name": "Reconquista Airport",
//       "Altitude (Ft)": 160,
//       "Country": "Argentina",
//       "Latitude": -59.68,
//       "Location": "Reconquista",
//       "Longitude": -29.2103,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Cordoba",
//       "Type": "airport"
//     },
//     {
//       "ID": 2485,
//       "Airport Code [ICAO]": "SATU",
//       "Airport Code iATA,FAA": "UZU",
//       "Airport Name": "Curuzu Cuatia Airport",
//       "Altitude (Ft)": 229,
//       "Country": "Argentina",
//       "Latitude": -57.9789,
//       "Location": "Curuzu Cuatia",
//       "Longitude": -29.7706,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Cordoba",
//       "Type": "airport"
//     },
//     {
//       "ID": 2486,
//       "Airport Code [ICAO]": "SAVB",
//       "Airport Code iATA,FAA": "EHL",
//       "Airport Name": "El Bolson Airport",
//       "Altitude (Ft)": 1141,
//       "Country": "Argentina",
//       "Latitude": -71.5323028564,
//       "Location": "El Bolson",
//       "Longitude": -41.9431991577,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Salta",
//       "Type": "airport"
//     },
//     {
//       "ID": 2487,
//       "Airport Code [ICAO]": "SAVC",
//       "Airport Code iATA,FAA": "CRD",
//       "Airport Name": "General E. Mosconi Airport",
//       "Altitude (Ft)": 189,
//       "Country": "Argentina",
//       "Latitude": -67.4655,
//       "Location": "Comodoro Rivadavia",
//       "Longitude": -45.7853,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Catamarca",
//       "Type": "airport"
//     },
//     {
//       "ID": 2488,
//       "Airport Code [ICAO]": "SAVE",
//       "Airport Code iATA,FAA": "EQS",
//       "Airport Name": "Brigadier Antonio Parodi Airport",
//       "Altitude (Ft)": 2621,
//       "Country": "Argentina",
//       "Latitude": -71.139503479,
//       "Location": "Esquel",
//       "Longitude": -42.908000946,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Catamarca",
//       "Type": "airport"
//     },
//     {
//       "ID": 2490,
//       "Airport Code [ICAO]": "SAVT",
//       "Airport Code iATA,FAA": "REL",
//       "Airport Name": "Almirante Marco Andres Zar Airport",
//       "Altitude (Ft)": 141,
//       "Country": "Argentina",
//       "Latitude": -65.2703,
//       "Location": "Trelew",
//       "Longitude": -43.2105,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Catamarca",
//       "Type": "airport"
//     },
//     {
//       "ID": 2491,
//       "Airport Code [ICAO]": "SAVV",
//       "Airport Code iATA,FAA": "VDM",
//       "Airport Name": "Gobernador Castello Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Argentina",
//       "Latitude": -63.0004,
//       "Location": "Viedma",
//       "Longitude": -40.8692,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Salta",
//       "Type": "airport"
//     },
//     {
//       "ID": 2492,
//       "Airport Code [ICAO]": "SAVY",
//       "Airport Code iATA,FAA": "PMY",
//       "Airport Name": "El Tehuelche Airport",
//       "Altitude (Ft)": 427,
//       "Country": "Argentina",
//       "Latitude": -65.1027,
//       "Location": "Puerto Madryn",
//       "Longitude": -42.7592,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Catamarca",
//       "Type": "airport"
//     },
//     {
//       "ID": 2493,
//       "Airport Code [ICAO]": "SAWB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Marambio Base",
//       "Altitude (Ft)": 760,
//       "Country": "Antarctica",
//       "Latitude": -56.6307983398,
//       "Location": "Marambio Base",
//       "Longitude": -64.2382965088,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Antarctica/South_Pole",
//       "Type": "airport"
//     },
//     {
//       "ID": 2494,
//       "Airport Code [ICAO]": "SAWD",
//       "Airport Code iATA,FAA": "PUD",
//       "Airport Name": "Puerto Deseado Airport",
//       "Altitude (Ft)": 268,
//       "Country": "Argentina",
//       "Latitude": -65.9041,
//       "Location": "Puerto Deseado",
//       "Longitude": -47.7353,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Rio_Gallegos",
//       "Type": "airport"
//     },
//     {
//       "ID": 2495,
//       "Airport Code [ICAO]": "SAWE",
//       "Airport Code iATA,FAA": "RGA",
//       "Airport Name": "Hermes Quijada International Airport",
//       "Altitude (Ft)": 65,
//       "Country": "Argentina",
//       "Latitude": -67.7494,
//       "Location": "Rio Grande",
//       "Longitude": -53.7777,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Ushuaia",
//       "Type": "airport"
//     },
//     {
//       "ID": 2496,
//       "Airport Code [ICAO]": "SAWG",
//       "Airport Code iATA,FAA": "RGL",
//       "Airport Name": "Piloto Civil N. Fernández Airport",
//       "Altitude (Ft)": 61,
//       "Country": "Argentina",
//       "Latitude": -69.3126,
//       "Location": "Rio Gallegos",
//       "Longitude": -51.6089,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Rio_Gallegos",
//       "Type": "airport"
//     },
//     {
//       "ID": 2497,
//       "Airport Code [ICAO]": "SAWH",
//       "Airport Code iATA,FAA": "USH",
//       "Airport Name": "Malvinas Argentinas Airport",
//       "Altitude (Ft)": 102,
//       "Country": "Argentina",
//       "Latitude": -68.2958,
//       "Location": "Ushuaia",
//       "Longitude": -54.8433,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Ushuaia",
//       "Type": "airport"
//     },
//     {
//       "ID": 2498,
//       "Airport Code [ICAO]": "SAWJ",
//       "Airport Code iATA,FAA": "ULA",
//       "Airport Name": "Capitan D Daniel Vazquez Airport",
//       "Altitude (Ft)": 203,
//       "Country": "Argentina",
//       "Latitude": -67.8026,
//       "Location": "San Julian",
//       "Longitude": -49.3068,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Rio_Gallegos",
//       "Type": "airport"
//     },
//     {
//       "ID": 2499,
//       "Airport Code [ICAO]": "SAWP",
//       "Airport Code iATA,FAA": "PMQ",
//       "Airport Name": "Perito Moreno Airport",
//       "Altitude (Ft)": 1410,
//       "Country": "Argentina",
//       "Latitude": -70.978699,
//       "Location": "Perito Moreno",
//       "Longitude": -46.537899,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Rio_Gallegos",
//       "Type": "airport"
//     },
//     {
//       "ID": 2500,
//       "Airport Code [ICAO]": "SAWU",
//       "Airport Code iATA,FAA": "RZA",
//       "Airport Name": "Santa Cruz Airport",
//       "Altitude (Ft)": 364,
//       "Country": "Argentina",
//       "Latitude": -68.5792,
//       "Location": "Santa Cruz",
//       "Longitude": -50.0165,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Rio_Gallegos",
//       "Type": "airport"
//     },
//     {
//       "ID": 2501,
//       "Airport Code [ICAO]": "SAZB",
//       "Airport Code iATA,FAA": "BHI",
//       "Airport Name": "Comandante Espora Airport",
//       "Altitude (Ft)": 246,
//       "Country": "Argentina",
//       "Latitude": -62.1693,
//       "Location": "Bahia Blanca",
//       "Longitude": -38.725,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Buenos_Aires",
//       "Type": "airport"
//     },
//     {
//       "ID": 2502,
//       "Airport Code [ICAO]": "SAZC",
//       "Airport Code iATA,FAA": "CSZ",
//       "Airport Name": "Brigadier D.H.E. Ruiz Airport",
//       "Altitude (Ft)": 767,
//       "Country": "Argentina",
//       "Latitude": -61.889301,
//       "Location": "Colonel Suarez",
//       "Longitude": -37.446098,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Buenos_Aires",
//       "Type": "airport"
//     },
//     {
//       "ID": 2503,
//       "Airport Code [ICAO]": "SAZF",
//       "Airport Code iATA,FAA": "OVR",
//       "Airport Name": "Olavarria Airport",
//       "Altitude (Ft)": 551,
//       "Country": "Argentina",
//       "Latitude": -60.216598510699995,
//       "Location": "Olavarria",
//       "Longitude": -36.8899993896,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Buenos_Aires",
//       "Type": "airport"
//     },
//     {
//       "ID": 2504,
//       "Airport Code [ICAO]": "SAZG",
//       "Airport Code iATA,FAA": "GPO",
//       "Airport Name": "General Pico Airport",
//       "Altitude (Ft)": 459,
//       "Country": "Argentina",
//       "Latitude": -63.7583007812,
//       "Location": "General Pico",
//       "Longitude": -35.6962013245,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Salta",
//       "Type": "airport"
//     },
//     {
//       "ID": 2505,
//       "Airport Code [ICAO]": "SAZH",
//       "Airport Code iATA,FAA": "OYO",
//       "Airport Name": "Tres Arroyos Airport",
//       "Altitude (Ft)": 400,
//       "Country": "Argentina",
//       "Latitude": -60.3297,
//       "Location": "Tres Arroyos",
//       "Longitude": -38.3869,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Buenos_Aires",
//       "Type": "airport"
//     },
//     {
//       "ID": 2506,
//       "Airport Code [ICAO]": "SAZI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bolivar Airport",
//       "Altitude (Ft)": 308,
//       "Country": "Argentina",
//       "Latitude": -61.0764,
//       "Location": "Bolivar",
//       "Longitude": -36.1866,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Buenos_Aires",
//       "Type": "airport"
//     },
//     {
//       "ID": 2508,
//       "Airport Code [ICAO]": "SAZM",
//       "Airport Code iATA,FAA": "MDQ",
//       "Airport Name": "Ástor Piazzola International Airport",
//       "Altitude (Ft)": 72,
//       "Country": "Argentina",
//       "Latitude": -57.5733,
//       "Location": "Mar Del Plata",
//       "Longitude": -37.9342,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Buenos_Aires",
//       "Type": "airport"
//     },
//     {
//       "ID": 2509,
//       "Airport Code [ICAO]": "SAZN",
//       "Airport Code iATA,FAA": "NQN",
//       "Airport Name": "Presidente Peron Airport",
//       "Altitude (Ft)": 895,
//       "Country": "Argentina",
//       "Latitude": -68.155701,
//       "Location": "Neuquen",
//       "Longitude": -38.949001,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Salta",
//       "Type": "airport"
//     },
//     {
//       "ID": 2511,
//       "Airport Code [ICAO]": "SAZP",
//       "Airport Code iATA,FAA": "PEH",
//       "Airport Name": "Comodoro Pedro Zanni Airport",
//       "Altitude (Ft)": 278,
//       "Country": "Argentina",
//       "Latitude": -61.8576,
//       "Location": "Pehuajo",
//       "Longitude": -35.8446,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Buenos_Aires",
//       "Type": "airport"
//     },
//     {
//       "ID": 2512,
//       "Airport Code [ICAO]": "SAZR",
//       "Airport Code iATA,FAA": "RSA",
//       "Airport Name": "Santa Rosa Airport",
//       "Altitude (Ft)": 630,
//       "Country": "Argentina",
//       "Latitude": -64.275703,
//       "Location": "Santa Rosa",
//       "Longitude": -36.588299,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Salta",
//       "Type": "airport"
//     },
//     {
//       "ID": 2513,
//       "Airport Code [ICAO]": "SAZS",
//       "Airport Code iATA,FAA": "BRC",
//       "Airport Name": "San Carlos De Bariloche Airport",
//       "Altitude (Ft)": 2774,
//       "Country": "Argentina",
//       "Latitude": -71.157501,
//       "Location": "San Carlos De Bariloche",
//       "Longitude": -41.151199,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Salta",
//       "Type": "airport"
//     },
//     {
//       "ID": 2514,
//       "Airport Code [ICAO]": "SAZT",
//       "Airport Code iATA,FAA": "TDL",
//       "Airport Name": "Héroes De Malvinas Airport",
//       "Altitude (Ft)": 574,
//       "Country": "Argentina",
//       "Latitude": -59.2279014587,
//       "Location": "Tandil",
//       "Longitude": -37.2374000549,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Buenos_Aires",
//       "Type": "airport"
//     },
//     {
//       "ID": 2515,
//       "Airport Code [ICAO]": "SAZV",
//       "Airport Code iATA,FAA": "VLG",
//       "Airport Name": "Villa Gesell Airport",
//       "Altitude (Ft)": 32,
//       "Country": "Argentina",
//       "Latitude": -57.0292,
//       "Location": "Villa Gesell",
//       "Longitude": -37.2354,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Buenos_Aires",
//       "Type": "airport"
//     },
//     {
//       "ID": 2516,
//       "Airport Code [ICAO]": "SAZW",
//       "Airport Code iATA,FAA": "CUT",
//       "Airport Name": "Cutral-Co Airport",
//       "Altitude (Ft)": 2132,
//       "Country": "Argentina",
//       "Latitude": -69.2646026611,
//       "Location": "Cutralco",
//       "Longitude": -38.939701080300004,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Salta",
//       "Type": "airport"
//     },
//     {
//       "ID": 2517,
//       "Airport Code [ICAO]": "SAZY",
//       "Airport Code iATA,FAA": "CPC",
//       "Airport Name": "Aviador C. Campos Airport",
//       "Altitude (Ft)": 2569,
//       "Country": "Argentina",
//       "Latitude": -71.137299,
//       "Location": "San Martin Des Andes",
//       "Longitude": -40.075401,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Salta",
//       "Type": "airport"
//     },
//     {
//       "ID": 2518,
//       "Airport Code [ICAO]": "SBAA",
//       "Airport Code iATA,FAA": "CDJ",
//       "Airport Name": "Conceição do Araguaia Airport",
//       "Altitude (Ft)": 653,
//       "Country": "Brazil",
//       "Latitude": -49.30149841308594,
//       "Location": "Conceicao Do Araguaia",
//       "Longitude": -8.348349571228027,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Belem",
//       "Type": "airport"
//     },
//     {
//       "ID": 2519,
//       "Airport Code [ICAO]": "SBAF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Campo Délio Jardim de Mattos Airport",
//       "Altitude (Ft)": 110,
//       "Country": "Brazil",
//       "Latitude": -43.384701,
//       "Location": "Rio De Janeiro",
//       "Longitude": -22.875099,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2520,
//       "Airport Code [ICAO]": "SBAM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Amapá Airport",
//       "Altitude (Ft)": 45,
//       "Country": "Brazil",
//       "Latitude": -50.8582,
//       "Location": "Amapa",
//       "Longitude": 2.07751,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2521,
//       "Airport Code [ICAO]": "SBAQ",
//       "Airport Code iATA,FAA": "AQA",
//       "Airport Name": "Araraquara Airport",
//       "Altitude (Ft)": 2334,
//       "Country": "Brazil",
//       "Latitude": -48.1329994202,
//       "Location": "Araracuara",
//       "Longitude": -21.812000274699997,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2522,
//       "Airport Code [ICAO]": "SBAR",
//       "Airport Code iATA,FAA": "AJU",
//       "Airport Name": "Santa Maria Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Brazil",
//       "Latitude": -37.0703010559,
//       "Location": "Aracaju",
//       "Longitude": -10.984000206,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2524,
//       "Airport Code [ICAO]": "SBAT",
//       "Airport Code iATA,FAA": "AFL",
//       "Airport Name": "Piloto Osvaldo Marques Dias Airport",
//       "Altitude (Ft)": 948,
//       "Country": "Brazil",
//       "Latitude": -56.1049995422,
//       "Location": "Alta Floresta",
//       "Longitude": -9.8663892746,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Campo_Grande",
//       "Type": "airport"
//     },
//     {
//       "ID": 2525,
//       "Airport Code [ICAO]": "SBAU",
//       "Airport Code iATA,FAA": "ARU",
//       "Airport Name": "Araçatuba Airport",
//       "Altitude (Ft)": 1361,
//       "Country": "Brazil",
//       "Latitude": -50.4247016907,
//       "Location": "Aracatuba",
//       "Longitude": -21.1413002014,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2526,
//       "Airport Code [ICAO]": "SBBE",
//       "Airport Code iATA,FAA": "BEL",
//       "Airport Name": "Val de Cans/Júlio Cezar Ribeiro International Airport",
//       "Altitude (Ft)": 54,
//       "Country": "Brazil",
//       "Latitude": -48.4762992859,
//       "Location": "Belem",
//       "Longitude": -1.3792500495900002,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Belem",
//       "Type": "airport"
//     },
//     {
//       "ID": 2527,
//       "Airport Code [ICAO]": "SBBG",
//       "Airport Code iATA,FAA": "BGX",
//       "Airport Name": "Comandante Gustavo Kraemer Airport",
//       "Altitude (Ft)": 600,
//       "Country": "Brazil",
//       "Latitude": -54.112201690674,
//       "Location": "Bage",
//       "Longitude": -31.39049911499,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2528,
//       "Airport Code [ICAO]": "SBBH",
//       "Airport Code iATA,FAA": "PLU",
//       "Airport Name": "Pampulha - Carlos Drummond de Andrade Airport",
//       "Altitude (Ft)": 2589,
//       "Country": "Brazil",
//       "Latitude": -43.950599670410156,
//       "Location": "Belo Horizonte",
//       "Longitude": -19.851200103759766,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2529,
//       "Airport Code [ICAO]": "SBBI",
//       "Airport Code iATA,FAA": "BFH",
//       "Airport Name": "Bacacheri Airport",
//       "Altitude (Ft)": 3057,
//       "Country": "Brazil",
//       "Latitude": -49.23199844359999,
//       "Location": "Curitiba",
//       "Longitude": -25.4050998688,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2530,
//       "Airport Code [ICAO]": "SBBQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Major Brigadeiro Doorgal Borges Airport",
//       "Altitude (Ft)": 3657,
//       "Country": "Brazil",
//       "Latitude": -43.761101,
//       "Location": "Barbacena",
//       "Longitude": -21.2672,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2531,
//       "Airport Code [ICAO]": "SBBR",
//       "Airport Code iATA,FAA": "BSB",
//       "Airport Name": "Presidente Juscelino Kubistschek International Airport",
//       "Altitude (Ft)": 3497,
//       "Country": "Brazil",
//       "Latitude": -47.920833587646484,
//       "Location": "Brasilia",
//       "Longitude": -15.86916732788086,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2532,
//       "Airport Code [ICAO]": "SBBU",
//       "Airport Code iATA,FAA": "BAU",
//       "Airport Name": "Bauru Airport",
//       "Altitude (Ft)": 2025,
//       "Country": "Brazil",
//       "Latitude": -49.0537986755,
//       "Location": "Bauru",
//       "Longitude": -22.3449993134,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2533,
//       "Airport Code [ICAO]": "SBBV",
//       "Airport Code iATA,FAA": "BVB",
//       "Airport Name": "Atlas Brasil Cantanhede Airport",
//       "Altitude (Ft)": 276,
//       "Country": "Brazil",
//       "Latitude": -60.6922225952,
//       "Location": "Boa Vista",
//       "Longitude": 2.84138894081,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 2534,
//       "Airport Code [ICAO]": "SBBW",
//       "Airport Code iATA,FAA": "BPG",
//       "Airport Name": "Barra do Garças Airport",
//       "Altitude (Ft)": 1147,
//       "Country": "Brazil",
//       "Latitude": -52.3889007568,
//       "Location": "Barra Do Garcas",
//       "Longitude": -15.861300468400001,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Campo_Grande",
//       "Type": "airport"
//     },
//     {
//       "ID": 2535,
//       "Airport Code [ICAO]": "SBCA",
//       "Airport Code iATA,FAA": "CAC",
//       "Airport Name": "Cascavel Airport",
//       "Altitude (Ft)": 2473,
//       "Country": "Brazil",
//       "Latitude": -53.500801086399996,
//       "Location": "Cascavel",
//       "Longitude": -25.0002994537,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2536,
//       "Airport Code [ICAO]": "SBCC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cachimbo Airport",
//       "Altitude (Ft)": 1762,
//       "Country": "Brazil",
//       "Latitude": -54.9654006958,
//       "Location": "Itaituba",
//       "Longitude": -9.33393955231,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Belem",
//       "Type": "airport"
//     },
//     {
//       "ID": 2537,
//       "Airport Code [ICAO]": "SBCF",
//       "Airport Code iATA,FAA": "CNF",
//       "Airport Name": "Tancredo Neves International Airport",
//       "Altitude (Ft)": 2715,
//       "Country": "Brazil",
//       "Latitude": -43.97194290161133,
//       "Location": "Belo Horizonte",
//       "Longitude": -19.62444305419922,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2538,
//       "Airport Code [ICAO]": "SBCG",
//       "Airport Code iATA,FAA": "CGR",
//       "Airport Name": "Campo Grande Airport",
//       "Altitude (Ft)": 1833,
//       "Country": "Brazil",
//       "Latitude": -54.6725006104,
//       "Location": "Campo Grande",
//       "Longitude": -20.468700408900002,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Campo_Grande",
//       "Type": "airport"
//     },
//     {
//       "ID": 2539,
//       "Airport Code [ICAO]": "SBCH",
//       "Airport Code iATA,FAA": "XAP",
//       "Airport Name": "Serafin Enoss Bertaso Airport",
//       "Altitude (Ft)": 2146,
//       "Country": "Brazil",
//       "Latitude": -52.656600952148,
//       "Location": "Chapeco",
//       "Longitude": -27.134199142456,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2540,
//       "Airport Code [ICAO]": "SBCI",
//       "Airport Code iATA,FAA": "CLN",
//       "Airport Name": "Brig. Lysias Augusto Rodrigues Airport",
//       "Altitude (Ft)": 565,
//       "Country": "Brazil",
//       "Latitude": -47.45869827270508,
//       "Location": "Carolina",
//       "Longitude": -7.32043981552124,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2541,
//       "Airport Code [ICAO]": "SBCM",
//       "Airport Code iATA,FAA": "CCM",
//       "Airport Name": "Diomício Freitas Airport",
//       "Altitude (Ft)": 93,
//       "Country": "Brazil",
//       "Latitude": -49.4213905334,
//       "Location": "Criciuma",
//       "Longitude": -28.7244434357,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2542,
//       "Airport Code [ICAO]": "SBCO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Canoas Air Force Base",
//       "Altitude (Ft)": 26,
//       "Country": "Brazil",
//       "Latitude": -51.144413,
//       "Location": "Porto Alegre",
//       "Longitude": -29.945928,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2543,
//       "Airport Code [ICAO]": "SBCP",
//       "Airport Code iATA,FAA": "CAW",
//       "Airport Name": "Bartolomeu Lisandro Airport",
//       "Altitude (Ft)": 57,
//       "Country": "Brazil",
//       "Latitude": -41.301700592,
//       "Location": "Campos",
//       "Longitude": -21.698299408,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2544,
//       "Airport Code [ICAO]": "SBCR",
//       "Airport Code iATA,FAA": "CMG",
//       "Airport Name": "Corumbá International Airport",
//       "Altitude (Ft)": 461,
//       "Country": "Brazil",
//       "Latitude": -57.6713905334,
//       "Location": "Corumba",
//       "Longitude": -19.0119438171,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Campo_Grande",
//       "Type": "airport"
//     },
//     {
//       "ID": 2545,
//       "Airport Code [ICAO]": "SBCT",
//       "Airport Code iATA,FAA": "CWB",
//       "Airport Name": "Afonso Pena Airport",
//       "Altitude (Ft)": 2988,
//       "Country": "Brazil",
//       "Latitude": -49.1758003235,
//       "Location": "Curitiba",
//       "Longitude": -25.5284996033,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2546,
//       "Airport Code [ICAO]": "SBCV",
//       "Airport Code iATA,FAA": "CRQ",
//       "Airport Name": "Caravelas Airport",
//       "Altitude (Ft)": 36,
//       "Country": "Brazil",
//       "Latitude": -39.253101348877,
//       "Location": "Caravelas",
//       "Longitude": -17.652299880981,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2547,
//       "Airport Code [ICAO]": "SBCX",
//       "Airport Code iATA,FAA": "CXJ",
//       "Airport Name": "Hugo Cantergiani Regional Airport",
//       "Altitude (Ft)": 2472,
//       "Country": "Brazil",
//       "Latitude": -51.1875,
//       "Location": "Caxias Do Sul",
//       "Longitude": -29.197099685699996,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2548,
//       "Airport Code [ICAO]": "SBCY",
//       "Airport Code iATA,FAA": "CGB",
//       "Airport Name": "Marechal Rondon Airport",
//       "Altitude (Ft)": 617,
//       "Country": "Brazil",
//       "Latitude": -56.1166992188,
//       "Location": "Cuiaba",
//       "Longitude": -15.6528997421,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Campo_Grande",
//       "Type": "airport"
//     },
//     {
//       "ID": 2549,
//       "Airport Code [ICAO]": "SBCZ",
//       "Airport Code iATA,FAA": "CZS",
//       "Airport Name": "Cruzeiro do Sul Airport",
//       "Altitude (Ft)": 637,
//       "Country": "Brazil",
//       "Latitude": -72.7695007324,
//       "Location": "Cruzeiro do Sul",
//       "Longitude": -7.59990978241,
//       "GMT Offset": "-5",
//       "Daylight Savings": "S",
//       "Region": "America/Rio_Branco",
//       "Type": "airport"
//     },
//     {
//       "ID": 2550,
//       "Airport Code [ICAO]": "SBDN",
//       "Airport Code iATA,FAA": "PPB",
//       "Airport Name": "Presidente Prudente Airport",
//       "Altitude (Ft)": 1477,
//       "Country": "Brazil",
//       "Latitude": -51.4245986938,
//       "Location": "President Prudente",
//       "Longitude": -22.1751003265,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2551,
//       "Airport Code [ICAO]": "SBEG",
//       "Airport Code iATA,FAA": "MAO",
//       "Airport Name": "Eduardo Gomes International Airport",
//       "Altitude (Ft)": 264,
//       "Country": "Brazil",
//       "Latitude": -60.04970169067383,
//       "Location": "Manaus",
//       "Longitude": -3.0386099815368652,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 2552,
//       "Airport Code [ICAO]": "SBEK",
//       "Airport Code iATA,FAA": "JCR",
//       "Airport Name": "Jacareacanga Airport",
//       "Altitude (Ft)": 323,
//       "Country": "Brazil",
//       "Latitude": -57.77690124511719,
//       "Location": "Jacare-acanga",
//       "Longitude": -6.233160018920898,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Belem",
//       "Type": "airport"
//     },
//     {
//       "ID": 2553,
//       "Airport Code [ICAO]": "SBES",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "São Pedro da Aldeia Airport",
//       "Altitude (Ft)": 61,
//       "Country": "Brazil",
//       "Latitude": -42.09260177612305,
//       "Location": "Sao Pedro Da Aldeia",
//       "Longitude": -22.81290054321289,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2554,
//       "Airport Code [ICAO]": "SBFI",
//       "Airport Code iATA,FAA": "IGU",
//       "Airport Name": "Cataratas International Airport",
//       "Altitude (Ft)": 786,
//       "Country": "Brazil",
//       "Latitude": -54.48500061035156,
//       "Location": "Foz Do Iguacu",
//       "Longitude": -25.600278854370117,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2555,
//       "Airport Code [ICAO]": "SBFL",
//       "Airport Code iATA,FAA": "FLN",
//       "Airport Name": "Hercílio Luz International Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Brazil",
//       "Latitude": -48.5525016784668,
//       "Location": "Florianopolis",
//       "Longitude": -27.670278549194336,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2556,
//       "Airport Code [ICAO]": "SBFN",
//       "Airport Code iATA,FAA": "FEN",
//       "Airport Name": "Fernando de Noronha Airport",
//       "Altitude (Ft)": 193,
//       "Country": "Brazil",
//       "Latitude": -32.423302,
//       "Location": "Fernando Do Noronha",
//       "Longitude": -3.85493,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2558,
//       "Airport Code [ICAO]": "SBFU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Furnas Airport",
//       "Altitude (Ft)": 2413,
//       "Country": "Brazil",
//       "Latitude": -46.33530044555664,
//       "Location": "Alpinopolis",
//       "Longitude": -20.702800750732422,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2559,
//       "Airport Code [ICAO]": "SBFZ",
//       "Airport Code iATA,FAA": "FOR",
//       "Airport Name": "Pinto Martins International Airport",
//       "Altitude (Ft)": 82,
//       "Country": "Brazil",
//       "Latitude": -38.53260040283203,
//       "Location": "Fortaleza",
//       "Longitude": -3.776279926300049,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2560,
//       "Airport Code [ICAO]": "SBGL",
//       "Airport Code iATA,FAA": "GIG",
//       "Airport Name": "Rio Galeão – Tom Jobim International Airport",
//       "Altitude (Ft)": 28,
//       "Country": "Brazil",
//       "Latitude": -43.2505569458,
//       "Location": "Rio De Janeiro",
//       "Longitude": -22.8099994659,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2561,
//       "Airport Code [ICAO]": "SBGM",
//       "Airport Code iATA,FAA": "GJM",
//       "Airport Name": "Guajará-Mirim Airport",
//       "Altitude (Ft)": 478,
//       "Country": "Brazil",
//       "Latitude": -65.28479766845703,
//       "Location": "Guajara-mirim",
//       "Longitude": -10.786399841308594,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 2562,
//       "Airport Code [ICAO]": "SBGO",
//       "Airport Code iATA,FAA": "GYN",
//       "Airport Name": "Santa Genoveva Airport",
//       "Altitude (Ft)": 2450,
//       "Country": "Brazil",
//       "Latitude": -49.220699310302734,
//       "Location": "Goiania",
//       "Longitude": -16.631999969482422,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2563,
//       "Airport Code [ICAO]": "SBGP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "EMBRAER - Unidade Gavião Peixoto Airport",
//       "Altitude (Ft)": 1998,
//       "Country": "Brazil",
//       "Latitude": -48.40510177612305,
//       "Location": "Macae",
//       "Longitude": -21.773700714111328,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2564,
//       "Airport Code [ICAO]": "SBGR",
//       "Airport Code iATA,FAA": "GRU",
//       "Airport Name": "Guarulhos - Governador André Franco Montoro International Airport",
//       "Altitude (Ft)": 2459,
//       "Country": "Brazil",
//       "Latitude": -46.47305679321289,
//       "Location": "Sao Paulo",
//       "Longitude": -23.435556411743164,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2565,
//       "Airport Code [ICAO]": "SBGW",
//       "Airport Code iATA,FAA": "GUJ",
//       "Airport Name": "Guaratinguetá Airport",
//       "Altitude (Ft)": 1761,
//       "Country": "Brazil",
//       "Latitude": -45.20479965209961,
//       "Location": "Guaratingueta",
//       "Longitude": -22.79159927368164,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2566,
//       "Airport Code [ICAO]": "SBHT",
//       "Airport Code iATA,FAA": "ATM",
//       "Airport Name": "Altamira Airport",
//       "Altitude (Ft)": 369,
//       "Country": "Brazil",
//       "Latitude": -52.254001617432,
//       "Location": "Altamira",
//       "Longitude": -3.2539100646973,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Belem",
//       "Type": "airport"
//     },
//     {
//       "ID": 2567,
//       "Airport Code [ICAO]": "SBIC",
//       "Airport Code iATA,FAA": "ITA",
//       "Airport Name": "Itacoatiara Airport",
//       "Altitude (Ft)": 142,
//       "Country": "Brazil",
//       "Latitude": -58.481201171875,
//       "Location": "Itaituba",
//       "Longitude": -3.1272599697113037,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 2568,
//       "Airport Code [ICAO]": "SBIH",
//       "Airport Code iATA,FAA": "ITB",
//       "Airport Name": "Itaituba Airport",
//       "Altitude (Ft)": 110,
//       "Country": "Brazil",
//       "Latitude": -56.000701904297,
//       "Location": "Itaituba",
//       "Longitude": -4.2423400878906,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Belem",
//       "Type": "airport"
//     },
//     {
//       "ID": 2569,
//       "Airport Code [ICAO]": "SBIL",
//       "Airport Code iATA,FAA": "IOS",
//       "Airport Name": "Bahia - Jorge Amado Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Brazil",
//       "Latitude": -39.033199310303,
//       "Location": "Ilheus",
//       "Longitude": -14.815999984741,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2570,
//       "Airport Code [ICAO]": "SBIP",
//       "Airport Code iATA,FAA": "IPN",
//       "Airport Name": "Usiminas Airport",
//       "Altitude (Ft)": 784,
//       "Country": "Brazil",
//       "Latitude": -42.487598419189,
//       "Location": "Ipatinga",
//       "Longitude": -19.470699310303,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2571,
//       "Airport Code [ICAO]": "SBIT",
//       "Airport Code iATA,FAA": "ITR",
//       "Airport Name": "Francisco Vilela do Amaral Airport",
//       "Altitude (Ft)": 1630,
//       "Country": "Brazil",
//       "Latitude": -49.2134017944,
//       "Location": "Itumbiara",
//       "Longitude": -18.4447002411,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2572,
//       "Airport Code [ICAO]": "SBIZ",
//       "Airport Code iATA,FAA": "IMP",
//       "Airport Name": "Prefeito Renato Moreira Airport",
//       "Altitude (Ft)": 432,
//       "Country": "Brazil",
//       "Latitude": -47.459999,
//       "Location": "Imperatriz",
//       "Longitude": -5.53129,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2573,
//       "Airport Code [ICAO]": "SBJC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Belém/Brigadeiro Protásio de Oliveira Airport",
//       "Altitude (Ft)": 52,
//       "Country": "Brazil",
//       "Latitude": -48.4607009888,
//       "Location": "Belem",
//       "Longitude": -1.4141600132,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Belem",
//       "Type": "airport"
//     },
//     {
//       "ID": 2574,
//       "Airport Code [ICAO]": "SBJF",
//       "Airport Code iATA,FAA": "JDF",
//       "Airport Name": "Francisco de Assis Airport",
//       "Altitude (Ft)": 2989,
//       "Country": "Brazil",
//       "Latitude": -43.38679885864258,
//       "Location": "Juiz De Fora",
//       "Longitude": -21.791500091552734,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2575,
//       "Airport Code [ICAO]": "SBJP",
//       "Airport Code iATA,FAA": "JPA",
//       "Airport Name": "Presidente Castro Pinto International Airport",
//       "Altitude (Ft)": 217,
//       "Country": "Brazil",
//       "Latitude": -34.9486122131,
//       "Location": "Joao Pessoa",
//       "Longitude": -7.145833015440001,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2576,
//       "Airport Code [ICAO]": "SBJV",
//       "Airport Code iATA,FAA": "JOI",
//       "Airport Name": "Lauro Carneiro de Loyola Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Brazil",
//       "Latitude": -48.797401428222656,
//       "Location": "Joinville",
//       "Longitude": -26.22450065612793,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2577,
//       "Airport Code [ICAO]": "SBKG",
//       "Airport Code iATA,FAA": "CPV",
//       "Airport Name": "Presidente João Suassuna Airport",
//       "Altitude (Ft)": 1646,
//       "Country": "Brazil",
//       "Latitude": -35.8964,
//       "Location": "Campina Grande",
//       "Longitude": -7.26992,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2578,
//       "Airport Code [ICAO]": "SBKP",
//       "Airport Code iATA,FAA": "VCP",
//       "Airport Name": "Viracopos International Airport",
//       "Altitude (Ft)": 2170,
//       "Country": "Brazil",
//       "Latitude": -47.1344985962,
//       "Location": "Campinas",
//       "Longitude": -23.0074005127,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2579,
//       "Airport Code [ICAO]": "SBLJ",
//       "Airport Code iATA,FAA": "LAJ",
//       "Airport Name": "Lages Airport",
//       "Altitude (Ft)": 3065,
//       "Country": "Brazil",
//       "Latitude": -50.28150177,
//       "Location": "Lajes",
//       "Longitude": -27.782100677499997,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2580,
//       "Airport Code [ICAO]": "SBLN",
//       "Airport Code iATA,FAA": "LIP",
//       "Airport Name": "Lins Airport",
//       "Altitude (Ft)": 1559,
//       "Country": "Brazil",
//       "Latitude": -49.730499267578,
//       "Location": "Lins",
//       "Longitude": -21.663999557495,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2581,
//       "Airport Code [ICAO]": "SBLO",
//       "Airport Code iATA,FAA": "LDB",
//       "Airport Name": "Governador José Richa Airport",
//       "Altitude (Ft)": 1867,
//       "Country": "Brazil",
//       "Latitude": -51.1301002502,
//       "Location": "Londrina",
//       "Longitude": -23.333599090599996,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2582,
//       "Airport Code [ICAO]": "SBLP",
//       "Airport Code iATA,FAA": "LAZ",
//       "Airport Name": "Bom Jesus da Lapa Airport",
//       "Altitude (Ft)": 1454,
//       "Country": "Brazil",
//       "Latitude": -43.4081001282,
//       "Location": "Bom Jesus Da Lapa",
//       "Longitude": -13.2621002197,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2583,
//       "Airport Code [ICAO]": "SBLS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lagoa Santa Airport",
//       "Altitude (Ft)": 2795,
//       "Country": "Brazil",
//       "Latitude": -43.896400451660156,
//       "Location": "Lagoa Santa",
//       "Longitude": -19.66160011291504,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2584,
//       "Airport Code [ICAO]": "SBMA",
//       "Airport Code iATA,FAA": "MAB",
//       "Airport Name": "João Correa da Rocha Airport",
//       "Altitude (Ft)": 357,
//       "Country": "Brazil",
//       "Latitude": -49.138000488299994,
//       "Location": "Maraba",
//       "Longitude": -5.36858987808,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Belem",
//       "Type": "airport"
//     },
//     {
//       "ID": 2585,
//       "Airport Code [ICAO]": "SBMD",
//       "Airport Code iATA,FAA": "MEU",
//       "Airport Name": "Monte Dourado Airport",
//       "Altitude (Ft)": 677,
//       "Country": "Brazil",
//       "Latitude": -52.6022,
//       "Location": "Almeirim",
//       "Longitude": -0.889839,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Belem",
//       "Type": "airport"
//     },
//     {
//       "ID": 2586,
//       "Airport Code [ICAO]": "SBMG",
//       "Airport Code iATA,FAA": "MGF",
//       "Airport Name": "Regional de Maringá - Sílvio Nane Junior Airport",
//       "Altitude (Ft)": 1788,
//       "Country": "Brazil",
//       "Latitude": -52.01222229,
//       "Location": "Maringa",
//       "Longitude": -23.479444503799996,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2587,
//       "Airport Code [ICAO]": "SBMK",
//       "Airport Code iATA,FAA": "MOC",
//       "Airport Name": "Mário Ribeiro Airport",
//       "Altitude (Ft)": 2191,
//       "Country": "Brazil",
//       "Latitude": -43.818901062,
//       "Location": "Montes Claros",
//       "Longitude": -16.706899642899998,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2589,
//       "Airport Code [ICAO]": "SBMN",
//       "Airport Code iATA,FAA": "PLL",
//       "Airport Name": "Ponta Pelada Airport",
//       "Altitude (Ft)": 267,
//       "Country": "Brazil",
//       "Latitude": -59.98630142211914,
//       "Location": "Manaus",
//       "Longitude": -3.1460399627685547,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 2590,
//       "Airport Code [ICAO]": "SBMO",
//       "Airport Code iATA,FAA": "MCZ",
//       "Airport Name": "Zumbi dos Palmares Airport",
//       "Altitude (Ft)": 387,
//       "Country": "Brazil",
//       "Latitude": -35.79169845581055,
//       "Location": "Maceio",
//       "Longitude": -9.510809898376465,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2591,
//       "Airport Code [ICAO]": "SBMQ",
//       "Airport Code iATA,FAA": "MCP",
//       "Airport Name": "Alberto Alcolumbre Airport",
//       "Altitude (Ft)": 56,
//       "Country": "Brazil",
//       "Latitude": -51.0722007751,
//       "Location": "Macapa",
//       "Longitude": 0.0506640002131,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2592,
//       "Airport Code [ICAO]": "SBMS",
//       "Airport Code iATA,FAA": "MVF",
//       "Airport Name": "Dix-Sept Rosado Airport",
//       "Altitude (Ft)": 76,
//       "Country": "Brazil",
//       "Latitude": -37.3642997742,
//       "Location": "Mocord",
//       "Longitude": -5.2019200324999995,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2593,
//       "Airport Code [ICAO]": "SBMT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Campo de Marte Airport",
//       "Altitude (Ft)": 2368,
//       "Country": "Brazil",
//       "Latitude": -46.6377983093,
//       "Location": "Sao Paulo",
//       "Longitude": -23.5090999603,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2594,
//       "Airport Code [ICAO]": "SBMY",
//       "Airport Code iATA,FAA": "MNX",
//       "Airport Name": "Manicoré Airport",
//       "Altitude (Ft)": 174,
//       "Country": "Brazil",
//       "Latitude": -61.278301239014,
//       "Location": "Manicore",
//       "Longitude": -5.8113799095154,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 2595,
//       "Airport Code [ICAO]": "SBNF",
//       "Airport Code iATA,FAA": "NVT",
//       "Airport Name": "Ministro Victor Konder International Airport",
//       "Altitude (Ft)": 18,
//       "Country": "Brazil",
//       "Latitude": -48.651402,
//       "Location": "Navegantes",
//       "Longitude": -26.879999,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2596,
//       "Airport Code [ICAO]": "SBNM",
//       "Airport Code iATA,FAA": "GEL",
//       "Airport Name": "Santo Ângelo Airport",
//       "Altitude (Ft)": 1056,
//       "Country": "Brazil",
//       "Latitude": -54.169102,
//       "Location": "Santo Angelo",
//       "Longitude": -28.2817,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2597,
//       "Airport Code [ICAO]": "SBSG",
//       "Airport Code iATA,FAA": "NAT",
//       "Airport Name": "Governador Aluízio Alves International Airport",
//       "Altitude (Ft)": 272,
//       "Country": "Brazil",
//       "Latitude": -35.376111,
//       "Location": "Natal",
//       "Longitude": -5.768056,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2598,
//       "Airport Code [ICAO]": "SBOI",
//       "Airport Code iATA,FAA": "OYK",
//       "Airport Name": "Oiapoque Airport",
//       "Altitude (Ft)": 63,
//       "Country": "Brazil",
//       "Latitude": -51.79690170288086,
//       "Location": "Oioiapoque",
//       "Longitude": 3.85548996925354,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2599,
//       "Airport Code [ICAO]": "SBPA",
//       "Airport Code iATA,FAA": "POA",
//       "Airport Name": "Salgado Filho Airport",
//       "Altitude (Ft)": 11,
//       "Country": "Brazil",
//       "Latitude": -51.1713981628418,
//       "Location": "Porto Alegre",
//       "Longitude": -29.994400024414062,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2600,
//       "Airport Code [ICAO]": "SBPB",
//       "Airport Code iATA,FAA": "PHB",
//       "Airport Name": "Prefeito Doutor João Silva Filho Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Brazil",
//       "Latitude": -41.73199844359999,
//       "Location": "Parnaiba",
//       "Longitude": -2.89374995232,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2601,
//       "Airport Code [ICAO]": "SBPC",
//       "Airport Code iATA,FAA": "POO",
//       "Airport Name": "Poços de Caldas - Embaixador Walther Moreira Salles Airport",
//       "Altitude (Ft)": 4135,
//       "Country": "Brazil",
//       "Latitude": -46.567901611328,
//       "Location": "Pocos De Caldas",
//       "Longitude": -21.843000411987,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2602,
//       "Airport Code [ICAO]": "SBPF",
//       "Airport Code iATA,FAA": "PFB",
//       "Airport Name": "Lauro Kurtz Airport",
//       "Altitude (Ft)": 2376,
//       "Country": "Brazil",
//       "Latitude": -52.326599,
//       "Location": "Passo Fundo",
//       "Longitude": -28.243999,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2603,
//       "Airport Code [ICAO]": "SBPK",
//       "Airport Code iATA,FAA": "PET",
//       "Airport Name": "João Simões Lopes Neto International Airport",
//       "Altitude (Ft)": 59,
//       "Country": "Brazil",
//       "Latitude": -52.327702,
//       "Location": "Pelotas",
//       "Longitude": -31.718399,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2604,
//       "Airport Code [ICAO]": "SBPL",
//       "Airport Code iATA,FAA": "PNZ",
//       "Airport Name": "Senador Nilo Coelho Airport",
//       "Altitude (Ft)": 1263,
//       "Country": "Brazil",
//       "Latitude": -40.56909942626953,
//       "Location": "Petrolina",
//       "Longitude": -9.362409591674805,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2605,
//       "Airport Code [ICAO]": "SBPN",
//       "Airport Code iATA,FAA": "PNB",
//       "Airport Name": "Porto Nacional Airport",
//       "Altitude (Ft)": 870,
//       "Country": "Brazil",
//       "Latitude": -48.39970016479492,
//       "Location": "Porto Nacional",
//       "Longitude": -10.719400405883789,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2606,
//       "Airport Code [ICAO]": "SBPP",
//       "Airport Code iATA,FAA": "PMG",
//       "Airport Name": "Ponta Porã Airport",
//       "Altitude (Ft)": 2156,
//       "Country": "Brazil",
//       "Latitude": -55.702598571777344,
//       "Location": "Ponta Pora",
//       "Longitude": -22.54960060119629,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Campo_Grande",
//       "Type": "airport"
//     },
//     {
//       "ID": 2607,
//       "Airport Code [ICAO]": "SBPV",
//       "Airport Code iATA,FAA": "PVH",
//       "Airport Name": "Governador Jorge Teixeira de Oliveira Airport",
//       "Altitude (Ft)": 290,
//       "Country": "Brazil",
//       "Latitude": -63.90230178833008,
//       "Location": "Porto Velho",
//       "Longitude": -8.70928955078125,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 2609,
//       "Airport Code [ICAO]": "SBRB",
//       "Airport Code iATA,FAA": "RBR",
//       "Airport Name": "Plácido de Castro Airport",
//       "Altitude (Ft)": 633,
//       "Country": "Brazil",
//       "Latitude": -67.89805603027344,
//       "Location": "Rio Branco",
//       "Longitude": -9.868888854980469,
//       "GMT Offset": "-5",
//       "Daylight Savings": "S",
//       "Region": "America/Rio_Branco",
//       "Type": "airport"
//     },
//     {
//       "ID": 2610,
//       "Airport Code [ICAO]": "SBRF",
//       "Airport Code iATA,FAA": "REC",
//       "Airport Name": "Guararapes - Gilberto Freyre International Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Brazil",
//       "Latitude": -34.92359924316406,
//       "Location": "Recife",
//       "Longitude": -8.126489639282227,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2612,
//       "Airport Code [ICAO]": "SBRJ",
//       "Airport Code iATA,FAA": "SDU",
//       "Airport Name": "Santos Dumont Airport",
//       "Altitude (Ft)": 11,
//       "Country": "Brazil",
//       "Latitude": -43.1631011963,
//       "Location": "Rio De Janeiro",
//       "Longitude": -22.910499572799996,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2613,
//       "Airport Code [ICAO]": "SBRP",
//       "Airport Code iATA,FAA": "RAO",
//       "Airport Name": "Leite Lopes Airport",
//       "Altitude (Ft)": 1806,
//       "Country": "Brazil",
//       "Latitude": -47.776668548583984,
//       "Location": "Ribeirao Preto",
//       "Longitude": -21.136388778686523,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2614,
//       "Airport Code [ICAO]": "SBSC",
//       "Airport Code iATA,FAA": "SNZ",
//       "Airport Name": "Santa Cruz Air Force Base",
//       "Altitude (Ft)": 10,
//       "Country": "Brazil",
//       "Latitude": -43.719101,
//       "Location": "Rio De Janeiro",
//       "Longitude": -22.9324,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2615,
//       "Airport Code [ICAO]": "SBSJ",
//       "Airport Code iATA,FAA": "SJK",
//       "Airport Name": "Professor Urbano Ernesto Stumpf Airport",
//       "Altitude (Ft)": 2120,
//       "Country": "Brazil",
//       "Latitude": -45.86149978637695,
//       "Location": "Sao Jose Dos Campos",
//       "Longitude": -23.22920036315918,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2616,
//       "Airport Code [ICAO]": "SBSL",
//       "Airport Code iATA,FAA": "SLZ",
//       "Airport Name": "Marechal Cunha Machado International Airport",
//       "Altitude (Ft)": 178,
//       "Country": "Brazil",
//       "Latitude": -44.234100341796875,
//       "Location": "Sao Luis",
//       "Longitude": -2.585360050201416,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2618,
//       "Airport Code [ICAO]": "SBSP",
//       "Airport Code iATA,FAA": "CGH",
//       "Airport Name": "Congonhas Airport",
//       "Altitude (Ft)": 2631,
//       "Country": "Brazil",
//       "Latitude": -46.65638732910156,
//       "Location": "Sao Paulo",
//       "Longitude": -23.626110076904297,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2619,
//       "Airport Code [ICAO]": "SBSR",
//       "Airport Code iATA,FAA": "SJP",
//       "Airport Name": "Prof. Eribelto Manoel Reino State Airport",
//       "Altitude (Ft)": 1784,
//       "Country": "Brazil",
//       "Latitude": -49.40650177,
//       "Location": "Sao Jose Do Rio Preto",
//       "Longitude": -20.816600799599996,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2620,
//       "Airport Code [ICAO]": "SBST",
//       "Airport Code iATA,FAA": "SSZ",
//       "Airport Name": "Base Aérea de Santos Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Brazil",
//       "Latitude": -46.299720764160156,
//       "Location": "Santos",
//       "Longitude": -23.928056716918945,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2621,
//       "Airport Code [ICAO]": "SBSV",
//       "Airport Code iATA,FAA": "SSA",
//       "Airport Name": "Deputado Luiz Eduardo Magalhães International Airport",
//       "Altitude (Ft)": 64,
//       "Country": "Brazil",
//       "Latitude": -38.3224983215,
//       "Location": "Salvador",
//       "Longitude": -12.9086112976,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2622,
//       "Airport Code [ICAO]": "SBTB",
//       "Airport Code iATA,FAA": "TMT",
//       "Airport Name": "Trombetas Airport",
//       "Altitude (Ft)": 287,
//       "Country": "Brazil",
//       "Latitude": -56.396800994873,
//       "Location": "Oriximina",
//       "Longitude": -1.489599943161,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Belem",
//       "Type": "airport"
//     },
//     {
//       "ID": 2623,
//       "Airport Code [ICAO]": "SBTE",
//       "Airport Code iATA,FAA": "THE",
//       "Airport Name": "Senador Petrônio Portela Airport",
//       "Altitude (Ft)": 219,
//       "Country": "Brazil",
//       "Latitude": -42.8235015869,
//       "Location": "Teresina",
//       "Longitude": -5.0599398613,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2624,
//       "Airport Code [ICAO]": "SBTF",
//       "Airport Code iATA,FAA": "TFF",
//       "Airport Name": "Tefé Airport",
//       "Altitude (Ft)": 188,
//       "Country": "Brazil",
//       "Latitude": -64.7240982056,
//       "Location": "Tefe",
//       "Longitude": -3.38294005394,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 2625,
//       "Airport Code [ICAO]": "SBTK",
//       "Airport Code iATA,FAA": "TRQ",
//       "Airport Name": "Tarauacá Airport",
//       "Altitude (Ft)": 646,
//       "Country": "Brazil",
//       "Latitude": -70.783302307129,
//       "Location": "Tarauaca",
//       "Longitude": -8.1552600860596,
//       "GMT Offset": "-5",
//       "Daylight Savings": "S",
//       "Region": "America/Rio_Branco",
//       "Type": "airport"
//     },
//     {
//       "ID": 2626,
//       "Airport Code [ICAO]": "SBTL",
//       "Airport Code iATA,FAA": "TEC",
//       "Airport Name": "Telêmaco Borba Airport",
//       "Altitude (Ft)": 2610,
//       "Country": "Brazil",
//       "Latitude": -50.6515998840332,
//       "Location": "Telemaco Borba",
//       "Longitude": -24.317800521850586,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2627,
//       "Airport Code [ICAO]": "SBTS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tiriós Airport",
//       "Altitude (Ft)": 1127,
//       "Country": "Brazil",
//       "Latitude": -55.946098,
//       "Location": "Obidos Tirios",
//       "Longitude": 2.22347,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Belem",
//       "Type": "airport"
//     },
//     {
//       "ID": 2628,
//       "Airport Code [ICAO]": "SBTT",
//       "Airport Code iATA,FAA": "TBT",
//       "Airport Name": "Tabatinga Airport",
//       "Altitude (Ft)": 279,
//       "Country": "Brazil",
//       "Latitude": -69.93579864502,
//       "Location": "Tabatinga",
//       "Longitude": -4.2556700706482,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 2629,
//       "Airport Code [ICAO]": "SBTU",
//       "Airport Code iATA,FAA": "TUR",
//       "Airport Name": "Tucuruí Airport",
//       "Altitude (Ft)": 830,
//       "Country": "Brazil",
//       "Latitude": -49.72029876709,
//       "Location": "Tucurui",
//       "Longitude": -3.7860100269318,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Belem",
//       "Type": "airport"
//     },
//     {
//       "ID": 2630,
//       "Airport Code [ICAO]": "SBUA",
//       "Airport Code iATA,FAA": "SJL",
//       "Airport Name": "São Gabriel da Cachoeira Airport",
//       "Altitude (Ft)": 251,
//       "Country": "Brazil",
//       "Latitude": -66.9855,
//       "Location": "Sao Gabriel",
//       "Longitude": -0.14835,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 2631,
//       "Airport Code [ICAO]": "SBUF",
//       "Airport Code iATA,FAA": "PAV",
//       "Airport Name": "Paulo Afonso Airport",
//       "Altitude (Ft)": 883,
//       "Country": "Brazil",
//       "Latitude": -38.250598907471,
//       "Location": "Paulo Alfonso",
//       "Longitude": -9.4008798599243,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 2632,
//       "Airport Code [ICAO]": "SBUG",
//       "Airport Code iATA,FAA": "URG",
//       "Airport Name": "Rubem Berta Airport",
//       "Altitude (Ft)": 256,
//       "Country": "Brazil",
//       "Latitude": -57.0382003784,
//       "Location": "Uruguaiana",
//       "Longitude": -29.7821998596,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2633,
//       "Airport Code [ICAO]": "SBUL",
//       "Airport Code iATA,FAA": "UDI",
//       "Airport Name": "Ten. Cel. Aviador César Bombonato Airport",
//       "Altitude (Ft)": 3094,
//       "Country": "Brazil",
//       "Latitude": -48.225277,
//       "Location": "Uberlandia",
//       "Longitude": -18.883612,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2635,
//       "Airport Code [ICAO]": "SBUR",
//       "Airport Code iATA,FAA": "UBA",
//       "Airport Name": "Mário de Almeida Franco Airport",
//       "Altitude (Ft)": 2655,
//       "Country": "Brazil",
//       "Latitude": -47.966110229492,
//       "Location": "Uberaba",
//       "Longitude": -19.764722824097,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2636,
//       "Airport Code [ICAO]": "SBVG",
//       "Airport Code iATA,FAA": "VAG",
//       "Airport Name": "Major Brigadeiro Trompowsky Airport",
//       "Altitude (Ft)": 3025,
//       "Country": "Brazil",
//       "Latitude": -45.4733009338,
//       "Location": "Varginha",
//       "Longitude": -21.5900993347,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2637,
//       "Airport Code [ICAO]": "SBVH",
//       "Airport Code iATA,FAA": "BVH",
//       "Airport Name": "Brigadeiro Camarão Airport",
//       "Altitude (Ft)": 2018,
//       "Country": "Brazil",
//       "Latitude": -60.098300933838,
//       "Location": "Vilhena",
//       "Longitude": -12.694399833679,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 2638,
//       "Airport Code [ICAO]": "SBVT",
//       "Airport Code iATA,FAA": "VIX",
//       "Airport Name": "Eurico de Aguiar Salles Airport",
//       "Altitude (Ft)": 11,
//       "Country": "Brazil",
//       "Latitude": -40.286388,
//       "Location": "Vitoria",
//       "Longitude": -20.258057,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2639,
//       "Airport Code [ICAO]": "SBYA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Iauaretê Airport",
//       "Altitude (Ft)": 345,
//       "Country": "Brazil",
//       "Latitude": -69.18579864502,
//       "Location": "Iauarete",
//       "Longitude": 0.6075000166893,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 2640,
//       "Airport Code [ICAO]": "SBYS",
//       "Airport Code iATA,FAA": "QPS",
//       "Airport Name": "Campo Fontenelle Airport",
//       "Altitude (Ft)": 1968,
//       "Country": "Brazil",
//       "Latitude": -47.334800720214844,
//       "Location": "Piracununga",
//       "Longitude": -21.984600067138672,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2641,
//       "Airport Code [ICAO]": "SCAR",
//       "Airport Code iATA,FAA": "ARI",
//       "Airport Name": "Chacalluta Airport",
//       "Altitude (Ft)": 167,
//       "Country": "Chile",
//       "Latitude": -70.338699,
//       "Location": "Arica",
//       "Longitude": -18.348499,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 2642,
//       "Airport Code [ICAO]": "SCBA",
//       "Airport Code iATA,FAA": "BBA",
//       "Airport Name": "Balmaceda Airport",
//       "Altitude (Ft)": 1722,
//       "Country": "Chile",
//       "Latitude": -71.68949890136719,
//       "Location": "Balmaceda",
//       "Longitude": -45.916099548339844,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 2643,
//       "Airport Code [ICAO]": "SCBQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "El Bosque Airport",
//       "Altitude (Ft)": 1844,
//       "Country": "Chile",
//       "Latitude": -70.68840026855469,
//       "Location": "Santiago",
//       "Longitude": -33.561798095703125,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 2644,
//       "Airport Code [ICAO]": "SCCC",
//       "Airport Code iATA,FAA": "CCH",
//       "Airport Name": "Chile Chico Airport",
//       "Altitude (Ft)": 1070,
//       "Country": "Chile",
//       "Latitude": -71.6874008178711,
//       "Location": "Chile Chico",
//       "Longitude": -46.58330154418945,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 2645,
//       "Airport Code [ICAO]": "SCCF",
//       "Airport Code iATA,FAA": "CJC",
//       "Airport Name": "El Loa Airport",
//       "Altitude (Ft)": 7543,
//       "Country": "Chile",
//       "Latitude": -68.90360260009766,
//       "Location": "Calama",
//       "Longitude": -22.498199462890625,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 2646,
//       "Airport Code [ICAO]": "SCCH",
//       "Airport Code iATA,FAA": "YAI",
//       "Airport Name": "Gral. Bernardo O´Higgins Airport",
//       "Altitude (Ft)": 495,
//       "Country": "Chile",
//       "Latitude": -72.03140258789062,
//       "Location": "Chillan",
//       "Longitude": -36.58250045776367,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 2647,
//       "Airport Code [ICAO]": "SCCI",
//       "Airport Code iATA,FAA": "PUQ",
//       "Airport Name": "Pdte. Carlos Ibañez del Campo Airport",
//       "Altitude (Ft)": 139,
//       "Country": "Chile",
//       "Latitude": -70.854599,
//       "Location": "Punta Arenas",
//       "Longitude": -53.002602,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 2648,
//       "Airport Code [ICAO]": "SCCY",
//       "Airport Code iATA,FAA": "GXQ",
//       "Airport Name": "Teniente Vidal Airport",
//       "Altitude (Ft)": 1020,
//       "Country": "Chile",
//       "Latitude": -72.1061019897461,
//       "Location": "Coyhaique",
//       "Longitude": -45.594200134277344,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 2649,
//       "Airport Code [ICAO]": "SCDA",
//       "Airport Code iATA,FAA": "IQQ",
//       "Airport Name": "Diego Aracena Airport",
//       "Altitude (Ft)": 155,
//       "Country": "Chile",
//       "Latitude": -70.1812973022461,
//       "Location": "Iquique",
//       "Longitude": -20.535200119018555,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 2650,
//       "Airport Code [ICAO]": "SCEL",
//       "Airport Code iATA,FAA": "SCL",
//       "Airport Name": "Comodoro Arturo Merino Benítez International Airport",
//       "Altitude (Ft)": 1555,
//       "Country": "Chile",
//       "Latitude": -70.78579711914062,
//       "Location": "Santiago",
//       "Longitude": -33.393001556396484,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 2651,
//       "Airport Code [ICAO]": "SCFA",
//       "Airport Code iATA,FAA": "ANF",
//       "Airport Name": "Andrés Sabella Gálvez International Airport",
//       "Altitude (Ft)": 455,
//       "Country": "Chile",
//       "Latitude": -70.445099,
//       "Location": "Antofagasta",
//       "Longitude": -23.444501,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 2652,
//       "Airport Code [ICAO]": "SCFM",
//       "Airport Code iATA,FAA": "WPR",
//       "Airport Name": "Capitan Fuentes Martinez Airport Airport",
//       "Altitude (Ft)": 104,
//       "Country": "Chile",
//       "Latitude": -70.31919860839844,
//       "Location": "Porvenir",
//       "Longitude": -53.253700256347656,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 2653,
//       "Airport Code [ICAO]": "SCFT",
//       "Airport Code iATA,FAA": "FFU",
//       "Airport Name": "Futaleufú Airport",
//       "Altitude (Ft)": 1148,
//       "Country": "Chile",
//       "Latitude": -71.8510971069336,
//       "Location": "Futaleufu",
//       "Longitude": -43.18920135498047,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 2654,
//       "Airport Code [ICAO]": "SCGE",
//       "Airport Code iATA,FAA": "LSQ",
//       "Airport Name": "María Dolores Airport",
//       "Altitude (Ft)": 374,
//       "Country": "Chile",
//       "Latitude": -72.42539978027344,
//       "Location": "Los Angeles",
//       "Longitude": -37.40169906616211,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 2655,
//       "Airport Code [ICAO]": "SCGZ",
//       "Airport Code iATA,FAA": "WPU",
//       "Airport Name": "Guardiamarina Zañartu Airport",
//       "Altitude (Ft)": 88,
//       "Country": "Chile",
//       "Latitude": -67.62629699707031,
//       "Location": "Puerto Williams",
//       "Longitude": -54.93109893798828,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 2656,
//       "Airport Code [ICAO]": "SCIE",
//       "Airport Code iATA,FAA": "CCP",
//       "Airport Name": "Carriel Sur Airport",
//       "Altitude (Ft)": 26,
//       "Country": "Chile",
//       "Latitude": -73.063103,
//       "Location": "Concepcion",
//       "Longitude": -36.772701,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 2657,
//       "Airport Code [ICAO]": "SCIP",
//       "Airport Code iATA,FAA": "IPC",
//       "Airport Name": "Mataveri Airport",
//       "Altitude (Ft)": 227,
//       "Country": "Chile",
//       "Latitude": -109.42199707,
//       "Location": "Easter Island",
//       "Longitude": -27.1648006439,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "Pacific/Easter",
//       "Type": "airport"
//     },
//     {
//       "ID": 2658,
//       "Airport Code [ICAO]": "SCJO",
//       "Airport Code iATA,FAA": "ZOS",
//       "Airport Name": "Cañal Bajo Carlos - Hott Siebert Airport",
//       "Altitude (Ft)": 187,
//       "Country": "Chile",
//       "Latitude": -73.06099700927734,
//       "Location": "Osorno",
//       "Longitude": -40.61119842529297,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 2659,
//       "Airport Code [ICAO]": "SCLL",
//       "Airport Code iATA,FAA": "VLR",
//       "Airport Name": "Vallenar Airport",
//       "Altitude (Ft)": 1725,
//       "Country": "Chile",
//       "Latitude": -70.75599670410156,
//       "Location": "Vallenar",
//       "Longitude": -28.596399307250977,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 2660,
//       "Airport Code [ICAO]": "SCRG",
//       "Airport Code iATA,FAA": "QRC",
//       "Airport Name": "De La Independencia Airport",
//       "Altitude (Ft)": 1446,
//       "Country": "Chile",
//       "Latitude": -70.77570343017578,
//       "Location": "Rancagua",
//       "Longitude": -34.17369842529297,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 2661,
//       "Airport Code [ICAO]": "SCRM",
//       "Airport Code iATA,FAA": "TNM",
//       "Airport Name": "Teniente Rodolfo Marsh Martin Base",
//       "Altitude (Ft)": 147,
//       "Country": "Antarctica",
//       "Latitude": -58.9866981506,
//       "Location": "Isla Rey Jorge",
//       "Longitude": -62.1907997131,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Antarctica/South_Pole",
//       "Type": "airport"
//     },
//     {
//       "ID": 2662,
//       "Airport Code [ICAO]": "SCSE",
//       "Airport Code iATA,FAA": "LSC",
//       "Airport Name": "La Florida Airport",
//       "Altitude (Ft)": 481,
//       "Country": "Chile",
//       "Latitude": -71.199501,
//       "Location": "La Serena",
//       "Longitude": -29.916201,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 2663,
//       "Airport Code [ICAO]": "SCTB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Eulogio Sánchez Airport",
//       "Altitude (Ft)": 2129,
//       "Country": "Chile",
//       "Latitude": -70.54669952392578,
//       "Location": "Santiago",
//       "Longitude": -33.456298828125,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 2664,
//       "Airport Code [ICAO]": "SCTC",
//       "Airport Code iATA,FAA": "PZS",
//       "Airport Name": "Maquehue Airport",
//       "Altitude (Ft)": 304,
//       "Country": "Chile",
//       "Latitude": -72.637100219727,
//       "Location": "Temuco",
//       "Longitude": -38.766799926758,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 2665,
//       "Airport Code [ICAO]": "SCTE",
//       "Airport Code iATA,FAA": "PMC",
//       "Airport Name": "El Tepual Airport",
//       "Altitude (Ft)": 294,
//       "Country": "Chile",
//       "Latitude": -73.09400177001953,
//       "Location": "Puerto Montt",
//       "Longitude": -41.438899993896484,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 2666,
//       "Airport Code [ICAO]": "SCTN",
//       "Airport Code iATA,FAA": "WCH",
//       "Airport Name": "Chaitén Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Chile",
//       "Latitude": -72.6990966796875,
//       "Location": "Chaiten",
//       "Longitude": -42.93280029296875,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 2667,
//       "Airport Code [ICAO]": "SCVD",
//       "Airport Code iATA,FAA": "ZAL",
//       "Airport Name": "Pichoy Airport",
//       "Altitude (Ft)": 59,
//       "Country": "Chile",
//       "Latitude": -73.0860977173,
//       "Location": "Valdivia",
//       "Longitude": -39.6500015259,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 2668,
//       "Airport Code [ICAO]": "SEAM",
//       "Airport Code iATA,FAA": "ATF",
//       "Airport Name": "Chachoán Airport",
//       "Altitude (Ft)": 8502,
//       "Country": "Ecuador",
//       "Latitude": -78.57460021972656,
//       "Location": "Ambato",
//       "Longitude": -1.2120699882507324,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2669,
//       "Airport Code [ICAO]": "SECM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hacienda Clementina Airport",
//       "Altitude (Ft)": 328,
//       "Country": "Ecuador",
//       "Latitude": -79.37889862060547,
//       "Location": "Clementia",
//       "Longitude": -1.7062699794769287,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2670,
//       "Airport Code [ICAO]": "SECO",
//       "Airport Code iATA,FAA": "OCC",
//       "Airport Name": "Francisco De Orellana Airport",
//       "Altitude (Ft)": 834,
//       "Country": "Ecuador",
//       "Latitude": -76.98680114746094,
//       "Location": "Coca",
//       "Longitude": -0.4628860056400299,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2671,
//       "Airport Code [ICAO]": "SECU",
//       "Airport Code iATA,FAA": "CUE",
//       "Airport Name": "Mariscal Lamar Airport",
//       "Altitude (Ft)": 8306,
//       "Country": "Ecuador",
//       "Latitude": -78.984398,
//       "Location": "Cuenca",
//       "Longitude": -2.88947,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2672,
//       "Airport Code [ICAO]": "SEGS",
//       "Airport Code iATA,FAA": "GPS",
//       "Airport Name": "Seymour Airport",
//       "Altitude (Ft)": 207,
//       "Country": "Ecuador",
//       "Latitude": -90.26589965820312,
//       "Location": "Galapagos",
//       "Longitude": -0.45375800132751465,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Galapagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 2673,
//       "Airport Code [ICAO]": "SEGU",
//       "Airport Code iATA,FAA": "GYE",
//       "Airport Name": "José Joaquín de Olmedo International Airport",
//       "Altitude (Ft)": 19,
//       "Country": "Ecuador",
//       "Latitude": -79.88359832760001,
//       "Location": "Guayaquil",
//       "Longitude": -2.1574199199699997,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2674,
//       "Airport Code [ICAO]": "SEGZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Gualaquiza Airport",
//       "Altitude (Ft)": 2640,
//       "Country": "Ecuador",
//       "Latitude": -78.56700134277344,
//       "Location": "Gualaquiza",
//       "Longitude": -3.4232099056243896,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2675,
//       "Airport Code [ICAO]": "SEIB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Atahualpa Airport",
//       "Altitude (Ft)": 7304,
//       "Country": "Ecuador",
//       "Latitude": -78.13639831542969,
//       "Location": "Ibarra",
//       "Longitude": 0.33841899037361145,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2676,
//       "Airport Code [ICAO]": "SEKK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Km 192 Airport",
//       "Altitude (Ft)": 1247,
//       "Country": "Ecuador",
//       "Latitude": -79.39199829101562,
//       "Location": "Km-192",
//       "Longitude": 0.18420299887657166,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2677,
//       "Airport Code [ICAO]": "SELJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hacienda La Julia Airport",
//       "Altitude (Ft)": 50,
//       "Country": "Ecuador",
//       "Latitude": -79.55229949951172,
//       "Location": "La Julia",
//       "Longitude": -1.7043800354003906,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2678,
//       "Airport Code [ICAO]": "SELT",
//       "Airport Code iATA,FAA": "LTX",
//       "Airport Name": "Cotopaxi International Airport",
//       "Altitude (Ft)": 9205,
//       "Country": "Ecuador",
//       "Latitude": -78.615799,
//       "Location": "Latacunga",
//       "Longitude": -0.906833,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2679,
//       "Airport Code [ICAO]": "SEMA",
//       "Airport Code iATA,FAA": "MRR",
//       "Airport Name": "Jose Maria Velasco Ibarra Airport",
//       "Altitude (Ft)": 1508,
//       "Country": "Ecuador",
//       "Latitude": -79.94100189210002,
//       "Location": "Macara",
//       "Longitude": -4.37823009491,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2680,
//       "Airport Code [ICAO]": "SEMC",
//       "Airport Code iATA,FAA": "XMS",
//       "Airport Name": "Coronel E Carvajal Airport",
//       "Altitude (Ft)": 3452,
//       "Country": "Ecuador",
//       "Latitude": -78.12079620361328,
//       "Location": "Macas",
//       "Longitude": -2.2991700172424316,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2681,
//       "Airport Code [ICAO]": "SEMH",
//       "Airport Code iATA,FAA": "MCH",
//       "Airport Name": "General Manuel Serrano Airport",
//       "Altitude (Ft)": 11,
//       "Country": "Ecuador",
//       "Latitude": -79.961601,
//       "Location": "Machala",
//       "Longitude": -3.2689,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2682,
//       "Airport Code [ICAO]": "SEMO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "El Carmen Airport",
//       "Altitude (Ft)": 960,
//       "Country": "Ecuador",
//       "Latitude": -76.97570037841797,
//       "Location": "Montalvo",
//       "Longitude": -2.067009925842285,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2683,
//       "Airport Code [ICAO]": "SEMT",
//       "Airport Code iATA,FAA": "MEC",
//       "Airport Name": "Eloy Alfaro International Airport",
//       "Altitude (Ft)": 48,
//       "Country": "Ecuador",
//       "Latitude": -80.67880249023438,
//       "Location": "Manta",
//       "Longitude": -0.9460780024528503,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2684,
//       "Airport Code [ICAO]": "SEMX",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Maragrosa Airport",
//       "Altitude (Ft)": 18,
//       "Country": "Ecuador",
//       "Latitude": -79.80359649658203,
//       "Location": "Maragrosa",
//       "Longitude": -2.851099967956543,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2685,
//       "Airport Code [ICAO]": "SEPS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Amable Calle Gutierrez Airport",
//       "Altitude (Ft)": 22,
//       "Country": "Ecuador",
//       "Latitude": -79.76920318603516,
//       "Location": "Pasaje",
//       "Longitude": -3.3196699619293213,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2686,
//       "Airport Code [ICAO]": "SEPV",
//       "Airport Code iATA,FAA": "PVO",
//       "Airport Name": "Reales Tamarindos Airport",
//       "Altitude (Ft)": 130,
//       "Country": "Ecuador",
//       "Latitude": -80.47219848632812,
//       "Location": "Portoviejo",
//       "Longitude": -1.0416500568389893,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2687,
//       "Airport Code [ICAO]": "SEQE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Quevedo Airport",
//       "Altitude (Ft)": 350,
//       "Country": "Ecuador",
//       "Latitude": -79.46510314941406,
//       "Location": "Quevedo",
//       "Longitude": -0.9894000291824341,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2688,
//       "Airport Code [ICAO]": "SEQM",
//       "Airport Code iATA,FAA": "UIO",
//       "Airport Name": "Mariscal Sucre International Airport",
//       "Altitude (Ft)": 7841,
//       "Country": "Ecuador",
//       "Latitude": -78.3575,
//       "Location": "Quito",
//       "Longitude": -0.129166666667,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2689,
//       "Airport Code [ICAO]": "SERB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Chimborazo Airport",
//       "Altitude (Ft)": 9151,
//       "Country": "Ecuador",
//       "Latitude": -78.65609741210938,
//       "Location": "Riobamba",
//       "Longitude": -1.6534299850463867,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2690,
//       "Airport Code [ICAO]": "SERO",
//       "Airport Code iATA,FAA": "ETR",
//       "Airport Name": "Santa Rosa International Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Ecuador",
//       "Latitude": -79.996957,
//       "Location": "Santa Rosa",
//       "Longitude": -3.441986,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2691,
//       "Airport Code [ICAO]": "SESA",
//       "Airport Code iATA,FAA": "SNC",
//       "Airport Name": "General Ulpiano Paez Airport",
//       "Altitude (Ft)": 18,
//       "Country": "Ecuador",
//       "Latitude": -80.988899,
//       "Location": "Salinas",
//       "Longitude": -2.20499,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2692,
//       "Airport Code [ICAO]": "SESD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Santo Domingo de Los Colorados Airport",
//       "Altitude (Ft)": 1714,
//       "Country": "Ecuador",
//       "Latitude": -79.21440124511719,
//       "Location": "Santo Domingo",
//       "Longitude": -0.2482219934463501,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2694,
//       "Airport Code [ICAO]": "SETA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Taura Airport",
//       "Altitude (Ft)": 56,
//       "Country": "Ecuador",
//       "Latitude": -79.6801986694336,
//       "Location": "Taura",
//       "Longitude": -2.261039972305298,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2695,
//       "Airport Code [ICAO]": "SETE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mayor Galo Torres Airport",
//       "Altitude (Ft)": 1708,
//       "Country": "Ecuador",
//       "Latitude": -77.81950378417969,
//       "Location": "Tena",
//       "Longitude": -0.9867669939994812,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2696,
//       "Airport Code [ICAO]": "SETR",
//       "Airport Code iATA,FAA": "TPC",
//       "Airport Name": "Tarapoa Airport",
//       "Altitude (Ft)": 814,
//       "Country": "Ecuador",
//       "Latitude": -76.33779907226562,
//       "Location": "Tarapoa",
//       "Longitude": -0.12295600026845932,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2697,
//       "Airport Code [ICAO]": "SETU",
//       "Airport Code iATA,FAA": "TUA",
//       "Airport Name": "Teniente Coronel Luis a Mantilla Airport",
//       "Altitude (Ft)": 9649,
//       "Country": "Ecuador",
//       "Latitude": -77.70809936523438,
//       "Location": "Tulcan",
//       "Longitude": 0.8095059990882874,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 2699,
//       "Airport Code [ICAO]": "SGAS",
//       "Airport Code iATA,FAA": "ASU",
//       "Airport Name": "Silvio Pettirossi International Airport",
//       "Altitude (Ft)": 292,
//       "Country": "Paraguay",
//       "Latitude": -57.52000045776367,
//       "Location": "Asuncion",
//       "Longitude": -25.239999771118164,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Asuncion",
//       "Type": "airport"
//     },
//     {
//       "ID": 2700,
//       "Airport Code [ICAO]": "SGAY",
//       "Airport Code iATA,FAA": "AYO",
//       "Airport Name": "Juan De Ayolas Airport",
//       "Altitude (Ft)": 223,
//       "Country": "Paraguay",
//       "Latitude": -56.854064,
//       "Location": "Ayolas",
//       "Longitude": -27.370554,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Asuncion",
//       "Type": "airport"
//     },
//     {
//       "ID": 2701,
//       "Airport Code [ICAO]": "SGCO",
//       "Airport Code iATA,FAA": "CIO",
//       "Airport Name": "Teniente Col Carmelo Peralta Airport",
//       "Altitude (Ft)": 253,
//       "Country": "Paraguay",
//       "Latitude": -57.427253,
//       "Location": "Conception",
//       "Longitude": -23.442363,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Asuncion",
//       "Type": "airport"
//     },
//     {
//       "ID": 2702,
//       "Airport Code [ICAO]": "SGIB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Itaipú Airport",
//       "Altitude (Ft)": 762,
//       "Country": "Paraguay",
//       "Latitude": -54.619377,
//       "Location": "Itaipu",
//       "Longitude": -25.407519,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Asuncion",
//       "Type": "airport"
//     },
//     {
//       "ID": 2703,
//       "Airport Code [ICAO]": "SGME",
//       "Airport Code iATA,FAA": "ESG",
//       "Airport Name": "Dr. Luis Maria Argaña International Airport",
//       "Altitude (Ft)": 553,
//       "Country": "Paraguay",
//       "Latitude": -60.619998931884766,
//       "Location": "Mariscal Estigarribia",
//       "Longitude": -22.049999237060547,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Asuncion",
//       "Type": "airport"
//     },
//     {
//       "ID": 2704,
//       "Airport Code [ICAO]": "SGPI",
//       "Airport Code iATA,FAA": "PIL",
//       "Airport Name": "Carlos Miguel Gimenez Airport",
//       "Altitude (Ft)": 249,
//       "Country": "Paraguay",
//       "Latitude": -58.318026,
//       "Location": "Pilar",
//       "Longitude": -26.881224,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Asuncion",
//       "Type": "airport"
//     },
//     {
//       "ID": 2705,
//       "Airport Code [ICAO]": "SKAR",
//       "Airport Code iATA,FAA": "AXM",
//       "Airport Name": "El Eden Airport",
//       "Altitude (Ft)": 3990,
//       "Country": "Colombia",
//       "Latitude": -75.7664,
//       "Location": "Armenia",
//       "Longitude": 4.45278,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2706,
//       "Airport Code [ICAO]": "SKAS",
//       "Airport Code iATA,FAA": "PUU",
//       "Airport Name": "Tres De Mayo Airport",
//       "Altitude (Ft)": 815,
//       "Country": "Colombia",
//       "Latitude": -76.5008,
//       "Location": "Puerto Asis",
//       "Longitude": 0.505228,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2707,
//       "Airport Code [ICAO]": "SKBC",
//       "Airport Code iATA,FAA": "ELB",
//       "Airport Name": "Las Flores Airport",
//       "Altitude (Ft)": 111,
//       "Country": "Colombia",
//       "Latitude": -73.9749,
//       "Location": "El Banco",
//       "Longitude": 9.04554,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2708,
//       "Airport Code [ICAO]": "SKBG",
//       "Airport Code iATA,FAA": "BGA",
//       "Airport Name": "Palonegro Airport",
//       "Altitude (Ft)": 3897,
//       "Country": "Colombia",
//       "Latitude": -73.1848,
//       "Location": "Bucaramanga",
//       "Longitude": 7.1265,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2709,
//       "Airport Code [ICAO]": "SKBO",
//       "Airport Code iATA,FAA": "BOG",
//       "Airport Name": "El Dorado International Airport",
//       "Altitude (Ft)": 8361,
//       "Country": "Colombia",
//       "Latitude": -74.1469,
//       "Location": "Bogota",
//       "Longitude": 4.70159,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2710,
//       "Airport Code [ICAO]": "SKBQ",
//       "Airport Code iATA,FAA": "BAQ",
//       "Airport Name": "Ernesto Cortissoz International Airport",
//       "Altitude (Ft)": 98,
//       "Country": "Colombia",
//       "Latitude": -74.7808,
//       "Location": "Barranquilla",
//       "Longitude": 10.8896,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2711,
//       "Airport Code [ICAO]": "SKBS",
//       "Airport Code iATA,FAA": "BSC",
//       "Airport Name": "José Celestino Mutis Airport",
//       "Altitude (Ft)": 80,
//       "Country": "Colombia",
//       "Latitude": -77.3947,
//       "Location": "Bahia Solano",
//       "Longitude": 6.20292,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2712,
//       "Airport Code [ICAO]": "SKBU",
//       "Airport Code iATA,FAA": "BUN",
//       "Airport Name": "Gerardo Tobar López Airport",
//       "Altitude (Ft)": 48,
//       "Country": "Colombia",
//       "Latitude": -76.9898,
//       "Location": "Buenaventura",
//       "Longitude": 3.81963,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2713,
//       "Airport Code [ICAO]": "SKCC",
//       "Airport Code iATA,FAA": "CUC",
//       "Airport Name": "Camilo Daza International Airport",
//       "Altitude (Ft)": 1096,
//       "Country": "Colombia",
//       "Latitude": -72.5115,
//       "Location": "Cucuta",
//       "Longitude": 7.92757,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2714,
//       "Airport Code [ICAO]": "SKCG",
//       "Airport Code iATA,FAA": "CTG",
//       "Airport Name": "Rafael Nuñez International Airport",
//       "Altitude (Ft)": 4,
//       "Country": "Colombia",
//       "Latitude": -75.513,
//       "Location": "Cartagena",
//       "Longitude": 10.4424,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2715,
//       "Airport Code [ICAO]": "SKCL",
//       "Airport Code iATA,FAA": "CLO",
//       "Airport Name": "Alfonso Bonilla Aragon International Airport",
//       "Altitude (Ft)": 3162,
//       "Country": "Colombia",
//       "Latitude": -76.3816,
//       "Location": "Cali",
//       "Longitude": 3.54322,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2716,
//       "Airport Code [ICAO]": "SKCO",
//       "Airport Code iATA,FAA": "TCO",
//       "Airport Name": "La Florida Airport",
//       "Altitude (Ft)": 8,
//       "Country": "Colombia",
//       "Latitude": -78.7492,
//       "Location": "Tumaco",
//       "Longitude": 1.81442,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2717,
//       "Airport Code [ICAO]": "SKCZ",
//       "Airport Code iATA,FAA": "CZU",
//       "Airport Name": "Las Brujas Airport",
//       "Altitude (Ft)": 528,
//       "Country": "Colombia",
//       "Latitude": -75.2856,
//       "Location": "Corozal",
//       "Longitude": 9.33274,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2718,
//       "Airport Code [ICAO]": "SKEJ",
//       "Airport Code iATA,FAA": "EJA",
//       "Airport Name": "Yariguíes Airport",
//       "Altitude (Ft)": 412,
//       "Country": "Colombia",
//       "Latitude": -73.8068,
//       "Location": "Barrancabermeja",
//       "Longitude": 7.02433,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2719,
//       "Airport Code [ICAO]": "SKFL",
//       "Airport Code iATA,FAA": "FLA",
//       "Airport Name": "Gustavo Artunduaga Paredes Airport",
//       "Altitude (Ft)": 803,
//       "Country": "Colombia",
//       "Latitude": -75.5644,
//       "Location": "Florencia",
//       "Longitude": 1.58919,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2720,
//       "Airport Code [ICAO]": "SKGI",
//       "Airport Code iATA,FAA": "GIR",
//       "Airport Name": "Santiago Vila Airport",
//       "Altitude (Ft)": 900,
//       "Country": "Colombia",
//       "Latitude": -74.7967,
//       "Location": "Girardot",
//       "Longitude": 4.27624,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2722,
//       "Airport Code [ICAO]": "SKGP",
//       "Airport Code iATA,FAA": "GPI",
//       "Airport Name": "Juan Casiano Airport",
//       "Altitude (Ft)": 164,
//       "Country": "Colombia",
//       "Latitude": -77.8986,
//       "Location": "Guapi",
//       "Longitude": 2.57013,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2723,
//       "Airport Code [ICAO]": "SKGY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Guaymaral Airport",
//       "Altitude (Ft)": 8390,
//       "Country": "Colombia",
//       "Latitude": -74.0649,
//       "Location": "Guaymaral",
//       "Longitude": 4.81233,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2724,
//       "Airport Code [ICAO]": "SKIB",
//       "Airport Code iATA,FAA": "IBE",
//       "Airport Name": "Perales Airport",
//       "Altitude (Ft)": 2999,
//       "Country": "Colombia",
//       "Latitude": -75.1333,
//       "Location": "Ibague",
//       "Longitude": 4.42161,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2725,
//       "Airport Code [ICAO]": "SKIP",
//       "Airport Code iATA,FAA": "IPI",
//       "Airport Name": "San Luis Airport",
//       "Altitude (Ft)": 9765,
//       "Country": "Colombia",
//       "Latitude": -77.6718,
//       "Location": "Ipiales",
//       "Longitude": 0.861925,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2726,
//       "Airport Code [ICAO]": "SKLC",
//       "Airport Code iATA,FAA": "APO",
//       "Airport Name": "Antonio Roldan Betancourt Airport",
//       "Altitude (Ft)": 46,
//       "Country": "Colombia",
//       "Latitude": -76.7164,
//       "Location": "Carepa",
//       "Longitude": 7.81196,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2727,
//       "Airport Code [ICAO]": "SKLM",
//       "Airport Code iATA,FAA": "MCJ",
//       "Airport Name": "Jorge Isaac Airport",
//       "Altitude (Ft)": 281,
//       "Country": "Colombia",
//       "Latitude": -72.4901,
//       "Location": "La Mina",
//       "Longitude": 11.2325,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2728,
//       "Airport Code [ICAO]": "SKLT",
//       "Airport Code iATA,FAA": "LET",
//       "Airport Name": "Alfredo Vásquez Cobo International Airport",
//       "Altitude (Ft)": 277,
//       "Country": "Colombia",
//       "Latitude": -69.9432,
//       "Location": "Leticia",
//       "Longitude": -4.19355,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2729,
//       "Airport Code [ICAO]": "SKMD",
//       "Airport Code iATA,FAA": "EOH",
//       "Airport Name": "Enrique Olaya Herrera Airport",
//       "Altitude (Ft)": 4949,
//       "Country": "Colombia",
//       "Latitude": -75.590582,
//       "Location": "Medellin",
//       "Longitude": 6.220549,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2730,
//       "Airport Code [ICAO]": "SKMG",
//       "Airport Code iATA,FAA": "MGN",
//       "Airport Name": "Baracoa Airport",
//       "Altitude (Ft)": 178,
//       "Country": "Colombia",
//       "Latitude": -74.8461,
//       "Location": "Magangue",
//       "Longitude": 9.28474,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2731,
//       "Airport Code [ICAO]": "SKMR",
//       "Airport Code iATA,FAA": "MTR",
//       "Airport Name": "Los Garzones Airport",
//       "Altitude (Ft)": 36,
//       "Country": "Colombia",
//       "Latitude": -75.8258,
//       "Location": "Monteria",
//       "Longitude": 8.82374,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2732,
//       "Airport Code [ICAO]": "SKMU",
//       "Airport Code iATA,FAA": "MVP",
//       "Airport Name": "Fabio Alberto Leon Bentley Airport",
//       "Altitude (Ft)": 680,
//       "Country": "Colombia",
//       "Latitude": -70.2339,
//       "Location": "Mitu",
//       "Longitude": 1.25366,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2733,
//       "Airport Code [ICAO]": "SKMZ",
//       "Airport Code iATA,FAA": "MZL",
//       "Airport Name": "La Nubia Airport",
//       "Altitude (Ft)": 6871,
//       "Country": "Colombia",
//       "Latitude": -75.4647,
//       "Location": "Manizales",
//       "Longitude": 5.0296,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2734,
//       "Airport Code [ICAO]": "SKNV",
//       "Airport Code iATA,FAA": "NVA",
//       "Airport Name": "Benito Salas Airport",
//       "Altitude (Ft)": 1464,
//       "Country": "Colombia",
//       "Latitude": -75.294,
//       "Location": "Neiva",
//       "Longitude": 2.95015,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2735,
//       "Airport Code [ICAO]": "SKOC",
//       "Airport Code iATA,FAA": "OCV",
//       "Airport Name": "Aguas Claras Airport",
//       "Altitude (Ft)": 3850,
//       "Country": "Colombia",
//       "Latitude": -73.3583,
//       "Location": "Ocana",
//       "Longitude": 8.31506,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2736,
//       "Airport Code [ICAO]": "SKOT",
//       "Airport Code iATA,FAA": "OTU",
//       "Airport Name": "Otu Airport",
//       "Altitude (Ft)": 2060,
//       "Country": "Colombia",
//       "Latitude": -74.7155,
//       "Location": "Otu",
//       "Longitude": 7.01037,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2737,
//       "Airport Code [ICAO]": "SKPB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Puerto Bolívar Airport",
//       "Altitude (Ft)": 90,
//       "Country": "Colombia",
//       "Latitude": -71.9848,
//       "Location": "Puerto Bolivar",
//       "Longitude": 12.2215,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2738,
//       "Airport Code [ICAO]": "SKPC",
//       "Airport Code iATA,FAA": "PCR",
//       "Airport Name": "German Olano Airport",
//       "Altitude (Ft)": 177,
//       "Country": "Colombia",
//       "Latitude": -67.4932,
//       "Location": "Puerto Carreno",
//       "Longitude": 6.18472,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2739,
//       "Airport Code [ICAO]": "SKPE",
//       "Airport Code iATA,FAA": "PEI",
//       "Airport Name": "Matecaña International Airport",
//       "Altitude (Ft)": 4416,
//       "Country": "Colombia",
//       "Latitude": -75.7395,
//       "Location": "Pereira",
//       "Longitude": 4.81267,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2740,
//       "Airport Code [ICAO]": "SKPI",
//       "Airport Code iATA,FAA": "PTX",
//       "Airport Name": "Pitalito Airport",
//       "Altitude (Ft)": 4212,
//       "Country": "Colombia",
//       "Latitude": -76.0857,
//       "Location": "Pitalito",
//       "Longitude": 1.85777,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2741,
//       "Airport Code [ICAO]": "SKPP",
//       "Airport Code iATA,FAA": "PPN",
//       "Airport Name": "Guillermo León Valencia Airport",
//       "Altitude (Ft)": 5687,
//       "Country": "Colombia",
//       "Latitude": -76.6093,
//       "Location": "Popayan",
//       "Longitude": 2.4544,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2742,
//       "Airport Code [ICAO]": "SKPS",
//       "Airport Code iATA,FAA": "PSO",
//       "Airport Name": "Antonio Narino Airport",
//       "Altitude (Ft)": 5951,
//       "Country": "Colombia",
//       "Latitude": -77.2915,
//       "Location": "Pasto",
//       "Longitude": 1.39625,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2743,
//       "Airport Code [ICAO]": "SKPV",
//       "Airport Code iATA,FAA": "PVA",
//       "Airport Name": "El Embrujo Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Colombia",
//       "Latitude": -81.3583,
//       "Location": "Providencia",
//       "Longitude": 13.3569,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2744,
//       "Airport Code [ICAO]": "SKQU",
//       "Airport Code iATA,FAA": "MQU",
//       "Airport Name": "Mariquita Airport",
//       "Altitude (Ft)": 1531,
//       "Country": "Colombia",
//       "Latitude": -74.8836,
//       "Location": "Mariquita",
//       "Longitude": 5.21256,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2745,
//       "Airport Code [ICAO]": "SKRG",
//       "Airport Code iATA,FAA": "MDE",
//       "Airport Name": "Jose Maria Córdova International Airport",
//       "Altitude (Ft)": 6955,
//       "Country": "Colombia",
//       "Latitude": -75.4231,
//       "Location": "Rio Negro",
//       "Longitude": 6.16454,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2746,
//       "Airport Code [ICAO]": "SKRH",
//       "Airport Code iATA,FAA": "RCH",
//       "Airport Name": "Almirante Padilla Airport",
//       "Altitude (Ft)": 43,
//       "Country": "Colombia",
//       "Latitude": -72.926,
//       "Location": "Rio Hacha",
//       "Longitude": 11.5262,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2747,
//       "Airport Code [ICAO]": "SKSJ",
//       "Airport Code iATA,FAA": "SJE",
//       "Airport Name": "Jorge E. Gonzalez Torres Airport",
//       "Altitude (Ft)": 605,
//       "Country": "Colombia",
//       "Latitude": -72.6394,
//       "Location": "San Jose Del Guaviare",
//       "Longitude": 2.57969,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2748,
//       "Airport Code [ICAO]": "SKSM",
//       "Airport Code iATA,FAA": "SMR",
//       "Airport Name": "Simón Bolívar International Airport",
//       "Altitude (Ft)": 22,
//       "Country": "Colombia",
//       "Latitude": -74.2306,
//       "Location": "Santa Marta",
//       "Longitude": 11.1196,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2749,
//       "Airport Code [ICAO]": "SKSP",
//       "Airport Code iATA,FAA": "ADZ",
//       "Airport Name": "Gustavo Rojas Pinilla International Airport",
//       "Altitude (Ft)": 19,
//       "Country": "Colombia",
//       "Latitude": -81.7112,
//       "Location": "San Andres Island",
//       "Longitude": 12.5836,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2750,
//       "Airport Code [ICAO]": "SKSV",
//       "Airport Code iATA,FAA": "SVI",
//       "Airport Name": "Eduardo Falla Solano Airport",
//       "Altitude (Ft)": 920,
//       "Country": "Colombia",
//       "Latitude": -74.7663,
//       "Location": "San Vincente De Caguan",
//       "Longitude": 2.15217,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2751,
//       "Airport Code [ICAO]": "SKTM",
//       "Airport Code iATA,FAA": "TME",
//       "Airport Name": "Gustavo Vargas Airport",
//       "Altitude (Ft)": 1050,
//       "Country": "Colombia",
//       "Latitude": -71.7603,
//       "Location": "Tame",
//       "Longitude": 6.45108,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2752,
//       "Airport Code [ICAO]": "SKUC",
//       "Airport Code iATA,FAA": "AUC",
//       "Airport Name": "Santiago Perez Airport",
//       "Altitude (Ft)": 420,
//       "Country": "Colombia",
//       "Latitude": -70.7369,
//       "Location": "Arauca",
//       "Longitude": 7.06888,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2753,
//       "Airport Code [ICAO]": "SKUI",
//       "Airport Code iATA,FAA": "UIB",
//       "Airport Name": "El Caraño Airport",
//       "Altitude (Ft)": 204,
//       "Country": "Colombia",
//       "Latitude": -76.6412,
//       "Location": "Quibdo",
//       "Longitude": 5.69076,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2754,
//       "Airport Code [ICAO]": "SKUL",
//       "Airport Code iATA,FAA": "ULQ",
//       "Airport Name": "Heriberto Gíl Martínez Airport",
//       "Altitude (Ft)": 3132,
//       "Country": "Colombia",
//       "Latitude": -76.2351,
//       "Location": "Tulua",
//       "Longitude": 4.08836,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2755,
//       "Airport Code [ICAO]": "SKVP",
//       "Airport Code iATA,FAA": "VUP",
//       "Airport Name": "Alfonso López Pumarejo Airport",
//       "Altitude (Ft)": 483,
//       "Country": "Colombia",
//       "Latitude": -73.2495,
//       "Location": "Valledupar",
//       "Longitude": 10.435,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2756,
//       "Airport Code [ICAO]": "SKVV",
//       "Airport Code iATA,FAA": "VVC",
//       "Airport Name": "Vanguardia Airport",
//       "Altitude (Ft)": 1394,
//       "Country": "Colombia",
//       "Latitude": -73.6138,
//       "Location": "Villavicencio",
//       "Longitude": 4.16787,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 2758,
//       "Airport Code [ICAO]": "SLBJ",
//       "Airport Code iATA,FAA": "BJO",
//       "Airport Name": "Bermejo Airport",
//       "Altitude (Ft)": 1249,
//       "Country": "Bolivia",
//       "Latitude": -64.31289672850001,
//       "Location": "Bermejo",
//       "Longitude": -22.7733001709,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/La_Paz",
//       "Type": "airport"
//     },
//     {
//       "ID": 2759,
//       "Airport Code [ICAO]": "SLCB",
//       "Airport Code iATA,FAA": "CBB",
//       "Airport Name": "Jorge Wilsterman International Airport",
//       "Altitude (Ft)": 8360,
//       "Country": "Bolivia",
//       "Latitude": -66.1771011352539,
//       "Location": "Cochabamba",
//       "Longitude": -17.421100616455078,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/La_Paz",
//       "Type": "airport"
//     },
//     {
//       "ID": 2760,
//       "Airport Code [ICAO]": "SLCH",
//       "Airport Code iATA,FAA": "CCA",
//       "Airport Name": "Chimore Airport",
//       "Altitude (Ft)": 875,
//       "Country": "Bolivia",
//       "Latitude": -65.145568,
//       "Location": "Chapacura",
//       "Longitude": -16.976834,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/La_Paz",
//       "Type": "airport"
//     },
//     {
//       "ID": 2761,
//       "Airport Code [ICAO]": "SLCO",
//       "Airport Code iATA,FAA": "CIJ",
//       "Airport Name": "Capitán Aníbal Arab Airport",
//       "Altitude (Ft)": 889,
//       "Country": "Bolivia",
//       "Latitude": -68.7829971313,
//       "Location": "Cobija",
//       "Longitude": -11.040399551400002,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/La_Paz",
//       "Type": "airport"
//     },
//     {
//       "ID": 2762,
//       "Airport Code [ICAO]": "SLLP",
//       "Airport Code iATA,FAA": "LPB",
//       "Airport Name": "El Alto International Airport",
//       "Altitude (Ft)": 13355,
//       "Country": "Bolivia",
//       "Latitude": -68.19229888916016,
//       "Location": "La Paz",
//       "Longitude": -16.5132999420166,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/La_Paz",
//       "Type": "airport"
//     },
//     {
//       "ID": 2763,
//       "Airport Code [ICAO]": "SLOR",
//       "Airport Code iATA,FAA": "ORU",
//       "Airport Name": "Juan Mendoza Airport",
//       "Altitude (Ft)": 12152,
//       "Country": "Bolivia",
//       "Latitude": -67.0762023926,
//       "Location": "Oruro",
//       "Longitude": -17.962600708,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/La_Paz",
//       "Type": "airport"
//     },
//     {
//       "ID": 2764,
//       "Airport Code [ICAO]": "SLPO",
//       "Airport Code iATA,FAA": "POI",
//       "Airport Name": "Capitan Nicolas Rojas Airport",
//       "Altitude (Ft)": 12913,
//       "Country": "Bolivia",
//       "Latitude": -65.72370147710001,
//       "Location": "Potosi",
//       "Longitude": -19.5431003571,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/La_Paz",
//       "Type": "airport"
//     },
//     {
//       "ID": 2765,
//       "Airport Code [ICAO]": "SLPS",
//       "Airport Code iATA,FAA": "PSZ",
//       "Airport Name": "Capitán Av. Salvador Ogaya G. airport",
//       "Altitude (Ft)": 440,
//       "Country": "Bolivia",
//       "Latitude": -57.820599,
//       "Location": "Puerto Suarez",
//       "Longitude": -18.975301,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/La_Paz",
//       "Type": "airport"
//     },
//     {
//       "ID": 2766,
//       "Airport Code [ICAO]": "SLSA",
//       "Airport Code iATA,FAA": "SBL",
//       "Airport Name": "Santa Ana Del Yacuma Airport",
//       "Altitude (Ft)": 472,
//       "Country": "Bolivia",
//       "Latitude": -65.4352035522,
//       "Location": "Santa Ana",
//       "Longitude": -13.762200355500001,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/La_Paz",
//       "Type": "airport"
//     },
//     {
//       "ID": 2767,
//       "Airport Code [ICAO]": "SLSU",
//       "Airport Code iATA,FAA": "SRE",
//       "Airport Name": "Juana Azurduy De Padilla Airport",
//       "Altitude (Ft)": 9540,
//       "Country": "Bolivia",
//       "Latitude": -65.2886962890625,
//       "Location": "Sucre",
//       "Longitude": -19.007099151611328,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/La_Paz",
//       "Type": "airport"
//     },
//     {
//       "ID": 2768,
//       "Airport Code [ICAO]": "SLTJ",
//       "Airport Code iATA,FAA": "TJA",
//       "Airport Name": "Capitan Oriel Lea Plaza Airport",
//       "Altitude (Ft)": 6079,
//       "Country": "Bolivia",
//       "Latitude": -64.7013015747,
//       "Location": "Tarija",
//       "Longitude": -21.5557003021,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/La_Paz",
//       "Type": "airport"
//     },
//     {
//       "ID": 2769,
//       "Airport Code [ICAO]": "SLTR",
//       "Airport Code iATA,FAA": "TDD",
//       "Airport Name": "Teniente Av. Jorge Henrich Arauz Airport",
//       "Altitude (Ft)": 509,
//       "Country": "Bolivia",
//       "Latitude": -64.9179992676,
//       "Location": "Trinidad",
//       "Longitude": -14.8186998367,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/La_Paz",
//       "Type": "airport"
//     },
//     {
//       "ID": 2770,
//       "Airport Code [ICAO]": "SLVM",
//       "Airport Code iATA,FAA": "VLM",
//       "Airport Name": "Teniente Coronel Rafael Pabón Airport",
//       "Altitude (Ft)": 1305,
//       "Country": "Bolivia",
//       "Latitude": -63.4056015015,
//       "Location": "Villa Montes",
//       "Longitude": -21.255199432399998,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/La_Paz",
//       "Type": "airport"
//     },
//     {
//       "ID": 2771,
//       "Airport Code [ICAO]": "SLVR",
//       "Airport Code iATA,FAA": "VVI",
//       "Airport Name": "Viru Viru International Airport",
//       "Altitude (Ft)": 1224,
//       "Country": "Bolivia",
//       "Latitude": -63.135399,
//       "Location": "Santa Cruz",
//       "Longitude": -17.6448,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/La_Paz",
//       "Type": "airport"
//     },
//     {
//       "ID": 2772,
//       "Airport Code [ICAO]": "SLYA",
//       "Airport Code iATA,FAA": "BYC",
//       "Airport Name": "Yacuiba Airport",
//       "Altitude (Ft)": 2112,
//       "Country": "Bolivia",
//       "Latitude": -63.65169906616211,
//       "Location": "Yacuiba",
//       "Longitude": -21.960899353027344,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/La_Paz",
//       "Type": "airport"
//     },
//     {
//       "ID": 2773,
//       "Airport Code [ICAO]": "SMJP",
//       "Airport Code iATA,FAA": "PBM",
//       "Airport Name": "Johan Adolf Pengel International Airport",
//       "Altitude (Ft)": 59,
//       "Country": "Suriname",
//       "Latitude": -55.1878013611,
//       "Location": "Zandery",
//       "Longitude": 5.4528298377999995,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "America/Paramaribo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2774,
//       "Airport Code [ICAO]": "SOCA",
//       "Airport Code iATA,FAA": "CAY",
//       "Airport Name": "Cayenne-Rochambeau Airport",
//       "Altitude (Ft)": 26,
//       "Country": "French Guiana",
//       "Latitude": -52.360401153599994,
//       "Location": "Cayenne",
//       "Longitude": 4.819809913639999,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "America/Cayenne",
//       "Type": "airport"
//     },
//     {
//       "ID": 2775,
//       "Airport Code [ICAO]": "SOOG",
//       "Airport Code iATA,FAA": "OYP",
//       "Airport Name": "Saint-Georges-de-l'Oyapock Airport",
//       "Altitude (Ft)": 46,
//       "Country": "French Guiana",
//       "Latitude": -51.8041000366,
//       "Location": "St.-georges Oyapock",
//       "Longitude": 3.89759993553,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "America/Cayenne",
//       "Type": "airport"
//     },
//     {
//       "ID": 2776,
//       "Airport Code [ICAO]": "SPAB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Huancabamba Airport",
//       "Altitude (Ft)": 6312,
//       "Country": "Peru",
//       "Latitude": -79.44290161132812,
//       "Location": "Huancabamba",
//       "Longitude": -5.256770133972168,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2777,
//       "Airport Code [ICAO]": "SPAS",
//       "Airport Code iATA,FAA": "AOP",
//       "Airport Name": "Alferez FAP Alfredo Vladimir Sara Bauer Airport",
//       "Altitude (Ft)": 728,
//       "Country": "Peru",
//       "Latitude": -76.46659851070001,
//       "Location": "Andoas",
//       "Longitude": -2.79612994194,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2778,
//       "Airport Code [ICAO]": "SPAY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Teniente General Gerardo Pérez Pinedo Airport",
//       "Altitude (Ft)": 751,
//       "Country": "Peru",
//       "Latitude": -73.7665023804,
//       "Location": "Atalaya",
//       "Longitude": -10.7291002274,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2780,
//       "Airport Code [ICAO]": "SPBR",
//       "Airport Code iATA,FAA": "IBP",
//       "Airport Name": "Iberia Airport",
//       "Altitude (Ft)": 750,
//       "Country": "Peru",
//       "Latitude": -69.48870086669922,
//       "Location": "Iberia",
//       "Longitude": -11.411600112915039,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2781,
//       "Airport Code [ICAO]": "SPCL",
//       "Airport Code iATA,FAA": "PCL",
//       "Airport Name": "Cap FAP David Abenzur Rengifo International Airport",
//       "Altitude (Ft)": 513,
//       "Country": "Peru",
//       "Latitude": -74.57430267333984,
//       "Location": "Pucallpa",
//       "Longitude": -8.37794017791748,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2782,
//       "Airport Code [ICAO]": "SPEO",
//       "Airport Code iATA,FAA": "CHM",
//       "Airport Name": "Teniente FAP Jaime A De Montreuil Morales Airport",
//       "Altitude (Ft)": 69,
//       "Country": "Peru",
//       "Latitude": -78.5238037109375,
//       "Location": "Chimbote",
//       "Longitude": -9.149609565734863,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2783,
//       "Airport Code [ICAO]": "SPEP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Puerto Esperanza Airport",
//       "Altitude (Ft)": 725,
//       "Country": "Peru",
//       "Latitude": -70.70649719238281,
//       "Location": "Puerto Esperanza",
//       "Longitude": -9.7681303024292,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2784,
//       "Airport Code [ICAO]": "SPEQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cesar Torke Podesta Airport",
//       "Altitude (Ft)": 4480,
//       "Country": "Peru",
//       "Latitude": -70.93080139160156,
//       "Location": "Moquegua",
//       "Longitude": -17.179000854492188,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2785,
//       "Airport Code [ICAO]": "SPHI",
//       "Airport Code iATA,FAA": "CIX",
//       "Airport Name": "Capitan FAP Jose A Quinones Gonzales International Airport",
//       "Altitude (Ft)": 97,
//       "Country": "Peru",
//       "Latitude": -79.8281021118164,
//       "Location": "Chiclayo",
//       "Longitude": -6.787479877471924,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2786,
//       "Airport Code [ICAO]": "SPHO",
//       "Airport Code iATA,FAA": "AYP",
//       "Airport Name": "Coronel FAP Alfredo Mendivil Duarte Airport",
//       "Altitude (Ft)": 8917,
//       "Country": "Peru",
//       "Latitude": -74.20439910888672,
//       "Location": "Ayacucho",
//       "Longitude": -13.154800415039062,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2787,
//       "Airport Code [ICAO]": "SPHY",
//       "Airport Code iATA,FAA": "ANS",
//       "Airport Name": "Andahuaylas Airport",
//       "Altitude (Ft)": 11300,
//       "Country": "Peru",
//       "Latitude": -73.35040283203125,
//       "Location": "Andahuaylas",
//       "Longitude": -13.706399917602539,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2788,
//       "Airport Code [ICAO]": "SPHZ",
//       "Airport Code iATA,FAA": "ATA",
//       "Airport Name": "Comandante FAP German Arias Graziani Airport",
//       "Altitude (Ft)": 9097,
//       "Country": "Peru",
//       "Latitude": -77.59839630126953,
//       "Location": "Anta",
//       "Longitude": -9.347439765930176,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2789,
//       "Airport Code [ICAO]": "SPIM",
//       "Airport Code iATA,FAA": "LIM",
//       "Airport Name": "Jorge Chávez International Airport",
//       "Altitude (Ft)": 113,
//       "Country": "Peru",
//       "Latitude": -77.114305,
//       "Location": "Lima",
//       "Longitude": -12.0219,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2790,
//       "Airport Code [ICAO]": "SPJI",
//       "Airport Code iATA,FAA": "JJI",
//       "Airport Name": "Juanjui Airport",
//       "Altitude (Ft)": 1148,
//       "Country": "Peru",
//       "Latitude": -76.72859954833984,
//       "Location": "Juanjui",
//       "Longitude": -7.169099807739258,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2791,
//       "Airport Code [ICAO]": "SPJJ",
//       "Airport Code iATA,FAA": "JAU",
//       "Airport Name": "Francisco Carle Airport",
//       "Altitude (Ft)": 11034,
//       "Country": "Peru",
//       "Latitude": -75.47339630130001,
//       "Location": "Jauja",
//       "Longitude": -11.7831001282,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2792,
//       "Airport Code [ICAO]": "SPJL",
//       "Airport Code iATA,FAA": "JUL",
//       "Airport Name": "Inca Manco Capac International Airport",
//       "Altitude (Ft)": 12552,
//       "Country": "Peru",
//       "Latitude": -70.158203125,
//       "Location": "Juliaca",
//       "Longitude": -15.467100143432617,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2794,
//       "Airport Code [ICAO]": "SPLO",
//       "Airport Code iATA,FAA": "ILQ",
//       "Airport Name": "Ilo Airport",
//       "Altitude (Ft)": 72,
//       "Country": "Peru",
//       "Latitude": -71.34400177001953,
//       "Location": "Ilo",
//       "Longitude": -17.69499969482422,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2795,
//       "Airport Code [ICAO]": "SPLP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Las Palmas Air Base",
//       "Altitude (Ft)": 250,
//       "Country": "Peru",
//       "Latitude": -76.998901367188,
//       "Location": "Las Palmas",
//       "Longitude": -12.16069984436,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2796,
//       "Airport Code [ICAO]": "SPME",
//       "Airport Code iATA,FAA": "TBP",
//       "Airport Name": "Capitan FAP Pedro Canga Rodriguez Airport",
//       "Altitude (Ft)": 115,
//       "Country": "Peru",
//       "Latitude": -80.38140106201172,
//       "Location": "Tumbes",
//       "Longitude": -3.55253005027771,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2797,
//       "Airport Code [ICAO]": "SPMS",
//       "Airport Code iATA,FAA": "YMS",
//       "Airport Name": "Moises Benzaquen Rengifo Airport",
//       "Altitude (Ft)": 587,
//       "Country": "Peru",
//       "Latitude": -76.11820220947266,
//       "Location": "Yurimaguas",
//       "Longitude": -5.893770217895508,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2799,
//       "Airport Code [ICAO]": "SPOL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Collique Airport",
//       "Altitude (Ft)": 410,
//       "Country": "Peru",
//       "Latitude": -77.06109619140625,
//       "Location": "Collique",
//       "Longitude": -11.92870044708252,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2800,
//       "Airport Code [ICAO]": "SPPY",
//       "Airport Code iATA,FAA": "CHH",
//       "Airport Name": "Chachapoyas Airport",
//       "Altitude (Ft)": 8333,
//       "Country": "Peru",
//       "Latitude": -77.8561019897461,
//       "Location": "Chachapoyas",
//       "Longitude": -6.201809883117676,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2801,
//       "Airport Code [ICAO]": "SPQT",
//       "Airport Code iATA,FAA": "IQT",
//       "Airport Name": "Coronel FAP Francisco Secada Vignetta International Airport",
//       "Altitude (Ft)": 306,
//       "Country": "Peru",
//       "Latitude": -73.30879974365234,
//       "Location": "Iquitos",
//       "Longitude": -3.7847399711608887,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2802,
//       "Airport Code [ICAO]": "SPQU",
//       "Airport Code iATA,FAA": "AQP",
//       "Airport Name": "Rodríguez Ballón International Airport",
//       "Altitude (Ft)": 8405,
//       "Country": "Peru",
//       "Latitude": -71.5830993652,
//       "Location": "Arequipa",
//       "Longitude": -16.3411006927,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2803,
//       "Airport Code [ICAO]": "SPRM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Capitán FAP Leonardo Alvariño Herr Airport",
//       "Altitude (Ft)": 2600,
//       "Country": "Peru",
//       "Latitude": -75.35050201416016,
//       "Location": "San Ramon",
//       "Longitude": -11.128600120544434,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2804,
//       "Airport Code [ICAO]": "SPRU",
//       "Airport Code iATA,FAA": "TRU",
//       "Airport Name": "Capitan FAP Carlos Martinez De Pinillos International Airport",
//       "Altitude (Ft)": 106,
//       "Country": "Peru",
//       "Latitude": -79.10880279541016,
//       "Location": "Trujillo",
//       "Longitude": -8.08141040802002,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2805,
//       "Airport Code [ICAO]": "SPSO",
//       "Airport Code iATA,FAA": "PIO",
//       "Airport Name": "Capitán FAP Renán Elías Olivera International Airport",
//       "Altitude (Ft)": 39,
//       "Country": "Peru",
//       "Latitude": -76.22029876708984,
//       "Location": "Pisco",
//       "Longitude": -13.74489974975586,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2806,
//       "Airport Code [ICAO]": "SPST",
//       "Airport Code iATA,FAA": "TPP",
//       "Airport Name": "Cadete FAP Guillermo Del Castillo Paredes Airport",
//       "Altitude (Ft)": 869,
//       "Country": "Peru",
//       "Latitude": -76.37319946289062,
//       "Location": "Tarapoto",
//       "Longitude": -6.508739948272705,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2807,
//       "Airport Code [ICAO]": "SPTN",
//       "Airport Code iATA,FAA": "TCQ",
//       "Airport Name": "Coronel FAP Carlos Ciriani Santa Rosa International Airport",
//       "Altitude (Ft)": 1538,
//       "Country": "Peru",
//       "Latitude": -70.2758026123,
//       "Location": "Tacna",
//       "Longitude": -18.053300857500002,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2808,
//       "Airport Code [ICAO]": "SPTU",
//       "Airport Code iATA,FAA": "PEM",
//       "Airport Name": "Padre Aldamiz International Airport",
//       "Altitude (Ft)": 659,
//       "Country": "Peru",
//       "Latitude": -69.2285995483,
//       "Location": "Puerto Maldonado",
//       "Longitude": -12.6135997772,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2809,
//       "Airport Code [ICAO]": "SPUR",
//       "Airport Code iATA,FAA": "PIU",
//       "Airport Name": "Capitán FAP Guillermo Concha Iberico International Airport",
//       "Altitude (Ft)": 120,
//       "Country": "Peru",
//       "Latitude": -80.61640167239999,
//       "Location": "Piura",
//       "Longitude": -5.20574998856,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2810,
//       "Airport Code [ICAO]": "SPYL",
//       "Airport Code iATA,FAA": "TYL",
//       "Airport Name": "Capitan Montes Airport",
//       "Altitude (Ft)": 282,
//       "Country": "Peru",
//       "Latitude": -81.254096984863,
//       "Location": "Talara",
//       "Longitude": -4.5766401290894,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2812,
//       "Airport Code [ICAO]": "SPZO",
//       "Airport Code iATA,FAA": "CUZ",
//       "Airport Name": "Alejandro Velasco Astete International Airport",
//       "Altitude (Ft)": 10860,
//       "Country": "Peru",
//       "Latitude": -71.9387969971,
//       "Location": "Cuzco",
//       "Longitude": -13.535699844400002,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 2813,
//       "Airport Code [ICAO]": "SUAA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Angel S Adami Airport",
//       "Altitude (Ft)": 174,
//       "Country": "Uruguay",
//       "Latitude": -56.264702,
//       "Location": "Montevideo",
//       "Longitude": -34.7892,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Montevideo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2814,
//       "Airport Code [ICAO]": "SUDU",
//       "Airport Code iATA,FAA": "DZO",
//       "Airport Name": "Santa Bernardina International Airport",
//       "Altitude (Ft)": 305,
//       "Country": "Uruguay",
//       "Latitude": -56.49919891357422,
//       "Location": "Durazno",
//       "Longitude": -33.3588981628418,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Montevideo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2816,
//       "Airport Code [ICAO]": "SUMU",
//       "Airport Code iATA,FAA": "MVD",
//       "Airport Name": "Carrasco International /General C L Berisso Airport",
//       "Altitude (Ft)": 105,
//       "Country": "Uruguay",
//       "Latitude": -56.0308,
//       "Location": "Montevideo",
//       "Longitude": -34.838402,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Montevideo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2817,
//       "Airport Code [ICAO]": "SUSO",
//       "Airport Code iATA,FAA": "STY",
//       "Airport Name": "Nueva Hesperides International Airport",
//       "Altitude (Ft)": 187,
//       "Country": "Uruguay",
//       "Latitude": -57.98529815673828,
//       "Location": "Salto",
//       "Longitude": -31.438499450683594,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Montevideo",
//       "Type": "airport"
//     },
//     {
//       "ID": 2818,
//       "Airport Code [ICAO]": "SVAC",
//       "Airport Code iATA,FAA": "AGV",
//       "Airport Name": "Oswaldo Guevara Mujica Airport",
//       "Altitude (Ft)": 640,
//       "Country": "Venezuela",
//       "Latitude": -69.23786926269531,
//       "Location": "Acarigua",
//       "Longitude": 9.553375244140625,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2819,
//       "Airport Code [ICAO]": "SVAN",
//       "Airport Code iATA,FAA": "AAO",
//       "Airport Name": "Anaco Airport",
//       "Altitude (Ft)": 721,
//       "Country": "Venezuela",
//       "Latitude": -64.4707260131836,
//       "Location": "Anaco",
//       "Longitude": 9.430225372314453,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2820,
//       "Airport Code [ICAO]": "SVAT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "San Fernando de Atabapo Airport",
//       "Altitude (Ft)": 301,
//       "Country": "Venezuela",
//       "Latitude": -67.6996994019,
//       "Location": "San Fernando Deatabapo",
//       "Longitude": 4.05104017258,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2821,
//       "Airport Code [ICAO]": "SVBC",
//       "Airport Code iATA,FAA": "BLA",
//       "Airport Name": "General José Antonio Anzoategui International Airport",
//       "Altitude (Ft)": 30,
//       "Country": "Venezuela",
//       "Latitude": -64.692222,
//       "Location": "Barcelona",
//       "Longitude": 10.111111,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2822,
//       "Airport Code [ICAO]": "SVBI",
//       "Airport Code iATA,FAA": "BNS",
//       "Airport Name": "Barinas Airport",
//       "Altitude (Ft)": 615,
//       "Country": "Venezuela",
//       "Latitude": -70.21416667,
//       "Location": "Barinas",
//       "Longitude": 8.615,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2823,
//       "Airport Code [ICAO]": "SVBL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "El Libertador Airbase",
//       "Altitude (Ft)": 1450,
//       "Country": "Venezuela",
//       "Latitude": -67.55731964110001,
//       "Location": "Maracaibo",
//       "Longitude": 10.183375358600001,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2824,
//       "Airport Code [ICAO]": "SVBM",
//       "Airport Code iATA,FAA": "BRM",
//       "Airport Name": "Barquisimeto International Airport",
//       "Altitude (Ft)": 2042,
//       "Country": "Venezuela",
//       "Latitude": -69.3586196899414,
//       "Location": "Barquisimeto",
//       "Longitude": 10.042746543884277,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2826,
//       "Airport Code [ICAO]": "SVCB",
//       "Airport Code iATA,FAA": "CBL",
//       "Airport Name": "Aeropuerto \"General Tomas de Heres\". Ciudad Bolivar",
//       "Altitude (Ft)": 197,
//       "Country": "Venezuela",
//       "Latitude": -63.5369567871,
//       "Location": "Ciudad Bolivar",
//       "Longitude": 8.12216091156,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2827,
//       "Airport Code [ICAO]": "SVCD",
//       "Airport Code iATA,FAA": "CXA",
//       "Airport Name": "Caicara del Orinoco Airport",
//       "Altitude (Ft)": 141,
//       "Country": "Venezuela",
//       "Latitude": -66.16280364990234,
//       "Location": "Caicara De Orinoco",
//       "Longitude": 7.625510215759277,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2828,
//       "Airport Code [ICAO]": "SVCJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "San Carlos Airport",
//       "Altitude (Ft)": 512,
//       "Country": "Venezuela",
//       "Latitude": -68.57469940185547,
//       "Location": "San Carlos",
//       "Longitude": 9.647720336914062,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2829,
//       "Airport Code [ICAO]": "SVCL",
//       "Airport Code iATA,FAA": "CLZ",
//       "Airport Name": "Calabozo Airport",
//       "Altitude (Ft)": 328,
//       "Country": "Venezuela",
//       "Latitude": -67.4170913696289,
//       "Location": "Calabozo",
//       "Longitude": 8.92465591430664,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2830,
//       "Airport Code [ICAO]": "SVCN",
//       "Airport Code iATA,FAA": "CAJ",
//       "Airport Name": "Canaima Airport",
//       "Altitude (Ft)": 1450,
//       "Country": "Venezuela",
//       "Latitude": -62.85443115234375,
//       "Location": "Canaima",
//       "Longitude": 6.231988906860352,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2831,
//       "Airport Code [ICAO]": "SVCO",
//       "Airport Code iATA,FAA": "VCR",
//       "Airport Name": "Carora Airport",
//       "Altitude (Ft)": 1437,
//       "Country": "Venezuela",
//       "Latitude": -70.06521606445312,
//       "Location": "Carora",
//       "Longitude": 10.175602912902832,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2832,
//       "Airport Code [ICAO]": "SVCP",
//       "Airport Code iATA,FAA": "CUP",
//       "Airport Name": "General Francisco Bermúdez Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Venezuela",
//       "Latitude": -63.261680603027344,
//       "Location": "Carupano",
//       "Longitude": 10.660014152526855,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2833,
//       "Airport Code [ICAO]": "SVCR",
//       "Airport Code iATA,FAA": "CZE",
//       "Airport Name": "José Leonardo Chirinos Airport",
//       "Altitude (Ft)": 52,
//       "Country": "Venezuela",
//       "Latitude": -69.68090057373047,
//       "Location": "Coro",
//       "Longitude": 11.41494369506836,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2834,
//       "Airport Code [ICAO]": "SVCS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Oscar Machado Zuluaga Airport",
//       "Altitude (Ft)": 2145,
//       "Country": "Venezuela",
//       "Latitude": -66.81610870361328,
//       "Location": "Caracas",
//       "Longitude": 10.286110877990723,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2835,
//       "Airport Code [ICAO]": "SVCU",
//       "Airport Code iATA,FAA": "CUM",
//       "Airport Name": "Cumaná (Antonio José de Sucre) Airport",
//       "Altitude (Ft)": 14,
//       "Country": "Venezuela",
//       "Latitude": -64.1304702758789,
//       "Location": "Cumana",
//       "Longitude": 10.450332641601562,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2836,
//       "Airport Code [ICAO]": "SVCZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Capitán Manuel Ríos Airbase",
//       "Altitude (Ft)": 595,
//       "Country": "Venezuela",
//       "Latitude": -66.92279815673828,
//       "Location": "Carrizal",
//       "Longitude": 9.372650146484375,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2837,
//       "Airport Code [ICAO]": "SVED",
//       "Airport Code iATA,FAA": "EOR",
//       "Airport Name": "El Dorado Airport",
//       "Altitude (Ft)": 318,
//       "Country": "Venezuela",
//       "Latitude": -61.58333206176758,
//       "Location": "El Dorado",
//       "Longitude": 6.733333110809326,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2838,
//       "Airport Code [ICAO]": "SVEZ",
//       "Airport Code iATA,FAA": "EOZ",
//       "Airport Name": "Elorza Airport",
//       "Altitude (Ft)": 295,
//       "Country": "Venezuela",
//       "Latitude": -69.53333282470703,
//       "Location": "Elorza",
//       "Longitude": 7.0833330154418945,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2839,
//       "Airport Code [ICAO]": "SVGD",
//       "Airport Code iATA,FAA": "GDO",
//       "Airport Name": "Guasdalito Airport",
//       "Altitude (Ft)": 426,
//       "Country": "Venezuela",
//       "Latitude": -70.80000305175781,
//       "Location": "Guasdualito",
//       "Longitude": 7.233333110809326,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2840,
//       "Airport Code [ICAO]": "SVGI",
//       "Airport Code iATA,FAA": "GUI",
//       "Airport Name": "Guiria Airport",
//       "Altitude (Ft)": 42,
//       "Country": "Venezuela",
//       "Latitude": -62.3126678467,
//       "Location": "Guiria",
//       "Longitude": 10.574077606200001,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2841,
//       "Airport Code [ICAO]": "SVGU",
//       "Airport Code iATA,FAA": "GUQ",
//       "Airport Name": "Guanare Airport",
//       "Altitude (Ft)": 606,
//       "Country": "Venezuela",
//       "Latitude": -69.7551498413086,
//       "Location": "Guanare",
//       "Longitude": 9.026944160461426,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2842,
//       "Airport Code [ICAO]": "SVHG",
//       "Airport Code iATA,FAA": "HGE",
//       "Airport Name": "Higuerote Airport",
//       "Altitude (Ft)": 12,
//       "Country": "Venezuela",
//       "Latitude": -66.092779,
//       "Location": "Higuerote",
//       "Longitude": 10.462474,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2843,
//       "Airport Code [ICAO]": "SVIE",
//       "Airport Code iATA,FAA": "ICC",
//       "Airport Name": "Andrés Miguel Salazar Marcano Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Venezuela",
//       "Latitude": -63.98159,
//       "Location": "Isla De Coche",
//       "Longitude": 10.794432,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2844,
//       "Airport Code [ICAO]": "SVJC",
//       "Airport Code iATA,FAA": "LSP",
//       "Airport Name": "Josefa Camejo International Airport",
//       "Altitude (Ft)": 75,
//       "Country": "Venezuela",
//       "Latitude": -70.15149688720703,
//       "Location": "Paraguana",
//       "Longitude": 11.78077507019043,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2845,
//       "Airport Code [ICAO]": "SVJM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "San Juan de Los Morros Airport",
//       "Altitude (Ft)": 1404,
//       "Country": "Venezuela",
//       "Latitude": -67.379638671875,
//       "Location": "San Juan De Los Morros",
//       "Longitude": 9.906952857971191,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2846,
//       "Airport Code [ICAO]": "SVLF",
//       "Airport Code iATA,FAA": "LFR",
//       "Airport Name": "La Fria Airport",
//       "Altitude (Ft)": 305,
//       "Country": "Venezuela",
//       "Latitude": -72.27102661132812,
//       "Location": "La Fria",
//       "Longitude": 8.239167213439941,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2847,
//       "Airport Code [ICAO]": "SVLO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "La Orchila Airport",
//       "Altitude (Ft)": 5,
//       "Country": "Venezuela",
//       "Latitude": -66.17960357666016,
//       "Location": "La Orchila",
//       "Longitude": 11.80720043182373,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2848,
//       "Airport Code [ICAO]": "SVMC",
//       "Airport Code iATA,FAA": "MAR",
//       "Airport Name": "La Chinita International Airport",
//       "Altitude (Ft)": 239,
//       "Country": "Venezuela",
//       "Latitude": -71.7278594971,
//       "Location": "Maracaibo",
//       "Longitude": 10.5582084656,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2849,
//       "Airport Code [ICAO]": "SVMD",
//       "Airport Code iATA,FAA": "MRD",
//       "Airport Name": "Alberto Carnevalli Airport",
//       "Altitude (Ft)": 5007,
//       "Country": "Venezuela",
//       "Latitude": -71.161041,
//       "Location": "Merida",
//       "Longitude": 8.582078,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2850,
//       "Airport Code [ICAO]": "SVMG",
//       "Airport Code iATA,FAA": "PMV",
//       "Airport Name": "Del Caribe Santiago Mariño International Airport",
//       "Altitude (Ft)": 74,
//       "Country": "Venezuela",
//       "Latitude": -63.96659851074219,
//       "Location": "Porlamar",
//       "Longitude": 10.912603378295898,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2851,
//       "Airport Code [ICAO]": "SVMI",
//       "Airport Code iATA,FAA": "CCS",
//       "Airport Name": "Simón Bolívar International Airport",
//       "Altitude (Ft)": 234,
//       "Country": "Venezuela",
//       "Latitude": -66.991222,
//       "Location": "Caracas",
//       "Longitude": 10.601194,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2852,
//       "Airport Code [ICAO]": "SVMT",
//       "Airport Code iATA,FAA": "MUN",
//       "Airport Name": "Maturín Airport",
//       "Altitude (Ft)": 224,
//       "Country": "Venezuela",
//       "Latitude": -63.14739990234375,
//       "Location": "Maturin",
//       "Longitude": 9.75452995300293,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2853,
//       "Airport Code [ICAO]": "SVPA",
//       "Airport Code iATA,FAA": "PYH",
//       "Airport Name": "Cacique Aramare Airport",
//       "Altitude (Ft)": 245,
//       "Country": "Venezuela",
//       "Latitude": -67.606101989746,
//       "Location": "Puerto Ayacucho",
//       "Longitude": 5.6199898719788,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2854,
//       "Airport Code [ICAO]": "SVPC",
//       "Airport Code iATA,FAA": "PBL",
//       "Airport Name": "General Bartolome Salom International Airport",
//       "Altitude (Ft)": 32,
//       "Country": "Venezuela",
//       "Latitude": -68.072998046875,
//       "Location": "Puerto Cabello",
//       "Longitude": 10.480500221252441,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2855,
//       "Airport Code [ICAO]": "SVPM",
//       "Airport Code iATA,FAA": "SCI",
//       "Airport Name": "Paramillo Airport",
//       "Altitude (Ft)": 3314,
//       "Country": "Venezuela",
//       "Latitude": -72.2029037475586,
//       "Location": "San Cristobal",
//       "Longitude": 7.8013200759887695,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2856,
//       "Airport Code [ICAO]": "SVPR",
//       "Airport Code iATA,FAA": "PZO",
//       "Airport Name": "General Manuel Carlos Piar International Airport",
//       "Altitude (Ft)": 472,
//       "Country": "Venezuela",
//       "Latitude": -62.760398864746094,
//       "Location": "Guayana",
//       "Longitude": 8.288530349731445,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2857,
//       "Airport Code [ICAO]": "SVPT",
//       "Airport Code iATA,FAA": "PTM",
//       "Airport Name": "Palmarito Airport",
//       "Altitude (Ft)": 347,
//       "Country": "Venezuela",
//       "Latitude": -70.18329620361328,
//       "Location": "Palmarito",
//       "Longitude": 7.566669940948486,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2858,
//       "Airport Code [ICAO]": "SVSA",
//       "Airport Code iATA,FAA": "SVZ",
//       "Airport Name": "San Antonio Del Tachira Airport",
//       "Altitude (Ft)": 1312,
//       "Country": "Venezuela",
//       "Latitude": -72.439697265625,
//       "Location": "San Antonio",
//       "Longitude": 7.840829849243164,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2859,
//       "Airport Code [ICAO]": "SVSB",
//       "Airport Code iATA,FAA": "SBB",
//       "Airport Name": "Santa Bárbara de Barinas Airport",
//       "Altitude (Ft)": 590,
//       "Country": "Venezuela",
//       "Latitude": -71.16571807861328,
//       "Location": "Santa Barbara",
//       "Longitude": 7.803514003753662,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2860,
//       "Airport Code [ICAO]": "SVSE",
//       "Airport Code iATA,FAA": "SNV",
//       "Airport Name": "Santa Elena de Uairen Airport",
//       "Altitude (Ft)": 2938,
//       "Country": "Venezuela",
//       "Latitude": -61.150001525878906,
//       "Location": "Santa Ana De Uairen",
//       "Longitude": 4.554999828338623,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2861,
//       "Airport Code [ICAO]": "SVSO",
//       "Airport Code iATA,FAA": "STD",
//       "Airport Name": "Mayor Buenaventura Vivas International Airport",
//       "Altitude (Ft)": 1083,
//       "Country": "Venezuela",
//       "Latitude": -72.035103,
//       "Location": "Santo Domingo",
//       "Longitude": 7.56538,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2862,
//       "Airport Code [ICAO]": "SVSP",
//       "Airport Code iATA,FAA": "SNF",
//       "Airport Name": "Sub Teniente Nestor Arias Airport",
//       "Altitude (Ft)": 761,
//       "Country": "Venezuela",
//       "Latitude": -68.755203,
//       "Location": "San Felipe",
//       "Longitude": 10.2787,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2863,
//       "Airport Code [ICAO]": "SVSR",
//       "Airport Code iATA,FAA": "SFD",
//       "Airport Name": "San Fernando De Apure Airport",
//       "Altitude (Ft)": 154,
//       "Country": "Venezuela",
//       "Latitude": -67.44400024414062,
//       "Location": "San Fernando De Apure",
//       "Longitude": 7.883319854736328,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2864,
//       "Airport Code [ICAO]": "SVST",
//       "Airport Code iATA,FAA": "SOM",
//       "Airport Name": "San Tomé Airport",
//       "Altitude (Ft)": 861,
//       "Country": "Venezuela",
//       "Latitude": -64.151084899902,
//       "Location": "San Tome",
//       "Longitude": 8.9451465606689,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2865,
//       "Airport Code [ICAO]": "SVSZ",
//       "Airport Code iATA,FAA": "STB",
//       "Airport Name": "Santa Bárbara del Zulia Airport",
//       "Altitude (Ft)": 32,
//       "Country": "Venezuela",
//       "Latitude": -71.94325256347656,
//       "Location": "Santa Barbara",
//       "Longitude": 8.974550247192383,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2866,
//       "Airport Code [ICAO]": "SVTC",
//       "Airport Code iATA,FAA": "TUV",
//       "Airport Name": "Tucupita Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Venezuela",
//       "Latitude": -62.094173431396484,
//       "Location": "Tucupita",
//       "Longitude": 9.088994026184082,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2867,
//       "Airport Code [ICAO]": "SVTM",
//       "Airport Code iATA,FAA": "TMO",
//       "Airport Name": "Tumeremo Airport",
//       "Altitude (Ft)": 345,
//       "Country": "Venezuela",
//       "Latitude": -61.52893,
//       "Location": "Tumeremo",
//       "Longitude": 7.24938,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2868,
//       "Airport Code [ICAO]": "SVVA",
//       "Airport Code iATA,FAA": "VLN",
//       "Airport Name": "Arturo Michelena International Airport",
//       "Altitude (Ft)": 1411,
//       "Country": "Venezuela",
//       "Latitude": -67.92839813232422,
//       "Location": "Valencia",
//       "Longitude": 10.14973258972168,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2870,
//       "Airport Code [ICAO]": "SVVL",
//       "Airport Code iATA,FAA": "VLV",
//       "Airport Name": "Dr. Antonio Nicolás Briceño Airport",
//       "Altitude (Ft)": 2060,
//       "Country": "Venezuela",
//       "Latitude": -70.58406066894531,
//       "Location": "Valera",
//       "Longitude": 9.34047794342041,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2871,
//       "Airport Code [ICAO]": "SVVP",
//       "Airport Code iATA,FAA": "VDP",
//       "Airport Name": "Valle de La Pascua Airport",
//       "Altitude (Ft)": 410,
//       "Country": "Venezuela",
//       "Latitude": -65.9935836792,
//       "Location": "Valle De La Pascua",
//       "Longitude": 9.22202777863,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2872,
//       "Airport Code [ICAO]": "SYLD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Linden Airport",
//       "Altitude (Ft)": 180,
//       "Country": "Guyana",
//       "Latitude": -58.270302,
//       "Location": "Linden",
//       "Longitude": 5.96592,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Guyana",
//       "Type": "airport"
//     },
//     {
//       "ID": 2873,
//       "Airport Code [ICAO]": "SYLT",
//       "Airport Code iATA,FAA": "LTM",
//       "Airport Name": "Lethem Airport",
//       "Altitude (Ft)": 351,
//       "Country": "Guyana",
//       "Latitude": -59.789398,
//       "Location": "Lethem",
//       "Longitude": 3.37276,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Guyana",
//       "Type": "airport"
//     },
//     {
//       "ID": 2874,
//       "Airport Code [ICAO]": "TAPA",
//       "Airport Code iATA,FAA": "ANU",
//       "Airport Name": "V.C. Bird International Airport",
//       "Altitude (Ft)": 62,
//       "Country": "Antigua and Barbuda",
//       "Latitude": -61.792702,
//       "Location": "Antigua",
//       "Longitude": 17.1367,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Antigua",
//       "Type": "airport"
//     },
//     {
//       "ID": 2875,
//       "Airport Code [ICAO]": "TBPB",
//       "Airport Code iATA,FAA": "BGI",
//       "Airport Name": "Sir Grantley Adams International Airport",
//       "Altitude (Ft)": 169,
//       "Country": "Barbados",
//       "Latitude": -59.4925003052,
//       "Location": "Bridgetown",
//       "Longitude": 13.0746002197,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Barbados",
//       "Type": "airport"
//     },
//     {
//       "ID": 2876,
//       "Airport Code [ICAO]": "TDCF",
//       "Airport Code iATA,FAA": "DCF",
//       "Airport Name": "Canefield Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Dominica",
//       "Latitude": -61.3922004699707,
//       "Location": "Canefield",
//       "Longitude": 15.336700439453125,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Dominica",
//       "Type": "airport"
//     },
//     {
//       "ID": 2877,
//       "Airport Code [ICAO]": "TDPD",
//       "Airport Code iATA,FAA": "DOM",
//       "Airport Name": "Douglas-Charles Airport",
//       "Altitude (Ft)": 73,
//       "Country": "Dominica",
//       "Latitude": -61.299999,
//       "Location": "Dominica",
//       "Longitude": 15.547,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Dominica",
//       "Type": "airport"
//     },
//     {
//       "ID": 2878,
//       "Airport Code [ICAO]": "TFFF",
//       "Airport Code iATA,FAA": "FDF",
//       "Airport Name": "Martinique Aimé Césaire International Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Martinique",
//       "Latitude": -61.00320053100586,
//       "Location": "Fort-de-france",
//       "Longitude": 14.590999603271484,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Martinique",
//       "Type": "airport"
//     },
//     {
//       "ID": 2879,
//       "Airport Code [ICAO]": "TFFG",
//       "Airport Code iATA,FAA": "SFG",
//       "Airport Name": "L'Espérance Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Guadeloupe",
//       "Latitude": -63.047199249268,
//       "Location": "St. Martin",
//       "Longitude": 18.099899291992,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 2881,
//       "Airport Code [ICAO]": "TFFR",
//       "Airport Code iATA,FAA": "PTP",
//       "Airport Name": "Pointe-à-Pitre Le Raizet",
//       "Altitude (Ft)": 36,
//       "Country": "Guadeloupe",
//       "Latitude": -61.531799,
//       "Location": "Pointe-a-pitre",
//       "Longitude": 16.265301,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Guadeloupe",
//       "Type": "airport"
//     },
//     {
//       "ID": 2882,
//       "Airport Code [ICAO]": "TGPY",
//       "Airport Code iATA,FAA": "GND",
//       "Airport Name": "Point Salines International Airport",
//       "Altitude (Ft)": 41,
//       "Country": "Grenada",
//       "Latitude": -61.78620147705078,
//       "Location": "Point Salines",
//       "Longitude": 12.004199981689453,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Grenada",
//       "Type": "airport"
//     },
//     {
//       "ID": 2883,
//       "Airport Code [ICAO]": "TIST",
//       "Airport Code iATA,FAA": "STT",
//       "Airport Name": "Cyril E. King Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Virgin Islands",
//       "Latitude": -64.97339630126953,
//       "Location": "St. Thomas",
//       "Longitude": 18.337299346923828,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/St_Thomas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2884,
//       "Airport Code [ICAO]": "TISX",
//       "Airport Code iATA,FAA": "STX",
//       "Airport Name": "Henry E Rohlsen Airport",
//       "Altitude (Ft)": 74,
//       "Country": "Virgin Islands",
//       "Latitude": -64.79859924316406,
//       "Location": "St. Croix Island",
//       "Longitude": 17.701900482177734,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/St_Thomas",
//       "Type": "airport"
//     },
//     {
//       "ID": 2885,
//       "Airport Code [ICAO]": "TJBQ",
//       "Airport Code iATA,FAA": "BQN",
//       "Airport Name": "Rafael Hernandez Airport",
//       "Altitude (Ft)": 237,
//       "Country": "Puerto Rico",
//       "Latitude": -67.12940216064453,
//       "Location": "Aguadilla",
//       "Longitude": 18.49489974975586,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Puerto_Rico",
//       "Type": "airport"
//     },
//     {
//       "ID": 2886,
//       "Airport Code [ICAO]": "TJFA",
//       "Airport Code iATA,FAA": "FAJ",
//       "Airport Name": "Diego Jimenez Torres Airport",
//       "Altitude (Ft)": 64,
//       "Country": "Puerto Rico",
//       "Latitude": -65.66190338134766,
//       "Location": "Fajardo",
//       "Longitude": 18.308900833129883,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Puerto_Rico",
//       "Type": "airport"
//     },
//     {
//       "ID": 2887,
//       "Airport Code [ICAO]": "TJIG",
//       "Airport Code iATA,FAA": "SIG",
//       "Airport Name": "Fernando Luis Ribas Dominicci Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Puerto Rico",
//       "Latitude": -66.09809875488281,
//       "Location": "San Juan",
//       "Longitude": 18.45680046081543,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Puerto_Rico",
//       "Type": "airport"
//     },
//     {
//       "ID": 2888,
//       "Airport Code [ICAO]": "TJMZ",
//       "Airport Code iATA,FAA": "MAZ",
//       "Airport Name": "Eugenio Maria De Hostos Airport",
//       "Altitude (Ft)": 28,
//       "Country": "Puerto Rico",
//       "Latitude": -67.14849853515625,
//       "Location": "Mayaguez",
//       "Longitude": 18.255699157714844,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Puerto_Rico",
//       "Type": "airport"
//     },
//     {
//       "ID": 2889,
//       "Airport Code [ICAO]": "TJPS",
//       "Airport Code iATA,FAA": "PSE",
//       "Airport Name": "Mercedita Airport",
//       "Altitude (Ft)": 29,
//       "Country": "Puerto Rico",
//       "Latitude": -66.56300354003906,
//       "Location": "Ponce",
//       "Longitude": 18.00830078125,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Puerto_Rico",
//       "Type": "airport"
//     },
//     {
//       "ID": 2890,
//       "Airport Code [ICAO]": "TJSJ",
//       "Airport Code iATA,FAA": "SJU",
//       "Airport Name": "Luis Munoz Marin International Airport",
//       "Altitude (Ft)": 9,
//       "Country": "Puerto Rico",
//       "Latitude": -66.0018005371,
//       "Location": "San Juan",
//       "Longitude": 18.4393997192,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Puerto_Rico",
//       "Type": "airport"
//     },
//     {
//       "ID": 2891,
//       "Airport Code [ICAO]": "TKPK",
//       "Airport Code iATA,FAA": "SKB",
//       "Airport Name": "Robert L. Bradshaw International Airport",
//       "Altitude (Ft)": 170,
//       "Country": "Saint Kitts and Nevis",
//       "Latitude": -62.71870040893555,
//       "Location": "Basse Terre",
//       "Longitude": 17.311199188232422,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/St_Kitts",
//       "Type": "airport"
//     },
//     {
//       "ID": 2893,
//       "Airport Code [ICAO]": "TLPC",
//       "Airport Code iATA,FAA": "SLU",
//       "Airport Name": "George F. L. Charles Airport",
//       "Altitude (Ft)": 22,
//       "Country": "Saint Lucia",
//       "Latitude": -60.992901,
//       "Location": "Castries",
//       "Longitude": 14.0202,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/St_Lucia",
//       "Type": "airport"
//     },
//     {
//       "ID": 2894,
//       "Airport Code [ICAO]": "TLPL",
//       "Airport Code iATA,FAA": "UVF",
//       "Airport Name": "Hewanorra International Airport",
//       "Altitude (Ft)": 14,
//       "Country": "Saint Lucia",
//       "Latitude": -60.952599,
//       "Location": "Hewandorra",
//       "Longitude": 13.7332,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/St_Lucia",
//       "Type": "airport"
//     },
//     {
//       "ID": 2895,
//       "Airport Code [ICAO]": "TNCA",
//       "Airport Code iATA,FAA": "AUA",
//       "Airport Name": "Queen Beatrix International Airport",
//       "Altitude (Ft)": 60,
//       "Country": "Aruba",
//       "Latitude": -70.015198,
//       "Location": "Oranjestad",
//       "Longitude": 12.5014,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Aruba",
//       "Type": "airport"
//     },
//     {
//       "ID": 2896,
//       "Airport Code [ICAO]": "TNCB",
//       "Airport Code iATA,FAA": "BON",
//       "Airport Name": "Flamingo International Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Netherlands Antilles",
//       "Latitude": -68.26850128173828,
//       "Location": "Kralendijk",
//       "Longitude": 12.130999565124512,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Curacao",
//       "Type": "airport"
//     },
//     {
//       "ID": 2897,
//       "Airport Code [ICAO]": "TNCC",
//       "Airport Code iATA,FAA": "CUR",
//       "Airport Name": "Hato International Airport",
//       "Altitude (Ft)": 29,
//       "Country": "Netherlands Antilles",
//       "Latitude": -68.959801,
//       "Location": "Willemstad",
//       "Longitude": 12.1889,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Curacao",
//       "Type": "airport"
//     },
//     {
//       "ID": 2898,
//       "Airport Code [ICAO]": "TNCE",
//       "Airport Code iATA,FAA": "EUX",
//       "Airport Name": "F. D. Roosevelt Airport",
//       "Altitude (Ft)": 129,
//       "Country": "Netherlands Antilles",
//       "Latitude": -62.979400634765625,
//       "Location": "Oranjestad",
//       "Longitude": 17.49650001525879,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Curacao",
//       "Type": "airport"
//     },
//     {
//       "ID": 2899,
//       "Airport Code [ICAO]": "TNCM",
//       "Airport Code iATA,FAA": "SXM",
//       "Airport Name": "Princess Juliana International Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Netherlands Antilles",
//       "Latitude": -63.1088981628,
//       "Location": "Philipsburg",
//       "Longitude": 18.041000366200002,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Curacao",
//       "Type": "airport"
//     },
//     {
//       "ID": 2900,
//       "Airport Code [ICAO]": "TQPF",
//       "Airport Code iATA,FAA": "AXA",
//       "Airport Name": "Clayton J Lloyd International Airport",
//       "Altitude (Ft)": 127,
//       "Country": "Anguilla",
//       "Latitude": -63.055099,
//       "Location": "The Valley",
//       "Longitude": 18.2048,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Anguilla",
//       "Type": "airport"
//     },
//     {
//       "ID": 2901,
//       "Airport Code [ICAO]": "TTCP",
//       "Airport Code iATA,FAA": "TAB",
//       "Airport Name": "Tobago-Crown Point Airport",
//       "Altitude (Ft)": 38,
//       "Country": "Trinidad and Tobago",
//       "Latitude": -60.83219909667969,
//       "Location": "Scarborough",
//       "Longitude": 11.149700164794922,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Port_of_Spain",
//       "Type": "airport"
//     },
//     {
//       "ID": 2902,
//       "Airport Code [ICAO]": "TTPP",
//       "Airport Code iATA,FAA": "POS",
//       "Airport Name": "Piarco International Airport",
//       "Altitude (Ft)": 58,
//       "Country": "Trinidad and Tobago",
//       "Latitude": -61.33720016479492,
//       "Location": "Port-of-spain",
//       "Longitude": 10.595399856567383,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Port_of_Spain",
//       "Type": "airport"
//     },
//     {
//       "ID": 2903,
//       "Airport Code [ICAO]": "TUPJ",
//       "Airport Code iATA,FAA": "EIS",
//       "Airport Name": "Terrance B. Lettsome International Airport",
//       "Altitude (Ft)": 15,
//       "Country": "British Virgin Islands",
//       "Latitude": -64.54299926757812,
//       "Location": "Tortola",
//       "Longitude": 18.444799423217773,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Tortola",
//       "Type": "airport"
//     },
//     {
//       "ID": 2905,
//       "Airport Code [ICAO]": "TVSC",
//       "Airport Code iATA,FAA": "CIW",
//       "Airport Name": "Canouan Airport",
//       "Altitude (Ft)": 11,
//       "Country": "Saint Vincent and the Grenadines",
//       "Latitude": -61.3424,
//       "Location": "Canouan Island",
//       "Longitude": 12.699,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/St_Vincent",
//       "Type": "airport"
//     },
//     {
//       "ID": 2906,
//       "Airport Code [ICAO]": "TVSM",
//       "Airport Code iATA,FAA": "MQS",
//       "Airport Name": "Mustique Airport",
//       "Altitude (Ft)": 8,
//       "Country": "Saint Vincent and the Grenadines",
//       "Latitude": -61.180198669433594,
//       "Location": "Mustique",
//       "Longitude": 12.887900352478027,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/St_Vincent",
//       "Type": "airport"
//     },
//     {
//       "ID": 2907,
//       "Airport Code [ICAO]": "TVSA",
//       "Airport Code iATA,FAA": "SVD",
//       "Airport Name": "Argyle International Airport",
//       "Altitude (Ft)": 136,
//       "Country": "Saint Vincent and the Grenadines",
//       "Latitude": -61.149945,
//       "Location": "Kingstown",
//       "Longitude": 13.156695,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/St_Vincent",
//       "Type": "airport"
//     },
//     {
//       "ID": 2908,
//       "Airport Code [ICAO]": "UAAA",
//       "Airport Code iATA,FAA": "ALA",
//       "Airport Name": "Almaty Airport",
//       "Altitude (Ft)": 2234,
//       "Country": "Kazakhstan",
//       "Latitude": 77.04049682617188,
//       "Location": "Alma-ata",
//       "Longitude": 43.35210037231445,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Qyzylorda",
//       "Type": "airport"
//     },
//     {
//       "ID": 2909,
//       "Airport Code [ICAO]": "UAAH",
//       "Airport Code iATA,FAA": "BXH",
//       "Airport Name": "Balkhash Airport",
//       "Altitude (Ft)": 1446,
//       "Country": "Kazakhstan",
//       "Latitude": 75.00499725341797,
//       "Location": "Balkhash",
//       "Longitude": 46.8932991027832,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Qyzylorda",
//       "Type": "airport"
//     },
//     {
//       "ID": 2910,
//       "Airport Code [ICAO]": "UACC",
//       "Airport Code iATA,FAA": "TSE",
//       "Airport Name": "Astana International Airport",
//       "Altitude (Ft)": 1165,
//       "Country": "Kazakhstan",
//       "Latitude": 71.46690368652344,
//       "Location": "Tselinograd",
//       "Longitude": 51.02220153808594,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Qyzylorda",
//       "Type": "airport"
//     },
//     {
//       "ID": 2911,
//       "Airport Code [ICAO]": "UADD",
//       "Airport Code iATA,FAA": "DMB",
//       "Airport Name": "Taraz Airport",
//       "Altitude (Ft)": 2184,
//       "Country": "Kazakhstan",
//       "Latitude": 71.30359649658203,
//       "Location": "Dzhambul",
//       "Longitude": 42.853599548339844,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Qyzylorda",
//       "Type": "airport"
//     },
//     {
//       "ID": 2912,
//       "Airport Code [ICAO]": "UAFM",
//       "Airport Code iATA,FAA": "FRU",
//       "Airport Name": "Manas International Airport",
//       "Altitude (Ft)": 2058,
//       "Country": "Kyrgyzstan",
//       "Latitude": 74.4776000977,
//       "Location": "Bishkek",
//       "Longitude": 43.0612983704,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bishkek",
//       "Type": "airport"
//     },
//     {
//       "ID": 2913,
//       "Airport Code [ICAO]": "UAFO",
//       "Airport Code iATA,FAA": "OSS",
//       "Airport Name": "Osh Airport",
//       "Altitude (Ft)": 2927,
//       "Country": "Kyrgyzstan",
//       "Latitude": 72.793296814,
//       "Location": "Osh",
//       "Longitude": 40.6090011597,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bishkek",
//       "Type": "airport"
//     },
//     {
//       "ID": 2914,
//       "Airport Code [ICAO]": "UAII",
//       "Airport Code iATA,FAA": "CIT",
//       "Airport Name": "Shymkent Airport",
//       "Altitude (Ft)": 1385,
//       "Country": "Kazakhstan",
//       "Latitude": 69.47889709472656,
//       "Location": "Chimkent",
//       "Longitude": 42.364200592041016,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Qyzylorda",
//       "Type": "airport"
//     },
//     {
//       "ID": 2916,
//       "Airport Code [ICAO]": "UARR",
//       "Airport Code iATA,FAA": "URA",
//       "Airport Name": "Uralsk Airport",
//       "Altitude (Ft)": 125,
//       "Country": "Kazakhstan",
//       "Latitude": 51.54309844970703,
//       "Location": "Uralsk",
//       "Longitude": 51.15079879760742,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Oral",
//       "Type": "airport"
//     },
//     {
//       "ID": 2917,
//       "Airport Code [ICAO]": "UASP",
//       "Airport Code iATA,FAA": "PWQ",
//       "Airport Name": "Pavlodar Airport",
//       "Altitude (Ft)": 410,
//       "Country": "Kazakhstan",
//       "Latitude": 77.07389831542969,
//       "Location": "Pavlodar",
//       "Longitude": 52.19499969482422,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Qyzylorda",
//       "Type": "airport"
//     },
//     {
//       "ID": 2918,
//       "Airport Code [ICAO]": "UASS",
//       "Airport Code iATA,FAA": "PLX",
//       "Airport Name": "Semipalatinsk Airport",
//       "Altitude (Ft)": 761,
//       "Country": "Kazakhstan",
//       "Latitude": 80.234398,
//       "Location": "Semiplatinsk",
//       "Longitude": 50.351295,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Qyzylorda",
//       "Type": "airport"
//     },
//     {
//       "ID": 2920,
//       "Airport Code [ICAO]": "UATT",
//       "Airport Code iATA,FAA": "AKX",
//       "Airport Name": "Aktobe Airport",
//       "Altitude (Ft)": 738,
//       "Country": "Kazakhstan",
//       "Latitude": 57.206699,
//       "Location": "Aktyubinsk",
//       "Longitude": 50.2458,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Oral",
//       "Type": "airport"
//     },
//     {
//       "ID": 2922,
//       "Airport Code [ICAO]": "UBBB",
//       "Airport Code iATA,FAA": "GYD",
//       "Airport Name": "Heydar Aliyev International Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Azerbaijan",
//       "Latitude": 50.04669952392578,
//       "Location": "Baku",
//       "Longitude": 40.467498779296875,
//       "GMT Offset": "4",
//       "Daylight Savings": "E",
//       "Region": "Asia/Baku",
//       "Type": "airport"
//     },
//     {
//       "ID": 2923,
//       "Airport Code [ICAO]": "UEEE",
//       "Airport Code iATA,FAA": "YKS",
//       "Airport Name": "Yakutsk Airport",
//       "Altitude (Ft)": 325,
//       "Country": "Russia",
//       "Latitude": 129.77099609375,
//       "Location": "Yakutsk",
//       "Longitude": 62.093299865722656,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yakutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 2925,
//       "Airport Code [ICAO]": "UERR",
//       "Airport Code iATA,FAA": "MJZ",
//       "Airport Name": "Mirny Airport",
//       "Altitude (Ft)": 1156,
//       "Country": "Russia",
//       "Latitude": 114.03900146484375,
//       "Location": "Mirnyj",
//       "Longitude": 62.534698486328125,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yakutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 2926,
//       "Airport Code [ICAO]": "UHBB",
//       "Airport Code iATA,FAA": "BQS",
//       "Airport Name": "Ignatyevo Airport",
//       "Altitude (Ft)": 638,
//       "Country": "Russia",
//       "Latitude": 127.41200256347656,
//       "Location": "Blagoveschensk",
//       "Longitude": 50.42539978027344,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yakutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 2927,
//       "Airport Code [ICAO]": "UHHH",
//       "Airport Code iATA,FAA": "KHV",
//       "Airport Name": "Khabarovsk-Novy Airport",
//       "Altitude (Ft)": 244,
//       "Country": "Russia",
//       "Latitude": 135.18800354004,
//       "Location": "Khabarovsk",
//       "Longitude": 48.52799987793,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": "Asia/Vladivostok",
//       "Type": "airport"
//     },
//     {
//       "ID": 2929,
//       "Airport Code [ICAO]": "UHMD",
//       "Airport Code iATA,FAA": "PVS",
//       "Airport Name": "Provideniya Bay Airport",
//       "Altitude (Ft)": 72,
//       "Country": "Russia",
//       "Latitude": -173.2429962158203,
//       "Location": "Provideniya Bay",
//       "Longitude": 64.37809753417969,
//       "GMT Offset": "12",
//       "Daylight Savings": "N",
//       "Region": "Asia/Anadyr",
//       "Type": "airport"
//     },
//     {
//       "ID": 2930,
//       "Airport Code [ICAO]": "UHMM",
//       "Airport Code iATA,FAA": "GDX",
//       "Airport Name": "Sokol Airport",
//       "Altitude (Ft)": 574,
//       "Country": "Russia",
//       "Latitude": 150.72000122070312,
//       "Location": "Magadan",
//       "Longitude": 59.9109992980957,
//       "GMT Offset": "11",
//       "Daylight Savings": "N",
//       "Region": "Asia/Srednekolymsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 2931,
//       "Airport Code [ICAO]": "UHMP",
//       "Airport Code iATA,FAA": "PWE",
//       "Airport Name": "Pevek Airport",
//       "Altitude (Ft)": 11,
//       "Country": "Russia",
//       "Latitude": 170.59700012207,
//       "Location": "Pevek",
//       "Longitude": 69.783302307129,
//       "GMT Offset": "12",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 2932,
//       "Airport Code [ICAO]": "UHPP",
//       "Airport Code iATA,FAA": "PKC",
//       "Airport Name": "Yelizovo Airport",
//       "Altitude (Ft)": 131,
//       "Country": "Russia",
//       "Latitude": 158.45399475097656,
//       "Location": "Petropavlovsk",
//       "Longitude": 53.16790008544922,
//       "GMT Offset": "12",
//       "Daylight Savings": "N",
//       "Region": "Asia/Anadyr",
//       "Type": "airport"
//     },
//     {
//       "ID": 2933,
//       "Airport Code [ICAO]": "UHSS",
//       "Airport Code iATA,FAA": "UUS",
//       "Airport Name": "Yuzhno-Sakhalinsk Airport",
//       "Altitude (Ft)": 59,
//       "Country": "Russia",
//       "Latitude": 142.71800231933594,
//       "Location": "Yuzhno-sakhalinsk",
//       "Longitude": 46.88869857788086,
//       "GMT Offset": "11",
//       "Daylight Savings": "N",
//       "Region": "Asia/Srednekolymsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 2934,
//       "Airport Code [ICAO]": "UHWW",
//       "Airport Code iATA,FAA": "VVO",
//       "Airport Name": "Vladivostok International Airport",
//       "Altitude (Ft)": 46,
//       "Country": "Russia",
//       "Latitude": 132.1479949951172,
//       "Location": "Vladivostok",
//       "Longitude": 43.39899826049805,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": "Asia/Vladivostok",
//       "Type": "airport"
//     },
//     {
//       "ID": 2935,
//       "Airport Code [ICAO]": "UIAA",
//       "Airport Code iATA,FAA": "HTA",
//       "Airport Name": "Chita-Kadala Airport",
//       "Altitude (Ft)": 2272,
//       "Country": "Russia",
//       "Latitude": 113.306,
//       "Location": "Chita",
//       "Longitude": 52.026299,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yakutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 2936,
//       "Airport Code [ICAO]": "UIBB",
//       "Airport Code iATA,FAA": "BTK",
//       "Airport Name": "Bratsk Airport",
//       "Altitude (Ft)": 1610,
//       "Country": "Russia",
//       "Latitude": 101.697998046875,
//       "Location": "Bratsk",
//       "Longitude": 56.370601654052734,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Irkutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 2937,
//       "Airport Code [ICAO]": "UIII",
//       "Airport Code iATA,FAA": "IKT",
//       "Airport Name": "Irkutsk Airport",
//       "Altitude (Ft)": 1675,
//       "Country": "Russia",
//       "Latitude": 104.38899993896,
//       "Location": "Irkutsk",
//       "Longitude": 52.268001556396,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Irkutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 2938,
//       "Airport Code [ICAO]": "UIUU",
//       "Airport Code iATA,FAA": "UUD",
//       "Airport Name": "Ulan-Ude Airport (Mukhino)",
//       "Altitude (Ft)": 1690,
//       "Country": "Russia",
//       "Latitude": 107.43800354003906,
//       "Location": "Ulan-ude",
//       "Longitude": 51.80780029296875,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Irkutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 2939,
//       "Airport Code [ICAO]": "UKBB",
//       "Airport Code iATA,FAA": "KBP",
//       "Airport Name": "Boryspil International Airport",
//       "Altitude (Ft)": 427,
//       "Country": "Ukraine",
//       "Latitude": 30.894699096679688,
//       "Location": "Kiev",
//       "Longitude": 50.345001220703125,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 2940,
//       "Airport Code [ICAO]": "UKCC",
//       "Airport Code iATA,FAA": "DOK",
//       "Airport Name": "Donetsk International Airport",
//       "Altitude (Ft)": 791,
//       "Country": "Ukraine",
//       "Latitude": 37.73970031738281,
//       "Location": "Donetsk",
//       "Longitude": 48.07360076904297,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 2941,
//       "Airport Code [ICAO]": "UKDD",
//       "Airport Code iATA,FAA": "DNK",
//       "Airport Name": "Dnipropetrovsk International Airport",
//       "Altitude (Ft)": 481,
//       "Country": "Ukraine",
//       "Latitude": 35.10060119628906,
//       "Location": "Dnepropetrovsk",
//       "Longitude": 48.357200622558594,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 2942,
//       "Airport Code [ICAO]": "UKFF",
//       "Airport Code iATA,FAA": "SIP",
//       "Airport Name": "Simferopol International Airport",
//       "Altitude (Ft)": 639,
//       "Country": "Ukraine",
//       "Latitude": 33.975101470947266,
//       "Location": "Simferopol",
//       "Longitude": 45.05220031738281,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Simferopol",
//       "Type": "airport"
//     },
//     {
//       "ID": 2944,
//       "Airport Code [ICAO]": "UKKK",
//       "Airport Code iATA,FAA": "IEV",
//       "Airport Name": "Kiev Zhuliany International Airport",
//       "Altitude (Ft)": 587,
//       "Country": "Ukraine",
//       "Latitude": 30.45194,
//       "Location": "Kiev",
//       "Longitude": 50.40194,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 2945,
//       "Airport Code [ICAO]": "UKLL",
//       "Airport Code iATA,FAA": "LWO",
//       "Airport Name": "Lviv International Airport",
//       "Altitude (Ft)": 1071,
//       "Country": "Ukraine",
//       "Latitude": 23.956100463867188,
//       "Location": "Lvov",
//       "Longitude": 49.8125,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 2947,
//       "Airport Code [ICAO]": "UKOO",
//       "Airport Code iATA,FAA": "ODS",
//       "Airport Name": "Odessa International Airport",
//       "Altitude (Ft)": 172,
//       "Country": "Ukraine",
//       "Latitude": 30.67650032043457,
//       "Location": "Odessa",
//       "Longitude": 46.42679977416992,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 2948,
//       "Airport Code [ICAO]": "ULLI",
//       "Airport Code iATA,FAA": "LED",
//       "Airport Name": "Pulkovo Airport",
//       "Altitude (Ft)": 78,
//       "Country": "Russia",
//       "Latitude": 30.262500762939453,
//       "Location": "St. Petersburg",
//       "Longitude": 59.80030059814453,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 2949,
//       "Airport Code [ICAO]": "ULMM",
//       "Airport Code iATA,FAA": "MMK",
//       "Airport Name": "Murmansk Airport",
//       "Altitude (Ft)": 266,
//       "Country": "Russia",
//       "Latitude": 32.75080108642578,
//       "Location": "Murmansk",
//       "Longitude": 68.78170013427734,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 2950,
//       "Airport Code [ICAO]": "UMGG",
//       "Airport Code iATA,FAA": "GME",
//       "Airport Name": "Gomel Airport",
//       "Altitude (Ft)": 472,
//       "Country": "Belarus",
//       "Latitude": 31.016700744628906,
//       "Location": "Gomel",
//       "Longitude": 52.527000427246094,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Minsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 2951,
//       "Airport Code [ICAO]": "UMII",
//       "Airport Code iATA,FAA": "VTB",
//       "Airport Name": "Vitebsk Vostochny Airport",
//       "Altitude (Ft)": 682,
//       "Country": "Belarus",
//       "Latitude": 30.349599838257,
//       "Location": "Vitebsk",
//       "Longitude": 55.126499176025,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Minsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 2952,
//       "Airport Code [ICAO]": "UMKK",
//       "Airport Code iATA,FAA": "KGD",
//       "Airport Name": "Khrabrovo Airport",
//       "Altitude (Ft)": 42,
//       "Country": "Russia",
//       "Latitude": 20.592599868774414,
//       "Location": "Kaliningrad",
//       "Longitude": 54.88999938964844,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Europe/Kaliningrad",
//       "Type": "airport"
//     },
//     {
//       "ID": 2953,
//       "Airport Code [ICAO]": "UMMM",
//       "Airport Code iATA,FAA": "MHP",
//       "Airport Name": "Minsk 1 Airport",
//       "Altitude (Ft)": 748,
//       "Country": "Belarus",
//       "Latitude": 27.53969955444336,
//       "Location": "Minsk",
//       "Longitude": 53.864498138427734,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Minsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 2954,
//       "Airport Code [ICAO]": "UMMS",
//       "Airport Code iATA,FAA": "MSQ",
//       "Airport Name": "Minsk National Airport",
//       "Altitude (Ft)": 670,
//       "Country": "Belarus",
//       "Latitude": 28.030700683594,
//       "Location": "Minsk 2",
//       "Longitude": 53.882499694824,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Minsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 2955,
//       "Airport Code [ICAO]": "UNAA",
//       "Airport Code iATA,FAA": "ABA",
//       "Airport Name": "Abakan Airport",
//       "Altitude (Ft)": 831,
//       "Country": "Russia",
//       "Latitude": 91.38500213623047,
//       "Location": "Abakan",
//       "Longitude": 53.7400016784668,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Krasnoyarsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 2956,
//       "Airport Code [ICAO]": "UNBB",
//       "Airport Code iATA,FAA": "BAX",
//       "Airport Name": "Barnaul Airport",
//       "Altitude (Ft)": 837,
//       "Country": "Russia",
//       "Latitude": 83.53849792480469,
//       "Location": "Barnaul",
//       "Longitude": 53.363800048828125,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Krasnoyarsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 2957,
//       "Airport Code [ICAO]": "UNEE",
//       "Airport Code iATA,FAA": "KEJ",
//       "Airport Name": "Kemerovo Airport",
//       "Altitude (Ft)": 863,
//       "Country": "Russia",
//       "Latitude": 86.1072006225586,
//       "Location": "Kemorovo",
//       "Longitude": 55.27009963989258,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Krasnoyarsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 2958,
//       "Airport Code [ICAO]": "UNOO",
//       "Airport Code iATA,FAA": "OMS",
//       "Airport Name": "Omsk Central Airport",
//       "Altitude (Ft)": 311,
//       "Country": "Russia",
//       "Latitude": 73.31050109863281,
//       "Location": "Omsk",
//       "Longitude": 54.96699905395508,
//       "GMT Offset": "6",
//       "Daylight Savings": "N",
//       "Region": "Asia/Omsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 2960,
//       "Airport Code [ICAO]": "URKK",
//       "Airport Code iATA,FAA": "KRR",
//       "Airport Name": "Krasnodar Pashkovsky International Airport",
//       "Altitude (Ft)": 118,
//       "Country": "Russia",
//       "Latitude": 39.170501708984,
//       "Location": "Krasnodar",
//       "Longitude": 45.034698486328,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 2961,
//       "Airport Code [ICAO]": "URML",
//       "Airport Code iATA,FAA": "MCX",
//       "Airport Name": "Uytash Airport",
//       "Altitude (Ft)": 12,
//       "Country": "Russia",
//       "Latitude": 47.65230178833008,
//       "Location": "Makhachkala",
//       "Longitude": 42.81679916381836,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 2962,
//       "Airport Code [ICAO]": "URMM",
//       "Airport Code iATA,FAA": "MRV",
//       "Airport Name": "Mineralnyye Vody Airport",
//       "Altitude (Ft)": 1054,
//       "Country": "Russia",
//       "Latitude": 43.08190155029297,
//       "Location": "Mineralnye Vody",
//       "Longitude": 44.225101470947266,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 2963,
//       "Airport Code [ICAO]": "URMT",
//       "Airport Code iATA,FAA": "STW",
//       "Airport Name": "Stavropol Shpakovskoye Airport",
//       "Altitude (Ft)": 1486,
//       "Country": "Russia",
//       "Latitude": 42.11280059814453,
//       "Location": "Stavropol",
//       "Longitude": 45.10919952392578,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 2964,
//       "Airport Code [ICAO]": "URRP",
//       "Airport Code iATA,FAA": "ROV",
//       "Airport Name": "Platov International Airport",
//       "Altitude (Ft)": 213,
//       "Country": "Russia",
//       "Latitude": 39.924722,
//       "Location": "Rostov",
//       "Longitude": 47.493888,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 2965,
//       "Airport Code [ICAO]": "URSS",
//       "Airport Code iATA,FAA": "AER",
//       "Airport Name": "Sochi International Airport",
//       "Altitude (Ft)": 89,
//       "Country": "Russia",
//       "Latitude": 39.9566,
//       "Location": "Sochi",
//       "Longitude": 43.449902,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 2966,
//       "Airport Code [ICAO]": "URWA",
//       "Airport Code iATA,FAA": "ASF",
//       "Airport Name": "Astrakhan Airport",
//       "Altitude (Ft)": -65,
//       "Country": "Russia",
//       "Latitude": 48.0063018799,
//       "Location": "Astrakhan",
//       "Longitude": 46.2832984924,
//       "GMT Offset": "4",
//       "Daylight Savings": "N",
//       "Region": "Europe/Samara",
//       "Type": "airport"
//     },
//     {
//       "ID": 2967,
//       "Airport Code [ICAO]": "URWW",
//       "Airport Code iATA,FAA": "VOG",
//       "Airport Name": "Volgograd International Airport",
//       "Altitude (Ft)": 482,
//       "Country": "Russia",
//       "Latitude": 44.34550094604492,
//       "Location": "Volgograd",
//       "Longitude": 48.782501220703125,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 2968,
//       "Airport Code [ICAO]": "USCC",
//       "Airport Code iATA,FAA": "CEK",
//       "Airport Name": "Chelyabinsk Balandino Airport",
//       "Altitude (Ft)": 769,
//       "Country": "Russia",
//       "Latitude": 61.5033,
//       "Location": "Chelyabinsk",
//       "Longitude": 55.305801,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 2969,
//       "Airport Code [ICAO]": "USCM",
//       "Airport Code iATA,FAA": "MQF",
//       "Airport Name": "Magnitogorsk International Airport",
//       "Altitude (Ft)": 1430,
//       "Country": "Russia",
//       "Latitude": 58.755699157714844,
//       "Location": "Magnetiogorsk",
//       "Longitude": 53.39310073852539,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 2972,
//       "Airport Code [ICAO]": "USNN",
//       "Airport Code iATA,FAA": "NJC",
//       "Airport Name": "Nizhnevartovsk Airport",
//       "Altitude (Ft)": 177,
//       "Country": "Russia",
//       "Latitude": 76.48359680175781,
//       "Location": "Nizhnevartovsk",
//       "Longitude": 60.94929885864258,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 2973,
//       "Airport Code [ICAO]": "USPP",
//       "Airport Code iATA,FAA": "PEE",
//       "Airport Name": "Bolshoye Savino Airport",
//       "Altitude (Ft)": 404,
//       "Country": "Russia",
//       "Latitude": 56.021198272705,
//       "Location": "Perm",
//       "Longitude": 57.914501190186,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 2974,
//       "Airport Code [ICAO]": "USRR",
//       "Airport Code iATA,FAA": "SGC",
//       "Airport Name": "Surgut Airport",
//       "Altitude (Ft)": 200,
//       "Country": "Russia",
//       "Latitude": 73.40180206298828,
//       "Location": "Surgut",
//       "Longitude": 61.34370040893555,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 2975,
//       "Airport Code [ICAO]": "USSS",
//       "Airport Code iATA,FAA": "SVX",
//       "Airport Name": "Koltsovo Airport",
//       "Altitude (Ft)": 764,
//       "Country": "Russia",
//       "Latitude": 60.802700042725,
//       "Location": "Yekaterinburg",
//       "Longitude": 56.743099212646,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 2976,
//       "Airport Code [ICAO]": "UTAA",
//       "Airport Code iATA,FAA": "ASB",
//       "Airport Name": "Ashgabat International Airport",
//       "Altitude (Ft)": 692,
//       "Country": "Turkmenistan",
//       "Latitude": 58.361,
//       "Location": "Ashkhabad",
//       "Longitude": 37.986801,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Ashgabat",
//       "Type": "airport"
//     },
//     {
//       "ID": 2977,
//       "Airport Code [ICAO]": "UTAK",
//       "Airport Code iATA,FAA": "KRW",
//       "Airport Name": "Turkmenbashi Airport",
//       "Altitude (Ft)": 279,
//       "Country": "Turkmenistan",
//       "Latitude": 53.007198,
//       "Location": "Krasnovodsk",
//       "Longitude": 40.063301,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Ashgabat",
//       "Type": "airport"
//     },
//     {
//       "ID": 2978,
//       "Airport Code [ICAO]": "UTAV",
//       "Airport Code iATA,FAA": "CRZ",
//       "Airport Name": "Turkmenabat Airport",
//       "Altitude (Ft)": 630,
//       "Country": "Turkmenistan",
//       "Latitude": 63.61330032348633,
//       "Location": "Chardzhou",
//       "Longitude": 39.08330154418945,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Ashgabat",
//       "Type": "airport"
//     },
//     {
//       "ID": 2979,
//       "Airport Code [ICAO]": "UTDD",
//       "Airport Code iATA,FAA": "DYU",
//       "Airport Name": "Dushanbe Airport",
//       "Altitude (Ft)": 2575,
//       "Country": "Tajikistan",
//       "Latitude": 68.8249969482,
//       "Location": "Dushanbe",
//       "Longitude": 38.543300628699996,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dushanbe",
//       "Type": "airport"
//     },
//     {
//       "ID": 2980,
//       "Airport Code [ICAO]": "UTSB",
//       "Airport Code iATA,FAA": "BHK",
//       "Airport Name": "Bukhara Airport",
//       "Altitude (Ft)": 751,
//       "Country": "Uzbekistan",
//       "Latitude": 64.4832992553711,
//       "Location": "Bukhara",
//       "Longitude": 39.775001525878906,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Samarkand",
//       "Type": "airport"
//     },
//     {
//       "ID": 2981,
//       "Airport Code [ICAO]": "UTSS",
//       "Airport Code iATA,FAA": "SKD",
//       "Airport Name": "Samarkand Airport",
//       "Altitude (Ft)": 2224,
//       "Country": "Uzbekistan",
//       "Latitude": 66.98380279541016,
//       "Location": "Samarkand",
//       "Longitude": 39.70050048828125,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Samarkand",
//       "Type": "airport"
//     },
//     {
//       "ID": 2983,
//       "Airport Code [ICAO]": "UTTT",
//       "Airport Code iATA,FAA": "TAS",
//       "Airport Name": "Tashkent International Airport",
//       "Altitude (Ft)": 1417,
//       "Country": "Uzbekistan",
//       "Latitude": 69.2811965942,
//       "Location": "Tashkent",
//       "Longitude": 41.257900238,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Samarkand",
//       "Type": "airport"
//     },
//     {
//       "ID": 2984,
//       "Airport Code [ICAO]": "UUBP",
//       "Airport Code iATA,FAA": "BZK",
//       "Airport Name": "Bryansk Airport",
//       "Altitude (Ft)": 663,
//       "Country": "Russia",
//       "Latitude": 34.176399,
//       "Location": "Bryansk",
//       "Longitude": 53.214199,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 2985,
//       "Airport Code [ICAO]": "UUEE",
//       "Airport Code iATA,FAA": "SVO",
//       "Airport Name": "Sheremetyevo International Airport",
//       "Altitude (Ft)": 622,
//       "Country": "Russia",
//       "Latitude": 37.4146,
//       "Location": "Moscow",
//       "Longitude": 55.972599,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 2986,
//       "Airport Code [ICAO]": "UUEM",
//       "Airport Code iATA,FAA": "KLD",
//       "Airport Name": "Migalovo Air Base",
//       "Altitude (Ft)": 469,
//       "Country": "Russia",
//       "Latitude": 35.7577018737793,
//       "Location": "Tver",
//       "Longitude": 56.82469940185547,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 2987,
//       "Airport Code [ICAO]": "UUOO",
//       "Airport Code iATA,FAA": "VOZ",
//       "Airport Name": "Voronezh International Airport",
//       "Altitude (Ft)": 514,
//       "Country": "Russia",
//       "Latitude": 39.22959899902344,
//       "Location": "Voronezh",
//       "Longitude": 51.81420135498047,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 2988,
//       "Airport Code [ICAO]": "UUWW",
//       "Airport Code iATA,FAA": "VKO",
//       "Airport Name": "Vnukovo International Airport",
//       "Altitude (Ft)": 685,
//       "Country": "Russia",
//       "Latitude": 37.2615013123,
//       "Location": "Moscow",
//       "Longitude": 55.5914993286,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 2989,
//       "Airport Code [ICAO]": "UUYY",
//       "Airport Code iATA,FAA": "SCW",
//       "Airport Name": "Syktyvkar Airport",
//       "Altitude (Ft)": 342,
//       "Country": "Russia",
//       "Latitude": 50.84510040283203,
//       "Location": "Syktyvkar",
//       "Longitude": 61.64699935913086,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 2990,
//       "Airport Code [ICAO]": "UWKD",
//       "Airport Code iATA,FAA": "KZN",
//       "Airport Name": "Kazan International Airport",
//       "Altitude (Ft)": 411,
//       "Country": "Russia",
//       "Latitude": 49.278701782227,
//       "Location": "Kazan",
//       "Longitude": 55.606201171875,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 2991,
//       "Airport Code [ICAO]": "UWOO",
//       "Airport Code iATA,FAA": "REN",
//       "Airport Name": "Orenburg Central Airport",
//       "Altitude (Ft)": 387,
//       "Country": "Russia",
//       "Latitude": 55.45669937133789,
//       "Location": "Orenburg",
//       "Longitude": 51.795799255371094,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 2992,
//       "Airport Code [ICAO]": "UWUU",
//       "Airport Code iATA,FAA": "UFA",
//       "Airport Name": "Ufa International Airport",
//       "Altitude (Ft)": 449,
//       "Country": "Russia",
//       "Latitude": 55.874401092529,
//       "Location": "Ufa",
//       "Longitude": 54.557498931885,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 2993,
//       "Airport Code [ICAO]": "UWWW",
//       "Airport Code iATA,FAA": "KUF",
//       "Airport Name": "Kurumoch International Airport",
//       "Altitude (Ft)": 477,
//       "Country": "Russia",
//       "Latitude": 50.16429901123,
//       "Location": "Samara",
//       "Longitude": 53.504901885986,
//       "GMT Offset": "4",
//       "Daylight Savings": "N",
//       "Region": "Europe/Samara",
//       "Type": "airport"
//     },
//     {
//       "ID": 2994,
//       "Airport Code [ICAO]": "VAAH",
//       "Airport Code iATA,FAA": "AMD",
//       "Airport Name": "Sardar Vallabhbhai Patel International Airport",
//       "Altitude (Ft)": 189,
//       "Country": "India",
//       "Latitude": 72.63469696039999,
//       "Location": "Ahmedabad",
//       "Longitude": 23.0771999359,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 2995,
//       "Airport Code [ICAO]": "VAAK",
//       "Airport Code iATA,FAA": "AKD",
//       "Airport Name": "Akola Airport",
//       "Altitude (Ft)": 999,
//       "Country": "India",
//       "Latitude": 77.058601,
//       "Location": "Akola",
//       "Longitude": 20.698999,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 2996,
//       "Airport Code [ICAO]": "VAAU",
//       "Airport Code iATA,FAA": "IXU",
//       "Airport Name": "Aurangabad Airport",
//       "Altitude (Ft)": 1911,
//       "Country": "India",
//       "Latitude": 75.39810180664062,
//       "Location": "Aurangabad",
//       "Longitude": 19.862699508666992,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 2997,
//       "Airport Code [ICAO]": "VABB",
//       "Airport Code iATA,FAA": "BOM",
//       "Airport Name": "Chhatrapati Shivaji International Airport",
//       "Altitude (Ft)": 39,
//       "Country": "India",
//       "Latitude": 72.8678970337,
//       "Location": "Mumbai",
//       "Longitude": 19.0886993408,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 2998,
//       "Airport Code [ICAO]": "VABI",
//       "Airport Code iATA,FAA": "PAB",
//       "Airport Name": "Bilaspur Airport",
//       "Altitude (Ft)": 899,
//       "Country": "India",
//       "Latitude": 82.111,
//       "Location": "Bilaspur",
//       "Longitude": 21.9884,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 2999,
//       "Airport Code [ICAO]": "VABJ",
//       "Airport Code iATA,FAA": "BHJ",
//       "Airport Name": "Bhuj Airport",
//       "Altitude (Ft)": 268,
//       "Country": "India",
//       "Latitude": 69.6701965332,
//       "Location": "Bhuj",
//       "Longitude": 23.2877998352,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3000,
//       "Airport Code [ICAO]": "VABM",
//       "Airport Code iATA,FAA": "IXG",
//       "Airport Name": "Belgaum Airport",
//       "Altitude (Ft)": 2487,
//       "Country": "India",
//       "Latitude": 74.6183013916,
//       "Location": "Belgaum",
//       "Longitude": 15.859299659700001,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3001,
//       "Airport Code [ICAO]": "VABO",
//       "Airport Code iATA,FAA": "BDQ",
//       "Airport Name": "Vadodara Airport",
//       "Altitude (Ft)": 129,
//       "Country": "India",
//       "Latitude": 73.226303,
//       "Location": "Baroda",
//       "Longitude": 22.336201,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3002,
//       "Airport Code [ICAO]": "VABP",
//       "Airport Code iATA,FAA": "BHO",
//       "Airport Name": "Raja Bhoj International Airport",
//       "Altitude (Ft)": 1711,
//       "Country": "India",
//       "Latitude": 77.3374023438,
//       "Location": "Bhopal",
//       "Longitude": 23.2875003815,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3003,
//       "Airport Code [ICAO]": "VABV",
//       "Airport Code iATA,FAA": "BHU",
//       "Airport Name": "Bhavnagar Airport",
//       "Altitude (Ft)": 44,
//       "Country": "India",
//       "Latitude": 72.1852035522,
//       "Location": "Bhaunagar",
//       "Longitude": 21.752199173,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3004,
//       "Airport Code [ICAO]": "VADN",
//       "Airport Code iATA,FAA": "NMB",
//       "Airport Name": "Daman Airport",
//       "Altitude (Ft)": 33,
//       "Country": "India",
//       "Latitude": 72.84320068359375,
//       "Location": "Daman",
//       "Longitude": 20.43440055847168,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3005,
//       "Airport Code [ICAO]": "VADS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Deesa Airport",
//       "Altitude (Ft)": 485,
//       "Country": "India",
//       "Latitude": 72.20439910888672,
//       "Location": "Deesa",
//       "Longitude": 24.267900466918945,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3006,
//       "Airport Code [ICAO]": "VAGN",
//       "Airport Code iATA,FAA": "GUX",
//       "Airport Name": "Guna Airport",
//       "Altitude (Ft)": 1600,
//       "Country": "India",
//       "Latitude": 77.347298,
//       "Location": "Guna",
//       "Longitude": 24.654699,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3007,
//       "Airport Code [ICAO]": "VAGO",
//       "Airport Code iATA,FAA": "GOI",
//       "Airport Name": "Dabolim Airport",
//       "Altitude (Ft)": 150,
//       "Country": "India",
//       "Latitude": 73.8313980103,
//       "Location": "Goa",
//       "Longitude": 15.3808002472,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3008,
//       "Airport Code [ICAO]": "VAID",
//       "Airport Code iATA,FAA": "IDR",
//       "Airport Name": "Devi Ahilyabai Holkar Airport",
//       "Altitude (Ft)": 1850,
//       "Country": "India",
//       "Latitude": 75.8011016846,
//       "Location": "Indore",
//       "Longitude": 22.7217998505,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3009,
//       "Airport Code [ICAO]": "VAJB",
//       "Airport Code iATA,FAA": "JLR",
//       "Airport Name": "Jabalpur Airport",
//       "Altitude (Ft)": 1624,
//       "Country": "India",
//       "Latitude": 80.052001953125,
//       "Location": "Jabalpur",
//       "Longitude": 23.177799224853516,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3010,
//       "Airport Code [ICAO]": "VAJM",
//       "Airport Code iATA,FAA": "JGA",
//       "Airport Name": "Jamnagar Airport",
//       "Altitude (Ft)": 69,
//       "Country": "India",
//       "Latitude": 70.01260375976562,
//       "Location": "Jamnagar",
//       "Longitude": 22.465499877929688,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3011,
//       "Airport Code [ICAO]": "VAKE",
//       "Airport Code iATA,FAA": "IXY",
//       "Airport Name": "Kandla Airport",
//       "Altitude (Ft)": 96,
//       "Country": "India",
//       "Latitude": 70.100304,
//       "Location": "Kandla",
//       "Longitude": 23.1127,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3012,
//       "Airport Code [ICAO]": "VAKJ",
//       "Airport Code iATA,FAA": "HJR",
//       "Airport Name": "Khajuraho Airport",
//       "Altitude (Ft)": 728,
//       "Country": "India",
//       "Latitude": 79.91860198970001,
//       "Location": "Khajuraho",
//       "Longitude": 24.817199707,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3013,
//       "Airport Code [ICAO]": "VAKP",
//       "Airport Code iATA,FAA": "KLH",
//       "Airport Name": "Kolhapur Airport",
//       "Altitude (Ft)": 1996,
//       "Country": "India",
//       "Latitude": 74.2893981934,
//       "Location": "Kolhapur",
//       "Longitude": 16.6646995544,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3014,
//       "Airport Code [ICAO]": "VAKS",
//       "Airport Code iATA,FAA": "IXK",
//       "Airport Name": "Keshod Airport",
//       "Altitude (Ft)": 167,
//       "Country": "India",
//       "Latitude": 70.27040100097656,
//       "Location": "Keshod",
//       "Longitude": 21.317100524902344,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3015,
//       "Airport Code [ICAO]": "VANP",
//       "Airport Code iATA,FAA": "NAG",
//       "Airport Name": "Dr. Babasaheb Ambedkar International Airport",
//       "Altitude (Ft)": 1033,
//       "Country": "India",
//       "Latitude": 79.04720306396484,
//       "Location": "Nagpur",
//       "Longitude": 21.092199325561523,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3016,
//       "Airport Code [ICAO]": "VAOZ",
//       "Airport Code iATA,FAA": "ISK",
//       "Airport Name": "Nashik Airport",
//       "Altitude (Ft)": 1900,
//       "Country": "India",
//       "Latitude": 73.912903,
//       "Location": "Nasik Road",
//       "Longitude": 20.119101,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3017,
//       "Airport Code [ICAO]": "VAPO",
//       "Airport Code iATA,FAA": "PNQ",
//       "Airport Name": "Pune Airport",
//       "Altitude (Ft)": 1942,
//       "Country": "India",
//       "Latitude": 73.9197006225586,
//       "Location": "Pune",
//       "Longitude": 18.58209991455078,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3018,
//       "Airport Code [ICAO]": "VAPR",
//       "Airport Code iATA,FAA": "PBD",
//       "Airport Name": "Porbandar Airport",
//       "Altitude (Ft)": 23,
//       "Country": "India",
//       "Latitude": 69.65720367429999,
//       "Location": "Porbandar",
//       "Longitude": 21.6487007141,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3019,
//       "Airport Code [ICAO]": "VARK",
//       "Airport Code iATA,FAA": "RAJ",
//       "Airport Name": "Rajkot Airport",
//       "Altitude (Ft)": 441,
//       "Country": "India",
//       "Latitude": 70.77950286869999,
//       "Location": "Rajkot",
//       "Longitude": 22.3092002869,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3020,
//       "Airport Code [ICAO]": "VARP",
//       "Airport Code iATA,FAA": "RPR",
//       "Airport Name": "Raipur Airport",
//       "Altitude (Ft)": 1041,
//       "Country": "India",
//       "Latitude": 81.7388,
//       "Location": "Raipur",
//       "Longitude": 21.180401,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3021,
//       "Airport Code [ICAO]": "VASL",
//       "Airport Code iATA,FAA": "SSE",
//       "Airport Name": "Solapur Airport",
//       "Altitude (Ft)": 1584,
//       "Country": "India",
//       "Latitude": 75.93479919430001,
//       "Location": "Sholapur",
//       "Longitude": 17.6280002594,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3022,
//       "Airport Code [ICAO]": "VASU",
//       "Airport Code iATA,FAA": "STV",
//       "Airport Name": "Surat Airport",
//       "Altitude (Ft)": 16,
//       "Country": "India",
//       "Latitude": 72.7417984009,
//       "Location": "Surat",
//       "Longitude": 21.1140995026,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3023,
//       "Airport Code [ICAO]": "VAUD",
//       "Airport Code iATA,FAA": "UDR",
//       "Airport Name": "Maharana Pratap Airport",
//       "Altitude (Ft)": 1684,
//       "Country": "India",
//       "Latitude": 73.89610290530001,
//       "Location": "Udaipur",
//       "Longitude": 24.617700576799997,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3024,
//       "Airport Code [ICAO]": "VCBI",
//       "Airport Code iATA,FAA": "CMB",
//       "Airport Name": "Bandaranaike International Colombo Airport",
//       "Altitude (Ft)": 30,
//       "Country": "Sri Lanka",
//       "Latitude": 79.88410186767578,
//       "Location": "Colombo",
//       "Longitude": 7.180759906768799,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Colombo",
//       "Type": "airport"
//     },
//     {
//       "ID": 3025,
//       "Airport Code [ICAO]": "VCCA",
//       "Airport Code iATA,FAA": "ACJ",
//       "Airport Name": "Anuradhapura Air Force Base",
//       "Altitude (Ft)": 324,
//       "Country": "Sri Lanka",
//       "Latitude": 80.42790222170001,
//       "Location": "Anuradhapura",
//       "Longitude": 8.30148983002,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Colombo",
//       "Type": "airport"
//     },
//     {
//       "ID": 3026,
//       "Airport Code [ICAO]": "VCCB",
//       "Airport Code iATA,FAA": "BTC",
//       "Airport Name": "Batticaloa Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Sri Lanka",
//       "Latitude": 81.678802,
//       "Location": "Batticaloa",
//       "Longitude": 7.70576,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Colombo",
//       "Type": "airport"
//     },
//     {
//       "ID": 3027,
//       "Airport Code [ICAO]": "VCCC",
//       "Airport Code iATA,FAA": "RML",
//       "Airport Name": "Colombo Ratmalana Airport",
//       "Altitude (Ft)": 22,
//       "Country": "Sri Lanka",
//       "Latitude": 79.88619995117188,
//       "Location": "Colombo",
//       "Longitude": 6.821990013122559,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Colombo",
//       "Type": "airport"
//     },
//     {
//       "ID": 3028,
//       "Airport Code [ICAO]": "VCCG",
//       "Airport Code iATA,FAA": "ADP",
//       "Airport Name": "Ampara Airport",
//       "Altitude (Ft)": 150,
//       "Country": "Sri Lanka",
//       "Latitude": 81.62594,
//       "Location": "Galoya",
//       "Longitude": 7.33776,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Colombo",
//       "Type": "airport"
//     },
//     {
//       "ID": 3029,
//       "Airport Code [ICAO]": "VCCJ",
//       "Airport Code iATA,FAA": "JAF",
//       "Airport Name": "Kankesanturai Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Sri Lanka",
//       "Latitude": 80.07009887695312,
//       "Location": "Jaffna",
//       "Longitude": 9.792329788208008,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Colombo",
//       "Type": "airport"
//     },
//     {
//       "ID": 3030,
//       "Airport Code [ICAO]": "VCCT",
//       "Airport Code iATA,FAA": "TRR",
//       "Airport Name": "China Bay Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Sri Lanka",
//       "Latitude": 81.18190002441406,
//       "Location": "Trinciomalee",
//       "Longitude": 8.5385103225708,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Colombo",
//       "Type": "airport"
//     },
//     {
//       "ID": 3033,
//       "Airport Code [ICAO]": "VDKH",
//       "Airport Code iATA,FAA": "KZC",
//       "Airport Name": "Kampong Chhnang Airport",
//       "Altitude (Ft)": 56,
//       "Country": "Cambodia",
//       "Latitude": 104.564002991,
//       "Location": "Kompong Chnang",
//       "Longitude": 12.255200386,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Phnom_Penh",
//       "Type": "airport"
//     },
//     {
//       "ID": 3034,
//       "Airport Code [ICAO]": "VDPP",
//       "Airport Code iATA,FAA": "PNH",
//       "Airport Name": "Phnom Penh International Airport",
//       "Altitude (Ft)": 40,
//       "Country": "Cambodia",
//       "Latitude": 104.84400177001953,
//       "Location": "Phnom-penh",
//       "Longitude": 11.546600341796875,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Phnom_Penh",
//       "Type": "airport"
//     },
//     {
//       "ID": 3035,
//       "Airport Code [ICAO]": "VDSR",
//       "Airport Code iATA,FAA": "REP",
//       "Airport Name": "Siem Reap International Airport",
//       "Altitude (Ft)": 60,
//       "Country": "Cambodia",
//       "Latitude": 103.81300354,
//       "Location": "Siem-reap",
//       "Longitude": 13.410699844400002,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Phnom_Penh",
//       "Type": "airport"
//     },
//     {
//       "ID": 3036,
//       "Airport Code [ICAO]": "VDST",
//       "Airport Code iATA,FAA": "TNX",
//       "Airport Name": "Stung Treng Airport",
//       "Altitude (Ft)": 203,
//       "Country": "Cambodia",
//       "Latitude": 106.01499938964844,
//       "Location": "Stung Treng",
//       "Longitude": 13.531900405883789,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Phnom_Penh",
//       "Type": "airport"
//     },
//     {
//       "ID": 3037,
//       "Airport Code [ICAO]": "VEAN",
//       "Airport Code iATA,FAA": "IXV",
//       "Airport Name": "Along Airport",
//       "Altitude (Ft)": 900,
//       "Country": "India",
//       "Latitude": 94.802001953125,
//       "Location": "Along",
//       "Longitude": 28.17530059814453,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3038,
//       "Airport Code [ICAO]": "VEAT",
//       "Airport Code iATA,FAA": "IXA",
//       "Airport Name": "Agartala Airport",
//       "Altitude (Ft)": 46,
//       "Country": "India",
//       "Latitude": 91.24040222170001,
//       "Location": "Agartala",
//       "Longitude": 23.8869991302,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3039,
//       "Airport Code [ICAO]": "VELP",
//       "Airport Code iATA,FAA": "AJL",
//       "Airport Name": "Lengpui Airport",
//       "Altitude (Ft)": 1398,
//       "Country": "India",
//       "Latitude": 92.6196975708,
//       "Location": "Aizwal",
//       "Longitude": 23.840599060099997,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3040,
//       "Airport Code [ICAO]": "VEBD",
//       "Airport Code iATA,FAA": "IXB",
//       "Airport Name": "Bagdogra Airport",
//       "Altitude (Ft)": 412,
//       "Country": "India",
//       "Latitude": 88.32859802246094,
//       "Location": "Baghdogra",
//       "Longitude": 26.68120002746582,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3041,
//       "Airport Code [ICAO]": "VEBK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bokaro Airport",
//       "Altitude (Ft)": 715,
//       "Country": "India",
//       "Latitude": 86.1489028930664,
//       "Location": "Bokaro",
//       "Longitude": 23.64349937438965,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3042,
//       "Airport Code [ICAO]": "VEBS",
//       "Airport Code iATA,FAA": "BBI",
//       "Airport Name": "Biju Patnaik Airport",
//       "Altitude (Ft)": 138,
//       "Country": "India",
//       "Latitude": 85.8178024292,
//       "Location": "Bhubaneswar",
//       "Longitude": 20.244400024399997,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3043,
//       "Airport Code [ICAO]": "VECC",
//       "Airport Code iATA,FAA": "CCU",
//       "Airport Name": "Netaji Subhash Chandra Bose International Airport",
//       "Altitude (Ft)": 16,
//       "Country": "India",
//       "Latitude": 88.44670104980469,
//       "Location": "Kolkata",
//       "Longitude": 22.654699325561523,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3044,
//       "Airport Code [ICAO]": "VECO",
//       "Airport Code iATA,FAA": "COH",
//       "Airport Name": "Cooch Behar Airport",
//       "Altitude (Ft)": 138,
//       "Country": "India",
//       "Latitude": 89.4672012329,
//       "Location": "Cooch-behar",
//       "Longitude": 26.330499649,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3045,
//       "Airport Code [ICAO]": "VEDB",
//       "Airport Code iATA,FAA": "DBD",
//       "Airport Name": "Dhanbad Airport",
//       "Altitude (Ft)": 847,
//       "Country": "India",
//       "Latitude": 86.42530059814453,
//       "Location": "Dhanbad",
//       "Longitude": 23.833999633789062,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3048,
//       "Airport Code [ICAO]": "VEGY",
//       "Airport Code iATA,FAA": "GAY",
//       "Airport Name": "Gaya Airport",
//       "Altitude (Ft)": 380,
//       "Country": "India",
//       "Latitude": 84.95120239257812,
//       "Location": "Gaya",
//       "Longitude": 24.744300842285156,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3049,
//       "Airport Code [ICAO]": "VEHK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hirakud Airport",
//       "Altitude (Ft)": 658,
//       "Country": "India",
//       "Latitude": 84.00569915771484,
//       "Location": "Hirakud",
//       "Longitude": 21.5802001953125,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3050,
//       "Airport Code [ICAO]": "VEIM",
//       "Airport Code iATA,FAA": "IMF",
//       "Airport Name": "Imphal Airport",
//       "Altitude (Ft)": 2540,
//       "Country": "India",
//       "Latitude": 93.896697998,
//       "Location": "Imphal",
//       "Longitude": 24.7600002289,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3051,
//       "Airport Code [ICAO]": "VEJH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Jharsuguda Airport",
//       "Altitude (Ft)": 751,
//       "Country": "India",
//       "Latitude": 84.05039978027344,
//       "Location": "Jharsuguda",
//       "Longitude": 21.91349983215332,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3052,
//       "Airport Code [ICAO]": "VEJS",
//       "Airport Code iATA,FAA": "IXW",
//       "Airport Name": "Sonari Airport",
//       "Altitude (Ft)": 475,
//       "Country": "India",
//       "Latitude": 86.1688,
//       "Location": "Jamshedpur",
//       "Longitude": 22.8132,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3053,
//       "Airport Code [ICAO]": "VEJT",
//       "Airport Code iATA,FAA": "JRH",
//       "Airport Name": "Jorhat Airport",
//       "Altitude (Ft)": 311,
//       "Country": "India",
//       "Latitude": 94.1754989624,
//       "Location": "Jorhat",
//       "Longitude": 26.7315006256,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3054,
//       "Airport Code [ICAO]": "VEKR",
//       "Airport Code iATA,FAA": "IXH",
//       "Airport Name": "Kailashahar Airport",
//       "Altitude (Ft)": 79,
//       "Country": "India",
//       "Latitude": 92.0072021484375,
//       "Location": "Kailashahar",
//       "Longitude": 24.30820083618164,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3055,
//       "Airport Code [ICAO]": "VEKU",
//       "Airport Code iATA,FAA": "IXS",
//       "Airport Name": "Silchar Airport",
//       "Altitude (Ft)": 352,
//       "Country": "India",
//       "Latitude": 92.97869873050001,
//       "Location": "Silchar",
//       "Longitude": 24.9129009247,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3056,
//       "Airport Code [ICAO]": "VELR",
//       "Airport Code iATA,FAA": "IXI",
//       "Airport Name": "North Lakhimpur Airport",
//       "Altitude (Ft)": 330,
//       "Country": "India",
//       "Latitude": 94.09760284423828,
//       "Location": "Lilabari",
//       "Longitude": 27.295499801635742,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3057,
//       "Airport Code [ICAO]": "VEMN",
//       "Airport Code iATA,FAA": "DIB",
//       "Airport Name": "Dibrugarh Airport",
//       "Altitude (Ft)": 362,
//       "Country": "India",
//       "Latitude": 95.0168991089,
//       "Location": "Mohanbari",
//       "Longitude": 27.4839000702,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3058,
//       "Airport Code [ICAO]": "VEMZ",
//       "Airport Code iATA,FAA": "MZU",
//       "Airport Name": "Muzaffarpur Airport",
//       "Altitude (Ft)": 174,
//       "Country": "India",
//       "Latitude": 85.3136978149414,
//       "Location": "Mazuffarpur",
//       "Longitude": 26.11910057067871,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3059,
//       "Airport Code [ICAO]": "VENP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nawapara Airport",
//       "Altitude (Ft)": 1058,
//       "Country": "India",
//       "Latitude": 82.51959991455078,
//       "Location": "Nawapara",
//       "Longitude": 20.8700008392334,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3060,
//       "Airport Code [ICAO]": "VEPH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Panagarh Air Force Station",
//       "Altitude (Ft)": 240,
//       "Country": "India",
//       "Latitude": 87.4274978638,
//       "Location": "Panagarh",
//       "Longitude": 23.474300384499998,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3061,
//       "Airport Code [ICAO]": "VEPT",
//       "Airport Code iATA,FAA": "PAT",
//       "Airport Name": "Lok Nayak Jayaprakash Airport",
//       "Altitude (Ft)": 170,
//       "Country": "India",
//       "Latitude": 85.0879974365,
//       "Location": "Patina",
//       "Longitude": 25.591299057,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3062,
//       "Airport Code [ICAO]": "VEPU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Purnea Airport",
//       "Altitude (Ft)": 129,
//       "Country": "India",
//       "Latitude": 87.41000366210938,
//       "Location": "Purnea",
//       "Longitude": 25.759599685668945,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3063,
//       "Airport Code [ICAO]": "VERC",
//       "Airport Code iATA,FAA": "IXR",
//       "Airport Name": "Birsa Munda Airport",
//       "Altitude (Ft)": 2148,
//       "Country": "India",
//       "Latitude": 85.3217010498,
//       "Location": "Ranchi",
//       "Longitude": 23.314300537100003,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3064,
//       "Airport Code [ICAO]": "VERK",
//       "Airport Code iATA,FAA": "RRK",
//       "Airport Name": "Rourkela Airport",
//       "Altitude (Ft)": 659,
//       "Country": "India",
//       "Latitude": 84.814598,
//       "Location": "Rourkela",
//       "Longitude": 22.256701,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3065,
//       "Airport Code [ICAO]": "VEUK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Utkela Airport",
//       "Altitude (Ft)": 680,
//       "Country": "India",
//       "Latitude": 83.18379974365234,
//       "Location": "Utkela",
//       "Longitude": 20.097400665283203,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3066,
//       "Airport Code [ICAO]": "VEVZ",
//       "Airport Code iATA,FAA": "VTZ",
//       "Airport Name": "Vishakhapatnam Airport",
//       "Altitude (Ft)": 15,
//       "Country": "India",
//       "Latitude": 83.2245025635,
//       "Location": "Vishakhapatnam",
//       "Longitude": 17.721200943,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3067,
//       "Airport Code [ICAO]": "VEZO",
//       "Airport Code iATA,FAA": "ZER",
//       "Airport Name": "Ziro Airport",
//       "Altitude (Ft)": 5403,
//       "Country": "India",
//       "Latitude": 93.828102,
//       "Location": "Zero",
//       "Longitude": 27.588301,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3068,
//       "Airport Code [ICAO]": "VGCB",
//       "Airport Code iATA,FAA": "CXB",
//       "Airport Name": "Cox's Bazar Airport",
//       "Altitude (Ft)": 12,
//       "Country": "Bangladesh",
//       "Latitude": 91.96389770507812,
//       "Location": "Cox's Bazar",
//       "Longitude": 21.452199935913086,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dhaka",
//       "Type": "airport"
//     },
//     {
//       "ID": 3069,
//       "Airport Code [ICAO]": "VGEG",
//       "Airport Code iATA,FAA": "CGP",
//       "Airport Name": "Shah Amanat International Airport",
//       "Altitude (Ft)": 12,
//       "Country": "Bangladesh",
//       "Latitude": 91.81330108642578,
//       "Location": "Chittagong",
//       "Longitude": 22.24959945678711,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dhaka",
//       "Type": "airport"
//     },
//     {
//       "ID": 3070,
//       "Airport Code [ICAO]": "VGIS",
//       "Airport Code iATA,FAA": "IRD",
//       "Airport Name": "Ishurdi Airport",
//       "Altitude (Ft)": 45,
//       "Country": "Bangladesh",
//       "Latitude": 89.04940032958984,
//       "Location": "Ishurdi",
//       "Longitude": 24.15250015258789,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dhaka",
//       "Type": "airport"
//     },
//     {
//       "ID": 3071,
//       "Airport Code [ICAO]": "VGJR",
//       "Airport Code iATA,FAA": "JSR",
//       "Airport Name": "Jessore Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Bangladesh",
//       "Latitude": 89.16079711914062,
//       "Location": "Jessore",
//       "Longitude": 23.183799743652344,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dhaka",
//       "Type": "airport"
//     },
//     {
//       "ID": 3072,
//       "Airport Code [ICAO]": "VGRJ",
//       "Airport Code iATA,FAA": "RJH",
//       "Airport Name": "Shah Mokhdum Airport",
//       "Altitude (Ft)": 64,
//       "Country": "Bangladesh",
//       "Latitude": 88.61650085449219,
//       "Location": "Rajshahi",
//       "Longitude": 24.43720054626465,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dhaka",
//       "Type": "airport"
//     },
//     {
//       "ID": 3073,
//       "Airport Code [ICAO]": "VGSD",
//       "Airport Code iATA,FAA": "SPD",
//       "Airport Name": "Saidpur Airport",
//       "Altitude (Ft)": 125,
//       "Country": "Bangladesh",
//       "Latitude": 88.90889739990234,
//       "Location": "Saidpur",
//       "Longitude": 25.759199142456055,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dhaka",
//       "Type": "airport"
//     },
//     {
//       "ID": 3074,
//       "Airport Code [ICAO]": "VGSY",
//       "Airport Code iATA,FAA": "ZYL",
//       "Airport Name": "Osmany International Airport",
//       "Altitude (Ft)": 50,
//       "Country": "Bangladesh",
//       "Latitude": 91.8667984008789,
//       "Location": "Sylhet Osmani",
//       "Longitude": 24.963199615478516,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dhaka",
//       "Type": "airport"
//     },
//     {
//       "ID": 3075,
//       "Airport Code [ICAO]": "VGTJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tejgaon Airport",
//       "Altitude (Ft)": 24,
//       "Country": "Bangladesh",
//       "Latitude": 90.38269805908203,
//       "Location": "Dhaka",
//       "Longitude": 23.778799057006836,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dhaka",
//       "Type": "airport"
//     },
//     {
//       "ID": 3076,
//       "Airport Code [ICAO]": "VGZR",
//       "Airport Code iATA,FAA": "DAC",
//       "Airport Name": "Hazrat Shahjalal International Airport",
//       "Altitude (Ft)": 30,
//       "Country": "Bangladesh",
//       "Latitude": 90.397783,
//       "Location": "Dhaka",
//       "Longitude": 23.843347,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dhaka",
//       "Type": "airport"
//     },
//     {
//       "ID": 3077,
//       "Airport Code [ICAO]": "VHHH",
//       "Airport Code iATA,FAA": "HKG",
//       "Airport Name": "Hong Kong International Airport",
//       "Altitude (Ft)": 28,
//       "Country": "Hong Kong",
//       "Latitude": 113.915001,
//       "Location": "Hong Kong",
//       "Longitude": 22.308901,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Hong_Kong",
//       "Type": "airport"
//     },
//     {
//       "ID": 3078,
//       "Airport Code [ICAO]": "VHSK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Shek Kong Air Base",
//       "Altitude (Ft)": 50,
//       "Country": "Hong Kong",
//       "Latitude": 114.08000183105469,
//       "Location": "Sek Kong",
//       "Longitude": 22.436599731445312,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Hong_Kong",
//       "Type": "airport"
//     },
//     {
//       "ID": 3079,
//       "Airport Code [ICAO]": "VIAG",
//       "Airport Code iATA,FAA": "AGR",
//       "Airport Name": "Agra Airport",
//       "Altitude (Ft)": 551,
//       "Country": "India",
//       "Latitude": 77.96089935302734,
//       "Location": "Agra",
//       "Longitude": 27.155799865722656,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3080,
//       "Airport Code [ICAO]": "VIAL",
//       "Airport Code iATA,FAA": "IXD",
//       "Airport Name": "Allahabad Airport",
//       "Altitude (Ft)": 322,
//       "Country": "India",
//       "Latitude": 81.73390197753906,
//       "Location": "Allahabad",
//       "Longitude": 25.440099716186523,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3081,
//       "Airport Code [ICAO]": "VIAR",
//       "Airport Code iATA,FAA": "ATQ",
//       "Airport Name": "Sri Guru Ram Dass Jee International Airport",
//       "Altitude (Ft)": 756,
//       "Country": "India",
//       "Latitude": 74.7973022461,
//       "Location": "Amritsar",
//       "Longitude": 31.7096004486,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3082,
//       "Airport Code [ICAO]": "VIBK",
//       "Airport Code iATA,FAA": "BKB",
//       "Airport Name": "Nal Airport",
//       "Altitude (Ft)": 750,
//       "Country": "India",
//       "Latitude": 73.20719909667969,
//       "Location": "Bikaner",
//       "Longitude": 28.070600509643555,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3083,
//       "Airport Code [ICAO]": "VIBL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bakshi Ka Talab Air Force Station",
//       "Altitude (Ft)": 385,
//       "Country": "India",
//       "Latitude": 80.89309692382812,
//       "Location": "Bakshi Ka Talab",
//       "Longitude": 26.988300323486328,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3084,
//       "Airport Code [ICAO]": "VIBN",
//       "Airport Code iATA,FAA": "VNS",
//       "Airport Name": "Lal Bahadur Shastri Airport",
//       "Altitude (Ft)": 266,
//       "Country": "India",
//       "Latitude": 82.8592987061,
//       "Location": "Varanasi",
//       "Longitude": 25.4524002075,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3085,
//       "Airport Code [ICAO]": "VIBR",
//       "Airport Code iATA,FAA": "KUU",
//       "Airport Name": "Kullu Manali Airport",
//       "Altitude (Ft)": 3573,
//       "Country": "India",
//       "Latitude": 77.15440368652344,
//       "Location": "Kulu",
//       "Longitude": 31.876699447631836,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3086,
//       "Airport Code [ICAO]": "VIBT",
//       "Airport Code iATA,FAA": "BUP",
//       "Airport Name": "Bhatinda Air Force Station",
//       "Altitude (Ft)": 662,
//       "Country": "India",
//       "Latitude": 74.75579833984375,
//       "Location": "Bhatinda",
//       "Longitude": 30.270099639892578,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3087,
//       "Airport Code [ICAO]": "VIBW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bhiwani Airport",
//       "Altitude (Ft)": 720,
//       "Country": "India",
//       "Latitude": 76.1791000366211,
//       "Location": "Bhiwani",
//       "Longitude": 28.836999893188477,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3088,
//       "Airport Code [ICAO]": "VIBY",
//       "Airport Code iATA,FAA": "BEK",
//       "Airport Name": "Bareilly Air Force Station",
//       "Altitude (Ft)": 580,
//       "Country": "India",
//       "Latitude": 79.45079803470001,
//       "Location": "Bareilly",
//       "Longitude": 28.4221000671,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3089,
//       "Airport Code [ICAO]": "VICG",
//       "Airport Code iATA,FAA": "IXC",
//       "Airport Name": "Chandigarh Airport",
//       "Altitude (Ft)": 1012,
//       "Country": "India",
//       "Latitude": 76.78849792480469,
//       "Location": "Chandigarh",
//       "Longitude": 30.673500061035156,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3090,
//       "Airport Code [ICAO]": "VICX",
//       "Airport Code iATA,FAA": "KNU",
//       "Airport Name": "Kanpur Airport",
//       "Altitude (Ft)": 410,
//       "Country": "India",
//       "Latitude": 80.410103,
//       "Location": "Kanpur",
//       "Longitude": 26.404301,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3091,
//       "Airport Code [ICAO]": "VIDD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Safdarjung Airport",
//       "Altitude (Ft)": 705,
//       "Country": "India",
//       "Latitude": 77.20580291748047,
//       "Location": "Delhi",
//       "Longitude": 28.58449935913086,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3092,
//       "Airport Code [ICAO]": "VIDN",
//       "Airport Code iATA,FAA": "DED",
//       "Airport Name": "Dehradun Airport",
//       "Altitude (Ft)": 1831,
//       "Country": "India",
//       "Latitude": 78.180298,
//       "Location": "Dehra Dun",
//       "Longitude": 30.189699,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3093,
//       "Airport Code [ICAO]": "VIDP",
//       "Airport Code iATA,FAA": "DEL",
//       "Airport Name": "Indira Gandhi International Airport",
//       "Altitude (Ft)": 777,
//       "Country": "India",
//       "Latitude": 77.103104,
//       "Location": "Delhi",
//       "Longitude": 28.5665,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3094,
//       "Airport Code [ICAO]": "VIGR",
//       "Airport Code iATA,FAA": "GWL",
//       "Airport Name": "Gwalior Airport",
//       "Altitude (Ft)": 617,
//       "Country": "India",
//       "Latitude": 78.22779846191406,
//       "Location": "Gwalior",
//       "Longitude": 26.29330062866211,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3095,
//       "Airport Code [ICAO]": "VIHR",
//       "Airport Code iATA,FAA": "HSS",
//       "Airport Name": "Hissar Airport",
//       "Altitude (Ft)": 700,
//       "Country": "India",
//       "Latitude": 75.75530242919922,
//       "Location": "Hissar",
//       "Longitude": 29.179399490356445,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3096,
//       "Airport Code [ICAO]": "VIJN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Jhansi Airport",
//       "Altitude (Ft)": 801,
//       "Country": "India",
//       "Latitude": 78.55840301513672,
//       "Location": "Jhansi",
//       "Longitude": 25.491199493408203,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3097,
//       "Airport Code [ICAO]": "VIJO",
//       "Airport Code iATA,FAA": "JDH",
//       "Airport Name": "Jodhpur Airport",
//       "Altitude (Ft)": 717,
//       "Country": "India",
//       "Latitude": 73.04889678955078,
//       "Location": "Jodhpur",
//       "Longitude": 26.251100540161133,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3098,
//       "Airport Code [ICAO]": "VIJP",
//       "Airport Code iATA,FAA": "JAI",
//       "Airport Name": "Jaipur International Airport",
//       "Altitude (Ft)": 1263,
//       "Country": "India",
//       "Latitude": 75.812202,
//       "Location": "Jaipur",
//       "Longitude": 26.8242,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3099,
//       "Airport Code [ICAO]": "VIJR",
//       "Airport Code iATA,FAA": "JSA",
//       "Airport Name": "Jaisalmer Airport",
//       "Altitude (Ft)": 751,
//       "Country": "India",
//       "Latitude": 70.86499786376953,
//       "Location": "Jaisalmer",
//       "Longitude": 26.888700485229492,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3100,
//       "Airport Code [ICAO]": "VIJU",
//       "Airport Code iATA,FAA": "IXJ",
//       "Airport Name": "Jammu Airport",
//       "Altitude (Ft)": 1029,
//       "Country": "India",
//       "Latitude": 74.8374023438,
//       "Location": "Jammu",
//       "Longitude": 32.6890983582,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3101,
//       "Airport Code [ICAO]": "VIKA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kanpur Civil Airport",
//       "Altitude (Ft)": 411,
//       "Country": "India",
//       "Latitude": 80.364899,
//       "Location": "Kanpur",
//       "Longitude": 26.441401,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3102,
//       "Airport Code [ICAO]": "VIKO",
//       "Airport Code iATA,FAA": "KTU",
//       "Airport Name": "Kota Airport",
//       "Altitude (Ft)": 896,
//       "Country": "India",
//       "Latitude": 75.84559631350001,
//       "Location": "Kota",
//       "Longitude": 25.160200119,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3103,
//       "Airport Code [ICAO]": "VILD",
//       "Airport Code iATA,FAA": "LUH",
//       "Airport Name": "Ludhiana Airport",
//       "Altitude (Ft)": 834,
//       "Country": "India",
//       "Latitude": 75.95259857177734,
//       "Location": "Ludhiaha",
//       "Longitude": 30.854700088500977,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3104,
//       "Airport Code [ICAO]": "VILH",
//       "Airport Code iATA,FAA": "IXL",
//       "Airport Name": "Leh Kushok Bakula Rimpochee Airport",
//       "Altitude (Ft)": 10682,
//       "Country": "India",
//       "Latitude": 77.5465011597,
//       "Location": "Leh",
//       "Longitude": 34.1358985901,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3105,
//       "Airport Code [ICAO]": "VILK",
//       "Airport Code iATA,FAA": "LKO",
//       "Airport Name": "Chaudhary Charan Singh International Airport",
//       "Altitude (Ft)": 410,
//       "Country": "India",
//       "Latitude": 80.8892974854,
//       "Location": "Lucknow",
//       "Longitude": 26.7605991364,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3106,
//       "Airport Code [ICAO]": "VIPK",
//       "Airport Code iATA,FAA": "IXP",
//       "Airport Name": "Pathankot Airport",
//       "Altitude (Ft)": 1017,
//       "Country": "India",
//       "Latitude": 75.634444,
//       "Location": "Pathankot",
//       "Longitude": 32.233611,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3107,
//       "Airport Code [ICAO]": "VIPL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Patiala Airport",
//       "Altitude (Ft)": 820,
//       "Country": "India",
//       "Latitude": 76.364501953125,
//       "Location": "Patiala",
//       "Longitude": 30.314800262451172,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3108,
//       "Airport Code [ICAO]": "VIPT",
//       "Airport Code iATA,FAA": "PGH",
//       "Airport Name": "Pantnagar Airport",
//       "Altitude (Ft)": 769,
//       "Country": "India",
//       "Latitude": 79.47370147705078,
//       "Location": "Nainital",
//       "Longitude": 29.03339958190918,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3109,
//       "Airport Code [ICAO]": "VIRB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Fursatganj Airport",
//       "Altitude (Ft)": 360,
//       "Country": "India",
//       "Latitude": 81.38050079345703,
//       "Location": "Raibarelli",
//       "Longitude": 26.24850082397461,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3111,
//       "Airport Code [ICAO]": "VISP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sarsawa Air Force Station",
//       "Altitude (Ft)": 891,
//       "Country": "India",
//       "Latitude": 77.42530059814453,
//       "Location": "Saharanpur",
//       "Longitude": 29.993900299072266,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3112,
//       "Airport Code [ICAO]": "VISR",
//       "Airport Code iATA,FAA": "SXR",
//       "Airport Name": "Sheikh ul Alam Airport",
//       "Altitude (Ft)": 5429,
//       "Country": "India",
//       "Latitude": 74.77420043945312,
//       "Location": "Srinagar",
//       "Longitude": 33.987098693847656,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3113,
//       "Airport Code [ICAO]": "VIST",
//       "Airport Code iATA,FAA": "TNI",
//       "Airport Name": "Satna Airport",
//       "Altitude (Ft)": 1060,
//       "Country": "India",
//       "Latitude": 80.854897,
//       "Location": "Satna",
//       "Longitude": 24.5623,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3115,
//       "Airport Code [ICAO]": "VLLB",
//       "Airport Code iATA,FAA": "LPQ",
//       "Airport Name": "Luang Phabang International Airport",
//       "Altitude (Ft)": 955,
//       "Country": "Laos",
//       "Latitude": 102.16100311279297,
//       "Location": "Luang Prabang",
//       "Longitude": 19.897300720214844,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Vientiane",
//       "Type": "airport"
//     },
//     {
//       "ID": 3116,
//       "Airport Code [ICAO]": "VLPS",
//       "Airport Code iATA,FAA": "PKZ",
//       "Airport Name": "Pakse International Airport",
//       "Altitude (Ft)": 351,
//       "Country": "Laos",
//       "Latitude": 105.78099822998047,
//       "Location": "Pakse",
//       "Longitude": 15.132100105285645,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Vientiane",
//       "Type": "airport"
//     },
//     {
//       "ID": 3117,
//       "Airport Code [ICAO]": "VLPV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Phonesavanh Airport",
//       "Altitude (Ft)": 3628,
//       "Country": "Laos",
//       "Latitude": 103.21800231933594,
//       "Location": "Phong Savanh",
//       "Longitude": 19.45490074157715,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Vientiane",
//       "Type": "airport"
//     },
//     {
//       "ID": 3118,
//       "Airport Code [ICAO]": "VLSK",
//       "Airport Code iATA,FAA": "ZVK",
//       "Airport Name": "Savannakhet Airport",
//       "Altitude (Ft)": 509,
//       "Country": "Laos",
//       "Latitude": 104.76000213623047,
//       "Location": "Savannakhet",
//       "Longitude": 16.55660057067871,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Vientiane",
//       "Type": "airport"
//     },
//     {
//       "ID": 3119,
//       "Airport Code [ICAO]": "VLSN",
//       "Airport Code iATA,FAA": "NEU",
//       "Airport Name": "Sam Neua Airport",
//       "Altitude (Ft)": 3281,
//       "Country": "Laos",
//       "Latitude": 104.06700134277344,
//       "Location": "Sam Neua",
//       "Longitude": 20.418399810791016,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Vientiane",
//       "Type": "airport"
//     },
//     {
//       "ID": 3120,
//       "Airport Code [ICAO]": "VLVT",
//       "Airport Code iATA,FAA": "VTE",
//       "Airport Name": "Wattay International Airport",
//       "Altitude (Ft)": 564,
//       "Country": "Laos",
//       "Latitude": 102.56300354,
//       "Location": "Vientiane",
//       "Longitude": 17.988300323500003,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Vientiane",
//       "Type": "airport"
//     },
//     {
//       "ID": 3121,
//       "Airport Code [ICAO]": "VMMC",
//       "Airport Code iATA,FAA": "MFM",
//       "Airport Name": "Macau International Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Macau",
//       "Latitude": 113.592003,
//       "Location": "Macau",
//       "Longitude": 22.149599,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Macau",
//       "Type": "airport"
//     },
//     {
//       "ID": 3122,
//       "Airport Code [ICAO]": "VNBW",
//       "Airport Code iATA,FAA": "BWA",
//       "Airport Name": "Gautam Buddha Airport",
//       "Altitude (Ft)": 358,
//       "Country": "Nepal",
//       "Latitude": 83.416293,
//       "Location": "Bhairawa",
//       "Longitude": 27.505685,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 3124,
//       "Airport Code [ICAO]": "VNJP",
//       "Airport Code iATA,FAA": "JKR",
//       "Airport Name": "Janakpur Airport",
//       "Altitude (Ft)": 256,
//       "Country": "Nepal",
//       "Latitude": 85.9224014282,
//       "Location": "Janakpur",
//       "Longitude": 26.708799362199997,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 3125,
//       "Airport Code [ICAO]": "VNKT",
//       "Airport Code iATA,FAA": "KTM",
//       "Airport Name": "Tribhuvan International Airport",
//       "Altitude (Ft)": 4390,
//       "Country": "Nepal",
//       "Latitude": 85.3591,
//       "Location": "Kathmandu",
//       "Longitude": 27.6966,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 3127,
//       "Airport Code [ICAO]": "VNPK",
//       "Airport Code iATA,FAA": "PKR",
//       "Airport Name": "Pokhara Airport",
//       "Altitude (Ft)": 2712,
//       "Country": "Nepal",
//       "Latitude": 83.98210144042969,
//       "Location": "Pokhara",
//       "Longitude": 28.200899124145508,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 3128,
//       "Airport Code [ICAO]": "VNSI",
//       "Airport Code iATA,FAA": "SIF",
//       "Airport Name": "Simara Airport",
//       "Altitude (Ft)": 450,
//       "Country": "Nepal",
//       "Latitude": 84.9801025390625,
//       "Location": "Simara",
//       "Longitude": 27.159500122070312,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 3129,
//       "Airport Code [ICAO]": "VNVT",
//       "Airport Code iATA,FAA": "BIR",
//       "Airport Name": "Biratnagar Airport",
//       "Altitude (Ft)": 236,
//       "Country": "Nepal",
//       "Latitude": 87.26399993896484,
//       "Location": "Biratnagar",
//       "Longitude": 26.48150062561035,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 3130,
//       "Airport Code [ICAO]": "VOAT",
//       "Airport Code iATA,FAA": "AGX",
//       "Airport Name": "Agatti Airport",
//       "Altitude (Ft)": 14,
//       "Country": "India",
//       "Latitude": 72.1760025024414,
//       "Location": "Agatti Island",
//       "Longitude": 10.823699951171875,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3131,
//       "Airport Code [ICAO]": "VOBL",
//       "Airport Code iATA,FAA": "BLR",
//       "Airport Name": "Kempegowda International Airport",
//       "Altitude (Ft)": 3000,
//       "Country": "India",
//       "Latitude": 77.706299,
//       "Location": "Bangalore",
//       "Longitude": 13.1979,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3132,
//       "Airport Code [ICAO]": "VOBI",
//       "Airport Code iATA,FAA": "BEP",
//       "Airport Name": "Bellary Airport",
//       "Altitude (Ft)": 30,
//       "Country": "India",
//       "Latitude": 76.88279724121094,
//       "Location": "Bellary",
//       "Longitude": 15.162799835205078,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3133,
//       "Airport Code [ICAO]": "VOBR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bidar Air Force Station",
//       "Altitude (Ft)": 2178,
//       "Country": "India",
//       "Latitude": 77.48709869384766,
//       "Location": "Bidar",
//       "Longitude": 17.908100128173828,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3134,
//       "Airport Code [ICAO]": "VOBZ",
//       "Airport Code iATA,FAA": "VGA",
//       "Airport Name": "Vijayawada Airport",
//       "Altitude (Ft)": 82,
//       "Country": "India",
//       "Latitude": 80.79679870605469,
//       "Location": "Vijayawada",
//       "Longitude": 16.530399322509766,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3135,
//       "Airport Code [ICAO]": "VOCB",
//       "Airport Code iATA,FAA": "CJB",
//       "Airport Name": "Coimbatore International Airport",
//       "Altitude (Ft)": 1324,
//       "Country": "India",
//       "Latitude": 77.0434036255,
//       "Location": "Coimbatore",
//       "Longitude": 11.029999733,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3136,
//       "Airport Code [ICAO]": "VOCI",
//       "Airport Code iATA,FAA": "COK",
//       "Airport Name": "Cochin International Airport",
//       "Altitude (Ft)": 30,
//       "Country": "India",
//       "Latitude": 76.401901,
//       "Location": "Kochi",
//       "Longitude": 10.152,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3137,
//       "Airport Code [ICAO]": "VOCL",
//       "Airport Code iATA,FAA": "CCJ",
//       "Airport Name": "Calicut International Airport",
//       "Altitude (Ft)": 342,
//       "Country": "India",
//       "Latitude": 75.95529937740001,
//       "Location": "Calicut",
//       "Longitude": 11.1367998123,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3138,
//       "Airport Code [ICAO]": "VOCP",
//       "Airport Code iATA,FAA": "CDP",
//       "Airport Name": "Kadapa Airport",
//       "Altitude (Ft)": 430,
//       "Country": "India",
//       "Latitude": 78.772778,
//       "Location": "Cuddapah",
//       "Longitude": 14.51,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3139,
//       "Airport Code [ICAO]": "VOCX",
//       "Airport Code iATA,FAA": "CBD",
//       "Airport Name": "Car Nicobar Air Force Station",
//       "Altitude (Ft)": 5,
//       "Country": "India",
//       "Latitude": 92.8196029663086,
//       "Location": "Carnicobar",
//       "Longitude": 9.152509689331055,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3140,
//       "Airport Code [ICAO]": "VODG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Dundigul Air Force Academy",
//       "Altitude (Ft)": 2013,
//       "Country": "India",
//       "Latitude": 78.4033966064,
//       "Location": "Dundigul",
//       "Longitude": 17.627199173,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3141,
//       "Airport Code [ICAO]": "VOHY",
//       "Airport Code iATA,FAA": "BPM",
//       "Airport Name": "Begumpet Airport",
//       "Altitude (Ft)": 1742,
//       "Country": "India",
//       "Latitude": 78.4675979614,
//       "Location": "Hyderabad",
//       "Longitude": 17.4531002045,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3142,
//       "Airport Code [ICAO]": "VOMD",
//       "Airport Code iATA,FAA": "IXM",
//       "Airport Name": "Madurai Airport",
//       "Altitude (Ft)": 459,
//       "Country": "India",
//       "Latitude": 78.09339904790001,
//       "Location": "Madurai",
//       "Longitude": 9.83450984955,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3143,
//       "Airport Code [ICAO]": "VOML",
//       "Airport Code iATA,FAA": "IXE",
//       "Airport Name": "Mangalore International Airport",
//       "Altitude (Ft)": 337,
//       "Country": "India",
//       "Latitude": 74.8900985718,
//       "Location": "Mangalore",
//       "Longitude": 12.9612998962,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3144,
//       "Airport Code [ICAO]": "VOMM",
//       "Airport Code iATA,FAA": "MAA",
//       "Airport Name": "Chennai International Airport",
//       "Altitude (Ft)": 52,
//       "Country": "India",
//       "Latitude": 80.16929626464844,
//       "Location": "Madras",
//       "Longitude": 12.990005493164062,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3145,
//       "Airport Code [ICAO]": "VONS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nagarjuna Sagar Airport",
//       "Altitude (Ft)": 658,
//       "Country": "India",
//       "Latitude": 79.3187026977539,
//       "Location": "Nagarjunsagar",
//       "Longitude": 16.542699813842773,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3146,
//       "Airport Code [ICAO]": "VOPB",
//       "Airport Code iATA,FAA": "IXZ",
//       "Airport Name": "Vir Savarkar International Airport",
//       "Altitude (Ft)": 14,
//       "Country": "India",
//       "Latitude": 92.72969818115234,
//       "Location": "Port Blair",
//       "Longitude": 11.641200065612793,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3147,
//       "Airport Code [ICAO]": "VOPC",
//       "Airport Code iATA,FAA": "PNY",
//       "Airport Name": "Pondicherry Airport",
//       "Altitude (Ft)": 134,
//       "Country": "India",
//       "Latitude": 79.812,
//       "Location": "Pendicherry",
//       "Longitude": 11.968,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3148,
//       "Airport Code [ICAO]": "VORY",
//       "Airport Code iATA,FAA": "RJA",
//       "Airport Name": "Rajahmundry Airport",
//       "Altitude (Ft)": 151,
//       "Country": "India",
//       "Latitude": 81.81819915770001,
//       "Location": "Rajahmundry",
//       "Longitude": 17.1103992462,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3149,
//       "Airport Code [ICAO]": "VOSM",
//       "Airport Code iATA,FAA": "SXV",
//       "Airport Name": "Salem Airport",
//       "Altitude (Ft)": 1008,
//       "Country": "India",
//       "Latitude": 78.06559753418,
//       "Location": "Salem",
//       "Longitude": 11.78330039978,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3150,
//       "Airport Code [ICAO]": "VOTJ",
//       "Airport Code iATA,FAA": "TJV",
//       "Airport Name": "Tanjore Air Force Base",
//       "Altitude (Ft)": 253,
//       "Country": "India",
//       "Latitude": 79.10160064697266,
//       "Location": "Tanjore",
//       "Longitude": 10.722399711608887,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3151,
//       "Airport Code [ICAO]": "VOTP",
//       "Airport Code iATA,FAA": "TIR",
//       "Airport Name": "Tirupati Airport",
//       "Altitude (Ft)": 350,
//       "Country": "India",
//       "Latitude": 79.543296814,
//       "Location": "Tirupeti",
//       "Longitude": 13.632499694800002,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3152,
//       "Airport Code [ICAO]": "VOTR",
//       "Airport Code iATA,FAA": "TRZ",
//       "Airport Name": "Tiruchirapally Civil Airport Airport",
//       "Altitude (Ft)": 288,
//       "Country": "India",
//       "Latitude": 78.70970153808594,
//       "Location": "Tiruchirappalli",
//       "Longitude": 10.765399932861328,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3153,
//       "Airport Code [ICAO]": "VOTV",
//       "Airport Code iATA,FAA": "TRV",
//       "Airport Name": "Trivandrum International Airport",
//       "Altitude (Ft)": 15,
//       "Country": "India",
//       "Latitude": 76.9200973511,
//       "Location": "Trivandrum",
//       "Longitude": 8.48211956024,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3154,
//       "Airport Code [ICAO]": "VOTX",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tambaram Air Force Station",
//       "Altitude (Ft)": 90,
//       "Country": "India",
//       "Latitude": 80.12190246582031,
//       "Location": "Tambaram",
//       "Longitude": 12.90719985961914,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3155,
//       "Airport Code [ICAO]": "VQPR",
//       "Airport Code iATA,FAA": "PBH",
//       "Airport Name": "Paro Airport",
//       "Altitude (Ft)": 7332,
//       "Country": "Bhutan",
//       "Latitude": 89.42459869380001,
//       "Location": "Thimphu",
//       "Longitude": 27.403200149499998,
//       "GMT Offset": "6",
//       "Daylight Savings": "N",
//       "Region": "Asia/Thimphu",
//       "Type": "airport"
//     },
//     {
//       "ID": 3156,
//       "Airport Code [ICAO]": "VRMM",
//       "Airport Code iATA,FAA": "MLE",
//       "Airport Name": "Malé International Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Maldives",
//       "Latitude": 73.52909851074219,
//       "Location": "Male",
//       "Longitude": 4.191830158233643,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Indian/Maldives",
//       "Type": "airport"
//     },
//     {
//       "ID": 3157,
//       "Airport Code [ICAO]": "VTBD",
//       "Airport Code iATA,FAA": "DMK",
//       "Airport Name": "Don Mueang International Airport",
//       "Altitude (Ft)": 9,
//       "Country": "Thailand",
//       "Latitude": 100.607002258,
//       "Location": "Bangkok",
//       "Longitude": 13.9125995636,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3158,
//       "Airport Code [ICAO]": "VTBK",
//       "Airport Code iATA,FAA": "KDT",
//       "Airport Name": "Kamphaeng Saen Airport",
//       "Altitude (Ft)": 30,
//       "Country": "Thailand",
//       "Latitude": 99.9171981812,
//       "Location": "Nakhon Pathom",
//       "Longitude": 14.1020002365,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3159,
//       "Airport Code [ICAO]": "VTBL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Khok Kathiam Airport",
//       "Altitude (Ft)": 123,
//       "Country": "Thailand",
//       "Latitude": 100.663002014,
//       "Location": "Lop Buri",
//       "Longitude": 14.8746004105,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3161,
//       "Airport Code [ICAO]": "VTBU",
//       "Airport Code iATA,FAA": "UTP",
//       "Airport Name": "U-Tapao International Airport",
//       "Altitude (Ft)": 42,
//       "Country": "Thailand",
//       "Latitude": 101.00499725341797,
//       "Location": "Pattaya",
//       "Longitude": 12.679900169372559,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3162,
//       "Airport Code [ICAO]": "VTBW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Watthana Nakhon Airport",
//       "Altitude (Ft)": 200,
//       "Country": "Thailand",
//       "Latitude": 102.31500244140625,
//       "Location": "Prachin Buri",
//       "Longitude": 13.768799781799316,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3163,
//       "Airport Code [ICAO]": "VTCL",
//       "Airport Code iATA,FAA": "LPT",
//       "Airport Name": "Lampang Airport",
//       "Altitude (Ft)": 811,
//       "Country": "Thailand",
//       "Latitude": 99.50420379638672,
//       "Location": "Lampang",
//       "Longitude": 18.27090072631836,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3164,
//       "Airport Code [ICAO]": "VTCP",
//       "Airport Code iATA,FAA": "PRH",
//       "Airport Name": "Phrae Airport",
//       "Altitude (Ft)": 538,
//       "Country": "Thailand",
//       "Latitude": 100.16500091552734,
//       "Location": "Phrae",
//       "Longitude": 18.132200241088867,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3165,
//       "Airport Code [ICAO]": "VTPH",
//       "Airport Code iATA,FAA": "HHQ",
//       "Airport Name": "Hua Hin Airport",
//       "Altitude (Ft)": 62,
//       "Country": "Thailand",
//       "Latitude": 99.951499939,
//       "Location": "Prachuap Khiri Khan",
//       "Longitude": 12.6361999512,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3166,
//       "Airport Code [ICAO]": "VTPI",
//       "Airport Code iATA,FAA": "TKH",
//       "Airport Name": "Takhli Airport",
//       "Altitude (Ft)": 107,
//       "Country": "Thailand",
//       "Latitude": 100.29599762,
//       "Location": "Nakhon Sawan",
//       "Longitude": 15.277299881,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3167,
//       "Airport Code [ICAO]": "VTPL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sak Long Airport",
//       "Altitude (Ft)": 500,
//       "Country": "Thailand",
//       "Latitude": 101.2509994506836,
//       "Location": "Phetchabun",
//       "Longitude": 16.82430076599121,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3169,
//       "Airport Code [ICAO]": "VTPN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nakhon Sawan Airport",
//       "Altitude (Ft)": 113,
//       "Country": "Thailand",
//       "Latitude": 100.13700103759766,
//       "Location": "Nakhon Sawan",
//       "Longitude": 15.67300033569336,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3170,
//       "Airport Code [ICAO]": "VTPP",
//       "Airport Code iATA,FAA": "PHS",
//       "Airport Name": "Phitsanulok Airport",
//       "Altitude (Ft)": 154,
//       "Country": "Thailand",
//       "Latitude": 100.27899932861328,
//       "Location": "Phitsanulok",
//       "Longitude": 16.782899856567383,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3171,
//       "Airport Code [ICAO]": "VTPY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Khunan Phumipol Airport",
//       "Altitude (Ft)": 492,
//       "Country": "Thailand",
//       "Latitude": 99.05789947509766,
//       "Location": "Tak",
//       "Longitude": 17.23419952392578,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3172,
//       "Airport Code [ICAO]": "VTSA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Khoun Khan Airport",
//       "Altitude (Ft)": 18,
//       "Country": "Thailand",
//       "Latitude": 100.08000183105469,
//       "Location": "Satun",
//       "Longitude": 6.661399841308594,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3173,
//       "Airport Code [ICAO]": "VTSC",
//       "Airport Code iATA,FAA": "NAW",
//       "Airport Name": "Narathiwat Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Thailand",
//       "Latitude": 101.74299621582031,
//       "Location": "Narathiwat",
//       "Longitude": 6.5199198722839355,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3174,
//       "Airport Code [ICAO]": "VTSG",
//       "Airport Code iATA,FAA": "KBV",
//       "Airport Name": "Krabi Airport",
//       "Altitude (Ft)": 82,
//       "Country": "Thailand",
//       "Latitude": 98.9861984253,
//       "Location": "Krabi",
//       "Longitude": 8.09912014008,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3175,
//       "Airport Code [ICAO]": "VTSH",
//       "Airport Code iATA,FAA": "SGZ",
//       "Airport Name": "Songkhla Airport",
//       "Altitude (Ft)": 12,
//       "Country": "Thailand",
//       "Latitude": 100.60800170898438,
//       "Location": "Songkhla",
//       "Longitude": 7.186560153961182,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3176,
//       "Airport Code [ICAO]": "VTSK",
//       "Airport Code iATA,FAA": "PAN",
//       "Airport Name": "Pattani Airport",
//       "Altitude (Ft)": 8,
//       "Country": "Thailand",
//       "Latitude": 101.15399932861328,
//       "Location": "Pattani",
//       "Longitude": 6.785459995269775,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3177,
//       "Airport Code [ICAO]": "VTSM",
//       "Airport Code iATA,FAA": "USM",
//       "Airport Name": "Samui Airport",
//       "Altitude (Ft)": 64,
//       "Country": "Thailand",
//       "Latitude": 100.06199646,
//       "Location": "Ko Samui",
//       "Longitude": 9.547789573669998,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3178,
//       "Airport Code [ICAO]": "VTSN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cha Eian Airport",
//       "Altitude (Ft)": 44,
//       "Country": "Thailand",
//       "Latitude": 99.9555969238,
//       "Location": "Nakhon Si Thammarat",
//       "Longitude": 8.47115039825,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3179,
//       "Airport Code [ICAO]": "VTSP",
//       "Airport Code iATA,FAA": "HKT",
//       "Airport Name": "Phuket International Airport",
//       "Altitude (Ft)": 82,
//       "Country": "Thailand",
//       "Latitude": 98.316902,
//       "Location": "Phuket",
//       "Longitude": 8.1132,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3180,
//       "Airport Code [ICAO]": "VTSR",
//       "Airport Code iATA,FAA": "UNN",
//       "Airport Name": "Ranong Airport",
//       "Altitude (Ft)": 57,
//       "Country": "Thailand",
//       "Latitude": 98.58550262451172,
//       "Location": "Ranong",
//       "Longitude": 9.777620315551758,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3181,
//       "Airport Code [ICAO]": "VTSS",
//       "Airport Code iATA,FAA": "HDY",
//       "Airport Name": "Hat Yai International Airport",
//       "Altitude (Ft)": 90,
//       "Country": "Thailand",
//       "Latitude": 100.392997742,
//       "Location": "Hat Yai",
//       "Longitude": 6.93320989609,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3182,
//       "Airport Code [ICAO]": "VTST",
//       "Airport Code iATA,FAA": "TST",
//       "Airport Name": "Trang Airport",
//       "Altitude (Ft)": 67,
//       "Country": "Thailand",
//       "Latitude": 99.6166000366211,
//       "Location": "Trang",
//       "Longitude": 7.508739948272705,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3183,
//       "Airport Code [ICAO]": "VTUD",
//       "Airport Code iATA,FAA": "UTH",
//       "Airport Name": "Udon Thani Airport",
//       "Altitude (Ft)": 579,
//       "Country": "Thailand",
//       "Latitude": 102.788002014,
//       "Location": "Udon Thani",
//       "Longitude": 17.386400222800003,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3184,
//       "Airport Code [ICAO]": "VTUI",
//       "Airport Code iATA,FAA": "SNO",
//       "Airport Name": "Sakon Nakhon Airport",
//       "Altitude (Ft)": 529,
//       "Country": "Thailand",
//       "Latitude": 104.11900329589844,
//       "Location": "Sakon Nakhon",
//       "Longitude": 17.195100784301758,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3185,
//       "Airport Code [ICAO]": "VTUJ",
//       "Airport Code iATA,FAA": "PXR",
//       "Airport Name": "Surin Airport",
//       "Altitude (Ft)": 478,
//       "Country": "Thailand",
//       "Latitude": 103.49800109863,
//       "Location": "Surin",
//       "Longitude": 14.868300437927,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3186,
//       "Airport Code [ICAO]": "VTUL",
//       "Airport Code iATA,FAA": "LOE",
//       "Airport Name": "Loei Airport",
//       "Altitude (Ft)": 860,
//       "Country": "Thailand",
//       "Latitude": 101.72200012207031,
//       "Location": "Loei",
//       "Longitude": 17.43910026550293,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3187,
//       "Airport Code [ICAO]": "VTUN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Khorat Airport",
//       "Altitude (Ft)": 729,
//       "Country": "Thailand",
//       "Latitude": 102.07900238,
//       "Location": "Nakhon Ratchasima",
//       "Longitude": 14.9344997406,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3188,
//       "Airport Code [ICAO]": "VTUR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Rob Muang Airport",
//       "Altitude (Ft)": 459,
//       "Country": "Thailand",
//       "Latitude": 103.64600372314453,
//       "Location": "Roi Et",
//       "Longitude": 16.07040023803711,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3196,
//       "Airport Code [ICAO]": "VVDN",
//       "Airport Code iATA,FAA": "DAD",
//       "Airport Name": "Da Nang International Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Vietnam",
//       "Latitude": 108.1989974975586,
//       "Location": "Danang",
//       "Longitude": 16.043899536132812,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Saigon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3197,
//       "Airport Code [ICAO]": "VVGL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Gia Lam Air Base",
//       "Altitude (Ft)": 50,
//       "Country": "Vietnam",
//       "Latitude": 105.88600158691406,
//       "Location": "Hanoi",
//       "Longitude": 21.04050064086914,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Saigon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3198,
//       "Airport Code [ICAO]": "VVKP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kep Air Base",
//       "Altitude (Ft)": 55,
//       "Country": "Vietnam",
//       "Latitude": 106.261001587,
//       "Location": "Kep",
//       "Longitude": 21.394599914599997,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Saigon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3199,
//       "Airport Code [ICAO]": "VVNB",
//       "Airport Code iATA,FAA": "HAN",
//       "Airport Name": "Noi Bai International Airport",
//       "Altitude (Ft)": 39,
//       "Country": "Vietnam",
//       "Latitude": 105.80699920654297,
//       "Location": "Hanoi",
//       "Longitude": 21.221200942993164,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Saigon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3200,
//       "Airport Code [ICAO]": "VVNT",
//       "Airport Code iATA,FAA": "NHA",
//       "Airport Name": "Nha Trang Air Base",
//       "Altitude (Ft)": 20,
//       "Country": "Vietnam",
//       "Latitude": 109.192001,
//       "Location": "Nhatrang",
//       "Longitude": 12.2275,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Saigon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3201,
//       "Airport Code [ICAO]": "VVPB",
//       "Airport Code iATA,FAA": "HUI",
//       "Airport Name": "Phu Bai Airport",
//       "Altitude (Ft)": 48,
//       "Country": "Vietnam",
//       "Latitude": 107.70300293,
//       "Location": "Hue",
//       "Longitude": 16.401500701899998,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Saigon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3204,
//       "Airport Code [ICAO]": "VVPQ",
//       "Airport Code iATA,FAA": "PQC",
//       "Airport Name": "Phu Quoc International Airport",
//       "Altitude (Ft)": 37,
//       "Country": "Vietnam",
//       "Latitude": 103.9931,
//       "Location": "Phuquoc",
//       "Longitude": 10.1698,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Saigon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3205,
//       "Airport Code [ICAO]": "VVTS",
//       "Airport Code iATA,FAA": "SGN",
//       "Airport Name": "Tan Son Nhat International Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Vietnam",
//       "Latitude": 106.652000427,
//       "Location": "Ho Chi Minh City",
//       "Longitude": 10.8187999725,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Saigon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3207,
//       "Airport Code [ICAO]": "VYAN",
//       "Airport Code iATA,FAA": "VBA",
//       "Airport Name": "Ann Airport",
//       "Altitude (Ft)": 74,
//       "Country": "Burma",
//       "Latitude": 94.0261,
//       "Location": "Ann",
//       "Longitude": 19.769199,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3208,
//       "Airport Code [ICAO]": "VYAS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Anisakan Airport",
//       "Altitude (Ft)": 3000,
//       "Country": "Burma",
//       "Latitude": 96.40609741210938,
//       "Location": "Anisakan",
//       "Longitude": 21.955400466918945,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3209,
//       "Airport Code [ICAO]": "VYBG",
//       "Airport Code iATA,FAA": "NYU",
//       "Airport Name": "Bagan Airport",
//       "Altitude (Ft)": 312,
//       "Country": "Burma",
//       "Latitude": 94.9301986694336,
//       "Location": "Bagan",
//       "Longitude": 21.178800582885742,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3211,
//       "Airport Code [ICAO]": "VYCI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Coco Island Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Burma",
//       "Latitude": 93.36849975585938,
//       "Location": "Coco Island",
//       "Longitude": 14.141500473022461,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 3213,
//       "Airport Code [ICAO]": "VYHH",
//       "Airport Code iATA,FAA": "HEH",
//       "Airport Name": "Heho Airport",
//       "Altitude (Ft)": 3858,
//       "Country": "Burma",
//       "Latitude": 96.79199981689453,
//       "Location": "Heho",
//       "Longitude": 20.746999740600586,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3214,
//       "Airport Code [ICAO]": "VYHL",
//       "Airport Code iATA,FAA": "HOX",
//       "Airport Name": "Hommalinn Airport",
//       "Altitude (Ft)": 534,
//       "Country": "Burma",
//       "Latitude": 94.91400146484375,
//       "Location": "Hommalin",
//       "Longitude": 24.899599075317383,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3215,
//       "Airport Code [ICAO]": "VYKG",
//       "Airport Code iATA,FAA": "KET",
//       "Airport Name": "Kengtung Airport",
//       "Altitude (Ft)": 2798,
//       "Country": "Burma",
//       "Latitude": 99.63600158691406,
//       "Location": "Kengtung",
//       "Longitude": 21.301599502563477,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3217,
//       "Airport Code [ICAO]": "VYKP",
//       "Airport Code iATA,FAA": "KYP",
//       "Airport Name": "Kyaukpyu Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Burma",
//       "Latitude": 93.53479766845703,
//       "Location": "Kyaukpyu",
//       "Longitude": 19.42639923095703,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3220,
//       "Airport Code [ICAO]": "VYLS",
//       "Airport Code iATA,FAA": "LSH",
//       "Airport Name": "Lashio Airport",
//       "Altitude (Ft)": 2450,
//       "Country": "Burma",
//       "Latitude": 97.752197265625,
//       "Location": "Lashio",
//       "Longitude": 22.9778995513916,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3221,
//       "Airport Code [ICAO]": "VYLY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lanywa Airport",
//       "Altitude (Ft)": 175,
//       "Country": "Burma",
//       "Latitude": 94.82260131835938,
//       "Location": "Lanywa",
//       "Longitude": 20.940399169921875,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3222,
//       "Airport Code [ICAO]": "VYMD",
//       "Airport Code iATA,FAA": "MDL",
//       "Airport Name": "Mandalay International Airport",
//       "Altitude (Ft)": 300,
//       "Country": "Burma",
//       "Latitude": 95.97789764404297,
//       "Location": "Mandalay",
//       "Longitude": 21.702199935913086,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3223,
//       "Airport Code [ICAO]": "VYME",
//       "Airport Code iATA,FAA": "MGZ",
//       "Airport Name": "Myeik Airport",
//       "Altitude (Ft)": 75,
//       "Country": "Burma",
//       "Latitude": 98.62149810791016,
//       "Location": "Myeik",
//       "Longitude": 12.439800262451172,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3224,
//       "Airport Code [ICAO]": "VYMK",
//       "Airport Code iATA,FAA": "MYT",
//       "Airport Name": "Myitkyina Airport",
//       "Altitude (Ft)": 475,
//       "Country": "Burma",
//       "Latitude": 97.35189819335938,
//       "Location": "Myitkyina",
//       "Longitude": 25.38360023498535,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3226,
//       "Airport Code [ICAO]": "VYMO",
//       "Airport Code iATA,FAA": "MOE",
//       "Airport Name": "Momeik Airport",
//       "Altitude (Ft)": 600,
//       "Country": "Burma",
//       "Latitude": 96.64530181884766,
//       "Location": "Momeik",
//       "Longitude": 23.092500686645508,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3227,
//       "Airport Code [ICAO]": "VYMS",
//       "Airport Code iATA,FAA": "MOG",
//       "Airport Name": "Mong Hsat Airport",
//       "Altitude (Ft)": 1875,
//       "Country": "Burma",
//       "Latitude": 99.25679779052734,
//       "Location": "Mong Hsat",
//       "Longitude": 20.516799926757812,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3228,
//       "Airport Code [ICAO]": "VYNP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nampong Air Base",
//       "Altitude (Ft)": 459,
//       "Country": "Burma",
//       "Latitude": 97.2951965332,
//       "Location": "Nampong",
//       "Longitude": 25.354400634799998,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3229,
//       "Airport Code [ICAO]": "VYNS",
//       "Airport Code iATA,FAA": "NMS",
//       "Airport Name": "Namsang Airport",
//       "Altitude (Ft)": 3100,
//       "Country": "Burma",
//       "Latitude": 97.73590087890625,
//       "Location": "Namsang",
//       "Longitude": 20.890499114990234,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3230,
//       "Airport Code [ICAO]": "VYPA",
//       "Airport Code iATA,FAA": "PAA",
//       "Airport Name": "Hpa-N Airport",
//       "Altitude (Ft)": 150,
//       "Country": "Burma",
//       "Latitude": 97.67459869384766,
//       "Location": "Hpa-an",
//       "Longitude": 16.893699645996094,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3232,
//       "Airport Code [ICAO]": "VYPT",
//       "Airport Code iATA,FAA": "PBU",
//       "Airport Name": "Putao Airport",
//       "Altitude (Ft)": 1500,
//       "Country": "Burma",
//       "Latitude": 97.42630004882812,
//       "Location": "Putao",
//       "Longitude": 27.32990074157715,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3233,
//       "Airport Code [ICAO]": "VYPY",
//       "Airport Code iATA,FAA": "PRU",
//       "Airport Name": "Pyay Airport",
//       "Altitude (Ft)": 120,
//       "Country": "Burma",
//       "Latitude": 95.26599884033203,
//       "Location": "Pyay",
//       "Longitude": 18.824499130249023,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3234,
//       "Airport Code [ICAO]": "VYST",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Shante Air Base",
//       "Altitude (Ft)": 630,
//       "Country": "Burma",
//       "Latitude": 95.91449737548828,
//       "Location": "Shante",
//       "Longitude": 20.941699981689453,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3235,
//       "Airport Code [ICAO]": "VYSW",
//       "Airport Code iATA,FAA": "AKY",
//       "Airport Name": "Sittwe Airport",
//       "Altitude (Ft)": 27,
//       "Country": "Burma",
//       "Latitude": 92.87259674072266,
//       "Location": "Sittwe",
//       "Longitude": 20.132699966430664,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3236,
//       "Airport Code [ICAO]": "VYTD",
//       "Airport Code iATA,FAA": "SNW",
//       "Airport Name": "Thandwe Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Burma",
//       "Latitude": 94.30010223388672,
//       "Location": "Thandwe",
//       "Longitude": 18.4606990814209,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3237,
//       "Airport Code [ICAO]": "VYTL",
//       "Airport Code iATA,FAA": "THL",
//       "Airport Name": "Tachileik Airport",
//       "Altitude (Ft)": 1280,
//       "Country": "Burma",
//       "Latitude": 99.9354019165039,
//       "Location": "Tachilek",
//       "Longitude": 20.483800888061523,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3238,
//       "Airport Code [ICAO]": "VYTO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Taungoo Airport",
//       "Altitude (Ft)": 160,
//       "Country": "Burma",
//       "Latitude": 96.40119934082031,
//       "Location": "Taungoo",
//       "Longitude": 19.031299591064453,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3239,
//       "Airport Code [ICAO]": "VYYY",
//       "Airport Code iATA,FAA": "RGN",
//       "Airport Name": "Yangon International Airport",
//       "Altitude (Ft)": 109,
//       "Country": "Burma",
//       "Latitude": 96.1332015991,
//       "Location": "Yangon",
//       "Longitude": 16.907300949099998,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 3240,
//       "Airport Code [ICAO]": "WAAA",
//       "Airport Code iATA,FAA": "UPG",
//       "Airport Name": "Hasanuddin International Airport",
//       "Altitude (Ft)": 47,
//       "Country": "Indonesia",
//       "Latitude": 119.55400085449219,
//       "Location": "Ujung Pandang",
//       "Longitude": -5.061629772186279,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3241,
//       "Airport Code [ICAO]": "WABB",
//       "Airport Code iATA,FAA": "BIK",
//       "Airport Name": "Frans Kaisiepo Airport",
//       "Altitude (Ft)": 46,
//       "Country": "Indonesia",
//       "Latitude": 136.10800170898438,
//       "Location": "Biak",
//       "Longitude": -1.190019965171814,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jayapura",
//       "Type": "airport"
//     },
//     {
//       "ID": 3242,
//       "Airport Code [ICAO]": "WABI",
//       "Airport Code iATA,FAA": "NBX",
//       "Airport Name": "Nabire Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Indonesia",
//       "Latitude": 135.496002,
//       "Location": "Nabire",
//       "Longitude": -3.36818,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jayapura",
//       "Type": "airport"
//     },
//     {
//       "ID": 3243,
//       "Airport Code [ICAO]": "WABP",
//       "Airport Code iATA,FAA": "TIM",
//       "Airport Name": "Moses Kilangin Airport",
//       "Altitude (Ft)": 103,
//       "Country": "Indonesia",
//       "Latitude": 136.88699340820312,
//       "Location": "Timika",
//       "Longitude": -4.528279781341553,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jayapura",
//       "Type": "airport"
//     },
//     {
//       "ID": 3244,
//       "Airport Code [ICAO]": "WAJJ",
//       "Airport Code iATA,FAA": "DJJ",
//       "Airport Name": "Sentani International Airport",
//       "Altitude (Ft)": 289,
//       "Country": "Indonesia",
//       "Latitude": 140.5160064698,
//       "Location": "Jayapura",
//       "Longitude": -2.5769500733,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jayapura",
//       "Type": "airport"
//     },
//     {
//       "ID": 3245,
//       "Airport Code [ICAO]": "WAJW",
//       "Airport Code iATA,FAA": "WMX",
//       "Airport Name": "Wamena Airport",
//       "Altitude (Ft)": 5085,
//       "Country": "Indonesia",
//       "Latitude": 138.957001,
//       "Location": "Wamena",
//       "Longitude": -4.10251,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jayapura",
//       "Type": "airport"
//     },
//     {
//       "ID": 3246,
//       "Airport Code [ICAO]": "WAKK",
//       "Airport Code iATA,FAA": "MKQ",
//       "Airport Name": "Mopah Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Indonesia",
//       "Latitude": 140.41799926757812,
//       "Location": "Merauke",
//       "Longitude": -8.52029037475586,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jayapura",
//       "Type": "airport"
//     },
//     {
//       "ID": 3247,
//       "Airport Code [ICAO]": "WAMG",
//       "Airport Code iATA,FAA": "GTO",
//       "Airport Name": "Jalaluddin Airport",
//       "Altitude (Ft)": 105,
//       "Country": "Indonesia",
//       "Latitude": 122.849998474,
//       "Location": "Gorontalo",
//       "Longitude": 0.63711899519,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3249,
//       "Airport Code [ICAO]": "WAML",
//       "Airport Code iATA,FAA": "PLW",
//       "Airport Name": "Mutiara Airport",
//       "Altitude (Ft)": 284,
//       "Country": "Indonesia",
//       "Latitude": 119.91000366210938,
//       "Location": "Palu",
//       "Longitude": -0.9185420274734497,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3250,
//       "Airport Code [ICAO]": "WAMM",
//       "Airport Code iATA,FAA": "MDC",
//       "Airport Name": "Sam Ratulangi Airport",
//       "Altitude (Ft)": 264,
//       "Country": "Indonesia",
//       "Latitude": 124.9260025024414,
//       "Location": "Manado",
//       "Longitude": 1.5492600202560425,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3251,
//       "Airport Code [ICAO]": "WAMP",
//       "Airport Code iATA,FAA": "PSJ",
//       "Airport Name": "Kasiguncu Airport",
//       "Altitude (Ft)": 174,
//       "Country": "Indonesia",
//       "Latitude": 120.657997131,
//       "Location": "Poso",
//       "Longitude": -1.41674995422,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3252,
//       "Airport Code [ICAO]": "WAMR",
//       "Airport Code iATA,FAA": "OTI",
//       "Airport Name": "Pitu Airport",
//       "Altitude (Ft)": 49,
//       "Country": "Indonesia",
//       "Latitude": 128.324996948,
//       "Location": "Morotai Island",
//       "Longitude": 2.0459899902300003,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jayapura",
//       "Type": "airport"
//     },
//     {
//       "ID": 3253,
//       "Airport Code [ICAO]": "WAMT",
//       "Airport Code iATA,FAA": "TTE",
//       "Airport Name": "Sultan Khairun Babullah Airport",
//       "Altitude (Ft)": 49,
//       "Country": "Indonesia",
//       "Latitude": 127.38099670410156,
//       "Location": "Ternate",
//       "Longitude": 0.831413984298706,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jayapura",
//       "Type": "airport"
//     },
//     {
//       "ID": 3254,
//       "Airport Code [ICAO]": "WAMW",
//       "Airport Code iATA,FAA": "LUW",
//       "Airport Name": "Syukuran Aminuddin Amir Airport",
//       "Altitude (Ft)": 56,
//       "Country": "Indonesia",
//       "Latitude": 122.772003,
//       "Location": "Luwuk",
//       "Longitude": -1.03892,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3256,
//       "Airport Code [ICAO]": "WAPP",
//       "Airport Code iATA,FAA": "AMQ",
//       "Airport Name": "Pattimura Airport, Ambon",
//       "Altitude (Ft)": 33,
//       "Country": "Indonesia",
//       "Latitude": 128.089004517,
//       "Location": "Ambon",
//       "Longitude": -3.7102599144,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jayapura",
//       "Type": "airport"
//     },
//     {
//       "ID": 3257,
//       "Airport Code [ICAO]": "WASF",
//       "Airport Code iATA,FAA": "FKQ",
//       "Airport Name": "Fakfak Airport",
//       "Altitude (Ft)": 462,
//       "Country": "Indonesia",
//       "Latitude": 132.26699829101562,
//       "Location": "Fak Fak",
//       "Longitude": -2.9201900959014893,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jayapura",
//       "Type": "airport"
//     },
//     {
//       "ID": 3258,
//       "Airport Code [ICAO]": "WASK",
//       "Airport Code iATA,FAA": "KNG",
//       "Airport Name": "Kaimana Airport",
//       "Altitude (Ft)": 19,
//       "Country": "Indonesia",
//       "Latitude": 133.6959991455078,
//       "Location": "Kaimana",
//       "Longitude": -3.6445200443267822,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jayapura",
//       "Type": "airport"
//     },
//     {
//       "ID": 3259,
//       "Airport Code [ICAO]": "WASO",
//       "Airport Code iATA,FAA": "BXB",
//       "Airport Name": "Babo Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Indonesia",
//       "Latitude": 133.43899536132812,
//       "Location": "Babo",
//       "Longitude": -2.5322399139404297,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jayapura",
//       "Type": "airport"
//     },
//     {
//       "ID": 3260,
//       "Airport Code [ICAO]": "WASR",
//       "Airport Code iATA,FAA": "MKW",
//       "Airport Name": "Rendani Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Indonesia",
//       "Latitude": 134.0489959716797,
//       "Location": "Manokwari",
//       "Longitude": -0.8918330073356628,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jayapura",
//       "Type": "airport"
//     },
//     {
//       "ID": 3261,
//       "Airport Code [ICAO]": "WAXX",
//       "Airport Code iATA,FAA": "SOQ",
//       "Airport Name": "Dominique Edward Osok Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Indonesia",
//       "Latitude": 131.287,
//       "Location": "Sorong",
//       "Longitude": -0.894,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jayapura",
//       "Type": "airport"
//     },
//     {
//       "ID": 3262,
//       "Airport Code [ICAO]": "WBGB",
//       "Airport Code iATA,FAA": "BTU",
//       "Airport Name": "Bintulu Airport",
//       "Altitude (Ft)": 74,
//       "Country": "Malaysia",
//       "Latitude": 113.019996643,
//       "Location": "Bintulu",
//       "Longitude": 3.12385010719,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 3263,
//       "Airport Code [ICAO]": "WBGG",
//       "Airport Code iATA,FAA": "KCH",
//       "Airport Name": "Kuching International Airport",
//       "Altitude (Ft)": 89,
//       "Country": "Malaysia",
//       "Latitude": 110.34700012207031,
//       "Location": "Kuching",
//       "Longitude": 1.4846999645233154,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 3264,
//       "Airport Code [ICAO]": "WBGJ",
//       "Airport Code iATA,FAA": "LMN",
//       "Airport Name": "Limbang Airport",
//       "Altitude (Ft)": 14,
//       "Country": "Malaysia",
//       "Latitude": 115.01000213623047,
//       "Location": "Limbang",
//       "Longitude": 4.808300018310547,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 3265,
//       "Airport Code [ICAO]": "WBGM",
//       "Airport Code iATA,FAA": "MUR",
//       "Airport Name": "Marudi Airport",
//       "Altitude (Ft)": 103,
//       "Country": "Malaysia",
//       "Latitude": 114.3290023803711,
//       "Location": "Marudi",
//       "Longitude": 4.178979873657227,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 3266,
//       "Airport Code [ICAO]": "WBGR",
//       "Airport Code iATA,FAA": "MYY",
//       "Airport Name": "Miri Airport",
//       "Altitude (Ft)": 59,
//       "Country": "Malaysia",
//       "Latitude": 113.98699951171875,
//       "Location": "Miri",
//       "Longitude": 4.322010040283203,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 3267,
//       "Airport Code [ICAO]": "WBGS",
//       "Airport Code iATA,FAA": "SBW",
//       "Airport Name": "Sibu Airport",
//       "Altitude (Ft)": 122,
//       "Country": "Malaysia",
//       "Latitude": 111.98500061035156,
//       "Location": "Sibu",
//       "Longitude": 2.2616000175476074,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 3268,
//       "Airport Code [ICAO]": "WBKD",
//       "Airport Code iATA,FAA": "LDU",
//       "Airport Name": "Lahad Datu Airport",
//       "Altitude (Ft)": 45,
//       "Country": "Malaysia",
//       "Latitude": 118.3239974975586,
//       "Location": "Lahad Datu",
//       "Longitude": 5.032249927520752,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 3269,
//       "Airport Code [ICAO]": "WBKK",
//       "Airport Code iATA,FAA": "BKI",
//       "Airport Name": "Kota Kinabalu International Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Malaysia",
//       "Latitude": 116.0510025024414,
//       "Location": "Kota Kinabalu",
//       "Longitude": 5.9372100830078125,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 3270,
//       "Airport Code [ICAO]": "WBKL",
//       "Airport Code iATA,FAA": "LBU",
//       "Airport Name": "Labuan Airport",
//       "Altitude (Ft)": 101,
//       "Country": "Malaysia",
//       "Latitude": 115.25,
//       "Location": "Labuan",
//       "Longitude": 5.300680160522461,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 3271,
//       "Airport Code [ICAO]": "WBKW",
//       "Airport Code iATA,FAA": "TWU",
//       "Airport Name": "Tawau Airport",
//       "Altitude (Ft)": 57,
//       "Country": "Malaysia",
//       "Latitude": 118.12799835205078,
//       "Location": "Tawau",
//       "Longitude": 4.320159912109375,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 3272,
//       "Airport Code [ICAO]": "WBSB",
//       "Airport Code iATA,FAA": "BWN",
//       "Airport Name": "Brunei International Airport",
//       "Altitude (Ft)": 73,
//       "Country": "Brunei",
//       "Latitude": 114.9280014038086,
//       "Location": "Bandar Seri Begawan",
//       "Longitude": 4.944200038909912,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Brunei",
//       "Type": "airport"
//     },
//     {
//       "ID": 3273,
//       "Airport Code [ICAO]": "WIBB",
//       "Airport Code iATA,FAA": "PKU",
//       "Airport Name": "Sultan Syarif Kasim Ii (Simpang Tiga) Airport",
//       "Altitude (Ft)": 102,
//       "Country": "Indonesia",
//       "Latitude": 101.44499969482422,
//       "Location": "Pekanbaru",
//       "Longitude": 0.46078601479530334,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3274,
//       "Airport Code [ICAO]": "WIBD",
//       "Airport Code iATA,FAA": "DUM",
//       "Airport Name": "Pinang Kampai Airport",
//       "Altitude (Ft)": 55,
//       "Country": "Indonesia",
//       "Latitude": 101.433998,
//       "Location": "Dumai",
//       "Longitude": 1.60919,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3275,
//       "Airport Code [ICAO]": "WIII",
//       "Airport Code iATA,FAA": "CGK",
//       "Airport Name": "Soekarno-Hatta International Airport",
//       "Altitude (Ft)": 34,
//       "Country": "Indonesia",
//       "Latitude": 106.65599823,
//       "Location": "Jakarta",
//       "Longitude": -6.1255698204,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3276,
//       "Airport Code [ICAO]": "WIMB",
//       "Airport Code iATA,FAA": "GNS",
//       "Airport Name": "Binaka Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Indonesia",
//       "Latitude": 97.704697,
//       "Location": "Gunung Sitoli",
//       "Longitude": 1.16638,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3277,
//       "Airport Code [ICAO]": "WIME",
//       "Airport Code iATA,FAA": "AEG",
//       "Airport Name": "Aek Godang Airport",
//       "Altitude (Ft)": 922,
//       "Country": "Indonesia",
//       "Latitude": 99.430496,
//       "Location": "Padang Sidempuan",
//       "Longitude": 1.4001,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3278,
//       "Airport Code [ICAO]": "WIPT",
//       "Airport Code iATA,FAA": "PDG",
//       "Airport Name": "Minangkabau International Airport",
//       "Altitude (Ft)": 18,
//       "Country": "Indonesia",
//       "Latitude": 100.280998,
//       "Location": "Padang",
//       "Longitude": -0.786917,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3279,
//       "Airport Code [ICAO]": "WIMK",
//       "Airport Code iATA,FAA": "MES",
//       "Airport Name": "Soewondo Air Force Base",
//       "Altitude (Ft)": 114,
//       "Country": "Indonesia",
//       "Latitude": 98.671111,
//       "Location": "Medan",
//       "Longitude": 3.559167,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3280,
//       "Airport Code [ICAO]": "WIMS",
//       "Airport Code iATA,FAA": "FLZ",
//       "Airport Name": "Dr Ferdinand Lumban Tobing Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Indonesia",
//       "Latitude": 98.888901,
//       "Location": "Sibolga",
//       "Longitude": 1.55594,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3281,
//       "Airport Code [ICAO]": "WIOG",
//       "Airport Code iATA,FAA": "NPO",
//       "Airport Name": "Nanga Pinoh Airport",
//       "Altitude (Ft)": 123,
//       "Country": "Indonesia",
//       "Latitude": 111.74800109863,
//       "Location": "Nangapinoh",
//       "Longitude": -0.34886899590492,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3282,
//       "Airport Code [ICAO]": "WIOK",
//       "Airport Code iATA,FAA": "KTG",
//       "Airport Name": "Ketapang(Rahadi Usman) Airport",
//       "Altitude (Ft)": 46,
//       "Country": "Indonesia",
//       "Latitude": 109.96299743652344,
//       "Location": "Ketapang",
//       "Longitude": -1.816640019416809,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3284,
//       "Airport Code [ICAO]": "WIOO",
//       "Airport Code iATA,FAA": "PNK",
//       "Airport Name": "Supadio Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Indonesia",
//       "Latitude": 109.40399932861328,
//       "Location": "Pontianak",
//       "Longitude": -0.15071099996566772,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3287,
//       "Airport Code [ICAO]": "WIPA",
//       "Airport Code iATA,FAA": "DJB",
//       "Airport Name": "Sultan Thaha Airport",
//       "Altitude (Ft)": 82,
//       "Country": "Indonesia",
//       "Latitude": 103.64399719238281,
//       "Location": "Jambi",
//       "Longitude": -1.6380200386047363,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3288,
//       "Airport Code [ICAO]": "WIPL",
//       "Airport Code iATA,FAA": "BKS",
//       "Airport Name": "Fatmawati Soekarno Airport",
//       "Altitude (Ft)": 50,
//       "Country": "Indonesia",
//       "Latitude": 102.338997,
//       "Location": "Bengkulu",
//       "Longitude": -3.8637,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3289,
//       "Airport Code [ICAO]": "WIPP",
//       "Airport Code iATA,FAA": "PLM",
//       "Airport Name": "Sultan Mahmud Badaruddin II Airport",
//       "Altitude (Ft)": 49,
//       "Country": "Indonesia",
//       "Latitude": 104.69999694824,
//       "Location": "Palembang",
//       "Longitude": -2.8982501029968,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3291,
//       "Airport Code [ICAO]": "WIPR",
//       "Airport Code iATA,FAA": "RGT",
//       "Airport Name": "Japura Airport",
//       "Altitude (Ft)": 62,
//       "Country": "Indonesia",
//       "Latitude": 102.33499908447266,
//       "Location": "Rengat",
//       "Longitude": -0.35280799865722656,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3292,
//       "Airport Code [ICAO]": "WITL",
//       "Airport Code iATA,FAA": "LSX",
//       "Airport Name": "Lhok Sukon Airport",
//       "Altitude (Ft)": 28,
//       "Country": "Indonesia",
//       "Latitude": 97.25920104980469,
//       "Location": "Lhok Sukon",
//       "Longitude": 5.069509983062744,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3294,
//       "Airport Code [ICAO]": "WITT",
//       "Airport Code iATA,FAA": "BTJ",
//       "Airport Name": "Sultan Iskandar Muda International Airport",
//       "Altitude (Ft)": 65,
//       "Country": "Indonesia",
//       "Latitude": 95.42063713070002,
//       "Location": "Banda Aceh",
//       "Longitude": 5.522872024010001,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3295,
//       "Airport Code [ICAO]": "WMAP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kluang Airport",
//       "Altitude (Ft)": 150,
//       "Country": "Malaysia",
//       "Latitude": 103.306999207,
//       "Location": "Kluang",
//       "Longitude": 2.04138994217,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 3296,
//       "Airport Code [ICAO]": "WMKA",
//       "Airport Code iATA,FAA": "AOR",
//       "Airport Name": "Sultan Abdul Halim Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Malaysia",
//       "Latitude": 100.39800262451172,
//       "Location": "Alor Setar",
//       "Longitude": 6.189670085906982,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 3297,
//       "Airport Code [ICAO]": "WMKB",
//       "Airport Code iATA,FAA": "BWH",
//       "Airport Name": "Butterworth Airport",
//       "Altitude (Ft)": 11,
//       "Country": "Malaysia",
//       "Latitude": 100.39099884033203,
//       "Location": "Butterworth",
//       "Longitude": 5.4659199714660645,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 3298,
//       "Airport Code [ICAO]": "WMKC",
//       "Airport Code iATA,FAA": "KBR",
//       "Airport Name": "Sultan Ismail Petra Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Malaysia",
//       "Latitude": 102.29299926757812,
//       "Location": "Kota Bahru",
//       "Longitude": 6.1668500900268555,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 3299,
//       "Airport Code [ICAO]": "WMKD",
//       "Airport Code iATA,FAA": "KUA",
//       "Airport Name": "Kuantan Airport",
//       "Altitude (Ft)": 58,
//       "Country": "Malaysia",
//       "Latitude": 103.20899963378906,
//       "Location": "Kuantan",
//       "Longitude": 3.7753899097442627,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 3300,
//       "Airport Code [ICAO]": "WMKE",
//       "Airport Code iATA,FAA": "KTE",
//       "Airport Name": "Kerteh Airport",
//       "Altitude (Ft)": 18,
//       "Country": "Malaysia",
//       "Latitude": 103.427001953125,
//       "Location": "Kerteh",
//       "Longitude": 4.537220001220703,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 3301,
//       "Airport Code [ICAO]": "WMKF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Simpang Airport",
//       "Altitude (Ft)": 111,
//       "Country": "Malaysia",
//       "Latitude": 101.7030029296875,
//       "Location": "Simpang",
//       "Longitude": 3.1122500896453857,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 3302,
//       "Airport Code [ICAO]": "WMKI",
//       "Airport Code iATA,FAA": "IPH",
//       "Airport Name": "Sultan Azlan Shah Airport",
//       "Altitude (Ft)": 130,
//       "Country": "Malaysia",
//       "Latitude": 101.09200286865234,
//       "Location": "Ipoh",
//       "Longitude": 4.567969799041748,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 3303,
//       "Airport Code [ICAO]": "WMKJ",
//       "Airport Code iATA,FAA": "JHB",
//       "Airport Name": "Senai International Airport",
//       "Altitude (Ft)": 135,
//       "Country": "Malaysia",
//       "Latitude": 103.669998,
//       "Location": "Johor Bahru",
//       "Longitude": 1.64131,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 3304,
//       "Airport Code [ICAO]": "WMKK",
//       "Airport Code iATA,FAA": "KUL",
//       "Airport Name": "Kuala Lumpur International Airport",
//       "Altitude (Ft)": 69,
//       "Country": "Malaysia",
//       "Latitude": 101.70999908447,
//       "Location": "Kuala Lumpur",
//       "Longitude": 2.745579957962,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 3305,
//       "Airport Code [ICAO]": "WMKL",
//       "Airport Code iATA,FAA": "LGK",
//       "Airport Name": "Langkawi International Airport",
//       "Altitude (Ft)": 29,
//       "Country": "Malaysia",
//       "Latitude": 99.72869873046875,
//       "Location": "Langkawi",
//       "Longitude": 6.329730033874512,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 3306,
//       "Airport Code [ICAO]": "WMKM",
//       "Airport Code iATA,FAA": "MKZ",
//       "Airport Name": "Malacca Airport",
//       "Altitude (Ft)": 35,
//       "Country": "Malaysia",
//       "Latitude": 102.251998901,
//       "Location": "Malacca",
//       "Longitude": 2.2633600235,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 3307,
//       "Airport Code [ICAO]": "WMKN",
//       "Airport Code iATA,FAA": "TGG",
//       "Airport Name": "Sultan Mahmud Airport",
//       "Altitude (Ft)": 21,
//       "Country": "Malaysia",
//       "Latitude": 103.10299682617188,
//       "Location": "Kuala Terengganu",
//       "Longitude": 5.3826398849487305,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 3308,
//       "Airport Code [ICAO]": "WMKP",
//       "Airport Code iATA,FAA": "PEN",
//       "Airport Name": "Penang International Airport",
//       "Altitude (Ft)": 11,
//       "Country": "Malaysia",
//       "Latitude": 100.2770004272461,
//       "Location": "Penang",
//       "Longitude": 5.297140121459961,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 3309,
//       "Airport Code [ICAO]": "WPDB",
//       "Airport Code iATA,FAA": "UAI",
//       "Airport Name": "Suai Airport",
//       "Altitude (Ft)": 96,
//       "Country": "East Timor",
//       "Latitude": 125.28700256347656,
//       "Location": "Suai",
//       "Longitude": -9.30331039428711,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dili",
//       "Type": "airport"
//     },
//     {
//       "ID": 3310,
//       "Airport Code [ICAO]": "WPDL",
//       "Airport Code iATA,FAA": "DIL",
//       "Airport Name": "Presidente Nicolau Lobato International Airport",
//       "Altitude (Ft)": 154,
//       "Country": "East Timor",
//       "Latitude": 125.526000977,
//       "Location": "Dili",
//       "Longitude": -8.54640007019,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dili",
//       "Type": "airport"
//     },
//     {
//       "ID": 3311,
//       "Airport Code [ICAO]": "WPEC",
//       "Airport Code iATA,FAA": "BCH",
//       "Airport Name": "Cakung Airport",
//       "Altitude (Ft)": 1771,
//       "Country": "East Timor",
//       "Latitude": 126.401000977,
//       "Location": "Baucau",
//       "Longitude": -8.489029884339999,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dili",
//       "Type": "airport"
//     },
//     {
//       "ID": 3312,
//       "Airport Code [ICAO]": "WSAG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sembawang Air Base",
//       "Altitude (Ft)": 86,
//       "Country": "Singapore",
//       "Latitude": 103.81300354003906,
//       "Location": "Sembawang",
//       "Longitude": 1.4252599477767944,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Singapore",
//       "Type": "airport"
//     },
//     {
//       "ID": 3313,
//       "Airport Code [ICAO]": "WSAP",
//       "Airport Code iATA,FAA": "QPG",
//       "Airport Name": "Paya Lebar Air Base",
//       "Altitude (Ft)": 65,
//       "Country": "Singapore",
//       "Latitude": 103.91000366210938,
//       "Location": "Paya Lebar",
//       "Longitude": 1.3604199886322021,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Singapore",
//       "Type": "airport"
//     },
//     {
//       "ID": 3314,
//       "Airport Code [ICAO]": "WSAT",
//       "Airport Code iATA,FAA": "TGA",
//       "Airport Name": "Tengah Air Base",
//       "Altitude (Ft)": 50,
//       "Country": "Singapore",
//       "Latitude": 103.708999634,
//       "Location": "Tengah",
//       "Longitude": 1.38725996017,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Singapore",
//       "Type": "airport"
//     },
//     {
//       "ID": 3315,
//       "Airport Code [ICAO]": "WSSL",
//       "Airport Code iATA,FAA": "XSP",
//       "Airport Name": "Seletar Airport",
//       "Altitude (Ft)": 36,
//       "Country": "Singapore",
//       "Latitude": 103.86799621582031,
//       "Location": "Singapore",
//       "Longitude": 1.416949987411499,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Singapore",
//       "Type": "airport"
//     },
//     {
//       "ID": 3316,
//       "Airport Code [ICAO]": "WSSS",
//       "Airport Code iATA,FAA": "SIN",
//       "Airport Name": "Singapore Changi Airport",
//       "Altitude (Ft)": 22,
//       "Country": "Singapore",
//       "Latitude": 103.994003,
//       "Location": "Singapore",
//       "Longitude": 1.35019,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Singapore",
//       "Type": "airport"
//     },
//     {
//       "ID": 3317,
//       "Airport Code [ICAO]": "YBAF",
//       "Airport Code iATA,FAA": "ACF",
//       "Airport Name": "Brisbane Archerfield Airport",
//       "Altitude (Ft)": 63,
//       "Country": "Australia",
//       "Latitude": 153.007995605,
//       "Location": "Brisbane",
//       "Longitude": -27.5702991486,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 3318,
//       "Airport Code [ICAO]": "YBAM",
//       "Airport Code iATA,FAA": "ABM",
//       "Airport Name": "Northern Peninsula Airport",
//       "Altitude (Ft)": 34,
//       "Country": "Australia",
//       "Latitude": 142.459,
//       "Location": "Amberley",
//       "Longitude": -10.9508,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 3319,
//       "Airport Code [ICAO]": "YBAS",
//       "Airport Code iATA,FAA": "ASP",
//       "Airport Name": "Alice Springs Airport",
//       "Altitude (Ft)": 1789,
//       "Country": "Australia",
//       "Latitude": 133.90199279785156,
//       "Location": "Alice Springs",
//       "Longitude": -23.806699752807617,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "N",
//       "Region": "Australia/Darwin",
//       "Type": "airport"
//     },
//     {
//       "ID": 3320,
//       "Airport Code [ICAO]": "YBBN",
//       "Airport Code iATA,FAA": "BNE",
//       "Airport Name": "Brisbane International Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Australia",
//       "Latitude": 153.11700439453125,
//       "Location": "Brisbane",
//       "Longitude": -27.384199142456055,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 3321,
//       "Airport Code [ICAO]": "YBCG",
//       "Airport Code iATA,FAA": "OOL",
//       "Airport Name": "Gold Coast Airport",
//       "Altitude (Ft)": 21,
//       "Country": "Australia",
//       "Latitude": 153.505004883,
//       "Location": "Coolangatta",
//       "Longitude": -28.1644001007,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 3322,
//       "Airport Code [ICAO]": "YBCS",
//       "Airport Code iATA,FAA": "CNS",
//       "Airport Name": "Cairns International Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Australia",
//       "Latitude": 145.755004883,
//       "Location": "Cairns",
//       "Longitude": -16.885799408,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 3323,
//       "Airport Code [ICAO]": "YBCV",
//       "Airport Code iATA,FAA": "CTL",
//       "Airport Name": "Charleville Airport",
//       "Altitude (Ft)": 1003,
//       "Country": "Australia",
//       "Latitude": 146.261993408,
//       "Location": "Charlieville",
//       "Longitude": -26.4132995605,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 3324,
//       "Airport Code [ICAO]": "YBMA",
//       "Airport Code iATA,FAA": "ISA",
//       "Airport Name": "Mount Isa Airport",
//       "Altitude (Ft)": 1121,
//       "Country": "Australia",
//       "Latitude": 139.488998413,
//       "Location": "Mount Isa",
//       "Longitude": -20.663900375399997,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 3325,
//       "Airport Code [ICAO]": "YBMC",
//       "Airport Code iATA,FAA": "MCY",
//       "Airport Name": "Sunshine Coast Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Australia",
//       "Latitude": 153.091003,
//       "Location": "Maroochydore",
//       "Longitude": -26.6033,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 3326,
//       "Airport Code [ICAO]": "YBMK",
//       "Airport Code iATA,FAA": "MKY",
//       "Airport Name": "Mackay Airport",
//       "Altitude (Ft)": 19,
//       "Country": "Australia",
//       "Latitude": 149.179992676,
//       "Location": "Mackay",
//       "Longitude": -21.171699523900003,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 3328,
//       "Airport Code [ICAO]": "YBPN",
//       "Airport Code iATA,FAA": "PPP",
//       "Airport Name": "Proserpine Whitsunday Coast Airport",
//       "Altitude (Ft)": 82,
//       "Country": "Australia",
//       "Latitude": 148.552001953,
//       "Location": "Prosserpine",
//       "Longitude": -20.4950008392,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 3329,
//       "Airport Code [ICAO]": "YBRK",
//       "Airport Code iATA,FAA": "ROK",
//       "Airport Name": "Rockhampton Airport",
//       "Altitude (Ft)": 34,
//       "Country": "Australia",
//       "Latitude": 150.475006104,
//       "Location": "Rockhampton",
//       "Longitude": -23.3819007874,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 3330,
//       "Airport Code [ICAO]": "YBTL",
//       "Airport Code iATA,FAA": "TSV",
//       "Airport Name": "Townsville Airport",
//       "Altitude (Ft)": 18,
//       "Country": "Australia",
//       "Latitude": 146.76499938964844,
//       "Location": "Townsville",
//       "Longitude": -19.252500534057617,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 3331,
//       "Airport Code [ICAO]": "YBWP",
//       "Airport Code iATA,FAA": "WEI",
//       "Airport Name": "Weipa Airport",
//       "Altitude (Ft)": 63,
//       "Country": "Australia",
//       "Latitude": 141.925003052,
//       "Location": "Weipa",
//       "Longitude": -12.6786003113,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 3332,
//       "Airport Code [ICAO]": "YMAV",
//       "Airport Code iATA,FAA": "AVV",
//       "Airport Name": "Avalon Airport",
//       "Altitude (Ft)": 35,
//       "Country": "Australia",
//       "Latitude": 144.468994,
//       "Location": "Avalon",
//       "Longitude": -38.039398,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Hobart",
//       "Type": "airport"
//     },
//     {
//       "ID": 3333,
//       "Airport Code [ICAO]": "YMAY",
//       "Airport Code iATA,FAA": "ABX",
//       "Airport Name": "Albury Airport",
//       "Altitude (Ft)": 539,
//       "Country": "Australia",
//       "Latitude": 146.95799255371094,
//       "Location": "Albury",
//       "Longitude": -36.06779861450195,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 3334,
//       "Airport Code [ICAO]": "YMEN",
//       "Airport Code iATA,FAA": "MEB",
//       "Airport Name": "Melbourne Essendon Airport",
//       "Altitude (Ft)": 282,
//       "Country": "Australia",
//       "Latitude": 144.901993,
//       "Location": "Melbourne",
//       "Longitude": -37.7281,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Hobart",
//       "Type": "airport"
//     },
//     {
//       "ID": 3335,
//       "Airport Code [ICAO]": "YMES",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "RAAF Base East Sale",
//       "Altitude (Ft)": 23,
//       "Country": "Australia",
//       "Latitude": 147.149002075,
//       "Location": "East Sale",
//       "Longitude": -38.098899841299996,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Hobart",
//       "Type": "airport"
//     },
//     {
//       "ID": 3336,
//       "Airport Code [ICAO]": "YMHB",
//       "Airport Code iATA,FAA": "HBA",
//       "Airport Name": "Hobart International Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Australia",
//       "Latitude": 147.509994507,
//       "Location": "Hobart",
//       "Longitude": -42.836101532,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Melbourne",
//       "Type": "airport"
//     },
//     {
//       "ID": 3337,
//       "Airport Code [ICAO]": "YMLT",
//       "Airport Code iATA,FAA": "LST",
//       "Airport Name": "Launceston Airport",
//       "Altitude (Ft)": 562,
//       "Country": "Australia",
//       "Latitude": 147.214004517,
//       "Location": "Launceston",
//       "Longitude": -41.54529953,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Melbourne",
//       "Type": "airport"
//     },
//     {
//       "ID": 3338,
//       "Airport Code [ICAO]": "YMMB",
//       "Airport Code iATA,FAA": "MBW",
//       "Airport Name": "Melbourne Moorabbin Airport",
//       "Altitude (Ft)": 50,
//       "Country": "Australia",
//       "Latitude": 145.1020050048828,
//       "Location": "Melbourne",
//       "Longitude": -37.975799560546875,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Hobart",
//       "Type": "airport"
//     },
//     {
//       "ID": 3339,
//       "Airport Code [ICAO]": "YMML",
//       "Airport Code iATA,FAA": "MEL",
//       "Airport Name": "Melbourne International Airport",
//       "Altitude (Ft)": 434,
//       "Country": "Australia",
//       "Latitude": 144.843002,
//       "Location": "Melbourne",
//       "Longitude": -37.673302,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Hobart",
//       "Type": "airport"
//     },
//     {
//       "ID": 3340,
//       "Airport Code [ICAO]": "YMPC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "RAAF Williams, Point Cook Base",
//       "Altitude (Ft)": 14,
//       "Country": "Australia",
//       "Latitude": 144.753006,
//       "Location": "Point Cook",
//       "Longitude": -37.932201,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Hobart",
//       "Type": "airport"
//     },
//     {
//       "ID": 3341,
//       "Airport Code [ICAO]": "YPAD",
//       "Airport Code iATA,FAA": "ADL",
//       "Airport Name": "Adelaide International Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Australia",
//       "Latitude": 138.531006,
//       "Location": "Adelaide",
//       "Longitude": -34.945,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Adelaide",
//       "Type": "airport"
//     },
//     {
//       "ID": 3343,
//       "Airport Code [ICAO]": "YPED",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "RAAF Base Edinburgh",
//       "Altitude (Ft)": 67,
//       "Country": "Australia",
//       "Latitude": 138.62100219726562,
//       "Location": "Edinburgh",
//       "Longitude": -34.70249938964844,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Adelaide",
//       "Type": "airport"
//     },
//     {
//       "ID": 3344,
//       "Airport Code [ICAO]": "YPJT",
//       "Airport Code iATA,FAA": "JAD",
//       "Airport Name": "Perth Jandakot Airport",
//       "Altitude (Ft)": 99,
//       "Country": "Australia",
//       "Latitude": 115.88099670410156,
//       "Location": "Perth",
//       "Longitude": -32.09749984741211,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 3345,
//       "Airport Code [ICAO]": "YPKA",
//       "Airport Code iATA,FAA": "KTA",
//       "Airport Name": "Karratha Airport",
//       "Altitude (Ft)": 29,
//       "Country": "Australia",
//       "Latitude": 116.773002625,
//       "Location": "Karratha",
//       "Longitude": -20.712200164799995,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 3346,
//       "Airport Code [ICAO]": "YPKG",
//       "Airport Code iATA,FAA": "KGI",
//       "Airport Name": "Kalgoorlie Boulder Airport",
//       "Altitude (Ft)": 1203,
//       "Country": "Australia",
//       "Latitude": 121.461997986,
//       "Location": "Kalgoorlie",
//       "Longitude": -30.789400100699996,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 3347,
//       "Airport Code [ICAO]": "YPKU",
//       "Airport Code iATA,FAA": "KNX",
//       "Airport Name": "Kununurra Airport",
//       "Altitude (Ft)": 145,
//       "Country": "Australia",
//       "Latitude": 128.707992554,
//       "Location": "Kununurra",
//       "Longitude": -15.7781000137,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 3348,
//       "Airport Code [ICAO]": "YPLM",
//       "Airport Code iATA,FAA": "LEA",
//       "Airport Name": "Learmonth Airport",
//       "Altitude (Ft)": 19,
//       "Country": "Australia",
//       "Latitude": 114.088996887,
//       "Location": "Learmonth",
//       "Longitude": -22.235599517799997,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 3349,
//       "Airport Code [ICAO]": "YPPD",
//       "Airport Code iATA,FAA": "PHE",
//       "Airport Name": "Port Hedland International Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Australia",
//       "Latitude": 118.625999451,
//       "Location": "Port Hedland",
//       "Longitude": -20.3777999878,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 3350,
//       "Airport Code [ICAO]": "YPPF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Adelaide Parafield Airport",
//       "Altitude (Ft)": 57,
//       "Country": "Australia",
//       "Latitude": 138.632995605,
//       "Location": "Adelaide",
//       "Longitude": -34.793300628699996,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Adelaide",
//       "Type": "airport"
//     },
//     {
//       "ID": 3351,
//       "Airport Code [ICAO]": "YPPH",
//       "Airport Code iATA,FAA": "PER",
//       "Airport Name": "Perth International Airport",
//       "Altitude (Ft)": 67,
//       "Country": "Australia",
//       "Latitude": 115.96700286865234,
//       "Location": "Perth",
//       "Longitude": -31.94029998779297,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 3352,
//       "Airport Code [ICAO]": "YPWR",
//       "Airport Code iATA,FAA": "UMR",
//       "Airport Name": "Woomera Airfield",
//       "Altitude (Ft)": 548,
//       "Country": "Australia",
//       "Latitude": 136.81700134277344,
//       "Location": "Woomera",
//       "Longitude": -31.14419937133789,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Adelaide",
//       "Type": "airport"
//     },
//     {
//       "ID": 3353,
//       "Airport Code [ICAO]": "YPXM",
//       "Airport Code iATA,FAA": "XCH",
//       "Airport Name": "Christmas Island Airport",
//       "Altitude (Ft)": 916,
//       "Country": "Christmas Island",
//       "Latitude": 105.69000244140625,
//       "Location": "Christmas Island",
//       "Longitude": -10.450599670410156,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Indian/Christmas",
//       "Type": "airport"
//     },
//     {
//       "ID": 3354,
//       "Airport Code [ICAO]": "YSBK",
//       "Airport Code iATA,FAA": "BWU",
//       "Airport Name": "Sydney Bankstown Airport",
//       "Altitude (Ft)": 29,
//       "Country": "Australia",
//       "Latitude": 150.98800659179688,
//       "Location": "Sydney",
//       "Longitude": -33.924400329589844,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 3355,
//       "Airport Code [ICAO]": "YSCB",
//       "Airport Code iATA,FAA": "CBR",
//       "Airport Name": "Canberra International Airport",
//       "Altitude (Ft)": 1886,
//       "Country": "Australia",
//       "Latitude": 149.19500732421875,
//       "Location": "Canberra",
//       "Longitude": -35.30690002441406,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 3356,
//       "Airport Code [ICAO]": "YSCH",
//       "Airport Code iATA,FAA": "CFS",
//       "Airport Name": "Coffs Harbour Airport",
//       "Altitude (Ft)": 18,
//       "Country": "Australia",
//       "Latitude": 153.115997314,
//       "Location": "Coff's Harbour",
//       "Longitude": -30.3206005096,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 3357,
//       "Airport Code [ICAO]": "YSCN",
//       "Airport Code iATA,FAA": "CDU",
//       "Airport Name": "Camden Airport",
//       "Altitude (Ft)": 230,
//       "Country": "Australia",
//       "Latitude": 150.68699645996094,
//       "Location": "Camden",
//       "Longitude": -34.04029846191406,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 3358,
//       "Airport Code [ICAO]": "YSDU",
//       "Airport Code iATA,FAA": "DBO",
//       "Airport Name": "Dubbo City Regional Airport",
//       "Altitude (Ft)": 935,
//       "Country": "Australia",
//       "Latitude": 148.574996948,
//       "Location": "Dubbo",
//       "Longitude": -32.2167015076,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 3359,
//       "Airport Code [ICAO]": "YSNF",
//       "Airport Code iATA,FAA": "NLK",
//       "Airport Name": "Norfolk Island International Airport",
//       "Altitude (Ft)": 371,
//       "Country": "Norfolk Island",
//       "Latitude": 167.93899536132812,
//       "Location": "Norfolk Island",
//       "Longitude": -29.04159927368164,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Norfolk",
//       "Type": "airport"
//     },
//     {
//       "ID": 3360,
//       "Airport Code [ICAO]": "YSRI",
//       "Airport Code iATA,FAA": "XRH",
//       "Airport Name": "RAAF Base Richmond",
//       "Altitude (Ft)": 67,
//       "Country": "Australia",
//       "Latitude": 150.781006,
//       "Location": "Richmond",
//       "Longitude": -33.600601,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 3361,
//       "Airport Code [ICAO]": "YSSY",
//       "Airport Code iATA,FAA": "SYD",
//       "Airport Name": "Sydney Kingsford Smith International Airport",
//       "Altitude (Ft)": 21,
//       "Country": "Australia",
//       "Latitude": 151.177001953125,
//       "Location": "Sydney",
//       "Longitude": -33.94609832763672,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 3362,
//       "Airport Code [ICAO]": "YSTW",
//       "Airport Code iATA,FAA": "TMW",
//       "Airport Name": "Tamworth Airport",
//       "Altitude (Ft)": 1334,
//       "Country": "Australia",
//       "Latitude": 150.847000122,
//       "Location": "Tamworth",
//       "Longitude": -31.0839004517,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 3363,
//       "Airport Code [ICAO]": "YSWG",
//       "Airport Code iATA,FAA": "WGA",
//       "Airport Name": "Wagga Wagga City Airport",
//       "Altitude (Ft)": 724,
//       "Country": "Australia",
//       "Latitude": 147.466003418,
//       "Location": "Wagga Wagga",
//       "Longitude": -35.1652984619,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 3364,
//       "Airport Code [ICAO]": "ZBAA",
//       "Airport Code iATA,FAA": "PEK",
//       "Airport Name": "Beijing Capital International Airport",
//       "Altitude (Ft)": 116,
//       "Country": "China",
//       "Latitude": 116.58499908447266,
//       "Location": "Beijing",
//       "Longitude": 40.080101013183594,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3366,
//       "Airport Code [ICAO]": "ZBLA",
//       "Airport Code iATA,FAA": "HLD",
//       "Airport Name": "Dongshan Airport",
//       "Altitude (Ft)": 2169,
//       "Country": "China",
//       "Latitude": 119.824996948,
//       "Location": "Hailar",
//       "Longitude": 49.2050018311,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3368,
//       "Airport Code [ICAO]": "ZBTJ",
//       "Airport Code iATA,FAA": "TSN",
//       "Airport Name": "Tianjin Binhai International Airport",
//       "Altitude (Ft)": 10,
//       "Country": "China",
//       "Latitude": 117.346000671,
//       "Location": "Tianjin",
//       "Longitude": 39.124401092499994,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3369,
//       "Airport Code [ICAO]": "ZBYN",
//       "Airport Code iATA,FAA": "TYN",
//       "Airport Name": "Taiyuan Wusu Airport",
//       "Altitude (Ft)": 2575,
//       "Country": "China",
//       "Latitude": 112.62799835205078,
//       "Location": "Taiyuan",
//       "Longitude": 37.74689865112305,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3370,
//       "Airport Code [ICAO]": "ZGGG",
//       "Airport Code iATA,FAA": "CAN",
//       "Airport Name": "Guangzhou Baiyun International Airport",
//       "Altitude (Ft)": 50,
//       "Country": "China",
//       "Latitude": 113.29900360107422,
//       "Location": "Guangzhou",
//       "Longitude": 23.39240074157715,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3371,
//       "Airport Code [ICAO]": "ZGHA",
//       "Airport Code iATA,FAA": "CSX",
//       "Airport Name": "Changsha Huanghua International Airport",
//       "Altitude (Ft)": 217,
//       "Country": "China",
//       "Latitude": 113.220001221,
//       "Location": "Changcha",
//       "Longitude": 28.189199447599997,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3372,
//       "Airport Code [ICAO]": "ZGKL",
//       "Airport Code iATA,FAA": "KWL",
//       "Airport Name": "Guilin Liangjiang International Airport",
//       "Altitude (Ft)": 570,
//       "Country": "China",
//       "Latitude": 110.03900146484375,
//       "Location": "Guilin",
//       "Longitude": 25.21809959411621,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3373,
//       "Airport Code [ICAO]": "ZGNN",
//       "Airport Code iATA,FAA": "NNG",
//       "Airport Name": "Nanning Wuxu Airport",
//       "Altitude (Ft)": 421,
//       "Country": "China",
//       "Latitude": 108.1719970703125,
//       "Location": "Nanning",
//       "Longitude": 22.608299255371094,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3374,
//       "Airport Code [ICAO]": "ZGSZ",
//       "Airport Code iATA,FAA": "SZX",
//       "Airport Name": "Shenzhen Bao'an International Airport",
//       "Altitude (Ft)": 13,
//       "Country": "China",
//       "Latitude": 113.81099700927734,
//       "Location": "Shenzhen",
//       "Longitude": 22.639299392700195,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3375,
//       "Airport Code [ICAO]": "ZHCC",
//       "Airport Code iATA,FAA": "CGO",
//       "Airport Name": "Zhengzhou Xinzheng International Airport",
//       "Altitude (Ft)": 495,
//       "Country": "China",
//       "Latitude": 113.841003418,
//       "Location": "Zhengzhou",
//       "Longitude": 34.519699096699995,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3376,
//       "Airport Code [ICAO]": "ZHHH",
//       "Airport Code iATA,FAA": "WUH",
//       "Airport Name": "Wuhan Tianhe International Airport",
//       "Altitude (Ft)": 113,
//       "Country": "China",
//       "Latitude": 114.208,
//       "Location": "Wuhan",
//       "Longitude": 30.7838,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3377,
//       "Airport Code [ICAO]": "ZKPY",
//       "Airport Code iATA,FAA": "FNJ",
//       "Airport Name": "Pyongyang Sunan International Airport",
//       "Altitude (Ft)": 117,
//       "Country": "North Korea",
//       "Latitude": 125.669998,
//       "Location": "Pyongyang",
//       "Longitude": 39.224098,
//       "GMT Offset": "8.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Pyongyang",
//       "Type": "airport"
//     },
//     {
//       "ID": 3378,
//       "Airport Code [ICAO]": "ZLLL",
//       "Airport Code iATA,FAA": "LHW",
//       "Airport Name": "Lanzhou Zhongchuan Airport",
//       "Altitude (Ft)": 6388,
//       "Country": "China",
//       "Latitude": 103.620002747,
//       "Location": "Lanzhou",
//       "Longitude": 36.5152015686,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3379,
//       "Airport Code [ICAO]": "ZLXY",
//       "Airport Code iATA,FAA": "XIY",
//       "Airport Name": "Xi'an Xianyang International Airport",
//       "Altitude (Ft)": 1572,
//       "Country": "China",
//       "Latitude": 108.751999,
//       "Location": "Xi'an",
//       "Longitude": 34.447102,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3380,
//       "Airport Code [ICAO]": "ZMUB",
//       "Airport Code iATA,FAA": "ULN",
//       "Airport Name": "Chinggis Khaan International Airport",
//       "Altitude (Ft)": 4364,
//       "Country": "Mongolia",
//       "Latitude": 106.766998,
//       "Location": "Ulan Bator",
//       "Longitude": 47.843102,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Ulaanbaatar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3381,
//       "Airport Code [ICAO]": "ZPJH",
//       "Airport Code iATA,FAA": "JHG",
//       "Airport Name": "Xishuangbanna Gasa Airport",
//       "Altitude (Ft)": 1815,
//       "Country": "China",
//       "Latitude": 100.76000213623047,
//       "Location": "Jinghonggasa",
//       "Longitude": 21.973899841308594,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3382,
//       "Airport Code [ICAO]": "ZPPP",
//       "Airport Code iATA,FAA": "KMG",
//       "Airport Name": "Kunming Changshui International Airport",
//       "Altitude (Ft)": 6903,
//       "Country": "China",
//       "Latitude": 102.9291667,
//       "Location": "Kunming",
//       "Longitude": 25.1019444,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3383,
//       "Airport Code [ICAO]": "ZSAM",
//       "Airport Code iATA,FAA": "XMN",
//       "Airport Name": "Xiamen Gaoqi International Airport",
//       "Altitude (Ft)": 59,
//       "Country": "China",
//       "Latitude": 118.12799835205078,
//       "Location": "Xiamen",
//       "Longitude": 24.54400062561035,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3384,
//       "Airport Code [ICAO]": "ZSCN",
//       "Airport Code iATA,FAA": "KHN",
//       "Airport Name": "Nanchang Changbei International Airport",
//       "Altitude (Ft)": 143,
//       "Country": "China",
//       "Latitude": 115.9000015258789,
//       "Location": "Nanchang",
//       "Longitude": 28.864999771118164,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3385,
//       "Airport Code [ICAO]": "ZSFZ",
//       "Airport Code iATA,FAA": "FOC",
//       "Airport Name": "Fuzhou Changle International Airport",
//       "Altitude (Ft)": 46,
//       "Country": "China",
//       "Latitude": 119.66300201416016,
//       "Location": "Fuzhou",
//       "Longitude": 25.935100555419922,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3386,
//       "Airport Code [ICAO]": "ZSHC",
//       "Airport Code iATA,FAA": "HGH",
//       "Airport Name": "Hangzhou Xiaoshan International Airport",
//       "Altitude (Ft)": 23,
//       "Country": "China",
//       "Latitude": 120.43399810791016,
//       "Location": "Hangzhou",
//       "Longitude": 30.22949981689453,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3387,
//       "Airport Code [ICAO]": "ZSNB",
//       "Airport Code iATA,FAA": "NGB",
//       "Airport Name": "Ningbo Lishe International Airport",
//       "Altitude (Ft)": 13,
//       "Country": "China",
//       "Latitude": 121.46199798583984,
//       "Location": "Ninbo",
//       "Longitude": 29.82670021057129,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3388,
//       "Airport Code [ICAO]": "ZSNJ",
//       "Airport Code iATA,FAA": "NKG",
//       "Airport Name": "Nanjing Lukou Airport",
//       "Altitude (Ft)": 49,
//       "Country": "China",
//       "Latitude": 118.86199951171875,
//       "Location": "Nanjing",
//       "Longitude": 31.742000579833984,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3389,
//       "Airport Code [ICAO]": "ZSOF",
//       "Airport Code iATA,FAA": "HFE",
//       "Airport Name": "Hefei Luogang International Airport",
//       "Altitude (Ft)": 108,
//       "Country": "China",
//       "Latitude": 117.2979965209961,
//       "Location": "Hefei",
//       "Longitude": 31.780000686645508,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3390,
//       "Airport Code [ICAO]": "ZSQD",
//       "Airport Code iATA,FAA": "TAO",
//       "Airport Name": "Liuting Airport",
//       "Altitude (Ft)": 33,
//       "Country": "China",
//       "Latitude": 120.374000549,
//       "Location": "Qingdao",
//       "Longitude": 36.2661018372,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3391,
//       "Airport Code [ICAO]": "ZSSS",
//       "Airport Code iATA,FAA": "SHA",
//       "Airport Name": "Shanghai Hongqiao International Airport",
//       "Altitude (Ft)": 10,
//       "Country": "China",
//       "Latitude": 121.33599853515625,
//       "Location": "Shanghai",
//       "Longitude": 31.197900772094727,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3392,
//       "Airport Code [ICAO]": "ZSYT",
//       "Airport Code iATA,FAA": "YNT",
//       "Airport Name": "Yantai Laishan Airport",
//       "Altitude (Ft)": 59,
//       "Country": "China",
//       "Latitude": 121.37200164794922,
//       "Location": "Yantai",
//       "Longitude": 37.40169906616211,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3393,
//       "Airport Code [ICAO]": "ZUCK",
//       "Airport Code iATA,FAA": "CKG",
//       "Airport Name": "Chongqing Jiangbei International Airport",
//       "Altitude (Ft)": 1365,
//       "Country": "China",
//       "Latitude": 106.64199829101562,
//       "Location": "Chongqing",
//       "Longitude": 29.719200134277344,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3394,
//       "Airport Code [ICAO]": "ZUGY",
//       "Airport Code iATA,FAA": "KWE",
//       "Airport Name": "Longdongbao Airport",
//       "Altitude (Ft)": 3736,
//       "Country": "China",
//       "Latitude": 106.8010025024414,
//       "Location": "Guiyang",
//       "Longitude": 26.53849983215332,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3395,
//       "Airport Code [ICAO]": "ZUUU",
//       "Airport Code iATA,FAA": "CTU",
//       "Airport Name": "Chengdu Shuangliu International Airport",
//       "Altitude (Ft)": 1625,
//       "Country": "China",
//       "Latitude": 103.9469985961914,
//       "Location": "Chengdu",
//       "Longitude": 30.578500747680664,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3396,
//       "Airport Code [ICAO]": "ZUXC",
//       "Airport Code iATA,FAA": "XIC",
//       "Airport Name": "Xichang Qingshan Airport",
//       "Altitude (Ft)": 5112,
//       "Country": "China",
//       "Latitude": 102.18399810791016,
//       "Location": "Xichang",
//       "Longitude": 27.989099502563477,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3397,
//       "Airport Code [ICAO]": "ZWSH",
//       "Airport Code iATA,FAA": "KHG",
//       "Airport Name": "Kashgar Airport",
//       "Altitude (Ft)": 4529,
//       "Country": "China",
//       "Latitude": 76.0199966431,
//       "Location": "Kashi",
//       "Longitude": 39.5429000854,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3398,
//       "Airport Code [ICAO]": "ZWTN",
//       "Airport Code iATA,FAA": "HTN",
//       "Airport Name": "Hotan Airport",
//       "Altitude (Ft)": 4672,
//       "Country": "China",
//       "Latitude": 79.86489868164062,
//       "Location": "Hotan",
//       "Longitude": 37.03850173950195,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3399,
//       "Airport Code [ICAO]": "ZWWW",
//       "Airport Code iATA,FAA": "URC",
//       "Airport Name": "Ürümqi Diwopu International Airport",
//       "Altitude (Ft)": 2125,
//       "Country": "China",
//       "Latitude": 87.47419738769531,
//       "Location": "Urumqi",
//       "Longitude": 43.907100677490234,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3400,
//       "Airport Code [ICAO]": "ZYHB",
//       "Airport Code iATA,FAA": "HRB",
//       "Airport Name": "Taiping Airport",
//       "Altitude (Ft)": 457,
//       "Country": "China",
//       "Latitude": 126.25,
//       "Location": "Harbin",
//       "Longitude": 45.6234016418457,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3402,
//       "Airport Code [ICAO]": "ZYMD",
//       "Airport Code iATA,FAA": "MDG",
//       "Airport Name": "Mudanjiang Hailang International Airport",
//       "Altitude (Ft)": 883,
//       "Country": "China",
//       "Latitude": 129.569000244,
//       "Location": "Mudanjiang",
//       "Longitude": 44.5241012573,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3404,
//       "Airport Code [ICAO]": "ZYTL",
//       "Airport Code iATA,FAA": "DLC",
//       "Airport Name": "Zhoushuizi Airport",
//       "Altitude (Ft)": 107,
//       "Country": "China",
//       "Latitude": 121.53900146484375,
//       "Location": "Dalian",
//       "Longitude": 38.9656982421875,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3406,
//       "Airport Code [ICAO]": "ZSPD",
//       "Airport Code iATA,FAA": "PVG",
//       "Airport Name": "Shanghai Pudong International Airport",
//       "Altitude (Ft)": 13,
//       "Country": "China",
//       "Latitude": 121.80500030517578,
//       "Location": "Shanghai",
//       "Longitude": 31.143400192260742,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 3407,
//       "Airport Code [ICAO]": "WMBT",
//       "Airport Code iATA,FAA": "TOD",
//       "Airport Name": "Pulau Tioman Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Malaysia",
//       "Latitude": 104.16000366210938,
//       "Location": "Tioman",
//       "Longitude": 2.8181800842285156,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 3408,
//       "Airport Code [ICAO]": "WMSA",
//       "Airport Code iATA,FAA": "SZB",
//       "Airport Name": "Sultan Abdul Aziz Shah International Airport",
//       "Altitude (Ft)": 90,
//       "Country": "Malaysia",
//       "Latitude": 101.54900360107422,
//       "Location": "Kuala Lumpur",
//       "Longitude": 3.130579948425293,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 3409,
//       "Airport Code [ICAO]": "RJNW",
//       "Airport Code iATA,FAA": "NTQ",
//       "Airport Name": "Noto Airport",
//       "Altitude (Ft)": 718,
//       "Country": "Japan",
//       "Latitude": 136.962005615,
//       "Location": "Wajima",
//       "Longitude": 37.2930984497,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 3410,
//       "Airport Code [ICAO]": "HEBA",
//       "Airport Code iATA,FAA": "HBE",
//       "Airport Name": "Borg El Arab International Airport",
//       "Altitude (Ft)": 177,
//       "Country": "Egypt",
//       "Latitude": 29.696399688720703,
//       "Location": "Alexandria",
//       "Longitude": 30.917699813842773,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Cairo",
//       "Type": "airport"
//     },
//     {
//       "ID": 3411,
//       "Airport Code [ICAO]": "PABA",
//       "Airport Code iATA,FAA": "BTI",
//       "Airport Name": "Barter Island LRRS Airport",
//       "Altitude (Ft)": 2,
//       "Country": "United States",
//       "Latitude": -143.582000732,
//       "Location": "Barter Island",
//       "Longitude": 70.1340026855,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3412,
//       "Airport Code [ICAO]": "PAWT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Wainwright Air Station",
//       "Altitude (Ft)": 35,
//       "Country": "United States",
//       "Latitude": -159.8600006,
//       "Location": "Fort Wainwright",
//       "Longitude": 70.61340332,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3413,
//       "Airport Code [ICAO]": "PALU",
//       "Airport Code iATA,FAA": "LUR",
//       "Airport Name": "Cape Lisburne LRRS Airport",
//       "Altitude (Ft)": 16,
//       "Country": "United States",
//       "Latitude": -166.1100006,
//       "Location": "Cape Lisburne",
//       "Longitude": 68.87509918,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3414,
//       "Airport Code [ICAO]": "PPIZ",
//       "Airport Code iATA,FAA": "PIZ",
//       "Airport Name": "Point Lay LRRS Airport",
//       "Altitude (Ft)": 22,
//       "Country": "United States",
//       "Latitude": -163.0050049,
//       "Location": "Point Lay",
//       "Longitude": 69.73290253,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3415,
//       "Airport Code [ICAO]": "PHTO",
//       "Airport Code iATA,FAA": "ITO",
//       "Airport Name": "Hilo International Airport",
//       "Altitude (Ft)": 38,
//       "Country": "United States",
//       "Latitude": -155.04800415039062,
//       "Location": "Hilo",
//       "Longitude": 19.721399307250977,
//       "GMT Offset": "-10",
//       "Daylight Savings": "N",
//       "Region": "Pacific/Honolulu",
//       "Type": "airport"
//     },
//     {
//       "ID": 3416,
//       "Airport Code [ICAO]": "KORL",
//       "Airport Code iATA,FAA": "ORL",
//       "Airport Name": "Orlando Executive Airport",
//       "Altitude (Ft)": 113,
//       "Country": "United States",
//       "Latitude": -81.332901,
//       "Location": "Orlando",
//       "Longitude": 28.5455,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3417,
//       "Airport Code [ICAO]": "PABT",
//       "Airport Code iATA,FAA": "BTT",
//       "Airport Name": "Bettles Airport",
//       "Altitude (Ft)": 647,
//       "Country": "United States",
//       "Latitude": -151.529007,
//       "Location": "Bettles",
//       "Longitude": 66.91390228,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3418,
//       "Airport Code [ICAO]": "PACL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Clear Airport",
//       "Altitude (Ft)": 552,
//       "Country": "United States",
//       "Latitude": -149.119995,
//       "Location": "Clear Mews",
//       "Longitude": 64.301201,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3419,
//       "Airport Code [ICAO]": "PAIM",
//       "Airport Code iATA,FAA": "UTO",
//       "Airport Name": "Indian Mountain LRRS Airport",
//       "Altitude (Ft)": 1273,
//       "Country": "United States",
//       "Latitude": -153.7039948,
//       "Location": "Indian Mountains",
//       "Longitude": 65.99279785,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3420,
//       "Airport Code [ICAO]": "PFYU",
//       "Airport Code iATA,FAA": "FYU",
//       "Airport Name": "Fort Yukon Airport",
//       "Altitude (Ft)": 433,
//       "Country": "United States",
//       "Latitude": -145.25,
//       "Location": "Fort Yukon",
//       "Longitude": 66.57150268554688,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3421,
//       "Airport Code [ICAO]": "PASV",
//       "Airport Code iATA,FAA": "SVW",
//       "Airport Name": "Sparrevohn LRRS Airport",
//       "Altitude (Ft)": 1585,
//       "Country": "United States",
//       "Latitude": -155.5740051,
//       "Location": "Sparrevohn",
//       "Longitude": 61.09740067,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3422,
//       "Airport Code [ICAO]": "PAFR",
//       "Airport Code iATA,FAA": "FRN",
//       "Airport Name": "Bryant Army Heliport",
//       "Altitude (Ft)": 378,
//       "Country": "United States",
//       "Latitude": -149.6529999,
//       "Location": "Fort Richardson",
//       "Longitude": 61.26639938,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3423,
//       "Airport Code [ICAO]": "PATL",
//       "Airport Code iATA,FAA": "TLJ",
//       "Airport Name": "Tatalina LRRS Airport",
//       "Altitude (Ft)": 964,
//       "Country": "United States",
//       "Latitude": -155.977005005,
//       "Location": "Tatalina",
//       "Longitude": 62.894401550299996,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3424,
//       "Airport Code [ICAO]": "PACZ",
//       "Airport Code iATA,FAA": "CZF",
//       "Airport Name": "Cape Romanzof LRRS Airport",
//       "Altitude (Ft)": 464,
//       "Country": "United States",
//       "Latitude": -166.0390015,
//       "Location": "Cape Romanzof",
//       "Longitude": 61.78030014,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3425,
//       "Airport Code [ICAO]": "KBED",
//       "Airport Code iATA,FAA": "BED",
//       "Airport Name": "Laurence G Hanscom Field",
//       "Altitude (Ft)": 133,
//       "Country": "United States",
//       "Latitude": -71.28900146,
//       "Location": "Bedford",
//       "Longitude": 42.47000122,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3426,
//       "Airport Code [ICAO]": "PASN",
//       "Airport Code iATA,FAA": "SNP",
//       "Airport Name": "St Paul Island Airport",
//       "Altitude (Ft)": 63,
//       "Country": "United States",
//       "Latitude": -170.22000122070312,
//       "Location": "St. Paul Island",
//       "Longitude": 57.167301177978516,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3427,
//       "Airport Code [ICAO]": "PAEH",
//       "Airport Code iATA,FAA": "EHM",
//       "Airport Name": "Cape Newenham LRRS Airport",
//       "Altitude (Ft)": 541,
//       "Country": "United States",
//       "Latitude": -162.06300354,
//       "Location": "Cape Newenham",
//       "Longitude": 58.646400451699996,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3428,
//       "Airport Code [ICAO]": "PAPB",
//       "Airport Code iATA,FAA": "STG",
//       "Airport Name": "St George Airport",
//       "Altitude (Ft)": 125,
//       "Country": "United States",
//       "Latitude": -169.662002563,
//       "Location": "Point Barrow",
//       "Longitude": 56.578300476100004,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3429,
//       "Airport Code [ICAO]": "PAIL",
//       "Airport Code iATA,FAA": "ILI",
//       "Airport Name": "Iliamna Airport",
//       "Altitude (Ft)": 192,
//       "Country": "United States",
//       "Latitude": -154.9109955,
//       "Location": "Iliamna",
//       "Longitude": 59.75439835,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3430,
//       "Airport Code [ICAO]": "PAPM",
//       "Airport Code iATA,FAA": "PTU",
//       "Airport Name": "Platinum Airport",
//       "Altitude (Ft)": 15,
//       "Country": "United States",
//       "Latitude": -161.82000732421875,
//       "Location": "Port Moller",
//       "Longitude": 59.01139831542969,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3431,
//       "Airport Code [ICAO]": "PABM",
//       "Airport Code iATA,FAA": "BMX",
//       "Airport Name": "Big Mountain Airport",
//       "Altitude (Ft)": 663,
//       "Country": "United States",
//       "Latitude": -155.259002686,
//       "Location": "Big Mountain",
//       "Longitude": 59.3611984253,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3432,
//       "Airport Code [ICAO]": "KOSC",
//       "Airport Code iATA,FAA": "OSC",
//       "Airport Name": "Oscoda Wurtsmith Airport",
//       "Altitude (Ft)": 633,
//       "Country": "United States",
//       "Latitude": -83.394096,
//       "Location": "Oscoda",
//       "Longitude": 44.451599,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3433,
//       "Airport Code [ICAO]": "KOAR",
//       "Airport Code iATA,FAA": "OAR",
//       "Airport Name": "Marina Municipal Airport",
//       "Altitude (Ft)": 137,
//       "Country": "United States",
//       "Latitude": -121.762001,
//       "Location": "Fort Ord",
//       "Longitude": 36.68190002,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3434,
//       "Airport Code [ICAO]": "KMHR",
//       "Airport Code iATA,FAA": "MHR",
//       "Airport Name": "Sacramento Mather Airport",
//       "Altitude (Ft)": 98,
//       "Country": "United States",
//       "Latitude": -121.2979965,
//       "Location": "Sacramento",
//       "Longitude": 38.55390167,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3435,
//       "Airport Code [ICAO]": "KBYS",
//       "Airport Code iATA,FAA": "BYS",
//       "Airport Name": "Bicycle Lake Army Air Field",
//       "Altitude (Ft)": 2350,
//       "Country": "United States",
//       "Latitude": -116.629997253,
//       "Location": "Fort Irwin",
//       "Longitude": 35.2804985046,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3436,
//       "Airport Code [ICAO]": "KNXP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Twentynine Palms (Self) Airport",
//       "Altitude (Ft)": 2051,
//       "Country": "United States",
//       "Latitude": -116.1620026,
//       "Location": "Twenty Nine Palms",
//       "Longitude": 34.2961998,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3437,
//       "Airport Code [ICAO]": "KFSM",
//       "Airport Code iATA,FAA": "FSM",
//       "Airport Name": "Fort Smith Regional Airport",
//       "Altitude (Ft)": 469,
//       "Country": "United States",
//       "Latitude": -94.36740112304688,
//       "Location": "Fort Smith",
//       "Longitude": 35.33660125732422,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3438,
//       "Airport Code [ICAO]": "PAMR",
//       "Airport Code iATA,FAA": "MRI",
//       "Airport Name": "Merrill Field",
//       "Altitude (Ft)": 137,
//       "Country": "United States",
//       "Latitude": -149.843994140625,
//       "Location": "Anchorage",
//       "Longitude": 61.2135009765625,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3439,
//       "Airport Code [ICAO]": "KGNT",
//       "Airport Code iATA,FAA": "GNT",
//       "Airport Name": "Grants-Milan Municipal Airport",
//       "Altitude (Ft)": 6537,
//       "Country": "United States",
//       "Latitude": -107.902000427,
//       "Location": "Grants",
//       "Longitude": 35.167301178,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3440,
//       "Airport Code [ICAO]": "KPNC",
//       "Airport Code iATA,FAA": "PNC",
//       "Airport Name": "Ponca City Regional Airport",
//       "Altitude (Ft)": 1008,
//       "Country": "United States",
//       "Latitude": -97.09980011,
//       "Location": "Ponca City",
//       "Longitude": 36.73199844,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3441,
//       "Airport Code [ICAO]": "KSVN",
//       "Airport Code iATA,FAA": "SVN",
//       "Airport Name": "Hunter Army Air Field",
//       "Altitude (Ft)": 41,
//       "Country": "United States",
//       "Latitude": -81.14569855,
//       "Location": "Hunter Aaf",
//       "Longitude": 32.00999832,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3442,
//       "Airport Code [ICAO]": "KGFK",
//       "Airport Code iATA,FAA": "GFK",
//       "Airport Name": "Grand Forks International Airport",
//       "Altitude (Ft)": 845,
//       "Country": "United States",
//       "Latitude": -97.176102,
//       "Location": "Grand Forks",
//       "Longitude": 47.949299,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3443,
//       "Airport Code [ICAO]": "KPBF",
//       "Airport Code iATA,FAA": "PBF",
//       "Airport Name": "Pine Bluff Regional Airport, Grider Field",
//       "Altitude (Ft)": 206,
//       "Country": "United States",
//       "Latitude": -91.9356,
//       "Location": "Pine Bluff",
//       "Longitude": 34.1731,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3444,
//       "Airport Code [ICAO]": "KNSE",
//       "Airport Code iATA,FAA": "NSE",
//       "Airport Name": "Whiting Field Naval Air Station - North",
//       "Altitude (Ft)": 199,
//       "Country": "United States",
//       "Latitude": -87.02189636,
//       "Location": "Milton",
//       "Longitude": 30.7241993,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3445,
//       "Airport Code [ICAO]": "PHHN",
//       "Airport Code iATA,FAA": "HNM",
//       "Airport Name": "Hana Airport",
//       "Altitude (Ft)": 78,
//       "Country": "United States",
//       "Latitude": -156.01400756835938,
//       "Location": "Hana",
//       "Longitude": 20.79560089111328,
//       "GMT Offset": "-10",
//       "Daylight Savings": "A",
//       "Region": "Pacific/Honolulu",
//       "Type": "airport"
//     },
//     {
//       "ID": 3446,
//       "Airport Code [ICAO]": "KPRC",
//       "Airport Code iATA,FAA": "PRC",
//       "Airport Name": "Ernest A. Love Field",
//       "Altitude (Ft)": 5045,
//       "Country": "United States",
//       "Latitude": -112.4199982,
//       "Location": "Prescott",
//       "Longitude": 34.65449905,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 3447,
//       "Airport Code [ICAO]": "KTTN",
//       "Airport Code iATA,FAA": "TTN",
//       "Airport Name": "Trenton Mercer Airport",
//       "Altitude (Ft)": 213,
//       "Country": "United States",
//       "Latitude": -74.8134994506836,
//       "Location": "Trenton",
//       "Longitude": 40.27669906616211,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3448,
//       "Airport Code [ICAO]": "KBOS",
//       "Airport Code iATA,FAA": "BOS",
//       "Airport Name": "General Edward Lawrence Logan International Airport",
//       "Altitude (Ft)": 20,
//       "Country": "United States",
//       "Latitude": -71.00520325,
//       "Location": "Boston",
//       "Longitude": 42.36429977,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3449,
//       "Airport Code [ICAO]": "KSUU",
//       "Airport Code iATA,FAA": "SUU",
//       "Airport Name": "Travis Air Force Base",
//       "Altitude (Ft)": 62,
//       "Country": "United States",
//       "Latitude": -121.92700195312,
//       "Location": "Fairfield",
//       "Longitude": 38.262699127197,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3450,
//       "Airport Code [ICAO]": "KRME",
//       "Airport Code iATA,FAA": "RME",
//       "Airport Name": "Griffiss International Airport",
//       "Altitude (Ft)": 504,
//       "Country": "United States",
//       "Latitude": -75.40699768,
//       "Location": "Rome",
//       "Longitude": 43.23379898,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3451,
//       "Airport Code [ICAO]": "KENV",
//       "Airport Code iATA,FAA": "ENV",
//       "Airport Name": "Wendover Airport",
//       "Altitude (Ft)": 4237,
//       "Country": "United States",
//       "Latitude": -114.03099823,
//       "Location": "Wendover",
//       "Longitude": 40.7187004089,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3452,
//       "Airport Code [ICAO]": "KBFM",
//       "Airport Code iATA,FAA": "BFM",
//       "Airport Name": "Mobile Downtown Airport",
//       "Altitude (Ft)": 26,
//       "Country": "United States",
//       "Latitude": -88.06809997559999,
//       "Location": "Mobile",
//       "Longitude": 30.626800537100003,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3453,
//       "Airport Code [ICAO]": "KOAK",
//       "Airport Code iATA,FAA": "OAK",
//       "Airport Name": "Metropolitan Oakland International Airport",
//       "Altitude (Ft)": 9,
//       "Country": "United States",
//       "Latitude": -122.221001,
//       "Location": "Oakland",
//       "Longitude": 37.721298,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3454,
//       "Airport Code [ICAO]": "KOMA",
//       "Airport Code iATA,FAA": "OMA",
//       "Airport Name": "Eppley Airfield",
//       "Altitude (Ft)": 984,
//       "Country": "United States",
//       "Latitude": -95.894096,
//       "Location": "Omaha",
//       "Longitude": 41.3032,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3455,
//       "Airport Code [ICAO]": "KNOW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Port Angeles Cgas Airport",
//       "Altitude (Ft)": 13,
//       "Country": "United States",
//       "Latitude": -123.41400146484375,
//       "Location": "Port Angeles",
//       "Longitude": 48.14149856567383,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3456,
//       "Airport Code [ICAO]": "PHOG",
//       "Airport Code iATA,FAA": "OGG",
//       "Airport Name": "Kahului Airport",
//       "Altitude (Ft)": 54,
//       "Country": "United States",
//       "Latitude": -156.429993,
//       "Location": "Kahului",
//       "Longitude": 20.8986,
//       "GMT Offset": "-10",
//       "Daylight Savings": "N",
//       "Region": "Pacific/Honolulu",
//       "Type": "airport"
//     },
//     {
//       "ID": 3457,
//       "Airport Code [ICAO]": "KICT",
//       "Airport Code iATA,FAA": "ICT",
//       "Airport Name": "Wichita Eisenhower National Airport",
//       "Altitude (Ft)": 1333,
//       "Country": "United States",
//       "Latitude": -97.433098,
//       "Location": "Wichita",
//       "Longitude": 37.649899,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3458,
//       "Airport Code [ICAO]": "KMCI",
//       "Airport Code iATA,FAA": "MCI",
//       "Airport Name": "Kansas City International Airport",
//       "Altitude (Ft)": 1026,
//       "Country": "United States",
//       "Latitude": -94.713898,
//       "Location": "Kansas City",
//       "Longitude": 39.2976,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3459,
//       "Airport Code [ICAO]": "KMSN",
//       "Airport Code iATA,FAA": "MSN",
//       "Airport Name": "Dane County Regional Truax Field",
//       "Altitude (Ft)": 887,
//       "Country": "United States",
//       "Latitude": -89.3375015258789,
//       "Location": "Madison",
//       "Longitude": 43.13990020751953,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3460,
//       "Airport Code [ICAO]": "PADL",
//       "Airport Code iATA,FAA": "DLG",
//       "Airport Name": "Dillingham Airport",
//       "Altitude (Ft)": 81,
//       "Country": "United States",
//       "Latitude": -158.5050049,
//       "Location": "Dillingham",
//       "Longitude": 59.04470062,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3461,
//       "Airport Code [ICAO]": "KHRO",
//       "Airport Code iATA,FAA": "HRO",
//       "Airport Name": "Boone County Airport",
//       "Altitude (Ft)": 1365,
//       "Country": "United States",
//       "Latitude": -93.15470123291016,
//       "Location": "Harrison",
//       "Longitude": 36.26150131225586,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3462,
//       "Airport Code [ICAO]": "KPHX",
//       "Airport Code iATA,FAA": "PHX",
//       "Airport Name": "Phoenix Sky Harbor International Airport",
//       "Altitude (Ft)": 1135,
//       "Country": "United States",
//       "Latitude": -112.01200103759766,
//       "Location": "Phoenix",
//       "Longitude": 33.43429946899414,
//       "GMT Offset": "-7",
//       "Daylight Savings": "N",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 3463,
//       "Airport Code [ICAO]": "KBGR",
//       "Airport Code iATA,FAA": "BGR",
//       "Airport Name": "Bangor International Airport",
//       "Altitude (Ft)": 192,
//       "Country": "United States",
//       "Latitude": -68.8281021118164,
//       "Location": "Bangor",
//       "Longitude": 44.80739974975586,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3464,
//       "Airport Code [ICAO]": "KFXE",
//       "Airport Code iATA,FAA": "FXE",
//       "Airport Name": "Fort Lauderdale Executive Airport",
//       "Altitude (Ft)": 13,
//       "Country": "United States",
//       "Latitude": -80.1707000732,
//       "Location": "Fort Lauderdale",
//       "Longitude": 26.1972999573,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3465,
//       "Airport Code [ICAO]": "KGGG",
//       "Airport Code iATA,FAA": "GGG",
//       "Airport Name": "East Texas Regional Airport",
//       "Altitude (Ft)": 365,
//       "Country": "United States",
//       "Latitude": -94.71150207519531,
//       "Location": "Longview",
//       "Longitude": 32.38399887084961,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3466,
//       "Airport Code [ICAO]": "KAND",
//       "Airport Code iATA,FAA": "AND",
//       "Airport Name": "Anderson Regional Airport",
//       "Altitude (Ft)": 782,
//       "Country": "United States",
//       "Latitude": -82.70939636230001,
//       "Location": "Andersen",
//       "Longitude": 34.4945983887,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3467,
//       "Airport Code [ICAO]": "KGEG",
//       "Airport Code iATA,FAA": "GEG",
//       "Airport Name": "Spokane International Airport",
//       "Altitude (Ft)": 2376,
//       "Country": "United States",
//       "Latitude": -117.53399658203125,
//       "Location": "Spokane",
//       "Longitude": 47.61989974975586,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3468,
//       "Airport Code [ICAO]": "KHWO",
//       "Airport Code iATA,FAA": "HWO",
//       "Airport Name": "North Perry Airport",
//       "Altitude (Ft)": 8,
//       "Country": "United States",
//       "Latitude": -80.2407,
//       "Location": "Hollywood",
//       "Longitude": 26.0012,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3469,
//       "Airport Code [ICAO]": "KSFO",
//       "Airport Code iATA,FAA": "SFO",
//       "Airport Name": "San Francisco International Airport",
//       "Altitude (Ft)": 13,
//       "Country": "United States",
//       "Latitude": -122.375,
//       "Location": "San Francisco",
//       "Longitude": 37.61899948120117,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3470,
//       "Airport Code [ICAO]": "KCTB",
//       "Airport Code iATA,FAA": "CTB",
//       "Airport Name": "Cut Bank International Airport",
//       "Altitude (Ft)": 3854,
//       "Country": "United States",
//       "Latitude": -112.375999451,
//       "Location": "Cutbank",
//       "Longitude": 48.6083984375,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3471,
//       "Airport Code [ICAO]": "KARA",
//       "Airport Code iATA,FAA": "ARA",
//       "Airport Name": "Acadiana Regional Airport",
//       "Altitude (Ft)": 24,
//       "Country": "United States",
//       "Latitude": -91.883904,
//       "Location": "Louisiana",
//       "Longitude": 30.0378,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3472,
//       "Airport Code [ICAO]": "KGNV",
//       "Airport Code iATA,FAA": "GNV",
//       "Airport Name": "Gainesville Regional Airport",
//       "Altitude (Ft)": 152,
//       "Country": "United States",
//       "Latitude": -82.2717971802,
//       "Location": "Gainesville",
//       "Longitude": 29.6900997162,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3473,
//       "Airport Code [ICAO]": "KMEM",
//       "Airport Code iATA,FAA": "MEM",
//       "Airport Name": "Memphis International Airport",
//       "Altitude (Ft)": 341,
//       "Country": "United States",
//       "Latitude": -89.97669982910156,
//       "Location": "Memphis",
//       "Longitude": 35.04240036010742,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3474,
//       "Airport Code [ICAO]": "KDUG",
//       "Airport Code iATA,FAA": "DUG",
//       "Airport Name": "Bisbee Douglas International Airport",
//       "Altitude (Ft)": 4154,
//       "Country": "United States",
//       "Latitude": -109.603996277,
//       "Location": "Douglas",
//       "Longitude": 31.4689998627,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 3475,
//       "Airport Code [ICAO]": "PABI",
//       "Airport Code iATA,FAA": "BIG",
//       "Airport Name": "Allen Army Airfield",
//       "Altitude (Ft)": 1291,
//       "Country": "United States",
//       "Latitude": -145.722000122,
//       "Location": "Delta Junction",
//       "Longitude": 63.9944992065,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3476,
//       "Airport Code [ICAO]": "KCNW",
//       "Airport Code iATA,FAA": "CNW",
//       "Airport Name": "TSTC Waco Airport",
//       "Altitude (Ft)": 470,
//       "Country": "United States",
//       "Latitude": -97.0740966797,
//       "Location": "Waco",
//       "Longitude": 31.637800216699997,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3477,
//       "Airport Code [ICAO]": "PANT",
//       "Airport Code iATA,FAA": "ANN",
//       "Airport Name": "Annette Island Airport",
//       "Altitude (Ft)": 119,
//       "Country": "United States",
//       "Latitude": -131.57200622558594,
//       "Location": "Annette Island",
//       "Longitude": 55.04240036010742,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3478,
//       "Airport Code [ICAO]": "KCAR",
//       "Airport Code iATA,FAA": "CAR",
//       "Airport Name": "Caribou Municipal Airport",
//       "Altitude (Ft)": 626,
//       "Country": "United States",
//       "Latitude": -68.0178985596,
//       "Location": "Caribou",
//       "Longitude": 46.871498107899995,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3479,
//       "Airport Code [ICAO]": "KLRF",
//       "Airport Code iATA,FAA": "LRF",
//       "Airport Name": "Little Rock Air Force Base",
//       "Altitude (Ft)": 311,
//       "Country": "United States",
//       "Latitude": -92.14969635010002,
//       "Location": "Jacksonville",
//       "Longitude": 34.916900634799994,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3480,
//       "Airport Code [ICAO]": "KHUA",
//       "Airport Code iATA,FAA": "HUA",
//       "Airport Name": "Redstone Army Air Field",
//       "Altitude (Ft)": 684,
//       "Country": "United States",
//       "Latitude": -86.68479919,
//       "Location": "Redstone",
//       "Longitude": 34.67869949,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3481,
//       "Airport Code [ICAO]": "KPOB",
//       "Airport Code iATA,FAA": "POB",
//       "Airport Name": "Pope Field",
//       "Altitude (Ft)": 217,
//       "Country": "United States",
//       "Latitude": -79.014503479004,
//       "Location": "Fort Bragg",
//       "Longitude": 35.1708984375,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3482,
//       "Airport Code [ICAO]": "KDHT",
//       "Airport Code iATA,FAA": "DHT",
//       "Airport Name": "Dalhart Municipal Airport",
//       "Altitude (Ft)": 3991,
//       "Country": "United States",
//       "Latitude": -102.54699707,
//       "Location": "Dalhart",
//       "Longitude": 36.0225982666,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3483,
//       "Airport Code [ICAO]": "KDLF",
//       "Airport Code iATA,FAA": "DLF",
//       "Airport Name": "DLF Airport",
//       "Altitude (Ft)": 1082,
//       "Country": "United States",
//       "Latitude": -100.778002,
//       "Location": "Del Rio",
//       "Longitude": 29.359501,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3484,
//       "Airport Code [ICAO]": "KLAX",
//       "Airport Code iATA,FAA": "LAX",
//       "Airport Name": "Los Angeles International Airport",
//       "Altitude (Ft)": 125,
//       "Country": "United States",
//       "Latitude": -118.4079971,
//       "Location": "Los Angeles",
//       "Longitude": 33.94250107,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3485,
//       "Airport Code [ICAO]": "KANB",
//       "Airport Code iATA,FAA": "ANB",
//       "Airport Name": "Anniston Regional Airport",
//       "Altitude (Ft)": 612,
//       "Country": "United States",
//       "Latitude": -85.8581,
//       "Location": "Anniston",
//       "Longitude": 33.5882,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3486,
//       "Airport Code [ICAO]": "KCLE",
//       "Airport Code iATA,FAA": "CLE",
//       "Airport Name": "Cleveland Hopkins International Airport",
//       "Altitude (Ft)": 791,
//       "Country": "United States",
//       "Latitude": -81.8498001099,
//       "Location": "Cleveland",
//       "Longitude": 41.4117012024,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3487,
//       "Airport Code [ICAO]": "KDOV",
//       "Airport Code iATA,FAA": "DOV",
//       "Airport Name": "Dover Air Force Base",
//       "Altitude (Ft)": 24,
//       "Country": "United States",
//       "Latitude": -75.46600342,
//       "Location": "Dover",
//       "Longitude": 39.12950134,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3488,
//       "Airport Code [ICAO]": "KCVG",
//       "Airport Code iATA,FAA": "CVG",
//       "Airport Name": "Cincinnati Northern Kentucky International Airport",
//       "Altitude (Ft)": 896,
//       "Country": "United States",
//       "Latitude": -84.6678009033,
//       "Location": "Cincinnati",
//       "Longitude": 39.0488014221,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3489,
//       "Airport Code [ICAO]": "KFME",
//       "Airport Code iATA,FAA": "FME",
//       "Airport Name": "Tipton Airport",
//       "Altitude (Ft)": 150,
//       "Country": "United States",
//       "Latitude": -76.7593994141,
//       "Location": "Fort Meade",
//       "Longitude": 39.08539962769999,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3490,
//       "Airport Code [ICAO]": "KNID",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "China Lake Naws (Armitage Field) Airport",
//       "Altitude (Ft)": 2283,
//       "Country": "United States",
//       "Latitude": -117.6920013,
//       "Location": "China Lake",
//       "Longitude": 35.6853981,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3491,
//       "Airport Code [ICAO]": "KHON",
//       "Airport Code iATA,FAA": "HON",
//       "Airport Name": "Huron Regional Airport",
//       "Altitude (Ft)": 1289,
//       "Country": "United States",
//       "Latitude": -98.22850036621094,
//       "Location": "Huron",
//       "Longitude": 44.38520050048828,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3492,
//       "Airport Code [ICAO]": "PAJN",
//       "Airport Code iATA,FAA": "JNU",
//       "Airport Name": "Juneau International Airport",
//       "Altitude (Ft)": 21,
//       "Country": "United States",
//       "Latitude": -134.5760040283203,
//       "Location": "Juneau",
//       "Longitude": 58.35499954223633,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3493,
//       "Airport Code [ICAO]": "KLFT",
//       "Airport Code iATA,FAA": "LFT",
//       "Airport Name": "Lafayette Regional Airport",
//       "Altitude (Ft)": 42,
//       "Country": "United States",
//       "Latitude": -91.98760223,
//       "Location": "Lafayette",
//       "Longitude": 30.20529938,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3494,
//       "Airport Code [ICAO]": "KEWR",
//       "Airport Code iATA,FAA": "EWR",
//       "Airport Name": "Newark Liberty International Airport",
//       "Altitude (Ft)": 18,
//       "Country": "United States",
//       "Latitude": -74.168701171875,
//       "Location": "Newark",
//       "Longitude": 40.692501068115234,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3495,
//       "Airport Code [ICAO]": "KBOI",
//       "Airport Code iATA,FAA": "BOI",
//       "Airport Name": "Boise Air Terminal/Gowen Field",
//       "Altitude (Ft)": 2871,
//       "Country": "United States",
//       "Latitude": -116.223,
//       "Location": "Boise",
//       "Longitude": 43.5644,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3496,
//       "Airport Code [ICAO]": "KINS",
//       "Airport Code iATA,FAA": "INS",
//       "Airport Name": "Creech Air Force Base",
//       "Altitude (Ft)": 3133,
//       "Country": "United States",
//       "Latitude": -115.672996521,
//       "Location": "Indian Springs",
//       "Longitude": 36.587200164799995,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3497,
//       "Airport Code [ICAO]": "KGCK",
//       "Airport Code iATA,FAA": "GCK",
//       "Airport Name": "Garden City Regional Airport",
//       "Altitude (Ft)": 2891,
//       "Country": "United States",
//       "Latitude": -100.723999023,
//       "Location": "Garden City",
//       "Longitude": 37.9275016785,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3498,
//       "Airport Code [ICAO]": "KMOT",
//       "Airport Code iATA,FAA": "MOT",
//       "Airport Name": "Minot International Airport",
//       "Altitude (Ft)": 1716,
//       "Country": "United States",
//       "Latitude": -101.27999877929688,
//       "Location": "Minot",
//       "Longitude": 48.2593994140625,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3499,
//       "Airport Code [ICAO]": "PHHI",
//       "Airport Code iATA,FAA": "HHI",
//       "Airport Name": "Wheeler Army Airfield",
//       "Altitude (Ft)": 837,
//       "Country": "United States",
//       "Latitude": -158.0399933,
//       "Location": "Wahiawa",
//       "Longitude": 21.48349953,
//       "GMT Offset": "-10",
//       "Daylight Savings": "A",
//       "Region": "Pacific/Honolulu",
//       "Type": "airport"
//     },
//     {
//       "ID": 3500,
//       "Airport Code [ICAO]": "KMXF",
//       "Airport Code iATA,FAA": "MXF",
//       "Airport Name": "Maxwell Air Force Base",
//       "Altitude (Ft)": 171,
//       "Country": "United States",
//       "Latitude": -86.365799,
//       "Location": "Montgomery",
//       "Longitude": 32.3829,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3501,
//       "Airport Code [ICAO]": "KRBM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Robinson Army Air Field",
//       "Altitude (Ft)": 587,
//       "Country": "United States",
//       "Latitude": -92.30020142,
//       "Location": "Robinson",
//       "Longitude": 34.85010147,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3502,
//       "Airport Code [ICAO]": "KDAL",
//       "Airport Code iATA,FAA": "DAL",
//       "Airport Name": "Dallas Love Field",
//       "Altitude (Ft)": 487,
//       "Country": "United States",
//       "Latitude": -96.851799,
//       "Location": "Dallas",
//       "Longitude": 32.847099,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3503,
//       "Airport Code [ICAO]": "KFCS",
//       "Airport Code iATA,FAA": "FCS",
//       "Airport Name": "Butts AAF (Fort Carson) Air Field",
//       "Altitude (Ft)": 5838,
//       "Country": "United States",
//       "Latitude": -104.7570038,
//       "Location": "Fort Carson",
//       "Longitude": 38.67839813,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3504,
//       "Airport Code [ICAO]": "KHLN",
//       "Airport Code iATA,FAA": "HLN",
//       "Airport Name": "Helena Regional Airport",
//       "Altitude (Ft)": 3877,
//       "Country": "United States",
//       "Latitude": -111.98300170898438,
//       "Location": "Helena",
//       "Longitude": 46.6068000793457,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3505,
//       "Airport Code [ICAO]": "KNKX",
//       "Airport Code iATA,FAA": "NKX",
//       "Airport Name": "Miramar Marine Corps Air Station - Mitscher Field",
//       "Altitude (Ft)": 477,
//       "Country": "United States",
//       "Latitude": -117.1429977,
//       "Location": "Miramar",
//       "Longitude": 32.86840057,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3506,
//       "Airport Code [ICAO]": "KLUF",
//       "Airport Code iATA,FAA": "LUF",
//       "Airport Name": "Luke Air Force Base",
//       "Altitude (Ft)": 1085,
//       "Country": "United States",
//       "Latitude": -112.383003235,
//       "Location": "Phoenix",
//       "Longitude": 33.534999847399995,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 3507,
//       "Airport Code [ICAO]": "KHRT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hurlburt Field",
//       "Altitude (Ft)": 38,
//       "Country": "United States",
//       "Latitude": -86.68930053710938,
//       "Location": "Mary Esther",
//       "Longitude": 30.427799224853516,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3508,
//       "Airport Code [ICAO]": "KHHR",
//       "Airport Code iATA,FAA": "HHR",
//       "Airport Name": "Jack Northrop Field Hawthorne Municipal Airport",
//       "Altitude (Ft)": 66,
//       "Country": "United States",
//       "Latitude": -118.334999,
//       "Location": "Hawthorne",
//       "Longitude": 33.922798,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3509,
//       "Airport Code [ICAO]": "KHUL",
//       "Airport Code iATA,FAA": "HUL",
//       "Airport Name": "Houlton International Airport",
//       "Altitude (Ft)": 489,
//       "Country": "United States",
//       "Latitude": -67.792098999,
//       "Location": "Houlton",
//       "Longitude": 46.1231002808,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3510,
//       "Airport Code [ICAO]": "KEND",
//       "Airport Code iATA,FAA": "END",
//       "Airport Name": "Vance Air Force Base",
//       "Altitude (Ft)": 1307,
//       "Country": "United States",
//       "Latitude": -97.9164962769,
//       "Location": "Enid",
//       "Longitude": 36.339199066199996,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3511,
//       "Airport Code [ICAO]": "KNTD",
//       "Airport Code iATA,FAA": "NTD",
//       "Airport Name": "Point Mugu Naval Air Station (Naval Base Ventura Co)",
//       "Altitude (Ft)": 13,
//       "Country": "United States",
//       "Latitude": -119.121002197,
//       "Location": "Point Mugu",
//       "Longitude": 34.120300293,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3512,
//       "Airport Code [ICAO]": "KEDW",
//       "Airport Code iATA,FAA": "EDW",
//       "Airport Name": "Edwards Air Force Base",
//       "Altitude (Ft)": 2312,
//       "Country": "United States",
//       "Latitude": -117.884003,
//       "Location": "Edwards Afb",
//       "Longitude": 34.905399,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3513,
//       "Airport Code [ICAO]": "KLCH",
//       "Airport Code iATA,FAA": "LCH",
//       "Airport Name": "Lake Charles Regional Airport",
//       "Altitude (Ft)": 15,
//       "Country": "United States",
//       "Latitude": -93.22329711914062,
//       "Location": "Lake Charles",
//       "Longitude": 30.126100540161133,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3514,
//       "Airport Code [ICAO]": "PHKO",
//       "Airport Code iATA,FAA": "KOA",
//       "Airport Name": "Ellison Onizuka Kona International At Keahole Airport",
//       "Altitude (Ft)": 47,
//       "Country": "United States",
//       "Latitude": -156.045603,
//       "Location": "Kona",
//       "Longitude": 19.738783,
//       "GMT Offset": "-10",
//       "Daylight Savings": "N",
//       "Region": "Pacific/Honolulu",
//       "Type": "airport"
//     },
//     {
//       "ID": 3515,
//       "Airport Code [ICAO]": "KMYR",
//       "Airport Code iATA,FAA": "MYR",
//       "Airport Name": "Myrtle Beach International Airport",
//       "Altitude (Ft)": 25,
//       "Country": "United States",
//       "Latitude": -78.9282989502,
//       "Location": "Myrtle Beach",
//       "Longitude": 33.6796989441,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3516,
//       "Airport Code [ICAO]": "KNLC",
//       "Airport Code iATA,FAA": "NLC",
//       "Airport Name": "Lemoore Naval Air Station (Reeves Field) Airport",
//       "Altitude (Ft)": 232,
//       "Country": "United States",
//       "Latitude": -119.9520035,
//       "Location": "Lemoore",
//       "Longitude": 36.33300018,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3517,
//       "Airport Code [ICAO]": "KACK",
//       "Airport Code iATA,FAA": "ACK",
//       "Airport Name": "Nantucket Memorial Airport",
//       "Altitude (Ft)": 47,
//       "Country": "United States",
//       "Latitude": -70.06020355,
//       "Location": "Nantucket",
//       "Longitude": 41.25310135,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3518,
//       "Airport Code [ICAO]": "KFAF",
//       "Airport Code iATA,FAA": "FAF",
//       "Airport Name": "Felker Army Air Field",
//       "Altitude (Ft)": 12,
//       "Country": "United States",
//       "Latitude": -76.60880279540001,
//       "Location": "Fort Eustis",
//       "Longitude": 37.132499694799996,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3519,
//       "Airport Code [ICAO]": "KHOP",
//       "Airport Code iATA,FAA": "HOP",
//       "Airport Name": "Campbell AAF (Fort Campbell) Air Field",
//       "Altitude (Ft)": 573,
//       "Country": "United States",
//       "Latitude": -87.49620056150002,
//       "Location": "Hopkinsville",
//       "Longitude": 36.668598175,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3520,
//       "Airport Code [ICAO]": "KDCA",
//       "Airport Code iATA,FAA": "DCA",
//       "Airport Name": "Ronald Reagan Washington National Airport",
//       "Altitude (Ft)": 15,
//       "Country": "United States",
//       "Latitude": -77.037697,
//       "Location": "Washington",
//       "Longitude": 38.8521,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3521,
//       "Airport Code [ICAO]": "KNHK",
//       "Airport Code iATA,FAA": "NHK",
//       "Airport Name": "Patuxent River Naval Air Station (Trapnell Field)",
//       "Altitude (Ft)": 39,
//       "Country": "United States",
//       "Latitude": -76.411797,
//       "Location": "Patuxent River",
//       "Longitude": 38.285999,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3522,
//       "Airport Code [ICAO]": "KPSX",
//       "Airport Code iATA,FAA": "PSX",
//       "Airport Name": "Palacios Municipal Airport",
//       "Altitude (Ft)": 14,
//       "Country": "United States",
//       "Latitude": -96.250999450684,
//       "Location": "Palacios",
//       "Longitude": 28.727500915527,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3523,
//       "Airport Code [ICAO]": "KBYH",
//       "Airport Code iATA,FAA": "BYH",
//       "Airport Name": "Arkansas International Airport",
//       "Altitude (Ft)": 254,
//       "Country": "United States",
//       "Latitude": -89.94400024410001,
//       "Location": "Blytheville",
//       "Longitude": 35.9642982483,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3524,
//       "Airport Code [ICAO]": "KACY",
//       "Airport Code iATA,FAA": "ACY",
//       "Airport Name": "Atlantic City International Airport",
//       "Altitude (Ft)": 75,
//       "Country": "United States",
//       "Latitude": -74.57720184326172,
//       "Location": "Atlantic City",
//       "Longitude": 39.45759963989258,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3525,
//       "Airport Code [ICAO]": "KTIK",
//       "Airport Code iATA,FAA": "TIK",
//       "Airport Name": "Tinker Air Force Base",
//       "Altitude (Ft)": 1291,
//       "Country": "United States",
//       "Latitude": -97.386596679688,
//       "Location": "Oklahoma City",
//       "Longitude": 35.414699554443,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3526,
//       "Airport Code [ICAO]": "KECG",
//       "Airport Code iATA,FAA": "ECG",
//       "Airport Name": "Elizabeth City Regional Airport & Coast Guard Air Station",
//       "Altitude (Ft)": 12,
//       "Country": "United States",
//       "Latitude": -76.17459869,
//       "Location": "Elizabeth City",
//       "Longitude": 36.26060104,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3527,
//       "Airport Code [ICAO]": "KPUB",
//       "Airport Code iATA,FAA": "PUB",
//       "Airport Name": "Pueblo Memorial Airport",
//       "Altitude (Ft)": 4726,
//       "Country": "United States",
//       "Latitude": -104.49700164794922,
//       "Location": "Pueblo",
//       "Longitude": 38.289100646972656,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3528,
//       "Airport Code [ICAO]": "KPQI",
//       "Airport Code iATA,FAA": "PQI",
//       "Airport Name": "Northern Maine Regional Airport at Presque Isle",
//       "Altitude (Ft)": 534,
//       "Country": "United States",
//       "Latitude": -68.0447998,
//       "Location": "Presque Isle",
//       "Longitude": 46.68899918,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3530,
//       "Airport Code [ICAO]": "KGRF",
//       "Airport Code iATA,FAA": "GRF",
//       "Airport Name": "Gray Army Air Field",
//       "Altitude (Ft)": 300,
//       "Country": "United States",
//       "Latitude": -122.5810013,
//       "Location": "Fort Lewis",
//       "Longitude": 47.07920074,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3531,
//       "Airport Code [ICAO]": "PADQ",
//       "Airport Code iATA,FAA": "ADQ",
//       "Airport Name": "Kodiak Airport",
//       "Altitude (Ft)": 78,
//       "Country": "United States",
//       "Latitude": -152.4940033,
//       "Location": "Kodiak",
//       "Longitude": 57.75,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3532,
//       "Airport Code [ICAO]": "PHUP",
//       "Airport Code iATA,FAA": "UPP",
//       "Airport Name": "Upolu Airport",
//       "Altitude (Ft)": 96,
//       "Country": "United States",
//       "Latitude": -155.86000061035156,
//       "Location": "Opolu",
//       "Longitude": 20.265300750732422,
//       "GMT Offset": "-10",
//       "Daylight Savings": "A",
//       "Region": "Pacific/Honolulu",
//       "Type": "airport"
//     },
//     {
//       "ID": 3533,
//       "Airport Code [ICAO]": "KFLL",
//       "Airport Code iATA,FAA": "FLL",
//       "Airport Name": "Fort Lauderdale Hollywood International Airport",
//       "Altitude (Ft)": 9,
//       "Country": "United States",
//       "Latitude": -80.152702,
//       "Location": "Fort Lauderdale",
//       "Longitude": 26.072599,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3534,
//       "Airport Code [ICAO]": "KMKO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Muskogee-Davis Regional Airport",
//       "Altitude (Ft)": 611,
//       "Country": "United States",
//       "Latitude": -95.366699,
//       "Location": "Muskogee",
//       "Longitude": 35.656502,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3535,
//       "Airport Code [ICAO]": "KINL",
//       "Airport Code iATA,FAA": "INL",
//       "Airport Name": "Falls International Airport",
//       "Altitude (Ft)": 1185,
//       "Country": "United States",
//       "Latitude": -93.4030990600586,
//       "Location": "International Falls",
//       "Longitude": 48.566200256347656,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3536,
//       "Airport Code [ICAO]": "KSLC",
//       "Airport Code iATA,FAA": "SLC",
//       "Airport Name": "Salt Lake City International Airport",
//       "Altitude (Ft)": 4227,
//       "Country": "United States",
//       "Latitude": -111.97799682617188,
//       "Location": "Salt Lake City",
//       "Longitude": 40.78839874267578,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3537,
//       "Airport Code [ICAO]": "KCDS",
//       "Airport Code iATA,FAA": "CDS",
//       "Airport Name": "Childress Municipal Airport",
//       "Altitude (Ft)": 1954,
//       "Country": "United States",
//       "Latitude": -100.288002014,
//       "Location": "Childress",
//       "Longitude": 34.4337997437,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3538,
//       "Airport Code [ICAO]": "KBIX",
//       "Airport Code iATA,FAA": "BIX",
//       "Airport Name": "Keesler Air Force Base",
//       "Altitude (Ft)": 33,
//       "Country": "United States",
//       "Latitude": -88.92440032959999,
//       "Location": "Biloxi",
//       "Longitude": 30.4104003906,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3539,
//       "Airport Code [ICAO]": "KLSF",
//       "Airport Code iATA,FAA": "LSF",
//       "Airport Name": "Lawson Army Air Field (Fort Benning)",
//       "Altitude (Ft)": 232,
//       "Country": "United States",
//       "Latitude": -84.9913024902,
//       "Location": "Fort Benning",
//       "Longitude": 32.337299346900004,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3540,
//       "Airport Code [ICAO]": "KNQI",
//       "Airport Code iATA,FAA": "NQI",
//       "Airport Name": "Kingsville Naval Air Station",
//       "Altitude (Ft)": 50,
//       "Country": "United States",
//       "Latitude": -97.8097000122,
//       "Location": "Kingsville",
//       "Longitude": 27.5072002411,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3541,
//       "Airport Code [ICAO]": "KFRI",
//       "Airport Code iATA,FAA": "FRI",
//       "Airport Name": "Marshall Army Air Field",
//       "Altitude (Ft)": 1065,
//       "Country": "United States",
//       "Latitude": -96.76450348,
//       "Location": "Fort Riley",
//       "Longitude": 39.05530167,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3542,
//       "Airport Code [ICAO]": "KMDT",
//       "Airport Code iATA,FAA": "MDT",
//       "Airport Name": "Harrisburg International Airport",
//       "Altitude (Ft)": 310,
//       "Country": "United States",
//       "Latitude": -76.7633972168,
//       "Location": "Harrisburg",
//       "Longitude": 40.1935005188,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3543,
//       "Airport Code [ICAO]": "KLNK",
//       "Airport Code iATA,FAA": "LNK",
//       "Airport Name": "Lincoln Airport",
//       "Altitude (Ft)": 1219,
//       "Country": "United States",
//       "Latitude": -96.75920104980469,
//       "Location": "Lincoln",
//       "Longitude": 40.85100173950195,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3544,
//       "Airport Code [ICAO]": "KLAN",
//       "Airport Code iATA,FAA": "LAN",
//       "Airport Name": "Capital City Airport",
//       "Altitude (Ft)": 861,
//       "Country": "United States",
//       "Latitude": -84.58740234375,
//       "Location": "Lansing",
//       "Longitude": 42.77870178222656,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3545,
//       "Airport Code [ICAO]": "PHMU",
//       "Airport Code iATA,FAA": "MUE",
//       "Airport Name": "Waimea Kohala Airport",
//       "Altitude (Ft)": 2671,
//       "Country": "United States",
//       "Latitude": -155.66799926757812,
//       "Location": "Kamuela",
//       "Longitude": 20.001300811767578,
//       "GMT Offset": "-10",
//       "Daylight Savings": "A",
//       "Region": "Pacific/Honolulu",
//       "Type": "airport"
//     },
//     {
//       "ID": 3546,
//       "Airport Code [ICAO]": "KMSS",
//       "Airport Code iATA,FAA": "MSS",
//       "Airport Name": "Massena International Richards Field",
//       "Altitude (Ft)": 215,
//       "Country": "United States",
//       "Latitude": -74.84559631347656,
//       "Location": "Massena",
//       "Longitude": 44.93579864501953,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3547,
//       "Airport Code [ICAO]": "KHKY",
//       "Airport Code iATA,FAA": "HKY",
//       "Airport Name": "Hickory Regional Airport",
//       "Altitude (Ft)": 1190,
//       "Country": "United States",
//       "Latitude": -81.38950348,
//       "Location": "Hickory",
//       "Longitude": 35.74110031,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3548,
//       "Airport Code [ICAO]": "KSPG",
//       "Airport Code iATA,FAA": "SPG",
//       "Airport Name": "Albert Whitted Airport",
//       "Altitude (Ft)": 7,
//       "Country": "United States",
//       "Latitude": -82.626999,
//       "Location": "St. Petersburg",
//       "Longitude": 27.7651,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3549,
//       "Airport Code [ICAO]": "KFMY",
//       "Airport Code iATA,FAA": "FMY",
//       "Airport Name": "Page Field",
//       "Altitude (Ft)": 17,
//       "Country": "United States",
//       "Latitude": -81.86329650879999,
//       "Location": "Fort Myers",
//       "Longitude": 26.58659935,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3550,
//       "Airport Code [ICAO]": "KIAH",
//       "Airport Code iATA,FAA": "IAH",
//       "Airport Name": "George Bush Intercontinental Houston Airport",
//       "Altitude (Ft)": 97,
//       "Country": "United States",
//       "Latitude": -95.34140014648438,
//       "Location": "Houston",
//       "Longitude": 29.984399795532227,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3551,
//       "Airport Code [ICAO]": "KMLT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Millinocket Municipal Airport",
//       "Altitude (Ft)": 408,
//       "Country": "United States",
//       "Latitude": -68.68560028076172,
//       "Location": "Millinocket",
//       "Longitude": 45.64780044555664,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3552,
//       "Airport Code [ICAO]": "KADW",
//       "Airport Code iATA,FAA": "ADW",
//       "Airport Name": "Joint Base Andrews",
//       "Altitude (Ft)": 280,
//       "Country": "United States",
//       "Latitude": -76.866997,
//       "Location": "Camp Springs",
//       "Longitude": 38.810799,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3553,
//       "Airport Code [ICAO]": "KINT",
//       "Airport Code iATA,FAA": "INT",
//       "Airport Name": "Smith Reynolds Airport",
//       "Altitude (Ft)": 969,
//       "Country": "United States",
//       "Latitude": -80.22200012207031,
//       "Location": "Winston-salem",
//       "Longitude": 36.13370132446289,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3554,
//       "Airport Code [ICAO]": "KVCV",
//       "Airport Code iATA,FAA": "VCV",
//       "Airport Name": "Southern California Logistics Airport",
//       "Altitude (Ft)": 2885,
//       "Country": "United States",
//       "Latitude": -117.383003235,
//       "Location": "Victorville",
//       "Longitude": 34.597499847399995,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3555,
//       "Airport Code [ICAO]": "KCEW",
//       "Airport Code iATA,FAA": "CEW",
//       "Airport Name": "Bob Sikes Airport",
//       "Altitude (Ft)": 213,
//       "Country": "United States",
//       "Latitude": -86.522102356,
//       "Location": "Crestview",
//       "Longitude": 30.778799057,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3556,
//       "Airport Code [ICAO]": "KGTB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Wheeler Sack Army Air Field",
//       "Altitude (Ft)": 688,
//       "Country": "United States",
//       "Latitude": -75.71949768,
//       "Location": "Fort Drum",
//       "Longitude": 44.05559921,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3557,
//       "Airport Code [ICAO]": "KPHN",
//       "Airport Code iATA,FAA": "PHN",
//       "Airport Name": "St Clair County International Airport",
//       "Altitude (Ft)": 650,
//       "Country": "United States",
//       "Latitude": -82.52890015,
//       "Location": "Port Huron",
//       "Longitude": 42.9109993,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3558,
//       "Airport Code [ICAO]": "KBFL",
//       "Airport Code iATA,FAA": "BFL",
//       "Airport Name": "Meadows Field",
//       "Altitude (Ft)": 510,
//       "Country": "United States",
//       "Latitude": -119.0569992,
//       "Location": "Bakersfield",
//       "Longitude": 35.43360138,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3559,
//       "Airport Code [ICAO]": "KELP",
//       "Airport Code iATA,FAA": "ELP",
//       "Airport Name": "El Paso International Airport",
//       "Altitude (Ft)": 3959,
//       "Country": "United States",
//       "Latitude": -106.3779984,
//       "Location": "El Paso",
//       "Longitude": 31.80719948,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3560,
//       "Airport Code [ICAO]": "KHRL",
//       "Airport Code iATA,FAA": "HRL",
//       "Airport Name": "Valley International Airport",
//       "Altitude (Ft)": 36,
//       "Country": "United States",
//       "Latitude": -97.65440368652344,
//       "Location": "Harlingen",
//       "Longitude": 26.228500366210938,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3561,
//       "Airport Code [ICAO]": "KCAE",
//       "Airport Code iATA,FAA": "CAE",
//       "Airport Name": "Columbia Metropolitan Airport",
//       "Altitude (Ft)": 236,
//       "Country": "United States",
//       "Latitude": -81.11949920654297,
//       "Location": "Columbia",
//       "Longitude": 33.93880081176758,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3562,
//       "Airport Code [ICAO]": "KDMA",
//       "Airport Code iATA,FAA": "DMA",
//       "Airport Name": "Davis Monthan Air Force Base",
//       "Altitude (Ft)": 2704,
//       "Country": "United States",
//       "Latitude": -110.883003235,
//       "Location": "Tucson",
//       "Longitude": 32.1665000916,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 3563,
//       "Airport Code [ICAO]": "KNPA",
//       "Airport Code iATA,FAA": "NPA",
//       "Airport Name": "Pensacola Naval Air Station/Forrest Sherman Field",
//       "Altitude (Ft)": 28,
//       "Country": "United States",
//       "Latitude": -87.31860352,
//       "Location": "Pensacola",
//       "Longitude": 30.35269928,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3564,
//       "Airport Code [ICAO]": "KPNS",
//       "Airport Code iATA,FAA": "PNS",
//       "Airport Name": "Pensacola Regional Airport",
//       "Altitude (Ft)": 121,
//       "Country": "United States",
//       "Latitude": -87.186599731445,
//       "Location": "Pensacola",
//       "Longitude": 30.473400115967,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3565,
//       "Airport Code [ICAO]": "KRDR",
//       "Airport Code iATA,FAA": "RDR",
//       "Airport Name": "Grand Forks Air Force Base",
//       "Altitude (Ft)": 913,
//       "Country": "United States",
//       "Latitude": -97.4011993408,
//       "Location": "Red River",
//       "Longitude": 47.961101532,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3566,
//       "Airport Code [ICAO]": "KHOU",
//       "Airport Code iATA,FAA": "HOU",
//       "Airport Name": "William P Hobby Airport",
//       "Altitude (Ft)": 46,
//       "Country": "United States",
//       "Latitude": -95.27890015,
//       "Location": "Houston",
//       "Longitude": 29.64539909,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3567,
//       "Airport Code [ICAO]": "KBKF",
//       "Airport Code iATA,FAA": "BFK",
//       "Airport Name": "Buckley Air Force Base",
//       "Altitude (Ft)": 5662,
//       "Country": "United States",
//       "Latitude": -104.751998901,
//       "Location": "Buckley",
//       "Longitude": 39.701698303200004,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3568,
//       "Airport Code [ICAO]": "PAOR",
//       "Airport Code iATA,FAA": "ORT",
//       "Airport Name": "Northway Airport",
//       "Altitude (Ft)": 1715,
//       "Country": "United States",
//       "Latitude": -141.9290009,
//       "Location": "Northway",
//       "Longitude": 62.9612999,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3569,
//       "Airport Code [ICAO]": "PAAQ",
//       "Airport Code iATA,FAA": "PAQ",
//       "Airport Name": "Warren \"Bud\" Woods Palmer Municipal Airport",
//       "Altitude (Ft)": 242,
//       "Country": "United States",
//       "Latitude": -149.08901,
//       "Location": "Palmer",
//       "Longitude": 61.594898,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3570,
//       "Airport Code [ICAO]": "KPIT",
//       "Airport Code iATA,FAA": "PIT",
//       "Airport Name": "Pittsburgh International Airport",
//       "Altitude (Ft)": 1203,
//       "Country": "United States",
//       "Latitude": -80.23290253,
//       "Location": "Pittsburgh",
//       "Longitude": 40.49150085,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3571,
//       "Airport Code [ICAO]": "PABR",
//       "Airport Code iATA,FAA": "BRW",
//       "Airport Name": "Wiley Post Will Rogers Memorial Airport",
//       "Altitude (Ft)": 44,
//       "Country": "United States",
//       "Latitude": -156.766008,
//       "Location": "Barrow",
//       "Longitude": 71.285402,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3572,
//       "Airport Code [ICAO]": "KEFD",
//       "Airport Code iATA,FAA": "EFD",
//       "Airport Name": "Ellington Airport",
//       "Altitude (Ft)": 32,
//       "Country": "United States",
//       "Latitude": -95.1587982178,
//       "Location": "Houston",
//       "Longitude": 29.607299804700002,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3573,
//       "Airport Code [ICAO]": "KNUW",
//       "Airport Code iATA,FAA": "NUW",
//       "Airport Name": "Whidbey Island Naval Air Station (Ault Field)",
//       "Altitude (Ft)": 47,
//       "Country": "United States",
//       "Latitude": -122.655998,
//       "Location": "Whidbey Island",
//       "Longitude": 48.351799,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3574,
//       "Airport Code [ICAO]": "KALI",
//       "Airport Code iATA,FAA": "ALI",
//       "Airport Name": "Alice International Airport",
//       "Altitude (Ft)": 178,
//       "Country": "United States",
//       "Latitude": -98.02690124510002,
//       "Location": "Alice",
//       "Longitude": 27.740900039699998,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3575,
//       "Airport Code [ICAO]": "KVAD",
//       "Airport Code iATA,FAA": "VAD",
//       "Airport Name": "Moody Air Force Base",
//       "Altitude (Ft)": 233,
//       "Country": "United States",
//       "Latitude": -83.1930007935,
//       "Location": "Valdosta",
//       "Longitude": 30.9678001404,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3576,
//       "Airport Code [ICAO]": "KMIA",
//       "Airport Code iATA,FAA": "MIA",
//       "Airport Name": "Miami International Airport",
//       "Altitude (Ft)": 8,
//       "Country": "United States",
//       "Latitude": -80.29060363769531,
//       "Location": "Miami",
//       "Longitude": 25.79319953918457,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3577,
//       "Airport Code [ICAO]": "KSEA",
//       "Airport Code iATA,FAA": "SEA",
//       "Airport Name": "Seattle Tacoma International Airport",
//       "Altitude (Ft)": 433,
//       "Country": "United States",
//       "Latitude": -122.308998,
//       "Location": "Seattle",
//       "Longitude": 47.449001,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3578,
//       "Airport Code [ICAO]": "KCHA",
//       "Airport Code iATA,FAA": "CHA",
//       "Airport Name": "Lovell Field",
//       "Altitude (Ft)": 683,
//       "Country": "United States",
//       "Latitude": -85.20379638671875,
//       "Location": "Chattanooga",
//       "Longitude": 35.035301208496094,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3579,
//       "Airport Code [ICAO]": "KBDR",
//       "Airport Code iATA,FAA": "BDR",
//       "Airport Name": "Igor I Sikorsky Memorial Airport",
//       "Altitude (Ft)": 9,
//       "Country": "United States",
//       "Latitude": -73.1261978149414,
//       "Location": "Stratford",
//       "Longitude": 41.16350173950195,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3580,
//       "Airport Code [ICAO]": "KJAN",
//       "Airport Code iATA,FAA": "JAN",
//       "Airport Name": "Jackson-Medgar Wiley Evers International Airport",
//       "Altitude (Ft)": 346,
//       "Country": "United States",
//       "Latitude": -90.0758972168,
//       "Location": "Jackson",
//       "Longitude": 32.3111991882,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3581,
//       "Airport Code [ICAO]": "KGLS",
//       "Airport Code iATA,FAA": "GLS",
//       "Airport Name": "Scholes International At Galveston Airport",
//       "Altitude (Ft)": 6,
//       "Country": "United States",
//       "Latitude": -94.86039733886719,
//       "Location": "Galveston",
//       "Longitude": 29.265300750732422,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3582,
//       "Airport Code [ICAO]": "KLGB",
//       "Airport Code iATA,FAA": "LGB",
//       "Airport Name": "Long Beach /Daugherty Field/ Airport",
//       "Altitude (Ft)": 60,
//       "Country": "United States",
//       "Latitude": -118.1520004,
//       "Location": "Long Beach",
//       "Longitude": 33.81769943,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3583,
//       "Airport Code [ICAO]": "PHDH",
//       "Airport Code iATA,FAA": "HDH",
//       "Airport Name": "Dillingham Airfield",
//       "Altitude (Ft)": 14,
//       "Country": "United States",
//       "Latitude": -158.197006226,
//       "Location": "Dillingham",
//       "Longitude": 21.5795001984,
//       "GMT Offset": "-10",
//       "Daylight Savings": "A",
//       "Region": "Pacific/Honolulu",
//       "Type": "airport"
//     },
//     {
//       "ID": 3584,
//       "Airport Code [ICAO]": "KIPT",
//       "Airport Code iATA,FAA": "IPT",
//       "Airport Name": "Williamsport Regional Airport",
//       "Altitude (Ft)": 529,
//       "Country": "United States",
//       "Latitude": -76.92109680175781,
//       "Location": "Williamsport",
//       "Longitude": 41.241798400878906,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3585,
//       "Airport Code [ICAO]": "KIND",
//       "Airport Code iATA,FAA": "IND",
//       "Airport Name": "Indianapolis International Airport",
//       "Altitude (Ft)": 797,
//       "Country": "United States",
//       "Latitude": -86.294403,
//       "Location": "Indianapolis",
//       "Longitude": 39.7173,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3586,
//       "Airport Code [ICAO]": "KSZL",
//       "Airport Code iATA,FAA": "SZL",
//       "Airport Name": "Whiteman Air Force Base",
//       "Altitude (Ft)": 870,
//       "Country": "United States",
//       "Latitude": -93.547897338867,
//       "Location": "Knobnoster",
//       "Longitude": 38.73030090332,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3587,
//       "Airport Code [ICAO]": "KAKR",
//       "Airport Code iATA,FAA": "AKC",
//       "Airport Name": "Akron Fulton International Airport",
//       "Altitude (Ft)": 1067,
//       "Country": "United States",
//       "Latitude": -81.4669036865,
//       "Location": "Akron",
//       "Longitude": 41.0374984741,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3588,
//       "Airport Code [ICAO]": "KGWO",
//       "Airport Code iATA,FAA": "GWO",
//       "Airport Name": "Greenwood–Leflore Airport",
//       "Altitude (Ft)": 162,
//       "Country": "United States",
//       "Latitude": -90.0847015381,
//       "Location": "Greenwood",
//       "Longitude": 33.4943008423,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3589,
//       "Airport Code [ICAO]": "KHPN",
//       "Airport Code iATA,FAA": "HPN",
//       "Airport Name": "Westchester County Airport",
//       "Altitude (Ft)": 439,
//       "Country": "United States",
//       "Latitude": -73.70760345458984,
//       "Location": "White Plains",
//       "Longitude": 41.06700134277344,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3590,
//       "Airport Code [ICAO]": "KFOK",
//       "Airport Code iATA,FAA": "FOK",
//       "Airport Name": "Francis S Gabreski Airport",
//       "Altitude (Ft)": 67,
//       "Country": "United States",
//       "Latitude": -72.6317977905,
//       "Location": "West Hampton Beach",
//       "Longitude": 40.8437004089,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3591,
//       "Airport Code [ICAO]": "KJBR",
//       "Airport Code iATA,FAA": "JBR",
//       "Airport Name": "Jonesboro Municipal Airport",
//       "Altitude (Ft)": 262,
//       "Country": "United States",
//       "Latitude": -90.64640045166016,
//       "Location": "Jonesboro",
//       "Longitude": 35.83169937133789,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3592,
//       "Airport Code [ICAO]": "KTNX",
//       "Airport Code iATA,FAA": "XSD",
//       "Airport Name": "Tonopah Test Range Airport",
//       "Altitude (Ft)": 5549,
//       "Country": "United States",
//       "Latitude": -116.78099823,
//       "Location": "Tonopah",
//       "Longitude": 37.7988014221,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3593,
//       "Airport Code [ICAO]": "KLNA",
//       "Airport Code iATA,FAA": "LNA",
//       "Airport Name": "Palm Beach County Park Airport",
//       "Altitude (Ft)": 14,
//       "Country": "United States",
//       "Latitude": -80.08509827,
//       "Location": "West Palm Beach",
//       "Longitude": 26.59300041,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3594,
//       "Airport Code [ICAO]": "KNZY",
//       "Airport Code iATA,FAA": "NZY",
//       "Airport Name": "North Island Naval Air Station-Halsey Field",
//       "Altitude (Ft)": 26,
//       "Country": "United States",
//       "Latitude": -117.2149963,
//       "Location": "San Diego",
//       "Longitude": 32.69919968,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3595,
//       "Airport Code [ICAO]": "KBIF",
//       "Airport Code iATA,FAA": "BIF",
//       "Airport Name": "Biggs Army Air Field (Fort Bliss)",
//       "Altitude (Ft)": 3946,
//       "Country": "United States",
//       "Latitude": -106.3799973,
//       "Location": "El Paso",
//       "Longitude": 31.84950066,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3596,
//       "Airport Code [ICAO]": "KNYL",
//       "Airport Code iATA,FAA": "YUM",
//       "Airport Name": "Yuma MCAS/Yuma International Airport",
//       "Altitude (Ft)": 213,
//       "Country": "United States",
//       "Latitude": -114.6060028,
//       "Location": "Yuma",
//       "Longitude": 32.65660095,
//       "GMT Offset": "-7",
//       "Daylight Savings": "N",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 3597,
//       "Airport Code [ICAO]": "KCNM",
//       "Airport Code iATA,FAA": "CNM",
//       "Airport Name": "Cavern City Air Terminal",
//       "Altitude (Ft)": 3295,
//       "Country": "United States",
//       "Latitude": -104.26300048828125,
//       "Location": "Carlsbad",
//       "Longitude": 32.337501525878906,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3598,
//       "Airport Code [ICAO]": "KDLH",
//       "Airport Code iATA,FAA": "DLH",
//       "Airport Name": "Duluth International Airport",
//       "Altitude (Ft)": 1428,
//       "Country": "United States",
//       "Latitude": -92.19360351559999,
//       "Location": "Duluth",
//       "Longitude": 46.8420982361,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3599,
//       "Airport Code [ICAO]": "PABE",
//       "Airport Code iATA,FAA": "BET",
//       "Airport Name": "Bethel Airport",
//       "Altitude (Ft)": 126,
//       "Country": "United States",
//       "Latitude": -161.8379974,
//       "Location": "Bethel",
//       "Longitude": 60.77980042,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3600,
//       "Airport Code [ICAO]": "KLOU",
//       "Airport Code iATA,FAA": "LOU",
//       "Airport Name": "Bowman Field",
//       "Altitude (Ft)": 546,
//       "Country": "United States",
//       "Latitude": -85.6636962891,
//       "Location": "Louisville",
//       "Longitude": 38.2280006409,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3601,
//       "Airport Code [ICAO]": "KFHU",
//       "Airport Code iATA,FAA": "FHU",
//       "Airport Name": "Sierra Vista Municipal Libby Army Air Field",
//       "Altitude (Ft)": 4719,
//       "Country": "United States",
//       "Latitude": -110.34400177001953,
//       "Location": "Fort Huachuca",
//       "Longitude": 31.588499069213867,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 3602,
//       "Airport Code [ICAO]": "PHLI",
//       "Airport Code iATA,FAA": "LIH",
//       "Airport Name": "Lihue Airport",
//       "Altitude (Ft)": 153,
//       "Country": "United States",
//       "Latitude": -159.33900451660156,
//       "Location": "Lihue",
//       "Longitude": 21.97599983215332,
//       "GMT Offset": "-10",
//       "Daylight Savings": "N",
//       "Region": "Pacific/Honolulu",
//       "Type": "airport"
//     },
//     {
//       "ID": 3603,
//       "Airport Code [ICAO]": "KHUF",
//       "Airport Code iATA,FAA": "HUF",
//       "Airport Name": "Terre Haute Regional Airport, Hulman Field",
//       "Altitude (Ft)": 589,
//       "Country": "United States",
//       "Latitude": -87.307602,
//       "Location": "Terre Haute",
//       "Longitude": 39.4515,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3604,
//       "Airport Code [ICAO]": "KHVR",
//       "Airport Code iATA,FAA": "HVR",
//       "Airport Name": "Havre City County Airport",
//       "Altitude (Ft)": 2591,
//       "Country": "United States",
//       "Latitude": -109.762001,
//       "Location": "Havre",
//       "Longitude": 48.54299927,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3605,
//       "Airport Code [ICAO]": "KMWH",
//       "Airport Code iATA,FAA": "MWH",
//       "Airport Name": "Grant County International Airport",
//       "Altitude (Ft)": 1189,
//       "Country": "United States",
//       "Latitude": -119.3199997,
//       "Location": "Grant County Airport",
//       "Longitude": 47.20769882,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3606,
//       "Airport Code [ICAO]": "KMPV",
//       "Airport Code iATA,FAA": "MPV",
//       "Airport Name": "Edward F Knapp State Airport",
//       "Altitude (Ft)": 1166,
//       "Country": "United States",
//       "Latitude": -72.56230164,
//       "Location": "Montpelier",
//       "Longitude": 44.20349884,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3607,
//       "Airport Code [ICAO]": "KNSI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "San Nicolas Island Nolf Airport",
//       "Altitude (Ft)": 506,
//       "Country": "United States",
//       "Latitude": -119.45800018310547,
//       "Location": "San Nicolas Island",
//       "Longitude": 33.23979949951172,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3608,
//       "Airport Code [ICAO]": "KRIC",
//       "Airport Code iATA,FAA": "RIC",
//       "Airport Name": "Richmond International Airport",
//       "Altitude (Ft)": 167,
//       "Country": "United States",
//       "Latitude": -77.3197021484375,
//       "Location": "Richmond",
//       "Longitude": 37.50519943237305,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3609,
//       "Airport Code [ICAO]": "KSHV",
//       "Airport Code iATA,FAA": "SHV",
//       "Airport Name": "Shreveport Regional Airport",
//       "Altitude (Ft)": 258,
//       "Country": "United States",
//       "Latitude": -93.8256,
//       "Location": "Shreveport",
//       "Longitude": 32.446602,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3610,
//       "Airport Code [ICAO]": "PACV",
//       "Airport Code iATA,FAA": "CDV",
//       "Airport Name": "Merle K (Mudhole) Smith Airport",
//       "Altitude (Ft)": 54,
//       "Country": "United States",
//       "Latitude": -145.4779968,
//       "Location": "Cordova",
//       "Longitude": 60.4917984,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3611,
//       "Airport Code [ICAO]": "KORF",
//       "Airport Code iATA,FAA": "ORF",
//       "Airport Name": "Norfolk International Airport",
//       "Altitude (Ft)": 26,
//       "Country": "United States",
//       "Latitude": -76.20120239257812,
//       "Location": "Norfolk",
//       "Longitude": 36.89459991455078,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3612,
//       "Airport Code [ICAO]": "KBPT",
//       "Airport Code iATA,FAA": "BPT",
//       "Airport Name": "Southeast Texas Regional Airport",
//       "Altitude (Ft)": 15,
//       "Country": "United States",
//       "Latitude": -94.02069854736328,
//       "Location": "Beaumont",
//       "Longitude": 29.9507999420166,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3613,
//       "Airport Code [ICAO]": "KSAV",
//       "Airport Code iATA,FAA": "SAV",
//       "Airport Name": "Savannah Hilton Head International Airport",
//       "Altitude (Ft)": 50,
//       "Country": "United States",
//       "Latitude": -81.20210266,
//       "Location": "Savannah",
//       "Longitude": 32.12760162,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3614,
//       "Airport Code [ICAO]": "KHIF",
//       "Airport Code iATA,FAA": "HIF",
//       "Airport Name": "Hill Air Force Base",
//       "Altitude (Ft)": 4789,
//       "Country": "United States",
//       "Latitude": -111.973086,
//       "Location": "Ogden",
//       "Longitude": 41.12403,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3615,
//       "Airport Code [ICAO]": "PAOM",
//       "Airport Code iATA,FAA": "OME",
//       "Airport Name": "Nome Airport",
//       "Altitude (Ft)": 37,
//       "Country": "United States",
//       "Latitude": -165.44500732421875,
//       "Location": "Nome",
//       "Longitude": 64.51219940185547,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3616,
//       "Airport Code [ICAO]": "KSPB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Scappoose Industrial Airpark",
//       "Altitude (Ft)": 58,
//       "Country": "United States",
//       "Latitude": -122.86199951171875,
//       "Location": "San Luis",
//       "Longitude": 45.770999908447266,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3617,
//       "Airport Code [ICAO]": "KPIE",
//       "Airport Code iATA,FAA": "PIE",
//       "Airport Name": "St Petersburg Clearwater International Airport",
//       "Altitude (Ft)": 11,
//       "Country": "United States",
//       "Latitude": -82.68740082,
//       "Location": "St. Petersburg",
//       "Longitude": 27.91020012,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3618,
//       "Airport Code [ICAO]": "KMNM",
//       "Airport Code iATA,FAA": "MNM",
//       "Airport Name": "Menominee Regional Airport",
//       "Altitude (Ft)": 625,
//       "Country": "United States",
//       "Latitude": -87.638397,
//       "Location": "Macon",
//       "Longitude": 45.126701,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3619,
//       "Airport Code [ICAO]": "KCXO",
//       "Airport Code iATA,FAA": "CXO",
//       "Airport Name": "Conroe-North Houston Regional Airport",
//       "Altitude (Ft)": 245,
//       "Country": "United States",
//       "Latitude": -95.414497,
//       "Location": "Conroe",
//       "Longitude": 30.351801,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3620,
//       "Airport Code [ICAO]": "PASC",
//       "Airport Code iATA,FAA": "SCC",
//       "Airport Name": "Deadhorse Airport",
//       "Altitude (Ft)": 65,
//       "Country": "United States",
//       "Latitude": -148.4649963,
//       "Location": "Deadhorse",
//       "Longitude": 70.19470215,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3621,
//       "Airport Code [ICAO]": "KSAT",
//       "Airport Code iATA,FAA": "SAT",
//       "Airport Name": "San Antonio International Airport",
//       "Altitude (Ft)": 809,
//       "Country": "United States",
//       "Latitude": -98.46980285644531,
//       "Location": "San Antonio",
//       "Longitude": 29.533700942993164,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3622,
//       "Airport Code [ICAO]": "KROC",
//       "Airport Code iATA,FAA": "ROC",
//       "Airport Name": "Greater Rochester International Airport",
//       "Altitude (Ft)": 559,
//       "Country": "United States",
//       "Latitude": -77.67240142822266,
//       "Location": "Rochester",
//       "Longitude": 43.118900299072266,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3623,
//       "Airport Code [ICAO]": "KCOF",
//       "Airport Code iATA,FAA": "COF",
//       "Airport Name": "Patrick Air Force Base",
//       "Altitude (Ft)": 8,
//       "Country": "United States",
//       "Latitude": -80.6100997925,
//       "Location": "Coco Beach",
//       "Longitude": 28.2348995209,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3624,
//       "Airport Code [ICAO]": "KTEB",
//       "Airport Code iATA,FAA": "TEB",
//       "Airport Name": "Teterboro Airport",
//       "Altitude (Ft)": 9,
//       "Country": "United States",
//       "Latitude": -74.060798645,
//       "Location": "Teterboro",
//       "Longitude": 40.85010147089999,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3625,
//       "Airport Code [ICAO]": "KRCA",
//       "Airport Code iATA,FAA": "RCA",
//       "Airport Name": "Ellsworth Air Force Base",
//       "Altitude (Ft)": 3276,
//       "Country": "United States",
//       "Latitude": -103.1039963,
//       "Location": "Rapid City",
//       "Longitude": 44.14500046,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3626,
//       "Airport Code [ICAO]": "KRDU",
//       "Airport Code iATA,FAA": "RDU",
//       "Airport Name": "Raleigh Durham International Airport",
//       "Altitude (Ft)": 435,
//       "Country": "United States",
//       "Latitude": -78.7874984741211,
//       "Location": "Raleigh-durham",
//       "Longitude": 35.877601623535156,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3627,
//       "Airport Code [ICAO]": "KDAY",
//       "Airport Code iATA,FAA": "DAY",
//       "Airport Name": "James M Cox Dayton International Airport",
//       "Altitude (Ft)": 1009,
//       "Country": "United States",
//       "Latitude": -84.21939849853516,
//       "Location": "Dayton",
//       "Longitude": 39.902400970458984,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3628,
//       "Airport Code [ICAO]": "PAEN",
//       "Airport Code iATA,FAA": "ENA",
//       "Airport Name": "Kenai Municipal Airport",
//       "Altitude (Ft)": 99,
//       "Country": "United States",
//       "Latitude": -151.2449951171875,
//       "Location": "Kenai",
//       "Longitude": 60.57310104370117,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3629,
//       "Airport Code [ICAO]": "KMLC",
//       "Airport Code iATA,FAA": "MLC",
//       "Airport Name": "Mc Alester Regional Airport",
//       "Altitude (Ft)": 770,
//       "Country": "United States",
//       "Latitude": -95.783501,
//       "Location": "Mcalester",
//       "Longitude": 34.882401,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3630,
//       "Airport Code [ICAO]": "KIAG",
//       "Airport Code iATA,FAA": "IAG",
//       "Airport Name": "Niagara Falls International Airport",
//       "Altitude (Ft)": 589,
//       "Country": "United States",
//       "Latitude": -78.94619750976562,
//       "Location": "Niagara Falls",
//       "Longitude": 43.1072998046875,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3631,
//       "Airport Code [ICAO]": "KCFD",
//       "Airport Code iATA,FAA": "CFD",
//       "Airport Name": "Coulter Field",
//       "Altitude (Ft)": 367,
//       "Country": "United States",
//       "Latitude": -96.3313980103,
//       "Location": "Bryan",
//       "Longitude": 30.715700149499998,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3632,
//       "Airport Code [ICAO]": "KLHW",
//       "Airport Code iATA,FAA": "LIY",
//       "Airport Name": "Wright AAF (Fort Stewart)/Midcoast Regional Airport",
//       "Altitude (Ft)": 45,
//       "Country": "United States",
//       "Latitude": -81.562303,
//       "Location": "Wright",
//       "Longitude": 31.889099,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3633,
//       "Airport Code [ICAO]": "KPHF",
//       "Airport Code iATA,FAA": "PHF",
//       "Airport Name": "Newport News Williamsburg International Airport",
//       "Altitude (Ft)": 42,
//       "Country": "United States",
//       "Latitude": -76.49299622,
//       "Location": "Newport News",
//       "Longitude": 37.13190079,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3634,
//       "Airport Code [ICAO]": "KESF",
//       "Airport Code iATA,FAA": "ESF",
//       "Airport Name": "Esler Regional Airport",
//       "Altitude (Ft)": 112,
//       "Country": "United States",
//       "Latitude": -92.2957992554,
//       "Location": "Alexandria",
//       "Longitude": 31.3948993683,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3635,
//       "Airport Code [ICAO]": "KLTS",
//       "Airport Code iATA,FAA": "LTS",
//       "Airport Name": "Altus Air Force Base",
//       "Altitude (Ft)": 1382,
//       "Country": "United States",
//       "Latitude": -99.2667007446,
//       "Location": "Altus",
//       "Longitude": 34.667098999,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3636,
//       "Airport Code [ICAO]": "KTUS",
//       "Airport Code iATA,FAA": "TUS",
//       "Airport Name": "Tucson International Airport",
//       "Altitude (Ft)": 2643,
//       "Country": "United States",
//       "Latitude": -110.94100189208984,
//       "Location": "Tucson",
//       "Longitude": 32.1161003112793,
//       "GMT Offset": "-7",
//       "Daylight Savings": "N",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 3637,
//       "Airport Code [ICAO]": "KMIB",
//       "Airport Code iATA,FAA": "MIB",
//       "Airport Name": "Minot Air Force Base",
//       "Altitude (Ft)": 1667,
//       "Country": "United States",
//       "Latitude": -101.358002,
//       "Location": "Minot",
//       "Longitude": 48.4156,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3638,
//       "Airport Code [ICAO]": "KBAB",
//       "Airport Code iATA,FAA": "BAB",
//       "Airport Name": "Beale Air Force Base",
//       "Altitude (Ft)": 113,
//       "Country": "United States",
//       "Latitude": -121.43699646,
//       "Location": "Marysville",
//       "Longitude": 39.136100769,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3639,
//       "Airport Code [ICAO]": "KIKK",
//       "Airport Code iATA,FAA": "IKK",
//       "Airport Name": "Greater Kankakee Airport",
//       "Altitude (Ft)": 630,
//       "Country": "United States",
//       "Latitude": -87.8462982178,
//       "Location": "Kankakee",
//       "Longitude": 41.07139968869999,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3640,
//       "Airport Code [ICAO]": "KGSB",
//       "Airport Code iATA,FAA": "GSB",
//       "Airport Name": "Seymour Johnson Air Force Base",
//       "Altitude (Ft)": 109,
//       "Country": "United States",
//       "Latitude": -77.96060181,
//       "Location": "Goldsboro",
//       "Longitude": 35.33940125,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3641,
//       "Airport Code [ICAO]": "KPVD",
//       "Airport Code iATA,FAA": "PVD",
//       "Airport Name": "Theodore Francis Green State Airport",
//       "Altitude (Ft)": 55,
//       "Country": "United States",
//       "Latitude": -71.420403,
//       "Location": "Providence",
//       "Longitude": 41.732601,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3642,
//       "Airport Code [ICAO]": "KSBY",
//       "Airport Code iATA,FAA": "SBY",
//       "Airport Name": "Salisbury Ocean City Wicomico Regional Airport",
//       "Altitude (Ft)": 52,
//       "Country": "United States",
//       "Latitude": -75.51029968261719,
//       "Location": "Salisbury",
//       "Longitude": 38.34049987792969,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3643,
//       "Airport Code [ICAO]": "KRIU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Rancho Murieta Airport",
//       "Altitude (Ft)": 141,
//       "Country": "United States",
//       "Latitude": -121.10299682617188,
//       "Location": "Rancho Murieta",
//       "Longitude": 38.48680114746094,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3644,
//       "Airport Code [ICAO]": "KBUR",
//       "Airport Code iATA,FAA": "BUR",
//       "Airport Name": "Bob Hope Airport",
//       "Altitude (Ft)": 778,
//       "Country": "United States",
//       "Latitude": -118.35900115966797,
//       "Location": "Burbank",
//       "Longitude": 34.20069885253906,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3645,
//       "Airport Code [ICAO]": "KDTW",
//       "Airport Code iATA,FAA": "DTW",
//       "Airport Name": "Detroit Metropolitan Wayne County Airport",
//       "Altitude (Ft)": 645,
//       "Country": "United States",
//       "Latitude": -83.35340118408203,
//       "Location": "Detroit",
//       "Longitude": 42.212398529052734,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3646,
//       "Airport Code [ICAO]": "KTPA",
//       "Airport Code iATA,FAA": "TPA",
//       "Airport Name": "Tampa International Airport",
//       "Altitude (Ft)": 26,
//       "Country": "United States",
//       "Latitude": -82.533203125,
//       "Location": "Tampa",
//       "Longitude": 27.975500106811523,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3647,
//       "Airport Code [ICAO]": "KPMB",
//       "Airport Code iATA,FAA": "PMB",
//       "Airport Name": "Pembina Municipal Airport",
//       "Altitude (Ft)": 795,
//       "Country": "United States",
//       "Latitude": -97.2407989502,
//       "Location": "Pembina",
//       "Longitude": 48.9425010681,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3648,
//       "Airport Code [ICAO]": "KPOE",
//       "Airport Code iATA,FAA": "POE",
//       "Airport Name": "Polk Army Air Field",
//       "Altitude (Ft)": 330,
//       "Country": "United States",
//       "Latitude": -93.1917038,
//       "Location": "Fort Polk",
//       "Longitude": 31.0447998,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3649,
//       "Airport Code [ICAO]": "PAEI",
//       "Airport Code iATA,FAA": "EIL",
//       "Airport Name": "Eielson Air Force Base",
//       "Altitude (Ft)": 547,
//       "Country": "United States",
//       "Latitude": -147.102005,
//       "Location": "Fairbanks",
//       "Longitude": 64.66570282,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3650,
//       "Airport Code [ICAO]": "KHIB",
//       "Airport Code iATA,FAA": "HIB",
//       "Airport Name": "Range Regional Airport",
//       "Altitude (Ft)": 1354,
//       "Country": "United States",
//       "Latitude": -92.83899689,
//       "Location": "Hibbing",
//       "Longitude": 47.38660049,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3651,
//       "Airport Code [ICAO]": "KLFK",
//       "Airport Code iATA,FAA": "LFK",
//       "Airport Name": "Angelina County Airport",
//       "Altitude (Ft)": 296,
//       "Country": "United States",
//       "Latitude": -94.75,
//       "Location": "Lufkin",
//       "Longitude": 31.2339992523,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3652,
//       "Airport Code [ICAO]": "KMAF",
//       "Airport Code iATA,FAA": "MAF",
//       "Airport Name": "Midland International Airport",
//       "Altitude (Ft)": 2871,
//       "Country": "United States",
//       "Latitude": -102.2020034790039,
//       "Location": "Midland",
//       "Longitude": 31.9424991607666,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3653,
//       "Airport Code [ICAO]": "KGRB",
//       "Airport Code iATA,FAA": "GRB",
//       "Airport Name": "Austin Straubel International Airport",
//       "Altitude (Ft)": 695,
//       "Country": "United States",
//       "Latitude": -88.12960052490234,
//       "Location": "Green Bay",
//       "Longitude": 44.48509979248047,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3654,
//       "Airport Code [ICAO]": "KADM",
//       "Airport Code iATA,FAA": "ADM",
//       "Airport Name": "Ardmore Municipal Airport",
//       "Altitude (Ft)": 777,
//       "Country": "United States",
//       "Latitude": -97.0196342,
//       "Location": "Ardmore",
//       "Longitude": 34.30301,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3655,
//       "Airport Code [ICAO]": "KWRI",
//       "Airport Code iATA,FAA": "WRI",
//       "Airport Name": "Mc Guire Air Force Base",
//       "Altitude (Ft)": 131,
//       "Country": "United States",
//       "Latitude": -74.59169769,
//       "Location": "Wrightstown",
//       "Longitude": 40.0155983,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3656,
//       "Airport Code [ICAO]": "KNKT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cherry Point MCAS /Cunningham Field/",
//       "Altitude (Ft)": 29,
//       "Country": "United States",
//       "Latitude": -76.88069916,
//       "Location": "Cherry Point",
//       "Longitude": 34.90090179,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3657,
//       "Airport Code [ICAO]": "KSBO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Emanuel County Airport",
//       "Altitude (Ft)": 327,
//       "Country": "United States",
//       "Latitude": -82.36990356445312,
//       "Location": "Santa Barbara",
//       "Longitude": 32.609100341796875,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3658,
//       "Airport Code [ICAO]": "KAGS",
//       "Airport Code iATA,FAA": "AGS",
//       "Airport Name": "Augusta Regional At Bush Field",
//       "Altitude (Ft)": 144,
//       "Country": "United States",
//       "Latitude": -81.9645004272461,
//       "Location": "Bush Field",
//       "Longitude": 33.36989974975586,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3659,
//       "Airport Code [ICAO]": "KISN",
//       "Airport Code iATA,FAA": "ISN",
//       "Airport Name": "Sloulin Field International Airport",
//       "Altitude (Ft)": 1982,
//       "Country": "United States",
//       "Latitude": -103.641998291,
//       "Location": "Williston",
//       "Longitude": 48.177898407,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3660,
//       "Airport Code [ICAO]": "KLIT",
//       "Airport Code iATA,FAA": "LIT",
//       "Airport Name": "Bill & Hillary Clinton National Airport/Adams Field",
//       "Altitude (Ft)": 262,
//       "Country": "United States",
//       "Latitude": -92.2242965698,
//       "Location": "Little Rock",
//       "Longitude": 34.729400634799994,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3661,
//       "Airport Code [ICAO]": "KSWF",
//       "Airport Code iATA,FAA": "SWF",
//       "Airport Name": "Stewart International Airport",
//       "Altitude (Ft)": 491,
//       "Country": "United States",
//       "Latitude": -74.10479736328125,
//       "Location": "Newburgh",
//       "Longitude": 41.50410079956055,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3662,
//       "Airport Code [ICAO]": "KBDE",
//       "Airport Code iATA,FAA": "BDE",
//       "Airport Name": "Baudette International Airport",
//       "Altitude (Ft)": 1086,
//       "Country": "United States",
//       "Latitude": -94.612197876,
//       "Location": "Baudette",
//       "Longitude": 48.7284011841,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3663,
//       "Airport Code [ICAO]": "KSAC",
//       "Airport Code iATA,FAA": "SAC",
//       "Airport Name": "Sacramento Executive Airport",
//       "Altitude (Ft)": 24,
//       "Country": "United States",
//       "Latitude": -121.492996216,
//       "Location": "Sacramento",
//       "Longitude": 38.5125007629,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3664,
//       "Airport Code [ICAO]": "PAHO",
//       "Airport Code iATA,FAA": "HOM",
//       "Airport Name": "Homer Airport",
//       "Altitude (Ft)": 84,
//       "Country": "United States",
//       "Latitude": -151.4770050048828,
//       "Location": "Homer",
//       "Longitude": 59.645599365234375,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3665,
//       "Airport Code [ICAO]": "KTBN",
//       "Airport Code iATA,FAA": "TBN",
//       "Airport Name": "Waynesville-St. Robert Regional Forney field",
//       "Altitude (Ft)": 1159,
//       "Country": "United States",
//       "Latitude": -92.14070129,
//       "Location": "Fort Leonardwood",
//       "Longitude": 37.74160004,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3666,
//       "Airport Code [ICAO]": "KMGE",
//       "Airport Code iATA,FAA": "MGE",
//       "Airport Name": "Dobbins Air Reserve Base",
//       "Altitude (Ft)": 1068,
//       "Country": "United States",
//       "Latitude": -84.51629639,
//       "Location": "Marietta",
//       "Longitude": 33.91540146,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3667,
//       "Airport Code [ICAO]": "KSKA",
//       "Airport Code iATA,FAA": "SKA",
//       "Airport Name": "Fairchild Air Force Base",
//       "Altitude (Ft)": 2461,
//       "Country": "United States",
//       "Latitude": -117.65599823,
//       "Location": "Spokane",
//       "Longitude": 47.6151008606,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3668,
//       "Airport Code [ICAO]": "KHTL",
//       "Airport Code iATA,FAA": "HTL",
//       "Airport Name": "Roscommon County - Blodgett Memorial Airport",
//       "Altitude (Ft)": 1150,
//       "Country": "United States",
//       "Latitude": -84.671095,
//       "Location": "Houghton Lake",
//       "Longitude": 44.359798,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3669,
//       "Airport Code [ICAO]": "KPAM",
//       "Airport Code iATA,FAA": "PAM",
//       "Airport Name": "Tyndall Air Force Base",
//       "Altitude (Ft)": 17,
//       "Country": "United States",
//       "Latitude": -85.57540130619999,
//       "Location": "Panama City",
//       "Longitude": 30.0695991516,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3670,
//       "Airport Code [ICAO]": "KDFW",
//       "Airport Code iATA,FAA": "DFW",
//       "Airport Name": "Dallas Fort Worth International Airport",
//       "Altitude (Ft)": 607,
//       "Country": "United States",
//       "Latitude": -97.038002,
//       "Location": "Dallas-Fort Worth",
//       "Longitude": 32.896801,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3671,
//       "Airport Code [ICAO]": "KMLB",
//       "Airport Code iATA,FAA": "MLB",
//       "Airport Name": "Melbourne International Airport",
//       "Altitude (Ft)": 33,
//       "Country": "United States",
//       "Latitude": -80.64530181884766,
//       "Location": "Melbourne",
//       "Longitude": 28.102800369262695,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3672,
//       "Airport Code [ICAO]": "KTCM",
//       "Airport Code iATA,FAA": "TCM",
//       "Airport Name": "McChord Air Force Base",
//       "Altitude (Ft)": 322,
//       "Country": "United States",
//       "Latitude": -122.475997925,
//       "Location": "Tacoma",
//       "Longitude": 47.1376991272,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3673,
//       "Airport Code [ICAO]": "KAUS",
//       "Airport Code iATA,FAA": "AUS",
//       "Airport Name": "Austin Bergstrom International Airport",
//       "Altitude (Ft)": 542,
//       "Country": "United States",
//       "Latitude": -97.6698989868164,
//       "Location": "Austin",
//       "Longitude": 30.194499969482422,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3674,
//       "Airport Code [ICAO]": "KLCK",
//       "Airport Code iATA,FAA": "LCK",
//       "Airport Name": "Rickenbacker International Airport",
//       "Altitude (Ft)": 744,
//       "Country": "United States",
//       "Latitude": -82.927803,
//       "Location": "Columbus",
//       "Longitude": 39.813801,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3675,
//       "Airport Code [ICAO]": "KSAW",
//       "Airport Code iATA,FAA": "MQT",
//       "Airport Name": "Sawyer International Airport",
//       "Altitude (Ft)": 1221,
//       "Country": "United States",
//       "Latitude": -87.395401001,
//       "Location": "Gwinn",
//       "Longitude": 46.353599548300004,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3676,
//       "Airport Code [ICAO]": "KTYS",
//       "Airport Code iATA,FAA": "TYS",
//       "Airport Name": "McGhee Tyson Airport",
//       "Altitude (Ft)": 981,
//       "Country": "United States",
//       "Latitude": -83.9940033,
//       "Location": "Knoxville",
//       "Longitude": 35.81100082,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3677,
//       "Airport Code [ICAO]": "KHLR",
//       "Airport Code iATA,FAA": "HLR",
//       "Airport Name": "Hood Army Air Field",
//       "Altitude (Ft)": 924,
//       "Country": "United States",
//       "Latitude": -97.71450042720001,
//       "Location": "Fort Hood",
//       "Longitude": 31.138700485199998,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3678,
//       "Airport Code [ICAO]": "KSTL",
//       "Airport Code iATA,FAA": "STL",
//       "Airport Name": "St Louis Lambert International Airport",
//       "Altitude (Ft)": 618,
//       "Country": "United States",
//       "Latitude": -90.370003,
//       "Location": "St. Louis",
//       "Longitude": 38.748697,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3679,
//       "Airport Code [ICAO]": "KMIV",
//       "Airport Code iATA,FAA": "MIV",
//       "Airport Name": "Millville Municipal Airport",
//       "Altitude (Ft)": 85,
//       "Country": "United States",
//       "Latitude": -75.072197,
//       "Location": "Millville",
//       "Longitude": 39.367802,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3680,
//       "Airport Code [ICAO]": "KSPS",
//       "Airport Code iATA,FAA": "SPS",
//       "Airport Name": "Sheppard Air Force Base-Wichita Falls Municipal Airport",
//       "Altitude (Ft)": 1019,
//       "Country": "United States",
//       "Latitude": -98.491898,
//       "Location": "Wichita Falls",
//       "Longitude": 33.9888,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3681,
//       "Airport Code [ICAO]": "KLUK",
//       "Airport Code iATA,FAA": "LUK",
//       "Airport Name": "Cincinnati Municipal Airport Lunken Field",
//       "Altitude (Ft)": 483,
//       "Country": "United States",
//       "Latitude": -84.41860199,
//       "Location": "Cincinnati",
//       "Longitude": 39.10329819,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3682,
//       "Airport Code [ICAO]": "KATL",
//       "Airport Code iATA,FAA": "ATL",
//       "Airport Name": "Hartsfield Jackson Atlanta International Airport",
//       "Altitude (Ft)": 1026,
//       "Country": "United States",
//       "Latitude": -84.428101,
//       "Location": "Atlanta",
//       "Longitude": 33.6367,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3683,
//       "Airport Code [ICAO]": "KMER",
//       "Airport Code iATA,FAA": "MER",
//       "Airport Name": "Castle Airport",
//       "Altitude (Ft)": 191,
//       "Country": "United States",
//       "Latitude": -120.5680008,
//       "Location": "Merced",
//       "Longitude": 37.38050079,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3684,
//       "Airport Code [ICAO]": "KMCC",
//       "Airport Code iATA,FAA": "MCC",
//       "Airport Name": "Mc Clellan Airfield",
//       "Altitude (Ft)": 77,
//       "Country": "United States",
//       "Latitude": -121.401001,
//       "Location": "Sacramento",
//       "Longitude": 38.66759872,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3685,
//       "Airport Code [ICAO]": "KGRR",
//       "Airport Code iATA,FAA": "GRR",
//       "Airport Name": "Gerald R. Ford International Airport",
//       "Altitude (Ft)": 794,
//       "Country": "United States",
//       "Latitude": -85.52279663,
//       "Location": "Grand Rapids",
//       "Longitude": 42.88079834,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3686,
//       "Airport Code [ICAO]": "KINK",
//       "Airport Code iATA,FAA": "INK",
//       "Airport Name": "Winkler County Airport",
//       "Altitude (Ft)": 2822,
//       "Country": "United States",
//       "Latitude": -103.200996399,
//       "Location": "Wink",
//       "Longitude": 31.779600143399996,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3687,
//       "Airport Code [ICAO]": "KFAT",
//       "Airport Code iATA,FAA": "FAT",
//       "Airport Name": "Fresno Yosemite International Airport",
//       "Altitude (Ft)": 336,
//       "Country": "United States",
//       "Latitude": -119.71800231933594,
//       "Location": "Fresno",
//       "Longitude": 36.77619934082031,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3688,
//       "Airport Code [ICAO]": "KVRB",
//       "Airport Code iATA,FAA": "VRB",
//       "Airport Name": "Vero Beach Regional Airport",
//       "Altitude (Ft)": 24,
//       "Country": "United States",
//       "Latitude": -80.417901,
//       "Location": "Vero Beach",
//       "Longitude": 27.6556,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3689,
//       "Airport Code [ICAO]": "KIPL",
//       "Airport Code iATA,FAA": "IPL",
//       "Airport Name": "Imperial County Airport",
//       "Altitude (Ft)": -54,
//       "Country": "United States",
//       "Latitude": -115.57900238,
//       "Location": "Imperial",
//       "Longitude": 32.834201812699995,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3690,
//       "Airport Code [ICAO]": "KBNA",
//       "Airport Code iATA,FAA": "BNA",
//       "Airport Name": "Nashville International Airport",
//       "Altitude (Ft)": 599,
//       "Country": "United States",
//       "Latitude": -86.6781997680664,
//       "Location": "Nashville",
//       "Longitude": 36.1245002746582,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3691,
//       "Airport Code [ICAO]": "KLRD",
//       "Airport Code iATA,FAA": "LRD",
//       "Airport Name": "Laredo International Airport",
//       "Altitude (Ft)": 508,
//       "Country": "United States",
//       "Latitude": -99.46160125732422,
//       "Location": "Laredo",
//       "Longitude": 27.543800354003906,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3692,
//       "Airport Code [ICAO]": "PAED",
//       "Airport Code iATA,FAA": "EDF",
//       "Airport Name": "Elmendorf Air Force Base",
//       "Altitude (Ft)": 212,
//       "Country": "United States",
//       "Latitude": -149.8070068359375,
//       "Location": "Anchorage",
//       "Longitude": 61.250999450683594,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3693,
//       "Airport Code [ICAO]": "PAOT",
//       "Airport Code iATA,FAA": "OTZ",
//       "Airport Name": "Ralph Wien Memorial Airport",
//       "Altitude (Ft)": 14,
//       "Country": "United States",
//       "Latitude": -162.598999,
//       "Location": "Kotzebue",
//       "Longitude": 66.88469696,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3694,
//       "Airport Code [ICAO]": "KAOO",
//       "Airport Code iATA,FAA": "AOO",
//       "Airport Name": "Altoona Blair County Airport",
//       "Altitude (Ft)": 1503,
//       "Country": "United States",
//       "Latitude": -78.31999969,
//       "Location": "Altoona",
//       "Longitude": 40.29639816,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3695,
//       "Airport Code [ICAO]": "KDYS",
//       "Airport Code iATA,FAA": "DYS",
//       "Airport Name": "Dyess Air Force Base",
//       "Altitude (Ft)": 1789,
//       "Country": "United States",
//       "Latitude": -99.854598999,
//       "Location": "Abilene",
//       "Longitude": 32.4207992554,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3696,
//       "Airport Code [ICAO]": "KELD",
//       "Airport Code iATA,FAA": "ELD",
//       "Airport Name": "South Arkansas Regional At Goodwin Field",
//       "Altitude (Ft)": 277,
//       "Country": "United States",
//       "Latitude": -92.81330108642578,
//       "Location": "El Dorado",
//       "Longitude": 33.22100067138672,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3697,
//       "Airport Code [ICAO]": "KLGA",
//       "Airport Code iATA,FAA": "LGA",
//       "Airport Name": "La Guardia Airport",
//       "Altitude (Ft)": 21,
//       "Country": "United States",
//       "Latitude": -73.87259674,
//       "Location": "New York",
//       "Longitude": 40.77719879,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3698,
//       "Airport Code [ICAO]": "KTLH",
//       "Airport Code iATA,FAA": "TLH",
//       "Airport Name": "Tallahassee Regional Airport",
//       "Altitude (Ft)": 81,
//       "Country": "United States",
//       "Latitude": -84.35030364990234,
//       "Location": "Tallahassee",
//       "Longitude": 30.396499633789062,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3699,
//       "Airport Code [ICAO]": "KDPA",
//       "Airport Code iATA,FAA": "DPA",
//       "Airport Name": "Dupage Airport",
//       "Altitude (Ft)": 759,
//       "Country": "United States",
//       "Latitude": -88.24859619,
//       "Location": "West Chicago",
//       "Longitude": 41.90779877,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3700,
//       "Airport Code [ICAO]": "KACT",
//       "Airport Code iATA,FAA": "ACT",
//       "Airport Name": "Waco Regional Airport",
//       "Altitude (Ft)": 516,
//       "Country": "United States",
//       "Latitude": -97.23049926757812,
//       "Location": "Waco",
//       "Longitude": 31.611299514770508,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3701,
//       "Airport Code [ICAO]": "KAUG",
//       "Airport Code iATA,FAA": "AUG",
//       "Airport Name": "Augusta State Airport",
//       "Altitude (Ft)": 352,
//       "Country": "United States",
//       "Latitude": -69.7973022461,
//       "Location": "Augusta",
//       "Longitude": 44.320598602299995,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3702,
//       "Airport Code [ICAO]": "KINJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hillsboro Municipal Airport",
//       "Altitude (Ft)": 686,
//       "Country": "United States",
//       "Latitude": -97.09719849,
//       "Location": "Hillsboro",
//       "Longitude": 32.08349991,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3703,
//       "Airport Code [ICAO]": "KNIP",
//       "Airport Code iATA,FAA": "NIP",
//       "Airport Name": "Jacksonville Naval Air Station (Towers Field)",
//       "Altitude (Ft)": 21,
//       "Country": "United States",
//       "Latitude": -81.680603,
//       "Location": "Jacksonville",
//       "Longitude": 30.2358,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3704,
//       "Airport Code [ICAO]": "KMKL",
//       "Airport Code iATA,FAA": "MKL",
//       "Airport Name": "McKellar-Sipes Regional Airport",
//       "Altitude (Ft)": 434,
//       "Country": "United States",
//       "Latitude": -88.915604,
//       "Location": "Jackson",
//       "Longitude": 35.599899,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3705,
//       "Airport Code [ICAO]": "PHMK",
//       "Airport Code iATA,FAA": "MKK",
//       "Airport Name": "Molokai Airport",
//       "Altitude (Ft)": 454,
//       "Country": "United States",
//       "Latitude": -157.0959930419922,
//       "Location": "Molokai",
//       "Longitude": 21.15290069580078,
//       "GMT Offset": "-10",
//       "Daylight Savings": "N",
//       "Region": "Pacific/Honolulu",
//       "Type": "airport"
//     },
//     {
//       "ID": 3706,
//       "Airport Code [ICAO]": "KFTK",
//       "Airport Code iATA,FAA": "FTK",
//       "Airport Name": "Godman Army Air Field",
//       "Altitude (Ft)": 756,
//       "Country": "United States",
//       "Latitude": -85.9720993042,
//       "Location": "Fort Knox",
//       "Longitude": 37.907100677500004,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3707,
//       "Airport Code [ICAO]": "KNCA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "New River MCAS /H/ /Mccutcheon Fld/ Airport",
//       "Altitude (Ft)": 26,
//       "Country": "United States",
//       "Latitude": -77.43969727,
//       "Location": "Jacksonville",
//       "Longitude": 34.70840073,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3708,
//       "Airport Code [ICAO]": "KSJT",
//       "Airport Code iATA,FAA": "SJT",
//       "Airport Name": "San Angelo Regional Mathis Field",
//       "Altitude (Ft)": 1919,
//       "Country": "United States",
//       "Latitude": -100.49600219726562,
//       "Location": "San Angelo",
//       "Longitude": 31.35770034790039,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3709,
//       "Airport Code [ICAO]": "KCXL",
//       "Airport Code iATA,FAA": "CXL",
//       "Airport Name": "Calexico International Airport",
//       "Altitude (Ft)": 4,
//       "Country": "United States",
//       "Latitude": -115.513000488,
//       "Location": "Calexico",
//       "Longitude": 32.6694984436,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3710,
//       "Airport Code [ICAO]": "KCIC",
//       "Airport Code iATA,FAA": "CIC",
//       "Airport Name": "Chico Municipal Airport",
//       "Altitude (Ft)": 240,
//       "Country": "United States",
//       "Latitude": -121.8580017,
//       "Location": "Chico",
//       "Longitude": 39.79539871,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3711,
//       "Airport Code [ICAO]": "KBTV",
//       "Airport Code iATA,FAA": "BTV",
//       "Airport Name": "Burlington International Airport",
//       "Altitude (Ft)": 335,
//       "Country": "United States",
//       "Latitude": -73.15329742429999,
//       "Location": "Burlington",
//       "Longitude": 44.471900939899996,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3712,
//       "Airport Code [ICAO]": "KJAX",
//       "Airport Code iATA,FAA": "JAX",
//       "Airport Name": "Jacksonville International Airport",
//       "Altitude (Ft)": 30,
//       "Country": "United States",
//       "Latitude": -81.68789672851562,
//       "Location": "Jacksonville",
//       "Longitude": 30.49410057067871,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3713,
//       "Airport Code [ICAO]": "KDRO",
//       "Airport Code iATA,FAA": "DRO",
//       "Airport Name": "Durango La Plata County Airport",
//       "Altitude (Ft)": 6685,
//       "Country": "United States",
//       "Latitude": -107.753997803,
//       "Location": "Durango",
//       "Longitude": 37.1515007019,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3714,
//       "Airport Code [ICAO]": "KIAD",
//       "Airport Code iATA,FAA": "IAD",
//       "Airport Name": "Washington Dulles International Airport",
//       "Altitude (Ft)": 312,
//       "Country": "United States",
//       "Latitude": -77.45580292,
//       "Location": "Washington",
//       "Longitude": 38.94449997,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3715,
//       "Airport Code [ICAO]": "KCLL",
//       "Airport Code iATA,FAA": "CLL",
//       "Airport Name": "Easterwood Field",
//       "Altitude (Ft)": 320,
//       "Country": "United States",
//       "Latitude": -96.36380005,
//       "Location": "College Station",
//       "Longitude": 30.58860016,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3716,
//       "Airport Code [ICAO]": "KSFF",
//       "Airport Code iATA,FAA": "SFF",
//       "Airport Name": "Felts Field",
//       "Altitude (Ft)": 1953,
//       "Country": "United States",
//       "Latitude": -117.32299804688,
//       "Location": "Spokane",
//       "Longitude": 47.682800292969,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3717,
//       "Airport Code [ICAO]": "KMKE",
//       "Airport Code iATA,FAA": "MKE",
//       "Airport Name": "General Mitchell International Airport",
//       "Altitude (Ft)": 723,
//       "Country": "United States",
//       "Latitude": -87.89659881591797,
//       "Location": "Milwaukee",
//       "Longitude": 42.947200775146484,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3718,
//       "Airport Code [ICAO]": "KABI",
//       "Airport Code iATA,FAA": "ABI",
//       "Airport Name": "Abilene Regional Airport",
//       "Altitude (Ft)": 1791,
//       "Country": "United States",
//       "Latitude": -99.68190002440001,
//       "Location": "Abilene",
//       "Longitude": 32.4113006592,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3719,
//       "Airport Code [ICAO]": "KCOU",
//       "Airport Code iATA,FAA": "COU",
//       "Airport Name": "Columbia Regional Airport",
//       "Altitude (Ft)": 889,
//       "Country": "United States",
//       "Latitude": -92.21959686279297,
//       "Location": "Columbia",
//       "Longitude": 38.81809997558594,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3720,
//       "Airport Code [ICAO]": "KPDX",
//       "Airport Code iATA,FAA": "PDX",
//       "Airport Name": "Portland International Airport",
//       "Altitude (Ft)": 31,
//       "Country": "United States",
//       "Latitude": -122.5979996,
//       "Location": "Portland",
//       "Longitude": 45.58869934,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3721,
//       "Airport Code [ICAO]": "KTNT",
//       "Airport Code iATA,FAA": "TNT",
//       "Airport Name": "Dade Collier Training and Transition Airport",
//       "Altitude (Ft)": 13,
//       "Country": "United States",
//       "Latitude": -80.897003173828,
//       "Location": "Miami",
//       "Longitude": 25.861799240112,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3722,
//       "Airport Code [ICAO]": "KPBI",
//       "Airport Code iATA,FAA": "PBI",
//       "Airport Name": "Palm Beach International Airport",
//       "Altitude (Ft)": 19,
//       "Country": "United States",
//       "Latitude": -80.09559631347656,
//       "Location": "West Palm Beach",
//       "Longitude": 26.68320083618164,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3723,
//       "Airport Code [ICAO]": "KFTW",
//       "Airport Code iATA,FAA": "FTW",
//       "Airport Name": "Fort Worth Meacham International Airport",
//       "Altitude (Ft)": 710,
//       "Country": "United States",
//       "Latitude": -97.362396,
//       "Location": "Fort Worth",
//       "Longitude": 32.819801,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3724,
//       "Airport Code [ICAO]": "KOGS",
//       "Airport Code iATA,FAA": "OGS",
//       "Airport Name": "Ogdensburg International Airport",
//       "Altitude (Ft)": 297,
//       "Country": "United States",
//       "Latitude": -75.46549987790002,
//       "Location": "Ogdensburg",
//       "Longitude": 44.6819000244,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3725,
//       "Airport Code [ICAO]": "KFMH",
//       "Airport Code iATA,FAA": "FMH",
//       "Airport Name": "Cape Cod Coast Guard Air Station",
//       "Altitude (Ft)": 131,
//       "Country": "United States",
//       "Latitude": -70.5214004517,
//       "Location": "Falmouth",
//       "Longitude": 41.6584014893,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3726,
//       "Airport Code [ICAO]": "KBFI",
//       "Airport Code iATA,FAA": "BFI",
//       "Airport Name": "Boeing Field King County International Airport",
//       "Altitude (Ft)": 21,
//       "Country": "United States",
//       "Latitude": -122.302001953125,
//       "Location": "Seattle",
//       "Longitude": 47.529998779296875,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3727,
//       "Airport Code [ICAO]": "KSKF",
//       "Airport Code iATA,FAA": "SKF",
//       "Airport Name": "Lackland Air Force Base",
//       "Altitude (Ft)": 691,
//       "Country": "United States",
//       "Latitude": -98.58110046,
//       "Location": "San Antonio",
//       "Longitude": 29.38419914,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3728,
//       "Airport Code [ICAO]": "PHNL",
//       "Airport Code iATA,FAA": "HNL",
//       "Airport Name": "Daniel K Inouye International Airport",
//       "Altitude (Ft)": 13,
//       "Country": "United States",
//       "Latitude": -157.924228,
//       "Location": "Honolulu",
//       "Longitude": 21.32062,
//       "GMT Offset": "-10",
//       "Daylight Savings": "N",
//       "Region": "Pacific/Honolulu",
//       "Type": "airport"
//     },
//     {
//       "ID": 3729,
//       "Airport Code [ICAO]": "KDSM",
//       "Airport Code iATA,FAA": "DSM",
//       "Airport Name": "Des Moines International Airport",
//       "Altitude (Ft)": 958,
//       "Country": "United States",
//       "Latitude": -93.66310119628906,
//       "Location": "Des Moines",
//       "Longitude": 41.534000396728516,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3730,
//       "Airport Code [ICAO]": "KEWN",
//       "Airport Code iATA,FAA": "EWN",
//       "Airport Name": "Coastal Carolina Regional Airport",
//       "Altitude (Ft)": 18,
//       "Country": "United States",
//       "Latitude": -77.04290008539999,
//       "Location": "New Bern",
//       "Longitude": 35.0730018616,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3731,
//       "Airport Code [ICAO]": "KSAN",
//       "Airport Code iATA,FAA": "SAN",
//       "Airport Name": "San Diego International Airport",
//       "Altitude (Ft)": 17,
//       "Country": "United States",
//       "Latitude": -117.190002441,
//       "Location": "San Diego",
//       "Longitude": 32.7336006165,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3732,
//       "Airport Code [ICAO]": "KMLU",
//       "Airport Code iATA,FAA": "MLU",
//       "Airport Name": "Monroe Regional Airport",
//       "Altitude (Ft)": 79,
//       "Country": "United States",
//       "Latitude": -92.0376968383789,
//       "Location": "Monroe",
//       "Longitude": 32.51089859008789,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3733,
//       "Airport Code [ICAO]": "KSSC",
//       "Airport Code iATA,FAA": "SSC",
//       "Airport Name": "Shaw Air Force Base",
//       "Altitude (Ft)": 241,
//       "Country": "United States",
//       "Latitude": -80.47059631,
//       "Location": "Sumter",
//       "Longitude": 33.97269821,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3734,
//       "Airport Code [ICAO]": "KONT",
//       "Airport Code iATA,FAA": "ONT",
//       "Airport Name": "Ontario International Airport",
//       "Altitude (Ft)": 944,
//       "Country": "United States",
//       "Latitude": -117.60099792480469,
//       "Location": "Ontario",
//       "Longitude": 34.055999755859375,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3735,
//       "Airport Code [ICAO]": "KGVT",
//       "Airport Code iATA,FAA": "GVT",
//       "Airport Name": "Majors Airport",
//       "Altitude (Ft)": 535,
//       "Country": "United States",
//       "Latitude": -96.0652999878,
//       "Location": "Greenvile",
//       "Longitude": 33.0677986145,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3736,
//       "Airport Code [ICAO]": "KROW",
//       "Airport Code iATA,FAA": "ROW",
//       "Airport Name": "Roswell International Air Center Airport",
//       "Altitude (Ft)": 3671,
//       "Country": "United States",
//       "Latitude": -104.53099822998047,
//       "Location": "Roswell",
//       "Longitude": 33.30160140991211,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3737,
//       "Airport Code [ICAO]": "KDET",
//       "Airport Code iATA,FAA": "DET",
//       "Airport Name": "Coleman A. Young Municipal Airport",
//       "Altitude (Ft)": 626,
//       "Country": "United States",
//       "Latitude": -83.00990295,
//       "Location": "Detroit",
//       "Longitude": 42.40919876,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3738,
//       "Airport Code [ICAO]": "KBRO",
//       "Airport Code iATA,FAA": "BRO",
//       "Airport Name": "Brownsville South Padre Island International Airport",
//       "Altitude (Ft)": 22,
//       "Country": "United States",
//       "Latitude": -97.4259033203125,
//       "Location": "Brownsville",
//       "Longitude": 25.90679931640625,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3739,
//       "Airport Code [ICAO]": "KDHN",
//       "Airport Code iATA,FAA": "DHN",
//       "Airport Name": "Dothan Regional Airport",
//       "Altitude (Ft)": 401,
//       "Country": "United States",
//       "Latitude": -85.44960021972656,
//       "Location": "Dothan",
//       "Longitude": 31.321300506591797,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3740,
//       "Airport Code [ICAO]": "KWWD",
//       "Airport Code iATA,FAA": "WWD",
//       "Airport Name": "Cape May County Airport",
//       "Altitude (Ft)": 23,
//       "Country": "United States",
//       "Latitude": -74.9083023071,
//       "Location": "Wildwood",
//       "Longitude": 39.008499145500004,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3741,
//       "Airport Code [ICAO]": "KNFL",
//       "Airport Code iATA,FAA": "NFL",
//       "Airport Name": "Fallon Naval Air Station",
//       "Altitude (Ft)": 3934,
//       "Country": "United States",
//       "Latitude": -118.7009964,
//       "Location": "Fallon",
//       "Longitude": 39.41659927,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3742,
//       "Airport Code [ICAO]": "KMTC",
//       "Airport Code iATA,FAA": "MTC",
//       "Airport Name": "Selfridge Air National Guard Base Airport",
//       "Altitude (Ft)": 580,
//       "Country": "United States",
//       "Latitude": -82.836919,
//       "Location": "Mount Clemens",
//       "Longitude": 42.613463,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3743,
//       "Airport Code [ICAO]": "KFMN",
//       "Airport Code iATA,FAA": "FMN",
//       "Airport Name": "Four Corners Regional Airport",
//       "Altitude (Ft)": 5506,
//       "Country": "United States",
//       "Latitude": -108.230003357,
//       "Location": "Farmington",
//       "Longitude": 36.741199493399996,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3744,
//       "Airport Code [ICAO]": "KCRP",
//       "Airport Code iATA,FAA": "CRP",
//       "Airport Name": "Corpus Christi International Airport",
//       "Altitude (Ft)": 44,
//       "Country": "United States",
//       "Latitude": -97.5011978149414,
//       "Location": "Corpus Christi",
//       "Longitude": 27.77039909362793,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3745,
//       "Airport Code [ICAO]": "KSYR",
//       "Airport Code iATA,FAA": "SYR",
//       "Airport Name": "Syracuse Hancock International Airport",
//       "Altitude (Ft)": 421,
//       "Country": "United States",
//       "Latitude": -76.1063003540039,
//       "Location": "Syracuse",
//       "Longitude": 43.11119842529297,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3746,
//       "Airport Code [ICAO]": "KNQX",
//       "Airport Code iATA,FAA": "NQX",
//       "Airport Name": "Naval Air Station Key West/Boca Chica Field",
//       "Altitude (Ft)": 6,
//       "Country": "United States",
//       "Latitude": -81.68890381,
//       "Location": "Key West",
//       "Longitude": 24.57579994,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3747,
//       "Airport Code [ICAO]": "KMDW",
//       "Airport Code iATA,FAA": "MDW",
//       "Airport Name": "Chicago Midway International Airport",
//       "Altitude (Ft)": 620,
//       "Country": "United States",
//       "Latitude": -87.752403,
//       "Location": "Chicago",
//       "Longitude": 41.785999,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3748,
//       "Airport Code [ICAO]": "KSJC",
//       "Airport Code iATA,FAA": "SJC",
//       "Airport Name": "Norman Y. Mineta San Jose International Airport",
//       "Altitude (Ft)": 62,
//       "Country": "United States",
//       "Latitude": -121.929001,
//       "Location": "San Jose",
//       "Longitude": 37.362598,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3749,
//       "Airport Code [ICAO]": "KHOB",
//       "Airport Code iATA,FAA": "HOB",
//       "Airport Name": "Lea County Regional Airport",
//       "Altitude (Ft)": 3661,
//       "Country": "United States",
//       "Latitude": -103.2170029,
//       "Location": "Hobbs",
//       "Longitude": 32.6875,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3750,
//       "Airport Code [ICAO]": "KPNE",
//       "Airport Code iATA,FAA": "PNE",
//       "Airport Name": "Northeast Philadelphia Airport",
//       "Altitude (Ft)": 120,
//       "Country": "United States",
//       "Latitude": -75.010597,
//       "Location": "Philadelphia",
//       "Longitude": 40.081902,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3751,
//       "Airport Code [ICAO]": "KDEN",
//       "Airport Code iATA,FAA": "DEN",
//       "Airport Name": "Denver International Airport",
//       "Altitude (Ft)": 5431,
//       "Country": "United States",
//       "Latitude": -104.672996521,
//       "Location": "Denver",
//       "Longitude": 39.861698150635,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3752,
//       "Airport Code [ICAO]": "KPHL",
//       "Airport Code iATA,FAA": "PHL",
//       "Airport Name": "Philadelphia International Airport",
//       "Altitude (Ft)": 36,
//       "Country": "United States",
//       "Latitude": -75.24109649658203,
//       "Location": "Philadelphia",
//       "Longitude": 39.87189865112305,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3753,
//       "Airport Code [ICAO]": "KSUX",
//       "Airport Code iATA,FAA": "SUX",
//       "Airport Name": "Sioux Gateway Col. Bud Day Field",
//       "Altitude (Ft)": 1098,
//       "Country": "United States",
//       "Latitude": -96.38439941,
//       "Location": "Sioux City",
//       "Longitude": 42.40259933,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3754,
//       "Airport Code [ICAO]": "KMCN",
//       "Airport Code iATA,FAA": "MCN",
//       "Airport Name": "Middle Georgia Regional Airport",
//       "Altitude (Ft)": 354,
//       "Country": "United States",
//       "Latitude": -83.64920043945312,
//       "Location": "Macon",
//       "Longitude": 32.69279861450195,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3755,
//       "Airport Code [ICAO]": "KTCS",
//       "Airport Code iATA,FAA": "TCS",
//       "Airport Name": "Truth Or Consequences Municipal Airport",
//       "Altitude (Ft)": 4853,
//       "Country": "United States",
//       "Latitude": -107.272003174,
//       "Location": "Truth Or Consequences",
//       "Longitude": 33.2369003296,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3756,
//       "Airport Code [ICAO]": "KPMD",
//       "Airport Code iATA,FAA": "PMD",
//       "Airport Name": "Palmdale Regional/USAF Plant 42 Airport",
//       "Altitude (Ft)": 2543,
//       "Country": "United States",
//       "Latitude": -118.0849991,
//       "Location": "Palmdale",
//       "Longitude": 34.62939835,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3757,
//       "Airport Code [ICAO]": "KRND",
//       "Airport Code iATA,FAA": "RND",
//       "Airport Name": "Randolph Air Force Base",
//       "Altitude (Ft)": 761,
//       "Country": "United States",
//       "Latitude": -98.27890015,
//       "Location": "San Antonio",
//       "Longitude": 29.52969933,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3758,
//       "Airport Code [ICAO]": "KNJK",
//       "Airport Code iATA,FAA": "NJK",
//       "Airport Name": "El Centro NAF Airport (Vraciu Field)",
//       "Altitude (Ft)": -42,
//       "Country": "United States",
//       "Latitude": -115.671996,
//       "Location": "El Centro",
//       "Longitude": 32.829201,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3759,
//       "Airport Code [ICAO]": "KCMH",
//       "Airport Code iATA,FAA": "CMH",
//       "Airport Name": "John Glenn Columbus International Airport",
//       "Altitude (Ft)": 815,
//       "Country": "United States",
//       "Latitude": -82.891899,
//       "Location": "Columbus",
//       "Longitude": 39.998001,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3760,
//       "Airport Code [ICAO]": "KFYV",
//       "Airport Code iATA,FAA": "FYV",
//       "Airport Name": "Drake Field",
//       "Altitude (Ft)": 1251,
//       "Country": "United States",
//       "Latitude": -94.17009735107422,
//       "Location": "Fayetteville",
//       "Longitude": 36.00510025024414,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3761,
//       "Airport Code [ICAO]": "KFSI",
//       "Airport Code iATA,FAA": "FSI",
//       "Airport Name": "Henry Post Army Air Field (Fort Sill)",
//       "Altitude (Ft)": 1189,
//       "Country": "United States",
//       "Latitude": -98.40219879,
//       "Location": "Fort Sill",
//       "Longitude": 34.64979935,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3762,
//       "Airport Code [ICAO]": "KPNM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Princeton Municipal Airport",
//       "Altitude (Ft)": 980,
//       "Country": "United States",
//       "Latitude": -93.60820007,
//       "Location": "Princeton",
//       "Longitude": 45.55989838,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3763,
//       "Airport Code [ICAO]": "KFFO",
//       "Airport Code iATA,FAA": "FFO",
//       "Airport Name": "Wright-Patterson Air Force Base",
//       "Altitude (Ft)": 823,
//       "Country": "United States",
//       "Latitude": -84.0483016968,
//       "Location": "Dayton",
//       "Longitude": 39.8260993958,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3764,
//       "Airport Code [ICAO]": "PAGA",
//       "Airport Code iATA,FAA": "GAL",
//       "Airport Name": "Edward G. Pitka Sr Airport",
//       "Altitude (Ft)": 153,
//       "Country": "United States",
//       "Latitude": -156.9369965,
//       "Location": "Galena",
//       "Longitude": 64.73619843,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3765,
//       "Airport Code [ICAO]": "KCHD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Chandler Municipal Airport",
//       "Altitude (Ft)": 1243,
//       "Country": "United States",
//       "Latitude": -111.810997,
//       "Location": "Chandler",
//       "Longitude": 33.2691,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 3766,
//       "Airport Code [ICAO]": "KMWL",
//       "Airport Code iATA,FAA": "MWL",
//       "Airport Name": "Mineral Wells Airport",
//       "Altitude (Ft)": 974,
//       "Country": "United States",
//       "Latitude": -98.0602035522,
//       "Location": "Mineral Wells",
//       "Longitude": 32.7816009521,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3767,
//       "Airport Code [ICAO]": "KIAB",
//       "Airport Code iATA,FAA": "IAB",
//       "Airport Name": "Mc Connell Air Force Base",
//       "Altitude (Ft)": 1371,
//       "Country": "United States",
//       "Latitude": -97.26820374,
//       "Location": "Wichita",
//       "Longitude": 37.62189865,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3768,
//       "Airport Code [ICAO]": "KNBG",
//       "Airport Code iATA,FAA": "NBG",
//       "Airport Name": "New Orleans NAS JRB/Alvin Callender Field",
//       "Altitude (Ft)": 2,
//       "Country": "United States",
//       "Latitude": -90.03500366,
//       "Location": "New Orleans",
//       "Longitude": 29.82530022,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3769,
//       "Airport Code [ICAO]": "KARW",
//       "Airport Code iATA,FAA": "BFT",
//       "Airport Name": "Beaufort County Airport",
//       "Altitude (Ft)": 10,
//       "Country": "United States",
//       "Latitude": -80.6343994141,
//       "Location": "Beaufort",
//       "Longitude": 32.4122009277,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3770,
//       "Airport Code [ICAO]": "KTXK",
//       "Airport Code iATA,FAA": "TXK",
//       "Airport Name": "Texarkana Regional Webb Field",
//       "Altitude (Ft)": 390,
//       "Country": "United States",
//       "Latitude": -93.99099731445312,
//       "Location": "Texarkana",
//       "Longitude": 33.45370101928711,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3771,
//       "Airport Code [ICAO]": "KPBG",
//       "Airport Code iATA,FAA": "PBG",
//       "Airport Name": "Plattsburgh International Airport",
//       "Altitude (Ft)": 234,
//       "Country": "United States",
//       "Latitude": -73.46810150146484,
//       "Location": "Plattsburgh",
//       "Longitude": 44.650901794433594,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3772,
//       "Airport Code [ICAO]": "KAPG",
//       "Airport Code iATA,FAA": "APG",
//       "Airport Name": "Phillips Army Air Field",
//       "Altitude (Ft)": 57,
//       "Country": "United States",
//       "Latitude": -76.1688,
//       "Location": "Aberdeen",
//       "Longitude": 39.466202,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3773,
//       "Airport Code [ICAO]": "KTCC",
//       "Airport Code iATA,FAA": "TCC",
//       "Airport Name": "Tucumcari Municipal Airport",
//       "Altitude (Ft)": 4065,
//       "Country": "United States",
//       "Latitude": -103.602996826,
//       "Location": "Tucumcari",
//       "Longitude": 35.182800293,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3774,
//       "Airport Code [ICAO]": "PANC",
//       "Airport Code iATA,FAA": "ANC",
//       "Airport Name": "Ted Stevens Anchorage International Airport",
//       "Altitude (Ft)": 152,
//       "Country": "United States",
//       "Latitude": -149.99600219726562,
//       "Location": "Anchorage",
//       "Longitude": 61.174400329589844,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3775,
//       "Airport Code [ICAO]": "KGRK",
//       "Airport Code iATA,FAA": "GRK",
//       "Airport Name": "Robert Gray  Army Air Field Airport",
//       "Altitude (Ft)": 1015,
//       "Country": "United States",
//       "Latitude": -97.82890319820001,
//       "Location": "Killeen",
//       "Longitude": 31.067199707,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3776,
//       "Airport Code [ICAO]": "KZUN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Black Rock Airport",
//       "Altitude (Ft)": 6454,
//       "Country": "United States",
//       "Latitude": -108.79199981689453,
//       "Location": "Zuni Pueblo",
//       "Longitude": 35.08319854736328,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3777,
//       "Airport Code [ICAO]": "KBLI",
//       "Airport Code iATA,FAA": "BLI",
//       "Airport Name": "Bellingham International Airport",
//       "Altitude (Ft)": 170,
//       "Country": "United States",
//       "Latitude": -122.53800201416016,
//       "Location": "Bellingham",
//       "Longitude": 48.79280090332031,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3778,
//       "Airport Code [ICAO]": "KNQA",
//       "Airport Code iATA,FAA": "NQA",
//       "Airport Name": "Millington-Memphis Airport",
//       "Altitude (Ft)": 320,
//       "Country": "United States",
//       "Latitude": -89.8703,
//       "Location": "Millington",
//       "Longitude": 35.356701,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3779,
//       "Airport Code [ICAO]": "KEKN",
//       "Airport Code iATA,FAA": "EKN",
//       "Airport Name": "Elkins-Randolph Co-Jennings Randolph Field",
//       "Altitude (Ft)": 1987,
//       "Country": "United States",
//       "Latitude": -79.85710144,
//       "Location": "Elkins",
//       "Longitude": 38.88940048,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3780,
//       "Airport Code [ICAO]": "KHFD",
//       "Airport Code iATA,FAA": "HFD",
//       "Airport Name": "Hartford Brainard Airport",
//       "Altitude (Ft)": 18,
//       "Country": "United States",
//       "Latitude": -72.649398803711,
//       "Location": "Hartford",
//       "Longitude": 41.736698150635,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3781,
//       "Airport Code [ICAO]": "KSFZ",
//       "Airport Code iATA,FAA": "SFZ",
//       "Airport Name": "North Central State Airport",
//       "Altitude (Ft)": 441,
//       "Country": "United States",
//       "Latitude": -71.49140167239999,
//       "Location": "Smithfield",
//       "Longitude": 41.9207992554,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3782,
//       "Airport Code [ICAO]": "KMOB",
//       "Airport Code iATA,FAA": "MOB",
//       "Airport Name": "Mobile Regional Airport",
//       "Altitude (Ft)": 219,
//       "Country": "United States",
//       "Latitude": -88.242797851562,
//       "Location": "Mobile",
//       "Longitude": 30.691200256348,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3783,
//       "Airport Code [ICAO]": "KNUQ",
//       "Airport Code iATA,FAA": "NUQ",
//       "Airport Name": "Moffett Federal Airfield",
//       "Altitude (Ft)": 32,
//       "Country": "United States",
//       "Latitude": -122.049004,
//       "Location": "Mountain View",
//       "Longitude": 37.4161,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3784,
//       "Airport Code [ICAO]": "KSAF",
//       "Airport Code iATA,FAA": "SAF",
//       "Airport Name": "Santa Fe Municipal Airport",
//       "Altitude (Ft)": 6348,
//       "Country": "United States",
//       "Latitude": -106.088996887,
//       "Location": "Santa Fe",
//       "Longitude": 35.617099762,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3785,
//       "Airport Code [ICAO]": "PHBK",
//       "Airport Code iATA,FAA": "BKH",
//       "Airport Name": "Barking Sands Airport",
//       "Altitude (Ft)": 23,
//       "Country": "United States",
//       "Latitude": -159.785003662,
//       "Location": "Barking Sands",
//       "Longitude": 22.022800445599998,
//       "GMT Offset": "-10",
//       "Daylight Savings": "A",
//       "Region": "Pacific/Honolulu",
//       "Type": "airport"
//     },
//     {
//       "ID": 3786,
//       "Airport Code [ICAO]": "KDRI",
//       "Airport Code iATA,FAA": "DRI",
//       "Airport Name": "Beauregard Regional Airport",
//       "Altitude (Ft)": 202,
//       "Country": "United States",
//       "Latitude": -93.33989715579999,
//       "Location": "Deridder",
//       "Longitude": 30.8316993713,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3787,
//       "Airport Code [ICAO]": "PHSF",
//       "Airport Code iATA,FAA": "BSF",
//       "Airport Name": "Bradshaw Army Airfield",
//       "Altitude (Ft)": 6190,
//       "Country": "United States",
//       "Latitude": -155.554000854,
//       "Location": "Bradshaw Field",
//       "Longitude": 19.760099411,
//       "GMT Offset": "-10",
//       "Daylight Savings": "A",
//       "Region": "Pacific/Honolulu",
//       "Type": "airport"
//     },
//     {
//       "ID": 3788,
//       "Airport Code [ICAO]": "KOLS",
//       "Airport Code iATA,FAA": "OLS",
//       "Airport Name": "Nogales International Airport",
//       "Altitude (Ft)": 3955,
//       "Country": "United States",
//       "Latitude": -110.848,
//       "Location": "Nogales",
//       "Longitude": 31.4177,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 3789,
//       "Airport Code [ICAO]": "KMCF",
//       "Airport Code iATA,FAA": "MCF",
//       "Airport Name": "Mac Dill Air Force Base",
//       "Altitude (Ft)": 14,
//       "Country": "United States",
//       "Latitude": -82.52120209,
//       "Location": "Tampa",
//       "Longitude": 27.84930038,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3790,
//       "Airport Code [ICAO]": "KBLV",
//       "Airport Code iATA,FAA": "BLV",
//       "Airport Name": "Scott AFB/Midamerica Airport",
//       "Altitude (Ft)": 459,
//       "Country": "United States",
//       "Latitude": -89.835197,
//       "Location": "Belleville",
//       "Longitude": 38.5452,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3791,
//       "Airport Code [ICAO]": "KOPF",
//       "Airport Code iATA,FAA": "OPF",
//       "Airport Name": "Opa-locka Executive Airport",
//       "Altitude (Ft)": 8,
//       "Country": "United States",
//       "Latitude": -80.278397,
//       "Location": "Miami",
//       "Longitude": 25.907,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3792,
//       "Airport Code [ICAO]": "KDRT",
//       "Airport Code iATA,FAA": "DRT",
//       "Airport Name": "Del Rio International Airport",
//       "Altitude (Ft)": 1002,
//       "Country": "United States",
//       "Latitude": -100.927001953,
//       "Location": "Del Rio",
//       "Longitude": 29.3742008209,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3793,
//       "Airport Code [ICAO]": "KRSW",
//       "Airport Code iATA,FAA": "RSW",
//       "Airport Name": "Southwest Florida International Airport",
//       "Altitude (Ft)": 30,
//       "Country": "United States",
//       "Latitude": -81.75520324707031,
//       "Location": "Fort Myers",
//       "Longitude": 26.53619956970215,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3794,
//       "Airport Code [ICAO]": "PAKN",
//       "Airport Code iATA,FAA": "AKN",
//       "Airport Name": "King Salmon Airport",
//       "Altitude (Ft)": 73,
//       "Country": "United States",
//       "Latitude": -156.6490021,
//       "Location": "King Salmon",
//       "Longitude": 58.67679977,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3795,
//       "Airport Code [ICAO]": "KMUI",
//       "Airport Code iATA,FAA": "MUI",
//       "Airport Name": "Muir Army Air Field (Fort Indiantown Gap) Airport",
//       "Altitude (Ft)": 488,
//       "Country": "United States",
//       "Latitude": -76.569397,
//       "Location": "Muir",
//       "Longitude": 40.434799,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3796,
//       "Airport Code [ICAO]": "PHJH",
//       "Airport Code iATA,FAA": "JHM",
//       "Airport Name": "Kapalua Airport",
//       "Altitude (Ft)": 256,
//       "Country": "United States",
//       "Latitude": -156.67300415039062,
//       "Location": "Lahania-kapalua",
//       "Longitude": 20.962900161743164,
//       "GMT Offset": "-10",
//       "Daylight Savings": "N",
//       "Region": "Pacific/Honolulu",
//       "Type": "airport"
//     },
//     {
//       "ID": 3797,
//       "Airport Code [ICAO]": "KJFK",
//       "Airport Code iATA,FAA": "JFK",
//       "Airport Name": "John F Kennedy International Airport",
//       "Altitude (Ft)": 13,
//       "Country": "United States",
//       "Latitude": -73.77890015,
//       "Location": "New York",
//       "Longitude": 40.63980103,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3798,
//       "Airport Code [ICAO]": "KHST",
//       "Airport Code iATA,FAA": "HST",
//       "Airport Name": "Homestead ARB Airport",
//       "Altitude (Ft)": 5,
//       "Country": "United States",
//       "Latitude": -80.38359833,
//       "Location": "Homestead",
//       "Longitude": 25.48859978,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3799,
//       "Airport Code [ICAO]": "KRAL",
//       "Airport Code iATA,FAA": "RAL",
//       "Airport Name": "Riverside Municipal Airport",
//       "Altitude (Ft)": 819,
//       "Country": "United States",
//       "Latitude": -117.4449997,
//       "Location": "Riverside",
//       "Longitude": 33.95190048,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3800,
//       "Airport Code [ICAO]": "KFLV",
//       "Airport Code iATA,FAA": "FLV",
//       "Airport Name": "Sherman Army Air Field",
//       "Altitude (Ft)": 772,
//       "Country": "United States",
//       "Latitude": -94.9147033691,
//       "Location": "Fort Leavenworth",
//       "Longitude": 39.3683013916,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3801,
//       "Airport Code [ICAO]": "KWAL",
//       "Airport Code iATA,FAA": "WAL",
//       "Airport Name": "Wallops Flight Facility Airport",
//       "Altitude (Ft)": 40,
//       "Country": "United States",
//       "Latitude": -75.4664001465,
//       "Location": "Wallops Island",
//       "Longitude": 37.9402008057,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3802,
//       "Airport Code [ICAO]": "KHMN",
//       "Airport Code iATA,FAA": "HMN",
//       "Airport Name": "Holloman Air Force Base",
//       "Altitude (Ft)": 4093,
//       "Country": "United States",
//       "Latitude": -106.107002258,
//       "Location": "Alamogordo",
//       "Longitude": 32.8525009155,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3803,
//       "Airport Code [ICAO]": "KNXX",
//       "Airport Code iATA,FAA": "NXX",
//       "Airport Name": "Willow Grove Naval Air Station/Joint Reserve Base",
//       "Altitude (Ft)": 358,
//       "Country": "United States",
//       "Latitude": -75.14820099,
//       "Location": "Willow Grove",
//       "Longitude": 40.19979858,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3804,
//       "Airport Code [ICAO]": "KCYS",
//       "Airport Code iATA,FAA": "CYS",
//       "Airport Name": "Cheyenne Regional Jerry Olson Field",
//       "Altitude (Ft)": 6159,
//       "Country": "United States",
//       "Latitude": -104.8119965,
//       "Location": "Cheyenne",
//       "Longitude": 41.15570068,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3805,
//       "Airport Code [ICAO]": "KSCK",
//       "Airport Code iATA,FAA": "SCK",
//       "Airport Name": "Stockton Metropolitan Airport",
//       "Altitude (Ft)": 33,
//       "Country": "United States",
//       "Latitude": -121.2379989624,
//       "Location": "Stockton",
//       "Longitude": 37.894199371338,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3806,
//       "Airport Code [ICAO]": "KCHS",
//       "Airport Code iATA,FAA": "CHS",
//       "Airport Name": "Charleston Air Force Base-International Airport",
//       "Altitude (Ft)": 46,
//       "Country": "United States",
//       "Latitude": -80.04049683,
//       "Location": "Charleston",
//       "Longitude": 32.89860153,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3807,
//       "Airport Code [ICAO]": "KRNO",
//       "Airport Code iATA,FAA": "RNO",
//       "Airport Name": "Reno Tahoe International Airport",
//       "Altitude (Ft)": 4415,
//       "Country": "United States",
//       "Latitude": -119.76799774169922,
//       "Location": "Reno",
//       "Longitude": 39.49909973144531,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3808,
//       "Airport Code [ICAO]": "PAKT",
//       "Airport Code iATA,FAA": "KTN",
//       "Airport Name": "Ketchikan International Airport",
//       "Altitude (Ft)": 89,
//       "Country": "United States",
//       "Latitude": -131.7140045,
//       "Location": "Ketchikan",
//       "Longitude": 55.35559845,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3809,
//       "Airport Code [ICAO]": "KYIP",
//       "Airport Code iATA,FAA": "YIP",
//       "Airport Name": "Willow Run Airport",
//       "Altitude (Ft)": 716,
//       "Country": "United States",
//       "Latitude": -83.53040314,
//       "Location": "Detroit",
//       "Longitude": 42.23789978,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3810,
//       "Airport Code [ICAO]": "KVBG",
//       "Airport Code iATA,FAA": "VBG",
//       "Airport Name": "Vandenberg Air Force Base",
//       "Altitude (Ft)": 369,
//       "Country": "United States",
//       "Latitude": -120.583999634,
//       "Location": "Lompoc",
//       "Longitude": 34.7373008728,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3811,
//       "Airport Code [ICAO]": "KBHM",
//       "Airport Code iATA,FAA": "BHM",
//       "Airport Name": "Birmingham-Shuttlesworth International Airport",
//       "Altitude (Ft)": 650,
//       "Country": "United States",
//       "Latitude": -86.75350189,
//       "Location": "Birmingham",
//       "Longitude": 33.56290054,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3812,
//       "Airport Code [ICAO]": "KNEL",
//       "Airport Code iATA,FAA": "NEL",
//       "Airport Name": "Lakehurst Maxfield Field Airport",
//       "Altitude (Ft)": 101,
//       "Country": "United States",
//       "Latitude": -74.353302,
//       "Location": "Lakehurst",
//       "Longitude": 40.03329849,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3813,
//       "Airport Code [ICAO]": "PASY",
//       "Airport Code iATA,FAA": "SYA",
//       "Airport Name": "Eareckson Air Station",
//       "Altitude (Ft)": 95,
//       "Country": "United States",
//       "Latitude": 174.1139984,
//       "Location": "Shemya",
//       "Longitude": 52.71229935,
//       "GMT Offset": "-10",
//       "Daylight Savings": "A",
//       "Region": "America/Adak",
//       "Type": "airport"
//     },
//     {
//       "ID": 3814,
//       "Airport Code [ICAO]": "KLSV",
//       "Airport Code iATA,FAA": "LSV",
//       "Airport Name": "Nellis Air Force Base",
//       "Altitude (Ft)": 1870,
//       "Country": "United States",
//       "Latitude": -115.033996582,
//       "Location": "Las Vegas",
//       "Longitude": 36.2361984253,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3815,
//       "Airport Code [ICAO]": "KRIV",
//       "Airport Code iATA,FAA": "RIV",
//       "Airport Name": "March ARB Airport",
//       "Altitude (Ft)": 1536,
//       "Country": "United States",
//       "Latitude": -117.259003,
//       "Location": "Riverside",
//       "Longitude": 33.880699,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3816,
//       "Airport Code [ICAO]": "KMOD",
//       "Airport Code iATA,FAA": "MOD",
//       "Airport Name": "Modesto City Co-Harry Sham Field",
//       "Altitude (Ft)": 97,
//       "Country": "United States",
//       "Latitude": -120.9540024,
//       "Location": "Modesto",
//       "Longitude": 37.62580109,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3817,
//       "Airport Code [ICAO]": "KSMF",
//       "Airport Code iATA,FAA": "SMF",
//       "Airport Name": "Sacramento International Airport",
//       "Altitude (Ft)": 27,
//       "Country": "United States",
//       "Latitude": -121.59100341796875,
//       "Location": "Sacramento",
//       "Longitude": 38.69540023803711,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3818,
//       "Airport Code [ICAO]": "KUGN",
//       "Airport Code iATA,FAA": "UGN",
//       "Airport Name": "Waukegan National Airport",
//       "Altitude (Ft)": 727,
//       "Country": "United States",
//       "Latitude": -87.867897033691,
//       "Location": "Chicago",
//       "Longitude": 42.422199249268,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3819,
//       "Airport Code [ICAO]": "KCOS",
//       "Airport Code iATA,FAA": "COS",
//       "Airport Name": "City of Colorado Springs Municipal Airport",
//       "Altitude (Ft)": 6187,
//       "Country": "United States",
//       "Latitude": -104.700996,
//       "Location": "Colorado Springs",
//       "Longitude": 38.805801,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3820,
//       "Airport Code [ICAO]": "KBUF",
//       "Airport Code iATA,FAA": "BUF",
//       "Airport Name": "Buffalo Niagara International Airport",
//       "Altitude (Ft)": 728,
//       "Country": "United States",
//       "Latitude": -78.73220062,
//       "Location": "Buffalo",
//       "Longitude": 42.94049835,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3821,
//       "Airport Code [ICAO]": "KSKY",
//       "Airport Code iATA,FAA": "SKY",
//       "Airport Name": "Griffing Sandusky Airport",
//       "Altitude (Ft)": 580,
//       "Country": "United States",
//       "Latitude": -82.6522979736,
//       "Location": "Sandusky",
//       "Longitude": 41.4333992004,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3822,
//       "Airport Code [ICAO]": "KPAE",
//       "Airport Code iATA,FAA": "PAE",
//       "Airport Name": "Snohomish County (Paine Field) Airport",
//       "Altitude (Ft)": 606,
//       "Country": "United States",
//       "Latitude": -122.2819977,
//       "Location": "Everett",
//       "Longitude": 47.90629959,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3823,
//       "Airport Code [ICAO]": "KMUO",
//       "Airport Code iATA,FAA": "MUO",
//       "Airport Name": "Mountain Home Air Force Base",
//       "Altitude (Ft)": 2996,
//       "Country": "United States",
//       "Latitude": -115.872002,
//       "Location": "Mountain Home",
//       "Longitude": 43.043598,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3824,
//       "Airport Code [ICAO]": "KCDC",
//       "Airport Code iATA,FAA": "CDC",
//       "Airport Name": "Cedar City Regional Airport",
//       "Altitude (Ft)": 5622,
//       "Country": "United States",
//       "Latitude": -113.0989990234375,
//       "Location": "Cedar City",
//       "Longitude": 37.70100021362305,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3825,
//       "Airport Code [ICAO]": "KBDL",
//       "Airport Code iATA,FAA": "BDL",
//       "Airport Name": "Bradley International Airport",
//       "Altitude (Ft)": 173,
//       "Country": "United States",
//       "Latitude": -72.68319702149999,
//       "Location": "Windsor Locks",
//       "Longitude": 41.9388999939,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3826,
//       "Airport Code [ICAO]": "KMFE",
//       "Airport Code iATA,FAA": "MFE",
//       "Airport Name": "Mc Allen Miller International Airport",
//       "Altitude (Ft)": 107,
//       "Country": "United States",
//       "Latitude": -98.23860168,
//       "Location": "Mcallen",
//       "Longitude": 26.17580032,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3827,
//       "Airport Code [ICAO]": "KNGU",
//       "Airport Code iATA,FAA": "NGU",
//       "Airport Name": "Norfolk Naval Station (Chambers Field)",
//       "Altitude (Ft)": 17,
//       "Country": "United States",
//       "Latitude": -76.289299,
//       "Location": "Norfolk",
//       "Longitude": 36.937599,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3828,
//       "Airport Code [ICAO]": "KCEF",
//       "Airport Code iATA,FAA": "CEF",
//       "Airport Name": "Westover ARB/Metropolitan Airport",
//       "Altitude (Ft)": 241,
//       "Country": "United States",
//       "Latitude": -72.53479767,
//       "Location": "Chicopee Falls",
//       "Longitude": 42.19400024,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3829,
//       "Airport Code [ICAO]": "KLBB",
//       "Airport Code iATA,FAA": "LBB",
//       "Airport Name": "Lubbock Preston Smith International Airport",
//       "Altitude (Ft)": 3282,
//       "Country": "United States",
//       "Latitude": -101.822998,
//       "Location": "Lubbock",
//       "Longitude": 33.663601,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3830,
//       "Airport Code [ICAO]": "KORD",
//       "Airport Code iATA,FAA": "ORD",
//       "Airport Name": "Chicago O'Hare International Airport",
//       "Altitude (Ft)": 672,
//       "Country": "United States",
//       "Latitude": -87.9048,
//       "Location": "Chicago",
//       "Longitude": 41.9786,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3831,
//       "Airport Code [ICAO]": "KBCT",
//       "Airport Code iATA,FAA": "BCT",
//       "Airport Name": "Boca Raton Airport",
//       "Altitude (Ft)": 13,
//       "Country": "United States",
//       "Latitude": -80.1076965332,
//       "Location": "Boca Raton",
//       "Longitude": 26.3784999847,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3832,
//       "Airport Code [ICAO]": "PAFA",
//       "Airport Code iATA,FAA": "FAI",
//       "Airport Name": "Fairbanks International Airport",
//       "Altitude (Ft)": 439,
//       "Country": "United States",
//       "Latitude": -147.8560028,
//       "Location": "Fairbanks",
//       "Longitude": 64.81510162,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3833,
//       "Airport Code [ICAO]": "KNYG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Quantico MCAF /Turner field",
//       "Altitude (Ft)": 10,
//       "Country": "United States",
//       "Latitude": -77.30529785,
//       "Location": "Quantico",
//       "Longitude": 38.50170135,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3834,
//       "Airport Code [ICAO]": "KCVS",
//       "Airport Code iATA,FAA": "CVS",
//       "Airport Name": "Cannon Air Force Base",
//       "Altitude (Ft)": 4295,
//       "Country": "United States",
//       "Latitude": -103.321998596,
//       "Location": "Clovis",
//       "Longitude": 34.3828010559,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3835,
//       "Airport Code [ICAO]": "PHNG",
//       "Airport Code iATA,FAA": "NGF",
//       "Airport Name": "Kaneohe Bay MCAS (Marion E. Carl Field) Airport",
//       "Altitude (Ft)": 24,
//       "Country": "United States",
//       "Latitude": -157.768005371,
//       "Location": "Kaneohe Bay",
//       "Longitude": 21.4505004883,
//       "GMT Offset": "-10",
//       "Daylight Savings": "A",
//       "Region": "Pacific/Honolulu",
//       "Type": "airport"
//     },
//     {
//       "ID": 3836,
//       "Airport Code [ICAO]": "KOFF",
//       "Airport Code iATA,FAA": "OFF",
//       "Airport Name": "Offutt Air Force Base",
//       "Altitude (Ft)": 1052,
//       "Country": "United States",
//       "Latitude": -95.912498474121,
//       "Location": "Omaha",
//       "Longitude": 41.118301391602,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3837,
//       "Airport Code [ICAO]": "PAGK",
//       "Airport Code iATA,FAA": "GKN",
//       "Airport Name": "Gulkana Airport",
//       "Altitude (Ft)": 1586,
//       "Country": "United States",
//       "Latitude": -145.4570007,
//       "Location": "Gulkana",
//       "Longitude": 62.1548996,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3838,
//       "Airport Code [ICAO]": "KART",
//       "Airport Code iATA,FAA": "ART",
//       "Airport Name": "Watertown International Airport",
//       "Altitude (Ft)": 325,
//       "Country": "United States",
//       "Latitude": -76.02169799804688,
//       "Location": "Watertown",
//       "Longitude": 43.99190139770508,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3839,
//       "Airport Code [ICAO]": "KPSP",
//       "Airport Code iATA,FAA": "PSP",
//       "Airport Name": "Palm Springs International Airport",
//       "Altitude (Ft)": 477,
//       "Country": "United States",
//       "Latitude": -116.50700378417969,
//       "Location": "Palm Springs",
//       "Longitude": 33.8297004699707,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3840,
//       "Airport Code [ICAO]": "KAMA",
//       "Airport Code iATA,FAA": "AMA",
//       "Airport Name": "Rick Husband Amarillo International Airport",
//       "Altitude (Ft)": 3607,
//       "Country": "United States",
//       "Latitude": -101.706001,
//       "Location": "Amarillo",
//       "Longitude": 35.219398,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3841,
//       "Airport Code [ICAO]": "KFOD",
//       "Airport Code iATA,FAA": "FOD",
//       "Airport Name": "Fort Dodge Regional Airport",
//       "Altitude (Ft)": 1156,
//       "Country": "United States",
//       "Latitude": -94.19259644,
//       "Location": "Fort Dodge",
//       "Longitude": 42.55149841,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3842,
//       "Airport Code [ICAO]": "KBAD",
//       "Airport Code iATA,FAA": "BAD",
//       "Airport Name": "Barksdale Air Force Base",
//       "Altitude (Ft)": 166,
//       "Country": "United States",
//       "Latitude": -93.6626968384,
//       "Location": "Shreveport",
//       "Longitude": 32.5018005371,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3843,
//       "Airport Code [ICAO]": "KFOE",
//       "Airport Code iATA,FAA": "FOE",
//       "Airport Name": "Topeka Regional Airport - Forbes Field",
//       "Altitude (Ft)": 1078,
//       "Country": "United States",
//       "Latitude": -95.66359710690001,
//       "Location": "Topeka",
//       "Longitude": 38.950901031499995,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3844,
//       "Airport Code [ICAO]": "KCOT",
//       "Airport Code iATA,FAA": "COT",
//       "Airport Name": "Cotulla-La Salle County Airport",
//       "Altitude (Ft)": 474,
//       "Country": "United States",
//       "Latitude": -99.22029877,
//       "Location": "Cotulla",
//       "Longitude": 28.45669937,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3845,
//       "Airport Code [ICAO]": "KILM",
//       "Airport Code iATA,FAA": "ILM",
//       "Airport Name": "Wilmington International Airport",
//       "Altitude (Ft)": 32,
//       "Country": "United States",
//       "Latitude": -77.90260314941406,
//       "Location": "Wilmington",
//       "Longitude": 34.270599365234375,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3846,
//       "Airport Code [ICAO]": "KBTR",
//       "Airport Code iATA,FAA": "BTR",
//       "Airport Name": "Baton Rouge Metropolitan Airport",
//       "Altitude (Ft)": 70,
//       "Country": "United States",
//       "Latitude": -91.149597,
//       "Location": "Baton Rouge",
//       "Longitude": 30.533199,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3847,
//       "Airport Code [ICAO]": "KNMM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Meridian Naval Air Station",
//       "Altitude (Ft)": 316,
//       "Country": "United States",
//       "Latitude": -88.55560303,
//       "Location": "Meridian",
//       "Longitude": 32.55210114,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3848,
//       "Airport Code [ICAO]": "KTYR",
//       "Airport Code iATA,FAA": "TYR",
//       "Airport Name": "Tyler Pounds Regional Airport",
//       "Altitude (Ft)": 544,
//       "Country": "United States",
//       "Latitude": -95.40239715576172,
//       "Location": "Tyler",
//       "Longitude": 32.35409927368164,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3849,
//       "Airport Code [ICAO]": "KBWI",
//       "Airport Code iATA,FAA": "BWI",
//       "Airport Name": "Baltimore/Washington International Thurgood Marshall Airport",
//       "Altitude (Ft)": 146,
//       "Country": "United States",
//       "Latitude": -76.668297,
//       "Location": "Baltimore",
//       "Longitude": 39.1754,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3850,
//       "Airport Code [ICAO]": "KHBR",
//       "Airport Code iATA,FAA": "HBR",
//       "Airport Name": "Hobart Regional Airport",
//       "Altitude (Ft)": 1563,
//       "Country": "United States",
//       "Latitude": -99.051313,
//       "Location": "Hobart",
//       "Longitude": 34.991317,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3851,
//       "Airport Code [ICAO]": "PHNY",
//       "Airport Code iATA,FAA": "LNY",
//       "Airport Name": "Lanai Airport",
//       "Altitude (Ft)": 1308,
//       "Country": "United States",
//       "Latitude": -156.9510040283203,
//       "Location": "Lanai",
//       "Longitude": 20.785600662231445,
//       "GMT Offset": "-10",
//       "Daylight Savings": "N",
//       "Region": "Pacific/Honolulu",
//       "Type": "airport"
//     },
//     {
//       "ID": 3852,
//       "Airport Code [ICAO]": "KAEX",
//       "Airport Code iATA,FAA": "AEX",
//       "Airport Name": "Alexandria International Airport",
//       "Altitude (Ft)": 89,
//       "Country": "United States",
//       "Latitude": -92.54979705810547,
//       "Location": "Alexandria",
//       "Longitude": 31.32740020751953,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3853,
//       "Airport Code [ICAO]": "KWSD",
//       "Airport Code iATA,FAA": "WSD",
//       "Airport Name": "Condron Army Air Field",
//       "Altitude (Ft)": 3934,
//       "Country": "United States",
//       "Latitude": -106.4029999,
//       "Location": "White Sands",
//       "Longitude": 32.34149933,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3854,
//       "Airport Code [ICAO]": "PACD",
//       "Airport Code iATA,FAA": "CDB",
//       "Airport Name": "Cold Bay Airport",
//       "Altitude (Ft)": 96,
//       "Country": "United States",
//       "Latitude": -162.72500610351562,
//       "Location": "Cold Bay",
//       "Longitude": 55.20610046386719,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3855,
//       "Airport Code [ICAO]": "KTUL",
//       "Airport Code iATA,FAA": "TUL",
//       "Airport Name": "Tulsa International Airport",
//       "Altitude (Ft)": 677,
//       "Country": "United States",
//       "Latitude": -95.88809967041016,
//       "Location": "Tulsa",
//       "Longitude": 36.19839859008789,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3856,
//       "Airport Code [ICAO]": "PASI",
//       "Airport Code iATA,FAA": "SIT",
//       "Airport Name": "Sitka Rocky Gutierrez Airport",
//       "Altitude (Ft)": 21,
//       "Country": "United States",
//       "Latitude": -135.36199951171875,
//       "Location": "Sitka",
//       "Longitude": 57.04710006713867,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3857,
//       "Airport Code [ICAO]": "KISP",
//       "Airport Code iATA,FAA": "ISP",
//       "Airport Name": "Long Island Mac Arthur Airport",
//       "Altitude (Ft)": 99,
//       "Country": "United States",
//       "Latitude": -73.10019684,
//       "Location": "Islip",
//       "Longitude": 40.79520035,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3858,
//       "Airport Code [ICAO]": "KMSP",
//       "Airport Code iATA,FAA": "MSP",
//       "Airport Name": "Minneapolis-St Paul International/Wold-Chamberlain Airport",
//       "Altitude (Ft)": 841,
//       "Country": "United States",
//       "Latitude": -93.221802,
//       "Location": "Minneapolis",
//       "Longitude": 44.882,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3859,
//       "Airport Code [ICAO]": "KILG",
//       "Airport Code iATA,FAA": "ILG",
//       "Airport Name": "New Castle Airport",
//       "Altitude (Ft)": 80,
//       "Country": "United States",
//       "Latitude": -75.60649872,
//       "Location": "Wilmington",
//       "Longitude": 39.67869949,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3860,
//       "Airport Code [ICAO]": "PADU",
//       "Airport Code iATA,FAA": "DUT",
//       "Airport Name": "Unalaska Airport",
//       "Altitude (Ft)": 22,
//       "Country": "United States",
//       "Latitude": -166.544006348,
//       "Location": "Unalaska",
//       "Longitude": 53.900100708,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3861,
//       "Airport Code [ICAO]": "KMSY",
//       "Airport Code iATA,FAA": "MSY",
//       "Airport Name": "Louis Armstrong New Orleans International Airport",
//       "Altitude (Ft)": 4,
//       "Country": "United States",
//       "Latitude": -90.25800323486328,
//       "Location": "New Orleans",
//       "Longitude": 29.99340057373047,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3862,
//       "Airport Code [ICAO]": "KPWM",
//       "Airport Code iATA,FAA": "PWM",
//       "Airport Name": "Portland International Jetport Airport",
//       "Altitude (Ft)": 76,
//       "Country": "United States",
//       "Latitude": -70.30930328,
//       "Location": "Portland",
//       "Longitude": 43.64619827,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3863,
//       "Airport Code [ICAO]": "KOKC",
//       "Airport Code iATA,FAA": "OKC",
//       "Airport Name": "Will Rogers World Airport",
//       "Altitude (Ft)": 1295,
//       "Country": "United States",
//       "Latitude": -97.60070037841797,
//       "Location": "Oklahoma City",
//       "Longitude": 35.39310073852539,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3864,
//       "Airport Code [ICAO]": "KALB",
//       "Airport Code iATA,FAA": "ALB",
//       "Airport Name": "Albany International Airport",
//       "Altitude (Ft)": 285,
//       "Country": "United States",
//       "Latitude": -73.80169677734375,
//       "Location": "Albany",
//       "Longitude": 42.74829864501953,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3865,
//       "Airport Code [ICAO]": "PAVD",
//       "Airport Code iATA,FAA": "VDZ",
//       "Airport Name": "Valdez Pioneer Field",
//       "Altitude (Ft)": 121,
//       "Country": "United States",
//       "Latitude": -146.2480011,
//       "Location": "Valdez",
//       "Longitude": 61.13389969,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3866,
//       "Airport Code [ICAO]": "KLFI",
//       "Airport Code iATA,FAA": "LFI",
//       "Airport Name": "Langley Air Force Base",
//       "Altitude (Ft)": 11,
//       "Country": "United States",
//       "Latitude": -76.360496521,
//       "Location": "Hampton",
//       "Longitude": 37.082901001,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3867,
//       "Airport Code [ICAO]": "KSNA",
//       "Airport Code iATA,FAA": "SNA",
//       "Airport Name": "John Wayne Airport-Orange County Airport",
//       "Altitude (Ft)": 56,
//       "Country": "United States",
//       "Latitude": -117.8679962,
//       "Location": "Santa Ana",
//       "Longitude": 33.67570114,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3868,
//       "Airport Code [ICAO]": "KCBM",
//       "Airport Code iATA,FAA": "CBM",
//       "Airport Name": "Columbus Air Force Base",
//       "Altitude (Ft)": 219,
//       "Country": "United States",
//       "Latitude": -88.44380187990001,
//       "Location": "Colombus",
//       "Longitude": 33.6437988281,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3869,
//       "Airport Code [ICAO]": "KTMB",
//       "Airport Code iATA,FAA": "TMB",
//       "Airport Name": "Kendall-Tamiami Executive Airport",
//       "Altitude (Ft)": 8,
//       "Country": "United States",
//       "Latitude": -80.432800293,
//       "Location": "Kendall-tamiami",
//       "Longitude": 25.6478996277,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3870,
//       "Airport Code [ICAO]": "KNTU",
//       "Airport Code iATA,FAA": "NTU",
//       "Airport Name": "Oceana Naval Air Station",
//       "Altitude (Ft)": 23,
//       "Country": "United States",
//       "Latitude": -76.033501,
//       "Location": "Oceana",
//       "Longitude": 36.820702,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3871,
//       "Airport Code [ICAO]": "KGUS",
//       "Airport Code iATA,FAA": "GUS",
//       "Airport Name": "Grissom Air Reserve Base",
//       "Altitude (Ft)": 812,
//       "Country": "United States",
//       "Latitude": -86.1520996094,
//       "Location": "Peru",
//       "Longitude": 40.648101806599996,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3872,
//       "Airport Code [ICAO]": "KCPR",
//       "Airport Code iATA,FAA": "CPR",
//       "Airport Name": "Casper-Natrona County International Airport",
//       "Altitude (Ft)": 5350,
//       "Country": "United States",
//       "Latitude": -106.463997,
//       "Location": "Casper",
//       "Longitude": 42.908001,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3873,
//       "Airport Code [ICAO]": "KVPS",
//       "Airport Code iATA,FAA": "VPS",
//       "Airport Name": "Destin-Ft Walton Beach Airport",
//       "Altitude (Ft)": 87,
//       "Country": "United States",
//       "Latitude": -86.525398,
//       "Location": "Valparaiso",
//       "Longitude": 30.4832,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3874,
//       "Airport Code [ICAO]": "KSEM",
//       "Airport Code iATA,FAA": "SEM",
//       "Airport Name": "Craig Field",
//       "Altitude (Ft)": 166,
//       "Country": "United States",
//       "Latitude": -86.987800598145,
//       "Location": "Selma",
//       "Longitude": 32.343898773193,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 3875,
//       "Airport Code [ICAO]": "KEYW",
//       "Airport Code iATA,FAA": "EYW",
//       "Airport Name": "Key West International Airport",
//       "Altitude (Ft)": 3,
//       "Country": "United States",
//       "Latitude": -81.75959777832031,
//       "Location": "Key West",
//       "Longitude": 24.556100845336914,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3876,
//       "Airport Code [ICAO]": "KCLT",
//       "Airport Code iATA,FAA": "CLT",
//       "Airport Name": "Charlotte Douglas International Airport",
//       "Altitude (Ft)": 748,
//       "Country": "United States",
//       "Latitude": -80.94309997558594,
//       "Location": "Charlotte",
//       "Longitude": 35.2140007019043,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3877,
//       "Airport Code [ICAO]": "KLAS",
//       "Airport Code iATA,FAA": "LAS",
//       "Airport Name": "McCarran International Airport",
//       "Altitude (Ft)": 2181,
//       "Country": "United States",
//       "Latitude": -115.1520004,
//       "Location": "Las Vegas",
//       "Longitude": 36.08010101,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3878,
//       "Airport Code [ICAO]": "KMCO",
//       "Airport Code iATA,FAA": "MCO",
//       "Airport Name": "Orlando International Airport",
//       "Altitude (Ft)": 96,
//       "Country": "United States",
//       "Latitude": -81.30899810791016,
//       "Location": "Orlando",
//       "Longitude": 28.429399490356445,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3879,
//       "Airport Code [ICAO]": "KFLO",
//       "Airport Code iATA,FAA": "FLO",
//       "Airport Name": "Florence Regional Airport",
//       "Altitude (Ft)": 146,
//       "Country": "United States",
//       "Latitude": -79.7238998413086,
//       "Location": "Florence",
//       "Longitude": 34.18539810180664,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3880,
//       "Airport Code [ICAO]": "KGTF",
//       "Airport Code iATA,FAA": "GTF",
//       "Airport Name": "Great Falls International Airport",
//       "Altitude (Ft)": 3680,
//       "Country": "United States",
//       "Latitude": -111.3710022,
//       "Location": "Great Falls",
//       "Longitude": 47.48199844,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 3881,
//       "Airport Code [ICAO]": "KYNG",
//       "Airport Code iATA,FAA": "YNG",
//       "Airport Name": "Youngstown Warren Regional Airport",
//       "Altitude (Ft)": 1192,
//       "Country": "United States",
//       "Latitude": -80.67910004,
//       "Location": "Youngstown",
//       "Longitude": 41.26070023,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3882,
//       "Airport Code [ICAO]": "PAFB",
//       "Airport Code iATA,FAA": "FBK",
//       "Airport Name": "Ladd AAF Airfield",
//       "Altitude (Ft)": 454,
//       "Country": "United States",
//       "Latitude": -147.6139984,
//       "Location": "Fort Wainwright",
//       "Longitude": 64.83750153,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 3883,
//       "Airport Code [ICAO]": "KMMV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mc Minnville Municipal Airport",
//       "Altitude (Ft)": 163,
//       "Country": "United States",
//       "Latitude": -123.1360016,
//       "Location": "Mackminnville",
//       "Longitude": 45.19440079,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3884,
//       "Airport Code [ICAO]": "KWRB",
//       "Airport Code iATA,FAA": "WRB",
//       "Airport Name": "Robins Air Force Base",
//       "Altitude (Ft)": 294,
//       "Country": "United States",
//       "Latitude": -83.5919036865,
//       "Location": "Macon",
//       "Longitude": 32.6400985718,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3885,
//       "Airport Code [ICAO]": "VTBS",
//       "Airport Code iATA,FAA": "BKK",
//       "Airport Name": "Suvarnabhumi Airport",
//       "Altitude (Ft)": 5,
//       "Country": "Thailand",
//       "Latitude": 100.74700164794922,
//       "Location": "Bangkok",
//       "Longitude": 13.681099891662598,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3886,
//       "Airport Code [ICAO]": "WAMH",
//       "Airport Code iATA,FAA": "NAH",
//       "Airport Name": "Naha Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Indonesia",
//       "Latitude": 125.52799987792969,
//       "Location": "Naha",
//       "Longitude": 3.6832098960876465,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3887,
//       "Airport Code [ICAO]": "WAWM",
//       "Airport Code iATA,FAA": "MXB",
//       "Airport Name": "Andi Jemma Airport",
//       "Altitude (Ft)": 164,
//       "Country": "Indonesia",
//       "Latitude": 120.323997,
//       "Location": "Masamba",
//       "Longitude": -2.55803,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3888,
//       "Airport Code [ICAO]": "WAWS",
//       "Airport Code iATA,FAA": "SQR",
//       "Airport Name": "Soroako Airport",
//       "Altitude (Ft)": 1388,
//       "Country": "Indonesia",
//       "Latitude": 121.35800170898438,
//       "Location": "Soroako",
//       "Longitude": -2.5311999320983887,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3889,
//       "Airport Code [ICAO]": "WAWT",
//       "Airport Code iATA,FAA": "TTR",
//       "Airport Name": "Pongtiku Airport",
//       "Altitude (Ft)": 2884,
//       "Country": "Indonesia",
//       "Latitude": 119.82199859619,
//       "Location": "Makale",
//       "Longitude": -3.0447399616241,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3890,
//       "Airport Code [ICAO]": "WAWW",
//       "Airport Code iATA,FAA": "KDI",
//       "Airport Name": "Wolter Monginsidi Airport",
//       "Altitude (Ft)": 538,
//       "Country": "Indonesia",
//       "Latitude": 122.41799926757812,
//       "Location": "Kendari",
//       "Longitude": -4.081610202789307,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3891,
//       "Airport Code [ICAO]": "WITB",
//       "Airport Code iATA,FAA": "SBG",
//       "Airport Name": "Maimun Saleh Airport",
//       "Altitude (Ft)": 393,
//       "Country": "Indonesia",
//       "Latitude": 95.33969879150001,
//       "Location": "Sabang",
//       "Longitude": 5.87412977219,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3892,
//       "Airport Code [ICAO]": "WICM",
//       "Airport Code iATA,FAA": "TSY",
//       "Airport Name": "Cibeureum Airport",
//       "Altitude (Ft)": 1148,
//       "Country": "Indonesia",
//       "Latitude": 108.246002197,
//       "Location": "Tasikmalaya",
//       "Longitude": -7.346600055690001,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3893,
//       "Airport Code [ICAO]": "WARI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Iswahyudi Airport",
//       "Altitude (Ft)": 361,
//       "Country": "Indonesia",
//       "Latitude": 111.43399810791016,
//       "Location": "Madiun",
//       "Longitude": -7.615769863128662,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3894,
//       "Airport Code [ICAO]": "WARA",
//       "Airport Code iATA,FAA": "MLG",
//       "Airport Name": "Abdul Rachman Saleh Airport",
//       "Altitude (Ft)": 1726,
//       "Country": "Indonesia",
//       "Latitude": 112.714996338,
//       "Location": "Malang",
//       "Longitude": -7.926559925079999,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3895,
//       "Airport Code [ICAO]": "WICB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Budiarto Airport",
//       "Altitude (Ft)": 151,
//       "Country": "Indonesia",
//       "Latitude": 106.57,
//       "Location": "Tangerang",
//       "Longitude": -6.293171,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3896,
//       "Airport Code [ICAO]": "WICC",
//       "Airport Code iATA,FAA": "BDO",
//       "Airport Name": "Husein Sastranegara International Airport",
//       "Altitude (Ft)": 2436,
//       "Country": "Indonesia",
//       "Latitude": 107.57599639892578,
//       "Location": "Bandung",
//       "Longitude": -6.900629997253418,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3897,
//       "Airport Code [ICAO]": "WICD",
//       "Airport Code iATA,FAA": "CBN",
//       "Airport Name": "Penggung Airport",
//       "Altitude (Ft)": 89,
//       "Country": "Indonesia",
//       "Latitude": 108.540000916,
//       "Location": "Cirebon",
//       "Longitude": -6.756140232090001,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3898,
//       "Airport Code [ICAO]": "WARJ",
//       "Airport Code iATA,FAA": "JOG",
//       "Airport Name": "Adi Sutjipto International Airport",
//       "Altitude (Ft)": 350,
//       "Country": "Indonesia",
//       "Latitude": 110.43199920654297,
//       "Location": "Yogyakarta",
//       "Longitude": -7.788179874420166,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3899,
//       "Airport Code [ICAO]": "WIHL",
//       "Airport Code iATA,FAA": "CXP",
//       "Airport Name": "Tunggul Wulung Airport",
//       "Altitude (Ft)": 69,
//       "Country": "Indonesia",
//       "Latitude": 109.033996582,
//       "Location": "Cilacap",
//       "Longitude": -7.645060062410001,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3900,
//       "Airport Code [ICAO]": "WIHP",
//       "Airport Code iATA,FAA": "PCB",
//       "Airport Name": "Pondok Cabe Air Base",
//       "Altitude (Ft)": 200,
//       "Country": "Indonesia",
//       "Latitude": 106.76499938964844,
//       "Location": "Jakarta",
//       "Longitude": -6.3369598388671875,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3901,
//       "Airport Code [ICAO]": "WARS",
//       "Airport Code iATA,FAA": "SRG",
//       "Airport Name": "Achmad Yani Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Indonesia",
//       "Latitude": 110.375,
//       "Location": "Semarang",
//       "Longitude": -6.97273,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3903,
//       "Airport Code [ICAO]": "WIDD",
//       "Airport Code iATA,FAA": "BTH",
//       "Airport Name": "Hang Nadim International Airport",
//       "Altitude (Ft)": 126,
//       "Country": "Indonesia",
//       "Latitude": 104.119003296,
//       "Location": "Batam",
//       "Longitude": 1.12102997303,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3904,
//       "Airport Code [ICAO]": "WIOD",
//       "Airport Code iATA,FAA": "TJQ",
//       "Airport Name": "Buluh Tumbang (H A S Hanandjoeddin) Airport",
//       "Altitude (Ft)": 164,
//       "Country": "Indonesia",
//       "Latitude": 107.754997253,
//       "Location": "Tanjung Pandan",
//       "Longitude": -2.74571990967,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3905,
//       "Airport Code [ICAO]": "WIPK",
//       "Airport Code iATA,FAA": "PGK",
//       "Airport Name": "Pangkal Pinang (Depati Amir) Airport",
//       "Altitude (Ft)": 109,
//       "Country": "Indonesia",
//       "Latitude": 106.138999939,
//       "Location": "Pangkal Pinang",
//       "Longitude": -2.16219997406,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3906,
//       "Airport Code [ICAO]": "WIDN",
//       "Airport Code iATA,FAA": "TNJ",
//       "Airport Name": "Raja Haji Fisabilillah International Airport",
//       "Altitude (Ft)": 52,
//       "Country": "Indonesia",
//       "Latitude": 104.531997681,
//       "Location": "Tanjung Pinang",
//       "Longitude": 0.922683000565,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3907,
//       "Airport Code [ICAO]": "WIDS",
//       "Airport Code iATA,FAA": "SIQ",
//       "Airport Name": "Dabo Airport",
//       "Altitude (Ft)": 95,
//       "Country": "Indonesia",
//       "Latitude": 104.5790023803711,
//       "Location": "Singkep",
//       "Longitude": -0.47918900847435,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3908,
//       "Airport Code [ICAO]": "WAOO",
//       "Airport Code iATA,FAA": "BDJ",
//       "Airport Name": "Syamsudin Noor Airport",
//       "Altitude (Ft)": 66,
//       "Country": "Indonesia",
//       "Latitude": 114.76300048828125,
//       "Location": "Banjarmasin",
//       "Longitude": -3.4423599243164062,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3909,
//       "Airport Code [ICAO]": "WAOC",
//       "Airport Code iATA,FAA": "BTW",
//       "Airport Name": "Batu Licin Airport",
//       "Altitude (Ft)": 3,
//       "Country": "Indonesia",
//       "Latitude": 115.995002747,
//       "Location": "Batu Licin",
//       "Longitude": -3.4124100208300003,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3910,
//       "Airport Code [ICAO]": "WAOI",
//       "Airport Code iATA,FAA": "PKN",
//       "Airport Name": "Iskandar Airport",
//       "Altitude (Ft)": 75,
//       "Country": "Indonesia",
//       "Latitude": 111.672996521,
//       "Location": "Pangkalan Bun",
//       "Longitude": -2.70519995689,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3911,
//       "Airport Code [ICAO]": "WAOP",
//       "Airport Code iATA,FAA": "PKY",
//       "Airport Name": "Tjilik Riwut Airport",
//       "Altitude (Ft)": 82,
//       "Country": "Indonesia",
//       "Latitude": 113.943000793,
//       "Location": "Palangkaraya",
//       "Longitude": -2.22513008118,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3913,
//       "Airport Code [ICAO]": "WATC",
//       "Airport Code iATA,FAA": "MOF",
//       "Airport Name": "Maumere(Wai Oti) Airport",
//       "Altitude (Ft)": 115,
//       "Country": "Indonesia",
//       "Latitude": 122.236999512,
//       "Location": "Maumere",
//       "Longitude": -8.64064979553,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3914,
//       "Airport Code [ICAO]": "WATE",
//       "Airport Code iATA,FAA": "ENE",
//       "Airport Name": "Ende (H Hasan Aroeboesman) Airport",
//       "Altitude (Ft)": 49,
//       "Country": "Indonesia",
//       "Latitude": 121.661003113,
//       "Location": "Ende",
//       "Longitude": -8.8492898941,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3915,
//       "Airport Code [ICAO]": "WATG",
//       "Airport Code iATA,FAA": "RTG",
//       "Airport Name": "Frans Sales Lega Airport",
//       "Altitude (Ft)": 3510,
//       "Country": "Indonesia",
//       "Latitude": 120.47699737549,
//       "Location": "Ruteng",
//       "Longitude": -8.5970096588135,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3916,
//       "Airport Code [ICAO]": "WATT",
//       "Airport Code iATA,FAA": "KOE",
//       "Airport Name": "El Tari Airport",
//       "Altitude (Ft)": 335,
//       "Country": "Indonesia",
//       "Latitude": 123.6709976196289,
//       "Location": "Kupang",
//       "Longitude": -10.171600341796875,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3917,
//       "Airport Code [ICAO]": "WATO",
//       "Airport Code iATA,FAA": "LBJ",
//       "Airport Name": "Komodo Airport",
//       "Altitude (Ft)": 66,
//       "Country": "Indonesia",
//       "Latitude": 119.889,
//       "Location": "Labuhan Bajo",
//       "Longitude": -8.48666,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3919,
//       "Airport Code [ICAO]": "WALL",
//       "Airport Code iATA,FAA": "BPN",
//       "Airport Name": "Sultan Aji Muhamad Sulaiman Airport",
//       "Altitude (Ft)": 12,
//       "Country": "Indonesia",
//       "Latitude": 116.893997192,
//       "Location": "Balikpapan",
//       "Longitude": -1.26827001572,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3920,
//       "Airport Code [ICAO]": "WALR",
//       "Airport Code iATA,FAA": "TRK",
//       "Airport Name": "Juwata Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Indonesia",
//       "Latitude": 117.569444,
//       "Location": "Taraken",
//       "Longitude": 3.326667,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3921,
//       "Airport Code [ICAO]": "WALS",
//       "Airport Code iATA,FAA": "SRI",
//       "Airport Name": "Temindung Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Indonesia",
//       "Latitude": 117.156997681,
//       "Location": "Samarinda",
//       "Longitude": -0.484530985355,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3922,
//       "Airport Code [ICAO]": "WALT",
//       "Airport Code iATA,FAA": "TSX",
//       "Airport Name": "Tanjung Santan Airport",
//       "Altitude (Ft)": 121,
//       "Country": "Indonesia",
//       "Latitude": 117.45300293,
//       "Location": "Tanjung Santan",
//       "Longitude": -0.0929730013013,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3923,
//       "Airport Code [ICAO]": "WADA",
//       "Airport Code iATA,FAA": "AMI",
//       "Airport Name": "Selaparang Airport",
//       "Altitude (Ft)": 52,
//       "Country": "Indonesia",
//       "Latitude": 116.095001221,
//       "Location": "Mataram",
//       "Longitude": -8.560709953309999,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3924,
//       "Airport Code [ICAO]": "WADB",
//       "Airport Code iATA,FAA": "BMU",
//       "Airport Name": "Muhammad Salahuddin Airport",
//       "Altitude (Ft)": 3,
//       "Country": "Indonesia",
//       "Latitude": 118.68699645996,
//       "Location": "Bima",
//       "Longitude": -8.5396499633789,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3927,
//       "Airport Code [ICAO]": "WADW",
//       "Airport Code iATA,FAA": "WGP",
//       "Airport Name": "Umbu Mehang Kunda Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Indonesia",
//       "Latitude": 120.302002,
//       "Location": "Waingapu",
//       "Longitude": -9.66922,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3928,
//       "Airport Code [ICAO]": "WARR",
//       "Airport Code iATA,FAA": "SUB",
//       "Airport Name": "Juanda International Airport",
//       "Altitude (Ft)": 9,
//       "Country": "Indonesia",
//       "Latitude": 112.78700256347656,
//       "Location": "Surabaya",
//       "Longitude": -7.3798298835754395,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3929,
//       "Airport Code [ICAO]": "WARQ",
//       "Airport Code iATA,FAA": "SOC",
//       "Airport Name": "Adi Sumarmo Wiryokusumo Airport",
//       "Altitude (Ft)": 421,
//       "Country": "Indonesia",
//       "Latitude": 110.75700378417969,
//       "Location": "Solo City",
//       "Longitude": -7.516089916229248,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 3930,
//       "Airport Code [ICAO]": "RKSI",
//       "Airport Code iATA,FAA": "ICN",
//       "Airport Name": "Incheon International Airport",
//       "Altitude (Ft)": 23,
//       "Country": "South Korea",
//       "Latitude": 126.45099639892578,
//       "Location": "Seoul",
//       "Longitude": 37.46910095214844,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Seoul",
//       "Type": "airport"
//     },
//     {
//       "ID": 3931,
//       "Airport Code [ICAO]": "VTCC",
//       "Airport Code iATA,FAA": "CNX",
//       "Airport Name": "Chiang Mai International Airport",
//       "Altitude (Ft)": 1036,
//       "Country": "Thailand",
//       "Latitude": 98.962600708,
//       "Location": "Chiang Mai",
//       "Longitude": 18.766799926799997,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3932,
//       "Airport Code [ICAO]": "VTCT",
//       "Airport Code iATA,FAA": "CEI",
//       "Airport Name": "Chiang Rai International Airport",
//       "Altitude (Ft)": 1280,
//       "Country": "Thailand",
//       "Latitude": 99.88289642330001,
//       "Location": "Chiang Rai",
//       "Longitude": 19.952299118,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3933,
//       "Airport Code [ICAO]": "VTSF",
//       "Airport Code iATA,FAA": "NST",
//       "Airport Name": "Nakhon Si Thammarat Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Thailand",
//       "Latitude": 99.9447021484375,
//       "Location": "Nakhon Si Thammarat",
//       "Longitude": 8.539620399475098,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3935,
//       "Airport Code [ICAO]": "VTUQ",
//       "Airport Code iATA,FAA": "NAK",
//       "Airport Name": "Nakhon Ratchasima Airport",
//       "Altitude (Ft)": 765,
//       "Country": "Thailand",
//       "Latitude": 102.31300354003906,
//       "Location": "Nakhon Ratchasima",
//       "Longitude": 14.94950008392334,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3936,
//       "Airport Code [ICAO]": "VTUW",
//       "Airport Code iATA,FAA": "KOP",
//       "Airport Name": "Nakhon Phanom Airport",
//       "Altitude (Ft)": 587,
//       "Country": "Thailand",
//       "Latitude": 104.64299774169922,
//       "Location": "Nakhon Phanom",
//       "Longitude": 17.383800506591797,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3937,
//       "Airport Code [ICAO]": "VTUU",
//       "Airport Code iATA,FAA": "UBP",
//       "Airport Name": "Ubon Ratchathani Airport",
//       "Altitude (Ft)": 406,
//       "Country": "Thailand",
//       "Latitude": 104.870002747,
//       "Location": "Ubon Ratchathani",
//       "Longitude": 15.2512998581,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3938,
//       "Airport Code [ICAO]": "VTUK",
//       "Airport Code iATA,FAA": "KKC",
//       "Airport Name": "Khon Kaen Airport",
//       "Altitude (Ft)": 670,
//       "Country": "Thailand",
//       "Latitude": 102.783996582,
//       "Location": "Khon Kaen",
//       "Longitude": 16.466600418099997,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3939,
//       "Airport Code [ICAO]": "VTPO",
//       "Airport Code iATA,FAA": "THS",
//       "Airport Name": "Sukhothai Airport",
//       "Altitude (Ft)": 179,
//       "Country": "Thailand",
//       "Latitude": 99.81819915771484,
//       "Location": "Sukhothai",
//       "Longitude": 17.238000869750977,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 3940,
//       "Airport Code [ICAO]": "WADD",
//       "Airport Code iATA,FAA": "DPS",
//       "Airport Name": "Ngurah Rai (Bali) International Airport",
//       "Altitude (Ft)": 14,
//       "Country": "Indonesia",
//       "Latitude": 115.16699981689,
//       "Location": "Denpasar",
//       "Longitude": -8.7481698989868,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 3941,
//       "Airport Code [ICAO]": "LGAV",
//       "Airport Code iATA,FAA": "ATH",
//       "Airport Name": "Eleftherios Venizelos International Airport",
//       "Altitude (Ft)": 308,
//       "Country": "Greece",
//       "Latitude": 23.9444999695,
//       "Location": "Athens",
//       "Longitude": 37.9364013672,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 3942,
//       "Airport Code [ICAO]": "RJGG",
//       "Airport Code iATA,FAA": "NGO",
//       "Airport Name": "Chubu Centrair International Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Japan",
//       "Latitude": 136.80499267578125,
//       "Location": "Nagoya",
//       "Longitude": 34.8583984375,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 3943,
//       "Airport Code [ICAO]": "RJBE",
//       "Airport Code iATA,FAA": "UKB",
//       "Airport Name": "Kobe Airport",
//       "Altitude (Ft)": 22,
//       "Country": "Japan",
//       "Latitude": 135.2239990234375,
//       "Location": "Kobe",
//       "Longitude": 34.6328010559082,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 3944,
//       "Airport Code [ICAO]": "KPUW",
//       "Airport Code iATA,FAA": "PUW",
//       "Airport Name": "Pullman Moscow Regional Airport",
//       "Altitude (Ft)": 2556,
//       "Country": "United States",
//       "Latitude": -117.110001,
//       "Location": "Pullman",
//       "Longitude": 46.7439,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3945,
//       "Airport Code [ICAO]": "KLWS",
//       "Airport Code iATA,FAA": "LWS",
//       "Airport Name": "Lewiston Nez Perce County Airport",
//       "Altitude (Ft)": 1442,
//       "Country": "United States",
//       "Latitude": -117.01499938964844,
//       "Location": "Lewiston",
//       "Longitude": 46.3745002746582,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3946,
//       "Airport Code [ICAO]": "KELM",
//       "Airport Code iATA,FAA": "ELM",
//       "Airport Name": "Elmira Corning Regional Airport",
//       "Altitude (Ft)": 954,
//       "Country": "United States",
//       "Latitude": -76.8916015625,
//       "Location": "Elmira",
//       "Longitude": 42.1599006652832,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3947,
//       "Airport Code [ICAO]": "KITH",
//       "Airport Code iATA,FAA": "ITH",
//       "Airport Name": "Ithaca Tompkins Regional Airport",
//       "Altitude (Ft)": 1099,
//       "Country": "United States",
//       "Latitude": -76.4583969116211,
//       "Location": "Ithaca",
//       "Longitude": 42.49100112915039,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3948,
//       "Airport Code [ICAO]": "KMRY",
//       "Airport Code iATA,FAA": "MRY",
//       "Airport Name": "Monterey Peninsula Airport",
//       "Altitude (Ft)": 257,
//       "Country": "United States",
//       "Latitude": -121.84300231933594,
//       "Location": "Monterey",
//       "Longitude": 36.58700180053711,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3949,
//       "Airport Code [ICAO]": "KSBA",
//       "Airport Code iATA,FAA": "SBA",
//       "Airport Name": "Santa Barbara Municipal Airport",
//       "Altitude (Ft)": 13,
//       "Country": "United States",
//       "Latitude": -119.8399963,
//       "Location": "Santa Barbara",
//       "Longitude": 34.42620087,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 3950,
//       "Airport Code [ICAO]": "KDAB",
//       "Airport Code iATA,FAA": "DAB",
//       "Airport Name": "Daytona Beach International Airport",
//       "Altitude (Ft)": 34,
//       "Country": "United States",
//       "Latitude": -81.058098,
//       "Location": "Daytona Beach",
//       "Longitude": 29.179899,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3952,
//       "Airport Code [ICAO]": "EVLA",
//       "Airport Code iATA,FAA": "LPX",
//       "Airport Name": "Liepāja International Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Latvia",
//       "Latitude": 21.096900939941406,
//       "Location": "Liepaja",
//       "Longitude": 56.51750183105469,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Riga",
//       "Type": "airport"
//     },
//     {
//       "ID": 3953,
//       "Airport Code [ICAO]": "EVRA",
//       "Airport Code iATA,FAA": "RIX",
//       "Airport Name": "Riga International Airport",
//       "Altitude (Ft)": 36,
//       "Country": "Latvia",
//       "Latitude": 23.971099853515625,
//       "Location": "Riga",
//       "Longitude": 56.92359924316406,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Riga",
//       "Type": "airport"
//     },
//     {
//       "ID": 3954,
//       "Airport Code [ICAO]": "EYSA",
//       "Airport Code iATA,FAA": "SQQ",
//       "Airport Name": "Šiauliai International Airport",
//       "Altitude (Ft)": 443,
//       "Country": "Lithuania",
//       "Latitude": 23.395000457763672,
//       "Location": "Siauliai",
//       "Longitude": 55.89390182495117,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vilnius",
//       "Type": "airport"
//     },
//     {
//       "ID": 3955,
//       "Airport Code [ICAO]": "EYSB",
//       "Airport Code iATA,FAA": "HLJ",
//       "Airport Name": "Barysiai Airport",
//       "Altitude (Ft)": 270,
//       "Country": "Lithuania",
//       "Latitude": 23.5580997467041,
//       "Location": "Barysiai",
//       "Longitude": 56.07059860229492,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vilnius",
//       "Type": "airport"
//     },
//     {
//       "ID": 3956,
//       "Airport Code [ICAO]": "EYKA",
//       "Airport Code iATA,FAA": "KUN",
//       "Airport Name": "Kaunas International Airport",
//       "Altitude (Ft)": 256,
//       "Country": "Lithuania",
//       "Latitude": 24.084800720214844,
//       "Location": "Kaunas",
//       "Longitude": 54.96390151977539,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vilnius",
//       "Type": "airport"
//     },
//     {
//       "ID": 3957,
//       "Airport Code [ICAO]": "EYKS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "S. Darius and S. Girėnas Airfield",
//       "Altitude (Ft)": 246,
//       "Country": "Lithuania",
//       "Latitude": 23.8815,
//       "Location": "Kaunas",
//       "Longitude": 54.879799,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vilnius",
//       "Type": "airport"
//     },
//     {
//       "ID": 3958,
//       "Airport Code [ICAO]": "EYPA",
//       "Airport Code iATA,FAA": "PLQ",
//       "Airport Name": "Palanga International Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Lithuania",
//       "Latitude": 21.093900680541992,
//       "Location": "Palanga",
//       "Longitude": 55.973201751708984,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vilnius",
//       "Type": "airport"
//     },
//     {
//       "ID": 3959,
//       "Airport Code [ICAO]": "EYVI",
//       "Airport Code iATA,FAA": "VNO",
//       "Airport Name": "Vilnius International Airport",
//       "Altitude (Ft)": 648,
//       "Country": "Lithuania",
//       "Latitude": 25.285801,
//       "Location": "Vilnius",
//       "Longitude": 54.634102,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vilnius",
//       "Type": "airport"
//     },
//     {
//       "ID": 3960,
//       "Airport Code [ICAO]": "EYPP",
//       "Airport Code iATA,FAA": "PNV",
//       "Airport Name": "Panevėžys Air Base",
//       "Altitude (Ft)": 197,
//       "Country": "Lithuania",
//       "Latitude": 24.460800170898438,
//       "Location": "Panevezys",
//       "Longitude": 55.729400634765625,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vilnius",
//       "Type": "airport"
//     },
//     {
//       "ID": 3962,
//       "Airport Code [ICAO]": "UDYE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Erebuni Airport",
//       "Altitude (Ft)": 2948,
//       "Country": "Armenia",
//       "Latitude": 44.465000152600005,
//       "Location": "Yerevan",
//       "Longitude": 40.122100830099996,
//       "GMT Offset": "4",
//       "Daylight Savings": "E",
//       "Region": "Asia/Yerevan",
//       "Type": "airport"
//     },
//     {
//       "ID": 3963,
//       "Airport Code [ICAO]": "UDLS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Stepanavan Airport",
//       "Altitude (Ft)": 4836,
//       "Country": "Armenia",
//       "Latitude": 44.337200164799995,
//       "Location": "Stepanavan",
//       "Longitude": 41.048500061,
//       "GMT Offset": "4",
//       "Daylight Savings": "E",
//       "Region": "Asia/Yerevan",
//       "Type": "airport"
//     },
//     {
//       "ID": 3964,
//       "Airport Code [ICAO]": "UDYZ",
//       "Airport Code iATA,FAA": "EVN",
//       "Airport Name": "Zvartnots International Airport",
//       "Altitude (Ft)": 2838,
//       "Country": "Armenia",
//       "Latitude": 44.3959007263,
//       "Location": "Yerevan",
//       "Longitude": 40.1473007202,
//       "GMT Offset": "4",
//       "Daylight Savings": "E",
//       "Region": "Asia/Yerevan",
//       "Type": "airport"
//     },
//     {
//       "ID": 3965,
//       "Airport Code [ICAO]": "UDSG",
//       "Airport Code iATA,FAA": "LWN",
//       "Airport Name": "Gyumri Shirak Airport",
//       "Altitude (Ft)": 5000,
//       "Country": "Armenia",
//       "Latitude": 43.859298706100006,
//       "Location": "Gyumri",
//       "Longitude": 40.7504005432,
//       "GMT Offset": "4",
//       "Daylight Savings": "E",
//       "Region": "Asia/Yerevan",
//       "Type": "airport"
//     },
//     {
//       "ID": 3966,
//       "Airport Code [ICAO]": "HHSB",
//       "Airport Code iATA,FAA": "ASA",
//       "Airport Name": "Assab International Airport",
//       "Altitude (Ft)": 46,
//       "Country": "Eritrea",
//       "Latitude": 42.64500045776367,
//       "Location": "Assab",
//       "Longitude": 13.071800231933594,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Asmera",
//       "Type": "airport"
//     },
//     {
//       "ID": 3967,
//       "Airport Code [ICAO]": "HHAS",
//       "Airport Code iATA,FAA": "ASM",
//       "Airport Name": "Asmara International Airport",
//       "Altitude (Ft)": 7661,
//       "Country": "Eritrea",
//       "Latitude": 38.910701751708984,
//       "Location": "Asmara",
//       "Longitude": 15.291899681091309,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Asmera",
//       "Type": "airport"
//     },
//     {
//       "ID": 3968,
//       "Airport Code [ICAO]": "HHMS",
//       "Airport Code iATA,FAA": "MSW",
//       "Airport Name": "Massawa International Airport",
//       "Altitude (Ft)": 194,
//       "Country": "Eritrea",
//       "Latitude": 39.37009811401367,
//       "Location": "Massawa",
//       "Longitude": 15.670000076293945,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Asmera",
//       "Type": "airport"
//     },
//     {
//       "ID": 3969,
//       "Airport Code [ICAO]": "LVGZ",
//       "Airport Code iATA,FAA": "GZA",
//       "Airport Name": "Yasser Arafat International Airport",
//       "Altitude (Ft)": 320,
//       "Country": "Palestine",
//       "Latitude": 34.276100158691,
//       "Location": "Gaza",
//       "Longitude": 31.24640083313,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Asia/Gaza",
//       "Type": "airport"
//     },
//     {
//       "ID": 3971,
//       "Airport Code [ICAO]": "UGSB",
//       "Airport Code iATA,FAA": "BUS",
//       "Airport Name": "Batumi International Airport",
//       "Altitude (Ft)": 105,
//       "Country": "Georgia",
//       "Latitude": 41.5997009277,
//       "Location": "Batumi",
//       "Longitude": 41.6102981567,
//       "GMT Offset": "4",
//       "Daylight Savings": "N",
//       "Region": "Asia/Tbilisi",
//       "Type": "airport"
//     },
//     {
//       "ID": 3972,
//       "Airport Code [ICAO]": "UGKO",
//       "Airport Code iATA,FAA": "KUT",
//       "Airport Name": "Kopitnari Airport",
//       "Altitude (Ft)": 223,
//       "Country": "Georgia",
//       "Latitude": 42.4826011658,
//       "Location": "Kutaisi",
//       "Longitude": 42.176700592,
//       "GMT Offset": "4",
//       "Daylight Savings": "N",
//       "Region": "Asia/Tbilisi",
//       "Type": "airport"
//     },
//     {
//       "ID": 3973,
//       "Airport Code [ICAO]": "UGTB",
//       "Airport Code iATA,FAA": "TBS",
//       "Airport Name": "Tbilisi International Airport",
//       "Altitude (Ft)": 1624,
//       "Country": "Georgia",
//       "Latitude": 44.95470047,
//       "Location": "Tbilisi",
//       "Longitude": 41.6692008972,
//       "GMT Offset": "4",
//       "Daylight Savings": "N",
//       "Region": "Asia/Tbilisi",
//       "Type": "airport"
//     },
//     {
//       "ID": 3974,
//       "Airport Code [ICAO]": "OYRN",
//       "Airport Code iATA,FAA": "RIY",
//       "Airport Name": "Mukalla International Airport",
//       "Altitude (Ft)": 54,
//       "Country": "Yemen",
//       "Latitude": 49.375,
//       "Location": "Mukalla",
//       "Longitude": 14.662599563598633,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Aden",
//       "Type": "airport"
//     },
//     {
//       "ID": 3975,
//       "Airport Code [ICAO]": "OYTZ",
//       "Airport Code iATA,FAA": "TAI",
//       "Airport Name": "Ta'izz International Airport",
//       "Altitude (Ft)": 4838,
//       "Country": "Yemen",
//       "Latitude": 44.139099121099996,
//       "Location": "Taiz",
//       "Longitude": 13.6859998703,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Aden",
//       "Type": "airport"
//     },
//     {
//       "ID": 3976,
//       "Airport Code [ICAO]": "OYHD",
//       "Airport Code iATA,FAA": "HOD",
//       "Airport Name": "Hodeidah International Airport",
//       "Altitude (Ft)": 41,
//       "Country": "Yemen",
//       "Latitude": 42.97629928588867,
//       "Location": "Hodeidah",
//       "Longitude": 14.753000259399414,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Aden",
//       "Type": "airport"
//     },
//     {
//       "ID": 3977,
//       "Airport Code [ICAO]": "OYAA",
//       "Airport Code iATA,FAA": "ADE",
//       "Airport Name": "Aden International Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Yemen",
//       "Latitude": 45.02880096435547,
//       "Location": "Aden",
//       "Longitude": 12.829500198364258,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Aden",
//       "Type": "airport"
//     },
//     {
//       "ID": 3978,
//       "Airport Code [ICAO]": "OYAT",
//       "Airport Code iATA,FAA": "AXK",
//       "Airport Name": "Ataq Airport",
//       "Altitude (Ft)": 3735,
//       "Country": "Yemen",
//       "Latitude": 46.82619857788086,
//       "Location": "Ataq",
//       "Longitude": 14.551300048828125,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Aden",
//       "Type": "airport"
//     },
//     {
//       "ID": 3979,
//       "Airport Code [ICAO]": "OYGD",
//       "Airport Code iATA,FAA": "AAY",
//       "Airport Name": "Al Ghaidah International Airport",
//       "Altitude (Ft)": 134,
//       "Country": "Yemen",
//       "Latitude": 52.17499923706055,
//       "Location": "Al Ghaidah Intl",
//       "Longitude": 16.191699981689453,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Aden",
//       "Type": "airport"
//     },
//     {
//       "ID": 3980,
//       "Airport Code [ICAO]": "OYSN",
//       "Airport Code iATA,FAA": "SAH",
//       "Airport Name": "Sana'a International Airport",
//       "Altitude (Ft)": 7216,
//       "Country": "Yemen",
//       "Latitude": 44.21969985961914,
//       "Location": "Sanaa",
//       "Longitude": 15.476300239562988,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Aden",
//       "Type": "airport"
//     },
//     {
//       "ID": 3982,
//       "Airport Code [ICAO]": "OYBN",
//       "Airport Code iATA,FAA": "BHN",
//       "Airport Name": "Beihan Airport",
//       "Altitude (Ft)": 3800,
//       "Country": "Yemen",
//       "Latitude": 45.72010040283203,
//       "Location": "Beihan",
//       "Longitude": 14.781999588012695,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Aden",
//       "Type": "airport"
//     },
//     {
//       "ID": 3984,
//       "Airport Code [ICAO]": "OYSQ",
//       "Airport Code iATA,FAA": "SCT",
//       "Airport Name": "Socotra International Airport",
//       "Altitude (Ft)": 146,
//       "Country": "Yemen",
//       "Latitude": 53.905799865722656,
//       "Location": "Socotra",
//       "Longitude": 12.63070011138916,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Aden",
//       "Type": "airport"
//     },
//     {
//       "ID": 3985,
//       "Airport Code [ICAO]": "OYBA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Al Badie Airport",
//       "Altitude (Ft)": 908,
//       "Country": "Yemen",
//       "Latitude": 50.83689880371094,
//       "Location": "Al Badie",
//       "Longitude": 18.71929931640625,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Aden",
//       "Type": "airport"
//     },
//     {
//       "ID": 3986,
//       "Airport Code [ICAO]": "EDJA",
//       "Airport Code iATA,FAA": "FMM",
//       "Airport Name": "Memmingen Allgau Airport",
//       "Altitude (Ft)": 2077,
//       "Country": "Germany",
//       "Latitude": 10.2395000458,
//       "Location": "Memmingen",
//       "Longitude": 47.988800048799995,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 3987,
//       "Airport Code [ICAO]": "LTAZ",
//       "Airport Code iATA,FAA": "NAV",
//       "Airport Name": "Nevşehir Kapadokya Airport",
//       "Altitude (Ft)": 3100,
//       "Country": "Turkey",
//       "Latitude": 34.5345,
//       "Location": "Nevsehir",
//       "Longitude": 38.7719,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 3988,
//       "Airport Code [ICAO]": "SAEZ",
//       "Airport Code iATA,FAA": "EZE",
//       "Airport Name": "Ministro Pistarini International Airport",
//       "Altitude (Ft)": 67,
//       "Country": "Argentina",
//       "Latitude": -58.5358,
//       "Location": "Buenos Aires",
//       "Longitude": -34.8222,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Buenos_Aires",
//       "Type": "airport"
//     },
//     {
//       "ID": 3989,
//       "Airport Code [ICAO]": "ORER",
//       "Airport Code iATA,FAA": "EBL",
//       "Airport Name": "Erbil International Airport",
//       "Altitude (Ft)": 1341,
//       "Country": "Iraq",
//       "Latitude": 43.963199615478516,
//       "Location": "Erbil",
//       "Longitude": 36.23759841918945,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Baghdad",
//       "Type": "airport"
//     },
//     {
//       "ID": 3990,
//       "Airport Code [ICAO]": "YEML",
//       "Airport Code iATA,FAA": "EMD",
//       "Airport Name": "Emerald Airport",
//       "Altitude (Ft)": 624,
//       "Country": "Australia",
//       "Latitude": 148.179000854,
//       "Location": "Emerald",
//       "Longitude": -23.5674991608,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 3991,
//       "Airport Code [ICAO]": "LGAT",
//       "Airport Code iATA,FAA": "HEW",
//       "Airport Name": "Athen Helenikon Airport",
//       "Altitude (Ft)": 69,
//       "Country": "Greece",
//       "Latitude": 23.726101,
//       "Location": "Athens",
//       "Longitude": 37.893299,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 3992,
//       "Airport Code [ICAO]": "RJBB",
//       "Airport Code iATA,FAA": "KIX",
//       "Airport Name": "Kansai International Airport",
//       "Altitude (Ft)": 26,
//       "Country": "Japan",
//       "Latitude": 135.24400329589844,
//       "Location": "Osaka",
//       "Longitude": 34.42729949951172,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 3993,
//       "Airport Code [ICAO]": "KJRB",
//       "Airport Code iATA,FAA": "JRB",
//       "Airport Name": "Downtown-Manhattan/Wall St Heliport",
//       "Altitude (Ft)": 7,
//       "Country": "United States",
//       "Latitude": -74.00900269,
//       "Location": "New York",
//       "Longitude": 40.70119858,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 3994,
//       "Airport Code [ICAO]": "RPVT",
//       "Airport Code iATA,FAA": "TAG",
//       "Airport Name": "Tagbilaran Airport",
//       "Altitude (Ft)": 38,
//       "Country": "Philippines",
//       "Latitude": 123.853533,
//       "Location": "Tagbilaran",
//       "Longitude": 9.665442,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 3995,
//       "Airport Code [ICAO]": "BGJN",
//       "Airport Code iATA,FAA": "JAV",
//       "Airport Name": "Ilulissat Airport",
//       "Altitude (Ft)": 95,
//       "Country": "Greenland",
//       "Latitude": -51.0570983887,
//       "Location": "Ilulissat",
//       "Longitude": 69.2432022095,
//       "GMT Offset": "-3",
//       "Daylight Savings": "E",
//       "Region": "America/Godthab",
//       "Type": "airport"
//     },
//     {
//       "ID": 3996,
//       "Airport Code [ICAO]": "BGCH",
//       "Airport Code iATA,FAA": "JCH",
//       "Airport Name": "Qasigiannguit Heliport",
//       "Altitude (Ft)": 70,
//       "Country": "Greenland",
//       "Latitude": -51.1734473705,
//       "Location": "Qasigiannguit",
//       "Longitude": 68.822815547,
//       "GMT Offset": "-3",
//       "Daylight Savings": "E",
//       "Region": "America/Godthab",
//       "Type": "airport"
//     },
//     {
//       "ID": 3997,
//       "Airport Code [ICAO]": "BGAA",
//       "Airport Code iATA,FAA": "JEG",
//       "Airport Name": "Aasiaat Airport",
//       "Altitude (Ft)": 74,
//       "Country": "Greenland",
//       "Latitude": -52.7846984863,
//       "Location": "Aasiaat",
//       "Longitude": 68.7218017578,
//       "GMT Offset": "-3",
//       "Daylight Savings": "E",
//       "Region": "America/Godthab",
//       "Type": "airport"
//     },
//     {
//       "ID": 3998,
//       "Airport Code [ICAO]": "LEPA",
//       "Airport Code iATA,FAA": "PMI",
//       "Airport Name": "Palma De Mallorca Airport",
//       "Altitude (Ft)": 27,
//       "Country": "Spain",
//       "Latitude": 2.73881006241,
//       "Location": "Palma de Mallorca",
//       "Longitude": 39.551700592,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 3999,
//       "Airport Code [ICAO]": "YPDN",
//       "Airport Code iATA,FAA": "DRW",
//       "Airport Name": "Darwin International Airport",
//       "Altitude (Ft)": 103,
//       "Country": "Australia",
//       "Latitude": 130.8769989013672,
//       "Location": "Darwin",
//       "Longitude": -12.41469955444336,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "N",
//       "Region": "Australia/Darwin",
//       "Type": "airport"
//     },
//     {
//       "ID": 4000,
//       "Airport Code [ICAO]": "VTSB",
//       "Airport Code iATA,FAA": "URT",
//       "Airport Name": "Surat Thani Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Thailand",
//       "Latitude": 99.135597229,
//       "Location": "Surat Thani",
//       "Longitude": 9.13259983063,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 4004,
//       "Airport Code [ICAO]": "PATK",
//       "Airport Code iATA,FAA": "TKA",
//       "Airport Name": "Talkeetna Airport",
//       "Altitude (Ft)": 358,
//       "Country": "United States",
//       "Latitude": -150.09399414062,
//       "Location": "Talkeetna",
//       "Longitude": 62.320499420166,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 4005,
//       "Airport Code [ICAO]": "LMMG",
//       "Airport Code iATA,FAA": "GZM",
//       "Airport Name": "Xewkija Heliport",
//       "Altitude (Ft)": 0,
//       "Country": "Malta",
//       "Latitude": 14.2728,
//       "Location": "Gozo",
//       "Longitude": 36.027199,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Malta",
//       "Type": "airport"
//     },
//     {
//       "ID": 4006,
//       "Airport Code [ICAO]": "KHVN",
//       "Airport Code iATA,FAA": "HVN",
//       "Airport Name": "Tweed New Haven Airport",
//       "Altitude (Ft)": 12,
//       "Country": "United States",
//       "Latitude": -72.88680267,
//       "Location": "New Haven",
//       "Longitude": 41.26369858,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4007,
//       "Airport Code [ICAO]": "KAVL",
//       "Airport Code iATA,FAA": "AVL",
//       "Airport Name": "Asheville Regional Airport",
//       "Altitude (Ft)": 2165,
//       "Country": "United States",
//       "Latitude": -82.54180145263672,
//       "Location": "Asheville",
//       "Longitude": 35.43619918823242,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4008,
//       "Airport Code [ICAO]": "KGSO",
//       "Airport Code iATA,FAA": "GSO",
//       "Airport Name": "Piedmont Triad International Airport",
//       "Altitude (Ft)": 925,
//       "Country": "United States",
//       "Latitude": -79.93730163574219,
//       "Location": "Greensboro",
//       "Longitude": 36.097801208496094,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4009,
//       "Airport Code [ICAO]": "KFSD",
//       "Airport Code iATA,FAA": "FSD",
//       "Airport Name": "Joe Foss Field Airport",
//       "Altitude (Ft)": 1429,
//       "Country": "United States",
//       "Latitude": -96.741897583,
//       "Location": "Sioux Falls",
//       "Longitude": 43.582000732400004,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4010,
//       "Airport Code [ICAO]": "YAYE",
//       "Airport Code iATA,FAA": "AYQ",
//       "Airport Name": "Ayers Rock Connellan Airport",
//       "Altitude (Ft)": 1626,
//       "Country": "Australia",
//       "Latitude": 130.975998,
//       "Location": "Uluru",
//       "Longitude": -25.1861,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "N",
//       "Region": "Australia/Darwin",
//       "Type": "airport"
//     },
//     {
//       "ID": 4011,
//       "Airport Code [ICAO]": "KMHT",
//       "Airport Code iATA,FAA": "MHT",
//       "Airport Name": "Manchester-Boston Regional Airport",
//       "Altitude (Ft)": 266,
//       "Country": "United States",
//       "Latitude": -71.435699,
//       "Location": "Manchester NH",
//       "Longitude": 42.932598,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4012,
//       "Airport Code [ICAO]": "KAPF",
//       "Airport Code iATA,FAA": "APF",
//       "Airport Name": "Naples Municipal Airport",
//       "Altitude (Ft)": 8,
//       "Country": "United States",
//       "Latitude": -81.7752990723,
//       "Location": "Naples",
//       "Longitude": 26.1525993347,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4013,
//       "Airport Code [ICAO]": "WMPR",
//       "Airport Code iATA,FAA": "RDN",
//       "Airport Name": "LTS Pulau Redang Airport",
//       "Altitude (Ft)": 36,
//       "Country": "Malaysia",
//       "Latitude": 103.00700378417969,
//       "Location": "Redang",
//       "Longitude": 5.765279769897461,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 4014,
//       "Airport Code [ICAO]": "KSDF",
//       "Airport Code iATA,FAA": "SDF",
//       "Airport Name": "Louisville International Standiford Field",
//       "Altitude (Ft)": 501,
//       "Country": "United States",
//       "Latitude": -85.736,
//       "Location": "Louisville",
//       "Longitude": 38.1744,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4015,
//       "Airport Code [ICAO]": "KCHO",
//       "Airport Code iATA,FAA": "CHO",
//       "Airport Name": "Charlottesville Albemarle Airport",
//       "Altitude (Ft)": 639,
//       "Country": "United States",
//       "Latitude": -78.4529037475586,
//       "Location": "Charlottesville VA",
//       "Longitude": 38.13859939575195,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4016,
//       "Airport Code [ICAO]": "KROA",
//       "Airport Code iATA,FAA": "ROA",
//       "Airport Name": "Roanoke–Blacksburg Regional Airport",
//       "Altitude (Ft)": 1175,
//       "Country": "United States",
//       "Latitude": -79.975403,
//       "Location": "Roanoke VA",
//       "Longitude": 37.3255,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4017,
//       "Airport Code [ICAO]": "KLEX",
//       "Airport Code iATA,FAA": "LEX",
//       "Airport Name": "Blue Grass Airport",
//       "Altitude (Ft)": 979,
//       "Country": "United States",
//       "Latitude": -84.60590362548828,
//       "Location": "Lexington KY",
//       "Longitude": 38.0364990234375,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4018,
//       "Airport Code [ICAO]": "KEVV",
//       "Airport Code iATA,FAA": "EVV",
//       "Airport Name": "Evansville Regional Airport",
//       "Altitude (Ft)": 418,
//       "Country": "United States",
//       "Latitude": -87.5324020386,
//       "Location": "Evansville",
//       "Longitude": 38.0369987488,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4019,
//       "Airport Code [ICAO]": "KABQ",
//       "Airport Code iATA,FAA": "ABQ",
//       "Airport Name": "Albuquerque International Sunport",
//       "Altitude (Ft)": 5355,
//       "Country": "United States",
//       "Latitude": -106.609001,
//       "Location": "Albuquerque",
//       "Longitude": 35.040199,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4020,
//       "Airport Code [ICAO]": "KBZN",
//       "Airport Code iATA,FAA": "BZN",
//       "Airport Name": "Gallatin Field",
//       "Altitude (Ft)": 4473,
//       "Country": "United States",
//       "Latitude": -111.1529999,
//       "Location": "Bozeman",
//       "Longitude": 45.77750015,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4021,
//       "Airport Code [ICAO]": "KBIL",
//       "Airport Code iATA,FAA": "BIL",
//       "Airport Name": "Billings Logan International Airport",
//       "Altitude (Ft)": 3652,
//       "Country": "United States",
//       "Latitude": -108.54299926757812,
//       "Location": "Billings",
//       "Longitude": 45.807701110839844,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4022,
//       "Airport Code [ICAO]": "KBTM",
//       "Airport Code iATA,FAA": "BTM",
//       "Airport Name": "Bert Mooney Airport",
//       "Altitude (Ft)": 5550,
//       "Country": "United States",
//       "Latitude": -112.49700164794922,
//       "Location": "Butte",
//       "Longitude": 45.95479965209961,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4023,
//       "Airport Code [ICAO]": "KTVC",
//       "Airport Code iATA,FAA": "TVC",
//       "Airport Name": "Cherry Capital Airport",
//       "Altitude (Ft)": 624,
//       "Country": "United States",
//       "Latitude": -85.58219909667969,
//       "Location": "Traverse City",
//       "Longitude": 44.74140167236328,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4024,
//       "Airport Code [ICAO]": "MGTK",
//       "Airport Code iATA,FAA": "FRS",
//       "Airport Name": "Mundo Maya International Airport",
//       "Altitude (Ft)": 427,
//       "Country": "Guatemala",
//       "Latitude": -89.86640167239999,
//       "Location": "Flores",
//       "Longitude": 16.913799285899998,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Guatemala",
//       "Type": "airport"
//     },
//     {
//       "ID": 4025,
//       "Airport Code [ICAO]": "KBHB",
//       "Airport Code iATA,FAA": "BHB",
//       "Airport Name": "Hancock County-Bar Harbor Airport",
//       "Altitude (Ft)": 83,
//       "Country": "United States",
//       "Latitude": -68.3615036,
//       "Location": "Bar Harbor",
//       "Longitude": 44.45000076,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4026,
//       "Airport Code [ICAO]": "KRKD",
//       "Airport Code iATA,FAA": "RKD",
//       "Airport Name": "Knox County Regional Airport",
//       "Altitude (Ft)": 56,
//       "Country": "United States",
//       "Latitude": -69.09919739,
//       "Location": "Rockland",
//       "Longitude": 44.06010056,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4027,
//       "Airport Code [ICAO]": "KJAC",
//       "Airport Code iATA,FAA": "JAC",
//       "Airport Name": "Jackson Hole Airport",
//       "Altitude (Ft)": 6451,
//       "Country": "United States",
//       "Latitude": -110.73799896240234,
//       "Location": "Jacksn Hole",
//       "Longitude": 43.6072998046875,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4028,
//       "Airport Code [ICAO]": "KRFD",
//       "Airport Code iATA,FAA": "RFD",
//       "Airport Name": "Chicago Rockford International Airport",
//       "Altitude (Ft)": 742,
//       "Country": "United States",
//       "Latitude": -89.09719848632812,
//       "Location": "Rockford",
//       "Longitude": 42.19540023803711,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4029,
//       "Airport Code [ICAO]": "UUDD",
//       "Airport Code iATA,FAA": "DME",
//       "Airport Name": "Domodedovo International Airport",
//       "Altitude (Ft)": 588,
//       "Country": "Russia",
//       "Latitude": 37.90629959106445,
//       "Location": "Moscow",
//       "Longitude": 55.40879821777344,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 4030,
//       "Airport Code [ICAO]": "ZJSY",
//       "Airport Code iATA,FAA": "SYX",
//       "Airport Name": "Sanya Phoenix International Airport",
//       "Altitude (Ft)": 92,
//       "Country": "China",
//       "Latitude": 109.41200256347656,
//       "Location": "Sanya",
//       "Longitude": 18.302900314331055,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 4031,
//       "Airport Code [ICAO]": "NZMF",
//       "Airport Code iATA,FAA": "MFN",
//       "Airport Name": "Milford Sound Airport",
//       "Altitude (Ft)": 10,
//       "Country": "New Zealand",
//       "Latitude": 167.92300415039062,
//       "Location": "Milford Sound",
//       "Longitude": -44.673301696777344,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 4033,
//       "Airport Code [ICAO]": "ZPLJ",
//       "Airport Code iATA,FAA": "LJG",
//       "Airport Name": "Lijiang Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 100.246002197,
//       "Location": "Lijiang",
//       "Longitude": 26.6800003052,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 4034,
//       "Airport Code [ICAO]": "KGSP",
//       "Airport Code iATA,FAA": "GSP",
//       "Airport Name": "Greenville Spartanburg International Airport",
//       "Altitude (Ft)": 964,
//       "Country": "United States",
//       "Latitude": -82.2189025879,
//       "Location": "Greenville",
//       "Longitude": 34.8956985474,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4037,
//       "Airport Code [ICAO]": "KBMI",
//       "Airport Code iATA,FAA": "BMI",
//       "Airport Name": "Central Illinois Regional Airport at Bloomington-Normal",
//       "Altitude (Ft)": 871,
//       "Country": "United States",
//       "Latitude": -88.91590118,
//       "Location": "Bloomington",
//       "Longitude": 40.47710037,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4038,
//       "Airport Code [ICAO]": "KGPT",
//       "Airport Code iATA,FAA": "GPT",
//       "Airport Name": "Gulfport Biloxi International Airport",
//       "Altitude (Ft)": 28,
//       "Country": "United States",
//       "Latitude": -89.07009887695312,
//       "Location": "Gulfport",
//       "Longitude": 30.40730094909668,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4039,
//       "Airport Code [ICAO]": "KAZO",
//       "Airport Code iATA,FAA": "AZO",
//       "Airport Name": "Kalamazoo Battle Creek International Airport",
//       "Altitude (Ft)": 874,
//       "Country": "United States",
//       "Latitude": -85.5521011352539,
//       "Location": "Kalamazoo",
//       "Longitude": 42.234901428222656,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4040,
//       "Airport Code [ICAO]": "KTOL",
//       "Airport Code iATA,FAA": "TOL",
//       "Airport Name": "Toledo Express Airport",
//       "Altitude (Ft)": 683,
//       "Country": "United States",
//       "Latitude": -83.80780029,
//       "Location": "Toledo",
//       "Longitude": 41.58679962,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4041,
//       "Airport Code [ICAO]": "KFWA",
//       "Airport Code iATA,FAA": "FWA",
//       "Airport Name": "Fort Wayne International Airport",
//       "Altitude (Ft)": 814,
//       "Country": "United States",
//       "Latitude": -85.19509888,
//       "Location": "Fort Wayne",
//       "Longitude": 40.97850037,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4042,
//       "Airport Code [ICAO]": "KDEC",
//       "Airport Code iATA,FAA": "DEC",
//       "Airport Name": "Decatur Airport",
//       "Altitude (Ft)": 682,
//       "Country": "United States",
//       "Latitude": -88.8656997680664,
//       "Location": "Decatur",
//       "Longitude": 39.834598541259766,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4043,
//       "Airport Code [ICAO]": "KCID",
//       "Airport Code iATA,FAA": "CID",
//       "Airport Name": "The Eastern Iowa Airport",
//       "Altitude (Ft)": 869,
//       "Country": "United States",
//       "Latitude": -91.71080017089844,
//       "Location": "Cedar Rapids",
//       "Longitude": 41.884700775146484,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4044,
//       "Airport Code [ICAO]": "KLSE",
//       "Airport Code iATA,FAA": "LSE",
//       "Airport Name": "La Crosse Municipal Airport",
//       "Altitude (Ft)": 655,
//       "Country": "United States",
//       "Latitude": -91.256699,
//       "Location": "La Crosse",
//       "Longitude": 43.879002,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4045,
//       "Airport Code [ICAO]": "KCWA",
//       "Airport Code iATA,FAA": "CWA",
//       "Airport Name": "Central Wisconsin Airport",
//       "Altitude (Ft)": 1277,
//       "Country": "United States",
//       "Latitude": -89.6668014526,
//       "Location": "Wassau",
//       "Longitude": 44.7775993347,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4046,
//       "Airport Code [ICAO]": "KPIA",
//       "Airport Code iATA,FAA": "PIA",
//       "Airport Name": "General Wayne A. Downing Peoria International Airport",
//       "Altitude (Ft)": 660,
//       "Country": "United States",
//       "Latitude": -89.6932983398,
//       "Location": "Peoria",
//       "Longitude": 40.664199829100006,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4047,
//       "Airport Code [ICAO]": "KATW",
//       "Airport Code iATA,FAA": "ATW",
//       "Airport Name": "Appleton International Airport",
//       "Altitude (Ft)": 918,
//       "Country": "United States",
//       "Latitude": -88.5190963745,
//       "Location": "Appleton",
//       "Longitude": 44.258098602299995,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4048,
//       "Airport Code [ICAO]": "KRST",
//       "Airport Code iATA,FAA": "RST",
//       "Airport Name": "Rochester International Airport",
//       "Altitude (Ft)": 1317,
//       "Country": "United States",
//       "Latitude": -92.5,
//       "Location": "Rochester",
//       "Longitude": 43.90829849243164,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4049,
//       "Airport Code [ICAO]": "KCMI",
//       "Airport Code iATA,FAA": "CMI",
//       "Airport Name": "University of Illinois Willard Airport",
//       "Altitude (Ft)": 755,
//       "Country": "United States",
//       "Latitude": -88.27809906,
//       "Location": "Champaign",
//       "Longitude": 40.03919983,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4050,
//       "Airport Code [ICAO]": "KMHK",
//       "Airport Code iATA,FAA": "MHK",
//       "Airport Name": "Manhattan Regional Airport",
//       "Altitude (Ft)": 1057,
//       "Country": "United States",
//       "Latitude": -96.6707992553711,
//       "Location": "Manhattan",
//       "Longitude": 39.14099884033203,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4051,
//       "Airport Code [ICAO]": "YKSC",
//       "Airport Code iATA,FAA": "KGC",
//       "Airport Name": "Kingscote Airport",
//       "Altitude (Ft)": 24,
//       "Country": "Australia",
//       "Latitude": 137.52099609375,
//       "Location": "Kingscote",
//       "Longitude": -35.71390151977539,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Adelaide",
//       "Type": "airport"
//     },
//     {
//       "ID": 4052,
//       "Airport Code [ICAO]": "YHBA",
//       "Airport Code iATA,FAA": "HVB",
//       "Airport Name": "Hervey Bay Airport",
//       "Altitude (Ft)": 60,
//       "Country": "Australia",
//       "Latitude": 152.880004883,
//       "Location": "Hervey Bay",
//       "Longitude": -25.3188991547,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 4054,
//       "Airport Code [ICAO]": "ZPDL",
//       "Airport Code iATA,FAA": "DLU",
//       "Airport Name": "Dali Airport",
//       "Altitude (Ft)": 7050,
//       "Country": "China",
//       "Latitude": 100.319,
//       "Location": "Dali",
//       "Longitude": 25.649401,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 4056,
//       "Airport Code [ICAO]": "WBMU",
//       "Airport Code iATA,FAA": "MZV",
//       "Airport Name": "Mulu Airport",
//       "Altitude (Ft)": 80,
//       "Country": "Malaysia",
//       "Latitude": 114.80500030517578,
//       "Location": "Mulu",
//       "Longitude": 4.048329830169678,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 4057,
//       "Airport Code [ICAO]": "HESH",
//       "Airport Code iATA,FAA": "SSH",
//       "Airport Name": "Sharm El Sheikh International Airport",
//       "Altitude (Ft)": 143,
//       "Country": "Egypt",
//       "Latitude": 34.3950004578,
//       "Location": "Sharm El Sheikh",
//       "Longitude": 27.9773006439,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Cairo",
//       "Type": "airport"
//     },
//     {
//       "ID": 4058,
//       "Airport Code [ICAO]": "KFKL",
//       "Airport Code iATA,FAA": "FKL",
//       "Airport Name": "Venango Regional Airport",
//       "Altitude (Ft)": 1540,
//       "Country": "United States",
//       "Latitude": -79.8603973389,
//       "Location": "Franklin",
//       "Longitude": 41.3778991699,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4059,
//       "Airport Code [ICAO]": "HKJK",
//       "Airport Code iATA,FAA": "NBO",
//       "Airport Name": "Jomo Kenyatta International Airport",
//       "Altitude (Ft)": 5330,
//       "Country": "Kenya",
//       "Latitude": 36.9277992249,
//       "Location": "Nairobi",
//       "Longitude": -1.31923997402,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 4060,
//       "Airport Code [ICAO]": "HTSN",
//       "Airport Code iATA,FAA": "SEU",
//       "Airport Name": "Seronera Airport",
//       "Altitude (Ft)": 5080,
//       "Country": "Tanzania",
//       "Latitude": 34.822498,
//       "Location": "Seronera",
//       "Longitude": -2.45806,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Dar_es_Salaam",
//       "Type": "airport"
//     },
//     {
//       "ID": 4061,
//       "Airport Code [ICAO]": "SAWC",
//       "Airport Code iATA,FAA": "FTE",
//       "Airport Name": "El Calafate Airport",
//       "Altitude (Ft)": 669,
//       "Country": "Argentina",
//       "Latitude": -72.053101,
//       "Location": "El Calafate",
//       "Longitude": -50.2803,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Rio_Gallegos",
//       "Type": "airport"
//     },
//     {
//       "ID": 4062,
//       "Airport Code [ICAO]": "YARM",
//       "Airport Code iATA,FAA": "ARM",
//       "Airport Name": "Armidale Airport",
//       "Altitude (Ft)": 3556,
//       "Country": "Australia",
//       "Latitude": 151.617004395,
//       "Location": "Armidale",
//       "Longitude": -30.528099060099997,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 4063,
//       "Airport Code [ICAO]": "KGJT",
//       "Airport Code iATA,FAA": "GJT",
//       "Airport Name": "Grand Junction Regional Airport",
//       "Altitude (Ft)": 4858,
//       "Country": "United States",
//       "Latitude": -108.527000427,
//       "Location": "Grand Junction",
//       "Longitude": 39.1223983765,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4064,
//       "Airport Code [ICAO]": "KSGU",
//       "Airport Code iATA,FAA": "SGU",
//       "Airport Name": "St George Municipal Airport",
//       "Altitude (Ft)": 2941,
//       "Country": "United States",
//       "Latitude": -113.510306,
//       "Location": "Saint George",
//       "Longitude": 37.036389,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4065,
//       "Airport Code [ICAO]": "KDWH",
//       "Airport Code iATA,FAA": "DWH",
//       "Airport Name": "David Wayne Hooks Memorial Airport",
//       "Altitude (Ft)": 152,
//       "Country": "United States",
//       "Latitude": -95.55280303960001,
//       "Location": "Houston",
//       "Longitude": 30.0618000031,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4066,
//       "Airport Code [ICAO]": "XS46",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Port O'Connor Private Heliport",
//       "Altitude (Ft)": 8,
//       "Country": "United States",
//       "Latitude": -96.444419,
//       "Location": "Port O\\'Connor",
//       "Longitude": 28.429725,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4067,
//       "Airport Code [ICAO]": "KSRQ",
//       "Airport Code iATA,FAA": "SRQ",
//       "Airport Name": "Sarasota Bradenton International Airport",
//       "Altitude (Ft)": 30,
//       "Country": "United States",
//       "Latitude": -82.55439758300781,
//       "Location": "Sarasota",
//       "Longitude": 27.39539909362793,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4069,
//       "Airport Code [ICAO]": "TXKF",
//       "Airport Code iATA,FAA": "BDA",
//       "Airport Name": "L.F. Wade International International Airport",
//       "Altitude (Ft)": 12,
//       "Country": "Bermuda",
//       "Latitude": -64.67870330810547,
//       "Location": "Bermuda",
//       "Longitude": 32.36399841308594,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "Atlantic/Bermuda",
//       "Type": "airport"
//     },
//     {
//       "ID": 4071,
//       "Airport Code [ICAO]": "KVNY",
//       "Airport Code iATA,FAA": "VNY",
//       "Airport Name": "Van Nuys Airport",
//       "Altitude (Ft)": 802,
//       "Country": "United States",
//       "Latitude": -118.48999786377,
//       "Location": "Van Nuys",
//       "Longitude": 34.209800720215,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 4072,
//       "Airport Code [ICAO]": "KMLI",
//       "Airport Code iATA,FAA": "MLI",
//       "Airport Name": "Quad City International Airport",
//       "Altitude (Ft)": 590,
//       "Country": "United States",
//       "Latitude": -90.50749969482422,
//       "Location": "Moline",
//       "Longitude": 41.44850158691406,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4073,
//       "Airport Code [ICAO]": "KPFN",
//       "Airport Code iATA,FAA": "PFN",
//       "Airport Name": "Panama City-Bay Co International Airport",
//       "Altitude (Ft)": 20,
//       "Country": "United States",
//       "Latitude": -85.6828,
//       "Location": "Panama City",
//       "Longitude": 30.212099,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4074,
//       "Airport Code [ICAO]": "AGGH",
//       "Airport Code iATA,FAA": "HIR",
//       "Airport Name": "Honiara International Airport",
//       "Altitude (Ft)": 28,
//       "Country": "Solomon Islands",
//       "Latitude": 160.05499267578,
//       "Location": "Honiara",
//       "Longitude": -9.4280004501343,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Guadalcanal",
//       "Type": "airport"
//     },
//     {
//       "ID": 4075,
//       "Airport Code [ICAO]": "NTAA",
//       "Airport Code iATA,FAA": "PPT",
//       "Airport Name": "Faa'a International Airport",
//       "Altitude (Ft)": 5,
//       "Country": "French Polynesia",
//       "Latitude": -149.606995,
//       "Location": "Papeete",
//       "Longitude": -17.553699,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tahiti",
//       "Type": "airport"
//     },
//     {
//       "ID": 4076,
//       "Airport Code [ICAO]": "ANYN",
//       "Airport Code iATA,FAA": "INU",
//       "Airport Name": "Nauru International Airport",
//       "Altitude (Ft)": 22,
//       "Country": "Nauru",
//       "Latitude": 166.919006,
//       "Location": "Nauru",
//       "Longitude": -0.547458,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Nauru",
//       "Type": "airport"
//     },
//     {
//       "ID": 4077,
//       "Airport Code [ICAO]": "NGFU",
//       "Airport Code iATA,FAA": "FUN",
//       "Airport Name": "Funafuti International Airport",
//       "Altitude (Ft)": 9,
//       "Country": "Tuvalu",
//       "Latitude": 179.195999,
//       "Location": "Funafuti",
//       "Longitude": -8.525,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Funafuti",
//       "Type": "airport"
//     },
//     {
//       "ID": 4078,
//       "Airport Code [ICAO]": "UNNT",
//       "Airport Code iATA,FAA": "OVB",
//       "Airport Name": "Tolmachevo Airport",
//       "Altitude (Ft)": 365,
//       "Country": "Russia",
//       "Latitude": 82.650703430176,
//       "Location": "Novosibirsk",
//       "Longitude": 55.012599945068,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Krasnoyarsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 4080,
//       "Airport Code [ICAO]": "EKSS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Samsø Airport",
//       "Altitude (Ft)": 1,
//       "Country": "Denmark",
//       "Latitude": 10.613699913024902,
//       "Location": "Samsoe",
//       "Longitude": 55.88949966430664,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Copenhagen",
//       "Type": "airport"
//     },
//     {
//       "ID": 4081,
//       "Airport Code [ICAO]": "VLXK",
//       "Airport Code iATA,FAA": "XKH",
//       "Airport Name": "Xieng Khouang Airport",
//       "Altitude (Ft)": 3445,
//       "Country": "Laos",
//       "Latitude": 103.157997,
//       "Location": "Phon Savan",
//       "Longitude": 19.450001,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Vientiane",
//       "Type": "airport"
//     },
//     {
//       "ID": 4083,
//       "Airport Code [ICAO]": "KBIS",
//       "Airport Code iATA,FAA": "BIS",
//       "Airport Name": "Bismarck Municipal Airport",
//       "Altitude (Ft)": 1661,
//       "Country": "United States",
//       "Latitude": -100.74600219726562,
//       "Location": "Bismarck",
//       "Longitude": 46.772701263427734,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4084,
//       "Airport Code [ICAO]": "KTEX",
//       "Airport Code iATA,FAA": "TEX",
//       "Airport Name": "Telluride Regional Airport",
//       "Altitude (Ft)": 9070,
//       "Country": "United States",
//       "Latitude": -107.9079971,
//       "Location": "Telluride",
//       "Longitude": 37.9538002,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4085,
//       "Airport Code [ICAO]": "ZLIC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Yinchuan Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 106.009003,
//       "Location": "Yinchuan",
//       "Longitude": 38.481899,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 4086,
//       "Airport Code [ICAO]": "VTCH",
//       "Airport Code iATA,FAA": "HGN",
//       "Airport Name": "Mae Hong Son Airport",
//       "Altitude (Ft)": 929,
//       "Country": "Thailand",
//       "Latitude": 97.97579956054688,
//       "Location": "Mae Hong Son",
//       "Longitude": 19.301300048828125,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 4087,
//       "Airport Code [ICAO]": "KRAP",
//       "Airport Code iATA,FAA": "RAP",
//       "Airport Name": "Rapid City Regional Airport",
//       "Altitude (Ft)": 3204,
//       "Country": "United States",
//       "Latitude": -103.05699920654297,
//       "Location": "Rapid City",
//       "Longitude": 44.0452995300293,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4088,
//       "Airport Code [ICAO]": "KCRQ",
//       "Airport Code iATA,FAA": "CLD",
//       "Airport Name": "Mc Clellan-Palomar Airport",
//       "Altitude (Ft)": 331,
//       "Country": "United States",
//       "Latitude": -117.2799988,
//       "Location": "Carlsbad",
//       "Longitude": 33.12829971,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 4089,
//       "Airport Code [ICAO]": "KFNT",
//       "Airport Code iATA,FAA": "FNT",
//       "Airport Name": "Bishop International Airport",
//       "Altitude (Ft)": 782,
//       "Country": "United States",
//       "Latitude": -83.74359893798828,
//       "Location": "Flint",
//       "Longitude": 42.96540069580078,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4090,
//       "Airport Code [ICAO]": "RPMD",
//       "Airport Code iATA,FAA": "DVO",
//       "Airport Name": "Francisco Bangoy International Airport",
//       "Altitude (Ft)": 96,
//       "Country": "Philippines",
//       "Latitude": 125.64600372314453,
//       "Location": "Davao",
//       "Longitude": 7.1255202293396,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 4091,
//       "Airport Code [ICAO]": "LPMA",
//       "Airport Code iATA,FAA": "FNC",
//       "Airport Name": "Madeira Airport",
//       "Altitude (Ft)": 192,
//       "Country": "Portugal",
//       "Latitude": -16.7745,
//       "Location": "Funchal",
//       "Longitude": 32.697899,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Lisbon",
//       "Type": "airport"
//     },
//     {
//       "ID": 4092,
//       "Airport Code [ICAO]": "SBSN",
//       "Airport Code iATA,FAA": "STM",
//       "Airport Name": "Maestro Wilson Fonseca Airport",
//       "Altitude (Ft)": 198,
//       "Country": "Brazil",
//       "Latitude": -54.785831451416016,
//       "Location": "Santarem",
//       "Longitude": -2.4247219562530518,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Belem",
//       "Type": "airport"
//     },
//     {
//       "ID": 4093,
//       "Airport Code [ICAO]": "VDSV",
//       "Airport Code iATA,FAA": "KOS",
//       "Airport Name": "Sihanoukville International Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Cambodia",
//       "Latitude": 103.637001038,
//       "Location": "Sihanoukville",
//       "Longitude": 10.57970047,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Phnom_Penh",
//       "Type": "airport"
//     },
//     {
//       "ID": 4094,
//       "Airport Code [ICAO]": "CYOA",
//       "Airport Code iATA,FAA": "YOA",
//       "Airport Name": "Ekati Airport",
//       "Altitude (Ft)": 1536,
//       "Country": "Canada",
//       "Latitude": -110.614997864,
//       "Location": "Ekati",
//       "Longitude": 64.6988983154,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 4095,
//       "Airport Code [ICAO]": "NZNR",
//       "Airport Code iATA,FAA": "NPE",
//       "Airport Name": "Hawke's Bay Airport",
//       "Altitude (Ft)": 6,
//       "Country": "New Zealand",
//       "Latitude": 176.869995,
//       "Location": "NAPIER",
//       "Longitude": -39.465801,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 4096,
//       "Airport Code [ICAO]": "NFNB",
//       "Airport Code iATA,FAA": "LEV",
//       "Airport Name": "Levuka Airfield",
//       "Altitude (Ft)": 11,
//       "Country": "Fiji",
//       "Latitude": 178.759002686,
//       "Location": "Levuka",
//       "Longitude": -17.7110996246,
//       "GMT Offset": "12",
//       "Daylight Savings": "N",
//       "Region": "Pacific/Fiji",
//       "Type": "airport"
//     },
//     {
//       "ID": 4097,
//       "Airport Code [ICAO]": "ZULS",
//       "Airport Code iATA,FAA": "LXA",
//       "Airport Name": "Lhasa Gonggar Airport",
//       "Altitude (Ft)": 11713,
//       "Country": "China",
//       "Latitude": 90.91190338130001,
//       "Location": "Lhasa",
//       "Longitude": 29.2978000641,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 4098,
//       "Airport Code [ICAO]": "KRDD",
//       "Airport Code iATA,FAA": "RDD",
//       "Airport Name": "Redding Municipal Airport",
//       "Altitude (Ft)": 505,
//       "Country": "United States",
//       "Latitude": -122.2929993,
//       "Location": "Redding",
//       "Longitude": 40.50899887,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 4099,
//       "Airport Code [ICAO]": "KEUG",
//       "Airport Code iATA,FAA": "EUG",
//       "Airport Name": "Mahlon Sweet Field",
//       "Altitude (Ft)": 374,
//       "Country": "United States",
//       "Latitude": -123.21199798583984,
//       "Location": "Eugene",
//       "Longitude": 44.12459945678711,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 4100,
//       "Airport Code [ICAO]": "KIDA",
//       "Airport Code iATA,FAA": "IDA",
//       "Airport Name": "Idaho Falls Regional Airport",
//       "Altitude (Ft)": 4744,
//       "Country": "United States",
//       "Latitude": -112.070999,
//       "Location": "Idaho Falls",
//       "Longitude": 43.514599,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4101,
//       "Airport Code [ICAO]": "KMFR",
//       "Airport Code iATA,FAA": "MFR",
//       "Airport Name": "Rogue Valley International Medford Airport",
//       "Altitude (Ft)": 1335,
//       "Country": "United States",
//       "Latitude": -122.87300109863281,
//       "Location": "Medford",
//       "Longitude": 42.37419891357422,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 4102,
//       "Airport Code [ICAO]": "NZKI",
//       "Airport Code iATA,FAA": "KBZ",
//       "Airport Name": "Kaikoura Airport",
//       "Altitude (Ft)": 20,
//       "Country": "New Zealand",
//       "Latitude": 173.60499572753906,
//       "Location": "Kaikoura",
//       "Longitude": -42.42499923706055,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 4103,
//       "Airport Code [ICAO]": "KRDM",
//       "Airport Code iATA,FAA": "RDM",
//       "Airport Name": "Roberts Field",
//       "Altitude (Ft)": 3080,
//       "Country": "United States",
//       "Latitude": -121.1500015,
//       "Location": "Redmond-Bend",
//       "Longitude": 44.2541008,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 4104,
//       "Airport Code [ICAO]": "NZPN",
//       "Airport Code iATA,FAA": "PCN",
//       "Airport Name": "Picton Aerodrome",
//       "Altitude (Ft)": 161,
//       "Country": "New Zealand",
//       "Latitude": 173.95599365234,
//       "Location": "Picton",
//       "Longitude": -41.346099853516,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 4105,
//       "Airport Code [ICAO]": "FYWH",
//       "Airport Code iATA,FAA": "WDH",
//       "Airport Name": "Hosea Kutako International Airport",
//       "Altitude (Ft)": 5640,
//       "Country": "Namibia",
//       "Latitude": 17.4709,
//       "Location": "Windhoek",
//       "Longitude": -22.4799,
//       "GMT Offset": "1",
//       "Daylight Savings": "S",
//       "Region": "Africa/Windhoek",
//       "Type": "airport"
//     },
//     {
//       "ID": 4106,
//       "Airport Code [ICAO]": "CYWH",
//       "Airport Code iATA,FAA": "YWH",
//       "Airport Name": "Victoria Harbour Seaplane Base",
//       "Altitude (Ft)": 0,
//       "Country": "Canada",
//       "Latitude": -123.388867378,
//       "Location": "Victoria",
//       "Longitude": 48.4249858939,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4107,
//       "Airport Code [ICAO]": "CAQ3",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Coal Harbour Seaplane Base",
//       "Altitude (Ft)": 0,
//       "Country": "Canada",
//       "Latitude": -127.583000183,
//       "Location": "Vancouver",
//       "Longitude": 50.5999984741,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4108,
//       "Airport Code [ICAO]": "ZSJN",
//       "Airport Code iATA,FAA": "TNA",
//       "Airport Name": "Yaoqiang Airport",
//       "Altitude (Ft)": 76,
//       "Country": "China",
//       "Latitude": 117.21600341796875,
//       "Location": "Jinan",
//       "Longitude": 36.857200622558594,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 4109,
//       "Airport Code [ICAO]": "ZSCG",
//       "Airport Code iATA,FAA": "CZX",
//       "Airport Name": "Changzhou Benniu Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 119.778999,
//       "Location": "Changzhou",
//       "Longitude": 31.919701,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 4110,
//       "Airport Code [ICAO]": "ZUYB",
//       "Airport Code iATA,FAA": "YBP",
//       "Airport Name": "Yibin Caiba Airport",
//       "Altitude (Ft)": 924,
//       "Country": "China",
//       "Latitude": 104.545,
//       "Location": "Yibin",
//       "Longitude": 28.8005555556,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 4111,
//       "Airport Code [ICAO]": "USTR",
//       "Airport Code iATA,FAA": "TJM",
//       "Airport Name": "Roshchino International Airport",
//       "Altitude (Ft)": 378,
//       "Country": "Russia",
//       "Latitude": 65.3243026733,
//       "Location": "Tyumen",
//       "Longitude": 57.189601898199996,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 4112,
//       "Airport Code [ICAO]": "KCAK",
//       "Airport Code iATA,FAA": "CAK",
//       "Airport Name": "Akron Canton Regional Airport",
//       "Altitude (Ft)": 1228,
//       "Country": "United States",
//       "Latitude": -81.44219970703125,
//       "Location": "Akron",
//       "Longitude": 40.916099548339844,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4113,
//       "Airport Code [ICAO]": "KHSV",
//       "Airport Code iATA,FAA": "HSV",
//       "Airport Name": "Huntsville International Carl T Jones Field",
//       "Altitude (Ft)": 629,
//       "Country": "United States",
//       "Latitude": -86.775100708008,
//       "Location": "Huntsville",
//       "Longitude": 34.637199401855,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4114,
//       "Airport Code [ICAO]": "KPKB",
//       "Airport Code iATA,FAA": "PKB",
//       "Airport Name": "Mid Ohio Valley Regional Airport",
//       "Altitude (Ft)": 858,
//       "Country": "United States",
//       "Latitude": -81.43920135498047,
//       "Location": "PARKERSBURG",
//       "Longitude": 39.34510040283203,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4115,
//       "Airport Code [ICAO]": "KMGM",
//       "Airport Code iATA,FAA": "MGM",
//       "Airport Name": "Montgomery Regional (Dannelly Field) Airport",
//       "Altitude (Ft)": 221,
//       "Country": "United States",
//       "Latitude": -86.39399719,
//       "Location": "MONTGOMERY",
//       "Longitude": 32.30059814,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4116,
//       "Airport Code [ICAO]": "KTRI",
//       "Airport Code iATA,FAA": "TRI",
//       "Airport Name": "Tri-Cities Regional TN/VA Airport",
//       "Altitude (Ft)": 1519,
//       "Country": "United States",
//       "Latitude": -82.407401,
//       "Location": "BRISTOL",
//       "Longitude": 36.475201,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4117,
//       "Airport Code [ICAO]": "KPAH",
//       "Airport Code iATA,FAA": "PAH",
//       "Airport Name": "Barkley Regional Airport",
//       "Altitude (Ft)": 410,
//       "Country": "United States",
//       "Latitude": -88.7738037109375,
//       "Location": "PADUCAH",
//       "Longitude": 37.06079864501953,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4119,
//       "Airport Code [ICAO]": "HDAM",
//       "Airport Code iATA,FAA": "JIB",
//       "Airport Name": "Djibouti-Ambouli Airport",
//       "Altitude (Ft)": 49,
//       "Country": "Djibouti",
//       "Latitude": 43.15950012207031,
//       "Location": "Djibouti",
//       "Longitude": 11.547300338745117,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Djibouti",
//       "Type": "airport"
//     },
//     {
//       "ID": 4120,
//       "Airport Code [ICAO]": "ZJHK",
//       "Airport Code iATA,FAA": "HAK",
//       "Airport Name": "Haikou Meilan International Airport",
//       "Altitude (Ft)": 75,
//       "Country": "China",
//       "Latitude": 110.45899963378906,
//       "Location": "Haikou",
//       "Longitude": 19.934900283813477,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 4121,
//       "Airport Code [ICAO]": "HTMA",
//       "Airport Code iATA,FAA": "MFA",
//       "Airport Name": "Mafia Island Airport",
//       "Altitude (Ft)": 60,
//       "Country": "Tanzania",
//       "Latitude": 39.668502,
//       "Location": "Mafia Island",
//       "Longitude": -7.917478,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Dar_es_Salaam",
//       "Type": "airport"
//     },
//     {
//       "ID": 4124,
//       "Airport Code [ICAO]": "KPGA",
//       "Airport Code iATA,FAA": "PGA",
//       "Airport Name": "Page Municipal Airport",
//       "Altitude (Ft)": 4316,
//       "Country": "United States",
//       "Latitude": -111.447998,
//       "Location": "Page",
//       "Longitude": 36.92610168,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 4125,
//       "Airport Code [ICAO]": "MHUT",
//       "Airport Code iATA,FAA": "UII",
//       "Airport Name": "Utila Airport",
//       "Altitude (Ft)": 29,
//       "Country": "Honduras",
//       "Latitude": -86.880302,
//       "Location": "Utila",
//       "Longitude": 16.1131,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Tegucigalpa",
//       "Type": "airport"
//     },
//     {
//       "ID": 4127,
//       "Airport Code [ICAO]": "KGPI",
//       "Airport Code iATA,FAA": "FCA",
//       "Airport Name": "Glacier Park International Airport",
//       "Altitude (Ft)": 2977,
//       "Country": "United States",
//       "Latitude": -114.25599670410156,
//       "Location": "Kalispell",
//       "Longitude": 48.31050109863281,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4128,
//       "Airport Code [ICAO]": "KMBS",
//       "Airport Code iATA,FAA": "MBS",
//       "Airport Name": "MBS International Airport",
//       "Altitude (Ft)": 668,
//       "Country": "United States",
//       "Latitude": -84.07959747314453,
//       "Location": "Saginaw",
//       "Longitude": 43.532901763916016,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4129,
//       "Airport Code [ICAO]": "KBGM",
//       "Airport Code iATA,FAA": "BGM",
//       "Airport Name": "Greater Binghamton/Edwin A Link field",
//       "Altitude (Ft)": 1636,
//       "Country": "United States",
//       "Latitude": -75.97979736,
//       "Location": "Binghamton",
//       "Longitude": 42.20869827,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4130,
//       "Airport Code [ICAO]": "ORBI",
//       "Airport Code iATA,FAA": "BGW",
//       "Airport Name": "Baghdad International Airport",
//       "Altitude (Ft)": 114,
//       "Country": "Iraq",
//       "Latitude": 44.2346000671,
//       "Location": "Baghdad",
//       "Longitude": 33.262500762900004,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Baghdad",
//       "Type": "airport"
//     },
//     {
//       "ID": 4131,
//       "Airport Code [ICAO]": "VTCN",
//       "Airport Code iATA,FAA": "NNT",
//       "Airport Name": "Nan Airport",
//       "Altitude (Ft)": 685,
//       "Country": "Thailand",
//       "Latitude": 100.78299713134766,
//       "Location": "Nan",
//       "Longitude": 18.807899475097656,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 4132,
//       "Airport Code [ICAO]": "VTUV",
//       "Airport Code iATA,FAA": "ROI",
//       "Airport Name": "Roi Et Airport",
//       "Altitude (Ft)": 451,
//       "Country": "Thailand",
//       "Latitude": 103.77400207519531,
//       "Location": "Roi Et",
//       "Longitude": 16.11680030822754,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 4133,
//       "Airport Code [ICAO]": "VTUO",
//       "Airport Code iATA,FAA": "BFV",
//       "Airport Name": "Buri Ram Airport",
//       "Altitude (Ft)": 590,
//       "Country": "Thailand",
//       "Latitude": 103.25299835205078,
//       "Location": "Buri Ram",
//       "Longitude": 15.229499816894531,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 4135,
//       "Airport Code [ICAO]": "VTBO",
//       "Airport Code iATA,FAA": "TDX",
//       "Airport Name": "Trat Airport",
//       "Altitude (Ft)": 105,
//       "Country": "Thailand",
//       "Latitude": 102.319000244,
//       "Location": "Trat",
//       "Longitude": 12.274600029,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 4136,
//       "Airport Code [ICAO]": "KBLH",
//       "Airport Code iATA,FAA": "BLH",
//       "Airport Name": "Blythe Airport",
//       "Altitude (Ft)": 399,
//       "Country": "United States",
//       "Latitude": -114.717002869,
//       "Location": "Blythe",
//       "Longitude": 33.6192016602,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 4137,
//       "Airport Code [ICAO]": "ORAA",
//       "Airport Code iATA,FAA": "IQA",
//       "Airport Name": "Al Asad Air Base",
//       "Altitude (Ft)": 618,
//       "Country": "Iraq",
//       "Latitude": 42.4412002563,
//       "Location": "Al Asad",
//       "Longitude": 33.7855987549,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Baghdad",
//       "Type": "airport"
//     },
//     {
//       "ID": 4138,
//       "Airport Code [ICAO]": "ORAT",
//       "Airport Code iATA,FAA": "TQD",
//       "Airport Name": "Al Taqaddum Air Base",
//       "Altitude (Ft)": 275,
//       "Country": "Iraq",
//       "Latitude": 43.597099304199,
//       "Location": "Al Taqaddum",
//       "Longitude": 33.33810043335,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Baghdad",
//       "Type": "airport"
//     },
//     {
//       "ID": 4139,
//       "Airport Code [ICAO]": "ORBD",
//       "Airport Code iATA,FAA": "XQC",
//       "Airport Name": "Joint Base Balad",
//       "Altitude (Ft)": 161,
//       "Country": "Iraq",
//       "Latitude": 44.361598968500005,
//       "Location": "Al Bakr",
//       "Longitude": 33.940200805699995,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Baghdad",
//       "Type": "airport"
//     },
//     {
//       "ID": 4140,
//       "Airport Code [ICAO]": "RPLC",
//       "Airport Code iATA,FAA": "CRK",
//       "Airport Name": "Diosdado Macapagal International Airport",
//       "Altitude (Ft)": 484,
//       "Country": "Philippines",
//       "Latitude": 120.559998,
//       "Location": "Angeles City",
//       "Longitude": 15.186,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 4141,
//       "Airport Code [ICAO]": "WBKS",
//       "Airport Code iATA,FAA": "SDK",
//       "Airport Name": "Sandakan Airport",
//       "Altitude (Ft)": 46,
//       "Country": "Malaysia",
//       "Latitude": 118.05899810791016,
//       "Location": "Sandakan",
//       "Longitude": 5.900899887084961,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 4142,
//       "Airport Code [ICAO]": "VLLN",
//       "Airport Code iATA,FAA": "LXG",
//       "Airport Name": "Luang Namtha Airport",
//       "Altitude (Ft)": 1968,
//       "Country": "Laos",
//       "Latitude": 101.400002,
//       "Location": "Luang Namtha",
//       "Longitude": 20.966999,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Vientiane",
//       "Type": "airport"
//     },
//     {
//       "ID": 4143,
//       "Airport Code [ICAO]": "VLOS",
//       "Airport Code iATA,FAA": "ODY",
//       "Airport Name": "Oudomsay Airport",
//       "Altitude (Ft)": 1804,
//       "Country": "Laos",
//       "Latitude": 101.99400329589844,
//       "Location": "Muang Xay",
//       "Longitude": 20.68269920349121,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Vientiane",
//       "Type": "airport"
//     },
//     {
//       "ID": 4144,
//       "Airport Code [ICAO]": "ZYTX",
//       "Airport Code iATA,FAA": "SHE",
//       "Airport Name": "Taoxian Airport",
//       "Altitude (Ft)": 198,
//       "Country": "China",
//       "Latitude": 123.48300170898438,
//       "Location": "Shenyang",
//       "Longitude": 41.639801025390625,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 4145,
//       "Airport Code [ICAO]": "ZSDY",
//       "Airport Code iATA,FAA": "DOY",
//       "Airport Name": "Dongying Shengli Airport",
//       "Altitude (Ft)": 15,
//       "Country": "China",
//       "Latitude": 118.788002014,
//       "Location": "Dongying",
//       "Longitude": 37.5085983276,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 4146,
//       "Airport Code [ICAO]": "TRPG",
//       "Airport Code iATA,FAA": "MNI",
//       "Airport Name": "John A. Osborne Airport",
//       "Altitude (Ft)": 550,
//       "Country": "Montserrat",
//       "Latitude": -62.19329833984375,
//       "Location": "Geralds",
//       "Longitude": 16.791400909423828,
//       "GMT Offset": "-4",
//       "Daylight Savings": "N",
//       "Region": "America/Montserrat",
//       "Type": "airport"
//     },
//     {
//       "ID": 4147,
//       "Airport Code [ICAO]": "PAPG",
//       "Airport Code iATA,FAA": "PSG",
//       "Airport Name": "Petersburg James A Johnson Airport",
//       "Altitude (Ft)": 111,
//       "Country": "United States",
//       "Latitude": -132.9450073,
//       "Location": "Petersburg",
//       "Longitude": 56.80170059,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 4148,
//       "Airport Code [ICAO]": "ZHLY",
//       "Airport Code iATA,FAA": "LYA",
//       "Airport Name": "Luoyang Airport",
//       "Altitude (Ft)": 840,
//       "Country": "China",
//       "Latitude": 112.388000488,
//       "Location": "Luoyang",
//       "Longitude": 34.741100311299995,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 4149,
//       "Airport Code [ICAO]": "ZSXZ",
//       "Airport Code iATA,FAA": "XUZ",
//       "Airport Name": "Xuzhou Guanyin Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 117.555278,
//       "Location": "Xuzhou",
//       "Longitude": 34.059056,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 4151,
//       "Airport Code [ICAO]": "VYMW",
//       "Airport Code iATA,FAA": "MWQ",
//       "Airport Name": "Magway Airport",
//       "Altitude (Ft)": 279,
//       "Country": "Burma",
//       "Latitude": 94.941399,
//       "Location": "Magwe",
//       "Longitude": 20.1656,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 4152,
//       "Airport Code [ICAO]": "VYKI",
//       "Airport Code iATA,FAA": "KHM",
//       "Airport Name": "Kanti Airport",
//       "Altitude (Ft)": 6000,
//       "Country": "Burma",
//       "Latitude": 95.67440032958984,
//       "Location": "Khamti",
//       "Longitude": 25.988300323486328,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 4153,
//       "Airport Code [ICAO]": "VVDL",
//       "Airport Code iATA,FAA": "DLI",
//       "Airport Name": "Lien Khuong Airport",
//       "Altitude (Ft)": 3156,
//       "Country": "Vietnam",
//       "Latitude": 108.366997,
//       "Location": "Dalat",
//       "Longitude": 11.75,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Saigon",
//       "Type": "airport"
//     },
//     {
//       "ID": 4154,
//       "Airport Code [ICAO]": "VVDH",
//       "Airport Code iATA,FAA": "VDH",
//       "Airport Name": "Dong Hoi Airport",
//       "Altitude (Ft)": 59,
//       "Country": "Vietnam",
//       "Latitude": 106.590556,
//       "Location": "Dong Hoi",
//       "Longitude": 17.515,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Saigon",
//       "Type": "airport"
//     },
//     {
//       "ID": 4155,
//       "Airport Code [ICAO]": "VVRG",
//       "Airport Code iATA,FAA": "VKG",
//       "Airport Name": "Rach Gia Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Vietnam",
//       "Latitude": 105.132379532,
//       "Location": "Rach Gia",
//       "Longitude": 9.95802997234,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Saigon",
//       "Type": "airport"
//     },
//     {
//       "ID": 4156,
//       "Airport Code [ICAO]": "VVCM",
//       "Airport Code iATA,FAA": "CAH",
//       "Airport Name": "Cà Mau Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Vietnam",
//       "Latitude": 105.177778,
//       "Location": "Ca Mau",
//       "Longitude": 9.177667,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Saigon",
//       "Type": "airport"
//     },
//     {
//       "ID": 4157,
//       "Airport Code [ICAO]": "VVCA",
//       "Airport Code iATA,FAA": "VCL",
//       "Airport Name": "Chu Lai International Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Vietnam",
//       "Latitude": 108.706001282,
//       "Location": "Chu Lai",
//       "Longitude": 15.403300285299999,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Saigon",
//       "Type": "airport"
//     },
//     {
//       "ID": 4158,
//       "Airport Code [ICAO]": "VVTH",
//       "Airport Code iATA,FAA": "TBB",
//       "Airport Name": "Dong Tac Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Vietnam",
//       "Latitude": 109.333999634,
//       "Location": "Tuy Hoa",
//       "Longitude": 13.049599647500001,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Saigon",
//       "Type": "airport"
//     },
//     {
//       "ID": 4159,
//       "Airport Code [ICAO]": "VTCI",
//       "Airport Code iATA,FAA": "PYY",
//       "Airport Name": "Mae Hong Son Airport",
//       "Altitude (Ft)": 1271,
//       "Country": "Thailand",
//       "Latitude": 98.43699646,
//       "Location": "Pai",
//       "Longitude": 19.3719997406,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 4160,
//       "Airport Code [ICAO]": "LDSB",
//       "Airport Code iATA,FAA": "BWK",
//       "Airport Name": "Bol Airport",
//       "Altitude (Ft)": 1776,
//       "Country": "Croatia",
//       "Latitude": 16.67970085144043,
//       "Location": "Brac",
//       "Longitude": 43.285701751708984,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zagreb",
//       "Type": "airport"
//     },
//     {
//       "ID": 4161,
//       "Airport Code [ICAO]": "FKYS",
//       "Airport Code iATA,FAA": "NSI",
//       "Airport Name": "Yaoundé Nsimalen International Airport",
//       "Altitude (Ft)": 2278,
//       "Country": "Cameroon",
//       "Latitude": 11.553299903869629,
//       "Location": "Yaounde",
//       "Longitude": 3.722559928894043,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Douala",
//       "Type": "airport"
//     },
//     {
//       "ID": 4162,
//       "Airport Code [ICAO]": "GUCY",
//       "Airport Code iATA,FAA": "CKY",
//       "Airport Name": "Conakry International Airport",
//       "Altitude (Ft)": 72,
//       "Country": "Guinea",
//       "Latitude": -13.612,
//       "Location": "Conakry",
//       "Longitude": 9.57689,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Conakry",
//       "Type": "airport"
//     },
//     {
//       "ID": 4165,
//       "Airport Code [ICAO]": "EDKA",
//       "Airport Code iATA,FAA": "AAH",
//       "Airport Name": "Aachen-Merzbrück Airport",
//       "Altitude (Ft)": 623,
//       "Country": "Germany",
//       "Latitude": 6.186388969421387,
//       "Location": "Aachen",
//       "Longitude": 50.823055267333984,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 4166,
//       "Airport Code [ICAO]": "EDSB",
//       "Airport Code iATA,FAA": "FKB",
//       "Airport Name": "Karlsruhe Baden-Baden Airport",
//       "Altitude (Ft)": 408,
//       "Country": "Germany",
//       "Latitude": 8.08049964905,
//       "Location": "Karlsruhe/Baden-Baden",
//       "Longitude": 48.7793998718,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 4167,
//       "Airport Code [ICAO]": "KSFB",
//       "Airport Code iATA,FAA": "SFB",
//       "Airport Name": "Orlando Sanford International Airport",
//       "Altitude (Ft)": 55,
//       "Country": "United States",
//       "Latitude": -81.23750305175781,
//       "Location": "Sanford",
//       "Longitude": 28.777599334716797,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4169,
//       "Airport Code [ICAO]": "KJST",
//       "Airport Code iATA,FAA": "JST",
//       "Airport Name": "John Murtha Johnstown Cambria County Airport",
//       "Altitude (Ft)": 2284,
//       "Country": "United States",
//       "Latitude": -78.83390045166016,
//       "Location": "Johnstown",
//       "Longitude": 40.31610107421875,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4170,
//       "Airport Code [ICAO]": "VNLK",
//       "Airport Code iATA,FAA": "LUA",
//       "Airport Name": "Lukla Airport",
//       "Altitude (Ft)": 9380,
//       "Country": "Nepal",
//       "Latitude": 86.72969818115234,
//       "Location": "Lukla",
//       "Longitude": 27.686899185180664,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 4171,
//       "Airport Code [ICAO]": "VNBJ",
//       "Airport Code iATA,FAA": "BHP",
//       "Airport Name": "Bhojpur Airport",
//       "Altitude (Ft)": 4000,
//       "Country": "Nepal",
//       "Latitude": 87.05079650878906,
//       "Location": "Bhojpur",
//       "Longitude": 27.14739990234375,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 4172,
//       "Airport Code [ICAO]": "VNLD",
//       "Airport Code iATA,FAA": "LDN",
//       "Airport Name": "Lamidanda Airport",
//       "Altitude (Ft)": 4100,
//       "Country": "Nepal",
//       "Latitude": 86.66999816894531,
//       "Location": "Lamidanda",
//       "Longitude": 27.25309944152832,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 4173,
//       "Airport Code [ICAO]": "VNJS",
//       "Airport Code iATA,FAA": "JMO",
//       "Airport Name": "Jomsom Airport",
//       "Altitude (Ft)": 8976,
//       "Country": "Nepal",
//       "Latitude": 83.723,
//       "Location": "Jomsom",
//       "Longitude": 28.780426,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 4174,
//       "Airport Code [ICAO]": "VNMA",
//       "Airport Code iATA,FAA": "NGX",
//       "Airport Name": "Manang Airport",
//       "Altitude (Ft)": 11001,
//       "Country": "Nepal",
//       "Latitude": 84.089203,
//       "Location": "Manang",
//       "Longitude": 28.641399,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 4175,
//       "Airport Code [ICAO]": "VNPL",
//       "Airport Code iATA,FAA": "PPL",
//       "Airport Name": "Phaplu Airport",
//       "Altitude (Ft)": 7918,
//       "Country": "Nepal",
//       "Latitude": 86.584454,
//       "Location": "Phaplu",
//       "Longitude": 27.517787,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 4177,
//       "Airport Code [ICAO]": "VNRT",
//       "Airport Code iATA,FAA": "RUM",
//       "Airport Name": "Rumjatar Airport",
//       "Altitude (Ft)": 4500,
//       "Country": "Nepal",
//       "Latitude": 86.55039978027344,
//       "Location": "Rumjatar",
//       "Longitude": 27.303499221801758,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 4178,
//       "Airport Code [ICAO]": "VNDG",
//       "Airport Code iATA,FAA": "DNP",
//       "Airport Name": "Tulsipur Airport",
//       "Altitude (Ft)": 2100,
//       "Country": "Nepal",
//       "Latitude": 82.29419708251953,
//       "Location": "Dang",
//       "Longitude": 28.111099243164062,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 4179,
//       "Airport Code [ICAO]": "VNRK",
//       "Airport Code iATA,FAA": "RUK",
//       "Airport Name": "Rukum Chaurjahari Airport",
//       "Altitude (Ft)": 2500,
//       "Country": "Nepal",
//       "Latitude": 82.195,
//       "Location": "Rukumkot",
//       "Longitude": 28.627001,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 4180,
//       "Airport Code [ICAO]": "VNJL",
//       "Airport Code iATA,FAA": "JUM",
//       "Airport Name": "Jumla Airport",
//       "Altitude (Ft)": 7700,
//       "Country": "Nepal",
//       "Latitude": 82.193298,
//       "Location": "Jumla",
//       "Longitude": 29.2742,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 4182,
//       "Airport Code [ICAO]": "VNTJ",
//       "Airport Code iATA,FAA": "TPJ",
//       "Airport Name": "Taplejung Airport",
//       "Altitude (Ft)": 7990,
//       "Country": "Nepal",
//       "Latitude": 87.69525,
//       "Location": "Taplejung",
//       "Longitude": 27.3509,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 4183,
//       "Airport Code [ICAO]": "VNTR",
//       "Airport Code iATA,FAA": "TMI",
//       "Airport Name": "Tumling Tar Airport",
//       "Altitude (Ft)": 1700,
//       "Country": "Nepal",
//       "Latitude": 87.193298,
//       "Location": "Tumling Tar",
//       "Longitude": 27.315001,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 4184,
//       "Airport Code [ICAO]": "VNSK",
//       "Airport Code iATA,FAA": "SKH",
//       "Airport Name": "Surkhet Airport",
//       "Altitude (Ft)": 2400,
//       "Country": "Nepal",
//       "Latitude": 81.636002,
//       "Location": "Surkhet",
//       "Longitude": 28.586,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 4185,
//       "Airport Code [ICAO]": "VNST",
//       "Airport Code iATA,FAA": "IMK",
//       "Airport Name": "Simikot Airport",
//       "Altitude (Ft)": 9246,
//       "Country": "Nepal",
//       "Latitude": 81.81890106201172,
//       "Location": "Simikot",
//       "Longitude": 29.971099853515625,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 4186,
//       "Airport Code [ICAO]": "VNDP",
//       "Airport Code iATA,FAA": "DOP",
//       "Airport Name": "Dolpa Airport",
//       "Altitude (Ft)": 8200,
//       "Country": "Nepal",
//       "Latitude": 82.81909942626953,
//       "Location": "Dolpa",
//       "Longitude": 28.985700607299805,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 4187,
//       "Airport Code [ICAO]": "VNBG",
//       "Airport Code iATA,FAA": "BJH",
//       "Airport Name": "Bajhang Airport",
//       "Altitude (Ft)": 4100,
//       "Country": "Nepal",
//       "Latitude": 81.1854019165039,
//       "Location": "Bajhang",
//       "Longitude": 29.538999557495117,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 4188,
//       "Airport Code [ICAO]": "VNDH",
//       "Airport Code iATA,FAA": "DHI",
//       "Airport Name": "Dhangarhi Airport",
//       "Altitude (Ft)": 690,
//       "Country": "Nepal",
//       "Latitude": 80.58190155029297,
//       "Location": "Dhangarhi",
//       "Longitude": 28.753299713134766,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 4189,
//       "Airport Code [ICAO]": "RKJB",
//       "Airport Code iATA,FAA": "MWX",
//       "Airport Name": "Muan International Airport",
//       "Altitude (Ft)": 35,
//       "Country": "South Korea",
//       "Latitude": 126.382814,
//       "Location": "Muan",
//       "Longitude": 34.991406,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Seoul",
//       "Type": "airport"
//     },
//     {
//       "ID": 4190,
//       "Airport Code [ICAO]": "LGPL",
//       "Airport Code iATA,FAA": "JTY",
//       "Airport Name": "Astypalaia Airport",
//       "Altitude (Ft)": 165,
//       "Country": "Greece",
//       "Latitude": 26.3757991791,
//       "Location": "Astypalaia",
//       "Longitude": 36.5798988342,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 4191,
//       "Airport Code [ICAO]": "LGIK",
//       "Airport Code iATA,FAA": "JIK",
//       "Airport Name": "Ikaria Airport",
//       "Altitude (Ft)": 79,
//       "Country": "Greece",
//       "Latitude": 26.3470993042,
//       "Location": "Ikaria",
//       "Longitude": 37.6827011108,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 4192,
//       "Airport Code [ICAO]": "LGKY",
//       "Airport Code iATA,FAA": "JKL",
//       "Airport Name": "Kalymnos Airport",
//       "Altitude (Ft)": 771,
//       "Country": "Greece",
//       "Latitude": 26.9405994415,
//       "Location": "Kalymnos",
//       "Longitude": 36.9632987976,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 4193,
//       "Airport Code [ICAO]": "LGML",
//       "Airport Code iATA,FAA": "MLO",
//       "Airport Name": "Milos Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Greece",
//       "Latitude": 24.4769,
//       "Location": "Milos",
//       "Longitude": 36.696899,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 4194,
//       "Airport Code [ICAO]": "LGNX",
//       "Airport Code iATA,FAA": "JNX",
//       "Airport Name": "Naxos Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Greece",
//       "Latitude": 25.3680992126,
//       "Location": "Cyclades Islands",
//       "Longitude": 37.0811004639,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 4195,
//       "Airport Code [ICAO]": "LGPA",
//       "Airport Code iATA,FAA": "PAS",
//       "Airport Name": "Paros National Airport",
//       "Altitude (Ft)": 131,
//       "Country": "Greece",
//       "Latitude": 25.113195,
//       "Location": "Paros",
//       "Longitude": 37.020495,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 4196,
//       "Airport Code [ICAO]": "LGKJ",
//       "Airport Code iATA,FAA": "KZS",
//       "Airport Name": "Kastelorizo Airport",
//       "Altitude (Ft)": 489,
//       "Country": "Greece",
//       "Latitude": 29.576400756799995,
//       "Location": "Kastelorizo",
//       "Longitude": 36.1417007446,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 4197,
//       "Airport Code [ICAO]": "HEMA",
//       "Airport Code iATA,FAA": "RMF",
//       "Airport Name": "Marsa Alam International Airport",
//       "Altitude (Ft)": 251,
//       "Country": "Egypt",
//       "Latitude": 34.5836982727,
//       "Location": "Marsa Alam",
//       "Longitude": 25.557100296,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Cairo",
//       "Type": "airport"
//     },
//     {
//       "ID": 4198,
//       "Airport Code [ICAO]": "EDLV",
//       "Airport Code iATA,FAA": "NRN",
//       "Airport Name": "Weeze Airport",
//       "Altitude (Ft)": 106,
//       "Country": "Germany",
//       "Latitude": 6.14216995239,
//       "Location": "Weeze",
//       "Longitude": 51.6024017334,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 4199,
//       "Airport Code [ICAO]": "RPVV",
//       "Airport Code iATA,FAA": "USU",
//       "Airport Name": "Francisco B. Reyes Airport",
//       "Altitude (Ft)": 148,
//       "Country": "Philippines",
//       "Latitude": 120.099998474,
//       "Location": "Busuanga",
//       "Longitude": 12.1215000153,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 4200,
//       "Airport Code [ICAO]": "RPME",
//       "Airport Code iATA,FAA": "BXU",
//       "Airport Name": "Bancasi Airport",
//       "Altitude (Ft)": 141,
//       "Country": "Philippines",
//       "Latitude": 125.4788,
//       "Location": "Butuan",
//       "Longitude": 8.9515,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 4201,
//       "Airport Code [ICAO]": "RPMG",
//       "Airport Code iATA,FAA": "DPL",
//       "Airport Name": "Dipolog Airport",
//       "Altitude (Ft)": 12,
//       "Country": "Philippines",
//       "Latitude": 123.341875076,
//       "Location": "Dipolog",
//       "Longitude": 8.60198349877,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 4202,
//       "Airport Code [ICAO]": "RPLI",
//       "Airport Code iATA,FAA": "LAO",
//       "Airport Name": "Laoag International Airport",
//       "Altitude (Ft)": 25,
//       "Country": "Philippines",
//       "Latitude": 120.53199768066406,
//       "Location": "Laoag",
//       "Longitude": 18.1781005859375,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 4203,
//       "Airport Code [ICAO]": "RPLP",
//       "Airport Code iATA,FAA": "LGP",
//       "Airport Name": "Legazpi City International Airport",
//       "Altitude (Ft)": 66,
//       "Country": "Philippines",
//       "Latitude": 123.735,
//       "Location": "Legazpi",
//       "Longitude": 13.1575,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 4204,
//       "Airport Code [ICAO]": "RPMO",
//       "Airport Code iATA,FAA": "OZC",
//       "Airport Name": "Labo Airport",
//       "Altitude (Ft)": 75,
//       "Country": "Philippines",
//       "Latitude": 123.84200286865234,
//       "Location": "Ozamis",
//       "Longitude": 8.178509712219238,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 4206,
//       "Airport Code [ICAO]": "RPVM",
//       "Airport Code iATA,FAA": "CEB",
//       "Airport Name": "Mactan Cebu International Airport",
//       "Altitude (Ft)": 31,
//       "Country": "Philippines",
//       "Latitude": 123.97899627686,
//       "Location": "Cebu",
//       "Longitude": 10.307499885559,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 4207,
//       "Airport Code [ICAO]": "EDWS",
//       "Airport Code iATA,FAA": "NOD",
//       "Airport Name": "Norden-Norddeich Airport",
//       "Altitude (Ft)": 3,
//       "Country": "Germany",
//       "Latitude": 7.19027805328,
//       "Location": "Norden",
//       "Longitude": 53.633056640599996,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 4208,
//       "Airport Code [ICAO]": "EDWJ",
//       "Airport Code iATA,FAA": "JUI",
//       "Airport Name": "Juist Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Germany",
//       "Latitude": 7.055832862854004,
//       "Location": "Juist",
//       "Longitude": 53.68111038208008,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 4209,
//       "Airport Code [ICAO]": "SBPS",
//       "Airport Code iATA,FAA": "BPS",
//       "Airport Name": "Porto Seguro Airport",
//       "Altitude (Ft)": 168,
//       "Country": "Brazil",
//       "Latitude": -39.080898,
//       "Location": "Porto Seguro",
//       "Longitude": -16.438601,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 4213,
//       "Airport Code [ICAO]": "SNIG",
//       "Airport Code iATA,FAA": "QIG",
//       "Airport Name": "Iguatu Airport",
//       "Altitude (Ft)": 699,
//       "Country": "Brazil",
//       "Latitude": -39.293800354003906,
//       "Location": "Iguatu",
//       "Longitude": -6.346640110015869,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 4214,
//       "Airport Code [ICAO]": "SBPJ",
//       "Airport Code iATA,FAA": "PMW",
//       "Airport Name": "Brigadeiro Lysias Rodrigues Airport",
//       "Altitude (Ft)": 774,
//       "Country": "Brazil",
//       "Latitude": -48.35699844359999,
//       "Location": "Palmas",
//       "Longitude": -10.291500091600001,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 4215,
//       "Airport Code [ICAO]": "SBCN",
//       "Airport Code iATA,FAA": "CLV",
//       "Airport Name": "Nelson Ribeiro Guimarães Airport",
//       "Altitude (Ft)": 2247,
//       "Country": "Brazil",
//       "Latitude": -48.607498168945,
//       "Location": "Caldas Novas",
//       "Longitude": -17.725299835205,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 4216,
//       "Airport Code [ICAO]": "KMSO",
//       "Airport Code iATA,FAA": "MSO",
//       "Airport Name": "Missoula International Airport",
//       "Altitude (Ft)": 3206,
//       "Country": "United States",
//       "Latitude": -114.0910034,
//       "Location": "Missoula",
//       "Longitude": 46.91630173,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4217,
//       "Airport Code [ICAO]": "YBCK",
//       "Airport Code iATA,FAA": "BKQ",
//       "Airport Name": "Blackall Airport",
//       "Altitude (Ft)": 928,
//       "Country": "Australia",
//       "Latitude": 145.429000854,
//       "Location": "Blackall",
//       "Longitude": -24.427799224900003,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 4218,
//       "Airport Code [ICAO]": "YBUD",
//       "Airport Code iATA,FAA": "BDB",
//       "Airport Name": "Bundaberg Airport",
//       "Altitude (Ft)": 107,
//       "Country": "Australia",
//       "Latitude": 152.319000244,
//       "Location": "Bundaberg",
//       "Longitude": -24.903900146499996,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 4219,
//       "Airport Code [ICAO]": "KGCN",
//       "Airport Code iATA,FAA": "GCN",
//       "Airport Name": "Grand Canyon National Park Airport",
//       "Altitude (Ft)": 6609,
//       "Country": "United States",
//       "Latitude": -112.14700317382812,
//       "Location": "Grand Canyon",
//       "Longitude": 35.95240020751953,
//       "GMT Offset": "-7",
//       "Daylight Savings": "N",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 4220,
//       "Airport Code [ICAO]": "KSGR",
//       "Airport Code iATA,FAA": "SGR",
//       "Airport Name": "Sugar Land Regional Airport",
//       "Altitude (Ft)": 82,
//       "Country": "United States",
//       "Latitude": -95.65650177002,
//       "Location": "Sugar Land",
//       "Longitude": 29.622299194336,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4221,
//       "Airport Code [ICAO]": "YHYN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hayman Island Heliport",
//       "Altitude (Ft)": 8,
//       "Country": "Australia",
//       "Latitude": 148.8834,
//       "Location": "Hayman Island",
//       "Longitude": -20.0599,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 4222,
//       "Airport Code [ICAO]": "KAPA",
//       "Airport Code iATA,FAA": "APA",
//       "Airport Name": "Centennial Airport",
//       "Altitude (Ft)": 5885,
//       "Country": "United States",
//       "Latitude": -104.848999,
//       "Location": "Denver",
//       "Longitude": 39.57009888,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4223,
//       "Airport Code [ICAO]": "KCVN",
//       "Airport Code iATA,FAA": "CVN",
//       "Airport Name": "Clovis Municipal Airport",
//       "Altitude (Ft)": 4216,
//       "Country": "United States",
//       "Latitude": -103.07900238,
//       "Location": "Clovis",
//       "Longitude": 34.4250984192,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4224,
//       "Airport Code [ICAO]": "KFST",
//       "Airport Code iATA,FAA": "FST",
//       "Airport Name": "Fort Stockton Pecos County Airport",
//       "Altitude (Ft)": 3011,
//       "Country": "United States",
//       "Latitude": -102.916000366,
//       "Location": "Fort Stockton",
//       "Longitude": 30.9157009125,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4225,
//       "Airport Code [ICAO]": "KLVS",
//       "Airport Code iATA,FAA": "LVS",
//       "Airport Name": "Las Vegas Municipal Airport",
//       "Altitude (Ft)": 6877,
//       "Country": "United States",
//       "Latitude": -105.141998291,
//       "Location": "Las Vegas",
//       "Longitude": 35.6542015076,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4226,
//       "Airport Code [ICAO]": "KIWS",
//       "Airport Code iATA,FAA": "IWS",
//       "Airport Name": "West Houston Airport",
//       "Altitude (Ft)": 111,
//       "Country": "United States",
//       "Latitude": -95.67259979250001,
//       "Location": "Houston",
//       "Longitude": 29.818199157699997,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4227,
//       "Airport Code [ICAO]": "KLHX",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "La Junta Municipal Airport",
//       "Altitude (Ft)": 4229,
//       "Country": "United States",
//       "Latitude": -103.5090027,
//       "Location": "La Junta",
//       "Longitude": 38.04970169,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4228,
//       "Airport Code [ICAO]": "KLRU",
//       "Airport Code iATA,FAA": "LRU",
//       "Airport Name": "Las Cruces International Airport",
//       "Altitude (Ft)": 4456,
//       "Country": "United States",
//       "Latitude": -106.9219970703125,
//       "Location": "Las Cruces",
//       "Longitude": 32.289398193359375,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4229,
//       "Airport Code [ICAO]": "KBKD",
//       "Airport Code iATA,FAA": "BKD",
//       "Airport Name": "Stephens County Airport",
//       "Altitude (Ft)": 1284,
//       "Country": "United States",
//       "Latitude": -98.89099884030001,
//       "Location": "Breckenridge",
//       "Longitude": 32.71900177,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4230,
//       "Airport Code [ICAO]": "KTPL",
//       "Airport Code iATA,FAA": "TPL",
//       "Airport Name": "Draughon Miller Central Texas Regional Airport",
//       "Altitude (Ft)": 682,
//       "Country": "United States",
//       "Latitude": -97.40779876708984,
//       "Location": "Temple",
//       "Longitude": 31.15250015258789,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4231,
//       "Airport Code [ICAO]": "KOZA",
//       "Airport Code iATA,FAA": "OZA",
//       "Airport Name": "Ozona Municipal Airport",
//       "Altitude (Ft)": 2381,
//       "Country": "United States",
//       "Latitude": -101.20300292969,
//       "Location": "Ozona",
//       "Longitude": 30.735300064087,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4235,
//       "Airport Code [ICAO]": "VRMT",
//       "Airport Code iATA,FAA": "KDM",
//       "Airport Name": "Kaadedhdhoo Airport",
//       "Altitude (Ft)": 2,
//       "Country": "Maldives",
//       "Latitude": 72.99690246582031,
//       "Location": "Kaadedhdhoo",
//       "Longitude": 0.48813098669052124,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Indian/Maldives",
//       "Type": "airport"
//     },
//     {
//       "ID": 4236,
//       "Airport Code [ICAO]": "CYKD",
//       "Airport Code iATA,FAA": "LAK",
//       "Airport Name": "Aklavik/Freddie Carmichael Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Canada",
//       "Latitude": -135.00599,
//       "Location": "Aklavik",
//       "Longitude": 68.223297,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 4237,
//       "Airport Code [ICAO]": "CYWJ",
//       "Airport Code iATA,FAA": "YWJ",
//       "Airport Name": "Déline Airport",
//       "Altitude (Ft)": 703,
//       "Country": "Canada",
//       "Latitude": -123.43599700927734,
//       "Location": "Deline",
//       "Longitude": 65.21109771728516,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 4238,
//       "Airport Code [ICAO]": "CZFN",
//       "Airport Code iATA,FAA": "ZFN",
//       "Airport Name": "Tulita Airport",
//       "Altitude (Ft)": 332,
//       "Country": "Canada",
//       "Latitude": -125.572998,
//       "Location": "Tulita",
//       "Longitude": 64.909697,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 4239,
//       "Airport Code [ICAO]": "CYGH",
//       "Airport Code iATA,FAA": "YGH",
//       "Airport Name": "Fort Good Hope Airport",
//       "Altitude (Ft)": 268,
//       "Country": "Canada",
//       "Latitude": -128.6510009765625,
//       "Location": "Fort Good Hope",
//       "Longitude": 66.24079895019531,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 4242,
//       "Airport Code [ICAO]": "NVVW",
//       "Airport Code iATA,FAA": "TAH",
//       "Airport Name": "Tanna Airport",
//       "Altitude (Ft)": 19,
//       "Country": "Vanuatu",
//       "Latitude": 169.2239990234375,
//       "Location": "Tanna",
//       "Longitude": -19.45509910583496,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 4244,
//       "Airport Code [ICAO]": "CYPC",
//       "Airport Code iATA,FAA": "YPC",
//       "Airport Name": "Paulatuk (Nora Aliqatchialuk Ruben) Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Canada",
//       "Latitude": -124.075469971,
//       "Location": "Paulatuk",
//       "Longitude": 69.3608381154,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 4247,
//       "Airport Code [ICAO]": "SLET",
//       "Airport Code iATA,FAA": "SRZ",
//       "Airport Name": "El Trompillo Airport",
//       "Altitude (Ft)": 1371,
//       "Country": "Bolivia",
//       "Latitude": -63.1715011597,
//       "Location": "Santa Cruz",
//       "Longitude": -17.8115997314,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/La_Paz",
//       "Type": "airport"
//     },
//     {
//       "ID": 4249,
//       "Airport Code [ICAO]": "TNCS",
//       "Airport Code iATA,FAA": "SAB",
//       "Airport Name": "Juancho E. Yrausquin Airport",
//       "Altitude (Ft)": 60,
//       "Country": "Netherlands Antilles",
//       "Latitude": -63.220001220703125,
//       "Location": "Saba",
//       "Longitude": 17.645000457763672,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Curacao",
//       "Type": "airport"
//     },
//     {
//       "ID": 4250,
//       "Airport Code [ICAO]": "KEGE",
//       "Airport Code iATA,FAA": "EGE",
//       "Airport Name": "Eagle County Regional Airport",
//       "Altitude (Ft)": 6548,
//       "Country": "United States",
//       "Latitude": -106.9179993,
//       "Location": "Vail",
//       "Longitude": 39.64260101,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4252,
//       "Airport Code [ICAO]": "ENSK",
//       "Airport Code iATA,FAA": "SKN",
//       "Airport Name": "Stokmarknes Skagen Airport",
//       "Altitude (Ft)": 11,
//       "Country": "Norway",
//       "Latitude": 15.033416748047,
//       "Location": "Stokmarknes",
//       "Longitude": 68.578826904297,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 4253,
//       "Airport Code [ICAO]": "KCGF",
//       "Airport Code iATA,FAA": "CGF",
//       "Airport Name": "Cuyahoga County Airport",
//       "Altitude (Ft)": 879,
//       "Country": "United States",
//       "Latitude": -81.4863967896,
//       "Location": "Richmond Heights",
//       "Longitude": 41.5651016235,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4254,
//       "Airport Code [ICAO]": "KMFD",
//       "Airport Code iATA,FAA": "MFD",
//       "Airport Name": "Mansfield Lahm Regional Airport",
//       "Altitude (Ft)": 1297,
//       "Country": "United States",
//       "Latitude": -82.5166015625,
//       "Location": "Mansfield",
//       "Longitude": 40.82139968869999,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4255,
//       "Airport Code [ICAO]": "KCSG",
//       "Airport Code iATA,FAA": "CSG",
//       "Airport Name": "Columbus Metropolitan Airport",
//       "Altitude (Ft)": 397,
//       "Country": "United States",
//       "Latitude": -84.93890380859375,
//       "Location": "Columbus",
//       "Longitude": 32.516300201416016,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4256,
//       "Airport Code [ICAO]": "KLAW",
//       "Airport Code iATA,FAA": "LAW",
//       "Airport Name": "Lawton Fort Sill Regional Airport",
//       "Altitude (Ft)": 1110,
//       "Country": "United States",
//       "Latitude": -98.4166030884,
//       "Location": "Lawton",
//       "Longitude": 34.5676994324,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4257,
//       "Airport Code [ICAO]": "KFNL",
//       "Airport Code iATA,FAA": "FNL",
//       "Airport Name": "Northern Colorado Regional Airport",
//       "Altitude (Ft)": 5016,
//       "Country": "United States",
//       "Latitude": -105.011,
//       "Location": "Fort Collins",
//       "Longitude": 40.451804,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4261,
//       "Airport Code [ICAO]": "KFLG",
//       "Airport Code iATA,FAA": "FLG",
//       "Airport Name": "Flagstaff Pulliam Airport",
//       "Altitude (Ft)": 7014,
//       "Country": "United States",
//       "Latitude": -111.6709976,
//       "Location": "Flagstaff",
//       "Longitude": 35.13850021,
//       "GMT Offset": "-7",
//       "Daylight Savings": "N",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 4262,
//       "Airport Code [ICAO]": "KTVL",
//       "Airport Code iATA,FAA": "TVL",
//       "Airport Name": "Lake Tahoe Airport",
//       "Altitude (Ft)": 6264,
//       "Country": "United States",
//       "Latitude": -119.99500274658203,
//       "Location": "South Lake Tahoe",
//       "Longitude": 38.89390182495117,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 4263,
//       "Airport Code [ICAO]": "KTWF",
//       "Airport Code iATA,FAA": "TWF",
//       "Airport Name": "Joslin Field Magic Valley Regional Airport",
//       "Altitude (Ft)": 4154,
//       "Country": "United States",
//       "Latitude": -114.487999,
//       "Location": "Twin Falls",
//       "Longitude": 42.4818,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4265,
//       "Airport Code [ICAO]": "KMVY",
//       "Airport Code iATA,FAA": "MVY",
//       "Airport Name": "Martha's Vineyard Airport",
//       "Altitude (Ft)": 67,
//       "Country": "United States",
//       "Latitude": -70.6143035889,
//       "Location": "Vineyard Haven MA",
//       "Longitude": 41.3931007385,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4268,
//       "Airport Code [ICAO]": "KCON",
//       "Airport Code iATA,FAA": "CON",
//       "Airport Name": "Concord Municipal Airport",
//       "Altitude (Ft)": 342,
//       "Country": "United States",
//       "Latitude": -71.50229645,
//       "Location": "Concord NH",
//       "Longitude": 43.20270157,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4270,
//       "Airport Code [ICAO]": "KGON",
//       "Airport Code iATA,FAA": "GON",
//       "Airport Name": "Groton New London Airport",
//       "Altitude (Ft)": 9,
//       "Country": "United States",
//       "Latitude": -72.04509735107422,
//       "Location": "Groton CT",
//       "Longitude": 41.330101013183594,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4271,
//       "Airport Code [ICAO]": "KSTC",
//       "Airport Code iATA,FAA": "STC",
//       "Airport Name": "St Cloud Regional Airport",
//       "Altitude (Ft)": 1031,
//       "Country": "United States",
//       "Latitude": -94.05989837646484,
//       "Location": "Saint Cloud",
//       "Longitude": 45.546600341796875,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4272,
//       "Airport Code [ICAO]": "ZBDH",
//       "Airport Code iATA,FAA": "BPE",
//       "Airport Name": "Qinhuangdao Beidaihe Airport",
//       "Altitude (Ft)": 46,
//       "Country": "Burma",
//       "Latitude": 119.058889,
//       "Location": "Bagan",
//       "Longitude": 39.666389,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 4273,
//       "Airport Code [ICAO]": "KGTR",
//       "Airport Code iATA,FAA": "GTR",
//       "Airport Name": "Golden Triangle Regional Airport",
//       "Altitude (Ft)": 264,
//       "Country": "United States",
//       "Latitude": -88.5914001465,
//       "Location": "Columbus Mississippi",
//       "Longitude": 33.450298309299995,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4274,
//       "Airport Code [ICAO]": "UWGG",
//       "Airport Code iATA,FAA": "GOJ",
//       "Airport Name": "Nizhny Novgorod Strigino International Airport",
//       "Altitude (Ft)": 256,
//       "Country": "Russia",
//       "Latitude": 43.784000396729,
//       "Location": "Nizhniy Novgorod",
//       "Longitude": 56.230098724365,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 4275,
//       "Airport Code [ICAO]": "KHQM",
//       "Airport Code iATA,FAA": "HQM",
//       "Airport Name": "Bowerman Airport",
//       "Altitude (Ft)": 18,
//       "Country": "United States",
//       "Latitude": -123.93699646,
//       "Location": "Hoquiam",
//       "Longitude": 46.971199035599994,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 4276,
//       "Airport Code [ICAO]": "KERI",
//       "Airport Code iATA,FAA": "ERI",
//       "Airport Name": "Erie International Tom Ridge Field",
//       "Altitude (Ft)": 732,
//       "Country": "United States",
//       "Latitude": -80.1738667488,
//       "Location": "Erie",
//       "Longitude": 42.0831270134,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4278,
//       "Airport Code [ICAO]": "KHYA",
//       "Airport Code iATA,FAA": "HYA",
//       "Airport Name": "Barnstable Municipal Boardman Polando Field",
//       "Altitude (Ft)": 54,
//       "Country": "United States",
//       "Latitude": -70.28040314,
//       "Location": "Barnstable",
//       "Longitude": 41.66930008,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4279,
//       "Airport Code [ICAO]": "MZSP",
//       "Airport Code iATA,FAA": "SPR",
//       "Airport Name": "San Pedro Airport",
//       "Altitude (Ft)": 4,
//       "Country": "Belize",
//       "Latitude": -87.9711,
//       "Location": "San Pedro",
//       "Longitude": 17.9139,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Belize",
//       "Type": "airport"
//     },
//     {
//       "ID": 4280,
//       "Airport Code [ICAO]": "KSEZ",
//       "Airport Code iATA,FAA": "SDX",
//       "Airport Name": "Sedona Airport",
//       "Altitude (Ft)": 4830,
//       "Country": "United States",
//       "Latitude": -111.78800201416,
//       "Location": "Sedona",
//       "Longitude": 34.848598480225,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 4284,
//       "Airport Code [ICAO]": "KMGW",
//       "Airport Code iATA,FAA": "MGW",
//       "Airport Name": "Morgantown Municipal Walter L. Bill Hart Field",
//       "Altitude (Ft)": 1248,
//       "Country": "United States",
//       "Latitude": -79.91629791,
//       "Location": "Morgantown",
//       "Longitude": 39.64289856,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4285,
//       "Airport Code [ICAO]": "KCRW",
//       "Airport Code iATA,FAA": "CRW",
//       "Airport Name": "Yeager Airport",
//       "Altitude (Ft)": 981,
//       "Country": "United States",
//       "Latitude": -81.59320068359375,
//       "Location": "Charleston",
//       "Longitude": 38.37310028076172,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4286,
//       "Airport Code [ICAO]": "KAVP",
//       "Airport Code iATA,FAA": "AVP",
//       "Airport Name": "Wilkes Barre Scranton International Airport",
//       "Altitude (Ft)": 962,
//       "Country": "United States",
//       "Latitude": -75.72339630130001,
//       "Location": "Scranton",
//       "Longitude": 41.338500976599995,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4287,
//       "Airport Code [ICAO]": "KBJI",
//       "Airport Code iATA,FAA": "BJI",
//       "Airport Name": "Bemidji Regional Airport",
//       "Altitude (Ft)": 1391,
//       "Country": "United States",
//       "Latitude": -94.93370056,
//       "Location": "Bemidji",
//       "Longitude": 47.50939941,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4289,
//       "Airport Code [ICAO]": "YTNG",
//       "Airport Code iATA,FAA": "THG",
//       "Airport Name": "Thangool Airport",
//       "Altitude (Ft)": 644,
//       "Country": "Australia",
//       "Latitude": 150.5760040283203,
//       "Location": "Biloela",
//       "Longitude": -24.493900299072266,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 4290,
//       "Airport Code [ICAO]": "NSFI",
//       "Airport Code iATA,FAA": "FGI",
//       "Airport Name": "Fagali'i Airport",
//       "Altitude (Ft)": 131,
//       "Country": "Samoa",
//       "Latitude": -171.740005493,
//       "Location": "Apia",
//       "Longitude": -13.848699569699999,
//       "GMT Offset": "13",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Apia",
//       "Type": "airport"
//     },
//     {
//       "ID": 4291,
//       "Airport Code [ICAO]": "YBNA",
//       "Airport Code iATA,FAA": "BNK",
//       "Airport Name": "Ballina Byron Gateway Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Australia",
//       "Latitude": 153.56199646,
//       "Location": "Ballina Byron Bay",
//       "Longitude": -28.8339004517,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 4292,
//       "Airport Code [ICAO]": "KFAR",
//       "Airport Code iATA,FAA": "FAR",
//       "Airport Name": "Hector International Airport",
//       "Altitude (Ft)": 902,
//       "Country": "United States",
//       "Latitude": -96.81580352783203,
//       "Location": "Fargo",
//       "Longitude": 46.92070007324219,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4293,
//       "Airport Code [ICAO]": "KMKC",
//       "Airport Code iATA,FAA": "MKC",
//       "Airport Name": "Charles B. Wheeler Downtown Airport",
//       "Altitude (Ft)": 759,
//       "Country": "United States",
//       "Latitude": -94.5927963256836,
//       "Location": "Kansas City",
//       "Longitude": 39.123199462890625,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4295,
//       "Airport Code [ICAO]": "VDRK",
//       "Airport Code iATA,FAA": "RBE",
//       "Airport Name": "Ratanakiri Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Cambodia",
//       "Latitude": 106.98699951171875,
//       "Location": "Ratanakiri",
//       "Longitude": 13.729999542236328,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Phnom_Penh",
//       "Type": "airport"
//     },
//     {
//       "ID": 4296,
//       "Airport Code [ICAO]": "KGCC",
//       "Airport Code iATA,FAA": "GCC",
//       "Airport Name": "Gillette Campbell County Airport",
//       "Altitude (Ft)": 4365,
//       "Country": "United States",
//       "Latitude": -105.539001465,
//       "Location": "Gillette",
//       "Longitude": 44.348899841299996,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4297,
//       "Airport Code [ICAO]": "UNTT",
//       "Airport Code iATA,FAA": "TOF",
//       "Airport Name": "Bogashevo Airport",
//       "Altitude (Ft)": 597,
//       "Country": "Russia",
//       "Latitude": 85.208297729492,
//       "Location": "Tomsk",
//       "Longitude": 56.380298614502,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Krasnoyarsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 4298,
//       "Airport Code [ICAO]": "KNZJ",
//       "Airport Code iATA,FAA": "NZJ",
//       "Airport Name": "El Toro Marine Corps Air Station",
//       "Altitude (Ft)": 383,
//       "Country": "United States",
//       "Latitude": -117.73100280761719,
//       "Location": "Santa Ana",
//       "Longitude": 33.67610168457031,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 4299,
//       "Airport Code [ICAO]": "VTPB",
//       "Airport Code iATA,FAA": "PHY",
//       "Airport Name": "Phetchabun Airport",
//       "Altitude (Ft)": 450,
//       "Country": "Thailand",
//       "Latitude": 101.194999695,
//       "Location": "Phetchabun",
//       "Longitude": 16.6760005951,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 4300,
//       "Airport Code [ICAO]": "VTSE",
//       "Airport Code iATA,FAA": "CJM",
//       "Airport Name": "Chumphon Airport",
//       "Altitude (Ft)": 18,
//       "Country": "Thailand",
//       "Latitude": 99.36170196533203,
//       "Location": "Chumphon",
//       "Longitude": 10.711199760437012,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 4301,
//       "Airport Code [ICAO]": "ZUJZ",
//       "Airport Code iATA,FAA": "JZH",
//       "Airport Name": "Jiuzhai Huanglong Airport",
//       "Altitude (Ft)": 11327,
//       "Country": "China",
//       "Latitude": 103.682222222,
//       "Location": "Jiuzhaigou",
//       "Longitude": 32.8533333333,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 4302,
//       "Airport Code [ICAO]": "ZGOW",
//       "Airport Code iATA,FAA": "SWA",
//       "Airport Name": "Jieyang Chaoshan International Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 116.5033,
//       "Location": "Shantou",
//       "Longitude": 23.552,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 4303,
//       "Airport Code [ICAO]": "LFFE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Enghien Moisselles Airfield",
//       "Altitude (Ft)": 335,
//       "Country": "France",
//       "Latitude": 2.35306,
//       "Location": "Enghien-moisselles",
//       "Longitude": 49.046398,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 4304,
//       "Airport Code [ICAO]": "SYCJ",
//       "Airport Code iATA,FAA": "GEO",
//       "Airport Name": "Cheddi Jagan International Airport",
//       "Altitude (Ft)": 95,
//       "Country": "Guyana",
//       "Latitude": -58.25410079956055,
//       "Location": "Georgetown",
//       "Longitude": 6.498549938201904,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Guyana",
//       "Type": "airport"
//     },
//     {
//       "ID": 4305,
//       "Airport Code [ICAO]": "SGES",
//       "Airport Code iATA,FAA": "AGT",
//       "Airport Name": "Guarani International Airport",
//       "Altitude (Ft)": 846,
//       "Country": "Paraguay",
//       "Latitude": -54.842682,
//       "Location": "Ciudad del Este",
//       "Longitude": -25.454516,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Asuncion",
//       "Type": "airport"
//     },
//     {
//       "ID": 4306,
//       "Airport Code [ICAO]": "SYGO",
//       "Airport Code iATA,FAA": "OGL",
//       "Airport Name": "Eugene F. Correira International Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Guyana",
//       "Latitude": -58.1059,
//       "Location": "Georgetown",
//       "Longitude": 6.80628,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Guyana",
//       "Type": "airport"
//     },
//     {
//       "ID": 4307,
//       "Airport Code [ICAO]": "PKSA",
//       "Airport Code iATA,FAA": "KAI",
//       "Airport Name": "Kaieteur International Airport",
//       "Altitude (Ft)": 1520,
//       "Country": "Guyana",
//       "Latitude": -59.491481781,
//       "Location": "Kaieteur",
//       "Longitude": 5.17275476456,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Guyana",
//       "Type": "airport"
//     },
//     {
//       "ID": 4308,
//       "Airport Code [ICAO]": "ZLDH",
//       "Airport Code iATA,FAA": "DNH",
//       "Airport Name": "Dunhuang Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 94.80919647216797,
//       "Location": "Dunhuang",
//       "Longitude": 40.16109848022461,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 4309,
//       "Airport Code [ICAO]": "LIPY",
//       "Airport Code iATA,FAA": "AOI",
//       "Airport Name": "Ancona Falconara Airport",
//       "Altitude (Ft)": 49,
//       "Country": "Italy",
//       "Latitude": 13.3623,
//       "Location": "Ancona",
//       "Longitude": 43.616299,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 4312,
//       "Airport Code [ICAO]": "SCHA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Chamonate Airport",
//       "Altitude (Ft)": 984,
//       "Country": "Chile",
//       "Latitude": -70.4131011963,
//       "Location": "Copiapo",
//       "Longitude": -27.2968997955,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4313,
//       "Airport Code [ICAO]": "HETB",
//       "Airport Code iATA,FAA": "TCP",
//       "Airport Name": "Taba International Airport",
//       "Altitude (Ft)": 2415,
//       "Country": "Egypt",
//       "Latitude": 34.7780990601,
//       "Location": "Taba",
//       "Longitude": 29.587799072299998,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Cairo",
//       "Type": "airport"
//     },
//     {
//       "ID": 4314,
//       "Airport Code [ICAO]": "MWCL",
//       "Airport Code iATA,FAA": "LYB",
//       "Airport Name": "Edward Bodden Airfield",
//       "Altitude (Ft)": 3,
//       "Country": "Cayman Islands",
//       "Latitude": -80.088826,
//       "Location": "Little Cayman",
//       "Longitude": 19.660161,
//       "GMT Offset": "-5",
//       "Daylight Savings": "N",
//       "Region": "America/Cayman",
//       "Type": "airport"
//     },
//     {
//       "ID": 4315,
//       "Airport Code [ICAO]": "LTFE",
//       "Airport Code iATA,FAA": "BJV",
//       "Airport Name": "Milas Bodrum International Airport",
//       "Altitude (Ft)": 21,
//       "Country": "Turkey",
//       "Latitude": 27.6643009186,
//       "Location": "Bodrum",
//       "Longitude": 37.25059890749999,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 4316,
//       "Airport Code [ICAO]": "DTKA",
//       "Airport Code iATA,FAA": "TBJ",
//       "Airport Name": "Tabarka 7 Novembre Airport",
//       "Altitude (Ft)": 230,
//       "Country": "Tunisia",
//       "Latitude": 8.87693977355957,
//       "Location": "Tabarka",
//       "Longitude": 36.97999954223633,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Africa/Tunis",
//       "Type": "airport"
//     },
//     {
//       "ID": 4317,
//       "Airport Code [ICAO]": "LTFJ",
//       "Airport Code iATA,FAA": "SAW",
//       "Airport Name": "Sabiha Gökçen International Airport",
//       "Altitude (Ft)": 312,
//       "Country": "Turkey",
//       "Latitude": 29.3092002869,
//       "Location": "Istanbul",
//       "Longitude": 40.898601532,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 4318,
//       "Airport Code [ICAO]": "KUNV",
//       "Airport Code iATA,FAA": "SCE",
//       "Airport Name": "University Park Airport",
//       "Altitude (Ft)": 1239,
//       "Country": "United States",
//       "Latitude": -77.84870147710001,
//       "Location": "State College Pennsylvania",
//       "Longitude": 40.8493003845,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4319,
//       "Airport Code [ICAO]": "YBRM",
//       "Airport Code iATA,FAA": "BME",
//       "Airport Name": "Broome International Airport",
//       "Altitude (Ft)": 56,
//       "Country": "Australia",
//       "Latitude": 122.23200225830078,
//       "Location": "Broome",
//       "Longitude": -17.944700241088867,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 4320,
//       "Airport Code [ICAO]": "YWLM",
//       "Airport Code iATA,FAA": "NTL",
//       "Airport Name": "Newcastle Airport",
//       "Altitude (Ft)": 31,
//       "Country": "Australia",
//       "Latitude": 151.83399963378906,
//       "Location": "Newcastle",
//       "Longitude": -32.79499816894531,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 4321,
//       "Airport Code [ICAO]": "BIBA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bakki Airport",
//       "Altitude (Ft)": 45,
//       "Country": "Iceland",
//       "Latitude": -20.137500762939453,
//       "Location": "Bakki",
//       "Longitude": 63.55609893798828,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Atlantic/Reykjavik",
//       "Type": "airport"
//     },
//     {
//       "ID": 4322,
//       "Airport Code [ICAO]": "LOWK",
//       "Airport Code iATA,FAA": "KLU",
//       "Airport Name": "Klagenfurt Airport",
//       "Altitude (Ft)": 1472,
//       "Country": "Austria",
//       "Latitude": 14.3377,
//       "Location": "Klagenfurt",
//       "Longitude": 46.642502,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vienna",
//       "Type": "airport"
//     },
//     {
//       "ID": 4325,
//       "Airport Code [ICAO]": "ENHF",
//       "Airport Code iATA,FAA": "HFT",
//       "Airport Name": "Hammerfest Airport",
//       "Altitude (Ft)": 266,
//       "Country": "Norway",
//       "Latitude": 23.668600082397,
//       "Location": "Hammerfest",
//       "Longitude": 70.679702758789,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 4326,
//       "Airport Code [ICAO]": "ENHV",
//       "Airport Code iATA,FAA": "HVG",
//       "Airport Name": "Valan Airport",
//       "Altitude (Ft)": 44,
//       "Country": "Norway",
//       "Latitude": 25.983600616455,
//       "Location": "Honningsvag",
//       "Longitude": 71.009696960449,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 4327,
//       "Airport Code [ICAO]": "ENMH",
//       "Airport Code iATA,FAA": "MEH",
//       "Airport Name": "Mehamn Airport",
//       "Altitude (Ft)": 39,
//       "Country": "Norway",
//       "Latitude": 27.826700210571,
//       "Location": "Mehamn",
//       "Longitude": 71.02970123291,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 4328,
//       "Airport Code [ICAO]": "ENVD",
//       "Airport Code iATA,FAA": "VDS",
//       "Airport Name": "Vadsø Airport",
//       "Altitude (Ft)": 127,
//       "Country": "Norway",
//       "Latitude": 29.844699859619,
//       "Location": "Vadsø",
//       "Longitude": 70.065299987793,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 4330,
//       "Airport Code [ICAO]": "OIIE",
//       "Airport Code iATA,FAA": "IKA",
//       "Airport Name": "Imam Khomeini International Airport",
//       "Altitude (Ft)": 3305,
//       "Country": "Iran",
//       "Latitude": 51.152198791503906,
//       "Location": "Tehran",
//       "Longitude": 35.416099548339844,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 4331,
//       "Airport Code [ICAO]": "OIMM",
//       "Airport Code iATA,FAA": "MHD",
//       "Airport Name": "Mashhad International Airport",
//       "Altitude (Ft)": 3263,
//       "Country": "Iran",
//       "Latitude": 59.64099884033203,
//       "Location": "Mashhad",
//       "Longitude": 36.235198974609375,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 4333,
//       "Airport Code [ICAO]": "UIBS",
//       "Airport Code iATA,FAA": "UIK",
//       "Airport Name": "Ust-Ilimsk Airport",
//       "Altitude (Ft)": 1339,
//       "Country": "Russia",
//       "Latitude": 102.56500244140625,
//       "Location": "Ust Ilimsk",
//       "Longitude": 58.13610076904297,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Irkutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 4335,
//       "Airport Code [ICAO]": "KMEI",
//       "Airport Code iATA,FAA": "MEI",
//       "Airport Name": "Key Field",
//       "Altitude (Ft)": 297,
//       "Country": "United States",
//       "Latitude": -88.75189971923828,
//       "Location": "Meridian",
//       "Longitude": 32.33259963989258,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4336,
//       "Airport Code [ICAO]": "KSPI",
//       "Airport Code iATA,FAA": "SPI",
//       "Airport Name": "Abraham Lincoln Capital Airport",
//       "Altitude (Ft)": 598,
//       "Country": "United States",
//       "Latitude": -89.67790222,
//       "Location": "Springfield",
//       "Longitude": 39.84410095,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4338,
//       "Airport Code [ICAO]": "KCEZ",
//       "Airport Code iATA,FAA": "CEZ",
//       "Airport Name": "Cortez Municipal Airport",
//       "Altitude (Ft)": 5918,
//       "Country": "United States",
//       "Latitude": -108.627998352,
//       "Location": "Cortez",
//       "Longitude": 37.3030014038,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4339,
//       "Airport Code [ICAO]": "KHDN",
//       "Airport Code iATA,FAA": "HDN",
//       "Airport Name": "Yampa Valley Airport",
//       "Altitude (Ft)": 6606,
//       "Country": "United States",
//       "Latitude": -107.2180023,
//       "Location": "Hayden",
//       "Longitude": 40.48120117,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4340,
//       "Airport Code [ICAO]": "KGUP",
//       "Airport Code iATA,FAA": "GUP",
//       "Airport Name": "Gallup Municipal Airport",
//       "Altitude (Ft)": 6472,
//       "Country": "United States",
//       "Latitude": -108.789001465,
//       "Location": "Gallup",
//       "Longitude": 35.511100769,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4341,
//       "Airport Code [ICAO]": "KLBL",
//       "Airport Code iATA,FAA": "LBL",
//       "Airport Name": "Liberal Mid-America Regional Airport",
//       "Altitude (Ft)": 2885,
//       "Country": "United States",
//       "Latitude": -100.9599991,
//       "Location": "Liberal",
//       "Longitude": 37.0442009,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4342,
//       "Airport Code [ICAO]": "KLAA",
//       "Airport Code iATA,FAA": "LAA",
//       "Airport Name": "Lamar Municipal Airport",
//       "Altitude (Ft)": 3706,
//       "Country": "United States",
//       "Latitude": -102.68800354,
//       "Location": "Lamar",
//       "Longitude": 38.069698333699996,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4343,
//       "Airport Code [ICAO]": "KGLD",
//       "Airport Code iATA,FAA": "GLD",
//       "Airport Name": "Renner Field-Goodland Municipal Airport",
//       "Altitude (Ft)": 3656,
//       "Country": "United States",
//       "Latitude": -101.6989975,
//       "Location": "Goodland",
//       "Longitude": 39.37060165,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4344,
//       "Airport Code [ICAO]": "KCOD",
//       "Airport Code iATA,FAA": "COD",
//       "Airport Name": "Yellowstone Regional Airport",
//       "Altitude (Ft)": 5102,
//       "Country": "United States",
//       "Latitude": -109.024002075,
//       "Location": "Cody",
//       "Longitude": 44.520198822,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 4345,
//       "Airport Code [ICAO]": "ENOV",
//       "Airport Code iATA,FAA": "HOV",
//       "Airport Name": "Ørsta-Volda Airport, Hovden",
//       "Altitude (Ft)": 243,
//       "Country": "Norway",
//       "Latitude": 6.0741000175476,
//       "Location": "Orsta-Volda",
//       "Longitude": 62.180000305176,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 4347,
//       "Airport Code [ICAO]": "EGHE",
//       "Airport Code iATA,FAA": "ISC",
//       "Airport Name": "St. Mary's Airport",
//       "Altitude (Ft)": 116,
//       "Country": "United Kingdom",
//       "Latitude": -6.291669845581055,
//       "Location": "ST MARY\\'S",
//       "Longitude": 49.913299560546875,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 4348,
//       "Airport Code [ICAO]": "KSGF",
//       "Airport Code iATA,FAA": "SGF",
//       "Airport Name": "Springfield Branson National Airport",
//       "Altitude (Ft)": 1268,
//       "Country": "United States",
//       "Latitude": -93.38860321,
//       "Location": "Springfield",
//       "Longitude": 37.24570084,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4349,
//       "Airport Code [ICAO]": "ENNK",
//       "Airport Code iATA,FAA": "NVK",
//       "Airport Name": "Narvik Framnes Airport",
//       "Altitude (Ft)": 95,
//       "Country": "Norway",
//       "Latitude": 17.386699676514,
//       "Location": "Narvik",
//       "Longitude": 68.436897277832,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 4350,
//       "Airport Code [ICAO]": "ENBV",
//       "Airport Code iATA,FAA": "BVG",
//       "Airport Name": "Berlevåg Airport",
//       "Altitude (Ft)": 42,
//       "Country": "Norway",
//       "Latitude": 29.034201,
//       "Location": "Berlevag",
//       "Longitude": 70.871399,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 4351,
//       "Airport Code [ICAO]": "ENFB",
//       "Airport Code iATA,FAA": "FBU",
//       "Airport Name": "Oslo, Fornebu Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Norway",
//       "Latitude": 10.617199897766113,
//       "Location": "Oslo",
//       "Longitude": 59.89580154418945,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 4352,
//       "Airport Code [ICAO]": "UOOO",
//       "Airport Code iATA,FAA": "NSK",
//       "Airport Name": "Norilsk-Alykel Airport",
//       "Altitude (Ft)": 574,
//       "Country": "Russia",
//       "Latitude": 87.33219909667969,
//       "Location": "Norilsk",
//       "Longitude": 69.31109619140625,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Krasnoyarsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 4353,
//       "Airport Code [ICAO]": "URKA",
//       "Airport Code iATA,FAA": "AAQ",
//       "Airport Name": "Anapa Vityazevo Airport",
//       "Altitude (Ft)": 174,
//       "Country": "Russia",
//       "Latitude": 37.347301483154,
//       "Location": "Anapa",
//       "Longitude": 45.002101898193,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 4354,
//       "Airport Code [ICAO]": "KJLN",
//       "Airport Code iATA,FAA": "JLN",
//       "Airport Name": "Joplin Regional Airport",
//       "Altitude (Ft)": 981,
//       "Country": "United States",
//       "Latitude": -94.49829864501953,
//       "Location": "Joplin",
//       "Longitude": 37.151798248291016,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4355,
//       "Airport Code [ICAO]": "KABE",
//       "Airport Code iATA,FAA": "ABE",
//       "Airport Name": "Lehigh Valley International Airport",
//       "Altitude (Ft)": 393,
//       "Country": "United States",
//       "Latitude": -75.44080352783203,
//       "Location": "Allentown",
//       "Longitude": 40.652099609375,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4356,
//       "Airport Code [ICAO]": "KXNA",
//       "Airport Code iATA,FAA": "XNA",
//       "Airport Name": "Northwest Arkansas Regional Airport",
//       "Altitude (Ft)": 1287,
//       "Country": "United States",
//       "Latitude": -94.306801,
//       "Location": "Bentonville",
//       "Longitude": 36.281898,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4357,
//       "Airport Code [ICAO]": "UATG",
//       "Airport Code iATA,FAA": "GUW",
//       "Airport Name": "Atyrau Airport",
//       "Altitude (Ft)": -72,
//       "Country": "Kazakhstan",
//       "Latitude": 51.8213996887207,
//       "Location": "Atyrau",
//       "Longitude": 47.12189865112305,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Oral",
//       "Type": "airport"
//     },
//     {
//       "ID": 4358,
//       "Airport Code [ICAO]": "UAOO",
//       "Airport Code iATA,FAA": "KZO",
//       "Airport Name": "Kzyl-Orda Southwest Airport",
//       "Altitude (Ft)": 433,
//       "Country": "Kazakhstan",
//       "Latitude": 65.592499,
//       "Location": "Kzyl-Orda",
//       "Longitude": 44.706902,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Qyzylorda",
//       "Type": "airport"
//     },
//     {
//       "ID": 4359,
//       "Airport Code [ICAO]": "KSBN",
//       "Airport Code iATA,FAA": "SBN",
//       "Airport Name": "South Bend Regional Airport",
//       "Altitude (Ft)": 799,
//       "Country": "United States",
//       "Latitude": -86.31729888916016,
//       "Location": "South Bend",
//       "Longitude": 41.70869827270508,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4360,
//       "Airport Code [ICAO]": "UUBB",
//       "Airport Code iATA,FAA": "BKA",
//       "Airport Name": "Bykovo Airport",
//       "Altitude (Ft)": 427,
//       "Country": "Russia",
//       "Latitude": 38.0600013733,
//       "Location": "Moscow",
//       "Longitude": 55.6171989441,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 4362,
//       "Airport Code [ICAO]": "ULAA",
//       "Airport Code iATA,FAA": "ARH",
//       "Airport Name": "Talagi Airport",
//       "Altitude (Ft)": 62,
//       "Country": "Russia",
//       "Latitude": 40.71670150756836,
//       "Location": "Arkhangelsk",
//       "Longitude": 64.60030364990234,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 4363,
//       "Airport Code [ICAO]": "UWSS",
//       "Airport Code iATA,FAA": "RTW",
//       "Airport Name": "Saratov Central Airport",
//       "Altitude (Ft)": 499,
//       "Country": "Russia",
//       "Latitude": 46.04669952392578,
//       "Location": "Saratov",
//       "Longitude": 51.564998626708984,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 4364,
//       "Airport Code [ICAO]": "USMU",
//       "Airport Code iATA,FAA": "NUX",
//       "Airport Name": "Novy Urengoy Airport",
//       "Altitude (Ft)": 210,
//       "Country": "Russia",
//       "Latitude": 76.52030181884766,
//       "Location": "Novy Urengoy",
//       "Longitude": 66.06939697265625,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 4365,
//       "Airport Code [ICAO]": "USRO",
//       "Airport Code iATA,FAA": "NOJ",
//       "Airport Name": "Noyabrsk Airport",
//       "Altitude (Ft)": 446,
//       "Country": "Russia",
//       "Latitude": 75.2699966430664,
//       "Location": "Noyabrsk",
//       "Longitude": 63.18330001831055,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 4367,
//       "Airport Code [ICAO]": "UATE",
//       "Airport Code iATA,FAA": "SCO",
//       "Airport Name": "Aktau Airport",
//       "Altitude (Ft)": 73,
//       "Country": "Kazakhstan",
//       "Latitude": 51.091999,
//       "Location": "Aktau",
//       "Longitude": 43.8601,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Oral",
//       "Type": "airport"
//     },
//     {
//       "ID": 4368,
//       "Airport Code [ICAO]": "UUYH",
//       "Airport Code iATA,FAA": "UCT",
//       "Airport Name": "Ukhta Airport",
//       "Altitude (Ft)": 482,
//       "Country": "Russia",
//       "Latitude": 53.8046989440918,
//       "Location": "Ukhta",
//       "Longitude": 63.566898345947266,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 4369,
//       "Airport Code [ICAO]": "UUYS",
//       "Airport Code iATA,FAA": "USK",
//       "Airport Name": "Usinsk Airport",
//       "Altitude (Ft)": 262,
//       "Country": "Russia",
//       "Latitude": 57.3671989440918,
//       "Location": "Usinsk",
//       "Longitude": 66.00469970703125,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 4370,
//       "Airport Code [ICAO]": "UUYP",
//       "Airport Code iATA,FAA": "PEX",
//       "Airport Name": "Pechora Airport",
//       "Altitude (Ft)": 98,
//       "Country": "Russia",
//       "Latitude": 57.13079833984375,
//       "Location": "Pechora",
//       "Longitude": 65.12110137939453,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 4371,
//       "Airport Code [ICAO]": "ULAM",
//       "Airport Code iATA,FAA": "NNM",
//       "Airport Name": "Naryan Mar Airport",
//       "Altitude (Ft)": 36,
//       "Country": "Russia",
//       "Latitude": 53.12189865112305,
//       "Location": "Naryan-Mar",
//       "Longitude": 67.63999938964844,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 4372,
//       "Airport Code [ICAO]": "ULOO",
//       "Airport Code iATA,FAA": "PKV",
//       "Airport Name": "Pskov Airport",
//       "Altitude (Ft)": 154,
//       "Country": "Russia",
//       "Latitude": 28.395599365234375,
//       "Location": "Pskov",
//       "Longitude": 57.78390121459961,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 4373,
//       "Airport Code [ICAO]": "USRK",
//       "Airport Code iATA,FAA": "KGP",
//       "Airport Name": "Kogalym International Airport",
//       "Altitude (Ft)": 220,
//       "Country": "Russia",
//       "Latitude": 74.53379821777344,
//       "Location": "Kogalym",
//       "Longitude": 62.190399169921875,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 4374,
//       "Airport Code [ICAO]": "UNKL",
//       "Airport Code iATA,FAA": "KJA",
//       "Airport Name": "Yemelyanovo Airport",
//       "Altitude (Ft)": 942,
//       "Country": "Russia",
//       "Latitude": 92.493301,
//       "Location": "Krasnoyarsk",
//       "Longitude": 56.172901,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Krasnoyarsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 4375,
//       "Airport Code [ICAO]": "UAKK",
//       "Airport Code iATA,FAA": "KGF",
//       "Airport Name": "Sary-Arka Airport",
//       "Altitude (Ft)": 1765,
//       "Country": "Kazakhstan",
//       "Latitude": 73.33439636230469,
//       "Location": "Karaganda",
//       "Longitude": 49.670799255371094,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Qyzylorda",
//       "Type": "airport"
//     },
//     {
//       "ID": 4376,
//       "Airport Code [ICAO]": "UNCC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Novosibirsk North Airport",
//       "Altitude (Ft)": 558,
//       "Country": "Russia",
//       "Latitude": 82.90670013427734,
//       "Location": "Novosibirsk",
//       "Longitude": 55.09170150756836,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Krasnoyarsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 4377,
//       "Airport Code [ICAO]": "USHU",
//       "Airport Code iATA,FAA": "URJ",
//       "Airport Name": "Uray Airport",
//       "Altitude (Ft)": 190,
//       "Country": "Russia",
//       "Latitude": 64.82669830322266,
//       "Location": "Uraj",
//       "Longitude": 60.10329818725586,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 4379,
//       "Airport Code [ICAO]": "UUBI",
//       "Airport Code iATA,FAA": "IWA",
//       "Airport Name": "Ivanovo South Airport",
//       "Altitude (Ft)": 410,
//       "Country": "Russia",
//       "Latitude": 40.940799713134766,
//       "Location": "Ivanovo",
//       "Longitude": 56.93939971923828,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 4380,
//       "Airport Code [ICAO]": "ZYCC",
//       "Airport Code iATA,FAA": "CGQ",
//       "Airport Name": "Longjia Airport",
//       "Altitude (Ft)": 706,
//       "Country": "China",
//       "Latitude": 125.684997559,
//       "Location": "Changchun",
//       "Longitude": 43.9962005615,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 4381,
//       "Airport Code [ICAO]": "RJSN",
//       "Airport Code iATA,FAA": "KIJ",
//       "Airport Name": "Niigata Airport",
//       "Altitude (Ft)": 29,
//       "Country": "Japan",
//       "Latitude": 139.121002197,
//       "Location": "Niigata",
//       "Longitude": 37.9558982849,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 4382,
//       "Airport Code [ICAO]": "PJON",
//       "Airport Code iATA,FAA": "JON",
//       "Airport Name": "Johnston Atoll Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Johnston Atoll",
//       "Latitude": -169.533996582,
//       "Location": "Johnston Island",
//       "Longitude": 16.7285995483,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Johnston",
//       "Type": "airport"
//     },
//     {
//       "ID": 4383,
//       "Airport Code [ICAO]": "KSMD",
//       "Airport Code iATA,FAA": "SMD",
//       "Airport Name": "Smith Field",
//       "Altitude (Ft)": 835,
//       "Country": "United States",
//       "Latitude": -85.15280151,
//       "Location": "Fort Wayne IN",
//       "Longitude": 41.14339828,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4384,
//       "Airport Code [ICAO]": "KACV",
//       "Airport Code iATA,FAA": "ACV",
//       "Airport Name": "California Redwood Coast-Humboldt County Airport",
//       "Altitude (Ft)": 221,
//       "Country": "United States",
//       "Latitude": -124.109,
//       "Location": "Arcata CA",
//       "Longitude": 40.978101,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 4386,
//       "Airport Code [ICAO]": "KOAJ",
//       "Airport Code iATA,FAA": "OAJ",
//       "Airport Name": "Albert J Ellis Airport",
//       "Altitude (Ft)": 94,
//       "Country": "United States",
//       "Latitude": -77.61209869380001,
//       "Location": "Jacksonville NC",
//       "Longitude": 34.8292007446,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 4387,
//       "Airport Code [ICAO]": "KTCL",
//       "Airport Code iATA,FAA": "TCL",
//       "Airport Name": "Tuscaloosa Regional Airport",
//       "Altitude (Ft)": 170,
//       "Country": "United States",
//       "Latitude": -87.611396789551,
//       "Location": "Tuscaloosa AL",
//       "Longitude": 33.220600128174,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4388,
//       "Airport Code [ICAO]": "KDBQ",
//       "Airport Code iATA,FAA": "DBQ",
//       "Airport Name": "Dubuque Regional Airport",
//       "Altitude (Ft)": 1077,
//       "Country": "United States",
//       "Latitude": -90.70950317,
//       "Location": "Dubuque IA",
//       "Longitude": 42.40200043,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 4390,
//       "Airport Code [ICAO]": "VHST",
//       "Airport Code iATA,FAA": "HHP",
//       "Airport Name": "Shun Tak Heliport",
//       "Altitude (Ft)": 107,
//       "Country": "Hong Kong",
//       "Latitude": 114.152153015,
//       "Location": "Hong Kong",
//       "Longitude": 22.2893714905,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Hong_Kong",
//       "Type": "airport"
//     },
//     {
//       "ID": 5404,
//       "Airport Code [ICAO]": "AGAT",
//       "Airport Code iATA,FAA": "ATD",
//       "Airport Name": "Uru Harbour Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Solomon Islands",
//       "Latitude": 161.011002,
//       "Location": "Atoifi",
//       "Longitude": -8.87333,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Guadalcanal",
//       "Type": "airport"
//     },
//     {
//       "ID": 5405,
//       "Airport Code [ICAO]": "AGGA",
//       "Airport Code iATA,FAA": "AKS",
//       "Airport Name": "Gwaunaru'u Airport",
//       "Altitude (Ft)": 5,
//       "Country": "Solomon Islands",
//       "Latitude": 160.682007,
//       "Location": "Auki",
//       "Longitude": -8.70257,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Guadalcanal",
//       "Type": "airport"
//     },
//     {
//       "ID": 5406,
//       "Airport Code [ICAO]": "AGGE",
//       "Airport Code iATA,FAA": "BAS",
//       "Airport Name": "Ballalae Airport",
//       "Altitude (Ft)": 5,
//       "Country": "Solomon Islands",
//       "Latitude": 155.886656,
//       "Location": "Ballalae",
//       "Longitude": -6.990745,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Guadalcanal",
//       "Type": "airport"
//     },
//     {
//       "ID": 5407,
//       "Airport Code [ICAO]": "AGGF",
//       "Airport Code iATA,FAA": "FRE",
//       "Airport Name": "Fera/Maringe Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Solomon Islands",
//       "Latitude": 159.576996,
//       "Location": "Fera Island",
//       "Longitude": -8.1075,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Guadalcanal",
//       "Type": "airport"
//     },
//     {
//       "ID": 5408,
//       "Airport Code [ICAO]": "AGGI",
//       "Airport Code iATA,FAA": "MBU",
//       "Airport Name": "Babanakira Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Solomon Islands",
//       "Latitude": 159.83900451660156,
//       "Location": "Mbambanakira",
//       "Longitude": -9.7475004196167,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Guadalcanal",
//       "Type": "airport"
//     },
//     {
//       "ID": 5409,
//       "Airport Code [ICAO]": "AGGK",
//       "Airport Code iATA,FAA": "IRA",
//       "Airport Name": "Ngorangora Airport",
//       "Altitude (Ft)": 54,
//       "Country": "Solomon Islands",
//       "Latitude": 161.897994995,
//       "Location": "Kirakira",
//       "Longitude": -10.449700355500001,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Guadalcanal",
//       "Type": "airport"
//     },
//     {
//       "ID": 5410,
//       "Airport Code [ICAO]": "AGGL",
//       "Airport Code iATA,FAA": "SCZ",
//       "Airport Name": "Santa Cruz/Graciosa Bay/Luova Airport",
//       "Altitude (Ft)": 18,
//       "Country": "Solomon Islands",
//       "Latitude": 165.7949981689453,
//       "Location": "Santa Cruz/Graciosa Bay/Luova",
//       "Longitude": -10.72029972076416,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Guadalcanal",
//       "Type": "airport"
//     },
//     {
//       "ID": 5411,
//       "Airport Code [ICAO]": "AGGM",
//       "Airport Code iATA,FAA": "MUA",
//       "Airport Name": "Munda Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Solomon Islands",
//       "Latitude": 157.26300048828125,
//       "Location": "Munda",
//       "Longitude": -8.327969551086426,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Guadalcanal",
//       "Type": "airport"
//     },
//     {
//       "ID": 5412,
//       "Airport Code [ICAO]": "AGGN",
//       "Airport Code iATA,FAA": "GZO",
//       "Airport Name": "Nusatupe Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Solomon Islands",
//       "Latitude": 156.863998413,
//       "Location": "Gizo",
//       "Longitude": -8.09778022766,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Guadalcanal",
//       "Type": "airport"
//     },
//     {
//       "ID": 5413,
//       "Airport Code [ICAO]": "AGGO",
//       "Airport Code iATA,FAA": "MNY",
//       "Airport Name": "Mono Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Solomon Islands",
//       "Latitude": 155.56500244140625,
//       "Location": "Stirling Island",
//       "Longitude": -7.416940212249756,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Guadalcanal",
//       "Type": "airport"
//     },
//     {
//       "ID": 5414,
//       "Airport Code [ICAO]": "AGGR",
//       "Airport Code iATA,FAA": "RNL",
//       "Airport Name": "Rennell/Tingoa Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Solomon Islands",
//       "Latitude": 160.06300354003906,
//       "Location": "Rennell Island",
//       "Longitude": -11.533900260925293,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Guadalcanal",
//       "Type": "airport"
//     },
//     {
//       "ID": 5415,
//       "Airport Code [ICAO]": "AGGU",
//       "Airport Code iATA,FAA": "RUS",
//       "Airport Name": "Marau Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Solomon Islands",
//       "Latitude": 160.824996948,
//       "Location": "Marau",
//       "Longitude": -9.861669540409999,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Guadalcanal",
//       "Type": "airport"
//     },
//     {
//       "ID": 5416,
//       "Airport Code [ICAO]": "AGGV",
//       "Airport Code iATA,FAA": "VAO",
//       "Airport Name": "Suavanao Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Solomon Islands",
//       "Latitude": 158.7310028076172,
//       "Location": "Suavanao",
//       "Longitude": -7.585559844970703,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Guadalcanal",
//       "Type": "airport"
//     },
//     {
//       "ID": 5417,
//       "Airport Code [ICAO]": "AGKG",
//       "Airport Code iATA,FAA": "KGE",
//       "Airport Name": "Kaghau Airport",
//       "Altitude (Ft)": 30,
//       "Country": "Solomon Islands",
//       "Latitude": 157.585,
//       "Location": "Kagau Island",
//       "Longitude": -7.3305,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Guadalcanal",
//       "Type": "airport"
//     },
//     {
//       "ID": 5418,
//       "Airport Code [ICAO]": "AGRM",
//       "Airport Code iATA,FAA": "RBV",
//       "Airport Name": "Ramata Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Solomon Islands",
//       "Latitude": 157.64300537109375,
//       "Location": "Ramata",
//       "Longitude": -8.168060302734375,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Guadalcanal",
//       "Type": "airport"
//     },
//     {
//       "ID": 5419,
//       "Airport Code [ICAO]": "AYBK",
//       "Airport Code iATA,FAA": "BUA",
//       "Airport Name": "Buka Airport",
//       "Altitude (Ft)": 11,
//       "Country": "Papua New Guinea",
//       "Latitude": 154.67300415039062,
//       "Location": "Buka Island",
//       "Longitude": -5.4223198890686035,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 5420,
//       "Airport Code [ICAO]": "AYCH",
//       "Airport Code iATA,FAA": "CMU",
//       "Airport Name": "Chimbu Airport",
//       "Altitude (Ft)": 4974,
//       "Country": "Papua New Guinea",
//       "Latitude": 144.9709930419922,
//       "Location": "Kundiawa",
//       "Longitude": -6.024290084838867,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 5421,
//       "Airport Code [ICAO]": "AYDU",
//       "Airport Code iATA,FAA": "DAU",
//       "Airport Name": "Daru Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Papua New Guinea",
//       "Latitude": 143.207992554,
//       "Location": "Daru",
//       "Longitude": -9.08675956726,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 5422,
//       "Airport Code [ICAO]": "AYGN",
//       "Airport Code iATA,FAA": "GUR",
//       "Airport Name": "Gurney Airport",
//       "Altitude (Ft)": 88,
//       "Country": "Papua New Guinea",
//       "Latitude": 150.333999634,
//       "Location": "Gurney",
//       "Longitude": -10.3114995956,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 5423,
//       "Airport Code [ICAO]": "AYGR",
//       "Airport Code iATA,FAA": "PNP",
//       "Airport Name": "Girua Airport",
//       "Altitude (Ft)": 311,
//       "Country": "Papua New Guinea",
//       "Latitude": 148.309005737,
//       "Location": "Girua",
//       "Longitude": -8.80453968048,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 5424,
//       "Airport Code [ICAO]": "AYHK",
//       "Airport Code iATA,FAA": "HKN",
//       "Airport Name": "Kimbe Airport",
//       "Altitude (Ft)": 66,
//       "Country": "Papua New Guinea",
//       "Latitude": 150.40499877929688,
//       "Location": "Hoskins",
//       "Longitude": -5.462170124053955,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 5425,
//       "Airport Code [ICAO]": "AYKI",
//       "Airport Code iATA,FAA": "UNG",
//       "Airport Name": "Kiunga Airport",
//       "Altitude (Ft)": 88,
//       "Country": "Papua New Guinea",
//       "Latitude": 141.28199768066406,
//       "Location": "Kiunga",
//       "Longitude": -6.1257100105285645,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 5426,
//       "Airport Code [ICAO]": "AYKK",
//       "Airport Code iATA,FAA": "KRI",
//       "Airport Name": "Kikori Airport",
//       "Altitude (Ft)": 50,
//       "Country": "Papua New Guinea",
//       "Latitude": 144.2500762939453,
//       "Location": "Kikori",
//       "Longitude": -7.424379825592041,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 5427,
//       "Airport Code [ICAO]": "AYKM",
//       "Airport Code iATA,FAA": "KMA",
//       "Airport Name": "Kerema Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Papua New Guinea",
//       "Latitude": 145.770996094,
//       "Location": "Kerema",
//       "Longitude": -7.96361017227,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 5428,
//       "Airport Code [ICAO]": "AYKV",
//       "Airport Code iATA,FAA": "KVG",
//       "Airport Name": "Kavieng Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Papua New Guinea",
//       "Latitude": 150.807998657,
//       "Location": "Kavieng",
//       "Longitude": -2.57940006256,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 5429,
//       "Airport Code [ICAO]": "AYMN",
//       "Airport Code iATA,FAA": "MDU",
//       "Airport Name": "Mendi Airport",
//       "Altitude (Ft)": 5680,
//       "Country": "Papua New Guinea",
//       "Latitude": 143.656998,
//       "Location": "Mendi",
//       "Longitude": -6.14774,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 5430,
//       "Airport Code [ICAO]": "AYMO",
//       "Airport Code iATA,FAA": "MAS",
//       "Airport Name": "Momote Airport",
//       "Altitude (Ft)": 12,
//       "Country": "Papua New Guinea",
//       "Latitude": 147.423996,
//       "Location": "Momote",
//       "Longitude": -2.06189,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 5431,
//       "Airport Code [ICAO]": "AYMR",
//       "Airport Code iATA,FAA": "MXH",
//       "Airport Name": "Moro Airport",
//       "Altitude (Ft)": 2740,
//       "Country": "Papua New Guinea",
//       "Latitude": 143.238006592,
//       "Location": "Moro",
//       "Longitude": -6.36332988739,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 5432,
//       "Airport Code [ICAO]": "AYMS",
//       "Airport Code iATA,FAA": "MIS",
//       "Airport Name": "Misima Island Airport",
//       "Altitude (Ft)": 26,
//       "Country": "Papua New Guinea",
//       "Latitude": 152.837997437,
//       "Location": "Misima Island",
//       "Longitude": -10.689200401299999,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 5433,
//       "Airport Code [ICAO]": "AYTA",
//       "Airport Code iATA,FAA": "TIZ",
//       "Airport Name": "Tari Airport",
//       "Altitude (Ft)": 5500,
//       "Country": "Papua New Guinea",
//       "Latitude": 142.947998047,
//       "Location": "Tari",
//       "Longitude": -5.84499979019,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 5434,
//       "Airport Code [ICAO]": "AYTB",
//       "Airport Code iATA,FAA": "TBG",
//       "Airport Name": "Tabubil Airport",
//       "Altitude (Ft)": 1570,
//       "Country": "Papua New Guinea",
//       "Latitude": 141.225998,
//       "Location": "Tabubil",
//       "Longitude": -5.27861,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 5435,
//       "Airport Code [ICAO]": "AYTK",
//       "Airport Code iATA,FAA": "RAB",
//       "Airport Name": "Tokua Airport",
//       "Altitude (Ft)": 32,
//       "Country": "Papua New Guinea",
//       "Latitude": 152.380004883,
//       "Location": "Tokua",
//       "Longitude": -4.34045982361,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 5436,
//       "Airport Code [ICAO]": "AYVN",
//       "Airport Code iATA,FAA": "VAI",
//       "Airport Name": "Vanimo Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Papua New Guinea",
//       "Latitude": 141.3028,
//       "Location": "Vanimo",
//       "Longitude": -2.6926,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 5437,
//       "Airport Code [ICAO]": "AYWD",
//       "Airport Code iATA,FAA": "WBM",
//       "Airport Name": "Wapenamanda Airport",
//       "Altitude (Ft)": 5889,
//       "Country": "Papua New Guinea",
//       "Latitude": 143.89500427246094,
//       "Location": "Wapenamanda",
//       "Longitude": -5.6433000564575195,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 5438,
//       "Airport Code [ICAO]": "BGAP",
//       "Airport Code iATA,FAA": "LLU",
//       "Airport Name": "Alluitsup Paa Heliport",
//       "Altitude (Ft)": 54,
//       "Country": "Greenland",
//       "Latitude": -45.56917,
//       "Location": "Alluitsup Paa",
//       "Longitude": 60.46445,
//       "GMT Offset": "-3",
//       "Daylight Savings": "E",
//       "Region": "America/Godthab",
//       "Type": "airport"
//     },
//     {
//       "ID": 5439,
//       "Airport Code [ICAO]": "BGCO",
//       "Airport Code iATA,FAA": "CNP",
//       "Airport Name": "Neerlerit Inaat Airport",
//       "Altitude (Ft)": 45,
//       "Country": "Greenland",
//       "Latitude": -22.6504993439,
//       "Location": "Neerlerit Inaat",
//       "Longitude": 70.7431030273,
//       "GMT Offset": "-1",
//       "Daylight Savings": "E",
//       "Region": "America/Scoresbysund",
//       "Type": "airport"
//     },
//     {
//       "ID": 5440,
//       "Airport Code [ICAO]": "BGFH",
//       "Airport Code iATA,FAA": "JFR",
//       "Airport Name": "Paamiut Heliport",
//       "Altitude (Ft)": 63,
//       "Country": "Greenland",
//       "Latitude": -49.6624984741,
//       "Location": "Paamiut",
//       "Longitude": 61.9921989441,
//       "GMT Offset": "-3",
//       "Daylight Savings": "E",
//       "Region": "America/Godthab",
//       "Type": "airport"
//     },
//     {
//       "ID": 5441,
//       "Airport Code [ICAO]": "BGGN",
//       "Airport Code iATA,FAA": "JGO",
//       "Airport Name": "Qeqertarsuaq Heliport",
//       "Altitude (Ft)": 9,
//       "Country": "Greenland",
//       "Latitude": -53.5148763657,
//       "Location": "Qeqertarsuaq Airport",
//       "Longitude": 69.251181993,
//       "GMT Offset": "-3",
//       "Daylight Savings": "E",
//       "Region": "America/Godthab",
//       "Type": "airport"
//     },
//     {
//       "ID": 5442,
//       "Airport Code [ICAO]": "BGJH",
//       "Airport Code iATA,FAA": "JJU",
//       "Airport Name": "Qaqortoq Heliport",
//       "Altitude (Ft)": 53,
//       "Country": "Greenland",
//       "Latitude": -46.0299186409,
//       "Location": "Qaqortoq",
//       "Longitude": 60.715684155299996,
//       "GMT Offset": "-3",
//       "Daylight Savings": "E",
//       "Region": "America/Godthab",
//       "Type": "airport"
//     },
//     {
//       "ID": 5443,
//       "Airport Code [ICAO]": "BGMQ",
//       "Airport Code iATA,FAA": "JSU",
//       "Airport Name": "Maniitsoq Airport",
//       "Altitude (Ft)": 91,
//       "Country": "Greenland",
//       "Latitude": -52.9393997192,
//       "Location": "Maniitsoq",
//       "Longitude": 65.4124984741,
//       "GMT Offset": "-3",
//       "Daylight Savings": "E",
//       "Region": "America/Godthab",
//       "Type": "airport"
//     },
//     {
//       "ID": 5444,
//       "Airport Code [ICAO]": "BGNN",
//       "Airport Code iATA,FAA": "JNN",
//       "Airport Name": "Nanortalik Heliport",
//       "Altitude (Ft)": 17,
//       "Country": "Greenland",
//       "Latitude": -45.232976675,
//       "Location": "Nanortalik",
//       "Longitude": 60.141883975899994,
//       "GMT Offset": "-3",
//       "Daylight Savings": "E",
//       "Region": "America/Godthab",
//       "Type": "airport"
//     },
//     {
//       "ID": 5445,
//       "Airport Code [ICAO]": "BGNS",
//       "Airport Code iATA,FAA": "JNS",
//       "Airport Name": "Narsaq Heliport",
//       "Altitude (Ft)": 83,
//       "Country": "Greenland",
//       "Latitude": -46.059923172,
//       "Location": "Narsaq",
//       "Longitude": 60.9172827256,
//       "GMT Offset": "-3",
//       "Daylight Savings": "E",
//       "Region": "America/Godthab",
//       "Type": "airport"
//     },
//     {
//       "ID": 5446,
//       "Airport Code [ICAO]": "BGQQ",
//       "Airport Code iATA,FAA": "NAQ",
//       "Airport Name": "Qaanaaq Airport",
//       "Altitude (Ft)": 51,
//       "Country": "Greenland",
//       "Latitude": -69.3887023926,
//       "Location": "Qaanaaq",
//       "Longitude": 77.4886016846,
//       "GMT Offset": "-4",
//       "Daylight Savings": "E",
//       "Region": "America/Thule",
//       "Type": "airport"
//     },
//     {
//       "ID": 5447,
//       "Airport Code [ICAO]": "BGSS",
//       "Airport Code iATA,FAA": "JHS",
//       "Airport Name": "Sisimiut Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Greenland",
//       "Latitude": -53.7293014526,
//       "Location": "Sisimiut",
//       "Longitude": 66.9513015747,
//       "GMT Offset": "-3",
//       "Daylight Savings": "E",
//       "Region": "America/Godthab",
//       "Type": "airport"
//     },
//     {
//       "ID": 5448,
//       "Airport Code [ICAO]": "BGUK",
//       "Airport Code iATA,FAA": "JUV",
//       "Airport Name": "Upernavik Airport",
//       "Altitude (Ft)": 414,
//       "Country": "Greenland",
//       "Latitude": -56.1305999756,
//       "Location": "Upernavik",
//       "Longitude": 72.7901992798,
//       "GMT Offset": "-3",
//       "Daylight Savings": "E",
//       "Region": "America/Godthab",
//       "Type": "airport"
//     },
//     {
//       "ID": 5449,
//       "Airport Code [ICAO]": "BGUQ",
//       "Airport Code iATA,FAA": "JQA",
//       "Airport Name": "Qaarsut Airport",
//       "Altitude (Ft)": 289,
//       "Country": "Greenland",
//       "Latitude": -52.6962013245,
//       "Location": "Uummannaq",
//       "Longitude": 70.7341995239,
//       "GMT Offset": "-3",
//       "Daylight Savings": "E",
//       "Region": "America/Godthab",
//       "Type": "airport"
//     },
//     {
//       "ID": 5450,
//       "Airport Code [ICAO]": "BIGR",
//       "Airport Code iATA,FAA": "GRY",
//       "Airport Name": "Grímsey Airport",
//       "Altitude (Ft)": 66,
//       "Country": "Iceland",
//       "Latitude": -18.0173,
//       "Location": "Grímsey",
//       "Longitude": 66.5458,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Atlantic/Reykjavik",
//       "Type": "airport"
//     },
//     {
//       "ID": 5452,
//       "Airport Code [ICAO]": "BITN",
//       "Airport Code iATA,FAA": "THO",
//       "Airport Name": "Thorshofn Airport",
//       "Altitude (Ft)": 65,
//       "Country": "Iceland",
//       "Latitude": -15.335599899291992,
//       "Location": "Thorshofn",
//       "Longitude": 66.21849822998047,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Atlantic/Reykjavik",
//       "Type": "airport"
//     },
//     {
//       "ID": 5453,
//       "Airport Code [ICAO]": "BIVO",
//       "Airport Code iATA,FAA": "VPN",
//       "Airport Name": "Vopnafjörður Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Iceland",
//       "Latitude": -14.850600242614746,
//       "Location": "Vopnafjörður",
//       "Longitude": 65.72059631347656,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Atlantic/Reykjavik",
//       "Type": "airport"
//     },
//     {
//       "ID": 5456,
//       "Airport Code [ICAO]": "CAE5",
//       "Airport Code iATA,FAA": "YWS",
//       "Airport Name": "Whistler/Green Lake Water Aerodrome",
//       "Altitude (Ft)": 2100,
//       "Country": "Canada",
//       "Latitude": -122.948997498,
//       "Location": "Whistler",
//       "Longitude": 50.1436004639,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 5457,
//       "Airport Code [ICAO]": "CAJ4",
//       "Airport Code iATA,FAA": "YAA",
//       "Airport Name": "Anahim Lake Airport",
//       "Altitude (Ft)": 3635,
//       "Country": "Canada",
//       "Latitude": -125.3030014038086,
//       "Location": "Anahim Lake",
//       "Longitude": 52.45249938964844,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 5458,
//       "Airport Code [ICAO]": "CCA6",
//       "Airport Code iATA,FAA": "YWM",
//       "Airport Name": "Williams Harbour Airport",
//       "Altitude (Ft)": 70,
//       "Country": "Canada",
//       "Latitude": -55.784698486328125,
//       "Location": "Williams Harbour",
//       "Longitude": 52.566898345947266,
//       "GMT Offset": "-3.5",
//       "Daylight Savings": "A",
//       "Region": "America/St_Johns",
//       "Type": "airport"
//     },
//     {
//       "ID": 5459,
//       "Airport Code [ICAO]": "CCK4",
//       "Airport Code iATA,FAA": "YFX",
//       "Airport Name": "St. Lewis (Fox Harbour) Airport",
//       "Altitude (Ft)": 74,
//       "Country": "Canada",
//       "Latitude": -55.67390060424805,
//       "Location": "St. Lewis",
//       "Longitude": 52.372798919677734,
//       "GMT Offset": "-3.5",
//       "Daylight Savings": "A",
//       "Region": "America/St_Johns",
//       "Type": "airport"
//     },
//     {
//       "ID": 5460,
//       "Airport Code [ICAO]": "CCP4",
//       "Airport Code iATA,FAA": "YHA",
//       "Airport Name": "Port Hope Simpson Airport",
//       "Altitude (Ft)": 347,
//       "Country": "Canada",
//       "Latitude": -56.28609848022461,
//       "Location": "Port Hope Simpson",
//       "Longitude": 52.528099060058594,
//       "GMT Offset": "-3.5",
//       "Daylight Savings": "A",
//       "Region": "America/St_Johns",
//       "Type": "airport"
//     },
//     {
//       "ID": 5461,
//       "Airport Code [ICAO]": "CCZ2",
//       "Airport Code iATA,FAA": "YRG",
//       "Airport Name": "Rigolet Airport",
//       "Altitude (Ft)": 180,
//       "Country": "Canada",
//       "Latitude": -58.45750045776367,
//       "Location": "Rigolet",
//       "Longitude": 54.1796989440918,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Halifax",
//       "Type": "airport"
//     },
//     {
//       "ID": 5462,
//       "Airport Code [ICAO]": "CEB3",
//       "Airport Code iATA,FAA": "YCK",
//       "Airport Name": "Colville Lake Airport",
//       "Altitude (Ft)": 850,
//       "Country": "Canada",
//       "Latitude": -126.08,
//       "Location": "Colville Lake",
//       "Longitude": 67.0392,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 5463,
//       "Airport Code [ICAO]": "CEM3",
//       "Airport Code iATA,FAA": "YLE",
//       "Airport Name": "Whatì Airport",
//       "Altitude (Ft)": 882,
//       "Country": "Canada",
//       "Latitude": -117.24600219726562,
//       "Location": "Whatì",
//       "Longitude": 63.13169860839844,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 5464,
//       "Airport Code [ICAO]": "CJV7",
//       "Airport Code iATA,FAA": "SUR",
//       "Airport Name": "Summer Beaver Airport",
//       "Altitude (Ft)": 832,
//       "Country": "Canada",
//       "Latitude": -88.54190063476562,
//       "Location": "Summer Beaver",
//       "Longitude": 52.70859909057617,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5465,
//       "Airport Code [ICAO]": "CKB6",
//       "Airport Code iATA,FAA": "YAX",
//       "Airport Name": "Wapekeka Airport",
//       "Altitude (Ft)": 712,
//       "Country": "Canada",
//       "Latitude": -89.57939910888672,
//       "Location": "Angling Lake",
//       "Longitude": 53.84920120239258,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5466,
//       "Airport Code [ICAO]": "CKL3",
//       "Airport Code iATA,FAA": "WNN",
//       "Airport Name": "Wunnumin Lake Airport",
//       "Altitude (Ft)": 819,
//       "Country": "Canada",
//       "Latitude": -89.28919982910156,
//       "Location": "Wunnumin Lake",
//       "Longitude": 52.89390182495117,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5467,
//       "Airport Code [ICAO]": "CKQ3",
//       "Airport Code iATA,FAA": "YNO",
//       "Airport Name": "North Spirit Lake Airport",
//       "Altitude (Ft)": 1082,
//       "Country": "Canada",
//       "Latitude": -92.97109985351562,
//       "Location": "North Spirit Lake",
//       "Longitude": 52.4900016784668,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5468,
//       "Airport Code [ICAO]": "CNE3",
//       "Airport Code iATA,FAA": "XBE",
//       "Airport Name": "Bearskin Lake Airport",
//       "Altitude (Ft)": 800,
//       "Country": "Canada",
//       "Latitude": -91.0271987915039,
//       "Location": "Bearskin Lake",
//       "Longitude": 53.965599060058594,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5469,
//       "Airport Code [ICAO]": "CNM5",
//       "Airport Code iATA,FAA": "KIF",
//       "Airport Name": "Kingfisher Lake Airport",
//       "Altitude (Ft)": 866,
//       "Country": "Canada",
//       "Latitude": -89.85530090332031,
//       "Location": "Kingfisher Lake",
//       "Longitude": 53.01250076293945,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5470,
//       "Airport Code [ICAO]": "CNT3",
//       "Airport Code iATA,FAA": "YOG",
//       "Airport Name": "Ogoki Post Airport",
//       "Altitude (Ft)": 594,
//       "Country": "Canada",
//       "Latitude": -85.9017028809,
//       "Location": "Ogoki Post",
//       "Longitude": 51.6585998535,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5471,
//       "Airport Code [ICAO]": "CPV7",
//       "Airport Code iATA,FAA": "YHP",
//       "Airport Name": "Poplar Hill Airport",
//       "Altitude (Ft)": 1095,
//       "Country": "Canada",
//       "Latitude": -94.2556,
//       "Location": "Poplar Hill",
//       "Longitude": 52.1133,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5472,
//       "Airport Code [ICAO]": "CSU2",
//       "Airport Code iATA,FAA": "YKU",
//       "Airport Name": "Chisasibi Airport",
//       "Altitude (Ft)": 43,
//       "Country": "Canada",
//       "Latitude": -78.91690063476562,
//       "Location": "Chisasibi",
//       "Longitude": 53.805599212646484,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5473,
//       "Airport Code [ICAO]": "CTB6",
//       "Airport Code iATA,FAA": "ZTB",
//       "Airport Name": "Tête-à-la-Baleine Airport",
//       "Altitude (Ft)": 107,
//       "Country": "Canada",
//       "Latitude": -59.38359832763672,
//       "Location": "Tête-à-la-Baleine",
//       "Longitude": 50.674400329589844,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Blanc-Sablon",
//       "Type": "airport"
//     },
//     {
//       "ID": 5474,
//       "Airport Code [ICAO]": "CTU5",
//       "Airport Code iATA,FAA": "ZLT",
//       "Airport Name": "La Tabatière Airport",
//       "Altitude (Ft)": 102,
//       "Country": "Canada",
//       "Latitude": -58.97560119628906,
//       "Location": "La Tabatière",
//       "Longitude": 50.8307991027832,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Blanc-Sablon",
//       "Type": "airport"
//     },
//     {
//       "ID": 5478,
//       "Airport Code [ICAO]": "CYAC",
//       "Airport Code iATA,FAA": "YAC",
//       "Airport Name": "Cat Lake Airport",
//       "Altitude (Ft)": 1344,
//       "Country": "Canada",
//       "Latitude": -91.82440185546875,
//       "Location": "Cat Lake",
//       "Longitude": 51.72719955444336,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5479,
//       "Airport Code [ICAO]": "CYAG",
//       "Airport Code iATA,FAA": "YAG",
//       "Airport Name": "Fort Frances Municipal Airport",
//       "Altitude (Ft)": 1125,
//       "Country": "Canada",
//       "Latitude": -93.439697265625,
//       "Location": "Fort Frances",
//       "Longitude": 48.65420150756836,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5480,
//       "Airport Code [ICAO]": "CYAQ",
//       "Airport Code iATA,FAA": "XKS",
//       "Airport Name": "Kasabonika Airport",
//       "Altitude (Ft)": 672,
//       "Country": "Canada",
//       "Latitude": -88.6427993774414,
//       "Location": "Kasabonika",
//       "Longitude": 53.52470016479492,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5481,
//       "Airport Code [ICAO]": "CYAS",
//       "Airport Code iATA,FAA": "YKG",
//       "Airport Name": "Kangirsuk Airport",
//       "Altitude (Ft)": 403,
//       "Country": "Canada",
//       "Latitude": -69.99919891357422,
//       "Location": "Kangirsuk",
//       "Longitude": 60.027198791503906,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5482,
//       "Airport Code [ICAO]": "CYAT",
//       "Airport Code iATA,FAA": "YAT",
//       "Airport Name": "Attawapiskat Airport",
//       "Altitude (Ft)": 31,
//       "Country": "Canada",
//       "Latitude": -82.43190002441406,
//       "Location": "Attawapiskat",
//       "Longitude": 52.9275016784668,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5483,
//       "Airport Code [ICAO]": "CYAX",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lac Du Bonnet Airport",
//       "Altitude (Ft)": 850,
//       "Country": "Canada",
//       "Latitude": -96.0100021362,
//       "Location": "Lac Du Bonnet",
//       "Longitude": 50.2943992615,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5484,
//       "Airport Code [ICAO]": "CYBE",
//       "Airport Code iATA,FAA": "YBE",
//       "Airport Name": "Uranium City Airport",
//       "Altitude (Ft)": 1044,
//       "Country": "Canada",
//       "Latitude": -108.48100280761719,
//       "Location": "Uranium City",
//       "Longitude": 59.5614013671875,
//       "GMT Offset": "-6",
//       "Daylight Savings": "N",
//       "Region": "America/Regina",
//       "Type": "airport"
//     },
//     {
//       "ID": 5485,
//       "Airport Code [ICAO]": "CYBX",
//       "Airport Code iATA,FAA": "YBX",
//       "Airport Name": "Lourdes de Blanc Sablon Airport",
//       "Altitude (Ft)": 121,
//       "Country": "Canada",
//       "Latitude": -57.185298919699996,
//       "Location": "Lourdes-De-Blanc-Sablon",
//       "Longitude": 51.443599700899995,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Blanc-Sablon",
//       "Type": "airport"
//     },
//     {
//       "ID": 5486,
//       "Airport Code [ICAO]": "CYCA",
//       "Airport Code iATA,FAA": "YRF",
//       "Airport Name": "Cartwright Airport",
//       "Altitude (Ft)": 40,
//       "Country": "Canada",
//       "Latitude": -57.041900634765625,
//       "Location": "Cartwright",
//       "Longitude": 53.68280029296875,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Halifax",
//       "Type": "airport"
//     },
//     {
//       "ID": 5487,
//       "Airport Code [ICAO]": "CYCS",
//       "Airport Code iATA,FAA": "YCS",
//       "Airport Name": "Chesterfield Inlet Airport",
//       "Altitude (Ft)": 32,
//       "Country": "Canada",
//       "Latitude": -90.73110198970001,
//       "Location": "Chesterfield Inlet",
//       "Longitude": 63.346900939899996,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5488,
//       "Airport Code [ICAO]": "CYDP",
//       "Airport Code iATA,FAA": "YDP",
//       "Airport Name": "Nain Airport",
//       "Altitude (Ft)": 22,
//       "Country": "Canada",
//       "Latitude": -61.680301666259766,
//       "Location": "Nain",
//       "Longitude": 56.549198150634766,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Halifax",
//       "Type": "airport"
//     },
//     {
//       "ID": 5489,
//       "Airport Code [ICAO]": "CYER",
//       "Airport Code iATA,FAA": "YER",
//       "Airport Name": "Fort Severn Airport",
//       "Altitude (Ft)": 48,
//       "Country": "Canada",
//       "Latitude": -87.67610168457031,
//       "Location": "Fort Severn",
//       "Longitude": 56.01890182495117,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5490,
//       "Airport Code [ICAO]": "CYFA",
//       "Airport Code iATA,FAA": "YFA",
//       "Airport Name": "Fort Albany Airport",
//       "Altitude (Ft)": 48,
//       "Country": "Canada",
//       "Latitude": -81.6968994140625,
//       "Location": "Fort Albany",
//       "Longitude": 52.20140075683594,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5491,
//       "Airport Code [ICAO]": "CYFH",
//       "Airport Code iATA,FAA": "YFH",
//       "Airport Name": "Fort Hope Airport",
//       "Altitude (Ft)": 899,
//       "Country": "Canada",
//       "Latitude": -87.90779876708984,
//       "Location": "Fort Hope",
//       "Longitude": 51.5619010925293,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5492,
//       "Airport Code [ICAO]": "CYFT",
//       "Airport Code iATA,FAA": "YMN",
//       "Airport Name": "Makkovik Airport",
//       "Altitude (Ft)": 234,
//       "Country": "Canada",
//       "Latitude": -59.1864013671875,
//       "Location": "Makkovik",
//       "Longitude": 55.076900482177734,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Halifax",
//       "Type": "airport"
//     },
//     {
//       "ID": 5493,
//       "Airport Code [ICAO]": "CYGB",
//       "Airport Code iATA,FAA": "YGB",
//       "Airport Name": "Texada Gillies Bay Airport",
//       "Altitude (Ft)": 326,
//       "Country": "Canada",
//       "Latitude": -124.51799774169922,
//       "Location": "Texada",
//       "Longitude": 49.69419860839844,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 5494,
//       "Airport Code [ICAO]": "CYGO",
//       "Airport Code iATA,FAA": "YGO",
//       "Airport Name": "Gods Lake Narrows Airport",
//       "Altitude (Ft)": 617,
//       "Country": "Canada",
//       "Latitude": -94.49140167236328,
//       "Location": "Gods Lake Narrows",
//       "Longitude": 54.55889892578125,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5495,
//       "Airport Code [ICAO]": "CYGT",
//       "Airport Code iATA,FAA": "YGT",
//       "Airport Name": "Igloolik Airport",
//       "Altitude (Ft)": 174,
//       "Country": "Canada",
//       "Latitude": -81.8161010742,
//       "Location": "Igloolik",
//       "Longitude": 69.3647003174,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5496,
//       "Airport Code [ICAO]": "CYGW",
//       "Airport Code iATA,FAA": "YGW",
//       "Airport Name": "Kuujjuarapik Airport",
//       "Altitude (Ft)": 34,
//       "Country": "Canada",
//       "Latitude": -77.76529693603516,
//       "Location": "Kuujjuarapik",
//       "Longitude": 55.281898498535156,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5497,
//       "Airport Code [ICAO]": "CYGX",
//       "Airport Code iATA,FAA": "YGX",
//       "Airport Name": "Gillam Airport",
//       "Altitude (Ft)": 476,
//       "Country": "Canada",
//       "Latitude": -94.71060180664062,
//       "Location": "Gillam",
//       "Longitude": 56.35749816894531,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5498,
//       "Airport Code [ICAO]": "CYGZ",
//       "Airport Code iATA,FAA": "YGZ",
//       "Airport Name": "Grise Fiord Airport",
//       "Altitude (Ft)": 146,
//       "Country": "Canada",
//       "Latitude": -82.90920257570001,
//       "Location": "Grise Fiord",
//       "Longitude": 76.4261016846,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5499,
//       "Airport Code [ICAO]": "CYHA",
//       "Airport Code iATA,FAA": "YQC",
//       "Airport Name": "Quaqtaq Airport",
//       "Altitude (Ft)": 103,
//       "Country": "Canada",
//       "Latitude": -69.6177978515625,
//       "Location": "Quaqtaq",
//       "Longitude": 61.0463981628418,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5500,
//       "Airport Code [ICAO]": "CYHC",
//       "Airport Code iATA,FAA": "CXH",
//       "Airport Name": "Vancouver Harbour Water Aerodrome",
//       "Altitude (Ft)": 0,
//       "Country": "Canada",
//       "Latitude": -123.111000061,
//       "Location": "Vancouver",
//       "Longitude": 49.2943992615,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 5501,
//       "Airport Code [ICAO]": "CYHH",
//       "Airport Code iATA,FAA": "YNS",
//       "Airport Name": "Nemiscau Airport",
//       "Altitude (Ft)": 802,
//       "Country": "Canada",
//       "Latitude": -76.1355972290039,
//       "Location": "Nemiscau",
//       "Longitude": 51.69110107421875,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5502,
//       "Airport Code [ICAO]": "CYHO",
//       "Airport Code iATA,FAA": "YHO",
//       "Airport Name": "Hopedale Airport",
//       "Altitude (Ft)": 39,
//       "Country": "Canada",
//       "Latitude": -60.228599548339844,
//       "Location": "Hopedale",
//       "Longitude": 55.448299407958984,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Halifax",
//       "Type": "airport"
//     },
//     {
//       "ID": 5503,
//       "Airport Code [ICAO]": "CYHR",
//       "Airport Code iATA,FAA": "YHR",
//       "Airport Name": "Chevery Airport",
//       "Altitude (Ft)": 39,
//       "Country": "Canada",
//       "Latitude": -59.63669967651367,
//       "Location": "Chevery",
//       "Longitude": 50.46889877319336,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Blanc-Sablon",
//       "Type": "airport"
//     },
//     {
//       "ID": 5504,
//       "Airport Code [ICAO]": "CYIK",
//       "Airport Code iATA,FAA": "YIK",
//       "Airport Name": "Ivujivik Airport",
//       "Altitude (Ft)": 126,
//       "Country": "Canada",
//       "Latitude": -77.92530059814453,
//       "Location": "Ivujivik",
//       "Longitude": 62.417301177978516,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5505,
//       "Airport Code [ICAO]": "CYIV",
//       "Airport Code iATA,FAA": "YIV",
//       "Airport Name": "Island Lake Airport",
//       "Altitude (Ft)": 770,
//       "Country": "Canada",
//       "Latitude": -94.65360260009766,
//       "Location": "Island Lake",
//       "Longitude": 53.857200622558594,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5506,
//       "Airport Code [ICAO]": "CYKO",
//       "Airport Code iATA,FAA": "AKV",
//       "Airport Name": "Akulivik Airport",
//       "Altitude (Ft)": 75,
//       "Country": "Canada",
//       "Latitude": -78.14859771728516,
//       "Location": "Akulivik",
//       "Longitude": 60.818599700927734,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5507,
//       "Airport Code [ICAO]": "CYKQ",
//       "Airport Code iATA,FAA": "YKQ",
//       "Airport Name": "Waskaganish Airport",
//       "Altitude (Ft)": 80,
//       "Country": "Canada",
//       "Latitude": -78.75830078125,
//       "Location": "Waskaganish",
//       "Longitude": 51.47330093383789,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5508,
//       "Airport Code [ICAO]": "CYLA",
//       "Airport Code iATA,FAA": "YPJ",
//       "Airport Name": "Aupaluk Airport",
//       "Altitude (Ft)": 119,
//       "Country": "Canada",
//       "Latitude": -69.59970092773438,
//       "Location": "Aupaluk",
//       "Longitude": 59.29669952392578,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5509,
//       "Airport Code [ICAO]": "CYLC",
//       "Airport Code iATA,FAA": "YLC",
//       "Airport Name": "Kimmirut Airport",
//       "Altitude (Ft)": 175,
//       "Country": "Canada",
//       "Latitude": -69.88330078119999,
//       "Location": "Kimmirut",
//       "Longitude": 62.8499984741,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5510,
//       "Airport Code [ICAO]": "CYLH",
//       "Airport Code iATA,FAA": "YLH",
//       "Airport Name": "Lansdowne House Airport",
//       "Altitude (Ft)": 834,
//       "Country": "Canada",
//       "Latitude": -87.93419647216797,
//       "Location": "Lansdowne House",
//       "Longitude": 52.19559860229492,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5511,
//       "Airport Code [ICAO]": "CYSG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "St Georges Airport",
//       "Altitude (Ft)": 893,
//       "Country": "Canada",
//       "Latitude": -70.714699,
//       "Location": "Lutselk'e",
//       "Longitude": 46.096401,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5512,
//       "Airport Code [ICAO]": "CYLU",
//       "Airport Code iATA,FAA": "XGR",
//       "Airport Name": "Kangiqsualujjuaq (Georges River) Airport",
//       "Altitude (Ft)": 215,
//       "Country": "Canada",
//       "Latitude": -65.9927978515625,
//       "Location": "Kangiqsualujjuaq",
//       "Longitude": 58.71139907836914,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5513,
//       "Airport Code [ICAO]": "CYMH",
//       "Airport Code iATA,FAA": "YMH",
//       "Airport Name": "Mary's Harbour Airport",
//       "Altitude (Ft)": 38,
//       "Country": "Canada",
//       "Latitude": -55.847198486328125,
//       "Location": "Mary's Harbour",
//       "Longitude": 52.302799224853516,
//       "GMT Offset": "-3.5",
//       "Daylight Savings": "A",
//       "Region": "America/St_Johns",
//       "Type": "airport"
//     },
//     {
//       "ID": 5514,
//       "Airport Code [ICAO]": "CYMT",
//       "Airport Code iATA,FAA": "YMT",
//       "Airport Name": "Chapais Airport",
//       "Altitude (Ft)": 1270,
//       "Country": "Canada",
//       "Latitude": -74.5280990600586,
//       "Location": "Chibougamau",
//       "Longitude": 49.77190017700195,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5515,
//       "Airport Code [ICAO]": "CYMU",
//       "Airport Code iATA,FAA": "YUD",
//       "Airport Name": "Umiujaq Airport",
//       "Altitude (Ft)": 250,
//       "Country": "Canada",
//       "Latitude": -76.51830291748047,
//       "Location": "Umiujaq",
//       "Longitude": 56.53609848022461,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5516,
//       "Airport Code [ICAO]": "CYNC",
//       "Airport Code iATA,FAA": "YNC",
//       "Airport Name": "Wemindji Airport",
//       "Altitude (Ft)": 66,
//       "Country": "Canada",
//       "Latitude": -78.83110046386719,
//       "Location": "Wemindji",
//       "Longitude": 53.01060104370117,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5517,
//       "Airport Code [ICAO]": "CYNE",
//       "Airport Code iATA,FAA": "YNE",
//       "Airport Name": "Norway House Airport",
//       "Altitude (Ft)": 734,
//       "Country": "Canada",
//       "Latitude": -97.84420013427734,
//       "Location": "Norway House",
//       "Longitude": 53.95830154418945,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5518,
//       "Airport Code [ICAO]": "CYNL",
//       "Airport Code iATA,FAA": "YNL",
//       "Airport Name": "Points North Landing Airport",
//       "Altitude (Ft)": 1605,
//       "Country": "Canada",
//       "Latitude": -104.08200073242188,
//       "Location": "Points North Landing",
//       "Longitude": 58.27669906616211,
//       "GMT Offset": "-6",
//       "Daylight Savings": "N",
//       "Region": "America/Regina",
//       "Type": "airport"
//     },
//     {
//       "ID": 5519,
//       "Airport Code [ICAO]": "CYOH",
//       "Airport Code iATA,FAA": "YOH",
//       "Airport Name": "Oxford House Airport",
//       "Altitude (Ft)": 663,
//       "Country": "Canada",
//       "Latitude": -95.27890014648438,
//       "Location": "Oxford House",
//       "Longitude": 54.93330001831055,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5520,
//       "Airport Code [ICAO]": "CYPH",
//       "Airport Code iATA,FAA": "YPH",
//       "Airport Name": "Inukjuak Airport",
//       "Altitude (Ft)": 83,
//       "Country": "Canada",
//       "Latitude": -78.07689666748047,
//       "Location": "Inukjuak",
//       "Longitude": 58.471900939941406,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5521,
//       "Airport Code [ICAO]": "CYPM",
//       "Airport Code iATA,FAA": "YPM",
//       "Airport Name": "Pikangikum Airport",
//       "Altitude (Ft)": 1114,
//       "Country": "Canada",
//       "Latitude": -93.97329711914062,
//       "Location": "Pikangikum",
//       "Longitude": 51.819698333740234,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5522,
//       "Airport Code [ICAO]": "CYPO",
//       "Airport Code iATA,FAA": "YPO",
//       "Airport Name": "Peawanuck Airport",
//       "Altitude (Ft)": 173,
//       "Country": "Canada",
//       "Latitude": -85.44329833984375,
//       "Location": "Peawanuck",
//       "Longitude": 54.98809814453125,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5523,
//       "Airport Code [ICAO]": "CYPW",
//       "Airport Code iATA,FAA": "YPW",
//       "Airport Name": "Powell River Airport",
//       "Altitude (Ft)": 425,
//       "Country": "Canada",
//       "Latitude": -124.5,
//       "Location": "Powell River",
//       "Longitude": 49.83420181274414,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 5524,
//       "Airport Code [ICAO]": "CYQD",
//       "Airport Code iATA,FAA": "YQD",
//       "Airport Name": "The Pas Airport",
//       "Altitude (Ft)": 887,
//       "Country": "Canada",
//       "Latitude": -101.09100341796875,
//       "Location": "The Pas",
//       "Longitude": 53.97140121459961,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5525,
//       "Airport Code [ICAO]": "CYQN",
//       "Airport Code iATA,FAA": "YQN",
//       "Airport Name": "Nakina Airport",
//       "Altitude (Ft)": 1057,
//       "Country": "Canada",
//       "Latitude": -86.69640350341797,
//       "Location": "Nakina",
//       "Longitude": 50.18280029296875,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5526,
//       "Airport Code [ICAO]": "CYRA",
//       "Airport Code iATA,FAA": "YRA",
//       "Airport Name": "Rae Lakes Airport",
//       "Altitude (Ft)": 723,
//       "Country": "Canada",
//       "Latitude": -117.30999755859375,
//       "Location": "Gamètì",
//       "Longitude": 64.11609649658203,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 5527,
//       "Airport Code [ICAO]": "CYRL",
//       "Airport Code iATA,FAA": "YRL",
//       "Airport Name": "Red Lake Airport",
//       "Altitude (Ft)": 1265,
//       "Country": "Canada",
//       "Latitude": -93.79309844970703,
//       "Location": "Red Lake",
//       "Longitude": 51.066898345947266,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5528,
//       "Airport Code [ICAO]": "CYSF",
//       "Airport Code iATA,FAA": "YSF",
//       "Airport Name": "Stony Rapids Airport",
//       "Altitude (Ft)": 805,
//       "Country": "Canada",
//       "Latitude": -105.84100341796875,
//       "Location": "Stony Rapids",
//       "Longitude": 59.250301361083984,
//       "GMT Offset": "-6",
//       "Daylight Savings": "N",
//       "Region": "America/Regina",
//       "Type": "airport"
//     },
//     {
//       "ID": 5529,
//       "Airport Code [ICAO]": "CYSK",
//       "Airport Code iATA,FAA": "YSK",
//       "Airport Name": "Sanikiluaq Airport",
//       "Altitude (Ft)": 104,
//       "Country": "Canada",
//       "Latitude": -79.2466964722,
//       "Location": "Sanikiluaq",
//       "Longitude": 56.5377998352,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 5530,
//       "Airport Code [ICAO]": "CYST",
//       "Airport Code iATA,FAA": "YST",
//       "Airport Name": "St. Theresa Point Airport",
//       "Altitude (Ft)": 773,
//       "Country": "Canada",
//       "Latitude": -94.85189819335938,
//       "Location": "St. Theresa Point",
//       "Longitude": 53.84560012817383,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5531,
//       "Airport Code [ICAO]": "CYTL",
//       "Airport Code iATA,FAA": "YTL",
//       "Airport Name": "Big Trout Lake Airport",
//       "Altitude (Ft)": 729,
//       "Country": "Canada",
//       "Latitude": -89.89689636230469,
//       "Location": "Big Trout Lake",
//       "Longitude": 53.81779861450195,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5532,
//       "Airport Code [ICAO]": "CYVZ",
//       "Airport Code iATA,FAA": "YVZ",
//       "Airport Name": "Deer Lake Airport",
//       "Altitude (Ft)": 1092,
//       "Country": "Canada",
//       "Latitude": -94.0614013671875,
//       "Location": "Deer Lake",
//       "Longitude": 52.655799865722656,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5533,
//       "Airport Code [ICAO]": "CYWP",
//       "Airport Code iATA,FAA": "YWP",
//       "Airport Name": "Webequie Airport",
//       "Altitude (Ft)": 685,
//       "Country": "Canada",
//       "Latitude": -87.3748683929,
//       "Location": "Webequie",
//       "Longitude": 52.9593933975,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5534,
//       "Airport Code [ICAO]": "CYXN",
//       "Airport Code iATA,FAA": "YXN",
//       "Airport Name": "Whale Cove Airport",
//       "Altitude (Ft)": 40,
//       "Country": "Canada",
//       "Latitude": -92.59809875490001,
//       "Location": "Whale Cove",
//       "Longitude": 62.24000167849999,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5535,
//       "Airport Code [ICAO]": "CYZG",
//       "Airport Code iATA,FAA": "YZG",
//       "Airport Name": "Salluit Airport",
//       "Altitude (Ft)": 743,
//       "Country": "Canada",
//       "Latitude": -75.66719818115234,
//       "Location": "Salluit",
//       "Longitude": 62.17940139770508,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5536,
//       "Airport Code [ICAO]": "CZAC",
//       "Airport Code iATA,FAA": "ZAC",
//       "Airport Name": "York Landing Airport",
//       "Altitude (Ft)": 621,
//       "Country": "Canada",
//       "Latitude": -96.08920288085938,
//       "Location": "York Landing",
//       "Longitude": 56.08940124511719,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5537,
//       "Airport Code [ICAO]": "CZBD",
//       "Airport Code iATA,FAA": "ILF",
//       "Airport Name": "Ilford Airport",
//       "Altitude (Ft)": 642,
//       "Country": "Canada",
//       "Latitude": -95.613899231,
//       "Location": "Ilford",
//       "Longitude": 56.0614013672,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5538,
//       "Airport Code [ICAO]": "CZBF",
//       "Airport Code iATA,FAA": "ZBF",
//       "Airport Name": "Bathurst Airport",
//       "Altitude (Ft)": 193,
//       "Country": "Canada",
//       "Latitude": -65.738899231,
//       "Location": "Bathurst",
//       "Longitude": 47.629699707,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Halifax",
//       "Type": "airport"
//     },
//     {
//       "ID": 5539,
//       "Airport Code [ICAO]": "CZEM",
//       "Airport Code iATA,FAA": "ZEM",
//       "Airport Name": "Eastmain River Airport",
//       "Altitude (Ft)": 24,
//       "Country": "Canada",
//       "Latitude": -78.52249908447266,
//       "Location": "Eastmain River",
//       "Longitude": 52.22639846801758,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5540,
//       "Airport Code [ICAO]": "CZFD",
//       "Airport Code iATA,FAA": "ZFD",
//       "Airport Name": "Fond-Du-Lac Airport",
//       "Altitude (Ft)": 814,
//       "Country": "Canada",
//       "Latitude": -107.18199920654297,
//       "Location": "Fond-Du-Lac",
//       "Longitude": 59.33440017700195,
//       "GMT Offset": "-6",
//       "Daylight Savings": "N",
//       "Region": "America/Regina",
//       "Type": "airport"
//     },
//     {
//       "ID": 5541,
//       "Airport Code [ICAO]": "CZGI",
//       "Airport Code iATA,FAA": "ZGI",
//       "Airport Name": "Gods River Airport",
//       "Altitude (Ft)": 627,
//       "Country": "Canada",
//       "Latitude": -94.07859802246094,
//       "Location": "Gods River",
//       "Longitude": 54.839698791503906,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5542,
//       "Airport Code [ICAO]": "CZJN",
//       "Airport Code iATA,FAA": "ZJN",
//       "Airport Name": "Swan River Airport",
//       "Altitude (Ft)": 1100,
//       "Country": "Canada",
//       "Latitude": -101.23600006103516,
//       "Location": "Swan River",
//       "Longitude": 52.120601654052734,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5543,
//       "Airport Code [ICAO]": "CZKE",
//       "Airport Code iATA,FAA": "ZKE",
//       "Airport Name": "Kashechewan Airport",
//       "Altitude (Ft)": 35,
//       "Country": "Canada",
//       "Latitude": -81.67780303955078,
//       "Location": "Kashechewan",
//       "Longitude": 52.282501220703125,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 5544,
//       "Airport Code [ICAO]": "CZMD",
//       "Airport Code iATA,FAA": "MSA",
//       "Airport Name": "Muskrat Dam Airport",
//       "Altitude (Ft)": 911,
//       "Country": "Canada",
//       "Latitude": -91.76280212402344,
//       "Location": "Muskrat Dam",
//       "Longitude": 53.44139862060547,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5545,
//       "Airport Code [ICAO]": "CZMT",
//       "Airport Code iATA,FAA": "ZMT",
//       "Airport Name": "Masset Airport",
//       "Altitude (Ft)": 25,
//       "Country": "Canada",
//       "Latitude": -132.125,
//       "Location": "Masset",
//       "Longitude": 54.02750015258789,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 5546,
//       "Airport Code [ICAO]": "CZPB",
//       "Airport Code iATA,FAA": "ZPB",
//       "Airport Name": "Sachigo Lake Airport",
//       "Altitude (Ft)": 876,
//       "Country": "Canada",
//       "Latitude": -92.19640350341797,
//       "Location": "Sachigo Lake",
//       "Longitude": 53.8911018371582,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5547,
//       "Airport Code [ICAO]": "CZRJ",
//       "Airport Code iATA,FAA": "ZRJ",
//       "Airport Name": "Round Lake (Weagamow Lake) Airport",
//       "Altitude (Ft)": 974,
//       "Country": "Canada",
//       "Latitude": -91.31279754638672,
//       "Location": "Round Lake",
//       "Longitude": 52.943599700927734,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5548,
//       "Airport Code [ICAO]": "CZSJ",
//       "Airport Code iATA,FAA": "ZSJ",
//       "Airport Name": "Sandy Lake Airport",
//       "Altitude (Ft)": 951,
//       "Country": "Canada",
//       "Latitude": -93.34439849853516,
//       "Location": "Sandy Lake",
//       "Longitude": 53.06420135498047,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5549,
//       "Airport Code [ICAO]": "CZTM",
//       "Airport Code iATA,FAA": "ZTM",
//       "Airport Name": "Shamattawa Airport",
//       "Altitude (Ft)": 289,
//       "Country": "Canada",
//       "Latitude": -92.0813980102539,
//       "Location": "Shamattawa",
//       "Longitude": 55.8656005859375,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5550,
//       "Airport Code [ICAO]": "CZUM",
//       "Airport Code iATA,FAA": "ZUM",
//       "Airport Name": "Churchill Falls Airport",
//       "Altitude (Ft)": 1442,
//       "Country": "Canada",
//       "Latitude": -64.10639953613281,
//       "Location": "Churchill Falls",
//       "Longitude": 53.5619010925293,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Halifax",
//       "Type": "airport"
//     },
//     {
//       "ID": 5551,
//       "Airport Code [ICAO]": "CZWL",
//       "Airport Code iATA,FAA": "ZWL",
//       "Airport Name": "Wollaston Lake Airport",
//       "Altitude (Ft)": 1360,
//       "Country": "Canada",
//       "Latitude": -103.1719970703125,
//       "Location": "Wollaston Lake",
//       "Longitude": 58.10689926147461,
//       "GMT Offset": "-6",
//       "Daylight Savings": "N",
//       "Region": "America/Regina",
//       "Type": "airport"
//     },
//     {
//       "ID": 5552,
//       "Airport Code [ICAO]": "DABT",
//       "Airport Code iATA,FAA": "BLJ",
//       "Airport Name": "Batna Airport",
//       "Altitude (Ft)": 2697,
//       "Country": "Algeria",
//       "Latitude": 6.308589935300001,
//       "Location": "Batna",
//       "Longitude": 35.752101898199996,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 5553,
//       "Airport Code [ICAO]": "DAOR",
//       "Airport Code iATA,FAA": "CBH",
//       "Airport Name": "Béchar Boudghene Ben Ali Lotfi Airport",
//       "Altitude (Ft)": 2661,
//       "Country": "Algeria",
//       "Latitude": -2.269860029220581,
//       "Location": "Béchar",
//       "Longitude": 31.645700454711914,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 5554,
//       "Airport Code [ICAO]": "DATM",
//       "Airport Code iATA,FAA": "BMW",
//       "Airport Name": "Bordj Badji Mokhtar Airport",
//       "Altitude (Ft)": 1303,
//       "Country": "Algeria",
//       "Latitude": 0.923888981342,
//       "Location": "Bordj Badji Mokhtar",
//       "Longitude": 21.375,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 5555,
//       "Airport Code [ICAO]": "DAUO",
//       "Airport Code iATA,FAA": "ELU",
//       "Airport Name": "Guemar Airport",
//       "Altitude (Ft)": 203,
//       "Country": "Algeria",
//       "Latitude": 6.77679014206,
//       "Location": "Guemar",
//       "Longitude": 33.5113983154,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 5556,
//       "Airport Code [ICAO]": "DGSI",
//       "Airport Code iATA,FAA": "KMS",
//       "Airport Name": "Kumasi Airport",
//       "Altitude (Ft)": 942,
//       "Country": "Ghana",
//       "Latitude": -1.5908199548721313,
//       "Location": "Kumasi",
//       "Longitude": 6.714560031890869,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Accra",
//       "Type": "airport"
//     },
//     {
//       "ID": 5557,
//       "Airport Code [ICAO]": "EDAH",
//       "Airport Code iATA,FAA": "HDF",
//       "Airport Name": "Heringsdorf Airport",
//       "Altitude (Ft)": 93,
//       "Country": "Germany",
//       "Latitude": 14.152299881,
//       "Location": "Heringsdorf",
//       "Longitude": 53.8787002563,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 5558,
//       "Airport Code [ICAO]": "EDXB",
//       "Airport Code iATA,FAA": "HEI",
//       "Airport Name": "Heide-Büsum Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Germany",
//       "Latitude": 8.90166664124,
//       "Location": "Büsum",
//       "Longitude": 54.153331756600004,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 5559,
//       "Airport Code [ICAO]": "EDXH",
//       "Airport Code iATA,FAA": "HGL",
//       "Airport Name": "Helgoland-Düne Airport",
//       "Altitude (Ft)": 8,
//       "Country": "Germany",
//       "Latitude": 7.91583299637,
//       "Location": "Helgoland",
//       "Longitude": 54.185279846200004,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 5560,
//       "Airport Code [ICAO]": "EFSI",
//       "Airport Code iATA,FAA": "SJY",
//       "Airport Name": "Seinäjoki Airport",
//       "Altitude (Ft)": 302,
//       "Country": "Finland",
//       "Latitude": 22.8323,
//       "Location": "Seinäjoki / Ilmajoki",
//       "Longitude": 62.692101,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 5561,
//       "Airport Code [ICAO]": "EGBN",
//       "Airport Code iATA,FAA": "NQT",
//       "Airport Name": "Nottingham Airport",
//       "Altitude (Ft)": 138,
//       "Country": "United Kingdom",
//       "Latitude": -1.0791699886322021,
//       "Location": "Nottingham",
//       "Longitude": 52.91999816894531,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 5562,
//       "Airport Code [ICAO]": "EGCN",
//       "Airport Code iATA,FAA": "DSA",
//       "Airport Name": "Robin Hood Doncaster Sheffield Airport",
//       "Altitude (Ft)": 55,
//       "Country": "United Kingdom",
//       "Latitude": -1.01065635681,
//       "Location": "Doncaster, Sheffield",
//       "Longitude": 53.4805378105,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 5563,
//       "Airport Code [ICAO]": "EGEC",
//       "Airport Code iATA,FAA": "CAL",
//       "Airport Name": "Campbeltown Airport",
//       "Altitude (Ft)": 42,
//       "Country": "United Kingdom",
//       "Latitude": -5.686389923095703,
//       "Location": "Campbeltown",
//       "Longitude": 55.437198638916016,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 5564,
//       "Airport Code [ICAO]": "EGED",
//       "Airport Code iATA,FAA": "EOI",
//       "Airport Name": "Eday Airport",
//       "Altitude (Ft)": 10,
//       "Country": "United Kingdom",
//       "Latitude": -2.7722198963165283,
//       "Location": "Eday",
//       "Longitude": 59.19060134887695,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 5565,
//       "Airport Code [ICAO]": "EGEF",
//       "Airport Code iATA,FAA": "FIE",
//       "Airport Name": "Fair Isle Airport",
//       "Altitude (Ft)": 223,
//       "Country": "United Kingdom",
//       "Latitude": -1.628059983253479,
//       "Location": "Fair Isle",
//       "Longitude": 59.53580093383789,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 5566,
//       "Airport Code [ICAO]": "EGEN",
//       "Airport Code iATA,FAA": "NRL",
//       "Airport Name": "North Ronaldsay Airport",
//       "Altitude (Ft)": 40,
//       "Country": "United Kingdom",
//       "Latitude": -2.43443989754,
//       "Location": "North Ronaldsay",
//       "Longitude": 59.3675003052,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 5567,
//       "Airport Code [ICAO]": "EGEP",
//       "Airport Code iATA,FAA": "PPW",
//       "Airport Name": "Papa Westray Airport",
//       "Altitude (Ft)": 91,
//       "Country": "United Kingdom",
//       "Latitude": -2.9002799987800003,
//       "Location": "Papa Westray",
//       "Longitude": 59.351699829100006,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 5568,
//       "Airport Code [ICAO]": "EGER",
//       "Airport Code iATA,FAA": "SOY",
//       "Airport Name": "Stronsay Airport",
//       "Altitude (Ft)": 39,
//       "Country": "United Kingdom",
//       "Latitude": -2.64139008522,
//       "Location": "Stronsay",
//       "Longitude": 59.1553001404,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 5569,
//       "Airport Code [ICAO]": "EGES",
//       "Airport Code iATA,FAA": "NDY",
//       "Airport Name": "Sanday Airport",
//       "Altitude (Ft)": 68,
//       "Country": "United Kingdom",
//       "Latitude": -2.576669931411743,
//       "Location": "Sanday",
//       "Longitude": 59.250301361083984,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 5570,
//       "Airport Code [ICAO]": "EGET",
//       "Airport Code iATA,FAA": "LWK",
//       "Airport Name": "Lerwick / Tingwall Airport",
//       "Altitude (Ft)": 43,
//       "Country": "United Kingdom",
//       "Latitude": -1.24361002445,
//       "Location": "Lerwick",
//       "Longitude": 60.192199707,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 5571,
//       "Airport Code [ICAO]": "EGEW",
//       "Airport Code iATA,FAA": "WRY",
//       "Airport Name": "Westray Airport",
//       "Altitude (Ft)": 29,
//       "Country": "United Kingdom",
//       "Latitude": -2.95000004768,
//       "Location": "Westray",
//       "Longitude": 59.3502998352,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 5572,
//       "Airport Code [ICAO]": "EGHC",
//       "Airport Code iATA,FAA": "LEQ",
//       "Airport Name": "Land's End Airport",
//       "Altitude (Ft)": 398,
//       "Country": "United Kingdom",
//       "Latitude": -5.67056,
//       "Location": "Land's End",
//       "Longitude": 50.102798,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 5573,
//       "Airport Code [ICAO]": "EGHK",
//       "Airport Code iATA,FAA": "PZE",
//       "Airport Name": "Penzance Heliport",
//       "Altitude (Ft)": 14,
//       "Country": "United Kingdom",
//       "Latitude": -5.51845,
//       "Location": "Penzance",
//       "Longitude": 50.128101,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 5574,
//       "Airport Code [ICAO]": "EGOV",
//       "Airport Code iATA,FAA": "VLY",
//       "Airport Name": "Anglesey Airport",
//       "Altitude (Ft)": 37,
//       "Country": "United Kingdom",
//       "Latitude": -4.53533983231,
//       "Location": "Angelsey",
//       "Longitude": 53.2481002808,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 5575,
//       "Airport Code [ICAO]": "EGPR",
//       "Airport Code iATA,FAA": "BRR",
//       "Airport Name": "Barra Airport",
//       "Altitude (Ft)": 5,
//       "Country": "United Kingdom",
//       "Latitude": -7.44306,
//       "Location": "Barra",
//       "Longitude": 57.0228,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 5577,
//       "Airport Code [ICAO]": "EIDL",
//       "Airport Code iATA,FAA": "CFN",
//       "Airport Name": "Donegal Airport",
//       "Altitude (Ft)": 30,
//       "Country": "Ireland",
//       "Latitude": -8.340999603271484,
//       "Location": "Dongloe",
//       "Longitude": 55.0442008972168,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Dublin",
//       "Type": "airport"
//     },
//     {
//       "ID": 5578,
//       "Airport Code [ICAO]": "EIWT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Weston Airport",
//       "Altitude (Ft)": 150,
//       "Country": "Ireland",
//       "Latitude": -6.48611021042,
//       "Location": "Leixlip",
//       "Longitude": 53.3521995544,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Dublin",
//       "Type": "airport"
//     },
//     {
//       "ID": 5579,
//       "Airport Code [ICAO]": "EKSN",
//       "Airport Code iATA,FAA": "CNL",
//       "Airport Name": "Sindal Airport",
//       "Altitude (Ft)": 92,
//       "Country": "Denmark",
//       "Latitude": 10.2294,
//       "Location": "Sindal",
//       "Longitude": 57.503502,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Europe/Copenhagen",
//       "Type": "airport"
//     },
//     {
//       "ID": 5580,
//       "Airport Code [ICAO]": "ENLK",
//       "Airport Code iATA,FAA": "LKN",
//       "Airport Name": "Leknes Airport",
//       "Altitude (Ft)": 78,
//       "Country": "Norway",
//       "Latitude": 13.609399795532,
//       "Location": "Leknes",
//       "Longitude": 68.152496337891,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5581,
//       "Airport Code [ICAO]": "ENNM",
//       "Airport Code iATA,FAA": "OSY",
//       "Airport Name": "Namsos Høknesøra Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Norway",
//       "Latitude": 11.57859992981,
//       "Location": "Namsos",
//       "Longitude": 64.472198486328,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5582,
//       "Airport Code [ICAO]": "ENRA",
//       "Airport Code iATA,FAA": "MQN",
//       "Airport Name": "Mo i Rana Airport, Røssvoll",
//       "Altitude (Ft)": 229,
//       "Country": "Norway",
//       "Latitude": 14.301400184631,
//       "Location": "Mo i Rana",
//       "Longitude": 66.363899230957,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5583,
//       "Airport Code [ICAO]": "ENRM",
//       "Airport Code iATA,FAA": "RVK",
//       "Airport Name": "Rørvik Airport, Ryum",
//       "Altitude (Ft)": 14,
//       "Country": "Norway",
//       "Latitude": 11.14610004425,
//       "Location": "Rørvik",
//       "Longitude": 64.838302612305,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5584,
//       "Airport Code [ICAO]": "ENRS",
//       "Airport Code iATA,FAA": "RET",
//       "Airport Name": "Røst Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Norway",
//       "Latitude": 12.103300094604,
//       "Location": "Røst",
//       "Longitude": 67.527801513672,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5585,
//       "Airport Code [ICAO]": "ENSD",
//       "Airport Code iATA,FAA": "SDN",
//       "Airport Name": "Sandane Airport (Anda)",
//       "Altitude (Ft)": 196,
//       "Country": "Norway",
//       "Latitude": 6.1058301925659,
//       "Location": "Sandane",
//       "Longitude": 61.830001831055,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5586,
//       "Airport Code [ICAO]": "ENSG",
//       "Airport Code iATA,FAA": "SOG",
//       "Airport Name": "Sogndal Airport",
//       "Altitude (Ft)": 1633,
//       "Country": "Norway",
//       "Latitude": 7.13778,
//       "Location": "Sogndal",
//       "Longitude": 61.156101,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5587,
//       "Airport Code [ICAO]": "ENSH",
//       "Airport Code iATA,FAA": "SVJ",
//       "Airport Name": "Svolvær Helle Airport",
//       "Altitude (Ft)": 27,
//       "Country": "Norway",
//       "Latitude": 14.669199943542,
//       "Location": "Svolvær",
//       "Longitude": 68.243301391602,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5588,
//       "Airport Code [ICAO]": "ENSR",
//       "Airport Code iATA,FAA": "SOJ",
//       "Airport Name": "Sørkjosen Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Norway",
//       "Latitude": 20.959400177002,
//       "Location": "Sorkjosen",
//       "Longitude": 69.786796569824,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5589,
//       "Airport Code [ICAO]": "ENSS",
//       "Airport Code iATA,FAA": "VAW",
//       "Airport Name": "Vardø Airport, Svartnes",
//       "Altitude (Ft)": 42,
//       "Country": "Norway",
//       "Latitude": 31.044900894165,
//       "Location": "Vardø",
//       "Longitude": 70.355400085449,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5590,
//       "Airport Code [ICAO]": "ENVR",
//       "Airport Code iATA,FAA": "VRY",
//       "Airport Name": "Værøy Heliport",
//       "Altitude (Ft)": 12,
//       "Country": "Norway",
//       "Latitude": 12.727257,
//       "Location": "Værøy",
//       "Longitude": 67.654555,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5591,
//       "Airport Code [ICAO]": "EPBY",
//       "Airport Code iATA,FAA": "BZG",
//       "Airport Name": "Bydgoszcz Ignacy Jan Paderewski Airport",
//       "Altitude (Ft)": 235,
//       "Country": "Poland",
//       "Latitude": 17.9776992798,
//       "Location": "Bydgoszcz",
//       "Longitude": 53.096801757799994,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 5592,
//       "Airport Code [ICAO]": "EPLL",
//       "Airport Code iATA,FAA": "LCJ",
//       "Airport Name": "Łódź Władysław Reymont Airport",
//       "Altitude (Ft)": 604,
//       "Country": "Poland",
//       "Latitude": 19.3980998993,
//       "Location": "Lodz",
//       "Longitude": 51.721900939899996,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 5593,
//       "Airport Code [ICAO]": "ESNZ",
//       "Airport Code iATA,FAA": "OSD",
//       "Airport Name": "Åre Östersund Airport",
//       "Altitude (Ft)": 1233,
//       "Country": "Sweden",
//       "Latitude": 14.50030040741,
//       "Location": "Östersund",
//       "Longitude": 63.194400787354,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 5594,
//       "Airport Code [ICAO]": "ESOH",
//       "Airport Code iATA,FAA": "HFS",
//       "Airport Name": "Hagfors Airport",
//       "Altitude (Ft)": 474,
//       "Country": "Sweden",
//       "Latitude": 13.578900337219238,
//       "Location": "Hagfors",
//       "Longitude": 60.02009963989258,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 5595,
//       "Airport Code [ICAO]": "ESOK",
//       "Airport Code iATA,FAA": "KSD",
//       "Airport Name": "Karlstad Airport",
//       "Altitude (Ft)": 352,
//       "Country": "Sweden",
//       "Latitude": 13.337400436400001,
//       "Location": "Karlstad",
//       "Longitude": 59.444698333699996,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 5596,
//       "Airport Code [ICAO]": "ESST",
//       "Airport Code iATA,FAA": "TYF",
//       "Airport Name": "Torsby Airport",
//       "Altitude (Ft)": 393,
//       "Country": "Sweden",
//       "Latitude": 12.991299629199998,
//       "Location": "Torsby",
//       "Longitude": 60.1576004028,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 5597,
//       "Airport Code [ICAO]": "ESTA",
//       "Airport Code iATA,FAA": "AGH",
//       "Airport Name": "Ängelholm-Helsingborg Airport",
//       "Altitude (Ft)": 68,
//       "Country": "Sweden",
//       "Latitude": 12.847100257873535,
//       "Location": "Ängelholm",
//       "Longitude": 56.29610061645508,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 5598,
//       "Airport Code [ICAO]": "ESUD",
//       "Airport Code iATA,FAA": "SQO",
//       "Airport Name": "Storuman Airport",
//       "Altitude (Ft)": 915,
//       "Country": "Sweden",
//       "Latitude": 17.69659996032715,
//       "Location": "Mohed",
//       "Longitude": 64.96089935302734,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 5599,
//       "Airport Code [ICAO]": "ESUT",
//       "Airport Code iATA,FAA": "HMV",
//       "Airport Name": "Hemavan Airport",
//       "Altitude (Ft)": 1503,
//       "Country": "Sweden",
//       "Latitude": 15.082799911499023,
//       "Location": "Hemavan",
//       "Longitude": 65.80609893798828,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 5602,
//       "Airport Code [ICAO]": "EVVA",
//       "Airport Code iATA,FAA": "VNT",
//       "Airport Name": "Ventspils International Airport",
//       "Altitude (Ft)": 19,
//       "Country": "Latvia",
//       "Latitude": 21.5442008972,
//       "Location": "Ventspils",
//       "Longitude": 57.35779953,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Riga",
//       "Type": "airport"
//     },
//     {
//       "ID": 5603,
//       "Airport Code [ICAO]": "FAGM",
//       "Airport Code iATA,FAA": "QRA",
//       "Airport Name": "Rand Airport",
//       "Altitude (Ft)": 5483,
//       "Country": "South Africa",
//       "Latitude": 28.1511993408,
//       "Location": "Johannesburg",
//       "Longitude": -26.2425003052,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5604,
//       "Airport Code [ICAO]": "FAKN",
//       "Airport Code iATA,FAA": "MQP",
//       "Airport Name": "Kruger Mpumalanga International Airport",
//       "Altitude (Ft)": 2829,
//       "Country": "South Africa",
//       "Latitude": 31.1056003571,
//       "Location": "Mpumalanga",
//       "Longitude": -25.3831996918,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5605,
//       "Airport Code [ICAO]": "FAMD",
//       "Airport Code iATA,FAA": "AAM",
//       "Airport Name": "Malamala Airport",
//       "Altitude (Ft)": 1124,
//       "Country": "South Africa",
//       "Latitude": 31.544599533081055,
//       "Location": "Malamala",
//       "Longitude": -24.818099975585938,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5606,
//       "Airport Code [ICAO]": "FAMM",
//       "Airport Code iATA,FAA": "MBD",
//       "Airport Name": "Mmabatho International Airport",
//       "Altitude (Ft)": 4181,
//       "Country": "South Africa",
//       "Latitude": 25.548000335699996,
//       "Location": "Mafeking",
//       "Longitude": -25.798400878900004,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 5607,
//       "Airport Code [ICAO]": "FBGZ",
//       "Airport Code iATA,FAA": "GNZ",
//       "Airport Name": "Ghanzi Airport",
//       "Altitude (Ft)": 3730,
//       "Country": "Botswana",
//       "Latitude": 21.658100128173828,
//       "Location": "Ghanzi",
//       "Longitude": -21.6924991607666,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Gaborone",
//       "Type": "airport"
//     },
//     {
//       "ID": 5608,
//       "Airport Code [ICAO]": "FBOR",
//       "Airport Code iATA,FAA": "ORP",
//       "Airport Name": "Orapa Airport",
//       "Altitude (Ft)": 3100,
//       "Country": "Botswana",
//       "Latitude": 25.3167,
//       "Location": "Orapa",
//       "Longitude": -21.266701,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Gaborone",
//       "Type": "airport"
//     },
//     {
//       "ID": 5609,
//       "Airport Code [ICAO]": "FBSW",
//       "Airport Code iATA,FAA": "SWX",
//       "Airport Name": "Shakawe Airport",
//       "Altitude (Ft)": 3379,
//       "Country": "Botswana",
//       "Latitude": 21.832599639892578,
//       "Location": "Shakawe",
//       "Longitude": -18.373899459838867,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Gaborone",
//       "Type": "airport"
//     },
//     {
//       "ID": 5610,
//       "Airport Code [ICAO]": "FBTL",
//       "Airport Code iATA,FAA": "TLD",
//       "Airport Name": "Limpopo Valley Airport",
//       "Altitude (Ft)": 1772,
//       "Country": "Botswana",
//       "Latitude": 29.126899719199997,
//       "Location": "Tuli Lodge",
//       "Longitude": -22.189199447599997,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Gaborone",
//       "Type": "airport"
//     },
//     {
//       "ID": 5611,
//       "Airport Code [ICAO]": "FCPL",
//       "Airport Code iATA,FAA": "DIS",
//       "Airport Name": "Ngot Nzoungou Airport",
//       "Altitude (Ft)": 1079,
//       "Country": "Congo (Brazzaville)",
//       "Latitude": 12.6599,
//       "Location": "Loubomo",
//       "Longitude": -4.20635,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Brazzaville",
//       "Type": "airport"
//     },
//     {
//       "ID": 5612,
//       "Airport Code [ICAO]": "FLCP",
//       "Airport Code iATA,FAA": "CIP",
//       "Airport Name": "Chipata Airport",
//       "Altitude (Ft)": 3360,
//       "Country": "Zambia",
//       "Latitude": 32.58720016479492,
//       "Location": "Chipata",
//       "Longitude": -13.558300018310547,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Lusaka",
//       "Type": "airport"
//     },
//     {
//       "ID": 5613,
//       "Airport Code [ICAO]": "KSLI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Los Alamitos Army Air Field",
//       "Altitude (Ft)": 32,
//       "Country": "Zambia",
//       "Latitude": -118.052002,
//       "Location": "Solwesi",
//       "Longitude": 33.79000092,
//       "GMT Offset": "-8",
//       "Daylight Savings": "U",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 5614,
//       "Airport Code [ICAO]": "FMCN",
//       "Airport Code iATA,FAA": "YVA",
//       "Airport Name": "Iconi Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Comoros",
//       "Latitude": 43.2439002991,
//       "Location": "Moroni",
//       "Longitude": -11.710800170899999,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Comoro",
//       "Type": "airport"
//     },
//     {
//       "ID": 5615,
//       "Airport Code [ICAO]": "FMMG",
//       "Airport Code iATA,FAA": "WAQ",
//       "Airport Name": "Antsalova Airport",
//       "Altitude (Ft)": 551,
//       "Country": "Madagascar",
//       "Latitude": 44.614921,
//       "Location": "Antsalova",
//       "Longitude": -18.701273,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5616,
//       "Airport Code [ICAO]": "FMMK",
//       "Airport Code iATA,FAA": "JVA",
//       "Airport Name": "Ankavandra Airport",
//       "Altitude (Ft)": 427,
//       "Country": "Madagascar",
//       "Latitude": 45.273467,
//       "Location": "Ankavandra",
//       "Longitude": -18.80501,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5617,
//       "Airport Code [ICAO]": "FMML",
//       "Airport Code iATA,FAA": "BMD",
//       "Airport Name": "Belo sur Tsiribihina Airport",
//       "Altitude (Ft)": 154,
//       "Country": "Madagascar",
//       "Latitude": 44.541900634799994,
//       "Location": "Belo sur Tsiribihina",
//       "Longitude": -19.6867008209,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5618,
//       "Airport Code [ICAO]": "FMMO",
//       "Airport Code iATA,FAA": "MXT",
//       "Airport Name": "Maintirano Airport",
//       "Altitude (Ft)": 95,
//       "Country": "Madagascar",
//       "Latitude": 44.033001,
//       "Location": "Maintirano",
//       "Longitude": -18.049999,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5619,
//       "Airport Code [ICAO]": "FMMR",
//       "Airport Code iATA,FAA": "TVA",
//       "Airport Name": "Morafenobe Airport",
//       "Altitude (Ft)": 748,
//       "Country": "Madagascar",
//       "Latitude": 44.920467,
//       "Location": "Morafenobe",
//       "Longitude": -17.850083,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5620,
//       "Airport Code [ICAO]": "FMMU",
//       "Airport Code iATA,FAA": "WTA",
//       "Airport Name": "Tambohorano Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Madagascar",
//       "Latitude": 43.972801208496094,
//       "Location": "Tambohorano",
//       "Longitude": -17.47610092163086,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5621,
//       "Airport Code [ICAO]": "FMMX",
//       "Airport Code iATA,FAA": "WTS",
//       "Airport Name": "Tsiroanomandidy Airport",
//       "Altitude (Ft)": 2776,
//       "Country": "Madagascar",
//       "Latitude": 46.054065,
//       "Location": "Tsiroanomandidy",
//       "Longitude": -18.759677,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5622,
//       "Airport Code [ICAO]": "FMMZ",
//       "Airport Code iATA,FAA": "WAM",
//       "Airport Name": "Ambatondrazaka Airport",
//       "Altitude (Ft)": 2513,
//       "Country": "Madagascar",
//       "Latitude": 48.442583,
//       "Location": "Ambatondrazaka",
//       "Longitude": -17.795378,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5623,
//       "Airport Code [ICAO]": "FMNG",
//       "Airport Code iATA,FAA": "WPB",
//       "Airport Name": "Port Bergé Airport",
//       "Altitude (Ft)": 213,
//       "Country": "Madagascar",
//       "Latitude": 47.623587,
//       "Location": "Port Bergé",
//       "Longitude": -15.584286,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5624,
//       "Airport Code [ICAO]": "FMNJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ambanja Airport",
//       "Altitude (Ft)": 36,
//       "Country": "Madagascar",
//       "Latitude": 48.459427,
//       "Location": "Ambanja",
//       "Longitude": -13.645705,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5625,
//       "Airport Code [ICAO]": "FMNO",
//       "Airport Code iATA,FAA": "DWB",
//       "Airport Name": "Soalala Airport",
//       "Altitude (Ft)": 141,
//       "Country": "Madagascar",
//       "Latitude": 45.358837,
//       "Location": "Soalala",
//       "Longitude": -16.10169,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5626,
//       "Airport Code [ICAO]": "FMNP",
//       "Airport Code iATA,FAA": "WMP",
//       "Airport Name": "Mampikony Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Madagascar",
//       "Latitude": 47.644164562200004,
//       "Location": "Mampikony",
//       "Longitude": -16.0722693402,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5627,
//       "Airport Code [ICAO]": "KTTS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nasa Shuttle Landing Facility Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Madagascar",
//       "Latitude": -80.69450378417969,
//       "Location": "Tsaratanana",
//       "Longitude": 28.614999771118164,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 5628,
//       "Airport Code [ICAO]": "FMNX",
//       "Airport Code iATA,FAA": "WMA",
//       "Airport Name": "Mandritsara Airport",
//       "Altitude (Ft)": 1007,
//       "Country": "Madagascar",
//       "Latitude": 48.833284,
//       "Location": "Mandritsara",
//       "Longitude": -15.833049,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5629,
//       "Airport Code [ICAO]": "FMSJ",
//       "Airport Code iATA,FAA": "MJA",
//       "Airport Name": "Manja Airport",
//       "Altitude (Ft)": 787,
//       "Country": "Madagascar",
//       "Latitude": 44.316509,
//       "Location": "Manja",
//       "Longitude": -21.426105,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5630,
//       "Airport Code [ICAO]": "FNCT",
//       "Airport Code iATA,FAA": "CBT",
//       "Airport Name": "Catumbela Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Angola",
//       "Latitude": 13.4869,
//       "Location": "Catumbela",
//       "Longitude": -12.4792,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Luanda",
//       "Type": "airport"
//     },
//     {
//       "ID": 5631,
//       "Airport Code [ICAO]": "FNDU",
//       "Airport Code iATA,FAA": "DUE",
//       "Airport Name": "Dundo Airport",
//       "Altitude (Ft)": 2451,
//       "Country": "Angola",
//       "Latitude": 20.818500518798828,
//       "Location": "Dundo",
//       "Longitude": -7.400889873504639,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Luanda",
//       "Type": "airport"
//     },
//     {
//       "ID": 5632,
//       "Airport Code [ICAO]": "FNGI",
//       "Airport Code iATA,FAA": "VPE",
//       "Airport Name": "Ngjiva Pereira Airport",
//       "Altitude (Ft)": 3566,
//       "Country": "Angola",
//       "Latitude": 15.683799743700002,
//       "Location": "Ondjiva",
//       "Longitude": -17.0435009003,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Luanda",
//       "Type": "airport"
//     },
//     {
//       "ID": 5633,
//       "Airport Code [ICAO]": "FNMO",
//       "Airport Code iATA,FAA": "MSZ",
//       "Airport Name": "Namibe Airport",
//       "Altitude (Ft)": 210,
//       "Country": "Angola",
//       "Latitude": 12.14680004119873,
//       "Location": "Mocamedes",
//       "Longitude": -15.261199951171875,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Luanda",
//       "Type": "airport"
//     },
//     {
//       "ID": 5634,
//       "Airport Code [ICAO]": "FOGK",
//       "Airport Code iATA,FAA": "KOU",
//       "Airport Name": "Koulamoutou Mabimbi Airport",
//       "Altitude (Ft)": 1070,
//       "Country": "Gabon",
//       "Latitude": 12.441300392151,
//       "Location": "Koulamoutou",
//       "Longitude": -1.1846100091934,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Libreville",
//       "Type": "airport"
//     },
//     {
//       "ID": 5635,
//       "Airport Code [ICAO]": "FOGM",
//       "Airport Code iATA,FAA": "MJL",
//       "Airport Name": "Mouilla Ville Airport",
//       "Altitude (Ft)": 295,
//       "Country": "Gabon",
//       "Latitude": 11.056699752807617,
//       "Location": "Mouila",
//       "Longitude": -1.845139980316162,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Libreville",
//       "Type": "airport"
//     },
//     {
//       "ID": 5636,
//       "Airport Code [ICAO]": "FOOT",
//       "Airport Code iATA,FAA": "TCH",
//       "Airport Name": "Tchibanga Airport",
//       "Altitude (Ft)": 269,
//       "Country": "Gabon",
//       "Latitude": 11.017000198364258,
//       "Location": "Tchibanga",
//       "Longitude": -2.8499999046325684,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Libreville",
//       "Type": "airport"
//     },
//     {
//       "ID": 5637,
//       "Airport Code [ICAO]": "FQCH",
//       "Airport Code iATA,FAA": "VPY",
//       "Airport Name": "Chimoio Airport",
//       "Altitude (Ft)": 2287,
//       "Country": "Mozambique",
//       "Latitude": 33.42900085449219,
//       "Location": "Chimoio",
//       "Longitude": -19.15130043029785,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Maputo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5639,
//       "Airport Code [ICAO]": "FTTA",
//       "Airport Code iATA,FAA": "SRH",
//       "Airport Name": "Sarh Airport",
//       "Altitude (Ft)": 1198,
//       "Country": "Chad",
//       "Latitude": 18.374399,
//       "Location": "Sarh",
//       "Longitude": 9.14444,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Ndjamena",
//       "Type": "airport"
//     },
//     {
//       "ID": 5640,
//       "Airport Code [ICAO]": "FWCM",
//       "Airport Code iATA,FAA": "CMK",
//       "Airport Name": "Club Makokola Airport",
//       "Altitude (Ft)": 1587,
//       "Country": "Malawi",
//       "Latitude": 35.13249969482422,
//       "Location": "Club Makokola",
//       "Longitude": -14.306900024414062,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Blantyre",
//       "Type": "airport"
//     },
//     {
//       "ID": 5641,
//       "Airport Code [ICAO]": "FYLZ",
//       "Airport Code iATA,FAA": "LUD",
//       "Airport Name": "Luderitz Airport",
//       "Altitude (Ft)": 457,
//       "Country": "Namibia",
//       "Latitude": 15.242899894714355,
//       "Location": "Luderitz",
//       "Longitude": -26.687400817871094,
//       "GMT Offset": "1",
//       "Daylight Savings": "S",
//       "Region": "Africa/Windhoek",
//       "Type": "airport"
//     },
//     {
//       "ID": 5642,
//       "Airport Code [ICAO]": "FYOA",
//       "Airport Code iATA,FAA": "OND",
//       "Airport Name": "Ondangwa Airport",
//       "Altitude (Ft)": 3599,
//       "Country": "Namibia",
//       "Latitude": 15.9526,
//       "Location": "Ondangwa",
//       "Longitude": -17.878201,
//       "GMT Offset": "1",
//       "Daylight Savings": "S",
//       "Region": "Africa/Windhoek",
//       "Type": "airport"
//     },
//     {
//       "ID": 5643,
//       "Airport Code [ICAO]": "FYOG",
//       "Airport Code iATA,FAA": "OMD",
//       "Airport Name": "Oranjemund Airport",
//       "Altitude (Ft)": 14,
//       "Country": "Namibia",
//       "Latitude": 16.446699,
//       "Location": "Oranjemund",
//       "Longitude": -28.5847,
//       "GMT Offset": "1",
//       "Daylight Savings": "S",
//       "Region": "Africa/Windhoek",
//       "Type": "airport"
//     },
//     {
//       "ID": 5644,
//       "Airport Code [ICAO]": "FYSM",
//       "Airport Code iATA,FAA": "SWP",
//       "Airport Name": "Swakopmund Airport",
//       "Altitude (Ft)": 207,
//       "Country": "Namibia",
//       "Latitude": 14.568099975586,
//       "Location": "Swakopmund",
//       "Longitude": -22.66189956665,
//       "GMT Offset": "1",
//       "Daylight Savings": "S",
//       "Region": "Africa/Windhoek",
//       "Type": "airport"
//     },
//     {
//       "ID": 5645,
//       "Airport Code [ICAO]": "FYWE",
//       "Airport Code iATA,FAA": "ERS",
//       "Airport Name": "Eros Airport",
//       "Altitude (Ft)": 5575,
//       "Country": "Namibia",
//       "Latitude": 17.080400466918945,
//       "Location": "Windhoek",
//       "Longitude": -22.612199783325195,
//       "GMT Offset": "1",
//       "Daylight Savings": "S",
//       "Region": "Africa/Windhoek",
//       "Type": "airport"
//     },
//     {
//       "ID": 5646,
//       "Airport Code [ICAO]": "FZAJ",
//       "Airport Code iATA,FAA": "BOA",
//       "Airport Name": "Boma Airport",
//       "Altitude (Ft)": 26,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 13.064000129699707,
//       "Location": "Boma",
//       "Longitude": -5.854000091552734,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Kinshasa",
//       "Type": "airport"
//     },
//     {
//       "ID": 5647,
//       "Airport Code [ICAO]": "FZAM",
//       "Airport Code iATA,FAA": "MAT",
//       "Airport Name": "Tshimpi Airport",
//       "Altitude (Ft)": 1115,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 13.440400123596191,
//       "Location": "Matadi",
//       "Longitude": -5.799610137939453,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Kinshasa",
//       "Type": "airport"
//     },
//     {
//       "ID": 5648,
//       "Airport Code [ICAO]": "FZBA",
//       "Airport Code iATA,FAA": "INO",
//       "Airport Name": "Inongo Airport",
//       "Altitude (Ft)": 1040,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 18.28580093383789,
//       "Location": "Inongo",
//       "Longitude": -1.947219967842102,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Kinshasa",
//       "Type": "airport"
//     },
//     {
//       "ID": 5649,
//       "Airport Code [ICAO]": "FZBI",
//       "Airport Code iATA,FAA": "NIO",
//       "Airport Name": "Nioki Airport",
//       "Altitude (Ft)": 1043,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 17.68470001220703,
//       "Location": "Nioki",
//       "Longitude": -2.7174999713897705,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Kinshasa",
//       "Type": "airport"
//     },
//     {
//       "ID": 5650,
//       "Airport Code [ICAO]": "FZBT",
//       "Airport Code iATA,FAA": "KRZ",
//       "Airport Name": "Basango Mboliasa Airport",
//       "Altitude (Ft)": 1013,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 19.02400016784668,
//       "Location": "Kiri",
//       "Longitude": -1.434999942779541,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Kinshasa",
//       "Type": "airport"
//     },
//     {
//       "ID": 5651,
//       "Airport Code [ICAO]": "FZEN",
//       "Airport Code iATA,FAA": "BSU",
//       "Airport Name": "Basankusu Airport",
//       "Altitude (Ft)": 1217,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 19.78890037536621,
//       "Location": "Basankusu",
//       "Longitude": 1.2247200012207031,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Kinshasa",
//       "Type": "airport"
//     },
//     {
//       "ID": 5652,
//       "Airport Code [ICAO]": "FZUK",
//       "Airport Code iATA,FAA": "TSH",
//       "Airport Name": "Tshikapa Airport",
//       "Altitude (Ft)": 1595,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 20.794701,
//       "Location": "Tshikapa",
//       "Longitude": -6.43833,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lubumbashi",
//       "Type": "airport"
//     },
//     {
//       "ID": 5653,
//       "Airport Code [ICAO]": "FZVA",
//       "Airport Code iATA,FAA": "LJA",
//       "Airport Name": "Lodja Airport",
//       "Altitude (Ft)": 1647,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 23.450000762939453,
//       "Location": "Lodja",
//       "Longitude": -3.4170000553131104,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lubumbashi",
//       "Type": "airport"
//     },
//     {
//       "ID": 5654,
//       "Airport Code [ICAO]": "FZVS",
//       "Airport Code iATA,FAA": "PFR",
//       "Airport Name": "Ilebo Airport",
//       "Altitude (Ft)": 1450,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 20.590124,
//       "Location": "Ilebo",
//       "Longitude": -4.329919,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lubumbashi",
//       "Type": "airport"
//     },
//     {
//       "ID": 5657,
//       "Airport Code [ICAO]": "EGOU",
//       "Airport Code iATA,FAA": "OUK",
//       "Airport Name": "Outer Skerries Airport",
//       "Altitude (Ft)": 76,
//       "Country": "United Kingdom",
//       "Latitude": -0.75,
//       "Location": "Outer Skerries",
//       "Longitude": 60.4252,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 5659,
//       "Airport Code [ICAO]": "GCGM",
//       "Airport Code iATA,FAA": "GMZ",
//       "Airport Name": "La Gomera Airport",
//       "Altitude (Ft)": 716,
//       "Country": "Spain",
//       "Latitude": -17.214599609375,
//       "Location": "La Gomera",
//       "Longitude": 28.029600143432617,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Atlantic/Canary",
//       "Type": "airport"
//     },
//     {
//       "ID": 5662,
//       "Airport Code [ICAO]": "GFBN",
//       "Airport Code iATA,FAA": "BTE",
//       "Airport Name": "Sherbro International Airport",
//       "Altitude (Ft)": 14,
//       "Country": "Sierra Leone",
//       "Latitude": -12.518899917602539,
//       "Location": "Bonthe",
//       "Longitude": 7.5324201583862305,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Freetown",
//       "Type": "airport"
//     },
//     {
//       "ID": 5663,
//       "Airport Code [ICAO]": "GFBO",
//       "Airport Code iATA,FAA": "KBS",
//       "Airport Name": "Bo Airport",
//       "Altitude (Ft)": 328,
//       "Country": "Sierra Leone",
//       "Latitude": -11.76099967956543,
//       "Location": "Bo",
//       "Longitude": 7.944399833679199,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Freetown",
//       "Type": "airport"
//     },
//     {
//       "ID": 5664,
//       "Airport Code [ICAO]": "GFKE",
//       "Airport Code iATA,FAA": "KEN",
//       "Airport Name": "Kenema Airport",
//       "Altitude (Ft)": 485,
//       "Country": "Sierra Leone",
//       "Latitude": -11.176600456237793,
//       "Location": "Kenema",
//       "Longitude": 7.891290187835693,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Freetown",
//       "Type": "airport"
//     },
//     {
//       "ID": 5665,
//       "Airport Code [ICAO]": "GGOV",
//       "Airport Code iATA,FAA": "OXB",
//       "Airport Name": "Osvaldo Vieira International Airport",
//       "Altitude (Ft)": 129,
//       "Country": "Guinea-Bissau",
//       "Latitude": -15.65369987487793,
//       "Location": "Bissau",
//       "Longitude": 11.894800186157227,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Bissau",
//       "Type": "airport"
//     },
//     {
//       "ID": 5669,
//       "Airport Code [ICAO]": "GMMA",
//       "Airport Code iATA,FAA": "SMW",
//       "Airport Name": "Smara Airport",
//       "Altitude (Ft)": 350,
//       "Country": "Western Sahara",
//       "Latitude": -11.6847,
//       "Location": "Smara",
//       "Longitude": 26.7318,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/El_Aaiun",
//       "Type": "airport"
//     },
//     {
//       "ID": 5670,
//       "Airport Code [ICAO]": "GMMH",
//       "Airport Code iATA,FAA": "VIL",
//       "Airport Name": "Dakhla Airport",
//       "Altitude (Ft)": 36,
//       "Country": "Western Sahara",
//       "Latitude": -15.932,
//       "Location": "Dakhla",
//       "Longitude": 23.7183,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/El_Aaiun",
//       "Type": "airport"
//     },
//     {
//       "ID": 5671,
//       "Airport Code [ICAO]": "GMMI",
//       "Airport Code iATA,FAA": "ESU",
//       "Airport Name": "Mogador Airport",
//       "Altitude (Ft)": 384,
//       "Country": "Morocco",
//       "Latitude": -9.6816701889,
//       "Location": "Essadouira",
//       "Longitude": 31.3974990845,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Casablanca",
//       "Type": "airport"
//     },
//     {
//       "ID": 5672,
//       "Airport Code [ICAO]": "GMML",
//       "Airport Code iATA,FAA": "EUN",
//       "Airport Name": "Hassan I Airport",
//       "Altitude (Ft)": 207,
//       "Country": "Western Sahara",
//       "Latitude": -13.2192,
//       "Location": "El Aaiún",
//       "Longitude": 27.151699,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/El_Aaiun",
//       "Type": "airport"
//     },
//     {
//       "ID": 5673,
//       "Airport Code [ICAO]": "GMMW",
//       "Airport Code iATA,FAA": "NDR",
//       "Airport Name": "Nador International Airport",
//       "Altitude (Ft)": 574,
//       "Country": "Morocco",
//       "Latitude": -3.0282099247,
//       "Location": "El Aroui",
//       "Longitude": 34.988800048799995,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Casablanca",
//       "Type": "airport"
//     },
//     {
//       "ID": 5674,
//       "Airport Code [ICAO]": "GVNP",
//       "Airport Code iATA,FAA": "RAI",
//       "Airport Name": "Praia International Airport",
//       "Altitude (Ft)": 230,
//       "Country": "Cape Verde",
//       "Latitude": -23.493499755859375,
//       "Location": "Praia, Santiago Island",
//       "Longitude": 14.924500465393066,
//       "GMT Offset": "-1",
//       "Daylight Savings": "U",
//       "Region": "Atlantic/Cape_Verde",
//       "Type": "airport"
//     },
//     {
//       "ID": 5675,
//       "Airport Code [ICAO]": "GVSF",
//       "Airport Code iATA,FAA": "SFL",
//       "Airport Name": "São Filipe Airport",
//       "Altitude (Ft)": 617,
//       "Country": "Cape Verde",
//       "Latitude": -24.4799995422,
//       "Location": "Sao Filipe, Fogo Island",
//       "Longitude": 14.8850002289,
//       "GMT Offset": "-1",
//       "Daylight Savings": "U",
//       "Region": "Atlantic/Cape_Verde",
//       "Type": "airport"
//     },
//     {
//       "ID": 5676,
//       "Airport Code [ICAO]": "HABC",
//       "Airport Code iATA,FAA": "BCO",
//       "Airport Name": "Baco Airport",
//       "Altitude (Ft)": 4580,
//       "Country": "Ethiopia",
//       "Latitude": 36.562,
//       "Location": "Baco",
//       "Longitude": 5.78287,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 5677,
//       "Airport Code [ICAO]": "HABE",
//       "Airport Code iATA,FAA": "BEI",
//       "Airport Name": "Beica Airport",
//       "Altitude (Ft)": 5410,
//       "Country": "Ethiopia",
//       "Latitude": 34.52190017700195,
//       "Location": "Beica",
//       "Longitude": 9.38638973236084,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 5678,
//       "Airport Code [ICAO]": "HADC",
//       "Airport Code iATA,FAA": "DSE",
//       "Airport Name": "Combolcha Airport",
//       "Altitude (Ft)": 6117,
//       "Country": "Ethiopia",
//       "Latitude": 39.71139907836914,
//       "Location": "Dessie",
//       "Longitude": 11.082500457763672,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 5679,
//       "Airport Code [ICAO]": "HADD",
//       "Airport Code iATA,FAA": "DEM",
//       "Airport Name": "Dembidollo Airport",
//       "Altitude (Ft)": 5200,
//       "Country": "Ethiopia",
//       "Latitude": 34.858001708984375,
//       "Location": "Dembidollo",
//       "Longitude": 8.553999900817871,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 5680,
//       "Airport Code [ICAO]": "HAGO",
//       "Airport Code iATA,FAA": "GDE",
//       "Airport Name": "Gode Airport",
//       "Altitude (Ft)": 834,
//       "Country": "Ethiopia",
//       "Latitude": 43.5786018372,
//       "Location": "Gode",
//       "Longitude": 5.93513011932,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 5681,
//       "Airport Code [ICAO]": "HAGR",
//       "Airport Code iATA,FAA": "GOR",
//       "Airport Name": "Gore Airport",
//       "Altitude (Ft)": 6580,
//       "Country": "Ethiopia",
//       "Latitude": 35.5529,
//       "Location": "Gore",
//       "Longitude": 8.1614,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 5682,
//       "Airport Code [ICAO]": "HAKD",
//       "Airport Code iATA,FAA": "ABK",
//       "Airport Name": "Kabri Dehar Airport",
//       "Altitude (Ft)": 1800,
//       "Country": "Ethiopia",
//       "Latitude": 44.252998,
//       "Location": "Kabri Dehar",
//       "Longitude": 6.734,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 5683,
//       "Airport Code [ICAO]": "HAMT",
//       "Airport Code iATA,FAA": "MTF",
//       "Airport Name": "Mizan Teferi Airport",
//       "Altitude (Ft)": 4396,
//       "Country": "Ethiopia",
//       "Latitude": 35.5547,
//       "Location": "Mizan Teferi",
//       "Longitude": 6.9571,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 5684,
//       "Airport Code [ICAO]": "HATP",
//       "Airport Code iATA,FAA": "TIE",
//       "Airport Name": "Tippi Airport",
//       "Altitude (Ft)": 1100,
//       "Country": "Ethiopia",
//       "Latitude": 35.415,
//       "Location": "Tippi",
//       "Longitude": 7.2024,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 5685,
//       "Airport Code [ICAO]": "HCMA",
//       "Airport Code iATA,FAA": "ALU",
//       "Airport Name": "Alula Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Somalia",
//       "Latitude": 50.748,
//       "Location": "Alula",
//       "Longitude": 11.9582,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Mogadishu",
//       "Type": "airport"
//     },
//     {
//       "ID": 5686,
//       "Airport Code [ICAO]": "HCMF",
//       "Airport Code iATA,FAA": "BSA",
//       "Airport Name": "Bosaso Airport",
//       "Altitude (Ft)": 3,
//       "Country": "Somalia",
//       "Latitude": 49.14939880371094,
//       "Location": "Bosaso",
//       "Longitude": 11.275300025939941,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Mogadishu",
//       "Type": "airport"
//     },
//     {
//       "ID": 5687,
//       "Airport Code [ICAO]": "HCMM",
//       "Airport Code iATA,FAA": "MGQ",
//       "Airport Name": "Aden Adde International Airport",
//       "Altitude (Ft)": 29,
//       "Country": "Somalia",
//       "Latitude": 45.3046989440918,
//       "Location": "Mogadishu",
//       "Longitude": 2.0144400596618652,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Mogadishu",
//       "Type": "airport"
//     },
//     {
//       "ID": 5688,
//       "Airport Code [ICAO]": "HCMR",
//       "Airport Code iATA,FAA": "GLK",
//       "Airport Name": "Galcaio Airport",
//       "Altitude (Ft)": 975,
//       "Country": "Somalia",
//       "Latitude": 47.45470047,
//       "Location": "Galcaio",
//       "Longitude": 6.78082990646,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Mogadishu",
//       "Type": "airport"
//     },
//     {
//       "ID": 5689,
//       "Airport Code [ICAO]": "HCMV",
//       "Airport Code iATA,FAA": "BUO",
//       "Airport Name": "Burao Airport",
//       "Altitude (Ft)": 3400,
//       "Country": "Somalia",
//       "Latitude": 45.5549,
//       "Location": "Burao",
//       "Longitude": 9.5275,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Mogadishu",
//       "Type": "airport"
//     },
//     {
//       "ID": 5690,
//       "Airport Code [ICAO]": "HEAR",
//       "Airport Code iATA,FAA": "AAC",
//       "Airport Name": "El Arish International Airport",
//       "Altitude (Ft)": 121,
//       "Country": "Egypt",
//       "Latitude": 33.8358001709,
//       "Location": "El Arish",
//       "Longitude": 31.073299408,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Cairo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5691,
//       "Airport Code [ICAO]": "HEAT",
//       "Airport Code iATA,FAA": "ATZ",
//       "Airport Name": "Assiut International Airport",
//       "Altitude (Ft)": 772,
//       "Country": "Egypt",
//       "Latitude": 31.0119991302,
//       "Location": "Asyut",
//       "Longitude": 27.0464992523,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Cairo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5692,
//       "Airport Code [ICAO]": "HKAM",
//       "Airport Code iATA,FAA": "ASV",
//       "Airport Name": "Amboseli Airport",
//       "Altitude (Ft)": 3755,
//       "Country": "Kenya",
//       "Latitude": 37.25310134887695,
//       "Location": "Amboseli National Park",
//       "Longitude": -2.645050048828125,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 5693,
//       "Airport Code [ICAO]": "HKLK",
//       "Airport Code iATA,FAA": "LKG",
//       "Airport Name": "Lokichoggio Airport",
//       "Altitude (Ft)": 2074,
//       "Country": "Kenya",
//       "Latitude": 34.348201751708984,
//       "Location": "Lokichoggio",
//       "Longitude": 4.20412015914917,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 5694,
//       "Airport Code [ICAO]": "HKML",
//       "Airport Code iATA,FAA": "MYD",
//       "Airport Name": "Malindi Airport",
//       "Altitude (Ft)": 80,
//       "Country": "Kenya",
//       "Latitude": 40.10169982910156,
//       "Location": "Malindi",
//       "Longitude": -3.2293100357055664,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 5695,
//       "Airport Code [ICAO]": "HKNY",
//       "Airport Code iATA,FAA": "NYK",
//       "Airport Name": "Nanyuki Airport",
//       "Altitude (Ft)": 6250,
//       "Country": "Kenya",
//       "Latitude": 37.04100799560547,
//       "Location": "Nanyuki",
//       "Longitude": -0.06239889934659004,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 5696,
//       "Airport Code [ICAO]": "HLGD",
//       "Airport Code iATA,FAA": "SRX",
//       "Airport Name": "Gardabya Airport",
//       "Altitude (Ft)": 267,
//       "Country": "Libya",
//       "Latitude": 16.5949993134,
//       "Location": "Sirt",
//       "Longitude": 31.063499450699997,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Tripoli",
//       "Type": "airport"
//     },
//     {
//       "ID": 5697,
//       "Airport Code [ICAO]": "HLGN",
//       "Airport Code iATA,FAA": "TOB",
//       "Airport Name": "Gamal Abdel Nasser Airport",
//       "Altitude (Ft)": 519,
//       "Country": "Libya",
//       "Latitude": 23.907,
//       "Location": "Tobruk",
//       "Longitude": 31.861,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Tripoli",
//       "Type": "airport"
//     },
//     {
//       "ID": 5698,
//       "Airport Code [ICAO]": "HLLM",
//       "Airport Code iATA,FAA": "MJI",
//       "Airport Name": "Mitiga Airport",
//       "Altitude (Ft)": 36,
//       "Country": "Libya",
//       "Latitude": 13.276000022888184,
//       "Location": "Tripoli",
//       "Longitude": 32.894100189208984,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Tripoli",
//       "Type": "airport"
//     },
//     {
//       "ID": 5699,
//       "Airport Code [ICAO]": "HLLQ",
//       "Airport Code iATA,FAA": "LAQ",
//       "Airport Name": "La Abraq Airport",
//       "Altitude (Ft)": 2157,
//       "Country": "Libya",
//       "Latitude": 21.96430015563965,
//       "Location": "Al Bayda'",
//       "Longitude": 32.788700103759766,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Tripoli",
//       "Type": "airport"
//     },
//     {
//       "ID": 5700,
//       "Airport Code [ICAO]": "HSAT",
//       "Airport Code iATA,FAA": "ATB",
//       "Airport Name": "Atbara Airport",
//       "Altitude (Ft)": 1181,
//       "Country": "Sudan",
//       "Latitude": 34.0570182800293,
//       "Location": "Atbara",
//       "Longitude": 17.710344314575195,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Khartoum",
//       "Type": "airport"
//     },
//     {
//       "ID": 5701,
//       "Airport Code [ICAO]": "HSNN",
//       "Airport Code iATA,FAA": "UYL",
//       "Airport Name": "Nyala Airport",
//       "Altitude (Ft)": 2106,
//       "Country": "Sudan",
//       "Latitude": 24.956199645996094,
//       "Location": "Nyala",
//       "Longitude": 12.053500175476074,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Khartoum",
//       "Type": "airport"
//     },
//     {
//       "ID": 5702,
//       "Airport Code [ICAO]": "HSPN",
//       "Airport Code iATA,FAA": "PZU",
//       "Airport Name": "Port Sudan New International Airport",
//       "Altitude (Ft)": 135,
//       "Country": "Sudan",
//       "Latitude": 37.234100341796875,
//       "Location": "Port Sudan",
//       "Longitude": 19.4335994720459,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Khartoum",
//       "Type": "airport"
//     },
//     {
//       "ID": 5703,
//       "Airport Code [ICAO]": "HTBU",
//       "Airport Code iATA,FAA": "BKZ",
//       "Airport Name": "Bukoba Airport",
//       "Altitude (Ft)": 3784,
//       "Country": "Tanzania",
//       "Latitude": 31.8212,
//       "Location": "Bukoba",
//       "Longitude": -1.332,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Dar_es_Salaam",
//       "Type": "airport"
//     },
//     {
//       "ID": 5704,
//       "Airport Code [ICAO]": "HTKA",
//       "Airport Code iATA,FAA": "TKQ",
//       "Airport Name": "Kigoma Airport",
//       "Altitude (Ft)": 2700,
//       "Country": "Tanzania",
//       "Latitude": 29.6709,
//       "Location": "Kigoma",
//       "Longitude": -4.8862,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Dar_es_Salaam",
//       "Type": "airport"
//     },
//     {
//       "ID": 5705,
//       "Airport Code [ICAO]": "HTLI",
//       "Airport Code iATA,FAA": "LDI",
//       "Airport Name": "Lindi Airport",
//       "Altitude (Ft)": 100,
//       "Country": "Tanzania",
//       "Latitude": 39.757801,
//       "Location": "Lindi",
//       "Longitude": -9.85111,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Dar_es_Salaam",
//       "Type": "airport"
//     },
//     {
//       "ID": 5706,
//       "Airport Code [ICAO]": "HTMU",
//       "Airport Code iATA,FAA": "MUZ",
//       "Airport Name": "Musoma Airport",
//       "Altitude (Ft)": 3806,
//       "Country": "Tanzania",
//       "Latitude": 33.8021,
//       "Location": "Musoma",
//       "Longitude": -1.503,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Dar_es_Salaam",
//       "Type": "airport"
//     },
//     {
//       "ID": 5707,
//       "Airport Code [ICAO]": "HTSY",
//       "Airport Code iATA,FAA": "SHY",
//       "Airport Name": "Shinyanga Airport",
//       "Altitude (Ft)": 3800,
//       "Country": "Tanzania",
//       "Latitude": 33.5035,
//       "Location": "Shinyanga",
//       "Longitude": -3.6093,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Dar_es_Salaam",
//       "Type": "airport"
//     },
//     {
//       "ID": 5708,
//       "Airport Code [ICAO]": "HTTB",
//       "Airport Code iATA,FAA": "TBO",
//       "Airport Name": "Tabora Airport",
//       "Altitude (Ft)": 3868,
//       "Country": "Tanzania",
//       "Latitude": 32.83330154418945,
//       "Location": "Tabora",
//       "Longitude": -5.076389789581299,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Dar_es_Salaam",
//       "Type": "airport"
//     },
//     {
//       "ID": 5709,
//       "Airport Code [ICAO]": "HUAR",
//       "Airport Code iATA,FAA": "RUA",
//       "Airport Name": "Arua Airport",
//       "Altitude (Ft)": 3951,
//       "Country": "Uganda",
//       "Latitude": 30.91699981689453,
//       "Location": "Arua",
//       "Longitude": 3.049999952316284,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Kampala",
//       "Type": "airport"
//     },
//     {
//       "ID": 5710,
//       "Airport Code [ICAO]": "HUGU",
//       "Airport Code iATA,FAA": "ULU",
//       "Airport Name": "Gulu Airport",
//       "Altitude (Ft)": 3510,
//       "Country": "Uganda",
//       "Latitude": 32.27180099487305,
//       "Location": "Gulu",
//       "Longitude": 2.8055601119995117,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Kampala",
//       "Type": "airport"
//     },
//     {
//       "ID": 5711,
//       "Airport Code [ICAO]": "VA1P",
//       "Airport Code iATA,FAA": "DIU",
//       "Airport Name": "Diu Airport",
//       "Altitude (Ft)": 31,
//       "Country": "India",
//       "Latitude": 70.92109680175781,
//       "Location": "Diu",
//       "Longitude": 20.71310043334961,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 5714,
//       "Airport Code [ICAO]": "KABR",
//       "Airport Code iATA,FAA": "ABR",
//       "Airport Name": "Aberdeen Regional Airport",
//       "Altitude (Ft)": 1302,
//       "Country": "United States",
//       "Latitude": -98.42179870605469,
//       "Location": "Aberdeen",
//       "Longitude": 45.449100494384766,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 5715,
//       "Airport Code [ICAO]": "KABY",
//       "Airport Code iATA,FAA": "ABY",
//       "Airport Name": "Southwest Georgia Regional Airport",
//       "Altitude (Ft)": 197,
//       "Country": "United States",
//       "Latitude": -84.19450378417969,
//       "Location": "Albany",
//       "Longitude": 31.535499572753906,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 5716,
//       "Airport Code [ICAO]": "KAHN",
//       "Airport Code iATA,FAA": "AHN",
//       "Airport Name": "Athens Ben Epps Airport",
//       "Altitude (Ft)": 808,
//       "Country": "United States",
//       "Latitude": -83.32630157470703,
//       "Location": "Athens",
//       "Longitude": 33.94860076904297,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 5717,
//       "Airport Code [ICAO]": "KALM",
//       "Airport Code iATA,FAA": "ALM",
//       "Airport Name": "Alamogordo White Sands Regional Airport",
//       "Altitude (Ft)": 4200,
//       "Country": "United States",
//       "Latitude": -105.990997314,
//       "Location": "Alamogordo",
//       "Longitude": 32.8399009705,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 5718,
//       "Airport Code [ICAO]": "KALO",
//       "Airport Code iATA,FAA": "ALO",
//       "Airport Name": "Waterloo Regional Airport",
//       "Altitude (Ft)": 873,
//       "Country": "United States",
//       "Latitude": -92.40029907226562,
//       "Location": "Waterloo",
//       "Longitude": 42.557098388671875,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 5719,
//       "Airport Code [ICAO]": "KALW",
//       "Airport Code iATA,FAA": "ALW",
//       "Airport Name": "Walla Walla Regional Airport",
//       "Altitude (Ft)": 1194,
//       "Country": "United States",
//       "Latitude": -118.288002,
//       "Location": "Walla Walla",
//       "Longitude": 46.09489822,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 5720,
//       "Airport Code [ICAO]": "KAPN",
//       "Airport Code iATA,FAA": "APN",
//       "Airport Name": "Alpena County Regional Airport",
//       "Altitude (Ft)": 690,
//       "Country": "United States",
//       "Latitude": -83.56030273,
//       "Location": "Alpena",
//       "Longitude": 45.0780983,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 5721,
//       "Airport Code [ICAO]": "KATY",
//       "Airport Code iATA,FAA": "ATY",
//       "Airport Name": "Watertown Regional Airport",
//       "Altitude (Ft)": 1749,
//       "Country": "United States",
//       "Latitude": -97.15470123,
//       "Location": "Watertown",
//       "Longitude": 44.91400146,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 5722,
//       "Airport Code [ICAO]": "KBFD",
//       "Airport Code iATA,FAA": "BFD",
//       "Airport Name": "Bradford Regional Airport",
//       "Altitude (Ft)": 2143,
//       "Country": "United States",
//       "Latitude": -78.64009857177734,
//       "Location": "Bradford",
//       "Longitude": 41.8031005859375,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 5723,
//       "Airport Code [ICAO]": "KBFF",
//       "Airport Code iATA,FAA": "BFF",
//       "Airport Name": "Western Neb. Rgnl/William B. Heilig Airport",
//       "Altitude (Ft)": 3967,
//       "Country": "United States",
//       "Latitude": -103.5960007,
//       "Location": "Scottsbluff",
//       "Longitude": 41.87400055,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 5724,
//       "Airport Code [ICAO]": "KBKW",
//       "Airport Code iATA,FAA": "BKW",
//       "Airport Name": "Raleigh County Memorial Airport",
//       "Altitude (Ft)": 2504,
//       "Country": "United States",
//       "Latitude": -81.1241989136,
//       "Location": "Beckley",
//       "Longitude": 37.787300109899995,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 5725,
//       "Airport Code [ICAO]": "KBQK",
//       "Airport Code iATA,FAA": "BQK",
//       "Airport Name": "Brunswick Golden Isles Airport",
//       "Altitude (Ft)": 26,
//       "Country": "United States",
//       "Latitude": -81.46649932861328,
//       "Location": "Brunswick",
//       "Longitude": 31.258800506591797,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 5726,
//       "Airport Code [ICAO]": "KBRL",
//       "Airport Code iATA,FAA": "BRL",
//       "Airport Name": "Southeast Iowa Regional Airport",
//       "Altitude (Ft)": 698,
//       "Country": "United States",
//       "Latitude": -91.12550354003906,
//       "Location": "Burlington",
//       "Longitude": 40.783199310302734,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 5727,
//       "Airport Code [ICAO]": "KCEC",
//       "Airport Code iATA,FAA": "CEC",
//       "Airport Name": "Jack Mc Namara Field Airport",
//       "Altitude (Ft)": 61,
//       "Country": "United States",
//       "Latitude": -124.2369995,
//       "Location": "Crescent City",
//       "Longitude": 41.78020096,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 5728,
//       "Airport Code [ICAO]": "KCGI",
//       "Airport Code iATA,FAA": "CGI",
//       "Airport Name": "Cape Girardeau Regional Airport",
//       "Altitude (Ft)": 342,
//       "Country": "United States",
//       "Latitude": -89.57080078125,
//       "Location": "Cape Girardeau",
//       "Longitude": 37.22529983520508,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 5729,
//       "Airport Code [ICAO]": "KCIU",
//       "Airport Code iATA,FAA": "CIU",
//       "Airport Name": "Chippewa County International Airport",
//       "Altitude (Ft)": 800,
//       "Country": "United States",
//       "Latitude": -84.47239685058594,
//       "Location": "Sault Ste Marie",
//       "Longitude": 46.25080108642578,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 5730,
//       "Airport Code [ICAO]": "KCKB",
//       "Airport Code iATA,FAA": "CKB",
//       "Airport Name": "North Central West Virginia Airport",
//       "Altitude (Ft)": 1217,
//       "Country": "United States",
//       "Latitude": -80.2281036377,
//       "Location": "Clarksburg",
//       "Longitude": 39.2966003418,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 5731,
//       "Airport Code [ICAO]": "KCLM",
//       "Airport Code iATA,FAA": "CLM",
//       "Airport Name": "William R Fairchild International Airport",
//       "Altitude (Ft)": 291,
//       "Country": "United States",
//       "Latitude": -123.5,
//       "Location": "Port Angeles",
//       "Longitude": 48.120201110839844,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 5732,
//       "Airport Code [ICAO]": "KCMX",
//       "Airport Code iATA,FAA": "CMX",
//       "Airport Name": "Houghton County Memorial Airport",
//       "Altitude (Ft)": 1095,
//       "Country": "United States",
//       "Latitude": -88.48909759521484,
//       "Location": "Hancock",
//       "Longitude": 47.168399810791016,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 5733,
//       "Airport Code [ICAO]": "KDDC",
//       "Airport Code iATA,FAA": "DDC",
//       "Airport Name": "Dodge City Regional Airport",
//       "Altitude (Ft)": 2594,
//       "Country": "United States",
//       "Latitude": -99.9655990600586,
//       "Location": "Dodge City",
//       "Longitude": 37.76340103149414,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 5734,
//       "Airport Code [ICAO]": "KDUJ",
//       "Airport Code iATA,FAA": "DUJ",
//       "Airport Name": "DuBois Regional Airport",
//       "Altitude (Ft)": 1817,
//       "Country": "United States",
//       "Latitude": -78.8986969,
//       "Location": "Du Bois",
//       "Longitude": 41.17829895,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 5735,
//       "Airport Code [ICAO]": "KEAU",
//       "Airport Code iATA,FAA": "EAU",
//       "Airport Name": "Chippewa Valley Regional Airport",
//       "Altitude (Ft)": 913,
//       "Country": "United States",
//       "Latitude": -91.48429870605469,
//       "Location": "Eau Claire",
//       "Longitude": 44.86579895019531,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 5736,
//       "Airport Code [ICAO]": "KEKO",
//       "Airport Code iATA,FAA": "EKO",
//       "Airport Name": "Elko Regional Airport",
//       "Altitude (Ft)": 5140,
//       "Country": "United States",
//       "Latitude": -115.79199981689453,
//       "Location": "Elko",
//       "Longitude": 40.82490158081055,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 5737,
//       "Airport Code [ICAO]": "KEWB",
//       "Airport Code iATA,FAA": "EWB",
//       "Airport Name": "New Bedford Regional Airport",
//       "Altitude (Ft)": 80,
//       "Country": "United States",
//       "Latitude": -70.95690155029297,
//       "Location": "New Bedford",
//       "Longitude": 41.67610168457031,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 5738,
//       "Airport Code [ICAO]": "KFAY",
//       "Airport Code iATA,FAA": "FAY",
//       "Airport Name": "Fayetteville Regional Grannis Field",
//       "Altitude (Ft)": 189,
//       "Country": "United States",
//       "Latitude": -78.88030242919922,
//       "Location": "Fayetteville",
//       "Longitude": 34.9911994934082,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 5739,
//       "Airport Code [ICAO]": "KGGW",
//       "Airport Code iATA,FAA": "GGW",
//       "Airport Name": "Wokal Field Glasgow International Airport",
//       "Altitude (Ft)": 2296,
//       "Country": "United States",
//       "Latitude": -106.614998,
//       "Location": "Glasgow",
//       "Longitude": 48.212502,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 5740,
//       "Airport Code [ICAO]": "KGRI",
//       "Airport Code iATA,FAA": "GRI",
//       "Airport Name": "Central Nebraska Regional Airport",
//       "Altitude (Ft)": 1847,
//       "Country": "United States",
//       "Latitude": -98.30960083007812,
//       "Location": "Grand Island",
//       "Longitude": 40.967498779296875,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 5741,
//       "Airport Code [ICAO]": "KHOT",
//       "Airport Code iATA,FAA": "HOT",
//       "Airport Name": "Memorial Field",
//       "Altitude (Ft)": 540,
//       "Country": "United States",
//       "Latitude": -93.09619903564453,
//       "Location": "Hot Springs",
//       "Longitude": 34.47800064086914,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 5742,
//       "Airport Code [ICAO]": "KHTS",
//       "Airport Code iATA,FAA": "HTS",
//       "Airport Name": "Tri-State/Milton J. Ferguson Field",
//       "Altitude (Ft)": 828,
//       "Country": "United States",
//       "Latitude": -82.55799866,
//       "Location": "Huntington",
//       "Longitude": 38.36669922,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 5743,
//       "Airport Code [ICAO]": "Q51",
//       "Airport Code iATA,FAA": "KIO",
//       "Airport Name": "Kili Airport",
//       "Altitude (Ft)": 5,
//       "Country": "Marshall Islands",
//       "Latitude": 169.119507,
//       "Location": "Kili Island",
//       "Longitude": 5.644515,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Majuro",
//       "Type": "airport"
//     },
//     {
//       "ID": 5744,
//       "Airport Code [ICAO]": "KIRK",
//       "Airport Code iATA,FAA": "IRK",
//       "Airport Name": "Kirksville Regional Airport",
//       "Altitude (Ft)": 966,
//       "Country": "United States",
//       "Latitude": -92.5448989868164,
//       "Location": "Kirksville",
//       "Longitude": 40.09349822998047,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 5745,
//       "Airport Code [ICAO]": "KJMS",
//       "Airport Code iATA,FAA": "JMS",
//       "Airport Name": "Jamestown Regional Airport",
//       "Altitude (Ft)": 1500,
//       "Country": "United States",
//       "Latitude": -98.67819977,
//       "Location": "Jamestown",
//       "Longitude": 46.92969894,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 5746,
//       "Airport Code [ICAO]": "KLAR",
//       "Airport Code iATA,FAA": "LAR",
//       "Airport Name": "Laramie Regional Airport",
//       "Altitude (Ft)": 7284,
//       "Country": "United States",
//       "Latitude": -105.67500305175781,
//       "Location": "Laramie",
//       "Longitude": 41.31209945678711,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 5747,
//       "Airport Code [ICAO]": "KLBE",
//       "Airport Code iATA,FAA": "LBE",
//       "Airport Name": "Arnold Palmer Regional Airport",
//       "Altitude (Ft)": 1199,
//       "Country": "United States",
//       "Latitude": -79.40480042,
//       "Location": "Latrobe",
//       "Longitude": 40.27590179,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 5748,
//       "Airport Code [ICAO]": "KLBF",
//       "Airport Code iATA,FAA": "LBF",
//       "Airport Name": "North Platte Regional Airport Lee Bird Field",
//       "Altitude (Ft)": 2777,
//       "Country": "United States",
//       "Latitude": -100.6839981,
//       "Location": "North Platte",
//       "Longitude": 41.12620163,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 5749,
//       "Airport Code [ICAO]": "KLEB",
//       "Airport Code iATA,FAA": "LEB",
//       "Airport Name": "Lebanon Municipal Airport",
//       "Altitude (Ft)": 603,
//       "Country": "United States",
//       "Latitude": -72.30419921880001,
//       "Location": "Lebanon",
//       "Longitude": 43.626098632799994,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 5750,
//       "Airport Code [ICAO]": "KLMT",
//       "Airport Code iATA,FAA": "LMT",
//       "Airport Name": "Crater Lake-Klamath Regional Airport",
//       "Altitude (Ft)": 4095,
//       "Country": "United States",
//       "Latitude": -121.733002,
//       "Location": "Klamath Falls",
//       "Longitude": 42.156101,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 5751,
//       "Airport Code [ICAO]": "KLNS",
//       "Airport Code iATA,FAA": "LNS",
//       "Airport Name": "Lancaster Airport",
//       "Altitude (Ft)": 403,
//       "Country": "United States",
//       "Latitude": -76.29609680175781,
//       "Location": "Lancaster",
//       "Longitude": 40.121700286865234,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 5752,
//       "Airport Code [ICAO]": "KLWT",
//       "Airport Code iATA,FAA": "LWT",
//       "Airport Name": "Lewistown Municipal Airport",
//       "Altitude (Ft)": 4170,
//       "Country": "United States",
//       "Latitude": -109.46700286865234,
//       "Location": "Lewistown",
//       "Longitude": 47.04930114746094,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 5753,
//       "Airport Code [ICAO]": "KLYH",
//       "Airport Code iATA,FAA": "LYH",
//       "Airport Name": "Lynchburg Regional Preston Glenn Field",
//       "Altitude (Ft)": 938,
//       "Country": "United States",
//       "Latitude": -79.20040130615234,
//       "Location": "Lynchburg",
//       "Longitude": 37.326698303222656,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 5754,
//       "Airport Code [ICAO]": "KMKG",
//       "Airport Code iATA,FAA": "MKG",
//       "Airport Name": "Muskegon County Airport",
//       "Altitude (Ft)": 629,
//       "Country": "United States",
//       "Latitude": -86.23819733,
//       "Location": "Muskegon",
//       "Longitude": 43.16949844,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 5755,
//       "Airport Code [ICAO]": "KMLS",
//       "Airport Code iATA,FAA": "MLS",
//       "Airport Name": "Frank Wiley Field",
//       "Altitude (Ft)": 2630,
//       "Country": "United States",
//       "Latitude": -105.88600158691406,
//       "Location": "Miles City",
//       "Longitude": 46.428001403808594,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 5756,
//       "Airport Code [ICAO]": "KMSL",
//       "Airport Code iATA,FAA": "MSL",
//       "Airport Name": "Northwest Alabama Regional Airport",
//       "Altitude (Ft)": 551,
//       "Country": "United States",
//       "Latitude": -87.61019897,
//       "Location": "Muscle Shoals",
//       "Longitude": 34.74530029,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 5757,
//       "Airport Code [ICAO]": "KOTH",
//       "Airport Code iATA,FAA": "OTH",
//       "Airport Name": "Southwest Oregon Regional Airport",
//       "Altitude (Ft)": 17,
//       "Country": "United States",
//       "Latitude": -124.24600219726562,
//       "Location": "North Bend",
//       "Longitude": 43.41709899902344,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 5758,
//       "Airport Code [ICAO]": "KOWB",
//       "Airport Code iATA,FAA": "OWB",
//       "Airport Name": "Owensboro Daviess County Airport",
//       "Altitude (Ft)": 407,
//       "Country": "United States",
//       "Latitude": -87.16680145,
//       "Location": "Owensboro",
//       "Longitude": 37.74010086,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 5759,
//       "Airport Code [ICAO]": "KPIB",
//       "Airport Code iATA,FAA": "PIB",
//       "Airport Name": "Hattiesburg Laurel Regional Airport",
//       "Altitude (Ft)": 298,
//       "Country": "United States",
//       "Latitude": -89.33709716796875,
//       "Location": "Hattiesburg/Laurel",
//       "Longitude": 31.467100143432617,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 5760,
//       "Airport Code [ICAO]": "KPIH",
//       "Airport Code iATA,FAA": "PIH",
//       "Airport Name": "Pocatello Regional Airport",
//       "Altitude (Ft)": 4452,
//       "Country": "United States",
//       "Latitude": -112.59600067138672,
//       "Location": "Pocatello",
//       "Longitude": 42.9098014831543,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 5761,
//       "Airport Code [ICAO]": "KPIR",
//       "Airport Code iATA,FAA": "PIR",
//       "Airport Name": "Pierre Regional Airport",
//       "Altitude (Ft)": 1744,
//       "Country": "United States",
//       "Latitude": -100.2860031,
//       "Location": "Pierre",
//       "Longitude": 44.38270187,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 5762,
//       "Airport Code [ICAO]": "KPLN",
//       "Airport Code iATA,FAA": "PLN",
//       "Airport Name": "Pellston Regional Airport of Emmet County Airport",
//       "Altitude (Ft)": 721,
//       "Country": "United States",
//       "Latitude": -84.79669952,
//       "Location": "Pellston",
//       "Longitude": 45.57089996,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 5763,
//       "Airport Code [ICAO]": "KPSM",
//       "Airport Code iATA,FAA": "PSM",
//       "Airport Name": "Portsmouth International at Pease Airport",
//       "Altitude (Ft)": 100,
//       "Country": "United States",
//       "Latitude": -70.8233032227,
//       "Location": "Portsmouth",
//       "Longitude": 43.0778999329,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 5764,
//       "Airport Code [ICAO]": "KRDG",
//       "Airport Code iATA,FAA": "RDG",
//       "Airport Name": "Reading Regional Carl A Spaatz Field",
//       "Altitude (Ft)": 344,
//       "Country": "United States",
//       "Latitude": -75.96520233154297,
//       "Location": "Reading",
//       "Longitude": 40.378501892089844,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 5765,
//       "Airport Code [ICAO]": "KRHI",
//       "Airport Code iATA,FAA": "RHI",
//       "Airport Name": "Rhinelander Oneida County Airport",
//       "Altitude (Ft)": 1624,
//       "Country": "United States",
//       "Latitude": -89.46749877929688,
//       "Location": "Rhinelander",
//       "Longitude": 45.63119888305664,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 5766,
//       "Airport Code [ICAO]": "KRKS",
//       "Airport Code iATA,FAA": "RKS",
//       "Airport Name": "Southwest Wyoming Regional Airport",
//       "Altitude (Ft)": 6764,
//       "Country": "United States",
//       "Latitude": -109.065001,
//       "Location": "Rock Springs",
//       "Longitude": 41.5942,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 5767,
//       "Airport Code [ICAO]": "KRUT",
//       "Airport Code iATA,FAA": "RUT",
//       "Airport Name": "Rutland - Southern Vermont Regional Airport",
//       "Altitude (Ft)": 787,
//       "Country": "United States",
//       "Latitude": -72.94960022,
//       "Location": "Rutland",
//       "Longitude": 43.52939987,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 5768,
//       "Airport Code [ICAO]": "KSBP",
//       "Airport Code iATA,FAA": "SBP",
//       "Airport Name": "San Luis County Regional Airport",
//       "Altitude (Ft)": 212,
//       "Country": "United States",
//       "Latitude": -120.641998291,
//       "Location": "San Luis Obispo",
//       "Longitude": 35.236801147499996,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 5769,
//       "Airport Code [ICAO]": "KSHR",
//       "Airport Code iATA,FAA": "SHR",
//       "Airport Name": "Sheridan County Airport",
//       "Altitude (Ft)": 4021,
//       "Country": "United States",
//       "Latitude": -106.9800033569336,
//       "Location": "Sheridan",
//       "Longitude": 44.76919937133789,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 5770,
//       "Airport Code [ICAO]": "KSLK",
//       "Airport Code iATA,FAA": "SLK",
//       "Airport Name": "Adirondack Regional Airport",
//       "Altitude (Ft)": 1663,
//       "Country": "United States",
//       "Latitude": -74.2061996459961,
//       "Location": "Saranac Lake",
//       "Longitude": 44.38529968261719,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 5771,
//       "Airport Code [ICAO]": "KSLN",
//       "Airport Code iATA,FAA": "SLN",
//       "Airport Name": "Salina Municipal Airport",
//       "Altitude (Ft)": 1288,
//       "Country": "United States",
//       "Latitude": -97.6521987915039,
//       "Location": "Salina",
//       "Longitude": 38.79100036621094,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 5772,
//       "Airport Code [ICAO]": "KSMX",
//       "Airport Code iATA,FAA": "SMX",
//       "Airport Name": "Santa Maria Pub/Capt G Allan Hancock Field",
//       "Altitude (Ft)": 261,
//       "Country": "United States",
//       "Latitude": -120.4570007,
//       "Location": "Santa Maria",
//       "Longitude": 34.89889908,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 5773,
//       "Airport Code [ICAO]": "KTUP",
//       "Airport Code iATA,FAA": "TUP",
//       "Airport Name": "Tupelo Regional Airport",
//       "Altitude (Ft)": 346,
//       "Country": "United States",
//       "Latitude": -88.7698974609375,
//       "Location": "Tupelo",
//       "Longitude": 34.26810073852539,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 5774,
//       "Airport Code [ICAO]": "KUIN",
//       "Airport Code iATA,FAA": "UIN",
//       "Airport Name": "Quincy Regional Baldwin Field",
//       "Altitude (Ft)": 768,
//       "Country": "United States",
//       "Latitude": -91.19460297,
//       "Location": "Quincy",
//       "Longitude": 39.94269943,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 5775,
//       "Airport Code [ICAO]": "KVCT",
//       "Airport Code iATA,FAA": "VCT",
//       "Airport Name": "Victoria Regional Airport",
//       "Altitude (Ft)": 115,
//       "Country": "United States",
//       "Latitude": -96.91850280761719,
//       "Location": "Victoria",
//       "Longitude": 28.85260009765625,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 5776,
//       "Airport Code [ICAO]": "KVLD",
//       "Airport Code iATA,FAA": "VLD",
//       "Airport Name": "Valdosta Regional Airport",
//       "Altitude (Ft)": 203,
//       "Country": "United States",
//       "Latitude": -83.27670288085938,
//       "Location": "Valdosta",
//       "Longitude": 30.782499313354492,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 5777,
//       "Airport Code [ICAO]": "KWRL",
//       "Airport Code iATA,FAA": "WRL",
//       "Airport Name": "Worland Municipal Airport",
//       "Altitude (Ft)": 4227,
//       "Country": "United States",
//       "Latitude": -107.95099639892578,
//       "Location": "Worland",
//       "Longitude": 43.9656982421875,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 5779,
//       "Airport Code [ICAO]": "KYKM",
//       "Airport Code iATA,FAA": "YKM",
//       "Airport Name": "Yakima Air Terminal McAllister Field",
//       "Altitude (Ft)": 1099,
//       "Country": "United States",
//       "Latitude": -120.5439987,
//       "Location": "Yakima",
//       "Longitude": 46.56819916,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 5780,
//       "Airport Code [ICAO]": "LCEN",
//       "Airport Code iATA,FAA": "ECN",
//       "Airport Name": "Ercan International Airport",
//       "Altitude (Ft)": 404,
//       "Country": "Cyprus",
//       "Latitude": 33.49610137939453,
//       "Location": "Nicosia",
//       "Longitude": 35.154701232910156,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Nicosia",
//       "Type": "airport"
//     },
//     {
//       "ID": 5781,
//       "Airport Code [ICAO]": "LELO",
//       "Airport Code iATA,FAA": "RJL",
//       "Airport Name": "Logroño-Agoncillo Airport",
//       "Altitude (Ft)": 1161,
//       "Country": "Spain",
//       "Latitude": -2.32223510742,
//       "Location": "Logroño-Agoncillo",
//       "Longitude": 42.4609534888,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 5782,
//       "Airport Code [ICAO]": "LFEY",
//       "Airport Code iATA,FAA": "IDY",
//       "Airport Name": "Île d'Yeu Airport",
//       "Altitude (Ft)": 79,
//       "Country": "France",
//       "Latitude": -2.3911099433898926,
//       "Location": "Île d'Yeu",
//       "Longitude": 46.71860122680664,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 5783,
//       "Airport Code [ICAO]": "LFJR",
//       "Airport Code iATA,FAA": "ANE",
//       "Airport Name": "Angers-Loire Airport",
//       "Altitude (Ft)": 194,
//       "Country": "France",
//       "Latitude": -0.312222,
//       "Location": "Angers/Marcé",
//       "Longitude": 47.560299,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 5786,
//       "Airport Code [ICAO]": "LFTZ",
//       "Airport Code iATA,FAA": "LTT",
//       "Airport Name": "La Môle Airport",
//       "Altitude (Ft)": 59,
//       "Country": "France",
//       "Latitude": 6.482,
//       "Location": "La Môle",
//       "Longitude": 43.205399,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 5787,
//       "Airport Code [ICAO]": "LGSO",
//       "Airport Code iATA,FAA": "JSY",
//       "Airport Name": "Syros Airport",
//       "Altitude (Ft)": 236,
//       "Country": "Greece",
//       "Latitude": 24.950899124099998,
//       "Location": "Syros Island",
//       "Longitude": 37.4227981567,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 5788,
//       "Airport Code [ICAO]": "LHPP",
//       "Airport Code iATA,FAA": "PEV",
//       "Airport Name": "Pécs-Pogány Airport",
//       "Altitude (Ft)": 1000,
//       "Country": "Hungary",
//       "Latitude": 18.240996,
//       "Location": "Pécs-Pogány",
//       "Longitude": 45.990898,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Europe/Budapest",
//       "Type": "airport"
//     },
//     {
//       "ID": 5789,
//       "Airport Code [ICAO]": "LHPR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Győr-Pér International Airport",
//       "Altitude (Ft)": 424,
//       "Country": "Hungary",
//       "Latitude": 17.813601,
//       "Location": "Győr",
//       "Longitude": 47.624401,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Europe/Budapest",
//       "Type": "airport"
//     },
//     {
//       "ID": 5790,
//       "Airport Code [ICAO]": "LHSM",
//       "Airport Code iATA,FAA": "SOB",
//       "Airport Name": "Sármellék International Airport",
//       "Altitude (Ft)": 408,
//       "Country": "Hungary",
//       "Latitude": 17.159084,
//       "Location": "Sármellék",
//       "Longitude": 46.686391,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Europe/Budapest",
//       "Type": "airport"
//     },
//     {
//       "ID": 5791,
//       "Airport Code [ICAO]": "LIMW",
//       "Airport Code iATA,FAA": "AOT",
//       "Airport Name": "Aosta Airport",
//       "Altitude (Ft)": 1791,
//       "Country": "Italy",
//       "Latitude": 7.36872,
//       "Location": "Aosta",
//       "Longitude": 45.738499,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 5792,
//       "Airport Code [ICAO]": "LIRI",
//       "Airport Code iATA,FAA": "QSR",
//       "Airport Name": "Salerno Costa d'Amalfi Airport",
//       "Altitude (Ft)": 119,
//       "Country": "Italy",
//       "Latitude": 14.9113,
//       "Location": "Salerno",
//       "Longitude": 40.620399,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 5793,
//       "Airport Code [ICAO]": "LPCR",
//       "Airport Code iATA,FAA": "CVU",
//       "Airport Name": "Corvo Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Portugal",
//       "Latitude": -31.1136,
//       "Location": "Corvo",
//       "Longitude": 39.671501,
//       "GMT Offset": "-1",
//       "Daylight Savings": "E",
//       "Region": "Atlantic/Azores",
//       "Type": "airport"
//     },
//     {
//       "ID": 5794,
//       "Airport Code [ICAO]": "LQBK",
//       "Airport Code iATA,FAA": "BNX",
//       "Airport Name": "Banja Luka International Airport",
//       "Altitude (Ft)": 400,
//       "Country": "Bosnia and Herzegovina",
//       "Latitude": 17.297500610351562,
//       "Location": "Banja Luka",
//       "Longitude": 44.94139862060547,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Sarajevo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5795,
//       "Airport Code [ICAO]": "LTBO",
//       "Airport Code iATA,FAA": "USQ",
//       "Airport Name": "Uşak Airport",
//       "Altitude (Ft)": 2897,
//       "Country": "Turkey",
//       "Latitude": 29.47170066833496,
//       "Location": "Usak",
//       "Longitude": 38.68149948120117,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 5796,
//       "Airport Code [ICAO]": "LTCF",
//       "Airport Code iATA,FAA": "KSY",
//       "Airport Name": "Kars Airport",
//       "Altitude (Ft)": 5889,
//       "Country": "Turkey",
//       "Latitude": 43.1150016784668,
//       "Location": "Kars",
//       "Longitude": 40.562198638916016,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 5797,
//       "Airport Code [ICAO]": "LTCH",
//       "Airport Code iATA,FAA": "SFQ",
//       "Airport Name": "Şanlıurfa Airport",
//       "Altitude (Ft)": 1483,
//       "Country": "Turkey",
//       "Latitude": 38.84709930419922,
//       "Location": "Sanliurfa",
//       "Longitude": 37.09429931640625,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 5798,
//       "Airport Code [ICAO]": "LTCN",
//       "Airport Code iATA,FAA": "KCM",
//       "Airport Name": "Kahramanmaraş Airport",
//       "Altitude (Ft)": 1723,
//       "Country": "Turkey",
//       "Latitude": 36.9535217285,
//       "Location": "Kahramanmaras",
//       "Longitude": 37.5388259888,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 5799,
//       "Airport Code [ICAO]": "LTCO",
//       "Airport Code iATA,FAA": "AJI",
//       "Airport Name": "Ağrı Airport",
//       "Altitude (Ft)": 5462,
//       "Country": "Turkey",
//       "Latitude": 43.025978088378906,
//       "Location": "Agri",
//       "Longitude": 39.654541015625,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 5800,
//       "Airport Code [ICAO]": "LTCP",
//       "Airport Code iATA,FAA": "ADF",
//       "Airport Name": "Adıyaman Airport",
//       "Altitude (Ft)": 2216,
//       "Country": "Turkey",
//       "Latitude": 38.4688987732,
//       "Location": "Adiyaman",
//       "Longitude": 37.7313995361,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 5801,
//       "Airport Code [ICAO]": "LTFC",
//       "Airport Code iATA,FAA": "ISE",
//       "Airport Name": "Süleyman Demirel International Airport",
//       "Altitude (Ft)": 2835,
//       "Country": "Turkey",
//       "Latitude": 30.368400573699997,
//       "Location": "Isparta",
//       "Longitude": 37.8554000854,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 5802,
//       "Airport Code [ICAO]": "LTFD",
//       "Airport Code iATA,FAA": "EDO",
//       "Airport Name": "Balıkesir Körfez Airport",
//       "Altitude (Ft)": 50,
//       "Country": "Turkey",
//       "Latitude": 27.0137996674,
//       "Location": "Balikesir Korfez",
//       "Longitude": 39.554599762,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 5803,
//       "Airport Code [ICAO]": "LTFH",
//       "Airport Code iATA,FAA": "SZF",
//       "Airport Name": "Samsun Çarşamba Airport",
//       "Altitude (Ft)": 18,
//       "Country": "Turkey",
//       "Latitude": 36.567101,
//       "Location": "Samsun",
//       "Longitude": 41.254501,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 5806,
//       "Airport Code [ICAO]": "LZZI",
//       "Airport Code iATA,FAA": "ILZ",
//       "Airport Name": "Žilina Airport",
//       "Altitude (Ft)": 1020,
//       "Country": "Slovakia",
//       "Latitude": 18.6135005951,
//       "Location": "Žilina",
//       "Longitude": 49.231498718299996,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Bratislava",
//       "Type": "airport"
//     },
//     {
//       "ID": 5807,
//       "Airport Code [ICAO]": "MBGT",
//       "Airport Code iATA,FAA": "GDT",
//       "Airport Name": "JAGS McCartney International Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Turks and Caicos Islands",
//       "Latitude": -71.14230346679688,
//       "Location": "Cockburn Town",
//       "Longitude": 21.444499969482422,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Grand_Turk",
//       "Type": "airport"
//     },
//     {
//       "ID": 5808,
//       "Airport Code [ICAO]": "MBMC",
//       "Airport Code iATA,FAA": "MDS",
//       "Airport Name": "Middle Caicos Airport",
//       "Altitude (Ft)": 9,
//       "Country": "Turks and Caicos Islands",
//       "Latitude": -71.8025,
//       "Location": "Middle Caicos",
//       "Longitude": 21.82602,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Grand_Turk",
//       "Type": "airport"
//     },
//     {
//       "ID": 5809,
//       "Airport Code [ICAO]": "MBSY",
//       "Airport Code iATA,FAA": "SLX",
//       "Airport Name": "Salt Cay Airport",
//       "Altitude (Ft)": 3,
//       "Country": "Turks and Caicos Islands",
//       "Latitude": -71.1999969482,
//       "Location": "Salt Cay",
//       "Longitude": 21.333000183099998,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Grand_Turk",
//       "Type": "airport"
//     },
//     {
//       "ID": 5810,
//       "Airport Code [ICAO]": "MDCY",
//       "Airport Code iATA,FAA": "AZS",
//       "Airport Name": "Samaná El Catey International Airport",
//       "Altitude (Ft)": 30,
//       "Country": "Dominican Republic",
//       "Latitude": -69.7419967651,
//       "Location": "Samana",
//       "Longitude": 19.2670001984,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Santo_Domingo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5811,
//       "Airport Code [ICAO]": "MDJB",
//       "Airport Code iATA,FAA": "JBQ",
//       "Airport Name": "La Isabela International Airport",
//       "Altitude (Ft)": 98,
//       "Country": "Dominican Republic",
//       "Latitude": -69.98560333251953,
//       "Location": "La Isabela",
//       "Longitude": 18.572500228881836,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Santo_Domingo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5812,
//       "Airport Code [ICAO]": "MGPB",
//       "Airport Code iATA,FAA": "PBR",
//       "Airport Name": "Puerto Barrios Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Guatemala",
//       "Latitude": -88.583801,
//       "Location": "Puerto Barrios",
//       "Longitude": 15.7309,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Guatemala",
//       "Type": "airport"
//     },
//     {
//       "ID": 5813,
//       "Airport Code [ICAO]": "MGQZ",
//       "Airport Code iATA,FAA": "AAZ",
//       "Airport Name": "Quezaltenango Airport",
//       "Altitude (Ft)": 7779,
//       "Country": "Guatemala",
//       "Latitude": -91.501999,
//       "Location": "Quezaltenango",
//       "Longitude": 14.8656,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Guatemala",
//       "Type": "airport"
//     },
//     {
//       "ID": 5814,
//       "Airport Code [ICAO]": "03N",
//       "Airport Code iATA,FAA": "UTK",
//       "Airport Name": "Utirik Airport",
//       "Altitude (Ft)": 4,
//       "Country": "Marshall Islands",
//       "Latitude": 169.852005,
//       "Location": "Utirik Island",
//       "Longitude": 11.222,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Majuro",
//       "Type": "airport"
//     },
//     {
//       "ID": 5831,
//       "Airport Code [ICAO]": "MHAH",
//       "Airport Code iATA,FAA": "AHS",
//       "Airport Name": "Ahuas Airport",
//       "Altitude (Ft)": 249,
//       "Country": "Honduras",
//       "Latitude": -84.352203,
//       "Location": "Ahuas",
//       "Longitude": 15.4722,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Tegucigalpa",
//       "Type": "airport"
//     },
//     {
//       "ID": 5832,
//       "Airport Code [ICAO]": "MHPL",
//       "Airport Code iATA,FAA": "PEU",
//       "Airport Name": "Puerto Lempira Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Honduras",
//       "Latitude": -83.781197,
//       "Location": "Puerto Lempira",
//       "Longitude": 15.2622,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Tegucigalpa",
//       "Type": "airport"
//     },
//     {
//       "ID": 5833,
//       "Airport Code [ICAO]": "MLIP",
//       "Airport Code iATA,FAA": "MIJ",
//       "Airport Name": "Mili Island Airport",
//       "Altitude (Ft)": 4,
//       "Country": "Marshall Islands",
//       "Latitude": 171.733002,
//       "Location": "Mili Island",
//       "Longitude": 6.08333,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Majuro",
//       "Type": "airport"
//     },
//     {
//       "ID": 5834,
//       "Airport Code [ICAO]": "MMCY",
//       "Airport Code iATA,FAA": "CYW",
//       "Airport Name": "Captain Rogelio Castillo National Airport",
//       "Altitude (Ft)": 5709,
//       "Country": "Mexico",
//       "Latitude": -100.887001,
//       "Location": "Celaya",
//       "Longitude": 20.546,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 5835,
//       "Airport Code [ICAO]": "MMDA",
//       "Airport Code iATA,FAA": "CUA",
//       "Airport Name": "Ciudad Constitución Airport",
//       "Altitude (Ft)": 213,
//       "Country": "Mexico",
//       "Latitude": -111.61499786377,
//       "Location": "Ciudad Constitución",
//       "Longitude": 25.053800582886,
//       "GMT Offset": "-7",
//       "Daylight Savings": "S",
//       "Region": "America/Mazatlan",
//       "Type": "airport"
//     },
//     {
//       "ID": 5836,
//       "Airport Code [ICAO]": "MMGR",
//       "Airport Code iATA,FAA": "GUB",
//       "Airport Name": "Guerrero Negro Airport",
//       "Altitude (Ft)": 59,
//       "Country": "Mexico",
//       "Latitude": -114.024002,
//       "Location": "Guerrero Negro",
//       "Longitude": 28.0261,
//       "GMT Offset": "-8",
//       "Daylight Savings": "S",
//       "Region": "America/Tijuana",
//       "Type": "airport"
//     },
//     {
//       "ID": 5837,
//       "Airport Code [ICAO]": "MMJA",
//       "Airport Code iATA,FAA": "JAL",
//       "Airport Name": "El Lencero Airport",
//       "Altitude (Ft)": 3127,
//       "Country": "Mexico",
//       "Latitude": -96.7975006104,
//       "Location": "Jalapa",
//       "Longitude": 19.4750995636,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 5838,
//       "Airport Code [ICAO]": "MPCE",
//       "Airport Code iATA,FAA": "CTD",
//       "Airport Name": "Alonso Valderrama Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Panama",
//       "Latitude": -80.40969848632812,
//       "Location": "Chitré",
//       "Longitude": 7.987840175628662,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Panama",
//       "Type": "airport"
//     },
//     {
//       "ID": 5839,
//       "Airport Code [ICAO]": "MPEJ",
//       "Airport Code iATA,FAA": "ONX",
//       "Airport Name": "Enrique Adolfo Jimenez Airport",
//       "Altitude (Ft)": 25,
//       "Country": "Panama",
//       "Latitude": -79.86740112304688,
//       "Location": "Colón",
//       "Longitude": 9.356639862060547,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Panama",
//       "Type": "airport"
//     },
//     {
//       "ID": 5840,
//       "Airport Code [ICAO]": "MPJE",
//       "Airport Code iATA,FAA": "JQE",
//       "Airport Name": "Jaqué Airport",
//       "Altitude (Ft)": 29,
//       "Country": "Panama",
//       "Latitude": -78.1572036743164,
//       "Location": "Jaqué",
//       "Longitude": 7.51777982711792,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Panama",
//       "Type": "airport"
//     },
//     {
//       "ID": 5841,
//       "Airport Code [ICAO]": "MPLP",
//       "Airport Code iATA,FAA": "PLP",
//       "Airport Name": "Captain Ramon Xatruch Airport",
//       "Altitude (Ft)": 30,
//       "Country": "Panama",
//       "Latitude": -78.1417007446289,
//       "Location": "La Palma",
//       "Longitude": 8.406669616699219,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Panama",
//       "Type": "airport"
//     },
//     {
//       "ID": 5842,
//       "Airport Code [ICAO]": "MRAO",
//       "Airport Code iATA,FAA": "TTQ",
//       "Airport Name": "Aerotortuguero Airport",
//       "Altitude (Ft)": 92,
//       "Country": "Costa Rica",
//       "Latitude": -83.6095,
//       "Location": "Roxana",
//       "Longitude": 10.42,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 5843,
//       "Airport Code [ICAO]": "MRBC",
//       "Airport Code iATA,FAA": "BCL",
//       "Airport Name": "Barra del Colorado Airport",
//       "Altitude (Ft)": 3,
//       "Country": "Costa Rica",
//       "Latitude": -83.58560180664062,
//       "Location": "Pococi",
//       "Longitude": 10.768699645996094,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 5844,
//       "Airport Code [ICAO]": "MRCV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cabo Velas Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Costa Rica",
//       "Latitude": -85.852897644043,
//       "Location": "Nicoya",
//       "Longitude": 10.355699539185,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 5845,
//       "Airport Code [ICAO]": "MRIA",
//       "Airport Code iATA,FAA": "PBP",
//       "Airport Name": "Islita Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Costa Rica",
//       "Latitude": -85.37079620361328,
//       "Location": "Nandayure",
//       "Longitude": 9.856109619140625,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 5847,
//       "Airport Code [ICAO]": "MRPJ",
//       "Airport Code iATA,FAA": "PJM",
//       "Airport Name": "Puerto Jimenez Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Costa Rica",
//       "Latitude": -83.30000305175781,
//       "Location": "Puerto Jimenez",
//       "Longitude": 8.533329963684082,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 5848,
//       "Airport Code [ICAO]": "MRPV",
//       "Airport Code iATA,FAA": "SYQ",
//       "Airport Name": "Tobias Bolanos International Airport",
//       "Altitude (Ft)": 3287,
//       "Country": "Costa Rica",
//       "Latitude": -84.13980102539062,
//       "Location": "San Jose",
//       "Longitude": 9.957050323486328,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 5849,
//       "Airport Code [ICAO]": "MRSR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "(Duplicate) Playa Samara Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Costa Rica",
//       "Latitude": -85.48,
//       "Location": "Playa Samara",
//       "Longitude": 9.87,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 5850,
//       "Airport Code [ICAO]": "MTJE",
//       "Airport Code iATA,FAA": "JEE",
//       "Airport Name": "Jérémie Airport",
//       "Altitude (Ft)": 147,
//       "Country": "Haiti",
//       "Latitude": -74.17030334472656,
//       "Location": "Jeremie",
//       "Longitude": 18.66309928894043,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Port-au-Prince",
//       "Type": "airport"
//     },
//     {
//       "ID": 5851,
//       "Airport Code [ICAO]": "MTPX",
//       "Airport Code iATA,FAA": "PAX",
//       "Airport Name": "Port-de-Paix Airport",
//       "Altitude (Ft)": 9,
//       "Country": "Haiti",
//       "Latitude": -72.84860229492188,
//       "Location": "Port-de-Paix",
//       "Longitude": 19.9335994720459,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Port-au-Prince",
//       "Type": "airport"
//     },
//     {
//       "ID": 5852,
//       "Airport Code [ICAO]": "MUOC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cayo Coco Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Cuba",
//       "Latitude": -78.51100158690001,
//       "Location": "Cayo Coco",
//       "Longitude": 22.513200759900002,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 5853,
//       "Airport Code [ICAO]": "MUTD",
//       "Airport Code iATA,FAA": "TND",
//       "Airport Name": "Alberto Delgado Airport",
//       "Altitude (Ft)": 125,
//       "Country": "Cuba",
//       "Latitude": -79.99720001220703,
//       "Location": "Trinidad",
//       "Longitude": 21.788299560546875,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 5856,
//       "Airport Code [ICAO]": "MYAK",
//       "Airport Code iATA,FAA": "COX",
//       "Airport Name": "Congo Town Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Bahamas",
//       "Latitude": -77.589798,
//       "Location": "Andros",
//       "Longitude": 24.158701,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 5857,
//       "Airport Code [ICAO]": "MYCA",
//       "Airport Code iATA,FAA": "ATC",
//       "Airport Name": "Arthur's Town Airport",
//       "Altitude (Ft)": 18,
//       "Country": "Bahamas",
//       "Latitude": -75.673797,
//       "Location": "Arthur's Town",
//       "Longitude": 24.6294,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 5858,
//       "Airport Code [ICAO]": "MYCB",
//       "Airport Code iATA,FAA": "TBI",
//       "Airport Name": "New Bight Airport",
//       "Altitude (Ft)": 5,
//       "Country": "Bahamas",
//       "Latitude": -75.452301,
//       "Location": "Cat Island",
//       "Longitude": 24.3153,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 5859,
//       "Airport Code [ICAO]": "MYCI",
//       "Airport Code iATA,FAA": "CRI",
//       "Airport Name": "Colonel Hill Airport",
//       "Altitude (Ft)": 5,
//       "Country": "Bahamas",
//       "Latitude": -74.1824035645,
//       "Location": "Colonel Hill",
//       "Longitude": 22.745599746699998,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 5860,
//       "Airport Code [ICAO]": "MYPI",
//       "Airport Code iATA,FAA": "PID",
//       "Airport Name": "Nassau Paradise Island Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Bahamas",
//       "Latitude": -77.30000305175781,
//       "Location": "Nassau",
//       "Longitude": 25.08300018310547,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 5861,
//       "Airport Code [ICAO]": "NCAT",
//       "Airport Code iATA,FAA": "AIU",
//       "Airport Name": "Enua Airport",
//       "Altitude (Ft)": 36,
//       "Country": "Cook Islands",
//       "Latitude": -158.11900329589844,
//       "Location": "Atiu Island",
//       "Longitude": -19.96780014038086,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Rarotonga",
//       "Type": "airport"
//     },
//     {
//       "ID": 5862,
//       "Airport Code [ICAO]": "NCMG",
//       "Airport Code iATA,FAA": "MGS",
//       "Airport Name": "Mangaia Island Airport",
//       "Altitude (Ft)": 45,
//       "Country": "Cook Islands",
//       "Latitude": -157.9066619873047,
//       "Location": "Mangaia Island",
//       "Longitude": -21.895986557006836,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Rarotonga",
//       "Type": "airport"
//     },
//     {
//       "ID": 5863,
//       "Airport Code [ICAO]": "NCMH",
//       "Airport Code iATA,FAA": "MHX",
//       "Airport Name": "Manihiki Island Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Cook Islands",
//       "Latitude": -161.0019989013672,
//       "Location": "Manihiki Island",
//       "Longitude": -10.376700401306152,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Rarotonga",
//       "Type": "airport"
//     },
//     {
//       "ID": 5864,
//       "Airport Code [ICAO]": "NCMK",
//       "Airport Code iATA,FAA": "MUK",
//       "Airport Name": "Mauke Airport",
//       "Altitude (Ft)": 26,
//       "Country": "Cook Islands",
//       "Latitude": -157.34500122070312,
//       "Location": "Mauke Island",
//       "Longitude": -20.13610076904297,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Rarotonga",
//       "Type": "airport"
//     },
//     {
//       "ID": 5865,
//       "Airport Code [ICAO]": "NCMR",
//       "Airport Code iATA,FAA": "MOI",
//       "Airport Name": "Mitiaro Island Airport",
//       "Altitude (Ft)": 25,
//       "Country": "Cook Islands",
//       "Latitude": -157.7030029296875,
//       "Location": "Mitiaro Island",
//       "Longitude": -19.842500686645508,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Rarotonga",
//       "Type": "airport"
//     },
//     {
//       "ID": 5866,
//       "Airport Code [ICAO]": "NCPY",
//       "Airport Code iATA,FAA": "PYE",
//       "Airport Name": "Tongareva Airport",
//       "Altitude (Ft)": 8,
//       "Country": "Cook Islands",
//       "Latitude": -158.03240966796875,
//       "Location": "Penrhyn Island",
//       "Longitude": -9.01436996459961,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Rarotonga",
//       "Type": "airport"
//     },
//     {
//       "ID": 5867,
//       "Airport Code [ICAO]": "NFCI",
//       "Airport Code iATA,FAA": "ICI",
//       "Airport Name": "Cicia Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Fiji",
//       "Latitude": -179.341995239,
//       "Location": "Cicia",
//       "Longitude": -17.7432994843,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Fiji",
//       "Type": "airport"
//     },
//     {
//       "ID": 5868,
//       "Airport Code [ICAO]": "NFFO",
//       "Airport Code iATA,FAA": "PTF",
//       "Airport Name": "Malolo Lailai Island Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Fiji",
//       "Latitude": 177.197006226,
//       "Location": "Malolo Lailai Island",
//       "Longitude": -17.7779006958,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Fiji",
//       "Type": "airport"
//     },
//     {
//       "ID": 5869,
//       "Airport Code [ICAO]": "NFKD",
//       "Airport Code iATA,FAA": "KDV",
//       "Airport Name": "Vunisea Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Fiji",
//       "Latitude": 178.156997681,
//       "Location": "Vunisea",
//       "Longitude": -19.058099746699998,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Fiji",
//       "Type": "airport"
//     },
//     {
//       "ID": 5870,
//       "Airport Code [ICAO]": "NFMA",
//       "Airport Code iATA,FAA": "MNF",
//       "Airport Name": "Mana Island Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Fiji",
//       "Latitude": 177.098007202,
//       "Location": "Mana Island",
//       "Longitude": -17.6730995178,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Fiji",
//       "Type": "airport"
//     },
//     {
//       "ID": 5871,
//       "Airport Code [ICAO]": "NFMO",
//       "Airport Code iATA,FAA": "MFJ",
//       "Airport Name": "Moala Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Fiji",
//       "Latitude": 179.951004028,
//       "Location": "Moala",
//       "Longitude": -18.566699981699998,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Fiji",
//       "Type": "airport"
//     },
//     {
//       "ID": 5872,
//       "Airport Code [ICAO]": "NFNG",
//       "Airport Code iATA,FAA": "NGI",
//       "Airport Name": "Ngau Airport",
//       "Altitude (Ft)": 50,
//       "Country": "Fiji",
//       "Latitude": 179.339996338,
//       "Location": "Ngau",
//       "Longitude": -18.115600585899998,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Fiji",
//       "Type": "airport"
//     },
//     {
//       "ID": 5873,
//       "Airport Code [ICAO]": "NFNK",
//       "Airport Code iATA,FAA": "LKB",
//       "Airport Name": "Lakeba Island Airport",
//       "Altitude (Ft)": 280,
//       "Country": "Fiji",
//       "Latitude": -178.817001343,
//       "Location": "Lakeba Island",
//       "Longitude": -18.1991996765,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Fiji",
//       "Type": "airport"
//     },
//     {
//       "ID": 5874,
//       "Airport Code [ICAO]": "NFNL",
//       "Airport Code iATA,FAA": "LBS",
//       "Airport Name": "Labasa Airport",
//       "Altitude (Ft)": 44,
//       "Country": "Fiji",
//       "Latitude": 179.33999633789062,
//       "Location": "Lambasa",
//       "Longitude": -16.466699600219727,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Fiji",
//       "Type": "airport"
//     },
//     {
//       "ID": 5875,
//       "Airport Code [ICAO]": "NFNM",
//       "Airport Code iATA,FAA": "TVU",
//       "Airport Name": "Matei Airport",
//       "Altitude (Ft)": 60,
//       "Country": "Fiji",
//       "Latitude": -179.876998901,
//       "Location": "Matei",
//       "Longitude": -16.6905994415,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Fiji",
//       "Type": "airport"
//     },
//     {
//       "ID": 5876,
//       "Airport Code [ICAO]": "NFNO",
//       "Airport Code iATA,FAA": "KXF",
//       "Airport Name": "Koro Island Airport",
//       "Altitude (Ft)": 358,
//       "Country": "Fiji",
//       "Latitude": 179.42199707,
//       "Location": "Koro Island",
//       "Longitude": -17.3458003998,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Fiji",
//       "Type": "airport"
//     },
//     {
//       "ID": 5877,
//       "Airport Code [ICAO]": "NFNR",
//       "Airport Code iATA,FAA": "RTA",
//       "Airport Name": "Rotuma Airport",
//       "Altitude (Ft)": 22,
//       "Country": "Fiji",
//       "Latitude": 177.0709991455078,
//       "Location": "Rotuma",
//       "Longitude": -12.482500076293945,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Fiji",
//       "Type": "airport"
//     },
//     {
//       "ID": 5878,
//       "Airport Code [ICAO]": "NFNS",
//       "Airport Code iATA,FAA": "SVU",
//       "Airport Name": "Savusavu Airport",
//       "Altitude (Ft)": 17,
//       "Country": "Fiji",
//       "Latitude": 179.341003418,
//       "Location": "Savusavu",
//       "Longitude": -16.8027992249,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Fiji",
//       "Type": "airport"
//     },
//     {
//       "ID": 5879,
//       "Airport Code [ICAO]": "NFTE",
//       "Airport Code iATA,FAA": "EUA",
//       "Airport Name": "Kaufana Airport",
//       "Altitude (Ft)": 325,
//       "Country": "Tonga",
//       "Latitude": -174.957992554,
//       "Location": "Eua Island",
//       "Longitude": -21.378299713100002,
//       "GMT Offset": "13",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tongatapu",
//       "Type": "airport"
//     },
//     {
//       "ID": 5880,
//       "Airport Code [ICAO]": "NFTL",
//       "Airport Code iATA,FAA": "HPA",
//       "Airport Name": "Lifuka Island Airport",
//       "Altitude (Ft)": 31,
//       "Country": "Tonga",
//       "Latitude": -174.34100341796875,
//       "Location": "Lifuka",
//       "Longitude": -19.777000427246094,
//       "GMT Offset": "13",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tongatapu",
//       "Type": "airport"
//     },
//     {
//       "ID": 5881,
//       "Airport Code [ICAO]": "NFTO",
//       "Airport Code iATA,FAA": "NFO",
//       "Airport Name": "Mata'aho Airport",
//       "Altitude (Ft)": 160,
//       "Country": "Tonga",
//       "Latitude": -175.632995605,
//       "Location": "Angaha, Niuafo'ou Island",
//       "Longitude": -15.5707998276,
//       "GMT Offset": "13",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tongatapu",
//       "Type": "airport"
//     },
//     {
//       "ID": 5882,
//       "Airport Code [ICAO]": "NFTP",
//       "Airport Code iATA,FAA": "NTT",
//       "Airport Name": "Kuini Lavenia Airport",
//       "Altitude (Ft)": 30,
//       "Country": "Tonga",
//       "Latitude": -173.791089,
//       "Location": "Niuatoputapu",
//       "Longitude": -15.977297,
//       "GMT Offset": "13",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tongatapu",
//       "Type": "airport"
//     },
//     {
//       "ID": 5883,
//       "Airport Code [ICAO]": "NFVB",
//       "Airport Code iATA,FAA": "VBV",
//       "Airport Name": "Vanua Balavu Airport",
//       "Altitude (Ft)": 76,
//       "Country": "Fiji",
//       "Latitude": -178.9759979248047,
//       "Location": "Vanua Balavu",
//       "Longitude": -17.268999099731445,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Fiji",
//       "Type": "airport"
//     },
//     {
//       "ID": 5884,
//       "Airport Code [ICAO]": "NIUE",
//       "Airport Code iATA,FAA": "IUE",
//       "Airport Name": "Niue International Airport",
//       "Altitude (Ft)": 209,
//       "Country": "Niue",
//       "Latitude": -169.92559814453125,
//       "Location": "Alofi",
//       "Longitude": -19.079030990600586,
//       "GMT Offset": "-11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Niue",
//       "Type": "airport"
//     },
//     {
//       "ID": 5885,
//       "Airport Code [ICAO]": "NLWF",
//       "Airport Code iATA,FAA": "FUT",
//       "Airport Name": "Pointe Vele Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Wallis and Futuna",
//       "Latitude": -178.065994263,
//       "Location": "Futuna Island",
//       "Longitude": -14.3114004135,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Wallis",
//       "Type": "airport"
//     },
//     {
//       "ID": 5887,
//       "Airport Code [ICAO]": "NSMA",
//       "Airport Code iATA,FAA": "MXS",
//       "Airport Name": "Maota Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Samoa",
//       "Latitude": -172.25799560546875,
//       "Location": "Savaii Island",
//       "Longitude": -13.742300033569336,
//       "GMT Offset": "13",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Apia",
//       "Type": "airport"
//     },
//     {
//       "ID": 5888,
//       "Airport Code [ICAO]": "NTGD",
//       "Airport Code iATA,FAA": "APK",
//       "Airport Name": "Apataki Airport",
//       "Altitude (Ft)": 8,
//       "Country": "French Polynesia",
//       "Latitude": -146.414993,
//       "Location": "Apataki",
//       "Longitude": -15.5736,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tahiti",
//       "Type": "airport"
//     },
//     {
//       "ID": 5889,
//       "Airport Code [ICAO]": "NTHE",
//       "Airport Code iATA,FAA": "AHE",
//       "Airport Name": "Ahe Airport",
//       "Altitude (Ft)": 11,
//       "Country": "French Polynesia",
//       "Latitude": -146.2570037841797,
//       "Location": "Ahe",
//       "Longitude": -14.428099632263184,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tahiti",
//       "Type": "airport"
//     },
//     {
//       "ID": 5890,
//       "Airport Code [ICAO]": "NTMN",
//       "Airport Code iATA,FAA": "AUQ",
//       "Airport Name": "Hiva Oa-Atuona Airport",
//       "Altitude (Ft)": 1481,
//       "Country": "French Polynesia",
//       "Latitude": -139.011001587,
//       "Location": "Hiva-oa",
//       "Longitude": -9.76879024506,
//       "GMT Offset": "-9.5",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Marquesas",
//       "Type": "airport"
//     },
//     {
//       "ID": 5891,
//       "Airport Code [ICAO]": "NTMP",
//       "Airport Code iATA,FAA": "UAP",
//       "Airport Name": "Ua Pou Airport",
//       "Altitude (Ft)": 16,
//       "Country": "French Polynesia",
//       "Latitude": -140.078003,
//       "Location": "Ua Pou",
//       "Longitude": -9.35167,
//       "GMT Offset": "-9.5",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Marquesas",
//       "Type": "airport"
//     },
//     {
//       "ID": 5892,
//       "Airport Code [ICAO]": "NTMU",
//       "Airport Code iATA,FAA": "UAH",
//       "Airport Name": "Ua Huka Airport",
//       "Altitude (Ft)": 160,
//       "Country": "French Polynesia",
//       "Latitude": -139.552002,
//       "Location": "Ua Huka",
//       "Longitude": -8.93611,
//       "GMT Offset": "-9.5",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Marquesas",
//       "Type": "airport"
//     },
//     {
//       "ID": 5893,
//       "Airport Code [ICAO]": "NVSA",
//       "Airport Code iATA,FAA": "MTV",
//       "Airport Name": "Mota Lava Airport",
//       "Altitude (Ft)": 63,
//       "Country": "Vanuatu",
//       "Latitude": 167.712005615,
//       "Location": "Ablow",
//       "Longitude": -13.6660003662,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 5894,
//       "Airport Code [ICAO]": "NVSC",
//       "Airport Code iATA,FAA": "SLH",
//       "Airport Name": "Sola Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Vanuatu",
//       "Latitude": 167.537002563,
//       "Location": "Sola",
//       "Longitude": -13.8516998291,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 5895,
//       "Airport Code [ICAO]": "NVSD",
//       "Airport Code iATA,FAA": "TOH",
//       "Airport Name": "Torres Airstrip",
//       "Altitude (Ft)": 75,
//       "Country": "Vanuatu",
//       "Latitude": 166.638000488,
//       "Location": "Loh/Linua",
//       "Longitude": -13.3280000687,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 5896,
//       "Airport Code [ICAO]": "NVSE",
//       "Airport Code iATA,FAA": "EAE",
//       "Airport Name": "Siwo Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Vanuatu",
//       "Latitude": 168.343002319,
//       "Location": "Sangafa",
//       "Longitude": -17.0902996063,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 5897,
//       "Airport Code [ICAO]": "NVSF",
//       "Airport Code iATA,FAA": "CCV",
//       "Airport Name": "Craig Cove Airport",
//       "Altitude (Ft)": 69,
//       "Country": "Vanuatu",
//       "Latitude": 167.923996,
//       "Location": "Craig Cove",
//       "Longitude": -16.264999,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 5898,
//       "Airport Code [ICAO]": "NVSG",
//       "Airport Code iATA,FAA": "LOD",
//       "Airport Name": "Longana Airport",
//       "Altitude (Ft)": 167,
//       "Country": "Vanuatu",
//       "Latitude": 167.966995239,
//       "Location": "Longana",
//       "Longitude": -15.3066997528,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 5899,
//       "Airport Code [ICAO]": "NVSH",
//       "Airport Code iATA,FAA": "SSR",
//       "Airport Name": "Sara Airport",
//       "Altitude (Ft)": 493,
//       "Country": "Vanuatu",
//       "Latitude": 168.151992798,
//       "Location": "Pentecost Island",
//       "Longitude": -15.4708003998,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 5900,
//       "Airport Code [ICAO]": "NVSI",
//       "Airport Code iATA,FAA": "PBJ",
//       "Airport Name": "Tavie Airport",
//       "Altitude (Ft)": 160,
//       "Country": "Vanuatu",
//       "Latitude": 168.257003784,
//       "Location": "Paama Island",
//       "Longitude": -16.438999176,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 5901,
//       "Airport Code [ICAO]": "NVSL",
//       "Airport Code iATA,FAA": "LPM",
//       "Airport Name": "Lamap Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Vanuatu",
//       "Latitude": 167.829253,
//       "Location": "Lamap",
//       "Longitude": -16.4611228,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 5902,
//       "Airport Code [ICAO]": "NVSM",
//       "Airport Code iATA,FAA": "LNB",
//       "Airport Name": "Lamen Bay Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Vanuatu",
//       "Latitude": 168.158996582,
//       "Location": "Lamen Bay",
//       "Longitude": -16.584199905400002,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 5903,
//       "Airport Code [ICAO]": "NVSN",
//       "Airport Code iATA,FAA": "MWF",
//       "Airport Name": "Maewo-Naone Airport",
//       "Altitude (Ft)": 509,
//       "Country": "Vanuatu",
//       "Latitude": 168.082992554,
//       "Location": "Maewo Island",
//       "Longitude": -15,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 5904,
//       "Airport Code [ICAO]": "NVSO",
//       "Airport Code iATA,FAA": "LNE",
//       "Airport Name": "Lonorore Airport",
//       "Altitude (Ft)": 43,
//       "Country": "Vanuatu",
//       "Latitude": 168.17199707,
//       "Location": "Lonorore",
//       "Longitude": -15.865599632299999,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 5905,
//       "Airport Code [ICAO]": "NVSP",
//       "Airport Code iATA,FAA": "NUS",
//       "Airport Name": "Norsup Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Vanuatu",
//       "Latitude": 167.401001,
//       "Location": "Norsup",
//       "Longitude": -16.0797,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 5906,
//       "Airport Code [ICAO]": "NVSQ",
//       "Airport Code iATA,FAA": "ZGU",
//       "Airport Name": "Gaua Island Airport",
//       "Altitude (Ft)": 100,
//       "Country": "Vanuatu",
//       "Latitude": 167.587005615,
//       "Location": "Gaua Island",
//       "Longitude": -14.218099594099998,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 5907,
//       "Airport Code [ICAO]": "NVSR",
//       "Airport Code iATA,FAA": "RCL",
//       "Airport Name": "Redcliffe Airport",
//       "Altitude (Ft)": 36,
//       "Country": "Vanuatu",
//       "Latitude": 167.835006714,
//       "Location": "Redcliffe",
//       "Longitude": -15.472000122099999,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 5908,
//       "Airport Code [ICAO]": "NVSS",
//       "Airport Code iATA,FAA": "SON",
//       "Airport Name": "Santo Pekoa International Airport",
//       "Altitude (Ft)": 184,
//       "Country": "Vanuatu",
//       "Latitude": 167.220001221,
//       "Location": "Santo",
//       "Longitude": -15.505000114399998,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 5909,
//       "Airport Code [ICAO]": "NVST",
//       "Airport Code iATA,FAA": "TGH",
//       "Airport Name": "Tongoa Airport",
//       "Altitude (Ft)": 443,
//       "Country": "Vanuatu",
//       "Latitude": 168.550994873,
//       "Location": "Tongoa Island",
//       "Longitude": -16.8910999298,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 5910,
//       "Airport Code [ICAO]": "NVSU",
//       "Airport Code iATA,FAA": "ULB",
//       "Airport Name": "Uléi Airport",
//       "Altitude (Ft)": 170,
//       "Country": "Vanuatu",
//       "Latitude": 168.3011,
//       "Location": "Ambryn Island",
//       "Longitude": -16.3297,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 5911,
//       "Airport Code [ICAO]": "NVSV",
//       "Airport Code iATA,FAA": "VLS",
//       "Airport Name": "Valesdir Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Vanuatu",
//       "Latitude": 168.177001953,
//       "Location": "Valesdir",
//       "Longitude": -16.796100616500002,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 5912,
//       "Airport Code [ICAO]": "NVSX",
//       "Airport Code iATA,FAA": "SWJ",
//       "Airport Name": "Southwest Bay Airport",
//       "Altitude (Ft)": 68,
//       "Country": "Vanuatu",
//       "Latitude": 167.4472,
//       "Location": "Malekula Island",
//       "Longitude": -16.4864,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 5913,
//       "Airport Code [ICAO]": "NVSZ",
//       "Airport Code iATA,FAA": "OLJ",
//       "Airport Name": "North West Santo Airport",
//       "Altitude (Ft)": 50,
//       "Country": "Vanuatu",
//       "Latitude": 166.557998657,
//       "Location": "Olpoi",
//       "Longitude": -14.881699562099998,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 5914,
//       "Airport Code [ICAO]": "NVVA",
//       "Airport Code iATA,FAA": "AUY",
//       "Airport Name": "Aneityum Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Vanuatu",
//       "Latitude": 169.770996094,
//       "Location": "Anelghowhat",
//       "Longitude": -20.2492008209,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 5915,
//       "Airport Code [ICAO]": "NVVB",
//       "Airport Code iATA,FAA": "AWD",
//       "Airport Name": "Aniwa Airport",
//       "Altitude (Ft)": 69,
//       "Country": "Vanuatu",
//       "Latitude": 169.6009,
//       "Location": "Aniwa",
//       "Longitude": -19.2346,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 5916,
//       "Airport Code [ICAO]": "NVVD",
//       "Airport Code iATA,FAA": "DLY",
//       "Airport Name": "Dillon's Bay Airport",
//       "Altitude (Ft)": 630,
//       "Country": "Vanuatu",
//       "Latitude": 169.00100708,
//       "Location": "Dillon's Bay",
//       "Longitude": -18.7693996429,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 5917,
//       "Airport Code [ICAO]": "NVVF",
//       "Airport Code iATA,FAA": "FTA",
//       "Airport Name": "Futuna Airport",
//       "Altitude (Ft)": 95,
//       "Country": "Vanuatu",
//       "Latitude": 170.231994629,
//       "Location": "Futuna Island",
//       "Longitude": -19.516399383499998,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 5918,
//       "Airport Code [ICAO]": "NVVI",
//       "Airport Code iATA,FAA": "IPA",
//       "Airport Name": "Ipota Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Vanuatu",
//       "Latitude": 169.283333,
//       "Location": "Ipota",
//       "Longitude": -18.856389,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 5919,
//       "Airport Code [ICAO]": "NWWA",
//       "Airport Code iATA,FAA": "TGJ",
//       "Airport Name": "Tiga Airport",
//       "Altitude (Ft)": 128,
//       "Country": "New Caledonia",
//       "Latitude": 167.8040008544922,
//       "Location": "Tiga",
//       "Longitude": -21.096099853515625,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Noumea",
//       "Type": "airport"
//     },
//     {
//       "ID": 5920,
//       "Airport Code [ICAO]": "NWWC",
//       "Airport Code iATA,FAA": "BMY",
//       "Airport Name": "Île Art - Waala Airport",
//       "Altitude (Ft)": 306,
//       "Country": "New Caledonia",
//       "Latitude": 163.66099548339844,
//       "Location": "Waala",
//       "Longitude": -19.720600128173828,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Noumea",
//       "Type": "airport"
//     },
//     {
//       "ID": 5921,
//       "Airport Code [ICAO]": "NWWE",
//       "Airport Code iATA,FAA": "ILP",
//       "Airport Name": "Île des Pins Airport",
//       "Altitude (Ft)": 315,
//       "Country": "New Caledonia",
//       "Latitude": 167.45599365234375,
//       "Location": "Île des Pins",
//       "Longitude": -22.588899612426758,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Noumea",
//       "Type": "airport"
//     },
//     {
//       "ID": 5922,
//       "Airport Code [ICAO]": "OAFZ",
//       "Airport Code iATA,FAA": "FBD",
//       "Airport Name": "Fayzabad Airport",
//       "Altitude (Ft)": 3872,
//       "Country": "Afghanistan",
//       "Latitude": 70.518097,
//       "Location": "Faizabad",
//       "Longitude": 37.121101,
//       "GMT Offset": "4.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Kabul",
//       "Type": "airport"
//     },
//     {
//       "ID": 5924,
//       "Airport Code [ICAO]": "OEDW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Dawadmi Domestic Airport",
//       "Altitude (Ft)": 3429,
//       "Country": "Saudi Arabia",
//       "Latitude": 44.400001525878906,
//       "Location": "Dawadmi",
//       "Longitude": 24.5,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 5925,
//       "Airport Code [ICAO]": "OESK",
//       "Airport Code iATA,FAA": "AJF",
//       "Airport Name": "Al-Jawf Domestic Airport",
//       "Altitude (Ft)": 2261,
//       "Country": "Saudi Arabia",
//       "Latitude": 40.099998474121094,
//       "Location": "Al-Jawf",
//       "Longitude": 29.78510093688965,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 5926,
//       "Airport Code [ICAO]": "OEWD",
//       "Airport Code iATA,FAA": "WAE",
//       "Airport Name": "Wadi Al Dawasir Airport",
//       "Altitude (Ft)": 2062,
//       "Country": "Saudi Arabia",
//       "Latitude": 45.199600219699995,
//       "Location": "Wadi-al-dawasir",
//       "Longitude": 20.504299163800003,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 5927,
//       "Airport Code [ICAO]": "OICK",
//       "Airport Code iATA,FAA": "KHD",
//       "Airport Name": "Khoram Abad Airport",
//       "Altitude (Ft)": 3782,
//       "Country": "Iran",
//       "Latitude": 48.282901763916016,
//       "Location": "Khorram Abad",
//       "Longitude": 33.43539810180664,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 5928,
//       "Airport Code [ICAO]": "OIKM",
//       "Airport Code iATA,FAA": "BXR",
//       "Airport Name": "Bam Airport",
//       "Altitude (Ft)": 3231,
//       "Country": "Iran",
//       "Latitude": 58.45000076293945,
//       "Location": "Bam",
//       "Longitude": 29.084199905395508,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 5929,
//       "Airport Code [ICAO]": "OIKR",
//       "Airport Code iATA,FAA": "RJN",
//       "Airport Name": "Rafsanjan Airport",
//       "Altitude (Ft)": 5298,
//       "Country": "Iran",
//       "Latitude": 56.05110168457031,
//       "Location": "Rafsanjan",
//       "Longitude": 30.297700881958008,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 5930,
//       "Airport Code [ICAO]": "OIMN",
//       "Airport Code iATA,FAA": "BJB",
//       "Airport Name": "Bojnord Airport",
//       "Altitude (Ft)": 3499,
//       "Country": "Iran",
//       "Latitude": 57.30820083618164,
//       "Location": "Bojnourd",
//       "Longitude": 37.49300003051758,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 5931,
//       "Airport Code [ICAO]": "OIMS",
//       "Airport Code iATA,FAA": "AFZ",
//       "Airport Name": "Sabzevar National Airport",
//       "Altitude (Ft)": 3010,
//       "Country": "Iran",
//       "Latitude": 57.59519958496094,
//       "Location": "Sabzevar",
//       "Longitude": 36.16809844970703,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 5932,
//       "Airport Code [ICAO]": "OINN",
//       "Airport Code iATA,FAA": "NSH",
//       "Airport Name": "Noshahr Airport",
//       "Altitude (Ft)": -61,
//       "Country": "Iran",
//       "Latitude": 51.464698791503906,
//       "Location": "Noshahr",
//       "Longitude": 36.663299560546875,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 5933,
//       "Airport Code [ICAO]": "OINZ",
//       "Airport Code iATA,FAA": "SRY",
//       "Airport Name": "Dasht-e Naz Airport",
//       "Altitude (Ft)": 35,
//       "Country": "Iran",
//       "Latitude": 53.193599700899995,
//       "Location": "Dasht-e-naz",
//       "Longitude": 36.635799408,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 5934,
//       "Airport Code [ICAO]": "OISL",
//       "Airport Code iATA,FAA": "LRR",
//       "Airport Name": "Lar Airport",
//       "Altitude (Ft)": 2641,
//       "Country": "Iran",
//       "Latitude": 54.3833007812,
//       "Location": "Lar",
//       "Longitude": 27.6746997833,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 5935,
//       "Airport Code [ICAO]": "OITL",
//       "Airport Code iATA,FAA": "ADU",
//       "Airport Name": "Ardabil Airport",
//       "Altitude (Ft)": 4315,
//       "Country": "Iran",
//       "Latitude": 48.4244003296,
//       "Location": "Ardabil",
//       "Longitude": 38.3256988525,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 5936,
//       "Airport Code [ICAO]": "OITR",
//       "Airport Code iATA,FAA": "OMH",
//       "Airport Name": "Urmia Airport",
//       "Altitude (Ft)": 4343,
//       "Country": "Iran",
//       "Latitude": 45.0686988831,
//       "Location": "Uromiyeh",
//       "Longitude": 37.6680984497,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 5937,
//       "Airport Code [ICAO]": "OMAL",
//       "Airport Code iATA,FAA": "AAN",
//       "Airport Name": "Al Ain International Airport",
//       "Altitude (Ft)": 869,
//       "Country": "United Arab Emirates",
//       "Latitude": 55.60919952392578,
//       "Location": "Al Ain",
//       "Longitude": 24.261699676513672,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dubai",
//       "Type": "airport"
//     },
//     {
//       "ID": 5938,
//       "Airport Code [ICAO]": "OPBN",
//       "Airport Code iATA,FAA": "BNP",
//       "Airport Name": "Bannu Airport",
//       "Altitude (Ft)": 1325,
//       "Country": "Pakistan",
//       "Latitude": 70.527901,
//       "Location": "Bannu",
//       "Longitude": 32.9729,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 5939,
//       "Airport Code [ICAO]": "OPBW",
//       "Airport Code iATA,FAA": "BHV",
//       "Airport Name": "Bahawalpur Airport",
//       "Altitude (Ft)": 392,
//       "Country": "Pakistan",
//       "Latitude": 71.71800231933594,
//       "Location": "Bahawalpur",
//       "Longitude": 29.348100662231445,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 5940,
//       "Airport Code [ICAO]": "OPCH",
//       "Airport Code iATA,FAA": "CJL",
//       "Airport Name": "Chitral Airport",
//       "Altitude (Ft)": 4920,
//       "Country": "Pakistan",
//       "Latitude": 71.80059814453125,
//       "Location": "Chitral",
//       "Longitude": 35.886600494384766,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 5941,
//       "Airport Code [ICAO]": "OPDB",
//       "Airport Code iATA,FAA": "DBA",
//       "Airport Name": "Dalbandin Airport",
//       "Altitude (Ft)": 2800,
//       "Country": "Pakistan",
//       "Latitude": 64.3998031616,
//       "Location": "Dalbandin",
//       "Longitude": 28.878299713100002,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 5942,
//       "Airport Code [ICAO]": "OPDG",
//       "Airport Code iATA,FAA": "DEA",
//       "Airport Name": "Dera Ghazi Khan Airport",
//       "Altitude (Ft)": 492,
//       "Country": "Pakistan",
//       "Latitude": 70.48590087890625,
//       "Location": "Dera Ghazi Khan",
//       "Longitude": 29.961000442504883,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 5943,
//       "Airport Code [ICAO]": "OPDI",
//       "Airport Code iATA,FAA": "DSK",
//       "Airport Name": "Dera Ismael Khan Airport",
//       "Altitude (Ft)": 594,
//       "Country": "Pakistan",
//       "Latitude": 70.89659881591797,
//       "Location": "Dera Ismael Khan",
//       "Longitude": 31.909400939941406,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 5944,
//       "Airport Code [ICAO]": "OPJI",
//       "Airport Code iATA,FAA": "JIW",
//       "Airport Name": "Jiwani Airport",
//       "Altitude (Ft)": 186,
//       "Country": "Pakistan",
//       "Latitude": 61.8054008484,
//       "Location": "Jiwani",
//       "Longitude": 25.067800521900004,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 5945,
//       "Airport Code [ICAO]": "OPKD",
//       "Airport Code iATA,FAA": "HDD",
//       "Airport Name": "Hyderabad Airport",
//       "Altitude (Ft)": 130,
//       "Country": "Pakistan",
//       "Latitude": 68.366096,
//       "Location": "Hyderabad",
//       "Longitude": 25.3181,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 5946,
//       "Airport Code [ICAO]": "OPKH",
//       "Airport Code iATA,FAA": "KDD",
//       "Airport Name": "Khuzdar Airport",
//       "Altitude (Ft)": 4012,
//       "Country": "Pakistan",
//       "Latitude": 66.6473007202,
//       "Location": "Khuzdar",
//       "Longitude": 27.790599823,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 5947,
//       "Airport Code [ICAO]": "OPOR",
//       "Airport Code iATA,FAA": "ORW",
//       "Airport Name": "Ormara Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Pakistan",
//       "Latitude": 64.58599853519999,
//       "Location": "Ormara Raik",
//       "Longitude": 25.274700164799995,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 5948,
//       "Airport Code [ICAO]": "OPPC",
//       "Airport Code iATA,FAA": "PAJ",
//       "Airport Name": "Parachinar Airport",
//       "Altitude (Ft)": 5800,
//       "Country": "Pakistan",
//       "Latitude": 70.0716018677,
//       "Location": "Parachinar",
//       "Longitude": 33.902099609400004,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 5949,
//       "Airport Code [ICAO]": "OPSD",
//       "Airport Code iATA,FAA": "KDU",
//       "Airport Name": "Skardu Airport",
//       "Altitude (Ft)": 7316,
//       "Country": "Pakistan",
//       "Latitude": 75.53600311279297,
//       "Location": "Skardu",
//       "Longitude": 35.33549880981445,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 5950,
//       "Airport Code [ICAO]": "OPSN",
//       "Airport Code iATA,FAA": "SYW",
//       "Airport Name": "Sehwan Sharif Airport",
//       "Altitude (Ft)": 121,
//       "Country": "Pakistan",
//       "Latitude": 67.71720123291016,
//       "Location": "Sehwan Sharif",
//       "Longitude": 26.473100662231445,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 5951,
//       "Airport Code [ICAO]": "OPTU",
//       "Airport Code iATA,FAA": "TUK",
//       "Airport Name": "Turbat International Airport",
//       "Altitude (Ft)": 498,
//       "Country": "Pakistan",
//       "Latitude": 63.03020095825195,
//       "Location": "Turbat",
//       "Longitude": 25.986400604248047,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 5952,
//       "Airport Code [ICAO]": "ORSU",
//       "Airport Code iATA,FAA": "ISU",
//       "Airport Name": "Sulaymaniyah International Airport",
//       "Altitude (Ft)": 2494,
//       "Country": "Iraq",
//       "Latitude": 45.316738128699996,
//       "Location": "Sulaymaniyah",
//       "Longitude": 35.5617485046,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Baghdad",
//       "Type": "airport"
//     },
//     {
//       "ID": 5953,
//       "Airport Code [ICAO]": "OSKL",
//       "Airport Code iATA,FAA": "KAC",
//       "Airport Name": "Kamishly Airport",
//       "Altitude (Ft)": 1480,
//       "Country": "Syria",
//       "Latitude": 41.19139862060547,
//       "Location": "Kamishly",
//       "Longitude": 37.020599365234375,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Asia/Damascus",
//       "Type": "airport"
//     },
//     {
//       "ID": 5954,
//       "Airport Code [ICAO]": "OYSY",
//       "Airport Code iATA,FAA": "GXF",
//       "Airport Name": "Sayun International Airport",
//       "Altitude (Ft)": 2097,
//       "Country": "Yemen",
//       "Latitude": 48.78829956049999,
//       "Location": "Sayun Intl",
//       "Longitude": 15.9660997391,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Aden",
//       "Type": "airport"
//     },
//     {
//       "ID": 5959,
//       "Airport Code [ICAO]": "PADK",
//       "Airport Code iATA,FAA": "ADK",
//       "Airport Name": "Adak Airport",
//       "Altitude (Ft)": 18,
//       "Country": "United States",
//       "Latitude": -176.64599609375,
//       "Location": "Adak Island",
//       "Longitude": 51.87799835205078,
//       "GMT Offset": "-10",
//       "Daylight Savings": "A",
//       "Region": "America/Adak",
//       "Type": "airport"
//     },
//     {
//       "ID": 5960,
//       "Airport Code [ICAO]": "PAGS",
//       "Airport Code iATA,FAA": "GST",
//       "Airport Name": "Gustavus Airport",
//       "Altitude (Ft)": 35,
//       "Country": "United States",
//       "Latitude": -135.7070007,
//       "Location": "Gustavus",
//       "Longitude": 58.4253006,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 5961,
//       "Airport Code [ICAO]": "PAGY",
//       "Airport Code iATA,FAA": "SGY",
//       "Airport Name": "Skagway Airport",
//       "Altitude (Ft)": 44,
//       "Country": "United States",
//       "Latitude": -135.3159942626953,
//       "Location": "Skagway",
//       "Longitude": 59.46009826660156,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 5962,
//       "Airport Code [ICAO]": "PAHC",
//       "Airport Code iATA,FAA": "HCR",
//       "Airport Name": "Holy Cross Airport",
//       "Altitude (Ft)": 70,
//       "Country": "United States",
//       "Latitude": -159.77499389648438,
//       "Location": "Holy Cross",
//       "Longitude": 62.18830108642578,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 5963,
//       "Airport Code [ICAO]": "PAHN",
//       "Airport Code iATA,FAA": "HNS",
//       "Airport Name": "Haines Airport",
//       "Altitude (Ft)": 15,
//       "Country": "United States",
//       "Latitude": -135.5240020751953,
//       "Location": "Haines",
//       "Longitude": 59.24380111694336,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 5964,
//       "Airport Code [ICAO]": "PALG",
//       "Airport Code iATA,FAA": "KLG",
//       "Airport Name": "Kalskag Airport",
//       "Altitude (Ft)": 55,
//       "Country": "United States",
//       "Latitude": -160.34100341796875,
//       "Location": "Kalskag",
//       "Longitude": 61.53630065917969,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 5965,
//       "Airport Code [ICAO]": "PAMC",
//       "Airport Code iATA,FAA": "MCG",
//       "Airport Name": "McGrath Airport",
//       "Altitude (Ft)": 341,
//       "Country": "United States",
//       "Latitude": -155.6060028,
//       "Location": "Mcgrath",
//       "Longitude": 62.95289993,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 5966,
//       "Airport Code [ICAO]": "PAMO",
//       "Airport Code iATA,FAA": "MOU",
//       "Airport Name": "Mountain Village Airport",
//       "Altitude (Ft)": 337,
//       "Country": "United States",
//       "Latitude": -163.6820068359375,
//       "Location": "Mountain Village",
//       "Longitude": 62.095401763916016,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 5967,
//       "Airport Code [ICAO]": "PANI",
//       "Airport Code iATA,FAA": "ANI",
//       "Airport Name": "Aniak Airport",
//       "Altitude (Ft)": 88,
//       "Country": "United States",
//       "Latitude": -159.54299926757812,
//       "Location": "Aniak",
//       "Longitude": 61.581600189208984,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 5968,
//       "Airport Code [ICAO]": "PAVA",
//       "Airport Code iATA,FAA": "VAK",
//       "Airport Name": "Chevak Airport",
//       "Altitude (Ft)": 75,
//       "Country": "United States",
//       "Latitude": -165.6005,
//       "Location": "Chevak",
//       "Longitude": 61.5409,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 5969,
//       "Airport Code [ICAO]": "PAWG",
//       "Airport Code iATA,FAA": "WRG",
//       "Airport Name": "Wrangell Airport",
//       "Altitude (Ft)": 49,
//       "Country": "United States",
//       "Latitude": -132.3699951,
//       "Location": "Wrangell",
//       "Longitude": 56.48429871,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 5982,
//       "Airport Code [ICAO]": "AYBM",
//       "Airport Code iATA,FAA": "OPU",
//       "Airport Name": "Balimo Airport",
//       "Altitude (Ft)": 51,
//       "Country": "Papua New Guinea",
//       "Latitude": 142.932998657,
//       "Location": "Balimo",
//       "Longitude": -8.05000019073,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 5986,
//       "Airport Code [ICAO]": "AYBA",
//       "Airport Code iATA,FAA": "VMU",
//       "Airport Name": "Baimuru Airport",
//       "Altitude (Ft)": 27,
//       "Country": "Papua New Guinea",
//       "Latitude": 144.819900513,
//       "Location": "Baimuru",
//       "Longitude": -7.49686002731,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Port_Moresby",
//       "Type": "airport"
//     },
//     {
//       "ID": 5989,
//       "Airport Code [ICAO]": "PHLU",
//       "Airport Code iATA,FAA": "LUP",
//       "Airport Name": "Kalaupapa Airport",
//       "Altitude (Ft)": 24,
//       "Country": "United States",
//       "Latitude": -156.973999,
//       "Location": "Molokai",
//       "Longitude": 21.21100044,
//       "GMT Offset": "-10",
//       "Daylight Savings": "A",
//       "Region": "Pacific/Honolulu",
//       "Type": "airport"
//     },
//     {
//       "ID": 5990,
//       "Airport Code [ICAO]": "PKMA",
//       "Airport Code iATA,FAA": "ENT",
//       "Airport Name": "Eniwetok Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Marshall Islands",
//       "Latitude": 162.3280029296875,
//       "Location": "Eniwetok Atoll",
//       "Longitude": 11.340700149536133,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Majuro",
//       "Type": "airport"
//     },
//     {
//       "ID": 5991,
//       "Airport Code [ICAO]": "RCFG",
//       "Airport Code iATA,FAA": "LZN",
//       "Airport Name": "Matsu Nangan Airport",
//       "Altitude (Ft)": 232,
//       "Country": "Taiwan",
//       "Latitude": 119.958,
//       "Location": "Matsu Islands",
//       "Longitude": 26.1598,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Taipei",
//       "Type": "airport"
//     },
//     {
//       "ID": 5992,
//       "Airport Code [ICAO]": "RCKW",
//       "Airport Code iATA,FAA": "HCN",
//       "Airport Name": "Hengchun Airport",
//       "Altitude (Ft)": 46,
//       "Country": "Taiwan",
//       "Latitude": 120.7300033569336,
//       "Location": "Hengchun",
//       "Longitude": 22.041099548339844,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Taipei",
//       "Type": "airport"
//     },
//     {
//       "ID": 5993,
//       "Airport Code [ICAO]": "RCMT",
//       "Airport Code iATA,FAA": "MFK",
//       "Airport Name": "Matsu Beigan Airport",
//       "Altitude (Ft)": 41,
//       "Country": "Taiwan",
//       "Latitude": 120.002998,
//       "Location": "Matsu Islands",
//       "Longitude": 26.224199,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Taipei",
//       "Type": "airport"
//     },
//     {
//       "ID": 5994,
//       "Airport Code [ICAO]": "RJCK",
//       "Airport Code iATA,FAA": "KUH",
//       "Airport Name": "Kushiro Airport",
//       "Altitude (Ft)": 327,
//       "Country": "Japan",
//       "Latitude": 144.192993164,
//       "Location": "Kushiro",
//       "Longitude": 43.041000366199995,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5995,
//       "Airport Code [ICAO]": "RJCO",
//       "Airport Code iATA,FAA": "OKD",
//       "Airport Name": "Okadama Airport",
//       "Altitude (Ft)": 25,
//       "Country": "Japan",
//       "Latitude": 141.38134,
//       "Location": "Sapporo",
//       "Longitude": 43.117447,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5996,
//       "Airport Code [ICAO]": "RJFS",
//       "Airport Code iATA,FAA": "HSG",
//       "Airport Name": "Saga Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Japan",
//       "Latitude": 130.302001953,
//       "Location": "Saga",
//       "Longitude": 33.149700164799995,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5997,
//       "Airport Code [ICAO]": "RJNA",
//       "Airport Code iATA,FAA": "NKM",
//       "Airport Name": "Nagoya Airport",
//       "Altitude (Ft)": 52,
//       "Country": "Japan",
//       "Latitude": 136.9239959716797,
//       "Location": "Nagoya",
//       "Longitude": 35.255001068115234,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5998,
//       "Airport Code [ICAO]": "RJOW",
//       "Airport Code iATA,FAA": "IWJ",
//       "Airport Name": "Iwami Airport",
//       "Altitude (Ft)": 184,
//       "Country": "Japan",
//       "Latitude": 131.789993286,
//       "Location": "Iwami",
//       "Longitude": 34.676399231,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 5999,
//       "Airport Code [ICAO]": "RJSF",
//       "Airport Code iATA,FAA": "FKS",
//       "Airport Name": "Fukushima Airport",
//       "Altitude (Ft)": 1221,
//       "Country": "Japan",
//       "Latitude": 140.43099975585938,
//       "Location": "Fukushima",
//       "Longitude": 37.22740173339844,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 6000,
//       "Airport Code [ICAO]": "RJSR",
//       "Airport Code iATA,FAA": "ONJ",
//       "Airport Name": "Odate Noshiro Airport",
//       "Altitude (Ft)": 292,
//       "Country": "Japan",
//       "Latitude": 140.371002197,
//       "Location": "Odate Noshiro",
//       "Longitude": 40.1918983459,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 6001,
//       "Airport Code [ICAO]": "RJSY",
//       "Airport Code iATA,FAA": "SYO",
//       "Airport Name": "Shonai Airport",
//       "Altitude (Ft)": 86,
//       "Country": "Japan",
//       "Latitude": 139.787002563,
//       "Location": "Shonai",
//       "Longitude": 38.81219863889999,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 6002,
//       "Airport Code [ICAO]": "RJTQ",
//       "Airport Code iATA,FAA": "MYE",
//       "Airport Name": "Miyakejima Airport",
//       "Altitude (Ft)": 67,
//       "Country": "Japan",
//       "Latitude": 139.559997559,
//       "Location": "Miyakejima",
//       "Longitude": 34.073600769,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 6003,
//       "Airport Code [ICAO]": "RKJK",
//       "Airport Code iATA,FAA": "KUV",
//       "Airport Name": "Kunsan Air Base",
//       "Altitude (Ft)": 29,
//       "Country": "South Korea",
//       "Latitude": 126.61599731445312,
//       "Location": "Kunsan",
//       "Longitude": 35.90380096435547,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Seoul",
//       "Type": "airport"
//     },
//     {
//       "ID": 6004,
//       "Airport Code [ICAO]": "RKJM",
//       "Airport Code iATA,FAA": "MPK",
//       "Airport Name": "Mokpo Heliport",
//       "Altitude (Ft)": 23,
//       "Country": "South Korea",
//       "Latitude": 126.379997253,
//       "Location": "Mokpo",
//       "Longitude": 34.7588996887,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Seoul",
//       "Type": "airport"
//     },
//     {
//       "ID": 6005,
//       "Airport Code [ICAO]": "RKNW",
//       "Airport Code iATA,FAA": "WJU",
//       "Airport Name": "Wonju/Hoengseong Air Base (K-38/K-46)",
//       "Altitude (Ft)": 329,
//       "Country": "South Korea",
//       "Latitude": 127.963858,
//       "Location": "Wonju",
//       "Longitude": 37.441201,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Seoul",
//       "Type": "airport"
//     },
//     {
//       "ID": 6006,
//       "Airport Code [ICAO]": "RKNY",
//       "Airport Code iATA,FAA": "YNY",
//       "Airport Name": "Yangyang International Airport",
//       "Altitude (Ft)": 241,
//       "Country": "South Korea",
//       "Latitude": 128.669006,
//       "Location": "Sokcho / Gangneung",
//       "Longitude": 38.061298,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Seoul",
//       "Type": "airport"
//     },
//     {
//       "ID": 6007,
//       "Airport Code [ICAO]": "RKPS",
//       "Airport Code iATA,FAA": "HIN",
//       "Airport Name": "Sacheon Air Base/Airport",
//       "Altitude (Ft)": 25,
//       "Country": "South Korea",
//       "Latitude": 128.071747,
//       "Location": "Sacheon",
//       "Longitude": 35.088591,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Seoul",
//       "Type": "airport"
//     },
//     {
//       "ID": 6008,
//       "Airport Code [ICAO]": "RKTU",
//       "Airport Code iATA,FAA": "CJJ",
//       "Airport Name": "Cheongju International Airport/Cheongju Air Base (K-59/G-513)",
//       "Altitude (Ft)": 191,
//       "Country": "South Korea",
//       "Latitude": 127.498741,
//       "Location": "Chongju",
//       "Longitude": 36.717008,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Seoul",
//       "Type": "airport"
//     },
//     {
//       "ID": 6009,
//       "Airport Code [ICAO]": "RPLB",
//       "Airport Code iATA,FAA": "SFS",
//       "Airport Name": "Subic Bay International Airport",
//       "Altitude (Ft)": 64,
//       "Country": "Philippines",
//       "Latitude": 120.27100372314453,
//       "Location": "Olongapo City",
//       "Longitude": 14.794400215148926,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 6010,
//       "Airport Code [ICAO]": "RPLO",
//       "Airport Code iATA,FAA": "CYU",
//       "Airport Name": "Cuyo Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Philippines",
//       "Latitude": 121.06900024414062,
//       "Location": "Cuyo",
//       "Longitude": 10.858099937438965,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 6011,
//       "Airport Code [ICAO]": "RPMB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Rajah Buayan Air Base",
//       "Altitude (Ft)": 28,
//       "Country": "Philippines",
//       "Latitude": 125.23500061,
//       "Location": "General Santos City",
//       "Longitude": 6.10644006729,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 6012,
//       "Airport Code [ICAO]": "RPMH",
//       "Airport Code iATA,FAA": "CGM",
//       "Airport Name": "Camiguin Airport",
//       "Altitude (Ft)": 53,
//       "Country": "Philippines",
//       "Latitude": 124.70700073242188,
//       "Location": "Camiguin",
//       "Longitude": 9.253520011901855,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 6013,
//       "Airport Code [ICAO]": "RPMJ",
//       "Airport Code iATA,FAA": "JOL",
//       "Airport Name": "Jolo Airport",
//       "Altitude (Ft)": 118,
//       "Country": "Philippines",
//       "Latitude": 121.01100158691406,
//       "Location": "Jolo",
//       "Longitude": 6.0536699295043945,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 6014,
//       "Airport Code [ICAO]": "RPMN",
//       "Airport Code iATA,FAA": "TWT",
//       "Airport Name": "Sanga Sanga Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Philippines",
//       "Latitude": 119.742996,
//       "Location": "Sanga Sanga",
//       "Longitude": 5.046991,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 6016,
//       "Airport Code [ICAO]": "RPMS",
//       "Airport Code iATA,FAA": "SUG",
//       "Airport Name": "Surigao Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Philippines",
//       "Latitude": 125.480947495,
//       "Location": "Sangley Point",
//       "Longitude": 9.755838325629998,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 6017,
//       "Airport Code [ICAO]": "RPMW",
//       "Airport Code iATA,FAA": "TDG",
//       "Airport Name": "Tandag Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Philippines",
//       "Latitude": 126.1709976196289,
//       "Location": "Tandag",
//       "Longitude": 9.072110176086426,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 6018,
//       "Airport Code [ICAO]": "RPUN",
//       "Airport Code iATA,FAA": "WNP",
//       "Airport Name": "Naga Airport",
//       "Altitude (Ft)": 142,
//       "Country": "Philippines",
//       "Latitude": 123.2699966430664,
//       "Location": "Naga",
//       "Longitude": 13.58489990234375,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 6019,
//       "Airport Code [ICAO]": "RPUO",
//       "Airport Code iATA,FAA": "BSO",
//       "Airport Name": "Basco Airport",
//       "Altitude (Ft)": 291,
//       "Country": "Philippines",
//       "Latitude": 121.980003,
//       "Location": "Basco",
//       "Longitude": 20.4513,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 6020,
//       "Airport Code [ICAO]": "RPUS",
//       "Airport Code iATA,FAA": "SFE",
//       "Airport Name": "San Fernando Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Philippines",
//       "Latitude": 120.3030014038086,
//       "Location": "San Fernando",
//       "Longitude": 16.595600128173828,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 6021,
//       "Airport Code [ICAO]": "RPUT",
//       "Airport Code iATA,FAA": "TUG",
//       "Airport Name": "Tuguegarao Airport",
//       "Altitude (Ft)": 70,
//       "Country": "Philippines",
//       "Latitude": 121.733150482,
//       "Location": "Tuguegarao",
//       "Longitude": 17.6433676823,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 6022,
//       "Airport Code [ICAO]": "RPUV",
//       "Airport Code iATA,FAA": "VRC",
//       "Airport Name": "Virac Airport",
//       "Altitude (Ft)": 121,
//       "Country": "Philippines",
//       "Latitude": 124.20600128173828,
//       "Location": "Virac",
//       "Longitude": 13.576399803161621,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 6023,
//       "Airport Code [ICAO]": "RPVC",
//       "Airport Code iATA,FAA": "CYP",
//       "Airport Name": "Calbayog Airport",
//       "Altitude (Ft)": 12,
//       "Country": "Philippines",
//       "Latitude": 124.54499816894531,
//       "Location": "Calbayog City",
//       "Longitude": 12.072699546813965,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 6024,
//       "Airport Code [ICAO]": "RPVF",
//       "Airport Code iATA,FAA": "CRM",
//       "Airport Name": "Catarman National Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Philippines",
//       "Latitude": 124.63600158691406,
//       "Location": "Catarman",
//       "Longitude": 12.502400398254395,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 6025,
//       "Airport Code [ICAO]": "RPVJ",
//       "Airport Code iATA,FAA": "MBT",
//       "Airport Name": "Moises R. Espinosa Airport",
//       "Altitude (Ft)": 49,
//       "Country": "Philippines",
//       "Latitude": 123.630095,
//       "Location": "Masbate",
//       "Longitude": 12.369682,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 6026,
//       "Airport Code [ICAO]": "RPVR",
//       "Airport Code iATA,FAA": "RXS",
//       "Airport Name": "Roxas Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Philippines",
//       "Latitude": 122.75199890136719,
//       "Location": "Roxas City",
//       "Longitude": 11.597700119018555,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 6027,
//       "Airport Code [ICAO]": "SAST",
//       "Airport Code iATA,FAA": "TTG",
//       "Airport Name": "General Enrique Mosconi Airport",
//       "Altitude (Ft)": 1472,
//       "Country": "Argentina",
//       "Latitude": -63.7937011719,
//       "Location": "Tartagal",
//       "Longitude": -22.619600296,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Salta",
//       "Type": "airport"
//     },
//     {
//       "ID": 6028,
//       "Airport Code [ICAO]": "SAVH",
//       "Airport Code iATA,FAA": "LHS",
//       "Airport Name": "Las Heras Airport",
//       "Altitude (Ft)": 1082,
//       "Country": "Argentina",
//       "Latitude": -68.9653015137,
//       "Location": "Las Heras",
//       "Longitude": -46.53829956049999,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Rio_Gallegos",
//       "Type": "airport"
//     },
//     {
//       "ID": 6029,
//       "Airport Code [ICAO]": "SAVN",
//       "Airport Code iATA,FAA": "OES",
//       "Airport Name": "Antoine de Saint Exupéry Airport",
//       "Altitude (Ft)": 85,
//       "Country": "Argentina",
//       "Latitude": -65.0343,
//       "Location": "San Antonio Oeste",
//       "Longitude": -40.7512,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Salta",
//       "Type": "airport"
//     },
//     {
//       "ID": 6030,
//       "Airport Code [ICAO]": "SAWA",
//       "Airport Code iATA,FAA": "ING",
//       "Airport Name": "Lago Argentino Airport",
//       "Altitude (Ft)": 732,
//       "Country": "Argentina",
//       "Latitude": -72.248596,
//       "Location": "El Calafate",
//       "Longitude": -50.336102,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Rio_Gallegos",
//       "Type": "airport"
//     },
//     {
//       "ID": 6031,
//       "Airport Code [ICAO]": "SAWR",
//       "Airport Code iATA,FAA": "GGS",
//       "Airport Name": "Gobernador Gregores Airport",
//       "Altitude (Ft)": 356,
//       "Country": "Argentina",
//       "Latitude": -70.150002,
//       "Location": "Gobernador Gregores",
//       "Longitude": -48.7831,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Rio_Gallegos",
//       "Type": "airport"
//     },
//     {
//       "ID": 6032,
//       "Airport Code [ICAO]": "SAZL",
//       "Airport Code iATA,FAA": "SST",
//       "Airport Name": "Santa Teresita Airport",
//       "Altitude (Ft)": 9,
//       "Country": "Argentina",
//       "Latitude": -56.7218,
//       "Location": "Santa Teresita",
//       "Longitude": -36.5423,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Buenos_Aires",
//       "Type": "airport"
//     },
//     {
//       "ID": 6033,
//       "Airport Code [ICAO]": "SAZO",
//       "Airport Code iATA,FAA": "NEC",
//       "Airport Name": "Necochea Airport",
//       "Altitude (Ft)": 72,
//       "Country": "Argentina",
//       "Latitude": -58.8172,
//       "Location": "Necochea",
//       "Longitude": -38.4831,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Buenos_Aires",
//       "Type": "airport"
//     },
//     {
//       "ID": 6034,
//       "Airport Code [ICAO]": "SBJU",
//       "Airport Code iATA,FAA": "JDO",
//       "Airport Name": "Orlando Bezerra de Menezes Airport",
//       "Altitude (Ft)": 1392,
//       "Country": "Brazil",
//       "Latitude": -39.270099639899996,
//       "Location": "Juazeiro Do Norte",
//       "Longitude": -7.21895980835,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 6036,
//       "Airport Code [ICAO]": "SBLE",
//       "Airport Code iATA,FAA": "LEC",
//       "Airport Name": "Coronel Horácio de Mattos Airport",
//       "Altitude (Ft)": 1676,
//       "Country": "Brazil",
//       "Latitude": -41.2770004272,
//       "Location": "Lençóis",
//       "Longitude": -12.4822998047,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 6037,
//       "Airport Code [ICAO]": "SBME",
//       "Airport Code iATA,FAA": "MEA",
//       "Airport Name": "Macaé Airport",
//       "Altitude (Ft)": 8,
//       "Country": "Brazil",
//       "Latitude": -41.7659988403,
//       "Location": "Macaé",
//       "Longitude": -22.343000412,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 6038,
//       "Airport Code [ICAO]": "SBML",
//       "Airport Code iATA,FAA": "MII",
//       "Airport Name": "Frank Miloye Milenkowichi–Marília State Airport",
//       "Altitude (Ft)": 2122,
//       "Country": "Brazil",
//       "Latitude": -49.926399231,
//       "Location": "Marília",
//       "Longitude": -22.1968994141,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 6039,
//       "Airport Code [ICAO]": "SBQV",
//       "Airport Code iATA,FAA": "VDC",
//       "Airport Name": "Vitória da Conquista Airport",
//       "Altitude (Ft)": 3002,
//       "Country": "Brazil",
//       "Latitude": -40.8630981445,
//       "Location": "Vitória Da Conquista",
//       "Longitude": -14.8627996445,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 6040,
//       "Airport Code [ICAO]": "SBSM",
//       "Airport Code iATA,FAA": "RIA",
//       "Airport Name": "Santa Maria Airport",
//       "Altitude (Ft)": 287,
//       "Country": "Brazil",
//       "Latitude": -53.688202,
//       "Location": "Santa Maria",
//       "Longitude": -29.711399,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 6041,
//       "Airport Code [ICAO]": "SBTD",
//       "Airport Code iATA,FAA": "TOW",
//       "Airport Name": "Toledo Airport",
//       "Altitude (Ft)": 1843,
//       "Country": "Brazil",
//       "Latitude": -53.697498,
//       "Location": "Toledo",
//       "Longitude": -24.6863,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 6042,
//       "Airport Code [ICAO]": "SCES",
//       "Airport Code iATA,FAA": "ESR",
//       "Airport Name": "Ricardo García Posada Airport",
//       "Altitude (Ft)": 5240,
//       "Country": "Chile",
//       "Latitude": -69.76519775390625,
//       "Location": "El Salvador",
//       "Longitude": -26.311100006103516,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 6043,
//       "Airport Code [ICAO]": "SCPC",
//       "Airport Code iATA,FAA": "ZPC",
//       "Airport Name": "Pucón Airport",
//       "Altitude (Ft)": 853,
//       "Country": "Chile",
//       "Latitude": -71.91590118408203,
//       "Location": "Pucon",
//       "Longitude": -39.29280090332031,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 6044,
//       "Airport Code [ICAO]": "SDCO",
//       "Airport Code iATA,FAA": "SOD",
//       "Airport Name": "Sorocaba Airport",
//       "Altitude (Ft)": 2077,
//       "Country": "Brazil",
//       "Latitude": -47.490002,
//       "Location": "Sorocaba",
//       "Longitude": -23.478001,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 6045,
//       "Airport Code [ICAO]": "SEST",
//       "Airport Code iATA,FAA": "SCY",
//       "Airport Name": "San Cristóbal Airport",
//       "Altitude (Ft)": 62,
//       "Country": "Ecuador",
//       "Latitude": -89.617401,
//       "Location": "San Cristóbal",
//       "Longitude": -0.910206,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Galapagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 6046,
//       "Airport Code [ICAO]": "SETM",
//       "Airport Code iATA,FAA": "LOH",
//       "Airport Name": "Camilo Ponce Enriquez Airport",
//       "Altitude (Ft)": 4056,
//       "Country": "Ecuador",
//       "Latitude": -79.371902,
//       "Location": "La Toma (Catamayo)",
//       "Longitude": -3.99589,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 6047,
//       "Airport Code [ICAO]": "SETN",
//       "Airport Code iATA,FAA": "ESM",
//       "Airport Name": "General Rivadeneira Airport",
//       "Altitude (Ft)": 32,
//       "Country": "Ecuador",
//       "Latitude": -79.62660217285156,
//       "Location": "Esmeraldas",
//       "Longitude": 0.9785190224647522,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 6048,
//       "Airport Code [ICAO]": "SFAL",
//       "Airport Code iATA,FAA": "PSY",
//       "Airport Name": "Port Stanley Airport",
//       "Altitude (Ft)": 75,
//       "Country": "Falkland Islands",
//       "Latitude": -57.777599334717,
//       "Location": "Stanley",
//       "Longitude": -51.685699462891,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "Atlantic/Stanley",
//       "Type": "airport"
//     },
//     {
//       "ID": 6049,
//       "Airport Code [ICAO]": "SKGO",
//       "Airport Code iATA,FAA": "CRC",
//       "Airport Name": "Santa Ana Airport",
//       "Altitude (Ft)": 2979,
//       "Country": "Colombia",
//       "Latitude": -75.9557,
//       "Location": "Cartago",
//       "Longitude": 4.75818,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 6050,
//       "Airport Code [ICAO]": "SKGZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "La Jagua Airport",
//       "Altitude (Ft)": 2620,
//       "Country": "Colombia",
//       "Latitude": -75.6944,
//       "Location": "Garzón",
//       "Longitude": 2.1464,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 6051,
//       "Airport Code [ICAO]": "SKLG",
//       "Airport Code iATA,FAA": "LQM",
//       "Airport Name": "Caucaya Airport",
//       "Altitude (Ft)": 573,
//       "Country": "Colombia",
//       "Latitude": -74.7708,
//       "Location": "Puerto Leguízamo",
//       "Longitude": -0.182278,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 6052,
//       "Airport Code [ICAO]": "SKLP",
//       "Airport Code iATA,FAA": "LPD",
//       "Airport Name": "La Pedrera Airport",
//       "Altitude (Ft)": 590,
//       "Country": "Colombia",
//       "Latitude": -69.5797,
//       "Location": "La Pedrera",
//       "Longitude": -1.32861,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 6053,
//       "Airport Code [ICAO]": "SKNQ",
//       "Airport Code iATA,FAA": "NQU",
//       "Airport Name": "Reyes Murillo Airport",
//       "Altitude (Ft)": 12,
//       "Country": "Colombia",
//       "Latitude": -77.2806,
//       "Location": "Nuquí",
//       "Longitude": 5.6964,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 6054,
//       "Airport Code [ICAO]": "SKPD",
//       "Airport Code iATA,FAA": "PDA",
//       "Airport Name": "Obando Airport",
//       "Altitude (Ft)": 460,
//       "Country": "Colombia",
//       "Latitude": -67.9062,
//       "Location": "Puerto Inírida",
//       "Longitude": 3.85353,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 6055,
//       "Airport Code [ICAO]": "SKYP",
//       "Airport Code iATA,FAA": "EYP",
//       "Airport Name": "El Yopal Airport",
//       "Altitude (Ft)": 1028,
//       "Country": "Colombia",
//       "Latitude": -72.384,
//       "Location": "Yopal",
//       "Longitude": 5.31911,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 6056,
//       "Airport Code [ICAO]": "SLGY",
//       "Airport Code iATA,FAA": "GYA",
//       "Airport Name": "Capitán de Av. Emilio Beltrán Airport",
//       "Altitude (Ft)": 557,
//       "Country": "Bolivia",
//       "Latitude": -65.3455963135,
//       "Location": "Guayaramerín",
//       "Longitude": -10.820599556,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/La_Paz",
//       "Type": "airport"
//     },
//     {
//       "ID": 6057,
//       "Airport Code [ICAO]": "SLPR",
//       "Airport Code iATA,FAA": "PUR",
//       "Airport Name": "Puerto Rico Airport",
//       "Altitude (Ft)": 597,
//       "Country": "Bolivia",
//       "Latitude": -67.551155,
//       "Location": "Puerto Rico/Manuripi",
//       "Longitude": -11.107663,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/La_Paz",
//       "Type": "airport"
//     },
//     {
//       "ID": 6058,
//       "Airport Code [ICAO]": "SLRI",
//       "Airport Code iATA,FAA": "RIB",
//       "Airport Name": "Capitán Av. Selin Zeitun Lopez Airport",
//       "Altitude (Ft)": 462,
//       "Country": "Bolivia",
//       "Latitude": -66,
//       "Location": "Riberalta",
//       "Longitude": -11,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/La_Paz",
//       "Type": "airport"
//     },
//     {
//       "ID": 6059,
//       "Airport Code [ICAO]": "SLRY",
//       "Airport Code iATA,FAA": "REY",
//       "Airport Name": "Reyes Airport",
//       "Altitude (Ft)": 935,
//       "Country": "Bolivia",
//       "Latitude": -67.353401,
//       "Location": "Reyes",
//       "Longitude": -14.3044,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/La_Paz",
//       "Type": "airport"
//     },
//     {
//       "ID": 6060,
//       "Airport Code [ICAO]": "SLSB",
//       "Airport Code iATA,FAA": "SRJ",
//       "Airport Name": "Capitán Av. German Quiroga G. Airport",
//       "Altitude (Ft)": 633,
//       "Country": "Bolivia",
//       "Latitude": -66.73750305175781,
//       "Location": "San Borja",
//       "Longitude": -14.859199523925781,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/La_Paz",
//       "Type": "airport"
//     },
//     {
//       "ID": 6061,
//       "Airport Code [ICAO]": "SMZO",
//       "Airport Code iATA,FAA": "ORG",
//       "Airport Name": "Zorg en Hoop Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Suriname",
//       "Latitude": -55.190701,
//       "Location": "Paramaribo",
//       "Longitude": 5.81108,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "America/Paramaribo",
//       "Type": "airport"
//     },
//     {
//       "ID": 6062,
//       "Airport Code [ICAO]": "SNMU",
//       "Airport Code iATA,FAA": "MVS",
//       "Airport Name": "Mucuri Airport",
//       "Altitude (Ft)": 276,
//       "Country": "Brazil",
//       "Latitude": -39.864200592041016,
//       "Location": "Mucuri",
//       "Longitude": -18.048900604248047,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 6065,
//       "Airport Code [ICAO]": "SPBC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Caballococha Airport",
//       "Altitude (Ft)": 328,
//       "Country": "Peru",
//       "Latitude": -70.5082015991,
//       "Location": "Caballococha",
//       "Longitude": -3.91686010361,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 6066,
//       "Airport Code [ICAO]": "SPJR",
//       "Airport Code iATA,FAA": "CJA",
//       "Airport Name": "Mayor General FAP Armando Revoredo Iglesias Airport",
//       "Altitude (Ft)": 8781,
//       "Country": "Peru",
//       "Latitude": -78.4894027709961,
//       "Location": "Cajamarca",
//       "Longitude": -7.1391801834106445,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 6067,
//       "Airport Code [ICAO]": "SPNC",
//       "Airport Code iATA,FAA": "HUU",
//       "Airport Name": "Alferez Fap David Figueroa Fernandini Airport",
//       "Altitude (Ft)": 6070,
//       "Country": "Peru",
//       "Latitude": -76.20480346679688,
//       "Location": "Huánuco",
//       "Longitude": -9.878809928894043,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 6068,
//       "Airport Code [ICAO]": "SPZA",
//       "Airport Code iATA,FAA": "NZC",
//       "Airport Name": "Maria Reiche Neuman Airport",
//       "Altitude (Ft)": 1860,
//       "Country": "Peru",
//       "Latitude": -74.9615020752,
//       "Location": "Nazca",
//       "Longitude": -14.854000091600001,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 6069,
//       "Airport Code [ICAO]": "SSZR",
//       "Airport Code iATA,FAA": "SRA",
//       "Airport Name": "Santa Rosa Airport",
//       "Altitude (Ft)": 984,
//       "Country": "Brazil",
//       "Latitude": -54.520401,
//       "Location": "Santa Rosa",
//       "Longitude": -27.9067,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 6070,
//       "Airport Code [ICAO]": "SUPE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "El Jagüel / Punta del Este Airport",
//       "Altitude (Ft)": 66,
//       "Country": "Uruguay",
//       "Latitude": -54.916999816899995,
//       "Location": "Maldonado",
//       "Longitude": -34.9169998169,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Montevideo",
//       "Type": "airport"
//     },
//     {
//       "ID": 6071,
//       "Airport Code [ICAO]": "SVBS",
//       "Airport Code iATA,FAA": "MYC",
//       "Airport Name": "Escuela Mariscal Sucre Airport",
//       "Altitude (Ft)": 1338,
//       "Country": "Venezuela",
//       "Latitude": -67.64942169189453,
//       "Location": "Maracay",
//       "Longitude": 10.249978065490723,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 6072,
//       "Airport Code [ICAO]": "SVVG",
//       "Airport Code iATA,FAA": "VIG",
//       "Airport Name": "Juan Pablo Pérez Alfonso Airport",
//       "Altitude (Ft)": 250,
//       "Country": "Venezuela",
//       "Latitude": -71.672668,
//       "Location": "El Vigía",
//       "Longitude": 8.624139,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 6073,
//       "Airport Code [ICAO]": "SWJI",
//       "Airport Code iATA,FAA": "JPR",
//       "Airport Name": "Ji-Paraná Airport",
//       "Altitude (Ft)": 598,
//       "Country": "Brazil",
//       "Latitude": -61.8465003967,
//       "Location": "Ji-Paraná",
//       "Longitude": -10.870800018299999,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 6074,
//       "Airport Code [ICAO]": "TAPH",
//       "Airport Code iATA,FAA": "BBQ",
//       "Airport Name": "Codrington Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Antigua and Barbuda",
//       "Latitude": -61.828602,
//       "Location": "Codrington",
//       "Longitude": 17.635799,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Antigua",
//       "Type": "airport"
//     },
//     {
//       "ID": 6075,
//       "Airport Code [ICAO]": "TFFA",
//       "Airport Code iATA,FAA": "DSD",
//       "Airport Name": "La Désirade Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Guadeloupe",
//       "Latitude": -61.0844,
//       "Location": "Grande Anse",
//       "Longitude": 16.296902,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Guadeloupe",
//       "Type": "airport"
//     },
//     {
//       "ID": 6076,
//       "Airport Code [ICAO]": "TFFB",
//       "Airport Code iATA,FAA": "BBR",
//       "Airport Name": "Baillif Airport",
//       "Altitude (Ft)": 59,
//       "Country": "Guadeloupe",
//       "Latitude": -61.7421989440918,
//       "Location": "Basse Terre",
//       "Longitude": 16.0132999420166,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Guadeloupe",
//       "Type": "airport"
//     },
//     {
//       "ID": 6077,
//       "Airport Code [ICAO]": "TFFC",
//       "Airport Code iATA,FAA": "SFC",
//       "Airport Name": "St-François Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Guadeloupe",
//       "Latitude": -61.26250076293945,
//       "Location": "St-François",
//       "Longitude": 16.25779914855957,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Guadeloupe",
//       "Type": "airport"
//     },
//     {
//       "ID": 6078,
//       "Airport Code [ICAO]": "TFFM",
//       "Airport Code iATA,FAA": "GBJ",
//       "Airport Name": "Les Bases Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Guadeloupe",
//       "Latitude": -61.27000045776367,
//       "Location": "Grand Bourg",
//       "Longitude": 15.86870002746582,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Guadeloupe",
//       "Type": "airport"
//     },
//     {
//       "ID": 6079,
//       "Airport Code [ICAO]": "TKPN",
//       "Airport Code iATA,FAA": "NEV",
//       "Airport Name": "Vance W. Amory International Airport",
//       "Altitude (Ft)": 14,
//       "Country": "Saint Kitts and Nevis",
//       "Latitude": -62.589900970458984,
//       "Location": "Charlestown",
//       "Longitude": 17.205699920654297,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/St_Kitts",
//       "Type": "airport"
//     },
//     {
//       "ID": 6080,
//       "Airport Code [ICAO]": "TUPW",
//       "Airport Code iATA,FAA": "VIJ",
//       "Airport Name": "Virgin Gorda Airport",
//       "Altitude (Ft)": 9,
//       "Country": "British Virgin Islands",
//       "Latitude": -64.42749786376953,
//       "Location": "Spanish Town",
//       "Longitude": 18.446399688720703,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Tortola",
//       "Type": "airport"
//     },
//     {
//       "ID": 6081,
//       "Airport Code [ICAO]": "TVSB",
//       "Airport Code iATA,FAA": "BQU",
//       "Airport Name": "J F Mitchell Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Saint Vincent and the Grenadines",
//       "Latitude": -61.2620010376,
//       "Location": "Bequia",
//       "Longitude": 12.9884004593,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/St_Vincent",
//       "Type": "airport"
//     },
//     {
//       "ID": 6082,
//       "Airport Code [ICAO]": "TVSU",
//       "Airport Code iATA,FAA": "UNI",
//       "Airport Name": "Union Island International Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Saint Vincent and the Grenadines",
//       "Latitude": -61.41194534301758,
//       "Location": "Union Island",
//       "Longitude": 12.60013484954834,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/St_Vincent",
//       "Type": "airport"
//     },
//     {
//       "ID": 6083,
//       "Airport Code [ICAO]": "UACK",
//       "Airport Code iATA,FAA": "KOV",
//       "Airport Name": "Kokshetau Airport",
//       "Altitude (Ft)": 900,
//       "Country": "Kazakhstan",
//       "Latitude": 69.594597,
//       "Location": "Kokshetau",
//       "Longitude": 53.329102,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Qyzylorda",
//       "Type": "airport"
//     },
//     {
//       "ID": 6084,
//       "Airport Code [ICAO]": "UACP",
//       "Airport Code iATA,FAA": "PPK",
//       "Airport Name": "Petropavlosk South Airport",
//       "Altitude (Ft)": 453,
//       "Country": "Kazakhstan",
//       "Latitude": 69.18389892578125,
//       "Location": "Petropavlosk",
//       "Longitude": 54.77470016479492,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Qyzylorda",
//       "Type": "airport"
//     },
//     {
//       "ID": 6085,
//       "Airport Code [ICAO]": "UAKD",
//       "Airport Code iATA,FAA": "DZN",
//       "Airport Name": "Zhezkazgan Airport",
//       "Altitude (Ft)": 1250,
//       "Country": "Kazakhstan",
//       "Latitude": 67.733299,
//       "Location": "Zhezkazgan",
//       "Longitude": 47.708302,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Qyzylorda",
//       "Type": "airport"
//     },
//     {
//       "ID": 6086,
//       "Airport Code [ICAO]": "UASK",
//       "Airport Code iATA,FAA": "UKK",
//       "Airport Name": "Ust-Kamennogorsk Airport",
//       "Altitude (Ft)": 939,
//       "Country": "Kazakhstan",
//       "Latitude": 82.49420166015625,
//       "Location": "Ust Kamenogorsk",
//       "Longitude": 50.036598205566406,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Qyzylorda",
//       "Type": "airport"
//     },
//     {
//       "ID": 6088,
//       "Airport Code [ICAO]": "UAUU",
//       "Airport Code iATA,FAA": "KSN",
//       "Airport Name": "Kostanay West Airport",
//       "Altitude (Ft)": 595,
//       "Country": "Kazakhstan",
//       "Latitude": 63.55030059814453,
//       "Location": "Kostanay",
//       "Longitude": 53.20690155029297,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Qyzylorda",
//       "Type": "airport"
//     },
//     {
//       "ID": 6089,
//       "Airport Code [ICAO]": "UBBG",
//       "Airport Code iATA,FAA": "KVD",
//       "Airport Name": "Ganja Airport",
//       "Altitude (Ft)": 1083,
//       "Country": "Azerbaijan",
//       "Latitude": 46.31760025024414,
//       "Location": "Ganja",
//       "Longitude": 40.737701416015625,
//       "GMT Offset": "4",
//       "Daylight Savings": "E",
//       "Region": "Asia/Baku",
//       "Type": "airport"
//     },
//     {
//       "ID": 6090,
//       "Airport Code [ICAO]": "UBBN",
//       "Airport Code iATA,FAA": "NAJ",
//       "Airport Name": "Nakhchivan Airport",
//       "Altitude (Ft)": 2863,
//       "Country": "Azerbaijan",
//       "Latitude": 45.45840072631836,
//       "Location": "Nakhchivan",
//       "Longitude": 39.18880081176758,
//       "GMT Offset": "4",
//       "Daylight Savings": "E",
//       "Region": "Asia/Baku",
//       "Type": "airport"
//     },
//     {
//       "ID": 6091,
//       "Airport Code [ICAO]": "UELL",
//       "Airport Code iATA,FAA": "NER",
//       "Airport Name": "Chulman Airport",
//       "Altitude (Ft)": 2812,
//       "Country": "Russia",
//       "Latitude": 124.91400146484,
//       "Location": "Neryungri",
//       "Longitude": 56.913898468018,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yakutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 6092,
//       "Airport Code [ICAO]": "UERP",
//       "Airport Code iATA,FAA": "PYJ",
//       "Airport Name": "Polyarny Airport",
//       "Altitude (Ft)": 1660,
//       "Country": "Russia",
//       "Latitude": 112.029998779,
//       "Location": "Yakutia",
//       "Longitude": 66.4003982544,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yakutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 6093,
//       "Airport Code [ICAO]": "UESO",
//       "Airport Code iATA,FAA": "CKH",
//       "Airport Name": "Chokurdakh Airport",
//       "Altitude (Ft)": 151,
//       "Country": "Russia",
//       "Latitude": 147.90199279785156,
//       "Location": "Chokurdah",
//       "Longitude": 70.62310028076172,
//       "GMT Offset": "11",
//       "Daylight Savings": "N",
//       "Region": "Asia/Srednekolymsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 6094,
//       "Airport Code [ICAO]": "UESS",
//       "Airport Code iATA,FAA": "CYX",
//       "Airport Name": "Cherskiy Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Russia",
//       "Latitude": 161.33799743652344,
//       "Location": "Cherskiy",
//       "Longitude": 68.7406005859375,
//       "GMT Offset": "11",
//       "Daylight Savings": "N",
//       "Region": "Asia/Srednekolymsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 6095,
//       "Airport Code [ICAO]": "UEST",
//       "Airport Code iATA,FAA": "IKS",
//       "Airport Name": "Tiksi Airport",
//       "Altitude (Ft)": 26,
//       "Country": "Russia",
//       "Latitude": 128.90299987793,
//       "Location": "Tiksi",
//       "Longitude": 71.697700500488,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yakutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 6097,
//       "Airport Code [ICAO]": "UHKK",
//       "Airport Code iATA,FAA": "KXK",
//       "Airport Name": "Komsomolsk-on-Amur Airport",
//       "Altitude (Ft)": 92,
//       "Country": "Russia",
//       "Latitude": 136.9340057373047,
//       "Location": "Komsomolsk-on-Amur",
//       "Longitude": 50.409000396728516,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": "Asia/Vladivostok",
//       "Type": "airport"
//     },
//     {
//       "ID": 6098,
//       "Airport Code [ICAO]": "UHMA",
//       "Airport Code iATA,FAA": "DYR",
//       "Airport Name": "Ugolny Airport",
//       "Altitude (Ft)": 194,
//       "Country": "Russia",
//       "Latitude": 177.740997,
//       "Location": "Anadyr",
//       "Longitude": 64.734902,
//       "GMT Offset": "12",
//       "Daylight Savings": "N",
//       "Region": "Asia/Anadyr",
//       "Type": "airport"
//     },
//     {
//       "ID": 6099,
//       "Airport Code [ICAO]": "UHOO",
//       "Airport Code iATA,FAA": "OHO",
//       "Airport Name": "Okhotsk Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Russia",
//       "Latitude": 143.05650329589844,
//       "Location": "Okhotsk",
//       "Longitude": 59.410064697265625,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": "Asia/Vladivostok",
//       "Type": "airport"
//     },
//     {
//       "ID": 6100,
//       "Airport Code [ICAO]": "UJAP",
//       "Airport Code iATA,FAA": "UJE",
//       "Airport Name": "Ujae Atoll Airport",
//       "Altitude (Ft)": 29,
//       "Country": "Marshall Islands",
//       "Latitude": 165.761993408,
//       "Location": "Ujae Atoll",
//       "Longitude": 8.92805957794,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Majuro",
//       "Type": "airport"
//     },
//     {
//       "ID": 6101,
//       "Airport Code [ICAO]": "UKCM",
//       "Airport Code iATA,FAA": "MPW",
//       "Airport Name": "Mariupol International Airport",
//       "Altitude (Ft)": 251,
//       "Country": "Ukraine",
//       "Latitude": 37.44960021972656,
//       "Location": "Mariupol International",
//       "Longitude": 47.07609939575195,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 6102,
//       "Airport Code [ICAO]": "UKCW",
//       "Airport Code iATA,FAA": "VSG",
//       "Airport Name": "Luhansk International Airport",
//       "Altitude (Ft)": 636,
//       "Country": "Ukraine",
//       "Latitude": 39.3740997314,
//       "Location": "Lugansk",
//       "Longitude": 48.4174003601,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 6103,
//       "Airport Code [ICAO]": "UKDE",
//       "Airport Code iATA,FAA": "OZH",
//       "Airport Name": "Zaporizhzhia International Airport",
//       "Altitude (Ft)": 373,
//       "Country": "Ukraine",
//       "Latitude": 35.31570053100586,
//       "Location": "Zaporozhye",
//       "Longitude": 47.867000579833984,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 6104,
//       "Airport Code [ICAO]": "UKDR",
//       "Airport Code iATA,FAA": "KWG",
//       "Airport Name": "Kryvyi Rih International Airport",
//       "Altitude (Ft)": 408,
//       "Country": "Ukraine",
//       "Latitude": 33.209999084472656,
//       "Location": "Krivoy Rog",
//       "Longitude": 48.04330062866211,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 6105,
//       "Airport Code [ICAO]": "UKHH",
//       "Airport Code iATA,FAA": "HRK",
//       "Airport Name": "Kharkiv International Airport",
//       "Altitude (Ft)": 508,
//       "Country": "Ukraine",
//       "Latitude": 36.290000915527344,
//       "Location": "Kharkov",
//       "Longitude": 49.924800872802734,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 6106,
//       "Airport Code [ICAO]": "UKLI",
//       "Airport Code iATA,FAA": "IFO",
//       "Airport Name": "Ivano-Frankivsk International Airport",
//       "Altitude (Ft)": 919,
//       "Country": "Ukraine",
//       "Latitude": 24.686100006103516,
//       "Location": "Ivano-Frankivsk",
//       "Longitude": 48.88420104980469,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 6107,
//       "Airport Code [ICAO]": "UKLN",
//       "Airport Code iATA,FAA": "CWC",
//       "Airport Name": "Chernivtsi International Airport",
//       "Altitude (Ft)": 826,
//       "Country": "Ukraine",
//       "Latitude": 25.98080062866211,
//       "Location": "Chernovtsk",
//       "Longitude": 48.259300231933594,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 6108,
//       "Airport Code [ICAO]": "UKLR",
//       "Airport Code iATA,FAA": "RWN",
//       "Airport Name": "Rivne International Airport",
//       "Altitude (Ft)": 755,
//       "Country": "Ukraine",
//       "Latitude": 26.141599655151367,
//       "Location": "Rivne",
//       "Longitude": 50.60710144042969,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 6109,
//       "Airport Code [ICAO]": "UKLU",
//       "Airport Code iATA,FAA": "UDJ",
//       "Airport Name": "Uzhhorod International Airport",
//       "Altitude (Ft)": 383,
//       "Country": "Ukraine",
//       "Latitude": 22.263399124145508,
//       "Location": "Uzhgorod",
//       "Longitude": 48.634300231933594,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 6110,
//       "Airport Code [ICAO]": "ULAS",
//       "Airport Code iATA,FAA": "CSH",
//       "Airport Name": "Solovki Airport",
//       "Altitude (Ft)": 60,
//       "Country": "Russia",
//       "Latitude": 35.7333335876,
//       "Location": "Solovetsky Islands",
//       "Longitude": 65.0299987793,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6111,
//       "Airport Code [ICAO]": "ULBC",
//       "Airport Code iATA,FAA": "CEE",
//       "Airport Name": "Cherepovets Airport",
//       "Altitude (Ft)": 377,
//       "Country": "Russia",
//       "Latitude": 38.015800476100004,
//       "Location": "Cherepovets",
//       "Longitude": 59.273601532,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6112,
//       "Airport Code [ICAO]": "ULDD",
//       "Airport Code iATA,FAA": "AMV",
//       "Airport Name": "Amderma Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Russia",
//       "Latitude": 61.556400299072266,
//       "Location": "Amderma",
//       "Longitude": 69.76329803466797,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6113,
//       "Airport Code [ICAO]": "ULKK",
//       "Airport Code iATA,FAA": "KSZ",
//       "Airport Name": "Kotlas Airport",
//       "Altitude (Ft)": 184,
//       "Country": "Russia",
//       "Latitude": 46.6974983215332,
//       "Location": "Kotlas",
//       "Longitude": 61.235801696777344,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6114,
//       "Airport Code [ICAO]": "ULPB",
//       "Airport Code iATA,FAA": "PES",
//       "Airport Name": "Petrozavodsk Airport",
//       "Altitude (Ft)": 151,
//       "Country": "Russia",
//       "Latitude": 34.154701232910156,
//       "Location": "Petrozavodsk",
//       "Longitude": 61.88520050048828,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6115,
//       "Airport Code [ICAO]": "UMMG",
//       "Airport Code iATA,FAA": "GNA",
//       "Airport Name": "Hrodna Airport",
//       "Altitude (Ft)": 443,
//       "Country": "Belarus",
//       "Latitude": 24.053800582885742,
//       "Location": "Hrodna",
//       "Longitude": 53.60200119018555,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Minsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 6116,
//       "Airport Code [ICAO]": "UMOO",
//       "Airport Code iATA,FAA": "MVQ",
//       "Airport Name": "Mogilev Airport",
//       "Altitude (Ft)": 637,
//       "Country": "Belarus",
//       "Latitude": 30.09510040283203,
//       "Location": "Mogilev",
//       "Longitude": 53.954898834228516,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Minsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 6117,
//       "Airport Code [ICAO]": "UNII",
//       "Airport Code iATA,FAA": "EIE",
//       "Airport Name": "Yeniseysk Airport",
//       "Altitude (Ft)": 253,
//       "Country": "Russia",
//       "Latitude": 92.11250305175781,
//       "Location": "Yeniseysk",
//       "Longitude": 58.47420120239258,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Krasnoyarsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 6118,
//       "Airport Code [ICAO]": "UNKY",
//       "Airport Code iATA,FAA": "KYZ",
//       "Airport Name": "Kyzyl Airport",
//       "Altitude (Ft)": 2123,
//       "Country": "Russia",
//       "Latitude": 94.40059661865234,
//       "Location": "Kyzyl",
//       "Longitude": 51.66939926147461,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Krasnoyarsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 6119,
//       "Airport Code [ICAO]": "UNWW",
//       "Airport Code iATA,FAA": "NOZ",
//       "Airport Name": "Spichenkovo Airport",
//       "Altitude (Ft)": 1024,
//       "Country": "Russia",
//       "Latitude": 86.877197,
//       "Location": "Novokuznetsk",
//       "Longitude": 53.811401,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Krasnoyarsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 6120,
//       "Airport Code [ICAO]": "UOHH",
//       "Airport Code iATA,FAA": "HTG",
//       "Airport Name": "Khatanga Airport",
//       "Altitude (Ft)": 95,
//       "Country": "Russia",
//       "Latitude": 102.49099731445312,
//       "Location": "Khatanga",
//       "Longitude": 71.97810363769531,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Krasnoyarsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 6121,
//       "Airport Code [ICAO]": "UOII",
//       "Airport Code iATA,FAA": "IAA",
//       "Airport Name": "Igarka Airport",
//       "Altitude (Ft)": 82,
//       "Country": "Russia",
//       "Latitude": 86.62190246582031,
//       "Location": "Igarka",
//       "Longitude": 67.43720245361328,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Krasnoyarsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 6122,
//       "Airport Code [ICAO]": "URMG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Khankala Air Base",
//       "Altitude (Ft)": 548,
//       "Country": "Russia",
//       "Latitude": 45.78409957885742,
//       "Location": "Grozny",
//       "Longitude": 43.298099517822266,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6123,
//       "Airport Code [ICAO]": "URMN",
//       "Airport Code iATA,FAA": "NAL",
//       "Airport Name": "Nalchik Airport",
//       "Altitude (Ft)": 1461,
//       "Country": "Russia",
//       "Latitude": 43.636600494384766,
//       "Location": "Nalchik",
//       "Longitude": 43.512901306152344,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6124,
//       "Airport Code [ICAO]": "URMO",
//       "Airport Code iATA,FAA": "OGZ",
//       "Airport Name": "Beslan Airport",
//       "Altitude (Ft)": 1673,
//       "Country": "Russia",
//       "Latitude": 44.6066017151,
//       "Location": "Beslan",
//       "Longitude": 43.2051010132,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6125,
//       "Airport Code [ICAO]": "URWI",
//       "Airport Code iATA,FAA": "ESL",
//       "Airport Name": "Elista Airport",
//       "Altitude (Ft)": 501,
//       "Country": "Russia",
//       "Latitude": 44.33089828491211,
//       "Location": "Elista",
//       "Longitude": 46.3739013671875,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6126,
//       "Airport Code [ICAO]": "5A8",
//       "Airport Code iATA,FAA": "WKK",
//       "Airport Name": "Aleknagik / New Airport",
//       "Altitude (Ft)": 66,
//       "Country": "United States",
//       "Latitude": -158.617996216,
//       "Location": "Aleknagik",
//       "Longitude": 59.2826004028,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6128,
//       "Airport Code [ICAO]": "KBLF",
//       "Airport Code iATA,FAA": "BLF",
//       "Airport Name": "Mercer County Airport",
//       "Altitude (Ft)": 2857,
//       "Country": "United States",
//       "Latitude": -81.20770263671875,
//       "Location": "Bluefield",
//       "Longitude": 37.295799255371094,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 6130,
//       "Airport Code [ICAO]": "KGLH",
//       "Airport Code iATA,FAA": "GLH",
//       "Airport Name": "Mid Delta Regional Airport",
//       "Altitude (Ft)": 131,
//       "Country": "United States",
//       "Latitude": -90.98560333251953,
//       "Location": "Greenville",
//       "Longitude": 33.4828987121582,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 6133,
//       "Airport Code [ICAO]": "KPSC",
//       "Airport Code iATA,FAA": "PSC",
//       "Airport Name": "Tri Cities Airport",
//       "Altitude (Ft)": 410,
//       "Country": "United States",
//       "Latitude": -119.11900329589844,
//       "Location": "Pasco",
//       "Longitude": 46.26470184326172,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 6134,
//       "Airport Code [ICAO]": "KQA",
//       "Airport Code iATA,FAA": "KQA",
//       "Airport Name": "Akutan Seaplane Base",
//       "Altitude (Ft)": 0,
//       "Country": "United States",
//       "Latitude": -165.778895617,
//       "Location": "Akutan",
//       "Longitude": 54.1337704415,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6136,
//       "Airport Code [ICAO]": "S31",
//       "Airport Code iATA,FAA": "LPS",
//       "Airport Name": "Lopez Island Airport",
//       "Altitude (Ft)": 209,
//       "Country": "United States",
//       "Latitude": -122.93800354003906,
//       "Location": "Lopez",
//       "Longitude": 48.4838981628418,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 6137,
//       "Airport Code [ICAO]": "USDD",
//       "Airport Code iATA,FAA": "SLY",
//       "Airport Name": "Salekhard Airport",
//       "Altitude (Ft)": 218,
//       "Country": "Russia",
//       "Latitude": 66.61100006103516,
//       "Location": "Salekhard",
//       "Longitude": 66.5907974243164,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 6138,
//       "Airport Code [ICAO]": "USHH",
//       "Airport Code iATA,FAA": "HMA",
//       "Airport Name": "Khanty Mansiysk Airport",
//       "Altitude (Ft)": 76,
//       "Country": "Russia",
//       "Latitude": 69.08609771728516,
//       "Location": "Khanty-Mansiysk",
//       "Longitude": 61.028499603271484,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 6139,
//       "Airport Code [ICAO]": "USHN",
//       "Airport Code iATA,FAA": "NYA",
//       "Airport Name": "Nyagan Airport",
//       "Altitude (Ft)": 361,
//       "Country": "Russia",
//       "Latitude": 65.614998,
//       "Location": "Nyagan",
//       "Longitude": 62.110001,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 6140,
//       "Airport Code [ICAO]": "USHS",
//       "Airport Code iATA,FAA": "OVS",
//       "Airport Name": "Sovetskiy Airport",
//       "Altitude (Ft)": 351,
//       "Country": "Russia",
//       "Latitude": 63.60191345214844,
//       "Location": "Sovetskiy",
//       "Longitude": 61.326622009277344,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 6141,
//       "Airport Code [ICAO]": "USII",
//       "Airport Code iATA,FAA": "IJK",
//       "Airport Name": "Izhevsk Airport",
//       "Altitude (Ft)": 531,
//       "Country": "Russia",
//       "Latitude": 53.45750045776367,
//       "Location": "Izhevsk",
//       "Longitude": 56.82809829711914,
//       "GMT Offset": "4",
//       "Daylight Savings": "N",
//       "Region": "Europe/Samara",
//       "Type": "airport"
//     },
//     {
//       "ID": 6142,
//       "Airport Code [ICAO]": "USKK",
//       "Airport Code iATA,FAA": "KVX",
//       "Airport Name": "Pobedilovo Airport",
//       "Altitude (Ft)": 479,
//       "Country": "Russia",
//       "Latitude": 49.348300933838,
//       "Location": "Kirov",
//       "Longitude": 58.503299713135,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6143,
//       "Airport Code [ICAO]": "USMM",
//       "Airport Code iATA,FAA": "NYM",
//       "Airport Name": "Nadym Airport",
//       "Altitude (Ft)": 49,
//       "Country": "Russia",
//       "Latitude": 72.69889831542969,
//       "Location": "Nadym",
//       "Longitude": 65.48090362548828,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 6144,
//       "Airport Code [ICAO]": "USNR",
//       "Airport Code iATA,FAA": "RAT",
//       "Airport Name": "Raduzhny Airport",
//       "Altitude (Ft)": 250,
//       "Country": "Russia",
//       "Latitude": 77.32890319820001,
//       "Location": "Raduzhnyi",
//       "Longitude": 62.1585998535,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 6145,
//       "Airport Code [ICAO]": "USRN",
//       "Airport Code iATA,FAA": "NFG",
//       "Airport Name": "Nefteyugansk Airport",
//       "Altitude (Ft)": 115,
//       "Country": "Russia",
//       "Latitude": 72.6500015258789,
//       "Location": "Nefteyugansk",
//       "Longitude": 61.108299255371094,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 6146,
//       "Airport Code [ICAO]": "USUU",
//       "Airport Code iATA,FAA": "KRO",
//       "Airport Name": "Kurgan Airport",
//       "Altitude (Ft)": 240,
//       "Country": "Russia",
//       "Latitude": 65.41560363769531,
//       "Location": "Kurgan",
//       "Longitude": 55.47529983520508,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 6147,
//       "Airport Code [ICAO]": "UTDL",
//       "Airport Code iATA,FAA": "LBD",
//       "Airport Name": "Khudzhand Airport",
//       "Altitude (Ft)": 1450,
//       "Country": "Tajikistan",
//       "Latitude": 69.6947021484375,
//       "Location": "Khudzhand",
//       "Longitude": 40.21540069580078,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dushanbe",
//       "Type": "airport"
//     },
//     {
//       "ID": 6148,
//       "Airport Code [ICAO]": "UTKA",
//       "Airport Code iATA,FAA": "AZN",
//       "Airport Name": "Andizhan Airport",
//       "Altitude (Ft)": 1515,
//       "Country": "Uzbekistan",
//       "Latitude": 72.2939987183,
//       "Location": "Andizhan",
//       "Longitude": 40.7276992798,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Samarkand",
//       "Type": "airport"
//     },
//     {
//       "ID": 6149,
//       "Airport Code [ICAO]": "UTKF",
//       "Airport Code iATA,FAA": "FEG",
//       "Airport Name": "Fergana International Airport",
//       "Altitude (Ft)": 1980,
//       "Country": "Uzbekistan",
//       "Latitude": 71.7450027466,
//       "Location": "Fergana",
//       "Longitude": 40.358798980699994,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Samarkand",
//       "Type": "airport"
//     },
//     {
//       "ID": 6150,
//       "Airport Code [ICAO]": "UTKN",
//       "Airport Code iATA,FAA": "NMA",
//       "Airport Name": "Namangan Airport",
//       "Altitude (Ft)": 1555,
//       "Country": "Uzbekistan",
//       "Latitude": 71.5567016602,
//       "Location": "Namangan",
//       "Longitude": 40.9846000671,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Samarkand",
//       "Type": "airport"
//     },
//     {
//       "ID": 6151,
//       "Airport Code [ICAO]": "UTNN",
//       "Airport Code iATA,FAA": "NCU",
//       "Airport Name": "Nukus Airport",
//       "Altitude (Ft)": 246,
//       "Country": "Uzbekistan",
//       "Latitude": 59.62329864501953,
//       "Location": "Nukus",
//       "Longitude": 42.488399505615234,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Samarkand",
//       "Type": "airport"
//     },
//     {
//       "ID": 6152,
//       "Airport Code [ICAO]": "UTNU",
//       "Airport Code iATA,FAA": "UGC",
//       "Airport Name": "Urgench Airport",
//       "Altitude (Ft)": 320,
//       "Country": "Uzbekistan",
//       "Latitude": 60.641700744628906,
//       "Location": "Urgench",
//       "Longitude": 41.58430099487305,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Samarkand",
//       "Type": "airport"
//     },
//     {
//       "ID": 6153,
//       "Airport Code [ICAO]": "UTSL",
//       "Airport Code iATA,FAA": "KSQ",
//       "Airport Name": "Karshi Khanabad Airport",
//       "Altitude (Ft)": 1365,
//       "Country": "Uzbekistan",
//       "Latitude": 65.9215011597,
//       "Location": "Khanabad",
//       "Longitude": 38.8335990906,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Samarkand",
//       "Type": "airport"
//     },
//     {
//       "ID": 6154,
//       "Airport Code [ICAO]": "UTST",
//       "Airport Code iATA,FAA": "TMJ",
//       "Airport Name": "Termez Airport",
//       "Altitude (Ft)": 1027,
//       "Country": "Uzbekistan",
//       "Latitude": 67.30999755859375,
//       "Location": "Termez",
//       "Longitude": 37.28670120239258,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Samarkand",
//       "Type": "airport"
//     },
//     {
//       "ID": 6155,
//       "Airport Code [ICAO]": "UUBK",
//       "Airport Code iATA,FAA": "RYB",
//       "Airport Name": "Staroselye Airport",
//       "Altitude (Ft)": 423,
//       "Country": "Russia",
//       "Latitude": 38.92940139770508,
//       "Location": "Rybinsk",
//       "Longitude": 58.10419845581055,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6156,
//       "Airport Code [ICAO]": "UUOB",
//       "Airport Code iATA,FAA": "EGO",
//       "Airport Name": "Belgorod International Airport",
//       "Altitude (Ft)": 735,
//       "Country": "Russia",
//       "Latitude": 36.5900993347168,
//       "Location": "Belgorod",
//       "Longitude": 50.643798828125,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6157,
//       "Airport Code [ICAO]": "UUOK",
//       "Airport Code iATA,FAA": "URS",
//       "Airport Name": "Kursk East Airport",
//       "Altitude (Ft)": 686,
//       "Country": "Russia",
//       "Latitude": 36.29560089111328,
//       "Location": "Kursk",
//       "Longitude": 51.7505989074707,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6158,
//       "Airport Code [ICAO]": "UUOL",
//       "Airport Code iATA,FAA": "LPK",
//       "Airport Name": "Lipetsk Airport",
//       "Altitude (Ft)": 584,
//       "Country": "Russia",
//       "Latitude": 39.53779983520508,
//       "Location": "Lipetsk",
//       "Longitude": 52.70280075073242,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6159,
//       "Airport Code [ICAO]": "UUYW",
//       "Airport Code iATA,FAA": "VKT",
//       "Airport Name": "Vorkuta Airport",
//       "Altitude (Ft)": 604,
//       "Country": "Russia",
//       "Latitude": 63.993099212646484,
//       "Location": "Vorkuta",
//       "Longitude": 67.48860168457031,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6160,
//       "Airport Code [ICAO]": "UWKB",
//       "Airport Code iATA,FAA": "UUA",
//       "Airport Name": "Bugulma Airport",
//       "Altitude (Ft)": 991,
//       "Country": "Russia",
//       "Latitude": 52.801700592041016,
//       "Location": "Bugulma",
//       "Longitude": 54.63999938964844,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6161,
//       "Airport Code [ICAO]": "UWKJ",
//       "Airport Code iATA,FAA": "JOK",
//       "Airport Name": "Yoshkar-Ola Airport",
//       "Altitude (Ft)": 348,
//       "Country": "Russia",
//       "Latitude": 47.904701232910156,
//       "Location": "Yoshkar-Ola",
//       "Longitude": 56.700599670410156,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6162,
//       "Airport Code [ICAO]": "UWKS",
//       "Airport Code iATA,FAA": "CSY",
//       "Airport Name": "Cheboksary Airport",
//       "Altitude (Ft)": 558,
//       "Country": "Russia",
//       "Latitude": 47.3473014831543,
//       "Location": "Cheboksary",
//       "Longitude": 56.090301513671875,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6163,
//       "Airport Code [ICAO]": "UWLW",
//       "Airport Code iATA,FAA": "ULY",
//       "Airport Name": "Ulyanovsk East Airport",
//       "Altitude (Ft)": 252,
//       "Country": "Russia",
//       "Latitude": 48.80270004272461,
//       "Location": "Ulyanovsk",
//       "Longitude": 54.4010009765625,
//       "GMT Offset": "4",
//       "Daylight Savings": "N",
//       "Region": "Europe/Samara",
//       "Type": "airport"
//     },
//     {
//       "ID": 6164,
//       "Airport Code [ICAO]": "UWOR",
//       "Airport Code iATA,FAA": "OSW",
//       "Airport Name": "Orsk Airport",
//       "Altitude (Ft)": 909,
//       "Country": "Russia",
//       "Latitude": 58.59560012817383,
//       "Location": "Orsk",
//       "Longitude": 51.0724983215332,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 6165,
//       "Airport Code [ICAO]": "UWPP",
//       "Airport Code iATA,FAA": "PEZ",
//       "Airport Name": "Penza Airport",
//       "Altitude (Ft)": 614,
//       "Country": "Russia",
//       "Latitude": 45.02109909057617,
//       "Location": "Penza",
//       "Longitude": 53.110599517822266,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6166,
//       "Airport Code [ICAO]": "UWPS",
//       "Airport Code iATA,FAA": "SKX",
//       "Airport Name": "Saransk Airport",
//       "Altitude (Ft)": 676,
//       "Country": "Russia",
//       "Latitude": 45.212257385253906,
//       "Location": "Saransk",
//       "Longitude": 54.12512969970703,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6167,
//       "Airport Code [ICAO]": "UWSB",
//       "Airport Code iATA,FAA": "BWO",
//       "Airport Name": "Balakovo Airport",
//       "Altitude (Ft)": 95,
//       "Country": "Russia",
//       "Latitude": 47.7456016541,
//       "Location": "Balakovo",
//       "Longitude": 51.8582992554,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6168,
//       "Airport Code [ICAO]": "VAHB",
//       "Airport Code iATA,FAA": "HBX",
//       "Airport Name": "Hubli Airport",
//       "Altitude (Ft)": 2171,
//       "Country": "India",
//       "Latitude": 75.08489990230001,
//       "Location": "Hubli",
//       "Longitude": 15.361700058,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 6169,
//       "Airport Code [ICAO]": "VCCK",
//       "Airport Code iATA,FAA": "KCT",
//       "Airport Name": "Koggala Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Sri Lanka",
//       "Latitude": 80.32029724121094,
//       "Location": "Koggala",
//       "Longitude": 5.993680000305176,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Colombo",
//       "Type": "airport"
//     },
//     {
//       "ID": 6170,
//       "Airport Code [ICAO]": "VCCW",
//       "Airport Code iATA,FAA": "WRZ",
//       "Airport Name": "Weerawila Airport",
//       "Altitude (Ft)": 50,
//       "Country": "Sri Lanka",
//       "Latitude": 81.23519897460001,
//       "Location": "Wirawila",
//       "Longitude": 6.25448989868,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Colombo",
//       "Type": "airport"
//     },
//     {
//       "ID": 6171,
//       "Airport Code [ICAO]": "VDBG",
//       "Airport Code iATA,FAA": "BBM",
//       "Airport Name": "Battambang Airport",
//       "Altitude (Ft)": 59,
//       "Country": "Cambodia",
//       "Latitude": 103.2239990234375,
//       "Location": "Battambang",
//       "Longitude": 13.095600128173828,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Phnom_Penh",
//       "Type": "airport"
//     },
//     {
//       "ID": 6172,
//       "Airport Code [ICAO]": "VEBI",
//       "Airport Code iATA,FAA": "SHL",
//       "Airport Name": "Shillong Airport",
//       "Altitude (Ft)": 2910,
//       "Country": "India",
//       "Latitude": 91.97869873046875,
//       "Location": "Shillong",
//       "Longitude": 25.70359992980957,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 6173,
//       "Airport Code [ICAO]": "VEGT",
//       "Airport Code iATA,FAA": "GAU",
//       "Airport Name": "Lokpriya Gopinath Bordoloi International Airport",
//       "Altitude (Ft)": 162,
//       "Country": "India",
//       "Latitude": 91.58589935302734,
//       "Location": "Guwahati",
//       "Longitude": 26.10610008239746,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 6174,
//       "Airport Code [ICAO]": "VEMR",
//       "Airport Code iATA,FAA": "DMU",
//       "Airport Name": "Dimapur Airport",
//       "Altitude (Ft)": 487,
//       "Country": "India",
//       "Latitude": 93.77110290530001,
//       "Location": "Dimapur",
//       "Longitude": 25.883899688699998,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 6175,
//       "Airport Code [ICAO]": "VETZ",
//       "Airport Code iATA,FAA": "TEZ",
//       "Airport Name": "Tezpur Airport",
//       "Altitude (Ft)": 240,
//       "Country": "India",
//       "Latitude": 92.78469848632812,
//       "Location": "Tezpur",
//       "Longitude": 26.7091007232666,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 6176,
//       "Airport Code [ICAO]": "VGBR",
//       "Airport Code iATA,FAA": "BZL",
//       "Airport Name": "Barisal Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Bangladesh",
//       "Latitude": 90.30120086669922,
//       "Location": "Barisal",
//       "Longitude": 22.801000595092773,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dhaka",
//       "Type": "airport"
//     },
//     {
//       "ID": 6177,
//       "Airport Code [ICAO]": "VLHS",
//       "Airport Code iATA,FAA": "HOE",
//       "Airport Name": "Ban Huoeisay Airport",
//       "Altitude (Ft)": 1380,
//       "Country": "Laos",
//       "Latitude": 100.43699646,
//       "Location": "Huay Xai",
//       "Longitude": 20.2572994232,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Vientiane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6179,
//       "Airport Code [ICAO]": "VNBP",
//       "Airport Code iATA,FAA": "BHR",
//       "Airport Name": "Bharatpur Airport",
//       "Altitude (Ft)": 600,
//       "Country": "Nepal",
//       "Latitude": 84.429398,
//       "Location": "Bharatpur",
//       "Longitude": 27.678101,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 6180,
//       "Airport Code [ICAO]": "VNCG",
//       "Airport Code iATA,FAA": "BDP",
//       "Airport Name": "Bhadrapur Airport",
//       "Altitude (Ft)": 300,
//       "Country": "Nepal",
//       "Latitude": 88.07959747310001,
//       "Location": "Chandragarhi",
//       "Longitude": 26.5708007812,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 6181,
//       "Airport Code [ICAO]": "VNMG",
//       "Airport Code iATA,FAA": "MEY",
//       "Airport Name": "Meghauli Airport",
//       "Altitude (Ft)": 600,
//       "Country": "Nepal",
//       "Latitude": 84.22875,
//       "Location": "Meghauli",
//       "Longitude": 27.5774,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 6182,
//       "Airport Code [ICAO]": "VNNG",
//       "Airport Code iATA,FAA": "KEP",
//       "Airport Name": "Nepalgunj Airport",
//       "Altitude (Ft)": 540,
//       "Country": "Nepal",
//       "Latitude": 81.66699981689453,
//       "Location": "Nepalgunj",
//       "Longitude": 28.103599548339844,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 6183,
//       "Airport Code [ICAO]": "VRMG",
//       "Airport Code iATA,FAA": "GAN",
//       "Airport Name": "Gan International Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Maldives",
//       "Latitude": 73.155602,
//       "Location": "Gan Island",
//       "Longitude": -0.693342,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Indian/Maldives",
//       "Type": "airport"
//     },
//     {
//       "ID": 6184,
//       "Airport Code [ICAO]": "VRMH",
//       "Airport Code iATA,FAA": "HAQ",
//       "Airport Name": "Hanimaadhoo Airport",
//       "Altitude (Ft)": 4,
//       "Country": "Maldives",
//       "Latitude": 73.17050170898438,
//       "Location": "Haa Dhaalu Atoll",
//       "Longitude": 6.744229793548584,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Indian/Maldives",
//       "Type": "airport"
//     },
//     {
//       "ID": 6185,
//       "Airport Code [ICAO]": "VRMK",
//       "Airport Code iATA,FAA": "KDO",
//       "Airport Name": "Kadhdhoo Airport",
//       "Altitude (Ft)": 4,
//       "Country": "Maldives",
//       "Latitude": 73.52189636230469,
//       "Location": "Laamu Atoll",
//       "Longitude": 1.8591699600219727,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Indian/Maldives",
//       "Type": "airport"
//     },
//     {
//       "ID": 6186,
//       "Airport Code [ICAO]": "VTPM",
//       "Airport Code iATA,FAA": "MAQ",
//       "Airport Name": "Mae Sot Airport",
//       "Altitude (Ft)": 690,
//       "Country": "Thailand",
//       "Latitude": 98.54509735107422,
//       "Location": "Tak",
//       "Longitude": 16.699899673461914,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 6187,
//       "Airport Code [ICAO]": "VVBM",
//       "Airport Code iATA,FAA": "BMV",
//       "Airport Name": "Buon Ma Thuot Airport",
//       "Altitude (Ft)": 1729,
//       "Country": "Vietnam",
//       "Latitude": 108.120002747,
//       "Location": "Buonmethuot",
//       "Longitude": 12.668299675,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Saigon",
//       "Type": "airport"
//     },
//     {
//       "ID": 6188,
//       "Airport Code [ICAO]": "VVCI",
//       "Airport Code iATA,FAA": "HPH",
//       "Airport Name": "Cat Bi International Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Vietnam",
//       "Latitude": 106.7249984741211,
//       "Location": "Haiphong",
//       "Longitude": 20.819400787353516,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Saigon",
//       "Type": "airport"
//     },
//     {
//       "ID": 6189,
//       "Airport Code [ICAO]": "VVCR",
//       "Airport Code iATA,FAA": "CXR",
//       "Airport Name": "Cam Ranh Airport",
//       "Altitude (Ft)": 40,
//       "Country": "Vietnam",
//       "Latitude": 109.21900177001953,
//       "Location": "Nha Trang",
//       "Longitude": 11.998200416564941,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Saigon",
//       "Type": "airport"
//     },
//     {
//       "ID": 6190,
//       "Airport Code [ICAO]": "VVCS",
//       "Airport Code iATA,FAA": "VCS",
//       "Airport Name": "Co Ong Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Vietnam",
//       "Latitude": 106.633003,
//       "Location": "Conson",
//       "Longitude": 8.73183,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Saigon",
//       "Type": "airport"
//     },
//     {
//       "ID": 6191,
//       "Airport Code [ICAO]": "VVCT",
//       "Airport Code iATA,FAA": "VCA",
//       "Airport Name": "Can Tho International Airport",
//       "Altitude (Ft)": 9,
//       "Country": "Vietnam",
//       "Latitude": 105.711997986,
//       "Location": "Can Tho",
//       "Longitude": 10.085100174,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Saigon",
//       "Type": "airport"
//     },
//     {
//       "ID": 6192,
//       "Airport Code [ICAO]": "VVDB",
//       "Airport Code iATA,FAA": "DIN",
//       "Airport Name": "Dien Bien Phu Airport",
//       "Altitude (Ft)": 1611,
//       "Country": "Vietnam",
//       "Latitude": 103.008003235,
//       "Location": "Dienbienphu",
//       "Longitude": 21.3974990845,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Saigon",
//       "Type": "airport"
//     },
//     {
//       "ID": 6193,
//       "Airport Code [ICAO]": "VVPC",
//       "Airport Code iATA,FAA": "UIH",
//       "Airport Name": "Phu Cat Airport",
//       "Altitude (Ft)": 80,
//       "Country": "Vietnam",
//       "Latitude": 109.042,
//       "Location": "Phucat",
//       "Longitude": 13.955,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Saigon",
//       "Type": "airport"
//     },
//     {
//       "ID": 6194,
//       "Airport Code [ICAO]": "VVPK",
//       "Airport Code iATA,FAA": "PXU",
//       "Airport Name": "Pleiku Airport",
//       "Altitude (Ft)": 2434,
//       "Country": "Vietnam",
//       "Latitude": 108.01699829101562,
//       "Location": "Pleiku",
//       "Longitude": 14.004500389099121,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Saigon",
//       "Type": "airport"
//     },
//     {
//       "ID": 6195,
//       "Airport Code [ICAO]": "VVVH",
//       "Airport Code iATA,FAA": "VII",
//       "Airport Name": "Vinh Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Vietnam",
//       "Latitude": 105.67099762,
//       "Location": "Vinh",
//       "Longitude": 18.7376003265,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Saigon",
//       "Type": "airport"
//     },
//     {
//       "ID": 6196,
//       "Airport Code [ICAO]": "VYBM",
//       "Airport Code iATA,FAA": "BMO",
//       "Airport Name": "Banmaw Airport",
//       "Altitude (Ft)": 370,
//       "Country": "Burma",
//       "Latitude": 97.24620056152344,
//       "Location": "Banmaw",
//       "Longitude": 24.268999099731445,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 6197,
//       "Airport Code [ICAO]": "VYDW",
//       "Airport Code iATA,FAA": "TVY",
//       "Airport Name": "Dawei Airport",
//       "Altitude (Ft)": 84,
//       "Country": "Burma",
//       "Latitude": 98.20359802246094,
//       "Location": "Dawei",
//       "Longitude": 14.103899955749512,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 6198,
//       "Airport Code [ICAO]": "VYKT",
//       "Airport Code iATA,FAA": "KAW",
//       "Airport Name": "Kawthoung Airport",
//       "Altitude (Ft)": 180,
//       "Country": "Burma",
//       "Latitude": 98.53800201416016,
//       "Location": "Kawthoung",
//       "Longitude": 10.049300193786621,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 6199,
//       "Airport Code [ICAO]": "VYLK",
//       "Airport Code iATA,FAA": "LIW",
//       "Airport Name": "Loikaw Airport",
//       "Altitude (Ft)": 2940,
//       "Country": "Burma",
//       "Latitude": 97.21479797363281,
//       "Location": "Loikaw",
//       "Longitude": 19.691499710083008,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 6200,
//       "Airport Code [ICAO]": "VYMM",
//       "Airport Code iATA,FAA": "MNU",
//       "Airport Name": "Mawlamyine Airport",
//       "Altitude (Ft)": 52,
//       "Country": "Burma",
//       "Latitude": 97.66069793701172,
//       "Location": "Mawlamyine",
//       "Longitude": 16.444700241088867,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 6201,
//       "Airport Code [ICAO]": "VYPN",
//       "Airport Code iATA,FAA": "BSX",
//       "Airport Name": "Pathein Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Burma",
//       "Latitude": 94.7799,
//       "Location": "Pathein",
//       "Longitude": 16.815201,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 6202,
//       "Airport Code [ICAO]": "VYPU",
//       "Airport Code iATA,FAA": "PKK",
//       "Airport Name": "Pakhokku Airport",
//       "Altitude (Ft)": 151,
//       "Country": "Burma",
//       "Latitude": 95.11125,
//       "Location": "Pakhokku",
//       "Longitude": 21.4043,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 6203,
//       "Airport Code [ICAO]": "WADS",
//       "Airport Code iATA,FAA": "SWQ",
//       "Airport Name": "Sumbawa Besar Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Indonesia",
//       "Latitude": 117.41200256347656,
//       "Location": "Sumbawa Island",
//       "Longitude": -8.48904037475586,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 6204,
//       "Airport Code [ICAO]": "WADT",
//       "Airport Code iATA,FAA": "TMC",
//       "Airport Name": "Tambolaka Airport",
//       "Altitude (Ft)": 161,
//       "Country": "Indonesia",
//       "Latitude": 119.244003,
//       "Location": "Waikabubak-Sumba Island",
//       "Longitude": -9.40972,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 6205,
//       "Airport Code [ICAO]": "WAJB",
//       "Airport Code iATA,FAA": "BUI",
//       "Airport Name": "Bokondini Airport",
//       "Altitude (Ft)": 4550,
//       "Country": "Indonesia",
//       "Latitude": 138.6755,
//       "Location": "Bokondini-Papua Island",
//       "Longitude": -3.6822,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jayapura",
//       "Type": "airport"
//     },
//     {
//       "ID": 6206,
//       "Airport Code [ICAO]": "WAJS",
//       "Airport Code iATA,FAA": "SEH",
//       "Airport Name": "Senggeh Airport",
//       "Altitude (Ft)": 914,
//       "Country": "Indonesia",
//       "Latitude": 140.779,
//       "Location": "Senggeh-Papua Island",
//       "Longitude": -3.45,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jayapura",
//       "Type": "airport"
//     },
//     {
//       "ID": 6207,
//       "Airport Code [ICAO]": "WALG",
//       "Airport Code iATA,FAA": "TJS",
//       "Airport Name": "Tanjung Harapan Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Indonesia",
//       "Latitude": 117.373611111,
//       "Location": "Tanjung Selor-Borneo Island",
//       "Longitude": 2.83583333333,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 6208,
//       "Airport Code [ICAO]": "WALJ",
//       "Airport Code iATA,FAA": "DTD",
//       "Airport Name": "Datadawai Airport",
//       "Altitude (Ft)": 508,
//       "Country": "Indonesia",
//       "Latitude": 114.5306,
//       "Location": "Datadawai-Borneo Island",
//       "Longitude": 0.8106,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 6209,
//       "Airport Code [ICAO]": "WALK",
//       "Airport Code iATA,FAA": "BEJ",
//       "Airport Name": "Kalimarau Airport",
//       "Altitude (Ft)": 59,
//       "Country": "Indonesia",
//       "Latitude": 117.431999,
//       "Location": "Tanjung Redep-Borneo Island",
//       "Longitude": 2.1555,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 6210,
//       "Airport Code [ICAO]": "WAON",
//       "Airport Code iATA,FAA": "TJG",
//       "Airport Name": "Warukin Airport",
//       "Altitude (Ft)": 197,
//       "Country": "Indonesia",
//       "Latitude": 115.435997009,
//       "Location": "Tanjung-Borneo Island",
//       "Longitude": -2.21655988693,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 6211,
//       "Airport Code [ICAO]": "WAOS",
//       "Airport Code iATA,FAA": "SMQ",
//       "Airport Name": "Sampit(Hasan) Airport",
//       "Altitude (Ft)": 50,
//       "Country": "Indonesia",
//       "Latitude": 112.974998474,
//       "Location": "Sampit-Borneo Island",
//       "Longitude": -2.49919009209,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 6212,
//       "Airport Code [ICAO]": "WAPL",
//       "Airport Code iATA,FAA": "LUV",
//       "Airport Name": "Dumatumbun Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Indonesia",
//       "Latitude": 132.7310028076172,
//       "Location": "Langgur-Kei Islands",
//       "Longitude": -5.661620140075684,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jayapura",
//       "Type": "airport"
//     },
//     {
//       "ID": 6213,
//       "Airport Code [ICAO]": "WATM",
//       "Airport Code iATA,FAA": "ARD",
//       "Airport Name": "Mali Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Indonesia",
//       "Latitude": 124.59700012207031,
//       "Location": "Alor Island",
//       "Longitude": -8.132340431213379,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 6214,
//       "Airport Code [ICAO]": "WBGC",
//       "Airport Code iATA,FAA": "BLG",
//       "Airport Name": "Belaga Airport",
//       "Altitude (Ft)": 200,
//       "Country": "Malaysia",
//       "Latitude": 113.766998291,
//       "Location": "Belaga",
//       "Longitude": 2.65000009537,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 6215,
//       "Airport Code [ICAO]": "WBGF",
//       "Airport Code iATA,FAA": "LGL",
//       "Airport Name": "Long Lellang Airport",
//       "Altitude (Ft)": 1400,
//       "Country": "Malaysia",
//       "Latitude": 115.153999329,
//       "Location": "Long Datih",
//       "Longitude": 3.4210000038099997,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 6216,
//       "Airport Code [ICAO]": "WBGI",
//       "Airport Code iATA,FAA": "ODN",
//       "Airport Name": "Long Seridan Airport",
//       "Altitude (Ft)": 607,
//       "Country": "Malaysia",
//       "Latitude": 115.05000305175781,
//       "Location": "Long Seridan",
//       "Longitude": 3.9670000076293945,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 6217,
//       "Airport Code [ICAO]": "WBGK",
//       "Airport Code iATA,FAA": "MKM",
//       "Airport Name": "Mukah Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Malaysia",
//       "Latitude": 112.080002,
//       "Location": "Mukah",
//       "Longitude": 2.90639,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 6218,
//       "Airport Code [ICAO]": "WBGQ",
//       "Airport Code iATA,FAA": "BKM",
//       "Airport Name": "Bakalalan Airport",
//       "Altitude (Ft)": 2900,
//       "Country": "Malaysia",
//       "Latitude": 115.61799621582031,
//       "Location": "Bakalalan",
//       "Longitude": 3.9739999771118164,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 6219,
//       "Airport Code [ICAO]": "WBGW",
//       "Airport Code iATA,FAA": "LWY",
//       "Airport Name": "Lawas Airport",
//       "Altitude (Ft)": 5,
//       "Country": "Malaysia",
//       "Latitude": 115.407997,
//       "Location": "Lawas",
//       "Longitude": 4.84917,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 6220,
//       "Airport Code [ICAO]": "WBGZ",
//       "Airport Code iATA,FAA": "BBN",
//       "Airport Name": "Bario Airport",
//       "Altitude (Ft)": 3350,
//       "Country": "Malaysia",
//       "Latitude": 115.47899627685547,
//       "Location": "Bario",
//       "Longitude": 3.7338900566101074,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 6221,
//       "Airport Code [ICAO]": "WBKM",
//       "Airport Code iATA,FAA": "TMG",
//       "Airport Name": "Tomanggong Airport",
//       "Altitude (Ft)": 26,
//       "Country": "Malaysia",
//       "Latitude": 118.65763,
//       "Location": "Tomanggong",
//       "Longitude": 5.40257,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 6222,
//       "Airport Code [ICAO]": "WBKT",
//       "Airport Code iATA,FAA": "KUD",
//       "Airport Name": "Kudat Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Malaysia",
//       "Latitude": 116.83599853515625,
//       "Location": "Kudat",
//       "Longitude": 6.922500133514404,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 6223,
//       "Airport Code [ICAO]": "WIAT",
//       "Airport Code iATA,FAA": "TKG",
//       "Airport Name": "Radin Inten II (Branti) Airport",
//       "Altitude (Ft)": 282,
//       "Country": "Indonesia",
//       "Latitude": 105.175556,
//       "Location": "Bandar Lampung-Sumatra Island",
//       "Longitude": -5.240556,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 6224,
//       "Airport Code [ICAO]": "WIHH",
//       "Airport Code iATA,FAA": "HLP",
//       "Airport Name": "Halim Perdanakusuma International Airport",
//       "Altitude (Ft)": 84,
//       "Country": "Indonesia",
//       "Latitude": 106.89099884033203,
//       "Location": "Jakarta",
//       "Longitude": -6.266610145568848,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 6225,
//       "Airport Code [ICAO]": "WION",
//       "Airport Code iATA,FAA": "NTX",
//       "Airport Name": "Ranai Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Indonesia",
//       "Latitude": 108.388,
//       "Location": "Ranai-Natuna Besar Island",
//       "Longitude": 3.90871,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 6226,
//       "Airport Code [ICAO]": "WIOP",
//       "Airport Code iATA,FAA": "PSU",
//       "Airport Name": "Pangsuma Airport",
//       "Altitude (Ft)": 297,
//       "Country": "Indonesia",
//       "Latitude": 112.93699645996094,
//       "Location": "Putussibau-Borneo Island",
//       "Longitude": 0.8355780243873596,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 6227,
//       "Airport Code [ICAO]": "WIOS",
//       "Airport Code iATA,FAA": "SQG",
//       "Airport Name": "Sintang(Susilo) Airport",
//       "Altitude (Ft)": 98,
//       "Country": "Indonesia",
//       "Latitude": 111.4729995727539,
//       "Location": "Sintang-Borneo Island",
//       "Longitude": 0.06361900269985199,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 6228,
//       "Airport Code [ICAO]": "WIPQ",
//       "Airport Code iATA,FAA": "PDO",
//       "Airport Name": "Pendopo Airport",
//       "Altitude (Ft)": 184,
//       "Country": "Indonesia",
//       "Latitude": 103.87999725341797,
//       "Location": "Talang Gudang-Sumatra Island",
//       "Longitude": -3.2860701084136963,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 6229,
//       "Airport Code [ICAO]": "WITM",
//       "Airport Code iATA,FAA": "LSW",
//       "Airport Name": "Malikus Saleh Airport",
//       "Altitude (Ft)": 90,
//       "Country": "Indonesia",
//       "Latitude": 96.95030212402344,
//       "Location": "Lhok Seumawe-Sumatra Island",
//       "Longitude": 5.226679801940918,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 6230,
//       "Airport Code [ICAO]": "WMPA",
//       "Airport Code iATA,FAA": "PKG",
//       "Airport Name": "Pulau Pangkor Airport",
//       "Altitude (Ft)": 19,
//       "Country": "Malaysia",
//       "Latitude": 100.5530014038086,
//       "Location": "Pangkor Island",
//       "Longitude": 4.244719982147217,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 6232,
//       "Airport Code [ICAO]": "WRLB",
//       "Airport Code iATA,FAA": "LBW",
//       "Airport Name": "Long Bawan Airport",
//       "Altitude (Ft)": 3165,
//       "Country": "Indonesia",
//       "Latitude": 115.6921,
//       "Location": "Long Bawan-Borneo Island",
//       "Longitude": 3.9028,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 6233,
//       "Airport Code [ICAO]": "WRLF",
//       "Airport Code iATA,FAA": "NNX",
//       "Airport Name": "Nunukan Airport",
//       "Altitude (Ft)": 30,
//       "Country": "Indonesia",
//       "Latitude": 117.666666667,
//       "Location": "Nunukan-Nunukan Island",
//       "Longitude": 4.13333333333,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 6234,
//       "Airport Code [ICAO]": "WRLP",
//       "Airport Code iATA,FAA": "LPU",
//       "Airport Name": "Long Apung Airport",
//       "Altitude (Ft)": 627,
//       "Country": "Indonesia",
//       "Latitude": 114.970297,
//       "Location": "Long Apung-Borneo Island",
//       "Longitude": 1.704486,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 6235,
//       "Airport Code [ICAO]": "YABA",
//       "Airport Code iATA,FAA": "ALH",
//       "Airport Name": "Albany Airport",
//       "Altitude (Ft)": 233,
//       "Country": "Australia",
//       "Latitude": 117.80899810791016,
//       "Location": "Albany",
//       "Longitude": -34.94329833984375,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 6236,
//       "Airport Code [ICAO]": "YARG",
//       "Airport Code iATA,FAA": "GYL",
//       "Airport Name": "Argyle Airport",
//       "Altitude (Ft)": 522,
//       "Country": "Australia",
//       "Latitude": 128.451004,
//       "Location": "Argyle",
//       "Longitude": -16.6369,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 6237,
//       "Airport Code [ICAO]": "YAUR",
//       "Airport Code iATA,FAA": "AUU",
//       "Airport Name": "Aurukun Airport",
//       "Altitude (Ft)": 31,
//       "Country": "Australia",
//       "Latitude": 141.72065,
//       "Location": "Aurukun",
//       "Longitude": -13.354067,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6238,
//       "Airport Code [ICAO]": "YBAR",
//       "Airport Code iATA,FAA": "BCI",
//       "Airport Name": "Barcaldine Airport",
//       "Altitude (Ft)": 878,
//       "Country": "Australia",
//       "Latitude": 145.307006836,
//       "Location": "Barcaldine",
//       "Longitude": -23.5652999878,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6239,
//       "Airport Code [ICAO]": "YBAU",
//       "Airport Code iATA,FAA": "BDD",
//       "Airport Name": "Badu Island Airport",
//       "Altitude (Ft)": 14,
//       "Country": "Australia",
//       "Latitude": 142.1734,
//       "Location": "Badu Island",
//       "Longitude": -10.149999618499999,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6240,
//       "Airport Code [ICAO]": "YBDV",
//       "Airport Code iATA,FAA": "BVI",
//       "Airport Name": "Birdsville Airport",
//       "Altitude (Ft)": 159,
//       "Country": "Australia",
//       "Latitude": 139.34800720214844,
//       "Location": "Birdsville",
//       "Longitude": -25.897499084472656,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6241,
//       "Airport Code [ICAO]": "YBHI",
//       "Airport Code iATA,FAA": "BHQ",
//       "Airport Name": "Broken Hill Airport",
//       "Altitude (Ft)": 958,
//       "Country": "Australia",
//       "Latitude": 141.472000122,
//       "Location": "Broken Hill",
//       "Longitude": -32.0013999939,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Adelaide",
//       "Type": "airport"
//     },
//     {
//       "ID": 6242,
//       "Airport Code [ICAO]": "YBHM",
//       "Airport Code iATA,FAA": "HTI",
//       "Airport Name": "Hamilton Island Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Australia",
//       "Latitude": 148.95199585,
//       "Location": "Hamilton Island",
//       "Longitude": -20.3581008911,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6243,
//       "Airport Code [ICAO]": "YBIE",
//       "Airport Code iATA,FAA": "BEU",
//       "Airport Name": "Bedourie Airport",
//       "Altitude (Ft)": 300,
//       "Country": "Australia",
//       "Latitude": 139.4600067138672,
//       "Location": "Bedourie",
//       "Longitude": -24.346099853515625,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6244,
//       "Airport Code [ICAO]": "YBKE",
//       "Airport Code iATA,FAA": "BRK",
//       "Airport Name": "Bourke Airport",
//       "Altitude (Ft)": 352,
//       "Country": "Australia",
//       "Latitude": 145.95199584960938,
//       "Location": "Bourke",
//       "Longitude": -30.039199829101562,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 6245,
//       "Airport Code [ICAO]": "YBKT",
//       "Airport Code iATA,FAA": "BUC",
//       "Airport Name": "Burketown Airport",
//       "Altitude (Ft)": 21,
//       "Country": "Australia",
//       "Latitude": 139.53399658203125,
//       "Location": "Burketown",
//       "Longitude": -17.748600006103516,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6246,
//       "Airport Code [ICAO]": "YBOI",
//       "Airport Code iATA,FAA": "GIC",
//       "Airport Name": "Boigu Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Australia",
//       "Latitude": 142.218002319,
//       "Location": "Boigu",
//       "Longitude": -9.23278045654,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6247,
//       "Airport Code [ICAO]": "YBOK",
//       "Airport Code iATA,FAA": "OKY",
//       "Airport Name": "Oakey Airport",
//       "Altitude (Ft)": 1335,
//       "Country": "Australia",
//       "Latitude": 151.73500061035156,
//       "Location": "Oakey",
//       "Longitude": -27.411399841308594,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6248,
//       "Airport Code [ICAO]": "YBOU",
//       "Airport Code iATA,FAA": "BQL",
//       "Airport Name": "Boulia Airport",
//       "Altitude (Ft)": 542,
//       "Country": "Australia",
//       "Latitude": 139.89999389648438,
//       "Location": "Boulia",
//       "Longitude": -22.913299560546875,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6249,
//       "Airport Code [ICAO]": "YBTH",
//       "Airport Code iATA,FAA": "BHS",
//       "Airport Name": "Bathurst Airport",
//       "Altitude (Ft)": 2435,
//       "Country": "Australia",
//       "Latitude": 149.651992798,
//       "Location": "Bathurst",
//       "Longitude": -33.4094009399,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 6250,
//       "Airport Code [ICAO]": "YBTR",
//       "Airport Code iATA,FAA": "BLT",
//       "Airport Name": "Blackwater Airport",
//       "Altitude (Ft)": 657,
//       "Country": "Australia",
//       "Latitude": 148.8070068359375,
//       "Location": "Blackwater",
//       "Longitude": -23.603099822998047,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6251,
//       "Airport Code [ICAO]": "YCAR",
//       "Airport Code iATA,FAA": "CVQ",
//       "Airport Name": "Carnarvon Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Australia",
//       "Latitude": 113.67174,
//       "Location": "Carnarvon",
//       "Longitude": -24.880211,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 6252,
//       "Airport Code [ICAO]": "YCBA",
//       "Airport Code iATA,FAA": "CAZ",
//       "Airport Name": "Cobar Airport",
//       "Altitude (Ft)": 724,
//       "Country": "Australia",
//       "Latitude": 145.79400634765625,
//       "Location": "Cobar",
//       "Longitude": -31.538299560546875,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 6253,
//       "Airport Code [ICAO]": "YCBP",
//       "Airport Code iATA,FAA": "CPD",
//       "Airport Name": "Coober Pedy Airport",
//       "Altitude (Ft)": 740,
//       "Country": "Australia",
//       "Latitude": 134.7209930419922,
//       "Location": "Coober Pedy",
//       "Longitude": -29.040000915527344,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Adelaide",
//       "Type": "airport"
//     },
//     {
//       "ID": 6254,
//       "Airport Code [ICAO]": "YCCT",
//       "Airport Code iATA,FAA": "CNC",
//       "Airport Name": "Coconut Island Airport",
//       "Altitude (Ft)": 3,
//       "Country": "Australia",
//       "Latitude": 143.07000732421875,
//       "Location": "Coconut Island",
//       "Longitude": -10.050000190734863,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6255,
//       "Airport Code [ICAO]": "YCCY",
//       "Airport Code iATA,FAA": "CNJ",
//       "Airport Name": "Cloncurry Airport",
//       "Altitude (Ft)": 616,
//       "Country": "Australia",
//       "Latitude": 140.503997803,
//       "Location": "Cloncurry",
//       "Longitude": -20.668600082399998,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6256,
//       "Airport Code [ICAO]": "YCDU",
//       "Airport Code iATA,FAA": "CED",
//       "Airport Name": "Ceduna Airport",
//       "Altitude (Ft)": 77,
//       "Country": "Australia",
//       "Latitude": 133.7100067138672,
//       "Location": "Ceduna",
//       "Longitude": -32.13059997558594,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Adelaide",
//       "Type": "airport"
//     },
//     {
//       "ID": 6257,
//       "Airport Code [ICAO]": "YCKN",
//       "Airport Code iATA,FAA": "CTN",
//       "Airport Name": "Cooktown Airport",
//       "Altitude (Ft)": 26,
//       "Country": "Australia",
//       "Latitude": 145.1840057373047,
//       "Location": "Cooktown",
//       "Longitude": -15.444700241088867,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6258,
//       "Airport Code [ICAO]": "YCMU",
//       "Airport Code iATA,FAA": "CMA",
//       "Airport Name": "Cunnamulla Airport",
//       "Altitude (Ft)": 630,
//       "Country": "Australia",
//       "Latitude": 145.6219940185547,
//       "Location": "Cunnamulla",
//       "Longitude": -28.030000686645508,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6259,
//       "Airport Code [ICAO]": "YCNM",
//       "Airport Code iATA,FAA": "CNB",
//       "Airport Name": "Coonamble Airport",
//       "Altitude (Ft)": 604,
//       "Country": "Australia",
//       "Latitude": 148.37600708007812,
//       "Location": "Coonamble",
//       "Longitude": -30.983299255371094,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 6260,
//       "Airport Code [ICAO]": "YCOE",
//       "Airport Code iATA,FAA": "CUQ",
//       "Airport Name": "Coen Airport",
//       "Altitude (Ft)": 532,
//       "Country": "Australia",
//       "Latitude": 143.113311,
//       "Location": "Coen",
//       "Longitude": -13.761133,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6261,
//       "Airport Code [ICAO]": "YCOM",
//       "Airport Code iATA,FAA": "OOM",
//       "Airport Name": "Cooma Snowy Mountains Airport",
//       "Altitude (Ft)": 3088,
//       "Country": "Australia",
//       "Latitude": 148.973999023,
//       "Location": "Cooma",
//       "Longitude": -36.3005981445,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 6262,
//       "Airport Code [ICAO]": "YDMG",
//       "Airport Code iATA,FAA": "DMD",
//       "Airport Name": "Doomadgee Airport",
//       "Altitude (Ft)": 153,
//       "Country": "Australia",
//       "Latitude": 138.822006,
//       "Location": "Doomadgee",
//       "Longitude": -17.9403,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6263,
//       "Airport Code [ICAO]": "YDNI",
//       "Airport Code iATA,FAA": "NLF",
//       "Airport Name": "Darnley Island Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 143.76699829101562,
//       "Location": "Darnley Island",
//       "Longitude": -9.583330154418945,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6264,
//       "Airport Code [ICAO]": "YDPO",
//       "Airport Code iATA,FAA": "DPO",
//       "Airport Name": "Devonport Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Australia",
//       "Latitude": 146.429992676,
//       "Location": "Devonport",
//       "Longitude": -41.1697006226,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Melbourne",
//       "Type": "airport"
//     },
//     {
//       "ID": 6265,
//       "Airport Code [ICAO]": "YELD",
//       "Airport Code iATA,FAA": "ELC",
//       "Airport Name": "Elcho Island Airport",
//       "Altitude (Ft)": 101,
//       "Country": "Australia",
//       "Latitude": 135.570999146,
//       "Location": "Elcho Island",
//       "Longitude": -12.019399642899998,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Darwin",
//       "Type": "airport"
//     },
//     {
//       "ID": 6266,
//       "Airport Code [ICAO]": "YESP",
//       "Airport Code iATA,FAA": "EPR",
//       "Airport Name": "Esperance Airport",
//       "Altitude (Ft)": 470,
//       "Country": "Australia",
//       "Latitude": 121.822998,
//       "Location": "Esperance",
//       "Longitude": -33.684399,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 6267,
//       "Airport Code [ICAO]": "YFLI",
//       "Airport Code iATA,FAA": "FLS",
//       "Airport Name": "Flinders Island Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Australia",
//       "Latitude": 147.992996216,
//       "Location": "Flinders Island",
//       "Longitude": -40.0917015076,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Melbourne",
//       "Type": "airport"
//     },
//     {
//       "ID": 6268,
//       "Airport Code [ICAO]": "YGEL",
//       "Airport Code iATA,FAA": "GET",
//       "Airport Name": "Geraldton Airport",
//       "Altitude (Ft)": 121,
//       "Country": "Australia",
//       "Latitude": 114.707001,
//       "Location": "Geraldton",
//       "Longitude": -28.796101,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 6269,
//       "Airport Code [ICAO]": "YGLA",
//       "Airport Code iATA,FAA": "GLT",
//       "Airport Name": "Gladstone Airport",
//       "Altitude (Ft)": 64,
//       "Country": "Australia",
//       "Latitude": 151.223007,
//       "Location": "Gladstone",
//       "Longitude": -23.869699,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6270,
//       "Airport Code [ICAO]": "YGTE",
//       "Airport Code iATA,FAA": "GTE",
//       "Airport Name": "Groote Eylandt Airport",
//       "Altitude (Ft)": 53,
//       "Country": "Australia",
//       "Latitude": 136.460006714,
//       "Location": "Groote Eylandt",
//       "Longitude": -13.975000381500001,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Darwin",
//       "Type": "airport"
//     },
//     {
//       "ID": 6271,
//       "Airport Code [ICAO]": "YGTH",
//       "Airport Code iATA,FAA": "GFF",
//       "Airport Name": "Griffith Airport",
//       "Altitude (Ft)": 439,
//       "Country": "Australia",
//       "Latitude": 146.067001343,
//       "Location": "Griffith",
//       "Longitude": -34.2508010864,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 6272,
//       "Airport Code [ICAO]": "YHID",
//       "Airport Code iATA,FAA": "HID",
//       "Airport Name": "Horn Island Airport",
//       "Altitude (Ft)": 43,
//       "Country": "Australia",
//       "Latitude": 142.289993286,
//       "Location": "Horn Island",
//       "Longitude": -10.586400032,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6273,
//       "Airport Code [ICAO]": "YHOO",
//       "Airport Code iATA,FAA": "HOK",
//       "Airport Name": "Hooker Creek Airport",
//       "Altitude (Ft)": 320,
//       "Country": "Australia",
//       "Latitude": 130.638000488,
//       "Location": "Hooker Creek",
//       "Longitude": -18.3367004395,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Darwin",
//       "Type": "airport"
//     },
//     {
//       "ID": 6274,
//       "Airport Code [ICAO]": "YHOT",
//       "Airport Code iATA,FAA": "MHU",
//       "Airport Name": "Mount Hotham Airport",
//       "Altitude (Ft)": 4260,
//       "Country": "Australia",
//       "Latitude": 147.333999634,
//       "Location": "Mount Hotham",
//       "Longitude": -37.0475006104,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Hobart",
//       "Type": "airport"
//     },
//     {
//       "ID": 6275,
//       "Airport Code [ICAO]": "YHUG",
//       "Airport Code iATA,FAA": "HGD",
//       "Airport Name": "Hughenden Airport",
//       "Altitude (Ft)": 1043,
//       "Country": "Australia",
//       "Latitude": 144.22500610351562,
//       "Location": "Hughenden",
//       "Longitude": -20.815000534057617,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6276,
//       "Airport Code [ICAO]": "YJLC",
//       "Airport Code iATA,FAA": "JCK",
//       "Airport Name": "Julia Creek Airport",
//       "Altitude (Ft)": 404,
//       "Country": "Australia",
//       "Latitude": 141.72300720214844,
//       "Location": "Julia Creek",
//       "Longitude": -20.66830062866211,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6277,
//       "Airport Code [ICAO]": "YKBR",
//       "Airport Code iATA,FAA": "KAX",
//       "Airport Name": "Kalbarri Airport",
//       "Altitude (Ft)": 157,
//       "Country": "Australia",
//       "Latitude": 114.259169,
//       "Location": "Kalbarri",
//       "Longitude": -27.692813,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 6278,
//       "Airport Code [ICAO]": "YKII",
//       "Airport Code iATA,FAA": "KNS",
//       "Airport Name": "King Island Airport",
//       "Altitude (Ft)": 132,
//       "Country": "Australia",
//       "Latitude": 143.8780059814453,
//       "Location": "King Island",
//       "Longitude": -39.877498626708984,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Melbourne",
//       "Type": "airport"
//     },
//     {
//       "ID": 6279,
//       "Airport Code [ICAO]": "YKKG",
//       "Airport Code iATA,FAA": "KFG",
//       "Airport Name": "Kalkgurung Airport",
//       "Altitude (Ft)": 646,
//       "Country": "Australia",
//       "Latitude": 130.80799865722656,
//       "Location": "Kalkgurung",
//       "Longitude": -17.431900024414062,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Darwin",
//       "Type": "airport"
//     },
//     {
//       "ID": 6280,
//       "Airport Code [ICAO]": "YKMB",
//       "Airport Code iATA,FAA": "KRB",
//       "Airport Name": "Karumba Airport",
//       "Altitude (Ft)": 5,
//       "Country": "Australia",
//       "Latitude": 140.8300018310547,
//       "Location": "Karumba",
//       "Longitude": -17.45669937133789,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6281,
//       "Airport Code [ICAO]": "YKOW",
//       "Airport Code iATA,FAA": "KWM",
//       "Airport Name": "Kowanyama Airport",
//       "Altitude (Ft)": 35,
//       "Country": "Australia",
//       "Latitude": 141.75100708007812,
//       "Location": "Kowanyama",
//       "Longitude": -15.485600471496582,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6282,
//       "Airport Code [ICAO]": "YKUB",
//       "Airport Code iATA,FAA": "KUG",
//       "Airport Name": "Kubin Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Australia",
//       "Latitude": 142.218002319,
//       "Location": "Kubin",
//       "Longitude": -10.225000381500001,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6283,
//       "Airport Code [ICAO]": "YLEO",
//       "Airport Code iATA,FAA": "LNO",
//       "Airport Name": "Leonora Airport",
//       "Altitude (Ft)": 1217,
//       "Country": "Australia",
//       "Latitude": 121.31500244140625,
//       "Location": "Leonora",
//       "Longitude": -28.87809944152832,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 6284,
//       "Airport Code [ICAO]": "YLEV",
//       "Airport Code iATA,FAA": "LEL",
//       "Airport Name": "Lake Evella Airport",
//       "Altitude (Ft)": 256,
//       "Country": "Australia",
//       "Latitude": 135.80599975585938,
//       "Location": "Lake Evella",
//       "Longitude": -12.498900413513184,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Darwin",
//       "Type": "airport"
//     },
//     {
//       "ID": 6285,
//       "Airport Code [ICAO]": "YLHI",
//       "Airport Code iATA,FAA": "LDH",
//       "Airport Name": "Lord Howe Island Airport",
//       "Altitude (Ft)": 5,
//       "Country": "Australia",
//       "Latitude": 159.07699585,
//       "Location": "Lord Howe Island",
//       "Longitude": -31.5382995605,
//       "GMT Offset": "10.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Lord_Howe",
//       "Type": "airport"
//     },
//     {
//       "ID": 6286,
//       "Airport Code [ICAO]": "YLHR",
//       "Airport Code iATA,FAA": "IRG",
//       "Airport Name": "Lockhart River Airport",
//       "Altitude (Ft)": 77,
//       "Country": "Australia",
//       "Latitude": 143.304993,
//       "Location": "Lockhart River",
//       "Longitude": -12.7869,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6287,
//       "Airport Code [ICAO]": "YLIS",
//       "Airport Code iATA,FAA": "LSY",
//       "Airport Name": "Lismore Airport",
//       "Altitude (Ft)": 35,
//       "Country": "Australia",
//       "Latitude": 153.259994507,
//       "Location": "Lismore",
//       "Longitude": -28.8302993774,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 6288,
//       "Airport Code [ICAO]": "YLRD",
//       "Airport Code iATA,FAA": "LHG",
//       "Airport Name": "Lightning Ridge Airport",
//       "Altitude (Ft)": 540,
//       "Country": "Australia",
//       "Latitude": 147.98399353027344,
//       "Location": "Lightning Ridge",
//       "Longitude": -29.45669937133789,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 6289,
//       "Airport Code [ICAO]": "YLRE",
//       "Airport Code iATA,FAA": "LRE",
//       "Airport Name": "Longreach Airport",
//       "Altitude (Ft)": 627,
//       "Country": "Australia",
//       "Latitude": 144.279998779,
//       "Location": "Longreach",
//       "Longitude": -23.4342002869,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6290,
//       "Airport Code [ICAO]": "YLST",
//       "Airport Code iATA,FAA": "LER",
//       "Airport Name": "Leinster Airport",
//       "Altitude (Ft)": 1631,
//       "Country": "Australia",
//       "Latitude": 120.7030029296875,
//       "Location": "Leinster",
//       "Longitude": -27.843299865722656,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 6291,
//       "Airport Code [ICAO]": "YLTN",
//       "Airport Code iATA,FAA": "LVO",
//       "Airport Name": "Laverton Airport",
//       "Altitude (Ft)": 1530,
//       "Country": "Australia",
//       "Latitude": 122.42400360107422,
//       "Location": "Laverton",
//       "Longitude": -28.61359977722168,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 6292,
//       "Airport Code [ICAO]": "YMAA",
//       "Airport Code iATA,FAA": "UBB",
//       "Airport Name": "Mabuiag Island Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 142.18299865722656,
//       "Location": "Mabuiag Island",
//       "Longitude": -9.949999809265137,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6293,
//       "Airport Code [ICAO]": "YMEK",
//       "Airport Code iATA,FAA": "MKR",
//       "Airport Name": "Meekatharra Airport",
//       "Altitude (Ft)": 1713,
//       "Country": "Australia",
//       "Latitude": 118.5479965209961,
//       "Location": "Meekatharra",
//       "Longitude": -26.6117000579834,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 6294,
//       "Airport Code [ICAO]": "YMER",
//       "Airport Code iATA,FAA": "MIM",
//       "Airport Name": "Merimbula Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Australia",
//       "Latitude": 149.901000977,
//       "Location": "Merimbula",
//       "Longitude": -36.9085998535,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 6295,
//       "Airport Code [ICAO]": "YMGB",
//       "Airport Code iATA,FAA": "MGT",
//       "Airport Name": "Milingimbi Airport",
//       "Altitude (Ft)": 53,
//       "Country": "Australia",
//       "Latitude": 134.893997192,
//       "Location": "Milingimbi",
//       "Longitude": -12.0944004059,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Darwin",
//       "Type": "airport"
//     },
//     {
//       "ID": 6296,
//       "Airport Code [ICAO]": "YMGD",
//       "Airport Code iATA,FAA": "MNG",
//       "Airport Name": "Maningrida Airport",
//       "Altitude (Ft)": 123,
//       "Country": "Australia",
//       "Latitude": 134.23399353,
//       "Location": "Maningrida",
//       "Longitude": -12.0560998917,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Darwin",
//       "Type": "airport"
//     },
//     {
//       "ID": 6297,
//       "Airport Code [ICAO]": "YMHU",
//       "Airport Code iATA,FAA": "MCV",
//       "Airport Name": "McArthur River Mine Airport",
//       "Altitude (Ft)": 131,
//       "Country": "Australia",
//       "Latitude": 136.083999634,
//       "Location": "McArthur River Mine",
//       "Longitude": -16.4424991608,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Darwin",
//       "Type": "airport"
//     },
//     {
//       "ID": 6298,
//       "Airport Code [ICAO]": "YMIA",
//       "Airport Code iATA,FAA": "MQL",
//       "Airport Name": "Mildura Airport",
//       "Altitude (Ft)": 167,
//       "Country": "Australia",
//       "Latitude": 142.085998535,
//       "Location": "Mildura",
//       "Longitude": -34.229198455799995,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Hobart",
//       "Type": "airport"
//     },
//     {
//       "ID": 6299,
//       "Airport Code [ICAO]": "YMOG",
//       "Airport Code iATA,FAA": "MMG",
//       "Airport Name": "Mount Magnet Airport",
//       "Altitude (Ft)": 1354,
//       "Country": "Australia",
//       "Latitude": 117.84200286865234,
//       "Location": "Mount Magnet",
//       "Longitude": -28.116100311279297,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 6300,
//       "Airport Code [ICAO]": "YMOR",
//       "Airport Code iATA,FAA": "MRZ",
//       "Airport Name": "Moree Airport",
//       "Altitude (Ft)": 701,
//       "Country": "Australia",
//       "Latitude": 149.845001221,
//       "Location": "Moree",
//       "Longitude": -29.498899459799997,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 6301,
//       "Airport Code [ICAO]": "YMRB",
//       "Airport Code iATA,FAA": "MOV",
//       "Airport Name": "Moranbah Airport",
//       "Altitude (Ft)": 770,
//       "Country": "Australia",
//       "Latitude": 148.07699585,
//       "Location": "Moranbah",
//       "Longitude": -22.057800293,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6302,
//       "Airport Code [ICAO]": "YMRY",
//       "Airport Code iATA,FAA": "MYA",
//       "Airport Name": "Moruya Airport",
//       "Altitude (Ft)": 14,
//       "Country": "Australia",
//       "Latitude": 150.143997192,
//       "Location": "Moruya",
//       "Longitude": -35.8978004456,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 6303,
//       "Airport Code [ICAO]": "YMTG",
//       "Airport Code iATA,FAA": "MGB",
//       "Airport Name": "Mount Gambier Airport",
//       "Altitude (Ft)": 212,
//       "Country": "Australia",
//       "Latitude": 140.78500366210938,
//       "Location": "Mount Gambier",
//       "Longitude": -37.745601654052734,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Adelaide",
//       "Type": "airport"
//     },
//     {
//       "ID": 6304,
//       "Airport Code [ICAO]": "YMTI",
//       "Airport Code iATA,FAA": "ONG",
//       "Airport Name": "Mornington Island Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Australia",
//       "Latitude": 139.17799377441406,
//       "Location": "Mornington Island",
//       "Longitude": -16.662500381469727,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6305,
//       "Airport Code [ICAO]": "YMUI",
//       "Airport Code iATA,FAA": "MYI",
//       "Airport Name": "Murray Island Airport",
//       "Altitude (Ft)": 300,
//       "Country": "Australia",
//       "Latitude": 144.054992676,
//       "Location": "Murray Island",
//       "Longitude": -9.91666984558,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6306,
//       "Airport Code [ICAO]": "YMYB",
//       "Airport Code iATA,FAA": "MBH",
//       "Airport Name": "Maryborough Airport",
//       "Altitude (Ft)": 38,
//       "Country": "Australia",
//       "Latitude": 152.714996,
//       "Location": "Maryborough",
//       "Longitude": -25.5133,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6307,
//       "Airport Code [ICAO]": "YNAR",
//       "Airport Code iATA,FAA": "NRA",
//       "Airport Name": "Narrandera Airport",
//       "Altitude (Ft)": 474,
//       "Country": "Australia",
//       "Latitude": 146.511993408,
//       "Location": "Narrandera",
//       "Longitude": -34.7022018433,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 6308,
//       "Airport Code [ICAO]": "YNBR",
//       "Airport Code iATA,FAA": "NAA",
//       "Airport Name": "Narrabri Airport",
//       "Altitude (Ft)": 788,
//       "Country": "Australia",
//       "Latitude": 149.82699585,
//       "Location": "Narrabri",
//       "Longitude": -30.3192005157,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 6309,
//       "Airport Code [ICAO]": "YNTN",
//       "Airport Code iATA,FAA": "NTN",
//       "Airport Name": "Normanton Airport",
//       "Altitude (Ft)": 73,
//       "Country": "Australia",
//       "Latitude": 141.069664,
//       "Location": "Normanton",
//       "Longitude": -17.68409,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6310,
//       "Airport Code [ICAO]": "YNWN",
//       "Airport Code iATA,FAA": "ZNE",
//       "Airport Name": "Newman Airport",
//       "Altitude (Ft)": 1724,
//       "Country": "Australia",
//       "Latitude": 119.803001404,
//       "Location": "Newman",
//       "Longitude": -23.417800903299998,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 6311,
//       "Airport Code [ICAO]": "YOLD",
//       "Airport Code iATA,FAA": "OLP",
//       "Airport Name": "Olympic Dam Airport",
//       "Altitude (Ft)": 343,
//       "Country": "Australia",
//       "Latitude": 136.876998901,
//       "Location": "Olympic Dam",
//       "Longitude": -30.485000610399997,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Adelaide",
//       "Type": "airport"
//     },
//     {
//       "ID": 6312,
//       "Airport Code [ICAO]": "YPAG",
//       "Airport Code iATA,FAA": "PUG",
//       "Airport Name": "Port Augusta Airport",
//       "Altitude (Ft)": 56,
//       "Country": "Australia",
//       "Latitude": 137.7169952392578,
//       "Location": "Argyle",
//       "Longitude": -32.506900787353516,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Adelaide",
//       "Type": "airport"
//     },
//     {
//       "ID": 6313,
//       "Airport Code [ICAO]": "YPAM",
//       "Airport Code iATA,FAA": "PMK",
//       "Airport Name": "Palm Island Airport",
//       "Altitude (Ft)": 28,
//       "Country": "Australia",
//       "Latitude": 146.58099365234375,
//       "Location": "Palm Island",
//       "Longitude": -18.755300521850586,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6314,
//       "Airport Code [ICAO]": "YPBO",
//       "Airport Code iATA,FAA": "PBO",
//       "Airport Name": "Paraburdoo Airport",
//       "Altitude (Ft)": 1406,
//       "Country": "Australia",
//       "Latitude": 117.745002747,
//       "Location": "Paraburdoo",
//       "Longitude": -23.1711006165,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 6315,
//       "Airport Code [ICAO]": "YPCC",
//       "Airport Code iATA,FAA": "CCK",
//       "Airport Name": "Cocos (Keeling) Islands Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Cocos (Keeling) Islands",
//       "Latitude": 96.8339004517,
//       "Location": "Cocos Keeling Island",
//       "Longitude": -12.1883001328,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Indian/Cocos",
//       "Type": "airport"
//     },
//     {
//       "ID": 6316,
//       "Airport Code [ICAO]": "YPGV",
//       "Airport Code iATA,FAA": "GOV",
//       "Airport Name": "Gove Airport",
//       "Altitude (Ft)": 192,
//       "Country": "Australia",
//       "Latitude": 136.817993164,
//       "Location": "Gove",
//       "Longitude": -12.269399642899998,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Darwin",
//       "Type": "airport"
//     },
//     {
//       "ID": 6317,
//       "Airport Code [ICAO]": "YPKS",
//       "Airport Code iATA,FAA": "PKE",
//       "Airport Name": "Parkes Airport",
//       "Altitude (Ft)": 1069,
//       "Country": "Australia",
//       "Latitude": 148.238998413,
//       "Location": "Parkes",
//       "Longitude": -33.131401062,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 6318,
//       "Airport Code [ICAO]": "YPLC",
//       "Airport Code iATA,FAA": "PLO",
//       "Airport Name": "Port Lincoln Airport",
//       "Altitude (Ft)": 36,
//       "Country": "Australia",
//       "Latitude": 135.880004883,
//       "Location": "Port Lincoln",
//       "Longitude": -34.6053009033,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Adelaide",
//       "Type": "airport"
//     },
//     {
//       "ID": 6319,
//       "Airport Code [ICAO]": "YPMP",
//       "Airport Code iATA,FAA": "EDR",
//       "Airport Name": "Pormpuraaw Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Australia",
//       "Latitude": 141.60908,
//       "Location": "Pormpuraaw",
//       "Longitude": -14.896451,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6320,
//       "Airport Code [ICAO]": "YPMQ",
//       "Airport Code iATA,FAA": "PQQ",
//       "Airport Name": "Port Macquarie Airport",
//       "Altitude (Ft)": 12,
//       "Country": "Australia",
//       "Latitude": 152.863006592,
//       "Location": "Port Macquarie",
//       "Longitude": -31.4358005524,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 6321,
//       "Airport Code [ICAO]": "YPOD",
//       "Airport Code iATA,FAA": "PTJ",
//       "Airport Name": "Portland Airport",
//       "Altitude (Ft)": 265,
//       "Country": "Australia",
//       "Latitude": 141.4709930419922,
//       "Location": "Portland",
//       "Longitude": -38.31809997558594,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Hobart",
//       "Type": "airport"
//     },
//     {
//       "ID": 6322,
//       "Airport Code [ICAO]": "YQLP",
//       "Airport Code iATA,FAA": "ULP",
//       "Airport Name": "Quilpie Airport",
//       "Altitude (Ft)": 655,
//       "Country": "Australia",
//       "Latitude": 144.2530059814453,
//       "Location": "Quilpie",
//       "Longitude": -26.612199783325195,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6323,
//       "Airport Code [ICAO]": "YRNG",
//       "Airport Code iATA,FAA": "RAM",
//       "Airport Name": "Ramingining Airport",
//       "Altitude (Ft)": 206,
//       "Country": "Australia",
//       "Latitude": 134.8979949951172,
//       "Location": "Ramingining",
//       "Longitude": -12.356399536132812,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Darwin",
//       "Type": "airport"
//     },
//     {
//       "ID": 6324,
//       "Airport Code [ICAO]": "YROM",
//       "Airport Code iATA,FAA": "RMA",
//       "Airport Name": "Roma Airport",
//       "Altitude (Ft)": 1032,
//       "Country": "Australia",
//       "Latitude": 148.774993896,
//       "Location": "Roma",
//       "Longitude": -26.545000076300003,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6325,
//       "Airport Code [ICAO]": "YSGE",
//       "Airport Code iATA,FAA": "SGO",
//       "Airport Name": "St George Airport",
//       "Altitude (Ft)": 656,
//       "Country": "Australia",
//       "Latitude": 148.59500122070312,
//       "Location": "St George",
//       "Longitude": -28.049699783325195,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6326,
//       "Airport Code [ICAO]": "YSHK",
//       "Airport Code iATA,FAA": "MJK",
//       "Airport Name": "Shark Bay Airport",
//       "Altitude (Ft)": 111,
//       "Country": "Australia",
//       "Latitude": 113.577003479,
//       "Location": "Shark Bay",
//       "Longitude": -25.8938999176,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 6327,
//       "Airport Code [ICAO]": "YSII",
//       "Airport Code iATA,FAA": "SBR",
//       "Airport Name": "Saibai Island Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Australia",
//       "Latitude": 142.625,
//       "Location": "Saibai Island",
//       "Longitude": -9.378330230710002,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6328,
//       "Airport Code [ICAO]": "YSRN",
//       "Airport Code iATA,FAA": "SRN",
//       "Airport Name": "Strahan Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Australia",
//       "Latitude": 145.29200744628906,
//       "Location": "Strahan",
//       "Longitude": -42.154998779296875,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Melbourne",
//       "Type": "airport"
//     },
//     {
//       "ID": 6329,
//       "Airport Code [ICAO]": "YTGM",
//       "Airport Code iATA,FAA": "XTG",
//       "Airport Name": "Thargomindah Airport",
//       "Altitude (Ft)": 433,
//       "Country": "Australia",
//       "Latitude": 143.81100463867188,
//       "Location": "Thargomindah",
//       "Longitude": -27.986400604248047,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6330,
//       "Airport Code [ICAO]": "YTNK",
//       "Airport Code iATA,FAA": "TCA",
//       "Airport Name": "Tennant Creek Airport",
//       "Altitude (Ft)": 1236,
//       "Country": "Australia",
//       "Latitude": 134.18299865722656,
//       "Location": "Tennant Creek",
//       "Longitude": -19.6343994140625,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Darwin",
//       "Type": "airport"
//     },
//     {
//       "ID": 6331,
//       "Airport Code [ICAO]": "YVRD",
//       "Airport Code iATA,FAA": "VCD",
//       "Airport Name": "Victoria River Downs Airport",
//       "Altitude (Ft)": 89,
//       "Country": "Australia",
//       "Latitude": 131.00497436523438,
//       "Location": "Victoria River Downs",
//       "Longitude": -16.402124404907227,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Darwin",
//       "Type": "airport"
//     },
//     {
//       "ID": 6332,
//       "Airport Code [ICAO]": "YWBS",
//       "Airport Code iATA,FAA": "SYU",
//       "Airport Name": "Warraber Island Airport",
//       "Altitude (Ft)": 3,
//       "Country": "Australia",
//       "Latitude": 142.8249969482422,
//       "Location": "Sue Islet",
//       "Longitude": -10.20829963684082,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6333,
//       "Airport Code [ICAO]": "YWDH",
//       "Airport Code iATA,FAA": "WNR",
//       "Airport Name": "Windorah Airport",
//       "Altitude (Ft)": 452,
//       "Country": "Australia",
//       "Latitude": 142.66700744628906,
//       "Location": "Windorah",
//       "Longitude": -25.41309928894043,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6334,
//       "Airport Code [ICAO]": "YWHA",
//       "Airport Code iATA,FAA": "WYA",
//       "Airport Name": "Whyalla Airport",
//       "Altitude (Ft)": 41,
//       "Country": "Australia",
//       "Latitude": 137.51400756835938,
//       "Location": "Whyalla",
//       "Longitude": -33.05889892578125,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Adelaide",
//       "Type": "airport"
//     },
//     {
//       "ID": 6335,
//       "Airport Code [ICAO]": "YWLU",
//       "Airport Code iATA,FAA": "WUN",
//       "Airport Name": "Wiluna Airport",
//       "Altitude (Ft)": 1649,
//       "Country": "Australia",
//       "Latitude": 120.22100067138672,
//       "Location": "Wiluna",
//       "Longitude": -26.629199981689453,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 6336,
//       "Airport Code [ICAO]": "YWOL",
//       "Airport Code iATA,FAA": "WOL",
//       "Airport Name": "Wollongong Airport",
//       "Altitude (Ft)": 31,
//       "Country": "Australia",
//       "Latitude": 150.78900146484375,
//       "Location": "Wollongong",
//       "Longitude": -34.561100006103516,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 6337,
//       "Airport Code [ICAO]": "YWTN",
//       "Airport Code iATA,FAA": "WIN",
//       "Airport Name": "Winton Airport",
//       "Altitude (Ft)": 638,
//       "Country": "Australia",
//       "Latitude": 143.08599853515625,
//       "Location": "Winton",
//       "Longitude": -22.36359977722168,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6338,
//       "Airport Code [ICAO]": "YWYY",
//       "Airport Code iATA,FAA": "BWT",
//       "Airport Name": "Wynyard Airport",
//       "Altitude (Ft)": 62,
//       "Country": "Australia",
//       "Latitude": 145.7310028076172,
//       "Location": "Burnie",
//       "Longitude": -40.9989013671875,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Melbourne",
//       "Type": "airport"
//     },
//     {
//       "ID": 6339,
//       "Airport Code [ICAO]": "YYKI",
//       "Airport Code iATA,FAA": "OKR",
//       "Airport Name": "Yorke Island Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Australia",
//       "Latitude": 143.405673,
//       "Location": "Yorke Island",
//       "Longitude": -9.752801,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6340,
//       "Airport Code [ICAO]": "YYMI",
//       "Airport Code iATA,FAA": "XMY",
//       "Airport Name": "Yam Island Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 142.7760009765625,
//       "Location": "Yam Island",
//       "Longitude": -9.90110969543457,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6341,
//       "Airport Code [ICAO]": "ZBNY",
//       "Airport Code iATA,FAA": "NAY",
//       "Airport Name": "Beijing Nanyuan Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 116.38800048828125,
//       "Location": "Beijing",
//       "Longitude": 39.782798767089844,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6342,
//       "Airport Code [ICAO]": "ZBCF",
//       "Airport Code iATA,FAA": "CIF",
//       "Airport Name": "Chifeng Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 118.90799713134766,
//       "Location": "Chifeng",
//       "Longitude": 42.23500061035156,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6343,
//       "Airport Code [ICAO]": "ZBCZ",
//       "Airport Code iATA,FAA": "CIH",
//       "Airport Name": "Changzhi Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 113.125999,
//       "Location": "Changzhi",
//       "Longitude": 36.247501,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6344,
//       "Airport Code [ICAO]": "ZBDT",
//       "Airport Code iATA,FAA": "DAT",
//       "Airport Name": "Datong Airport",
//       "Altitude (Ft)": 3442,
//       "Country": "China",
//       "Latitude": 113.482002,
//       "Location": "Datong",
//       "Longitude": 40.060299,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6345,
//       "Airport Code [ICAO]": "ZBHH",
//       "Airport Code iATA,FAA": "HET",
//       "Airport Name": "Baita International Airport",
//       "Altitude (Ft)": 3556,
//       "Country": "China",
//       "Latitude": 111.823997,
//       "Location": "Hohhot",
//       "Longitude": 40.851398,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6346,
//       "Airport Code [ICAO]": "ZBOW",
//       "Airport Code iATA,FAA": "BAV",
//       "Airport Name": "Baotou Airport",
//       "Altitude (Ft)": 3321,
//       "Country": "China",
//       "Latitude": 109.99700164794922,
//       "Location": "Baotou",
//       "Longitude": 40.560001373291016,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6347,
//       "Airport Code [ICAO]": "ZBSJ",
//       "Airport Code iATA,FAA": "SJW",
//       "Airport Name": "Shijiazhuang Daguocun International Airport",
//       "Altitude (Ft)": 233,
//       "Country": "China",
//       "Latitude": 114.6969985961914,
//       "Location": "Shijiazhuang",
//       "Longitude": 38.28070068359375,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6348,
//       "Airport Code [ICAO]": "ZBTL",
//       "Airport Code iATA,FAA": "TGO",
//       "Airport Name": "Tongliao Airport",
//       "Altitude (Ft)": 2395,
//       "Country": "China",
//       "Latitude": 122.199997,
//       "Location": "Tongliao",
//       "Longitude": 43.556702,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6349,
//       "Airport Code [ICAO]": "ZBUL",
//       "Airport Code iATA,FAA": "HLH",
//       "Airport Name": "Ulanhot Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 122.008333,
//       "Location": "Ulanhot",
//       "Longitude": 46.195333,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6350,
//       "Airport Code [ICAO]": "ZBXH",
//       "Airport Code iATA,FAA": "XIL",
//       "Airport Name": "Xilinhot Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 115.96399688720703,
//       "Location": "Xilinhot",
//       "Longitude": 43.91559982299805,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6351,
//       "Airport Code [ICAO]": "ZGBH",
//       "Airport Code iATA,FAA": "BHY",
//       "Airport Name": "Beihai Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 109.293999,
//       "Location": "Beihai",
//       "Longitude": 21.5394,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6352,
//       "Airport Code [ICAO]": "ZGCD",
//       "Airport Code iATA,FAA": "CGD",
//       "Airport Name": "Changde Airport",
//       "Altitude (Ft)": 128,
//       "Country": "China",
//       "Latitude": 111.63999939,
//       "Location": "Changde",
//       "Longitude": 28.9188995361,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6353,
//       "Airport Code [ICAO]": "ZGDY",
//       "Airport Code iATA,FAA": "DYG",
//       "Airport Name": "Dayong Airport",
//       "Altitude (Ft)": 692,
//       "Country": "China",
//       "Latitude": 110.443001,
//       "Location": "Dayong",
//       "Longitude": 29.1028,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6354,
//       "Airport Code [ICAO]": "ZGMX",
//       "Airport Code iATA,FAA": "MXZ",
//       "Airport Name": "Meixian Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 116.13300323486328,
//       "Location": "Meixian",
//       "Longitude": 24.350000381469727,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6355,
//       "Airport Code [ICAO]": "ZGSD",
//       "Airport Code iATA,FAA": "ZUH",
//       "Airport Name": "Zhuhai Jinwan Airport",
//       "Altitude (Ft)": 23,
//       "Country": "China",
//       "Latitude": 113.375999,
//       "Location": "Zhuhai",
//       "Longitude": 22.006399,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6356,
//       "Airport Code [ICAO]": "ZGZH",
//       "Airport Code iATA,FAA": "LZH",
//       "Airport Name": "Liuzhou Bailian Airport",
//       "Altitude (Ft)": 295,
//       "Country": "China",
//       "Latitude": 109.390999,
//       "Location": "Liuzhou",
//       "Longitude": 24.2075,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6357,
//       "Airport Code [ICAO]": "ZGZJ",
//       "Airport Code iATA,FAA": "ZHA",
//       "Airport Name": "Zhanjiang Airport",
//       "Altitude (Ft)": 125,
//       "Country": "China",
//       "Latitude": 110.358002,
//       "Location": "Zhanjiang",
//       "Longitude": 21.214399,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6358,
//       "Airport Code [ICAO]": "ZHES",
//       "Airport Code iATA,FAA": "ENH",
//       "Airport Name": "Enshi Airport",
//       "Altitude (Ft)": 1605,
//       "Country": "China",
//       "Latitude": 109.48500061,
//       "Location": "Enshi",
//       "Longitude": 30.3202991486,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6359,
//       "Airport Code [ICAO]": "ZHNY",
//       "Airport Code iATA,FAA": "NNY",
//       "Airport Name": "Nanyang Jiangying Airport",
//       "Altitude (Ft)": 840,
//       "Country": "China",
//       "Latitude": 112.614998,
//       "Location": "Nanyang",
//       "Longitude": 32.980801,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6360,
//       "Airport Code [ICAO]": "ZHXF",
//       "Airport Code iATA,FAA": "XFN",
//       "Airport Name": "Xiangyang Liuji Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 112.291,
//       "Location": "Xiangfan",
//       "Longitude": 32.1506,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6361,
//       "Airport Code [ICAO]": "ZHYC",
//       "Airport Code iATA,FAA": "YIH",
//       "Airport Name": "Yichang Sanxia Airport",
//       "Altitude (Ft)": 673,
//       "Country": "China",
//       "Latitude": 111.479988,
//       "Location": "Yichang",
//       "Longitude": 30.55655,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6362,
//       "Airport Code [ICAO]": "ZLAK",
//       "Airport Code iATA,FAA": "AKA",
//       "Airport Name": "Ankang Wulipu Airport",
//       "Altitude (Ft)": 860,
//       "Country": "China",
//       "Latitude": 108.931,
//       "Location": "Ankang",
//       "Longitude": 32.708099,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6363,
//       "Airport Code [ICAO]": "ZLGM",
//       "Airport Code iATA,FAA": "GOQ",
//       "Airport Name": "Golmud Airport",
//       "Altitude (Ft)": 9334,
//       "Country": "China",
//       "Latitude": 94.786102,
//       "Location": "Golmud",
//       "Longitude": 36.4006,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6364,
//       "Airport Code [ICAO]": "ZLHZ",
//       "Airport Code iATA,FAA": "HZG",
//       "Airport Name": "Hanzhong Chenggu Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 107.206014,
//       "Location": "Hanzhong",
//       "Longitude": 33.134136,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6365,
//       "Airport Code [ICAO]": "ZLQY",
//       "Airport Code iATA,FAA": "IQN",
//       "Airport Name": "Qingyang Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 107.602997,
//       "Location": "Qingyang",
//       "Longitude": 35.799702,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6366,
//       "Airport Code [ICAO]": "ZLXN",
//       "Airport Code iATA,FAA": "XNN",
//       "Airport Name": "Xining Caojiabu Airport",
//       "Altitude (Ft)": 7119,
//       "Country": "China",
//       "Latitude": 102.042999,
//       "Location": "Xining",
//       "Longitude": 36.5275,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6367,
//       "Airport Code [ICAO]": "ZLYA",
//       "Airport Code iATA,FAA": "ENY",
//       "Airport Name": "Yan'an Ershilipu Airport",
//       "Altitude (Ft)": 3100,
//       "Country": "China",
//       "Latitude": 109.554001,
//       "Location": "Yan'an",
//       "Longitude": 36.636902,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6368,
//       "Airport Code [ICAO]": "ZLYL",
//       "Airport Code iATA,FAA": "UYN",
//       "Airport Name": "Yulin Yuyang Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 109.590927,
//       "Location": "Yulin",
//       "Longitude": 38.35971,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6369,
//       "Airport Code [ICAO]": "ZMAH",
//       "Airport Code iATA,FAA": "AVK",
//       "Airport Name": "Arvaikheer Airport",
//       "Altitude (Ft)": 5932,
//       "Country": "Mongolia",
//       "Latitude": 102.802001953125,
//       "Location": "Arvaikheer",
//       "Longitude": 46.250301361083984,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Ulaanbaatar",
//       "Type": "airport"
//     },
//     {
//       "ID": 6370,
//       "Airport Code [ICAO]": "ZMAT",
//       "Airport Code iATA,FAA": "LTI",
//       "Airport Name": "Altai Airport",
//       "Altitude (Ft)": 7260,
//       "Country": "Mongolia",
//       "Latitude": 96.22109985351562,
//       "Location": "Altai",
//       "Longitude": 46.376399993896484,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Ulaanbaatar",
//       "Type": "airport"
//     },
//     {
//       "ID": 6371,
//       "Airport Code [ICAO]": "ZMBH",
//       "Airport Code iATA,FAA": "BYN",
//       "Airport Name": "Bayankhongor Airport",
//       "Altitude (Ft)": 6085,
//       "Country": "Mongolia",
//       "Latitude": 100.7040023803711,
//       "Location": "Bayankhongor",
//       "Longitude": 46.163299560546875,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Ulaanbaatar",
//       "Type": "airport"
//     },
//     {
//       "ID": 6372,
//       "Airport Code [ICAO]": "ZMDZ",
//       "Airport Code iATA,FAA": "DLZ",
//       "Airport Name": "Dalanzadgad Airport",
//       "Altitude (Ft)": 4787,
//       "Country": "Mongolia",
//       "Latitude": 104.43000030517578,
//       "Location": "Dalanzadgad",
//       "Longitude": 43.59170150756836,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Ulaanbaatar",
//       "Type": "airport"
//     },
//     {
//       "ID": 6373,
//       "Airport Code [ICAO]": "ZMKD",
//       "Airport Code iATA,FAA": "HVD",
//       "Airport Name": "Khovd Airport",
//       "Altitude (Ft)": 4898,
//       "Country": "Mongolia",
//       "Latitude": 91.6281967163086,
//       "Location": "Khovd",
//       "Longitude": 47.9541015625,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Hovd",
//       "Type": "airport"
//     },
//     {
//       "ID": 6374,
//       "Airport Code [ICAO]": "ZMMN",
//       "Airport Code iATA,FAA": "MXV",
//       "Airport Name": "Mörön Airport",
//       "Altitude (Ft)": 4272,
//       "Country": "Mongolia",
//       "Latitude": 100.0989990234375,
//       "Location": "Muren",
//       "Longitude": 49.663299560546875,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Ulaanbaatar",
//       "Type": "airport"
//     },
//     {
//       "ID": 6375,
//       "Airport Code [ICAO]": "ZPDQ",
//       "Airport Code iATA,FAA": "DIG",
//       "Airport Name": "Diqing Airport",
//       "Altitude (Ft)": 10761,
//       "Country": "China",
//       "Latitude": 99.6772,
//       "Location": "Shangri-La",
//       "Longitude": 27.7936,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6376,
//       "Airport Code [ICAO]": "ZPLX",
//       "Airport Code iATA,FAA": "LUM",
//       "Airport Name": "Mangshi Airport",
//       "Altitude (Ft)": 2890,
//       "Country": "China",
//       "Latitude": 98.5317,
//       "Location": "Luxi",
//       "Longitude": 24.4011,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6377,
//       "Airport Code [ICAO]": "ZPSM",
//       "Airport Code iATA,FAA": "SYM",
//       "Airport Name": "Pu'er Simao Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 100.959,
//       "Location": "Simao",
//       "Longitude": 22.793301,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6378,
//       "Airport Code [ICAO]": "ZPZT",
//       "Airport Code iATA,FAA": "ZAT",
//       "Airport Name": "Zhaotong Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 103.75499725341797,
//       "Location": "Zhaotong",
//       "Longitude": 27.325599670410156,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6379,
//       "Airport Code [ICAO]": "ZSGZ",
//       "Airport Code iATA,FAA": "KOW",
//       "Airport Name": "Ganzhou Airport",
//       "Altitude (Ft)": 387,
//       "Country": "China",
//       "Latitude": 114.778889,
//       "Location": "Ganzhou",
//       "Longitude": 25.853333,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6380,
//       "Airport Code [ICAO]": "ZSJD",
//       "Airport Code iATA,FAA": "JDZ",
//       "Airport Name": "Jingdezhen Airport",
//       "Altitude (Ft)": 112,
//       "Country": "China",
//       "Latitude": 117.176002502,
//       "Location": "Jingdezhen",
//       "Longitude": 29.3386001587,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6381,
//       "Airport Code [ICAO]": "ZSJJ",
//       "Airport Code iATA,FAA": "JIU",
//       "Airport Name": "Jiujiang Lushan Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 115.801111,
//       "Location": "Jiujiang",
//       "Longitude": 29.476944,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6382,
//       "Airport Code [ICAO]": "ZSJU",
//       "Airport Code iATA,FAA": "JUZ",
//       "Airport Name": "Quzhou Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 118.899002,
//       "Location": "Quzhou",
//       "Longitude": 28.965799,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6383,
//       "Airport Code [ICAO]": "ZSLG",
//       "Airport Code iATA,FAA": "LYG",
//       "Airport Name": "Lianyungang Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 118.873611,
//       "Location": "Lianyungang",
//       "Longitude": 34.571667,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6384,
//       "Airport Code [ICAO]": "ZSLQ",
//       "Airport Code iATA,FAA": "HYN",
//       "Airport Name": "Huangyan Luqiao Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 121.42900085449219,
//       "Location": "Huangyan",
//       "Longitude": 28.56220054626465,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6385,
//       "Airport Code [ICAO]": "ZSLY",
//       "Airport Code iATA,FAA": "LYI",
//       "Airport Name": "Shubuling Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 118.41200256347656,
//       "Location": "Linyi",
//       "Longitude": 35.04610061645508,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6386,
//       "Airport Code [ICAO]": "ZSQZ",
//       "Airport Code iATA,FAA": "JJN",
//       "Airport Name": "Quanzhou Jinjiang International Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 118.589996,
//       "Location": "Quanzhou",
//       "Longitude": 24.7964,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6387,
//       "Airport Code [ICAO]": "ZSTX",
//       "Airport Code iATA,FAA": "TXN",
//       "Airport Name": "Tunxi International Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 118.25599670410156,
//       "Location": "Huangshan",
//       "Longitude": 29.733299255371094,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6388,
//       "Airport Code [ICAO]": "ZSWF",
//       "Airport Code iATA,FAA": "WEF",
//       "Airport Name": "Weifang Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 119.119003,
//       "Location": "Weifang",
//       "Longitude": 36.646702,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6389,
//       "Airport Code [ICAO]": "ZSWH",
//       "Airport Code iATA,FAA": "WEH",
//       "Airport Name": "Weihai Airport",
//       "Altitude (Ft)": 145,
//       "Country": "China",
//       "Latitude": 122.22899627685547,
//       "Location": "Weihai",
//       "Longitude": 37.18709945678711,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6390,
//       "Airport Code [ICAO]": "ZSWX",
//       "Airport Code iATA,FAA": "WUX",
//       "Airport Name": "Sunan Shuofang International Airport",
//       "Altitude (Ft)": 24,
//       "Country": "China",
//       "Latitude": 120.429000854,
//       "Location": "Wuxi",
//       "Longitude": 31.494400024399997,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6391,
//       "Airport Code [ICAO]": "ZSWY",
//       "Airport Code iATA,FAA": "WUS",
//       "Airport Name": "Nanping Wuyishan Airport",
//       "Altitude (Ft)": 614,
//       "Country": "China",
//       "Latitude": 118.000999,
//       "Location": "Wuyishan",
//       "Longitude": 27.7019,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6392,
//       "Airport Code [ICAO]": "ZSWZ",
//       "Airport Code iATA,FAA": "WNZ",
//       "Airport Name": "Wenzhou Longwan International Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 120.851997,
//       "Location": "Wenzhou",
//       "Longitude": 27.912201,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6393,
//       "Airport Code [ICAO]": "ZSYN",
//       "Airport Code iATA,FAA": "YNZ",
//       "Airport Name": "Yancheng Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 120.203056,
//       "Location": "Yancheng",
//       "Longitude": 33.425833,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6394,
//       "Airport Code [ICAO]": "ZSYW",
//       "Airport Code iATA,FAA": "YIW",
//       "Airport Name": "Yiwu Airport",
//       "Altitude (Ft)": 262,
//       "Country": "China",
//       "Latitude": 120.031997681,
//       "Location": "Yiwu",
//       "Longitude": 29.3446998596,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6395,
//       "Airport Code [ICAO]": "ZSZS",
//       "Airport Code iATA,FAA": "HSN",
//       "Airport Name": "Zhoushan Airport",
//       "Altitude (Ft)": 3,
//       "Country": "China",
//       "Latitude": 122.361999512,
//       "Location": "Zhoushan",
//       "Longitude": 29.9342002869,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6396,
//       "Airport Code [ICAO]": "ZUBD",
//       "Airport Code iATA,FAA": "BPX",
//       "Airport Name": "Qamdo Bangda Airport",
//       "Altitude (Ft)": 14219,
//       "Country": "China",
//       "Latitude": 97.1082992553711,
//       "Location": "Bangda",
//       "Longitude": 30.553600311279297,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6397,
//       "Airport Code [ICAO]": "ZUDX",
//       "Airport Code iATA,FAA": "DAX",
//       "Airport Name": "Dachuan Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 107.4295,
//       "Location": "Dazhou",
//       "Longitude": 31.1302,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6398,
//       "Airport Code [ICAO]": "ZUGU",
//       "Airport Code iATA,FAA": "GYS",
//       "Airport Name": "Guangyuan Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 105.7020034790039,
//       "Location": "Guangyuan",
//       "Longitude": 32.3911018371582,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6399,
//       "Airport Code [ICAO]": "ZULZ",
//       "Airport Code iATA,FAA": "LZO",
//       "Airport Name": "Luzhou Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 105.39299774169922,
//       "Location": "Luzhou",
//       "Longitude": 28.85219955444336,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6400,
//       "Airport Code [ICAO]": "ZUMY",
//       "Airport Code iATA,FAA": "MIG",
//       "Airport Name": "Mianyang Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 104.74099731445312,
//       "Location": "Mianyang",
//       "Longitude": 31.4281005859375,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6401,
//       "Airport Code [ICAO]": "ZUNC",
//       "Airport Code iATA,FAA": "NAO",
//       "Airport Name": "Nanchong Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 106.1626,
//       "Location": "Nanchong",
//       "Longitude": 30.79545,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6402,
//       "Airport Code [ICAO]": "ZUNZ",
//       "Airport Code iATA,FAA": "LZY",
//       "Airport Name": "Nyingchi Airport",
//       "Altitude (Ft)": 9675,
//       "Country": "China",
//       "Latitude": 94.33529663085938,
//       "Location": "Nyingchi",
//       "Longitude": 29.303300857543945,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6403,
//       "Airport Code [ICAO]": "ZUWX",
//       "Airport Code iATA,FAA": "WXN",
//       "Airport Name": "Wanxian Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 108.433,
//       "Location": "Wanxian",
//       "Longitude": 30.8017,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6404,
//       "Airport Code [ICAO]": "ZWAK",
//       "Airport Code iATA,FAA": "AKU",
//       "Airport Name": "Aksu Airport",
//       "Altitude (Ft)": 3816,
//       "Country": "China",
//       "Latitude": 80.291702,
//       "Location": "Aksu",
//       "Longitude": 41.262501,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6405,
//       "Airport Code [ICAO]": "ZWCM",
//       "Airport Code iATA,FAA": "IQM",
//       "Airport Name": "Qiemo Yudu Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 85.465556,
//       "Location": "Qiemo",
//       "Longitude": 38.233611,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6406,
//       "Airport Code [ICAO]": "ZWKC",
//       "Airport Code iATA,FAA": "KCA",
//       "Airport Name": "Kuqa Airport",
//       "Altitude (Ft)": 3524,
//       "Country": "China",
//       "Latitude": 82.872917,
//       "Location": "Kuqa",
//       "Longitude": 41.677856,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6407,
//       "Airport Code [ICAO]": "ZWKL",
//       "Airport Code iATA,FAA": "KRL",
//       "Airport Name": "Korla Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 86.12889862060547,
//       "Location": "Korla",
//       "Longitude": 41.69779968261719,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6408,
//       "Airport Code [ICAO]": "ZWKM",
//       "Airport Code iATA,FAA": "KRY",
//       "Airport Name": "Karamay Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 84.9527,
//       "Location": "Karamay",
//       "Longitude": 45.46655,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6409,
//       "Airport Code [ICAO]": "ZWYN",
//       "Airport Code iATA,FAA": "YIN",
//       "Airport Name": "Yining Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 81.330299,
//       "Location": "Yining",
//       "Longitude": 43.955799,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6410,
//       "Airport Code [ICAO]": "ZYHE",
//       "Airport Code iATA,FAA": "HEK",
//       "Airport Name": "Heihe Airport",
//       "Altitude (Ft)": 8530,
//       "Country": "China",
//       "Latitude": 127.308883667,
//       "Location": "Heihe",
//       "Longitude": 50.1716209371,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6411,
//       "Airport Code [ICAO]": "ZYJM",
//       "Airport Code iATA,FAA": "JMU",
//       "Airport Name": "Jiamusi Airport",
//       "Altitude (Ft)": 262,
//       "Country": "China",
//       "Latitude": 130.464996338,
//       "Location": "Jiamusi",
//       "Longitude": 46.84339904789999,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6412,
//       "Airport Code [ICAO]": "ZYJZ",
//       "Airport Code iATA,FAA": "JNZ",
//       "Airport Name": "Jinzhou Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 121.06199645996094,
//       "Location": "Jinzhou",
//       "Longitude": 41.10139846801758,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6413,
//       "Airport Code [ICAO]": "ZYQQ",
//       "Airport Code iATA,FAA": "NDG",
//       "Airport Name": "Qiqihar Sanjiazi Airport",
//       "Altitude (Ft)": 477,
//       "Country": "China",
//       "Latitude": 123.91799926757812,
//       "Location": "Qiqihar",
//       "Longitude": 47.239601135253906,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6414,
//       "Airport Code [ICAO]": "ZYYJ",
//       "Airport Code iATA,FAA": "YNJ",
//       "Airport Name": "Yanji Chaoyangchuan Airport",
//       "Altitude (Ft)": 624,
//       "Country": "China",
//       "Latitude": 129.451004028,
//       "Location": "Yanji",
//       "Longitude": 42.8828010559,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6415,
//       "Airport Code [ICAO]": "HI07",
//       "Airport Code iATA,FAA": "WKL",
//       "Airport Name": "Waikoloa Heliport",
//       "Altitude (Ft)": 119,
//       "Country": "United States",
//       "Latitude": -155.8607,
//       "Location": "Waikoloa Village",
//       "Longitude": 19.9205,
//       "GMT Offset": "-10",
//       "Daylight Savings": "N",
//       "Region": "Pacific/Honolulu",
//       "Type": "airport"
//     },
//     {
//       "ID": 6419,
//       "Airport Code [ICAO]": "YMNE",
//       "Airport Code iATA,FAA": "WME",
//       "Airport Name": "Mount Keith Airport",
//       "Altitude (Ft)": 1792,
//       "Country": "Australia",
//       "Latitude": 120.55500030517578,
//       "Location": "Mount Keith",
//       "Longitude": -27.286399841308594,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 6420,
//       "Airport Code [ICAO]": "SVRS",
//       "Airport Code iATA,FAA": "LRV",
//       "Airport Name": "Los Roques Airport",
//       "Altitude (Ft)": 17,
//       "Country": "Venezuela",
//       "Latitude": -66.66999816890001,
//       "Location": "Los Roques",
//       "Longitude": 11.9499998093,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 6421,
//       "Airport Code [ICAO]": "EIIM",
//       "Airport Code iATA,FAA": "IOR",
//       "Airport Name": "Inishmore Aerodrome",
//       "Altitude (Ft)": 24,
//       "Country": "Ireland",
//       "Latitude": -9.653610229492188,
//       "Location": "Inis Mor",
//       "Longitude": 53.1067008972168,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": "Europe/Dublin",
//       "Type": "airport"
//     },
//     {
//       "ID": 6422,
//       "Airport Code [ICAO]": "EICA",
//       "Airport Code iATA,FAA": "NNR",
//       "Airport Name": "Connemara Regional Airport",
//       "Altitude (Ft)": 70,
//       "Country": "Ireland",
//       "Latitude": -9.467780113220215,
//       "Location": "Indreabhan",
//       "Longitude": 53.23030090332031,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": "Europe/Dublin",
//       "Type": "airport"
//     },
//     {
//       "ID": 6423,
//       "Airport Code [ICAO]": "EDCG",
//       "Airport Code iATA,FAA": "GTI",
//       "Airport Name": "Rügen Airport",
//       "Altitude (Ft)": 69,
//       "Country": "Germany",
//       "Latitude": 13.3255558014,
//       "Location": "Ruegen",
//       "Longitude": 54.3833312988,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 6424,
//       "Airport Code [ICAO]": "USHB",
//       "Airport Code iATA,FAA": "EZV",
//       "Airport Name": "Berezovo Airport",
//       "Altitude (Ft)": 98,
//       "Country": "Russia",
//       "Latitude": 65.03050231933594,
//       "Location": "Berezovo",
//       "Longitude": 63.92100143432617,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 6425,
//       "Airport Code [ICAO]": "EPSD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Szczecin-Dąbie Airport",
//       "Altitude (Ft)": 3,
//       "Country": "Poland",
//       "Latitude": 14.63379955291748,
//       "Location": "Szczecin",
//       "Longitude": 53.3921012878418,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 6426,
//       "Airport Code [ICAO]": "KORH",
//       "Airport Code iATA,FAA": "ORH",
//       "Airport Name": "Worcester Regional Airport",
//       "Altitude (Ft)": 1009,
//       "Country": "United States",
//       "Latitude": -71.87570190429688,
//       "Location": "Worcester",
//       "Longitude": 42.26729965209961,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 6427,
//       "Airport Code [ICAO]": "ZSAQ",
//       "Airport Code iATA,FAA": "AQG",
//       "Airport Name": "Anqing Tianzhushan Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 117.050003,
//       "Location": "Anqing",
//       "Longitude": 30.582199,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6429,
//       "Airport Code [ICAO]": "ZBSH",
//       "Airport Code iATA,FAA": "SHP",
//       "Airport Name": "Shanhaiguan Airport",
//       "Altitude (Ft)": 30,
//       "Country": "China",
//       "Latitude": 119.731003,
//       "Location": "Qinhuangdao",
//       "Longitude": 39.968102,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6430,
//       "Airport Code [ICAO]": "ZBYC",
//       "Airport Code iATA,FAA": "YCU",
//       "Airport Name": "Yuncheng Guangong Airport",
//       "Altitude (Ft)": 1242,
//       "Country": "China",
//       "Latitude": 111.031388889,
//       "Location": "Yuncheng",
//       "Longitude": 35.116391,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6431,
//       "Airport Code [ICAO]": "ZLAN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lanzhou City Airport",
//       "Altitude (Ft)": 5040,
//       "Country": "China",
//       "Latitude": 103.86667,
//       "Location": "Lanzhou",
//       "Longitude": 36.033333,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6432,
//       "Airport Code [ICAO]": "ZLJQ",
//       "Airport Code iATA,FAA": "JGN",
//       "Airport Name": "Jiayuguan Airport",
//       "Altitude (Ft)": 5112,
//       "Country": "China",
//       "Latitude": 98.3414,
//       "Location": "Jiayuguan",
//       "Longitude": 39.856899,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6433,
//       "Airport Code [ICAO]": "ZYDD",
//       "Airport Code iATA,FAA": "DDG",
//       "Airport Name": "Dandong Airport",
//       "Altitude (Ft)": 30,
//       "Country": "China",
//       "Latitude": 124.286003,
//       "Location": "Dandong",
//       "Longitude": 40.0247,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6434,
//       "Airport Code [ICAO]": "ZBDS",
//       "Airport Code iATA,FAA": "DSN",
//       "Airport Name": "Ordos Ejin Horo Airport",
//       "Altitude (Ft)": 4557,
//       "Country": "China",
//       "Latitude": 109.861388889,
//       "Location": "Dongsheng",
//       "Longitude": 39.49,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6435,
//       "Airport Code [ICAO]": "ZUZH",
//       "Airport Code iATA,FAA": "PZI",
//       "Airport Name": "Bao'anying Airport",
//       "Altitude (Ft)": 1620,
//       "Country": "China",
//       "Latitude": 101.79852,
//       "Location": "Panzhihua",
//       "Longitude": 26.54,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6445,
//       "Airport Code [ICAO]": "KPWT",
//       "Airport Code iATA,FAA": "PWT",
//       "Airport Name": "Bremerton National Airport",
//       "Altitude (Ft)": 444,
//       "Country": "United States",
//       "Latitude": -122.76499938965,
//       "Location": "Bremerton",
//       "Longitude": 47.490200042725,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 6446,
//       "Airport Code [ICAO]": "KSPW",
//       "Airport Code iATA,FAA": "SPW",
//       "Airport Name": "Spencer Municipal Airport",
//       "Altitude (Ft)": 1339,
//       "Country": "United States",
//       "Latitude": -95.202796936035,
//       "Location": "Spencer",
//       "Longitude": 43.165500640869,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 6447,
//       "Airport Code [ICAO]": "KJEF",
//       "Airport Code iATA,FAA": "JEF",
//       "Airport Name": "Jefferson City Memorial Airport",
//       "Altitude (Ft)": 549,
//       "Country": "United States",
//       "Latitude": -92.15609741210001,
//       "Location": "Jefferson City",
//       "Longitude": 38.5912017822,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 6450,
//       "Airport Code [ICAO]": "EDMT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tannheim Airfield",
//       "Altitude (Ft)": 1903,
//       "Country": "Germany",
//       "Latitude": 10.098611,
//       "Location": "Tannheim",
//       "Longitude": 48.009998,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 6454,
//       "Airport Code [ICAO]": "EGPW",
//       "Airport Code iATA,FAA": "UNT",
//       "Airport Name": "Unst Airport",
//       "Altitude (Ft)": 0,
//       "Country": "United Kingdom",
//       "Latitude": -0.8538500070571899,
//       "Location": "Unst",
//       "Longitude": 60.74720001220703,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 6455,
//       "Airport Code [ICAO]": "WA19",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Berkley Structures Heliport",
//       "Altitude (Ft)": 50,
//       "Country": "Indonesia",
//       "Latitude": -122.148456,
//       "Location": "Pagerungan",
//       "Longitude": 47.682043,
//       "GMT Offset": "-8",
//       "Daylight Savings": "N",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 6456,
//       "Airport Code [ICAO]": "KPVC",
//       "Airport Code iATA,FAA": "PVC",
//       "Airport Name": "Provincetown Municipal Airport",
//       "Altitude (Ft)": 9,
//       "Country": "United States",
//       "Latitude": -70.2213973999,
//       "Location": "Provincetown",
//       "Longitude": 42.0718994141,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 6458,
//       "Airport Code [ICAO]": "WBAK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Anduki Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Brunei",
//       "Latitude": 114.382003784,
//       "Location": "Seria",
//       "Longitude": 4.637000083920001,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Brunei",
//       "Type": "airport"
//     },
//     {
//       "ID": 6460,
//       "Airport Code [ICAO]": "TFFJ",
//       "Airport Code iATA,FAA": "SBH",
//       "Airport Name": "Gustaf III Airport",
//       "Altitude (Ft)": 49,
//       "Country": "France",
//       "Latitude": -62.84360122680664,
//       "Location": "Gustavia",
//       "Longitude": 17.904399871826172,
//       "GMT Offset": "-4",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 6464,
//       "Airport Code [ICAO]": "UUBA",
//       "Airport Code iATA,FAA": "KMW",
//       "Airport Name": "Kostroma Sokerkino Airport",
//       "Altitude (Ft)": 446,
//       "Country": "Russia",
//       "Latitude": 41.019401550299996,
//       "Location": "Kostroma",
//       "Longitude": 57.7969017029,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6465,
//       "Airport Code [ICAO]": "UGSS",
//       "Airport Code iATA,FAA": "SUI",
//       "Airport Name": "Sukhumi Dranda Airport",
//       "Altitude (Ft)": 53,
//       "Country": "Georgia",
//       "Latitude": 41.128101348899996,
//       "Location": "Sukhumi",
//       "Longitude": 42.8582000732,
//       "GMT Offset": "4",
//       "Daylight Savings": "N",
//       "Region": "Asia/Tbilisi",
//       "Type": "airport"
//     },
//     {
//       "ID": 6466,
//       "Airport Code [ICAO]": "UUOT",
//       "Airport Code iATA,FAA": "TBW",
//       "Airport Name": "Donskoye Airport",
//       "Altitude (Ft)": 413,
//       "Country": "Russia",
//       "Latitude": 41.482799530029,
//       "Location": "Tambow",
//       "Longitude": 52.806098937988,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6467,
//       "Airport Code [ICAO]": "EGEO",
//       "Airport Code iATA,FAA": "OBN",
//       "Airport Name": "Oban Airport",
//       "Altitude (Ft)": 20,
//       "Country": "United Kingdom",
//       "Latitude": -5.399670124053955,
//       "Location": "North Connel",
//       "Longitude": 56.4635009765625,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 6477,
//       "Airport Code [ICAO]": "SSER",
//       "Airport Code iATA,FAA": "ERM",
//       "Airport Name": "Erechim Airport",
//       "Altitude (Ft)": 2498,
//       "Country": "Brazil",
//       "Latitude": -52.2682991027832,
//       "Location": "Erechim",
//       "Longitude": -27.66189956665039,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 6478,
//       "Airport Code [ICAO]": "LSGP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "La Côte Airport",
//       "Altitude (Ft)": 1352,
//       "Country": "Switzerland",
//       "Latitude": 6.258059978485107,
//       "Location": "Prangins",
//       "Longitude": 46.40639877319336,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 6479,
//       "Airport Code [ICAO]": "LFLJ",
//       "Airport Code iATA,FAA": "CVF",
//       "Airport Name": "Courchevel Airport",
//       "Altitude (Ft)": 6588,
//       "Country": "France",
//       "Latitude": 6.6347198486328125,
//       "Location": "Courcheval",
//       "Longitude": 45.39670181274414,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 6481,
//       "Airport Code [ICAO]": "KFUL",
//       "Airport Code iATA,FAA": "FUL",
//       "Airport Name": "Fullerton Municipal Airport",
//       "Altitude (Ft)": 96,
//       "Country": "United States",
//       "Latitude": -117.980003357,
//       "Location": "Fullerton",
//       "Longitude": 33.8720016479,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 6482,
//       "Airport Code [ICAO]": "KJQF",
//       "Airport Code iATA,FAA": "USA",
//       "Airport Name": "Concord-Padgett Regional Airport",
//       "Altitude (Ft)": 705,
//       "Country": "United States",
//       "Latitude": -80.709099,
//       "Location": "Concord",
//       "Longitude": 35.387798,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 6483,
//       "Airport Code [ICAO]": "EGHN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Isle of Wight / Sandown Airport",
//       "Altitude (Ft)": 55,
//       "Country": "United Kingdom",
//       "Latitude": -1.18221998,
//       "Location": "Isle Of Wight",
//       "Longitude": 50.653099,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 6485,
//       "Airport Code [ICAO]": "UTSA",
//       "Airport Code iATA,FAA": "NVI",
//       "Airport Name": "Navoi Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Uzbekistan",
//       "Latitude": 65.1707992553711,
//       "Location": "Navoi",
//       "Longitude": 40.1171989440918,
//       "GMT Offset": "5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Samarkand",
//       "Type": "airport"
//     },
//     {
//       "ID": 6487,
//       "Airport Code [ICAO]": "LFCD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Andernos Les Bains Airport",
//       "Altitude (Ft)": 66,
//       "Country": "France",
//       "Latitude": -1.06333,
//       "Location": "Andernos-Les-Bains",
//       "Longitude": 44.7561,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 6489,
//       "Airport Code [ICAO]": "EDOI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bienenfarm Airport",
//       "Altitude (Ft)": 120,
//       "Country": "Germany",
//       "Latitude": 12.745833,
//       "Location": "Nauen",
//       "Longitude": 52.661667,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 6492,
//       "Airport Code [ICAO]": "DAAS",
//       "Airport Code iATA,FAA": "QSF",
//       "Airport Name": "Ain Arnat Airport",
//       "Altitude (Ft)": 3360,
//       "Country": "Algeria",
//       "Latitude": 5.3244900703399995,
//       "Location": "Setif",
//       "Longitude": 36.178100585900005,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 6493,
//       "Airport Code [ICAO]": "LFBH",
//       "Airport Code iATA,FAA": "LRH",
//       "Airport Name": "La Rochelle-Île de Ré Airport",
//       "Altitude (Ft)": 74,
//       "Country": "France",
//       "Latitude": -1.1952799558639526,
//       "Location": "La Rochelle",
//       "Longitude": 46.17919921875,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 6494,
//       "Airport Code [ICAO]": "KSUN",
//       "Airport Code iATA,FAA": "SUN",
//       "Airport Name": "Friedman Memorial Airport",
//       "Altitude (Ft)": 5318,
//       "Country": "United States",
//       "Latitude": -114.2959976,
//       "Location": "Hailey",
//       "Longitude": 43.50439835,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 6497,
//       "Airport Code [ICAO]": "LSGY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Yverdon-les-Bains Airport",
//       "Altitude (Ft)": 1421,
//       "Country": "Switzerland",
//       "Latitude": 6.61332988739,
//       "Location": "Yverdon-Les-Bains",
//       "Longitude": 46.7619018555,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 6499,
//       "Airport Code [ICAO]": "EPBA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bielsko Biala Airport",
//       "Altitude (Ft)": 1319,
//       "Country": "Poland",
//       "Latitude": 19.00189971923828,
//       "Location": "Bielsko-Biala",
//       "Longitude": 49.80500030517578,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 6500,
//       "Airport Code [ICAO]": "KMCW",
//       "Airport Code iATA,FAA": "MCW",
//       "Airport Name": "Mason City Municipal Airport",
//       "Altitude (Ft)": 1213,
//       "Country": "United States",
//       "Latitude": -93.3312988281,
//       "Location": "Mason City",
//       "Longitude": 43.157798767100005,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 6505,
//       "Airport Code [ICAO]": "KIWA",
//       "Airport Code iATA,FAA": "AZA",
//       "Airport Name": "Phoenix-Mesa-Gateway Airport",
//       "Altitude (Ft)": 1382,
//       "Country": "United States",
//       "Latitude": -111.6549988,
//       "Location": "Mesa",
//       "Longitude": 33.30780029,
//       "GMT Offset": "-7",
//       "Daylight Savings": "N",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 6506,
//       "Airport Code [ICAO]": "SOOS",
//       "Airport Code iATA,FAA": "XAU",
//       "Airport Name": "Saúl Airport",
//       "Altitude (Ft)": 656,
//       "Country": "French Guiana",
//       "Latitude": -53.204201,
//       "Location": "Saul",
//       "Longitude": 3.61361,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "America/Cayenne",
//       "Type": "airport"
//     },
//     {
//       "ID": 6511,
//       "Airport Code [ICAO]": "NZTL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tekapo Aerodrome",
//       "Altitude (Ft)": 2496,
//       "Country": "New Zealand",
//       "Latitude": 170.444000244,
//       "Location": "Lake Tekapo",
//       "Longitude": -44.0052986145,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 6519,
//       "Airport Code [ICAO]": "LOGO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Niederoeblarn Airport",
//       "Altitude (Ft)": 2142,
//       "Country": "Austria",
//       "Latitude": 14.0083,
//       "Location": "Niederoeblarn",
//       "Longitude": 47.478298,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vienna",
//       "Type": "airport"
//     },
//     {
//       "ID": 6520,
//       "Airport Code [ICAO]": "LOAV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Vöslau Airport",
//       "Altitude (Ft)": 765,
//       "Country": "Austria",
//       "Latitude": 16.260000228881836,
//       "Location": "Bad Voeslau",
//       "Longitude": 47.96500015258789,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vienna",
//       "Type": "airport"
//     },
//     {
//       "ID": 6522,
//       "Airport Code [ICAO]": "EDHE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Uetersen/Heist Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Germany",
//       "Latitude": 9.704167366027832,
//       "Location": "Uetersen",
//       "Longitude": 53.64638900756836,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 6712,
//       "Airport Code [ICAO]": "PAKP",
//       "Airport Code iATA,FAA": "AKP",
//       "Airport Name": "Anaktuvuk Pass Airport",
//       "Altitude (Ft)": 2102,
//       "Country": "United States",
//       "Latitude": -151.7429962,
//       "Location": "Anaktuvuk Pass",
//       "Longitude": 68.13359833,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6713,
//       "Airport Code [ICAO]": "PANV",
//       "Airport Code iATA,FAA": "ANV",
//       "Airport Name": "Anvik Airport",
//       "Altitude (Ft)": 291,
//       "Country": "United States",
//       "Latitude": -160.190994,
//       "Location": "Anvik",
//       "Longitude": 62.646702,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6714,
//       "Airport Code [ICAO]": "PATQ",
//       "Airport Code iATA,FAA": "ATK",
//       "Airport Name": "Atqasuk Edward Burnell Sr Memorial Airport",
//       "Altitude (Ft)": 96,
//       "Country": "United States",
//       "Latitude": -157.43600463867188,
//       "Location": "Atqasuk",
//       "Longitude": 70.46730041503906,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6715,
//       "Airport Code [ICAO]": "PAGM",
//       "Airport Code iATA,FAA": "GAM",
//       "Airport Name": "Gambell Airport",
//       "Altitude (Ft)": 27,
//       "Country": "United States",
//       "Latitude": -171.73300170898438,
//       "Location": "Gambell",
//       "Longitude": 63.76679992675781,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6716,
//       "Airport Code [ICAO]": "PAHP",
//       "Airport Code iATA,FAA": "HPB",
//       "Airport Name": "Hooper Bay Airport",
//       "Altitude (Ft)": 13,
//       "Country": "United States",
//       "Latitude": -166.1470032,
//       "Location": "Hooper Bay",
//       "Longitude": 61.52389908,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6717,
//       "Airport Code [ICAO]": "PAKV",
//       "Airport Code iATA,FAA": "KAL",
//       "Airport Name": "Kaltag Airport",
//       "Altitude (Ft)": 181,
//       "Country": "United States",
//       "Latitude": -158.7409973,
//       "Location": "Kaltag",
//       "Longitude": 64.31909943,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6718,
//       "Airport Code [ICAO]": "PASM",
//       "Airport Code iATA,FAA": "KSM",
//       "Airport Name": "St Mary's Airport",
//       "Altitude (Ft)": 312,
//       "Country": "United States",
//       "Latitude": -163.302002,
//       "Location": "St Mary's",
//       "Longitude": 62.0605011,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6719,
//       "Airport Code [ICAO]": "PAVL",
//       "Airport Code iATA,FAA": "KVL",
//       "Airport Name": "Kivalina Airport",
//       "Altitude (Ft)": 13,
//       "Country": "United States",
//       "Latitude": -164.56300354003906,
//       "Location": "Kivalina",
//       "Longitude": 67.73619842529297,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6720,
//       "Airport Code [ICAO]": "PAMY",
//       "Airport Code iATA,FAA": "MYU",
//       "Airport Name": "Mekoryuk Airport",
//       "Altitude (Ft)": 48,
//       "Country": "United States",
//       "Latitude": -166.27099609375,
//       "Location": "Mekoryuk",
//       "Longitude": 60.37139892578125,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6722,
//       "Airport Code [ICAO]": "PARY",
//       "Airport Code iATA,FAA": "RBY",
//       "Airport Name": "Ruby Airport",
//       "Altitude (Ft)": 658,
//       "Country": "United States",
//       "Latitude": -155.4700012,
//       "Location": "Ruby",
//       "Longitude": 64.72720337,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6723,
//       "Airport Code [ICAO]": "PASH",
//       "Airport Code iATA,FAA": "SHH",
//       "Airport Name": "Shishmaref Airport",
//       "Altitude (Ft)": 12,
//       "Country": "United States",
//       "Latitude": -166.089112,
//       "Location": "Shishmaref",
//       "Longitude": 66.249604,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6724,
//       "Airport Code [ICAO]": "PASA",
//       "Airport Code iATA,FAA": "SVA",
//       "Airport Name": "Savoonga Airport",
//       "Altitude (Ft)": 53,
//       "Country": "United States",
//       "Latitude": -170.4929962158203,
//       "Location": "Savoonga",
//       "Longitude": 63.6864013671875,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6725,
//       "Airport Code [ICAO]": "PAWN",
//       "Airport Code iATA,FAA": "WTK",
//       "Airport Name": "Noatak Airport",
//       "Altitude (Ft)": 88,
//       "Country": "United States",
//       "Latitude": -162.97500610351562,
//       "Location": "Noatak",
//       "Longitude": 67.56610107421875,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6726,
//       "Airport Code [ICAO]": "RPVO",
//       "Airport Code iATA,FAA": "OMC",
//       "Airport Name": "Ormoc Airport",
//       "Altitude (Ft)": 83,
//       "Country": "Philippines",
//       "Latitude": 124.56500244140625,
//       "Location": "Ormoc City",
//       "Longitude": 11.057999610900879,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 6727,
//       "Airport Code [ICAO]": "CYPX",
//       "Airport Code iATA,FAA": "YPX",
//       "Airport Name": "Puvirnituq Airport",
//       "Altitude (Ft)": 74,
//       "Country": "Canada",
//       "Latitude": -77.28690338134766,
//       "Location": "Puvirnituq",
//       "Longitude": 60.05059814453125,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 6728,
//       "Airport Code [ICAO]": "CYTQ",
//       "Airport Code iATA,FAA": "YTQ",
//       "Airport Name": "Tasiujaq Airport",
//       "Altitude (Ft)": 122,
//       "Country": "Canada",
//       "Latitude": -69.95580291748047,
//       "Location": "Tasiujaq",
//       "Longitude": 58.66780090332031,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 6729,
//       "Airport Code [ICAO]": "PARC",
//       "Airport Code iATA,FAA": "ARC",
//       "Airport Name": "Arctic Village Airport",
//       "Altitude (Ft)": 2092,
//       "Country": "United States",
//       "Latitude": -145.578995,
//       "Location": "Arctic Village",
//       "Longitude": 68.1147,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6730,
//       "Airport Code [ICAO]": "DNIM",
//       "Airport Code iATA,FAA": "QOW",
//       "Airport Name": "Sam Mbakwe International Airport",
//       "Altitude (Ft)": 373,
//       "Country": "Nigeria",
//       "Latitude": 7.206029891967773,
//       "Location": "Imo",
//       "Longitude": 5.427060127258301,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 6731,
//       "Airport Code [ICAO]": "MRAN",
//       "Airport Code iATA,FAA": "FON",
//       "Airport Name": "Arenal Airport",
//       "Altitude (Ft)": 342,
//       "Country": "Costa Rica",
//       "Latitude": -84.634499,
//       "Location": "La Fortuna/San Carlos",
//       "Longitude": 10.478,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 6732,
//       "Airport Code [ICAO]": "MRTR",
//       "Airport Code iATA,FAA": "TMU",
//       "Airport Name": "Tambor Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Costa Rica",
//       "Latitude": -85.013802,
//       "Location": "Nicoya",
//       "Longitude": 9.73852,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 6733,
//       "Airport Code [ICAO]": "RPUY",
//       "Airport Code iATA,FAA": "CYZ",
//       "Airport Name": "Cauayan Airport",
//       "Altitude (Ft)": 200,
//       "Country": "Philippines",
//       "Latitude": 121.752998352,
//       "Location": "Cauayan",
//       "Longitude": 16.9298992157,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 6734,
//       "Airport Code [ICAO]": "ULMK",
//       "Airport Code iATA,FAA": "KVK",
//       "Airport Name": "Kirovsk-Apatity Airport",
//       "Altitude (Ft)": 515,
//       "Country": "Russia",
//       "Latitude": 33.58829879760742,
//       "Location": "Apatity",
//       "Longitude": 67.46330261230469,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6735,
//       "Airport Code [ICAO]": "SBGV",
//       "Airport Code iATA,FAA": "GVR",
//       "Airport Name": "Coronel Altino Machado de Oliveira Airport",
//       "Altitude (Ft)": 561,
//       "Country": "Brazil",
//       "Latitude": -41.982200622559,
//       "Location": "Governador Valadares",
//       "Longitude": -18.89520072937,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 6736,
//       "Airport Code [ICAO]": "PAPC",
//       "Airport Code iATA,FAA": "KPC",
//       "Airport Name": "Port Clarence Coast Guard Station",
//       "Altitude (Ft)": 10,
//       "Country": "United States",
//       "Latitude": -166.85899353,
//       "Location": "Port Clarence",
//       "Longitude": 65.2537002563,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6737,
//       "Airport Code [ICAO]": "ESUP",
//       "Airport Code iATA,FAA": "PJA",
//       "Airport Name": "Pajala Airport",
//       "Altitude (Ft)": 542,
//       "Country": "Sweden",
//       "Latitude": 23.068899154663086,
//       "Location": "Pajala",
//       "Longitude": 67.24559783935547,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 6738,
//       "Airport Code [ICAO]": "CYBD",
//       "Airport Code iATA,FAA": "QBC",
//       "Airport Name": "Bella Coola Airport",
//       "Altitude (Ft)": 117,
//       "Country": "Canada",
//       "Latitude": -126.596001,
//       "Location": "Bella Coola",
//       "Longitude": 52.387501,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 6739,
//       "Airport Code [ICAO]": "KHGR",
//       "Airport Code iATA,FAA": "HGR",
//       "Airport Name": "Hagerstown Regional Richard A Henson Field",
//       "Altitude (Ft)": 703,
//       "Country": "United States",
//       "Latitude": -77.72949982,
//       "Location": "Hagerstown",
//       "Longitude": 39.707901,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 6740,
//       "Airport Code [ICAO]": "SKAC",
//       "Airport Code iATA,FAA": "ACR",
//       "Airport Name": "Araracuara Airport",
//       "Altitude (Ft)": 1250,
//       "Country": "Colombia",
//       "Latitude": -72.4083,
//       "Location": "Araracuara",
//       "Longitude": -0.5833,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 6741,
//       "Airport Code [ICAO]": "VEGK",
//       "Airport Code iATA,FAA": "GOP",
//       "Airport Name": "Gorakhpur Airport",
//       "Altitude (Ft)": 259,
//       "Country": "India",
//       "Latitude": 83.4496994019,
//       "Location": "Gorakhpur",
//       "Longitude": 26.739700317399997,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 6742,
//       "Airport Code [ICAO]": "PASD",
//       "Airport Code iATA,FAA": "SDP",
//       "Airport Name": "Sand Point Airport",
//       "Altitude (Ft)": 21,
//       "Country": "United States",
//       "Latitude": -160.5229949951172,
//       "Location": "Sand Point",
//       "Longitude": 55.314998626708984,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6743,
//       "Airport Code [ICAO]": "ZWHM",
//       "Airport Code iATA,FAA": "HMI",
//       "Airport Name": "Hami Airport",
//       "Altitude (Ft)": 2703,
//       "Country": "China",
//       "Latitude": 93.669197,
//       "Location": "Hami",
//       "Longitude": 42.8414,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6744,
//       "Airport Code [ICAO]": "ZGWZ",
//       "Airport Code iATA,FAA": "WUZ",
//       "Airport Name": "Wuzhou Changzhoudao Airport",
//       "Altitude (Ft)": 89,
//       "Country": "China",
//       "Latitude": 111.248001,
//       "Location": "Wuzhou",
//       "Longitude": 23.456699,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6745,
//       "Airport Code [ICAO]": "RPVU",
//       "Airport Code iATA,FAA": "TBH",
//       "Airport Name": "Tugdan Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Philippines",
//       "Latitude": 122.084999084,
//       "Location": "Romblon",
//       "Longitude": 12.3109998703,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 6746,
//       "Airport Code [ICAO]": "OITM",
//       "Airport Code iATA,FAA": "ACP",
//       "Airport Name": "Sahand Airport",
//       "Altitude (Ft)": 4396,
//       "Country": "Iran",
//       "Latitude": 46.127899169921875,
//       "Location": "Maragheh",
//       "Longitude": 37.347999572753906,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 6747,
//       "Airport Code [ICAO]": "OING",
//       "Airport Code iATA,FAA": "GBT",
//       "Airport Name": "Gorgan Airport",
//       "Altitude (Ft)": -24,
//       "Country": "Iran",
//       "Latitude": 54.4012985229,
//       "Location": "Gorgan",
//       "Longitude": 36.909400939899996,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 6748,
//       "Airport Code [ICAO]": "OICI",
//       "Airport Code iATA,FAA": "IIL",
//       "Airport Name": "Ilam Airport",
//       "Altitude (Ft)": 4404,
//       "Country": "Iran",
//       "Latitude": 46.40480041503906,
//       "Location": "Ilam",
//       "Longitude": 33.58660125732422,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 6749,
//       "Airport Code [ICAO]": "OITP",
//       "Airport Code iATA,FAA": "PFQ",
//       "Airport Name": "Parsabade Moghan Airport",
//       "Altitude (Ft)": 251,
//       "Country": "Iran",
//       "Latitude": 47.881500244141,
//       "Location": "Parsabad",
//       "Longitude": 39.60359954834,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 6751,
//       "Airport Code [ICAO]": "SPCH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tocache Airport",
//       "Altitude (Ft)": 1500,
//       "Country": "Peru",
//       "Latitude": -76.516998291,
//       "Location": "Tocache",
//       "Longitude": -8.1829996109,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 6752,
//       "Airport Code [ICAO]": "ZWTC",
//       "Airport Code iATA,FAA": "TCG",
//       "Airport Name": "Tacheng Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 83.3407974243164,
//       "Location": "Tacheng",
//       "Longitude": 46.67250061035156,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6753,
//       "Airport Code [ICAO]": "LTCR",
//       "Airport Code iATA,FAA": "MQM",
//       "Airport Name": "Mardin Airport",
//       "Altitude (Ft)": 1729,
//       "Country": "Turkey",
//       "Latitude": 40.6316986084,
//       "Location": "Mardin",
//       "Longitude": 37.223300933800004,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 6754,
//       "Airport Code [ICAO]": "UTSN",
//       "Airport Code iATA,FAA": "AFS",
//       "Airport Name": "Sugraly Airport",
//       "Altitude (Ft)": 1396,
//       "Country": "Uzbekistan",
//       "Latitude": 64.23320007324219,
//       "Location": "Zarafshan",
//       "Longitude": 41.61389923095703,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Samarkand",
//       "Type": "airport"
//     },
//     {
//       "ID": 6755,
//       "Airport Code [ICAO]": "PADE",
//       "Airport Code iATA,FAA": "DRG",
//       "Airport Name": "Deering Airport",
//       "Altitude (Ft)": 21,
//       "Country": "United States",
//       "Latitude": -162.76600647,
//       "Location": "Deering",
//       "Longitude": 66.0696029663,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6756,
//       "Airport Code [ICAO]": "LELN",
//       "Airport Code iATA,FAA": "LEN",
//       "Airport Name": "Leon Airport",
//       "Altitude (Ft)": 3006,
//       "Country": "Spain",
//       "Latitude": -5.65556001663208,
//       "Location": "Leon",
//       "Longitude": 42.5890007019043,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 6757,
//       "Airport Code [ICAO]": "LEBG",
//       "Airport Code iATA,FAA": "RGS",
//       "Airport Name": "Burgos Airport",
//       "Altitude (Ft)": 2945,
//       "Country": "Spain",
//       "Latitude": -3.62076,
//       "Location": "Burgos",
//       "Longitude": 42.357601,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 6758,
//       "Airport Code [ICAO]": "AGGS",
//       "Airport Code iATA,FAA": "EGM",
//       "Airport Name": "Sege Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Solomon Islands",
//       "Latitude": 157.87600708007812,
//       "Location": "Sege",
//       "Longitude": -8.578889846801758,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Guadalcanal",
//       "Type": "airport"
//     },
//     {
//       "ID": 6759,
//       "Airport Code [ICAO]": "OIFS",
//       "Airport Code iATA,FAA": "CQD",
//       "Airport Name": "Shahrekord Airport",
//       "Altitude (Ft)": 6723,
//       "Country": "Iran",
//       "Latitude": 50.842201232899995,
//       "Location": "Shahre Kord",
//       "Longitude": 32.2971992493,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 6760,
//       "Airport Code [ICAO]": "VIGG",
//       "Airport Code iATA,FAA": "DHM",
//       "Airport Name": "Kangra Airport",
//       "Altitude (Ft)": 2525,
//       "Country": "India",
//       "Latitude": 76.26339721679688,
//       "Location": "Kangra",
//       "Longitude": 32.16510009765625,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 6761,
//       "Airport Code [ICAO]": "VAND",
//       "Airport Code iATA,FAA": "NDC",
//       "Airport Name": "Nanded Airport",
//       "Altitude (Ft)": 1250,
//       "Country": "India",
//       "Latitude": 77.31670379639999,
//       "Location": "Nanded",
//       "Longitude": 19.1833000183,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 6762,
//       "Airport Code [ICAO]": "VISM",
//       "Airport Code iATA,FAA": "SLV",
//       "Airport Name": "Shimla Airport",
//       "Altitude (Ft)": 5072,
//       "Country": "India",
//       "Latitude": 77.068001,
//       "Location": "Shimla",
//       "Longitude": 31.0818,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 6763,
//       "Airport Code [ICAO]": "PAIG",
//       "Airport Code iATA,FAA": "IGG",
//       "Airport Name": "Igiugig Airport",
//       "Altitude (Ft)": 90,
//       "Country": "United States",
//       "Latitude": -155.90199279785156,
//       "Location": "Igiugig",
//       "Longitude": 59.32400131225586,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6764,
//       "Airport Code [ICAO]": "PANW",
//       "Airport Code iATA,FAA": "KNW",
//       "Airport Name": "New Stuyahok Airport",
//       "Altitude (Ft)": 364,
//       "Country": "United States",
//       "Latitude": -157.32800293,
//       "Location": "New Stuyahok",
//       "Longitude": 59.4499015808,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6765,
//       "Airport Code [ICAO]": "PAVC",
//       "Airport Code iATA,FAA": "KVC",
//       "Airport Name": "King Cove Airport",
//       "Altitude (Ft)": 155,
//       "Country": "United States",
//       "Latitude": -162.26600646972656,
//       "Location": "King Cove",
//       "Longitude": 55.11629867553711,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6766,
//       "Airport Code [ICAO]": "PAPH",
//       "Airport Code iATA,FAA": "PTH",
//       "Airport Name": "Port Heiden Airport",
//       "Altitude (Ft)": 95,
//       "Country": "United States",
//       "Latitude": -158.63299560546875,
//       "Location": "Port Heiden",
//       "Longitude": 56.95909881591797,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6767,
//       "Airport Code [ICAO]": "PATG",
//       "Airport Code iATA,FAA": "TOG",
//       "Airport Name": "Togiak Airport",
//       "Altitude (Ft)": 21,
//       "Country": "United States",
//       "Latitude": -160.39700317382812,
//       "Location": "Togiak Village",
//       "Longitude": 59.052799224853516,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6768,
//       "Airport Code [ICAO]": "HSGN",
//       "Airport Code iATA,FAA": "EGN",
//       "Airport Name": "Geneina Airport",
//       "Altitude (Ft)": 2650,
//       "Country": "Sudan",
//       "Latitude": 22.467199325561523,
//       "Location": "Geneina",
//       "Longitude": 13.48169994354248,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Khartoum",
//       "Type": "airport"
//     },
//     {
//       "ID": 6769,
//       "Airport Code [ICAO]": "WBGL",
//       "Airport Code iATA,FAA": "LKH",
//       "Airport Name": "Long Akah Airport",
//       "Altitude (Ft)": 289,
//       "Country": "Malaysia",
//       "Latitude": 114.78299713134766,
//       "Location": "Long Akah",
//       "Longitude": 3.299999952316284,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kuala_Lumpur",
//       "Type": "airport"
//     },
//     {
//       "ID": 6771,
//       "Airport Code [ICAO]": "NVSW",
//       "Airport Code iATA,FAA": "WLH",
//       "Airport Name": "Walaha Airport",
//       "Altitude (Ft)": 151,
//       "Country": "Vanuatu",
//       "Latitude": 167.690994263,
//       "Location": "Walaha",
//       "Longitude": -15.411999702500001,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Efate",
//       "Type": "airport"
//     },
//     {
//       "ID": 6772,
//       "Airport Code [ICAO]": "ZYCY",
//       "Airport Code iATA,FAA": "CHG",
//       "Airport Name": "Chaoyang Airport",
//       "Altitude (Ft)": 568,
//       "Country": "China",
//       "Latitude": 120.434998,
//       "Location": "Chaoyang",
//       "Longitude": 41.538101,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6773,
//       "Airport Code [ICAO]": "HKSB",
//       "Airport Code iATA,FAA": "UAS",
//       "Airport Name": "Buffalo Spring",
//       "Altitude (Ft)": 3295,
//       "Country": "Kenya",
//       "Latitude": 37.534195,
//       "Location": "Samburu South",
//       "Longitude": 0.530583,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 6774,
//       "Airport Code [ICAO]": "MHBL",
//       "Airport Code iATA,FAA": "BHG",
//       "Airport Name": "Brus Laguna Airport",
//       "Altitude (Ft)": 19,
//       "Country": "Honduras",
//       "Latitude": -84.543602,
//       "Location": "Brus Laguna",
//       "Longitude": 15.7631,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Tegucigalpa",
//       "Type": "airport"
//     },
//     {
//       "ID": 6775,
//       "Airport Code [ICAO]": "CYVB",
//       "Airport Code iATA,FAA": "YVB",
//       "Airport Name": "Bonaventure Airport",
//       "Altitude (Ft)": 123,
//       "Country": "Canada",
//       "Latitude": -65.46029663085938,
//       "Location": "Bonaventure",
//       "Longitude": 48.07109832763672,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 6776,
//       "Airport Code [ICAO]": "OPST",
//       "Airport Code iATA,FAA": "SKT",
//       "Airport Name": "Sialkot Airport",
//       "Altitude (Ft)": 837,
//       "Country": "Pakistan",
//       "Latitude": 74.3638916016,
//       "Location": "Sialkot",
//       "Longitude": 32.5355567932,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Karachi",
//       "Type": "airport"
//     },
//     {
//       "ID": 6777,
//       "Airport Code [ICAO]": "SULS",
//       "Airport Code iATA,FAA": "PDP",
//       "Airport Name": "Capitan Corbeta CA Curbelo International Airport",
//       "Altitude (Ft)": 95,
//       "Country": "Uruguay",
//       "Latitude": -55.09429931640625,
//       "Location": "Punta del Este",
//       "Longitude": -34.855098724365234,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Montevideo",
//       "Type": "airport"
//     },
//     {
//       "ID": 6778,
//       "Airport Code [ICAO]": "FYWB",
//       "Airport Code iATA,FAA": "WVB",
//       "Airport Name": "Walvis Bay Airport",
//       "Altitude (Ft)": 299,
//       "Country": "Namibia",
//       "Latitude": 14.6453,
//       "Location": "Walvis Bay",
//       "Longitude": -22.9799,
//       "GMT Offset": "1",
//       "Daylight Savings": "S",
//       "Region": "Africa/Windhoek",
//       "Type": "airport"
//     },
//     {
//       "ID": 6779,
//       "Airport Code [ICAO]": "FYKM",
//       "Airport Code iATA,FAA": "MPA",
//       "Airport Name": "Katima Mulilo Airport",
//       "Altitude (Ft)": 3144,
//       "Country": "Namibia",
//       "Latitude": 24.176701,
//       "Location": "Mpacha",
//       "Longitude": -17.634399,
//       "GMT Offset": "1",
//       "Daylight Savings": "S",
//       "Region": "Africa/Windhoek",
//       "Type": "airport"
//     },
//     {
//       "ID": 6780,
//       "Airport Code [ICAO]": "LTBY",
//       "Airport Code iATA,FAA": "AOE",
//       "Airport Name": "Anadolu Airport",
//       "Altitude (Ft)": 2588,
//       "Country": "Turkey",
//       "Latitude": 30.5194,
//       "Location": "Eskissehir",
//       "Longitude": 39.809898,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 6781,
//       "Airport Code [ICAO]": "LTBH",
//       "Airport Code iATA,FAA": "CKZ",
//       "Airport Name": "Çanakkale Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Turkey",
//       "Latitude": 26.4267997742,
//       "Location": "Canakkale",
//       "Longitude": 40.1376991272,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 6782,
//       "Airport Code [ICAO]": "LTCK",
//       "Airport Code iATA,FAA": "MSR",
//       "Airport Name": "Muş Airport",
//       "Altitude (Ft)": 4157,
//       "Country": "Turkey",
//       "Latitude": 41.66120147705078,
//       "Location": "Mus",
//       "Longitude": 38.747798919677734,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 6783,
//       "Airport Code [ICAO]": "LTCM",
//       "Airport Code iATA,FAA": "NOP",
//       "Airport Name": "Sinop Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Turkey",
//       "Latitude": 35.066398620605,
//       "Location": "Sinop",
//       "Longitude": 42.015800476074,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 6784,
//       "Airport Code [ICAO]": "LTBU",
//       "Airport Code iATA,FAA": "TEQ",
//       "Airport Name": "Tekirdağ Çorlu Airport",
//       "Altitude (Ft)": 574,
//       "Country": "Turkey",
//       "Latitude": 27.919099807739258,
//       "Location": "Çorlu",
//       "Longitude": 41.13819885253906,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 6785,
//       "Airport Code [ICAO]": "LTBR",
//       "Airport Code iATA,FAA": "YEI",
//       "Airport Name": "Bursa Yenişehir Airport",
//       "Altitude (Ft)": 764,
//       "Country": "Turkey",
//       "Latitude": 29.5625991821,
//       "Location": "Yenisehir",
//       "Longitude": 40.2551994324,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 6786,
//       "Airport Code [ICAO]": "TFFS",
//       "Airport Code iATA,FAA": "LSS",
//       "Airport Name": "Terre-de-Haut Airport",
//       "Altitude (Ft)": 46,
//       "Country": "Guadeloupe",
//       "Latitude": -61.5806007385,
//       "Location": "Les Saintes",
//       "Longitude": 15.86439991,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Guadeloupe",
//       "Type": "airport"
//     },
//     {
//       "ID": 6787,
//       "Airport Code [ICAO]": "VYKL",
//       "Airport Code iATA,FAA": "KMV",
//       "Airport Name": "Kalay Airport",
//       "Altitude (Ft)": 499,
//       "Country": "Myanmar",
//       "Latitude": 94.05110168457031,
//       "Location": "Kalemyo",
//       "Longitude": 23.188800811767578,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 6788,
//       "Airport Code [ICAO]": "TJCG",
//       "Airport Code iATA,FAA": "VQS",
//       "Airport Name": "Vieques Airport",
//       "Altitude (Ft)": 19,
//       "Country": "Puerto Rico",
//       "Latitude": -65.4226989746,
//       "Location": "Vieques Island",
//       "Longitude": 18.115800857500002,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Puerto_Rico",
//       "Type": "airport"
//     },
//     {
//       "ID": 6789,
//       "Airport Code [ICAO]": "CYIF",
//       "Airport Code iATA,FAA": "YIF",
//       "Airport Name": "St Augustin Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Canada",
//       "Latitude": -58.6582984924,
//       "Location": "St-Augustin",
//       "Longitude": 51.2117004395,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Blanc-Sablon",
//       "Type": "airport"
//     },
//     {
//       "ID": 6790,
//       "Airport Code [ICAO]": "OIHH",
//       "Airport Code iATA,FAA": "HDM",
//       "Airport Name": "Hamadan Airport",
//       "Altitude (Ft)": 5755,
//       "Country": "Iran",
//       "Latitude": 48.5525016784668,
//       "Location": "Hamadan",
//       "Longitude": 34.86920166015625,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 6791,
//       "Airport Code [ICAO]": "RPUW",
//       "Airport Code iATA,FAA": "MRQ",
//       "Airport Name": "Marinduque Airport",
//       "Altitude (Ft)": 32,
//       "Country": "Philippines",
//       "Latitude": 121.82599639892578,
//       "Location": "Gasan",
//       "Longitude": 13.361000061035156,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 6792,
//       "Airport Code [ICAO]": "YGFN",
//       "Airport Code iATA,FAA": "GFN",
//       "Airport Name": "Grafton Airport",
//       "Altitude (Ft)": 110,
//       "Country": "Australia",
//       "Latitude": 153.02999877929688,
//       "Location": "Grafton",
//       "Longitude": -29.7593994140625,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 6793,
//       "Airport Code [ICAO]": "YORG",
//       "Airport Code iATA,FAA": "OAG",
//       "Airport Name": "Orange Airport",
//       "Altitude (Ft)": 3115,
//       "Country": "Australia",
//       "Latitude": 149.132995605,
//       "Location": "Orange",
//       "Longitude": -33.3816986084,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 6794,
//       "Airport Code [ICAO]": "YTRE",
//       "Airport Code iATA,FAA": "TRO",
//       "Airport Name": "Taree Airport",
//       "Altitude (Ft)": 38,
//       "Country": "Australia",
//       "Latitude": 152.514007568,
//       "Location": "Taree",
//       "Longitude": -31.8885993958,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 6795,
//       "Airport Code [ICAO]": "ZMCD",
//       "Airport Code iATA,FAA": "COQ",
//       "Airport Name": "Choibalsan Airport",
//       "Altitude (Ft)": 2457,
//       "Country": "Mongolia",
//       "Latitude": 114.64600372314453,
//       "Location": "Choibalsan",
//       "Longitude": 48.13570022583008,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Ulaanbaatar",
//       "Type": "airport"
//     },
//     {
//       "ID": 6797,
//       "Airport Code [ICAO]": "LOIH",
//       "Airport Code iATA,FAA": "HOH",
//       "Airport Name": "Hohenems-Dornbirn Airport",
//       "Altitude (Ft)": 1352,
//       "Country": "Austria",
//       "Latitude": 9.69999980927,
//       "Location": "Hohenems",
//       "Longitude": 47.3849983215,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vienna",
//       "Type": "airport"
//     },
//     {
//       "ID": 6798,
//       "Airport Code [ICAO]": "EDWM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Weser-Wümme Airport",
//       "Altitude (Ft)": 59,
//       "Country": "Germany",
//       "Latitude": 9.208610534667969,
//       "Location": "Hellwege",
//       "Longitude": 53.053890228271484,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 6799,
//       "Airport Code [ICAO]": "LOAG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Krems Airport",
//       "Altitude (Ft)": 1017,
//       "Country": "Austria",
//       "Latitude": 15.634167,
//       "Location": "Krems",
//       "Longitude": 48.446389,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vienna",
//       "Type": "airport"
//     },
//     {
//       "ID": 6800,
//       "Airport Code [ICAO]": "KESC",
//       "Airport Code iATA,FAA": "ESC",
//       "Airport Name": "Delta County Airport",
//       "Altitude (Ft)": 609,
//       "Country": "United States",
//       "Latitude": -87.0936965942,
//       "Location": "Escanaba",
//       "Longitude": 45.7226982117,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 6803,
//       "Airport Code [ICAO]": "PAYA",
//       "Airport Code iATA,FAA": "YAK",
//       "Airport Name": "Yakutat Airport",
//       "Altitude (Ft)": 33,
//       "Country": "United States",
//       "Latitude": -139.660003662,
//       "Location": "Yakutat",
//       "Longitude": 59.5032997131,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6805,
//       "Airport Code [ICAO]": "YGLB",
//       "Airport Code iATA,FAA": "GUL",
//       "Airport Name": "Goulburn Airport",
//       "Altitude (Ft)": 2141,
//       "Country": "Australia",
//       "Latitude": 149.7259979248047,
//       "Location": "Goulburn",
//       "Longitude": -34.810298919677734,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 6806,
//       "Airport Code [ICAO]": "YCNK",
//       "Airport Code iATA,FAA": "CES",
//       "Airport Name": "Cessnock Airport",
//       "Altitude (Ft)": 210,
//       "Country": "Australia",
//       "Latitude": 151.341995,
//       "Location": "Cessnock",
//       "Longitude": -32.787498,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 6807,
//       "Airport Code [ICAO]": "YSCO",
//       "Airport Code iATA,FAA": "NSO",
//       "Airport Name": "Scone Airport",
//       "Altitude (Ft)": 745,
//       "Country": "Australia",
//       "Latitude": 150.83200073242188,
//       "Location": "Scone",
//       "Longitude": -32.037200927734375,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 6808,
//       "Airport Code [ICAO]": "YMDG",
//       "Airport Code iATA,FAA": "DGE",
//       "Airport Name": "Mudgee Airport",
//       "Altitude (Ft)": 1545,
//       "Country": "Australia",
//       "Latitude": 149.610992432,
//       "Location": "Mudgee",
//       "Longitude": -32.5625,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 6809,
//       "Airport Code [ICAO]": "YMND",
//       "Airport Code iATA,FAA": "MTL",
//       "Airport Name": "Maitland Airport",
//       "Altitude (Ft)": 85,
//       "Country": "Australia",
//       "Latitude": 151.492912,
//       "Location": "Maitland",
//       "Longitude": -32.701265,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 6814,
//       "Airport Code [ICAO]": "TJCP",
//       "Airport Code iATA,FAA": "CPX",
//       "Airport Name": "Benjamin Rivera Noriega Airport",
//       "Altitude (Ft)": 49,
//       "Country": "Puerto Rico",
//       "Latitude": -65.304324,
//       "Location": "Culebra Island",
//       "Longitude": 18.313289,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Puerto_Rico",
//       "Type": "airport"
//     },
//     {
//       "ID": 6816,
//       "Airport Code [ICAO]": "LSTA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Raron Airport",
//       "Altitude (Ft)": 2029,
//       "Country": "Switzerland",
//       "Latitude": 7.823329925537109,
//       "Location": "Raron",
//       "Longitude": 46.3036003112793,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 6819,
//       "Airport Code [ICAO]": "UMMB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Borovaya Airfield",
//       "Altitude (Ft)": 0,
//       "Country": "Belarus",
//       "Latitude": 27.6505961,
//       "Location": "Minsk",
//       "Longitude": 53.9604611,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Minsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 6823,
//       "Airport Code [ICAO]": "LSPA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Amlikon Glider Airport",
//       "Altitude (Ft)": 1371,
//       "Country": "Switzerland",
//       "Latitude": 9.047499656677246,
//       "Location": "Amlikon",
//       "Longitude": 47.57419967651367,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 6824,
//       "Airport Code [ICAO]": "LSZT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lommis Airfield",
//       "Altitude (Ft)": 1539,
//       "Country": "Switzerland",
//       "Latitude": 9.00306,
//       "Location": "Lommis",
//       "Longitude": 47.524399,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 6825,
//       "Airport Code [ICAO]": "KMWA",
//       "Airport Code iATA,FAA": "MWA",
//       "Airport Name": "Williamson County Regional Airport",
//       "Altitude (Ft)": 472,
//       "Country": "United States",
//       "Latitude": -89.01110077,
//       "Location": "Marion",
//       "Longitude": 37.75500107,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 6826,
//       "Airport Code [ICAO]": "KBMQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Burnet Municipal Kate Craddock Field",
//       "Altitude (Ft)": 1284,
//       "Country": "Kenya",
//       "Latitude": -98.23860168457031,
//       "Location": "Bamburi",
//       "Longitude": 30.73889923095703,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 6829,
//       "Airport Code [ICAO]": "KOKB",
//       "Airport Code iATA,FAA": "OCN",
//       "Airport Name": "Oceanside Municipal Airport",
//       "Altitude (Ft)": 28,
//       "Country": "Australia",
//       "Latitude": -117.35399627686,
//       "Location": "Fraser Island",
//       "Longitude": 33.217300415039,
//       "GMT Offset": "-8",
//       "Daylight Savings": "O",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 6830,
//       "Airport Code [ICAO]": "EDAS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Flugplatz Finsterwalde/Heinrichsruh",
//       "Altitude (Ft)": 384,
//       "Country": "Germany",
//       "Latitude": 13.675556,
//       "Location": "Finsterwalde",
//       "Longitude": 51.634445,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 6832,
//       "Airport Code [ICAO]": "ORKK",
//       "Airport Code iATA,FAA": "KIK",
//       "Airport Name": "Kirkuk Air Base",
//       "Altitude (Ft)": 1061,
//       "Country": "Iraq",
//       "Latitude": 44.348899841308594,
//       "Location": "Kirkuk",
//       "Longitude": 35.46950149536133,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Asia/Baghdad",
//       "Type": "airport"
//     },
//     {
//       "ID": 6833,
//       "Airport Code [ICAO]": "OTBH",
//       "Airport Code iATA,FAA": "XJD",
//       "Airport Name": "Al Udeid Air Base",
//       "Altitude (Ft)": 130,
//       "Country": "Qatar",
//       "Latitude": 51.3149986267,
//       "Location": "Doha",
//       "Longitude": 25.1173000336,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Asia/Qatar",
//       "Type": "airport"
//     },
//     {
//       "ID": 6834,
//       "Airport Code [ICAO]": "NZGB",
//       "Airport Code iATA,FAA": "GBZ",
//       "Airport Name": "Great Barrier Aerodrome",
//       "Altitude (Ft)": 20,
//       "Country": "New Zealand",
//       "Latitude": 175.4720001220703,
//       "Location": "Claris",
//       "Longitude": -36.24140167236328,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 6837,
//       "Airport Code [ICAO]": "KIMT",
//       "Airport Code iATA,FAA": "IMT",
//       "Airport Name": "Ford Airport",
//       "Altitude (Ft)": 1182,
//       "Country": "United States",
//       "Latitude": -88.1145019531,
//       "Location": "Iron Mountain",
//       "Longitude": 45.8184013367,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 6838,
//       "Airport Code [ICAO]": "KMQT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Marquette Airport",
//       "Altitude (Ft)": 1424,
//       "Country": "United States",
//       "Latitude": -87.5614013671875,
//       "Location": "Marquette",
//       "Longitude": 46.53390121459961,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 6839,
//       "Airport Code [ICAO]": "PFAL",
//       "Airport Code iATA,FAA": "AET",
//       "Airport Name": "Allakaket Airport",
//       "Altitude (Ft)": 441,
//       "Country": "United States",
//       "Latitude": -152.621994019,
//       "Location": "Allakaket",
//       "Longitude": 66.5518035889,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6840,
//       "Airport Code [ICAO]": "EDLD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Dinslaken/Schwarze Heide Airport",
//       "Altitude (Ft)": 217,
//       "Country": "Germany",
//       "Latitude": 6.865278,
//       "Location": "Dinslaken",
//       "Longitude": 51.616112,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 6841,
//       "Airport Code [ICAO]": "EDVI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Höxter-Holzminden Airport",
//       "Altitude (Ft)": 1263,
//       "Country": "Germany",
//       "Latitude": 9.37833309173584,
//       "Location": "Hoexter Holzminden",
//       "Longitude": 51.80666732788086,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 6843,
//       "Airport Code [ICAO]": "RJAN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Niijima Airport",
//       "Altitude (Ft)": 92,
//       "Country": "Japan",
//       "Latitude": 139.268997192,
//       "Location": "Niijima",
//       "Longitude": 34.3694000244,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 6844,
//       "Airport Code [ICAO]": "KMGC",
//       "Airport Code iATA,FAA": "MGC",
//       "Airport Name": "Michigan City Municipal Airport",
//       "Altitude (Ft)": 655,
//       "Country": "United States",
//       "Latitude": -86.8211975098,
//       "Location": "Michigan City",
//       "Longitude": 41.703300476100004,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 6845,
//       "Airport Code [ICAO]": "PAWD",
//       "Airport Code iATA,FAA": "SWD",
//       "Airport Name": "Seward Airport",
//       "Altitude (Ft)": 22,
//       "Country": "United States",
//       "Latitude": -149.41900634765625,
//       "Location": "Seward",
//       "Longitude": 60.12689971923828,
//       "GMT Offset": "-9",
//       "Daylight Savings": "U",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6849,
//       "Airport Code [ICAO]": "KCKC",
//       "Airport Code iATA,FAA": "GRM",
//       "Airport Name": "Grand Marais Cook County Airport",
//       "Altitude (Ft)": 1799,
//       "Country": "United States",
//       "Latitude": -90.38289642330001,
//       "Location": "Grand Marais",
//       "Longitude": 47.8382987976,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 6853,
//       "Airport Code [ICAO]": "KAUW",
//       "Airport Code iATA,FAA": "AUW",
//       "Airport Name": "Wausau Downtown Airport",
//       "Altitude (Ft)": 1201,
//       "Country": "United States",
//       "Latitude": -89.6266021729,
//       "Location": "Wausau",
//       "Longitude": 44.9262008667,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 6855,
//       "Airport Code [ICAO]": "EKKL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kalundborg Airport",
//       "Altitude (Ft)": 1,
//       "Country": "Denmark",
//       "Latitude": 11.25,
//       "Location": "Kalundborg",
//       "Longitude": 55.70029830932617,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Copenhagen",
//       "Type": "airport"
//     },
//     {
//       "ID": 6859,
//       "Airport Code [ICAO]": "UTAM",
//       "Airport Code iATA,FAA": "MYP",
//       "Airport Name": "Mary Airport",
//       "Altitude (Ft)": 728,
//       "Country": "Turkmenistan",
//       "Latitude": 61.896702,
//       "Location": "Mary",
//       "Longitude": 37.6194,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Ashgabat",
//       "Type": "airport"
//     },
//     {
//       "ID": 6860,
//       "Airport Code [ICAO]": "YBUU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bungle Bungle Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 128.3070068359375,
//       "Location": "Bungle Bungle",
//       "Longitude": -17.545299530029297,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 6862,
//       "Airport Code [ICAO]": "LKSZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sazená Airport",
//       "Altitude (Ft)": 761,
//       "Country": "Czech Republic",
//       "Latitude": 14.2588996887,
//       "Location": "Sazena",
//       "Longitude": 50.3246994019,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Prague",
//       "Type": "airport"
//     },
//     {
//       "ID": 6866,
//       "Airport Code [ICAO]": "FVSV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Spray View Airport",
//       "Altitude (Ft)": 3210,
//       "Country": "Zimbabwe",
//       "Latitude": 25.816999435424805,
//       "Location": "Spray View",
//       "Longitude": -17.91699981689453,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Harare",
//       "Type": "airport"
//     },
//     {
//       "ID": 6867,
//       "Airport Code [ICAO]": "BIRL",
//       "Airport Code iATA,FAA": "MVA",
//       "Airport Name": "Reykjahlíð Airport",
//       "Altitude (Ft)": 1030,
//       "Country": "Iceland",
//       "Latitude": -16.918100357055664,
//       "Location": "Myvatn",
//       "Longitude": 65.65579986572266,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Atlantic/Reykjavik",
//       "Type": "airport"
//     },
//     {
//       "ID": 6868,
//       "Airport Code [ICAO]": "LELL",
//       "Airport Code iATA,FAA": "QSA",
//       "Airport Name": "Sabadell Airport",
//       "Altitude (Ft)": 485,
//       "Country": "Spain",
//       "Latitude": 2.1050798892974854,
//       "Location": "Sabadell",
//       "Longitude": 41.52090072631836,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 6870,
//       "Airport Code [ICAO]": "YWHI",
//       "Airport Code iATA,FAA": "WSY",
//       "Airport Name": "Whitsunday Island Airport",
//       "Altitude (Ft)": 40,
//       "Country": "Australia",
//       "Latitude": 148.755,
//       "Location": "Airlie Beach",
//       "Longitude": -20.27611,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6871,
//       "Airport Code [ICAO]": "KMIE",
//       "Airport Code iATA,FAA": "MIE",
//       "Airport Name": "Delaware County Johnson Field",
//       "Altitude (Ft)": 937,
//       "Country": "United States",
//       "Latitude": -85.3958969116211,
//       "Location": "Muncie",
//       "Longitude": 40.2422981262207,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 6873,
//       "Airport Code [ICAO]": "KLAF",
//       "Airport Code iATA,FAA": "LAF",
//       "Airport Name": "Purdue University Airport",
//       "Altitude (Ft)": 606,
//       "Country": "United States",
//       "Latitude": -86.93689727783203,
//       "Location": "Lafayette",
//       "Longitude": 40.41230010986328,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 6876,
//       "Airport Code [ICAO]": "KGEO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Brown County Airport",
//       "Altitude (Ft)": 958,
//       "Country": "United States",
//       "Latitude": -83.88269805908203,
//       "Location": "Georgetown",
//       "Longitude": 38.881900787353516,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 6877,
//       "Airport Code [ICAO]": "KVGT",
//       "Airport Code iATA,FAA": "VGT",
//       "Airport Name": "North Las Vegas Airport",
//       "Altitude (Ft)": 2205,
//       "Country": "United States",
//       "Latitude": -115.19400024414,
//       "Location": "Las Vegas",
//       "Longitude": 36.21070098877,
//       "GMT Offset": "-8",
//       "Daylight Savings": "U",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 6878,
//       "Airport Code [ICAO]": "KENW",
//       "Airport Code iATA,FAA": "ENW",
//       "Airport Name": "Kenosha Regional Airport",
//       "Altitude (Ft)": 742,
//       "Country": "United States",
//       "Latitude": -87.92780304,
//       "Location": "Kenosha",
//       "Longitude": 42.59569931,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 6880,
//       "Airport Code [ICAO]": "KMTJ",
//       "Airport Code iATA,FAA": "MTJ",
//       "Airport Name": "Montrose Regional Airport",
//       "Altitude (Ft)": 5759,
//       "Country": "United States",
//       "Latitude": -107.893997192,
//       "Location": "Montrose CO",
//       "Longitude": 38.509799957300004,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 6881,
//       "Airport Code [ICAO]": "KRIW",
//       "Airport Code iATA,FAA": "RIW",
//       "Airport Name": "Riverton Regional Airport",
//       "Altitude (Ft)": 5525,
//       "Country": "United States",
//       "Latitude": -108.459999084,
//       "Location": "Riverton WY",
//       "Longitude": 43.064201355,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 6883,
//       "Airport Code [ICAO]": "KPDT",
//       "Airport Code iATA,FAA": "PDT",
//       "Airport Name": "Eastern Oregon Regional At Pendleton Airport",
//       "Altitude (Ft)": 1497,
//       "Country": "United States",
//       "Latitude": -118.841003418,
//       "Location": "Pendleton",
//       "Longitude": 45.695098877,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 6886,
//       "Airport Code [ICAO]": "EGMK",
//       "Airport Code iATA,FAA": "LYM",
//       "Airport Name": "Lympne Airport",
//       "Altitude (Ft)": 351,
//       "Country": "United Kingdom",
//       "Latitude": 1.013,
//       "Location": "Lympne",
//       "Longitude": 51.08,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 6887,
//       "Airport Code [ICAO]": "LGHL",
//       "Airport Code iATA,FAA": "PKH",
//       "Airport Name": "Porto Cheli Airport",
//       "Altitude (Ft)": 69,
//       "Country": "Greece",
//       "Latitude": 23.1478,
//       "Location": "Porto Heli",
//       "Longitude": 37.297501,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Athens",
//       "Type": "airport"
//     },
//     {
//       "ID": 6894,
//       "Airport Code [ICAO]": "LOWZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Zell Am See Airport",
//       "Altitude (Ft)": 2470,
//       "Country": "Austria",
//       "Latitude": 12.7875,
//       "Location": "Zell am See",
//       "Longitude": 47.292222,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vienna",
//       "Type": "airport"
//     },
//     {
//       "ID": 6896,
//       "Airport Code [ICAO]": "YAMB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "RAAF Base Amberley",
//       "Altitude (Ft)": 91,
//       "Country": "Australia",
//       "Latitude": 152.712006,
//       "Location": "Amberley",
//       "Longitude": -27.6406,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 6897,
//       "Airport Code [ICAO]": "YPTN",
//       "Airport Code iATA,FAA": "KTR",
//       "Airport Name": "Tindal Airport",
//       "Altitude (Ft)": 443,
//       "Country": "Australia",
//       "Latitude": 132.3780059814453,
//       "Location": "Katherine",
//       "Longitude": -14.521100044250488,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Darwin",
//       "Type": "airport"
//     },
//     {
//       "ID": 6898,
//       "Airport Code [ICAO]": "YLVT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "RAAF Williams, Laverton Base",
//       "Altitude (Ft)": 18,
//       "Country": "Australia",
//       "Latitude": 144.74600219726562,
//       "Location": "Laverton",
//       "Longitude": -37.86360168457031,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Hobart",
//       "Type": "airport"
//     },
//     {
//       "ID": 6899,
//       "Airport Code [ICAO]": "YSNW",
//       "Airport Code iATA,FAA": "NOA",
//       "Airport Name": "Nowra Airport",
//       "Altitude (Ft)": 400,
//       "Country": "Australia",
//       "Latitude": 150.53700256347656,
//       "Location": "Nowra",
//       "Longitude": -34.94889831542969,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 6905,
//       "Airport Code [ICAO]": "UKLC",
//       "Airport Code iATA,FAA": "UCK",
//       "Airport Name": "Lutsk Airport",
//       "Altitude (Ft)": 774,
//       "Country": "Ukraine",
//       "Latitude": 25.487165,
//       "Location": "Lutsk",
//       "Longitude": 50.678404,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 6906,
//       "Airport Code [ICAO]": "UKRR",
//       "Airport Code iATA,FAA": "CEJ",
//       "Airport Name": "Chernihiv Shestovitsa Airport",
//       "Altitude (Ft)": 446,
//       "Country": "Ukraine",
//       "Latitude": 31.1583003998,
//       "Location": "Chernigov",
//       "Longitude": 51.4021987915,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 6907,
//       "Airport Code [ICAO]": "UKLT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ternopil International Airport",
//       "Altitude (Ft)": 1072,
//       "Country": "Ukraine",
//       "Latitude": 25.7001,
//       "Location": "Ternopol",
//       "Longitude": 49.5242,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 6908,
//       "Airport Code [ICAO]": "UMBB",
//       "Airport Code iATA,FAA": "BQT",
//       "Airport Name": "Brest Airport",
//       "Altitude (Ft)": 468,
//       "Country": "Belarus",
//       "Latitude": 23.8981,
//       "Location": "Brest",
//       "Longitude": 52.108299,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Minsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 6909,
//       "Airport Code [ICAO]": "KOSH",
//       "Airport Code iATA,FAA": "OSH",
//       "Airport Name": "Wittman Regional Airport",
//       "Altitude (Ft)": 808,
//       "Country": "United States",
//       "Latitude": -88.55699920654297,
//       "Location": "Oshkosh",
//       "Longitude": 43.98440170288086,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 6910,
//       "Airport Code [ICAO]": "EDXP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Harle Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Germany",
//       "Latitude": 7.820278167724609,
//       "Location": "Harlesiel",
//       "Longitude": 53.706668853759766,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 6911,
//       "Airport Code [ICAO]": "EDWG",
//       "Airport Code iATA,FAA": "AGE",
//       "Airport Name": "Wangerooge Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Germany",
//       "Latitude": 7.913888931274414,
//       "Location": "Wangerooge",
//       "Longitude": 53.782779693603516,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 6914,
//       "Airport Code [ICAO]": "YPEA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "RAAF Base Pearce",
//       "Altitude (Ft)": 149,
//       "Country": "Australia",
//       "Latitude": 116.01499938964844,
//       "Location": "Perth",
//       "Longitude": -31.667800903320312,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 6915,
//       "Airport Code [ICAO]": "YBDG",
//       "Airport Code iATA,FAA": "BXG",
//       "Airport Name": "Bendigo Airport",
//       "Altitude (Ft)": 705,
//       "Country": "Australia",
//       "Latitude": 144.330001831,
//       "Location": "Bendigo",
//       "Longitude": -36.7393989563,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Australia/Hobart",
//       "Type": "airport"
//     },
//     {
//       "ID": 6916,
//       "Airport Code [ICAO]": "KEAT",
//       "Airport Code iATA,FAA": "EAT",
//       "Airport Name": "Pangborn Memorial Airport",
//       "Altitude (Ft)": 1249,
//       "Country": "United States",
//       "Latitude": -120.207000732,
//       "Location": "Wenatchee",
//       "Longitude": 47.3988990784,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 6917,
//       "Airport Code [ICAO]": "TJAB",
//       "Airport Code iATA,FAA": "ARE",
//       "Airport Name": "Antonio Nery Juarbe Pol Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Puerto Rico",
//       "Latitude": -66.6753005981,
//       "Location": "Arecibo",
//       "Longitude": 18.4500007629,
//       "GMT Offset": "-4",
//       "Daylight Savings": "N",
//       "Region": "America/Puerto_Rico",
//       "Type": "airport"
//     },
//     {
//       "ID": 6918,
//       "Airport Code [ICAO]": "AGRC",
//       "Airport Code iATA,FAA": "RIN",
//       "Airport Name": "Ringi Cove Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Solomon Islands",
//       "Latitude": 157.14300537109375,
//       "Location": "Ringi Cove",
//       "Longitude": -8.12639045715332,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Guadalcanal",
//       "Type": "airport"
//     },
//     {
//       "ID": 6922,
//       "Airport Code [ICAO]": "UIKK",
//       "Airport Code iATA,FAA": "KCK",
//       "Airport Name": "Kirensk Airport",
//       "Altitude (Ft)": 840,
//       "Country": "Russia",
//       "Latitude": 108.064,
//       "Location": "Kirensk",
//       "Longitude": 57.773,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Irkutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 6924,
//       "Airport Code [ICAO]": "UITT",
//       "Airport Code iATA,FAA": "UKX",
//       "Airport Name": "Ust-Kut Airport",
//       "Altitude (Ft)": 2188,
//       "Country": "Russia",
//       "Latitude": 105.7300033569336,
//       "Location": "Ust-Kut",
//       "Longitude": 56.8567008972168,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Irkutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 6926,
//       "Airport Code [ICAO]": "NTAM",
//       "Airport Code iATA,FAA": "RMT",
//       "Airport Name": "Rimatara Airport",
//       "Altitude (Ft)": 60,
//       "Country": "French Polynesia",
//       "Latitude": -152.8059,
//       "Location": "Rimatara",
//       "Longitude": -22.63725,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 6927,
//       "Airport Code [ICAO]": "LSPN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Triengen Airport",
//       "Altitude (Ft)": 1594,
//       "Country": "Switzerland",
//       "Latitude": 8.078060150146484,
//       "Location": "Triengen",
//       "Longitude": 47.22669982910156,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 6928,
//       "Airport Code [ICAO]": "LSGL",
//       "Airport Code iATA,FAA": "QLS",
//       "Airport Name": "Lausanne-Blécherette Airport",
//       "Altitude (Ft)": 2041,
//       "Country": "Switzerland",
//       "Latitude": 6.61667013168,
//       "Location": "Lausanne",
//       "Longitude": 46.54529953,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 6929,
//       "Airport Code [ICAO]": "LSZK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Speck-Fehraltorf Airport",
//       "Altitude (Ft)": 1748,
//       "Country": "Switzerland",
//       "Latitude": 8.757499694824219,
//       "Location": "Fehraltorf",
//       "Longitude": 47.376399993896484,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 6930,
//       "Airport Code [ICAO]": "LSZL",
//       "Airport Code iATA,FAA": "ZJI",
//       "Airport Name": "Locarno Airport",
//       "Altitude (Ft)": 650,
//       "Country": "Switzerland",
//       "Latitude": 8.87860965729,
//       "Location": "Locarno",
//       "Longitude": 46.160800933800004,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 6931,
//       "Airport Code [ICAO]": "LSGN",
//       "Airport Code iATA,FAA": "QNC",
//       "Airport Name": "Neuchatel Airport",
//       "Altitude (Ft)": 1427,
//       "Country": "Switzerland",
//       "Latitude": 6.86471986771,
//       "Location": "Neuchatel",
//       "Longitude": 46.9575004578,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 6932,
//       "Airport Code [ICAO]": "URRT",
//       "Airport Code iATA,FAA": "TGK",
//       "Airport Name": "Taganrog Yuzhny Airport",
//       "Altitude (Ft)": 117,
//       "Country": "Russia",
//       "Latitude": 38.8491667,
//       "Location": "Taganrog",
//       "Longitude": 47.1983333,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6933,
//       "Airport Code [ICAO]": "URKG",
//       "Airport Code iATA,FAA": "GDZ",
//       "Airport Name": "Gelendzhik Airport",
//       "Altitude (Ft)": 98,
//       "Country": "Russia",
//       "Latitude": 38.0124807358,
//       "Location": "Gelendzhik",
//       "Longitude": 44.5820926295,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6934,
//       "Airport Code [ICAO]": "UUBW",
//       "Airport Code iATA,FAA": "ZIA",
//       "Airport Name": "Zhukovsky International Airport",
//       "Altitude (Ft)": 377,
//       "Country": "Russia",
//       "Latitude": 38.150002,
//       "Location": "Ramenskoe",
//       "Longitude": 55.553299,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6935,
//       "Airport Code [ICAO]": "UIIR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Irkutsk Northwest Airport",
//       "Altitude (Ft)": 1470,
//       "Country": "Russia",
//       "Latitude": 104.18299865722656,
//       "Location": "Irkutsk",
//       "Longitude": 52.36830139160156,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Irkutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 6936,
//       "Airport Code [ICAO]": "UHHT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Khabarovsk Airport",
//       "Altitude (Ft)": 262,
//       "Country": "Russia",
//       "Latitude": 135.1529998779297,
//       "Location": "Khabarovsk",
//       "Longitude": 48.525001525878906,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": "Asia/Vladivostok",
//       "Type": "airport"
//     },
//     {
//       "ID": 6937,
//       "Airport Code [ICAO]": "UHKD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Dzemgi Airport",
//       "Altitude (Ft)": 89,
//       "Country": "Russia",
//       "Latitude": 137.08099365234375,
//       "Location": "Komsomolsk-on-Amur",
//       "Longitude": 50.60559844970703,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": "Asia/Vladivostok",
//       "Type": "airport"
//     },
//     {
//       "ID": 6939,
//       "Airport Code [ICAO]": "UIIB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Belaya Air Base",
//       "Altitude (Ft)": 1503,
//       "Country": "Russia",
//       "Latitude": 103.57499694824219,
//       "Location": "Sredniiy",
//       "Longitude": 52.915000915527344,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Irkutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 6940,
//       "Airport Code [ICAO]": "UUDL",
//       "Airport Code iATA,FAA": "IAR",
//       "Airport Name": "Tunoshna Airport",
//       "Altitude (Ft)": 287,
//       "Country": "Russia",
//       "Latitude": 40.15739822387695,
//       "Location": "Yaroslavl",
//       "Longitude": 57.560699462890625,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6943,
//       "Airport Code [ICAO]": "ZYMH",
//       "Airport Code iATA,FAA": "OHE",
//       "Airport Name": "Gu-Lian Airport",
//       "Altitude (Ft)": 1836,
//       "Country": "China",
//       "Latitude": 122.43,
//       "Location": "Mohe County",
//       "Longitude": 52.912777777799995,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6944,
//       "Airport Code [ICAO]": "ZLJN",
//       "Airport Code iATA,FAA": "JNG",
//       "Airport Name": "Jining Qufu Airport",
//       "Altitude (Ft)": 134,
//       "Country": "China",
//       "Latitude": 116.346667,
//       "Location": "Jining",
//       "Longitude": 35.292778,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6945,
//       "Airport Code [ICAO]": "MRDK",
//       "Airport Code iATA,FAA": "DRK",
//       "Airport Name": "Drake Bay Airport",
//       "Altitude (Ft)": 12,
//       "Country": "Costa Rica",
//       "Latitude": -83.6417007446,
//       "Location": "Puntarenas",
//       "Longitude": 8.71889019012,
//       "GMT Offset": "-6",
//       "Daylight Savings": "N",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 6946,
//       "Airport Code [ICAO]": "ZWAT",
//       "Airport Code iATA,FAA": "AAT",
//       "Airport Name": "Altay Air Base",
//       "Altitude (Ft)": 2460,
//       "Country": "China",
//       "Latitude": 88.08580780030002,
//       "Location": "Altay",
//       "Longitude": 47.7498855591,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6947,
//       "Airport Code [ICAO]": "LQTZ",
//       "Airport Code iATA,FAA": "TZL",
//       "Airport Name": "Tuzla International Airport",
//       "Altitude (Ft)": 784,
//       "Country": "Bosnia and Herzegovina",
//       "Latitude": 18.72480010986328,
//       "Location": null,
//       "Longitude": 44.45869827270508,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Sarajevo",
//       "Type": "airport"
//     },
//     {
//       "ID": 6948,
//       "Airport Code [ICAO]": "KNFW",
//       "Airport Code iATA,FAA": "FWH",
//       "Airport Name": "NAS Fort Worth JRB/Carswell Field",
//       "Altitude (Ft)": 650,
//       "Country": "United States",
//       "Latitude": -97.4414978,
//       "Location": "Dallas",
//       "Longitude": 32.76919937,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 6949,
//       "Airport Code [ICAO]": "VYEL",
//       "Airport Code iATA,FAA": "NYT",
//       "Airport Name": "Naypyidaw Airport",
//       "Altitude (Ft)": 302,
//       "Country": "Burma",
//       "Latitude": 96.200996,
//       "Location": "Naypyidaw",
//       "Longitude": 19.623501,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 6950,
//       "Airport Code [ICAO]": "VYXG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kyaukhtu South Airport",
//       "Altitude (Ft)": 1345,
//       "Country": "Burma",
//       "Latitude": 94.13030242919922,
//       "Location": "Kyauktu",
//       "Longitude": 21.406700134277344,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 6951,
//       "Airport Code [ICAO]": "ENJA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Jan Mayensfield",
//       "Altitude (Ft)": 39,
//       "Country": "Norway",
//       "Latitude": -8.6520736,
//       "Location": "Jan Mayen",
//       "Longitude": 70.9441166,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Arctic/Longyearbyen",
//       "Type": "airport"
//     },
//     {
//       "ID": 6952,
//       "Airport Code [ICAO]": "VYBP",
//       "Airport Code iATA,FAA": "VBP",
//       "Airport Name": "Bokpyinn Airport",
//       "Altitude (Ft)": 100,
//       "Country": "Burma",
//       "Latitude": 98.735901,
//       "Location": "Bokepyin",
//       "Longitude": 11.1494,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 6954,
//       "Airport Code [ICAO]": "ZBMZ",
//       "Airport Code iATA,FAA": "NZH",
//       "Airport Name": "Manzhouli Xijiao Airport",
//       "Altitude (Ft)": 2231,
//       "Country": "China",
//       "Latitude": 117.33,
//       "Location": "Manzhouli",
//       "Longitude": 49.566667,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6955,
//       "Airport Code [ICAO]": "ZBUH",
//       "Airport Code iATA,FAA": "WUA",
//       "Airport Name": "Wuhai Airport",
//       "Altitude (Ft)": 3650,
//       "Country": "China",
//       "Latitude": 106.7993,
//       "Location": "Wuhai",
//       "Longitude": 39.7934,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6956,
//       "Airport Code [ICAO]": "KGYY",
//       "Airport Code iATA,FAA": "GYY",
//       "Airport Name": "Gary Chicago International Airport",
//       "Altitude (Ft)": 591,
//       "Country": "United States",
//       "Latitude": -87.41280364990234,
//       "Location": "Gary",
//       "Longitude": 41.61629867553711,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 6957,
//       "Airport Code [ICAO]": "KBRD",
//       "Airport Code iATA,FAA": "BRD",
//       "Airport Name": "Brainerd Lakes Regional Airport",
//       "Altitude (Ft)": 1232,
//       "Country": "United States",
//       "Latitude": -94.13809967,
//       "Location": "Brainerd",
//       "Longitude": 46.39830017,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 6958,
//       "Airport Code [ICAO]": "KLWB",
//       "Airport Code iATA,FAA": "LWB",
//       "Airport Name": "Greenbrier Valley Airport",
//       "Altitude (Ft)": 2302,
//       "Country": "United States",
//       "Latitude": -80.3994979858,
//       "Location": "Lewisburg",
//       "Longitude": 37.8582992554,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 6959,
//       "Airport Code [ICAO]": "KPGV",
//       "Airport Code iATA,FAA": "PGV",
//       "Airport Name": "Pitt Greenville Airport",
//       "Altitude (Ft)": 26,
//       "Country": "United States",
//       "Latitude": -77.38529968,
//       "Location": "Greenville",
//       "Longitude": 35.6352005,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 6960,
//       "Airport Code [ICAO]": "PACK",
//       "Airport Code iATA,FAA": "CYF",
//       "Airport Name": "Chefornak Airport",
//       "Altitude (Ft)": 40,
//       "Country": "United States",
//       "Latitude": -164.285995483,
//       "Location": "Chefornak",
//       "Longitude": 60.1492004395,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 6961,
//       "Airport Code [ICAO]": "KOXR",
//       "Airport Code iATA,FAA": "OXR",
//       "Airport Name": "Oxnard Airport",
//       "Altitude (Ft)": 45,
//       "Country": "United States",
//       "Latitude": -119.20700073242,
//       "Location": "Oxnard",
//       "Longitude": 34.200801849365,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 6962,
//       "Airport Code [ICAO]": "KBBG",
//       "Airport Code iATA,FAA": "BKG",
//       "Airport Name": "Branson Airport",
//       "Altitude (Ft)": 1302,
//       "Country": "United States",
//       "Latitude": -93.200544,
//       "Location": "Branson",
//       "Longitude": 36.532082,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 6963,
//       "Airport Code [ICAO]": "ZUTR",
//       "Airport Code iATA,FAA": "TEN",
//       "Airport Name": "Tongren Fenghuang Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 109.308889,
//       "Location": "Tongren",
//       "Longitude": 27.883333,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6964,
//       "Airport Code [ICAO]": "ZSJA",
//       "Airport Code iATA,FAA": "JGS",
//       "Airport Name": "Jinggangshan Airport",
//       "Altitude (Ft)": 281,
//       "Country": "China",
//       "Latitude": 114.736999512,
//       "Location": "Jian",
//       "Longitude": 26.856899261499997,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 6967,
//       "Airport Code [ICAO]": "NTKN",
//       "Airport Code iATA,FAA": "NIU",
//       "Airport Name": "Naiu Airport",
//       "Altitude (Ft)": 50,
//       "Country": "French Polynesia",
//       "Latitude": -146.3683,
//       "Location": "Niau",
//       "Longitude": -16.1191,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tahiti",
//       "Type": "airport"
//     },
//     {
//       "ID": 6968,
//       "Airport Code [ICAO]": "KSCH",
//       "Airport Code iATA,FAA": "SCH",
//       "Airport Name": "Schenectady County Airport",
//       "Altitude (Ft)": 378,
//       "Country": "United States",
//       "Latitude": -73.928901672363,
//       "Location": "Scotia NY",
//       "Longitude": 42.852500915527,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 6969,
//       "Airport Code [ICAO]": "UWKE",
//       "Airport Code iATA,FAA": "NBC",
//       "Airport Name": "Begishevo Airport",
//       "Altitude (Ft)": 643,
//       "Country": "Russia",
//       "Latitude": 52.092498779296875,
//       "Location": "Nizhnekamsk",
//       "Longitude": 55.564701080322266,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 6972,
//       "Airport Code [ICAO]": "DNSU",
//       "Airport Code iATA,FAA": "QRW",
//       "Airport Name": "Warri Airport",
//       "Altitude (Ft)": 242,
//       "Country": "Nigeria",
//       "Latitude": 5.81778,
//       "Location": "Osubi",
//       "Longitude": 5.59611,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": "Africa/Lagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 6973,
//       "Airport Code [ICAO]": "EHVK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Volkel Air Base",
//       "Altitude (Ft)": 72,
//       "Country": "Netherlands",
//       "Latitude": 5.7077778,
//       "Location": "Volkel",
//       "Longitude": 51.657222,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Amsterdam",
//       "Type": "airport"
//     },
//     {
//       "ID": 6974,
//       "Airport Code [ICAO]": "RPNS",
//       "Airport Code iATA,FAA": "IAO",
//       "Airport Name": "Siargao Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Philippines",
//       "Latitude": 126.014,
//       "Location": "Siargao",
//       "Longitude": 9.8591,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 6975,
//       "Airport Code [ICAO]": "EDWL",
//       "Airport Code iATA,FAA": "LGO",
//       "Airport Name": "Langeoog Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Germany",
//       "Latitude": 7.497777938842773,
//       "Location": "Langeoog",
//       "Longitude": 53.74250030517578,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 6984,
//       "Airport Code [ICAO]": "LSZP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Biel-Kappelen Airport",
//       "Altitude (Ft)": 1437,
//       "Country": "Switzerland",
//       "Latitude": 7.289999961853027,
//       "Location": "Biel",
//       "Longitude": 47.08919906616211,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 6985,
//       "Airport Code [ICAO]": "FANS",
//       "Airport Code iATA,FAA": "NLP",
//       "Airport Name": "Nelspruit Airport",
//       "Altitude (Ft)": 2875,
//       "Country": "South Africa",
//       "Latitude": 30.9137992859,
//       "Location": "Nelspruit",
//       "Longitude": -25.5,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 6986,
//       "Airport Code [ICAO]": "UKKE",
//       "Airport Code iATA,FAA": "CKC",
//       "Airport Name": "Cherkasy International Airport",
//       "Altitude (Ft)": 375,
//       "Country": "Ukraine",
//       "Latitude": 31.99530029296875,
//       "Location": "Cherkassy",
//       "Longitude": 49.41559982299805,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 6989,
//       "Airport Code [ICAO]": "KSGJ",
//       "Airport Code iATA,FAA": "UST",
//       "Airport Name": "Northeast Florida Regional Airport",
//       "Altitude (Ft)": 10,
//       "Country": "United States",
//       "Latitude": -81.339798,
//       "Location": "St. Augustine Airport",
//       "Longitude": 29.9592,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 6990,
//       "Airport Code [ICAO]": "UKON",
//       "Airport Code iATA,FAA": "NLV",
//       "Airport Name": "Mykolaiv International Airport",
//       "Altitude (Ft)": 184,
//       "Country": "Ukraine",
//       "Latitude": 31.9197998046875,
//       "Location": "Nikolayev",
//       "Longitude": 47.057899475097656,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 6991,
//       "Airport Code [ICAO]": "VNRC",
//       "Airport Code iATA,FAA": "RHP",
//       "Airport Name": "Ramechhap Airport",
//       "Altitude (Ft)": 1555,
//       "Country": "Nepal",
//       "Latitude": 86.0614013671875,
//       "Location": "Ramechhap",
//       "Longitude": 27.393999099731445,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "U",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 6992,
//       "Airport Code [ICAO]": "KSTS",
//       "Airport Code iATA,FAA": "STS",
//       "Airport Name": "Charles M. Schulz Sonoma County Airport",
//       "Altitude (Ft)": 128,
//       "Country": "United States",
//       "Latitude": -122.8130035,
//       "Location": "Santa Rosa",
//       "Longitude": 38.50899887,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 6993,
//       "Airport Code [ICAO]": "KISM",
//       "Airport Code iATA,FAA": "ISM",
//       "Airport Name": "Kissimmee Gateway Airport",
//       "Altitude (Ft)": 82,
//       "Country": "United States",
//       "Latitude": -81.4371032715,
//       "Location": "Kissimmee",
//       "Longitude": 28.2898006439,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 6994,
//       "Airport Code [ICAO]": "KLCQ",
//       "Airport Code iATA,FAA": "LCQ",
//       "Airport Name": "Lake City Gateway Airport",
//       "Altitude (Ft)": 201,
//       "Country": "United States",
//       "Latitude": -82.57689666750001,
//       "Location": "Lake City",
//       "Longitude": 30.1819992065,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 6995,
//       "Airport Code [ICAO]": "KDED",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Deland Municipal Sidney H Taylor Field",
//       "Altitude (Ft)": 79,
//       "Country": "United States",
//       "Latitude": -81.28379822,
//       "Location": "DeLand",
//       "Longitude": 29.06699944,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 6996,
//       "Airport Code [ICAO]": "7FL4",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Haller Airpark",
//       "Altitude (Ft)": 75,
//       "Country": "United States",
//       "Latitude": -81.68515,
//       "Location": "Green Cove Springs",
//       "Longitude": 29.904057,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 6997,
//       "Airport Code [ICAO]": "SLPA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Palmar Airport",
//       "Altitude (Ft)": 1342,
//       "Country": "Peru",
//       "Latitude": -62.97533416748047,
//       "Location": "Santa Lucia",
//       "Longitude": -18.97166633605957,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/La_Paz",
//       "Type": "airport"
//     },
//     {
//       "ID": 6998,
//       "Airport Code [ICAO]": "KLGU",
//       "Airport Code iATA,FAA": "LGU",
//       "Airport Name": "Logan-Cache Airport",
//       "Altitude (Ft)": 4457,
//       "Country": "United States",
//       "Latitude": -111.851997375,
//       "Location": "Logan",
//       "Longitude": 41.7911987305,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 6999,
//       "Airport Code [ICAO]": "KBMC",
//       "Airport Code iATA,FAA": "BMC",
//       "Airport Name": "Brigham City Regional Airport",
//       "Altitude (Ft)": 4229,
//       "Country": "United States",
//       "Latitude": -112.061996,
//       "Location": "Brigham City",
//       "Longitude": 41.552399,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7000,
//       "Airport Code [ICAO]": "KMLD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Malad City Airport",
//       "Altitude (Ft)": 4503,
//       "Country": "United States",
//       "Latitude": -112.2969970703125,
//       "Location": "Malad City",
//       "Longitude": 42.16659927368164,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7001,
//       "Airport Code [ICAO]": "KASE",
//       "Airport Code iATA,FAA": "ASE",
//       "Airport Name": "Aspen-Pitkin Co/Sardy Field",
//       "Altitude (Ft)": 7820,
//       "Country": "United States",
//       "Latitude": -106.8690033,
//       "Location": "Aspen",
//       "Longitude": 39.22320175,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7003,
//       "Airport Code [ICAO]": "UWLL",
//       "Airport Code iATA,FAA": "ULV",
//       "Airport Name": "Ulyanovsk Baratayevka Airport",
//       "Altitude (Ft)": 463,
//       "Country": "Russia",
//       "Latitude": 48.226699829100006,
//       "Location": "Ulyanovsk",
//       "Longitude": 54.26829910279999,
//       "GMT Offset": "4",
//       "Daylight Savings": "N",
//       "Region": "Europe/Samara",
//       "Type": "airport"
//     },
//     {
//       "ID": 7009,
//       "Airport Code [ICAO]": "KERV",
//       "Airport Code iATA,FAA": "ERV",
//       "Airport Name": "Kerrville Municipal Louis Schreiner Field",
//       "Altitude (Ft)": 1617,
//       "Country": "United States",
//       "Latitude": -99.08570098879999,
//       "Location": "Kerrville",
//       "Longitude": 29.9766998291,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7010,
//       "Airport Code [ICAO]": "LSZF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Birrfeld Airport",
//       "Altitude (Ft)": 1300,
//       "Country": "Switzerland",
//       "Latitude": 8.23361,
//       "Location": "Birrfeld",
//       "Longitude": 47.4436,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 7011,
//       "Airport Code [ICAO]": "KGED",
//       "Airport Code iATA,FAA": "GED",
//       "Airport Name": "Sussex County Airport",
//       "Altitude (Ft)": 53,
//       "Country": "United States",
//       "Latitude": -75.35890198,
//       "Location": "Georgetown",
//       "Longitude": 38.68920135,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7012,
//       "Airport Code [ICAO]": "CZSW",
//       "Airport Code iATA,FAA": "ZSW",
//       "Airport Name": "Prince Rupert/Seal Cove Seaplane Base",
//       "Altitude (Ft)": 0,
//       "Country": "Canada",
//       "Latitude": -130.2830047607422,
//       "Location": "Prince Rupert",
//       "Longitude": 54.33330154418945,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7013,
//       "Airport Code [ICAO]": "KGBD",
//       "Airport Code iATA,FAA": "GBD",
//       "Airport Name": "Great Bend Municipal Airport",
//       "Altitude (Ft)": 1887,
//       "Country": "United States",
//       "Latitude": -98.8591995239,
//       "Location": "Great Bend",
//       "Longitude": 38.3442993164,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7014,
//       "Airport Code [ICAO]": "KHYS",
//       "Airport Code iATA,FAA": "HYS",
//       "Airport Name": "Hays Regional Airport",
//       "Altitude (Ft)": 1999,
//       "Country": "United States",
//       "Latitude": -99.27320099,
//       "Location": "Hays",
//       "Longitude": 38.84220123,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7015,
//       "Airport Code [ICAO]": "KSUS",
//       "Airport Code iATA,FAA": "SUS",
//       "Airport Name": "Spirit of St Louis Airport",
//       "Altitude (Ft)": 463,
//       "Country": "United States",
//       "Latitude": -90.652000427246,
//       "Location": null,
//       "Longitude": 38.662101745605,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7016,
//       "Airport Code [ICAO]": "KELO",
//       "Airport Code iATA,FAA": "LYU",
//       "Airport Name": "Ely Municipal Airport",
//       "Altitude (Ft)": 1456,
//       "Country": "United States",
//       "Latitude": -91.83070374,
//       "Location": "Ely",
//       "Longitude": 47.82450104,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7017,
//       "Airport Code [ICAO]": "KGPZ",
//       "Airport Code iATA,FAA": "GPZ",
//       "Airport Name": "Grand Rapids Itasca Co-Gordon Newstrom field",
//       "Altitude (Ft)": 1355,
//       "Country": "United States",
//       "Latitude": -93.50980377,
//       "Location": "Grand Rapids MN",
//       "Longitude": 47.21110153,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7018,
//       "Airport Code [ICAO]": "KTVF",
//       "Airport Code iATA,FAA": "TVF",
//       "Airport Name": "Thief River Falls Regional Airport",
//       "Altitude (Ft)": 1119,
//       "Country": "United States",
//       "Latitude": -96.18499756,
//       "Location": "Thief River Falls",
//       "Longitude": 48.06570053,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7019,
//       "Airport Code [ICAO]": "KEGV",
//       "Airport Code iATA,FAA": "EGV",
//       "Airport Name": "Eagle River Union Airport",
//       "Altitude (Ft)": 1642,
//       "Country": "United States",
//       "Latitude": -89.26830291750001,
//       "Location": "Eagle River",
//       "Longitude": 45.932300567599995,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7020,
//       "Airport Code [ICAO]": "KARV",
//       "Airport Code iATA,FAA": "ARV",
//       "Airport Name": "Lakeland-Noble F. Lee Memorial field",
//       "Altitude (Ft)": 1629,
//       "Country": "United States",
//       "Latitude": -89.73090363,
//       "Location": "Minocqua - Woodruff",
//       "Longitude": 45.92789841,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7021,
//       "Airport Code [ICAO]": "KIKV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ankeny Regional Airport",
//       "Altitude (Ft)": 910,
//       "Country": "United States",
//       "Latitude": -93.56639862060547,
//       "Location": "Ankeny",
//       "Longitude": 41.69139862060547,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7022,
//       "Airport Code [ICAO]": "CYBV",
//       "Airport Code iATA,FAA": "YBV",
//       "Airport Name": "Berens River Airport",
//       "Altitude (Ft)": 728,
//       "Country": "Canada",
//       "Latitude": -97.018303,
//       "Location": "Berens River",
//       "Longitude": 52.358898,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 7023,
//       "Airport Code [ICAO]": "KNGP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Corpus Christi Naval Air Station/Truax Field",
//       "Altitude (Ft)": 18,
//       "Country": "United States",
//       "Latitude": -97.29109955,
//       "Location": "Corpus Christi",
//       "Longitude": 27.69260025,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7025,
//       "Airport Code [ICAO]": "KAVX",
//       "Airport Code iATA,FAA": "AVX",
//       "Airport Name": "Catalina Airport",
//       "Altitude (Ft)": 1602,
//       "Country": "United States",
//       "Latitude": -118.416,
//       "Location": "Catalina Island",
//       "Longitude": 33.4049,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7026,
//       "Airport Code [ICAO]": "KMHV",
//       "Airport Code iATA,FAA": "MHV",
//       "Airport Name": "Mojave Airport",
//       "Altitude (Ft)": 2801,
//       "Country": "United States",
//       "Latitude": -118.1520004,
//       "Location": "Mojave",
//       "Longitude": 35.05939865,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7027,
//       "Airport Code [ICAO]": "LSMI",
//       "Airport Code iATA,FAA": "ZIN",
//       "Airport Name": "Interlaken Air Base",
//       "Altitude (Ft)": 0,
//       "Country": "Switzerland",
//       "Latitude": 7.8790798,
//       "Location": "Interlaken",
//       "Longitude": 46.6766014,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 7030,
//       "Airport Code [ICAO]": "EIIR",
//       "Airport Code iATA,FAA": "INQ",
//       "Airport Name": "Inisheer Aerodrome",
//       "Altitude (Ft)": 40,
//       "Country": "Ireland",
//       "Latitude": -9.510899543762207,
//       "Location": "Inisheer",
//       "Longitude": 53.064701080322266,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Dublin",
//       "Type": "airport"
//     },
//     {
//       "ID": 7033,
//       "Airport Code [ICAO]": "UNSS",
//       "Airport Code iATA,FAA": "SWT",
//       "Airport Name": "Strezhevoy Airport",
//       "Altitude (Ft)": 164,
//       "Country": "Russia",
//       "Latitude": 77.66000366210001,
//       "Location": "Strezhevoy",
//       "Longitude": 60.709400177,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Krasnoyarsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 7035,
//       "Airport Code [ICAO]": "KHUT",
//       "Airport Code iATA,FAA": "HUT",
//       "Airport Name": "Hutchinson Municipal Airport",
//       "Altitude (Ft)": 1543,
//       "Country": "United States",
//       "Latitude": -97.86060333250002,
//       "Location": "Hutchinson",
//       "Longitude": 38.0654983521,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7036,
//       "Airport Code [ICAO]": "OAIX",
//       "Airport Code iATA,FAA": "OAI",
//       "Airport Name": "Bagram Air Base",
//       "Altitude (Ft)": 4895,
//       "Country": "Afghanistan",
//       "Latitude": 69.26499938959999,
//       "Location": "Kabul",
//       "Longitude": 34.9460983276,
//       "GMT Offset": "4.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Kabul",
//       "Type": "airport"
//     },
//     {
//       "ID": 7037,
//       "Airport Code [ICAO]": "OEPS",
//       "Airport Code iATA,FAA": "AKH",
//       "Airport Name": "Prince Sultan Air Base",
//       "Altitude (Ft)": 1651,
//       "Country": "Saudi Arabia",
//       "Latitude": 47.580501556396484,
//       "Location": "Al Kharj",
//       "Longitude": 24.062700271606445,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 7042,
//       "Airport Code [ICAO]": "KSTJ",
//       "Airport Code iATA,FAA": "STJ",
//       "Airport Name": "Rosecrans Memorial Airport",
//       "Altitude (Ft)": 826,
//       "Country": "United States",
//       "Latitude": -94.909698486328,
//       "Location": "Rosecrans",
//       "Longitude": 39.771900177002,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7046,
//       "Airport Code [ICAO]": "LHPA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Pápa Air Base",
//       "Altitude (Ft)": 466,
//       "Country": "Hungary",
//       "Latitude": 17.5007991791,
//       "Location": "Papa",
//       "Longitude": 47.3636016846,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Budapest",
//       "Type": "airport"
//     },
//     {
//       "ID": 7047,
//       "Airport Code [ICAO]": "KNDZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Whiting Field Naval Air Station South Airport",
//       "Altitude (Ft)": 177,
//       "Country": "Germany",
//       "Latitude": -87.02300262451172,
//       "Location": "Cuxhaven",
//       "Longitude": 30.70439910888672,
//       "GMT Offset": "-6",
//       "Daylight Savings": "E",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7048,
//       "Airport Code [ICAO]": "KVOK",
//       "Airport Code iATA,FAA": "VOK",
//       "Airport Name": "Volk Field",
//       "Altitude (Ft)": 912,
//       "Country": "United States",
//       "Latitude": -90.253402709961,
//       "Location": "Camp Douglas",
//       "Longitude": 43.938999176025,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7051,
//       "Airport Code [ICAO]": "KGUC",
//       "Airport Code iATA,FAA": "GUC",
//       "Airport Name": "Gunnison Crested Butte Regional Airport",
//       "Altitude (Ft)": 7680,
//       "Country": "United States",
//       "Latitude": -106.9329987,
//       "Location": "Gunnison",
//       "Longitude": 38.53390121,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7052,
//       "Airport Code [ICAO]": "ZLSN",
//       "Airport Code iATA,FAA": "SIA",
//       "Airport Name": "Xi'an Xiguan Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 109.120003,
//       "Location": "Xi\\'AN",
//       "Longitude": 34.376701,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 7053,
//       "Airport Code [ICAO]": "KTOA",
//       "Airport Code iATA,FAA": "TOA",
//       "Airport Name": "Zamperini Field",
//       "Altitude (Ft)": 103,
//       "Country": "United States",
//       "Latitude": -118.33999633789,
//       "Location": "Torrance",
//       "Longitude": 33.803398132324,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7054,
//       "Airport Code [ICAO]": "KMBL",
//       "Airport Code iATA,FAA": "MBL",
//       "Airport Name": "Manistee Co Blacker Airport",
//       "Altitude (Ft)": 621,
//       "Country": "United States",
//       "Latitude": -86.24690247,
//       "Location": "Manistee",
//       "Longitude": 44.2723999,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7056,
//       "Airport Code [ICAO]": "KPGD",
//       "Airport Code iATA,FAA": "PGD",
//       "Airport Name": "Charlotte County Airport",
//       "Altitude (Ft)": 26,
//       "Country": "United States",
//       "Latitude": -81.9905014,
//       "Location": "Punta Gorda",
//       "Longitude": 26.92020035,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7058,
//       "Airport Code [ICAO]": "KFVE",
//       "Airport Code iATA,FAA": "WFK",
//       "Airport Name": "Northern Aroostook Regional Airport",
//       "Altitude (Ft)": 988,
//       "Country": "United States",
//       "Latitude": -68.31279754639999,
//       "Location": "Frenchville",
//       "Longitude": 47.2854995728,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7059,
//       "Airport Code [ICAO]": "KJHW",
//       "Airport Code iATA,FAA": "JHW",
//       "Airport Name": "Chautauqua County-Jamestown Airport",
//       "Altitude (Ft)": 1723,
//       "Country": "United States",
//       "Latitude": -79.25800323,
//       "Location": "Jamestown",
//       "Longitude": 42.15340042,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7060,
//       "Airport Code [ICAO]": "CYFJ",
//       "Airport Code iATA,FAA": "YTM",
//       "Airport Name": "La Macaza / Mont-Tremblant International Inc Airport",
//       "Altitude (Ft)": 827,
//       "Country": "Canada",
//       "Latitude": -74.7799987793,
//       "Location": "Mont-Tremblant",
//       "Longitude": 46.409400939899996,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 7061,
//       "Airport Code [ICAO]": "KSME",
//       "Airport Code iATA,FAA": "SME",
//       "Airport Name": "Lake Cumberland Regional Airport",
//       "Altitude (Ft)": 927,
//       "Country": "United States",
//       "Latitude": -84.6158981323,
//       "Location": "Somerset",
//       "Longitude": 37.053398132299996,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7062,
//       "Airport Code [ICAO]": "KSHD",
//       "Airport Code iATA,FAA": "SHD",
//       "Airport Name": "Shenandoah Valley Regional Airport",
//       "Altitude (Ft)": 1201,
//       "Country": "United States",
//       "Latitude": -78.8964004517,
//       "Location": "Weyers Cave",
//       "Longitude": 38.2638015747,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7063,
//       "Airport Code [ICAO]": "KDVL",
//       "Airport Code iATA,FAA": "DVL",
//       "Airport Name": "Devils Lake Regional Airport",
//       "Altitude (Ft)": 1456,
//       "Country": "United States",
//       "Latitude": -98.90879822,
//       "Location": "Devils Lake",
//       "Longitude": 48.11420059,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7064,
//       "Airport Code [ICAO]": "KDIK",
//       "Airport Code iATA,FAA": "DIK",
//       "Airport Name": "Dickinson Theodore Roosevelt Regional Airport",
//       "Altitude (Ft)": 2592,
//       "Country": "United States",
//       "Latitude": -102.802001953,
//       "Location": "Dickinson",
//       "Longitude": 46.7974014282,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7065,
//       "Airport Code [ICAO]": "KSDY",
//       "Airport Code iATA,FAA": "SDY",
//       "Airport Name": "Sidney - Richland Regional Airport",
//       "Altitude (Ft)": 1985,
//       "Country": "United States",
//       "Latitude": -104.193001,
//       "Location": "Sidney",
//       "Longitude": 47.706902,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7066,
//       "Airport Code [ICAO]": "KCDR",
//       "Airport Code iATA,FAA": "CDR",
//       "Airport Name": "Chadron Municipal Airport",
//       "Altitude (Ft)": 3297,
//       "Country": "United States",
//       "Latitude": -103.095001221,
//       "Location": "Chadron",
//       "Longitude": 42.837600708,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7067,
//       "Airport Code [ICAO]": "KAIA",
//       "Airport Code iATA,FAA": "AIA",
//       "Airport Name": "Alliance Municipal Airport",
//       "Altitude (Ft)": 3931,
//       "Country": "United States",
//       "Latitude": -102.804000854,
//       "Location": "Alliance",
//       "Longitude": 42.0531997681,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7068,
//       "Airport Code [ICAO]": "KMCK",
//       "Airport Code iATA,FAA": "MCK",
//       "Airport Name": "Mc Cook Ben Nelson Regional Airport",
//       "Altitude (Ft)": 2583,
//       "Country": "United States",
//       "Latitude": -100.5920029,
//       "Location": "McCook",
//       "Longitude": 40.20629883,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7069,
//       "Airport Code [ICAO]": "KMTH",
//       "Airport Code iATA,FAA": "MTH",
//       "Airport Name": "The Florida Keys Marathon Airport",
//       "Altitude (Ft)": 5,
//       "Country": "United States",
//       "Latitude": -81.051399,
//       "Location": "Marathon",
//       "Longitude": 24.726101,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7070,
//       "Airport Code [ICAO]": "KGDV",
//       "Airport Code iATA,FAA": "GDV",
//       "Airport Name": "Dawson Community Airport",
//       "Altitude (Ft)": 2458,
//       "Country": "United States",
//       "Latitude": -104.8069992,
//       "Location": "Glendive",
//       "Longitude": 47.13869858,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7071,
//       "Airport Code [ICAO]": "KOLF",
//       "Airport Code iATA,FAA": "OLF",
//       "Airport Name": "L M Clayton Airport",
//       "Altitude (Ft)": 1986,
//       "Country": "United States",
//       "Latitude": -105.574996948,
//       "Location": "Wolf Point",
//       "Longitude": 48.094501495399996,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7072,
//       "Airport Code [ICAO]": "KWYS",
//       "Airport Code iATA,FAA": "WYS",
//       "Airport Name": "Yellowstone Airport",
//       "Altitude (Ft)": 6649,
//       "Country": "United States",
//       "Latitude": -111.1179962,
//       "Location": "West Yellowstone",
//       "Longitude": 44.68840027,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7073,
//       "Airport Code [ICAO]": "KALS",
//       "Airport Code iATA,FAA": "ALS",
//       "Airport Name": "San Luis Valley Regional Bergman Field",
//       "Altitude (Ft)": 7539,
//       "Country": "United States",
//       "Latitude": -105.866997,
//       "Location": "Alamosa",
//       "Longitude": 37.434898,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7074,
//       "Airport Code [ICAO]": "KCNY",
//       "Airport Code iATA,FAA": "CNY",
//       "Airport Name": "Canyonlands Field",
//       "Altitude (Ft)": 4557,
//       "Country": "United States",
//       "Latitude": -109.7549973,
//       "Location": "Moab",
//       "Longitude": 38.75500107,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7075,
//       "Airport Code [ICAO]": "KELY",
//       "Airport Code iATA,FAA": "ELY",
//       "Airport Name": "Ely Airport Yelland Field",
//       "Altitude (Ft)": 6259,
//       "Country": "United States",
//       "Latitude": -114.8420029,
//       "Location": "Ely",
//       "Longitude": 39.29970169,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7076,
//       "Airport Code [ICAO]": "KVEL",
//       "Airport Code iATA,FAA": "VEL",
//       "Airport Name": "Vernal Regional Airport",
//       "Altitude (Ft)": 5278,
//       "Country": "United States",
//       "Latitude": -109.5100021,
//       "Location": "Vernal",
//       "Longitude": 40.4408989,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7077,
//       "Airport Code [ICAO]": "KSRR",
//       "Airport Code iATA,FAA": "RUI",
//       "Airport Name": "Sierra Blanca Regional Airport",
//       "Altitude (Ft)": 6814,
//       "Country": "United States",
//       "Latitude": -105.53500366211,
//       "Location": "Ruidoso",
//       "Longitude": 33.462799072266,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7078,
//       "Airport Code [ICAO]": "KSOW",
//       "Airport Code iATA,FAA": "SOW",
//       "Airport Name": "Show Low Regional Airport",
//       "Altitude (Ft)": 6415,
//       "Country": "United States",
//       "Latitude": -110.005996704,
//       "Location": "Show Low",
//       "Longitude": 34.265499115,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 7079,
//       "Airport Code [ICAO]": "KMYL",
//       "Airport Code iATA,FAA": "MYL",
//       "Airport Name": "McCall Municipal Airport",
//       "Altitude (Ft)": 5024,
//       "Country": "United States",
//       "Latitude": -116.1009979,
//       "Location": "McCall",
//       "Longitude": 44.88970184,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7080,
//       "Airport Code [ICAO]": "KSMN",
//       "Airport Code iATA,FAA": "SMN",
//       "Airport Name": "Lemhi County Airport",
//       "Altitude (Ft)": 4043,
//       "Country": "United States",
//       "Latitude": -113.880996704,
//       "Location": "Salmon",
//       "Longitude": 45.1237983704,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7081,
//       "Airport Code [ICAO]": "KMMH",
//       "Airport Code iATA,FAA": "MMH",
//       "Airport Name": "Mammoth Yosemite Airport",
//       "Altitude (Ft)": 7135,
//       "Country": "United States",
//       "Latitude": -118.8379974,
//       "Location": "Mammoth Lakes",
//       "Longitude": 37.62409973,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7082,
//       "Airport Code [ICAO]": "KFHR",
//       "Airport Code iATA,FAA": "FRD",
//       "Airport Name": "Friday Harbor Airport",
//       "Altitude (Ft)": 113,
//       "Country": "United States",
//       "Latitude": -123.024002075,
//       "Location": "Friday Harbor",
//       "Longitude": 48.5219993591,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7083,
//       "Airport Code [ICAO]": "KORS",
//       "Airport Code iATA,FAA": "ESD",
//       "Airport Name": "Orcas Island Airport",
//       "Altitude (Ft)": 31,
//       "Country": "United States",
//       "Latitude": -122.910003662,
//       "Location": "Eastsound",
//       "Longitude": 48.7081985474,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7085,
//       "Airport Code [ICAO]": "KAST",
//       "Airport Code iATA,FAA": "AST",
//       "Airport Name": "Astoria Regional Airport",
//       "Altitude (Ft)": 15,
//       "Country": "United States",
//       "Latitude": -123.878997803,
//       "Location": "Astoria",
//       "Longitude": 46.158000946,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7086,
//       "Airport Code [ICAO]": "KONP",
//       "Airport Code iATA,FAA": "ONP",
//       "Airport Name": "Newport Municipal Airport",
//       "Altitude (Ft)": 160,
//       "Country": "United States",
//       "Latitude": -124.057999,
//       "Location": "Newport",
//       "Longitude": 44.580399,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7087,
//       "Airport Code [ICAO]": "PAEM",
//       "Airport Code iATA,FAA": "EMK",
//       "Airport Name": "Emmonak Airport",
//       "Altitude (Ft)": 13,
//       "Country": "United States",
//       "Latitude": -164.4909973,
//       "Location": "Emmonak",
//       "Longitude": 62.78609848,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7088,
//       "Airport Code [ICAO]": "PAUN",
//       "Airport Code iATA,FAA": "UNK",
//       "Airport Name": "Unalakleet Airport",
//       "Altitude (Ft)": 27,
//       "Country": "United States",
//       "Latitude": -160.798996,
//       "Location": "Unalakleet",
//       "Longitude": 63.88840103,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7089,
//       "Airport Code [ICAO]": "PAKU",
//       "Airport Code iATA,FAA": "UUK",
//       "Airport Name": "Ugnu-Kuparuk Airport",
//       "Altitude (Ft)": 67,
//       "Country": "United States",
//       "Latitude": -149.598007202,
//       "Location": "Kuparuk",
//       "Longitude": 70.33080291750001,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7090,
//       "Airport Code [ICAO]": "PAHX",
//       "Airport Code iATA,FAA": "SHX",
//       "Airport Name": "Shageluk Airport",
//       "Altitude (Ft)": 79,
//       "Country": "United States",
//       "Latitude": -159.569000244,
//       "Location": "Shageluk",
//       "Longitude": 62.6922988892,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7091,
//       "Airport Code [ICAO]": "PACH",
//       "Airport Code iATA,FAA": "CHU",
//       "Airport Name": "Chuathbaluk Airport",
//       "Altitude (Ft)": 244,
//       "Country": "United States",
//       "Latitude": -159.216003,
//       "Location": "Chuathbaluk",
//       "Longitude": 61.579102,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7092,
//       "Airport Code [ICAO]": "PAQT",
//       "Airport Code iATA,FAA": "NUI",
//       "Airport Name": "Nuiqsut Airport",
//       "Altitude (Ft)": 38,
//       "Country": "United States",
//       "Latitude": -151.005996704,
//       "Location": "Nuiqsut",
//       "Longitude": 70.2099990845,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7093,
//       "Airport Code [ICAO]": "PAEE",
//       "Airport Code iATA,FAA": "EEK",
//       "Airport Name": "Eek Airport",
//       "Altitude (Ft)": 12,
//       "Country": "United States",
//       "Latitude": -162.0438843,
//       "Location": "Eek",
//       "Longitude": 60.21367264,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7094,
//       "Airport Code [ICAO]": "PFKA",
//       "Airport Code iATA,FAA": "KUK",
//       "Airport Name": "Kasigluk Airport",
//       "Altitude (Ft)": 48,
//       "Country": "United States",
//       "Latitude": -162.5240021,
//       "Location": "Kasigluk",
//       "Longitude": 60.87440109,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7095,
//       "Airport Code [ICAO]": "PFKW",
//       "Airport Code iATA,FAA": "KWT",
//       "Airport Name": "Kwethluk Airport",
//       "Altitude (Ft)": 25,
//       "Country": "United States",
//       "Latitude": -161.444000244,
//       "Location": "Kwethluk",
//       "Longitude": 60.790298461899994,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7096,
//       "Airport Code [ICAO]": "PAGG",
//       "Airport Code iATA,FAA": "KWK",
//       "Airport Name": "Kwigillingok Airport",
//       "Altitude (Ft)": 18,
//       "Country": "United States",
//       "Latitude": -163.169005,
//       "Location": "Kwigillingok",
//       "Longitude": 59.876499,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7097,
//       "Airport Code [ICAO]": "PADM",
//       "Airport Code iATA,FAA": "MLL",
//       "Airport Name": "Marshall Don Hunter Sr Airport",
//       "Altitude (Ft)": 103,
//       "Country": "United States",
//       "Latitude": -162.026000977,
//       "Location": "Marshall",
//       "Longitude": 61.8642997742,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7098,
//       "Airport Code [ICAO]": "PARS",
//       "Airport Code iATA,FAA": "RSH",
//       "Airport Name": "Russian Mission Airport",
//       "Altitude (Ft)": 51,
//       "Country": "United States",
//       "Latitude": -161.319458008,
//       "Location": "Russian Mission",
//       "Longitude": 61.7788848877,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7101,
//       "Airport Code [ICAO]": "PAJZ",
//       "Airport Code iATA,FAA": "KGK",
//       "Airport Name": "Koliganek Airport",
//       "Altitude (Ft)": 269,
//       "Country": "United States",
//       "Latitude": -157.259002686,
//       "Location": "Koliganek",
//       "Longitude": 59.726600647,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7103,
//       "Airport Code [ICAO]": "PAMB",
//       "Airport Code iATA,FAA": "KMO",
//       "Airport Name": "Manokotak Airport",
//       "Altitude (Ft)": 100,
//       "Country": "United States",
//       "Latitude": -159.050003052,
//       "Location": "Manokotak",
//       "Longitude": 58.990200042699996,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7105,
//       "Airport Code [ICAO]": "PACI",
//       "Airport Code iATA,FAA": "CIK",
//       "Airport Name": "Chalkyitsik Airport",
//       "Altitude (Ft)": 544,
//       "Country": "United States",
//       "Latitude": -143.740005493,
//       "Location": "Chalkyitsik",
//       "Longitude": 66.6449966431,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7106,
//       "Airport Code [ICAO]": "PAEG",
//       "Airport Code iATA,FAA": "EAA",
//       "Airport Name": "Eagle Airport",
//       "Altitude (Ft)": 908,
//       "Country": "United States",
//       "Latitude": -141.151001,
//       "Location": "Eagle",
//       "Longitude": 64.77639771,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7107,
//       "Airport Code [ICAO]": "PAHU",
//       "Airport Code iATA,FAA": "HUS",
//       "Airport Name": "Hughes Airport",
//       "Altitude (Ft)": 299,
//       "Country": "United States",
//       "Latitude": -154.2630005,
//       "Location": "Hughes",
//       "Longitude": 66.04109955,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7108,
//       "Airport Code [ICAO]": "PAHL",
//       "Airport Code iATA,FAA": "HSL",
//       "Airport Name": "Huslia Airport",
//       "Altitude (Ft)": 220,
//       "Country": "United States",
//       "Latitude": -156.3509979,
//       "Location": "Huslia",
//       "Longitude": 65.69789886,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7111,
//       "Airport Code [ICAO]": "PANU",
//       "Airport Code iATA,FAA": "NUL",
//       "Airport Name": "Nulato Airport",
//       "Altitude (Ft)": 399,
//       "Country": "United States",
//       "Latitude": -158.074005,
//       "Location": "Nulato",
//       "Longitude": 64.729301,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7114,
//       "Airport Code [ICAO]": "PAVE",
//       "Airport Code iATA,FAA": "VEE",
//       "Airport Name": "Venetie Airport",
//       "Altitude (Ft)": 574,
//       "Country": "United States",
//       "Latitude": -146.365997314,
//       "Location": "Venetie",
//       "Longitude": 67.0086975098,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7115,
//       "Airport Code [ICAO]": "PAWB",
//       "Airport Code iATA,FAA": "WBQ",
//       "Airport Name": "Beaver Airport",
//       "Altitude (Ft)": 359,
//       "Country": "United States",
//       "Latitude": -147.406997681,
//       "Location": "Beaver",
//       "Longitude": 66.362197876,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7116,
//       "Airport Code [ICAO]": "PACE",
//       "Airport Code iATA,FAA": "CEM",
//       "Airport Name": "Central Airport",
//       "Altitude (Ft)": 937,
//       "Country": "United States",
//       "Latitude": -144.7830048,
//       "Location": "Central",
//       "Longitude": 65.57379913,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7117,
//       "Airport Code [ICAO]": "PAGH",
//       "Airport Code iATA,FAA": "SHG",
//       "Airport Name": "Shungnak Airport",
//       "Altitude (Ft)": 197,
//       "Country": "United States",
//       "Latitude": -157.16200256348,
//       "Location": "Shungnak",
//       "Longitude": 66.88809967041,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7120,
//       "Airport Code [ICAO]": "KIYK",
//       "Airport Code iATA,FAA": "IYK",
//       "Airport Name": "Inyokern Airport",
//       "Altitude (Ft)": 2457,
//       "Country": "United States",
//       "Latitude": -117.8300018,
//       "Location": "Inyokern",
//       "Longitude": 35.65879822,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7121,
//       "Airport Code [ICAO]": "KVIS",
//       "Airport Code iATA,FAA": "VIS",
//       "Airport Name": "Visalia Municipal Airport",
//       "Altitude (Ft)": 295,
//       "Country": "United States",
//       "Latitude": -119.392997742,
//       "Location": "Visalia",
//       "Longitude": 36.3186988831,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7122,
//       "Airport Code [ICAO]": "KMCE",
//       "Airport Code iATA,FAA": "MCE",
//       "Airport Name": "Merced Regional Macready Field",
//       "Altitude (Ft)": 155,
//       "Country": "United States",
//       "Latitude": -120.5139999,
//       "Location": "Merced",
//       "Longitude": 37.28469849,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7123,
//       "Airport Code [ICAO]": "SUCA",
//       "Airport Code iATA,FAA": "CYR",
//       "Airport Name": "Laguna de Los Patos International Airport",
//       "Altitude (Ft)": 66,
//       "Country": "Uruguay",
//       "Latitude": -57.770599365234,
//       "Location": "Colonia",
//       "Longitude": -34.456401824951,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "America/Montevideo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7125,
//       "Airport Code [ICAO]": "SDAM",
//       "Airport Code iATA,FAA": "CPQ",
//       "Airport Name": "Amarais Airport",
//       "Altitude (Ft)": 2008,
//       "Country": "Brazil",
//       "Latitude": -47.10820007324219,
//       "Location": "Campinas",
//       "Longitude": -22.85919952392578,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7126,
//       "Airport Code [ICAO]": "KGYR",
//       "Airport Code iATA,FAA": "GYR",
//       "Airport Name": "Phoenix Goodyear Airport",
//       "Altitude (Ft)": 968,
//       "Country": "United States",
//       "Latitude": -112.375999451,
//       "Location": "Goodyear",
//       "Longitude": 33.4225006104,
//       "GMT Offset": "-7",
//       "Daylight Savings": "N",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 7128,
//       "Airport Code [ICAO]": "YTWB",
//       "Airport Code iATA,FAA": "TWB",
//       "Airport Name": "Toowoomba Airport",
//       "Altitude (Ft)": 2086,
//       "Country": "Australia",
//       "Latitude": 151.916,
//       "Location": "Toowoomba",
//       "Longitude": -27.542801,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 7129,
//       "Airport Code [ICAO]": "YLLE",
//       "Airport Code iATA,FAA": "BBL",
//       "Airport Name": "Ballera Airport",
//       "Altitude (Ft)": 385,
//       "Country": "Australia",
//       "Latitude": 141.809458,
//       "Location": "Ballera",
//       "Longitude": -27.405633,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 7130,
//       "Airport Code [ICAO]": "YGAT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Gatton Campus Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 152.340459824,
//       "Location": "Gatton",
//       "Longitude": -27.5604060653,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 7131,
//       "Airport Code [ICAO]": "UAUR",
//       "Airport Code iATA,FAA": "AYK",
//       "Airport Name": "Arkalyk North Airport",
//       "Altitude (Ft)": 1266,
//       "Country": "Kazakhstan",
//       "Latitude": 66.95279693603516,
//       "Location": "Arkalyk",
//       "Longitude": 50.318599700927734,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Qyzylorda",
//       "Type": "airport"
//     },
//     {
//       "ID": 7134,
//       "Airport Code [ICAO]": "EDTN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nabern/Teck Airport",
//       "Altitude (Ft)": 1214,
//       "Country": "Germany",
//       "Latitude": 9.477222442629998,
//       "Location": "Kirchheim-Teck",
//       "Longitude": 48.61277771,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 7135,
//       "Airport Code [ICAO]": "PAGN",
//       "Airport Code iATA,FAA": "AGN",
//       "Airport Name": "Angoon Seaplane Base",
//       "Altitude (Ft)": 0,
//       "Country": "United States",
//       "Latitude": -134.585007,
//       "Location": "Angoon",
//       "Longitude": 57.503601,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7136,
//       "Airport Code [ICAO]": "PAEL",
//       "Airport Code iATA,FAA": "ELV",
//       "Airport Name": "Elfin Cove Seaplane Base",
//       "Altitude (Ft)": 0,
//       "Country": "United States",
//       "Latitude": -136.347000122,
//       "Location": "Elfin Cove",
//       "Longitude": 58.195201873799995,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7140,
//       "Airport Code [ICAO]": "PANR",
//       "Airport Code iATA,FAA": "FNR",
//       "Airport Name": "Funter Bay Seaplane Base",
//       "Altitude (Ft)": 0,
//       "Country": "United States",
//       "Latitude": -134.897994995,
//       "Location": "Funter Bay",
//       "Longitude": 58.2543983459,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7142,
//       "Airport Code [ICAO]": "PAOH",
//       "Airport Code iATA,FAA": "HNH",
//       "Airport Name": "Hoonah Airport",
//       "Altitude (Ft)": 19,
//       "Country": "United States",
//       "Latitude": -135.410111,
//       "Location": "Hoonah",
//       "Longitude": 58.0961,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7143,
//       "Airport Code [ICAO]": "PAFE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kake Airport",
//       "Altitude (Ft)": 172,
//       "Country": "United States",
//       "Latitude": -133.910003662,
//       "Location": "Kake",
//       "Longitude": 56.9613990784,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7146,
//       "Airport Code [ICAO]": "PAMM",
//       "Airport Code iATA,FAA": "MTM",
//       "Airport Name": "Metlakatla Seaplane Base",
//       "Altitude (Ft)": 0,
//       "Country": "United States",
//       "Latitude": -131.578003,
//       "Location": "Metakatla",
//       "Longitude": 55.131001,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7148,
//       "Airport Code [ICAO]": "PAHY",
//       "Airport Code iATA,FAA": "HYG",
//       "Airport Name": "Hydaburg Seaplane Base",
//       "Altitude (Ft)": 0,
//       "Country": "United States",
//       "Latitude": -132.8280029296875,
//       "Location": "Hydaburg",
//       "Longitude": 55.206298828125,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7154,
//       "Airport Code [ICAO]": "PAII",
//       "Airport Code iATA,FAA": "EGX",
//       "Airport Name": "Egegik Airport",
//       "Altitude (Ft)": 92,
//       "Country": "United States",
//       "Latitude": -157.375,
//       "Location": "Egegik",
//       "Longitude": 58.1855010986,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7157,
//       "Airport Code [ICAO]": "PAPE",
//       "Airport Code iATA,FAA": "KPV",
//       "Airport Name": "Perryville Airport",
//       "Altitude (Ft)": 29,
//       "Country": "United States",
//       "Latitude": -159.162993,
//       "Location": "Perryville",
//       "Longitude": 55.905998,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7158,
//       "Airport Code [ICAO]": "PAPN",
//       "Airport Code iATA,FAA": "PIP",
//       "Airport Name": "Pilot Point Airport",
//       "Altitude (Ft)": 57,
//       "Country": "United States",
//       "Latitude": -157.572006226,
//       "Location": "Pilot Point",
//       "Longitude": 57.5803985596,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7159,
//       "Airport Code [ICAO]": "PFWS",
//       "Airport Code iATA,FAA": "WSN",
//       "Airport Name": "South Naknek Nr 2 Airport",
//       "Altitude (Ft)": 162,
//       "Country": "United States",
//       "Latitude": -157.007995605,
//       "Location": "South Naknek",
//       "Longitude": 58.7033996582,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7160,
//       "Airport Code [ICAO]": "PAKH",
//       "Airport Code iATA,FAA": "AKK",
//       "Airport Name": "Akhiok Airport",
//       "Altitude (Ft)": 44,
//       "Country": "United States",
//       "Latitude": -154.182998657,
//       "Location": "Akhiok",
//       "Longitude": 56.9387016296,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7161,
//       "Airport Code [ICAO]": "PAKY",
//       "Airport Code iATA,FAA": "KYK",
//       "Airport Name": "Karluk Airport",
//       "Altitude (Ft)": 137,
//       "Country": "United States",
//       "Latitude": -154.449996948,
//       "Location": "Karluk",
//       "Longitude": 57.5671005249,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7162,
//       "Airport Code [ICAO]": "PALB",
//       "Airport Code iATA,FAA": "KLN",
//       "Airport Name": "Larsen Bay Airport",
//       "Altitude (Ft)": 87,
//       "Country": "United States",
//       "Latitude": -153.977996826,
//       "Location": "Larsen Bay",
//       "Longitude": 57.5350990295,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7177,
//       "Airport Code [ICAO]": "PAFM",
//       "Airport Code iATA,FAA": "ABL",
//       "Airport Name": "Ambler Airport",
//       "Altitude (Ft)": 334,
//       "Country": "United States",
//       "Latitude": -157.856989,
//       "Location": "Ambler",
//       "Longitude": 67.1063,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7178,
//       "Airport Code [ICAO]": "PABL",
//       "Airport Code iATA,FAA": "BKC",
//       "Airport Name": "Buckland Airport",
//       "Altitude (Ft)": 31,
//       "Country": "United States",
//       "Latitude": -161.149002075,
//       "Location": "Buckland",
//       "Longitude": 65.9815979004,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7179,
//       "Airport Code [ICAO]": "PAIK",
//       "Airport Code iATA,FAA": "IAN",
//       "Airport Name": "Bob Baker Memorial Airport",
//       "Altitude (Ft)": 166,
//       "Country": "United States",
//       "Latitude": -160.43699646,
//       "Location": "Kiana",
//       "Longitude": 66.9759979248,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7180,
//       "Airport Code [ICAO]": "PAOB",
//       "Airport Code iATA,FAA": "OBU",
//       "Airport Name": "Kobuk Airport",
//       "Altitude (Ft)": 137,
//       "Country": "United States",
//       "Latitude": -156.897003174,
//       "Location": "Kobuk",
//       "Longitude": 66.9123001099,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7181,
//       "Airport Code [ICAO]": "PFNO",
//       "Airport Code iATA,FAA": "ORV",
//       "Airport Name": "Robert (Bob) Curtis Memorial Airport",
//       "Altitude (Ft)": 55,
//       "Country": "United States",
//       "Latitude": -161.0189972,
//       "Location": "Noorvik",
//       "Longitude": 66.81790161,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7182,
//       "Airport Code [ICAO]": "PASK",
//       "Airport Code iATA,FAA": "WLK",
//       "Airport Name": "Selawik Airport",
//       "Altitude (Ft)": 17,
//       "Country": "United States",
//       "Latitude": -159.9859924,
//       "Location": "Selawik",
//       "Longitude": 66.60009766,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7183,
//       "Airport Code [ICAO]": "PFKT",
//       "Airport Code iATA,FAA": "KTS",
//       "Airport Name": "Brevig Mission Airport",
//       "Altitude (Ft)": 38,
//       "Country": "United States",
//       "Latitude": -166.466003418,
//       "Location": "Brevig Mission",
//       "Longitude": 65.3312988281,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7184,
//       "Airport Code [ICAO]": "PFEL",
//       "Airport Code iATA,FAA": "ELI",
//       "Airport Name": "Elim Airport",
//       "Altitude (Ft)": 162,
//       "Country": "United States",
//       "Latitude": -162.2720032,
//       "Location": "Elim",
//       "Longitude": 64.61470032,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7185,
//       "Airport Code [ICAO]": "PAGL",
//       "Airport Code iATA,FAA": "GLV",
//       "Airport Name": "Golovin Airport",
//       "Altitude (Ft)": 59,
//       "Country": "United States",
//       "Latitude": -163.007003784,
//       "Location": "Golovin",
//       "Longitude": 64.5504989624,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7186,
//       "Airport Code [ICAO]": "PATE",
//       "Airport Code iATA,FAA": "TLA",
//       "Airport Name": "Teller Airport",
//       "Altitude (Ft)": 294,
//       "Country": "United States",
//       "Latitude": -166.339004517,
//       "Location": "Teller",
//       "Longitude": 65.2404022217,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7187,
//       "Airport Code [ICAO]": "PAIW",
//       "Airport Code iATA,FAA": "WAA",
//       "Airport Name": "Wales Airport",
//       "Altitude (Ft)": 22,
//       "Country": "United States",
//       "Latitude": -168.095,
//       "Location": "Wales",
//       "Longitude": 65.622593,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7188,
//       "Airport Code [ICAO]": "PAWM",
//       "Airport Code iATA,FAA": "WMO",
//       "Airport Name": "White Mountain Airport",
//       "Altitude (Ft)": 267,
//       "Country": "United States",
//       "Latitude": -163.412994385,
//       "Location": "White Mountain",
//       "Longitude": 64.689201355,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7190,
//       "Airport Code [ICAO]": "PAKK",
//       "Airport Code iATA,FAA": "KKA",
//       "Airport Name": "Koyuk Alfred Adams Airport",
//       "Altitude (Ft)": 154,
//       "Country": "United States",
//       "Latitude": -161.154006958,
//       "Location": "Koyuk",
//       "Longitude": 64.9394989014,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7191,
//       "Airport Code [ICAO]": "PAMK",
//       "Airport Code iATA,FAA": "SMK",
//       "Airport Name": "St Michael Airport",
//       "Altitude (Ft)": 98,
//       "Country": "United States",
//       "Latitude": -162.1100006,
//       "Location": "St. Michael",
//       "Longitude": 63.49010086,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7192,
//       "Airport Code [ICAO]": "PFSH",
//       "Airport Code iATA,FAA": "SKK",
//       "Airport Name": "Shaktoolik Airport",
//       "Altitude (Ft)": 24,
//       "Country": "United States",
//       "Latitude": -161.223999,
//       "Location": "Shaktoolik",
//       "Longitude": 64.37110138,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7194,
//       "Airport Code [ICAO]": "PATC",
//       "Airport Code iATA,FAA": "TNC",
//       "Airport Name": "Tin City Long Range Radar Station Airport",
//       "Altitude (Ft)": 271,
//       "Country": "United States",
//       "Latitude": -167.9219971,
//       "Location": "Tin City",
//       "Longitude": 65.56310272,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7195,
//       "Airport Code [ICAO]": "PAAK",
//       "Airport Code iATA,FAA": "AKB",
//       "Airport Name": "Atka Airport",
//       "Altitude (Ft)": 57,
//       "Country": "United States",
//       "Latitude": -174.2059937,
//       "Location": "Atka",
//       "Longitude": 52.22029877,
//       "GMT Offset": "-10",
//       "Daylight Savings": "A",
//       "Region": "America/Adak",
//       "Type": "airport"
//     },
//     {
//       "ID": 7196,
//       "Airport Code [ICAO]": "PAKO",
//       "Airport Code iATA,FAA": "IKO",
//       "Airport Name": "Nikolski Air Station",
//       "Altitude (Ft)": 77,
//       "Country": "United States",
//       "Latitude": -168.8489990234375,
//       "Location": "Nikolski",
//       "Longitude": 52.94160079956055,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7198,
//       "Airport Code [ICAO]": "PACY",
//       "Airport Code iATA,FAA": "CYT",
//       "Airport Name": "Yakataga Airport",
//       "Altitude (Ft)": 12,
//       "Country": "United States",
//       "Latitude": -142.494541,
//       "Location": "Yakataga",
//       "Longitude": 60.080974,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7199,
//       "Airport Code [ICAO]": "PAUK",
//       "Airport Code iATA,FAA": "AUK",
//       "Airport Name": "Alakanuk Airport",
//       "Altitude (Ft)": 10,
//       "Country": "United States",
//       "Latitude": -164.659927368,
//       "Location": "Alakanuk",
//       "Longitude": 62.680042266799994,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7201,
//       "Airport Code [ICAO]": "PAKI",
//       "Airport Code iATA,FAA": "KPN",
//       "Airport Name": "Kipnuk Airport",
//       "Altitude (Ft)": 11,
//       "Country": "United States",
//       "Latitude": -164.031005859,
//       "Location": "Kipnuk",
//       "Longitude": 59.932998657199995,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7202,
//       "Airport Code [ICAO]": "PAKF",
//       "Airport Code iATA,FAA": "KFP",
//       "Airport Name": "False Pass Airport",
//       "Altitude (Ft)": 20,
//       "Country": "United States",
//       "Latitude": -163.41000366210938,
//       "Location": "False Pass",
//       "Longitude": 54.8474006652832,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7203,
//       "Airport Code [ICAO]": "PAOU",
//       "Airport Code iATA,FAA": "NLG",
//       "Airport Name": "Nelson Lagoon Airport",
//       "Altitude (Ft)": 14,
//       "Country": "United States",
//       "Latitude": -161.16000366211,
//       "Location": "Nelson Lagoon",
//       "Longitude": 56.007499694824,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7204,
//       "Airport Code [ICAO]": "PAAL",
//       "Airport Code iATA,FAA": "PML",
//       "Airport Name": "Port Moller Airport",
//       "Altitude (Ft)": 20,
//       "Country": "United States",
//       "Latitude": -160.561004639,
//       "Location": "Cold Bay",
//       "Longitude": 56.0060005188,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7205,
//       "Airport Code [ICAO]": "PAKW",
//       "Airport Code iATA,FAA": "KLW",
//       "Airport Name": "Klawock Airport",
//       "Altitude (Ft)": 80,
//       "Country": "United States",
//       "Latitude": -133.076004028,
//       "Location": "Klawock",
//       "Longitude": 55.579200744599994,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7206,
//       "Airport Code [ICAO]": "PAQH",
//       "Airport Code iATA,FAA": "KWN",
//       "Airport Name": "Quinhagak Airport",
//       "Altitude (Ft)": 42,
//       "Country": "United States",
//       "Latitude": -161.845,
//       "Location": "Quinhagak",
//       "Longitude": 59.7551,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7207,
//       "Airport Code [ICAO]": "PFKO",
//       "Airport Code iATA,FAA": "KOT",
//       "Airport Name": "Kotlik Airport",
//       "Altitude (Ft)": 15,
//       "Country": "United States",
//       "Latitude": -163.533004761,
//       "Location": "Kotlik",
//       "Longitude": 63.0306015015,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7208,
//       "Airport Code [ICAO]": "PFKU",
//       "Airport Code iATA,FAA": "KYU",
//       "Airport Name": "Koyukuk Airport",
//       "Altitude (Ft)": 149,
//       "Country": "United States",
//       "Latitude": -157.727005005,
//       "Location": "Koyukuk",
//       "Longitude": 64.8760986328,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7209,
//       "Airport Code [ICAO]": "PACM",
//       "Airport Code iATA,FAA": "SCM",
//       "Airport Name": "Scammon Bay Airport",
//       "Altitude (Ft)": 14,
//       "Country": "United States",
//       "Latitude": -165.570999146,
//       "Location": "Scammon Bay",
//       "Longitude": 61.845298767100005,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7210,
//       "Airport Code [ICAO]": "PANO",
//       "Airport Code iATA,FAA": "NNL",
//       "Airport Name": "Nondalton Airport",
//       "Altitude (Ft)": 314,
//       "Country": "United States",
//       "Latitude": -154.8390045166,
//       "Location": "Nondalton",
//       "Longitude": 59.980201721191,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7213,
//       "Airport Code [ICAO]": "PADY",
//       "Airport Code iATA,FAA": "KKH",
//       "Airport Name": "Kongiganak Airport",
//       "Altitude (Ft)": 30,
//       "Country": "United States",
//       "Latitude": -162.880996704,
//       "Location": "Kongiganak",
//       "Longitude": 59.960800170899994,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7214,
//       "Airport Code [ICAO]": "PAFS",
//       "Airport Code iATA,FAA": "NIB",
//       "Airport Name": "Nikolai Airport",
//       "Altitude (Ft)": 441,
//       "Country": "United States",
//       "Latitude": -154.358002,
//       "Location": "Nikolai",
//       "Longitude": 63.0186,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7217,
//       "Airport Code [ICAO]": "PFAK",
//       "Airport Code iATA,FAA": "AKI",
//       "Airport Name": "Akiak Airport",
//       "Altitude (Ft)": 30,
//       "Country": "United States",
//       "Latitude": -161.231002808,
//       "Location": "Akiak",
//       "Longitude": 60.9029006958,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7220,
//       "Airport Code [ICAO]": "PAWI",
//       "Airport Code iATA,FAA": "AIN",
//       "Airport Name": "Wainwright Airport",
//       "Altitude (Ft)": 41,
//       "Country": "United States",
//       "Latitude": -159.994995117,
//       "Location": "Wainwright",
//       "Longitude": 70.6380004883,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7221,
//       "Airport Code [ICAO]": "SAHZ",
//       "Airport Code iATA,FAA": "APZ",
//       "Airport Name": "Zapala Airport",
//       "Altitude (Ft)": 3330,
//       "Country": "Argentina",
//       "Latitude": -70.113602,
//       "Location": "ZAPALA",
//       "Longitude": -38.975498,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Salta",
//       "Type": "airport"
//     },
//     {
//       "ID": 7222,
//       "Airport Code [ICAO]": "SAHS",
//       "Airport Code iATA,FAA": "RDS",
//       "Airport Name": "Rincon De Los Sauces Airport",
//       "Altitude (Ft)": 1968,
//       "Country": "Argentina",
//       "Latitude": -68.9041976929,
//       "Location": "Rincon de los Sauces",
//       "Longitude": -37.3905982971,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Salta",
//       "Type": "airport"
//     },
//     {
//       "ID": 7226,
//       "Airport Code [ICAO]": "SCNT",
//       "Airport Code iATA,FAA": "PNT",
//       "Airport Name": "Tte. Julio Gallardo Airport",
//       "Altitude (Ft)": 217,
//       "Country": "Chile",
//       "Latitude": -72.52839660644531,
//       "Location": "Puerto Natales",
//       "Longitude": -51.67150115966797,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7229,
//       "Airport Code [ICAO]": "SAVS",
//       "Airport Code iATA,FAA": "SGV",
//       "Airport Name": "Sierra Grande Airport",
//       "Altitude (Ft)": 688,
//       "Country": "Argentina",
//       "Latitude": -65.33940124509999,
//       "Location": "Sierra Grande",
//       "Longitude": -41.5917015076,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Salta",
//       "Type": "airport"
//     },
//     {
//       "ID": 7230,
//       "Airport Code [ICAO]": "SAVJ",
//       "Airport Code iATA,FAA": "IGB",
//       "Airport Name": "Cabo F.A.A. H. R. Bordón Airport",
//       "Altitude (Ft)": 2925,
//       "Country": "Argentina",
//       "Latitude": -69.5748977661,
//       "Location": "Ingeniero Jacobacci",
//       "Longitude": -41.320899963399995,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Argentina/Salta",
//       "Type": "airport"
//     },
//     {
//       "ID": 7233,
//       "Airport Code [ICAO]": "PFCB",
//       "Airport Code iATA,FAA": "NCN",
//       "Airport Name": "Chenega Bay Airport",
//       "Altitude (Ft)": 72,
//       "Country": "United States",
//       "Latitude": -147.992004395,
//       "Location": "Chenega",
//       "Longitude": 60.0773010254,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7235,
//       "Airport Code [ICAO]": "PFTO",
//       "Airport Code iATA,FAA": "TKJ",
//       "Airport Name": "Tok Junction Airport",
//       "Altitude (Ft)": 1639,
//       "Country": "United States",
//       "Latitude": -142.9539948,
//       "Location": "Tok",
//       "Longitude": 63.32949829,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7236,
//       "Airport Code [ICAO]": "PACR",
//       "Airport Code iATA,FAA": "IRC",
//       "Airport Name": "Circle City /New/ Airport",
//       "Altitude (Ft)": 613,
//       "Country": "United States",
//       "Latitude": -144.076008,
//       "Location": "Circle",
//       "Longitude": 65.830498,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7240,
//       "Airport Code [ICAO]": "PASL",
//       "Airport Code iATA,FAA": "SLQ",
//       "Airport Name": "Sleetmute Airport",
//       "Altitude (Ft)": 190,
//       "Country": "United States",
//       "Latitude": -157.166000366,
//       "Location": "Sleetmute",
//       "Longitude": 61.7005004883,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7242,
//       "Airport Code [ICAO]": "PAHV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Healy River Airport",
//       "Altitude (Ft)": 1263,
//       "Country": "United States",
//       "Latitude": -148.968994140625,
//       "Location": "Healy",
//       "Longitude": 63.8661994934082,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7244,
//       "Airport Code [ICAO]": "PAQC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Klawock Seaplane Base",
//       "Altitude (Ft)": 0,
//       "Country": "United States",
//       "Latitude": -133.1020050048828,
//       "Location": "Klawock",
//       "Longitude": 55.5546989440918,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7245,
//       "Airport Code [ICAO]": "PAMH",
//       "Airport Code iATA,FAA": "LMA",
//       "Airport Name": "Minchumina Airport",
//       "Altitude (Ft)": 678,
//       "Country": "United States",
//       "Latitude": -152.302002,
//       "Location": "Lake Minchumina",
//       "Longitude": 63.886002,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7246,
//       "Airport Code [ICAO]": "PAML",
//       "Airport Code iATA,FAA": "MLY",
//       "Airport Name": "Manley Hot Springs Airport",
//       "Altitude (Ft)": 270,
//       "Country": "United States",
//       "Latitude": -150.643997192,
//       "Location": "Manley Hot Springs",
//       "Longitude": 64.99759674069999,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7251,
//       "Airport Code [ICAO]": "CNH2",
//       "Airport Code iATA,FAA": "YNP",
//       "Airport Name": "Natuashish Airport",
//       "Altitude (Ft)": 30,
//       "Country": "Canada",
//       "Latitude": -61.184399,
//       "Location": "Natuashish",
//       "Longitude": 55.913898,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Halifax",
//       "Type": "airport"
//     },
//     {
//       "ID": 7252,
//       "Airport Code [ICAO]": "CCD4",
//       "Airport Code iATA,FAA": "YSO",
//       "Airport Name": "Postville Airport",
//       "Altitude (Ft)": 193,
//       "Country": "Canada",
//       "Latitude": -59.78507,
//       "Location": "Postville",
//       "Longitude": 54.9105,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Halifax",
//       "Type": "airport"
//     },
//     {
//       "ID": 7253,
//       "Airport Code [ICAO]": "CYKG",
//       "Airport Code iATA,FAA": "YWB",
//       "Airport Name": "Kangiqsujuaq (Wakeham Bay) Airport",
//       "Altitude (Ft)": 501,
//       "Country": "Canada",
//       "Latitude": -71.929397583,
//       "Location": "Kangiqsujuaq",
//       "Longitude": 61.5886001587,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 7254,
//       "Airport Code [ICAO]": "CYTF",
//       "Airport Code iATA,FAA": "YTF",
//       "Airport Name": "Alma Airport",
//       "Altitude (Ft)": 445,
//       "Country": "Canada",
//       "Latitude": -71.64189910889999,
//       "Location": "Alma",
//       "Longitude": 48.50889968869999,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 7255,
//       "Airport Code [ICAO]": "CYGV",
//       "Airport Code iATA,FAA": "YGV",
//       "Airport Name": "Havre St Pierre Airport",
//       "Altitude (Ft)": 124,
//       "Country": "Canada",
//       "Latitude": -63.61140060424805,
//       "Location": "Havre-Saint-Pierre",
//       "Longitude": 50.281898498535156,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 7256,
//       "Airport Code [ICAO]": "CYXK",
//       "Airport Code iATA,FAA": "YXK",
//       "Airport Name": "Rimouski Airport",
//       "Altitude (Ft)": 82,
//       "Country": "Canada",
//       "Latitude": -68.49690246582031,
//       "Location": "Rimouski",
//       "Longitude": 48.47809982299805,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 7259,
//       "Airport Code [ICAO]": "CYBQ",
//       "Airport Code iATA,FAA": "XTL",
//       "Airport Name": "Tadoule Lake Airport",
//       "Altitude (Ft)": 923,
//       "Country": "Canada",
//       "Latitude": -98.512199,
//       "Location": "Tadoule Lake",
//       "Longitude": 58.7061,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 7260,
//       "Airport Code [ICAO]": "CZWH",
//       "Airport Code iATA,FAA": "XLB",
//       "Airport Name": "Lac Brochet Airport",
//       "Altitude (Ft)": 1211,
//       "Country": "Canada",
//       "Latitude": -101.46900177,
//       "Location": "Lac Brochet",
//       "Longitude": 58.6175003052,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 7261,
//       "Airport Code [ICAO]": "CZSN",
//       "Airport Code iATA,FAA": "XSI",
//       "Airport Name": "South Indian Lake Airport",
//       "Altitude (Ft)": 951,
//       "Country": "Canada",
//       "Latitude": -98.9072036743,
//       "Location": "South Indian Lake",
//       "Longitude": 56.7928009033,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 7262,
//       "Airport Code [ICAO]": "CYBT",
//       "Airport Code iATA,FAA": "YBT",
//       "Airport Name": "Brochet Airport",
//       "Altitude (Ft)": 1136,
//       "Country": "Canada",
//       "Latitude": -101.679001,
//       "Location": "Brochet",
//       "Longitude": 57.8894,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 7263,
//       "Airport Code [ICAO]": "CZGR",
//       "Airport Code iATA,FAA": "ZGR",
//       "Airport Name": "Little Grand Rapids Airport",
//       "Altitude (Ft)": 1005,
//       "Country": "Canada",
//       "Latitude": -95.4657974243164,
//       "Location": "Little Grand Rapids",
//       "Longitude": 52.04560089111328,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 7264,
//       "Airport Code [ICAO]": "CYCR",
//       "Airport Code iATA,FAA": "YCR",
//       "Airport Name": "Cross Lake (Charlie Sinclair Memorial) Airport",
//       "Altitude (Ft)": 709,
//       "Country": "Canada",
//       "Latitude": -97.76080322265625,
//       "Location": "Cross Lake",
//       "Longitude": 54.610599517822266,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 7265,
//       "Airport Code [ICAO]": "CYRS",
//       "Airport Code iATA,FAA": "YRS",
//       "Airport Name": "Red Sucker Lake Airport",
//       "Altitude (Ft)": 729,
//       "Country": "Canada",
//       "Latitude": -93.55719757080078,
//       "Location": "Red Sucker Lake",
//       "Longitude": 54.167198181152344,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 7266,
//       "Airport Code [ICAO]": "CYOP",
//       "Airport Code iATA,FAA": "YOP",
//       "Airport Name": "Rainbow Lake Airport",
//       "Altitude (Ft)": 1759,
//       "Country": "Canada",
//       "Latitude": -119.40799713134766,
//       "Location": "Rainbow Lake",
//       "Longitude": 58.49140167236328,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 7267,
//       "Airport Code [ICAO]": "CYBF",
//       "Airport Code iATA,FAA": "YBY",
//       "Airport Name": "Bonnyville Airport",
//       "Altitude (Ft)": 1836,
//       "Country": "Canada",
//       "Latitude": -110.744003,
//       "Location": "Bonnyville",
//       "Longitude": 54.304199,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 7268,
//       "Airport Code [ICAO]": "CAC8",
//       "Airport Code iATA,FAA": "ZNA",
//       "Airport Name": "Nanaimo Harbour Water Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Canada",
//       "Latitude": -123.949996948,
//       "Location": "Nanaimo",
//       "Longitude": 49.1833000183,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7269,
//       "Airport Code [ICAO]": "CAX6",
//       "Airport Code iATA,FAA": "YGG",
//       "Airport Name": "Ganges Seaplane Base",
//       "Altitude (Ft)": 0,
//       "Country": "Canada",
//       "Latitude": -123.4969,
//       "Location": "Ganges",
//       "Longitude": 48.8545,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7272,
//       "Airport Code [ICAO]": "CYJM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Fort St James Airport",
//       "Altitude (Ft)": 2364,
//       "Country": "Canada",
//       "Latitude": -124.26300048828125,
//       "Location": "Fort St. James",
//       "Longitude": 54.39720153808594,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7273,
//       "Airport Code [ICAO]": "CZBB",
//       "Airport Code iATA,FAA": "YDT",
//       "Airport Name": "Boundary Bay Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Canada",
//       "Latitude": -123.012001,
//       "Location": "Boundary Bay",
//       "Longitude": 49.0742,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7274,
//       "Airport Code [ICAO]": "CYNJ",
//       "Airport Code iATA,FAA": "YLY",
//       "Airport Name": "Langley Airport",
//       "Altitude (Ft)": 34,
//       "Country": "Canada",
//       "Latitude": -122.630996704,
//       "Location": "Langley Township",
//       "Longitude": 49.10079956049999,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7275,
//       "Airport Code [ICAO]": "CYJQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Denny Island Airport",
//       "Altitude (Ft)": 162,
//       "Country": "Canada",
//       "Latitude": -128.059748,
//       "Location": "Bella Bella",
//       "Longitude": 52.137937,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7277,
//       "Airport Code [ICAO]": "CFJ2",
//       "Airport Code iATA,FAA": "YFJ",
//       "Airport Name": "Wekweètì Airport",
//       "Altitude (Ft)": 1208,
//       "Country": "Canada",
//       "Latitude": -114.077002,
//       "Location": "Wekweeti",
//       "Longitude": 64.190804,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 7278,
//       "Airport Code [ICAO]": "MM52",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Camaguey-Campo Cuatro Milpas Airport",
//       "Altitude (Ft)": 92,
//       "Country": "Mexico",
//       "Latitude": -108.53800201416,
//       "Location": "Guasave",
//       "Longitude": 25.652200698853,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Mazatlan",
//       "Type": "airport"
//     },
//     {
//       "ID": 7280,
//       "Airport Code [ICAO]": "MMSL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cabo San Lucas International Airport",
//       "Altitude (Ft)": 459,
//       "Country": "Mexico",
//       "Latitude": -109.936996,
//       "Location": "Cabo San Lucas",
//       "Longitude": 22.947701,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Mazatlan",
//       "Type": "airport"
//     },
//     {
//       "ID": 7291,
//       "Airport Code [ICAO]": "MNCI",
//       "Airport Code iATA,FAA": "RNI",
//       "Airport Name": "Corn Island",
//       "Altitude (Ft)": 1,
//       "Country": "Nicaragua",
//       "Latitude": -83.06379699707031,
//       "Location": "Corn Island",
//       "Longitude": 12.1628999710083,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Managua",
//       "Type": "airport"
//     },
//     {
//       "ID": 7292,
//       "Airport Code [ICAO]": "MNBZ",
//       "Airport Code iATA,FAA": "BZA",
//       "Airport Name": "San Pedro Airport",
//       "Altitude (Ft)": 600,
//       "Country": "Nicaragua",
//       "Latitude": -84.5999984741211,
//       "Location": "Bonanza",
//       "Longitude": 13.949999809265137,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Managua",
//       "Type": "airport"
//     },
//     {
//       "ID": 7293,
//       "Airport Code [ICAO]": "MNRT",
//       "Airport Code iATA,FAA": "RFS",
//       "Airport Name": "Rosita Airport",
//       "Altitude (Ft)": 193,
//       "Country": "Nicaragua",
//       "Latitude": -84.40889739990234,
//       "Location": "Rosita",
//       "Longitude": 13.889699935913086,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Managua",
//       "Type": "airport"
//     },
//     {
//       "ID": 7294,
//       "Airport Code [ICAO]": "MNSI",
//       "Airport Code iATA,FAA": "SIU",
//       "Airport Name": "Siuna",
//       "Altitude (Ft)": 606,
//       "Country": "Nicaragua",
//       "Latitude": -84.77777862548828,
//       "Location": "Siuna",
//       "Longitude": 13.727222442626953,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Managua",
//       "Type": "airport"
//     },
//     {
//       "ID": 7295,
//       "Airport Code [ICAO]": "MNWP",
//       "Airport Code iATA,FAA": "WSP",
//       "Airport Name": "Waspam Airport",
//       "Altitude (Ft)": 98,
//       "Country": "Nicaragua",
//       "Latitude": -83.96939849853516,
//       "Location": "Waspam",
//       "Longitude": 14.7391996383667,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Managua",
//       "Type": "airport"
//     },
//     {
//       "ID": 7296,
//       "Airport Code [ICAO]": "MNSC",
//       "Airport Code iATA,FAA": "NCR",
//       "Airport Name": "San Carlos",
//       "Altitude (Ft)": 91,
//       "Country": "Nicaragua",
//       "Latitude": -84.7699966430664,
//       "Location": "San Carlos",
//       "Longitude": 11.133399963378906,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Managua",
//       "Type": "airport"
//     },
//     {
//       "ID": 7297,
//       "Airport Code [ICAO]": "MRCR",
//       "Airport Code iATA,FAA": "PLD",
//       "Airport Name": "Playa Samara/Carrillo Airport",
//       "Altitude (Ft)": 50,
//       "Country": "Costa Rica",
//       "Latitude": -85.481399536133,
//       "Location": "Carrillo",
//       "Longitude": 9.8705101013184,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 7301,
//       "Airport Code [ICAO]": "MDCZ",
//       "Airport Code iATA,FAA": "COZ",
//       "Airport Name": "Constanza - Expedición 14 de Junio National Airport",
//       "Altitude (Ft)": 3950,
//       "Country": "Dominican Republic",
//       "Latitude": -70.721900939941,
//       "Location": "Constanza",
//       "Longitude": 18.907499313354,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Santo_Domingo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7302,
//       "Airport Code [ICAO]": "MKNG",
//       "Airport Code iATA,FAA": "NEG",
//       "Airport Name": "Negril Airport",
//       "Altitude (Ft)": 9,
//       "Country": "Jamaica",
//       "Latitude": -78.33209991455078,
//       "Location": "Negril",
//       "Longitude": 18.34280014038086,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Jamaica",
//       "Type": "airport"
//     },
//     {
//       "ID": 7307,
//       "Airport Code [ICAO]": "TJRV",
//       "Airport Code iATA,FAA": "NRR",
//       "Airport Name": "José Aponte de la Torre Airport",
//       "Altitude (Ft)": 38,
//       "Country": "Puerto Rico",
//       "Latitude": -65.6434020996,
//       "Location": "Ceiba",
//       "Longitude": 18.245300293,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Puerto_Rico",
//       "Type": "airport"
//     },
//     {
//       "ID": 7309,
//       "Airport Code [ICAO]": "VI22",
//       "Airport Code iATA,FAA": "SPB",
//       "Airport Name": "Charlotte Amalie Harbor Seaplane Base",
//       "Altitude (Ft)": 0,
//       "Country": "Virgin Islands",
//       "Latitude": -64.9406967163086,
//       "Location": "Charlotte Amalie",
//       "Longitude": 18.338600158691406,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/St_Thomas",
//       "Type": "airport"
//     },
//     {
//       "ID": 7311,
//       "Airport Code [ICAO]": "SAVR",
//       "Airport Code iATA,FAA": "ARR",
//       "Airport Name": "D. Casimiro Szlapelis Airport",
//       "Altitude (Ft)": 2286,
//       "Country": "Argentina",
//       "Latitude": -70.812202,
//       "Location": "Alto Rio Senguer",
//       "Longitude": -45.013599,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Catamarca",
//       "Type": "airport"
//     },
//     {
//       "ID": 7312,
//       "Airport Code [ICAO]": "SAWS",
//       "Airport Code iATA,FAA": "JSM",
//       "Airport Name": "Jose De San Martin Airport",
//       "Altitude (Ft)": 2407,
//       "Country": "Argentina",
//       "Latitude": -70.4589004517,
//       "Location": "Jose de San Martin",
//       "Longitude": -44.048599243199995,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Catamarca",
//       "Type": "airport"
//     },
//     {
//       "ID": 7313,
//       "Airport Code [ICAO]": "SLUY",
//       "Airport Code iATA,FAA": "UYU",
//       "Airport Name": "Uyuni Airport",
//       "Altitude (Ft)": 11136,
//       "Country": "Bolivia",
//       "Latitude": -66.8483963013,
//       "Location": "Uyuni",
//       "Longitude": -20.446300506599997,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/La_Paz",
//       "Type": "airport"
//     },
//     {
//       "ID": 7317,
//       "Airport Code [ICAO]": "SLRQ",
//       "Airport Code iATA,FAA": "RBQ",
//       "Airport Name": "Rurenabaque Airport",
//       "Altitude (Ft)": 676,
//       "Country": "Bolivia",
//       "Latitude": -67.4968032837,
//       "Location": "Rerrenabaque",
//       "Longitude": -14.427900314299999,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/La_Paz",
//       "Type": "airport"
//     },
//     {
//       "ID": 7320,
//       "Airport Code [ICAO]": "NGAB",
//       "Airport Code iATA,FAA": "ABF",
//       "Airport Name": "Abaiang Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Kiribati",
//       "Latitude": 173.04100036621094,
//       "Location": "Abaiang Atoll",
//       "Longitude": 1.798609972000122,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tarawa",
//       "Type": "airport"
//     },
//     {
//       "ID": 7323,
//       "Airport Code [ICAO]": "SMAF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Afobakka Airstrip",
//       "Altitude (Ft)": 80,
//       "Country": "Suriname",
//       "Latitude": -54.992033,
//       "Location": "Afobaka",
//       "Longitude": 4.998505,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "America/Paramaribo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7324,
//       "Airport Code [ICAO]": "SMDU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Alalapadu Airstrip",
//       "Altitude (Ft)": 880,
//       "Country": "Suriname",
//       "Latitude": -56.3247,
//       "Location": "Alapadu",
//       "Longitude": 2.5235,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "America/Paramaribo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7325,
//       "Airport Code [ICAO]": "SMBN",
//       "Airport Code iATA,FAA": "ABN",
//       "Airport Name": "Albina Airport",
//       "Altitude (Ft)": 19,
//       "Country": "Suriname",
//       "Latitude": -54.05009841918945,
//       "Location": "Albina",
//       "Longitude": 5.512720108032227,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "America/Paramaribo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7326,
//       "Airport Code [ICAO]": "SMLA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lawa Anapaike Airstrip",
//       "Altitude (Ft)": 373,
//       "Country": "Suriname",
//       "Latitude": -54.028049,
//       "Location": "Anapaike",
//       "Longitude": 3.411301,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "America/Paramaribo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7327,
//       "Airport Code [ICAO]": "SMPT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Apetina Airstrip",
//       "Altitude (Ft)": 0,
//       "Country": "Suriname",
//       "Latitude": -55.058791,
//       "Location": "Apetina",
//       "Longitude": 3.50278,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "America/Paramaribo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7330,
//       "Airport Code [ICAO]": "SMDA",
//       "Airport Code iATA,FAA": "DRJ",
//       "Airport Name": "Drietabbetje Airport",
//       "Altitude (Ft)": 236,
//       "Country": "Suriname",
//       "Latitude": -54.672766,
//       "Location": "Drietabbetje",
//       "Longitude": 4.111359,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "America/Paramaribo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7331,
//       "Airport Code [ICAO]": "SMKA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kabalebo Airport",
//       "Altitude (Ft)": 535,
//       "Country": "Suriname",
//       "Latitude": -57.223,
//       "Location": "Kabalebo",
//       "Longitude": 4.406,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "America/Paramaribo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7332,
//       "Airport Code [ICAO]": "SMKE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kayser Airport",
//       "Altitude (Ft)": 849,
//       "Country": "Suriname",
//       "Latitude": -56.472702,
//       "Location": "Kayser",
//       "Longitude": 3.094343,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "America/Paramaribo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7333,
//       "Airport Code [ICAO]": "SMSM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kwamalasoemoetoe Airport",
//       "Altitude (Ft)": 905,
//       "Country": "Suriname",
//       "Latitude": -56.792431,
//       "Location": "Kwamelasemoetoe",
//       "Longitude": 2.354427,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "America/Paramaribo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7334,
//       "Airport Code [ICAO]": "SMMO",
//       "Airport Code iATA,FAA": "MOJ",
//       "Airport Name": "Moengo Airstrip",
//       "Altitude (Ft)": 49,
//       "Country": "Suriname",
//       "Latitude": -54.4003,
//       "Location": "Moengo",
//       "Longitude": 5.6076,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "America/Paramaribo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7335,
//       "Airport Code [ICAO]": "SMNI",
//       "Airport Code iATA,FAA": "ICK",
//       "Airport Name": "Nieuw Nickerie Airport",
//       "Altitude (Ft)": 9,
//       "Country": "Suriname",
//       "Latitude": -57.039398193359375,
//       "Location": "Nieuw Nickerie",
//       "Longitude": 5.955560207366943,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "America/Paramaribo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7336,
//       "Airport Code [ICAO]": "SMPA",
//       "Airport Code iATA,FAA": "OEM",
//       "Airport Name": "Vincent Fayks Airport",
//       "Altitude (Ft)": 714,
//       "Country": "Suriname",
//       "Latitude": -55.442501068115234,
//       "Location": "Paloemeu",
//       "Longitude": 3.3452799320220947,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "America/Paramaribo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7337,
//       "Airport Code [ICAO]": "SMSK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sarakreek Airstrip",
//       "Altitude (Ft)": 223,
//       "Country": "Suriname",
//       "Latitude": -54.966667,
//       "Location": "Sarakreek",
//       "Longitude": 4.319444,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "America/Paramaribo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7338,
//       "Airport Code [ICAO]": "SMSI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sipaliwini Airport",
//       "Altitude (Ft)": 744,
//       "Country": "Suriname",
//       "Latitude": -56.126412,
//       "Location": "Sipaliwini",
//       "Longitude": 2.026438,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "America/Paramaribo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7339,
//       "Airport Code [ICAO]": "SMST",
//       "Airport Code iATA,FAA": "SMZ",
//       "Airport Name": "Stoelmanseiland Airport",
//       "Altitude (Ft)": 187,
//       "Country": "Suriname",
//       "Latitude": -54.41666793823242,
//       "Location": "Stoelmans Eiland",
//       "Longitude": 4.349999904632568,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "America/Paramaribo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7340,
//       "Airport Code [ICAO]": "SMCO",
//       "Airport Code iATA,FAA": "TOT",
//       "Airport Name": "Totness Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Suriname",
//       "Latitude": -56.32749938964844,
//       "Location": "Totness",
//       "Longitude": 5.865829944610596,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "America/Paramaribo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7341,
//       "Airport Code [ICAO]": "SMWA",
//       "Airport Code iATA,FAA": "AGI",
//       "Airport Name": "Wageningen Airstrip",
//       "Altitude (Ft)": 6,
//       "Country": "Suriname",
//       "Latitude": -56.673328,
//       "Location": "Wageningen",
//       "Longitude": 5.841128,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "America/Paramaribo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7343,
//       "Airport Code [ICAO]": "MRCA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Codela Airport",
//       "Altitude (Ft)": 328,
//       "Country": "Costa Rica",
//       "Latitude": -85.0916976929,
//       "Location": "Guapiles",
//       "Longitude": 10.4139995575,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 7346,
//       "Airport Code [ICAO]": "SYOR",
//       "Airport Code iATA,FAA": "ORJ",
//       "Airport Name": "Orinduik Airport",
//       "Altitude (Ft)": 1797,
//       "Country": "Guyana",
//       "Latitude": -60.03499984741211,
//       "Location": "Orinduik",
//       "Longitude": 4.725269794464111,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Guyana",
//       "Type": "airport"
//     },
//     {
//       "ID": 7347,
//       "Airport Code [ICAO]": "SYAN",
//       "Airport Code iATA,FAA": "NAI",
//       "Airport Name": "Annai Airport",
//       "Altitude (Ft)": 301,
//       "Country": "Guyana",
//       "Latitude": -59.12419891357422,
//       "Location": "Annai",
//       "Longitude": 3.959439992904663,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Guyana",
//       "Type": "airport"
//     },
//     {
//       "ID": 7348,
//       "Airport Code [ICAO]": "SYAP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Apoteri Airport",
//       "Altitude (Ft)": 301,
//       "Country": "Guyana",
//       "Latitude": -58.60499954223633,
//       "Location": "Apoteri",
//       "Longitude": 4.013879776000977,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Guyana",
//       "Type": "airport"
//     },
//     {
//       "ID": 7349,
//       "Airport Code [ICAO]": "SYIB",
//       "Airport Code iATA,FAA": "IMB",
//       "Airport Name": "Imbaimadai Airport",
//       "Altitude (Ft)": 1646,
//       "Country": "Guyana",
//       "Latitude": -60.2942008972168,
//       "Location": "Imbaimadai",
//       "Longitude": 5.7081098556518555,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Guyana",
//       "Type": "airport"
//     },
//     {
//       "ID": 7350,
//       "Airport Code [ICAO]": "SYKM",
//       "Airport Code iATA,FAA": "KAR",
//       "Airport Name": "Kamarang Airport",
//       "Altitude (Ft)": 1601,
//       "Country": "Guyana",
//       "Latitude": -60.614200592041016,
//       "Location": "Kamarang",
//       "Longitude": 5.865340232849121,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Guyana",
//       "Type": "airport"
//     },
//     {
//       "ID": 7351,
//       "Airport Code [ICAO]": "SYMB",
//       "Airport Code iATA,FAA": "USI",
//       "Airport Name": "Mabaruma Airport",
//       "Altitude (Ft)": 45,
//       "Country": "Guyana",
//       "Latitude": -59.78329849243164,
//       "Location": "Mabaruma",
//       "Longitude": 8.199999809265137,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Guyana",
//       "Type": "airport"
//     },
//     {
//       "ID": 7352,
//       "Airport Code [ICAO]": "SYMD",
//       "Airport Code iATA,FAA": "MHA",
//       "Airport Name": "Mahdia Airport",
//       "Altitude (Ft)": 300,
//       "Country": "Guyana",
//       "Latitude": -59.151100158691406,
//       "Location": "Mahdia",
//       "Longitude": 5.277490139007568,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Guyana",
//       "Type": "airport"
//     },
//     {
//       "ID": 7353,
//       "Airport Code [ICAO]": "SGPJ",
//       "Airport Code iATA,FAA": "PJC",
//       "Airport Name": "Dr Augusto Roberto Fuster International Airport",
//       "Altitude (Ft)": 1873,
//       "Country": "Paraguay",
//       "Latitude": -55.83000183105469,
//       "Location": "Pedro Juan Caballero",
//       "Longitude": -22.639999389648438,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Asuncion",
//       "Type": "airport"
//     },
//     {
//       "ID": 7354,
//       "Airport Code [ICAO]": "SKAD",
//       "Airport Code iATA,FAA": "ACD",
//       "Airport Name": "Alcides Fernández Airport",
//       "Altitude (Ft)": 50,
//       "Country": "Colombia",
//       "Latitude": -77.3,
//       "Location": "Acandi",
//       "Longitude": 8.51667,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 7355,
//       "Airport Code [ICAO]": "SKSA",
//       "Airport Code iATA,FAA": "RVE",
//       "Airport Name": "Los Colonizadores Airport",
//       "Altitude (Ft)": 680,
//       "Country": "Colombia",
//       "Latitude": -71.857179,
//       "Location": "Saravena",
//       "Longitude": 6.951868,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 7357,
//       "Airport Code [ICAO]": "UEBB",
//       "Airport Code iATA,FAA": "BQJ",
//       "Airport Name": "Batagay Airport",
//       "Altitude (Ft)": 696,
//       "Country": "Russia",
//       "Latitude": 134.69500732422,
//       "Location": "Batagay",
//       "Longitude": 67.648002624512,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": "Asia/Vladivostok",
//       "Type": "airport"
//     },
//     {
//       "ID": 7359,
//       "Airport Code [ICAO]": "SKVG",
//       "Airport Code iATA,FAA": "VGZ",
//       "Airport Name": "Villa Garzón Airport",
//       "Altitude (Ft)": 1248,
//       "Country": "Colombia",
//       "Latitude": -76.6056,
//       "Location": "Villa Garzon",
//       "Longitude": 0.978767,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 7360,
//       "Airport Code [ICAO]": "SKEB",
//       "Airport Code iATA,FAA": "EBG",
//       "Airport Name": "El Bagre Airport",
//       "Altitude (Ft)": 180,
//       "Country": "Colombia",
//       "Latitude": -74.8089,
//       "Location": "El Bagre",
//       "Longitude": 7.59647,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 7361,
//       "Airport Code [ICAO]": "SKCU",
//       "Airport Code iATA,FAA": "CAQ",
//       "Airport Name": "Juan H White Airport",
//       "Altitude (Ft)": 174,
//       "Country": "Colombia",
//       "Latitude": -75.1985,
//       "Location": "Caucasia",
//       "Longitude": 7.96847,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 7362,
//       "Airport Code [ICAO]": "SKCD",
//       "Airport Code iATA,FAA": "COG",
//       "Airport Name": "Mandinga Airport",
//       "Altitude (Ft)": 213,
//       "Country": "Colombia",
//       "Latitude": -76.7,
//       "Location": "Condoto",
//       "Longitude": 5.08333,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 7363,
//       "Airport Code [ICAO]": "SKTL",
//       "Airport Code iATA,FAA": "TLU",
//       "Airport Name": "Golfo de Morrosquillo Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Colombia",
//       "Latitude": -75.5854,
//       "Location": "Tolu",
//       "Longitude": 9.50945,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 7364,
//       "Airport Code [ICAO]": "SBCB",
//       "Airport Code iATA,FAA": "CFB",
//       "Airport Name": "Cabo Frio Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Brazil",
//       "Latitude": -42.074298858599995,
//       "Location": "Cabo Frio",
//       "Longitude": -22.921699523900003,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7367,
//       "Airport Code [ICAO]": "SWSI",
//       "Airport Code iATA,FAA": "OPS",
//       "Airport Name": "Presidente João Batista Figueiredo Airport",
//       "Altitude (Ft)": 1227,
//       "Country": "Brazil",
//       "Latitude": -55.58610916137695,
//       "Location": "Sinop",
//       "Longitude": -11.885000228881836,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Campo_Grande",
//       "Type": "airport"
//     },
//     {
//       "ID": 7368,
//       "Airport Code [ICAO]": "SWGI",
//       "Airport Code iATA,FAA": "GRP",
//       "Airport Name": "Gurupi Airport",
//       "Altitude (Ft)": 1148,
//       "Country": "Brazil",
//       "Latitude": -49.132198333740234,
//       "Location": "Gurupi",
//       "Longitude": -11.73960018157959,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 7369,
//       "Airport Code [ICAO]": "SNKE",
//       "Airport Code iATA,FAA": "CMP",
//       "Airport Name": "Santana do Araguaia Airport",
//       "Altitude (Ft)": 597,
//       "Country": "Brazil",
//       "Latitude": -50.32849884033203,
//       "Location": "Santana do Araguaia",
//       "Longitude": -9.31997013092041,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Belem",
//       "Type": "airport"
//     },
//     {
//       "ID": 7370,
//       "Airport Code [ICAO]": "SNVS",
//       "Airport Code iATA,FAA": "BVS",
//       "Airport Name": "Breves Airport",
//       "Altitude (Ft)": 98,
//       "Country": "Brazil",
//       "Latitude": -50.443599700927734,
//       "Location": "Breves",
//       "Longitude": -1.6365300416946411,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Belem",
//       "Type": "airport"
//     },
//     {
//       "ID": 7371,
//       "Airport Code [ICAO]": "SNSW",
//       "Airport Code iATA,FAA": "SFK",
//       "Airport Name": "Soure Airport",
//       "Altitude (Ft)": 43,
//       "Country": "Brazil",
//       "Latitude": -48.520999908447266,
//       "Location": "Soure",
//       "Longitude": -0.6994310021400452,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Belem",
//       "Type": "airport"
//     },
//     {
//       "ID": 7372,
//       "Airport Code [ICAO]": "SWPI",
//       "Airport Code iATA,FAA": "PIN",
//       "Airport Name": "Parintins Airport",
//       "Altitude (Ft)": 87,
//       "Country": "Brazil",
//       "Latitude": -56.777198791503906,
//       "Location": "Parintins",
//       "Longitude": -2.6730198860168457,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 7373,
//       "Airport Code [ICAO]": "SNBR",
//       "Airport Code iATA,FAA": "BRA",
//       "Airport Name": "Barreiras Airport",
//       "Altitude (Ft)": 2447,
//       "Country": "Brazil",
//       "Latitude": -45.00899887084961,
//       "Location": "Barreiras",
//       "Longitude": -12.078900337219238,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 7374,
//       "Airport Code [ICAO]": "SWST",
//       "Airport Code iATA,FAA": "STZ",
//       "Airport Name": "Santa Terezinha Airport",
//       "Altitude (Ft)": 663,
//       "Country": "Brazil",
//       "Latitude": -50.518611907958984,
//       "Location": "Santa Terezinha",
//       "Longitude": -10.4647216796875,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Campo_Grande",
//       "Type": "airport"
//     },
//     {
//       "ID": 7375,
//       "Airport Code [ICAO]": "SBMC",
//       "Airport Code iATA,FAA": "MQH",
//       "Airport Name": "Minaçu Airport",
//       "Altitude (Ft)": 1401,
//       "Country": "Brazil",
//       "Latitude": -48.195301,
//       "Location": "Minacu",
//       "Longitude": -13.5491,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7376,
//       "Airport Code [ICAO]": "SWGN",
//       "Airport Code iATA,FAA": "AUX",
//       "Airport Name": "Araguaína Airport",
//       "Altitude (Ft)": 771,
//       "Country": "Brazil",
//       "Latitude": -48.240501,
//       "Location": "Araguaina",
//       "Longitude": -7.22787,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 7377,
//       "Airport Code [ICAO]": "SWNA",
//       "Airport Code iATA,FAA": "NVP",
//       "Airport Name": "Novo Aripuanã Airport",
//       "Altitude (Ft)": 118,
//       "Country": "Brazil",
//       "Latitude": -60.364898681640625,
//       "Location": "Novo Aripuana",
//       "Longitude": -5.118030071258545,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 7378,
//       "Airport Code [ICAO]": "SWFE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Fazenda Colen Airport",
//       "Altitude (Ft)": 1345,
//       "Country": "Brazil",
//       "Latitude": -56.11277770996094,
//       "Location": "Lucas do Rio Verde",
//       "Longitude": -13.314443588256836,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Campo_Grande",
//       "Type": "airport"
//     },
//     {
//       "ID": 7379,
//       "Airport Code [ICAO]": "SIMK",
//       "Airport Code iATA,FAA": "FRC",
//       "Airport Name": "Tenente Lund Pressoto Airport",
//       "Altitude (Ft)": 3292,
//       "Country": "Brazil",
//       "Latitude": -47.3829,
//       "Location": "Franca",
//       "Longitude": -20.592199,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7380,
//       "Airport Code [ICAO]": "SSDO",
//       "Airport Code iATA,FAA": "DOU",
//       "Airport Name": "Dourados Airport",
//       "Altitude (Ft)": 1503,
//       "Country": "Brazil",
//       "Latitude": -54.926601,
//       "Location": "Dourados",
//       "Longitude": -22.2019,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Campo_Grande",
//       "Type": "airport"
//     },
//     {
//       "ID": 7381,
//       "Airport Code [ICAO]": "SWLB",
//       "Airport Code iATA,FAA": "LBR",
//       "Airport Name": "Lábrea Airport",
//       "Altitude (Ft)": 190,
//       "Country": "Brazil",
//       "Latitude": -64.76950073242188,
//       "Location": "Labrea",
//       "Longitude": -7.278969764709473,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 7382,
//       "Airport Code [ICAO]": "SWRD",
//       "Airport Code iATA,FAA": "ROO",
//       "Airport Name": "Maestro Marinho Franco Airport",
//       "Altitude (Ft)": 1467,
//       "Country": "Brazil",
//       "Latitude": -54.7248,
//       "Location": "Rondonopolis",
//       "Longitude": -16.586,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Campo_Grande",
//       "Type": "airport"
//     },
//     {
//       "ID": 7383,
//       "Airport Code [ICAO]": "SBGU",
//       "Airport Code iATA,FAA": "GPB",
//       "Airport Name": "Tancredo Thomas de Faria Airport",
//       "Altitude (Ft)": 3494,
//       "Country": "Brazil",
//       "Latitude": -51.520198822,
//       "Location": "Guarapuava",
//       "Longitude": -25.3875007629,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7384,
//       "Airport Code [ICAO]": "SSJA",
//       "Airport Code iATA,FAA": "JCB",
//       "Airport Name": "Santa Terezinha Airport",
//       "Altitude (Ft)": 2546,
//       "Country": "Brazil",
//       "Latitude": -51.5532989502,
//       "Location": "Joacaba",
//       "Longitude": -27.1714000702,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7394,
//       "Airport Code [ICAO]": "SWLC",
//       "Airport Code iATA,FAA": "RVD",
//       "Airport Name": "General Leite de Castro Airport",
//       "Altitude (Ft)": 2464,
//       "Country": "Brazil",
//       "Latitude": -50.956111907958984,
//       "Location": "Rio Verde",
//       "Longitude": -17.8347225189209,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7395,
//       "Airport Code [ICAO]": "SBAX",
//       "Airport Code iATA,FAA": "AAX",
//       "Airport Name": "Romeu Zema Airport",
//       "Altitude (Ft)": 3276,
//       "Country": "Brazil",
//       "Latitude": -46.960399627686,
//       "Location": "Araxa",
//       "Longitude": -19.563199996948,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7396,
//       "Airport Code [ICAO]": "SWMW",
//       "Airport Code iATA,FAA": "MBZ",
//       "Airport Name": "Maués Airport",
//       "Altitude (Ft)": 69,
//       "Country": "Brazil",
//       "Latitude": -57.7248,
//       "Location": "Maues",
//       "Longitude": -3.37217,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 7397,
//       "Airport Code [ICAO]": "SWBR",
//       "Airport Code iATA,FAA": "RBB",
//       "Airport Name": "Borba Airport",
//       "Altitude (Ft)": 293,
//       "Country": "Brazil",
//       "Latitude": -59.60240173339844,
//       "Location": "Borba",
//       "Longitude": -4.4063401222229,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 7398,
//       "Airport Code [ICAO]": "SWKO",
//       "Airport Code iATA,FAA": "CIZ",
//       "Airport Name": "Coari Airport",
//       "Altitude (Ft)": 131,
//       "Country": "Brazil",
//       "Latitude": -63.132598876953125,
//       "Location": "Coari",
//       "Longitude": -4.134059906005859,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 7399,
//       "Airport Code [ICAO]": "SWBC",
//       "Airport Code iATA,FAA": "BAZ",
//       "Airport Name": "Barcelos Airport",
//       "Altitude (Ft)": 112,
//       "Country": "Brazil",
//       "Latitude": -62.919601,
//       "Location": "Barcelos",
//       "Longitude": -0.981292,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 7406,
//       "Airport Code [ICAO]": "SWDM",
//       "Airport Code iATA,FAA": "DMT",
//       "Airport Name": "Diamantino Airport",
//       "Altitude (Ft)": 1476,
//       "Country": "Brazil",
//       "Latitude": -56.40039825439453,
//       "Location": "Diamantino",
//       "Longitude": -14.376899719238281,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Campo_Grande",
//       "Type": "airport"
//     },
//     {
//       "ID": 7407,
//       "Airport Code [ICAO]": "SNGI",
//       "Airport Code iATA,FAA": "GNM",
//       "Airport Name": "Guanambi Airport",
//       "Altitude (Ft)": 1815,
//       "Country": "Brazil",
//       "Latitude": -42.74610137939453,
//       "Location": "Guanambi",
//       "Longitude": -14.208200454711914,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 7408,
//       "Airport Code [ICAO]": "DAFI",
//       "Airport Code iATA,FAA": "QDJ",
//       "Airport Name": "Tsletsi Airport",
//       "Altitude (Ft)": 3753,
//       "Country": "Algeria",
//       "Latitude": 3.351,
//       "Location": "Djelfa",
//       "Longitude": 34.6657,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": "Africa/Algiers",
//       "Type": "airport"
//     },
//     {
//       "ID": 7409,
//       "Airport Code [ICAO]": "FNZG",
//       "Airport Code iATA,FAA": "NZA",
//       "Airport Name": "Nzagi Airport",
//       "Altitude (Ft)": 2431,
//       "Country": "Angola",
//       "Latitude": 21.358200073200003,
//       "Location": "Nzagi",
//       "Longitude": -7.716939926149999,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": "Africa/Luanda",
//       "Type": "airport"
//     },
//     {
//       "ID": 7411,
//       "Airport Code [ICAO]": "FNLK",
//       "Airport Code iATA,FAA": "LBZ",
//       "Airport Name": "Lucapa Airport",
//       "Altitude (Ft)": 3029,
//       "Country": "Angola",
//       "Latitude": 20.7320861816,
//       "Location": "Lucapa",
//       "Longitude": -8.445727348330001,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": "Africa/Luanda",
//       "Type": "airport"
//     },
//     {
//       "ID": 7412,
//       "Airport Code [ICAO]": "FNCP",
//       "Airport Code iATA,FAA": "KNP",
//       "Airport Name": "Capanda Airport",
//       "Altitude (Ft)": 3366,
//       "Country": "Angola",
//       "Latitude": 15.4553194046,
//       "Location": "Kapanda",
//       "Longitude": -9.76937007904,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": "Africa/Luanda",
//       "Type": "airport"
//     },
//     {
//       "ID": 7413,
//       "Airport Code [ICAO]": "FTTN",
//       "Airport Code iATA,FAA": "AMC",
//       "Airport Name": "Am Timan Airport",
//       "Altitude (Ft)": 1420,
//       "Country": "Chad",
//       "Latitude": 20.274000167799997,
//       "Location": "Am Timan",
//       "Longitude": 11.0340003967,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": "Africa/Ndjamena",
//       "Type": "airport"
//     },
//     {
//       "ID": 7414,
//       "Airport Code [ICAO]": "HEOW",
//       "Airport Code iATA,FAA": "GSQ",
//       "Airport Name": "Shark El Oweinat International Airport",
//       "Altitude (Ft)": 859,
//       "Country": "Egypt",
//       "Latitude": 28.71660041809082,
//       "Location": "Sharq Al-Owainat",
//       "Longitude": 22.5856990814209,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Cairo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7415,
//       "Airport Code [ICAO]": "KMRB",
//       "Airport Code iATA,FAA": "MRB",
//       "Airport Name": "Eastern WV Regional Airport/Shepherd Field",
//       "Altitude (Ft)": 565,
//       "Country": "United States",
//       "Latitude": -77.98459625,
//       "Location": "Martinsburg",
//       "Longitude": 39.40190125,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7416,
//       "Airport Code [ICAO]": "HALA",
//       "Airport Code iATA,FAA": "AWA",
//       "Airport Name": "Awassa Airport",
//       "Altitude (Ft)": 5450,
//       "Country": "Ethiopia",
//       "Latitude": 38.5,
//       "Location": "Awasa",
//       "Longitude": 7.066999912261963,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 7417,
//       "Airport Code [ICAO]": "HAJJ",
//       "Airport Code iATA,FAA": "JIJ",
//       "Airport Name": "Wilwal International Airport",
//       "Altitude (Ft)": 5954,
//       "Country": "Ethiopia",
//       "Latitude": 42.9121,
//       "Location": "Jijiga",
//       "Longitude": 9.3325,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 7418,
//       "Airport Code [ICAO]": "HAMA",
//       "Airport Code iATA,FAA": "MKS",
//       "Airport Name": "Mekane Selam Airport",
//       "Altitude (Ft)": 8405,
//       "Country": "Ethiopia",
//       "Latitude": 38.7415,
//       "Location": "Mekane Selam",
//       "Longitude": 10.7254,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 7419,
//       "Airport Code [ICAO]": "HADM",
//       "Airport Code iATA,FAA": "DBM",
//       "Airport Name": "Debra Marcos Airport",
//       "Altitude (Ft)": 8136,
//       "Country": "Ethiopia",
//       "Latitude": 37.71699905395508,
//       "Location": "Debre Marqos",
//       "Longitude": 10.350000381469727,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 7420,
//       "Airport Code [ICAO]": "HADT",
//       "Airport Code iATA,FAA": "DBT",
//       "Airport Name": "Debre Tabor Airport",
//       "Altitude (Ft)": 8490,
//       "Country": "Ethiopia",
//       "Latitude": 38,
//       "Location": "Debre Tabor",
//       "Longitude": 11.967000007629395,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 7421,
//       "Airport Code [ICAO]": "HAHM",
//       "Airport Code iATA,FAA": "QHR",
//       "Airport Name": "Harar Meda Airport",
//       "Altitude (Ft)": 6201,
//       "Country": "Ethiopia",
//       "Latitude": 39.0059,
//       "Location": "Debre Zeyit",
//       "Longitude": 8.7163,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 7422,
//       "Airport Code [ICAO]": "HAGB",
//       "Airport Code iATA,FAA": "GOB",
//       "Airport Name": "Robe Airport",
//       "Altitude (Ft)": 7892,
//       "Country": "Ethiopia",
//       "Latitude": 40.0463033,
//       "Location": "Goba",
//       "Longitude": 7.1160634,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 7423,
//       "Airport Code [ICAO]": "FOOY",
//       "Airport Code iATA,FAA": "MYB",
//       "Airport Name": "Mayumba Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Gabon",
//       "Latitude": 10.674076080322266,
//       "Location": "Mayumba",
//       "Longitude": -3.4584197998046875,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": "Africa/Libreville",
//       "Type": "airport"
//     },
//     {
//       "ID": 7424,
//       "Airport Code [ICAO]": "HKMS",
//       "Airport Code iATA,FAA": "MRE",
//       "Airport Name": "Mara Serena Lodge Airstrip",
//       "Altitude (Ft)": 5200,
//       "Country": "Kenya",
//       "Latitude": 35.008057,
//       "Location": "Masai Mara",
//       "Longitude": -1.406111,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 7426,
//       "Airport Code [ICAO]": "HKMK",
//       "Airport Code iATA,FAA": "JJM",
//       "Airport Name": "Mulika Lodge Airport",
//       "Altitude (Ft)": 2000,
//       "Country": "Kenya",
//       "Latitude": 38.1951408386,
//       "Location": "Meru National Park",
//       "Longitude": 0.165083006024,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 7427,
//       "Airport Code [ICAO]": "HSMK",
//       "Airport Code iATA,FAA": "RBX",
//       "Airport Name": "Rumbek Airport",
//       "Altitude (Ft)": 1378,
//       "Country": "Sudan",
//       "Latitude": 29.6690006256,
//       "Location": "Rumbek",
//       "Longitude": 6.8249998092699995,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Juba",
//       "Type": "airport"
//     },
//     {
//       "ID": 7428,
//       "Airport Code [ICAO]": "HSYE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Yei Airport",
//       "Altitude (Ft)": 2000,
//       "Country": "Sudan",
//       "Latitude": 30.7280731201,
//       "Location": "Yei",
//       "Longitude": 4.13028160099,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Juba",
//       "Type": "airport"
//     },
//     {
//       "ID": 7429,
//       "Airport Code [ICAO]": "GLCP",
//       "Airport Code iATA,FAA": "CPA",
//       "Airport Name": "Cape Palmas Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Liberia",
//       "Latitude": -7.6969499588012695,
//       "Location": "Greenville",
//       "Longitude": 4.3790202140808105,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": "Africa/Monrovia",
//       "Type": "airport"
//     },
//     {
//       "ID": 7432,
//       "Airport Code [ICAO]": "LSGE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ecuvillens Airport",
//       "Altitude (Ft)": 2293,
//       "Country": "Switzerland",
//       "Latitude": 7.076109886169434,
//       "Location": "Ecuvillens",
//       "Longitude": 46.755001068115234,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 7437,
//       "Airport Code [ICAO]": "FQIA",
//       "Airport Code iATA,FAA": "IHC",
//       "Airport Name": "Inhaca Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Mozambique",
//       "Latitude": 32.929351806599996,
//       "Location": "Inhaca",
//       "Longitude": -25.9971446991,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Maputo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7442,
//       "Airport Code [ICAO]": "GOSM",
//       "Airport Code iATA,FAA": "MAX",
//       "Airport Name": "Ouro Sogui Airport",
//       "Altitude (Ft)": 85,
//       "Country": "Senegal",
//       "Latitude": -13.322799682617188,
//       "Location": "Matam",
//       "Longitude": 15.593600273132324,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": "Africa/Dakar",
//       "Type": "airport"
//     },
//     {
//       "ID": 7443,
//       "Airport Code [ICAO]": "FSSB",
//       "Airport Code iATA,FAA": "BDI",
//       "Airport Name": "Bird Island Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Seychelles",
//       "Latitude": 55.205299,
//       "Location": "Bird Island",
//       "Longitude": -3.72472,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 7446,
//       "Airport Code [ICAO]": "HSSW",
//       "Airport Code iATA,FAA": "WHF",
//       "Airport Name": "Wadi Halfa Airport",
//       "Altitude (Ft)": 961,
//       "Country": "Sudan",
//       "Latitude": 31.521577835083008,
//       "Location": "Wadi Halfa",
//       "Longitude": 21.802698135375977,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Khartoum",
//       "Type": "airport"
//     },
//     {
//       "ID": 7447,
//       "Airport Code [ICAO]": "DTNH",
//       "Airport Code iATA,FAA": "NBE",
//       "Airport Name": "Enfidha - Hammamet International Airport",
//       "Altitude (Ft)": 21,
//       "Country": "Tunisia",
//       "Latitude": 10.438611,
//       "Location": "Enfidha",
//       "Longitude": 36.075833,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": "Africa/Tunis",
//       "Type": "airport"
//     },
//     {
//       "ID": 7448,
//       "Airport Code [ICAO]": "HUKD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kidepo Airport",
//       "Altitude (Ft)": 4033,
//       "Country": "Uganda",
//       "Latitude": 33.7487,
//       "Location": "Kidepo",
//       "Longitude": 3.7176,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Kampala",
//       "Type": "airport"
//     },
//     {
//       "ID": 7449,
//       "Airport Code [ICAO]": "HUKT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kitgum Airport",
//       "Altitude (Ft)": 3130,
//       "Country": "Uganda",
//       "Latitude": 32.8882,
//       "Location": "Kitgum",
//       "Longitude": 3.2817,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Kampala",
//       "Type": "airport"
//     },
//     {
//       "ID": 7450,
//       "Airport Code [ICAO]": "HUPA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bugungu Airport",
//       "Altitude (Ft)": 2472,
//       "Country": "Uganda",
//       "Latitude": 31.55,
//       "Location": "Pakuba",
//       "Longitude": 2.2,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Kampala",
//       "Type": "airport"
//     },
//     {
//       "ID": 7451,
//       "Airport Code [ICAO]": "ENSA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Svea Airport",
//       "Altitude (Ft)": 32,
//       "Country": "Svalbard",
//       "Latitude": 16.725,
//       "Location": "Sveagruva",
//       "Longitude": 77.8969444,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": "Arctic/Longyearbyen",
//       "Type": "airport"
//     },
//     {
//       "ID": 7452,
//       "Airport Code [ICAO]": "ENAS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ny-Ålesund Airport (Hamnerabben)",
//       "Altitude (Ft)": 50,
//       "Country": "Svalbard",
//       "Latitude": 11.8743,
//       "Location": "Ny-Alesund",
//       "Longitude": 78.9274978638,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": "Arctic/Longyearbyen",
//       "Type": "airport"
//     },
//     {
//       "ID": 7453,
//       "Airport Code [ICAO]": "LTDA",
//       "Airport Code iATA,FAA": "HTY",
//       "Airport Name": "Hatay Airport",
//       "Altitude (Ft)": 269,
//       "Country": "Turkey",
//       "Latitude": 36.282222747800006,
//       "Location": "Hatay",
//       "Longitude": 36.36277771,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 7454,
//       "Airport Code [ICAO]": "EEKU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kihnu Airfield",
//       "Altitude (Ft)": 10,
//       "Country": "Estonia",
//       "Latitude": 24.002500534057617,
//       "Location": "Kihnu",
//       "Longitude": 58.14830017089844,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Europe/Tallinn",
//       "Type": "airport"
//     },
//     {
//       "ID": 7455,
//       "Airport Code [ICAO]": "EERU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ruhnu Airfield",
//       "Altitude (Ft)": 10,
//       "Country": "Estonia",
//       "Latitude": 23.26609992980957,
//       "Location": "Ruhnu",
//       "Longitude": 57.78390121459961,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Europe/Tallinn",
//       "Type": "airport"
//     },
//     {
//       "ID": 7456,
//       "Airport Code [ICAO]": "NTAV",
//       "Airport Code iATA,FAA": "RVV",
//       "Airport Name": "Raivavae Airport",
//       "Altitude (Ft)": 7,
//       "Country": "French Polynesia",
//       "Latitude": -147.662002563,
//       "Location": "Raivavae",
//       "Longitude": -23.885200500499998,
//       "GMT Offset": "-10",
//       "Daylight Savings": "N",
//       "Region": "Pacific/Tahiti",
//       "Type": "airport"
//     },
//     {
//       "ID": 7457,
//       "Airport Code [ICAO]": "ZGFS",
//       "Airport Code iATA,FAA": "FUO",
//       "Airport Name": "Foshan Shadi Airport",
//       "Altitude (Ft)": 6,
//       "Country": "China",
//       "Latitude": 113.069999695,
//       "Location": "Foshan",
//       "Longitude": 23.083299636799996,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 7458,
//       "Airport Code [ICAO]": "ZGHZ",
//       "Airport Code iATA,FAA": "HUZ",
//       "Airport Name": "Huizhou Airport",
//       "Altitude (Ft)": 50,
//       "Country": "China",
//       "Latitude": 114.599998474,
//       "Location": "Huizhou",
//       "Longitude": 23.0499992371,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 7459,
//       "Airport Code [ICAO]": "LEDA",
//       "Airport Code iATA,FAA": "ILD",
//       "Airport Name": "Lleida-Alguaire Airport",
//       "Altitude (Ft)": 1170,
//       "Country": "Spain",
//       "Latitude": 0.535023,
//       "Location": "Lleida",
//       "Longitude": 41.728185,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 7461,
//       "Airport Code [ICAO]": "LFEC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ouessant Airport",
//       "Altitude (Ft)": 142,
//       "Country": "France",
//       "Latitude": -5.06358003616333,
//       "Location": "Ouessant",
//       "Longitude": 48.463199615478516,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 7464,
//       "Airport Code [ICAO]": "BIBD",
//       "Airport Code iATA,FAA": "BIU",
//       "Airport Name": "Bildudalur Airport",
//       "Altitude (Ft)": 18,
//       "Country": "Iceland",
//       "Latitude": -23.546199798583984,
//       "Location": "Bildudalur",
//       "Longitude": 65.64129638671875,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Atlantic/Reykjavik",
//       "Type": "airport"
//     },
//     {
//       "ID": 7465,
//       "Airport Code [ICAO]": "BIGJ",
//       "Airport Code iATA,FAA": "GJR",
//       "Airport Name": "Gjögur Airport",
//       "Altitude (Ft)": 83,
//       "Country": "Iceland",
//       "Latitude": -21.326900482177734,
//       "Location": "Gjogur",
//       "Longitude": 65.99530029296875,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Atlantic/Reykjavik",
//       "Type": "airport"
//     },
//     {
//       "ID": 7466,
//       "Airport Code [ICAO]": "BIKR",
//       "Airport Code iATA,FAA": "SAK",
//       "Airport Name": "Sauðárkrókur Airport",
//       "Altitude (Ft)": 8,
//       "Country": "Iceland",
//       "Latitude": -19.572799682599996,
//       "Location": "Saudarkrokur",
//       "Longitude": 65.73169708249999,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Atlantic/Reykjavik",
//       "Type": "airport"
//     },
//     {
//       "ID": 7467,
//       "Airport Code [ICAO]": "BISF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Selfoss Airport",
//       "Altitude (Ft)": 45,
//       "Country": "Iceland",
//       "Latitude": -21.037799835205078,
//       "Location": "Selfoss",
//       "Longitude": 63.92919921875,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Atlantic/Reykjavik",
//       "Type": "airport"
//     },
//     {
//       "ID": 7468,
//       "Airport Code [ICAO]": "EIMN",
//       "Airport Code iATA,FAA": "IIA",
//       "Airport Name": "Inishmaan Aerodrome",
//       "Altitude (Ft)": 15,
//       "Country": "Ireland",
//       "Latitude": -9.568059921264648,
//       "Location": "Inishmaan",
//       "Longitude": 53.09299850463867,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": "Europe/Dublin",
//       "Type": "airport"
//     },
//     {
//       "ID": 7469,
//       "Airport Code [ICAO]": "UAAT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ak Bashat Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Kazakhstan",
//       "Latitude": 73.602004,
//       "Location": "Taldykorgan",
//       "Longitude": 42.88958,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Bishkek",
//       "Type": "airport"
//     },
//     {
//       "ID": 7470,
//       "Airport Code [ICAO]": "ZMUL",
//       "Airport Code iATA,FAA": "ULG",
//       "Airport Name": "Ulgii Mongolei Airport",
//       "Altitude (Ft)": 5732,
//       "Country": "Mongolia",
//       "Latitude": 89.9225006104,
//       "Location": "Olgii",
//       "Longitude": 48.9933013916,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Hovd",
//       "Type": "airport"
//     },
//     {
//       "ID": 7472,
//       "Airport Code [ICAO]": "UTDT",
//       "Airport Code iATA,FAA": "KQT",
//       "Airport Name": "Qurghonteppa International Airport",
//       "Altitude (Ft)": 1473,
//       "Country": "Tajikistan",
//       "Latitude": 68.86470031738281,
//       "Location": "Kurgan Tyube",
//       "Longitude": 37.86640167236328,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dushanbe",
//       "Type": "airport"
//     },
//     {
//       "ID": 7473,
//       "Airport Code [ICAO]": "ULWW",
//       "Airport Code iATA,FAA": "VGD",
//       "Airport Name": "Vologda Airport",
//       "Altitude (Ft)": 387,
//       "Country": "Russia",
//       "Latitude": 39.944400787353516,
//       "Location": "Vologda",
//       "Longitude": 59.282501220703125,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 7474,
//       "Airport Code [ICAO]": "UHMW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Severo-Evensk Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Russia",
//       "Latitude": 159.22999572753906,
//       "Location": "Evensk",
//       "Longitude": 61.92166519165039,
//       "GMT Offset": "11",
//       "Daylight Savings": "N",
//       "Region": "Asia/Srednekolymsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 7475,
//       "Airport Code [ICAO]": "UERO",
//       "Airport Code iATA,FAA": "ONK",
//       "Airport Name": "Olenyok Airport",
//       "Altitude (Ft)": 847,
//       "Country": "Russia",
//       "Latitude": 112.480003,
//       "Location": "Olenyok",
//       "Longitude": 68.514999,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yakutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 7476,
//       "Airport Code [ICAO]": "UERS",
//       "Airport Code iATA,FAA": "SYS",
//       "Airport Name": "Saskylakh Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Russia",
//       "Latitude": 114.08000183105,
//       "Location": "Saskylakh",
//       "Longitude": 71.92790222168,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yakutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 7480,
//       "Airport Code [ICAO]": "ULAL",
//       "Airport Code iATA,FAA": "LDG",
//       "Airport Name": "Leshukonskoye Airport",
//       "Altitude (Ft)": 220,
//       "Country": "Russia",
//       "Latitude": 45.7229995728,
//       "Location": "Arkhangelsk",
//       "Longitude": 64.8960037231,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 7481,
//       "Airport Code [ICAO]": "UIUN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nizhneangarsk Airport",
//       "Altitude (Ft)": 1545,
//       "Country": "Russia",
//       "Latitude": 109.59500122070312,
//       "Location": "Nizhneangarsk",
//       "Longitude": 55.80080032348633,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Irkutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 7483,
//       "Airport Code [ICAO]": "UNIW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Vanavara Airport",
//       "Altitude (Ft)": 892,
//       "Country": "Russia",
//       "Latitude": 102.3239974975586,
//       "Location": "Vanavara",
//       "Longitude": 60.35969924926758,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Krasnoyarsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 7484,
//       "Airport Code [ICAO]": "UERA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Aykhal Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Russia",
//       "Latitude": 111.54650115967,
//       "Location": "Aykhal",
//       "Longitude": 65.959197998047,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yakutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 7485,
//       "Airport Code [ICAO]": "USSK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Uktus Airport",
//       "Altitude (Ft)": 643,
//       "Country": "Russia",
//       "Latitude": 60.790000915527344,
//       "Location": "Yekaterinburg",
//       "Longitude": 56.701698303222656,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 7486,
//       "Airport Code [ICAO]": "UNIB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Baykit Airport",
//       "Altitude (Ft)": 853,
//       "Country": "Russia",
//       "Latitude": 96.3550033569336,
//       "Location": "Baykit",
//       "Longitude": 61.676700592041016,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Krasnoyarsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 7487,
//       "Airport Code [ICAO]": "UNBI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Biysk Airport",
//       "Altitude (Ft)": 620,
//       "Country": "Russia",
//       "Latitude": 85.33999633789062,
//       "Location": "Biysk",
//       "Longitude": 52.47999954223633,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Krasnoyarsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 7488,
//       "Airport Code [ICAO]": "LEHC",
//       "Airport Code iATA,FAA": "HSK",
//       "Airport Name": "Huesca/Pirineos Airport",
//       "Altitude (Ft)": 1768,
//       "Country": "Spain",
//       "Latitude": -0.316666990519,
//       "Location": "Huesca",
//       "Longitude": 42.0760993958,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 7489,
//       "Airport Code [ICAO]": "LERL",
//       "Airport Code iATA,FAA": "CQM",
//       "Airport Name": "Ciudad Real Central Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Spain",
//       "Latitude": -3.97,
//       "Location": "Ciudad Real",
//       "Longitude": 38.8563888889,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 7490,
//       "Airport Code [ICAO]": "ORNI",
//       "Airport Code iATA,FAA": "NJF",
//       "Airport Name": "Al Najaf International Airport",
//       "Altitude (Ft)": 103,
//       "Country": "Iraq",
//       "Latitude": 44.404317,
//       "Location": "Najaf",
//       "Longitude": 31.989853,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Baghdad",
//       "Type": "airport"
//     },
//     {
//       "ID": 7492,
//       "Airport Code [ICAO]": "EGEY",
//       "Airport Code iATA,FAA": "CSA",
//       "Airport Name": "Colonsay Airstrip",
//       "Altitude (Ft)": 44,
//       "Country": "United Kingdom",
//       "Latitude": -6.243060112,
//       "Location": "Colonsay",
//       "Longitude": 56.0574989319,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 7494,
//       "Airport Code [ICAO]": "KUZA",
//       "Airport Code iATA,FAA": "RKH",
//       "Airport Name": "Rock Hill - York County Airport",
//       "Altitude (Ft)": 666,
//       "Country": "United States",
//       "Latitude": -81.05719757,
//       "Location": "Rock Hill",
//       "Longitude": 34.9878006,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7495,
//       "Airport Code [ICAO]": "KAGC",
//       "Airport Code iATA,FAA": "AGC",
//       "Airport Name": "Allegheny County Airport",
//       "Altitude (Ft)": 1252,
//       "Country": "United States",
//       "Latitude": -79.9301986694336,
//       "Location": "Pittsburgh",
//       "Longitude": 40.354400634765625,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7496,
//       "Airport Code [ICAO]": "KVQQ",
//       "Airport Code iATA,FAA": "VQQ",
//       "Airport Name": "Cecil Airport",
//       "Altitude (Ft)": 81,
//       "Country": "United States",
//       "Latitude": -81.876701355,
//       "Location": "Jacksonville",
//       "Longitude": 30.2187004089,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7497,
//       "Airport Code [ICAO]": "KFTY",
//       "Airport Code iATA,FAA": "FTY",
//       "Airport Name": "Fulton County Airport Brown Field",
//       "Altitude (Ft)": 841,
//       "Country": "United States",
//       "Latitude": -84.5214004517,
//       "Location": "Atlanta",
//       "Longitude": 33.7790985107,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7498,
//       "Airport Code [ICAO]": "EGHT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tresco Heliport",
//       "Altitude (Ft)": 20,
//       "Country": "United Kingdom",
//       "Latitude": -6.331389904022217,
//       "Location": "Tresco",
//       "Longitude": 49.94559860229492,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 7499,
//       "Airport Code [ICAO]": "OATN",
//       "Airport Code iATA,FAA": "TII",
//       "Airport Name": "Tarin Kowt Airport",
//       "Altitude (Ft)": 4429,
//       "Country": "Afghanistan",
//       "Latitude": 65.8657989502,
//       "Location": "Tarin Kowt",
//       "Longitude": 32.604198455799995,
//       "GMT Offset": "4.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Kabul",
//       "Type": "airport"
//     },
//     {
//       "ID": 7500,
//       "Airport Code [ICAO]": "OAZJ",
//       "Airport Code iATA,FAA": "ZAJ",
//       "Airport Name": "Zaranj Airport",
//       "Altitude (Ft)": 1572,
//       "Country": "Afghanistan",
//       "Latitude": 61.865833,
//       "Location": "Zaranj",
//       "Longitude": 30.972222,
//       "GMT Offset": "4.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Kabul",
//       "Type": "airport"
//     },
//     {
//       "ID": 7501,
//       "Airport Code [ICAO]": "OACC",
//       "Airport Code iATA,FAA": "CCN",
//       "Airport Name": "Chakcharan Airport",
//       "Altitude (Ft)": 7383,
//       "Country": "Afghanistan",
//       "Latitude": 65.26699829101562,
//       "Location": "Chaghcharan",
//       "Longitude": 34.53300094604492,
//       "GMT Offset": "4.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Kabul",
//       "Type": "airport"
//     },
//     {
//       "ID": 7503,
//       "Airport Code [ICAO]": "ZSFY",
//       "Airport Code iATA,FAA": "FUG",
//       "Airport Name": "Fuyang Xiguan Airport",
//       "Altitude (Ft)": 104,
//       "Country": "China",
//       "Latitude": 115.734364,
//       "Location": "Fuyang",
//       "Longitude": 32.882157,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 7504,
//       "Airport Code [ICAO]": "ZSLD",
//       "Airport Code iATA,FAA": "LCX",
//       "Airport Name": "Longyan Guanzhishan Airport",
//       "Altitude (Ft)": 1225,
//       "Country": "China",
//       "Latitude": 116.747001648,
//       "Location": "Longyan",
//       "Longitude": 25.6746997833,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 7505,
//       "Airport Code [ICAO]": "ZPBS",
//       "Airport Code iATA,FAA": "BSD",
//       "Airport Name": "Baoshan Yunduan Airport",
//       "Altitude (Ft)": 5453,
//       "Country": "China",
//       "Latitude": 99.168297,
//       "Location": "Baoshan",
//       "Longitude": 25.053301,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 7506,
//       "Airport Code [ICAO]": "ZUYI",
//       "Airport Code iATA,FAA": "ACX",
//       "Airport Name": "Xingyi Airport",
//       "Altitude (Ft)": 4150,
//       "Country": "China",
//       "Latitude": 104.959444444,
//       "Location": "Xingyi",
//       "Longitude": 25.0863888889,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 7508,
//       "Airport Code [ICAO]": "ZUNP",
//       "Airport Code iATA,FAA": "HZH",
//       "Airport Name": "Liping Airport",
//       "Altitude (Ft)": 1620,
//       "Country": "China",
//       "Latitude": 109.1499,
//       "Location": "Liping",
//       "Longitude": 26.32217,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 7510,
//       "Airport Code [ICAO]": "UB13",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Stepanakert Air Base",
//       "Altitude (Ft)": 2001,
//       "Country": "Azerbaijan",
//       "Latitude": 46.7869987487793,
//       "Location": "Stepanakert",
//       "Longitude": 39.90140151977539,
//       "GMT Offset": "4",
//       "Daylight Savings": "E",
//       "Region": "Asia/Baku",
//       "Type": "airport"
//     },
//     {
//       "ID": 7511,
//       "Airport Code [ICAO]": "KOSU",
//       "Airport Code iATA,FAA": "OSU",
//       "Airport Name": "The Ohio State University Airport - Don Scott Field",
//       "Altitude (Ft)": 905,
//       "Country": "United States",
//       "Latitude": -83.072998,
//       "Location": "Columbus",
//       "Longitude": 40.0798,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7513,
//       "Airport Code [ICAO]": "KADS",
//       "Airport Code iATA,FAA": "ADS",
//       "Airport Name": "Addison Airport",
//       "Altitude (Ft)": 644,
//       "Country": "United States",
//       "Latitude": -96.8364028931,
//       "Location": "Addison",
//       "Longitude": 32.9686012268,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7514,
//       "Airport Code [ICAO]": "KDTS",
//       "Airport Code iATA,FAA": "DSI",
//       "Airport Name": "Destin Executive Airport",
//       "Altitude (Ft)": 23,
//       "Country": "United States",
//       "Latitude": -86.47149658,
//       "Location": "Destin",
//       "Longitude": 30.40010071,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7516,
//       "Airport Code [ICAO]": "UKOH",
//       "Airport Code iATA,FAA": "KHE",
//       "Airport Name": "Kherson International Airport",
//       "Altitude (Ft)": 148,
//       "Country": "Ukraine",
//       "Latitude": 32.506401,
//       "Location": "Kherson",
//       "Longitude": 46.6758,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 7517,
//       "Airport Code [ICAO]": "NZRC",
//       "Airport Code iATA,FAA": "SZS",
//       "Airport Name": "Ryan's Creek Aerodrome",
//       "Altitude (Ft)": 62,
//       "Country": "New Zealand",
//       "Latitude": 168.100998,
//       "Location": "Stewart Island",
//       "Longitude": -46.8997,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 7518,
//       "Airport Code [ICAO]": "FSAS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Assumption Island Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Seychelles",
//       "Latitude": 46.506802,
//       "Location": "Assumption Island",
//       "Longitude": -9.7422199993,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Indian/Mahe",
//       "Type": "airport"
//     },
//     {
//       "ID": 7519,
//       "Airport Code [ICAO]": "ZGCJ",
//       "Airport Code iATA,FAA": "HJJ",
//       "Airport Name": "Zhijiang Airport",
//       "Altitude (Ft)": 882,
//       "Country": "China",
//       "Latitude": 109.7,
//       "Location": "Zhijiang",
//       "Longitude": 27.4411111111,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 7521,
//       "Airport Code [ICAO]": "CYQI",
//       "Airport Code iATA,FAA": "YQI",
//       "Airport Name": "Yarmouth Airport",
//       "Altitude (Ft)": 141,
//       "Country": "Canada",
//       "Latitude": -66.08809661865234,
//       "Location": "Yarmouth",
//       "Longitude": 43.826900482177734,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Halifax",
//       "Type": "airport"
//     },
//     {
//       "ID": 7522,
//       "Airport Code [ICAO]": "KISO",
//       "Airport Code iATA,FAA": "ISO",
//       "Airport Name": "Kinston Regional Jetport At Stallings Field",
//       "Altitude (Ft)": 93,
//       "Country": "United States",
//       "Latitude": -77.60880279540001,
//       "Location": "Kinston",
//       "Longitude": 35.331401825,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7523,
//       "Airport Code [ICAO]": "KFFA",
//       "Airport Code iATA,FAA": "FFA",
//       "Airport Name": "First Flight Airport",
//       "Altitude (Ft)": 13,
//       "Country": "United States",
//       "Latitude": -75.67130279540001,
//       "Location": "Kill Devil Hills",
//       "Longitude": 36.0181999207,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7527,
//       "Airport Code [ICAO]": "ZPLC",
//       "Airport Code iATA,FAA": "LNJ",
//       "Airport Name": "Lintsang Airfield",
//       "Altitude (Ft)": 6230,
//       "Country": "China",
//       "Latitude": 100.025001526,
//       "Location": "Lincang",
//       "Longitude": 23.738100051900002,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 7531,
//       "Airport Code [ICAO]": "SWMK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Maturacá Airport",
//       "Altitude (Ft)": 354,
//       "Country": "Brazil",
//       "Latitude": -66.11509704589844,
//       "Location": "Maturaca",
//       "Longitude": 0.6282690167427063,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 7532,
//       "Airport Code [ICAO]": "SBCJ",
//       "Airport Code iATA,FAA": "CKS",
//       "Airport Name": "Carajás Airport",
//       "Altitude (Ft)": 2064,
//       "Country": "Brazil",
//       "Latitude": -50.0013885498,
//       "Location": "Parauapebas",
//       "Longitude": -6.11527776718,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Belem",
//       "Type": "airport"
//     },
//     {
//       "ID": 7533,
//       "Airport Code [ICAO]": "SNCW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Centro de Lançamento de Alcântara Airport",
//       "Altitude (Ft)": 148,
//       "Country": "Brazil",
//       "Latitude": -44.396400451660156,
//       "Location": "Alcantara",
//       "Longitude": -2.372999906539917,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 7536,
//       "Airport Code [ICAO]": "WIOM",
//       "Airport Code iATA,FAA": "MWK",
//       "Airport Name": "Tarempa Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Indonesia",
//       "Latitude": 106.25800323486328,
//       "Location": "Anambas Islands",
//       "Longitude": 3.3481199741363525,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 7538,
//       "Airport Code [ICAO]": "EDFW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Würzburg-Schenkenturm Airport",
//       "Altitude (Ft)": 991,
//       "Country": "Germany",
//       "Latitude": 9.8975,
//       "Location": "Wuerzburg",
//       "Longitude": 49.81778,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 7542,
//       "Airport Code [ICAO]": "LPAV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "São Jacinto Airport",
//       "Altitude (Ft)": 26,
//       "Country": "Portugal",
//       "Latitude": -8.741544,
//       "Location": "Aveiro",
//       "Longitude": 40.656741,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Lisbon",
//       "Type": "airport"
//     },
//     {
//       "ID": 7544,
//       "Airport Code [ICAO]": "ETNP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hopsten Air Base",
//       "Altitude (Ft)": 423,
//       "Country": "Germany",
//       "Latitude": 7.541329860687256,
//       "Location": "Hopsten",
//       "Longitude": 52.33869934082031,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 7546,
//       "Airport Code [ICAO]": "OIBP",
//       "Airport Code iATA,FAA": "PGU",
//       "Airport Name": "Persian Gulf International Airport",
//       "Altitude (Ft)": 27,
//       "Country": "Iran",
//       "Latitude": 52.737701,
//       "Location": "Khalije Fars",
//       "Longitude": 27.379601,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 7547,
//       "Airport Code [ICAO]": "OISY",
//       "Airport Code iATA,FAA": "YES",
//       "Airport Name": "Yasouj Airport",
//       "Altitude (Ft)": 5939,
//       "Country": "Iran",
//       "Latitude": 51.545101165771,
//       "Location": "Yasuj",
//       "Longitude": 30.700500488281,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tehran",
//       "Type": "airport"
//     },
//     {
//       "ID": 7548,
//       "Airport Code [ICAO]": "ORBM",
//       "Airport Code iATA,FAA": "OSM",
//       "Airport Name": "Mosul International Airport",
//       "Altitude (Ft)": 719,
//       "Country": "Iraq",
//       "Latitude": 43.1474,
//       "Location": "Mosul",
//       "Longitude": 36.305801,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Asia/Baghdad",
//       "Type": "airport"
//     },
//     {
//       "ID": 7549,
//       "Airport Code [ICAO]": "RJBT",
//       "Airport Code iATA,FAA": "TJH",
//       "Airport Name": "Tajima Airport",
//       "Altitude (Ft)": 584,
//       "Country": "Japan",
//       "Latitude": 134.78700256347656,
//       "Location": "Toyooka",
//       "Longitude": 35.51279830932617,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7550,
//       "Airport Code [ICAO]": "RJDA",
//       "Airport Code iATA,FAA": "AXJ",
//       "Airport Name": "Amakusa Airport",
//       "Altitude (Ft)": 340,
//       "Country": "Japan",
//       "Latitude": 130.158997,
//       "Location": "Amakusa",
//       "Longitude": 32.482498,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7551,
//       "Airport Code [ICAO]": "RJKI",
//       "Airport Code iATA,FAA": "KKX",
//       "Airport Name": "Kikai Airport",
//       "Altitude (Ft)": 21,
//       "Country": "Japan",
//       "Latitude": 129.927993774,
//       "Location": "Kikai",
//       "Longitude": 28.321300506599997,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7552,
//       "Airport Code [ICAO]": "RORA",
//       "Airport Code iATA,FAA": "AGJ",
//       "Airport Name": "Aguni Airport",
//       "Altitude (Ft)": 38,
//       "Country": "Japan",
//       "Latitude": 127.240997314,
//       "Location": "Aguni",
//       "Longitude": 26.5925006866,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7556,
//       "Airport Code [ICAO]": "ZMDN",
//       "Airport Code iATA,FAA": "ULZ",
//       "Airport Name": "Donoi Airport",
//       "Altitude (Ft)": 5800,
//       "Country": "Mongolia",
//       "Latitude": 96.5258,
//       "Location": "Uliastai",
//       "Longitude": 47.7093,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Ulaanbaatar",
//       "Type": "airport"
//     },
//     {
//       "ID": 7557,
//       "Airport Code [ICAO]": "ZMBN",
//       "Airport Code iATA,FAA": "UGA",
//       "Airport Name": "Bulgan Airport",
//       "Altitude (Ft)": 4311,
//       "Country": "Mongolia",
//       "Latitude": 103.47599792480469,
//       "Location": "Bulgan",
//       "Longitude": 48.85499954223633,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Ulaanbaatar",
//       "Type": "airport"
//     },
//     {
//       "ID": 7558,
//       "Airport Code [ICAO]": "ZMUG",
//       "Airport Code iATA,FAA": "ULO",
//       "Airport Name": "Ulaangom Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Mongolia",
//       "Latitude": 91.938273,
//       "Location": "Ulaangom",
//       "Longitude": 50.066588,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Hovd",
//       "Type": "airport"
//     },
//     {
//       "ID": 7559,
//       "Airport Code [ICAO]": "RPVW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Borongan Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Philippines",
//       "Latitude": 125.478996277,
//       "Location": "Borongan",
//       "Longitude": 11.674300193799999,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 7560,
//       "Airport Code [ICAO]": "RPLU",
//       "Airport Code iATA,FAA": "LBX",
//       "Airport Name": "Lubang Airport",
//       "Altitude (Ft)": 43,
//       "Country": "Philippines",
//       "Latitude": 120.1050033569336,
//       "Location": "Lubang",
//       "Longitude": 13.855400085449219,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 7563,
//       "Airport Code [ICAO]": "UTDK",
//       "Airport Code iATA,FAA": "TJU",
//       "Airport Name": "Kulob Airport",
//       "Altitude (Ft)": 2293,
//       "Country": "Tajikistan",
//       "Latitude": 69.80500030517578,
//       "Location": "Kulyab",
//       "Longitude": 37.98809814453125,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dushanbe",
//       "Type": "airport"
//     },
//     {
//       "ID": 7564,
//       "Airport Code [ICAO]": "RCCM",
//       "Airport Code iATA,FAA": "CMJ",
//       "Airport Name": "Chi Mei Airport",
//       "Altitude (Ft)": 63,
//       "Country": "Taiwan",
//       "Latitude": 119.417999268,
//       "Location": "Cimei",
//       "Longitude": 23.2131004333,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Taipei",
//       "Type": "airport"
//     },
//     {
//       "ID": 7565,
//       "Airport Code [ICAO]": "UTAT",
//       "Airport Code iATA,FAA": "TAZ",
//       "Airport Name": "Daşoguz Airport",
//       "Altitude (Ft)": 272,
//       "Country": "Turkmenistan",
//       "Latitude": 59.826698,
//       "Location": "Dasoguz",
//       "Longitude": 41.761101,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Ashgabat",
//       "Type": "airport"
//     },
//     {
//       "ID": 7566,
//       "Airport Code [ICAO]": "YBWX",
//       "Airport Code iATA,FAA": "BWB",
//       "Airport Name": "Barrow Island Airport",
//       "Altitude (Ft)": 26,
//       "Country": "Australia",
//       "Latitude": 115.40599822998047,
//       "Location": "Barrow Island",
//       "Longitude": -20.86440086364746,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 7569,
//       "Airport Code [ICAO]": "YDBY",
//       "Airport Code iATA,FAA": "DRB",
//       "Airport Name": "Derby Airport",
//       "Altitude (Ft)": 24,
//       "Country": "Australia",
//       "Latitude": 123.66100311279297,
//       "Location": "Derby",
//       "Longitude": -17.3700008392334,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 7570,
//       "Airport Code [ICAO]": "YWLG",
//       "Airport Code iATA,FAA": "WGE",
//       "Airport Name": "Walgett Airport",
//       "Altitude (Ft)": 439,
//       "Country": "Australia",
//       "Latitude": 148.12600708007812,
//       "Location": "Walgett",
//       "Longitude": -30.032800674438477,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 7571,
//       "Airport Code [ICAO]": "YBTI",
//       "Airport Code iATA,FAA": "BRT",
//       "Airport Name": "Bathurst Island Airport",
//       "Altitude (Ft)": 67,
//       "Country": "Australia",
//       "Latitude": 130.6199951171875,
//       "Location": "Bathurst Island",
//       "Longitude": -11.769200325012207,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "U",
//       "Region": "Australia/Darwin",
//       "Type": "airport"
//     },
//     {
//       "ID": 7572,
//       "Airport Code [ICAO]": "YDKI",
//       "Airport Code iATA,FAA": "DKI",
//       "Airport Name": "Dunk Island Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Australia",
//       "Latitude": 146.13999939,
//       "Location": "Dunk Island",
//       "Longitude": -17.9416999817,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 7573,
//       "Airport Code [ICAO]": "YLZI",
//       "Airport Code iATA,FAA": "LZR",
//       "Airport Name": "Lizard Island Airport",
//       "Altitude (Ft)": 70,
//       "Country": "Australia",
//       "Latitude": 145.454571,
//       "Location": "Lizard Island",
//       "Longitude": -14.673273,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 7574,
//       "Airport Code [ICAO]": "YHML",
//       "Airport Code iATA,FAA": "HLT",
//       "Airport Name": "Hamilton Airport",
//       "Altitude (Ft)": 803,
//       "Country": "Australia",
//       "Latitude": 142.06500244140625,
//       "Location": "Hamilton",
//       "Longitude": -37.64889907836914,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Hobart",
//       "Type": "airport"
//     },
//     {
//       "ID": 7575,
//       "Airport Code [ICAO]": "YHLC",
//       "Airport Code iATA,FAA": "HCQ",
//       "Airport Name": "Halls Creek Airport",
//       "Altitude (Ft)": 1346,
//       "Country": "Australia",
//       "Latitude": 127.66999816894531,
//       "Location": "Halls Creek",
//       "Longitude": -18.23390007019043,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 7576,
//       "Airport Code [ICAO]": "YFTZ",
//       "Airport Code iATA,FAA": "FIZ",
//       "Airport Name": "Fitzroy Crossing Airport",
//       "Altitude (Ft)": 368,
//       "Country": "Australia",
//       "Latitude": 125.55899810791016,
//       "Location": "Fitzroy Crossing",
//       "Longitude": -18.181900024414062,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 7577,
//       "Airport Code [ICAO]": "YNRV",
//       "Airport Code iATA,FAA": "RVT",
//       "Airport Name": "Ravensthorpe Airport",
//       "Altitude (Ft)": 197,
//       "Country": "Australia",
//       "Latitude": 120.208000183,
//       "Location": "Ravensthorpe",
//       "Longitude": -33.7971992493,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 7578,
//       "Airport Code [ICAO]": "YWKS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Wilkins Runway",
//       "Altitude (Ft)": 2529,
//       "Country": "Antarctica",
//       "Latitude": 111.523611,
//       "Location": "Budd Coast",
//       "Longitude": -66.690833,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Antarctica/South_Pole",
//       "Type": "airport"
//     },
//     {
//       "ID": 7579,
//       "Airport Code [ICAO]": "KPVU",
//       "Airport Code iATA,FAA": "PVU",
//       "Airport Name": "Provo Municipal Airport",
//       "Altitude (Ft)": 4497,
//       "Country": "United States",
//       "Latitude": -111.72299957275,
//       "Location": "Provo",
//       "Longitude": 40.219200134277,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7580,
//       "Airport Code [ICAO]": "KSBS",
//       "Airport Code iATA,FAA": "SBS",
//       "Airport Name": "Steamboat Springs Bob Adams Field",
//       "Altitude (Ft)": 6882,
//       "Country": "United States",
//       "Latitude": -106.8659973,
//       "Location": "Steamboat Springs",
//       "Longitude": 40.5163002,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7581,
//       "Airport Code [ICAO]": "KDTA",
//       "Airport Code iATA,FAA": "DTA",
//       "Airport Name": "Delta Municipal Airport",
//       "Altitude (Ft)": 4759,
//       "Country": "United States",
//       "Latitude": -112.508003235,
//       "Location": "Delta",
//       "Longitude": 39.3805999756,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7582,
//       "Airport Code [ICAO]": "KRIF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Richfield Municipal Airport",
//       "Altitude (Ft)": 5301,
//       "Country": "United States",
//       "Latitude": -112.0989990234375,
//       "Location": "Richfield",
//       "Longitude": 38.73640060424805,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7583,
//       "Airport Code [ICAO]": "KPUC",
//       "Airport Code iATA,FAA": "PUC",
//       "Airport Name": "Carbon County Regional/Buck Davis Field",
//       "Altitude (Ft)": 5957,
//       "Country": "United States",
//       "Latitude": -110.7509995,
//       "Location": "Price",
//       "Longitude": 39.61389923,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7584,
//       "Airport Code [ICAO]": "KLAM",
//       "Airport Code iATA,FAA": "LAM",
//       "Airport Name": "Los Alamos Airport",
//       "Altitude (Ft)": 7171,
//       "Country": "United States",
//       "Latitude": -106.268997192,
//       "Location": "Los Alamos",
//       "Longitude": 35.8797988892,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7586,
//       "Airport Code [ICAO]": "KHII",
//       "Airport Code iATA,FAA": "HII",
//       "Airport Name": "Lake Havasu City Airport",
//       "Altitude (Ft)": 783,
//       "Country": "United States",
//       "Latitude": -114.358002,
//       "Location": "Lake Havasu City",
//       "Longitude": 34.571098,
//       "GMT Offset": "-7",
//       "Daylight Savings": "N",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 7587,
//       "Airport Code [ICAO]": "KINW",
//       "Airport Code iATA,FAA": "INW",
//       "Airport Name": "Winslow Lindbergh Regional Airport",
//       "Altitude (Ft)": 4941,
//       "Country": "United States",
//       "Latitude": -110.722999573,
//       "Location": "Winslow",
//       "Longitude": 35.021900177,
//       "GMT Offset": "-7",
//       "Daylight Savings": "N",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 7588,
//       "Airport Code [ICAO]": "KDGL",
//       "Airport Code iATA,FAA": "DGL",
//       "Airport Name": "Douglas Municipal Airport",
//       "Altitude (Ft)": 4173,
//       "Country": "United States",
//       "Latitude": -109.505996704,
//       "Location": "Douglas",
//       "Longitude": 31.3425998688,
//       "GMT Offset": "-7",
//       "Daylight Savings": "N",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 7589,
//       "Airport Code [ICAO]": "NGMK",
//       "Airport Code iATA,FAA": "MZK",
//       "Airport Name": "Marakei Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Kiribati",
//       "Latitude": 173.27099609375,
//       "Location": "Marakei",
//       "Longitude": 2.058609962463379,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tarawa",
//       "Type": "airport"
//     },
//     {
//       "ID": 7590,
//       "Airport Code [ICAO]": "NGTB",
//       "Airport Code iATA,FAA": "AEA",
//       "Airport Name": "Abemama Atoll Airport",
//       "Altitude (Ft)": 8,
//       "Country": "Kiribati",
//       "Latitude": 173.82899475097656,
//       "Location": "Abemama",
//       "Longitude": 0.49083301424980164,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tarawa",
//       "Type": "airport"
//     },
//     {
//       "ID": 7591,
//       "Airport Code [ICAO]": "NGUK",
//       "Airport Code iATA,FAA": "AAK",
//       "Airport Name": "Buariki Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Kiribati",
//       "Latitude": 173.63699340820312,
//       "Location": "Buariki",
//       "Longitude": 0.1852779984474182,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 7592,
//       "Airport Code [ICAO]": "NGKT",
//       "Airport Code iATA,FAA": "KUC",
//       "Airport Name": "Kuria Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Kiribati",
//       "Latitude": 173.44200134277344,
//       "Location": "Kuria",
//       "Longitude": 0.2186110019683838,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tarawa",
//       "Type": "airport"
//     },
//     {
//       "ID": 7593,
//       "Airport Code [ICAO]": "NGTR",
//       "Airport Code iATA,FAA": "AIS",
//       "Airport Name": "Arorae Island Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Kiribati",
//       "Latitude": 176.80299377441406,
//       "Location": "Arorae",
//       "Longitude": -2.61611008644104,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tarawa",
//       "Type": "airport"
//     },
//     {
//       "ID": 7594,
//       "Airport Code [ICAO]": "NGTM",
//       "Airport Code iATA,FAA": "TMN",
//       "Airport Name": "Tamana Island Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Kiribati",
//       "Latitude": 175.97000122070312,
//       "Location": "Tamana",
//       "Longitude": -2.485830068588257,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tarawa",
//       "Type": "airport"
//     },
//     {
//       "ID": 7595,
//       "Airport Code [ICAO]": "NGBR",
//       "Airport Code iATA,FAA": "BEZ",
//       "Airport Name": "Beru Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Kiribati",
//       "Latitude": 176.0070037841797,
//       "Location": "Beru Island",
//       "Longitude": -1.3547199964523315,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tarawa",
//       "Type": "airport"
//     },
//     {
//       "ID": 7596,
//       "Airport Code [ICAO]": "NGNU",
//       "Airport Code iATA,FAA": "NIG",
//       "Airport Name": "Nikunau Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Kiribati",
//       "Latitude": 176.410003662,
//       "Location": "Nikunau",
//       "Longitude": -1.31444001198,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tarawa",
//       "Type": "airport"
//     },
//     {
//       "ID": 7597,
//       "Airport Code [ICAO]": "NGTU",
//       "Airport Code iATA,FAA": "BBG",
//       "Airport Name": "Butaritari Atoll Airport",
//       "Altitude (Ft)": 5,
//       "Country": "Kiribati",
//       "Latitude": 172.811005,
//       "Location": "Butaritari",
//       "Longitude": 3.08583,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tarawa",
//       "Type": "airport"
//     },
//     {
//       "ID": 7598,
//       "Airport Code [ICAO]": "NGMN",
//       "Airport Code iATA,FAA": "MTK",
//       "Airport Name": "Makin Island Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Kiribati",
//       "Latitude": 172.99200439453125,
//       "Location": "Makin",
//       "Longitude": 3.3744399547576904,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tarawa",
//       "Type": "airport"
//     },
//     {
//       "ID": 7599,
//       "Airport Code [ICAO]": "NGMA",
//       "Airport Code iATA,FAA": "MNK",
//       "Airport Name": "Maiana Airport",
//       "Altitude (Ft)": 8,
//       "Country": "Kiribati",
//       "Latitude": 173.031005859375,
//       "Location": "Maiana",
//       "Longitude": 1.0036100149154663,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tarawa",
//       "Type": "airport"
//     },
//     {
//       "ID": 7600,
//       "Airport Code [ICAO]": "NGTO",
//       "Airport Code iATA,FAA": "NON",
//       "Airport Name": "Nonouti Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Kiribati",
//       "Latitude": 174.42799377441406,
//       "Location": "Nonouti",
//       "Longitude": -0.6397219896316528,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tarawa",
//       "Type": "airport"
//     },
//     {
//       "ID": 7601,
//       "Airport Code [ICAO]": "NGTS",
//       "Airport Code iATA,FAA": "TSU",
//       "Airport Name": "Tabiteuea South Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Kiribati",
//       "Latitude": 175.06399536132812,
//       "Location": "Tabiteuea",
//       "Longitude": -1.4744399785995483,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Tarawa",
//       "Type": "airport"
//     },
//     {
//       "ID": 7610,
//       "Airport Code [ICAO]": "NZWT",
//       "Airport Code iATA,FAA": "WTZ",
//       "Airport Name": "Whitianga Airport",
//       "Altitude (Ft)": 10,
//       "Country": "New Zealand",
//       "Latitude": 175.6790008544922,
//       "Location": "Whitianga",
//       "Longitude": -36.83169937133789,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 7611,
//       "Airport Code [ICAO]": "NZTK",
//       "Airport Code iATA,FAA": "KTF",
//       "Airport Name": "Takaka Airport",
//       "Altitude (Ft)": 102,
//       "Country": "New Zealand",
//       "Latitude": 172.77499389648438,
//       "Location": "Takaka",
//       "Longitude": -40.81330108642578,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 7615,
//       "Airport Code [ICAO]": "AGAF",
//       "Airport Code iATA,FAA": "AFT",
//       "Airport Name": "Afutara Aerodrome",
//       "Altitude (Ft)": 23,
//       "Country": "Solomon Islands",
//       "Latitude": 160.948611111,
//       "Location": "Afutara",
//       "Longitude": -9.19138888889,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Guadalcanal",
//       "Type": "airport"
//     },
//     {
//       "ID": 7616,
//       "Airport Code [ICAO]": "AGAR",
//       "Airport Code iATA,FAA": "RNA",
//       "Airport Name": "Ulawa Airport",
//       "Altitude (Ft)": 40,
//       "Country": "Solomon Islands",
//       "Latitude": 161.979546547,
//       "Location": "Ulawa",
//       "Longitude": -9.86054358262,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Guadalcanal",
//       "Type": "airport"
//     },
//     {
//       "ID": 7617,
//       "Airport Code [ICAO]": "AGGC",
//       "Airport Code iATA,FAA": "CHY",
//       "Airport Name": "Choiseul Bay Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Solomon Islands",
//       "Latitude": 156.396111,
//       "Location": "Choiseul Bay",
//       "Longitude": -6.711944,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Guadalcanal",
//       "Type": "airport"
//     },
//     {
//       "ID": 7618,
//       "Airport Code [ICAO]": "AGGT",
//       "Airport Code iATA,FAA": "NNB",
//       "Airport Name": "Santa Ana Airport",
//       "Altitude (Ft)": 3,
//       "Country": "Solomon Islands",
//       "Latitude": 162.454108,
//       "Location": "Santa Ana",
//       "Longitude": -10.847994,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Guadalcanal",
//       "Type": "airport"
//     },
//     {
//       "ID": 7619,
//       "Airport Code [ICAO]": "AGGY",
//       "Airport Code iATA,FAA": "XYA",
//       "Airport Name": "Yandina Airport",
//       "Altitude (Ft)": 60,
//       "Country": "Solomon Islands",
//       "Latitude": 159.21841,
//       "Location": "Yandina",
//       "Longitude": -9.092816,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Guadalcanal",
//       "Type": "airport"
//     },
//     {
//       "ID": 7620,
//       "Airport Code [ICAO]": "AGBT",
//       "Airport Code iATA,FAA": "BPF",
//       "Airport Name": "Batuna Aerodrome",
//       "Altitude (Ft)": 24,
//       "Country": "Solomon Islands",
//       "Latitude": 158.119305556,
//       "Location": "Batuna",
//       "Longitude": -8.56202777778,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Guadalcanal",
//       "Type": "airport"
//     },
//     {
//       "ID": 7621,
//       "Airport Code [ICAO]": "KBOW",
//       "Airport Code iATA,FAA": "BOW",
//       "Airport Name": "Bartow Municipal Airport",
//       "Altitude (Ft)": 125,
//       "Country": "United States",
//       "Latitude": -81.78340148930002,
//       "Location": "Bartow",
//       "Longitude": 27.943399429299998,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7622,
//       "Airport Code [ICAO]": "UUBD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Dyagilevo Air Base",
//       "Altitude (Ft)": 440,
//       "Country": "Russia",
//       "Latitude": 39.57040023803711,
//       "Location": "Kostroma",
//       "Longitude": 54.64469909667969,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 7627,
//       "Airport Code [ICAO]": "NSFQ",
//       "Airport Code iATA,FAA": "FTI",
//       "Airport Name": "Fitiuta Airport",
//       "Altitude (Ft)": 110,
//       "Country": "American Samoa",
//       "Latitude": -169.425003,
//       "Location": "Fiti\\'uta",
//       "Longitude": -14.2172,
//       "GMT Offset": "-11",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Pago_Pago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7629,
//       "Airport Code [ICAO]": "KLVK",
//       "Airport Code iATA,FAA": "LVK",
//       "Airport Name": "Livermore Municipal Airport",
//       "Altitude (Ft)": 400,
//       "Country": "United States",
//       "Latitude": -121.819999695,
//       "Location": "Livermore",
//       "Longitude": 37.6934013367,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7630,
//       "Airport Code [ICAO]": "KMPI",
//       "Airport Code iATA,FAA": "RMY",
//       "Airport Name": "Mariposa Yosemite Airport",
//       "Altitude (Ft)": 2254,
//       "Country": "United States",
//       "Latitude": -120.040000916,
//       "Location": "Mariposa",
//       "Longitude": 37.5108985901,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7633,
//       "Airport Code [ICAO]": "FYGF",
//       "Airport Code iATA,FAA": "GFY",
//       "Airport Name": "Grootfontein Airport",
//       "Altitude (Ft)": 4636,
//       "Country": "Namibia",
//       "Latitude": 18.122699737548828,
//       "Location": "Grootfontein",
//       "Longitude": -19.60219955444336,
//       "GMT Offset": "1",
//       "Daylight Savings": "S",
//       "Region": "Africa/Windhoek",
//       "Type": "airport"
//     },
//     {
//       "ID": 7634,
//       "Airport Code [ICAO]": "FYRU",
//       "Airport Code iATA,FAA": "NDU",
//       "Airport Name": "Rundu Airport",
//       "Altitude (Ft)": 3627,
//       "Country": "Namibia",
//       "Latitude": 19.719400405884,
//       "Location": "Rundu",
//       "Longitude": -17.956499099731,
//       "GMT Offset": "1",
//       "Daylight Savings": "S",
//       "Region": "Africa/Windhoek",
//       "Type": "airport"
//     },
//     {
//       "ID": 7642,
//       "Airport Code [ICAO]": "BGAM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tasiilaq Heliport",
//       "Altitude (Ft)": 24,
//       "Country": "Greenland",
//       "Latitude": -37.618335,
//       "Location": "Angmagssalik",
//       "Longitude": 65.612296,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": "America/Godthab",
//       "Type": "airport"
//     },
//     {
//       "ID": 7646,
//       "Airport Code [ICAO]": "KTRM",
//       "Airport Code iATA,FAA": "TRM",
//       "Airport Name": "Jacqueline Cochran Regional Airport",
//       "Altitude (Ft)": -115,
//       "Country": "United States",
//       "Latitude": -116.16000366211,
//       "Location": "Palm Springs",
//       "Longitude": 33.62670135498,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7647,
//       "Airport Code [ICAO]": "KSMO",
//       "Airport Code iATA,FAA": "SMO",
//       "Airport Name": "Santa Monica Municipal Airport",
//       "Altitude (Ft)": 177,
//       "Country": "United States",
//       "Latitude": -118.450996399,
//       "Location": "Santa Monica",
//       "Longitude": 34.015800476100004,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7648,
//       "Airport Code [ICAO]": "KUDD",
//       "Airport Code iATA,FAA": "UDD",
//       "Airport Name": "Bermuda Dunes Airport",
//       "Altitude (Ft)": 73,
//       "Country": "United States",
//       "Latitude": -116.27500152588,
//       "Location": "Palm Springs",
//       "Longitude": 33.748401641846,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7649,
//       "Airport Code [ICAO]": "KSDL",
//       "Airport Code iATA,FAA": "SCF",
//       "Airport Name": "Scottsdale Airport",
//       "Altitude (Ft)": 1510,
//       "Country": "United States",
//       "Latitude": -111.91100311279,
//       "Location": "Scottsdale",
//       "Longitude": 33.622898101807,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 7650,
//       "Airport Code [ICAO]": "KOLM",
//       "Airport Code iATA,FAA": "OLM",
//       "Airport Name": "Olympia Regional Airport",
//       "Altitude (Ft)": 209,
//       "Country": "United States",
//       "Latitude": -122.9029999,
//       "Location": "Olympia",
//       "Longitude": 46.9693985,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7651,
//       "Airport Code [ICAO]": "KDWA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Yolo County Davis Woodland Winters Airport",
//       "Altitude (Ft)": 100,
//       "Country": "United States",
//       "Latitude": -121.8570023,
//       "Location": "Davis-Woodland-Winters",
//       "Longitude": 38.57910156,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7652,
//       "Airport Code [ICAO]": "KRIL",
//       "Airport Code iATA,FAA": "RIL",
//       "Airport Name": "Garfield County Regional Airport",
//       "Altitude (Ft)": 5548,
//       "Country": "United States",
//       "Latitude": -107.726997,
//       "Location": "Rifle",
//       "Longitude": 39.526299,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7653,
//       "Airport Code [ICAO]": "KSAA",
//       "Airport Code iATA,FAA": "SAA",
//       "Airport Name": "Shively Field",
//       "Altitude (Ft)": 7012,
//       "Country": "United States",
//       "Latitude": -106.823997,
//       "Location": "SARATOGA",
//       "Longitude": 41.444901,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7654,
//       "Airport Code [ICAO]": "KPDK",
//       "Airport Code iATA,FAA": "PDK",
//       "Airport Name": "DeKalb Peachtree Airport",
//       "Altitude (Ft)": 1003,
//       "Country": "United States",
//       "Latitude": -84.3020019531,
//       "Location": "Atlanta",
//       "Longitude": 33.8755989075,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7655,
//       "Airport Code [ICAO]": "KBMG",
//       "Airport Code iATA,FAA": "BMG",
//       "Airport Name": "Monroe County Airport",
//       "Altitude (Ft)": 846,
//       "Country": "United States",
//       "Latitude": -86.61669921875,
//       "Location": "Bloomington",
//       "Longitude": 39.145999908447266,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7656,
//       "Airport Code [ICAO]": "KSUA",
//       "Airport Code iATA,FAA": "SUA",
//       "Airport Name": "Witham Field",
//       "Altitude (Ft)": 16,
//       "Country": "United States",
//       "Latitude": -80.22109985,
//       "Location": "Stuart",
//       "Longitude": 27.18169975,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7657,
//       "Airport Code [ICAO]": "KMMU",
//       "Airport Code iATA,FAA": "MMU",
//       "Airport Name": "Morristown Municipal Airport",
//       "Altitude (Ft)": 187,
//       "Country": "United States",
//       "Latitude": -74.41490173339844,
//       "Location": "Morristown",
//       "Longitude": 40.799400329589844,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7658,
//       "Airport Code [ICAO]": "KAPC",
//       "Airport Code iATA,FAA": "APC",
//       "Airport Name": "Napa County Airport",
//       "Altitude (Ft)": 35,
//       "Country": "United States",
//       "Latitude": -122.280998,
//       "Location": "Napa",
//       "Longitude": 38.2132,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7659,
//       "Airport Code [ICAO]": "KSDM",
//       "Airport Code iATA,FAA": "SDM",
//       "Airport Name": "Brown Field Municipal Airport",
//       "Altitude (Ft)": 526,
//       "Country": "United States",
//       "Latitude": -116.98000335693,
//       "Location": "San Diego",
//       "Longitude": 32.572299957275,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7660,
//       "Airport Code [ICAO]": "LSPV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Wangen-Lachen Airport",
//       "Altitude (Ft)": 1335,
//       "Country": "Switzerland",
//       "Latitude": 8.8675,
//       "Location": "Wangen-Lachen",
//       "Longitude": 47.2047,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 7662,
//       "Airport Code [ICAO]": "KVNC",
//       "Airport Code iATA,FAA": "VNC",
//       "Airport Name": "Venice Municipal Airport",
//       "Altitude (Ft)": 18,
//       "Country": "United States",
//       "Latitude": -82.440299987793,
//       "Location": "Venice",
//       "Longitude": 27.071599960327,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7663,
//       "Airport Code [ICAO]": "KPHK",
//       "Airport Code iATA,FAA": "PHK",
//       "Airport Name": "Palm Beach County Glades Airport",
//       "Altitude (Ft)": 16,
//       "Country": "United States",
//       "Latitude": -80.69339752,
//       "Location": "Pahokee",
//       "Longitude": 26.78499985,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7669,
//       "Airport Code [ICAO]": "KECP",
//       "Airport Code iATA,FAA": "ECP",
//       "Airport Name": "Northwest Florida Beaches International Airport",
//       "Altitude (Ft)": 69,
//       "Country": "United States",
//       "Latitude": -85.795414,
//       "Location": "Panama City",
//       "Longitude": 30.357106,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7670,
//       "Airport Code [ICAO]": "KSBD",
//       "Airport Code iATA,FAA": "SBD",
//       "Airport Name": "San Bernardino International Airport",
//       "Altitude (Ft)": 1159,
//       "Country": "United States",
//       "Latitude": -117.23500061,
//       "Location": "San Bernardino",
//       "Longitude": 34.0954017639,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7671,
//       "Airport Code [ICAO]": "SNVB",
//       "Airport Code iATA,FAA": "VAL",
//       "Airport Name": "Valença Airport",
//       "Altitude (Ft)": 21,
//       "Country": "Brazil",
//       "Latitude": -38.992401,
//       "Location": "Valenca",
//       "Longitude": -13.2965,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 7673,
//       "Airport Code [ICAO]": "SNRU",
//       "Airport Code iATA,FAA": "CAU",
//       "Airport Name": "Caruaru Airport",
//       "Altitude (Ft)": 1891,
//       "Country": "Brazil",
//       "Latitude": -36.01350021362305,
//       "Location": "Caruaru",
//       "Longitude": -8.282389640808105,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 7674,
//       "Airport Code [ICAO]": "PWAK",
//       "Airport Code iATA,FAA": "AWK",
//       "Airport Name": "Wake Island Airfield",
//       "Altitude (Ft)": 14,
//       "Country": "Wake Island",
//       "Latitude": 166.63600158691406,
//       "Location": "Wake island",
//       "Longitude": 19.282100677490234,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Johnston",
//       "Type": "airport"
//     },
//     {
//       "ID": 7675,
//       "Airport Code [ICAO]": "SDNY",
//       "Airport Code iATA,FAA": "QNV",
//       "Airport Name": "Aeroclube Airport",
//       "Altitude (Ft)": 164,
//       "Country": "Brazil",
//       "Latitude": -43.46030044555664,
//       "Location": "Nova Iguacu",
//       "Longitude": -22.74530029296875,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7683,
//       "Airport Code [ICAO]": "KSQL",
//       "Airport Code iATA,FAA": "SQL",
//       "Airport Name": "San Carlos Airport",
//       "Altitude (Ft)": 5,
//       "Country": "United States",
//       "Latitude": -122.25,
//       "Location": "San Carlos",
//       "Longitude": 37.511901855469,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7684,
//       "Airport Code [ICAO]": "LSZJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Courtelary Airport",
//       "Altitude (Ft)": 2247,
//       "Country": "Switzerland",
//       "Latitude": 7.090829849243164,
//       "Location": "Courtelary ",
//       "Longitude": 47.18360137939453,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 7685,
//       "Airport Code [ICAO]": "EPKO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lotnisko Korne",
//       "Altitude (Ft)": 515,
//       "Country": "Poland",
//       "Latitude": 17.844972,
//       "Location": "Koszalin",
//       "Longitude": 54.129583,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 7686,
//       "Airport Code [ICAO]": "FBCO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Camp Okavango Airport",
//       "Altitude (Ft)": 3158,
//       "Country": "Botswana",
//       "Latitude": 23.102505,
//       "Location": "Okavango Delta",
//       "Longitude": -19.131115,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Gaborone",
//       "Type": "airport"
//     },
//     {
//       "ID": 7690,
//       "Airport Code [ICAO]": "KRWI",
//       "Airport Code iATA,FAA": "RWI",
//       "Airport Name": "Rocky Mount Wilson Regional Airport",
//       "Altitude (Ft)": 159,
//       "Country": "United States",
//       "Latitude": -77.89189910888672,
//       "Location": "Rocky Mount",
//       "Longitude": 35.856300354003906,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7691,
//       "Airport Code [ICAO]": "PAWR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Whittier Airport",
//       "Altitude (Ft)": 30,
//       "Country": "United States",
//       "Latitude": -148.7220001220703,
//       "Location": "Whittier",
//       "Longitude": 60.777198791503906,
//       "GMT Offset": "-9",
//       "Daylight Savings": "U",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7692,
//       "Airport Code [ICAO]": "PASX",
//       "Airport Code iATA,FAA": "SXQ",
//       "Airport Name": "Soldotna Airport",
//       "Altitude (Ft)": 113,
//       "Country": "United States",
//       "Latitude": -151.03399658203125,
//       "Location": "Soldotna",
//       "Longitude": 60.47570037841797,
//       "GMT Offset": "-9",
//       "Daylight Savings": "U",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 7693,
//       "Airport Code [ICAO]": "KSEE",
//       "Airport Code iATA,FAA": "SEE",
//       "Airport Name": "Gillespie Field",
//       "Altitude (Ft)": 388,
//       "Country": "United States",
//       "Latitude": -116.97200012207,
//       "Location": "El Cajon",
//       "Longitude": 32.826198577881,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7694,
//       "Airport Code [ICAO]": "KNUC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "San Clemente Island Naval Auxiliary Landing Field",
//       "Altitude (Ft)": 184,
//       "Country": "United States",
//       "Latitude": -118.5879974,
//       "Location": "San Clemente Island",
//       "Longitude": 33.02270126,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7700,
//       "Airport Code [ICAO]": "VVPR",
//       "Airport Code iATA,FAA": "PHA",
//       "Airport Name": "Phan Rang Airport",
//       "Altitude (Ft)": 101,
//       "Country": "Vietnam",
//       "Latitude": 108.952003479,
//       "Location": "Phan Rang",
//       "Longitude": 11.6335000992,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Saigon",
//       "Type": "airport"
//     },
//     {
//       "ID": 7701,
//       "Airport Code [ICAO]": "VVNS",
//       "Airport Code iATA,FAA": "SQH",
//       "Airport Name": "Na-San Airport",
//       "Altitude (Ft)": 2133,
//       "Country": "Vietnam",
//       "Latitude": 104.03299713134766,
//       "Location": "Son-La",
//       "Longitude": 21.216999053955078,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Saigon",
//       "Type": "airport"
//     },
//     {
//       "ID": 7702,
//       "Airport Code [ICAO]": "KTRK",
//       "Airport Code iATA,FAA": "TKF",
//       "Airport Name": "Truckee Tahoe Airport",
//       "Altitude (Ft)": 5900,
//       "Country": "United States",
//       "Latitude": -120.13999939,
//       "Location": "Truckee",
//       "Longitude": 39.319999694799996,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7703,
//       "Airport Code [ICAO]": "LFTU",
//       "Airport Code iATA,FAA": "FRJ",
//       "Airport Name": "Fréjus Airport",
//       "Altitude (Ft)": 33,
//       "Country": "France",
//       "Latitude": 6.7357,
//       "Location": "Frejus",
//       "Longitude": 43.4175,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 7704,
//       "Airport Code [ICAO]": "YGLG",
//       "Airport Code iATA,FAA": "GEX",
//       "Airport Name": "Geelong Airport",
//       "Altitude (Ft)": 43,
//       "Country": "Australia",
//       "Latitude": 144.33299255371094,
//       "Location": "Geelong",
//       "Longitude": -38.224998474121094,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Hobart",
//       "Type": "airport"
//     },
//     {
//       "ID": 7710,
//       "Airport Code [ICAO]": "ULAE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mezen Airport",
//       "Altitude (Ft)": 46,
//       "Country": "Russia",
//       "Latitude": 44.21500015258789,
//       "Location": "Mezen",
//       "Longitude": 65.87833404541016,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 7711,
//       "Airport Code [ICAO]": "ULAH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Vaskovo Airport",
//       "Altitude (Ft)": 82,
//       "Country": "Russia",
//       "Latitude": 40.421699523899996,
//       "Location": "Arkhangelsk",
//       "Longitude": 64.44170379639999,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 7712,
//       "Airport Code [ICAO]": "KRYY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cobb County-Mc Collum Field",
//       "Altitude (Ft)": 1040,
//       "Country": "United States",
//       "Latitude": -84.59860229,
//       "Location": "Atlanta",
//       "Longitude": 34.01319885,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7714,
//       "Airport Code [ICAO]": "LOXT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Brumowski  Air Base",
//       "Altitude (Ft)": 574,
//       "Country": "Austria",
//       "Latitude": 16.116699,
//       "Location": "Tulln",
//       "Longitude": 48.3167,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": "Europe/Vienna",
//       "Type": "airport"
//     },
//     {
//       "ID": 7716,
//       "Airport Code [ICAO]": "K4U9",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Dell Flight Strip",
//       "Altitude (Ft)": 6007,
//       "Country": "United States",
//       "Latitude": -112.720001221,
//       "Location": "Dell",
//       "Longitude": 44.7356987,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7717,
//       "Airport Code [ICAO]": "KLVM",
//       "Airport Code iATA,FAA": "LVM",
//       "Airport Name": "Mission Field",
//       "Altitude (Ft)": 4660,
//       "Country": "United States",
//       "Latitude": -110.447998047,
//       "Location": "Livingston-Montana",
//       "Longitude": 45.6994018555,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7720,
//       "Airport Code [ICAO]": "K6S0",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Big Timber Airport",
//       "Altitude (Ft)": 4492,
//       "Country": "United States",
//       "Latitude": -109.98100280761719,
//       "Location": "Big Timber",
//       "Longitude": 45.806400299072266,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7721,
//       "Airport Code [ICAO]": "KBIV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tulip City Airport",
//       "Altitude (Ft)": 698,
//       "Country": "United States",
//       "Latitude": -86.107398986816,
//       "Location": "Holland",
//       "Longitude": 42.742900848389,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7722,
//       "Airport Code [ICAO]": "EGLW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "London Heliport",
//       "Altitude (Ft)": 18,
//       "Country": "United Kingdom",
//       "Latitude": -0.179444000125,
//       "Location": "London",
//       "Longitude": 51.46972274779999,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 7723,
//       "Airport Code [ICAO]": "LIPV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Venice-Lido Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Italy",
//       "Latitude": 12.3881,
//       "Location": "Venice",
//       "Longitude": 45.428299,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 7724,
//       "Airport Code [ICAO]": "EECL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tallinn Linnahall Heliport",
//       "Altitude (Ft)": 23,
//       "Country": "Estonia",
//       "Latitude": 24.75322723388672,
//       "Location": "Tallinn",
//       "Longitude": 59.4486198425293,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Tallinn",
//       "Type": "airport"
//     },
//     {
//       "ID": 7725,
//       "Airport Code [ICAO]": "EFHE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hernesaari Heliport",
//       "Altitude (Ft)": 7,
//       "Country": "Finland",
//       "Latitude": 24.9244441986084,
//       "Location": "Helsinki",
//       "Longitude": 60.14777755737305,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Helsinki",
//       "Type": "airport"
//     },
//     {
//       "ID": 7726,
//       "Airport Code [ICAO]": "EDRI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Linkenheim Airport",
//       "Altitude (Ft)": 325,
//       "Country": "Germany",
//       "Latitude": 8.394721984863281,
//       "Location": "Linkenheim",
//       "Longitude": 49.141666412353516,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 7727,
//       "Airport Code [ICAO]": "UT25",
//       "Airport Code iATA,FAA": "GMV",
//       "Airport Name": "Monument Valley Airport",
//       "Altitude (Ft)": 5192,
//       "Country": "United States",
//       "Latitude": -110.200996399,
//       "Location": "Monument Valley",
//       "Longitude": 37.016700744599994,
//       "GMT Offset": "-7",
//       "Daylight Savings": "U",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7728,
//       "Airport Code [ICAO]": "EHHV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hilversum Airfield",
//       "Altitude (Ft)": 3,
//       "Country": "Netherlands",
//       "Latitude": 5.14694,
//       "Location": "Hilversum",
//       "Longitude": 52.191898,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Amsterdam",
//       "Type": "airport"
//     },
//     {
//       "ID": 7729,
//       "Airport Code [ICAO]": "KJRA",
//       "Airport Code iATA,FAA": "JRA",
//       "Airport Name": "West 30th St. Heliport",
//       "Altitude (Ft)": 7,
//       "Country": "United States",
//       "Latitude": -74.007103,
//       "Location": "New York",
//       "Longitude": 40.754501,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7730,
//       "Airport Code [ICAO]": "EHTX",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Texel Airfield",
//       "Altitude (Ft)": 2,
//       "Country": "Netherlands",
//       "Latitude": 4.83361,
//       "Location": "Texel",
//       "Longitude": 53.115299,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Amsterdam",
//       "Type": "airport"
//     },
//     {
//       "ID": 7731,
//       "Airport Code [ICAO]": "LECD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "La Cerdanya Airport",
//       "Altitude (Ft)": 3586,
//       "Country": "Spain",
//       "Latitude": 1.86667001247406,
//       "Location": "Das i Fontanals de Cerdanya",
//       "Longitude": 42.38639831542969,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 7732,
//       "Airport Code [ICAO]": "KLAL",
//       "Airport Code iATA,FAA": "LAL",
//       "Airport Name": "Lakeland Linder International Airport",
//       "Altitude (Ft)": 142,
//       "Country": "United States",
//       "Latitude": -82.018602,
//       "Location": "Lakeland",
//       "Longitude": 27.988899,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7738,
//       "Airport Code [ICAO]": "UUOS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Stary Oskol Airport",
//       "Altitude (Ft)": 791,
//       "Country": "Russia",
//       "Latitude": 37.76884841918945,
//       "Location": "Stary Oskol",
//       "Longitude": 51.32921600341797,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 7766,
//       "Airport Code [ICAO]": "VNSB",
//       "Airport Code iATA,FAA": "SYH",
//       "Airport Name": "Syangboche Airport",
//       "Altitude (Ft)": 12400,
//       "Country": "Nepal",
//       "Latitude": 86.7124,
//       "Location": "Syangboche",
//       "Longitude": 27.8112,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 7767,
//       "Airport Code [ICAO]": "KIDL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Indianola Municipal Airport",
//       "Altitude (Ft)": 126,
//       "Country": "United States",
//       "Latitude": -90.678902,
//       "Location": "New York",
//       "Longitude": 33.485699,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7768,
//       "Airport Code [ICAO]": "UNKM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cheremshanka Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Russia",
//       "Latitude": 92.5400009155,
//       "Location": "Krasnoyarsk",
//       "Longitude": 56.176998138399995,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Krasnoyarsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 7769,
//       "Airport Code [ICAO]": "KF70",
//       "Airport Code iATA,FAA": "RBK",
//       "Airport Name": "French Valley Airport",
//       "Altitude (Ft)": 1350,
//       "Country": "United States",
//       "Latitude": -117.127998352,
//       "Location": "Murrieta-Temecula",
//       "Longitude": 33.5741996765,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7772,
//       "Airport Code [ICAO]": "LIER",
//       "Airport Code iATA,FAA": "FNU",
//       "Airport Name": "Oristano-Fenosu Airport",
//       "Altitude (Ft)": 36,
//       "Country": "Italy",
//       "Latitude": 8.642661,
//       "Location": "Oristano",
//       "Longitude": 39.895308,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 7773,
//       "Airport Code [ICAO]": "EGLM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "White Waltham Airfield",
//       "Altitude (Ft)": 131,
//       "Country": "United Kingdom",
//       "Latitude": -0.774443984032,
//       "Location": "Maidenhead",
//       "Longitude": 51.500801086399996,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 7774,
//       "Airport Code [ICAO]": "VOMY",
//       "Airport Code iATA,FAA": "MYQ",
//       "Airport Name": "Mysore Airport",
//       "Altitude (Ft)": 2349,
//       "Country": "India",
//       "Latitude": 76.655833,
//       "Location": "Mysore",
//       "Longitude": 12.23,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 7775,
//       "Airport Code [ICAO]": "KPCW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Carl R Keller Field",
//       "Altitude (Ft)": 590,
//       "Country": "United States",
//       "Latitude": -82.86869812011719,
//       "Location": "Port Clinton",
//       "Longitude": 41.516300201416016,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7776,
//       "Airport Code [ICAO]": "KMGY",
//       "Airport Code iATA,FAA": "MGY",
//       "Airport Name": "Dayton-Wright Brothers Airport",
//       "Altitude (Ft)": 957,
//       "Country": "United States",
//       "Latitude": -84.224899292,
//       "Location": "Dayton",
//       "Longitude": 39.5890007019,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7777,
//       "Airport Code [ICAO]": "KRID",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Richmond Municipal Airport",
//       "Altitude (Ft)": 1140,
//       "Country": "United States",
//       "Latitude": -84.8427963256836,
//       "Location": "Richmond",
//       "Longitude": 39.757198333740234,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7778,
//       "Airport Code [ICAO]": "KFDY",
//       "Airport Code iATA,FAA": "FDY",
//       "Airport Name": "Findlay Airport",
//       "Altitude (Ft)": 813,
//       "Country": "United States",
//       "Latitude": -83.66870117190001,
//       "Location": "Findley",
//       "Longitude": 41.013500213600004,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7780,
//       "Airport Code [ICAO]": "CZBA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Burlington Executive",
//       "Altitude (Ft)": 602,
//       "Country": "Canada",
//       "Latitude": -79.850102663,
//       "Location": "Burlington",
//       "Longitude": 43.4414531515,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 7782,
//       "Airport Code [ICAO]": "YPSH",
//       "Airport Code iATA,FAA": "PEA",
//       "Airport Name": "Penneshaw Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 137.962875366,
//       "Location": "Penneshaw",
//       "Longitude": -35.7558462874,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Adelaide",
//       "Type": "airport"
//     },
//     {
//       "ID": 7787,
//       "Airport Code [ICAO]": "EBEN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Engels heliport",
//       "Altitude (Ft)": 33,
//       "Country": "Germany",
//       "Latitude": 4.5808329582214355,
//       "Location": "Ebenhofen",
//       "Longitude": 51.211666107177734,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Brussels",
//       "Type": "airport"
//     },
//     {
//       "ID": 7792,
//       "Airport Code [ICAO]": "KEMP",
//       "Airport Code iATA,FAA": "EMP",
//       "Airport Name": "Emporia Municipal Airport",
//       "Altitude (Ft)": 1208,
//       "Country": "Germany",
//       "Latitude": -96.19120025630001,
//       "Location": "Kempten",
//       "Longitude": 38.3320999146,
//       "GMT Offset": "-6",
//       "Daylight Savings": "E",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7800,
//       "Airport Code [ICAO]": "ESSE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Skå-Edeby Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Germany",
//       "Latitude": 17.74049949645996,
//       "Location": "Essen",
//       "Longitude": 59.34510040283203,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 7804,
//       "Airport Code [ICAO]": "EGTB",
//       "Airport Code iATA,FAA": "HYC",
//       "Airport Name": "Wycombe Air Park",
//       "Altitude (Ft)": 515,
//       "Country": "United Kingdom",
//       "Latitude": -0.8083329796790001,
//       "Location": "Wycombe",
//       "Longitude": 51.6116981506,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 7805,
//       "Airport Code [ICAO]": "EGHJ",
//       "Airport Code iATA,FAA": "BBP",
//       "Airport Name": "Bembridge Airport",
//       "Altitude (Ft)": 53,
//       "Country": "United Kingdom",
//       "Latitude": -1.10943996906,
//       "Location": "Bembridge",
//       "Longitude": 50.6781005859,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 7806,
//       "Airport Code [ICAO]": "CCW3",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Waterville / Kings County Municipal Airport",
//       "Altitude (Ft)": 119,
//       "Country": "Canada",
//       "Latitude": -64.6517028809,
//       "Location": "Waterville",
//       "Longitude": 45.0518989563,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Halifax",
//       "Type": "airport"
//     },
//     {
//       "ID": 7809,
//       "Airport Code [ICAO]": "KSPF",
//       "Airport Code iATA,FAA": "SPF",
//       "Airport Name": "Black Hills Airport-Clyde Ice Field",
//       "Altitude (Ft)": 3931,
//       "Country": "United States",
//       "Latitude": -103.78299713135,
//       "Location": "Spearfish-South Dakota",
//       "Longitude": 44.48030090332,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7810,
//       "Airport Code [ICAO]": "EBKW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Westkapelle heliport",
//       "Altitude (Ft)": 10,
//       "Country": "Belgium",
//       "Latitude": 3.2930560112,
//       "Location": "Knokke",
//       "Longitude": 51.32222366333,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": "Europe/Brussels",
//       "Type": "airport"
//     },
//     {
//       "ID": 7811,
//       "Airport Code [ICAO]": "YRED",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Redcliffe Airport",
//       "Altitude (Ft)": 2,
//       "Country": "Australia",
//       "Latitude": 153.0679931640625,
//       "Location": "Rothwell",
//       "Longitude": -27.20669937133789,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 7812,
//       "Airport Code [ICAO]": "EPOK",
//       "Airport Code iATA,FAA": "QYD",
//       "Airport Name": "Oksywie Military Air Base",
//       "Altitude (Ft)": 144,
//       "Country": "Poland",
//       "Latitude": 18.51720047,
//       "Location": "Gdynia",
//       "Longitude": 54.57970047,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 7813,
//       "Airport Code [ICAO]": "EPMB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Malbork Military Air Base",
//       "Altitude (Ft)": 16,
//       "Country": "Poland",
//       "Latitude": 19.134199142499998,
//       "Location": "Malbork",
//       "Longitude": 54.0269012451,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 7814,
//       "Airport Code [ICAO]": "EPLK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lask Military Air Base",
//       "Altitude (Ft)": 633,
//       "Country": "Poland",
//       "Latitude": 19.179100036599998,
//       "Location": "Lask",
//       "Longitude": 51.551700592,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 7815,
//       "Airport Code [ICAO]": "EPMI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Miroslawiec Military Air Base",
//       "Altitude (Ft)": 459,
//       "Country": "Poland",
//       "Latitude": 16.0827999115,
//       "Location": "Miroslawiec",
//       "Longitude": 53.395099639899996,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 7816,
//       "Airport Code [ICAO]": "EPKS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Krzesiny Military Air Base",
//       "Altitude (Ft)": 265,
//       "Country": "Poland",
//       "Latitude": 16.9664001465,
//       "Location": "Poznan",
//       "Longitude": 52.3316993713,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 7817,
//       "Airport Code [ICAO]": "KOLV",
//       "Airport Code iATA,FAA": "OLV",
//       "Airport Name": "Olive Branch Airport",
//       "Altitude (Ft)": 402,
//       "Country": "United States",
//       "Latitude": -89.78690338130001,
//       "Location": "Olive Branch",
//       "Longitude": 34.9786987305,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7818,
//       "Airport Code [ICAO]": "SCVM",
//       "Airport Code iATA,FAA": "KNA",
//       "Airport Name": "Viña del mar Airport",
//       "Altitude (Ft)": 461,
//       "Country": "Chile",
//       "Latitude": -71.4786,
//       "Location": "Vina del Mar",
//       "Longitude": -32.9496,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7820,
//       "Airport Code [ICAO]": "CNC3",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Brampton Airport",
//       "Altitude (Ft)": 935,
//       "Country": "Canada",
//       "Latitude": -79.875,
//       "Location": "Brampton",
//       "Longitude": 43.7602996826,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 7821,
//       "Airport Code [ICAO]": "LTAS",
//       "Airport Code iATA,FAA": "ONQ",
//       "Airport Name": "Zonguldak Airport",
//       "Altitude (Ft)": 39,
//       "Country": "Turkey",
//       "Latitude": 32.0886001587,
//       "Location": "Zonguldak",
//       "Longitude": 41.506401062,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 7822,
//       "Airport Code [ICAO]": "KBJC",
//       "Airport Code iATA,FAA": "BJC",
//       "Airport Name": "Rocky Mountain Metropolitan Airport",
//       "Altitude (Ft)": 5673,
//       "Country": "United States",
//       "Latitude": -105.1169968,
//       "Location": "Broomfield-CO",
//       "Longitude": 39.90879822,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7826,
//       "Airport Code [ICAO]": "KSLE",
//       "Airport Code iATA,FAA": "SLE",
//       "Airport Name": "Salem Municipal Airport/McNary Field",
//       "Altitude (Ft)": 214,
//       "Country": "United States",
//       "Latitude": -123.0029984,
//       "Location": "Salem",
//       "Longitude": 44.90950012,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7827,
//       "Airport Code [ICAO]": "KUTA",
//       "Airport Code iATA,FAA": "UTM",
//       "Airport Name": "Tunica Municipal Airport",
//       "Altitude (Ft)": 194,
//       "Country": "United States",
//       "Latitude": -90.346702575684,
//       "Location": "Tunica",
//       "Longitude": 34.680999755859,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7828,
//       "Airport Code [ICAO]": "UA30",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Batken Airport",
//       "Altitude (Ft)": 3517,
//       "Country": "Kyrgyzstan",
//       "Latitude": 70.83784,
//       "Location": "Batken",
//       "Longitude": 40.042899,
//       "GMT Offset": "6",
//       "Daylight Savings": "E",
//       "Region": "Asia/Bishkek",
//       "Type": "airport"
//     },
//     {
//       "ID": 7830,
//       "Airport Code [ICAO]": "FLKY",
//       "Airport Code iATA,FAA": "ZKB",
//       "Airport Name": "Kasaba Bay Airport",
//       "Altitude (Ft)": 2780,
//       "Country": "Zambia",
//       "Latitude": 30.663000106811523,
//       "Location": "Kasaba Bay",
//       "Longitude": -8.524999618530273,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Lusaka",
//       "Type": "airport"
//     },
//     {
//       "ID": 7831,
//       "Airport Code [ICAO]": "KLND",
//       "Airport Code iATA,FAA": "LND",
//       "Airport Name": "Hunt Field",
//       "Altitude (Ft)": 5586,
//       "Country": "Germany",
//       "Latitude": -108.730003357,
//       "Location": "Lindau",
//       "Longitude": 42.8152008057,
//       "GMT Offset": "-7",
//       "Daylight Savings": "E",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7834,
//       "Airport Code [ICAO]": "EHHO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hoogeveen Airfield",
//       "Altitude (Ft)": 40,
//       "Country": "Netherlands",
//       "Latitude": 6.51611,
//       "Location": "Hoogeveen",
//       "Longitude": 52.730801,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Amsterdam",
//       "Type": "airport"
//     },
//     {
//       "ID": 7835,
//       "Airport Code [ICAO]": "EHTE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Teuge Airport",
//       "Altitude (Ft)": 17,
//       "Country": "Netherlands",
//       "Latitude": 6.04666996002,
//       "Location": "Deventer",
//       "Longitude": 52.2447013855,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Amsterdam",
//       "Type": "airport"
//     },
//     {
//       "ID": 7836,
//       "Airport Code [ICAO]": "EHMZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Midden-Zeeland Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Netherlands",
//       "Latitude": 3.73111009598,
//       "Location": "Middelburg",
//       "Longitude": 51.5121994019,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Amsterdam",
//       "Type": "airport"
//     },
//     {
//       "ID": 7837,
//       "Airport Code [ICAO]": "EHAL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ameland Airfield",
//       "Altitude (Ft)": 11,
//       "Country": "Netherlands",
//       "Latitude": 5.67722,
//       "Location": "Ameland",
//       "Longitude": 53.451698,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Amsterdam",
//       "Type": "airport"
//     },
//     {
//       "ID": 7838,
//       "Airport Code [ICAO]": "LFPZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Saint-Cyr-l'École Airport",
//       "Altitude (Ft)": 371,
//       "Country": "France",
//       "Latitude": 2.0747199058532715,
//       "Location": "Saint-Cyr",
//       "Longitude": 48.8114013671875,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 7839,
//       "Airport Code [ICAO]": "KMWC",
//       "Airport Code iATA,FAA": "MWC",
//       "Airport Name": "Lawrence J Timmerman Airport",
//       "Altitude (Ft)": 745,
//       "Country": "United States",
//       "Latitude": -88.034401,
//       "Location": "Milwaukee",
//       "Longitude": 43.110401,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7840,
//       "Airport Code [ICAO]": "KJVL",
//       "Airport Code iATA,FAA": "JVL",
//       "Airport Name": "Southern Wisconsin Regional Airport",
//       "Altitude (Ft)": 808,
//       "Country": "United States",
//       "Latitude": -89.0416030884,
//       "Location": "Janesville",
//       "Longitude": 42.620300293,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7841,
//       "Airport Code [ICAO]": "FXMN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mantsonyane Airport",
//       "Altitude (Ft)": 7100,
//       "Country": "Lesotho",
//       "Latitude": 28.270999908447266,
//       "Location": "Mantsonyane",
//       "Longitude": -29.546100616455078,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Maseru",
//       "Type": "airport"
//     },
//     {
//       "ID": 7846,
//       "Airport Code [ICAO]": "KGKY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Arlington Municipal Airport",
//       "Altitude (Ft)": 628,
//       "Country": "United States",
//       "Latitude": -97.09429931640625,
//       "Location": "Arlington",
//       "Longitude": 32.66389846801758,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7847,
//       "Airport Code [ICAO]": "KLZU",
//       "Airport Code iATA,FAA": "LZU",
//       "Airport Name": "Gwinnett County Briscoe Field",
//       "Altitude (Ft)": 1061,
//       "Country": "United States",
//       "Latitude": -83.96240234,
//       "Location": "Lawrenceville",
//       "Longitude": 33.97809982,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7848,
//       "Airport Code [ICAO]": "KBWG",
//       "Airport Code iATA,FAA": "BWG",
//       "Airport Name": "Bowling Green Warren County Regional Airport",
//       "Altitude (Ft)": 547,
//       "Country": "United States",
//       "Latitude": -86.41970062259999,
//       "Location": "Bowling Green",
//       "Longitude": 36.964500427199994,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7849,
//       "Airport Code [ICAO]": "KRVS",
//       "Airport Code iATA,FAA": "RVS",
//       "Airport Name": "Richard Lloyd Jones Jr Airport",
//       "Altitude (Ft)": 638,
//       "Country": "United States",
//       "Latitude": -95.984596252441,
//       "Location": "Tulsa",
//       "Longitude": 36.039600372314,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7853,
//       "Airport Code [ICAO]": "OMDM",
//       "Airport Code iATA,FAA": "NHD",
//       "Airport Name": "Al Minhad Air Base",
//       "Altitude (Ft)": 165,
//       "Country": "United Arab Emirates",
//       "Latitude": 55.3661994934,
//       "Location": "Minhad AB",
//       "Longitude": 25.0268001556,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dubai",
//       "Type": "airport"
//     },
//     {
//       "ID": 7854,
//       "Airport Code [ICAO]": "UKKG",
//       "Airport Code iATA,FAA": "KGO",
//       "Airport Name": "Kirovograd Airport",
//       "Altitude (Ft)": 568,
//       "Country": "Ukraine",
//       "Latitude": 32.28499984741211,
//       "Location": "Kirovograd",
//       "Longitude": 48.54280090332031,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 7855,
//       "Airport Code [ICAO]": "EDAW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Roitzschjora Airfield",
//       "Altitude (Ft)": 289,
//       "Country": "Germany",
//       "Latitude": 12.494444,
//       "Location": "Roitzschjora",
//       "Longitude": 51.577778,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 7856,
//       "Airport Code [ICAO]": "HEAL",
//       "Airport Code iATA,FAA": "DBB",
//       "Airport Name": "El Alamein International Airport",
//       "Altitude (Ft)": 143,
//       "Country": "Egypt",
//       "Latitude": 28.46139907836914,
//       "Location": "Dabaa City",
//       "Longitude": 30.92449951171875,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Cairo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7857,
//       "Airport Code [ICAO]": "KBCE",
//       "Airport Code iATA,FAA": "BCE",
//       "Airport Name": "Bryce Canyon Airport",
//       "Altitude (Ft)": 7590,
//       "Country": "United States",
//       "Latitude": -112.144996643,
//       "Location": "Bryce Canyon",
//       "Longitude": 37.706401825,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7859,
//       "Airport Code [ICAO]": "KBUY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Burlington Alamance Regional Airport",
//       "Altitude (Ft)": 617,
//       "Country": "United States",
//       "Latitude": -79.47489929199219,
//       "Location": "Burlington",
//       "Longitude": 36.048500061035156,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7861,
//       "Airport Code [ICAO]": "UUMU",
//       "Airport Code iATA,FAA": "CKL",
//       "Airport Name": "Chkalovskiy Air Base",
//       "Altitude (Ft)": 499,
//       "Country": "Russia",
//       "Latitude": 38.061699,
//       "Location": "Shchyolkovo",
//       "Longitude": 55.8783,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 7862,
//       "Airport Code [ICAO]": "ZUTC",
//       "Airport Code iATA,FAA": "TCZ",
//       "Airport Name": "Tengchong Tuofeng Airport",
//       "Altitude (Ft)": 6250,
//       "Country": "China",
//       "Latitude": 98.48583333330001,
//       "Location": "Tengchong",
//       "Longitude": 24.9380555556,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 7863,
//       "Airport Code [ICAO]": "UKFB",
//       "Airport Code iATA,FAA": "UKS",
//       "Airport Name": "Belbek Airport",
//       "Altitude (Ft)": 344,
//       "Country": "Ukraine",
//       "Latitude": 33.570999145500004,
//       "Location": "Sevastopol",
//       "Longitude": 44.688999176,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Europe/Simferopol",
//       "Type": "airport"
//     },
//     {
//       "ID": 7867,
//       "Airport Code [ICAO]": "EHDP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "De Peel Air Base",
//       "Altitude (Ft)": 98,
//       "Country": "Netherlands",
//       "Latitude": 5.855720043182373,
//       "Location": "Deurne",
//       "Longitude": 51.51729965209961,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Amsterdam",
//       "Type": "airport"
//     },
//     {
//       "ID": 7868,
//       "Airport Code [ICAO]": "OAZI",
//       "Airport Code iATA,FAA": "OAZ",
//       "Airport Name": "Camp Bastion Airport",
//       "Altitude (Ft)": 2943,
//       "Country": "Afghanistan",
//       "Latitude": 64.2246017456,
//       "Location": "Camp Bastion",
//       "Longitude": 31.863800048799998,
//       "GMT Offset": "4.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kabul",
//       "Type": "airport"
//     },
//     {
//       "ID": 7869,
//       "Airport Code [ICAO]": "KIXD",
//       "Airport Code iATA,FAA": "JCI",
//       "Airport Name": "New Century Aircenter Airport",
//       "Altitude (Ft)": 1087,
//       "Country": "United States",
//       "Latitude": -94.890296936,
//       "Location": "Olathe",
//       "Longitude": 38.8308982849,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7870,
//       "Airport Code [ICAO]": "KESN",
//       "Airport Code iATA,FAA": "ESN",
//       "Airport Name": "Easton Newnam Field",
//       "Altitude (Ft)": 72,
//       "Country": "United States",
//       "Latitude": -76.06900024410001,
//       "Location": "Easton",
//       "Longitude": 38.8041992188,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7871,
//       "Airport Code [ICAO]": "ENHA",
//       "Airport Code iATA,FAA": "HMR",
//       "Airport Name": "Stafsberg Airport",
//       "Altitude (Ft)": 713,
//       "Country": "Norway",
//       "Latitude": 11.067999839782715,
//       "Location": "Hamar",
//       "Longitude": 60.81809997558594,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7872,
//       "Airport Code [ICAO]": "ENRI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ringebu Airfield Frya",
//       "Altitude (Ft)": 571,
//       "Country": "Norway",
//       "Latitude": 10.061588287,
//       "Location": "Frya",
//       "Longitude": 61.545440673,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7875,
//       "Airport Code [ICAO]": "KMYV",
//       "Airport Code iATA,FAA": "MYV",
//       "Airport Name": "Yuba County Airport",
//       "Altitude (Ft)": 64,
//       "Country": "United States",
//       "Latitude": -121.5699997,
//       "Location": "Yuba City",
//       "Longitude": 39.09780121,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7879,
//       "Airport Code [ICAO]": "YPID",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Phillip Island Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Australia",
//       "Latitude": 145.32699584960938,
//       "Location": "Phillip Island",
//       "Longitude": -38.52330017089844,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Hobart",
//       "Type": "airport"
//     },
//     {
//       "ID": 7880,
//       "Airport Code [ICAO]": "KDUC",
//       "Airport Code iATA,FAA": "DUC",
//       "Airport Name": "Halliburton Field",
//       "Altitude (Ft)": 1114,
//       "Country": "United States",
//       "Latitude": -97.9598999,
//       "Location": "Duncan",
//       "Longitude": 34.47090149,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7885,
//       "Airport Code [ICAO]": "KUVA",
//       "Airport Code iATA,FAA": "UVA",
//       "Airport Name": "Garner Field",
//       "Altitude (Ft)": 942,
//       "Country": "United States",
//       "Latitude": -99.743598938,
//       "Location": "Uvalde",
//       "Longitude": 29.2112998962,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7886,
//       "Airport Code [ICAO]": "KLOT",
//       "Airport Code iATA,FAA": "LOT",
//       "Airport Name": "Lewis University Airport",
//       "Altitude (Ft)": 679,
//       "Country": "United States",
//       "Latitude": -88.09619904,
//       "Location": "Lockport",
//       "Longitude": 41.6072998,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7888,
//       "Airport Code [ICAO]": "KCCR",
//       "Airport Code iATA,FAA": "CCR",
//       "Airport Name": "Buchanan Field",
//       "Altitude (Ft)": 26,
//       "Country": "United States",
//       "Latitude": -122.056999207,
//       "Location": "Concord",
//       "Longitude": 37.9897003174,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7889,
//       "Airport Code [ICAO]": "07FA",
//       "Airport Code iATA,FAA": "OCA",
//       "Airport Name": "Ocean Reef Club Airport",
//       "Altitude (Ft)": 8,
//       "Country": "United States",
//       "Latitude": -80.274803161621,
//       "Location": "Ocean Reef Club Airport",
//       "Longitude": 25.325399398804,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7892,
//       "Airport Code [ICAO]": "LFGC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Strasbourg Neuhof Airfield",
//       "Altitude (Ft)": 456,
//       "Country": "France",
//       "Latitude": 7.77806,
//       "Location": "Strasbourg Neudorf",
//       "Longitude": 48.554401,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 7893,
//       "Airport Code [ICAO]": "EDRN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nannhausen Airport",
//       "Altitude (Ft)": 1224,
//       "Country": "Germany",
//       "Latitude": 7.47916698456,
//       "Location": "Nannhausen",
//       "Longitude": 49.9702796936,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 7894,
//       "Airport Code [ICAO]": "ZYLS",
//       "Airport Code iATA,FAA": "YUS",
//       "Airport Name": "Yushu Batang Airport",
//       "Altitude (Ft)": 12816,
//       "Country": "China",
//       "Latitude": 97.0363888889,
//       "Location": "Yushu",
//       "Longitude": 32.836388888900004,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 7898,
//       "Airport Code [ICAO]": "ZSSH",
//       "Airport Code iATA,FAA": "HIA",
//       "Airport Name": "Lianshui Airport",
//       "Altitude (Ft)": 28,
//       "Country": "China",
//       "Latitude": 119.125,
//       "Location": "Huai An",
//       "Longitude": 33.7908333333,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 7902,
//       "Airport Code [ICAO]": "CYOO",
//       "Airport Code iATA,FAA": "YOO",
//       "Airport Name": "Toronto/Oshawa Executive Airport",
//       "Altitude (Ft)": 460,
//       "Country": "Canada",
//       "Latitude": -78.894997,
//       "Location": "Oshawa",
//       "Longitude": 43.922798,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 7903,
//       "Airport Code [ICAO]": "EDLM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Marl-Loemühle Airfield",
//       "Altitude (Ft)": 240,
//       "Country": "Germany",
//       "Latitude": 7.16333,
//       "Location": "Recklinghausen",
//       "Longitude": 51.647202,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 7904,
//       "Airport Code [ICAO]": "ESNF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Färila Air Base",
//       "Altitude (Ft)": 0,
//       "Country": "Sweden",
//       "Latitude": 15.705300331116,
//       "Location": "Farila",
//       "Longitude": 61.897998809814,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 7905,
//       "Airport Code [ICAO]": "EDTL",
//       "Airport Code iATA,FAA": "LHA",
//       "Airport Name": "Lahr Airport",
//       "Altitude (Ft)": 511,
//       "Country": "Germany",
//       "Latitude": 7.82772016525,
//       "Location": "Lahr",
//       "Longitude": 48.3693008423,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 7906,
//       "Airport Code [ICAO]": "VYMY",
//       "Airport Code iATA,FAA": "NYW",
//       "Airport Name": "Monywar Airport",
//       "Altitude (Ft)": 298,
//       "Country": "Burma",
//       "Latitude": 95.093479,
//       "Location": "Monywa",
//       "Longitude": 22.221638,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 7907,
//       "Airport Code [ICAO]": "KUNI",
//       "Airport Code iATA,FAA": "ATO",
//       "Airport Name": "Ohio University Snyder Field",
//       "Altitude (Ft)": 766,
//       "Country": "United States",
//       "Latitude": -82.23139953610001,
//       "Location": "Athens",
//       "Longitude": 39.2109985352,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7908,
//       "Airport Code [ICAO]": "KSGH",
//       "Airport Code iATA,FAA": "SGH",
//       "Airport Name": "Springfield-Beckley Municipal Airport",
//       "Altitude (Ft)": 1051,
//       "Country": "United States",
//       "Latitude": -83.840202331543,
//       "Location": "Springfield",
//       "Longitude": 39.840301513672,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7909,
//       "Airport Code [ICAO]": null,
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sun Island Resort and SPA",
//       "Altitude (Ft)": 0,
//       "Country": "Maldives",
//       "Latitude": 72.862989,
//       "Location": "South Aari Atoll",
//       "Longitude": 3.488334,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7910,
//       "Airport Code [ICAO]": "GMFU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Fes Sefrou Airport",
//       "Altitude (Ft)": 1539,
//       "Country": "Morocco",
//       "Latitude": -4.965559959411621,
//       "Location": "Fes",
//       "Longitude": 34.00809860229492,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": "Africa/Casablanca",
//       "Type": "airport"
//     },
//     {
//       "ID": 7911,
//       "Airport Code [ICAO]": "MDHE",
//       "Airport Code iATA,FAA": "HEX",
//       "Airport Name": "Herrera Airport",
//       "Altitude (Ft)": 190,
//       "Country": "Dominican Republic",
//       "Latitude": -69.9693984985,
//       "Location": "Santo Domingo",
//       "Longitude": 18.4696998596,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Santo_Domingo",
//       "Type": "airport"
//     },
//     {
//       "ID": 7912,
//       "Airport Code [ICAO]": "YCOO",
//       "Airport Code iATA,FAA": "CDA",
//       "Airport Name": "Cooinda Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Australia",
//       "Latitude": 132.53199768066406,
//       "Location": "Cooinda",
//       "Longitude": -12.903300285339355,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "U",
//       "Region": "Australia/Darwin",
//       "Type": "airport"
//     },
//     {
//       "ID": 7913,
//       "Airport Code [ICAO]": "YJAB",
//       "Airport Code iATA,FAA": "JAB",
//       "Airport Name": "Jabiru Airport",
//       "Altitude (Ft)": 85,
//       "Country": "Australia",
//       "Latitude": 132.89300537109375,
//       "Location": "Jabiru",
//       "Longitude": -12.658300399780273,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "U",
//       "Region": "Australia/Darwin",
//       "Type": "airport"
//     },
//     {
//       "ID": 7918,
//       "Airport Code [ICAO]": "EDNR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Regensburg-Oberhub Airport",
//       "Altitude (Ft)": 1299,
//       "Country": "Germany",
//       "Latitude": 12.081944465637207,
//       "Location": "Regensburg",
//       "Longitude": 49.141944885253906,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 7924,
//       "Airport Code [ICAO]": "GFHA",
//       "Airport Code iATA,FAA": "HGS",
//       "Airport Name": "Hastings Airport",
//       "Altitude (Ft)": 60,
//       "Country": "Sierra Leone",
//       "Latitude": -13.12909984588623,
//       "Location": "Freetown",
//       "Longitude": 8.397130012512207,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Freetown",
//       "Type": "airport"
//     },
//     {
//       "ID": 7925,
//       "Airport Code [ICAO]": "KTOP",
//       "Airport Code iATA,FAA": "TOP",
//       "Airport Name": "Philip Billard Municipal Airport",
//       "Altitude (Ft)": 881,
//       "Country": "United States",
//       "Latitude": -95.622497558594,
//       "Location": "Topeka",
//       "Longitude": 39.068698883057,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7928,
//       "Airport Code [ICAO]": "2XS8",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Benson Airstrip",
//       "Altitude (Ft)": 929,
//       "Country": "United States",
//       "Latitude": -99.82389831542969,
//       "Location": "Uvalde",
//       "Longitude": 29.229400634765625,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7929,
//       "Airport Code [ICAO]": "K2I3",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Rough River State Park Airport",
//       "Altitude (Ft)": 577,
//       "Country": "United States",
//       "Latitude": -86.507262,
//       "Location": null,
//       "Longitude": 37.610022,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7930,
//       "Airport Code [ICAO]": "KMQY",
//       "Airport Code iATA,FAA": "MQY",
//       "Airport Name": "Smyrna Airport",
//       "Altitude (Ft)": 543,
//       "Country": "United States",
//       "Latitude": -86.5201034546,
//       "Location": "Smyrna",
//       "Longitude": 36.0089988708,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7931,
//       "Airport Code [ICAO]": "KUOS",
//       "Airport Code iATA,FAA": "UOS",
//       "Airport Name": "Franklin County Airport",
//       "Altitude (Ft)": 1953,
//       "Country": "United States",
//       "Latitude": -85.898101806641,
//       "Location": "Sewanee",
//       "Longitude": 35.205101013184,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7932,
//       "Airport Code [ICAO]": "ZUAL",
//       "Airport Code iATA,FAA": "NGQ",
//       "Airport Name": "Ngari Gunsa Airport",
//       "Altitude (Ft)": 14022,
//       "Country": "China",
//       "Latitude": 80.0530555556,
//       "Location": "Shiquanhe",
//       "Longitude": 32.1,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 7933,
//       "Airport Code [ICAO]": "EDBC",
//       "Airport Code iATA,FAA": "CSO",
//       "Airport Name": "Cochstedt Airport",
//       "Altitude (Ft)": 594,
//       "Country": "Germany",
//       "Latitude": 11.42029953,
//       "Location": "Cochstedt",
//       "Longitude": 51.8563995361,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 7935,
//       "Airport Code [ICAO]": "KTKI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Collin County Regional At Mc Kinney Airport",
//       "Altitude (Ft)": 585,
//       "Country": "United States",
//       "Latitude": -96.59049988,
//       "Location": "DALLAS",
//       "Longitude": 33.17789841,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7936,
//       "Airport Code [ICAO]": "KPWK",
//       "Airport Code iATA,FAA": "PWK",
//       "Airport Name": "Chicago Executive Airport",
//       "Altitude (Ft)": 647,
//       "Country": "United States",
//       "Latitude": -87.901494,
//       "Location": "Chicago-Wheeling",
//       "Longitude": 42.114222,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7938,
//       "Airport Code [ICAO]": "KKLS",
//       "Airport Code iATA,FAA": "KLS",
//       "Airport Name": "Southwest Washington Regional Airport",
//       "Altitude (Ft)": 20,
//       "Country": "United States",
//       "Latitude": -122.898002625,
//       "Location": "Kelso",
//       "Longitude": 46.11800003049999,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 7939,
//       "Airport Code [ICAO]": "LKBE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Benešov Airport",
//       "Altitude (Ft)": 1319,
//       "Country": "Czech Republic",
//       "Latitude": 14.644700050354004,
//       "Location": "Benesov",
//       "Longitude": 49.74079895019531,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": "Europe/Prague",
//       "Type": "airport"
//     },
//     {
//       "ID": 7941,
//       "Airport Code [ICAO]": "GABG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bougouni Airport",
//       "Altitude (Ft)": 1139,
//       "Country": "Mali",
//       "Latitude": -7.517000198364258,
//       "Location": "Bougouni",
//       "Longitude": 11.449999809265137,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Bamako",
//       "Type": "airport"
//     },
//     {
//       "ID": 7946,
//       "Airport Code [ICAO]": "NTGY",
//       "Airport Code iATA,FAA": "ZTA",
//       "Airport Name": "Tureia Airport",
//       "Altitude (Ft)": 12,
//       "Country": "French Polynesia",
//       "Latitude": -138.57000732421875,
//       "Location": "Tureia",
//       "Longitude": -20.78969955444336,
//       "GMT Offset": "-10",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 7947,
//       "Airport Code [ICAO]": "NZIR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "McMurdo Station Ice Runway",
//       "Altitude (Ft)": 1,
//       "Country": "Antarctica",
//       "Latitude": 166.468994140625,
//       "Location": "Ross Island",
//       "Longitude": -77.85399627685547,
//       "GMT Offset": "12",
//       "Daylight Savings": "N",
//       "Region": "Antarctica/South_Pole",
//       "Type": "airport"
//     },
//     {
//       "ID": 7948,
//       "Airport Code [ICAO]": "HKKE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Keekorok Airport",
//       "Altitude (Ft)": 5800,
//       "Country": "Kenya",
//       "Latitude": 35.25,
//       "Location": "Keekorok",
//       "Longitude": -1.5829999446868896,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 7962,
//       "Airport Code [ICAO]": "MPOA",
//       "Airport Code iATA,FAA": "PUE",
//       "Airport Name": "Puerto Obaldia Airport",
//       "Altitude (Ft)": 223,
//       "Country": "Panama",
//       "Latitude": -77.418,
//       "Location": "Puerto Obaldia",
//       "Longitude": 8.667,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Panama",
//       "Type": "airport"
//     },
//     {
//       "ID": 7963,
//       "Airport Code [ICAO]": "UKFK",
//       "Airport Code iATA,FAA": "KHC",
//       "Airport Name": "Kerch Airport",
//       "Altitude (Ft)": 171,
//       "Country": "Ukraine",
//       "Latitude": 36.40140151977539,
//       "Location": "Kerch",
//       "Longitude": 45.372501373291016,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Simferopol",
//       "Type": "airport"
//     },
//     {
//       "ID": 7976,
//       "Airport Code [ICAO]": "HKUK",
//       "Airport Code iATA,FAA": "UKA",
//       "Airport Name": "Ukunda Airstrip",
//       "Altitude (Ft)": 98,
//       "Country": "Kenya",
//       "Latitude": 39.571098,
//       "Location": "Ukunda",
//       "Longitude": -4.29333,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 7978,
//       "Airport Code [ICAO]": "KILN",
//       "Airport Code iATA,FAA": "ILN",
//       "Airport Name": "Wilmington Airpark",
//       "Altitude (Ft)": 1077,
//       "Country": "United States",
//       "Latitude": -83.792098999,
//       "Location": "Wilmington",
//       "Longitude": 39.427898407,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7979,
//       "Airport Code [ICAO]": "KAVQ",
//       "Airport Code iATA,FAA": "AVW",
//       "Airport Name": "Marana Regional Airport",
//       "Altitude (Ft)": 2031,
//       "Country": "United States",
//       "Latitude": -111.218002319,
//       "Location": "Tucson",
//       "Longitude": 32.4095993042,
//       "GMT Offset": "-7",
//       "Daylight Savings": "U",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 7980,
//       "Airport Code [ICAO]": "KCGZ",
//       "Airport Code iATA,FAA": "CGZ",
//       "Airport Name": "Casa Grande Municipal Airport",
//       "Altitude (Ft)": 1464,
//       "Country": "United States",
//       "Latitude": -111.766998,
//       "Location": "Casa Grande",
//       "Longitude": 32.954899,
//       "GMT Offset": "-7",
//       "Daylight Savings": "U",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 7981,
//       "Airport Code [ICAO]": "1AZ0",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mobile Airport",
//       "Altitude (Ft)": 1261,
//       "Country": "United States",
//       "Latitude": -112.26899719238281,
//       "Location": "Mobile",
//       "Longitude": 33.111900329589844,
//       "GMT Offset": "-7",
//       "Daylight Savings": "U",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 7982,
//       "Airport Code [ICAO]": "KBXK",
//       "Airport Code iATA,FAA": "BXK",
//       "Airport Name": "Buckeye Municipal Airport",
//       "Altitude (Ft)": 1033,
//       "Country": "United States",
//       "Latitude": -112.685997,
//       "Location": "Buckeye",
//       "Longitude": 33.42039871,
//       "GMT Offset": "-7",
//       "Daylight Savings": "U",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 7983,
//       "Airport Code [ICAO]": "KE63",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Gila Bend Municipal Airport",
//       "Altitude (Ft)": 789,
//       "Country": "United States",
//       "Latitude": -112.6780014,
//       "Location": "Gila Bend",
//       "Longitude": 32.95809937,
//       "GMT Offset": "-7",
//       "Daylight Savings": "U",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 7984,
//       "Airport Code [ICAO]": "KMMI",
//       "Airport Code iATA,FAA": "MMI",
//       "Airport Name": "McMinn County Airport",
//       "Altitude (Ft)": 875,
//       "Country": "United States",
//       "Latitude": -84.56259918,
//       "Location": "Athens",
//       "Longitude": 35.39730072,
//       "GMT Offset": "-5",
//       "Daylight Savings": "N",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7985,
//       "Airport Code [ICAO]": "KSTK",
//       "Airport Code iATA,FAA": "STK",
//       "Airport Name": "Sterling Municipal Airport",
//       "Altitude (Ft)": 4040,
//       "Country": "United States",
//       "Latitude": -103.2649994,
//       "Location": "Sterling",
//       "Longitude": 40.61529922,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7986,
//       "Airport Code [ICAO]": "KRWL",
//       "Airport Code iATA,FAA": "RWL",
//       "Airport Name": "Rawlins Municipal Airport/Harvey Field",
//       "Altitude (Ft)": 6813,
//       "Country": "United States",
//       "Latitude": -107.1999969,
//       "Location": "Rawlins",
//       "Longitude": 41.80559921,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7987,
//       "Airport Code [ICAO]": "CYZY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mackenzie Airport",
//       "Altitude (Ft)": 2264,
//       "Country": "Canada",
//       "Latitude": -123.132004,
//       "Location": "Mackenzie British Columbia",
//       "Longitude": 55.304402,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 7990,
//       "Airport Code [ICAO]": "KCDW",
//       "Airport Code iATA,FAA": "CDW",
//       "Airport Name": "Essex County Airport",
//       "Altitude (Ft)": 173,
//       "Country": "United States",
//       "Latitude": -74.2814025879,
//       "Location": "Caldwell",
//       "Longitude": 40.875198364300005,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7991,
//       "Airport Code [ICAO]": "KAIZ",
//       "Airport Code iATA,FAA": "AIZ",
//       "Airport Name": "Lee C Fine Memorial Airport",
//       "Altitude (Ft)": 869,
//       "Country": "United States",
//       "Latitude": -92.54949951170002,
//       "Location": "Kaiser Lake Ozark",
//       "Longitude": 38.0960006714,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 7998,
//       "Airport Code [ICAO]": "KTVI",
//       "Airport Code iATA,FAA": "TVI",
//       "Airport Name": "Thomasville Regional Airport",
//       "Altitude (Ft)": 264,
//       "Country": "United States",
//       "Latitude": -83.881301879883,
//       "Location": "Thomasville",
//       "Longitude": 30.901599884033,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 7999,
//       "Airport Code [ICAO]": "KHND",
//       "Airport Code iATA,FAA": "HSH",
//       "Airport Name": "Henderson Executive Airport",
//       "Altitude (Ft)": 2492,
//       "Country": "United States",
//       "Latitude": -115.134002686,
//       "Location": "Henderson",
//       "Longitude": 35.9728012085,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 8000,
//       "Airport Code [ICAO]": "UKKM",
//       "Airport Code iATA,FAA": "GML",
//       "Airport Name": "Gostomel Airport",
//       "Altitude (Ft)": 517,
//       "Country": "Ukraine",
//       "Latitude": 30.1919002532959,
//       "Location": "Kiev",
//       "Longitude": 50.60350036621094,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 8005,
//       "Airport Code [ICAO]": "KTMA",
//       "Airport Code iATA,FAA": "TMA",
//       "Airport Name": "Henry Tift Myers Airport",
//       "Altitude (Ft)": 355,
//       "Country": "United States",
//       "Latitude": -83.4885025024,
//       "Location": "Tifton",
//       "Longitude": 31.4290008545,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8006,
//       "Airport Code [ICAO]": "EDML",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Landshut Airport",
//       "Altitude (Ft)": 1312,
//       "Country": "Germany",
//       "Latitude": 12.033332824707031,
//       "Location": "Landshut",
//       "Longitude": 48.51166534423828,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8007,
//       "Airport Code [ICAO]": "EDHF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Itzehoe/Hungriger Wolf Airport",
//       "Altitude (Ft)": 89,
//       "Country": "Germany",
//       "Latitude": 9.578611373901367,
//       "Location": "Itzehoe",
//       "Longitude": 53.99444580078125,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8029,
//       "Airport Code [ICAO]": "EPRA",
//       "Airport Code iATA,FAA": "RDO",
//       "Airport Name": "Radom Airport",
//       "Altitude (Ft)": 610,
//       "Country": "Poland",
//       "Latitude": 21.213300705,
//       "Location": "RADOM",
//       "Longitude": 51.3891983032,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 8030,
//       "Airport Code [ICAO]": "KDVT",
//       "Airport Code iATA,FAA": "DVT",
//       "Airport Name": "Phoenix Deer Valley Airport",
//       "Altitude (Ft)": 1478,
//       "Country": "United States",
//       "Latitude": -112.083000183,
//       "Location": "Phoenix ",
//       "Longitude": 33.6883010864,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 8031,
//       "Airport Code [ICAO]": "CYBW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Calgary / Springbank Airport",
//       "Altitude (Ft)": 3940,
//       "Country": "Canada",
//       "Latitude": -114.374000549,
//       "Location": "Calgary",
//       "Longitude": 51.103099823,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 8032,
//       "Airport Code [ICAO]": "CYGE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Golden Airport",
//       "Altitude (Ft)": 2575,
//       "Country": "Canada",
//       "Latitude": -116.982002,
//       "Location": "Golden",
//       "Longitude": 51.299196,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 8033,
//       "Airport Code [ICAO]": "CYRV",
//       "Airport Code iATA,FAA": "YRV",
//       "Airport Name": "Revelstoke Airport",
//       "Altitude (Ft)": 1459,
//       "Country": "Canada",
//       "Latitude": -118.182998657,
//       "Location": "Revelstoke",
//       "Longitude": 50.9667015076,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 8034,
//       "Airport Code [ICAO]": "KFRG",
//       "Airport Code iATA,FAA": "FRG",
//       "Airport Name": "Republic Airport",
//       "Altitude (Ft)": 82,
//       "Country": "United States",
//       "Latitude": -73.4133987427,
//       "Location": "Farmingdale",
//       "Longitude": 40.7288017273,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8035,
//       "Airport Code [ICAO]": "EDBT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Allstedt Airport",
//       "Altitude (Ft)": 932,
//       "Country": "Germany",
//       "Latitude": 11.446666717529297,
//       "Location": "Allstedt",
//       "Longitude": 51.38055419921875,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8038,
//       "Airport Code [ICAO]": "SCIC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "General Freire Airport",
//       "Altitude (Ft)": 722,
//       "Country": "Chile",
//       "Latitude": -71.21639251708984,
//       "Location": "Curico",
//       "Longitude": -34.96666717529297,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8039,
//       "Airport Code [ICAO]": "EDVP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Peine-Eddesse Airport",
//       "Altitude (Ft)": 249,
//       "Country": "Germany",
//       "Latitude": 10.228889465299998,
//       "Location": "Peine",
//       "Longitude": 52.402500152600005,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8042,
//       "Airport Code [ICAO]": "KHDO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "South Texas Regional Airport at Hondo",
//       "Altitude (Ft)": 930,
//       "Country": "United States",
//       "Latitude": -99.176696777344,
//       "Location": "Hondo",
//       "Longitude": 29.35950088501,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8043,
//       "Airport Code [ICAO]": "ZLZW",
//       "Airport Code iATA,FAA": "ZHY",
//       "Airport Name": "Zhongwei Shapotou Airport",
//       "Altitude (Ft)": 8202,
//       "Country": "China",
//       "Latitude": 105.154454,
//       "Location": "Zhongwei",
//       "Longitude": 37.573125,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 8050,
//       "Airport Code [ICAO]": "PAIN",
//       "Airport Code iATA,FAA": "MCL",
//       "Airport Name": "McKinley National Park Airport",
//       "Altitude (Ft)": 1720,
//       "Country": "United States",
//       "Latitude": -148.910995483,
//       "Location": "McKinley Park",
//       "Longitude": 63.7326011658,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 8051,
//       "Airport Code [ICAO]": "PALH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lake Hood Airport",
//       "Altitude (Ft)": 73,
//       "Country": "United States",
//       "Latitude": -149.965442,
//       "Location": "Anchorage",
//       "Longitude": 61.186946,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 8052,
//       "Airport Code [ICAO]": "PAPR",
//       "Airport Code iATA,FAA": "PPC",
//       "Airport Name": "Prospect Creek Airport",
//       "Altitude (Ft)": 1095,
//       "Country": "United States",
//       "Latitude": -150.64399719238,
//       "Location": "Prospect Creek",
//       "Longitude": 66.814102172852,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 8053,
//       "Airport Code [ICAO]": "FBKR",
//       "Airport Code iATA,FAA": "KHW",
//       "Airport Name": "Khwai River Lodge Airport",
//       "Altitude (Ft)": 3000,
//       "Country": "Botswana",
//       "Latitude": 23.783000946044922,
//       "Location": "Khwai River",
//       "Longitude": -19.149999618530273,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Gaborone",
//       "Type": "airport"
//     },
//     {
//       "ID": 8054,
//       "Airport Code [ICAO]": "EDCY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Spremberg-Welzow Airport",
//       "Altitude (Ft)": 374,
//       "Country": "Germany",
//       "Latitude": 14.136943817138672,
//       "Location": "Welzow",
//       "Longitude": 51.575557708740234,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8055,
//       "Airport Code [ICAO]": "RCLG",
//       "Airport Code iATA,FAA": "TXG",
//       "Airport Name": "Taichung Airport",
//       "Altitude (Ft)": 369,
//       "Country": "Taiwan",
//       "Latitude": 120.65399932861328,
//       "Location": "Taichung",
//       "Longitude": 24.18630027770996,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Taipei",
//       "Type": "airport"
//     },
//     {
//       "ID": 8062,
//       "Airport Code [ICAO]": "KHLG",
//       "Airport Code iATA,FAA": "HLG",
//       "Airport Name": "Wheeling Ohio County Airport",
//       "Altitude (Ft)": 1195,
//       "Country": "United States",
//       "Latitude": -80.6463012695,
//       "Location": "Wheeling",
//       "Longitude": 40.1749992371,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8063,
//       "Airport Code [ICAO]": "KFZG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Fitzgerald Municipal Airport",
//       "Altitude (Ft)": 365,
//       "Country": "United States",
//       "Latitude": -83.27050018310547,
//       "Location": "Fitzgerald",
//       "Longitude": 31.683700561523438,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8069,
//       "Airport Code [ICAO]": "VYYE",
//       "Airport Code iATA,FAA": "XYE",
//       "Airport Name": "Ye Airport",
//       "Altitude (Ft)": 30,
//       "Country": "Burma",
//       "Latitude": 97.86699676513672,
//       "Location": "Ye",
//       "Longitude": 15.300000190734863,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 8070,
//       "Airport Code [ICAO]": "SCFX",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Isla San Felix Airport",
//       "Altitude (Ft)": 165,
//       "Country": "Chile",
//       "Latitude": -80.09619903564453,
//       "Location": "Isla San Felix",
//       "Longitude": -26.293899536132812,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8075,
//       "Airport Code [ICAO]": "OESB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Shaibah Airport",
//       "Altitude (Ft)": 300,
//       "Country": "Saudi Arabia",
//       "Latitude": 53.9640998840332,
//       "Location": "Shaybah",
//       "Longitude": 22.51449966430664,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 8076,
//       "Airport Code [ICAO]": "OMDW",
//       "Airport Code iATA,FAA": "DWC",
//       "Airport Name": "Al Maktoum International Airport",
//       "Altitude (Ft)": 114,
//       "Country": "United Arab Emirates",
//       "Latitude": 55.161389,
//       "Location": "Dubai",
//       "Longitude": 24.896356,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dubai",
//       "Type": "airport"
//     },
//     {
//       "ID": 8077,
//       "Airport Code [ICAO]": "KRKP",
//       "Airport Code iATA,FAA": "RKP",
//       "Airport Name": "Aransas County Airport",
//       "Altitude (Ft)": 24,
//       "Country": "United States",
//       "Latitude": -97.0446014404,
//       "Location": "Rockport",
//       "Longitude": 28.0867996216,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8079,
//       "Airport Code [ICAO]": "LFHM",
//       "Airport Code iATA,FAA": "MVV",
//       "Airport Name": "Megève Airport",
//       "Altitude (Ft)": 4823,
//       "Country": "France",
//       "Latitude": 6.652219772338867,
//       "Location": "Verdun",
//       "Longitude": 45.82080078125,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 8080,
//       "Airport Code [ICAO]": "LFKX",
//       "Airport Code iATA,FAA": "MFX",
//       "Airport Name": "Méribel Altiport",
//       "Altitude (Ft)": 5636,
//       "Country": "France",
//       "Latitude": 6.577942,
//       "Location": "Ajaccio",
//       "Longitude": 45.407003,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 8082,
//       "Airport Code [ICAO]": "ZGBS",
//       "Airport Code iATA,FAA": "AEB",
//       "Airport Name": "Baise Youjiang Airport",
//       "Altitude (Ft)": 490,
//       "Country": "China",
//       "Latitude": 106.959999084,
//       "Location": "Baise",
//       "Longitude": 23.7206001282,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 8083,
//       "Airport Code [ICAO]": "FYOO",
//       "Airport Code iATA,FAA": "OKF",
//       "Airport Name": "Okaukuejo Airport",
//       "Altitude (Ft)": 3911,
//       "Country": "Namibia",
//       "Latitude": 15.91189956665039,
//       "Location": "Okaukuejo",
//       "Longitude": -19.149200439453125,
//       "GMT Offset": "1",
//       "Daylight Savings": "S",
//       "Region": "Africa/Windhoek",
//       "Type": "airport"
//     },
//     {
//       "ID": 8084,
//       "Airport Code [ICAO]": "FYMO",
//       "Airport Code iATA,FAA": "OKU",
//       "Airport Name": "Mokuti Lodge Airport",
//       "Altitude (Ft)": 3665,
//       "Country": "Namibia",
//       "Latitude": 17.05940055847168,
//       "Location": "Mokuti Lodge",
//       "Longitude": -18.81279945373535,
//       "GMT Offset": "1",
//       "Daylight Savings": "S",
//       "Region": "Africa/Windhoek",
//       "Type": "airport"
//     },
//     {
//       "ID": 8085,
//       "Airport Code [ICAO]": "EDXQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Rotenburg (Wümme) Airport",
//       "Altitude (Ft)": 98,
//       "Country": "Germany",
//       "Latitude": 9.348610877990723,
//       "Location": "Rotenburg Wuemme",
//       "Longitude": 53.128334045410156,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8086,
//       "Airport Code [ICAO]": "EDKN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Wipperfürth-Neye Airport",
//       "Altitude (Ft)": 863,
//       "Country": "Germany",
//       "Latitude": 7.373610973358154,
//       "Location": "Wipperfuerth",
//       "Longitude": 51.124168395996094,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8087,
//       "Airport Code [ICAO]": "EDWO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Osnabrück-Atterheide Airport",
//       "Altitude (Ft)": 287,
//       "Country": "Germany",
//       "Latitude": 7.969722,
//       "Location": "Osnabrueck",
//       "Longitude": 52.286388,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8088,
//       "Airport Code [ICAO]": "EDCB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Flugplatz Ballenstedt",
//       "Altitude (Ft)": 535,
//       "Country": "Germany",
//       "Latitude": 11.229722,
//       "Location": "Ballenstedt",
//       "Longitude": 51.745834,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8089,
//       "Airport Code [ICAO]": "EDHM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Flugplatz Hartenholm",
//       "Altitude (Ft)": 108,
//       "Country": "Germany",
//       "Latitude": 10.035556,
//       "Location": "Hasenmoor",
//       "Longitude": 53.915001,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8090,
//       "Airport Code [ICAO]": "EDWQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ganderkesee Atlas Airfield",
//       "Altitude (Ft)": 95,
//       "Country": "Germany",
//       "Latitude": 8.505556,
//       "Location": "Ganderkesee",
//       "Longitude": 53.03611,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8091,
//       "Airport Code [ICAO]": "EDXI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nienburg-Holzbalge Airport",
//       "Altitude (Ft)": 82,
//       "Country": "Germany",
//       "Latitude": 9.162500381469727,
//       "Location": "Nienburg Weser",
//       "Longitude": 52.709720611572266,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8092,
//       "Airport Code [ICAO]": "EDWC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Damme Airfield",
//       "Altitude (Ft)": 151,
//       "Country": "Germany",
//       "Latitude": 8.185556,
//       "Location": "Damme",
//       "Longitude": 52.487499,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8093,
//       "Airport Code [ICAO]": "EDLB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Borkenberge Airport",
//       "Altitude (Ft)": 157,
//       "Country": "Germany",
//       "Latitude": 7.28805589676,
//       "Location": "Duelmen",
//       "Longitude": 51.779998779299994,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8094,
//       "Airport Code [ICAO]": "EDCO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Obermehler-Schlotheim Airport",
//       "Altitude (Ft)": 909,
//       "Country": "Germany",
//       "Latitude": 10.634721755981445,
//       "Location": "Obermehler",
//       "Longitude": 51.26777648925781,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8095,
//       "Airport Code [ICAO]": "EDVH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hodenhagen Airport",
//       "Altitude (Ft)": 79,
//       "Country": "Germany",
//       "Latitude": 9.61056,
//       "Location": "Hodenhagen",
//       "Longitude": 52.761865,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8096,
//       "Airport Code [ICAO]": "EDHB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Grube Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Germany",
//       "Latitude": 11.0247220993042,
//       "Location": "Grube",
//       "Longitude": 54.24444580078125,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8097,
//       "Airport Code [ICAO]": "EKTD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tønder Airport",
//       "Altitude (Ft)": 1,
//       "Country": "Denmark",
//       "Latitude": 8.840570449829102,
//       "Location": "Toender",
//       "Longitude": 54.9296989440918,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Copenhagen",
//       "Type": "airport"
//     },
//     {
//       "ID": 8098,
//       "Airport Code [ICAO]": "EDVC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Celle-Arloh Airport",
//       "Altitude (Ft)": 207,
//       "Country": "Germany",
//       "Latitude": 10.11138916015625,
//       "Location": "Celle",
//       "Longitude": 52.68722152709961,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8099,
//       "Airport Code [ICAO]": "EDVU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Uelzen Airport",
//       "Altitude (Ft)": 246,
//       "Country": "Germany",
//       "Latitude": 10.4650001526,
//       "Location": "Uelzen",
//       "Longitude": 52.9838905334,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8100,
//       "Airport Code [ICAO]": "EDLH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hamm-Lippewiesen Airport",
//       "Altitude (Ft)": 190,
//       "Country": "Germany",
//       "Latitude": 7.816111087799072,
//       "Location": "Hamm",
//       "Longitude": 51.689720153808594,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8101,
//       "Airport Code [ICAO]": "EDOJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lüsse Airport",
//       "Altitude (Ft)": 217,
//       "Country": "Germany",
//       "Latitude": 12.664722442626953,
//       "Location": "Luesse",
//       "Longitude": 52.141109466552734,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8102,
//       "Airport Code [ICAO]": "EDVY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Porta Westfalica Airport",
//       "Altitude (Ft)": 148,
//       "Country": "Germany",
//       "Latitude": 8.859167098999023,
//       "Location": "Bad Oeynhausen",
//       "Longitude": 52.22083282470703,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8103,
//       "Airport Code [ICAO]": "EDKO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Brilon/Hochsauerlandkreis Airfield",
//       "Altitude (Ft)": 1509,
//       "Country": "Germany",
//       "Latitude": 8.641667,
//       "Location": "Brilon",
//       "Longitude": 51.4025,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8104,
//       "Airport Code [ICAO]": "EDVW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hameln-Pyrmont Airport",
//       "Altitude (Ft)": 1178,
//       "Country": "Germany",
//       "Latitude": 9.291666984558105,
//       "Location": "Bad Pyrmont",
//       "Longitude": 51.96666717529297,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8105,
//       "Airport Code [ICAO]": "EDXN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nordholz-Spieka Airfield",
//       "Altitude (Ft)": 72,
//       "Country": "Germany",
//       "Latitude": 8.643611,
//       "Location": "Cuxhaven",
//       "Longitude": 53.767223,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8106,
//       "Airport Code [ICAO]": "EDCK",
//       "Airport Code iATA,FAA": "KOQ",
//       "Airport Name": "Köthen Airport",
//       "Altitude (Ft)": 305,
//       "Country": "Germany",
//       "Latitude": 11.952777862500001,
//       "Location": "Koethen",
//       "Longitude": 51.7211112976,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8107,
//       "Airport Code [ICAO]": "EDXM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "St. Michaelisdonn Airport",
//       "Altitude (Ft)": 125,
//       "Country": "Germany",
//       "Latitude": 9.144721984863281,
//       "Location": "Sankt Michaelisdonn",
//       "Longitude": 53.978057861328125,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8108,
//       "Airport Code [ICAO]": "EDVS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Salzgitter-Drütte Airport",
//       "Altitude (Ft)": 328,
//       "Country": "Germany",
//       "Latitude": 10.426667213439941,
//       "Location": "Salzgitter",
//       "Longitude": 52.15444564819336,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8109,
//       "Airport Code [ICAO]": "EDWK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Karlshöfen Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Germany",
//       "Latitude": 9.028332710266113,
//       "Location": "Karlshoefen",
//       "Longitude": 53.33277893066406,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8110,
//       "Airport Code [ICAO]": "EDWH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Oldenburg-Hatten Airfield",
//       "Altitude (Ft)": 26,
//       "Country": "Germany",
//       "Latitude": 8.313611,
//       "Location": "Oldenburg",
//       "Longitude": 53.06889,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8111,
//       "Airport Code [ICAO]": "EDVR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Rinteln Airport",
//       "Altitude (Ft)": 180,
//       "Country": "Germany",
//       "Latitude": 9.053333282470703,
//       "Location": "Rinteln",
//       "Longitude": 52.17527770996094,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8112,
//       "Airport Code [ICAO]": "EDLT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Münster-Telgte Airport",
//       "Altitude (Ft)": 177,
//       "Country": "Germany",
//       "Latitude": 7.773889064788818,
//       "Location": "Muenster",
//       "Longitude": 51.94444274902344,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8113,
//       "Airport Code [ICAO]": "EDXO",
//       "Airport Code iATA,FAA": "PSH",
//       "Airport Name": "St. Peter-Ording Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Germany",
//       "Latitude": 8.686944007873535,
//       "Location": "Sankt Peter-Ording",
//       "Longitude": 54.30888748168945,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8114,
//       "Airport Code [ICAO]": "EDHC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lüchow-Rehbeck Airport",
//       "Altitude (Ft)": 49,
//       "Country": "Germany",
//       "Latitude": 11.144444465637207,
//       "Location": "Luechow",
//       "Longitude": 53.016109466552734,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8115,
//       "Airport Code [ICAO]": "EDCL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Klietz/Scharlibbe Airport",
//       "Altitude (Ft)": 95,
//       "Country": "Germany",
//       "Latitude": 12.073332786560059,
//       "Location": "Scharlibbe",
//       "Longitude": 52.709442138671875,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8116,
//       "Airport Code [ICAO]": "EDBG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Burg Airport",
//       "Altitude (Ft)": 174,
//       "Country": "Germany",
//       "Latitude": 11.856110572814941,
//       "Location": "Burg",
//       "Longitude": 52.241668701171875,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8117,
//       "Airport Code [ICAO]": "KCKF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Crisp County Cordele Airport",
//       "Altitude (Ft)": 310,
//       "Country": "United States",
//       "Latitude": -83.77390289,
//       "Location": "Cordele",
//       "Longitude": 31.98880005,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8118,
//       "Airport Code [ICAO]": "KOMN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ormond Beach Municipal Airport",
//       "Altitude (Ft)": 29,
//       "Country": "United States",
//       "Latitude": -81.11360168457031,
//       "Location": "Ormond Beach",
//       "Longitude": 29.300600051879883,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8119,
//       "Airport Code [ICAO]": "EDRA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bad Neuenahr-Ahrweiler Airfield",
//       "Altitude (Ft)": 673,
//       "Country": "Germany",
//       "Latitude": 7.136389,
//       "Location": "Bad Neuenahr",
//       "Longitude": 50.557777,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8120,
//       "Airport Code [ICAO]": "EDRF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bad Dürkheim Airport",
//       "Altitude (Ft)": 351,
//       "Country": "Germany",
//       "Latitude": 8.196389198303223,
//       "Location": "Bad Duerkheim",
//       "Longitude": 49.47305679321289,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8121,
//       "Airport Code [ICAO]": "KTTD",
//       "Airport Code iATA,FAA": "TTD",
//       "Airport Name": "Portland Troutdale Airport",
//       "Altitude (Ft)": 39,
//       "Country": "United States",
//       "Latitude": -122.40100097656,
//       "Location": "Troutdale",
//       "Longitude": 45.54940032959,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 8122,
//       "Airport Code [ICAO]": "KHIO",
//       "Airport Code iATA,FAA": "HIO",
//       "Airport Name": "Portland Hillsboro Airport",
//       "Altitude (Ft)": 208,
//       "Country": "United States",
//       "Latitude": -122.949997,
//       "Location": "Hillsboro",
//       "Longitude": 45.540401,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 8123,
//       "Airport Code [ICAO]": "NK39",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "One Police Plaza Heliport",
//       "Altitude (Ft)": 244,
//       "Country": "United States",
//       "Latitude": -73.99960327148438,
//       "Location": "New York",
//       "Longitude": 40.71260070800781,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8124,
//       "Airport Code [ICAO]": "EDKL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Leverkusen Airport",
//       "Altitude (Ft)": 157,
//       "Country": "Germany",
//       "Latitude": 7.005556106567383,
//       "Location": "Leverkusen",
//       "Longitude": 51.01527786254883,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8126,
//       "Airport Code [ICAO]": "EDRV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Wershofen/Eifel Airfield",
//       "Altitude (Ft)": 1581,
//       "Country": "Germany",
//       "Latitude": 6.783333,
//       "Location": "Wershofen",
//       "Longitude": 50.451389,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8127,
//       "Airport Code [ICAO]": "OAKS",
//       "Airport Code iATA,FAA": "KHT",
//       "Airport Name": "Khost Airport",
//       "Altitude (Ft)": 3756,
//       "Country": "Afghanistan",
//       "Latitude": 69.952003479,
//       "Location": "Khost",
//       "Longitude": 33.3334007263,
//       "GMT Offset": "4.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kabul",
//       "Type": "airport"
//     },
//     {
//       "ID": 8130,
//       "Airport Code [ICAO]": "VYNT",
//       "Airport Code iATA,FAA": "NMT",
//       "Airport Name": "Namtu Airport",
//       "Altitude (Ft)": 2000,
//       "Country": "Burma",
//       "Latitude": 97.383003,
//       "Location": "Naypyidaw",
//       "Longitude": 23.083,
//       "GMT Offset": "6.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Rangoon",
//       "Type": "airport"
//     },
//     {
//       "ID": 8133,
//       "Airport Code [ICAO]": "KBDN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bend Municipal Airport",
//       "Altitude (Ft)": 3460,
//       "Country": "United States",
//       "Latitude": -121.2009964,
//       "Location": "Bend",
//       "Longitude": 44.09479904,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 8134,
//       "Airport Code [ICAO]": "K62S",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Christmas Valley Airport",
//       "Altitude (Ft)": 4317,
//       "Country": "United States",
//       "Latitude": -120.66600036621094,
//       "Location": "Christmas Valley",
//       "Longitude": 43.23649978637695,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 8135,
//       "Airport Code [ICAO]": "KBNO",
//       "Airport Code iATA,FAA": "BNO",
//       "Airport Name": "Burns Municipal Airport",
//       "Altitude (Ft)": 4148,
//       "Country": "United States",
//       "Latitude": -118.955001831,
//       "Location": "Burns",
//       "Longitude": 43.5918998718,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 8136,
//       "Airport Code [ICAO]": "KS39",
//       "Airport Code iATA,FAA": "PRZ",
//       "Airport Name": "Prineville Airport",
//       "Altitude (Ft)": 3250,
//       "Country": "United States",
//       "Latitude": -120.90399932861,
//       "Location": "Prineville",
//       "Longitude": 44.286998748779,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 8137,
//       "Airport Code [ICAO]": "KRBL",
//       "Airport Code iATA,FAA": "RBL",
//       "Airport Name": "Red Bluff Municipal Airport",
//       "Altitude (Ft)": 352,
//       "Country": "United States",
//       "Latitude": -122.251998901,
//       "Location": "Red Bluff",
//       "Longitude": 40.1506996155,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 8138,
//       "Airport Code [ICAO]": "KDVO",
//       "Airport Code iATA,FAA": "NOT",
//       "Airport Name": "Marin County Airport - Gnoss Field",
//       "Altitude (Ft)": 2,
//       "Country": "United States",
//       "Latitude": -122.55599975586,
//       "Location": "Novato",
//       "Longitude": 38.143600463867,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 8139,
//       "Airport Code [ICAO]": "KLKV",
//       "Airport Code iATA,FAA": "LKV",
//       "Airport Name": "Lake County Airport",
//       "Altitude (Ft)": 4733,
//       "Country": "United States",
//       "Latitude": -120.399002075,
//       "Location": "Lakeview",
//       "Longitude": 42.161098480199996,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 8140,
//       "Airport Code [ICAO]": "KTMK",
//       "Airport Code iATA,FAA": "OTK",
//       "Airport Name": "Tillamook Airport",
//       "Altitude (Ft)": 36,
//       "Country": "United States",
//       "Latitude": -123.814002991,
//       "Location": "Tillamook",
//       "Longitude": 45.4182014465,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 8141,
//       "Airport Code [ICAO]": "KONO",
//       "Airport Code iATA,FAA": "ONO",
//       "Airport Name": "Ontario Municipal Airport",
//       "Altitude (Ft)": 2193,
//       "Country": "United States",
//       "Latitude": -117.01399993896,
//       "Location": "Ontario",
//       "Longitude": 44.020500183105,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 8142,
//       "Airport Code [ICAO]": "KDLS",
//       "Airport Code iATA,FAA": "DLS",
//       "Airport Name": "Columbia Gorge Regional the Dalles Municipal Airport",
//       "Altitude (Ft)": 247,
//       "Country": "United States",
//       "Latitude": -121.166999817,
//       "Location": "The Dalles",
//       "Longitude": 45.6184997559,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 8143,
//       "Airport Code [ICAO]": "KGAI",
//       "Airport Code iATA,FAA": "GAI",
//       "Airport Name": "Montgomery County Airpark",
//       "Altitude (Ft)": 539,
//       "Country": "United States",
//       "Latitude": -77.1660003662,
//       "Location": "Gaithersburg",
//       "Longitude": 39.168300628699996,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8146,
//       "Airport Code [ICAO]": "OASA",
//       "Airport Code iATA,FAA": "OAS",
//       "Airport Name": "Sharana Airstrip",
//       "Altitude (Ft)": 7340,
//       "Country": "Afghanistan",
//       "Latitude": 68.838517,
//       "Location": "Sharona",
//       "Longitude": 33.12575,
//       "GMT Offset": "4.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kabul",
//       "Type": "airport"
//     },
//     {
//       "ID": 8147,
//       "Airport Code [ICAO]": "CYTA",
//       "Airport Code iATA,FAA": "YTA",
//       "Airport Name": "Pembroke Airport",
//       "Altitude (Ft)": 529,
//       "Country": "Canada",
//       "Latitude": -77.25170135498047,
//       "Location": "Pembroke",
//       "Longitude": 45.86439895629883,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 8148,
//       "Airport Code [ICAO]": "FYTM",
//       "Airport Code iATA,FAA": "TSB",
//       "Airport Name": "Tsumeb Airport",
//       "Altitude (Ft)": 4353,
//       "Country": "Namibia",
//       "Latitude": 17.732500076294,
//       "Location": "Tsumeb",
//       "Longitude": -19.26189994812,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": "Africa/Windhoek",
//       "Type": "airport"
//     },
//     {
//       "ID": 8149,
//       "Airport Code [ICAO]": "CYSD",
//       "Airport Code iATA,FAA": "YSD",
//       "Airport Name": "Suffield Heliport",
//       "Altitude (Ft)": 2525,
//       "Country": "Canada",
//       "Latitude": -111.18299865722656,
//       "Location": "Suffield",
//       "Longitude": 50.266700744628906,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Edmonton",
//       "Type": "airport"
//     },
//     {
//       "ID": 8152,
//       "Airport Code [ICAO]": "SSBL",
//       "Airport Code iATA,FAA": "BNU",
//       "Airport Name": "Blumenau Airport",
//       "Altitude (Ft)": 60,
//       "Country": "Brazil",
//       "Latitude": -49.090301513671875,
//       "Location": "BLUMENAU",
//       "Longitude": -26.83060073852539,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 8156,
//       "Airport Code [ICAO]": "UUDG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bolshoye Gryzlovo Airfield",
//       "Altitude (Ft)": 797,
//       "Country": "Russia",
//       "Latitude": 37.645,
//       "Location": "Stupino",
//       "Longitude": 54.785,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 8162,
//       "Airport Code [ICAO]": "KCVX",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Charlevoix Municipal Airport",
//       "Altitude (Ft)": 669,
//       "Country": "United States",
//       "Latitude": -85.2748031616211,
//       "Location": "Charelvoix",
//       "Longitude": 45.3047981262207,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8165,
//       "Airport Code [ICAO]": "EKMS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mykines Heliport",
//       "Altitude (Ft)": 110,
//       "Country": "Faroe Islands",
//       "Latitude": -7.645919799800001,
//       "Location": "Mykines",
//       "Longitude": 62.1021003723,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Atlantic/Faeroe",
//       "Type": "airport"
//     },
//     {
//       "ID": 8177,
//       "Airport Code [ICAO]": "CYCC",
//       "Airport Code iATA,FAA": "YCC",
//       "Airport Name": "Cornwall Regional Airport",
//       "Altitude (Ft)": 175,
//       "Country": "Canada",
//       "Latitude": -74.56330108642578,
//       "Location": "Cornwall",
//       "Longitude": 45.09280014038086,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 8178,
//       "Airport Code [ICAO]": "EHSE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Seppe Airfield",
//       "Altitude (Ft)": 30,
//       "Country": "Netherlands",
//       "Latitude": 4.5525,
//       "Location": "Bosschenhoofd",
//       "Longitude": 51.554699,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Amsterdam",
//       "Type": "airport"
//     },
//     {
//       "ID": 8179,
//       "Airport Code [ICAO]": "LSTS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "St Stephan Airport",
//       "Altitude (Ft)": 3304,
//       "Country": "Switzerland",
//       "Latitude": 7.412569999694824,
//       "Location": "St.Stephan",
//       "Longitude": 46.497398376464844,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 8180,
//       "Airport Code [ICAO]": "SDZY",
//       "Airport Code iATA,FAA": "IZA",
//       "Airport Name": "Zona da Mata Regional Airport",
//       "Altitude (Ft)": 1348,
//       "Country": "Brazil",
//       "Latitude": -43.1730575562,
//       "Location": "Juiz de Fora",
//       "Longitude": -21.5130558014,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 8181,
//       "Airport Code [ICAO]": "LRBG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "IAR Gimbav Heliport",
//       "Altitude (Ft)": 0,
//       "Country": "Romania",
//       "Latitude": 25.529058,
//       "Location": "Brasov",
//       "Longitude": 45.686443,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Bucharest",
//       "Type": "airport"
//     },
//     {
//       "ID": 8182,
//       "Airport Code [ICAO]": "KXFL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Flagler Executive Airport",
//       "Altitude (Ft)": 33,
//       "Country": "United States",
//       "Latitude": -81.206299,
//       "Location": "Flagler",
//       "Longitude": 29.4674,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8187,
//       "Airport Code [ICAO]": "KMVL",
//       "Airport Code iATA,FAA": "MVL",
//       "Airport Name": "Morrisville Stowe State Airport",
//       "Altitude (Ft)": 732,
//       "Country": "United States",
//       "Latitude": -72.6139984131,
//       "Location": "Morrisville",
//       "Longitude": 44.53459930419999,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8188,
//       "Airport Code [ICAO]": "KRBD",
//       "Airport Code iATA,FAA": "RBD",
//       "Airport Name": "Dallas Executive Airport",
//       "Altitude (Ft)": 660,
//       "Country": "United States",
//       "Latitude": -96.8682022095,
//       "Location": "Dallas",
//       "Longitude": 32.6809005737,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8189,
//       "Airport Code [ICAO]": "LILY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Como (Idroscalo - Water Ad) Hidroport",
//       "Altitude (Ft)": 663,
//       "Country": "Italy",
//       "Latitude": 9.06972,
//       "Location": "Como",
//       "Longitude": 45.814701,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 8192,
//       "Airport Code [ICAO]": "UAOL",
//       "Airport Code iATA,FAA": "BXY",
//       "Airport Name": "Krainiy Airport",
//       "Altitude (Ft)": 317,
//       "Country": "Kazakhstan",
//       "Latitude": 63.210773,
//       "Location": "Baikonur",
//       "Longitude": 45.621994,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": "Asia/Qyzylorda",
//       "Type": "airport"
//     },
//     {
//       "ID": 8194,
//       "Airport Code [ICAO]": "KWST",
//       "Airport Code iATA,FAA": "WST",
//       "Airport Name": "Westerly State Airport",
//       "Altitude (Ft)": 81,
//       "Country": "United States",
//       "Latitude": -71.8033981323,
//       "Location": "Washington County",
//       "Longitude": 41.3496017456,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8195,
//       "Airport Code [ICAO]": "KBID",
//       "Airport Code iATA,FAA": "BID",
//       "Airport Name": "Block Island State Airport",
//       "Altitude (Ft)": 108,
//       "Country": "United States",
//       "Latitude": -71.577796936,
//       "Location": "Block Island",
//       "Longitude": 41.1680984497,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8199,
//       "Airport Code [ICAO]": "PAGT",
//       "Airport Code iATA,FAA": "NME",
//       "Airport Name": "Nightmute Airport",
//       "Altitude (Ft)": 4,
//       "Country": "United States",
//       "Latitude": -164.70100402832,
//       "Location": "Nightmute",
//       "Longitude": 60.471000671387,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 8200,
//       "Airport Code [ICAO]": "PAOO",
//       "Airport Code iATA,FAA": "OOK",
//       "Airport Name": "Toksook Bay Airport",
//       "Altitude (Ft)": 59,
//       "Country": "United States",
//       "Latitude": -165.0870056,
//       "Location": "Toksook Bay",
//       "Longitude": 60.54140091,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 8208,
//       "Airport Code [ICAO]": "BGSC",
//       "Airport Code iATA,FAA": "OBY",
//       "Airport Name": "Ittoqqortoormiit Heliport",
//       "Altitude (Ft)": 238,
//       "Country": "Greenland",
//       "Latitude": -21.971679925900002,
//       "Location": "Ittoqqortoormiit",
//       "Longitude": 70.4882288244,
//       "GMT Offset": "-1",
//       "Daylight Savings": "U",
//       "Region": "America/Scoresbysund",
//       "Type": "airport"
//     },
//     {
//       "ID": 8209,
//       "Airport Code [ICAO]": "UKWW",
//       "Airport Code iATA,FAA": "VIN",
//       "Airport Name": "Vinnytsia/Gavyryshivka Airport",
//       "Altitude (Ft)": 961,
//       "Country": "Ukraine",
//       "Latitude": 28.613778,
//       "Location": "Vinnitsa",
//       "Longitude": 49.242531,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 8215,
//       "Airport Code [ICAO]": "KBGE",
//       "Airport Code iATA,FAA": "BGE",
//       "Airport Name": "Decatur County Industrial Air Park",
//       "Altitude (Ft)": 141,
//       "Country": "United States",
//       "Latitude": -84.63739777,
//       "Location": "Bainbridge",
//       "Longitude": 30.9715004,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8216,
//       "Airport Code [ICAO]": "CTT5",
//       "Airport Code iATA,FAA": "ZGS",
//       "Airport Name": "La Romaine Airport",
//       "Altitude (Ft)": 90,
//       "Country": "Canada",
//       "Latitude": -60.679401,
//       "Location": "La Romaine",
//       "Longitude": 50.259701,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Blanc-Sablon",
//       "Type": "airport"
//     },
//     {
//       "ID": 8217,
//       "Airport Code [ICAO]": "CTK6",
//       "Airport Code iATA,FAA": "ZKG",
//       "Airport Name": "Kegaska Airport",
//       "Altitude (Ft)": 32,
//       "Country": "Canada",
//       "Latitude": -61.265800476100004,
//       "Location": "Kegaska",
//       "Longitude": 50.1958007812,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Blanc-Sablon",
//       "Type": "airport"
//     },
//     {
//       "ID": 8218,
//       "Airport Code [ICAO]": "CCE4",
//       "Airport Code iATA,FAA": "YBI",
//       "Airport Name": "Black Tickle Airport",
//       "Altitude (Ft)": 57,
//       "Country": "Canada",
//       "Latitude": -55.784999847399995,
//       "Location": "Black Tickle",
//       "Longitude": 53.4693984985,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Halifax",
//       "Type": "airport"
//     },
//     {
//       "ID": 8219,
//       "Airport Code [ICAO]": "KSPZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Silver Springs Airport",
//       "Altitude (Ft)": 4269,
//       "Country": "United States",
//       "Latitude": -119.2509994506836,
//       "Location": "Silver Springs",
//       "Longitude": 39.40299987792969,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 8220,
//       "Airport Code [ICAO]": "KWHP",
//       "Airport Code iATA,FAA": "WHP",
//       "Airport Name": "Whiteman Airport",
//       "Altitude (Ft)": 1003,
//       "Country": "United States",
//       "Latitude": -118.413002014,
//       "Location": "Los Angeles",
//       "Longitude": 34.2593002319,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 8221,
//       "Airport Code [ICAO]": "KMAE",
//       "Airport Code iATA,FAA": "MAE",
//       "Airport Name": "Madera Municipal Airport",
//       "Altitude (Ft)": 255,
//       "Country": "United States",
//       "Latitude": -120.111999512,
//       "Location": "Madera",
//       "Longitude": 36.9886016846,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 8223,
//       "Airport Code [ICAO]": "CAD4",
//       "Airport Code iATA,FAA": "YZZ",
//       "Airport Name": "Trail Airport",
//       "Altitude (Ft)": 1427,
//       "Country": "Canada",
//       "Latitude": -117.60900116,
//       "Location": "Trail",
//       "Longitude": 49.0555992126,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 8224,
//       "Airport Code [ICAO]": "CAP5",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Victoria Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Canada",
//       "Latitude": -123.450450897,
//       "Location": "Patricia Bay",
//       "Longitude": 48.6538920506,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 8225,
//       "Airport Code [ICAO]": "CJX7",
//       "Airport Code iATA,FAA": "YAB",
//       "Airport Name": "Old Arctic Bay Airport",
//       "Altitude (Ft)": 100,
//       "Country": "Canada",
//       "Latitude": -85.0325489044,
//       "Location": "Arctic Bay",
//       "Longitude": 73.0058922479,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 8227,
//       "Airport Code [ICAO]": "PABV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Birchwood Airport",
//       "Altitude (Ft)": 83,
//       "Country": "Belize",
//       "Latitude": -149.5070038,
//       "Location": "Belmopan",
//       "Longitude": 61.41650009,
//       "GMT Offset": "-9",
//       "Daylight Savings": "U",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 8228,
//       "Airport Code [ICAO]": "SOGS",
//       "Airport Code iATA,FAA": "GSI",
//       "Airport Name": "Grand-Santi Airport",
//       "Altitude (Ft)": 207,
//       "Country": "French Guiana",
//       "Latitude": -54.373056,
//       "Location": "Grand-Santi",
//       "Longitude": 4.285833,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Cayenne",
//       "Type": "airport"
//     },
//     {
//       "ID": 8229,
//       "Airport Code [ICAO]": "SOOA",
//       "Airport Code iATA,FAA": "MPY",
//       "Airport Name": "Maripasoula Airport",
//       "Altitude (Ft)": 406,
//       "Country": "French Guiana",
//       "Latitude": -54.037201,
//       "Location": "Maripasoula",
//       "Longitude": 3.6575,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Cayenne",
//       "Type": "airport"
//     },
//     {
//       "ID": 8230,
//       "Airport Code [ICAO]": "SOOM",
//       "Airport Code iATA,FAA": "LDX",
//       "Airport Name": "Saint-Laurent-du-Maroni Airport",
//       "Altitude (Ft)": 16,
//       "Country": "French Guiana",
//       "Latitude": -54.034401,
//       "Location": "Saint-Laurent-du-Maroni",
//       "Longitude": 5.48306,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Cayenne",
//       "Type": "airport"
//     },
//     {
//       "ID": 8233,
//       "Airport Code [ICAO]": "ZWKN",
//       "Airport Code iATA,FAA": "KJI",
//       "Airport Name": "Kanas Airport",
//       "Altitude (Ft)": 3921,
//       "Country": "China",
//       "Latitude": 86.9959,
//       "Location": "Burqin",
//       "Longitude": 48.2223,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 8234,
//       "Airport Code [ICAO]": "SKCA",
//       "Airport Code iATA,FAA": "CPB",
//       "Airport Name": "Capurganá Airport",
//       "Altitude (Ft)": 49,
//       "Country": "Colombia",
//       "Latitude": -77.35,
//       "Location": "Capurgana",
//       "Longitude": 8.63333,
//       "GMT Offset": "-5",
//       "Daylight Savings": "S",
//       "Region": "America/Bogota",
//       "Type": "airport"
//     },
//     {
//       "ID": 8235,
//       "Airport Code [ICAO]": "HEMK",
//       "Airport Code iATA,FAA": "HMB",
//       "Airport Name": "Sohag International Airport",
//       "Altitude (Ft)": 310,
//       "Country": "Egypt",
//       "Latitude": 31.742778,
//       "Location": "Sohag",
//       "Longitude": 26.342778,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Africa/Cairo",
//       "Type": "airport"
//     },
//     {
//       "ID": 8236,
//       "Airport Code [ICAO]": "SURV",
//       "Airport Code iATA,FAA": "RVY",
//       "Airport Name": "Presidente General Don Oscar D. Gestido International Airport",
//       "Altitude (Ft)": 712,
//       "Country": "Uruguay",
//       "Latitude": -55.476200103759766,
//       "Location": "Rivera",
//       "Longitude": -30.974599838256836,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Montevideo",
//       "Type": "airport"
//     },
//     {
//       "ID": 8237,
//       "Airport Code [ICAO]": "SNPD",
//       "Airport Code iATA,FAA": "POJ",
//       "Airport Name": "Patos de Minas Airport",
//       "Altitude (Ft)": 2793,
//       "Country": "Brazil",
//       "Latitude": -46.4911994934082,
//       "Location": "Patos de Minas",
//       "Longitude": -18.672800064086914,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 8238,
//       "Airport Code [ICAO]": "SJTC",
//       "Airport Code iATA,FAA": "JTC",
//       "Airport Name": "Bauru - Arealva Airport",
//       "Altitude (Ft)": 1949,
//       "Country": "Brazil",
//       "Latitude": -49.0502866745,
//       "Location": "Bauru",
//       "Longitude": -22.166859140899998,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 8239,
//       "Airport Code [ICAO]": "SDOW",
//       "Airport Code iATA,FAA": "OIA",
//       "Airport Name": "Ourilândia do Norte Airport",
//       "Altitude (Ft)": 901,
//       "Country": "Brazil",
//       "Latitude": -51.0499000549,
//       "Location": "Ourilandia do Norte",
//       "Longitude": -6.763100147250001,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Belem",
//       "Type": "airport"
//     },
//     {
//       "ID": 8240,
//       "Airport Code [ICAO]": "SNDC",
//       "Airport Code iATA,FAA": "RDC",
//       "Airport Name": "Redenção Airport",
//       "Altitude (Ft)": 670,
//       "Country": "Brazil",
//       "Latitude": -49.97990036010742,
//       "Location": "Redencao",
//       "Longitude": -8.033289909362793,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Belem",
//       "Type": "airport"
//     },
//     {
//       "ID": 8241,
//       "Airport Code [ICAO]": "SNFX",
//       "Airport Code iATA,FAA": "SXX",
//       "Airport Name": "São Félix do Xingu Airport",
//       "Altitude (Ft)": 656,
//       "Country": "Brazil",
//       "Latitude": -51.9523,
//       "Location": "Sao Felix do Xingu",
//       "Longitude": -6.6413,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Belem",
//       "Type": "airport"
//     },
//     {
//       "ID": 8242,
//       "Airport Code [ICAO]": "SJDB",
//       "Airport Code iATA,FAA": "BYO",
//       "Airport Name": "Bonito Airport",
//       "Altitude (Ft)": 1180,
//       "Country": "Brazil",
//       "Latitude": -56.452499,
//       "Location": "Bointo",
//       "Longitude": -21.247299,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Campo_Grande",
//       "Type": "airport"
//     },
//     {
//       "ID": 8243,
//       "Airport Code [ICAO]": "SWFX",
//       "Airport Code iATA,FAA": "SXO",
//       "Airport Name": "São Félix do Araguaia Airport",
//       "Altitude (Ft)": 650,
//       "Country": "Brazil",
//       "Latitude": -50.68960189819336,
//       "Location": "Sao Felix do Araguaia",
//       "Longitude": -11.632399559020996,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Campo_Grande",
//       "Type": "airport"
//     },
//     {
//       "ID": 8244,
//       "Airport Code [ICAO]": "SBCD",
//       "Airport Code iATA,FAA": "CFC",
//       "Airport Name": "Caçador Airport",
//       "Altitude (Ft)": 3376,
//       "Country": "Brazil",
//       "Latitude": -50.9398002625,
//       "Location": "Cacador",
//       "Longitude": -26.78840065,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 8245,
//       "Airport Code [ICAO]": "SWCA",
//       "Airport Code iATA,FAA": "CAF",
//       "Airport Name": "Carauari Airport",
//       "Altitude (Ft)": 355,
//       "Country": "Brazil",
//       "Latitude": -66.89749908447266,
//       "Location": "Carauari",
//       "Longitude": -4.871520042419434,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 8246,
//       "Airport Code [ICAO]": "SWUY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Urucu Airport",
//       "Altitude (Ft)": 243,
//       "Country": "Brazil",
//       "Latitude": -65.3554000854,
//       "Location": "Porto Urucu",
//       "Longitude": -4.88422012329,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 8247,
//       "Airport Code [ICAO]": "SWEI",
//       "Airport Code iATA,FAA": "ERN",
//       "Airport Name": "Eirunepé Airport",
//       "Altitude (Ft)": 412,
//       "Country": "Brazil",
//       "Latitude": -69.87979888916016,
//       "Location": "Eirunepe",
//       "Longitude": -6.639530181884766,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 8248,
//       "Airport Code [ICAO]": "SSCK",
//       "Airport Code iATA,FAA": "CCI",
//       "Airport Name": "Concórdia Airport",
//       "Altitude (Ft)": 2461,
//       "Country": "Brazil",
//       "Latitude": -52.05270004272461,
//       "Location": "Concordia",
//       "Longitude": -27.180599212646484,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 8249,
//       "Airport Code [ICAO]": "SSFB",
//       "Airport Code iATA,FAA": "FBE",
//       "Airport Name": "Francisco Beltrão Airport",
//       "Altitude (Ft)": 2100,
//       "Country": "Brazil",
//       "Latitude": -53.063499450683594,
//       "Location": "Francisco Beltrao",
//       "Longitude": -26.059200286865234,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 8250,
//       "Airport Code [ICAO]": "SJHG",
//       "Airport Code iATA,FAA": "CFO",
//       "Airport Name": "Confresa Airport",
//       "Altitude (Ft)": 781,
//       "Country": "Brazil",
//       "Latitude": -51.5635986328125,
//       "Location": "Confresa",
//       "Longitude": -10.634400367736816,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Campo_Grande",
//       "Type": "airport"
//     },
//     {
//       "ID": 8252,
//       "Airport Code [ICAO]": "KAAF",
//       "Airport Code iATA,FAA": "AAF",
//       "Airport Name": "Apalachicola Regional Airport",
//       "Altitude (Ft)": 20,
//       "Country": "United States",
//       "Latitude": -85.02749634,
//       "Location": "Apalachicola",
//       "Longitude": 29.72750092,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8253,
//       "Airport Code [ICAO]": "SSUM",
//       "Airport Code iATA,FAA": "UMU",
//       "Airport Name": "Umuarama Airport",
//       "Altitude (Ft)": 1558,
//       "Country": "Brazil",
//       "Latitude": -53.31380081176758,
//       "Location": "Umuarama",
//       "Longitude": -23.7987003326416,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 8254,
//       "Airport Code [ICAO]": "SNDT",
//       "Airport Code iATA,FAA": "DTI",
//       "Airport Name": "Diamantina Airport",
//       "Altitude (Ft)": 4446,
//       "Country": "Brazil",
//       "Latitude": -43.650398254399995,
//       "Location": "Diamantina",
//       "Longitude": -18.232000351,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 8255,
//       "Airport Code [ICAO]": "SWOB",
//       "Airport Code iATA,FAA": "FBA",
//       "Airport Name": "Fonte Boa Airport",
//       "Altitude (Ft)": 207,
//       "Country": "Brazil",
//       "Latitude": -66.0831985474,
//       "Location": "Fonte Boa",
//       "Longitude": -2.5326099395800004,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 8256,
//       "Airport Code [ICAO]": "SDCG",
//       "Airport Code iATA,FAA": "OLC",
//       "Airport Name": "Senadora Eunice Micheles Airport",
//       "Altitude (Ft)": 335,
//       "Country": "Brazil",
//       "Latitude": -68.9204120636,
//       "Location": "Sao Paulo de Olivenca",
//       "Longitude": -3.46792950765,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 8257,
//       "Airport Code [ICAO]": "SWHT",
//       "Airport Code iATA,FAA": "HUW",
//       "Airport Name": "Humaitá Airport",
//       "Altitude (Ft)": 230,
//       "Country": "Brazil",
//       "Latitude": -63.072101593,
//       "Location": "Humaita",
//       "Longitude": -7.532120227810001,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 8258,
//       "Airport Code [ICAO]": "SWTP",
//       "Airport Code iATA,FAA": "IRZ",
//       "Airport Name": "Tapuruquara Airport",
//       "Altitude (Ft)": 223,
//       "Country": "Brazil",
//       "Latitude": -64.9923,
//       "Location": "Santa Isabel do Rio Negro",
//       "Longitude": -0.3786,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 8259,
//       "Airport Code [ICAO]": "SNOX",
//       "Airport Code iATA,FAA": "ORX",
//       "Airport Name": "Oriximiná Airport",
//       "Altitude (Ft)": 262,
//       "Country": "Brazil",
//       "Latitude": -55.83620071411133,
//       "Location": "Oriximina",
//       "Longitude": -1.7140799760818481,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Belem",
//       "Type": "airport"
//     },
//     {
//       "ID": 8260,
//       "Airport Code [ICAO]": "SBTC",
//       "Airport Code iATA,FAA": "UNA",
//       "Airport Name": "Hotel Transamérica Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Brazil",
//       "Latitude": -38.9990005493,
//       "Location": "Una",
//       "Longitude": -15.355199813799999,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 8265,
//       "Airport Code [ICAO]": "YTEF",
//       "Airport Code iATA,FAA": "TEF",
//       "Airport Name": "Telfer Airport",
//       "Altitude (Ft)": 970,
//       "Country": "Australia",
//       "Latitude": 122.22899627685547,
//       "Location": "Telfer",
//       "Longitude": -21.71500015258789,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 8266,
//       "Airport Code [ICAO]": "LTGP",
//       "Airport Code iATA,FAA": "GZP",
//       "Airport Name": "Gazipaşa Airport",
//       "Altitude (Ft)": 86,
//       "Country": "Turkey",
//       "Latitude": 32.300598,
//       "Location": "Alanya",
//       "Longitude": 36.299217,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 8267,
//       "Airport Code [ICAO]": "OASH",
//       "Airport Code iATA,FAA": "OAA",
//       "Airport Name": "Shank Air Base",
//       "Altitude (Ft)": 6890,
//       "Country": "Afghanistan",
//       "Latitude": 69.07722222219999,
//       "Location": "Shank",
//       "Longitude": 33.9225,
//       "GMT Offset": "4.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kabul",
//       "Type": "airport"
//     },
//     {
//       "ID": 8273,
//       "Airport Code [ICAO]": "KDQH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Douglas Municipal Airport",
//       "Altitude (Ft)": 257,
//       "Country": "United States",
//       "Latitude": -82.8604965209961,
//       "Location": "Douglas",
//       "Longitude": 31.476699829101562,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8274,
//       "Airport Code [ICAO]": "KFPR",
//       "Airport Code iATA,FAA": "FPR",
//       "Airport Name": "St Lucie County International Airport",
//       "Altitude (Ft)": 24,
//       "Country": "United States",
//       "Latitude": -80.36830139,
//       "Location": "Fort Pierce",
//       "Longitude": 27.49510002,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8276,
//       "Airport Code [ICAO]": "KTAN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Taunton Municipal King Field",
//       "Altitude (Ft)": 43,
//       "Country": "United States",
//       "Latitude": -71.0166015625,
//       "Location": "Taunton",
//       "Longitude": 41.8744010925293,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8277,
//       "Airport Code [ICAO]": "KPYM",
//       "Airport Code iATA,FAA": "PYM",
//       "Airport Name": "Plymouth Municipal Airport",
//       "Altitude (Ft)": 148,
//       "Country": "United States",
//       "Latitude": -70.728798,
//       "Location": "Plymouth",
//       "Longitude": 41.909,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8278,
//       "Airport Code [ICAO]": "KOQU",
//       "Airport Code iATA,FAA": "NCO",
//       "Airport Name": "Quonset State Airport",
//       "Altitude (Ft)": 18,
//       "Country": "United States",
//       "Latitude": -71.412101745605,
//       "Location": "North Kingstown",
//       "Longitude": 41.597099304199,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8280,
//       "Airport Code [ICAO]": "KOWD",
//       "Airport Code iATA,FAA": "OWD",
//       "Airport Name": "Norwood Memorial Airport",
//       "Altitude (Ft)": 49,
//       "Country": "United States",
//       "Latitude": -71.1728973389,
//       "Location": "Norwood",
//       "Longitude": 42.1904983521,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8281,
//       "Airport Code [ICAO]": "KBAF",
//       "Airport Code iATA,FAA": "BAF",
//       "Airport Name": "Westfield-Barnes Regional Airport",
//       "Altitude (Ft)": 271,
//       "Country": "United States",
//       "Latitude": -72.715599,
//       "Location": "Westfield",
//       "Longitude": 42.157799,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8282,
//       "Airport Code [ICAO]": "KIJD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Windham Airport",
//       "Altitude (Ft)": 247,
//       "Country": "United States",
//       "Latitude": -72.1802978515625,
//       "Location": "Willimantic",
//       "Longitude": 41.74399948120117,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8283,
//       "Airport Code [ICAO]": "KMGJ",
//       "Airport Code iATA,FAA": "MGJ",
//       "Airport Name": "Orange County Airport",
//       "Altitude (Ft)": 364,
//       "Country": "United States",
//       "Latitude": -74.26460266,
//       "Location": "Montgomery",
//       "Longitude": 41.50999832,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8284,
//       "Airport Code [ICAO]": "KCXY",
//       "Airport Code iATA,FAA": "HAR",
//       "Airport Name": "Capital City Airport",
//       "Altitude (Ft)": 347,
//       "Country": "United States",
//       "Latitude": -76.85150146480001,
//       "Location": "Harrisburg",
//       "Longitude": 40.2170982361,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8285,
//       "Airport Code [ICAO]": "KGHG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Marshfield Municipal George Harlow Field",
//       "Altitude (Ft)": 11,
//       "Country": "United States",
//       "Latitude": -70.67220306396484,
//       "Location": "Marshfield",
//       "Longitude": 42.09830093383789,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8286,
//       "Airport Code [ICAO]": "KDXR",
//       "Airport Code iATA,FAA": "DXR",
//       "Airport Name": "Danbury Municipal Airport",
//       "Altitude (Ft)": 458,
//       "Country": "United States",
//       "Latitude": -73.48220062259999,
//       "Location": "Danbury",
//       "Longitude": 41.371498107899995,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8287,
//       "Airport Code [ICAO]": "KASH",
//       "Airport Code iATA,FAA": "ASH",
//       "Airport Name": "Boire Field",
//       "Altitude (Ft)": 199,
//       "Country": "United States",
//       "Latitude": -71.51480102539999,
//       "Location": "Nashua",
//       "Longitude": 42.7817001343,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8288,
//       "Airport Code [ICAO]": "KLWM",
//       "Airport Code iATA,FAA": "LWM",
//       "Airport Name": "Lawrence Municipal Airport",
//       "Altitude (Ft)": 148,
//       "Country": "United States",
//       "Latitude": -71.1233978271,
//       "Location": "Lawrence",
//       "Longitude": 42.717201232899995,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8289,
//       "Airport Code [ICAO]": "KOXC",
//       "Airport Code iATA,FAA": "OXC",
//       "Airport Name": "Waterbury Oxford Airport",
//       "Altitude (Ft)": 726,
//       "Country": "United States",
//       "Latitude": -73.135200500488,
//       "Location": "Oxford",
//       "Longitude": 41.47859954834,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8290,
//       "Airport Code [ICAO]": "KFIT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Fitchburg Municipal Airport",
//       "Altitude (Ft)": 348,
//       "Country": "United States",
//       "Latitude": -71.75900268554688,
//       "Location": "Fitchburg",
//       "Longitude": 42.554100036621094,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8291,
//       "Airport Code [ICAO]": "20GA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Earl L. Small Jr. Field/Stockmar Airport",
//       "Altitude (Ft)": 1110,
//       "Country": "United States",
//       "Latitude": -84.88469696039999,
//       "Location": "Villa Rica",
//       "Longitude": 33.756500244099996,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8292,
//       "Airport Code [ICAO]": "KVPC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cartersville Airport",
//       "Altitude (Ft)": 759,
//       "Country": "United States",
//       "Latitude": -84.84870147705078,
//       "Location": "Cartersville",
//       "Longitude": 34.12310028076172,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8293,
//       "Airport Code [ICAO]": "KPYP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Centre-Piedmont-Cherokee County Regional Airport",
//       "Altitude (Ft)": 596,
//       "Country": "United States",
//       "Latitude": -85.610069,
//       "Location": "Centre",
//       "Longitude": 34.089977,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8294,
//       "Airport Code [ICAO]": "KRMG",
//       "Airport Code iATA,FAA": "RMG",
//       "Airport Name": "Richard B Russell Airport",
//       "Altitude (Ft)": 644,
//       "Country": "United States",
//       "Latitude": -85.15799713130001,
//       "Location": "Rome",
//       "Longitude": 34.3506011963,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8295,
//       "Airport Code [ICAO]": "KGAD",
//       "Airport Code iATA,FAA": "GAD",
//       "Airport Name": "Northeast Alabama Regional Airport",
//       "Altitude (Ft)": 569,
//       "Country": "United States",
//       "Latitude": -86.088996,
//       "Location": "Gadsden",
//       "Longitude": 33.972599,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8296,
//       "Airport Code [ICAO]": "KDKX",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Knoxville Downtown Island Airport",
//       "Altitude (Ft)": 833,
//       "Country": "United States",
//       "Latitude": -83.8739013671875,
//       "Location": "Knoxville",
//       "Longitude": 35.96390151977539,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8297,
//       "Airport Code [ICAO]": "KWDR",
//       "Airport Code iATA,FAA": "WDR",
//       "Airport Name": "Barrow County Airport",
//       "Altitude (Ft)": 943,
//       "Country": "United States",
//       "Latitude": -83.66739655,
//       "Location": "Winder",
//       "Longitude": 33.98289871,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8298,
//       "Airport Code [ICAO]": "KJYL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Plantation Airpark",
//       "Altitude (Ft)": 188,
//       "Country": "United States",
//       "Latitude": -81.59709930419922,
//       "Location": "Sylvania",
//       "Longitude": 32.645301818847656,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8299,
//       "Airport Code [ICAO]": "KDNN",
//       "Airport Code iATA,FAA": "DNN",
//       "Airport Name": "Dalton Municipal Airport",
//       "Altitude (Ft)": 709,
//       "Country": "United States",
//       "Latitude": -84.87020111,
//       "Location": "Dalton",
//       "Longitude": 34.72290039,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8300,
//       "Airport Code [ICAO]": "KCTJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "West Georgia Regional O V Gray Field",
//       "Altitude (Ft)": 1161,
//       "Country": "United States",
//       "Latitude": -85.1520004272461,
//       "Location": "Carrollton",
//       "Longitude": 33.63100051879883,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8302,
//       "Airport Code [ICAO]": "KLGC",
//       "Airport Code iATA,FAA": "LGC",
//       "Airport Name": "LaGrange Callaway Airport",
//       "Altitude (Ft)": 693,
//       "Country": "United States",
//       "Latitude": -85.072601,
//       "Location": "LaGrange",
//       "Longitude": 33.0089,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8303,
//       "Airport Code [ICAO]": "KMLJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Baldwin County Regional Airport",
//       "Altitude (Ft)": 385,
//       "Country": "United States",
//       "Latitude": -83.240701,
//       "Location": "Milledgeville",
//       "Longitude": 33.154202,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8305,
//       "Airport Code [ICAO]": "KPIM",
//       "Airport Code iATA,FAA": "PIM",
//       "Airport Name": "Harris County Airport",
//       "Altitude (Ft)": 902,
//       "Country": "United States",
//       "Latitude": -84.8824005127,
//       "Location": "Pine Mountain",
//       "Longitude": 32.8406982422,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8306,
//       "Airport Code [ICAO]": "KFFC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Peachtree City Falcon Field",
//       "Altitude (Ft)": 808,
//       "Country": "United States",
//       "Latitude": -84.5718002319336,
//       "Location": "Atlanta",
//       "Longitude": 33.3572998046875,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8308,
//       "Airport Code [ICAO]": "KGVL",
//       "Airport Code iATA,FAA": "GVL",
//       "Airport Name": "Lee Gilmer Memorial Airport",
//       "Altitude (Ft)": 1276,
//       "Country": "United States",
//       "Latitude": -83.8302002,
//       "Location": "Gainesville",
//       "Longitude": 34.27259827,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8312,
//       "Airport Code [ICAO]": "KPHD",
//       "Airport Code iATA,FAA": "PHD",
//       "Airport Name": "Harry Clever Field",
//       "Altitude (Ft)": 894,
//       "Country": "United States",
//       "Latitude": -81.419700622559,
//       "Location": "New Philadelpha",
//       "Longitude": 40.470901489258,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8313,
//       "Airport Code [ICAO]": "KUDG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Darlington County Airport",
//       "Altitude (Ft)": 192,
//       "Country": "United States",
//       "Latitude": -79.890098,
//       "Location": "Darlington",
//       "Longitude": 34.449401,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8314,
//       "Airport Code [ICAO]": "KHXD",
//       "Airport Code iATA,FAA": "HHH",
//       "Airport Name": "Hilton Head Airport",
//       "Altitude (Ft)": 19,
//       "Country": "United States",
//       "Latitude": -80.6975021362,
//       "Location": "Hilton Head Island",
//       "Longitude": 32.2243995667,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8318,
//       "Airport Code [ICAO]": "KDNL",
//       "Airport Code iATA,FAA": "DNL",
//       "Airport Name": "Daniel Field",
//       "Altitude (Ft)": 423,
//       "Country": "United States",
//       "Latitude": -82.0393981934,
//       "Location": "Augusta",
//       "Longitude": 33.4664993286,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8319,
//       "Airport Code [ICAO]": "KMRN",
//       "Airport Code iATA,FAA": "MRN",
//       "Airport Name": "Foothills Regional Airport",
//       "Altitude (Ft)": 1270,
//       "Country": "United States",
//       "Latitude": -81.611397,
//       "Location": "Morganton",
//       "Longitude": 35.820202,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8320,
//       "Airport Code [ICAO]": "KPBX",
//       "Airport Code iATA,FAA": "PVL",
//       "Airport Name": "Pike County-Hatcher Field",
//       "Altitude (Ft)": 1473,
//       "Country": "United States",
//       "Latitude": -82.56639862,
//       "Location": "Pikeville",
//       "Longitude": 37.5617981,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8321,
//       "Airport Code [ICAO]": "GA04",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mallards Landing Airport",
//       "Altitude (Ft)": 837,
//       "Country": "United States",
//       "Latitude": -84.16519927978516,
//       "Location": "Locust Grove",
//       "Longitude": 33.365699768066406,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8322,
//       "Airport Code [ICAO]": "KTOC",
//       "Airport Code iATA,FAA": "TOC",
//       "Airport Name": "Toccoa Airport - R.G. Letourneau Field",
//       "Altitude (Ft)": 996,
//       "Country": "United States",
//       "Latitude": -83.29579926,
//       "Location": "Toccoa",
//       "Longitude": 34.59379959,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8323,
//       "Airport Code [ICAO]": "EGHA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Compton Abbas Aerodrome",
//       "Altitude (Ft)": 811,
//       "Country": "United Kingdom",
//       "Latitude": -2.1536099910736084,
//       "Location": "Shaftesbury",
//       "Longitude": 50.967201232910156,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 8328,
//       "Airport Code [ICAO]": "UKHP",
//       "Airport Code iATA,FAA": "PLV",
//       "Airport Name": "Suprunovka Airport",
//       "Altitude (Ft)": 505,
//       "Country": "Ukraine",
//       "Latitude": 34.39720153808594,
//       "Location": "Poltava",
//       "Longitude": 49.568599700927734,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 8331,
//       "Airport Code [ICAO]": "HSAW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Aweil Airport",
//       "Altitude (Ft)": 1394,
//       "Country": "Sudan",
//       "Latitude": 27.399999618530273,
//       "Location": "Aweil",
//       "Longitude": 8.767000198364258,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Juba",
//       "Type": "airport"
//     },
//     {
//       "ID": 8332,
//       "Airport Code [ICAO]": "HSWW",
//       "Airport Code iATA,FAA": "WUU",
//       "Airport Name": "Wau Airport",
//       "Altitude (Ft)": 1529,
//       "Country": "Sudan",
//       "Latitude": 27.9750003815,
//       "Location": "Wau",
//       "Longitude": 7.7258300781199996,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Juba",
//       "Type": "airport"
//     },
//     {
//       "ID": 8333,
//       "Airport Code [ICAO]": "HAHU",
//       "Airport Code iATA,FAA": "HUE",
//       "Airport Name": "Humera Airport",
//       "Altitude (Ft)": 1930,
//       "Country": "Ethiopia",
//       "Latitude": 36.58300018310547,
//       "Location": "Humera",
//       "Longitude": 14.25,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Addis_Ababa",
//       "Type": "airport"
//     },
//     {
//       "ID": 8334,
//       "Airport Code [ICAO]": "HKMY",
//       "Airport Code iATA,FAA": "OYL",
//       "Airport Name": "Moyale Airport",
//       "Altitude (Ft)": 2790,
//       "Country": "Kenya",
//       "Latitude": 39.101398,
//       "Location": "Moyale",
//       "Longitude": 3.46972,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 8335,
//       "Airport Code [ICAO]": "GMAZ",
//       "Airport Code iATA,FAA": "OZG",
//       "Airport Name": "Zagora Airport",
//       "Altitude (Ft)": 2631,
//       "Country": "Morocco",
//       "Latitude": -5.86667013168,
//       "Location": "Zagora",
//       "Longitude": 30.3202991486,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": "Africa/Casablanca",
//       "Type": "airport"
//     },
//     {
//       "ID": 8336,
//       "Airport Code [ICAO]": "GFYE",
//       "Airport Code iATA,FAA": "WYE",
//       "Airport Name": "Yengema Airport",
//       "Altitude (Ft)": 1300,
//       "Country": "Sierra Leone",
//       "Latitude": -11.04539966583252,
//       "Location": "Yengema",
//       "Longitude": 8.610469818115234,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": "Africa/Freetown",
//       "Type": "airport"
//     },
//     {
//       "ID": 8337,
//       "Airport Code [ICAO]": "GFGK",
//       "Airport Code iATA,FAA": "GBK",
//       "Airport Name": "Gbangbatok Airport",
//       "Altitude (Ft)": 75,
//       "Country": "Sierra Leone",
//       "Latitude": -12.383000373840332,
//       "Location": "Gbangbatok",
//       "Longitude": 7.767000198364258,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": "Africa/Freetown",
//       "Type": "airport"
//     },
//     {
//       "ID": 8340,
//       "Airport Code [ICAO]": "UOTT",
//       "Airport Code iATA,FAA": "THX",
//       "Airport Name": "Turukhansk Airport",
//       "Altitude (Ft)": 128,
//       "Country": "Russia",
//       "Latitude": 87.9353027344,
//       "Location": "Turukhansk",
//       "Longitude": 65.797203064,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Krasnoyarsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 8341,
//       "Airport Code [ICAO]": "UNIP",
//       "Airport Code iATA,FAA": "TGP",
//       "Airport Name": "Podkamennaya Tunguska Airport",
//       "Altitude (Ft)": 213,
//       "Country": "Russia",
//       "Latitude": 89.994003,
//       "Location": "Bor",
//       "Longitude": 61.589699,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Krasnoyarsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 8342,
//       "Airport Code [ICAO]": "KAFW",
//       "Airport Code iATA,FAA": "AFW",
//       "Airport Name": "Fort Worth Alliance Airport",
//       "Altitude (Ft)": 722,
//       "Country": "United States",
//       "Latitude": -97.31880187990001,
//       "Location": "Fort Worth",
//       "Longitude": 32.9875984192,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8343,
//       "Airport Code [ICAO]": "K57C",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "East Troy Municipal Airport",
//       "Altitude (Ft)": 860,
//       "Country": "United States",
//       "Latitude": -88.37259674072266,
//       "Location": "East Troy",
//       "Longitude": 42.79719924926758,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8344,
//       "Airport Code [ICAO]": "UNLL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kolpashevo Airport",
//       "Altitude (Ft)": 243,
//       "Country": "Russia",
//       "Latitude": 82.93250274658203,
//       "Location": "Kolpashevo",
//       "Longitude": 58.32529830932617,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Krasnoyarsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 8347,
//       "Airport Code [ICAO]": "OKAS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ali Al Salem Air Base",
//       "Altitude (Ft)": 472,
//       "Country": "Kuwait",
//       "Latitude": 47.52080154418945,
//       "Location": "Kuwait",
//       "Longitude": 29.34670066833496,
//       "GMT Offset": "3",
//       "Daylight Savings": "A",
//       "Region": "Asia/Kuwait",
//       "Type": "airport"
//     },
//     {
//       "ID": 8348,
//       "Airport Code [ICAO]": "YREN",
//       "Airport Code iATA,FAA": "RMK",
//       "Airport Name": "Renmark Airport",
//       "Altitude (Ft)": 115,
//       "Country": "Australia",
//       "Latitude": 140.6739959716797,
//       "Location": "Renmark",
//       "Longitude": -34.1963996887207,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Adelaide",
//       "Type": "airport"
//     },
//     {
//       "ID": 8349,
//       "Airport Code [ICAO]": "YLEC",
//       "Airport Code iATA,FAA": "LGH",
//       "Airport Name": "Leigh Creek Airport",
//       "Altitude (Ft)": 856,
//       "Country": "Australia",
//       "Latitude": 138.42599487304688,
//       "Location": "Leigh Creek",
//       "Longitude": -30.59830093383789,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Adelaide",
//       "Type": "airport"
//     },
//     {
//       "ID": 8350,
//       "Airport Code [ICAO]": "YWBR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Warburton Airport",
//       "Altitude (Ft)": 1500,
//       "Country": "Australia",
//       "Latitude": 126.58300018310547,
//       "Location": "Warburton Community",
//       "Longitude": -26.128299713134766,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 8351,
//       "Airport Code [ICAO]": "YCUN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cunderdin Airport",
//       "Altitude (Ft)": 705,
//       "Country": "Australia",
//       "Latitude": 117.21700286865234,
//       "Location": "Cunderdin",
//       "Longitude": -31.62220001220703,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 8352,
//       "Airport Code [ICAO]": "YRTI",
//       "Airport Code iATA,FAA": "RTS",
//       "Airport Name": "Rottnest Island Airport",
//       "Altitude (Ft)": 12,
//       "Country": "Australia",
//       "Latitude": 115.540001,
//       "Location": "Rottnest Island",
//       "Longitude": -32.006699,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 8353,
//       "Airport Code [ICAO]": "YFRT",
//       "Airport Code iATA,FAA": "FOS",
//       "Airport Name": "Forrest Airport",
//       "Altitude (Ft)": 511,
//       "Country": "Australia",
//       "Latitude": 128.11500549316406,
//       "Location": "Forrest",
//       "Longitude": -30.83810043334961,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 8354,
//       "Airport Code [ICAO]": "YBLT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ballarat Airport",
//       "Altitude (Ft)": 1433,
//       "Country": "Australia",
//       "Latitude": 143.79100036621094,
//       "Location": "Ballarat",
//       "Longitude": -37.51169967651367,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Hobart",
//       "Type": "airport"
//     },
//     {
//       "ID": 8355,
//       "Airport Code [ICAO]": "CPV8",
//       "Airport Code iATA,FAA": "KEW",
//       "Airport Name": "Keewaywin Airport",
//       "Altitude (Ft)": 988,
//       "Country": "Canada",
//       "Latitude": -92.8364028931,
//       "Location": "Keewaywin",
//       "Longitude": 52.991100311299995,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 8356,
//       "Airport Code [ICAO]": "CYSP",
//       "Airport Code iATA,FAA": "YSP",
//       "Airport Name": "Marathon Airport",
//       "Altitude (Ft)": 1035,
//       "Country": "Canada",
//       "Latitude": -86.34439849853516,
//       "Location": "Marathon",
//       "Longitude": 48.75529861450195,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 8357,
//       "Airport Code [ICAO]": "CYHF",
//       "Airport Code iATA,FAA": "YHF",
//       "Airport Name": "Hearst René Fontaine Municipal Airport",
//       "Altitude (Ft)": 827,
//       "Country": "Canada",
//       "Latitude": -83.68609619140625,
//       "Location": "Hearst",
//       "Longitude": 49.71419906616211,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 8358,
//       "Airport Code [ICAO]": "CYHN",
//       "Airport Code iATA,FAA": "YHN",
//       "Airport Name": "Hornepayne Municipal Airport",
//       "Altitude (Ft)": 1099,
//       "Country": "Canada",
//       "Latitude": -84.75890350341797,
//       "Location": "Hornepayne",
//       "Longitude": 49.19309997558594,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 8359,
//       "Airport Code [ICAO]": "CYKX",
//       "Airport Code iATA,FAA": "YKX",
//       "Airport Name": "Kirkland Lake Airport",
//       "Altitude (Ft)": 1157,
//       "Country": "Canada",
//       "Latitude": -79.98139953613281,
//       "Location": "Kirkland Lake",
//       "Longitude": 48.21030044555664,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 8360,
//       "Airport Code [ICAO]": "CYMG",
//       "Airport Code iATA,FAA": "YMG",
//       "Airport Name": "Manitouwadge Airport",
//       "Altitude (Ft)": 1198,
//       "Country": "Canada",
//       "Latitude": -85.86060333251953,
//       "Location": "Manitouwadge",
//       "Longitude": 49.083900451660156,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 8361,
//       "Airport Code [ICAO]": "CYXZ",
//       "Airport Code iATA,FAA": "YXZ",
//       "Airport Name": "Wawa Airport",
//       "Altitude (Ft)": 942,
//       "Country": "Canada",
//       "Latitude": -84.78669738769531,
//       "Location": "Wawa",
//       "Longitude": 47.96670150756836,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 8362,
//       "Airport Code [ICAO]": "CYEM",
//       "Airport Code iATA,FAA": "YEM",
//       "Airport Name": "Manitoulin East Municipal Airport",
//       "Altitude (Ft)": 869,
//       "Country": "Canada",
//       "Latitude": -81.85810089111328,
//       "Location": "Manitowaning",
//       "Longitude": 45.84280014038086,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 8363,
//       "Airport Code [ICAO]": "CKD9",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Slate Falls Airport",
//       "Altitude (Ft)": 1355,
//       "Country": "Canada",
//       "Latitude": -91.66560363769531,
//       "Location": "Slate Falls",
//       "Longitude": 51.130001068115234,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 8364,
//       "Airport Code [ICAO]": "CNY3",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Collingwood Airport",
//       "Altitude (Ft)": 730,
//       "Country": "Canada",
//       "Latitude": -80.1583023071,
//       "Location": "Collingwood",
//       "Longitude": 44.4491996765,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 8365,
//       "Airport Code [ICAO]": "CYFD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Brantford Municipal Airport",
//       "Altitude (Ft)": 815,
//       "Country": "Canada",
//       "Latitude": -80.34249877929688,
//       "Location": "Brantford",
//       "Longitude": 43.13140106201172,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 8366,
//       "Airport Code [ICAO]": "KLWC",
//       "Airport Code iATA,FAA": "LWC",
//       "Airport Name": "Lawrence Municipal Airport",
//       "Altitude (Ft)": 833,
//       "Country": "United States",
//       "Latitude": -95.21659851,
//       "Location": "Lawrence",
//       "Longitude": 39.01119995,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8367,
//       "Airport Code [ICAO]": "KEGT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Wellington Municipal Airport",
//       "Altitude (Ft)": 1277,
//       "Country": "United States",
//       "Latitude": -97.38829803466797,
//       "Location": "Wellington",
//       "Longitude": 37.32360076904297,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8379,
//       "Airport Code [ICAO]": "KPMP",
//       "Airport Code iATA,FAA": "PPM",
//       "Airport Name": "Pompano Beach Airpark",
//       "Altitude (Ft)": 19,
//       "Country": "United States",
//       "Latitude": -80.111099243164,
//       "Location": "Pompano Beach",
//       "Longitude": 26.247100830078,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8380,
//       "Airport Code [ICAO]": "YMCO",
//       "Airport Code iATA,FAA": "XMC",
//       "Airport Name": "Mallacoota Airport",
//       "Altitude (Ft)": 31,
//       "Country": "Australia",
//       "Latitude": 149.72000122070312,
//       "Location": "Mallacoota",
//       "Longitude": -37.59830093383789,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Australia/Hobart",
//       "Type": "airport"
//     },
//     {
//       "ID": 8381,
//       "Airport Code [ICAO]": "OEAO",
//       "Airport Code iATA,FAA": "ULH",
//       "Airport Name": "Majeed Bin Abdulaziz Airport",
//       "Altitude (Ft)": 2050,
//       "Country": "Saudi Arabia",
//       "Latitude": 38.1288888889,
//       "Location": "Al-Ula",
//       "Longitude": 26.48,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Asia/Riyadh",
//       "Type": "airport"
//     },
//     {
//       "ID": 8394,
//       "Airport Code [ICAO]": "KEET",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Shelby County Airport",
//       "Altitude (Ft)": 586,
//       "Country": "United States",
//       "Latitude": -86.78279877,
//       "Location": "Alabaster",
//       "Longitude": 33.17699814,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8395,
//       "Airport Code [ICAO]": "YYND",
//       "Airport Code iATA,FAA": "YUE",
//       "Airport Name": "Yuendumu Airport",
//       "Altitude (Ft)": 2205,
//       "Country": "Australia",
//       "Latitude": 131.78199768066406,
//       "Location": "Yuendumu ",
//       "Longitude": -22.254199981689453,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Darwin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8397,
//       "Airport Code [ICAO]": "18AZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sky Ranch At Carefree Airport",
//       "Altitude (Ft)": 2568,
//       "Country": "United States",
//       "Latitude": -111.89800262451172,
//       "Location": "Carefree",
//       "Longitude": 33.81809997558594,
//       "GMT Offset": "-7",
//       "Daylight Savings": "N",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 8401,
//       "Airport Code [ICAO]": "WADL",
//       "Airport Code iATA,FAA": "LOP",
//       "Airport Name": "Lombok International Airport",
//       "Altitude (Ft)": 319,
//       "Country": "Indonesia",
//       "Latitude": 116.276675,
//       "Location": "Praya",
//       "Longitude": -8.757322,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 8403,
//       "Airport Code [ICAO]": "CAV3",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "One Hundred Mile House Airport",
//       "Altitude (Ft)": 3055,
//       "Country": "Canada",
//       "Latitude": -121.30699920654297,
//       "Location": "One Hundred Mile House",
//       "Longitude": 51.64250183105469,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 8404,
//       "Airport Code [ICAO]": "CZML",
//       "Airport Code iATA,FAA": "ZMH",
//       "Airport Name": "South Cariboo Region / 108 Mile Airport",
//       "Altitude (Ft)": 3126,
//       "Country": "Canada",
//       "Latitude": -121.333000183,
//       "Location": "108 Mile Ranch",
//       "Longitude": 51.736099243199995,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 8405,
//       "Airport Code [ICAO]": "EGEG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Glasgow City Heliport",
//       "Altitude (Ft)": 0,
//       "Country": "United Kingdom",
//       "Latitude": -4.2969441413879395,
//       "Location": "Glasgow",
//       "Longitude": 55.86138916015625,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 8406,
//       "Airport Code [ICAO]": "YYRM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Yarram Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Australia",
//       "Latitude": 146.7550048828125,
//       "Location": "Yarram",
//       "Longitude": -38.56669998168945,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Hobart",
//       "Type": "airport"
//     },
//     {
//       "ID": 8407,
//       "Airport Code [ICAO]": "ZBHD",
//       "Airport Code iATA,FAA": "HDG",
//       "Airport Name": "Handan Airport",
//       "Altitude (Ft)": 229,
//       "Country": "China",
//       "Latitude": 114.425555556,
//       "Location": "Handan",
//       "Longitude": 36.5258333333,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 8409,
//       "Airport Code [ICAO]": "KUMP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Indianapolis Metropolitan Airport",
//       "Altitude (Ft)": 811,
//       "Country": "United States",
//       "Latitude": -86.04499817,
//       "Location": "Indianapolis",
//       "Longitude": 39.93519974,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8410,
//       "Airport Code [ICAO]": "KLOZ",
//       "Airport Code iATA,FAA": "LOZ",
//       "Airport Name": "London-Corbin Airport/Magee Field",
//       "Altitude (Ft)": 1212,
//       "Country": "United States",
//       "Latitude": -84.08489990230001,
//       "Location": "London",
//       "Longitude": 37.0821990967,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8412,
//       "Airport Code [ICAO]": "KFBG",
//       "Airport Code iATA,FAA": "FBG",
//       "Airport Name": "Simmons Army Air Field",
//       "Altitude (Ft)": 244,
//       "Country": "United States",
//       "Latitude": -78.93669891,
//       "Location": "Fredericksburg",
//       "Longitude": 35.13180161,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8414,
//       "Airport Code [ICAO]": "EPMO",
//       "Airport Code iATA,FAA": "WMI",
//       "Airport Name": "Modlin Airport",
//       "Altitude (Ft)": 341,
//       "Country": "Poland",
//       "Latitude": 20.6518,
//       "Location": "Warsaw",
//       "Longitude": 52.451099,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 8417,
//       "Airport Code [ICAO]": "ZYJX",
//       "Airport Code iATA,FAA": "JXA",
//       "Airport Name": "Jixi Xingkaihu Airport",
//       "Altitude (Ft)": 760,
//       "Country": "China",
//       "Latitude": 131.193,
//       "Location": "Jixi",
//       "Longitude": 45.293,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 8419,
//       "Airport Code [ICAO]": "RKPD",
//       "Airport Code iATA,FAA": "JDG",
//       "Airport Name": "Jeongseok Airport",
//       "Altitude (Ft)": 1171,
//       "Country": "South Korea",
//       "Latitude": 126.711997986,
//       "Location": "Seogwipo",
//       "Longitude": 33.3996009827,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Seoul",
//       "Type": "airport"
//     },
//     {
//       "ID": 8423,
//       "Airport Code [ICAO]": "CYGM",
//       "Airport Code iATA,FAA": "YGM",
//       "Airport Name": "Gimli Industrial Park Airport",
//       "Altitude (Ft)": 753,
//       "Country": "Canada",
//       "Latitude": -97.04329681396484,
//       "Location": "Gimli",
//       "Longitude": 50.62810134887695,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 8426,
//       "Airport Code [ICAO]": "CJT2",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Matheson Island Airport",
//       "Altitude (Ft)": 725,
//       "Country": "Canada",
//       "Latitude": -96.93440246582031,
//       "Location": "Matheson Island",
//       "Longitude": 51.732200622558594,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Winnipeg",
//       "Type": "airport"
//     },
//     {
//       "ID": 8427,
//       "Airport Code [ICAO]": "UNIT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tura Mountain Airport",
//       "Altitude (Ft)": 2044,
//       "Country": "Russia",
//       "Latitude": 100.4328918457,
//       "Location": "Tura",
//       "Longitude": 64.333511352539,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Krasnoyarsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 8428,
//       "Airport Code [ICAO]": "USHY",
//       "Airport Code iATA,FAA": "EYK",
//       "Airport Name": "Beloyarskiy Airport",
//       "Altitude (Ft)": 82,
//       "Country": "Russia",
//       "Latitude": 66.698601,
//       "Location": "Beloyarsky",
//       "Longitude": 63.686901,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 8430,
//       "Airport Code [ICAO]": "KRAC",
//       "Airport Code iATA,FAA": "RAC",
//       "Airport Name": "John H Batten Airport",
//       "Altitude (Ft)": 674,
//       "Country": "United States",
//       "Latitude": -87.8152008057,
//       "Location": "Racine",
//       "Longitude": 42.7606010437,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8431,
//       "Airport Code [ICAO]": "RPSD",
//       "Airport Code iATA,FAA": "RZP",
//       "Airport Name": "Cesar Lim Rodriguez Airport",
//       "Altitude (Ft)": 80,
//       "Country": "Philippines",
//       "Latitude": 119.507697,
//       "Location": "Taytay",
//       "Longitude": 10.81874,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Manila",
//       "Type": "airport"
//     },
//     {
//       "ID": 8432,
//       "Airport Code [ICAO]": "EDAJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Gera-Leumnitz Airfield",
//       "Altitude (Ft)": 1014,
//       "Country": "Germany",
//       "Latitude": 12.135833,
//       "Location": "Gera",
//       "Longitude": 50.881668,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8435,
//       "Airport Code [ICAO]": "XLLN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kasimovo Airfield",
//       "Altitude (Ft)": 230,
//       "Country": "Russia",
//       "Latitude": 30.334999,
//       "Location": "Saint-Petersburg",
//       "Longitude": 60.198299,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 8438,
//       "Airport Code [ICAO]": "EHTL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Terlet Glider Field",
//       "Altitude (Ft)": 276,
//       "Country": "Netherlands",
//       "Latitude": 5.92444,
//       "Location": "Arnhem",
//       "Longitude": 52.057201,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Amsterdam",
//       "Type": "airport"
//     },
//     {
//       "ID": 8442,
//       "Airport Code [ICAO]": "ZURK",
//       "Airport Code iATA,FAA": "RKZ",
//       "Airport Name": "Shigatse Air Base",
//       "Altitude (Ft)": 3782,
//       "Country": "China",
//       "Latitude": 89.311401,
//       "Location": "Shigatse",
//       "Longitude": 29.3519,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 8443,
//       "Airport Code [ICAO]": "KREI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Redlands Municipal Airport",
//       "Altitude (Ft)": 1571,
//       "Country": "United States",
//       "Latitude": -117.14600372314453,
//       "Location": "Redlands",
//       "Longitude": 34.08530044555664,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 8445,
//       "Airport Code [ICAO]": "KRIR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Flabob Airport",
//       "Altitude (Ft)": 764,
//       "Country": "United States",
//       "Latitude": -117.41100311279297,
//       "Location": "Riverside",
//       "Longitude": 33.98970031738281,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 8446,
//       "Airport Code [ICAO]": "KTIW",
//       "Airport Code iATA,FAA": "TIW",
//       "Airport Name": "Tacoma Narrows Airport",
//       "Altitude (Ft)": 294,
//       "Country": "United States",
//       "Latitude": -122.5780029,
//       "Location": "Tacoma",
//       "Longitude": 47.26789856,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 8459,
//       "Airport Code [ICAO]": "EDLO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Oerlinghausen Airport",
//       "Altitude (Ft)": 558,
//       "Country": "Germany",
//       "Latitude": 8.661666870117188,
//       "Location": "TELGTE",
//       "Longitude": 51.932220458984375,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8460,
//       "Airport Code [ICAO]": "KJKA",
//       "Airport Code iATA,FAA": "GUF",
//       "Airport Name": "Jack Edwards Airport",
//       "Altitude (Ft)": 17,
//       "Country": "United States",
//       "Latitude": -87.67179871,
//       "Location": "Gulf Shores",
//       "Longitude": 30.29050064,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8461,
//       "Airport Code [ICAO]": "SEII",
//       "Airport Code iATA,FAA": "IBB",
//       "Airport Name": "General Villamil Airport",
//       "Altitude (Ft)": 35,
//       "Country": "Ecuador",
//       "Latitude": -90.9530029297,
//       "Location": "Isabela",
//       "Longitude": -0.942628026009,
//       "GMT Offset": "-6",
//       "Daylight Savings": "N",
//       "Region": "Pacific/Galapagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 8462,
//       "Airport Code [ICAO]": "LSXB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Balzers Heliport",
//       "Altitude (Ft)": 1585,
//       "Country": "Switzerland",
//       "Latitude": 9.48111,
//       "Location": "Balzers",
//       "Longitude": 47.0681,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vaduz",
//       "Type": "airport"
//     },
//     {
//       "ID": 8467,
//       "Airport Code [ICAO]": "LOGG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Flugplatz Punitz",
//       "Altitude (Ft)": 950,
//       "Country": "Austria",
//       "Latitude": 16.316843,
//       "Location": "Punitz-Guessing",
//       "Longitude": 47.14653,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vienna",
//       "Type": "airport"
//     },
//     {
//       "ID": 8468,
//       "Airport Code [ICAO]": "UKLH",
//       "Airport Code iATA,FAA": "HMJ",
//       "Airport Name": "Khmelnytskyi Airport",
//       "Altitude (Ft)": 1150,
//       "Country": "Ukraine",
//       "Latitude": 26.933399,
//       "Location": "Khmeinitskiy",
//       "Longitude": 49.359699,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 8469,
//       "Airport Code [ICAO]": "RJBH",
//       "Airport Code iATA,FAA": "HIW",
//       "Airport Name": "Hiroshimanishi Airport",
//       "Altitude (Ft)": 15,
//       "Country": "Japan",
//       "Latitude": 132.41400146484375,
//       "Location": "Hiroshima",
//       "Longitude": 34.36690139770508,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 8472,
//       "Airport Code [ICAO]": "YYTA",
//       "Airport Code iATA,FAA": "KYI",
//       "Airport Name": "Yalata Mission Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 131.8249969482422,
//       "Location": "Yalata",
//       "Longitude": -31.470600128173828,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Adelaide",
//       "Type": "airport"
//     },
//     {
//       "ID": 8473,
//       "Airport Code [ICAO]": "KHZL",
//       "Airport Code iATA,FAA": "HZL",
//       "Airport Name": "Hazleton Municipal Airport",
//       "Altitude (Ft)": 1603,
//       "Country": "United States",
//       "Latitude": -75.9949035645,
//       "Location": "Hazleton",
//       "Longitude": 40.986801147499996,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8474,
//       "Airport Code [ICAO]": "KCBE",
//       "Airport Code iATA,FAA": "CBE",
//       "Airport Name": "Greater Cumberland Regional Airport",
//       "Altitude (Ft)": 775,
//       "Country": "United States",
//       "Latitude": -78.7609024048,
//       "Location": "Cumberland",
//       "Longitude": 39.615398407,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8475,
//       "Airport Code [ICAO]": "7FA1",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sugar Loaf Shores Airport",
//       "Altitude (Ft)": 4,
//       "Country": "United States",
//       "Latitude": -81.57980346679688,
//       "Location": "Key West",
//       "Longitude": 24.648799896240234,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8476,
//       "Airport Code [ICAO]": "YWYM",
//       "Airport Code iATA,FAA": "WYN",
//       "Airport Name": "Wyndham Airport",
//       "Altitude (Ft)": 14,
//       "Country": "Australia",
//       "Latitude": 128.1529998779297,
//       "Location": "Wyndham",
//       "Longitude": -15.51140022277832,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 8477,
//       "Airport Code [ICAO]": "CBW4",
//       "Airport Code iATA,FAA": "YBO",
//       "Airport Name": "Bob Quinn Lake Airport",
//       "Altitude (Ft)": 2000,
//       "Country": "Canada",
//       "Latitude": -130.25,
//       "Location": "Bob Quinn Lake",
//       "Longitude": 56.9667015076,
//       "GMT Offset": "-8",
//       "Daylight Savings": "U",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 8480,
//       "Airport Code [ICAO]": "HTMR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Msembe Airport",
//       "Altitude (Ft)": 2500,
//       "Country": "Tanzania",
//       "Latitude": 34.922282,
//       "Location": "Msembe",
//       "Longitude": -7.684811,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Dar_es_Salaam",
//       "Type": "airport"
//     },
//     {
//       "ID": 8482,
//       "Airport Code [ICAO]": "UUBC",
//       "Airport Code iATA,FAA": "KLF",
//       "Airport Name": "Grabtsevo Airport",
//       "Altitude (Ft)": 656,
//       "Country": "Russia",
//       "Latitude": 36.3666687012,
//       "Location": "Kaluga",
//       "Longitude": 54.5499992371,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 8493,
//       "Airport Code [ICAO]": "KLNR",
//       "Airport Code iATA,FAA": "LNR",
//       "Airport Name": "Tri-County Regional Airport",
//       "Altitude (Ft)": 717,
//       "Country": "United States",
//       "Latitude": -90.181602478,
//       "Location": "Lone Rock",
//       "Longitude": 43.2117004395,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8494,
//       "Airport Code [ICAO]": "KPBH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Price County Airport",
//       "Altitude (Ft)": 1497,
//       "Country": "United States",
//       "Latitude": -90.40249633789062,
//       "Location": "Phillips",
//       "Longitude": 45.70899963378906,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8495,
//       "Airport Code [ICAO]": "KEFT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Monroe Municipal Airport",
//       "Altitude (Ft)": 1086,
//       "Country": "United States",
//       "Latitude": -89.59040069580078,
//       "Location": "Monroe",
//       "Longitude": 42.614898681640625,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8496,
//       "Airport Code [ICAO]": "KJOT",
//       "Airport Code iATA,FAA": "JOT",
//       "Airport Name": "Joliet Regional Airport",
//       "Altitude (Ft)": 582,
//       "Country": "United States",
//       "Latitude": -88.17549896,
//       "Location": "Joliet",
//       "Longitude": 41.51779938,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8497,
//       "Airport Code [ICAO]": "KVYS",
//       "Airport Code iATA,FAA": "VYS",
//       "Airport Name": "Illinois Valley Regional Airport-Walter A Duncan Field",
//       "Altitude (Ft)": 654,
//       "Country": "United States",
//       "Latitude": -89.153099,
//       "Location": "Peru",
//       "Longitude": 41.351898,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8499,
//       "Airport Code [ICAO]": "KJXN",
//       "Airport Code iATA,FAA": "JXN",
//       "Airport Name": "Jackson County Reynolds Field",
//       "Altitude (Ft)": 1001,
//       "Country": "United States",
//       "Latitude": -84.45939636230001,
//       "Location": "Jackson",
//       "Longitude": 42.259799957300004,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8500,
//       "Airport Code [ICAO]": "EDAL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Fuerstenwalde Airport",
//       "Altitude (Ft)": 184,
//       "Country": "Germany",
//       "Latitude": 14.0972003937,
//       "Location": "Furstenwalde",
//       "Longitude": 52.3905982971,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8501,
//       "Airport Code [ICAO]": "EDAV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Eberswalde-Finow Airport",
//       "Altitude (Ft)": 121,
//       "Country": "Germany",
//       "Latitude": 13.693611145019531,
//       "Location": "Eberswalde",
//       "Longitude": 52.82722091674805,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8502,
//       "Airport Code [ICAO]": "KVVS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Joseph A. Hardy Connellsville Airport",
//       "Altitude (Ft)": 1267,
//       "Country": "United States",
//       "Latitude": -79.65709686,
//       "Location": "Connellsville",
//       "Longitude": 39.95920181,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8503,
//       "Airport Code [ICAO]": "KHMZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bedford County Airport",
//       "Altitude (Ft)": 1162,
//       "Country": "United States",
//       "Latitude": -78.5121994,
//       "Location": "Bedford",
//       "Longitude": 40.08530045,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8504,
//       "Airport Code [ICAO]": "KLOM",
//       "Airport Code iATA,FAA": "BBX",
//       "Airport Name": "Wings Field",
//       "Altitude (Ft)": 302,
//       "Country": "United States",
//       "Latitude": -75.2650985718,
//       "Location": "Philadelphia",
//       "Longitude": 40.1375007629,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8505,
//       "Airport Code [ICAO]": "KOBE",
//       "Airport Code iATA,FAA": "OBE",
//       "Airport Name": "Okeechobee County Airport",
//       "Altitude (Ft)": 34,
//       "Country": "United States",
//       "Latitude": -80.8498001099,
//       "Location": "Okeechobee",
//       "Longitude": 27.262800216699997,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8506,
//       "Airport Code [ICAO]": "KSEF",
//       "Airport Code iATA,FAA": "SEF",
//       "Airport Name": "Sebring Regional Airport",
//       "Altitude (Ft)": 62,
//       "Country": "United States",
//       "Latitude": -81.3423996,
//       "Location": "Sebring",
//       "Longitude": 27.45639992,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8507,
//       "Airport Code [ICAO]": "KAVO",
//       "Airport Code iATA,FAA": "AVO",
//       "Airport Name": "Avon Park Executive Airport",
//       "Altitude (Ft)": 160,
//       "Country": "United States",
//       "Latitude": -81.52780151,
//       "Location": "Avon Park",
//       "Longitude": 27.59119987,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8508,
//       "Airport Code [ICAO]": "KGIF",
//       "Airport Code iATA,FAA": "GIF",
//       "Airport Name": "Winter Haven Regional Airport - Gilbert Field",
//       "Altitude (Ft)": 145,
//       "Country": "United States",
//       "Latitude": -81.753304,
//       "Location": "Winter Haven",
//       "Longitude": 28.062901,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8509,
//       "Airport Code [ICAO]": "KZPH",
//       "Airport Code iATA,FAA": "ZPH",
//       "Airport Name": "Zephyrhills Municipal Airport",
//       "Altitude (Ft)": 90,
//       "Country": "United States",
//       "Latitude": -82.15589904790001,
//       "Location": "Zephyrhills",
//       "Longitude": 28.2282009125,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8510,
//       "Airport Code [ICAO]": "KOCF",
//       "Airport Code iATA,FAA": "OCF",
//       "Airport Name": "Ocala International Airport - Jim Taylor Field",
//       "Altitude (Ft)": 90,
//       "Country": "United States",
//       "Latitude": -82.22419739,
//       "Location": "Ocala",
//       "Longitude": 29.17259979,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8511,
//       "Airport Code [ICAO]": "KJES",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Jesup Wayne County Airport",
//       "Altitude (Ft)": 107,
//       "Country": "United States",
//       "Latitude": -81.88249969,
//       "Location": "Jesup",
//       "Longitude": 31.55400085,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8512,
//       "Airport Code [ICAO]": "K52A",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Madison Municipal Airport",
//       "Altitude (Ft)": 694,
//       "Country": "United States",
//       "Latitude": -83.46040344240001,
//       "Location": "Madison",
//       "Longitude": 33.6120986938,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8513,
//       "Airport Code [ICAO]": "KCCO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Newnan Coweta County Airport",
//       "Altitude (Ft)": 970,
//       "Country": "United States",
//       "Latitude": -84.7697982788086,
//       "Location": "Newnan",
//       "Longitude": 33.31159973144531,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8514,
//       "Airport Code [ICAO]": "KHQU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Thomson-McDuffie County Airport",
//       "Altitude (Ft)": 501,
//       "Country": "United States",
//       "Latitude": -82.51650238,
//       "Location": "Thomson",
//       "Longitude": 33.52970123,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8515,
//       "Airport Code [ICAO]": "KAIK",
//       "Airport Code iATA,FAA": "AIK",
//       "Airport Name": "Aiken Regional Airport",
//       "Altitude (Ft)": 528,
//       "Country": "United States",
//       "Latitude": -81.684998,
//       "Location": "Aiken",
//       "Longitude": 33.649399,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8516,
//       "Airport Code [ICAO]": "KCDN",
//       "Airport Code iATA,FAA": "CDN",
//       "Airport Name": "Woodward Field",
//       "Altitude (Ft)": 302,
//       "Country": "United States",
//       "Latitude": -80.56490325930001,
//       "Location": "Camden",
//       "Longitude": 34.2835998535,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8517,
//       "Airport Code [ICAO]": "KLBT",
//       "Airport Code iATA,FAA": "LBT",
//       "Airport Name": "Lumberton Regional Airport",
//       "Altitude (Ft)": 126,
//       "Country": "United States",
//       "Latitude": -79.05940246579999,
//       "Location": "Lumberton",
//       "Longitude": 34.6099014282,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8519,
//       "Airport Code [ICAO]": "KSOP",
//       "Airport Code iATA,FAA": "SOP",
//       "Airport Name": "Moore County Airport",
//       "Altitude (Ft)": 455,
//       "Country": "United States",
//       "Latitude": -79.3911972,
//       "Location": "Pinehurst-Southern Pines",
//       "Longitude": 35.23740005,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8520,
//       "Airport Code [ICAO]": "KRCZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Richmond County Airport",
//       "Altitude (Ft)": 358,
//       "Country": "United States",
//       "Latitude": -79.759598,
//       "Location": "Rockingham",
//       "Longitude": 34.8913,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8524,
//       "Airport Code [ICAO]": "KDLL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Baraboo Wisconsin Dells Airport",
//       "Altitude (Ft)": 979,
//       "Country": "United States",
//       "Latitude": -89.77020264,
//       "Location": "Baraboo",
//       "Longitude": 43.52270126,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8526,
//       "Airport Code [ICAO]": "KSVH",
//       "Airport Code iATA,FAA": "SVH",
//       "Airport Name": "Statesville Regional Airport",
//       "Altitude (Ft)": 968,
//       "Country": "United States",
//       "Latitude": -80.953903198242,
//       "Location": "Statesville",
//       "Longitude": 35.765300750732,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8528,
//       "Airport Code [ICAO]": "KBUU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Burlington Municipal Airport",
//       "Altitude (Ft)": 779,
//       "Country": "United States",
//       "Latitude": -88.30460357666016,
//       "Location": "Burlington",
//       "Longitude": 42.69070053100586,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8532,
//       "Airport Code [ICAO]": "KLHV",
//       "Airport Code iATA,FAA": "LHV",
//       "Airport Name": "William T. Piper Memorial Airport",
//       "Altitude (Ft)": 556,
//       "Country": "United States",
//       "Latitude": -77.42230225,
//       "Location": "Lock Haven",
//       "Longitude": 41.13560104,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8538,
//       "Airport Code [ICAO]": "KPJC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Zelienople Municipal Airport",
//       "Altitude (Ft)": 898,
//       "Country": "United States",
//       "Latitude": -80.16069794,
//       "Location": "Zelienople",
//       "Longitude": 40.80160141,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8542,
//       "Airport Code [ICAO]": "KLPR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lorain County Regional Airport",
//       "Altitude (Ft)": 793,
//       "Country": "United States",
//       "Latitude": -82.17759705,
//       "Location": "Lorain-Elyria",
//       "Longitude": 41.34429932,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8544,
//       "Airport Code [ICAO]": "KBKL",
//       "Airport Code iATA,FAA": "BKL",
//       "Airport Name": "Burke Lakefront Airport",
//       "Altitude (Ft)": 583,
//       "Country": "United States",
//       "Latitude": -81.68329620361328,
//       "Location": "Cleveland",
//       "Longitude": 41.51750183105469,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8545,
//       "Airport Code [ICAO]": "KDKK",
//       "Airport Code iATA,FAA": "DKK",
//       "Airport Name": "Chautauqua County-Dunkirk Airport",
//       "Altitude (Ft)": 693,
//       "Country": "United States",
//       "Latitude": -79.27200317,
//       "Location": "Dunkirk",
//       "Longitude": 42.49330139,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8548,
//       "Airport Code [ICAO]": "KVAY",
//       "Airport Code iATA,FAA": "LLY",
//       "Airport Name": "South Jersey Regional Airport",
//       "Altitude (Ft)": 53,
//       "Country": "United States",
//       "Latitude": -74.845703125,
//       "Location": "Mount Holly",
//       "Longitude": 39.942901611299995,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8550,
//       "Airport Code [ICAO]": "KLDJ",
//       "Airport Code iATA,FAA": "LDJ",
//       "Airport Name": "Linden Airport",
//       "Altitude (Ft)": 23,
//       "Country": "United States",
//       "Latitude": -74.2445983887,
//       "Location": "Linden",
//       "Longitude": 40.617401123,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8553,
//       "Airport Code [ICAO]": "KANQ",
//       "Airport Code iATA,FAA": "ANQ",
//       "Airport Name": "Tri State Steuben County Airport",
//       "Altitude (Ft)": 995,
//       "Country": "United States",
//       "Latitude": -85.083504,
//       "Location": "Angola",
//       "Longitude": 41.639702,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8555,
//       "Airport Code [ICAO]": "KASW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Warsaw Municipal Airport",
//       "Altitude (Ft)": 850,
//       "Country": "United States",
//       "Latitude": -85.84010314941406,
//       "Location": "Warsaw",
//       "Longitude": 41.27470016479492,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8556,
//       "Airport Code [ICAO]": "KVNW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Van Wert County Airport",
//       "Altitude (Ft)": 785,
//       "Country": "United States",
//       "Latitude": -84.6093978881836,
//       "Location": "Van Wert",
//       "Longitude": 40.86470031738281,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8559,
//       "Airport Code [ICAO]": "KRMY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Brooks Field",
//       "Altitude (Ft)": 941,
//       "Country": "United States",
//       "Latitude": -84.95549774169922,
//       "Location": "Marshall",
//       "Longitude": 42.25120162963867,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8560,
//       "Airport Code [ICAO]": "KGVQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Genesee County Airport",
//       "Altitude (Ft)": 914,
//       "Country": "United States",
//       "Latitude": -78.16760254,
//       "Location": "Batavia",
//       "Longitude": 43.03170013,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8565,
//       "Airport Code [ICAO]": "KCLW",
//       "Airport Code iATA,FAA": "CLW",
//       "Airport Name": "Clearwater Air Park",
//       "Altitude (Ft)": 71,
//       "Country": "United States",
//       "Latitude": -82.7586975098,
//       "Location": "Clearwater",
//       "Longitude": 27.9766998291,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8577,
//       "Airport Code [ICAO]": "FA08",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Orlampa Inc Airport",
//       "Altitude (Ft)": 139,
//       "Country": "United Kingdom",
//       "Latitude": -81.80809783939999,
//       "Location": "Forties Alpha",
//       "Longitude": 28.166999816900002,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8593,
//       "Airport Code [ICAO]": "KCGX",
//       "Airport Code iATA,FAA": "CGX",
//       "Airport Name": "Chicago Meigs Airport",
//       "Altitude (Ft)": 593,
//       "Country": "United States",
//       "Latitude": -87.60790252685547,
//       "Location": "Chicago",
//       "Longitude": 41.85879898071289,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8601,
//       "Airport Code [ICAO]": "KJZP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Pickens County Airport",
//       "Altitude (Ft)": 1535,
//       "Country": "United States",
//       "Latitude": -84.4573974609375,
//       "Location": "Jasper",
//       "Longitude": 34.453399658203125,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8603,
//       "Airport Code [ICAO]": "EDCS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Saarmund Airport",
//       "Altitude (Ft)": 174,
//       "Country": "Germany",
//       "Latitude": 13.100556373596191,
//       "Location": "Saarmund",
//       "Longitude": 52.30833435058594,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8604,
//       "Airport Code [ICAO]": "KCRE",
//       "Airport Code iATA,FAA": "CRE",
//       "Airport Name": "Grand Strand Airport",
//       "Altitude (Ft)": 32,
//       "Country": "United States",
//       "Latitude": -78.72389984130001,
//       "Location": "North Myrtle Beach",
//       "Longitude": 33.8116989136,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8606,
//       "Airport Code [ICAO]": "KIGQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lansing Municipal Airport",
//       "Altitude (Ft)": 620,
//       "Country": "United States",
//       "Latitude": -87.52950286865234,
//       "Location": "Lansing",
//       "Longitude": 41.5349006652832,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8608,
//       "Airport Code [ICAO]": "KRNM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ramona Airport",
//       "Altitude (Ft)": 1395,
//       "Country": "United States",
//       "Latitude": -116.915001,
//       "Location": "Ramona",
//       "Longitude": 33.0392,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 8609,
//       "Airport Code [ICAO]": "LFGO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Pont Sur Yonne Airfield",
//       "Altitude (Ft)": 236,
//       "Country": "France",
//       "Latitude": 3.247912,
//       "Location": "Pont Sur Yonne",
//       "Longitude": 48.289172,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 8610,
//       "Airport Code [ICAO]": "LFGP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "St Florentin Cheu Airfield",
//       "Altitude (Ft)": 351,
//       "Country": "France",
//       "Latitude": 3.775583,
//       "Location": "St Florentin Cheu",
//       "Longitude": 47.98019,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 8611,
//       "Airport Code [ICAO]": "LFEW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Saulieu Liernais Airfield",
//       "Altitude (Ft)": 1722,
//       "Country": "France",
//       "Latitude": 4.26583,
//       "Location": "Saulieu",
//       "Longitude": 47.239399,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 8612,
//       "Airport Code [ICAO]": "LSPO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Olten Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Switzerland",
//       "Latitude": 7.889440059661865,
//       "Location": "Olten",
//       "Longitude": 47.344398498535156,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 8613,
//       "Airport Code [ICAO]": "LSZC",
//       "Airport Code iATA,FAA": "BXO",
//       "Airport Name": "Buochs Airport",
//       "Altitude (Ft)": 1475,
//       "Country": "Switzerland",
//       "Latitude": 8.396944,
//       "Location": "Buochs",
//       "Longitude": 46.974444,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 8614,
//       "Airport Code [ICAO]": "LSPM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ambri Airport",
//       "Altitude (Ft)": 3241,
//       "Country": "Switzerland",
//       "Latitude": 8.68978,
//       "Location": "Quinto",
//       "Longitude": 46.512501,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 8615,
//       "Airport Code [ICAO]": "LSML",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lodrino Air Base",
//       "Altitude (Ft)": 0,
//       "Country": "Switzerland",
//       "Latitude": 8.992130279541016,
//       "Location": "Lodrino",
//       "Longitude": 46.2958984375,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 8616,
//       "Airport Code [ICAO]": "LKRO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Roudnice Airport",
//       "Altitude (Ft)": 732,
//       "Country": "Czech Republic",
//       "Latitude": 14.2261,
//       "Location": "Roudnice nad Lebem",
//       "Longitude": 50.41059875,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Prague",
//       "Type": "airport"
//     },
//     {
//       "ID": 8617,
//       "Airport Code [ICAO]": "LKUL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Usti Nad Labem Airfield",
//       "Altitude (Ft)": 791,
//       "Country": "Czech Republic",
//       "Latitude": 13.9697,
//       "Location": "Usti Nad Labem",
//       "Longitude": 50.699699,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Prague",
//       "Type": "airport"
//     },
//     {
//       "ID": 8618,
//       "Airport Code [ICAO]": "LOSM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mauterndorf Airport",
//       "Altitude (Ft)": 3642,
//       "Country": "Austria",
//       "Latitude": 13.696900367736816,
//       "Location": "Mauterndorf",
//       "Longitude": 47.13249969482422,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vienna",
//       "Type": "airport"
//     },
//     {
//       "ID": 8619,
//       "Airport Code [ICAO]": "LOKN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nötsch Im Gailtal Airport",
//       "Altitude (Ft)": 1801,
//       "Country": "Austria",
//       "Latitude": 13.629199981689453,
//       "Location": "Notsch Im Gailtal",
//       "Longitude": 46.58110046386719,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vienna",
//       "Type": "airport"
//     },
//     {
//       "ID": 8620,
//       "Airport Code [ICAO]": "EDTK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sinsheim Airfield",
//       "Altitude (Ft)": 525,
//       "Country": "Germany",
//       "Latitude": 8.8938,
//       "Location": "Karlsruhe",
//       "Longitude": 49.2472,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8621,
//       "Airport Code [ICAO]": "EDGZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Weinheim/Bergstraße Airport",
//       "Altitude (Ft)": 318,
//       "Country": "Germany",
//       "Latitude": 8.610555648803711,
//       "Location": "Weinheim",
//       "Longitude": 49.567501068115234,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8622,
//       "Airport Code [ICAO]": "LFPE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Meaux Esbly Airport",
//       "Altitude (Ft)": 217,
//       "Country": "France",
//       "Latitude": 2.835279941558838,
//       "Location": "Meaux",
//       "Longitude": 48.927799224853516,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 8623,
//       "Airport Code [ICAO]": "LFFH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Château-Thierry - Belleau Airfield",
//       "Altitude (Ft)": 728,
//       "Country": "France",
//       "Latitude": 3.35694,
//       "Location": "Chateau-Thierry",
//       "Longitude": 49.0672,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 8624,
//       "Airport Code [ICAO]": "KOEB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Branch County Memorial Airport",
//       "Altitude (Ft)": 959,
//       "Country": "United States",
//       "Latitude": -85.05259705,
//       "Location": "Coldwater",
//       "Longitude": 41.9333992,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8625,
//       "Airport Code [ICAO]": "KWBW",
//       "Airport Code iATA,FAA": "WBW",
//       "Airport Name": "Wilkes Barre Wyoming Valley Airport",
//       "Altitude (Ft)": 545,
//       "Country": "United States",
//       "Latitude": -75.8511962891,
//       "Location": "Wilkes-Barre",
//       "Longitude": 41.2971992493,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8626,
//       "Airport Code [ICAO]": "KLNN",
//       "Airport Code iATA,FAA": "LNN",
//       "Airport Name": "Willoughby Lost Nation Municipal Airport",
//       "Altitude (Ft)": 626,
//       "Country": "United States",
//       "Latitude": -81.3897018433,
//       "Location": "Willoughby",
//       "Longitude": 41.683998107899995,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8627,
//       "Airport Code [ICAO]": "RCGM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Taoyuan Air Base",
//       "Altitude (Ft)": 144,
//       "Country": "Taiwan",
//       "Latitude": 121.2442626953125,
//       "Location": "Taoyuan",
//       "Longitude": 25.05660057067871,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Taipei",
//       "Type": "airport"
//     },
//     {
//       "ID": 8628,
//       "Airport Code [ICAO]": "BGUM",
//       "Airport Code iATA,FAA": "UMD",
//       "Airport Name": "Uummannaq Heliport",
//       "Altitude (Ft)": 50,
//       "Country": "Greenland",
//       "Latitude": -52.111630439799995,
//       "Location": "Uummannaq",
//       "Longitude": 70.6804279261,
//       "GMT Offset": "-3",
//       "Daylight Savings": "E",
//       "Region": "America/Godthab",
//       "Type": "airport"
//     },
//     {
//       "ID": 8630,
//       "Airport Code [ICAO]": "ZBYZ",
//       "Airport Code iATA,FAA": "RLK",
//       "Airport Name": "Bayannur Tianjitai Airport",
//       "Altitude (Ft)": 3400,
//       "Country": "China",
//       "Latitude": 107.7428,
//       "Location": "Bayannur",
//       "Longitude": 40.926,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 8631,
//       "Airport Code [ICAO]": "KFFT",
//       "Airport Code iATA,FAA": "FFT",
//       "Airport Name": "Capital City Airport",
//       "Altitude (Ft)": 806,
//       "Country": "United States",
//       "Latitude": -84.90470123,
//       "Location": "Frankfort",
//       "Longitude": 38.18249893,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8632,
//       "Airport Code [ICAO]": "KLEW",
//       "Airport Code iATA,FAA": "LEW",
//       "Airport Name": "Auburn Lewiston Municipal Airport",
//       "Altitude (Ft)": 288,
//       "Country": "United States",
//       "Latitude": -70.2835006714,
//       "Location": "Lewiston",
//       "Longitude": 44.048500061,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8633,
//       "Airport Code [ICAO]": "EPIR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Inowroclaw Military Air Base",
//       "Altitude (Ft)": 259,
//       "Country": "Poland",
//       "Latitude": 18.3306007385,
//       "Location": "Inowroclaw",
//       "Longitude": 52.8293991089,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 8634,
//       "Airport Code [ICAO]": "EPPR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Pruszcz Gdanski Air Base",
//       "Altitude (Ft)": 21,
//       "Country": "Poland",
//       "Latitude": 18.6716,
//       "Location": "Pruszcz Gdansk",
//       "Longitude": 54.248001,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 8639,
//       "Airport Code [ICAO]": "KY72",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bloyer Field",
//       "Altitude (Ft)": 966,
//       "Country": "United States",
//       "Latitude": -90.48349762,
//       "Location": "Tomah",
//       "Longitude": 43.97499847,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8641,
//       "Airport Code [ICAO]": "KMKY",
//       "Airport Code iATA,FAA": "MRK",
//       "Airport Name": "Marco Island Executive Airport",
//       "Altitude (Ft)": 5,
//       "Country": "United States",
//       "Latitude": -81.672501,
//       "Location": "Marco Island Airport",
//       "Longitude": 25.995001,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8644,
//       "Airport Code [ICAO]": "KDRM",
//       "Airport Code iATA,FAA": "DRE",
//       "Airport Name": "Drummond Island Airport",
//       "Altitude (Ft)": 668,
//       "Country": "United States",
//       "Latitude": -83.74389648440001,
//       "Location": "Drummond Island",
//       "Longitude": 46.0093002319,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8646,
//       "Airport Code [ICAO]": "KGDW",
//       "Airport Code iATA,FAA": "GDW",
//       "Airport Name": "Gladwin Zettel Memorial Airport",
//       "Altitude (Ft)": 776,
//       "Country": "United States",
//       "Latitude": -84.47499847410002,
//       "Location": "Gladwin",
//       "Longitude": 43.9706001282,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8648,
//       "Airport Code [ICAO]": "KLWA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "South Haven Area Regional Airport",
//       "Altitude (Ft)": 666,
//       "Country": "United States",
//       "Latitude": -86.25569915771484,
//       "Location": "South Haven",
//       "Longitude": 42.351200103759766,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8653,
//       "Airport Code [ICAO]": "KMFI",
//       "Airport Code iATA,FAA": "MFI",
//       "Airport Name": "Marshfield Municipal Airport",
//       "Altitude (Ft)": 1277,
//       "Country": "United States",
//       "Latitude": -90.18930053710001,
//       "Location": "Marshfield",
//       "Longitude": 44.6369018555,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8654,
//       "Airport Code [ICAO]": "KISW",
//       "Airport Code iATA,FAA": "ISW",
//       "Airport Name": "Alexander Field South Wood County Airport",
//       "Altitude (Ft)": 1021,
//       "Country": "United States",
//       "Latitude": -89.83899688720001,
//       "Location": "Wisconsin Rapids",
//       "Longitude": 44.3602981567,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8655,
//       "Airport Code [ICAO]": "KCWI",
//       "Airport Code iATA,FAA": "CWI",
//       "Airport Name": "Clinton Municipal Airport",
//       "Altitude (Ft)": 708,
//       "Country": "United States",
//       "Latitude": -90.3291015625,
//       "Location": "Clinton",
//       "Longitude": 41.8311004639,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8658,
//       "Airport Code [ICAO]": "KBVY",
//       "Airport Code iATA,FAA": "BVY",
//       "Airport Name": "Beverly Municipal Airport",
//       "Altitude (Ft)": 107,
//       "Country": "United States",
//       "Latitude": -70.91649627689999,
//       "Location": "Beverly",
//       "Longitude": 42.584201812699995,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8660,
//       "Airport Code [ICAO]": "VI73",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nagaur Airport",
//       "Altitude (Ft)": 950,
//       "Country": "India",
//       "Latitude": 73.7114028930664,
//       "Location": "Nagaur",
//       "Longitude": 27.20829963684082,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 8661,
//       "Airport Code [ICAO]": "UUMO",
//       "Airport Code iATA,FAA": "OSF",
//       "Airport Name": "Ostafyevo International Airport",
//       "Altitude (Ft)": 568,
//       "Country": "Russia",
//       "Latitude": 37.507222,
//       "Location": "Moscow",
//       "Longitude": 55.511667,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 8663,
//       "Airport Code [ICAO]": "CYRQ",
//       "Airport Code iATA,FAA": "YRQ",
//       "Airport Name": "Trois-Rivières Airport",
//       "Altitude (Ft)": 199,
//       "Country": "Canada",
//       "Latitude": -72.67939758300781,
//       "Location": "Trois Rivieres",
//       "Longitude": 46.35279846191406,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 8664,
//       "Airport Code [ICAO]": "KPOF",
//       "Airport Code iATA,FAA": "POF",
//       "Airport Name": "Poplar Bluff Municipal Airport",
//       "Altitude (Ft)": 331,
//       "Country": "United States",
//       "Latitude": -90.324897766113,
//       "Location": "Poplar Bluff",
//       "Longitude": 36.773899078369,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8665,
//       "Airport Code [ICAO]": "KSMQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Somerset Airport",
//       "Altitude (Ft)": 105,
//       "Country": "United States",
//       "Latitude": -74.67019653320312,
//       "Location": "Somerville",
//       "Longitude": 40.625999450683594,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8666,
//       "Airport Code [ICAO]": "KEPM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Eastport Municipal Airport",
//       "Altitude (Ft)": 45,
//       "Country": "United States",
//       "Latitude": -67.01270294189453,
//       "Location": "Eastport",
//       "Longitude": 44.910099029541016,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8667,
//       "Airport Code [ICAO]": "KEOK",
//       "Airport Code iATA,FAA": "EOK",
//       "Airport Name": "Keokuk Municipal Airport",
//       "Altitude (Ft)": 671,
//       "Country": "United States",
//       "Latitude": -91.4284973145,
//       "Location": "Keokuk",
//       "Longitude": 40.459899902299995,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8669,
//       "Airport Code [ICAO]": "EGPT",
//       "Airport Code iATA,FAA": "PSL",
//       "Airport Name": "Perth/Scone Airport",
//       "Altitude (Ft)": 397,
//       "Country": "United Kingdom",
//       "Latitude": -3.372220039367676,
//       "Location": "Perth",
//       "Longitude": 56.43920135498047,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 8670,
//       "Airport Code [ICAO]": "EGCK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Caernarfon Airport",
//       "Altitude (Ft)": 1,
//       "Country": "United Kingdom",
//       "Latitude": -4.337614,
//       "Location": "Caernarfon",
//       "Longitude": 53.101819,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 8671,
//       "Airport Code [ICAO]": "EDLF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Grefrath-Niershorst Airport",
//       "Altitude (Ft)": 105,
//       "Country": "Germany",
//       "Latitude": 6.3594441413879395,
//       "Location": "Grefrath",
//       "Longitude": 51.33388900756836,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8681,
//       "Airport Code [ICAO]": "KSTP",
//       "Airport Code iATA,FAA": "STP",
//       "Airport Name": "St Paul Downtown Holman Field",
//       "Altitude (Ft)": 705,
//       "Country": "United States",
//       "Latitude": -93.05999755859375,
//       "Location": "St. Paul",
//       "Longitude": 44.93450164794922,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8682,
//       "Airport Code [ICAO]": "ESNY",
//       "Airport Code iATA,FAA": "SOO",
//       "Airport Name": "Söderhamn Airport",
//       "Altitude (Ft)": 88,
//       "Country": "Sweden",
//       "Latitude": 17.09910011291504,
//       "Location": "Soderhamn",
//       "Longitude": 61.26150131225586,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 8683,
//       "Airport Code [ICAO]": "EINC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Newcastle Aerodrome",
//       "Altitude (Ft)": 1,
//       "Country": "Ireland",
//       "Latitude": -6.0452799797058105,
//       "Location": "Newcastle",
//       "Longitude": 53.07109832763672,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Dublin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8684,
//       "Airport Code [ICAO]": "VLSV",
//       "Airport Code iATA,FAA": "VNA",
//       "Airport Name": "Saravane Airport",
//       "Altitude (Ft)": 574,
//       "Country": "Laos",
//       "Latitude": 106.410698891,
//       "Location": "Saravane",
//       "Longitude": 15.709439207700001,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Vientiane",
//       "Type": "airport"
//     },
//     {
//       "ID": 8689,
//       "Airport Code [ICAO]": "EDAI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Segeletz Airport",
//       "Altitude (Ft)": 141,
//       "Country": "Germany",
//       "Latitude": 12.541943550109863,
//       "Location": "Segeletz",
//       "Longitude": 52.82666778564453,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8690,
//       "Airport Code [ICAO]": "LEFM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Fuentemilanos Airport",
//       "Altitude (Ft)": 3307,
//       "Country": "Spain",
//       "Latitude": -4.23750019073,
//       "Location": "Segovia",
//       "Longitude": 40.888610839799995,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Madrid",
//       "Type": "airport"
//     },
//     {
//       "ID": 8691,
//       "Airport Code [ICAO]": "RJOE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Akeno Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Japan",
//       "Latitude": 136.6719970703125,
//       "Location": "Akeno",
//       "Longitude": 34.53329849243164,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 8692,
//       "Airport Code [ICAO]": "UHPK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ust-Kamchatsk Airport",
//       "Altitude (Ft)": 200,
//       "Country": "Russia",
//       "Latitude": 162.68800354003906,
//       "Location": "Ust Kamchatsk",
//       "Longitude": 56.23860168457031,
//       "GMT Offset": "12",
//       "Daylight Savings": "N",
//       "Region": "Asia/Anadyr",
//       "Type": "airport"
//     },
//     {
//       "ID": 8693,
//       "Airport Code [ICAO]": "0WI8",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Oconomowoc Airport",
//       "Altitude (Ft)": 885,
//       "Country": "United States",
//       "Latitude": -88.47229766845703,
//       "Location": "Oconomowoc",
//       "Longitude": 43.13890075683594,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8694,
//       "Airport Code [ICAO]": "UHPO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kozyrevsk Airport",
//       "Altitude (Ft)": 331,
//       "Country": "Russia",
//       "Latitude": 159.8766632080078,
//       "Location": "Kozyrevsk",
//       "Longitude": 56.09000015258789,
//       "GMT Offset": "12",
//       "Daylight Savings": "N",
//       "Region": "Asia/Anadyr",
//       "Type": "airport"
//     },
//     {
//       "ID": 8695,
//       "Airport Code [ICAO]": "UODD",
//       "Airport Code iATA,FAA": "DKS",
//       "Airport Name": "Dikson Airport",
//       "Altitude (Ft)": 47,
//       "Country": "Russia",
//       "Latitude": 80.37966918945312,
//       "Location": "Dikson",
//       "Longitude": 73.51780700683594,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Krasnoyarsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 8696,
//       "Airport Code [ICAO]": "YBEE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Beverley Airport",
//       "Altitude (Ft)": 116,
//       "Country": "Australia",
//       "Latitude": 139.55799865722656,
//       "Location": "Mine Site",
//       "Longitude": -30.18670082092285,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Adelaide",
//       "Type": "airport"
//     },
//     {
//       "ID": 8698,
//       "Airport Code [ICAO]": "EIBN",
//       "Airport Code iATA,FAA": "BYT",
//       "Airport Name": "Bantry Aerodrome",
//       "Altitude (Ft)": 7,
//       "Country": "Ireland",
//       "Latitude": -9.484169960021973,
//       "Location": "Bantry",
//       "Longitude": 51.66859817504883,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/Dublin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8700,
//       "Airport Code [ICAO]": "FAAN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Aliwal North Airport",
//       "Altitude (Ft)": 4405,
//       "Country": "South Africa",
//       "Latitude": 26.729999542236328,
//       "Location": "Aliwal North",
//       "Longitude": -30.68000030517578,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 8701,
//       "Airport Code [ICAO]": "FACO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Alkantpan Copper Airport",
//       "Altitude (Ft)": 3589,
//       "Country": "South Africa",
//       "Latitude": 22.316699981689453,
//       "Location": "Alkantpan",
//       "Longitude": -29.906400680541992,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 8702,
//       "Airport Code [ICAO]": "FAAL",
//       "Airport Code iATA,FAA": "ADY",
//       "Airport Name": "Alldays Airport",
//       "Altitude (Ft)": 2600,
//       "Country": "South Africa",
//       "Latitude": 29.0555000305,
//       "Location": "Alldays",
//       "Longitude": -22.6790008545,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 8703,
//       "Airport Code [ICAO]": "FABR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bredasdorp Airport",
//       "Altitude (Ft)": 30,
//       "Country": "South Africa",
//       "Latitude": 20.0628,
//       "Location": "Barberton",
//       "Longitude": -34.665,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 8706,
//       "Airport Code [ICAO]": "KHAO",
//       "Airport Code iATA,FAA": "HAO",
//       "Airport Name": "Butler Co Regional Airport - Hogan Field",
//       "Altitude (Ft)": 633,
//       "Country": "United States",
//       "Latitude": -84.5220031738,
//       "Location": "Hamilton",
//       "Longitude": 39.363800048799995,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8707,
//       "Airport Code [ICAO]": "HKBU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bungoma Airport",
//       "Altitude (Ft)": 4726,
//       "Country": "Kenya",
//       "Latitude": 34.55345153808594,
//       "Location": "Bungoma",
//       "Longitude": 0.5762079954147339,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 8708,
//       "Airport Code [ICAO]": "HKBR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bura East Airport",
//       "Altitude (Ft)": 345,
//       "Country": "Kenya",
//       "Latitude": 39.95000076293945,
//       "Location": "Bura",
//       "Longitude": -1.100000023841858,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 8709,
//       "Airport Code [ICAO]": "HKBA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Busia Airport",
//       "Altitude (Ft)": 3989,
//       "Country": "Kenya",
//       "Latitude": 34.130279541015625,
//       "Location": "Busia",
//       "Longitude": 0.4570850133895874,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 8710,
//       "Airport Code [ICAO]": "HKEM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Embu Airport",
//       "Altitude (Ft)": 4150,
//       "Country": "Kenya",
//       "Latitude": 37.49726,
//       "Location": "Embu",
//       "Longitude": -0.57327,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 8711,
//       "Airport Code [ICAO]": "HKGT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Garba Tula Airport",
//       "Altitude (Ft)": 2000,
//       "Country": "Kenya",
//       "Latitude": 38.516667,
//       "Location": "Garba Tula",
//       "Longitude": 0.533333,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 8712,
//       "Airport Code [ICAO]": "HKGA",
//       "Airport Code iATA,FAA": "GAS",
//       "Airport Name": "Garissa Airport",
//       "Altitude (Ft)": 475,
//       "Country": "Kenya",
//       "Latitude": 39.64830017089844,
//       "Location": "Garissa",
//       "Longitude": -0.4635080099105835,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 8713,
//       "Airport Code [ICAO]": "HKHO",
//       "Airport Code iATA,FAA": "HOA",
//       "Airport Name": "Hola Airport",
//       "Altitude (Ft)": 195,
//       "Country": "Kenya",
//       "Latitude": 40.00400161743164,
//       "Location": "Hola",
//       "Longitude": -1.5219999551773071,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 8714,
//       "Airport Code [ICAO]": "HKHB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Homa Bay Airport",
//       "Altitude (Ft)": 4280,
//       "Country": "Kenya",
//       "Latitude": 34.46699905395508,
//       "Location": "Homa Bay",
//       "Longitude": -0.6000000238418579,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 8715,
//       "Airport Code [ICAO]": "HKIS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Isiolo Airport",
//       "Altitude (Ft)": 3495,
//       "Country": "Kenya",
//       "Latitude": 37.59169387817383,
//       "Location": "Isiolo",
//       "Longitude": 0.33817094564437866,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 8717,
//       "Airport Code [ICAO]": "HKKR",
//       "Airport Code iATA,FAA": "KEY",
//       "Airport Name": "Kericho Airport",
//       "Altitude (Ft)": 6446,
//       "Country": "Kenya",
//       "Latitude": 35.242093,
//       "Location": "Kericho",
//       "Longitude": -0.3899,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 8718,
//       "Airport Code [ICAO]": "HKKL",
//       "Airport Code iATA,FAA": "ILU",
//       "Airport Name": "Kilaguni Airport",
//       "Altitude (Ft)": 2750,
//       "Country": "Kenya",
//       "Latitude": 38.06520080566406,
//       "Location": "Kilaguni",
//       "Longitude": -2.9106099605560303,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 8721,
//       "Airport Code [ICAO]": "FMME",
//       "Airport Code iATA,FAA": "ATJ",
//       "Airport Name": "Antsirabe Airport",
//       "Altitude (Ft)": 4997,
//       "Country": "Madagascar",
//       "Latitude": 47.063713073699994,
//       "Location": "Antsirabe",
//       "Longitude": -19.8392214824,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 8722,
//       "Airport Code [ICAO]": "FMSL",
//       "Airport Code iATA,FAA": "OVA",
//       "Airport Name": "Bekily Airport",
//       "Altitude (Ft)": 1270,
//       "Country": "Madagascar",
//       "Latitude": 45.3045272827,
//       "Location": "Bekily",
//       "Longitude": -24.235694754699995,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Indian/Antananarivo",
//       "Type": "airport"
//     },
//     {
//       "ID": 8723,
//       "Airport Code [ICAO]": "UUYX",
//       "Airport Code iATA,FAA": "UTS",
//       "Airport Name": "Ust-Tsylma Airport",
//       "Altitude (Ft)": 262,
//       "Country": "Russia",
//       "Latitude": 52.20033645629999,
//       "Location": "Ust-Tsylma",
//       "Longitude": 65.43729400630001,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 8740,
//       "Airport Code [ICAO]": "UNBG",
//       "Airport Code iATA,FAA": "RGK",
//       "Airport Name": "Gorno-Altaysk Airport",
//       "Altitude (Ft)": 965,
//       "Country": "Russia",
//       "Latitude": 85.8332977295,
//       "Location": "Gorno-Altaysk",
//       "Longitude": 51.9667015076,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Krasnoyarsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 8742,
//       "Airport Code [ICAO]": "KFLD",
//       "Airport Code iATA,FAA": "FLD",
//       "Airport Name": "Fond du Lac County Airport",
//       "Altitude (Ft)": 808,
//       "Country": "United States",
//       "Latitude": -88.48840332030001,
//       "Location": "Fond du Lac",
//       "Longitude": 43.7711982727,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8743,
//       "Airport Code [ICAO]": "KPCZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Waupaca Municipal Airport",
//       "Altitude (Ft)": 840,
//       "Country": "United States",
//       "Latitude": -89.01979828,
//       "Location": "Waupaca",
//       "Longitude": 44.33330154,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8744,
//       "Airport Code [ICAO]": "KSTE",
//       "Airport Code iATA,FAA": "STE",
//       "Airport Name": "Stevens Point Municipal Airport",
//       "Altitude (Ft)": 1110,
//       "Country": "United States",
//       "Latitude": -89.530296325684,
//       "Location": "Stevens Point",
//       "Longitude": 44.5452003479,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8745,
//       "Airport Code [ICAO]": "UHMI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mys Shmidta Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Russia",
//       "Latitude": -179.3730010986328,
//       "Location": "Mys Shmidta",
//       "Longitude": 68.86830139160156,
//       "GMT Offset": "12",
//       "Daylight Savings": "N",
//       "Region": "Asia/Anadyr",
//       "Type": "airport"
//     },
//     {
//       "ID": 8746,
//       "Airport Code [ICAO]": "UEMA",
//       "Airport Code iATA,FAA": "MQJ",
//       "Airport Name": "Moma Airport",
//       "Altitude (Ft)": 656,
//       "Country": "Russia",
//       "Latitude": 143.261551,
//       "Location": "Honuu",
//       "Longitude": 66.450861,
//       "GMT Offset": "11",
//       "Daylight Savings": "N",
//       "Region": "Asia/Srednekolymsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 8747,
//       "Airport Code [ICAO]": "KERY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Luce County Airport",
//       "Altitude (Ft)": 869,
//       "Country": "United States",
//       "Latitude": -85.4572982788086,
//       "Location": "Newberry",
//       "Longitude": 46.31119918823242,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8750,
//       "Airport Code [ICAO]": "EDCP",
//       "Airport Code iATA,FAA": "PEF",
//       "Airport Name": "Peenemünde Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Germany",
//       "Latitude": 13.774443626399998,
//       "Location": "Peenemunde",
//       "Longitude": 54.1577796936,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8751,
//       "Airport Code [ICAO]": "EPGO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Góraszka Airport",
//       "Altitude (Ft)": 361,
//       "Country": "Poland",
//       "Latitude": 21.281110763549805,
//       "Location": "Goraszka",
//       "Longitude": 52.18444061279297,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 8752,
//       "Airport Code [ICAO]": "WI1A",
//       "Airport Code iATA,FAA": "CJN",
//       "Airport Name": "Nusawiru Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Indonesia",
//       "Latitude": 108.488995,
//       "Location": "Nusawiru",
//       "Longitude": -7.719895,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 8759,
//       "Airport Code [ICAO]": "KGQQ",
//       "Airport Code iATA,FAA": "GQQ",
//       "Airport Name": "Galion Municipal Airport",
//       "Altitude (Ft)": 1224,
//       "Country": "United States",
//       "Latitude": -82.7238006592,
//       "Location": "Galion",
//       "Longitude": 40.7533988953,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8761,
//       "Airport Code [ICAO]": "SETI",
//       "Airport Code iATA,FAA": "TPN",
//       "Airport Name": "Tiputini Airport",
//       "Altitude (Ft)": 997,
//       "Country": "Ecuador",
//       "Latitude": -75.52639770507812,
//       "Location": "Tiputini",
//       "Longitude": -0.7761110067367554,
//       "GMT Offset": "-5",
//       "Daylight Savings": "S",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 8762,
//       "Airport Code [ICAO]": "SESM",
//       "Airport Code iATA,FAA": "PTZ",
//       "Airport Name": "Rio Amazonas Airport",
//       "Altitude (Ft)": 3465,
//       "Country": "Ecuador",
//       "Latitude": -78.0626983643,
//       "Location": "Pastaza",
//       "Longitude": -1.5052399635299998,
//       "GMT Offset": "-5",
//       "Daylight Savings": "S",
//       "Region": "America/Guayaquil",
//       "Type": "airport"
//     },
//     {
//       "ID": 8763,
//       "Airport Code [ICAO]": "KCKV",
//       "Airport Code iATA,FAA": "CKV",
//       "Airport Name": "Clarksville–Montgomery County Regional Airport",
//       "Altitude (Ft)": 550,
//       "Country": "United States",
//       "Latitude": -87.4150009155,
//       "Location": "Clarksville",
//       "Longitude": 36.6218986511,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8771,
//       "Airport Code [ICAO]": "KLPC",
//       "Airport Code iATA,FAA": "LPC",
//       "Airport Name": "Lompoc Airport",
//       "Altitude (Ft)": 88,
//       "Country": "United States",
//       "Latitude": -120.468002319,
//       "Location": "Lompoc",
//       "Longitude": 34.665599823,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 8772,
//       "Airport Code [ICAO]": "KMQS",
//       "Airport Code iATA,FAA": "CTH",
//       "Airport Name": "Chester County G O Carlson Airport",
//       "Altitude (Ft)": 660,
//       "Country": "United States",
//       "Latitude": -75.8655014,
//       "Location": "Coatesville",
//       "Longitude": 39.97900009,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8773,
//       "Airport Code [ICAO]": "OABT",
//       "Airport Code iATA,FAA": "BST",
//       "Airport Name": "Bost Airport",
//       "Altitude (Ft)": 2464,
//       "Country": "Afghanistan",
//       "Latitude": 64.36499786376953,
//       "Location": "Lashkar Gah",
//       "Longitude": 31.55970001220703,
//       "GMT Offset": "4.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Kabul",
//       "Type": "airport"
//     },
//     {
//       "ID": 8774,
//       "Airport Code [ICAO]": "UBBL",
//       "Airport Code iATA,FAA": "LLK",
//       "Airport Name": "Lankaran International Airport",
//       "Altitude (Ft)": 30,
//       "Country": "Azerbaijan",
//       "Latitude": 48.8180007935,
//       "Location": "Lankaran",
//       "Longitude": 38.746398925799994,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Asia/Baku",
//       "Type": "airport"
//     },
//     {
//       "ID": 8775,
//       "Airport Code [ICAO]": "UBBQ",
//       "Airport Code iATA,FAA": "GBB",
//       "Airport Name": "Gabala International Airport",
//       "Altitude (Ft)": 935,
//       "Country": "Azerbaijan",
//       "Latitude": 47.7125,
//       "Location": "Qabala",
//       "Longitude": 40.826667,
//       "GMT Offset": "4",
//       "Daylight Savings": "E",
//       "Region": "Asia/Baku",
//       "Type": "airport"
//     },
//     {
//       "ID": 8776,
//       "Airport Code [ICAO]": "UBBY",
//       "Airport Code iATA,FAA": "ZTU",
//       "Airport Name": "Zaqatala International Airport",
//       "Altitude (Ft)": 1279,
//       "Country": "Azerbaijan",
//       "Latitude": 46.667221,
//       "Location": "Zaqatala",
//       "Longitude": 41.562222,
//       "GMT Offset": "4",
//       "Daylight Savings": "E",
//       "Region": "Asia/Baku",
//       "Type": "airport"
//     },
//     {
//       "ID": 8777,
//       "Airport Code [ICAO]": "KLKP",
//       "Airport Code iATA,FAA": "LKP",
//       "Airport Name": "Lake Placid Airport",
//       "Altitude (Ft)": 1747,
//       "Country": "United States",
//       "Latitude": -73.96189880370001,
//       "Location": "Lake Placid",
//       "Longitude": 44.2644996643,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8779,
//       "Airport Code [ICAO]": "UEMH",
//       "Airport Code iATA,FAA": "KDY",
//       "Airport Name": "Typliy Klyuch Airport",
//       "Altitude (Ft)": 925,
//       "Country": "Russia",
//       "Latitude": 136.854995728,
//       "Location": "Khandyga",
//       "Longitude": 62.7890014648,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yakutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 8780,
//       "Airport Code [ICAO]": "UEMM",
//       "Airport Code iATA,FAA": "GYG",
//       "Airport Name": "Magan Airport",
//       "Altitude (Ft)": 577,
//       "Country": "Russia",
//       "Latitude": 129.545288,
//       "Location": "Yakutsk",
//       "Longitude": 62.103484,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yakutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 8781,
//       "Airport Code [ICAO]": "ZUQJ",
//       "Airport Code iATA,FAA": "JIQ",
//       "Airport Name": "Qianjiang Wulingshan Airport",
//       "Altitude (Ft)": 2075,
//       "Country": "China",
//       "Latitude": 108.831111111,
//       "Location": "Qianjiang",
//       "Longitude": 29.5133333333,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 8782,
//       "Airport Code [ICAO]": "YXCM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cooma Hospital Helipad",
//       "Altitude (Ft)": 2656,
//       "Country": "Russia",
//       "Latitude": 149.130147,
//       "Location": "Yuzhno-Kurilsk",
//       "Longitude": -36.242155,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 8784,
//       "Airport Code [ICAO]": "KAOH",
//       "Airport Code iATA,FAA": "AOH",
//       "Airport Name": "Lima Allen County Airport",
//       "Altitude (Ft)": 975,
//       "Country": "United States",
//       "Latitude": -84.026703,
//       "Location": "Lima",
//       "Longitude": 40.706902,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8785,
//       "Airport Code [ICAO]": "ZKSD",
//       "Airport Code iATA,FAA": "DSO",
//       "Airport Name": "Sondok Airport",
//       "Altitude (Ft)": 12,
//       "Country": "North Korea",
//       "Latitude": 127.473999,
//       "Location": "Hamhung",
//       "Longitude": 39.745201,
//       "GMT Offset": "8.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Pyongyang",
//       "Type": "airport"
//     },
//     {
//       "ID": 8789,
//       "Airport Code [ICAO]": "KSSI",
//       "Airport Code iATA,FAA": "SSI",
//       "Airport Name": "Malcolm McKinnon Airport",
//       "Altitude (Ft)": 19,
//       "Country": "United States",
//       "Latitude": -81.39129639,
//       "Location": "Brunswick",
//       "Longitude": 31.15180016,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8790,
//       "Airport Code [ICAO]": "KBVI",
//       "Airport Code iATA,FAA": "BFP",
//       "Airport Name": "Beaver County Airport",
//       "Altitude (Ft)": 1253,
//       "Country": "United States",
//       "Latitude": -80.39140319820001,
//       "Location": "Beaver Falls",
//       "Longitude": 40.7724990845,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8792,
//       "Airport Code [ICAO]": "KGGE",
//       "Airport Code iATA,FAA": "GGE",
//       "Airport Name": "Georgetown County Airport",
//       "Altitude (Ft)": 39,
//       "Country": "United States",
//       "Latitude": -79.3196029663,
//       "Location": "Georgetown",
//       "Longitude": 33.3116989136,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8793,
//       "Airport Code [ICAO]": "KHDI",
//       "Airport Code iATA,FAA": "HDI",
//       "Airport Name": "Hardwick Field",
//       "Altitude (Ft)": 874,
//       "Country": "United States",
//       "Latitude": -84.8323974609375,
//       "Location": "Cleveland",
//       "Longitude": 35.22010040283203,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8796,
//       "Airport Code [ICAO]": "KRNT",
//       "Airport Code iATA,FAA": "RNT",
//       "Airport Name": "Renton Municipal Airport",
//       "Altitude (Ft)": 32,
//       "Country": "United States",
//       "Latitude": -122.216003418,
//       "Location": "Renton",
//       "Longitude": 47.4930992126,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 8798,
//       "Airport Code [ICAO]": "KPOC",
//       "Airport Code iATA,FAA": "POC",
//       "Airport Name": "Brackett Field",
//       "Altitude (Ft)": 1011,
//       "Country": "United States",
//       "Latitude": -117.78199768066,
//       "Location": "La Verne",
//       "Longitude": 34.091598510742,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 8801,
//       "Airport Code [ICAO]": "KCTY",
//       "Airport Code iATA,FAA": "CTY",
//       "Airport Name": "Cross City Airport",
//       "Altitude (Ft)": 42,
//       "Country": "United States",
//       "Latitude": -83.10479736330001,
//       "Location": "Cross City",
//       "Longitude": 29.6354999542,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8802,
//       "Airport Code [ICAO]": "KCEU",
//       "Airport Code iATA,FAA": "CEU",
//       "Airport Name": "Oconee County Regional Airport",
//       "Altitude (Ft)": 892,
//       "Country": "United States",
//       "Latitude": -82.8864975,
//       "Location": "Clemson",
//       "Longitude": 34.6719017,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8804,
//       "Airport Code [ICAO]": "KBEC",
//       "Airport Code iATA,FAA": "BEC",
//       "Airport Name": "Beech Factory Airport",
//       "Altitude (Ft)": 1408,
//       "Country": "United States",
//       "Latitude": -97.21499633790002,
//       "Location": "Wichita",
//       "Longitude": 37.694499969499994,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8807,
//       "Airport Code [ICAO]": "WIMG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tabing Airport",
//       "Altitude (Ft)": 9,
//       "Country": "Indonesia",
//       "Latitude": 100.351997,
//       "Location": "Padang",
//       "Longitude": -0.874989,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 8808,
//       "Airport Code [ICAO]": "KCZL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tom B. David Field",
//       "Altitude (Ft)": 647,
//       "Country": "United States",
//       "Latitude": -84.93920135,
//       "Location": "Calhoun",
//       "Longitude": 34.45539856,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8809,
//       "Airport Code [ICAO]": "KAJR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Habersham County Airport",
//       "Altitude (Ft)": 1448,
//       "Country": "United States",
//       "Latitude": -83.55670166,
//       "Location": "Cornelia",
//       "Longitude": 34.49990082,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8811,
//       "Airport Code [ICAO]": "KGTU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Georgetown Municipal Airport",
//       "Altitude (Ft)": 790,
//       "Country": "United States",
//       "Latitude": -97.67939758300781,
//       "Location": "Georgetown",
//       "Longitude": 30.678800582885742,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8812,
//       "Airport Code [ICAO]": "NY94",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Old Rhinebeck Airport",
//       "Altitude (Ft)": 323,
//       "Country": "United States",
//       "Latitude": -73.86289978,
//       "Location": "Rhinebeck",
//       "Longitude": 41.9715004,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8813,
//       "Airport Code [ICAO]": "EGSU",
//       "Airport Code iATA,FAA": "QFO",
//       "Airport Name": "Duxford Aerodrome",
//       "Altitude (Ft)": 125,
//       "Country": "United Kingdom",
//       "Latitude": 0.131944,
//       "Location": "Duxford",
//       "Longitude": 52.090801,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 8815,
//       "Airport Code [ICAO]": "KSNY",
//       "Airport Code iATA,FAA": "SNY",
//       "Airport Name": "Sidney Municipal-Lloyd W Carr Field",
//       "Altitude (Ft)": 4313,
//       "Country": "United States",
//       "Latitude": -102.9850006,
//       "Location": "Sidney",
//       "Longitude": 41.10129929,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 8816,
//       "Airport Code [ICAO]": "GA46",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Newnan Hospital Heliport",
//       "Altitude (Ft)": 960,
//       "Country": "Mali",
//       "Latitude": -84.8010025024414,
//       "Location": "Bengassi",
//       "Longitude": 33.38090133666992,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Bamako",
//       "Type": "airport"
//     },
//     {
//       "ID": 8820,
//       "Airport Code [ICAO]": "YGKL",
//       "Airport Code iATA,FAA": "GKL",
//       "Airport Name": "Great Keppel Is Airport",
//       "Altitude (Ft)": 21,
//       "Country": "Australia",
//       "Latitude": 150.942001343,
//       "Location": "Great Keppel Island",
//       "Longitude": -23.1833000183,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 8821,
//       "Airport Code [ICAO]": "YRRB",
//       "Airport Code iATA,FAA": "RPB",
//       "Airport Name": "Roper Bar Airport",
//       "Altitude (Ft)": 92,
//       "Country": "Australia",
//       "Latitude": 134.525485,
//       "Location": "Roper Bar",
//       "Longitude": -14.734814,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "U",
//       "Region": "Australia/Darwin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8822,
//       "Airport Code [ICAO]": "YMRT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mount Garnet Airport",
//       "Altitude (Ft)": 657,
//       "Country": "Australia",
//       "Latitude": 145.14999389648438,
//       "Location": "Mount Garnet",
//       "Longitude": -17.700000762939453,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 8823,
//       "Airport Code [ICAO]": "YIFL",
//       "Airport Code iATA,FAA": "IFL",
//       "Airport Name": "Innisfail Airport",
//       "Altitude (Ft)": 46,
//       "Country": "Australia",
//       "Latitude": 146.01199340820312,
//       "Location": "Innisfail",
//       "Longitude": -17.55940055847168,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 8824,
//       "Airport Code [ICAO]": "PHJR",
//       "Airport Code iATA,FAA": "JRF",
//       "Airport Name": "Kalaeloa Airport",
//       "Altitude (Ft)": 30,
//       "Country": "United States",
//       "Latitude": -158.070009,
//       "Location": "Kapolei",
//       "Longitude": 21.3074,
//       "GMT Offset": "-10",
//       "Daylight Savings": "U",
//       "Region": "Pacific/Honolulu",
//       "Type": "airport"
//     },
//     {
//       "ID": 8825,
//       "Airport Code [ICAO]": "OABN",
//       "Airport Code iATA,FAA": "BIN",
//       "Airport Name": "Bamiyan Airport",
//       "Altitude (Ft)": 8367,
//       "Country": "Afghanistan",
//       "Latitude": 67.81700134277344,
//       "Location": "Bamyan",
//       "Longitude": 34.81700134277344,
//       "GMT Offset": "4.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Kabul",
//       "Type": "airport"
//     },
//     {
//       "ID": 8826,
//       "Airport Code [ICAO]": "ZYBS",
//       "Airport Code iATA,FAA": "NBS",
//       "Airport Name": "Changbaishan Airport",
//       "Altitude (Ft)": 2874,
//       "Country": "China",
//       "Latitude": 127.602222,
//       "Location": "Baishan",
//       "Longitude": 42.066944,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 8830,
//       "Airport Code [ICAO]": "ZKHM",
//       "Airport Code iATA,FAA": "RGO",
//       "Airport Name": "Orang Airport",
//       "Altitude (Ft)": 12,
//       "Country": "North Korea",
//       "Latitude": 129.647555,
//       "Location": "Chongjin",
//       "Longitude": 41.428538,
//       "GMT Offset": "8.5",
//       "Daylight Savings": "U",
//       "Region": "Asia/Pyongyang",
//       "Type": "airport"
//     },
//     {
//       "ID": 8831,
//       "Airport Code [ICAO]": "YOOM",
//       "Airport Code iATA,FAA": "MOO",
//       "Airport Name": "Moomba Airport",
//       "Altitude (Ft)": 143,
//       "Country": "Australia",
//       "Latitude": 140.19700622558594,
//       "Location": "Moomba",
//       "Longitude": -28.09939956665039,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Adelaide",
//       "Type": "airport"
//     },
//     {
//       "ID": 8832,
//       "Airport Code [ICAO]": "EPLB",
//       "Airport Code iATA,FAA": "LUZ",
//       "Airport Name": "Lublin Airport",
//       "Altitude (Ft)": 633,
//       "Country": "Poland",
//       "Latitude": 22.713611,
//       "Location": "Lublin",
//       "Longitude": 51.240278,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Warsaw",
//       "Type": "airport"
//     },
//     {
//       "ID": 8833,
//       "Airport Code [ICAO]": "K6D9",
//       "Airport Code iATA,FAA": "ECA",
//       "Airport Name": "Iosco County Airport",
//       "Altitude (Ft)": 606,
//       "Country": "United States",
//       "Latitude": -83.422302,
//       "Location": "East Tawas",
//       "Longitude": 44.312801,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8834,
//       "Airport Code [ICAO]": "KMDQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Madison County Executive Airport-Tom Sharp Jr Field",
//       "Altitude (Ft)": 756,
//       "Country": "United States",
//       "Latitude": -86.55750275,
//       "Location": "Huntsville",
//       "Longitude": 34.8614006,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8835,
//       "Airport Code [ICAO]": "KJYO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Leesburg Executive Airport",
//       "Altitude (Ft)": 389,
//       "Country": "United States",
//       "Latitude": -77.557503,
//       "Location": "Leesburg",
//       "Longitude": 39.077999,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8838,
//       "Airport Code [ICAO]": "KANE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Anoka County-Blaine (Janes Field) Airport",
//       "Altitude (Ft)": 912,
//       "Country": "United States",
//       "Latitude": -93.211401,
//       "Location": "Anoka",
//       "Longitude": 45.145,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8839,
//       "Airport Code [ICAO]": "SCIR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Robinson Crusoe Airport",
//       "Altitude (Ft)": 433,
//       "Country": "Chile",
//       "Latitude": -78.9297027588,
//       "Location": "San Juan Bautista",
//       "Longitude": -33.6650009155,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8840,
//       "Airport Code [ICAO]": "VRMV",
//       "Airport Code iATA,FAA": "VAM",
//       "Airport Name": "Villa Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Maldives",
//       "Latitude": 72.8358333333,
//       "Location": "Maamigili",
//       "Longitude": 3.47055555556,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Indian/Maldives",
//       "Type": "airport"
//     },
//     {
//       "ID": 8845,
//       "Airport Code [ICAO]": "ZGLG",
//       "Airport Code iATA,FAA": "LLF",
//       "Airport Name": "Lingling Airport",
//       "Altitude (Ft)": 340,
//       "Country": "China",
//       "Latitude": 111.610043,
//       "Location": "Yongzhou",
//       "Longitude": 26.338661,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 8846,
//       "Airport Code [ICAO]": "LDLO",
//       "Airport Code iATA,FAA": "LSZ",
//       "Airport Name": "Lošinj Island Airport",
//       "Altitude (Ft)": 151,
//       "Country": "Croatia",
//       "Latitude": 14.3930997849,
//       "Location": "Mali Losinj",
//       "Longitude": 44.5657997131,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zagreb",
//       "Type": "airport"
//     },
//     {
//       "ID": 8847,
//       "Airport Code [ICAO]": "YOLW",
//       "Airport Code iATA,FAA": "ONS",
//       "Airport Name": "Onslow Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Australia",
//       "Latitude": 115.1129989624,
//       "Location": "Onslow",
//       "Longitude": -21.668300628662,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 8848,
//       "Airport Code [ICAO]": "YTDR",
//       "Airport Code iATA,FAA": "TDR",
//       "Airport Name": "Theodore Airport",
//       "Altitude (Ft)": 171,
//       "Country": "Australia",
//       "Latitude": 150.09300231933594,
//       "Location": "Theodore",
//       "Longitude": -24.99329948425293,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 8850,
//       "Airport Code [ICAO]": "KSDC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Williamson Sodus Airport",
//       "Altitude (Ft)": 424,
//       "Country": "United States",
//       "Latitude": -77.121,
//       "Location": "Williamson",
//       "Longitude": 43.234699,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8853,
//       "Airport Code [ICAO]": "EGTF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Fairoaks Airport",
//       "Altitude (Ft)": 80,
//       "Country": "United Kingdom",
//       "Latitude": -0.558888971806,
//       "Location": "Fairoaks",
//       "Longitude": 51.3480987549,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 8854,
//       "Airport Code [ICAO]": "KBDU",
//       "Airport Code iATA,FAA": "WBU",
//       "Airport Name": "Boulder Municipal Airport",
//       "Altitude (Ft)": 5288,
//       "Country": "United States",
//       "Latitude": -105.225997925,
//       "Location": "Boulder",
//       "Longitude": 40.0393981934,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 8855,
//       "Airport Code [ICAO]": "EDAN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Neustadt-Glewe Airport",
//       "Altitude (Ft)": 115,
//       "Country": "Germany",
//       "Latitude": 11.615278244018555,
//       "Location": "Neustadt-Glewe",
//       "Longitude": 53.35972213745117,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8859,
//       "Airport Code [ICAO]": "EBTN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Goetsenhoven Air Base",
//       "Altitude (Ft)": 246,
//       "Country": "Belgium",
//       "Latitude": 4.957779884338379,
//       "Location": "GOETSENHOVEN",
//       "Longitude": 50.781700134277344,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Brussels",
//       "Type": "airport"
//     },
//     {
//       "ID": 8860,
//       "Airport Code [ICAO]": "EDUW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tutow Airport",
//       "Altitude (Ft)": 26,
//       "Country": "Germany",
//       "Latitude": 13.218889236450195,
//       "Location": "Wildenrath",
//       "Longitude": 53.92194366455078,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8861,
//       "Airport Code [ICAO]": "EDTG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bremgarten Airport",
//       "Altitude (Ft)": 696,
//       "Country": "Germany",
//       "Latitude": 7.6177778244,
//       "Location": "Bremgarten",
//       "Longitude": 47.9027786255,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8862,
//       "Airport Code [ICAO]": "EDRB",
//       "Airport Code iATA,FAA": "BBJ",
//       "Airport Name": "Bitburg Airport",
//       "Altitude (Ft)": 1220,
//       "Country": "Germany",
//       "Latitude": 6.565,
//       "Location": "Birburg",
//       "Longitude": 49.945278,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 8864,
//       "Airport Code [ICAO]": "KPAO",
//       "Airport Code iATA,FAA": "PAO",
//       "Airport Name": "Palo Alto Airport of Santa Clara County",
//       "Altitude (Ft)": 4,
//       "Country": "United States",
//       "Latitude": -122.114997864,
//       "Location": "Palo Alto",
//       "Longitude": 37.461101532,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 8866,
//       "Airport Code [ICAO]": "UEMT",
//       "Airport Code iATA,FAA": "USR",
//       "Airport Name": "Ust-Nera Airport",
//       "Altitude (Ft)": 1805,
//       "Country": "Russia",
//       "Latitude": 143.11500549316,
//       "Location": "Ust-Nera",
//       "Longitude": 64.550003051758,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": "Asia/Vladivostok",
//       "Type": "airport"
//     },
//     {
//       "ID": 8867,
//       "Airport Code [ICAO]": "LKVM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Letiště Vysoké Mýto",
//       "Altitude (Ft)": 991,
//       "Country": "Czech Republic",
//       "Latitude": 16.185801,
//       "Location": "Vysoke Myto",
//       "Longitude": 49.926899,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Prague",
//       "Type": "airport"
//     },
//     {
//       "ID": 8868,
//       "Airport Code [ICAO]": "EBSP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Spa (la Sauvenière) Airfield",
//       "Altitude (Ft)": 1581,
//       "Country": "Belgium",
//       "Latitude": 5.9103,
//       "Location": "Spa",
//       "Longitude": 50.482498,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Brussels",
//       "Type": "airport"
//     },
//     {
//       "ID": 8869,
//       "Airport Code [ICAO]": "KFFZ",
//       "Airport Code iATA,FAA": "MSC",
//       "Airport Name": "Falcon Field",
//       "Altitude (Ft)": 1394,
//       "Country": "United States",
//       "Latitude": -111.727996826,
//       "Location": "Mesa",
//       "Longitude": 33.4608001709,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 8870,
//       "Airport Code [ICAO]": "KP08",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Coolidge Municipal Airport",
//       "Altitude (Ft)": 1574,
//       "Country": "United States",
//       "Latitude": -111.427001953125,
//       "Location": "Cooldige",
//       "Longitude": 32.9359016418457,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 8871,
//       "Airport Code [ICAO]": "KP52",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cottonwood Airport",
//       "Altitude (Ft)": 3550,
//       "Country": "United States",
//       "Latitude": -112.035003662,
//       "Location": "Cottonwood",
//       "Longitude": 34.7299995422,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 8872,
//       "Airport Code [ICAO]": "EBNM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Namur-Suarlée Airfield",
//       "Altitude (Ft)": 594,
//       "Country": "Belgium",
//       "Latitude": 4.76892,
//       "Location": "Namur",
//       "Longitude": 50.487999,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Brussels",
//       "Type": "airport"
//     },
//     {
//       "ID": 8873,
//       "Airport Code [ICAO]": "EBZH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kiewit Airfield Hasselt",
//       "Altitude (Ft)": 141,
//       "Country": "Belgium",
//       "Latitude": 5.37507,
//       "Location": "Hasselt",
//       "Longitude": 50.970001,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Brussels",
//       "Type": "airport"
//     },
//     {
//       "ID": 8874,
//       "Airport Code [ICAO]": "KA39",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ak-Chin Regional Airport",
//       "Altitude (Ft)": 1300,
//       "Country": "United States",
//       "Latitude": -111.918528,
//       "Location": "Phoenix",
//       "Longitude": 32.990806,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 8875,
//       "Airport Code [ICAO]": "KE25",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Wickenburg Municipal Airport",
//       "Altitude (Ft)": 2377,
//       "Country": "United States",
//       "Latitude": -112.7990036,
//       "Location": "Wickenburg",
//       "Longitude": 33.96889877,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 8876,
//       "Airport Code [ICAO]": "ZSYA",
//       "Airport Code iATA,FAA": "YTY",
//       "Airport Name": "Yangzhou Taizhou Airport",
//       "Altitude (Ft)": 7,
//       "Country": "China",
//       "Latitude": 119.7198,
//       "Location": "Yangzhou",
//       "Longitude": 32.5634,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 8877,
//       "Airport Code [ICAO]": "KPTK",
//       "Airport Code iATA,FAA": "PTK",
//       "Airport Name": "Oakland County International Airport",
//       "Altitude (Ft)": 980,
//       "Country": "United States",
//       "Latitude": -83.420097351074,
//       "Location": "Pontiac",
//       "Longitude": 42.665500640869,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8880,
//       "Airport Code [ICAO]": "GUKU",
//       "Airport Code iATA,FAA": "KSI",
//       "Airport Name": "Kissidougou Airport",
//       "Altitude (Ft)": 1808,
//       "Country": "Guinea",
//       "Latitude": -10.124400138855,
//       "Location": "Kissidougou",
//       "Longitude": 9.1605596542358,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Conakry",
//       "Type": "airport"
//     },
//     {
//       "ID": 8888,
//       "Airport Code [ICAO]": "KEEN",
//       "Airport Code iATA,FAA": "EEN",
//       "Airport Name": "Dillant Hopkins Airport",
//       "Altitude (Ft)": 488,
//       "Country": "United States",
//       "Latitude": -72.27079772949219,
//       "Location": "Keene",
//       "Longitude": 42.898399353027344,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8889,
//       "Airport Code [ICAO]": "ZLTS",
//       "Airport Code iATA,FAA": "THQ",
//       "Airport Name": "Tianshui Maijishan Airport",
//       "Altitude (Ft)": 3590,
//       "Country": "China",
//       "Latitude": 105.86000061,
//       "Location": "Tianshui",
//       "Longitude": 34.5593986511,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 8890,
//       "Airport Code [ICAO]": "MUKW",
//       "Airport Code iATA,FAA": "VRO",
//       "Airport Name": "Kawama Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Cuba",
//       "Latitude": -81.3015975952,
//       "Location": "Kawama",
//       "Longitude": 23.1240005493,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": "America/Havana",
//       "Type": "airport"
//     },
//     {
//       "ID": 8892,
//       "Airport Code [ICAO]": "VRMO",
//       "Airport Code iATA,FAA": "GKK",
//       "Airport Name": "Kooddoo Airport",
//       "Altitude (Ft)": 29,
//       "Country": "Maldives",
//       "Latitude": 73.4336,
//       "Location": "Kooddoo",
//       "Longitude": 0.7324,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": "Indian/Maldives",
//       "Type": "airport"
//     },
//     {
//       "ID": 8893,
//       "Airport Code [ICAO]": "07MT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Glasgow Industrial Airport",
//       "Altitude (Ft)": 2762,
//       "Country": "United States",
//       "Latitude": -106.52799987792969,
//       "Location": "Glasgow",
//       "Longitude": 48.42110061645508,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 8894,
//       "Airport Code [ICAO]": "EGTO",
//       "Airport Code iATA,FAA": "RCS",
//       "Airport Name": "Rochester Airport",
//       "Altitude (Ft)": 436,
//       "Country": "United Kingdom",
//       "Latitude": 0.5033329725265503,
//       "Location": "Rochester",
//       "Longitude": 51.351898193359375,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 8914,
//       "Airport Code [ICAO]": "SANR",
//       "Airport Code iATA,FAA": "RHD",
//       "Airport Name": "Termas de Río Hondo international Airport",
//       "Altitude (Ft)": 935,
//       "Country": "Argentina",
//       "Latitude": -64.93595,
//       "Location": "Rio Hondo",
//       "Longitude": -27.4966,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Cordoba",
//       "Type": "airport"
//     },
//     {
//       "ID": 8918,
//       "Airport Code [ICAO]": "FYKT",
//       "Airport Code iATA,FAA": "KMP",
//       "Airport Name": "Keetmanshoop Airport",
//       "Altitude (Ft)": 3506,
//       "Country": "Namibia",
//       "Latitude": 18.111400604248047,
//       "Location": "Keetmanshoop",
//       "Longitude": -26.5398006439209,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": "Africa/Windhoek",
//       "Type": "airport"
//     },
//     {
//       "ID": 8921,
//       "Airport Code [ICAO]": "ZUKD",
//       "Airport Code iATA,FAA": "KGT",
//       "Airport Name": "Kangding Airport",
//       "Altitude (Ft)": 14042,
//       "Country": "China",
//       "Latitude": 101.734722,
//       "Location": "Kangding",
//       "Longitude": 30.1575,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 8924,
//       "Airport Code [ICAO]": "ULWU",
//       "Airport Code iATA,FAA": "VUS",
//       "Airport Name": "Velikiy Ustyug Airport",
//       "Altitude (Ft)": 331,
//       "Country": "Russia",
//       "Latitude": 46.2599983215332,
//       "Location": "Veliky Ustyug",
//       "Longitude": 60.788299560546875,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 8925,
//       "Airport Code [ICAO]": "KIOW",
//       "Airport Code iATA,FAA": "IOW",
//       "Airport Name": "Iowa City Municipal Airport",
//       "Altitude (Ft)": 668,
//       "Country": "United States",
//       "Latitude": -91.5465011597,
//       "Location": "Iowa City",
//       "Longitude": 41.639198303200004,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8926,
//       "Airport Code [ICAO]": "ZWTP",
//       "Airport Code iATA,FAA": "TLQ",
//       "Airport Name": "Turpan Jiaohe Airport",
//       "Altitude (Ft)": 934,
//       "Country": "China",
//       "Latitude": 89.0987,
//       "Location": "Turpan",
//       "Longitude": 43.0308,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 8927,
//       "Airport Code [ICAO]": "SNCL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lorenzo Airport",
//       "Altitude (Ft)": 3,
//       "Country": "Brazil",
//       "Latitude": -38.90999984741211,
//       "Location": "Morro de Sao Paulo",
//       "Longitude": -13.389444351196289,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 8928,
//       "Airport Code [ICAO]": "KMWM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Windom Municipal Airport",
//       "Altitude (Ft)": 1410,
//       "Country": "United States",
//       "Latitude": -95.1093978881836,
//       "Location": "Windom",
//       "Longitude": 43.91339874267578,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8931,
//       "Airport Code [ICAO]": "OG39",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Longview Ranch Airport",
//       "Altitude (Ft)": 2080,
//       "Country": "United States",
//       "Latitude": -119.6520004272461,
//       "Location": "Longview",
//       "Longitude": 44.66170120239258,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 8932,
//       "Airport Code [ICAO]": "EGAR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Rothera Research Station",
//       "Altitude (Ft)": 9,
//       "Country": "United Kingdom",
//       "Latitude": -68.1274027778,
//       "Location": "Rothera Research Station",
//       "Longitude": -67.5675,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": "Antarctica/South_Pole",
//       "Type": "airport"
//     },
//     {
//       "ID": 8933,
//       "Airport Code [ICAO]": "ULPW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sortavala Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Russia",
//       "Latitude": 30.673599243164062,
//       "Location": "Sortavala",
//       "Longitude": 61.73609924316406,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 8935,
//       "Airport Code [ICAO]": "KANP",
//       "Airport Code iATA,FAA": "ANP",
//       "Airport Name": "Lee Airport",
//       "Altitude (Ft)": 34,
//       "Country": "United States",
//       "Latitude": -76.568398,
//       "Location": "Annapolis",
//       "Longitude": 38.942902,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8943,
//       "Airport Code [ICAO]": "FQCB",
//       "Airport Code iATA,FAA": "FXO",
//       "Airport Name": "Cuamba Airport",
//       "Altitude (Ft)": 1919,
//       "Country": "Mozambique",
//       "Latitude": 36.529999,
//       "Location": "Cuamba",
//       "Longitude": -14.815,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Maputo",
//       "Type": "airport"
//     },
//     {
//       "ID": 8944,
//       "Airport Code [ICAO]": "UIKB",
//       "Airport Code iATA,FAA": "ODO",
//       "Airport Name": "Bodaybo Airport",
//       "Altitude (Ft)": 919,
//       "Country": "Russia",
//       "Latitude": 114.242996216,
//       "Location": "Bodaibo",
//       "Longitude": 57.866100311299995,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Irkutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 8946,
//       "Airport Code [ICAO]": "UKKV",
//       "Airport Code iATA,FAA": "ZTR",
//       "Airport Name": "Zhytomyr Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Ukraine",
//       "Latitude": 28.738611,
//       "Location": "Zhytomyr",
//       "Longitude": 50.270556,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Kiev",
//       "Type": "airport"
//     },
//     {
//       "ID": 8948,
//       "Airport Code [ICAO]": "EYVP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Paluknys Airport",
//       "Altitude (Ft)": 400,
//       "Country": "Lithuania",
//       "Latitude": 24.98737335205078,
//       "Location": "Paluknys",
//       "Longitude": 54.48484802246094,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vilnius",
//       "Type": "airport"
//     },
//     {
//       "ID": 8949,
//       "Airport Code [ICAO]": "VCRI",
//       "Airport Code iATA,FAA": "HRI",
//       "Airport Name": "Mattala Rajapaksa International Airport",
//       "Altitude (Ft)": 157,
//       "Country": "Sri Lanka",
//       "Latitude": 81.124128,
//       "Location": "Mattala",
//       "Longitude": 6.284467,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Colombo",
//       "Type": "airport"
//     },
//     {
//       "ID": 8950,
//       "Airport Code [ICAO]": "KPEQ",
//       "Airport Code iATA,FAA": "PEQ",
//       "Airport Name": "Pecos Municipal Airport",
//       "Altitude (Ft)": 2613,
//       "Country": "United States",
//       "Latitude": -103.51100158691,
//       "Location": "Pecos",
//       "Longitude": 31.382400512695,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8951,
//       "Airport Code [ICAO]": "KHBG",
//       "Airport Code iATA,FAA": "HBG",
//       "Airport Name": "Hattiesburg Bobby L Chain Municipal Airport",
//       "Altitude (Ft)": 151,
//       "Country": "United States",
//       "Latitude": -89.25279999,
//       "Location": "Hattiesburg",
//       "Longitude": 31.26479912,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8952,
//       "Airport Code [ICAO]": "SDBK",
//       "Airport Code iATA,FAA": "QCJ",
//       "Airport Name": "Botucatu - Tancredo de Almeida Neves Airport",
//       "Altitude (Ft)": 3012,
//       "Country": "Brazil",
//       "Latitude": -48.467999,
//       "Location": "Botucatu",
//       "Longitude": -22.939501,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 8953,
//       "Airport Code [ICAO]": "SBAN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Base Aérea Airport",
//       "Altitude (Ft)": 3731,
//       "Country": "Brazil",
//       "Latitude": -48.964298,
//       "Location": "Anapolis",
//       "Longitude": -16.2292,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 8954,
//       "Airport Code [ICAO]": "SDSC",
//       "Airport Code iATA,FAA": "QSC",
//       "Airport Name": "Mário Pereira Lopes–São Carlos Airport",
//       "Altitude (Ft)": 2649,
//       "Country": "Brazil",
//       "Latitude": -47.903703,
//       "Location": "Sao Carlos",
//       "Longitude": -21.875401,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 8958,
//       "Airport Code [ICAO]": "KYKN",
//       "Airport Code iATA,FAA": "YKN",
//       "Airport Name": "Chan Gurney Municipal Airport",
//       "Altitude (Ft)": 1306,
//       "Country": "United States",
//       "Latitude": -97.385902404785,
//       "Location": "Yankton",
//       "Longitude": 42.916698455811,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8961,
//       "Airport Code [ICAO]": "OMBY",
//       "Airport Code iATA,FAA": "XSB",
//       "Airport Name": "Sir Bani Yas Airport",
//       "Altitude (Ft)": 25,
//       "Country": "United Arab Emirates",
//       "Latitude": 52.580278,
//       "Location": "Sir Bani Yas Island",
//       "Longitude": 24.283611,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": "Asia/Dubai",
//       "Type": "airport"
//     },
//     {
//       "ID": 8963,
//       "Airport Code [ICAO]": "CZBM",
//       "Airport Code iATA,FAA": "ZBM",
//       "Airport Name": "Bromont (Roland Desourdy) Airport",
//       "Altitude (Ft)": 375,
//       "Country": "Canada",
//       "Latitude": -72.74140167239999,
//       "Location": "Bromont",
//       "Longitude": 45.2907981873,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 8965,
//       "Airport Code [ICAO]": "EGSM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Beccles Airport",
//       "Altitude (Ft)": 80,
//       "Country": "United Kingdom",
//       "Latitude": 1.6183300018300002,
//       "Location": "Beccles",
//       "Longitude": 52.435298919699996,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 8966,
//       "Airport Code [ICAO]": "VDKT",
//       "Airport Code iATA,FAA": "KTI",
//       "Airport Name": "Kratie Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Cambodia",
//       "Latitude": 106.05500030517578,
//       "Location": "Kratie",
//       "Longitude": 12.48799991607666,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Phnom_Penh",
//       "Type": "airport"
//     },
//     {
//       "ID": 8967,
//       "Airport Code [ICAO]": "SCCL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Caldera Airport",
//       "Altitude (Ft)": 180,
//       "Country": "Chile",
//       "Latitude": -70.79530334472656,
//       "Location": "Caldera",
//       "Longitude": -27.078100204467773,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8968,
//       "Airport Code [ICAO]": "SCPE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "San Pedro de Atacama Airport",
//       "Altitude (Ft)": 7960,
//       "Country": "Chile",
//       "Latitude": -68.15840148925781,
//       "Location": "San Pedro de Atacama",
//       "Longitude": -22.92169952392578,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 8969,
//       "Airport Code [ICAO]": "SLCC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Copacabana Airport",
//       "Altitude (Ft)": 12591,
//       "Country": "Bolivia",
//       "Latitude": -69.09559631347656,
//       "Location": "Copacabana",
//       "Longitude": -16.191099166870117,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": "America/La_Paz",
//       "Type": "airport"
//     },
//     {
//       "ID": 8971,
//       "Airport Code [ICAO]": "ZLGY",
//       "Airport Code iATA,FAA": "GYU",
//       "Airport Name": "Guyuan Liupanshan Airport",
//       "Altitude (Ft)": 5696,
//       "Country": "China",
//       "Latitude": 106.216944444,
//       "Location": "Guyuan",
//       "Longitude": 36.0788888889,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 8972,
//       "Airport Code [ICAO]": "EGDA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "RAF Brawdy",
//       "Altitude (Ft)": 0,
//       "Country": "United Kingdom",
//       "Latitude": -5.119972,
//       "Location": "Brawdy",
//       "Longitude": 51.88375,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 8974,
//       "Airport Code [ICAO]": "ZYCH",
//       "Airport Code iATA,FAA": "CNI",
//       "Airport Name": "Changhai Airport",
//       "Altitude (Ft)": 80,
//       "Country": "China",
//       "Latitude": 122.666944444,
//       "Location": "Changhai",
//       "Longitude": 39.2666666667,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 8975,
//       "Airport Code [ICAO]": "EGKR",
//       "Airport Code iATA,FAA": "KRH",
//       "Airport Name": "Redhill Aerodrome",
//       "Altitude (Ft)": 222,
//       "Country": "United Kingdom",
//       "Latitude": -0.138611003757,
//       "Location": "Redhill",
//       "Longitude": 51.2136001587,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 8979,
//       "Airport Code [ICAO]": "ZYJD",
//       "Airport Code iATA,FAA": "JGD",
//       "Airport Name": "Jiagedaqi Airport",
//       "Altitude (Ft)": 1205,
//       "Country": "China",
//       "Latitude": 124.1175,
//       "Location": "Jiagedaqi District",
//       "Longitude": 50.371389,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 8980,
//       "Airport Code [ICAO]": "YCCA",
//       "Airport Code iATA,FAA": "CCL",
//       "Airport Name": "Chinchilla Airport",
//       "Altitude (Ft)": 1028,
//       "Country": "Australia",
//       "Latitude": 150.61700439453125,
//       "Location": "Chinchilla",
//       "Longitude": -26.774999618530273,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 8982,
//       "Airport Code [ICAO]": "KHWD",
//       "Airport Code iATA,FAA": "HWD",
//       "Airport Name": "Hayward Executive Airport",
//       "Altitude (Ft)": 52,
//       "Country": "United States",
//       "Latitude": -122.122001648,
//       "Location": "Hayward",
//       "Longitude": 37.659198761,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 8983,
//       "Airport Code [ICAO]": "NZMK",
//       "Airport Code iATA,FAA": "MZP",
//       "Airport Name": "Motueka Airport",
//       "Altitude (Ft)": 39,
//       "Country": "New Zealand",
//       "Latitude": 172.98899841308594,
//       "Location": "Motueka",
//       "Longitude": -41.12329864501953,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Pacific/Auckland",
//       "Type": "airport"
//     },
//     {
//       "ID": 8984,
//       "Airport Code [ICAO]": "YSHR",
//       "Airport Code iATA,FAA": "JHQ",
//       "Airport Name": "Shute Harbour Airport",
//       "Altitude (Ft)": 12,
//       "Country": "Australia",
//       "Latitude": 148.755556,
//       "Location": "Shute Harbour",
//       "Longitude": -20.277221,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 8985,
//       "Airport Code [ICAO]": "EGTN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Enstone Aerodrome",
//       "Altitude (Ft)": 550,
//       "Country": "United Kingdom",
//       "Latitude": -1.4285,
//       "Location": "Enstone",
//       "Longitude": 51.928166666699994,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 8989,
//       "Airport Code [ICAO]": "KARB",
//       "Airport Code iATA,FAA": "ARB",
//       "Airport Name": "Ann Arbor Municipal Airport",
//       "Altitude (Ft)": 839,
//       "Country": "United States",
//       "Latitude": -83.74559783939999,
//       "Location": "Ann Arbor",
//       "Longitude": 42.2229995728,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 8994,
//       "Airport Code [ICAO]": "YSHT",
//       "Airport Code iATA,FAA": "SHT",
//       "Airport Name": "Shepparton Airport",
//       "Altitude (Ft)": 374,
//       "Country": "Australia",
//       "Latitude": 145.39300537109375,
//       "Location": "Shepparton",
//       "Longitude": -36.42890167236328,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Hobart",
//       "Type": "airport"
//     },
//     {
//       "ID": 8995,
//       "Airport Code [ICAO]": "YTEM",
//       "Airport Code iATA,FAA": "TEM",
//       "Airport Name": "Temora Airport",
//       "Altitude (Ft)": 921,
//       "Country": "Australia",
//       "Latitude": 147.51199340820312,
//       "Location": "Temora",
//       "Longitude": -34.4213981628418,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 8996,
//       "Airport Code [ICAO]": "YGAY",
//       "Airport Code iATA,FAA": "GAH",
//       "Airport Name": "Gayndah Airport",
//       "Altitude (Ft)": 369,
//       "Country": "Australia",
//       "Latitude": 151.61900329589844,
//       "Location": "Gayndah",
//       "Longitude": -25.61440086364746,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 8998,
//       "Airport Code [ICAO]": "YWCA",
//       "Airport Code iATA,FAA": "WIO",
//       "Airport Name": "Wilcannia Airport",
//       "Altitude (Ft)": 250,
//       "Country": "Australia",
//       "Latitude": 143.375,
//       "Location": "Wilcannia",
//       "Longitude": -31.526399612426758,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 9001,
//       "Airport Code [ICAO]": "YIVO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ivanhoe Airport",
//       "Altitude (Ft)": 330,
//       "Country": "Australia",
//       "Latitude": 144.30999755859375,
//       "Location": "Ivanhoe",
//       "Longitude": -32.88330078125,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 9002,
//       "Airport Code [ICAO]": "YMED",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Menindee Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 142.40499877929688,
//       "Location": "Menindee",
//       "Longitude": -32.36669921875,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 9004,
//       "Airport Code [ICAO]": "YPCE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Pooncarie Airport",
//       "Altitude (Ft)": 50,
//       "Country": "Australia",
//       "Latitude": 142.58799743652344,
//       "Location": "Pooncarie",
//       "Longitude": -33.36669921875,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 9006,
//       "Airport Code [ICAO]": "YTLP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tilpa Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 144.41700744628906,
//       "Location": "Tilpa",
//       "Longitude": -30.933300018310547,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 9008,
//       "Airport Code [ICAO]": "LSGR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Reichenbach Air Base",
//       "Altitude (Ft)": 2385,
//       "Country": "Switzerland",
//       "Latitude": 7.6777801513671875,
//       "Location": "Reichenbach im Kandertal",
//       "Longitude": 46.61360168457031,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Zurich",
//       "Type": "airport"
//     },
//     {
//       "ID": 9025,
//       "Airport Code [ICAO]": "ZUBJ",
//       "Airport Code iATA,FAA": "BFJ",
//       "Airport Name": "Bijie Feixiong Airport",
//       "Altitude (Ft)": 4751,
//       "Country": "China",
//       "Latitude": 105.472097,
//       "Location": "Bijie",
//       "Longitude": 27.267066,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 9026,
//       "Airport Code [ICAO]": "UERL",
//       "Airport Code iATA,FAA": "ULK",
//       "Airport Name": "Lensk Airport",
//       "Altitude (Ft)": 801,
//       "Country": "Russia",
//       "Latitude": 114.825996399,
//       "Location": "Lensk",
//       "Longitude": 60.7206001282,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yakutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 9042,
//       "Airport Code [ICAO]": "UHWK",
//       "Airport Code iATA,FAA": "KVR",
//       "Airport Name": "Kavalerovo Airport",
//       "Altitude (Ft)": 730,
//       "Country": "Russia",
//       "Latitude": 135.029,
//       "Location": "Kavalerovo",
//       "Longitude": 44.2726,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": "Asia/Vladivostok",
//       "Type": "airport"
//     },
//     {
//       "ID": 9043,
//       "Airport Code [ICAO]": "LTCT",
//       "Airport Code iATA,FAA": "IGD",
//       "Airport Name": "Iğdır Airport",
//       "Altitude (Ft)": 3101,
//       "Country": "Turkey",
//       "Latitude": 43.8766479492,
//       "Location": "Igdir",
//       "Longitude": 39.9766273499,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 9044,
//       "Airport Code [ICAO]": "LTCS",
//       "Airport Code iATA,FAA": "GNY",
//       "Airport Name": "Şanlıurfa GAP Airport",
//       "Altitude (Ft)": 2708,
//       "Country": "Turkey",
//       "Latitude": 38.895592,
//       "Location": "Sanliurfa",
//       "Longitude": 37.445663,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 9045,
//       "Airport Code [ICAO]": "LTBZ",
//       "Airport Code iATA,FAA": "KZR",
//       "Airport Name": "Zafer Airport",
//       "Altitude (Ft)": 3327,
//       "Country": "Turkey",
//       "Latitude": 30.128111,
//       "Location": "Kutahya",
//       "Longitude": 39.113079,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 9048,
//       "Airport Code [ICAO]": "ULOL",
//       "Airport Code iATA,FAA": "VLU",
//       "Airport Name": "Velikiye Luki Airport",
//       "Altitude (Ft)": 328,
//       "Country": "Russia",
//       "Latitude": 30.60810089111328,
//       "Location": "Velikiye Luki",
//       "Longitude": 56.381099700927734,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 9053,
//       "Airport Code [ICAO]": "VOYK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Yelahanka Air Force Station",
//       "Altitude (Ft)": 3045,
//       "Country": "India",
//       "Latitude": 77.60600280761719,
//       "Location": "Bangalore",
//       "Longitude": 13.135499954223633,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Calcutta",
//       "Type": "airport"
//     },
//     {
//       "ID": 9054,
//       "Airport Code [ICAO]": "UNNE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Yeltsovka Airport",
//       "Altitude (Ft)": 617,
//       "Country": "Russia",
//       "Latitude": 83.00450134277344,
//       "Location": "Novosibirsk",
//       "Longitude": 55.09239959716797,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Krasnoyarsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 9062,
//       "Airport Code [ICAO]": "UNKI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kodinsk Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Russia",
//       "Latitude": 99.09390258789062,
//       "Location": "Kodinsk",
//       "Longitude": 58.479400634765625,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Krasnoyarsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 9065,
//       "Airport Code [ICAO]": "YPEC",
//       "Airport Code iATA,FAA": "BEO",
//       "Airport Name": "Lake Macquarie Airport",
//       "Altitude (Ft)": 2,
//       "Country": "Australia",
//       "Latitude": 151.647995,
//       "Location": "Lake Macquarie",
//       "Longitude": -33.0667,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 9066,
//       "Airport Code [ICAO]": "K4A7",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Henry County Airport",
//       "Altitude (Ft)": 874,
//       "Country": "United States",
//       "Latitude": -84.332397,
//       "Location": "Hampton",
//       "Longitude": 33.389099,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9068,
//       "Airport Code [ICAO]": "YBPI",
//       "Airport Code iATA,FAA": "BMP",
//       "Airport Name": "Brampton Island Airport",
//       "Altitude (Ft)": 11,
//       "Country": "Australia",
//       "Latitude": 149.27000427246094,
//       "Location": "Brampton Island",
//       "Longitude": -20.803300857543945,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 9071,
//       "Airport Code [ICAO]": "KNGZ",
//       "Airport Code iATA,FAA": "NGZ",
//       "Airport Name": "Alameda Naval Air Station",
//       "Altitude (Ft)": 10,
//       "Country": "United States",
//       "Latitude": -122.31999969482422,
//       "Location": "Alameda",
//       "Longitude": 37.78889846801758,
//       "GMT Offset": "-8",
//       "Daylight Savings": "U",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 9075,
//       "Airport Code [ICAO]": "EKEL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Endelave Flyveplads",
//       "Altitude (Ft)": 15,
//       "Country": "Denmark",
//       "Latitude": 10.2485,
//       "Location": "Endelage",
//       "Longitude": 55.7565,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Copenhagen",
//       "Type": "airport"
//     },
//     {
//       "ID": 9076,
//       "Airport Code [ICAO]": "LOIJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "St. Johann In Tirol Airport",
//       "Altitude (Ft)": 2198,
//       "Country": "Austria",
//       "Latitude": 12.4497,
//       "Location": "St. Johann in Tirol",
//       "Longitude": 47.5201,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Vienna",
//       "Type": "airport"
//     },
//     {
//       "ID": 9079,
//       "Airport Code [ICAO]": "EDPW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Thalmässing-Waizenhofen Airport",
//       "Altitude (Ft)": 1893,
//       "Country": "Germany",
//       "Latitude": 11.209166526794434,
//       "Location": "Thalmaessing",
//       "Longitude": 49.06416702270508,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 9088,
//       "Airport Code [ICAO]": "CYCN",
//       "Airport Code iATA,FAA": "YCN",
//       "Airport Name": "Cochrane Airport",
//       "Altitude (Ft)": 861,
//       "Country": "Canada",
//       "Latitude": -81.01360321044922,
//       "Location": "Cochrane",
//       "Longitude": 49.10559844970703,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 9089,
//       "Airport Code [ICAO]": "SBBP",
//       "Airport Code iATA,FAA": "BJP",
//       "Airport Name": "Estadual Arthur Siqueira Airport",
//       "Altitude (Ft)": 2887,
//       "Country": "Brazil",
//       "Latitude": -46.537508,
//       "Location": "Braganca Paulista",
//       "Longitude": -22.979162,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 9090,
//       "Airport Code [ICAO]": "YBLN",
//       "Airport Code iATA,FAA": "BQB",
//       "Airport Name": "Busselton Regional Airport",
//       "Altitude (Ft)": 55,
//       "Country": "Australia",
//       "Latitude": 115.401596069,
//       "Location": "Brusselton",
//       "Longitude": -33.6884231567,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 9091,
//       "Airport Code [ICAO]": "UESK",
//       "Airport Code iATA,FAA": "SEK",
//       "Airport Name": "Srednekolymsk Airport",
//       "Altitude (Ft)": 60,
//       "Country": "Russia",
//       "Latitude": 153.7364,
//       "Location": "Srednekolymsk",
//       "Longitude": 67.4805,
//       "GMT Offset": "11",
//       "Daylight Savings": "N",
//       "Region": "Asia/Srednekolymsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 9093,
//       "Airport Code [ICAO]": "SLCR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Comarapa Airport",
//       "Altitude (Ft)": 6186,
//       "Country": "United States",
//       "Latitude": -64.5177993774414,
//       "Location": "Salt Lake City",
//       "Longitude": -17.91360092163086,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/La_Paz",
//       "Type": "airport"
//     },
//     {
//       "ID": 9095,
//       "Airport Code [ICAO]": "YIVL",
//       "Airport Code iATA,FAA": "IVR",
//       "Airport Name": "Inverell Airport",
//       "Altitude (Ft)": 2667,
//       "Country": "Australia",
//       "Latitude": 151.143997192,
//       "Location": "Inverell",
//       "Longitude": -29.888299942,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 9096,
//       "Airport Code [ICAO]": "YGLI",
//       "Airport Code iATA,FAA": "GLI",
//       "Airport Name": "Glen Innes Airport",
//       "Altitude (Ft)": 3433,
//       "Country": "Australia",
//       "Latitude": 151.68899536132812,
//       "Location": "Glen Innes",
//       "Longitude": -29.674999237060547,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 9100,
//       "Airport Code [ICAO]": "EDQF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ansbach-Petersdorf Airport",
//       "Altitude (Ft)": 1371,
//       "Country": "Germany",
//       "Latitude": 10.669444,
//       "Location": "Ansbach",
//       "Longitude": 49.361111,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 9102,
//       "Airport Code [ICAO]": "KIMM",
//       "Airport Code iATA,FAA": "IMM",
//       "Airport Name": "Immokalee Regional Airport",
//       "Altitude (Ft)": 37,
//       "Country": "United States",
//       "Latitude": -81.40100098,
//       "Location": "Immokalee ",
//       "Longitude": 26.43320084,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9103,
//       "Airport Code [ICAO]": "WA44",
//       "Airport Code iATA,FAA": "TQQ",
//       "Airport Name": "Maranggo Airport",
//       "Altitude (Ft)": 169,
//       "Country": "Indonesia",
//       "Latitude": 123.91699981689,
//       "Location": "Sulawesi Tenggara",
//       "Longitude": -5.7645702362061,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Makassar",
//       "Type": "airport"
//     },
//     {
//       "ID": 9104,
//       "Airport Code [ICAO]": "66CA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Rancho San Simeon Airport",
//       "Altitude (Ft)": 320,
//       "Country": "United States",
//       "Latitude": -121.11000061035156,
//       "Location": "Cambria",
//       "Longitude": 35.60770034790039,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 9107,
//       "Airport Code [ICAO]": "ZSYC",
//       "Airport Code iATA,FAA": "YIC",
//       "Airport Name": "Yichun Mingyueshan Airport",
//       "Altitude (Ft)": 430,
//       "Country": "China",
//       "Latitude": 114.3062,
//       "Location": "Yichun",
//       "Longitude": 27.8025,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 9120,
//       "Airport Code [ICAO]": "KPTB",
//       "Airport Code iATA,FAA": "PTB",
//       "Airport Name": "Dinwiddie County Airport",
//       "Altitude (Ft)": 193,
//       "Country": "United States",
//       "Latitude": -77.507400512695,
//       "Location": "Petersburg",
//       "Longitude": 37.183799743652,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9123,
//       "Airport Code [ICAO]": "FZOK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kasongo Airport",
//       "Altitude (Ft)": 1785,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 26.617000579833984,
//       "Location": "Kasongo",
//       "Longitude": -4.5329999923706055,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Africa/Lubumbashi",
//       "Type": "airport"
//     },
//     {
//       "ID": 9124,
//       "Airport Code [ICAO]": "NZPG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "McMurdo Station Pegasus Field",
//       "Altitude (Ft)": 18,
//       "Country": "Antarctica",
//       "Latitude": 166.52499389648438,
//       "Location": "McMurdo Station",
//       "Longitude": -77.9634017944336,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": "Antarctica/South_Pole",
//       "Type": "airport"
//     },
//     {
//       "ID": 9125,
//       "Airport Code [ICAO]": "LKKT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Klatovy Airport",
//       "Altitude (Ft)": 1299,
//       "Country": "Czech Republic",
//       "Latitude": 13.321900367736816,
//       "Location": "Klatovy",
//       "Longitude": 49.41830062866211,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Prague",
//       "Type": "airport"
//     },
//     {
//       "ID": 9126,
//       "Airport Code [ICAO]": "KSBM",
//       "Airport Code iATA,FAA": "SBM",
//       "Airport Name": "Sheboygan County Memorial Airport",
//       "Altitude (Ft)": 755,
//       "Country": "United States",
//       "Latitude": -87.85140228,
//       "Location": "Sheboygan",
//       "Longitude": 43.76959991,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9127,
//       "Airport Code [ICAO]": "YFDF",
//       "Airport Code iATA,FAA": "KFE",
//       "Airport Name": "Fortescue - Dave Forrest Aerodrome",
//       "Altitude (Ft)": 1555,
//       "Country": "Australia",
//       "Latitude": 119.437143,
//       "Location": "Cloudbreak",
//       "Longitude": -22.290754,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 9129,
//       "Airport Code [ICAO]": "VNKL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kangel Danda Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Nepal",
//       "Latitude": 86.6465950012,
//       "Location": "Kangel Danda",
//       "Longitude": 27.4106333137,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 9132,
//       "Airport Code [ICAO]": "VNBR",
//       "Airport Code iATA,FAA": "BJU",
//       "Airport Name": "Bajura Airport",
//       "Altitude (Ft)": 4300,
//       "Country": "Nepal",
//       "Latitude": 81.66899871826172,
//       "Location": "Bajura",
//       "Longitude": 29.50200080871582,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": "Asia/Katmandu",
//       "Type": "airport"
//     },
//     {
//       "ID": 9134,
//       "Airport Code [ICAO]": "UIAR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Chara Airport",
//       "Altitude (Ft)": 2201,
//       "Country": "Russia",
//       "Latitude": 118.2699966430664,
//       "Location": "Chara",
//       "Longitude": 56.913333892822266,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yakutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 9136,
//       "Airport Code [ICAO]": "EKHG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Herning Airport",
//       "Altitude (Ft)": 167,
//       "Country": "Denmark",
//       "Latitude": 9.044449806213379,
//       "Location": "Herning",
//       "Longitude": 56.18470001220703,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Copenhagen",
//       "Type": "airport"
//     },
//     {
//       "ID": 9137,
//       "Airport Code [ICAO]": "EDNX",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Oberschleißheim Airfield",
//       "Altitude (Ft)": 1594,
//       "Country": "Germany",
//       "Latitude": 11.561389,
//       "Location": "Schleissheim",
//       "Longitude": 48.239445,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 9138,
//       "Airport Code [ICAO]": "KMZJ",
//       "Airport Code iATA,FAA": "MZJ",
//       "Airport Name": "Pinal Airpark",
//       "Altitude (Ft)": 1893,
//       "Country": "United States",
//       "Latitude": -111.32800293,
//       "Location": "Marana",
//       "Longitude": 32.5106010437,
//       "GMT Offset": "-7",
//       "Daylight Savings": "N",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 9139,
//       "Airport Code [ICAO]": "KGEU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Glendale Municipal Airport",
//       "Altitude (Ft)": 1071,
//       "Country": "United States",
//       "Latitude": -112.29499816894531,
//       "Location": "Glendale",
//       "Longitude": 33.52690124511719,
//       "GMT Offset": "-7",
//       "Daylight Savings": "N",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 9140,
//       "Airport Code [ICAO]": "KSAD",
//       "Airport Code iATA,FAA": "SAD",
//       "Airport Name": "Safford Regional Airport",
//       "Altitude (Ft)": 3179,
//       "Country": "United States",
//       "Latitude": -109.6350021,
//       "Location": "Safford",
//       "Longitude": 32.85480118,
//       "GMT Offset": "-7",
//       "Daylight Savings": "N",
//       "Region": "America/Phoenix",
//       "Type": "airport"
//     },
//     {
//       "ID": 9141,
//       "Airport Code [ICAO]": "EDWV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Verden-Scharnhorst Airfield",
//       "Altitude (Ft)": 144,
//       "Country": "Germany",
//       "Latitude": 9.282778,
//       "Location": "Verden",
//       "Longitude": 52.965279,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 9146,
//       "Airport Code [ICAO]": "YSOL",
//       "Airport Code iATA,FAA": "SLJ",
//       "Airport Name": "Solomon Airport",
//       "Altitude (Ft)": 2030,
//       "Country": "Australia",
//       "Latitude": 117.7627,
//       "Location": "Solomon",
//       "Longitude": -22.2554,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 9147,
//       "Airport Code [ICAO]": "EDNM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nittenau-Bruck Airport",
//       "Altitude (Ft)": 1161,
//       "Country": "Germany",
//       "Latitude": 12.296667098999023,
//       "Location": "Nittenau",
//       "Longitude": 49.22249984741211,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 9148,
//       "Airport Code [ICAO]": "ROKR",
//       "Airport Code iATA,FAA": "KJP",
//       "Airport Name": "Kerama Airport",
//       "Altitude (Ft)": 156,
//       "Country": "Japan",
//       "Latitude": 127.292999268,
//       "Location": "Kerama",
//       "Longitude": 26.168300628699996,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Tokyo",
//       "Type": "airport"
//     },
//     {
//       "ID": 9149,
//       "Airport Code [ICAO]": "SDAI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Americana Airport",
//       "Altitude (Ft)": 2085,
//       "Country": "Brazil",
//       "Latitude": -47.26940155029297,
//       "Location": "Americana",
//       "Longitude": -22.755800247192383,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 9156,
//       "Airport Code [ICAO]": "UASB",
//       "Airport Code iATA,FAA": "EKB",
//       "Airport Name": "Ekibastuz Airport",
//       "Altitude (Ft)": 621,
//       "Country": "Kazakhstan",
//       "Latitude": 75.21499633789062,
//       "Location": "Ekibastuz",
//       "Longitude": 51.590999603271484,
//       "GMT Offset": "6",
//       "Daylight Savings": "N",
//       "Region": "Asia/Qyzylorda",
//       "Type": "airport"
//     },
//     {
//       "ID": 9162,
//       "Airport Code [ICAO]": "UWOD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Adamovka",
//       "Altitude (Ft)": 0,
//       "Country": "Russia",
//       "Latitude": 59.936111,
//       "Location": "Adamovka",
//       "Longitude": 51.5,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 9163,
//       "Airport Code [ICAO]": "UWOH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kvarkeno",
//       "Altitude (Ft)": 0,
//       "Country": "Russia",
//       "Latitude": 59.683333,
//       "Location": "Kvarkeno",
//       "Longitude": 52.078333,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yekaterinburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 9164,
//       "Airport Code [ICAO]": "KSIK",
//       "Airport Code iATA,FAA": "SIK",
//       "Airport Name": "Sikeston Memorial Municipal Airport",
//       "Altitude (Ft)": 315,
//       "Country": "United States",
//       "Latitude": -89.561798095703,
//       "Location": "Sikeston",
//       "Longitude": 36.898899078369,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9175,
//       "Airport Code [ICAO]": "NTTE",
//       "Airport Code iATA,FAA": "TTI",
//       "Airport Name": "Tetiaroa Airport",
//       "Altitude (Ft)": 7,
//       "Country": "French Polynesia",
//       "Latitude": -149.58700561523438,
//       "Location": "Tetiaroa",
//       "Longitude": -17.0132999420166,
//       "GMT Offset": "-10",
//       "Daylight Savings": "N",
//       "Region": "Pacific/Tahiti",
//       "Type": "airport"
//     },
//     {
//       "ID": 9178,
//       "Airport Code [ICAO]": "KGFL",
//       "Airport Code iATA,FAA": "GFL",
//       "Airport Name": "Floyd Bennett Memorial Airport",
//       "Altitude (Ft)": 328,
//       "Country": "United States",
//       "Latitude": -73.6102981567,
//       "Location": "Queensbury",
//       "Longitude": 43.3412017822,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9179,
//       "Airport Code [ICAO]": "K5B2",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Saratoga County Airport",
//       "Altitude (Ft)": 434,
//       "Country": "United States",
//       "Latitude": -73.86119843,
//       "Location": "Ballston Spa",
//       "Longitude": 43.05130005,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9182,
//       "Airport Code [ICAO]": "KCGC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Crystal River Airport",
//       "Altitude (Ft)": 9,
//       "Country": "United States",
//       "Latitude": -82.57129669189453,
//       "Location": "Crystal River",
//       "Longitude": 28.867300033569336,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9183,
//       "Airport Code [ICAO]": "KMTN",
//       "Airport Code iATA,FAA": "MTN",
//       "Airport Name": "Martin State Airport",
//       "Altitude (Ft)": 21,
//       "Country": "United States",
//       "Latitude": -76.413803,
//       "Location": "Baltimore",
//       "Longitude": 39.325699,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9184,
//       "Airport Code [ICAO]": "KLHM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lincoln Regional Karl Harder Field",
//       "Altitude (Ft)": 121,
//       "Country": "United States",
//       "Latitude": -121.35099792480469,
//       "Location": "Lincoln",
//       "Longitude": 38.90919876098633,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 9185,
//       "Airport Code [ICAO]": "KFZI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Fostoria Metropolitan Airport",
//       "Altitude (Ft)": 752,
//       "Country": "United States",
//       "Latitude": -83.39450073,
//       "Location": "Fostoria",
//       "Longitude": 41.19079971,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9186,
//       "Airport Code [ICAO]": "KIZG",
//       "Airport Code iATA,FAA": "FRY",
//       "Airport Name": "Eastern Slopes Regional Airport",
//       "Altitude (Ft)": 454,
//       "Country": "United States",
//       "Latitude": -70.9478988647,
//       "Location": "Fryeburg",
//       "Longitude": 43.991100311299995,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9187,
//       "Airport Code [ICAO]": "FA54",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Coral Creek Airport",
//       "Altitude (Ft)": 4,
//       "Country": "United States",
//       "Latitude": -82.2511978149414,
//       "Location": "Placida",
//       "Longitude": 26.85449981689453,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9188,
//       "Airport Code [ICAO]": "KNEW",
//       "Airport Code iATA,FAA": "NEW",
//       "Airport Name": "Lakefront Airport",
//       "Altitude (Ft)": 8,
//       "Country": "United States",
//       "Latitude": -90.028297424316,
//       "Location": "New Orleans",
//       "Longitude": 30.042400360107,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9189,
//       "Airport Code [ICAO]": "KCOE",
//       "Airport Code iATA,FAA": "COE",
//       "Airport Name": "Coeur D'Alene - Pappy Boyington Field",
//       "Altitude (Ft)": 2320,
//       "Country": "United States",
//       "Latitude": -116.8199997,
//       "Location": "Coeur d'Alene",
//       "Longitude": 47.77429962,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 9190,
//       "Airport Code [ICAO]": "KBMT",
//       "Airport Code iATA,FAA": "BMT",
//       "Airport Name": "Beaumont Municipal Airport",
//       "Altitude (Ft)": 32,
//       "Country": "United States",
//       "Latitude": -94.21579742430002,
//       "Location": "Beaumont",
//       "Longitude": 30.0706996918,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9191,
//       "Airport Code [ICAO]": "KDNV",
//       "Airport Code iATA,FAA": "DNV",
//       "Airport Name": "Vermilion Regional Airport",
//       "Altitude (Ft)": 697,
//       "Country": "United States",
//       "Latitude": -87.59590149,
//       "Location": "Danville",
//       "Longitude": 40.19919968,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9193,
//       "Airport Code [ICAO]": "YCBB",
//       "Airport Code iATA,FAA": "COJ",
//       "Airport Name": "Coonabarabran Airport",
//       "Altitude (Ft)": 2117,
//       "Country": "Australia",
//       "Latitude": 149.26699829101562,
//       "Location": "Coonabarabran",
//       "Longitude": -31.332500457763672,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 9199,
//       "Airport Code [ICAO]": "KTIX",
//       "Airport Code iATA,FAA": "TIX",
//       "Airport Name": "Space Coast Regional Airport",
//       "Altitude (Ft)": 34,
//       "Country": "United States",
//       "Latitude": -80.799201965332,
//       "Location": "Titusville",
//       "Longitude": 28.514799118042,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9205,
//       "Airport Code [ICAO]": "FVBM",
//       "Airport Code iATA,FAA": "BZH",
//       "Airport Name": "Bumi Airport",
//       "Altitude (Ft)": 1650,
//       "Country": "Zimbabwe",
//       "Latitude": 28.3500003815,
//       "Location": "Bumi Hills",
//       "Longitude": -16.8169994354,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Harare",
//       "Type": "airport"
//     },
//     {
//       "ID": 9207,
//       "Airport Code [ICAO]": "YWVA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Warnervale Airport",
//       "Altitude (Ft)": 25,
//       "Country": "Australia",
//       "Latitude": 151.429722,
//       "Location": "Warnervale Airport",
//       "Longitude": -33.240278,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 9215,
//       "Airport Code [ICAO]": "GMFB",
//       "Airport Code iATA,FAA": "UAR",
//       "Airport Name": "Bouarfa Airport",
//       "Altitude (Ft)": 3630,
//       "Country": "Morocco",
//       "Latitude": -1.98305555556,
//       "Location": "Bouarfa",
//       "Longitude": 32.5143055556,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Casablanca",
//       "Type": "airport"
//     },
//     {
//       "ID": 9218,
//       "Airport Code [ICAO]": "HKNI",
//       "Airport Code iATA,FAA": "NYE",
//       "Airport Name": "Nyeri Airport",
//       "Altitude (Ft)": 5830,
//       "Country": "Kenya",
//       "Latitude": 36.978485107421875,
//       "Location": "NYERI",
//       "Longitude": -0.3644140064716339,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Africa/Nairobi",
//       "Type": "airport"
//     },
//     {
//       "ID": 9225,
//       "Airport Code [ICAO]": "KAAP",
//       "Airport Code iATA,FAA": "AAP",
//       "Airport Name": "Andrau Airpark",
//       "Altitude (Ft)": 79,
//       "Country": "United States",
//       "Latitude": -95.58830261230001,
//       "Location": "Houston",
//       "Longitude": 29.722499847399998,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9226,
//       "Airport Code [ICAO]": "KFCM",
//       "Airport Code iATA,FAA": "FCM",
//       "Airport Name": "Flying Cloud Airport",
//       "Altitude (Ft)": 906,
//       "Country": "United States",
//       "Latitude": -93.45709991460001,
//       "Location": "Eden Prairie",
//       "Longitude": 44.8272018433,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9227,
//       "Airport Code [ICAO]": "FWLK",
//       "Airport Code iATA,FAA": "LIX",
//       "Airport Name": "Likoma Island Airport",
//       "Altitude (Ft)": 1600,
//       "Country": "Malawi",
//       "Latitude": 34.737222,
//       "Location": "Likoma Island",
//       "Longitude": -12.075833,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Blantyre",
//       "Type": "airport"
//     },
//     {
//       "ID": 9228,
//       "Airport Code [ICAO]": "KOJC",
//       "Airport Code iATA,FAA": "OJC",
//       "Airport Name": "Johnson County Executive Airport",
//       "Altitude (Ft)": 1096,
//       "Country": "United States",
//       "Latitude": -94.73760223,
//       "Location": "Olathe",
//       "Longitude": 38.84759903,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9229,
//       "Airport Code [ICAO]": "VCCS",
//       "Airport Code iATA,FAA": "GIU",
//       "Airport Name": "Sigiriya Air Force Base",
//       "Altitude (Ft)": 630,
//       "Country": "Sri Lanka",
//       "Latitude": 80.7285003662,
//       "Location": "Sigiriya",
//       "Longitude": 7.956669807430001,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": "Asia/Colombo",
//       "Type": "airport"
//     },
//     {
//       "ID": 9232,
//       "Airport Code [ICAO]": "EDHN",
//       "Airport Code iATA,FAA": "EUM",
//       "Airport Name": "Neumünster Airport",
//       "Altitude (Ft)": 72,
//       "Country": "Germany",
//       "Latitude": 9.941389083862305,
//       "Location": "Neumuenster",
//       "Longitude": 54.079444885253906,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 9233,
//       "Airport Code [ICAO]": "VTPT",
//       "Airport Code iATA,FAA": "TKT",
//       "Airport Name": "Tak Airport",
//       "Altitude (Ft)": 478,
//       "Country": "Thailand",
//       "Latitude": 99.25330352783203,
//       "Location": "Tak",
//       "Longitude": 16.895999908447266,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Bangkok",
//       "Type": "airport"
//     },
//     {
//       "ID": 9236,
//       "Airport Code [ICAO]": "CYLS",
//       "Airport Code iATA,FAA": "YLK",
//       "Airport Name": "Barrie-Orillia (Lake Simcoe Regional Airport)",
//       "Altitude (Ft)": 972,
//       "Country": "Canada",
//       "Latitude": -79.55560302730001,
//       "Location": "Barrie-Orillia",
//       "Longitude": 44.4852981567,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 9237,
//       "Airport Code [ICAO]": "CYEE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Huronia Airport",
//       "Altitude (Ft)": 770,
//       "Country": "Canada",
//       "Latitude": -79.9282989502,
//       "Location": "Midland",
//       "Longitude": 44.6833000183,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 9238,
//       "Airport Code [ICAO]": "CNU8",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Markham Airport",
//       "Altitude (Ft)": 807,
//       "Country": "Canada",
//       "Latitude": -79.26219940185547,
//       "Location": "Markham",
//       "Longitude": 43.93579864501953,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 9239,
//       "Airport Code [ICAO]": "CND4",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Stanhope Municipal Airport",
//       "Altitude (Ft)": 1066,
//       "Country": "Canada",
//       "Latitude": -78.64,
//       "Location": "Haliburton",
//       "Longitude": 45.1108333333,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 9240,
//       "Airport Code [ICAO]": "CNF4",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kawartha Lakes (Lindsay) Airport",
//       "Altitude (Ft)": 882,
//       "Country": "Canada",
//       "Latitude": -78.783897,
//       "Location": "Lindsay",
//       "Longitude": 44.3647,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 9241,
//       "Airport Code [ICAO]": "CYSN",
//       "Airport Code iATA,FAA": "YCM",
//       "Airport Name": "Niagara District Airport",
//       "Altitude (Ft)": 321,
//       "Country": "Canada",
//       "Latitude": -79.17169952392578,
//       "Location": "Saint Catherines",
//       "Longitude": 43.19169998168945,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 9243,
//       "Airport Code [ICAO]": "CNV8",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Edenvale Aerodrome",
//       "Altitude (Ft)": 718,
//       "Country": "Canada",
//       "Latitude": -79.962799,
//       "Location": "Edenvale",
//       "Longitude": 44.441101,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 9244,
//       "Airport Code [ICAO]": "CNJ4",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Orillia Airport",
//       "Altitude (Ft)": 725,
//       "Country": "Canada",
//       "Latitude": -79.31021690370001,
//       "Location": "Orillia",
//       "Longitude": 44.67765578389999,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 9245,
//       "Airport Code [ICAO]": "CLA4",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Holland Landing Airpark",
//       "Altitude (Ft)": 855,
//       "Country": "Canada",
//       "Latitude": -79.49500274658203,
//       "Location": "Holland Landing",
//       "Longitude": 44.08940124511719,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 9247,
//       "Airport Code [ICAO]": "CNK4",
//       "Airport Code iATA,FAA": "YPD",
//       "Airport Name": "Parry Sound Area Municipal Airport",
//       "Altitude (Ft)": 832,
//       "Country": "Canada",
//       "Latitude": -79.829697,
//       "Location": "Parry Sound",
//       "Longitude": 45.2575,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 9248,
//       "Airport Code [ICAO]": "CYHS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hanover / Saugeen Municipal Airport",
//       "Altitude (Ft)": 939,
//       "Country": "Canada",
//       "Latitude": -81.062798,
//       "Location": "Hanover",
//       "Longitude": 44.158298,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 9249,
//       "Airport Code [ICAO]": "KOQN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Brandywine Airport",
//       "Altitude (Ft)": 466,
//       "Country": "United States",
//       "Latitude": -75.58190155029297,
//       "Location": "West Goshen Township",
//       "Longitude": 39.9901008605957,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9251,
//       "Airport Code [ICAO]": "KHEF",
//       "Airport Code iATA,FAA": "MNZ",
//       "Airport Name": "Manassas Regional Airport/Harry P. Davis Field",
//       "Altitude (Ft)": 192,
//       "Country": "United States",
//       "Latitude": -77.51540375,
//       "Location": "Manassas",
//       "Longitude": 38.72140121,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9252,
//       "Airport Code [ICAO]": "KLBX",
//       "Airport Code iATA,FAA": "LJN",
//       "Airport Name": "Texas Gulf Coast Regional Airport",
//       "Altitude (Ft)": 25,
//       "Country": "United States",
//       "Latitude": -95.462097168,
//       "Location": "Angleton",
//       "Longitude": 29.1086006165,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9253,
//       "Airport Code [ICAO]": "LKBU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bubovice Airport",
//       "Altitude (Ft)": 1401,
//       "Country": "Czech Republic",
//       "Latitude": 14.178099632263184,
//       "Location": "Bubovice",
//       "Longitude": 49.97439956665039,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Prague",
//       "Type": "airport"
//     },
//     {
//       "ID": 9255,
//       "Airport Code [ICAO]": "ENRK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Rakkestad Astorp Airport",
//       "Altitude (Ft)": 400,
//       "Country": "Norway",
//       "Latitude": 11.3469,
//       "Location": "Rakkestad",
//       "Longitude": 59.397499,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Oslo",
//       "Type": "airport"
//     },
//     {
//       "ID": 9272,
//       "Airport Code [ICAO]": "LTCU",
//       "Airport Code iATA,FAA": "BGG",
//       "Airport Name": "Bingöl Çeltiksuyu Airport",
//       "Altitude (Ft)": 3506,
//       "Country": "Turkey",
//       "Latitude": 40.5959625244,
//       "Location": "Bingol",
//       "Longitude": 38.8592605591,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 9273,
//       "Airport Code [ICAO]": "LTAL",
//       "Airport Code iATA,FAA": "KFS",
//       "Airport Name": "Kastamonu Airport",
//       "Altitude (Ft)": 3520,
//       "Country": "Turkey",
//       "Latitude": 33.795799255371094,
//       "Location": "Kastamonu",
//       "Longitude": 41.31420135498047,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 9276,
//       "Airport Code [ICAO]": "EGTR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Elstree Airfield",
//       "Altitude (Ft)": 332,
//       "Country": "United Kingdom",
//       "Latitude": -0.325832992792,
//       "Location": "Elstree",
//       "Longitude": 51.6557998657,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 9277,
//       "Airport Code [ICAO]": "EGCF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sandtoft Airfield",
//       "Altitude (Ft)": 11,
//       "Country": "United Kingdom",
//       "Latitude": -0.8583329916,
//       "Location": "Sandtoft",
//       "Longitude": 53.559700012200004,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 9290,
//       "Airport Code [ICAO]": "HSTR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Torit Airport",
//       "Altitude (Ft)": 2050,
//       "Country": "South Sudan",
//       "Latitude": 32.58300018310547,
//       "Location": "Torit",
//       "Longitude": 4.400000095367432,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Africa/Juba",
//       "Type": "airport"
//     },
//     {
//       "ID": 9305,
//       "Airport Code [ICAO]": "K2H0",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Shelby County Airport",
//       "Altitude (Ft)": 618,
//       "Country": "United States",
//       "Latitude": -88.8453979492,
//       "Location": "Shelbyville",
//       "Longitude": 39.410400390599996,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9308,
//       "Airport Code [ICAO]": "ZBLL",
//       "Airport Code iATA,FAA": "LLV",
//       "Airport Name": "Lüliang Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 111.142778,
//       "Location": "Lvliang",
//       "Longitude": 37.683333,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 9310,
//       "Airport Code [ICAO]": "ZUDC",
//       "Airport Code iATA,FAA": "DCY",
//       "Airport Name": "Daocheng Yading Airport",
//       "Altitude (Ft)": 14472,
//       "Country": "China",
//       "Latitude": 100.053333,
//       "Location": "Daocheng",
//       "Longitude": 29.323056,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 9311,
//       "Airport Code [ICAO]": "ZLXH",
//       "Airport Code iATA,FAA": "GXH",
//       "Airport Name": "Gannan Xiahe Airport",
//       "Altitude (Ft)": 10510,
//       "Country": "China",
//       "Latitude": 102.6447,
//       "Location": "Xiahe city",
//       "Longitude": 34.8105,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 9317,
//       "Airport Code [ICAO]": "ESSZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Vängsö Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Sweden",
//       "Latitude": 17.21109962463379,
//       "Location": "Vangso",
//       "Longitude": 59.10110092163086,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Stockholm",
//       "Type": "airport"
//     },
//     {
//       "ID": 9327,
//       "Airport Code [ICAO]": "LICB",
//       "Airport Code iATA,FAA": "CIY",
//       "Airport Name": "Comiso Airport",
//       "Altitude (Ft)": 623,
//       "Country": "Italy",
//       "Latitude": 14.607182,
//       "Location": "Comiso",
//       "Longitude": 36.994601,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Rome",
//       "Type": "airport"
//     },
//     {
//       "ID": 9348,
//       "Airport Code [ICAO]": "WA77",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Enumclaw Airport",
//       "Altitude (Ft)": 738,
//       "Country": "United States",
//       "Latitude": -122.02200317382812,
//       "Location": "Enumclaw",
//       "Longitude": 47.195701599121094,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 9371,
//       "Airport Code [ICAO]": "UHMO",
//       "Airport Code iATA,FAA": "KVM",
//       "Airport Name": "Markovo Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Russia",
//       "Latitude": 170.417007,
//       "Location": "Markovo",
//       "Longitude": 64.667,
//       "GMT Offset": "12",
//       "Daylight Savings": "N",
//       "Region": "Asia/Anadyr",
//       "Type": "airport"
//     },
//     {
//       "ID": 9372,
//       "Airport Code [ICAO]": "UHMS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Seymchan Airport",
//       "Altitude (Ft)": 679,
//       "Country": "Russia",
//       "Latitude": 152.4227752685547,
//       "Location": "Seymchan",
//       "Longitude": 62.920780181884766,
//       "GMT Offset": "11",
//       "Daylight Savings": "N",
//       "Region": "Asia/Srednekolymsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 9373,
//       "Airport Code [ICAO]": "UESU",
//       "Airport Code iATA,FAA": "ZKP",
//       "Airport Name": "Zyryanka Airport",
//       "Altitude (Ft)": 140,
//       "Country": "Russia",
//       "Latitude": 150.8889,
//       "Location": "Zyryanka",
//       "Longitude": 65.7485,
//       "GMT Offset": "11",
//       "Daylight Savings": "N",
//       "Region": "Asia/Srednekolymsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 9374,
//       "Airport Code [ICAO]": "UHMH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Susuman Airport",
//       "Altitude (Ft)": 2129,
//       "Country": "Russia",
//       "Latitude": 148.14666748046875,
//       "Location": "Susuman",
//       "Longitude": 62.766666412353516,
//       "GMT Offset": "11",
//       "Daylight Savings": "N",
//       "Region": "Asia/Srednekolymsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 9376,
//       "Airport Code [ICAO]": "UEMU",
//       "Airport Code iATA,FAA": "UMS",
//       "Airport Name": "Ust-Maya Airport",
//       "Altitude (Ft)": 561,
//       "Country": "Russia",
//       "Latitude": 134.43499755859,
//       "Location": "Ust-Maya",
//       "Longitude": 60.356998443604,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yakutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 9377,
//       "Airport Code [ICAO]": "UEEA",
//       "Airport Code iATA,FAA": "ADH",
//       "Airport Name": "Aldan Airport",
//       "Altitude (Ft)": 2241,
//       "Country": "Russia",
//       "Latitude": 125.40899658203125,
//       "Location": "Aldan",
//       "Longitude": 58.60279846191406,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yakutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 9378,
//       "Airport Code [ICAO]": "UEMO",
//       "Airport Code iATA,FAA": "OLZ",
//       "Airport Name": "Olyokminsk Airport",
//       "Altitude (Ft)": 656,
//       "Country": "Russia",
//       "Latitude": 120.471001,
//       "Location": "Olekminsk",
//       "Longitude": 60.397499,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yakutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 9379,
//       "Airport Code [ICAO]": "UERT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Vitim Airport",
//       "Altitude (Ft)": 610,
//       "Country": "Russia",
//       "Latitude": 112.563004,
//       "Location": "Vitim",
//       "Longitude": 59.458,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yakutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 9380,
//       "Airport Code [ICAO]": "EDHP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Pellworm Field",
//       "Altitude (Ft)": 3,
//       "Country": "Germany",
//       "Latitude": 8.68000030518,
//       "Location": "Pellworm",
//       "Longitude": 54.5363883972,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Berlin",
//       "Type": "airport"
//     },
//     {
//       "ID": 9386,
//       "Airport Code [ICAO]": "ZWNL",
//       "Airport Code iATA,FAA": "NLT",
//       "Airport Name": "Xinyuan Nalati Airport",
//       "Altitude (Ft)": 3050,
//       "Country": "China",
//       "Latitude": 83.3786,
//       "Location": "Xinyuan",
//       "Longitude": 43.4318,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 9387,
//       "Airport Code [ICAO]": "PALJ",
//       "Airport Code iATA,FAA": "PTA",
//       "Airport Name": "Port Alsworth Airport",
//       "Altitude (Ft)": 280,
//       "Country": "United States",
//       "Latitude": -154.325863,
//       "Location": "Port alsworth",
//       "Longitude": 60.201681,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 9388,
//       "Airport Code [ICAO]": "LFSQ",
//       "Airport Code iATA,FAA": "BOR",
//       "Airport Name": "Fontaine Airport",
//       "Altitude (Ft)": 1208,
//       "Country": "France",
//       "Latitude": 7.0108299255371,
//       "Location": "Belfort",
//       "Longitude": 47.655601501465,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 9390,
//       "Airport Code [ICAO]": "KFDW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Fairfield County Airport",
//       "Altitude (Ft)": 577,
//       "Country": "United States",
//       "Latitude": -81.10880279541016,
//       "Location": "Winnsboro",
//       "Longitude": 34.31549835205078,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9391,
//       "Airport Code [ICAO]": "HDOB",
//       "Airport Code iATA,FAA": "OBC",
//       "Airport Name": "Obock Airport",
//       "Altitude (Ft)": 69,
//       "Country": "Djibouti",
//       "Latitude": 43.266998291015625,
//       "Location": "Obock",
//       "Longitude": 11.967000007629395,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Africa/Djibouti",
//       "Type": "airport"
//     },
//     {
//       "ID": 9392,
//       "Airport Code [ICAO]": "HDTJ",
//       "Airport Code iATA,FAA": "TDJ",
//       "Airport Name": "Tadjoura Airport",
//       "Altitude (Ft)": 246,
//       "Country": "Djibouti",
//       "Latitude": 42.91699981689453,
//       "Location": "Tadjoura",
//       "Longitude": 11.782999992370605,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Africa/Djibouti",
//       "Type": "airport"
//     },
//     {
//       "ID": 9393,
//       "Airport Code [ICAO]": "MGQC",
//       "Airport Code iATA,FAA": "AQB",
//       "Airport Name": "Santa Cruz del Quiche Airport",
//       "Altitude (Ft)": 6631,
//       "Country": "Guatemala",
//       "Latitude": -91.15059661865234,
//       "Location": "Santa Cruz des Quiche",
//       "Longitude": 15.012200355529785,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Guatemala",
//       "Type": "airport"
//     },
//     {
//       "ID": 9394,
//       "Airport Code [ICAO]": "BINF",
//       "Airport Code iATA,FAA": "NOR",
//       "Airport Name": "Norðfjörður Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Iceland",
//       "Latitude": -13.746399879455566,
//       "Location": "Nordfjordur",
//       "Longitude": 65.13189697265625,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Atlantic/Reykjavik",
//       "Type": "airport"
//     },
//     {
//       "ID": 9395,
//       "Airport Code [ICAO]": "LTBE",
//       "Airport Code iATA,FAA": "BTZ",
//       "Airport Name": "Bursa Airport",
//       "Altitude (Ft)": 331,
//       "Country": "Turkey",
//       "Latitude": 29.009199142456055,
//       "Location": "Bursa",
//       "Longitude": 40.233299255371094,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 9396,
//       "Airport Code [ICAO]": "KDAW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Skyhaven Airport",
//       "Altitude (Ft)": 322,
//       "Country": "United States",
//       "Latitude": -70.9292984008789,
//       "Location": "Rochester",
//       "Longitude": 43.28409957885742,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9397,
//       "Airport Code [ICAO]": "WAJR",
//       "Airport Code iATA,FAA": "WAR",
//       "Airport Name": "Waris Airport",
//       "Altitude (Ft)": 1500,
//       "Country": "Indonesia",
//       "Latitude": 140.994,
//       "Location": "Waris-Papua Island",
//       "Longitude": -3.235,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": "Asia/Jayapura",
//       "Type": "airport"
//     },
//     {
//       "ID": 9399,
//       "Airport Code [ICAO]": "KEWK",
//       "Airport Code iATA,FAA": "EWK",
//       "Airport Name": "Newton City-County Airport",
//       "Altitude (Ft)": 1533,
//       "Country": "United States",
//       "Latitude": -97.2744979858,
//       "Location": "Newton",
//       "Longitude": 38.058200836199994,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9400,
//       "Airport Code [ICAO]": "LFFQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "La Ferté Alais Airfield",
//       "Altitude (Ft)": 453,
//       "Country": "France",
//       "Latitude": 2.338867,
//       "Location": "La Ferte Alais",
//       "Longitude": 48.498652,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": "Europe/Paris",
//       "Type": "airport"
//     },
//     {
//       "ID": 9402,
//       "Airport Code [ICAO]": "YBNS",
//       "Airport Code iATA,FAA": "BSJ",
//       "Airport Name": "Bairnsdale Airport",
//       "Altitude (Ft)": 165,
//       "Country": "Australia",
//       "Latitude": 147.5679931640625,
//       "Location": "Bairnsdale",
//       "Longitude": -37.88750076293945,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Hobart",
//       "Type": "airport"
//     },
//     {
//       "ID": 9403,
//       "Airport Code [ICAO]": "LHTA",
//       "Airport Code iATA,FAA": "TZR",
//       "Airport Name": "Taszár Air Base",
//       "Altitude (Ft)": 531,
//       "Country": "United States",
//       "Latitude": 17.917499542236328,
//       "Location": "Columbus",
//       "Longitude": 46.39310073852539,
//       "GMT Offset": "1",
//       "Daylight Savings": "A",
//       "Region": "Europe/Budapest",
//       "Type": "airport"
//     },
//     {
//       "ID": 9406,
//       "Airport Code [ICAO]": "KFBR",
//       "Airport Code iATA,FAA": "FBR",
//       "Airport Name": "Fort Bridger Airport",
//       "Altitude (Ft)": 7034,
//       "Country": "United States",
//       "Latitude": -110.406997681,
//       "Location": "Fort Bridger",
//       "Longitude": 41.3918991089,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 9407,
//       "Airport Code [ICAO]": "KS40",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Prosser Airport",
//       "Altitude (Ft)": 697,
//       "Country": "United States",
//       "Latitude": -119.7910004,
//       "Location": "Prosser",
//       "Longitude": 46.21340179,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 9408,
//       "Airport Code [ICAO]": "KCLS",
//       "Airport Code iATA,FAA": "CLS",
//       "Airport Name": "Chehalis Centralia Airport",
//       "Altitude (Ft)": 176,
//       "Country": "United States",
//       "Latitude": -122.983001709,
//       "Location": "Chehalis",
//       "Longitude": 46.676998138399995,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 9409,
//       "Airport Code [ICAO]": "KM94",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Desert Aire Regional Airport",
//       "Altitude (Ft)": 586,
//       "Country": "United States",
//       "Latitude": -119.920998,
//       "Location": "Mattawa",
//       "Longitude": 46.687401,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 9411,
//       "Airport Code [ICAO]": "KEVW",
//       "Airport Code iATA,FAA": "EVW",
//       "Airport Name": "Evanston-Uinta County Airport-Burns Field",
//       "Altitude (Ft)": 7143,
//       "Country": "United States",
//       "Latitude": -111.0350037,
//       "Location": "Evanston",
//       "Longitude": 41.27479935,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 9412,
//       "Airport Code [ICAO]": "KK83",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sabetha Municipal Airport",
//       "Altitude (Ft)": 1330,
//       "Country": "United States",
//       "Latitude": -95.77940368652344,
//       "Location": "Sabetha",
//       "Longitude": 39.90420150756836,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9413,
//       "Airport Code [ICAO]": "KLRO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mt Pleasant Regional-Faison field",
//       "Altitude (Ft)": 12,
//       "Country": "United States",
//       "Latitude": -79.78289795,
//       "Location": "Mount Pleasant",
//       "Longitude": 32.89780045,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9414,
//       "Airport Code [ICAO]": "KACJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Jimmy Carter Regional Airport",
//       "Altitude (Ft)": 468,
//       "Country": "United States",
//       "Latitude": -84.188904,
//       "Location": "Americus",
//       "Longitude": 32.110802,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9415,
//       "Airport Code [ICAO]": "KEUF",
//       "Airport Code iATA,FAA": "EUF",
//       "Airport Name": "Weedon Field",
//       "Altitude (Ft)": 285,
//       "Country": "United States",
//       "Latitude": -85.1288986206,
//       "Location": "Eufala",
//       "Longitude": 31.9512996674,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9416,
//       "Airport Code [ICAO]": "K6J4",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Saluda County Airport",
//       "Altitude (Ft)": 555,
//       "Country": "United States",
//       "Latitude": -81.79460144042969,
//       "Location": "Saluda",
//       "Longitude": 33.92679977416992,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9417,
//       "Airport Code [ICAO]": "KMQI",
//       "Airport Code iATA,FAA": "MEO",
//       "Airport Name": "Dare County Regional Airport",
//       "Altitude (Ft)": 13,
//       "Country": "United States",
//       "Latitude": -75.69550323,
//       "Location": "Manteo",
//       "Longitude": 35.91899872,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9418,
//       "Airport Code [ICAO]": "KAUO",
//       "Airport Code iATA,FAA": "AUO",
//       "Airport Name": "Auburn University Regional Airport",
//       "Altitude (Ft)": 777,
//       "Country": "United States",
//       "Latitude": -85.433998,
//       "Location": "Auburn",
//       "Longitude": 32.615101,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9419,
//       "Airport Code [ICAO]": "KCZG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tri Cities Airport",
//       "Altitude (Ft)": 833,
//       "Country": "United States",
//       "Latitude": -76.096296,
//       "Location": "Endicott",
//       "Longitude": 42.078499,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9428,
//       "Airport Code [ICAO]": "KEKY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bessemer Airport",
//       "Altitude (Ft)": 700,
//       "Country": "United States",
//       "Latitude": -86.92590332,
//       "Location": "Bessemer",
//       "Longitude": 33.31290054,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9429,
//       "Airport Code [ICAO]": "KA50",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Colorado Springs East Airport",
//       "Altitude (Ft)": 6145,
//       "Country": "United States",
//       "Latitude": -104.41000366210938,
//       "Location": "Ellicott",
//       "Longitude": 38.8744010925293,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 9447,
//       "Airport Code [ICAO]": "KMIC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Crystal Airport",
//       "Altitude (Ft)": 869,
//       "Country": "United States",
//       "Latitude": -93.35389709472656,
//       "Location": "Crystal",
//       "Longitude": 45.0620002746582,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9448,
//       "Airport Code [ICAO]": "K23M",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Clarke County Airport",
//       "Altitude (Ft)": 320,
//       "Country": "United States",
//       "Latitude": -88.738897,
//       "Location": "Quitman",
//       "Longitude": 32.0849,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9450,
//       "Airport Code [ICAO]": "KDBN",
//       "Airport Code iATA,FAA": "DBN",
//       "Airport Name": "W H 'Bud' Barron Airport",
//       "Altitude (Ft)": 309,
//       "Country": "United States",
//       "Latitude": -82.98529816,
//       "Location": "Dublin",
//       "Longitude": 32.56439972,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9474,
//       "Airport Code [ICAO]": "NTGQ",
//       "Airport Code iATA,FAA": "PUK",
//       "Airport Name": "Pukarua Airport",
//       "Altitude (Ft)": 5,
//       "Country": "French Polynesia",
//       "Latitude": -137.01699829101562,
//       "Location": "Pukarua",
//       "Longitude": -18.29560089111328,
//       "GMT Offset": "-10",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 9475,
//       "Airport Code [ICAO]": "HUKB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kabale Airport",
//       "Altitude (Ft)": 6000,
//       "Country": "Uganda",
//       "Latitude": 29.96,
//       "Location": "Kabale",
//       "Longitude": -1.226111,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Africa/Kampala",
//       "Type": "airport"
//     },
//     {
//       "ID": 9481,
//       "Airport Code [ICAO]": "MRGT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Guatuso Airport",
//       "Altitude (Ft)": 164,
//       "Country": "France",
//       "Latitude": -84.83333587646484,
//       "Location": "Marigot",
//       "Longitude": 10.683333396911621,
//       "GMT Offset": "-6",
//       "Daylight Savings": "N",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 9484,
//       "Airport Code [ICAO]": "SVSJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Central Bolívar Airport",
//       "Altitude (Ft)": 39,
//       "Country": "Spain",
//       "Latitude": -71.93333435058594,
//       "Location": "Sevilla",
//       "Longitude": 8.916666984558105,
//       "GMT Offset": "-4",
//       "Daylight Savings": "E",
//       "Region": "America/Caracas",
//       "Type": "airport"
//     },
//     {
//       "ID": 9488,
//       "Airport Code [ICAO]": "KCVO",
//       "Airport Code iATA,FAA": "CVO",
//       "Airport Name": "Corvallis Municipal Airport",
//       "Altitude (Ft)": 250,
//       "Country": "United States",
//       "Latitude": -123.2900009,
//       "Location": "Corvallis",
//       "Longitude": 44.49720001,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 9492,
//       "Airport Code [ICAO]": "LRTZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tuzla Romania Airport",
//       "Altitude (Ft)": 161,
//       "Country": "Romania",
//       "Latitude": 28.609699249267578,
//       "Location": "Tuzla",
//       "Longitude": 43.98419952392578,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": "Europe/Bucharest",
//       "Type": "airport"
//     },
//     {
//       "ID": 9500,
//       "Airport Code [ICAO]": "SCRT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "El Almendro Airport",
//       "Altitude (Ft)": 486,
//       "Country": "United States",
//       "Latitude": -71.7933349609375,
//       "Location": "Scranton",
//       "Longitude": -35.96055603027344,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9511,
//       "Airport Code [ICAO]": "MRST",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "San Agustin Airport",
//       "Altitude (Ft)": 66,
//       "Country": "United States",
//       "Latitude": -84.88333129882812,
//       "Location": "Morristown",
//       "Longitude": 10.066666603088379,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Costa_Rica",
//       "Type": "airport"
//     },
//     {
//       "ID": 9521,
//       "Airport Code [ICAO]": "SDNS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Samambaia Heliport",
//       "Altitude (Ft)": 3167,
//       "Country": "Reunion",
//       "Latitude": -43.13055419921875,
//       "Location": "Saint Denis",
//       "Longitude": -22.462499618530273,
//       "GMT Offset": "-3",
//       "Daylight Savings": "E",
//       "Region": "America/Sao_Paulo",
//       "Type": "airport"
//     },
//     {
//       "ID": 9528,
//       "Airport Code [ICAO]": "SNKV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Fazenda Campo Verde Airport",
//       "Altitude (Ft)": 49,
//       "Country": "Cambodia",
//       "Latitude": -50.516700744599994,
//       "Location": "Sihanoukville",
//       "Longitude": 1.04278004169,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Fortaleza",
//       "Type": "airport"
//     },
//     {
//       "ID": 9532,
//       "Airport Code [ICAO]": "YPMH",
//       "Airport Code iATA,FAA": "PXH",
//       "Airport Name": "Prominent Hill Airport",
//       "Altitude (Ft)": 745,
//       "Country": "Australia",
//       "Latitude": 135.5244,
//       "Location": "Prominent Hill",
//       "Longitude": -29.716,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": "Australia/Adelaide",
//       "Type": "airport"
//     },
//     {
//       "ID": 9533,
//       "Airport Code [ICAO]": "YCWR",
//       "Airport Code iATA,FAA": "CWT",
//       "Airport Name": "Cowra Airport",
//       "Altitude (Ft)": 966,
//       "Country": "Australia",
//       "Latitude": 148.6490020751953,
//       "Location": "Chatsworth",
//       "Longitude": -33.84469985961914,
//       "GMT Offset": "10",
//       "Daylight Savings": "A",
//       "Region": "Australia/Sydney",
//       "Type": "airport"
//     },
//     {
//       "ID": 9542,
//       "Airport Code [ICAO]": "YCOY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Coral Bay Airport",
//       "Altitude (Ft)": 5,
//       "Country": "Australia",
//       "Latitude": 113.7770004272461,
//       "Location": "Coral Bay",
//       "Longitude": -23.1299991607666,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": "Australia/Perth",
//       "Type": "airport"
//     },
//     {
//       "ID": 9543,
//       "Airport Code [ICAO]": "KOGD",
//       "Airport Code iATA,FAA": "OGD",
//       "Airport Name": "Ogden Hinckley Airport",
//       "Altitude (Ft)": 4473,
//       "Country": "United States",
//       "Latitude": -112.0120010376,
//       "Location": "Ogden",
//       "Longitude": 41.195899963379,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 9544,
//       "Airport Code [ICAO]": "KW63",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lake Country Regional Airport",
//       "Altitude (Ft)": 421,
//       "Country": "United Arab Emirates",
//       "Latitude": -78.56009674070002,
//       "Location": "Clarksville",
//       "Longitude": 36.5957984924,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9545,
//       "Airport Code [ICAO]": "KRKR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Robert S Kerr Airport",
//       "Altitude (Ft)": 451,
//       "Country": "United States",
//       "Latitude": -94.62129974365234,
//       "Location": "Poteau",
//       "Longitude": 35.02159881591797,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9546,
//       "Airport Code [ICAO]": "KAKO",
//       "Airport Code iATA,FAA": "AKO",
//       "Airport Name": "Colorado Plains Regional Airport",
//       "Altitude (Ft)": 4714,
//       "Country": "United States",
//       "Latitude": -103.222000122,
//       "Location": "Akron",
//       "Longitude": 40.1755981445,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": "America/Denver",
//       "Type": "airport"
//     },
//     {
//       "ID": 9547,
//       "Airport Code [ICAO]": "KSHN",
//       "Airport Code iATA,FAA": "SHN",
//       "Airport Name": "Sanderson Field",
//       "Altitude (Ft)": 273,
//       "Country": "United States",
//       "Latitude": -123.14800262451,
//       "Location": "Shelton",
//       "Longitude": 47.233600616455,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Los_Angeles",
//       "Type": "airport"
//     },
//     {
//       "ID": 9739,
//       "Airport Code [ICAO]": "PANA",
//       "Airport Code iATA,FAA": "WNA",
//       "Airport Name": "Napakiak Airport",
//       "Altitude (Ft)": 17,
//       "Country": "United States",
//       "Latitude": -161.97900390625,
//       "Location": "Napakiak",
//       "Longitude": 60.69029998779297,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 9744,
//       "Airport Code [ICAO]": "PAPK",
//       "Airport Code iATA,FAA": "PKA",
//       "Airport Name": "Napaskiak Airport",
//       "Altitude (Ft)": 24,
//       "Country": "United States",
//       "Latitude": -161.7779999,
//       "Location": "Napaskiak",
//       "Longitude": 60.70289993,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 9746,
//       "Airport Code [ICAO]": "PATJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tok Airport",
//       "Altitude (Ft)": 1670,
//       "Country": "United States",
//       "Latitude": -143.001111,
//       "Location": "Tok",
//       "Longitude": 63.303333,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": "America/Anchorage",
//       "Type": "airport"
//     },
//     {
//       "ID": 9749,
//       "Airport Code [ICAO]": "CAB3",
//       "Airport Code iATA,FAA": "YBW",
//       "Airport Name": "Bedwell Harbour Seaplane Base",
//       "Altitude (Ft)": 0,
//       "Country": "Canada",
//       "Latitude": -123.233001709,
//       "Location": "Bedwell Harbour",
//       "Longitude": 48.75,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": "America/Vancouver",
//       "Type": "airport"
//     },
//     {
//       "ID": 9751,
//       "Airport Code [ICAO]": "SMWS",
//       "Airport Code iATA,FAA": "WSO",
//       "Airport Name": "Washabo Airport",
//       "Altitude (Ft)": 68,
//       "Country": "Suriname",
//       "Latitude": -57.185302,
//       "Location": "Washabo",
//       "Longitude": 5.215277,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": "America/Paramaribo",
//       "Type": "airport"
//     },
//     {
//       "ID": 9753,
//       "Airport Code [ICAO]": "EGHP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Popham Airfield",
//       "Altitude (Ft)": 550,
//       "Country": "United Kingdom",
//       "Latitude": -1.23472,
//       "Location": "Popham",
//       "Longitude": 51.193901,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 9754,
//       "Airport Code [ICAO]": "K2A5",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Causey Airport",
//       "Altitude (Ft)": 723,
//       "Country": "United States",
//       "Latitude": -79.61759948730469,
//       "Location": "Liberty",
//       "Longitude": 35.911800384521484,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9759,
//       "Airport Code [ICAO]": "MYAW",
//       "Airport Code iATA,FAA": "WKR",
//       "Airport Name": "Abaco I Walker C Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Bahamas",
//       "Latitude": -78.39969635009766,
//       "Location": "Walker's Cay",
//       "Longitude": 27.266700744628906,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 9760,
//       "Airport Code [ICAO]": "MYEB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Black Point Airstrip",
//       "Altitude (Ft)": 10,
//       "Country": "Bahamas",
//       "Latitude": -76.3979172707,
//       "Location": "Black Point",
//       "Longitude": 24.089488448799997,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Nassau",
//       "Type": "airport"
//     },
//     {
//       "ID": 9761,
//       "Airport Code [ICAO]": "SYBT",
//       "Airport Code iATA,FAA": "GFO",
//       "Airport Name": "Bartica A Airport",
//       "Altitude (Ft)": 3,
//       "Country": "Guyana",
//       "Latitude": -58.655207,
//       "Location": "Bartica",
//       "Longitude": 6.358864,
//       "GMT Offset": "-4",
//       "Daylight Savings": "N",
//       "Region": "America/Guyana",
//       "Type": "airport"
//     },
//     {
//       "ID": 9763,
//       "Airport Code [ICAO]": "KDYL",
//       "Airport Code iATA,FAA": "DYL",
//       "Airport Name": "Doylestown Airport",
//       "Altitude (Ft)": 394,
//       "Country": "United States",
//       "Latitude": -75.1222991943,
//       "Location": "Doylestown",
//       "Longitude": 40.3330001831,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9766,
//       "Airport Code [ICAO]": "FADW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cape Town Waterfort Heliport",
//       "Altitude (Ft)": 0,
//       "Country": "South Africa",
//       "Latitude": 0,
//       "Location": "Cap Town",
//       "Longitude": 0,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Johannesburg",
//       "Type": "airport"
//     },
//     {
//       "ID": 9768,
//       "Airport Code [ICAO]": "SPGM",
//       "Airport Code iATA,FAA": "TGI",
//       "Airport Name": "Tingo Maria Airport",
//       "Altitude (Ft)": 2010,
//       "Country": "Peru",
//       "Latitude": -75.94999694824219,
//       "Location": "Tingo Maria",
//       "Longitude": -9.133000373840332,
//       "GMT Offset": "-5",
//       "Daylight Savings": "N",
//       "Region": "America/Lima",
//       "Type": "airport"
//     },
//     {
//       "ID": 9769,
//       "Airport Code [ICAO]": "SSTL",
//       "Airport Code iATA,FAA": "TJL",
//       "Airport Name": "Plínio Alarcom Airport",
//       "Altitude (Ft)": 1050,
//       "Country": "Brazil",
//       "Latitude": -51.684200286865,
//       "Location": "Tres Lagoas",
//       "Longitude": -20.754199981689,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Campo_Grande",
//       "Type": "airport"
//     },
//     {
//       "ID": 9770,
//       "Airport Code [ICAO]": "ZLZY",
//       "Airport Code iATA,FAA": "YZY",
//       "Airport Name": "Zhangye Ganzhou Airport",
//       "Altitude (Ft)": 5280,
//       "Country": "China",
//       "Latitude": 100.675003052,
//       "Location": "Zhangye",
//       "Longitude": 38.8018989563,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 9771,
//       "Airport Code [ICAO]": "SSKW",
//       "Airport Code iATA,FAA": "OAL",
//       "Airport Name": "Cacoal Airport",
//       "Altitude (Ft)": 778,
//       "Country": "Brazil",
//       "Latitude": -61.4508,
//       "Location": "Cacoal",
//       "Longitude": -11.496,
//       "GMT Offset": "-4",
//       "Daylight Savings": "N",
//       "Region": "America/Boa_Vista",
//       "Type": "airport"
//     },
//     {
//       "ID": 9772,
//       "Airport Code [ICAO]": "KOCW",
//       "Airport Code iATA,FAA": "OCW",
//       "Airport Name": "Warren Field",
//       "Altitude (Ft)": 38,
//       "Country": "United States",
//       "Latitude": -77.049797058105,
//       "Location": "Washington",
//       "Longitude": 35.570499420166,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9773,
//       "Airport Code [ICAO]": "K7W6",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hyde County Airport",
//       "Altitude (Ft)": 8,
//       "Country": "United States",
//       "Latitude": -75.9552001953125,
//       "Location": "Engelhard",
//       "Longitude": 35.562400817871094,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9774,
//       "Airport Code [ICAO]": "SCPQ",
//       "Airport Code iATA,FAA": "MHC",
//       "Airport Name": "Mocopulli Airport",
//       "Altitude (Ft)": 528,
//       "Country": "Chile",
//       "Latitude": -73.715693,
//       "Location": "Castro",
//       "Longitude": -42.340388,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": "America/Santiago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9776,
//       "Airport Code [ICAO]": "KSWO",
//       "Airport Code iATA,FAA": "SWO",
//       "Airport Name": "Stillwater Regional Airport",
//       "Altitude (Ft)": 1000,
//       "Country": "United States",
//       "Latitude": -97.08570098877,
//       "Location": "Stillwater",
//       "Longitude": 36.161201477051,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9777,
//       "Airport Code [ICAO]": "KOKM",
//       "Airport Code iATA,FAA": "OKM",
//       "Airport Name": "Okmulgee Regional Airport",
//       "Altitude (Ft)": 720,
//       "Country": "United States",
//       "Latitude": -95.948699951172,
//       "Location": "Okmulgee",
//       "Longitude": 35.668098449707,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9778,
//       "Airport Code [ICAO]": "KCUH",
//       "Airport Code iATA,FAA": "CUH",
//       "Airport Name": "Cushing Municipal Airport",
//       "Altitude (Ft)": 916,
//       "Country": "United States",
//       "Latitude": -96.7731018066,
//       "Location": "Cushing",
//       "Longitude": 35.9499015808,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9779,
//       "Airport Code [ICAO]": "KCSM",
//       "Airport Code iATA,FAA": "CSM",
//       "Airport Name": "Clinton Sherman Airport",
//       "Altitude (Ft)": 1922,
//       "Country": "United States",
//       "Latitude": -99.20050048830001,
//       "Location": "Clinton",
//       "Longitude": 35.3398017883,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9780,
//       "Airport Code [ICAO]": "KWLD",
//       "Airport Code iATA,FAA": "WLD",
//       "Airport Name": "Strother Field",
//       "Altitude (Ft)": 1160,
//       "Country": "United States",
//       "Latitude": -97.0375976562,
//       "Location": "Winfield",
//       "Longitude": 37.168598175,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9781,
//       "Airport Code [ICAO]": "KPWA",
//       "Airport Code iATA,FAA": "PWA",
//       "Airport Name": "Wiley Post Airport",
//       "Altitude (Ft)": 1300,
//       "Country": "United States",
//       "Latitude": -97.64710236,
//       "Location": "Oklahoma City",
//       "Longitude": 35.53419876,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9782,
//       "Airport Code [ICAO]": "KDTN",
//       "Airport Code iATA,FAA": "DTN",
//       "Airport Name": "Shreveport Downtown Airport",
//       "Altitude (Ft)": 179,
//       "Country": "United States",
//       "Latitude": -93.7450027466,
//       "Location": "Shreveport",
//       "Longitude": 32.5401992798,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9783,
//       "Airport Code [ICAO]": "KSEP",
//       "Airport Code iATA,FAA": "SEP",
//       "Airport Name": "Stephenville Clark Regional Airport",
//       "Altitude (Ft)": 1321,
//       "Country": "United States",
//       "Latitude": -98.177696228027,
//       "Location": "Stephenville",
//       "Longitude": 32.215301513672,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9784,
//       "Airport Code [ICAO]": "KF22",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Perry Municipal Airport",
//       "Altitude (Ft)": 1002,
//       "Country": "United States",
//       "Latitude": -97.2771987915039,
//       "Location": "Perry",
//       "Longitude": 36.38560104370117,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9786,
//       "Airport Code [ICAO]": "KMNZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hamilton Municipal Airport",
//       "Altitude (Ft)": 1299,
//       "Country": "United States",
//       "Latitude": -98.14859771728516,
//       "Location": "Hamilton",
//       "Longitude": 31.6658992767334,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9787,
//       "Airport Code [ICAO]": "KADH",
//       "Airport Code iATA,FAA": "ADT",
//       "Airport Name": "Ada Regional Airport",
//       "Altitude (Ft)": 1016,
//       "Country": "United States",
//       "Latitude": -96.671303,
//       "Location": "Ada",
//       "Longitude": 34.804298,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9788,
//       "Airport Code [ICAO]": "KHQZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mesquite Metro Airport",
//       "Altitude (Ft)": 447,
//       "Country": "United States",
//       "Latitude": -96.53040313720703,
//       "Location": "Misquite",
//       "Longitude": 32.74700164794922,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9789,
//       "Airport Code [ICAO]": "KDTO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Denton Municipal Airport",
//       "Altitude (Ft)": 642,
//       "Country": "United States",
//       "Latitude": -97.19799804690001,
//       "Location": "Denton",
//       "Longitude": 33.2006988525,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9790,
//       "Airport Code [ICAO]": "KEDC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Austin Executive Airport",
//       "Altitude (Ft)": 620,
//       "Country": "United States",
//       "Latitude": -97.5663935,
//       "Location": "Austin",
//       "Longitude": 30.3974931,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9791,
//       "Airport Code [ICAO]": "KRYW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lago Vista Tx Rusty Allen Airport",
//       "Altitude (Ft)": 1231,
//       "Country": "United States",
//       "Latitude": -97.96949768066406,
//       "Location": "Lago Vista",
//       "Longitude": 30.498600006103516,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9792,
//       "Airport Code [ICAO]": "K11R",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Brenham Municipal Airport",
//       "Altitude (Ft)": 307,
//       "Country": "United States",
//       "Latitude": -96.3742981,
//       "Location": "Brenham",
//       "Longitude": 30.21899986,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9793,
//       "Airport Code [ICAO]": "K3R9",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lakeway Airpark",
//       "Altitude (Ft)": 909,
//       "Country": "United States",
//       "Latitude": -97.99449920654297,
//       "Location": "Lakeway",
//       "Longitude": 30.357500076293945,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9794,
//       "Airport Code [ICAO]": "K2F0",
//       "Airport Code iATA,FAA": "IRB",
//       "Airport Name": "Iraan Municipal Airport",
//       "Altitude (Ft)": 2200,
//       "Country": "United States",
//       "Latitude": -101.891998291,
//       "Location": "Iraan",
//       "Longitude": 30.9057006836,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9795,
//       "Airport Code [ICAO]": "K1T7",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kestrel Airpark",
//       "Altitude (Ft)": 1250,
//       "Country": "United States",
//       "Latitude": -98.42530059814453,
//       "Location": "San Antonio",
//       "Longitude": 29.812700271606445,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": "America/Chicago",
//       "Type": "airport"
//     },
//     {
//       "ID": 9796,
//       "Airport Code [ICAO]": "CYEL",
//       "Airport Code iATA,FAA": "YEL",
//       "Airport Name": "Elliot Lake Municipal Airport",
//       "Altitude (Ft)": 1087,
//       "Country": "Canada",
//       "Latitude": -82.5614013672,
//       "Location": "ELLIOT LAKE",
//       "Longitude": 46.351398468,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/Toronto",
//       "Type": "airport"
//     },
//     {
//       "ID": 9797,
//       "Airport Code [ICAO]": "KUKF",
//       "Airport Code iATA,FAA": "IKB",
//       "Airport Name": "Wilkes County Airport",
//       "Altitude (Ft)": 1301,
//       "Country": "United States",
//       "Latitude": -81.09829711910001,
//       "Location": "North Wilkesboro",
//       "Longitude": 36.2228012085,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9798,
//       "Airport Code [ICAO]": "KJZI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Charleston Executive Airport",
//       "Altitude (Ft)": 17,
//       "Country": "United States",
//       "Latitude": -80.00289916992188,
//       "Location": "Charleston",
//       "Longitude": 32.70090103149414,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9799,
//       "Airport Code [ICAO]": "KDAN",
//       "Airport Code iATA,FAA": "DAN",
//       "Airport Name": "Danville Regional Airport",
//       "Altitude (Ft)": 571,
//       "Country": "United States",
//       "Latitude": -79.33609771728516,
//       "Location": "Danville",
//       "Longitude": 36.572898864746094,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9800,
//       "Airport Code [ICAO]": "K0V4",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Brookneal/Campbell County Airport",
//       "Altitude (Ft)": 596,
//       "Country": "United States",
//       "Latitude": -79.01640319820001,
//       "Location": "Brookneal",
//       "Longitude": 37.141700744599994,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9805,
//       "Airport Code [ICAO]": "UIKE",
//       "Airport Code iATA,FAA": "ERG",
//       "Airport Name": "Yerbogachen Airport",
//       "Altitude (Ft)": 400,
//       "Country": "Russia",
//       "Latitude": 108.029998779,
//       "Location": "Yerbogachen",
//       "Longitude": 61.2750015259,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Irkutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 9806,
//       "Airport Code [ICAO]": "KCQW",
//       "Airport Code iATA,FAA": "HCW",
//       "Airport Name": "Cheraw Municipal Airport/Lynch Bellinger Field",
//       "Altitude (Ft)": 239,
//       "Country": "United States",
//       "Latitude": -79.95700073,
//       "Location": "Cheraw",
//       "Longitude": 34.71289825,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9808,
//       "Airport Code [ICAO]": "KCHN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Wauchula Municipal Airport",
//       "Altitude (Ft)": 106,
//       "Country": "United States",
//       "Latitude": -81.880500793457,
//       "Location": "Wuchula",
//       "Longitude": 27.51490020752,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9818,
//       "Airport Code [ICAO]": "YLIL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lilydale Airport",
//       "Altitude (Ft)": 76,
//       "Country": "Australia",
//       "Latitude": 145.36700439453125,
//       "Location": "Lilydale",
//       "Longitude": -37.69169998168945,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": "Australia/Hobart",
//       "Type": "airport"
//     },
//     {
//       "ID": 9819,
//       "Airport Code [ICAO]": "GMMD",
//       "Airport Code iATA,FAA": "BEM",
//       "Airport Name": "Beni Mellal Airport",
//       "Altitude (Ft)": 1694,
//       "Country": "Morocco",
//       "Latitude": -6.315905,
//       "Location": "Beni Mellal",
//       "Longitude": 32.401895,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": "Africa/Casablanca",
//       "Type": "airport"
//     },
//     {
//       "ID": 9820,
//       "Airport Code [ICAO]": "LTCV",
//       "Airport Code iATA,FAA": "NKT",
//       "Airport Name": "Şırnak Şerafettin Elçi Airport",
//       "Altitude (Ft)": 2038,
//       "Country": "Turkey",
//       "Latitude": 42.0582,
//       "Location": "Cizre",
//       "Longitude": 37.3647,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": "Europe/Istanbul",
//       "Type": "airport"
//     },
//     {
//       "ID": 9821,
//       "Airport Code [ICAO]": "UENS",
//       "Airport Code iATA,FAA": "SUY",
//       "Airport Name": "Suntar Airport",
//       "Altitude (Ft)": 452,
//       "Country": "Russia",
//       "Latitude": 117.63500213623,
//       "Location": "Suntar",
//       "Longitude": 62.185001373291,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Yakutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 9824,
//       "Airport Code [ICAO]": "GQPZ",
//       "Airport Code iATA,FAA": "OUZ",
//       "Airport Name": "Tazadit Airport",
//       "Altitude (Ft)": 1129,
//       "Country": "Mauritania",
//       "Latitude": -12.483599662780762,
//       "Location": "Zouerat",
//       "Longitude": 22.756399154663086,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Nouakchott",
//       "Type": "airport"
//     },
//     {
//       "ID": 9825,
//       "Airport Code [ICAO]": "DNAS",
//       "Airport Code iATA,FAA": "ABB",
//       "Airport Name": "Asaba International Airport",
//       "Altitude (Ft)": 305,
//       "Country": "Nigeria",
//       "Latitude": 6.665278,
//       "Location": "Asaba",
//       "Longitude": 6.204167,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 9826,
//       "Airport Code [ICAO]": "DNAI",
//       "Airport Code iATA,FAA": "QUO",
//       "Airport Name": "Akwa Ibom International Airport",
//       "Altitude (Ft)": 170,
//       "Country": "Nigeria",
//       "Latitude": 8.093,
//       "Location": "Uyo",
//       "Longitude": 4.8725,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lagos",
//       "Type": "airport"
//     },
//     {
//       "ID": 9828,
//       "Airport Code [ICAO]": "FLKS",
//       "Airport Code iATA,FAA": "KAA",
//       "Airport Name": "Kasama Airport",
//       "Altitude (Ft)": 4541,
//       "Country": "Zambia",
//       "Latitude": 31.13330078125,
//       "Location": "Kasama",
//       "Longitude": -10.216699600219727,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": "Africa/Lusaka",
//       "Type": "airport"
//     },
//     {
//       "ID": 9829,
//       "Airport Code [ICAO]": "HTMB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mbeya Airport",
//       "Altitude (Ft)": 5600,
//       "Country": "Tanzania",
//       "Latitude": 33.463889,
//       "Location": "Mbeya",
//       "Longitude": -8.919444,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Africa/Dar_es_Salaam",
//       "Type": "airport"
//     },
//     {
//       "ID": 9830,
//       "Airport Code [ICAO]": "HTMP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mpanda Airport",
//       "Altitude (Ft)": 3520,
//       "Country": "Tanzania",
//       "Latitude": 31.084116,
//       "Location": "Mpanda",
//       "Longitude": -6.355374,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Africa/Dar_es_Salaam",
//       "Type": "airport"
//     },
//     {
//       "ID": 9831,
//       "Airport Code [ICAO]": "HTSO",
//       "Airport Code iATA,FAA": "SGX",
//       "Airport Name": "Songea Airport",
//       "Altitude (Ft)": 3445,
//       "Country": "Tanzania",
//       "Latitude": 35.58300018310547,
//       "Location": "Songea",
//       "Longitude": -10.682999610900879,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Africa/Dar_es_Salaam",
//       "Type": "airport"
//     },
//     {
//       "ID": 9832,
//       "Airport Code [ICAO]": "HTMG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Morogoro Airport",
//       "Altitude (Ft)": 1676,
//       "Country": "Tanzania",
//       "Latitude": 37.653099060058594,
//       "Location": "Morogoro",
//       "Longitude": -6.797220230102539,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Africa/Dar_es_Salaam",
//       "Type": "airport"
//     },
//     {
//       "ID": 9843,
//       "Airport Code [ICAO]": "ZSJH",
//       "Airport Code iATA,FAA": "JUH",
//       "Airport Name": "Jiuhuashan Airport",
//       "Altitude (Ft)": 60,
//       "Country": "China",
//       "Latitude": 117.6856,
//       "Location": "Chizhou",
//       "Longitude": 30.7403,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 9844,
//       "Airport Code [ICAO]": "ZYAS",
//       "Airport Code iATA,FAA": "AOG",
//       "Airport Name": "Anshan Air Base",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 122.853996,
//       "Location": "Anshan",
//       "Longitude": 41.105301,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 9845,
//       "Airport Code [ICAO]": "ZYDQ",
//       "Airport Code iATA,FAA": "DQA",
//       "Airport Name": "Saertu Airport",
//       "Altitude (Ft)": 496,
//       "Country": "China",
//       "Latitude": 125.140555556,
//       "Location": "Daqing",
//       "Longitude": 46.7463888889,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 9846,
//       "Airport Code [ICAO]": "ZUZY",
//       "Airport Code iATA,FAA": "ZYI",
//       "Airport Name": "Zunyi Xinzhou Airport",
//       "Altitude (Ft)": 2920,
//       "Country": "China",
//       "Latitude": 107.0007,
//       "Location": "Zunyi",
//       "Longitude": 27.5895,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 9847,
//       "Airport Code [ICAO]": "KHYW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Conway Horry County Airport",
//       "Altitude (Ft)": 35,
//       "Country": "United States",
//       "Latitude": -79.12220001,
//       "Location": "Conway",
//       "Longitude": 33.82849884,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": "America/New_York",
//       "Type": "airport"
//     },
//     {
//       "ID": 9848,
//       "Airport Code [ICAO]": "ZYLD",
//       "Airport Code iATA,FAA": "LDS",
//       "Airport Name": "Lindu Airport",
//       "Altitude (Ft)": 791,
//       "Country": "China",
//       "Latitude": 129.019125,
//       "Location": "Yinchun",
//       "Longitude": 47.7520555556,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 9849,
//       "Airport Code [ICAO]": "ZUAS",
//       "Airport Code iATA,FAA": "AVA",
//       "Airport Name": "Anshun Huangguoshu Airport",
//       "Altitude (Ft)": 4812,
//       "Country": "China",
//       "Latitude": 105.873333333,
//       "Location": "Anshun",
//       "Longitude": 26.2605555556,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 9850,
//       "Airport Code [ICAO]": "GASK",
//       "Airport Code iATA,FAA": "KSS",
//       "Airport Name": "Sikasso Airport",
//       "Altitude (Ft)": 1378,
//       "Country": "Mali",
//       "Latitude": -5.699999809265137,
//       "Location": "Sikasso",
//       "Longitude": 11.333000183105469,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": "Africa/Bamako",
//       "Type": "airport"
//     },
//     {
//       "ID": 9853,
//       "Airport Code [ICAO]": "YBWW",
//       "Airport Code iATA,FAA": "WTB",
//       "Airport Name": "Toowoomba Wellcamp Airport",
//       "Altitude (Ft)": 1509,
//       "Country": "Australia",
//       "Latitude": 151.793335,
//       "Location": "Toowoomba",
//       "Longitude": -27.558332,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": "Australia/Brisbane",
//       "Type": "airport"
//     },
//     {
//       "ID": 9854,
//       "Airport Code [ICAO]": "ZYTN",
//       "Airport Code iATA,FAA": "TNH",
//       "Airport Name": "Tonghua Sanyuanpu Airport",
//       "Altitude (Ft)": 1200,
//       "Country": "China",
//       "Latitude": 125.703333333,
//       "Location": "Tonghua",
//       "Longitude": 42.2538888889,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 9857,
//       "Airport Code [ICAO]": "ZSSZ",
//       "Airport Code iATA,FAA": "SZV",
//       "Airport Name": "Suzhou Guangfu Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 120.401001,
//       "Location": "Suzhou",
//       "Longitude": 31.2631,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Shanghai",
//       "Type": "airport"
//     },
//     {
//       "ID": 9860,
//       "Airport Code [ICAO]": "EGCB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "City Airport Manchester",
//       "Altitude (Ft)": 73,
//       "Country": "United Kingdom",
//       "Latitude": -2.38971996307,
//       "Location": "Manchester",
//       "Longitude": 53.471698761,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 9861,
//       "Airport Code [ICAO]": "EGCV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sleap Airport",
//       "Altitude (Ft)": 275,
//       "Country": "United Kingdom",
//       "Latitude": -2.77167010307,
//       "Location": "Shrewsbury",
//       "Longitude": 52.833900451699996,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 9862,
//       "Airport Code [ICAO]": "EGBM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tatenhill Airfield",
//       "Altitude (Ft)": 439,
//       "Country": "United Kingdom",
//       "Latitude": -1.76110994816,
//       "Location": "Burton-on-Trent",
//       "Longitude": 52.814701080300004,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 9863,
//       "Airport Code [ICAO]": "EGNU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Full Sutton Airfield",
//       "Altitude (Ft)": 86,
//       "Country": "United Kingdom",
//       "Latitude": -0.864722,
//       "Location": "York",
//       "Longitude": 53.980598,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 9864,
//       "Airport Code [ICAO]": "EGCJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sherburn-In-Elmet Airfield",
//       "Altitude (Ft)": 26,
//       "Country": "United Kingdom",
//       "Latitude": -1.216877,
//       "Location": "Leeds",
//       "Longitude": 53.788458,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": "Europe/London",
//       "Type": "airport"
//     },
//     {
//       "ID": 9866,
//       "Airport Code [ICAO]": "WAOM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Beringin Airport",
//       "Altitude (Ft)": 126,
//       "Country": "Indonesia",
//       "Latitude": 114.893875122,
//       "Location": "Muara Teweh",
//       "Longitude": -0.940325021744,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 9868,
//       "Airport Code [ICAO]": "WAJM",
//       "Airport Code iATA,FAA": "LII",
//       "Airport Name": "Mulia Airport",
//       "Altitude (Ft)": 6527,
//       "Country": "Indonesia",
//       "Latitude": 137.957,
//       "Location": "Mulia",
//       "Longitude": -3.7018,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jayapura",
//       "Type": "airport"
//     },
//     {
//       "ID": 9872,
//       "Airport Code [ICAO]": "WASB",
//       "Airport Code iATA,FAA": "NTI",
//       "Airport Name": "Stenkol Airport",
//       "Altitude (Ft)": 57,
//       "Country": "Indonesia",
//       "Latitude": 133.5164,
//       "Location": "Bintuni",
//       "Longitude": -2.1033,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jayapura",
//       "Type": "airport"
//     },
//     {
//       "ID": 9873,
//       "Airport Code [ICAO]": "WASW",
//       "Airport Code iATA,FAA": "WSR",
//       "Airport Name": "Wasior Airport",
//       "Altitude (Ft)": 49,
//       "Country": "Indonesia",
//       "Latitude": 134.5061,
//       "Location": "Wasior",
//       "Longitude": -2.721,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jayapura",
//       "Type": "airport"
//     },
//     {
//       "ID": 9874,
//       "Airport Code [ICAO]": "WIMN",
//       "Airport Code iATA,FAA": "DTB",
//       "Airport Name": "Silangit Airport",
//       "Altitude (Ft)": 4700,
//       "Country": "Indonesia",
//       "Latitude": 98.991898,
//       "Location": "Siborong-Borong",
//       "Longitude": 2.25973,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 9875,
//       "Airport Code [ICAO]": "WITG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lasikin Airport",
//       "Altitude (Ft)": 19,
//       "Country": "Indonesia",
//       "Latitude": 96.32559967041016,
//       "Location": "Sinabang",
//       "Longitude": 2.4102799892425537,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 9886,
//       "Airport Code [ICAO]": "WITC",
//       "Airport Code iATA,FAA": "MEQ",
//       "Airport Name": "Seunagan Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Indonesia",
//       "Latitude": 96.21700286865234,
//       "Location": "Nagan Raya",
//       "Longitude": 4.25,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 9887,
//       "Airport Code [ICAO]": "WAWB",
//       "Airport Code iATA,FAA": "BUW",
//       "Airport Name": "Betoambari Airport",
//       "Altitude (Ft)": 164,
//       "Country": "Indonesia",
//       "Latitude": 122.56900024414062,
//       "Location": "Bau-Bau",
//       "Longitude": -5.486879825592041,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 9888,
//       "Airport Code [ICAO]": "WAMK",
//       "Airport Code iATA,FAA": "KAZ",
//       "Airport Name": "Kao Airport",
//       "Altitude (Ft)": 27,
//       "Country": "Indonesia",
//       "Latitude": 127.89600372314453,
//       "Location": "Kao",
//       "Longitude": 1.1852799654006958,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 9889,
//       "Airport Code [ICAO]": "WAMN",
//       "Airport Code iATA,FAA": "MNA",
//       "Airport Name": "Melangguane Airport",
//       "Altitude (Ft)": 3,
//       "Country": "Indonesia",
//       "Latitude": 126.672997,
//       "Location": "Melonguane",
//       "Longitude": 4.00694,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 9890,
//       "Airport Code [ICAO]": "WRLA",
//       "Airport Code iATA,FAA": "SGQ",
//       "Airport Name": "Sanggata/Sangkimah Airport",
//       "Altitude (Ft)": 60,
//       "Country": "Indonesia",
//       "Latitude": 117.543,
//       "Location": "Sanggata",
//       "Longitude": 0.3847,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 9891,
//       "Airport Code [ICAO]": "WIPI",
//       "Airport Code iATA,FAA": "BUU",
//       "Airport Name": "Muara Bungo Airport",
//       "Altitude (Ft)": 214,
//       "Country": "Indonesia",
//       "Latitude": 102.135,
//       "Location": "Muara Bungo",
//       "Longitude": -1.1278,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 9892,
//       "Airport Code [ICAO]": "WAWH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Selayar/Aroepala Airport",
//       "Altitude (Ft)": 30,
//       "Country": "Indonesia",
//       "Latitude": 120.4362,
//       "Location": "Selayar",
//       "Longitude": -6.1751,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 9897,
//       "Airport Code [ICAO]": "WABL",
//       "Airport Code iATA,FAA": "ILA",
//       "Airport Name": "Illaga Airport",
//       "Altitude (Ft)": 7989,
//       "Country": "Indonesia",
//       "Latitude": 137.6225,
//       "Location": "Illaga",
//       "Longitude": -3.97648,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 9898,
//       "Airport Code [ICAO]": "WAJO",
//       "Airport Code iATA,FAA": "OKL",
//       "Airport Name": "Oksibil Airport",
//       "Altitude (Ft)": 4315,
//       "Country": "Indonesia",
//       "Latitude": 140.6277,
//       "Location": "Oksibil",
//       "Longitude": -4.9071,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 9899,
//       "Airport Code [ICAO]": "WABN",
//       "Airport Code iATA,FAA": "KOX",
//       "Airport Name": "Kokonau Airport",
//       "Altitude (Ft)": 59,
//       "Country": "Indonesia",
//       "Latitude": 136.43515,
//       "Location": "Kokonau",
//       "Longitude": -4.71075,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 9901,
//       "Airport Code [ICAO]": "YCMT",
//       "Airport Code iATA,FAA": "CMQ",
//       "Airport Name": "Clermont Airport",
//       "Altitude (Ft)": 908,
//       "Country": "Australia",
//       "Latitude": 147.62100219726562,
//       "Location": "Clermont",
//       "Longitude": -22.773099899291992,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 9902,
//       "Airport Code [ICAO]": "YWBL",
//       "Airport Code iATA,FAA": "WMB",
//       "Airport Name": "Warrnambool Airport",
//       "Altitude (Ft)": 242,
//       "Country": "Australia",
//       "Latitude": 142.44700622558594,
//       "Location": "Warrnambool",
//       "Longitude": -38.2952995300293,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 9904,
//       "Airport Code [ICAO]": "YRMD",
//       "Airport Code iATA,FAA": "RCM",
//       "Airport Name": "Richmond Airport",
//       "Altitude (Ft)": 676,
//       "Country": "Australia",
//       "Latitude": 143.11500549316406,
//       "Location": "Richmond",
//       "Longitude": -20.701900482177734,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 9905,
//       "Airport Code [ICAO]": "YCIN",
//       "Airport Code iATA,FAA": "DCN",
//       "Airport Name": "RAAF Base Curtin",
//       "Altitude (Ft)": 300,
//       "Country": "Australia",
//       "Latitude": 123.82800293,
//       "Location": "Derby",
//       "Longitude": -17.5813999176,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 9935,
//       "Airport Code [ICAO]": "WIMM",
//       "Airport Code iATA,FAA": "KNO",
//       "Airport Name": "Kualanamu International Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Indonesia",
//       "Latitude": 98.885278,
//       "Location": "Medan",
//       "Longitude": 3.642222,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": "Asia/Jakarta",
//       "Type": "airport"
//     },
//     {
//       "ID": 9937,
//       "Airport Code [ICAO]": "KAMN",
//       "Airport Code iATA,FAA": "AMN",
//       "Airport Name": "Gratiot Community Airport",
//       "Altitude (Ft)": 754,
//       "Country": "Canada",
//       "Latitude": -84.68800354,
//       "Location": "Kamloops",
//       "Longitude": 43.322101593,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 9938,
//       "Airport Code [ICAO]": "KHBI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Asheboro Regional Airport",
//       "Altitude (Ft)": 671,
//       "Country": "United States",
//       "Latitude": -79.8946991,
//       "Location": "Asheboro",
//       "Longitude": 35.65449905,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 9939,
//       "Airport Code [ICAO]": "RKTP",
//       "Airport Code iATA,FAA": "HMY",
//       "Airport Name": "Seosan Air Base",
//       "Altitude (Ft)": 39,
//       "Country": "South Korea",
//       "Latitude": 126.486,
//       "Location": "Seosan",
//       "Longitude": 36.703999,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 9942,
//       "Airport Code [ICAO]": "KACZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Henderson Field",
//       "Altitude (Ft)": 39,
//       "Country": "United States",
//       "Latitude": -78.00360107421875,
//       "Location": "Wallace",
//       "Longitude": 34.717899322509766,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 9943,
//       "Airport Code [ICAO]": "KEMV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Emporia Greensville Regional Airport",
//       "Altitude (Ft)": 127,
//       "Country": "United States",
//       "Latitude": -77.48280334472656,
//       "Location": "Emporia",
//       "Longitude": 36.6869010925293,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10017,
//       "Airport Code [ICAO]": "KEMT",
//       "Airport Code iATA,FAA": "EMT",
//       "Airport Name": "San Gabriel Valley Airport",
//       "Altitude (Ft)": 296,
//       "Country": "United States",
//       "Latitude": -118.035004,
//       "Location": "El Monte",
//       "Longitude": 34.086102,
//       "GMT Offset": "-8",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10057,
//       "Airport Code [ICAO]": "OAFR",
//       "Airport Code iATA,FAA": "FAH",
//       "Airport Name": "Farah Airport",
//       "Altitude (Ft)": 3083,
//       "Country": "Afghanistan",
//       "Latitude": 62.18299865722656,
//       "Location": "Farah",
//       "Longitude": 32.367000579833984,
//       "GMT Offset": "4.3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10061,
//       "Airport Code [ICAO]": "VEPG",
//       "Airport Code iATA,FAA": "IXT",
//       "Airport Name": "Pasighat Airport",
//       "Altitude (Ft)": 477,
//       "Country": "India",
//       "Latitude": 95.33560180664062,
//       "Location": "Pasighat",
//       "Longitude": 28.066099166870117,
//       "GMT Offset": "5.3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10062,
//       "Airport Code [ICAO]": "KI16",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kee Field",
//       "Altitude (Ft)": 1783,
//       "Country": "United States",
//       "Latitude": -81.5593032836914,
//       "Location": "Pineville",
//       "Longitude": 37.600399017333984,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10069,
//       "Airport Code [ICAO]": "UKCK",
//       "Airport Code iATA,FAA": "KRQ",
//       "Airport Name": "Kramatorsk Airport",
//       "Altitude (Ft)": 646,
//       "Country": "Ukraine",
//       "Latitude": 37.62889862060547,
//       "Location": "Kramatorsk",
//       "Longitude": 48.70560073852539,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10080,
//       "Airport Code [ICAO]": "ENKA",
//       "Airport Code iATA,FAA": "QKX",
//       "Airport Name": "Kautokeino Air Base",
//       "Altitude (Ft)": 1165,
//       "Country": "Norway",
//       "Latitude": 23.034000396728516,
//       "Location": "Kautokeino",
//       "Longitude": 69.04029846191406,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10089,
//       "Airport Code [ICAO]": "LFQO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lille/Marcq-en-Baroeul Airport",
//       "Altitude (Ft)": 69,
//       "Country": "France",
//       "Latitude": 3.0755600929260254,
//       "Location": "Marcq En Baroeul",
//       "Longitude": 50.687198638916016,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10096,
//       "Airport Code [ICAO]": "GUKR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kawass Airport",
//       "Altitude (Ft)": 36,
//       "Country": "Guinea",
//       "Latitude": -14.533599853516,
//       "Location": "Kamsar",
//       "Longitude": 10.651000022888,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10102,
//       "Airport Code [ICAO]": "KSSF",
//       "Airport Code iATA,FAA": "SSF",
//       "Airport Name": "Stinson Municipal Airport",
//       "Altitude (Ft)": 577,
//       "Country": "United States",
//       "Latitude": -98.471099853516,
//       "Location": "Stinson",
//       "Longitude": 29.336999893188,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10103,
//       "Airport Code [ICAO]": "KJSV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sallisaw Municipal Airport",
//       "Altitude (Ft)": 527,
//       "Country": "United States",
//       "Latitude": -94.80280304,
//       "Location": "Sallisaw",
//       "Longitude": 35.4382019,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10104,
//       "Airport Code [ICAO]": "KJAS",
//       "Airport Code iATA,FAA": "JAS",
//       "Airport Name": "Jasper County Airport-Bell Field",
//       "Altitude (Ft)": 213,
//       "Country": "United States",
//       "Latitude": -94.03489685,
//       "Location": "Jasper",
//       "Longitude": 30.88570023,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10105,
//       "Airport Code [ICAO]": "K87K",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "El Dorado Springs Memorial Airport",
//       "Altitude (Ft)": 931,
//       "Country": "United States",
//       "Latitude": -93.99909973144531,
//       "Location": "El dorado springs",
//       "Longitude": 37.8567008972168,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10106,
//       "Airport Code [ICAO]": "KMRF",
//       "Airport Code iATA,FAA": "MRF",
//       "Airport Name": "Marfa Municipal Airport",
//       "Altitude (Ft)": 4849,
//       "Country": "United States",
//       "Latitude": -104.017997,
//       "Location": "Marfa",
//       "Longitude": 30.371099,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10107,
//       "Airport Code [ICAO]": "KE38",
//       "Airport Code iATA,FAA": "ALE",
//       "Airport Name": "Alpine Casparis Municipal Airport",
//       "Altitude (Ft)": 4515,
//       "Country": "United States",
//       "Latitude": -103.683998108,
//       "Location": "Alpine",
//       "Longitude": 30.384199142499998,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10110,
//       "Airport Code [ICAO]": "GGBU",
//       "Airport Code iATA,FAA": "BQE",
//       "Airport Name": "Bubaque Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Guinea-Bissau",
//       "Latitude": -15.838079452514648,
//       "Location": "Bubaque",
//       "Longitude": 11.297355651855469,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10114,
//       "Airport Code [ICAO]": "MMCT",
//       "Airport Code iATA,FAA": "CZA",
//       "Airport Name": "Chichen Itza International Airport",
//       "Altitude (Ft)": 102,
//       "Country": "Mexico",
//       "Latitude": -88.4461975098,
//       "Location": "Chichen Itza",
//       "Longitude": 20.6413002014,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 10116,
//       "Airport Code [ICAO]": "YBUN",
//       "Airport Code iATA,FAA": "BUY",
//       "Airport Name": "Bunbury Airport",
//       "Altitude (Ft)": 53,
//       "Country": "Australia",
//       "Latitude": 115.677001953125,
//       "Location": "Bunbury",
//       "Longitude": -33.378299713134766,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10117,
//       "Airport Code [ICAO]": "KCCB",
//       "Airport Code iATA,FAA": "CCB",
//       "Airport Name": "Cable Airport",
//       "Altitude (Ft)": 1444,
//       "Country": "United States",
//       "Latitude": -117.68800354,
//       "Location": "Upland",
//       "Longitude": 34.1115989685,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10121,
//       "Airport Code [ICAO]": "KIOB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mount Sterling Montgomery County Airport",
//       "Altitude (Ft)": 1019,
//       "Country": "United States",
//       "Latitude": -83.979599,
//       "Location": "Mount Sterling",
//       "Longitude": 38.05810165,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10122,
//       "Airport Code [ICAO]": "KEKM",
//       "Airport Code iATA,FAA": "EKI",
//       "Airport Name": "Elkhart Municipal Airport",
//       "Altitude (Ft)": 778,
//       "Country": "United States",
//       "Latitude": -86.00319671630001,
//       "Location": "Elkhart",
//       "Longitude": 41.7193984985,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10123,
//       "Airport Code [ICAO]": "KC03",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nappanee Municipal Airport",
//       "Altitude (Ft)": 860,
//       "Country": "United States",
//       "Latitude": -85.93479919433594,
//       "Location": "Nappanee ",
//       "Longitude": 41.44620132446289,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10128,
//       "Airport Code [ICAO]": "KCUB",
//       "Airport Code iATA,FAA": "CUB",
//       "Airport Name": "Jim Hamilton L.B. Owens Airport",
//       "Altitude (Ft)": 193,
//       "Country": "United States",
//       "Latitude": -80.9952011108,
//       "Location": "Columbia",
//       "Longitude": 33.970500946,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10129,
//       "Airport Code [ICAO]": "KGMJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Grove Municipal Airport",
//       "Altitude (Ft)": 831,
//       "Country": "United States",
//       "Latitude": -94.73860168,
//       "Location": "Grove",
//       "Longitude": 36.60680008,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10130,
//       "Airport Code [ICAO]": "KMPR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mc Pherson Airport",
//       "Altitude (Ft)": 1498,
//       "Country": "United States",
//       "Latitude": -97.69129944,
//       "Location": "Mc Pherson",
//       "Longitude": 38.35240173,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10131,
//       "Airport Code [ICAO]": "KGYH",
//       "Airport Code iATA,FAA": "GDC",
//       "Airport Name": "Donaldson Field Airport",
//       "Altitude (Ft)": 955,
//       "Country": "United States",
//       "Latitude": -82.376404,
//       "Location": "Greenville",
//       "Longitude": 34.758301,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10132,
//       "Airport Code [ICAO]": "KPXE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Perry Houston County Airport",
//       "Altitude (Ft)": 418,
//       "Country": "United States",
//       "Latitude": -83.76730346679688,
//       "Location": "Perry",
//       "Longitude": 32.51060104370117,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10133,
//       "Airport Code [ICAO]": "KHVS",
//       "Airport Code iATA,FAA": "HVS",
//       "Airport Name": "Hartsville Regional Airport",
//       "Altitude (Ft)": 364,
//       "Country": "United States",
//       "Latitude": -80.11920166019999,
//       "Location": "Hartsville",
//       "Longitude": 34.4030990601,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10134,
//       "Airport Code [ICAO]": "KIGX",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Horace Williams Airport",
//       "Altitude (Ft)": 512,
//       "Country": "United States",
//       "Latitude": -79.065902,
//       "Location": "Chapel Hill",
//       "Longitude": 35.935001,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10135,
//       "Airport Code [ICAO]": "MMSC",
//       "Airport Code iATA,FAA": "SZT",
//       "Airport Name": "San Cristobal de las Casas Airport",
//       "Altitude (Ft)": 7707,
//       "Country": "Mexico",
//       "Latitude": -92.530097961426,
//       "Location": "San Cristobal de las Casas",
//       "Longitude": 16.690299987793,
//       "GMT Offset": "-6",
//       "Daylight Savings": "N",
//       "Region": "America/Mexico_City",
//       "Type": "airport"
//     },
//     {
//       "ID": 10137,
//       "Airport Code [ICAO]": "CDU9",
//       "Airport Code iATA,FAA": "DU9",
//       "Airport Name": "Dunnville Airport",
//       "Altitude (Ft)": 600,
//       "Country": "Canada",
//       "Latitude": -79.5958023071,
//       "Location": "Dunnville",
//       "Longitude": 42.872200012200004,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10138,
//       "Airport Code [ICAO]": "KSUT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Brunswick County Airport",
//       "Altitude (Ft)": 24,
//       "Country": "United States",
//       "Latitude": -78.07499695,
//       "Location": "Oak Island",
//       "Longitude": 33.9292984,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10139,
//       "Airport Code [ICAO]": "KFCI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Chesterfield County Airport",
//       "Altitude (Ft)": 236,
//       "Country": "United States",
//       "Latitude": -77.52500153,
//       "Location": "Richmond",
//       "Longitude": 37.40650177,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10140,
//       "Airport Code [ICAO]": "YATN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Atherton Airport",
//       "Altitude (Ft)": 2450,
//       "Country": "Australia",
//       "Latitude": 145.51499939,
//       "Location": "Atherton",
//       "Longitude": -17.2616996765,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10141,
//       "Airport Code [ICAO]": "UIUB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bagdarin Airport",
//       "Altitude (Ft)": 3084,
//       "Country": "Russia",
//       "Latitude": 113.47899627685547,
//       "Location": "Bagdarin",
//       "Longitude": 54.36920166015625,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": "Asia/Irkutsk",
//       "Type": "airport"
//     },
//     {
//       "ID": 10142,
//       "Airport Code [ICAO]": "MPRH",
//       "Airport Code iATA,FAA": "RIH",
//       "Airport Name": "Scarlett Martinez International Airport",
//       "Altitude (Ft)": 105,
//       "Country": "Panama",
//       "Latitude": -80.127899169922,
//       "Location": "Rio Hato",
//       "Longitude": 8.375880241394,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10144,
//       "Airport Code [ICAO]": "KHNZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Henderson Oxford Airport",
//       "Altitude (Ft)": 526,
//       "Country": "United States",
//       "Latitude": -78.52919769,
//       "Location": "Oxford",
//       "Longitude": 36.36159897,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10145,
//       "Airport Code [ICAO]": "KLEE",
//       "Airport Code iATA,FAA": "LEE",
//       "Airport Name": "Leesburg International Airport",
//       "Altitude (Ft)": 76,
//       "Country": "United States",
//       "Latitude": -81.80870056,
//       "Location": "Leesburg",
//       "Longitude": 28.82309914,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10148,
//       "Airport Code [ICAO]": "UUBL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Semyazino Airport",
//       "Altitude (Ft)": 554,
//       "Country": "Russia",
//       "Latitude": 40.314998626708984,
//       "Location": "Vladimir",
//       "Longitude": 56.12670135498047,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 10149,
//       "Airport Code [ICAO]": "FATW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Witberg Tswalu Airport",
//       "Altitude (Ft)": 3921,
//       "Country": "South Africa",
//       "Latitude": 22.48189926147461,
//       "Location": "Dedeben",
//       "Longitude": -27.204999923706055,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10151,
//       "Airport Code [ICAO]": "FVMN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mana Pools Airport",
//       "Altitude (Ft)": 1300,
//       "Country": "Zimbabwe",
//       "Latitude": 29.385617,
//       "Location": "Mana Pools",
//       "Longitude": -15.768735,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10154,
//       "Airport Code [ICAO]": "SNZA",
//       "Airport Code iATA,FAA": "PPY",
//       "Airport Name": "Pouso Alegre Airport",
//       "Altitude (Ft)": 2904,
//       "Country": "Brazil",
//       "Latitude": -45.91910171508789,
//       "Location": "Pouso Alegre",
//       "Longitude": -22.289199829101562,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10155,
//       "Airport Code [ICAO]": "SNDV",
//       "Airport Code iATA,FAA": "DIQ",
//       "Airport Name": "Brigadeiro Cabral Airport",
//       "Altitude (Ft)": 2608,
//       "Country": "Brazil",
//       "Latitude": -44.870899200439,
//       "Location": "Divinopolis",
//       "Longitude": -20.180700302124,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10156,
//       "Airport Code [ICAO]": "URKE",
//       "Airport Code iATA,FAA": "EIK",
//       "Airport Name": "Yeysk Airport",
//       "Altitude (Ft)": 60,
//       "Country": "Russia",
//       "Latitude": 38.21,
//       "Location": "Eysk",
//       "Longitude": 46.68,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 10157,
//       "Airport Code [ICAO]": "UKDB",
//       "Airport Code iATA,FAA": "ERD",
//       "Airport Name": "Berdyansk Airport",
//       "Altitude (Ft)": 171,
//       "Country": "Ukraine",
//       "Latitude": 36.75809860229492,
//       "Location": "Berdyansk",
//       "Longitude": 46.814998626708984,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10158,
//       "Airport Code [ICAO]": "WARB",
//       "Airport Code iATA,FAA": "BWX",
//       "Airport Name": "Blimbingsari Airport",
//       "Altitude (Ft)": 112,
//       "Country": "Indonesia",
//       "Latitude": 114.3401,
//       "Location": "Banyuwangi",
//       "Longitude": -8.31015,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10160,
//       "Airport Code [ICAO]": "ZBER",
//       "Airport Code iATA,FAA": "ERL",
//       "Airport Name": "Erenhot Saiwusu International Airport",
//       "Altitude (Ft)": 3301,
//       "Country": "China",
//       "Latitude": 112.096667,
//       "Location": "Erenhot",
//       "Longitude": 43.4225,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10161,
//       "Airport Code [ICAO]": "EGBS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Shobdon Aerodrome",
//       "Altitude (Ft)": 318,
//       "Country": "United Kingdom",
//       "Latitude": -2.8811099529299997,
//       "Location": "Shobdon",
//       "Longitude": 52.2416992188,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10162,
//       "Airport Code [ICAO]": "KCNO",
//       "Airport Code iATA,FAA": "CNO",
//       "Airport Name": "Chino Airport",
//       "Altitude (Ft)": 650,
//       "Country": "United States",
//       "Latitude": -117.637001,
//       "Location": "Chino",
//       "Longitude": 33.97470093,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10163,
//       "Airport Code [ICAO]": "RJDK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kamigoto Airport",
//       "Altitude (Ft)": 263,
//       "Country": "Japan",
//       "Latitude": 129.192001343,
//       "Location": "Shin-kamigoto",
//       "Longitude": 33.0130996704,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10164,
//       "Airport Code [ICAO]": "RJDO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ojika Airport",
//       "Altitude (Ft)": 30,
//       "Country": "Japan",
//       "Latitude": 129.089996338,
//       "Location": "Odika",
//       "Longitude": 33.1907997131,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10165,
//       "Airport Code [ICAO]": "RJTF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Chofu Airport",
//       "Altitude (Ft)": 141,
//       "Country": "Japan",
//       "Latitude": 139.5279998779297,
//       "Location": "Tokyo",
//       "Longitude": 35.67169952392578,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10166,
//       "Airport Code [ICAO]": "RORH",
//       "Airport Code iATA,FAA": "HTR",
//       "Airport Name": "Hateruma Airport",
//       "Altitude (Ft)": 43,
//       "Country": "Japan",
//       "Latitude": 123.805999756,
//       "Location": "Taketomi",
//       "Longitude": 24.0589008331,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10169,
//       "Airport Code [ICAO]": "KUYF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Madison County Airport",
//       "Altitude (Ft)": 1082,
//       "Country": "United States",
//       "Latitude": -83.46199799,
//       "Location": "London",
//       "Longitude": 39.93270111,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10171,
//       "Airport Code [ICAO]": "MUBR",
//       "Airport Code iATA,FAA": "BWW",
//       "Airport Name": "Las Brujas Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Cuba",
//       "Latitude": -79.1472015381,
//       "Location": "Cayo Santa Maria",
//       "Longitude": 22.621299743699996,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10173,
//       "Airport Code [ICAO]": "CDG2",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Digby (General Hospital) Heliport",
//       "Altitude (Ft)": 105,
//       "Country": "France",
//       "Latitude": -65.76190185546875,
//       "Location": "Tremblay-en-France",
//       "Longitude": 44.6161003112793,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10178,
//       "Airport Code [ICAO]": "LSZW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Thun Airport",
//       "Altitude (Ft)": 1837,
//       "Country": "Switzerland",
//       "Latitude": 7.60056018829,
//       "Location": "Thun",
//       "Longitude": 46.756401062,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10184,
//       "Airport Code [ICAO]": "UHML",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lavrentiya Airport",
//       "Altitude (Ft)": 30,
//       "Country": "Russia",
//       "Latitude": -170.99667358398438,
//       "Location": "Lavrentiya",
//       "Longitude": 65.58000183105469,
//       "GMT Offset": "12",
//       "Daylight Savings": "N",
//       "Region": "Asia/Anadyr",
//       "Type": "airport"
//     },
//     {
//       "ID": 10185,
//       "Airport Code [ICAO]": "KPRB",
//       "Airport Code iATA,FAA": "PRB",
//       "Airport Name": "Paso Robles Municipal Airport",
//       "Altitude (Ft)": 840,
//       "Country": "United States",
//       "Latitude": -120.6269989,
//       "Location": "Paso Robles",
//       "Longitude": 35.67290115,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10186,
//       "Airport Code [ICAO]": "RKDU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "N 104 Helipad",
//       "Altitude (Ft)": 476,
//       "Country": "South Korea",
//       "Latitude": 130.895555556,
//       "Location": "Ulleung",
//       "Longitude": 37.479166666699996,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10209,
//       "Airport Code [ICAO]": "ZBAD",
//       "Airport Code iATA,FAA": "PKX",
//       "Airport Name": "Beijing Daxing International Airport",
//       "Altitude (Ft)": 98,
//       "Country": "China",
//       "Latitude": 116.41092,
//       "Location": "Beijing",
//       "Longitude": 39.509945,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10295,
//       "Airport Code [ICAO]": "EFKY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kymi Airport",
//       "Altitude (Ft)": 223,
//       "Country": "Finland",
//       "Latitude": 26.896099090576172,
//       "Location": "Kotka",
//       "Longitude": 60.5713996887207,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10360,
//       "Airport Code [ICAO]": "KHAF",
//       "Airport Code iATA,FAA": "HAF",
//       "Airport Name": "Half Moon Bay Airport",
//       "Altitude (Ft)": 66,
//       "Country": "United States",
//       "Latitude": -122.500999451,
//       "Location": "Half Moon Bay",
//       "Longitude": 37.513401031499995,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10363,
//       "Airport Code [ICAO]": "ZGHC",
//       "Airport Code iATA,FAA": "HCJ",
//       "Airport Name": "Hechi Jinchengjiang Airport",
//       "Altitude (Ft)": 2221,
//       "Country": "China",
//       "Latitude": 107.6997,
//       "Location": "Hechi",
//       "Longitude": 24.805,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10370,
//       "Airport Code [ICAO]": "YKDI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kadina Airport",
//       "Altitude (Ft)": 42,
//       "Country": "Australia",
//       "Latitude": 137.66000366210938,
//       "Location": "Kadina",
//       "Longitude": -33.97669982910156,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10371,
//       "Airport Code [ICAO]": "KWJF",
//       "Airport Code iATA,FAA": "WJF",
//       "Airport Name": "General WM J Fox Airfield",
//       "Altitude (Ft)": 2351,
//       "Country": "United States",
//       "Latitude": -118.2190018,
//       "Location": "Lancaster",
//       "Longitude": 34.74110031,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10378,
//       "Airport Code [ICAO]": "YCWA",
//       "Airport Code iATA,FAA": "CJF",
//       "Airport Name": "Coondewanna Airport",
//       "Altitude (Ft)": 2300,
//       "Country": "Australia",
//       "Latitude": 118.81300354004,
//       "Location": "Coondewanna",
//       "Longitude": -22.96669960022,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10544,
//       "Airport Code [ICAO]": "SNGA",
//       "Airport Code iATA,FAA": "GUZ",
//       "Airport Name": "Guarapari Airport",
//       "Altitude (Ft)": 28,
//       "Country": "Brazil",
//       "Latitude": -40.491901397700005,
//       "Location": "Guarapari",
//       "Longitude": -20.646499633800005,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10545,
//       "Airport Code [ICAO]": "SDUB",
//       "Airport Code iATA,FAA": "UBT",
//       "Airport Name": "Ubatuba Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Brazil",
//       "Latitude": -45.075599670410156,
//       "Location": "Ubatuba",
//       "Longitude": -23.441099166870117,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10611,
//       "Airport Code [ICAO]": "ORTL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ali Air Base",
//       "Altitude (Ft)": 20,
//       "Country": "Iraq",
//       "Latitude": 46.0900993347168,
//       "Location": "Nasiriyah",
//       "Longitude": 30.935800552368164,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10617,
//       "Airport Code [ICAO]": "LPOT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ota Air Base",
//       "Altitude (Ft)": 140,
//       "Country": "Portugal",
//       "Latitude": -8.96278,
//       "Location": "Ota",
//       "Longitude": 39.087502,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10618,
//       "Airport Code [ICAO]": "YBRL",
//       "Airport Code iATA,FAA": "BOX",
//       "Airport Name": "Borroloola Airport",
//       "Altitude (Ft)": 55,
//       "Country": "Australia",
//       "Latitude": 136.302001953125,
//       "Location": "Borroloola",
//       "Longitude": -16.075300216674805,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10723,
//       "Airport Code [ICAO]": "EDNF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Elsenthal Grafe Airport",
//       "Altitude (Ft)": 1417,
//       "Country": "Germany",
//       "Latitude": 13.3675,
//       "Location": "Elsenthal",
//       "Longitude": 48.822498,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10744,
//       "Airport Code [ICAO]": "EGAD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Newtownards Airport",
//       "Altitude (Ft)": 9,
//       "Country": "United Kingdom",
//       "Latitude": -5.69193983078,
//       "Location": "Newtownards",
//       "Longitude": 54.5811004639,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10745,
//       "Airport Code [ICAO]": "EGKH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lashenden (Headcorn) Airfield",
//       "Altitude (Ft)": 72,
//       "Country": "United Kingdom",
//       "Latitude": 0.641667,
//       "Location": "Headcorn",
//       "Longitude": 51.156898,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10746,
//       "Airport Code [ICAO]": "EGLG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Panshanger Aerodrome",
//       "Altitude (Ft)": 249,
//       "Country": "United Kingdom",
//       "Latitude": -0.158056005836,
//       "Location": "Panshanger",
//       "Longitude": 51.80250167849999,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10747,
//       "Airport Code [ICAO]": "EGMT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Thurrock Airfield",
//       "Altitude (Ft)": 20,
//       "Country": "United Kingdom",
//       "Latitude": 0.367634,
//       "Location": "Thurrock",
//       "Longitude": 51.537505,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10748,
//       "Airport Code [ICAO]": "EGSG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Stapleford Aerodrome",
//       "Altitude (Ft)": 183,
//       "Country": "United Kingdom",
//       "Latitude": 0.155833005905,
//       "Location": "Stapleford",
//       "Longitude": 51.652500152600005,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10750,
//       "Airport Code [ICAO]": "VOBG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "HAL Airport",
//       "Altitude (Ft)": 2912,
//       "Country": "India",
//       "Latitude": 77.6681976318,
//       "Location": "Bangalore",
//       "Longitude": 12.9499998093,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10751,
//       "Airport Code [ICAO]": "EGHR",
//       "Airport Code iATA,FAA": "QUG",
//       "Airport Name": "Chichester/Goodwood Airport",
//       "Altitude (Ft)": 98,
//       "Country": "United Kingdom",
//       "Latitude": -0.7591670155525208,
//       "Location": "Goodwood",
//       "Longitude": 50.85940170288086,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10752,
//       "Airport Code [ICAO]": "NZOA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Omarama Glider Airport",
//       "Altitude (Ft)": 1381,
//       "Country": "New Zealand",
//       "Latitude": 169.98599243164062,
//       "Location": "Omarama",
//       "Longitude": -44.486698150634766,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10753,
//       "Airport Code [ICAO]": "NZHT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Haast Aerodrome",
//       "Altitude (Ft)": 13,
//       "Country": "New Zealand",
//       "Latitude": 169.04100036621094,
//       "Location": "Haast",
//       "Longitude": -43.865299224853516,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10776,
//       "Airport Code [ICAO]": "EDKM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Meschede-Schüren Airport",
//       "Altitude (Ft)": 1434,
//       "Country": "Germany",
//       "Latitude": 8.239167213439941,
//       "Location": "Meschede",
//       "Longitude": 51.30277633666992,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10792,
//       "Airport Code [ICAO]": "SEJD",
//       "Airport Code iATA,FAA": "TNW",
//       "Airport Name": "Jumandy Airport",
//       "Altitude (Ft)": 1234,
//       "Country": "Ecuador",
//       "Latitude": -77.583333,
//       "Location": "Tena",
//       "Longitude": -1.059722,
//       "GMT Offset": "-5",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10794,
//       "Airport Code [ICAO]": "SDHU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Morro da Urca Heliport",
//       "Altitude (Ft)": 692,
//       "Country": "Brazil",
//       "Latitude": -43.16583251953125,
//       "Location": "Rio de Janeiro",
//       "Longitude": -22.95166778564453,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10800,
//       "Airport Code [ICAO]": "ZYFY",
//       "Airport Code iATA,FAA": "FYJ",
//       "Airport Name": "Dongji Aiport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 134.366447,
//       "Location": "Fuyuan",
//       "Longitude": 48.199494,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10802,
//       "Airport Code [ICAO]": "EKSY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Skúvoy Heliport",
//       "Altitude (Ft)": 70,
//       "Country": "Faroe Islands",
//       "Latitude": -6.803410053253174,
//       "Location": "Skuvoy",
//       "Longitude": 61.76959991455078,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10803,
//       "Airport Code [ICAO]": "EKSR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Stóra Dímun Heliport",
//       "Altitude (Ft)": 305,
//       "Country": "Faroe Islands",
//       "Latitude": -6.758500099182129,
//       "Location": "Stora Dimun",
//       "Longitude": 61.685298919677734,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10804,
//       "Airport Code [ICAO]": "EKFA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Frooba Heliport",
//       "Altitude (Ft)": 77,
//       "Country": "Faroe Islands",
//       "Latitude": -6.774419784545898,
//       "Location": "Frooba",
//       "Longitude": 61.543701171875,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10831,
//       "Airport Code [ICAO]": "KTDF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Person County Airport",
//       "Altitude (Ft)": 609,
//       "Country": "United States",
//       "Latitude": -78.98419952,
//       "Location": "Roxboro",
//       "Longitude": 36.28490067,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10934,
//       "Airport Code [ICAO]": "FADQ",
//       "Airport Code iATA,FAA": "PZL",
//       "Airport Name": "Zulu Inyala Airport",
//       "Altitude (Ft)": 160,
//       "Country": "South Africa",
//       "Latitude": 32.30970001220703,
//       "Location": "Phinda",
//       "Longitude": -27.84939956665039,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10937,
//       "Airport Code [ICAO]": "ZUPS",
//       "Airport Code iATA,FAA": "LPF",
//       "Airport Name": "Liupanshui Yuezhao Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 104.979,
//       "Location": "Liupanshui",
//       "Longitude": 26.609417,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10938,
//       "Airport Code [ICAO]": "ZUKJ",
//       "Airport Code iATA,FAA": "KJH",
//       "Airport Name": "Kaili Airport",
//       "Altitude (Ft)": 3115,
//       "Country": "China",
//       "Latitude": 107.988,
//       "Location": "Kaili",
//       "Longitude": 26.972,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10939,
//       "Airport Code [ICAO]": "ZHSN",
//       "Airport Code iATA,FAA": "HPG",
//       "Airport Name": "Shennongjia Hongping Airport",
//       "Altitude (Ft)": 8365,
//       "Country": "China",
//       "Latitude": 110.34,
//       "Location": "Shennongjia",
//       "Longitude": 31.626,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10940,
//       "Airport Code [ICAO]": "ZBZJ",
//       "Airport Code iATA,FAA": "ZQZ",
//       "Airport Name": "Zhangjiakou Ningyuan Airport",
//       "Altitude (Ft)": 2347,
//       "Country": "China",
//       "Latitude": 114.930000305,
//       "Location": "Zhangjiakou",
//       "Longitude": 40.7386016846,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10941,
//       "Airport Code [ICAO]": "ZBES",
//       "Airport Code iATA,FAA": "YIE",
//       "Airport Name": "Arxan Yi'ershi Airport",
//       "Altitude (Ft)": 2925,
//       "Country": "China",
//       "Latitude": 119.9117,
//       "Location": "Arxan",
//       "Longitude": 47.3106,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10942,
//       "Airport Code [ICAO]": "ZGHY",
//       "Airport Code iATA,FAA": "HNY",
//       "Airport Name": "Hengyang Nanyue Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 112.627998,
//       "Location": "Hengyang",
//       "Longitude": 26.9053,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10943,
//       "Airport Code [ICAO]": "ZUHY",
//       "Airport Code iATA,FAA": "AHJ",
//       "Airport Name": "Hongyuan Airport",
//       "Altitude (Ft)": 11600,
//       "Country": "China",
//       "Latitude": 102.35224,
//       "Location": "Ngawa",
//       "Longitude": 32.53154,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10949,
//       "Airport Code [ICAO]": "ZKWS",
//       "Airport Code iATA,FAA": "WOS",
//       "Airport Name": "Wonsan Kalma International Airport",
//       "Altitude (Ft)": 7,
//       "Country": "North Korea",
//       "Latitude": 127.486,
//       "Location": "Wonsan",
//       "Longitude": 39.166801,
//       "GMT Offset": "8.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10951,
//       "Airport Code [ICAO]": "UGMS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mestia Queen Tamar Airport",
//       "Altitude (Ft)": 4778,
//       "Country": "Georgia",
//       "Latitude": 42.749012,
//       "Location": "Mestia",
//       "Longitude": 43.053597,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 10952,
//       "Airport Code [ICAO]": "URMS",
//       "Airport Code iATA,FAA": "IGT",
//       "Airport Name": "Magas Airport",
//       "Altitude (Ft)": 1165,
//       "Country": "Russia",
//       "Latitude": 45.0125999451,
//       "Location": "Magas",
//       "Longitude": 43.322299957300004,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": "Europe/Moscow",
//       "Type": "airport"
//     },
//     {
//       "ID": 11004,
//       "Airport Code [ICAO]": "KASN",
//       "Airport Code iATA,FAA": "ASN",
//       "Airport Name": "Talladega Municipal Airport",
//       "Altitude (Ft)": 529,
//       "Country": "United States",
//       "Latitude": -86.05090332030001,
//       "Location": "Talladega",
//       "Longitude": 33.569900512699995,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11005,
//       "Airport Code [ICAO]": "KGMU",
//       "Airport Code iATA,FAA": "GMU",
//       "Airport Name": "Greenville Downtown Airport",
//       "Altitude (Ft)": 1048,
//       "Country": "United States",
//       "Latitude": -82.34999847410002,
//       "Location": "Greenville",
//       "Longitude": 34.847900390599996,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11008,
//       "Airport Code [ICAO]": "KMKT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mankato Regional Airport",
//       "Altitude (Ft)": 1021,
//       "Country": "United States",
//       "Latitude": -93.91870117,
//       "Location": "Mankato",
//       "Longitude": 44.22159958,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11009,
//       "Airport Code [ICAO]": "TUPA",
//       "Airport Code iATA,FAA": "NGD",
//       "Airport Name": "Captain Auguste George Airport",
//       "Altitude (Ft)": 9,
//       "Country": "British Virgin Islands",
//       "Latitude": -64.32969665527344,
//       "Location": "Anegada",
//       "Longitude": 18.72719955444336,
//       "GMT Offset": "-4",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11010,
//       "Airport Code [ICAO]": "KTOI",
//       "Airport Code iATA,FAA": "TOI",
//       "Airport Name": "Troy Municipal Airport at N Kenneth Campbell Field",
//       "Altitude (Ft)": 398,
//       "Country": "United States",
//       "Latitude": -86.012101,
//       "Location": "Troy",
//       "Longitude": 31.860399,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11011,
//       "Airport Code [ICAO]": "KSCD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Merkel Field Sylacauga Municipal Airport",
//       "Altitude (Ft)": 569,
//       "Country": "United States",
//       "Latitude": -86.30549621582031,
//       "Location": "Sylacauga",
//       "Longitude": 33.17179870605469,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11012,
//       "Airport Code [ICAO]": "KEDN",
//       "Airport Code iATA,FAA": "ETS",
//       "Airport Name": "Enterprise Municipal Airport",
//       "Altitude (Ft)": 361,
//       "Country": "United States",
//       "Latitude": -85.89990234,
//       "Location": "Enterprise",
//       "Longitude": 31.29969978,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11017,
//       "Airport Code [ICAO]": "KRYN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ryan Field",
//       "Altitude (Ft)": 2417,
//       "Country": "United States",
//       "Latitude": -111.17500305175781,
//       "Location": "Tucson",
//       "Longitude": 32.1422004699707,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11018,
//       "Airport Code [ICAO]": "AYEF",
//       "Airport Code iATA,FAA": "EFG",
//       "Airport Name": "Efogi Airport",
//       "Altitude (Ft)": 3900,
//       "Country": "Papua New Guinea",
//       "Latitude": 147.659805556,
//       "Location": "Efogi",
//       "Longitude": -9.15380555556,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11019,
//       "Airport Code [ICAO]": "AYKQ",
//       "Airport Code iATA,FAA": "KGW",
//       "Airport Name": "Kagi Airport",
//       "Altitude (Ft)": 4200,
//       "Country": "Papua New Guinea",
//       "Latitude": 147.669444444,
//       "Location": "Kagi",
//       "Longitude": -9.135916666670001,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11020,
//       "Airport Code [ICAO]": "AYNC",
//       "Airport Code iATA,FAA": "NDN",
//       "Airport Name": "Nadunumu Airport",
//       "Altitude (Ft)": 5100,
//       "Country": "Papua New Guinea",
//       "Latitude": 147.68425,
//       "Location": "Nadunumu",
//       "Longitude": -9.14355555556,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11021,
//       "Airport Code [ICAO]": "AYBD",
//       "Airport Code iATA,FAA": "BNM",
//       "Airport Name": "Bodinumu Airport",
//       "Altitude (Ft)": 3700,
//       "Country": "Papua New Guinea",
//       "Latitude": 147.666722222,
//       "Location": "Bodinumu",
//       "Longitude": -9.107777777779999,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11022,
//       "Airport Code [ICAO]": "KALX",
//       "Airport Code iATA,FAA": "ALX",
//       "Airport Name": "Thomas C Russell Field",
//       "Altitude (Ft)": 686,
//       "Country": "United States",
//       "Latitude": -85.9629974365,
//       "Location": "Alexander City",
//       "Longitude": 32.914699554399995,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11030,
//       "Airport Code [ICAO]": "YPKT",
//       "Airport Code iATA,FAA": "PKT",
//       "Airport Name": "Port Keats Airport",
//       "Altitude (Ft)": 91,
//       "Country": "Australia",
//       "Latitude": 129.5290069580078,
//       "Location": "Wadeye",
//       "Longitude": -14.25,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11031,
//       "Airport Code [ICAO]": "YGPT",
//       "Airport Code iATA,FAA": "GPN",
//       "Airport Name": "Garden Point Airport",
//       "Altitude (Ft)": 90,
//       "Country": "Australia",
//       "Latitude": 130.4219970703125,
//       "Location": "Pirlangimpi",
//       "Longitude": -11.40250015258789,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11032,
//       "Airport Code [ICAO]": "EDHS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Stade Airport",
//       "Altitude (Ft)": 62,
//       "Country": "Germany",
//       "Latitude": 9.499167442321777,
//       "Location": "Stade",
//       "Longitude": 53.56111145019531,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11035,
//       "Airport Code [ICAO]": "KMMK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Meriden Markham Municipal Airport",
//       "Altitude (Ft)": 103,
//       "Country": "United States",
//       "Latitude": -72.82949829101562,
//       "Location": "Meriden",
//       "Longitude": 41.50870132446289,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11038,
//       "Airport Code [ICAO]": "YFLS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Flinders Island Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 134.501,
//       "Location": "Elliston",
//       "Longitude": -33.730989,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11048,
//       "Airport Code [ICAO]": "LSZO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Luzern-Beromunster Airport",
//       "Altitude (Ft)": 2146,
//       "Country": "Switzerland",
//       "Latitude": 8.204719543457031,
//       "Location": "Lucerne",
//       "Longitude": 47.189998626708984,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11049,
//       "Airport Code [ICAO]": "LFTN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "La Grand'combe Airport",
//       "Altitude (Ft)": 1647,
//       "Country": "France",
//       "Latitude": 4.0122199058532715,
//       "Location": "La Grand'Combe",
//       "Longitude": 44.24440002441406,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11051,
//       "Airport Code [ICAO]": "OTHH",
//       "Airport Code iATA,FAA": "DOH",
//       "Airport Name": "Hamad International Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Qatar",
//       "Latitude": 51.608056,
//       "Location": "Doha",
//       "Longitude": 25.273056,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11053,
//       "Airport Code [ICAO]": "CYNR",
//       "Airport Code iATA,FAA": "HZP",
//       "Airport Name": "Fort Mackay / Horizon Airport",
//       "Altitude (Ft)": 916,
//       "Country": "Canada",
//       "Latitude": -111.700996399,
//       "Location": "Wood Buffalo",
//       "Longitude": 57.3816986084,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11054,
//       "Airport Code [ICAO]": "CEW9",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Canmore Municipal Heliport",
//       "Altitude (Ft)": 4296,
//       "Country": "Canada",
//       "Latitude": -115.337997437,
//       "Location": "Canmore",
//       "Longitude": 51.07780075069999,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11055,
//       "Airport Code [ICAO]": "UIAE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Krasnokamensk Airport",
//       "Altitude (Ft)": 2139,
//       "Country": "Russia",
//       "Latitude": 118.06114196777344,
//       "Location": "Krasnokamensk",
//       "Longitude": 50.03310775756836,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11067,
//       "Airport Code [ICAO]": "KRJD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ridgely Airpark",
//       "Altitude (Ft)": 64,
//       "Country": "United States",
//       "Latitude": -75.86630249023438,
//       "Location": "Ridgely",
//       "Longitude": 38.97010040283203,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11068,
//       "Airport Code [ICAO]": "KUWL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "New Castle Henry Co. Municipal Airport",
//       "Altitude (Ft)": 1088,
//       "Country": "United States",
//       "Latitude": -85.32649994,
//       "Location": "New Castle",
//       "Longitude": 39.87590027,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11069,
//       "Airport Code [ICAO]": "KTQK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Scott City Municipal Airport",
//       "Altitude (Ft)": 2963,
//       "Country": "United States",
//       "Latitude": -100.88500213623047,
//       "Location": "Scott City",
//       "Longitude": 38.474300384521484,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11070,
//       "Airport Code [ICAO]": "KHDE",
//       "Airport Code iATA,FAA": "HDE",
//       "Airport Name": "Brewster Field",
//       "Altitude (Ft)": 2313,
//       "Country": "United States",
//       "Latitude": -99.336502,
//       "Location": "Holdredge",
//       "Longitude": 40.452099,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11071,
//       "Airport Code [ICAO]": "KPTT",
//       "Airport Code iATA,FAA": "PTT",
//       "Airport Name": "Pratt Regional Airport",
//       "Altitude (Ft)": 1953,
//       "Country": "United States",
//       "Latitude": -98.74690247,
//       "Location": "Pratt",
//       "Longitude": 37.70159912,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11072,
//       "Airport Code [ICAO]": "UAON",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Yubileyniy Airfield",
//       "Altitude (Ft)": 328,
//       "Country": "Kazakhstan",
//       "Latitude": 63.25,
//       "Location": "Baikonur",
//       "Longitude": 46.05500030517578,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11073,
//       "Airport Code [ICAO]": "KAHQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Wahoo Municipal Airport",
//       "Altitude (Ft)": 1224,
//       "Country": "United States",
//       "Latitude": -96.59400177,
//       "Location": "Wahoo",
//       "Longitude": 41.2412986755,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11074,
//       "Airport Code [ICAO]": "KLXN",
//       "Airport Code iATA,FAA": "LXN",
//       "Airport Name": "Jim Kelly Field",
//       "Altitude (Ft)": 2413,
//       "Country": "United States",
//       "Latitude": -99.7772979736,
//       "Location": "Lexington",
//       "Longitude": 40.791000366199995,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11075,
//       "Airport Code [ICAO]": "K19S",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sublette Municipal Airport",
//       "Altitude (Ft)": 2908,
//       "Country": "United States",
//       "Latitude": -100.8300018,
//       "Location": "Sublette",
//       "Longitude": 37.49140167,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11076,
//       "Airport Code [ICAO]": "KCBF",
//       "Airport Code iATA,FAA": "CBF",
//       "Airport Name": "Council Bluffs Municipal Airport",
//       "Altitude (Ft)": 1253,
//       "Country": "United States",
//       "Latitude": -95.760597229,
//       "Location": "Council Bluffs",
//       "Longitude": 41.2592010498,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11077,
//       "Airport Code [ICAO]": "KOKK",
//       "Airport Code iATA,FAA": "OKK",
//       "Airport Name": "Kokomo Municipal Airport",
//       "Altitude (Ft)": 830,
//       "Country": "United States",
//       "Latitude": -86.05899810791,
//       "Location": "Kokomo",
//       "Longitude": 40.528198242188,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11078,
//       "Airport Code [ICAO]": "K2K7",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Neodesha Municipal Airport",
//       "Altitude (Ft)": 841,
//       "Country": "United States",
//       "Latitude": -95.64610290527344,
//       "Location": "Neodesha",
//       "Longitude": 37.43539810180664,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11079,
//       "Airport Code [ICAO]": "AK59",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "King Ranch Airport",
//       "Altitude (Ft)": 1350,
//       "Country": "United States",
//       "Latitude": -148.35499572753906,
//       "Location": "Sutton",
//       "Longitude": 61.79560089111328,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11080,
//       "Airport Code [ICAO]": "KEHA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Elkhart Morton County Airport",
//       "Altitude (Ft)": 3622,
//       "Country": "United States",
//       "Latitude": -101.879997,
//       "Location": "Elkhart",
//       "Longitude": 37.000702,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11081,
//       "Airport Code [ICAO]": "KFTG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Front Range Airport",
//       "Altitude (Ft)": 5512,
//       "Country": "United States",
//       "Latitude": -104.54299926757812,
//       "Location": "Denver",
//       "Longitude": 39.785301208496094,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11082,
//       "Airport Code [ICAO]": "KGBG",
//       "Airport Code iATA,FAA": "GBG",
//       "Airport Name": "Galesburg Municipal Airport",
//       "Altitude (Ft)": 764,
//       "Country": "United States",
//       "Latitude": -90.431098938,
//       "Location": "Galesburg",
//       "Longitude": 40.937999725299996,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11084,
//       "Airport Code [ICAO]": "KGUY",
//       "Airport Code iATA,FAA": "GUY",
//       "Airport Name": "Guymon Municipal Airport",
//       "Altitude (Ft)": 3123,
//       "Country": "United States",
//       "Latitude": -101.508003235,
//       "Location": "Guymon",
//       "Longitude": 36.6851005554,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11086,
//       "Airport Code [ICAO]": "KMEJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Meade Municipal Airport",
//       "Altitude (Ft)": 2529,
//       "Country": "United States",
//       "Latitude": -100.35600280761719,
//       "Location": "Meade",
//       "Longitude": 37.27690124511719,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11087,
//       "Airport Code [ICAO]": "MO00",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Turkey Mountain Estates Airport",
//       "Altitude (Ft)": 1000,
//       "Country": "United States",
//       "Latitude": -93.66690063476562,
//       "Location": "Shell Knob",
//       "Longitude": 36.59170150756836,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11088,
//       "Airport Code [ICAO]": "KULS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ulysses Airport",
//       "Altitude (Ft)": 3071,
//       "Country": "United States",
//       "Latitude": -101.3740005,
//       "Location": "Ulysses",
//       "Longitude": 37.60400009,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11089,
//       "Airport Code [ICAO]": "CO00",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Flagler Aerial Spraying Inc Airport",
//       "Altitude (Ft)": 4945,
//       "Country": "United States",
//       "Latitude": -103.06700134277344,
//       "Location": "Flagler",
//       "Longitude": 39.279998779296875,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11090,
//       "Airport Code [ICAO]": "KIDP",
//       "Airport Code iATA,FAA": "IDP",
//       "Airport Name": "Independence Municipal Airport",
//       "Altitude (Ft)": 825,
//       "Country": "United States",
//       "Latitude": -95.77839660640001,
//       "Location": "Independence",
//       "Longitude": 37.1584014893,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11091,
//       "Airport Code [ICAO]": "K3AU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Augusta Municipal Airport",
//       "Altitude (Ft)": 1328,
//       "Country": "United States",
//       "Latitude": -97.0779037475586,
//       "Location": "Augusta",
//       "Longitude": 37.671600341796875,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11092,
//       "Airport Code [ICAO]": "KLQR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Larned Pawnee County Airport",
//       "Altitude (Ft)": 2012,
//       "Country": "United States",
//       "Latitude": -99.08599854,
//       "Location": "Larned",
//       "Longitude": 38.20859909,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11093,
//       "Airport Code [ICAO]": "KLZZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lampasas Airport",
//       "Altitude (Ft)": 1215,
//       "Country": "United States",
//       "Latitude": -98.1958999633789,
//       "Location": "Lampasas",
//       "Longitude": 31.106199264526367,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11094,
//       "Airport Code [ICAO]": "KBYY",
//       "Airport Code iATA,FAA": "BBC",
//       "Airport Name": "Bay City Municipal Airport",
//       "Altitude (Ft)": 45,
//       "Country": "United States",
//       "Latitude": -95.8635025024,
//       "Location": "Bay City",
//       "Longitude": 28.9733009338,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11095,
//       "Airport Code [ICAO]": "KPRX",
//       "Airport Code iATA,FAA": "PRX",
//       "Airport Name": "Cox Field",
//       "Altitude (Ft)": 547,
//       "Country": "United States",
//       "Latitude": -95.450798034668,
//       "Location": "Paris",
//       "Longitude": 33.636600494385,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11096,
//       "Airport Code [ICAO]": "KCFV",
//       "Airport Code iATA,FAA": "CFV",
//       "Airport Name": "Coffeyville Municipal Airport",
//       "Altitude (Ft)": 754,
//       "Country": "United States",
//       "Latitude": -95.5718994141,
//       "Location": "Coffeyville",
//       "Longitude": 37.09400177,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11097,
//       "Airport Code [ICAO]": "24SC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "The Farm Airport",
//       "Altitude (Ft)": 580,
//       "Country": "United States",
//       "Latitude": -81.42970275878906,
//       "Location": "Summit",
//       "Longitude": 33.93579864501953,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11098,
//       "Airport Code [ICAO]": "KGXY",
//       "Airport Code iATA,FAA": "GXY",
//       "Airport Name": "Greeley–Weld County Airport",
//       "Altitude (Ft)": 4697,
//       "Country": "United States",
//       "Latitude": -104.633003235,
//       "Location": "Greeley",
//       "Longitude": 40.4374008179,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11099,
//       "Airport Code [ICAO]": "KM01",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "General Dewitt Spain Airport",
//       "Altitude (Ft)": 225,
//       "Country": "United States",
//       "Latitude": -90.05400085,
//       "Location": "Memphis",
//       "Longitude": 35.20069885,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11100,
//       "Airport Code [ICAO]": "UUOR",
//       "Airport Code iATA,FAA": "OEL",
//       "Airport Name": "Oryol Yuzhny Airport",
//       "Altitude (Ft)": 656,
//       "Country": "United States",
//       "Latitude": 36.0022010803,
//       "Location": "Oakley",
//       "Longitude": 52.934700012200004,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11101,
//       "Airport Code [ICAO]": "KFET",
//       "Airport Code iATA,FAA": "FET",
//       "Airport Name": "Fremont Municipal Airport",
//       "Altitude (Ft)": 1204,
//       "Country": "United States",
//       "Latitude": -96.52020264,
//       "Location": "Fremont",
//       "Longitude": 41.44910049,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11102,
//       "Airport Code [ICAO]": "KLGD",
//       "Airport Code iATA,FAA": "LGD",
//       "Airport Name": "La Grande/Union County Airport",
//       "Altitude (Ft)": 2717,
//       "Country": "United States",
//       "Latitude": -118.007003784,
//       "Location": "La Grande",
//       "Longitude": 45.2901992798,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11103,
//       "Airport Code [ICAO]": "EPSY",
//       "Airport Code iATA,FAA": "SZY",
//       "Airport Name": "Olsztyn-Mazury Airport",
//       "Altitude (Ft)": 463,
//       "Country": "Poland",
//       "Latitude": 20.9377,
//       "Location": "Szczytno-Szymany",
//       "Longitude": 53.481899,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11110,
//       "Airport Code [ICAO]": "KMPO",
//       "Airport Code iATA,FAA": "MPO",
//       "Airport Name": "Pocono Mountains Municipal Airport",
//       "Altitude (Ft)": 1915,
//       "Country": "United States",
//       "Latitude": -75.378897,
//       "Location": "Mount Pocono",
//       "Longitude": 41.137503,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11111,
//       "Airport Code [ICAO]": "KUKT",
//       "Airport Code iATA,FAA": "UKT",
//       "Airport Name": "Quakertown Airport",
//       "Altitude (Ft)": 526,
//       "Country": "United States",
//       "Latitude": -75.381896972656,
//       "Location": "Quakertown",
//       "Longitude": 40.435199737549,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11127,
//       "Airport Code [ICAO]": "CYBA",
//       "Airport Code iATA,FAA": "YBA",
//       "Airport Name": "Banff Airport",
//       "Altitude (Ft)": 4583,
//       "Country": "Canada",
//       "Latitude": -115.541861057,
//       "Location": "Banff",
//       "Longitude": 51.207340469900004,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11129,
//       "Airport Code [ICAO]": "EKNB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nordborg Flyveplads",
//       "Altitude (Ft)": 0,
//       "Country": "Denmark",
//       "Latitude": 9.753379,
//       "Location": "Nordborg",
//       "Longitude": 55.07485,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11131,
//       "Airport Code [ICAO]": "KBNG",
//       "Airport Code iATA,FAA": "BNG",
//       "Airport Name": "Banning Municipal Airport",
//       "Altitude (Ft)": 2219,
//       "Country": "United States",
//       "Latitude": -116.850997925,
//       "Location": "Banning",
//       "Longitude": 33.9230995178,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11132,
//       "Airport Code [ICAO]": "EDFL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Gießen-Lützellinden Airport",
//       "Altitude (Ft)": 755,
//       "Country": "Germany",
//       "Latitude": 8.590277671813965,
//       "Location": "Giessen",
//       "Longitude": 50.543888092041016,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11133,
//       "Airport Code [ICAO]": "LHTL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tököl Airport",
//       "Altitude (Ft)": 318,
//       "Country": "Hungary",
//       "Latitude": 18.980800628699996,
//       "Location": "Budapest",
//       "Longitude": 47.345298767100005,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11134,
//       "Airport Code [ICAO]": "KOFK",
//       "Airport Code iATA,FAA": "OFK",
//       "Airport Name": "Karl Stefan Memorial Airport",
//       "Altitude (Ft)": 1573,
//       "Country": "United States",
//       "Latitude": -97.435096740723,
//       "Location": "Norfolk  Nebraska",
//       "Longitude": 41.985500335693,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11139,
//       "Airport Code [ICAO]": "HUKO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kotido Airport",
//       "Altitude (Ft)": 4020,
//       "Country": "Uganda",
//       "Latitude": 34.1232,
//       "Location": "Kotido",
//       "Longitude": 2.953,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11140,
//       "Airport Code [ICAO]": "HUKJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kajjansi Airfield",
//       "Altitude (Ft)": 3743,
//       "Country": "Uganda",
//       "Latitude": 32.55297,
//       "Location": "Kampala",
//       "Longitude": 0.1968,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11141,
//       "Airport Code [ICAO]": "KAWO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Arlington Municipal Airport",
//       "Altitude (Ft)": 142,
//       "Country": "United States",
//       "Latitude": -122.1589966,
//       "Location": "Arlington",
//       "Longitude": 48.16070175,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11142,
//       "Airport Code [ICAO]": "SNZR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Paracatu Airport",
//       "Altitude (Ft)": 2359,
//       "Country": "Brazil",
//       "Latitude": -46.88309860229492,
//       "Location": "Paracatu",
//       "Longitude": -17.242599487304688,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11143,
//       "Airport Code [ICAO]": "SNKF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Das Bandeirinhas Airport",
//       "Altitude (Ft)": 3478,
//       "Country": "Brazil",
//       "Latitude": -43.797444,
//       "Location": "Conselheiro Lafaiete",
//       "Longitude": -20.738585,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11144,
//       "Airport Code [ICAO]": "SNAP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Janaúba Airport",
//       "Altitude (Ft)": 1732,
//       "Country": "Brazil",
//       "Latitude": -43.323102,
//       "Location": "Janauba",
//       "Longitude": -15.732,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11145,
//       "Airport Code [ICAO]": "SNTO",
//       "Airport Code iATA,FAA": "TFL",
//       "Airport Name": "Juscelino Kubitscheck Airport",
//       "Altitude (Ft)": 1572,
//       "Country": "Brazil",
//       "Latitude": -41.51359939575195,
//       "Location": "Teofilo Otoni",
//       "Longitude": -17.89229965209961,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11146,
//       "Airport Code [ICAO]": "SNBM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cristiano Ferreira Varella Airport",
//       "Altitude (Ft)": 886,
//       "Country": "Brazil",
//       "Latitude": -42.39440155029297,
//       "Location": "Muriae",
//       "Longitude": -21.126100540161133,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11153,
//       "Airport Code [ICAO]": "KTPF",
//       "Airport Code iATA,FAA": "TPF",
//       "Airport Name": "Peter O Knight Airport",
//       "Altitude (Ft)": 8,
//       "Country": "United States",
//       "Latitude": -82.44930267334,
//       "Location": "Tampa",
//       "Longitude": 27.915599822998,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11170,
//       "Airport Code [ICAO]": "LFYL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lure Malbouhans Air Base",
//       "Altitude (Ft)": 1040,
//       "Country": "France",
//       "Latitude": 6.54583,
//       "Location": "Malbouhans",
//       "Longitude": 47.7047,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11175,
//       "Airport Code [ICAO]": "SDTK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Parati Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Brazil",
//       "Latitude": -44.720298767100005,
//       "Location": "Paraty",
//       "Longitude": -23.2243995667,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11176,
//       "Airport Code [ICAO]": "SBBZ",
//       "Airport Code iATA,FAA": "BZC",
//       "Airport Name": "Umberto Modiano Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Brazil",
//       "Latitude": -41.96289825439453,
//       "Location": "Buzios",
//       "Longitude": -22.770999908447266,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11177,
//       "Airport Code [ICAO]": "SDAG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Angra dos Reis Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Brazil",
//       "Latitude": -44.307098388671875,
//       "Location": "Angra dos Reis",
//       "Longitude": -22.975299835205078,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11178,
//       "Airport Code [ICAO]": "SDUN",
//       "Airport Code iATA,FAA": "ITP",
//       "Airport Name": "Itaperuna Airport",
//       "Altitude (Ft)": 410,
//       "Country": "Brazil",
//       "Latitude": -41.8759002686,
//       "Location": "Itaperuna",
//       "Longitude": -21.219299316399997,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11179,
//       "Airport Code [ICAO]": "SDMC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Maricá Airport",
//       "Altitude (Ft)": 13,
//       "Country": "Brazil",
//       "Latitude": -42.830898,
//       "Location": "Marica",
//       "Longitude": -22.9195,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11180,
//       "Airport Code [ICAO]": "SDRS",
//       "Airport Code iATA,FAA": "REZ",
//       "Airport Name": "Resende Airport",
//       "Altitude (Ft)": 1320,
//       "Country": "Brazil",
//       "Latitude": -44.4803009033,
//       "Location": "Resende",
//       "Longitude": -22.4785003662,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11181,
//       "Airport Code [ICAO]": "SDSK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Saquarema Airport",
//       "Altitude (Ft)": 26,
//       "Country": "Brazil",
//       "Latitude": -42.50694274902344,
//       "Location": "Saquarema",
//       "Longitude": -22.92972183227539,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11182,
//       "Airport Code [ICAO]": "CEG4",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Drumheller Municipal Airport",
//       "Altitude (Ft)": 2597,
//       "Country": "Canada",
//       "Latitude": -112.7490005493164,
//       "Location": "Drumheller",
//       "Longitude": 51.49639892578125,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11193,
//       "Airport Code [ICAO]": "FZWE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mwene-Ditu Airport",
//       "Altitude (Ft)": 3198,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 23.08300018310547,
//       "Location": "Mwene-Ditu",
//       "Longitude": -6.982999801635742,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11194,
//       "Airport Code [ICAO]": "FZWT",
//       "Airport Code iATA,FAA": "KBN",
//       "Airport Name": "Tunta Airport",
//       "Altitude (Ft)": 2766,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 24.4829998016,
//       "Location": "Kabinda",
//       "Longitude": -6.132999897,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11195,
//       "Airport Code [ICAO]": "FZGV",
//       "Airport Code iATA,FAA": "IKL",
//       "Airport Name": "Ikela Airport",
//       "Altitude (Ft)": 1283,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 23.372501,
//       "Location": "Ikela",
//       "Longitude": -1.048109,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11198,
//       "Airport Code [ICAO]": "SWRP",
//       "Airport Code iATA,FAA": "AIR",
//       "Airport Name": "Aripuanã Airport",
//       "Altitude (Ft)": 623,
//       "Country": "Brazil",
//       "Latitude": -59.457273,
//       "Location": "Aripuana",
//       "Longitude": -10.188278,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11199,
//       "Airport Code [ICAO]": "SWJU",
//       "Airport Code iATA,FAA": "JRN",
//       "Airport Name": "Juruena Airport",
//       "Altitude (Ft)": 525,
//       "Country": "Brazil",
//       "Latitude": -58.489444732666016,
//       "Location": "Juruena",
//       "Longitude": -10.305832862854004,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11200,
//       "Airport Code [ICAO]": "SWJN",
//       "Airport Code iATA,FAA": "JIA",
//       "Airport Name": "Juína Airport",
//       "Altitude (Ft)": 1083,
//       "Country": "Brazil",
//       "Latitude": -58.701668,
//       "Location": "Juina",
//       "Longitude": -11.419444,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11201,
//       "Airport Code [ICAO]": "SWVC",
//       "Airport Code iATA,FAA": "VLP",
//       "Airport Name": "Vila Rica Airport",
//       "Altitude (Ft)": 892,
//       "Country": "Brazil",
//       "Latitude": -51.1422233581543,
//       "Location": "Vila Rica",
//       "Longitude": -9.979443550109863,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11202,
//       "Airport Code [ICAO]": "SIZX",
//       "Airport Code iATA,FAA": "JUA",
//       "Airport Name": "Inácio Luís do Nascimento Airport",
//       "Altitude (Ft)": 870,
//       "Country": "Brazil",
//       "Latitude": -57.5495,
//       "Location": "Juara",
//       "Longitude": -11.2966,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11203,
//       "Airport Code [ICAO]": "SWKC",
//       "Airport Code iATA,FAA": "CCX",
//       "Airport Name": "Cáceres Airport",
//       "Altitude (Ft)": 492,
//       "Country": "Brazil",
//       "Latitude": -57.62990188598633,
//       "Location": "Caceres",
//       "Longitude": -16.04360008239746,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11204,
//       "Airport Code [ICAO]": "SWPL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Posto Leonardo Vilas Boas Airport",
//       "Altitude (Ft)": 1083,
//       "Country": "Brazil",
//       "Latitude": -53.38166809082031,
//       "Location": "Chapada dos Guimaraes",
//       "Longitude": -12.198332786560059,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11205,
//       "Airport Code [ICAO]": "SWTS",
//       "Airport Code iATA,FAA": "TGQ",
//       "Airport Name": "Tangará da Serra Airport",
//       "Altitude (Ft)": 1460,
//       "Country": "Brazil",
//       "Latitude": -57.4435005188,
//       "Location": "Tangara da Serra",
//       "Longitude": -14.661999702500001,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11206,
//       "Airport Code [ICAO]": "SWEK",
//       "Airport Code iATA,FAA": "CQA",
//       "Airport Name": "Canarana Airport",
//       "Altitude (Ft)": 1314,
//       "Country": "Brazil",
//       "Latitude": -52.27055740356445,
//       "Location": "Canarana",
//       "Longitude": -13.574443817138672,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11207,
//       "Airport Code [ICAO]": "SWVB",
//       "Airport Code iATA,FAA": "MTG",
//       "Airport Name": "Vila Bela da Santíssima Trindade Airport",
//       "Altitude (Ft)": 660,
//       "Country": "Brazil",
//       "Latitude": -59.9458,
//       "Location": "Vila Bela da Santissima Trindade ",
//       "Longitude": -14.9942,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11208,
//       "Airport Code [ICAO]": "FZFU",
//       "Airport Code iATA,FAA": "BMB",
//       "Airport Name": "Bumbar Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 22.481701,
//       "Location": "Bumba",
//       "Longitude": 2.18278,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11209,
//       "Airport Code [ICAO]": "SNOB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sobral Airport",
//       "Altitude (Ft)": 210,
//       "Country": "Brazil",
//       "Latitude": -40.336802,
//       "Location": "Sobral",
//       "Longitude": -3.67889,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11210,
//       "Airport Code [ICAO]": "SNAL",
//       "Airport Code iATA,FAA": "APQ",
//       "Airport Name": "Arapiraca Airport",
//       "Altitude (Ft)": 886,
//       "Country": "Brazil",
//       "Latitude": -36.62919998168945,
//       "Location": "Arapiraca",
//       "Longitude": -9.775360107421875,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11211,
//       "Airport Code [ICAO]": "SNQG",
//       "Airport Code iATA,FAA": "FLB",
//       "Airport Name": "Cangapara Airport",
//       "Altitude (Ft)": 689,
//       "Country": "Brazil",
//       "Latitude": -43.077301025390625,
//       "Location": "Floriano",
//       "Longitude": -6.8463897705078125,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11212,
//       "Airport Code [ICAO]": "SNPC",
//       "Airport Code iATA,FAA": "PCS",
//       "Airport Name": "Picos Airport",
//       "Altitude (Ft)": 1050,
//       "Country": "Brazil",
//       "Latitude": -41.52370071411133,
//       "Location": "Picos",
//       "Longitude": -7.0620598793029785,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11229,
//       "Airport Code [ICAO]": "FZNP",
//       "Airport Code iATA,FAA": "BNC",
//       "Airport Name": "Beni Airport",
//       "Altitude (Ft)": 3517,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 29.4739,
//       "Location": "Beni",
//       "Longitude": 0.575,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11230,
//       "Airport Code [ICAO]": "FZGN",
//       "Airport Code iATA,FAA": "BNB",
//       "Airport Name": "Boende Airport",
//       "Altitude (Ft)": 1168,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 20.850000381469727,
//       "Location": "Boende",
//       "Longitude": -0.21699999272823334,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11243,
//       "Airport Code [ICAO]": "KMTP",
//       "Airport Code iATA,FAA": "MTP",
//       "Airport Name": "Montauk Airport",
//       "Altitude (Ft)": 6,
//       "Country": "United States",
//       "Latitude": -71.920797,
//       "Location": "Montauk",
//       "Longitude": 41.0765,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11255,
//       "Airport Code [ICAO]": "KVPZ",
//       "Airport Code iATA,FAA": "VPZ",
//       "Airport Name": "Porter County Municipal Airport",
//       "Altitude (Ft)": 770,
//       "Country": "United States",
//       "Latitude": -87.00710297,
//       "Location": "Valparaiso IN",
//       "Longitude": 41.45399857,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11257,
//       "Airport Code [ICAO]": "VRMD",
//       "Airport Code iATA,FAA": "DRV",
//       "Airport Name": "Dharavandhoo Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Maldives",
//       "Latitude": 73.1302,
//       "Location": "DHARAVANDHOO",
//       "Longitude": 5.1561,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11258,
//       "Airport Code [ICAO]": "WAPI",
//       "Airport Code iATA,FAA": "SXK",
//       "Airport Name": "Saumlaki/Olilit Airport",
//       "Altitude (Ft)": 218,
//       "Country": "Indonesia",
//       "Latitude": 131.305999756,
//       "Location": "Saumlaki",
//       "Longitude": -7.9886097908,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11268,
//       "Airport Code [ICAO]": "SUMO",
//       "Airport Code iATA,FAA": "MLZ",
//       "Airport Name": "Cerro Largo International Airport",
//       "Altitude (Ft)": 364,
//       "Country": "Uruguay",
//       "Latitude": -54.21670150756836,
//       "Location": "Melo",
//       "Longitude": -32.33789825439453,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11269,
//       "Airport Code [ICAO]": "SUPU",
//       "Airport Code iATA,FAA": "PDU",
//       "Airport Name": "Tydeo Larre Borges Airport",
//       "Altitude (Ft)": 138,
//       "Country": "Uruguay",
//       "Latitude": -58.0619010925293,
//       "Location": "Paysandu",
//       "Longitude": -32.36330032348633,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11270,
//       "Airport Code [ICAO]": "SUAG",
//       "Airport Code iATA,FAA": "ATI",
//       "Airport Name": "Artigas International Airport",
//       "Altitude (Ft)": 410,
//       "Country": "Uruguay",
//       "Latitude": -56.50790023803711,
//       "Location": "Artigas ",
//       "Longitude": -30.400699615478516,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11272,
//       "Airport Code [ICAO]": "YMBD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Murray Bridge Airport",
//       "Altitude (Ft)": 55,
//       "Country": "Australia",
//       "Latitude": 139.2270050048828,
//       "Location": "Murray Bridge",
//       "Longitude": -35.06669998168945,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11273,
//       "Airport Code [ICAO]": "YHSM",
//       "Airport Code iATA,FAA": "HSM",
//       "Airport Name": "Horsham Airport",
//       "Altitude (Ft)": 445,
//       "Country": "Australia",
//       "Latitude": 142.17300415039062,
//       "Location": "Horsham",
//       "Longitude": -36.669700622558594,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11274,
//       "Airport Code [ICAO]": "YSWH",
//       "Airport Code iATA,FAA": "SWH",
//       "Airport Name": "Swan Hill Airport",
//       "Altitude (Ft)": 234,
//       "Country": "Australia",
//       "Latitude": 143.5330047607422,
//       "Location": "Swan Hill",
//       "Longitude": -35.37580108642578,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11277,
//       "Airport Code [ICAO]": "NFUL",
//       "Airport Code iATA,FAA": "TTL",
//       "Airport Name": "Turtle Island Seaplane Base",
//       "Altitude (Ft)": 0,
//       "Country": "Fiji",
//       "Latitude": 177.368,
//       "Location": "Turtle Island",
//       "Longitude": -16.966,
//       "GMT Offset": "12",
//       "Daylight Savings": "O",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11288,
//       "Airport Code [ICAO]": "WARU",
//       "Airport Code iATA,FAA": "KWB",
//       "Airport Name": "Dewadaru - Kemujan Island",
//       "Altitude (Ft)": 35,
//       "Country": "Indonesia",
//       "Latitude": 110.47838,
//       "Location": "Karimunjawa",
//       "Longitude": -5.80091,
//       "GMT Offset": "7",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11290,
//       "Airport Code [ICAO]": "FZRQ",
//       "Airport Code iATA,FAA": "KOO",
//       "Airport Name": "Kongolo Airport",
//       "Altitude (Ft)": 1850,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 26.99,
//       "Location": "Kongolo",
//       "Longitude": -5.39444,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11291,
//       "Airport Code [ICAO]": "VLAP",
//       "Airport Code iATA,FAA": "AOU",
//       "Airport Name": "Attopeu Airport",
//       "Altitude (Ft)": 344,
//       "Country": "Laos",
//       "Latitude": 106.821699142,
//       "Location": "Attopeu",
//       "Longitude": 14.814715357,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11292,
//       "Airport Code [ICAO]": "FZCF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kahemba Airport",
//       "Altitude (Ft)": 3425,
//       "Country": "Congo (Kinshasa)",
//       "Latitude": 19.017000198364258,
//       "Location": "Kahemba",
//       "Longitude": -7.333000183105469,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11293,
//       "Airport Code [ICAO]": "SVFM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Francisco de Miranda Airport",
//       "Altitude (Ft)": 2739,
//       "Country": "Venezuela",
//       "Latitude": -66.8435134888,
//       "Location": "Caracas",
//       "Longitude": 10.485033035299999,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11295,
//       "Airport Code [ICAO]": "SSOE",
//       "Airport Code iATA,FAA": "SQX",
//       "Airport Name": "São Miguel do Oeste Airport",
//       "Altitude (Ft)": 2180,
//       "Country": "Brazil",
//       "Latitude": -53.503501892089844,
//       "Location": "Sao Miguel do Oeste",
//       "Longitude": -26.781600952148438,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11299,
//       "Airport Code [ICAO]": "KLDM",
//       "Airport Code iATA,FAA": "LDM",
//       "Airport Name": "Mason County Airport",
//       "Altitude (Ft)": 646,
//       "Country": "United States",
//       "Latitude": -86.40789795,
//       "Location": "Ludington",
//       "Longitude": 43.96250153,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11312,
//       "Airport Code [ICAO]": "KRHV",
//       "Airport Code iATA,FAA": "RHV",
//       "Airport Name": "Reid-Hillview Airport of Santa Clara County",
//       "Altitude (Ft)": 135,
//       "Country": "United States",
//       "Latitude": -121.819000244,
//       "Location": "San Jose",
//       "Longitude": 37.332901001,
//       "GMT Offset": "8",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11328,
//       "Airport Code [ICAO]": "OOSH",
//       "Airport Code iATA,FAA": "OHS",
//       "Airport Name": "Sohar Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Oman",
//       "Latitude": 56.62541,
//       "Location": "Sohar",
//       "Longitude": 24.38604,
//       "GMT Offset": "4",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11331,
//       "Airport Code [ICAO]": "OPKW",
//       "Airport Code iATA,FAA": "KCF",
//       "Airport Name": "Kadanwari Airport",
//       "Altitude (Ft)": 248,
//       "Country": "Pakistan",
//       "Latitude": 69.31670379639999,
//       "Location": "Kadanwari",
//       "Longitude": 27.1667003632,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11332,
//       "Airport Code [ICAO]": "OPSW",
//       "Airport Code iATA,FAA": "RZS",
//       "Airport Name": "Sawan Airport",
//       "Altitude (Ft)": 160,
//       "Country": "Pakistan",
//       "Latitude": 68.878098,
//       "Location": "Sindh",
//       "Longitude": 26.9662,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11352,
//       "Airport Code [ICAO]": "VNTH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Thamkharka Airport",
//       "Altitude (Ft)": 5240,
//       "Country": "Nepal",
//       "Latitude": 86.858022,
//       "Location": "Thamkharka",
//       "Longitude": 27.04787,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11353,
//       "Airport Code [ICAO]": "VRNT",
//       "Airport Code iATA,FAA": "TMF",
//       "Airport Name": "Thimarafushi Airport",
//       "Altitude (Ft)": 6,
//       "Country": "Maldives",
//       "Latitude": 73.1533,
//       "Location": "Thimarafushi",
//       "Longitude": 2.211,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11354,
//       "Airport Code [ICAO]": "VREI",
//       "Airport Code iATA,FAA": "IFU",
//       "Airport Name": "Ifuru Airport",
//       "Altitude (Ft)": 20,
//       "Country": "Maldives",
//       "Latitude": 73.025,
//       "Location": "Ifuru",
//       "Longitude": 5.7083,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11355,
//       "Airport Code [ICAO]": "AYKT",
//       "Airport Code iATA,FAA": "KZF",
//       "Airport Name": "Kaintiba Airport",
//       "Altitude (Ft)": 2050,
//       "Country": "Papua New Guinea",
//       "Latitude": 146.033833333,
//       "Location": "Kieta",
//       "Longitude": -7.50025,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11356,
//       "Airport Code [ICAO]": "LTCB",
//       "Airport Code iATA,FAA": "OGU",
//       "Airport Name": "Ordu Giresun Airport",
//       "Altitude (Ft)": 11,
//       "Country": "Turkey",
//       "Latitude": 38.080994,
//       "Location": "Ordu-Giresun",
//       "Longitude": 40.966047,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11357,
//       "Airport Code [ICAO]": "LTCW",
//       "Airport Code iATA,FAA": "YKO",
//       "Airport Name": "Hakkari Yüksekova Airport",
//       "Altitude (Ft)": 6400,
//       "Country": "Turkey",
//       "Latitude": 44.2381,
//       "Location": "Hakkari",
//       "Longitude": 37.5497,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11386,
//       "Airport Code [ICAO]": "EPJG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Jelenia Góra Glider Airport",
//       "Altitude (Ft)": 1119,
//       "Country": "Poland",
//       "Latitude": 15.785599708557129,
//       "Location": "Jelenia Gora",
//       "Longitude": 50.89889907836914,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11389,
//       "Airport Code [ICAO]": "VQBT",
//       "Airport Code iATA,FAA": "BUT",
//       "Airport Name": "Bathpalathang Airport",
//       "Altitude (Ft)": 8485,
//       "Country": "Bhutan",
//       "Latitude": 90.7471,
//       "Location": "Jakar",
//       "Longitude": 27.5622,
//       "GMT Offset": "6",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11401,
//       "Airport Code [ICAO]": "WAMI",
//       "Airport Code iATA,FAA": "TLI",
//       "Airport Name": "Sultan Bantilan Airport",
//       "Altitude (Ft)": 40,
//       "Country": "Indonesia",
//       "Latitude": 120.793658,
//       "Location": "Toli-Toli",
//       "Longitude": 1.123428,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11406,
//       "Airport Code [ICAO]": "UUYK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Vuktyl Airport",
//       "Altitude (Ft)": 358,
//       "Country": "Russia",
//       "Latitude": 57.279998779296875,
//       "Location": "Vuktyl",
//       "Longitude": 63.823299407958984,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11407,
//       "Airport Code [ICAO]": "USPT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Berezniki Airport",
//       "Altitude (Ft)": 207,
//       "Country": "Russia",
//       "Latitude": 56.85768127441406,
//       "Location": "Berezniki",
//       "Longitude": 59.57954788208008,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11431,
//       "Airport Code [ICAO]": "SCSS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "San Sebastián Airport",
//       "Altitude (Ft)": 50,
//       "Country": "Chile",
//       "Latitude": -68.6500015258789,
//       "Location": "San Sebastian",
//       "Longitude": -53.31700134277344,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11433,
//       "Airport Code [ICAO]": "SAWL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tolwin Observatory Airport",
//       "Altitude (Ft)": 90,
//       "Country": "Argentina",
//       "Latitude": -67.1989974976,
//       "Location": "Tolhuin",
//       "Longitude": -54.521900177,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11436,
//       "Airport Code [ICAO]": "USDS",
//       "Airport Code iATA,FAA": "TQL",
//       "Airport Name": "Tarko-Sale Airport",
//       "Altitude (Ft)": 82,
//       "Country": "Russia",
//       "Latitude": 77.81809997559999,
//       "Location": "Tarko-Sale",
//       "Longitude": 64.9308013916,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11438,
//       "Airport Code [ICAO]": "KCMA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Camarillo Airport",
//       "Altitude (Ft)": 77,
//       "Country": "United States",
//       "Latitude": -119.09400177001953,
//       "Location": "Camarillo - CA",
//       "Longitude": 34.21369934082031,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11452,
//       "Airport Code [ICAO]": "EBLE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Leopoldsburg Airfield",
//       "Altitude (Ft)": 207,
//       "Country": "Belgium",
//       "Latitude": 5.3072222,
//       "Location": "Leopoldsburg",
//       "Longitude": 51.12,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11473,
//       "Airport Code [ICAO]": "ZLJC",
//       "Airport Code iATA,FAA": "JIC",
//       "Airport Name": "Jinchuan Airport",
//       "Altitude (Ft)": 4740,
//       "Country": "China",
//       "Latitude": 102.348333333,
//       "Location": "Jinchuan",
//       "Longitude": 38.542222222199996,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11479,
//       "Airport Code [ICAO]": "MNLP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Omtepe Airport",
//       "Altitude (Ft)": 200,
//       "Country": "Nicaragua",
//       "Latitude": -85.702995,
//       "Location": "Moyogalpa",
//       "Longitude": 11.523616,
//       "GMT Offset": "-6",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11498,
//       "Airport Code [ICAO]": "ZWAX",
//       "Airport Code iATA,FAA": "BPL",
//       "Airport Name": "Alashankou Bole (Bortala) airport",
//       "Altitude (Ft)": 1253,
//       "Country": "China",
//       "Latitude": 82.3,
//       "Location": "Bole",
//       "Longitude": 44.895,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11499,
//       "Airport Code [ICAO]": "ZWFY",
//       "Airport Code iATA,FAA": "FYN",
//       "Airport Name": "Fuyun Koktokay Airport",
//       "Altitude (Ft)": 3081,
//       "Country": "China",
//       "Latitude": 89.512006,
//       "Location": "Fuyun",
//       "Longitude": 46.804169,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11524,
//       "Airport Code [ICAO]": "UNKS",
//       "Airport Code iATA,FAA": "ACS",
//       "Airport Name": "Achinsk Airport",
//       "Altitude (Ft)": 1033,
//       "Country": "Russia",
//       "Latitude": 90.57080078125,
//       "Location": "Achinsk",
//       "Longitude": 56.2682991027832,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11545,
//       "Airport Code [ICAO]": "ZBLF",
//       "Airport Code iATA,FAA": "LFQ",
//       "Airport Name": "Linfen Qiaoli Airport",
//       "Altitude (Ft)": 1483,
//       "Country": "China",
//       "Latitude": 111.641236,
//       "Location": "LINFEN",
//       "Longitude": 36.132629,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11583,
//       "Airport Code [ICAO]": "CEC4",
//       "Airport Code iATA,FAA": "YJP",
//       "Airport Name": "Hinton/Jasper-Hinton Airport",
//       "Altitude (Ft)": 4006,
//       "Country": "Canada",
//       "Latitude": -117.752998352,
//       "Location": "Hinton",
//       "Longitude": 53.3191986084,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11638,
//       "Airport Code [ICAO]": "KWVI",
//       "Airport Code iATA,FAA": "WVI",
//       "Airport Name": "Watsonville Municipal Airport",
//       "Altitude (Ft)": 163,
//       "Country": "United States",
//       "Latitude": -121.790000916,
//       "Location": "Watsonville",
//       "Longitude": 36.9356994629,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11644,
//       "Airport Code [ICAO]": "VQGP",
//       "Airport Code iATA,FAA": "GLU",
//       "Airport Name": "Gelephu Airport",
//       "Altitude (Ft)": 980,
//       "Country": "Bhutan",
//       "Latitude": 90.46412,
//       "Location": "Gelephu",
//       "Longitude": 26.88456,
//       "GMT Offset": "6",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11648,
//       "Airport Code [ICAO]": "KCVH",
//       "Airport Code iATA,FAA": "HLI",
//       "Airport Name": "Hollister Municipal Airport",
//       "Altitude (Ft)": 230,
//       "Country": "United States",
//       "Latitude": -121.410003662,
//       "Location": "Hollister",
//       "Longitude": 36.8932991028,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11649,
//       "Airport Code [ICAO]": "KJGG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Williamsburg Jamestown Airport",
//       "Altitude (Ft)": 49,
//       "Country": "United States",
//       "Latitude": -76.71610260009766,
//       "Location": "Williamsburg",
//       "Longitude": 37.239200592041016,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11658,
//       "Airport Code [ICAO]": "YDLK",
//       "Airport Code iATA,FAA": "DLK",
//       "Airport Name": "Dulkaninna Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 138.4810028076172,
//       "Location": "Dulkaninna",
//       "Longitude": -29.0132999420166,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11673,
//       "Airport Code [ICAO]": "YWMC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "William Creek Airport",
//       "Altitude (Ft)": 91,
//       "Country": "Australia",
//       "Latitude": 136.3419952392578,
//       "Location": "William Creek",
//       "Longitude": -28.906700134277344,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11684,
//       "Airport Code [ICAO]": "CJM9",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kenora Seaplane Base",
//       "Altitude (Ft)": 1060,
//       "Country": "Canada",
//       "Latitude": -94.49274,
//       "Location": "Kenora",
//       "Longitude": 49.767424,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11685,
//       "Airport Code [ICAO]": "EVRC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Rumbula Air Base",
//       "Altitude (Ft)": 26,
//       "Country": "Latvia",
//       "Latitude": 24.2266998291,
//       "Location": "Riga",
//       "Longitude": 56.8833007812,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11700,
//       "Airport Code [ICAO]": "RPVZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Siquijor Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Philippines",
//       "Latitude": 123.472251892,
//       "Location": "Siquijor",
//       "Longitude": 9.214203223190001,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11702,
//       "Airport Code [ICAO]": "CKM8",
//       "Airport Code iATA,FAA": "YBS",
//       "Airport Name": "Opapimiskan Lake Airport",
//       "Altitude (Ft)": 1023,
//       "Country": "Canada",
//       "Latitude": -90.3768997192,
//       "Location": "Musselwhite Mine",
//       "Longitude": 52.6067008972,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11713,
//       "Airport Code [ICAO]": "ZSRZ",
//       "Airport Code iATA,FAA": "RIZ",
//       "Airport Name": "Rizhao Shanzihe Airport",
//       "Altitude (Ft)": 121,
//       "Country": "China",
//       "Latitude": 119.324403,
//       "Location": "Rizhao",
//       "Longitude": 35.405033,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11714,
//       "Airport Code [ICAO]": "ZSSM",
//       "Airport Code iATA,FAA": "SQJ",
//       "Airport Name": "Shaxian Airport",
//       "Altitude (Ft)": 830,
//       "Country": "China",
//       "Latitude": 117.8336,
//       "Location": "Sanming",
//       "Longitude": 26.4263,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11717,
//       "Airport Code [ICAO]": "YTAM",
//       "Airport Code iATA,FAA": "XTO",
//       "Airport Name": "Taroom Airport",
//       "Altitude (Ft)": 240,
//       "Country": "Australia",
//       "Latitude": 149.89999389648438,
//       "Location": "Taroom",
//       "Longitude": -25.801700592041016,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11718,
//       "Airport Code [ICAO]": "EDFG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Gelnhausen Airport",
//       "Altitude (Ft)": 446,
//       "Country": "Germany",
//       "Latitude": 9.170000076293945,
//       "Location": "Gelnhausen",
//       "Longitude": 50.19722366333008,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11719,
//       "Airport Code [ICAO]": "EKAE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ærø Airport",
//       "Altitude (Ft)": 3,
//       "Country": "Denmark",
//       "Latitude": 10.456399917602539,
//       "Location": "Aero",
//       "Longitude": 54.85279846191406,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11720,
//       "Airport Code [ICAO]": "EDFT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lauterbach Airport",
//       "Altitude (Ft)": 1211,
//       "Country": "Germany",
//       "Latitude": 9.410833,
//       "Location": "Lauterbach-Wernges",
//       "Longitude": 50.683334,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11721,
//       "Airport Code [ICAO]": "EDFC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Aschaffenburg Airport",
//       "Altitude (Ft)": 410,
//       "Country": "Germany",
//       "Latitude": 9.063888549804688,
//       "Location": "Aschaffenburg",
//       "Longitude": 49.93888854980469,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11722,
//       "Airport Code [ICAO]": "CYSE",
//       "Airport Code iATA,FAA": "YSE",
//       "Airport Name": "Squamish Airport",
//       "Altitude (Ft)": 171,
//       "Country": "Canada",
//       "Latitude": -123.162002563,
//       "Location": "Squamish",
//       "Longitude": 49.7817001343,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11735,
//       "Airport Code [ICAO]": "EDFB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Reichelsheim Airport",
//       "Altitude (Ft)": 397,
//       "Country": "Germany",
//       "Latitude": 8.878055572509766,
//       "Location": "Reichelsheim",
//       "Longitude": 50.33583450317383,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11737,
//       "Airport Code [ICAO]": "EDFN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Marburg-Schönstadt Airport",
//       "Altitude (Ft)": 833,
//       "Country": "Germany",
//       "Latitude": 8.8149995803833,
//       "Location": "Schoenstadt",
//       "Longitude": 50.87444305419922,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11738,
//       "Airport Code [ICAO]": "EDHU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lauenbrück Airport",
//       "Altitude (Ft)": 98,
//       "Country": "Germany",
//       "Latitude": 9.573332786560059,
//       "Location": "Lauenbrueck",
//       "Longitude": 53.20750045776367,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11739,
//       "Airport Code [ICAO]": "EDXU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hüttenbusch Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Germany",
//       "Latitude": 8.947221755981445,
//       "Location": "Huettenbusch",
//       "Longitude": 53.28666687011719,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11740,
//       "Airport Code [ICAO]": "LDPV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Vrsar Crljenka Airport",
//       "Altitude (Ft)": 121,
//       "Country": "Croatia",
//       "Latitude": 13.630599975585938,
//       "Location": "Vsar",
//       "Longitude": 45.141700744628906,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11742,
//       "Airport Code [ICAO]": "KMRT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Union County Airport",
//       "Altitude (Ft)": 1021,
//       "Country": "United States",
//       "Latitude": -83.35160064697266,
//       "Location": "Marysville",
//       "Longitude": 40.224700927734375,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11743,
//       "Airport Code [ICAO]": "CYAH",
//       "Airport Code iATA,FAA": "YAH",
//       "Airport Name": "La Grande-4 Airport",
//       "Altitude (Ft)": 1005,
//       "Country": "Canada",
//       "Latitude": -73.6753005981,
//       "Location": "La Grande-4",
//       "Longitude": 53.754699707,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11744,
//       "Airport Code [ICAO]": "CYAL",
//       "Airport Code iATA,FAA": "YAL",
//       "Airport Name": "Alert Bay Airport",
//       "Altitude (Ft)": 240,
//       "Country": "Canada",
//       "Latitude": -126.91600036621094,
//       "Location": "Alert Bay",
//       "Longitude": 50.58219909667969,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11745,
//       "Airport Code [ICAO]": "CYAU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Liverpool South Shore Regional Airport",
//       "Altitude (Ft)": 321,
//       "Country": "Canada",
//       "Latitude": -64.85610198970001,
//       "Location": "Liverpool",
//       "Longitude": 44.2303009033,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11747,
//       "Airport Code [ICAO]": "CYBU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nipawin Airport",
//       "Altitude (Ft)": 1220,
//       "Country": "Canada",
//       "Latitude": -104.00800323486328,
//       "Location": "Nipawin",
//       "Longitude": 53.33250045776367,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11748,
//       "Airport Code [ICAO]": "CYCE",
//       "Airport Code iATA,FAA": "YCE",
//       "Airport Name": "Centralia / James T. Field Memorial Aerodrome",
//       "Altitude (Ft)": 824,
//       "Country": "Canada",
//       "Latitude": -81.508301,
//       "Location": "Centralia",
//       "Longitude": 43.285599,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11749,
//       "Airport Code [ICAO]": "CYCP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Blue River Airport",
//       "Altitude (Ft)": 2240,
//       "Country": "Canada",
//       "Latitude": -119.28299713134766,
//       "Location": "Blue River",
//       "Longitude": 52.11669921875,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11750,
//       "Airport Code [ICAO]": "CYCQ",
//       "Airport Code iATA,FAA": "YCQ",
//       "Airport Name": "Chetwynd Airport",
//       "Altitude (Ft)": 2000,
//       "Country": "Canada",
//       "Latitude": -121.62699890136719,
//       "Location": "Chetwynd",
//       "Longitude": 55.687198638916016,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11751,
//       "Airport Code [ICAO]": "CYDM",
//       "Airport Code iATA,FAA": "XRR",
//       "Airport Name": "Ross River Airport",
//       "Altitude (Ft)": 2314,
//       "Country": "Canada",
//       "Latitude": -132.42300415,
//       "Location": "Ross River",
//       "Longitude": 61.9706001282,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11752,
//       "Airport Code [ICAO]": "CYDO",
//       "Airport Code iATA,FAA": "YDO",
//       "Airport Name": "Dolbeau St Felicien Airport",
//       "Altitude (Ft)": 372,
//       "Country": "Canada",
//       "Latitude": -72.375,
//       "Location": "Dolbeau-St-Félicien",
//       "Longitude": 48.778499603271,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11753,
//       "Airport Code [ICAO]": "CYEY",
//       "Airport Code iATA,FAA": "YEY",
//       "Airport Name": "Amos/Magny Airport",
//       "Altitude (Ft)": 1068,
//       "Country": "Canada",
//       "Latitude": -78.249702,
//       "Location": "Amos",
//       "Longitude": 48.563903,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11754,
//       "Airport Code [ICAO]": "CYGD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Goderich Airport",
//       "Altitude (Ft)": 712,
//       "Country": "Canada",
//       "Latitude": -81.7106018066,
//       "Location": "Goderich",
//       "Longitude": 43.7668991089,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11755,
//       "Airport Code [ICAO]": "CYHE",
//       "Airport Code iATA,FAA": "YHE",
//       "Airport Name": "Hope Airport",
//       "Altitude (Ft)": 128,
//       "Country": "Canada",
//       "Latitude": -121.498001099,
//       "Location": "Hope",
//       "Longitude": 49.3683013916,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11756,
//       "Airport Code [ICAO]": "CYHT",
//       "Airport Code iATA,FAA": "YHT",
//       "Airport Name": "Haines Junction Airport",
//       "Altitude (Ft)": 2150,
//       "Country": "Canada",
//       "Latitude": -137.54600524902344,
//       "Location": "Haines Junction",
//       "Longitude": 60.78919982910156,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11757,
//       "Airport Code [ICAO]": "CYID",
//       "Airport Code iATA,FAA": "YDG",
//       "Airport Name": "Digby / Annapolis Regional Airport",
//       "Altitude (Ft)": 499,
//       "Country": "Canada",
//       "Latitude": -65.7854247093,
//       "Location": "Digby",
//       "Longitude": 44.5458450365,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11758,
//       "Airport Code [ICAO]": "CYJF",
//       "Airport Code iATA,FAA": "YJF",
//       "Airport Name": "Fort Liard Airport",
//       "Altitude (Ft)": 708,
//       "Country": "Canada",
//       "Latitude": -123.46900177,
//       "Location": "Fort Liard",
//       "Longitude": 60.235801696799996,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11759,
//       "Airport Code [ICAO]": "CYKJ",
//       "Airport Code iATA,FAA": "YKJ",
//       "Airport Name": "Key Lake Airport",
//       "Altitude (Ft)": 1679,
//       "Country": "Canada",
//       "Latitude": -105.61799621582031,
//       "Location": "Key Lake",
//       "Longitude": 57.256099700927734,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11760,
//       "Airport Code [ICAO]": "CYLR",
//       "Airport Code iATA,FAA": "YLR",
//       "Airport Name": "Leaf Rapids Airport",
//       "Altitude (Ft)": 959,
//       "Country": "Canada",
//       "Latitude": -99.98529815673828,
//       "Location": "Leaf Rapids",
//       "Longitude": 56.513301849365234,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11761,
//       "Airport Code [ICAO]": "CYME",
//       "Airport Code iATA,FAA": "YME",
//       "Airport Name": "Matane Airport",
//       "Altitude (Ft)": 102,
//       "Country": "Canada",
//       "Latitude": -67.45330047607422,
//       "Location": "Matane",
//       "Longitude": 48.85689926147461,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11762,
//       "Airport Code [ICAO]": "CYML",
//       "Airport Code iATA,FAA": "YML",
//       "Airport Name": "Charlevoix Airport",
//       "Altitude (Ft)": 977,
//       "Country": "Canada",
//       "Latitude": -70.2238998413086,
//       "Location": "Charlevoix",
//       "Longitude": 47.59749984741211,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11763,
//       "Airport Code [ICAO]": "CYNN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nejanilini Lake Airport",
//       "Altitude (Ft)": 1000,
//       "Country": "Canada",
//       "Latitude": -97.78029632570001,
//       "Location": "Nejanilini Lake",
//       "Longitude": 59.4874992371,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11764,
//       "Airport Code [ICAO]": "CYOS",
//       "Airport Code iATA,FAA": "YOS",
//       "Airport Name": "Owen Sound / Billy Bishop Regional Airport",
//       "Altitude (Ft)": 1007,
//       "Country": "Canada",
//       "Latitude": -80.8375015259,
//       "Location": "Owen Sound",
//       "Longitude": 44.5903015137,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11765,
//       "Airport Code [ICAO]": "CYPD",
//       "Airport Code iATA,FAA": "YPS",
//       "Airport Name": "Port Hawkesbury Airport",
//       "Altitude (Ft)": 377,
//       "Country": "Canada",
//       "Latitude": -61.3680992126,
//       "Location": "Port Hawkesbury",
//       "Longitude": 45.6567001343,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11766,
//       "Airport Code [ICAO]": "CYQS",
//       "Airport Code iATA,FAA": "YQS",
//       "Airport Name": "St Thomas Municipal Airport",
//       "Altitude (Ft)": 778,
//       "Country": "Canada",
//       "Latitude": -81.11080169677734,
//       "Location": "St Thomas",
//       "Longitude": 42.77000045776367,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11767,
//       "Airport Code [ICAO]": "CYRO",
//       "Airport Code iATA,FAA": "YRO",
//       "Airport Name": "Ottawa / Rockcliffe Airport",
//       "Altitude (Ft)": 188,
//       "Country": "Canada",
//       "Latitude": -75.64610290530001,
//       "Location": "Ottawa",
//       "Longitude": 45.4603004456,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11768,
//       "Airport Code [ICAO]": "CYRP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ottawa / Carp Airport",
//       "Altitude (Ft)": 382,
//       "Country": "Canada",
//       "Latitude": -76.0222015381,
//       "Location": "Ottawa",
//       "Longitude": 45.3191986084,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11769,
//       "Airport Code [ICAO]": "CYSH",
//       "Airport Code iATA,FAA": "YSH",
//       "Airport Name": "Smiths Falls-Montague (Russ Beach) Airport",
//       "Altitude (Ft)": 416,
//       "Country": "Canada",
//       "Latitude": -75.94059753417969,
//       "Location": "Smiths Falls",
//       "Longitude": 44.94580078125,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11770,
//       "Airport Code [ICAO]": "CYSL",
//       "Airport Code iATA,FAA": "YSL",
//       "Airport Name": "St Leonard Airport",
//       "Altitude (Ft)": 793,
//       "Country": "Canada",
//       "Latitude": -67.83470153808594,
//       "Location": "St Leonard",
//       "Longitude": 47.157501220703125,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11771,
//       "Airport Code [ICAO]": "CYVD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Virden/R.J. (Bob) Andrew Field Regional Aerodrome",
//       "Altitude (Ft)": 1454,
//       "Country": "Canada",
//       "Latitude": -100.917999268,
//       "Location": "Virden",
//       "Longitude": 49.8782997131,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11772,
//       "Airport Code [ICAO]": "CYVK",
//       "Airport Code iATA,FAA": "YVE",
//       "Airport Name": "Vernon Airport",
//       "Altitude (Ft)": 1140,
//       "Country": "Canada",
//       "Latitude": -119.33100128173828,
//       "Location": "Vernon",
//       "Longitude": 50.24810028076172,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11773,
//       "Airport Code [ICAO]": "CYXQ",
//       "Airport Code iATA,FAA": "YXQ",
//       "Airport Name": "Beaver Creek Airport",
//       "Altitude (Ft)": 2131,
//       "Country": "Canada",
//       "Latitude": -140.86700439453125,
//       "Location": "Beaver Creek",
//       "Longitude": 62.410301208496094,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11774,
//       "Airport Code [ICAO]": "CZAM",
//       "Airport Code iATA,FAA": "YSN",
//       "Airport Name": "Shuswap Regional Airport",
//       "Altitude (Ft)": 1751,
//       "Country": "Canada",
//       "Latitude": -119.228996,
//       "Location": "Salmon Arm",
//       "Longitude": 50.682802,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11775,
//       "Airport Code [ICAO]": "CZEE",
//       "Airport Code iATA,FAA": "KES",
//       "Airport Name": "Kelsey Airport",
//       "Altitude (Ft)": 600,
//       "Country": "Canada",
//       "Latitude": -96.50969696039999,
//       "Location": "Kelsey",
//       "Longitude": 56.0374984741,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11776,
//       "Airport Code [ICAO]": "CZFG",
//       "Airport Code iATA,FAA": "XPK",
//       "Airport Name": "Pukatawagan Airport",
//       "Altitude (Ft)": 958,
//       "Country": "Canada",
//       "Latitude": -101.26599884,
//       "Location": "Pukatawagan",
//       "Longitude": 55.7491989136,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11777,
//       "Airport Code [ICAO]": "CZGF",
//       "Airport Code iATA,FAA": "ZGF",
//       "Airport Name": "Grand Forks Airport",
//       "Altitude (Ft)": 1720,
//       "Country": "Canada",
//       "Latitude": -118.43099975585938,
//       "Location": "Grand Forks",
//       "Longitude": 49.01559829711914,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11778,
//       "Airport Code [ICAO]": "CZJG",
//       "Airport Code iATA,FAA": "ZJG",
//       "Airport Name": "Jenpeg Airport",
//       "Altitude (Ft)": 729,
//       "Country": "Canada",
//       "Latitude": -98.04609680175781,
//       "Location": "Jenpeg",
//       "Longitude": 54.51890182495117,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11779,
//       "Airport Code [ICAO]": "CZLQ",
//       "Airport Code iATA,FAA": "YTD",
//       "Airport Name": "Thicket Portage Airport",
//       "Altitude (Ft)": 678,
//       "Country": "Canada",
//       "Latitude": -97.70780181884766,
//       "Location": "Thicket Portage",
//       "Longitude": 55.31890106201172,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11780,
//       "Airport Code [ICAO]": "CZMN",
//       "Airport Code iATA,FAA": "PIW",
//       "Airport Name": "Pikwitonei Airport",
//       "Altitude (Ft)": 630,
//       "Country": "Canada",
//       "Latitude": -97.16419982910156,
//       "Location": "Pikwitonei",
//       "Longitude": 55.58890151977539,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11781,
//       "Airport Code [ICAO]": "CZNG",
//       "Airport Code iATA,FAA": "XPP",
//       "Airport Name": "Poplar River Airport",
//       "Altitude (Ft)": 728,
//       "Country": "Canada",
//       "Latitude": -97.2741937637,
//       "Location": "Poplar River",
//       "Longitude": 52.9965258788,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11782,
//       "Airport Code [ICAO]": "CZPC",
//       "Airport Code iATA,FAA": "WPC",
//       "Airport Name": "Pincher Creek Airport",
//       "Altitude (Ft)": 3903,
//       "Country": "Canada",
//       "Latitude": -113.997001648,
//       "Location": "Pincher Creek",
//       "Longitude": 49.520599365200006,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11783,
//       "Airport Code [ICAO]": "CZST",
//       "Airport Code iATA,FAA": "ZST",
//       "Airport Name": "Stewart Airport",
//       "Altitude (Ft)": 24,
//       "Country": "Canada",
//       "Latitude": -129.982434511,
//       "Location": "Stewart",
//       "Longitude": 55.935410448,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11784,
//       "Airport Code [ICAO]": "CZUC",
//       "Airport Code iATA,FAA": "ZUC",
//       "Airport Name": "Ignace Municipal Airport",
//       "Altitude (Ft)": 1435,
//       "Country": "Canada",
//       "Latitude": -91.7177963256836,
//       "Location": "Ignace",
//       "Longitude": 49.4296989440918,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11785,
//       "Airport Code [ICAO]": "EDBN",
//       "Airport Code iATA,FAA": "FNB",
//       "Airport Name": "Neubrandenburg Airport",
//       "Altitude (Ft)": 228,
//       "Country": "Germany",
//       "Latitude": 13.306,
//       "Location": "Neubrandenburg",
//       "Longitude": 53.6022,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11786,
//       "Airport Code [ICAO]": "EDQA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Airport Bamberg-Breitenau",
//       "Altitude (Ft)": 811,
//       "Country": "Germany",
//       "Latitude": 10.914233,
//       "Location": "Bamberg",
//       "Longitude": 49.920403,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11787,
//       "Airport Code [ICAO]": "EDUZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Zerbst Airport",
//       "Altitude (Ft)": 289,
//       "Country": "Germany",
//       "Latitude": 12.148611,
//       "Location": "Zerbst/Anhalt",
//       "Longitude": 52.000832,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11788,
//       "Airport Code [ICAO]": "EGOM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "RAF Spadeadam",
//       "Altitude (Ft)": 1066,
//       "Country": "United Kingdom",
//       "Latitude": -2.54999995232,
//       "Location": "Spadeadam",
//       "Longitude": 55.0499992371,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11789,
//       "Airport Code [ICAO]": "EGQK",
//       "Airport Code iATA,FAA": "FSS",
//       "Airport Name": "RAF Kinloss",
//       "Altitude (Ft)": 22,
//       "Country": "United Kingdom",
//       "Latitude": -3.56064009666,
//       "Location": "Kinloss",
//       "Longitude": 57.6493988037,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11790,
//       "Airport Code [ICAO]": "EPBP",
//       "Airport Code iATA,FAA": "BXP",
//       "Airport Name": "Biała Podlaska Airfield",
//       "Altitude (Ft)": 485,
//       "Country": "Poland",
//       "Latitude": 23.1325278,
//       "Location": "Biała Podlaska",
//       "Longitude": 52.00078,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11791,
//       "Airport Code [ICAO]": "EPCE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cewice Air Base",
//       "Altitude (Ft)": 495,
//       "Country": "Poland",
//       "Latitude": 17.7633,
//       "Location": "Lębork",
//       "Longitude": 54.416,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11792,
//       "Airport Code [ICAO]": "EPDE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Deblin Military Air Base",
//       "Altitude (Ft)": 392,
//       "Country": "Poland",
//       "Latitude": 21.8936004639,
//       "Location": "Dęblin",
//       "Longitude": 51.551399231,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11793,
//       "Airport Code [ICAO]": "EPLY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Leczyca Military Air Base",
//       "Altitude (Ft)": 377,
//       "Country": "Poland",
//       "Latitude": 19.1455993652,
//       "Location": "Leczyca",
//       "Longitude": 52.004699707,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11794,
//       "Airport Code [ICAO]": "EPMM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Minsk Mazowiecki Military Air Base",
//       "Altitude (Ft)": 604,
//       "Country": "Poland",
//       "Latitude": 21.6558990479,
//       "Location": "",
//       "Longitude": 52.1954994202,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11795,
//       "Airport Code [ICAO]": "EPPW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Powidz Military Air Base",
//       "Altitude (Ft)": 371,
//       "Country": "Poland",
//       "Latitude": 17.853901,
//       "Location": "",
//       "Longitude": 52.379398,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11796,
//       "Airport Code [ICAO]": "EPTM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tomaszow Mazowiecki Military Air Base",
//       "Altitude (Ft)": 571,
//       "Country": "Poland",
//       "Latitude": 20.0977993011,
//       "Location": "Tomaszów Mazowiecki",
//       "Longitude": 51.584400177,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11797,
//       "Airport Code [ICAO]": "ESKX",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Björkvik Air Base",
//       "Altitude (Ft)": 0,
//       "Country": "Sweden",
//       "Latitude": 16.571199417114258,
//       "Location": "Björkvik",
//       "Longitude": 58.79079818725586,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11798,
//       "Airport Code [ICAO]": "ESTL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ljungbyhed Airport",
//       "Altitude (Ft)": 140,
//       "Country": "Sweden",
//       "Latitude": 13.212499618530273,
//       "Location": "Ljungbyhed",
//       "Longitude": 56.082801818847656,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11799,
//       "Airport Code [ICAO]": "EVDA",
//       "Airport Code iATA,FAA": "DGP",
//       "Airport Name": "Daugavpils Intrenational Airport",
//       "Altitude (Ft)": 398,
//       "Country": "Latvia",
//       "Latitude": 26.6650009155,
//       "Location": "Daugavpils",
//       "Longitude": 55.944721221900004,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11800,
//       "Airport Code [ICAO]": "EVKA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Jēkabpils Air Base",
//       "Altitude (Ft)": 289,
//       "Country": "Latvia",
//       "Latitude": 25.8925,
//       "Location": "Jēkabpils",
//       "Longitude": 56.534698,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11801,
//       "Airport Code [ICAO]": "EVTA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Jūrmala Airport",
//       "Altitude (Ft)": 220,
//       "Country": "Latvia",
//       "Latitude": 23.2239,
//       "Location": "Tukums",
//       "Longitude": 56.9422,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11802,
//       "Airport Code [ICAO]": "EYKD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kėdainiai Air Base",
//       "Altitude (Ft)": 171,
//       "Country": "Lithuania",
//       "Latitude": 23.95639991760254,
//       "Location": "Kėdainiai",
//       "Longitude": 55.31169891357422,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11803,
//       "Airport Code [ICAO]": "FALC",
//       "Airport Code iATA,FAA": "LMR",
//       "Airport Name": "Lime Acres Finsch Mine Airport",
//       "Altitude (Ft)": 4900,
//       "Country": "South Africa",
//       "Latitude": 23.43910026550293,
//       "Location": "Lime Acres",
//       "Longitude": -28.36009979248047,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11804,
//       "Airport Code [ICAO]": "FBSN",
//       "Airport Code iATA,FAA": "SXN",
//       "Airport Name": "Sua Pan Airport",
//       "Altitude (Ft)": 2985,
//       "Country": "Botswana",
//       "Latitude": 26.115801,
//       "Location": "Sowa",
//       "Longitude": -20.5534,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11805,
//       "Airport Code [ICAO]": "FLLC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lusaka City Airport",
//       "Altitude (Ft)": 4200,
//       "Country": "Zambia",
//       "Latitude": 28.3306999207,
//       "Location": "Lusaka",
//       "Longitude": -15.4138002396,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11806,
//       "Airport Code [ICAO]": "FNSU",
//       "Airport Code iATA,FAA": "NDD",
//       "Airport Name": "Sumbe Airport",
//       "Altitude (Ft)": 36,
//       "Country": "Angola",
//       "Latitude": 13.84749984741211,
//       "Location": "Sumbe",
//       "Longitude": -11.167900085449219,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11807,
//       "Airport Code [ICAO]": "FWMG",
//       "Airport Code iATA,FAA": "MAI",
//       "Airport Name": "Mangochi Airport",
//       "Altitude (Ft)": 1580,
//       "Country": "Malawi",
//       "Latitude": 35.266998291015625,
//       "Location": "Mangochi",
//       "Longitude": -14.482999801635742,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11808,
//       "Airport Code [ICAO]": "FYAR",
//       "Airport Code iATA,FAA": "ADI",
//       "Airport Name": "Arandis Airport",
//       "Altitude (Ft)": 1905,
//       "Country": "Namibia",
//       "Latitude": 14.979999542236328,
//       "Location": "Arandis",
//       "Longitude": -22.462200164794922,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11809,
//       "Airport Code [ICAO]": "FYML",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mariental Airport",
//       "Altitude (Ft)": 3650,
//       "Country": "Namibia",
//       "Latitude": 17.925399780273438,
//       "Location": "Mariental",
//       "Longitude": -24.60540008544922,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11810,
//       "Airport Code [ICAO]": "HEAZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Almaza Air Force Base",
//       "Altitude (Ft)": 300,
//       "Country": "Egypt",
//       "Latitude": 31.360000610351562,
//       "Location": "Cairo",
//       "Longitude": 30.091800689697266,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11811,
//       "Airport Code [ICAO]": "HEBS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Beni Suef Air Base",
//       "Altitude (Ft)": 108,
//       "Country": "Egypt",
//       "Latitude": 31.016599655151367,
//       "Location": "Beni Suef",
//       "Longitude": 29.20829963684082,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11812,
//       "Airport Code [ICAO]": "HEGS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Jiyanklis Air Base",
//       "Altitude (Ft)": 49,
//       "Country": "Egypt",
//       "Latitude": 30.191200256347656,
//       "Location": "Jiyanklis",
//       "Longitude": 30.819799423217773,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11813,
//       "Airport Code [ICAO]": "HSMN",
//       "Airport Code iATA,FAA": "MWE",
//       "Airport Name": "Merowe New Airport",
//       "Altitude (Ft)": 897,
//       "Country": "Sudan",
//       "Latitude": 31.8433333333,
//       "Location": "Merowe",
//       "Longitude": 18.4433333333,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11814,
//       "Airport Code [ICAO]": "KALN",
//       "Airport Code iATA,FAA": "ALN",
//       "Airport Name": "St Louis Regional Airport",
//       "Altitude (Ft)": 544,
//       "Country": "United States",
//       "Latitude": -90.0459976196,
//       "Location": "Alton/St Louis",
//       "Longitude": 38.89030075069999,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11815,
//       "Airport Code [ICAO]": "KAXN",
//       "Airport Code iATA,FAA": "AXN",
//       "Airport Name": "Chandler Field",
//       "Altitude (Ft)": 1425,
//       "Country": "United States",
//       "Latitude": -95.39469909670001,
//       "Location": "Alexandria",
//       "Longitude": 45.8662986755,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11816,
//       "Airport Code [ICAO]": "KBAK",
//       "Airport Code iATA,FAA": "CLU",
//       "Airport Name": "Columbus Municipal Airport",
//       "Altitude (Ft)": 656,
//       "Country": "United States",
//       "Latitude": -85.8963012695,
//       "Location": "Columbus",
//       "Longitude": 39.2619018555,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11817,
//       "Airport Code [ICAO]": "KBBD",
//       "Airport Code iATA,FAA": "BBD",
//       "Airport Name": "Curtis Field",
//       "Altitude (Ft)": 1827,
//       "Country": "United States",
//       "Latitude": -99.3238983154,
//       "Location": "Brady",
//       "Longitude": 31.1793003082,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11818,
//       "Airport Code [ICAO]": "KBIH",
//       "Airport Code iATA,FAA": "BIH",
//       "Airport Name": "Eastern Sierra Regional Airport",
//       "Altitude (Ft)": 4124,
//       "Country": "United States",
//       "Latitude": -118.363998413,
//       "Location": "Bishop",
//       "Longitude": 37.3731002808,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11819,
//       "Airport Code [ICAO]": "KBKE",
//       "Airport Code iATA,FAA": "BKE",
//       "Airport Name": "Baker City Municipal Airport",
//       "Altitude (Ft)": 3373,
//       "Country": "United States",
//       "Latitude": -117.808998108,
//       "Location": "Baker City",
//       "Longitude": 44.837299346900004,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11820,
//       "Airport Code [ICAO]": "KBPI",
//       "Airport Code iATA,FAA": "BPI",
//       "Airport Name": "Miley Memorial Field",
//       "Altitude (Ft)": 6990,
//       "Country": "United States",
//       "Latitude": -110.1110001,
//       "Location": "Big Piney",
//       "Longitude": 42.58509827,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11821,
//       "Airport Code [ICAO]": "KBPK",
//       "Airport Code iATA,FAA": "WMH",
//       "Airport Name": "Ozark Regional Airport",
//       "Altitude (Ft)": 928,
//       "Country": "United States",
//       "Latitude": -92.47049713130001,
//       "Location": "Mountain Home",
//       "Longitude": 36.3689002991,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11822,
//       "Airport Code [ICAO]": "KBTL",
//       "Airport Code iATA,FAA": "BTL",
//       "Airport Name": "W K Kellogg Airport",
//       "Altitude (Ft)": 952,
//       "Country": "United States",
//       "Latitude": -85.2515029907,
//       "Location": "Battle Creek",
//       "Longitude": 42.307300567599995,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11823,
//       "Airport Code [ICAO]": "KBYI",
//       "Airport Code iATA,FAA": "BYI",
//       "Airport Name": "Burley Municipal Airport",
//       "Altitude (Ft)": 4150,
//       "Country": "United States",
//       "Latitude": -113.772003174,
//       "Location": "Burley",
//       "Longitude": 42.542598724399994,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11824,
//       "Airport Code [ICAO]": "KCCY",
//       "Airport Code iATA,FAA": "CCY",
//       "Airport Name": "Northeast Iowa Regional Airport",
//       "Altitude (Ft)": 1125,
//       "Country": "United States",
//       "Latitude": -92.6108016968,
//       "Location": "Charles City",
//       "Longitude": 43.0726013184,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11825,
//       "Airport Code [ICAO]": "KCNU",
//       "Airport Code iATA,FAA": "CNU",
//       "Airport Name": "Chanute Martin Johnson Airport",
//       "Altitude (Ft)": 1002,
//       "Country": "United States",
//       "Latitude": -95.4850997925,
//       "Location": "Chanute",
//       "Longitude": 37.668800354,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11826,
//       "Airport Code [ICAO]": "KCRG",
//       "Airport Code iATA,FAA": "CRG",
//       "Airport Name": "Jacksonville Executive at Craig Airport",
//       "Altitude (Ft)": 41,
//       "Country": "United States",
//       "Latitude": -81.51439666750001,
//       "Location": "Jacksonville",
//       "Longitude": 30.3362998962,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11827,
//       "Airport Code [ICAO]": "KCSV",
//       "Airport Code iATA,FAA": "CSV",
//       "Airport Name": "Crossville Memorial Whitson Field",
//       "Altitude (Ft)": 1881,
//       "Country": "United States",
//       "Latitude": -85.08499908450001,
//       "Location": "Crossville",
//       "Longitude": 35.9513015747,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11828,
//       "Airport Code [ICAO]": "KDAA",
//       "Airport Code iATA,FAA": "DAA",
//       "Airport Name": "Davison Army Air Field",
//       "Altitude (Ft)": 73,
//       "Country": "United States",
//       "Latitude": -77.1809997559,
//       "Location": "Fort Belvoir",
//       "Longitude": 38.715000152600005,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11829,
//       "Airport Code [ICAO]": "KDAG",
//       "Airport Code iATA,FAA": "DAG",
//       "Airport Name": "Barstow Daggett Airport",
//       "Altitude (Ft)": 1930,
//       "Country": "United States",
//       "Latitude": -116.7870026,
//       "Location": "Daggett",
//       "Longitude": 34.85369873,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11830,
//       "Airport Code [ICAO]": "KDMN",
//       "Airport Code iATA,FAA": "DMN",
//       "Airport Name": "Deming Municipal Airport",
//       "Altitude (Ft)": 4314,
//       "Country": "United States",
//       "Latitude": -107.721000671,
//       "Location": "Deming",
//       "Longitude": 32.262298584,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11831,
//       "Airport Code [ICAO]": "KDRA",
//       "Airport Code iATA,FAA": "DRA",
//       "Airport Name": "Desert Rock Airport",
//       "Altitude (Ft)": 3314,
//       "Country": "United States",
//       "Latitude": -116.032997,
//       "Location": "Mercury",
//       "Longitude": 36.6194,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11832,
//       "Airport Code [ICAO]": "KEED",
//       "Airport Code iATA,FAA": "EED",
//       "Airport Name": "Needles Airport",
//       "Altitude (Ft)": 983,
//       "Country": "United States",
//       "Latitude": -114.623001099,
//       "Location": "Needles",
//       "Longitude": 34.7663002014,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11833,
//       "Airport Code [ICAO]": "KEGI",
//       "Airport Code iATA,FAA": "EGI",
//       "Airport Name": "Duke Field",
//       "Altitude (Ft)": 191,
//       "Country": "United States",
//       "Latitude": -86.52290344,
//       "Location": "Crestview",
//       "Longitude": 30.65040016,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11834,
//       "Airport Code [ICAO]": "KEKA",
//       "Airport Code iATA,FAA": "EKA",
//       "Airport Name": "Murray Field",
//       "Altitude (Ft)": 7,
//       "Country": "United States",
//       "Latitude": -124.112998962,
//       "Location": "Eureka",
//       "Longitude": 40.803398132299996,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11835,
//       "Airport Code [ICAO]": "KHYI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "San Marcos Regional Airport",
//       "Altitude (Ft)": 597,
//       "Country": "United States",
//       "Latitude": -97.862999,
//       "Location": "San Marcos",
//       "Longitude": 29.8927,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11836,
//       "Airport Code [ICAO]": "KHYR",
//       "Airport Code iATA,FAA": "HYR",
//       "Airport Name": "Sawyer County Airport",
//       "Altitude (Ft)": 1216,
//       "Country": "United States",
//       "Latitude": -91.44429779050002,
//       "Location": "Hayward",
//       "Longitude": 46.025199890100005,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11837,
//       "Airport Code [ICAO]": "KJCT",
//       "Airport Code iATA,FAA": "JCT",
//       "Airport Name": "Kimble County Airport",
//       "Altitude (Ft)": 1749,
//       "Country": "United States",
//       "Latitude": -99.7634963989,
//       "Location": "Junction",
//       "Longitude": 30.5112991333,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11838,
//       "Airport Code [ICAO]": "KLLQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Monticello Municipal Ellis Field",
//       "Altitude (Ft)": 270,
//       "Country": "United States",
//       "Latitude": -91.75099945,
//       "Location": "Monticello",
//       "Longitude": 33.6385994,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11839,
//       "Airport Code [ICAO]": "KLOL",
//       "Airport Code iATA,FAA": "LOL",
//       "Airport Name": "Derby Field",
//       "Altitude (Ft)": 3904,
//       "Country": "United States",
//       "Latitude": -118.565002441,
//       "Location": "Lovelock",
//       "Longitude": 40.0663986206,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11840,
//       "Airport Code [ICAO]": "KMBG",
//       "Airport Code iATA,FAA": "MBG",
//       "Airport Name": "Mobridge Municipal Airport",
//       "Altitude (Ft)": 1716,
//       "Country": "United States",
//       "Latitude": -100.4079971,
//       "Location": "Mobridge",
//       "Longitude": 45.54650116,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11841,
//       "Airport Code [ICAO]": "KMCB",
//       "Airport Code iATA,FAA": "MCB",
//       "Airport Name": "Mc Comb/Pike County Airport/John E Lewis Field",
//       "Altitude (Ft)": 413,
//       "Country": "United States",
//       "Latitude": -90.47190094,
//       "Location": "Mc Comb",
//       "Longitude": 31.17849922,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11842,
//       "Airport Code [ICAO]": "KMDH",
//       "Airport Code iATA,FAA": "MDH",
//       "Airport Name": "Southern Illinois Airport",
//       "Altitude (Ft)": 411,
//       "Country": "United States",
//       "Latitude": -89.25199890136719,
//       "Location": "Carbondale/Murphysboro",
//       "Longitude": 37.778099060058594,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11843,
//       "Airport Code [ICAO]": "KMMT",
//       "Airport Code iATA,FAA": "MMT",
//       "Airport Name": "Mc Entire Joint National Guard Base",
//       "Altitude (Ft)": 254,
//       "Country": "United States",
//       "Latitude": -80.80130005,
//       "Location": "Eastover",
//       "Longitude": 33.92079926,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11844,
//       "Airport Code [ICAO]": "KNHZ",
//       "Airport Code iATA,FAA": "NHZ",
//       "Airport Name": "Brunswick Executive Airport",
//       "Altitude (Ft)": 72,
//       "Country": "United States",
//       "Latitude": -69.93859863,
//       "Location": "Brunswick",
//       "Longitude": 43.89220047,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11845,
//       "Airport Code [ICAO]": "KNRB",
//       "Airport Code iATA,FAA": "NRB",
//       "Airport Name": "Naval Station Mayport (Admiral David L. Mcdonald Field)",
//       "Altitude (Ft)": 15,
//       "Country": "United States",
//       "Latitude": -81.42469788,
//       "Location": "Mayport",
//       "Longitude": 30.39109993,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11846,
//       "Airport Code [ICAO]": "KOGB",
//       "Airport Code iATA,FAA": "OGB",
//       "Airport Name": "Orangeburg Municipal Airport",
//       "Altitude (Ft)": 195,
//       "Country": "United States",
//       "Latitude": -80.859497070312,
//       "Location": "Orangeburg",
//       "Longitude": 33.456798553467,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11847,
//       "Airport Code [ICAO]": "KOLU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Columbus Municipal Airport",
//       "Altitude (Ft)": 1447,
//       "Country": "United States",
//       "Latitude": -97.34259796,
//       "Location": "Columbus",
//       "Longitude": 41.44800186,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11848,
//       "Airport Code [ICAO]": "KOTM",
//       "Airport Code iATA,FAA": "OTM",
//       "Airport Name": "Ottumwa Regional Airport",
//       "Altitude (Ft)": 845,
//       "Country": "United States",
//       "Latitude": -92.44789886,
//       "Location": "Ottumwa",
//       "Longitude": 41.10660172,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11849,
//       "Airport Code [ICAO]": "KOZR",
//       "Airport Code iATA,FAA": "OZR",
//       "Airport Name": "Cairns AAF (Fort Rucker) Air Field",
//       "Altitude (Ft)": 301,
//       "Country": "United States",
//       "Latitude": -85.71340179,
//       "Location": "Fort Rucker/Ozark",
//       "Longitude": 31.27569962,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11850,
//       "Airport Code [ICAO]": "KPNA",
//       "Airport Code iATA,FAA": "PWY",
//       "Airport Name": "Ralph Wenz Field",
//       "Altitude (Ft)": 7102,
//       "Country": "United States",
//       "Latitude": -109.8069992,
//       "Location": "Pinedale",
//       "Longitude": 42.79550171,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11851,
//       "Airport Code [ICAO]": "KPOU",
//       "Airport Code iATA,FAA": "POU",
//       "Airport Name": "Dutchess County Airport",
//       "Altitude (Ft)": 165,
//       "Country": "United States",
//       "Latitude": -73.88420104980469,
//       "Location": "Poughkeepsie",
//       "Longitude": 41.6265983581543,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11852,
//       "Airport Code [ICAO]": "KRNH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "New Richmond Regional Airport",
//       "Altitude (Ft)": 998,
//       "Country": "United States",
//       "Latitude": -92.5381012,
//       "Location": "New Richmond",
//       "Longitude": 45.14830017,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11853,
//       "Airport Code [ICAO]": "KRSL",
//       "Airport Code iATA,FAA": "RSL",
//       "Airport Name": "Russell Municipal Airport",
//       "Altitude (Ft)": 1862,
//       "Country": "United States",
//       "Latitude": -98.811798095703,
//       "Location": "Russell",
//       "Longitude": 38.872100830078,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11854,
//       "Airport Code [ICAO]": "KRWF",
//       "Airport Code iATA,FAA": "RWF",
//       "Airport Name": "Redwood Falls Municipal Airport",
//       "Altitude (Ft)": 1024,
//       "Country": "United States",
//       "Latitude": -95.08229828,
//       "Location": "Redwood Falls",
//       "Longitude": 44.54719925,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11855,
//       "Airport Code [ICAO]": "KSNS",
//       "Airport Code iATA,FAA": "SNS",
//       "Airport Name": "Salinas Municipal Airport",
//       "Altitude (Ft)": 85,
//       "Country": "United States",
//       "Latitude": -121.60600280762,
//       "Location": "Salinas",
//       "Longitude": 36.662799835205,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11856,
//       "Airport Code [ICAO]": "KSOA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sonora Municipal Airport",
//       "Altitude (Ft)": 2140,
//       "Country": "United States",
//       "Latitude": -100.6490020752,
//       "Location": "Sonora",
//       "Longitude": 30.585699081421,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11857,
//       "Airport Code [ICAO]": "KSUZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Saline County Regional Airport",
//       "Altitude (Ft)": 390,
//       "Country": "United States",
//       "Latitude": -92.47940063,
//       "Location": "Benton",
//       "Longitude": 34.59059906,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11858,
//       "Airport Code [ICAO]": "KTPH",
//       "Airport Code iATA,FAA": "TPH",
//       "Airport Name": "Tonopah Airport",
//       "Altitude (Ft)": 5430,
//       "Country": "United States",
//       "Latitude": -117.086998,
//       "Location": "Tonopah",
//       "Longitude": 38.06019974,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11859,
//       "Airport Code [ICAO]": "KUAO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Aurora State Airport",
//       "Altitude (Ft)": 200,
//       "Country": "United States",
//       "Latitude": -122.7699966430664,
//       "Location": "Aurora",
//       "Longitude": 45.247100830078125,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11860,
//       "Airport Code [ICAO]": "KUKI",
//       "Airport Code iATA,FAA": "UKI",
//       "Airport Name": "Ukiah Municipal Airport",
//       "Altitude (Ft)": 614,
//       "Country": "United States",
//       "Latitude": -123.20099639893,
//       "Location": "Ukiah",
//       "Longitude": 39.125999450684,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11861,
//       "Airport Code [ICAO]": "KUOX",
//       "Airport Code iATA,FAA": "UOX",
//       "Airport Name": "University Oxford Airport",
//       "Altitude (Ft)": 452,
//       "Country": "United States",
//       "Latitude": -89.536796569824,
//       "Location": "Oxford",
//       "Longitude": 34.384300231934,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11862,
//       "Airport Code [ICAO]": "KUTS",
//       "Airport Code iATA,FAA": "HTV",
//       "Airport Name": "Huntsville Regional Airport",
//       "Altitude (Ft)": 363,
//       "Country": "United States",
//       "Latitude": -95.5871963501,
//       "Location": "Huntsville",
//       "Longitude": 30.7469005585,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11863,
//       "Airport Code [ICAO]": "KVTN",
//       "Airport Code iATA,FAA": "VTN",
//       "Airport Name": "Miller Field",
//       "Altitude (Ft)": 2596,
//       "Country": "United States",
//       "Latitude": -100.5479965,
//       "Location": "Valentine",
//       "Longitude": 42.85779953,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11864,
//       "Airport Code [ICAO]": "KWMC",
//       "Airport Code iATA,FAA": "WMC",
//       "Airport Name": "Winnemucca Municipal Airport",
//       "Altitude (Ft)": 4308,
//       "Country": "United States",
//       "Latitude": -117.805999756,
//       "Location": "Winnemucca",
//       "Longitude": 40.8965988159,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11865,
//       "Airport Code [ICAO]": "KWWR",
//       "Airport Code iATA,FAA": "WWR",
//       "Airport Name": "West Woodward Airport",
//       "Altitude (Ft)": 2189,
//       "Country": "United States",
//       "Latitude": -99.5226667,
//       "Location": "Woodward",
//       "Longitude": 36.438,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11866,
//       "Airport Code [ICAO]": "KXMR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cape Canaveral AFS Skid Strip",
//       "Altitude (Ft)": 10,
//       "Country": "United States",
//       "Latitude": -80.56659698490002,
//       "Location": "Cocoa Beach",
//       "Longitude": 28.4675998688,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11867,
//       "Airport Code [ICAO]": "KXTA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Homey (Area 51) Airport",
//       "Altitude (Ft)": 4462,
//       "Country": "United States",
//       "Latitude": -115.81099700927734,
//       "Location": "Groom Lake",
//       "Longitude": 37.23500061035156,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11868,
//       "Airport Code [ICAO]": "KZZV",
//       "Airport Code iATA,FAA": "ZZV",
//       "Airport Name": "Zanesville Municipal Airport",
//       "Altitude (Ft)": 900,
//       "Country": "United States",
//       "Latitude": -81.89209747310001,
//       "Location": "Zanesville",
//       "Longitude": 39.9444007874,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11869,
//       "Airport Code [ICAO]": "LAGJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Gjadër Air Base",
//       "Altitude (Ft)": 23,
//       "Country": "Albania",
//       "Latitude": 19.5987,
//       "Location": "Gjadër",
//       "Longitude": 41.895199,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11870,
//       "Airport Code [ICAO]": "LAKU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kukës Airport",
//       "Altitude (Ft)": 1120,
//       "Country": "Albania",
//       "Latitude": 20.4158992767,
//       "Location": "Kukës",
//       "Longitude": 42.033699035599994,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11871,
//       "Airport Code [ICAO]": "LAKV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kuçovë Air Base",
//       "Altitude (Ft)": 135,
//       "Country": "Albania",
//       "Latitude": 19.901899337768555,
//       "Location": "Kuçovë",
//       "Longitude": 40.77190017700195,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11872,
//       "Airport Code [ICAO]": "LAVL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Vlorë Air Base",
//       "Altitude (Ft)": 3,
//       "Country": "Albania",
//       "Latitude": 19.474199295043945,
//       "Location": "Vlorë",
//       "Longitude": 40.47610092163086,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11873,
//       "Airport Code [ICAO]": "LBHS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Haskovo Malevo Airport",
//       "Altitude (Ft)": 160,
//       "Country": "Bulgaria",
//       "Latitude": 25.5898,
//       "Location": "Haskovo",
//       "Longitude": 41.976398,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11874,
//       "Airport Code [ICAO]": "LBMG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Gabrovnitsa Air Base",
//       "Altitude (Ft)": 626,
//       "Country": "Bulgaria",
//       "Latitude": 23.272499084472656,
//       "Location": "Gabrovnitsa",
//       "Longitude": 43.5443000793457,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11875,
//       "Airport Code [ICAO]": "LBPG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Graf Ignatievo Air Base",
//       "Altitude (Ft)": 190,
//       "Country": "Bulgaria",
//       "Latitude": 24.714000701904297,
//       "Location": "Graf Ignatievo",
//       "Longitude": 42.290401458740234,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11876,
//       "Airport Code [ICAO]": "LBPL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Dolna Mitropoliya Air Base",
//       "Altitude (Ft)": 330,
//       "Country": "Bulgaria",
//       "Latitude": 24.5028,
//       "Location": "Dolna Mitropoliya",
//       "Longitude": 43.451401,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11877,
//       "Airport Code [ICAO]": "LBWB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Balchik Air Base",
//       "Altitude (Ft)": 660,
//       "Country": "Bulgaria",
//       "Latitude": 28.181299209594727,
//       "Location": "Balchik",
//       "Longitude": 43.42380142211914,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11878,
//       "Airport Code [ICAO]": "LECU",
//       "Airport Code iATA,FAA": "ECV",
//       "Airport Name": "Cuatro Vientos Airport",
//       "Altitude (Ft)": 2269,
//       "Country": "Spain",
//       "Latitude": -3.78514,
//       "Location": "Madrid",
//       "Longitude": 40.370701,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11879,
//       "Airport Code [ICAO]": "LEDS",
//       "Airport Code iATA,FAA": "CDT",
//       "Airport Name": "Castellón-Costa Azahar Airport",
//       "Altitude (Ft)": 1145,
//       "Country": "Spain",
//       "Latitude": 0.073333,
//       "Location": "Castellón de la Plana",
//       "Longitude": 40.213889,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11880,
//       "Airport Code [ICAO]": "LETL",
//       "Airport Code iATA,FAA": "TEV",
//       "Airport Name": "Teruel Airport",
//       "Altitude (Ft)": 3380,
//       "Country": "Spain",
//       "Latitude": -1.2183,
//       "Location": "Teruel",
//       "Longitude": 40.403,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11881,
//       "Airport Code [ICAO]": "LFOQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Blois-Le Breuil Airport",
//       "Altitude (Ft)": 398,
//       "Country": "France",
//       "Latitude": 1.20884,
//       "Location": "Blois/Le Breuil",
//       "Longitude": 47.678502,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11882,
//       "Airport Code [ICAO]": "LIDT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Trento-Mattarello Airport",
//       "Altitude (Ft)": 610,
//       "Country": "Italy",
//       "Latitude": 11.1242,
//       "Location": "Trento (TN)",
//       "Longitude": 46.0214,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11883,
//       "Airport Code [ICAO]": "LILA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Alessandria Airport",
//       "Altitude (Ft)": 299,
//       "Country": "Italy",
//       "Latitude": 8.62513,
//       "Location": "Alessandria",
//       "Longitude": 44.925201,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11884,
//       "Airport Code [ICAO]": "LILE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Biella-Cerrione Airport",
//       "Altitude (Ft)": 920,
//       "Country": "Italy",
//       "Latitude": 8.10278,
//       "Location": "Biella (BI)",
//       "Longitude": 45.4953,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11885,
//       "Airport Code [ICAO]": "LILI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Vercelli Airport",
//       "Altitude (Ft)": 417,
//       "Country": "Italy",
//       "Latitude": 8.417935,
//       "Location": "Vercelli",
//       "Longitude": 45.310233,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11886,
//       "Airport Code [ICAO]": "LILM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Casale Monferrato Airport",
//       "Altitude (Ft)": 377,
//       "Country": "Italy",
//       "Latitude": 8.45603,
//       "Location": "Casale Monferrato",
//       "Longitude": 45.111198,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11887,
//       "Airport Code [ICAO]": "LILN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Varese-Venegono Airport",
//       "Altitude (Ft)": 1050,
//       "Country": "Italy",
//       "Latitude": 8.888233,
//       "Location": "Varese",
//       "Longitude": 45.742199,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11888,
//       "Airport Code [ICAO]": "LIMR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Novi Ligure Airport",
//       "Altitude (Ft)": 607,
//       "Country": "Italy",
//       "Latitude": 8.78639,
//       "Location": "Novi Ligure",
//       "Longitude": 44.779999,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11889,
//       "Airport Code [ICAO]": "LIQW",
//       "Airport Code iATA,FAA": "QLP",
//       "Airport Name": "Sarzana-Luni Air Base",
//       "Altitude (Ft)": 45,
//       "Country": "Italy",
//       "Latitude": 9.98795,
//       "Location": "Sarzana (SP)",
//       "Longitude": 44.088001,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11890,
//       "Airport Code [ICAO]": "LRCT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Câmpia Turzii Air Base",
//       "Altitude (Ft)": 1083,
//       "Country": "Romania",
//       "Latitude": 23.885900497436523,
//       "Location": "Câmpia Turzii",
//       "Longitude": 46.50230026245117,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11891,
//       "Airport Code [ICAO]": "LUBM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mărculeşti International Airport",
//       "Altitude (Ft)": 312,
//       "Country": "Moldova",
//       "Latitude": 28.212799072265625,
//       "Location": "Mărculeşti",
//       "Longitude": 47.862701416015625,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11892,
//       "Airport Code [ICAO]": "LUCH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cahul International Airport",
//       "Altitude (Ft)": 652,
//       "Country": "Moldova",
//       "Latitude": 28.263700485199998,
//       "Location": "Cahul",
//       "Longitude": 45.8437995911,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11893,
//       "Airport Code [ICAO]": "LUTR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tiraspol Airport",
//       "Altitude (Ft)": 141,
//       "Country": "Moldova",
//       "Latitude": 29.590599060058594,
//       "Location": "Tiraspol",
//       "Longitude": 46.868099212646484,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11894,
//       "Airport Code [ICAO]": "LYBT",
//       "Airport Code iATA,FAA": "BJY",
//       "Airport Name": "Batajnica Air Base",
//       "Altitude (Ft)": 265,
//       "Country": "Serbia",
//       "Latitude": 20.2575,
//       "Location": "Batajnica",
//       "Longitude": 44.935299,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11895,
//       "Airport Code [ICAO]": "MGRB",
//       "Airport Code iATA,FAA": "RUV",
//       "Airport Name": "Rubelsanto Airport",
//       "Altitude (Ft)": 426,
//       "Country": "Guatemala",
//       "Latitude": -90.44529724121094,
//       "Location": "Rubelsanto",
//       "Longitude": 15.991999626159668,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11896,
//       "Airport Code [ICAO]": "MHSC",
//       "Airport Code iATA,FAA": "XPL",
//       "Airport Name": "Coronel Enrique Soto Cano Air Base",
//       "Altitude (Ft)": 2061,
//       "Country": "Honduras",
//       "Latitude": -87.621201,
//       "Location": "Comayagua",
//       "Longitude": 14.3824,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11897,
//       "Airport Code [ICAO]": "MRUP",
//       "Airport Code iATA,FAA": "UPL",
//       "Airport Name": "Upala Airport",
//       "Altitude (Ft)": 184,
//       "Country": "Costa Rica",
//       "Latitude": -85.016197,
//       "Location": "Upala",
//       "Longitude": 10.8922,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11898,
//       "Airport Code [ICAO]": "MUNB",
//       "Airport Code iATA,FAA": "QSN",
//       "Airport Name": "San Nicolas De Bari Airport",
//       "Altitude (Ft)": 49,
//       "Country": "Cuba",
//       "Latitude": -81.9208984375,
//       "Location": "San Nicolás",
//       "Longitude": 22.756099700927734,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11899,
//       "Airport Code [ICAO]": "MUSJ",
//       "Airport Code iATA,FAA": "SNJ",
//       "Airport Name": "San Julian Air Base",
//       "Altitude (Ft)": 98,
//       "Country": "Cuba",
//       "Latitude": -84.1520004272461,
//       "Location": "Pinar Del Rio",
//       "Longitude": 22.095300674438477,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11900,
//       "Airport Code [ICAO]": "OEDM",
//       "Airport Code iATA,FAA": "DWD",
//       "Airport Name": "King Salman Abdulaziz Airport",
//       "Altitude (Ft)": 3026,
//       "Country": "Saudi Arabia",
//       "Latitude": 44.121201,
//       "Location": "",
//       "Longitude": 24.4499,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11901,
//       "Airport Code [ICAO]": "OEKM",
//       "Airport Code iATA,FAA": "KMX",
//       "Airport Name": "King Khaled Air Base",
//       "Altitude (Ft)": 6778,
//       "Country": "Saudi Arabia",
//       "Latitude": 42.80350112915039,
//       "Location": "",
//       "Longitude": 18.297300338745117,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11902,
//       "Airport Code [ICAO]": "OERY",
//       "Airport Code iATA,FAA": "XXN",
//       "Airport Name": "Riyadh Air Base",
//       "Altitude (Ft)": 2082,
//       "Country": "Saudi Arabia",
//       "Latitude": 46.72520065307617,
//       "Location": "Riyadh",
//       "Longitude": 24.709800720214844,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11903,
//       "Airport Code [ICAO]": "OIBJ",
//       "Airport Code iATA,FAA": "KNR",
//       "Airport Name": "Jam Airport",
//       "Altitude (Ft)": 2173,
//       "Country": "Iran",
//       "Latitude": 52.35219955444336,
//       "Location": "Kangan",
//       "Longitude": 27.820499420166016,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11904,
//       "Airport Code [ICAO]": "OIHS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hamadan Air Base",
//       "Altitude (Ft)": 5609,
//       "Country": "Iran",
//       "Latitude": 48.65340042114258,
//       "Location": "Hamadan",
//       "Longitude": 35.21160125732422,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11905,
//       "Airport Code [ICAO]": "OIIP",
//       "Airport Code iATA,FAA": "PYK",
//       "Airport Name": "Payam International Airport",
//       "Altitude (Ft)": 4170,
//       "Country": "Iran",
//       "Latitude": 50.826698303223,
//       "Location": "Karaj",
//       "Longitude": 35.776100158691,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11906,
//       "Airport Code [ICAO]": "OKAJ",
//       "Airport Code iATA,FAA": "XIJ",
//       "Airport Name": "Ahmed Al Jaber Air Base",
//       "Altitude (Ft)": 409,
//       "Country": "Kuwait",
//       "Latitude": 47.791900634799994,
//       "Location": "Ahmed Al Jaber AB",
//       "Longitude": 28.9347991943,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11907,
//       "Airport Code [ICAO]": "OLRA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Rayak Air Base",
//       "Altitude (Ft)": 3018,
//       "Country": "Lebanon",
//       "Latitude": 35.987701416015625,
//       "Location": "Rayak",
//       "Longitude": 33.850799560546875,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11908,
//       "Airport Code [ICAO]": "OPMK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mirpur Khas Air Base",
//       "Altitude (Ft)": 180,
//       "Country": "Pakistan",
//       "Latitude": 69.0728,
//       "Location": "Mirpur Khas",
//       "Longitude": 25.682501,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11909,
//       "Airport Code [ICAO]": "OPMS",
//       "Airport Code iATA,FAA": "ATG",
//       "Airport Name": "Minhas Air Base",
//       "Altitude (Ft)": 1023,
//       "Country": "Pakistan",
//       "Latitude": 72.4009017944,
//       "Location": "Kamra",
//       "Longitude": 33.8690986633,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11910,
//       "Airport Code [ICAO]": "OPRQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Rafiqui Air Base",
//       "Altitude (Ft)": 492,
//       "Country": "Pakistan",
//       "Latitude": 72.28250122070312,
//       "Location": "Shorkot",
//       "Longitude": 30.758100509643555,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11911,
//       "Airport Code [ICAO]": "OPSF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Faisal Air Base",
//       "Altitude (Ft)": 29,
//       "Country": "Pakistan",
//       "Latitude": 67.11849975585938,
//       "Location": "Karachi",
//       "Longitude": 24.87420082092285,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11912,
//       "Airport Code [ICAO]": "OPSR",
//       "Airport Code iATA,FAA": "SGI",
//       "Airport Name": "Mushaf Air Base",
//       "Altitude (Ft)": 614,
//       "Country": "Pakistan",
//       "Latitude": 72.66500091552734,
//       "Location": "Sargodha",
//       "Longitude": 32.04859924316406,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11913,
//       "Airport Code [ICAO]": "ORQW",
//       "Airport Code iATA,FAA": "RQW",
//       "Airport Name": "Qayyarah West Airport",
//       "Altitude (Ft)": 749,
//       "Country": "Iraq",
//       "Latitude": 43.125099182099994,
//       "Location": "Qayyarah",
//       "Longitude": 35.76720047,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11914,
//       "Airport Code [ICAO]": "ORSH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Al Sahra Army Air Field",
//       "Altitude (Ft)": 451,
//       "Country": "Iraq",
//       "Latitude": 43.542999267578125,
//       "Location": "Tikrit",
//       "Longitude": 34.67319869995117,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11915,
//       "Airport Code [ICAO]": "ORTF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tall Afar Army Air Field",
//       "Altitude (Ft)": 996,
//       "Country": "Iraq",
//       "Latitude": 42.40299987792969,
//       "Location": "Tall Afar",
//       "Longitude": 36.28310012817383,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11916,
//       "Airport Code [ICAO]": "ORTI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Al Taji Army Air Field",
//       "Altitude (Ft)": 121,
//       "Country": "Iraq",
//       "Latitude": 44.25669860839844,
//       "Location": "Taji",
//       "Longitude": 33.52389907836914,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11917,
//       "Airport Code [ICAO]": "ORUB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ubaydah Bin Al Jarrah Airport",
//       "Altitude (Ft)": 68,
//       "Country": "Iraq",
//       "Latitude": 45.75709915161133,
//       "Location": "Al Cut",
//       "Longitude": 32.481998443603516,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11918,
//       "Airport Code [ICAO]": "PANN",
//       "Airport Code iATA,FAA": "ENN",
//       "Airport Name": "Nenana Municipal Airport",
//       "Altitude (Ft)": 362,
//       "Country": "United States",
//       "Latitude": -149.07400512695312,
//       "Location": "Nenana",
//       "Longitude": 64.54730224609375,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11919,
//       "Airport Code [ICAO]": "PAWS",
//       "Airport Code iATA,FAA": "WWA",
//       "Airport Name": "Wasilla Airport",
//       "Altitude (Ft)": 354,
//       "Country": "United States",
//       "Latitude": -149.539993286,
//       "Location": "Wasilla",
//       "Longitude": 61.5717010498,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11920,
//       "Airport Code [ICAO]": "RCAY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Gangshan Air Force Base",
//       "Altitude (Ft)": 34,
//       "Country": "Taiwan",
//       "Latitude": 120.26300048828125,
//       "Location": "Gangshan",
//       "Longitude": 22.782499313354492,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11921,
//       "Airport Code [ICAO]": "RJCA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Asahikawa Airfield",
//       "Altitude (Ft)": 377,
//       "Country": "Japan",
//       "Latitude": 142.365432,
//       "Location": "",
//       "Longitude": 43.794734,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11922,
//       "Airport Code [ICAO]": "RJOI",
//       "Airport Code iATA,FAA": "IWK",
//       "Airport Name": "Iwakuni Marine Corps Air Station",
//       "Altitude (Ft)": 7,
//       "Country": "Japan",
//       "Latitude": 132.235992,
//       "Location": "Iwakuni",
//       "Longitude": 34.143902,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11923,
//       "Airport Code [ICAO]": "RJTU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Utsunomiya Airport",
//       "Altitude (Ft)": 334,
//       "Country": "Japan",
//       "Latitude": 139.87101,
//       "Location": "",
//       "Longitude": 36.5145,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11924,
//       "Airport Code [ICAO]": "RKTI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Jungwon Air Base/Chungju Airport",
//       "Altitude (Ft)": 281,
//       "Country": "South Korea",
//       "Latitude": 127.886353,
//       "Location": "",
//       "Longitude": 37.03024,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11925,
//       "Airport Code [ICAO]": "RORE",
//       "Airport Code iATA,FAA": "IEJ",
//       "Airport Name": "Ie Jima Airport",
//       "Altitude (Ft)": 246,
//       "Country": "Japan",
//       "Latitude": 127.785003662,
//       "Location": "Ie",
//       "Longitude": 26.7220001221,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11926,
//       "Airport Code [ICAO]": "RPMA",
//       "Airport Code iATA,FAA": "AAV",
//       "Airport Name": "Allah Valley Airport",
//       "Altitude (Ft)": 659,
//       "Country": "Philippines",
//       "Latitude": 124.7509994506836,
//       "Location": "Surallah",
//       "Longitude": 6.366819858551025,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11927,
//       "Airport Code [ICAO]": "RPMF",
//       "Airport Code iATA,FAA": "BPH",
//       "Airport Name": "Bislig Airport",
//       "Altitude (Ft)": 12,
//       "Country": "Philippines",
//       "Latitude": 126.3219985961914,
//       "Location": "",
//       "Longitude": 8.19594955444336,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11928,
//       "Airport Code [ICAO]": "RPMQ",
//       "Airport Code iATA,FAA": "MXI",
//       "Airport Name": "Mati National Airport",
//       "Altitude (Ft)": 156,
//       "Country": "Philippines",
//       "Latitude": 126.27368,
//       "Location": "",
//       "Longitude": 6.949091,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11929,
//       "Airport Code [ICAO]": "SAOV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Presidente Néstor Kirchner Regionsl Airport",
//       "Altitude (Ft)": 670,
//       "Country": "Argentina",
//       "Latitude": -63.22663,
//       "Location": "Villa Maria",
//       "Longitude": -32.3201,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11930,
//       "Airport Code [ICAO]": "SBBT",
//       "Airport Code iATA,FAA": "BAT",
//       "Airport Name": "Chafei Amsei Airport",
//       "Altitude (Ft)": 1898,
//       "Country": "Brazil",
//       "Latitude": -48.594100952148,
//       "Location": "Barretos",
//       "Longitude": -20.584499359131,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11931,
//       "Airport Code [ICAO]": "SBTA",
//       "Airport Code iATA,FAA": "QHP",
//       "Airport Name": "Base de Aviação de Taubaté Airport",
//       "Altitude (Ft)": 1908,
//       "Country": "Brazil",
//       "Latitude": -45.51599884033203,
//       "Location": "Taubaté",
//       "Longitude": -23.04010009765625,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11932,
//       "Airport Code [ICAO]": "SCBE",
//       "Airport Code iATA,FAA": "TOQ",
//       "Airport Name": "Barriles Airport",
//       "Altitude (Ft)": 3475,
//       "Country": "Chile",
//       "Latitude": -70.06289672851562,
//       "Location": "Tocopilla",
//       "Longitude": -22.14109992980957,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11933,
//       "Airport Code [ICAO]": "SCHR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Schroeder's field",
//       "Altitude (Ft)": 0,
//       "Country": "Chile",
//       "Latitude": -91.738801,
//       "Location": "Cochrane",
//       "Longitude": 35.071718,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11934,
//       "Airport Code [ICAO]": "SCRA",
//       "Airport Code iATA,FAA": "CNR",
//       "Airport Name": "Chañaral Airport",
//       "Altitude (Ft)": 97,
//       "Country": "Chile",
//       "Latitude": -70.6072998046875,
//       "Location": "Chañaral",
//       "Longitude": -26.332500457763672,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11935,
//       "Airport Code [ICAO]": "SCTL",
//       "Airport Code iATA,FAA": "TLX",
//       "Airport Name": "Panguilemo Airport",
//       "Altitude (Ft)": 371,
//       "Country": "Chile",
//       "Latitude": -71.60169982910156,
//       "Location": "Talca",
//       "Longitude": -35.37779998779297,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11936,
//       "Airport Code [ICAO]": "SCTO",
//       "Airport Code iATA,FAA": "ZIC",
//       "Airport Name": "Victoria Airport",
//       "Altitude (Ft)": 1148,
//       "Country": "Chile",
//       "Latitude": -72.34860229492188,
//       "Location": "Victoria",
//       "Longitude": -38.245601654052734,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11937,
//       "Airport Code [ICAO]": "SCTT",
//       "Airport Code iATA,FAA": "TTC",
//       "Airport Name": "Las Breas Airport",
//       "Altitude (Ft)": 2580,
//       "Country": "Chile",
//       "Latitude": -70.37590026855469,
//       "Location": "Taltal",
//       "Longitude": -25.564300537109375,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11938,
//       "Airport Code [ICAO]": "SKAP",
//       "Airport Code iATA,FAA": "API",
//       "Airport Name": "Gomez Nino Apiay Air Base",
//       "Altitude (Ft)": 1207,
//       "Country": "Colombia",
//       "Latitude": -73.5627,
//       "Location": "Apiay",
//       "Longitude": 4.07607,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11939,
//       "Airport Code [ICAO]": "SKCV",
//       "Airport Code iATA,FAA": "CVE",
//       "Airport Name": "Coveñas Airport",
//       "Altitude (Ft)": 31,
//       "Country": "Colombia",
//       "Latitude": -75.6913,
//       "Location": "Coveñas",
//       "Longitude": 9.40092,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11940,
//       "Airport Code [ICAO]": "SKPQ",
//       "Airport Code iATA,FAA": "PAL",
//       "Airport Name": "German Olano Air Base",
//       "Altitude (Ft)": 566,
//       "Country": "Colombia",
//       "Latitude": -74.6574,
//       "Location": "La Dorada",
//       "Longitude": 5.48361,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11941,
//       "Airport Code [ICAO]": "SKPZ",
//       "Airport Code iATA,FAA": "PZA",
//       "Airport Name": "Paz De Ariporo Airport",
//       "Altitude (Ft)": 900,
//       "Country": "Colombia",
//       "Latitude": -71.8866,
//       "Location": "Paz De Ariporo",
//       "Longitude": 5.87615,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11942,
//       "Airport Code [ICAO]": "SKTJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tunja Airport",
//       "Altitude (Ft)": 8940,
//       "Country": "Colombia",
//       "Latitude": -73.3445,
//       "Location": "Tunja",
//       "Longitude": 5.54138,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11943,
//       "Airport Code [ICAO]": "SKTQ",
//       "Airport Code iATA,FAA": "TQS",
//       "Airport Name": "Tres Esquinas Air Base",
//       "Altitude (Ft)": 585,
//       "Country": "Colombia",
//       "Latitude": -75.234,
//       "Location": "Tres Esquinas",
//       "Longitude": 0.7459,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11944,
//       "Airport Code [ICAO]": "SKUA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Marandúa Air Base",
//       "Altitude (Ft)": 285,
//       "Country": "Colombia",
//       "Latitude": -68.6856,
//       "Location": "Marandúa",
//       "Longitude": 5.52448,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11945,
//       "Airport Code [ICAO]": "SPJA",
//       "Airport Code iATA,FAA": "RIJ",
//       "Airport Name": "Juan Simons Vela Airport",
//       "Altitude (Ft)": 2707,
//       "Country": "Peru",
//       "Latitude": -77.16000366210938,
//       "Location": "Rioja",
//       "Longitude": -6.067860126495361,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11946,
//       "Airport Code [ICAO]": "SPJE",
//       "Airport Code iATA,FAA": "JAE",
//       "Airport Name": "Shumba Airport",
//       "Altitude (Ft)": 2477,
//       "Country": "Peru",
//       "Latitude": -78.774002,
//       "Location": "Jaén",
//       "Longitude": -5.59248,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11947,
//       "Airport Code [ICAO]": "SVMP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Metropolitano Airport",
//       "Altitude (Ft)": 574,
//       "Country": "Venezuela",
//       "Latitude": -66.78782653808594,
//       "Location": "",
//       "Longitude": 10.133169174194336,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11948,
//       "Airport Code [ICAO]": "UAFL",
//       "Airport Code iATA,FAA": "IKU",
//       "Airport Name": "Issyk-Kul International Airport",
//       "Altitude (Ft)": 5425,
//       "Country": "Kyrgyzstan",
//       "Latitude": 76.713046,
//       "Location": "Tamchy",
//       "Longitude": 42.58792,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11949,
//       "Airport Code [ICAO]": "UAFW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kant Air Base",
//       "Altitude (Ft)": 2549,
//       "Country": "Kyrgyzstan",
//       "Latitude": 74.84649658203125,
//       "Location": "Kant",
//       "Longitude": 42.85319900512695,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11950,
//       "Airport Code [ICAO]": "UASA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ayaguz Airport",
//       "Altitude (Ft)": 2119,
//       "Country": "Kazakhstan",
//       "Latitude": 80.45279693603516,
//       "Location": "Ayaguz",
//       "Longitude": 47.91859817504883,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11951,
//       "Airport Code [ICAO]": "UATR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Chelkar Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Kazakhstan",
//       "Latitude": 59.619998931884766,
//       "Location": "Chelkar",
//       "Longitude": 47.904998779296875,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11952,
//       "Airport Code [ICAO]": "UENK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kyzyl-Syr Airport",
//       "Altitude (Ft)": 331,
//       "Country": "Russia",
//       "Latitude": 122.7770004272461,
//       "Location": "Kyzyl-Syr",
//       "Longitude": 63.8849983215332,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11953,
//       "Airport Code [ICAO]": "UENW",
//       "Airport Code iATA,FAA": "VYI",
//       "Airport Name": "Vilyuisk Airport",
//       "Altitude (Ft)": 361,
//       "Country": "Russia",
//       "Latitude": 121.69333648682,
//       "Location": "Vilyuisk",
//       "Longitude": 63.75666809082,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11954,
//       "Airport Code [ICAO]": "UESG",
//       "Airport Code iATA,FAA": "BGN",
//       "Airport Name": "Belaya Gora Airport",
//       "Altitude (Ft)": 118,
//       "Country": "Russia",
//       "Latitude": 146.231506,
//       "Location": "",
//       "Longitude": 68.556602,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11955,
//       "Airport Code [ICAO]": "UGEJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Dzhermuk Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Armenia",
//       "Latitude": 45.673999786399996,
//       "Location": "Dzhermuk",
//       "Longitude": 39.8240013123,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11956,
//       "Airport Code [ICAO]": "UHKG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kamenny Ruchey Naval Air Base",
//       "Altitude (Ft)": 659,
//       "Country": "Russia",
//       "Latitude": 140.19309997558594,
//       "Location": "Sovetskaya Gavan",
//       "Longitude": 49.23570251464844,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11957,
//       "Airport Code [ICAO]": "UHKM",
//       "Airport Code iATA,FAA": "GVN",
//       "Airport Name": "Maygatka Airport.",
//       "Altitude (Ft)": 768,
//       "Country": "Russia",
//       "Latitude": 140.033996582,
//       "Location": "Sovetskaya Gavan",
//       "Longitude": 48.926998138399995,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11958,
//       "Airport Code [ICAO]": "UHMF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Omsukchan Airport",
//       "Altitude (Ft)": 1732,
//       "Country": "Russia",
//       "Latitude": 155.7449951171875,
//       "Location": "Omsukchan",
//       "Longitude": 62.457000732421875,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11959,
//       "Airport Code [ICAO]": "UHMG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Chaybukha Airport",
//       "Altitude (Ft)": 207,
//       "Country": "Russia",
//       "Latitude": 160.54800415039062,
//       "Location": "Chaybukha",
//       "Longitude": 61.834999084472656,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11960,
//       "Airport Code [ICAO]": "UHMK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Keperveem Airport",
//       "Altitude (Ft)": 623,
//       "Country": "Russia",
//       "Latitude": 166.13999939,
//       "Location": "Keperveem",
//       "Longitude": 67.8450012207,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11961,
//       "Airport Code [ICAO]": "UHMT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Magadan-13 Airport",
//       "Altitude (Ft)": 164,
//       "Country": "Russia",
//       "Latitude": 150.9219970703125,
//       "Location": "Magadan",
//       "Longitude": 59.62329864501953,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11962,
//       "Airport Code [ICAO]": "UHNN",
//       "Airport Code iATA,FAA": "NLI",
//       "Airport Name": "Nikolayevsk-na-Amure Airport",
//       "Altitude (Ft)": 170,
//       "Country": "Russia",
//       "Latitude": 140.649994,
//       "Location": "Nikolayevsk-na-Amure Airport",
//       "Longitude": 53.154999,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11963,
//       "Airport Code [ICAO]": "UHPL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Palana Airport",
//       "Altitude (Ft)": 112,
//       "Country": "Russia",
//       "Latitude": 159.88800048828125,
//       "Location": "Palana",
//       "Longitude": 59.08169937133789,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11964,
//       "Airport Code [ICAO]": "UIBV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Zheleznogorsk Airport",
//       "Altitude (Ft)": 1946,
//       "Country": "Russia",
//       "Latitude": 104.10600280761719,
//       "Location": "Zheleznogorsk-Ilimsky",
//       "Longitude": 56.487701416015625,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11965,
//       "Airport Code [ICAO]": "UKFG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Gvardeyskoe Air Base",
//       "Altitude (Ft)": 721,
//       "Country": "Ukraine",
//       "Latitude": 33.97700119018555,
//       "Location": "Sarabuz",
//       "Longitude": 45.1150016784668,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11966,
//       "Airport Code [ICAO]": "UKFI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Saki Air Base",
//       "Altitude (Ft)": 36,
//       "Country": "Ukraine",
//       "Latitude": 33.595001220703125,
//       "Location": "Saki",
//       "Longitude": 45.09299850463867,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11967,
//       "Airport Code [ICAO]": "UKFY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Dzhankoy Airport",
//       "Altitude (Ft)": 75,
//       "Country": "Ukraine",
//       "Latitude": 34.41889953613281,
//       "Location": "Dzhankoy",
//       "Longitude": 45.70090103149414,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11968,
//       "Airport Code [ICAO]": "UKKO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ozerne Air Base",
//       "Altitude (Ft)": 761,
//       "Country": "Ukraine",
//       "Latitude": 28.7383,
//       "Location": "Zhytomyr",
//       "Longitude": 50.158298,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11969,
//       "Airport Code [ICAO]": "ULAK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Severomorsk-1 Naval Air Base",
//       "Altitude (Ft)": 239,
//       "Country": "Russia",
//       "Latitude": 33.418331146240234,
//       "Location": "Severomorsk",
//       "Longitude": 69.03166961669922,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11970,
//       "Airport Code [ICAO]": "ULLP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Pushkin Airport",
//       "Altitude (Ft)": 230,
//       "Country": "Russia",
//       "Latitude": 30.338301,
//       "Location": "St. Petersburg",
//       "Longitude": 59.685001,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11971,
//       "Airport Code [ICAO]": "ULLS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Siversky Air Base",
//       "Altitude (Ft)": 341,
//       "Country": "Russia",
//       "Latitude": 30.036699295043945,
//       "Location": "Siversky",
//       "Longitude": 59.3567008972168,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11972,
//       "Airport Code [ICAO]": "ULNR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Staraya Russa Air Base",
//       "Altitude (Ft)": 52,
//       "Country": "Russia",
//       "Latitude": 31.384399,
//       "Location": "Staraya Russa",
//       "Longitude": 57.961399,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11973,
//       "Airport Code [ICAO]": "ULPP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Peski Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Russia",
//       "Latitude": 34.295555114746094,
//       "Location": "Petrozavodsk",
//       "Longitude": 61.83194351196289,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11974,
//       "Airport Code [ICAO]": "UMMA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Baranavichi Air Base",
//       "Altitude (Ft)": 0,
//       "Country": "Belarus",
//       "Latitude": 26.049999237060547,
//       "Location": "Baranavichi",
//       "Longitude": 53.099998474121094,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11975,
//       "Airport Code [ICAO]": "UMNB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Babruisk Air Base",
//       "Altitude (Ft)": 502,
//       "Country": "Belarus",
//       "Latitude": 29.205,
//       "Location": "Babruisk",
//       "Longitude": 53.105,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11976,
//       "Airport Code [ICAO]": "UNIS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Severo-Eniseysk Airport",
//       "Altitude (Ft)": 1706,
//       "Country": "Russia",
//       "Latitude": 93.01170349121094,
//       "Location": "Severo-Eniseysk",
//       "Longitude": 60.37329864501953,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11977,
//       "Airport Code [ICAO]": "UNKO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sharypovo Airport",
//       "Altitude (Ft)": 1099,
//       "Country": "Russia",
//       "Latitude": 89.1738815308,
//       "Location": "Sharypovo",
//       "Longitude": 55.454914093,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11978,
//       "Airport Code [ICAO]": "UNOS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Omsk Severny Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Russia",
//       "Latitude": 73.5550003052,
//       "Location": "Omsk",
//       "Longitude": 54.9749984741,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11979,
//       "Airport Code [ICAO]": "UODN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nagurskoye",
//       "Altitude (Ft)": 59,
//       "Country": "Russia",
//       "Latitude": 47.663586,
//       "Location": "Murmansk",
//       "Longitude": 80.803207,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11980,
//       "Airport Code [ICAO]": "UOIG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Svetlogorsk Airport",
//       "Altitude (Ft)": 394,
//       "Country": "Russia",
//       "Latitude": 88.40333557128906,
//       "Location": "Svetlogorsk",
//       "Longitude": 66.83999633789062,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11981,
//       "Airport Code [ICAO]": "UOOW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Valek Airport",
//       "Altitude (Ft)": 108,
//       "Country": "Russia",
//       "Latitude": 88.35368347167969,
//       "Location": "Valek",
//       "Longitude": 69.39690399169922,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11982,
//       "Airport Code [ICAO]": "URKH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Khanskaya Air Base",
//       "Altitude (Ft)": 600,
//       "Country": "Russia",
//       "Latitude": 40.035,
//       "Location": "Maykop",
//       "Longitude": 44.68,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11983,
//       "Airport Code [ICAO]": "UROD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Dudinka Airport",
//       "Altitude (Ft)": 82,
//       "Country": "Russia",
//       "Latitude": 86.15666961669922,
//       "Location": "Dudinka",
//       "Longitude": 69.375,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11984,
//       "Airport Code [ICAO]": "URRY",
//       "Airport Code iATA,FAA": "VLK",
//       "Airport Name": "Volgodonsk Airport",
//       "Altitude (Ft)": 276,
//       "Country": "Russia",
//       "Latitude": 42.07279968261719,
//       "Location": "",
//       "Longitude": 47.682098388671875,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11985,
//       "Airport Code [ICAO]": "UTTP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tashkent East Airport",
//       "Altitude (Ft)": 1574,
//       "Country": "Uzbekistan",
//       "Latitude": 69.39140319824219,
//       "Location": "Tashkent",
//       "Longitude": 41.31269836425781,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11986,
//       "Airport Code [ICAO]": "UUBM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Myachkovo Airport",
//       "Altitude (Ft)": 410,
//       "Country": "Russia",
//       "Latitude": 37.9850006104,
//       "Location": "Moscow",
//       "Longitude": 55.5600013733,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11987,
//       "Airport Code [ICAO]": "UUMT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tretyakovo Airport",
//       "Altitude (Ft)": 515,
//       "Country": "Russia",
//       "Latitude": 39.02666854859999,
//       "Location": "Lukhovitsy",
//       "Longitude": 54.904998779299994,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11988,
//       "Airport Code [ICAO]": "UUWE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Yermolino Airport",
//       "Altitude (Ft)": 640,
//       "Country": "Russia",
//       "Latitude": 36.6083335876,
//       "Location": "Balabanovo",
//       "Longitude": 55.2283325195,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11989,
//       "Airport Code [ICAO]": "UUYI",
//       "Airport Code iATA,FAA": "INA",
//       "Airport Name": "Inta Airport",
//       "Altitude (Ft)": 184,
//       "Country": "Russia",
//       "Latitude": 60.110321044921875,
//       "Location": "Inta",
//       "Longitude": 66.0548324584961,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11990,
//       "Airport Code [ICAO]": "UUYV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Izhma Airport",
//       "Altitude (Ft)": 230,
//       "Country": "Russia",
//       "Latitude": 53.970001220703125,
//       "Location": "Izhma",
//       "Longitude": 65.03170013427734,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11991,
//       "Airport Code [ICAO]": "UWKG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Borisoglebskoye Airport",
//       "Altitude (Ft)": 213,
//       "Country": "Russia",
//       "Latitude": 49.13330078125,
//       "Location": "Kazan",
//       "Longitude": 55.86669921875,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11992,
//       "Airport Code [ICAO]": "UWKV",
//       "Airport Code iATA,FAA": "ZIX",
//       "Airport Name": "Zhigansk Airport",
//       "Altitude (Ft)": 292,
//       "Country": "Russia",
//       "Latitude": 123.361000061,
//       "Location": "Zhigansk",
//       "Longitude": 66.7965011597,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11993,
//       "Airport Code [ICAO]": "UWUM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Maksimovka Airport",
//       "Altitude (Ft)": 325,
//       "Country": "Russia",
//       "Latitude": 56.16830062866211,
//       "Location": "Ufa",
//       "Longitude": 54.83000183105469,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11994,
//       "Airport Code [ICAO]": "UWWB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Buguruslan Severny Airport",
//       "Altitude (Ft)": 728,
//       "Country": "Russia",
//       "Latitude": 52.3718986511,
//       "Location": "Buguruslan",
//       "Longitude": 53.71839904789999,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11995,
//       "Airport Code [ICAO]": "UWWG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bezymyanka Airfield",
//       "Altitude (Ft)": 135,
//       "Country": "Russia",
//       "Latitude": 50.32500076293945,
//       "Location": "Samara",
//       "Longitude": 53.220001220703125,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11996,
//       "Airport Code [ICAO]": "VARG",
//       "Airport Code iATA,FAA": "RTC",
//       "Airport Name": "Ratnagiri Airport",
//       "Altitude (Ft)": 305,
//       "Country": "India",
//       "Latitude": 73.327797,
//       "Location": "",
//       "Longitude": 17.013599,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11997,
//       "Airport Code [ICAO]": "VCCH",
//       "Airport Code iATA,FAA": "HIM",
//       "Airport Name": "Hingurakgoda Air Force Base",
//       "Altitude (Ft)": 170,
//       "Country": "Sri Lanka",
//       "Latitude": 80.9814,
//       "Location": "Polonnaruwa Town",
//       "Longitude": 8.04981,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11998,
//       "Airport Code [ICAO]": "VEDG",
//       "Airport Code iATA,FAA": "RDP",
//       "Airport Name": "Kazi Nazrul Islam Airport",
//       "Altitude (Ft)": 300,
//       "Country": "India",
//       "Latitude": 87.243,
//       "Location": "Durgapur",
//       "Longitude": 23.6225,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 11999,
//       "Airport Code [ICAO]": "VIAM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ambala Air Force Station",
//       "Altitude (Ft)": 909,
//       "Country": "India",
//       "Latitude": 76.81670379638672,
//       "Location": "",
//       "Longitude": 30.368099212646484,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12000,
//       "Airport Code [ICAO]": "VISA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sirsa Air Force Station",
//       "Altitude (Ft)": 650,
//       "Country": "India",
//       "Latitude": 75.006103515625,
//       "Location": "",
//       "Longitude": 29.56060028076172,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12001,
//       "Airport Code [ICAO]": "VIUX",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Udhampur Air Force Station",
//       "Altitude (Ft)": 2066,
//       "Country": "India",
//       "Latitude": 75.15619659423828,
//       "Location": "",
//       "Longitude": 32.902198791503906,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12002,
//       "Airport Code [ICAO]": "VOPN",
//       "Airport Code iATA,FAA": "PUT",
//       "Airport Name": "Sri Sathya Sai Airport",
//       "Altitude (Ft)": 1558,
//       "Country": "India",
//       "Latitude": 77.7910995483,
//       "Location": "Puttaparthi",
//       "Longitude": 14.1492996216,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12003,
//       "Airport Code [ICAO]": "VYML",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Meiktila Air Base",
//       "Altitude (Ft)": 699,
//       "Country": "Burma",
//       "Latitude": 95.89279174804688,
//       "Location": "Meiktila",
//       "Longitude": 20.88640022277832,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12004,
//       "Airport Code [ICAO]": "WSAC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Changi Air Base (East)",
//       "Altitude (Ft)": 22,
//       "Country": "Singapore",
//       "Latitude": 104.009002686,
//       "Location": "Singapore",
//       "Longitude": 1.3441400528,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12005,
//       "Airport Code [ICAO]": "XLLL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Soltsy-2 Air Base",
//       "Altitude (Ft)": 266,
//       "Country": "Russia",
//       "Latitude": 30.33042,
//       "Location": "Soltsy",
//       "Longitude": 58.139545,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12006,
//       "Airport Code [ICAO]": "XLMV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Severomorsk-3 Naval Air Base",
//       "Altitude (Ft)": 564,
//       "Country": "Russia",
//       "Latitude": 33.716667,
//       "Location": "Severomorsk",
//       "Longitude": 68.866669,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12007,
//       "Airport Code [ICAO]": "XLWF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Fedotovo Naval Air Base",
//       "Altitude (Ft)": 574,
//       "Country": "Russia",
//       "Latitude": 39.129411,
//       "Location": "Kipelovo",
//       "Longitude": 59.193031,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12008,
//       "Airport Code [ICAO]": "XRWL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lebyazhye Air Base",
//       "Altitude (Ft)": 381,
//       "Country": "Russia",
//       "Latitude": 45.2083015442,
//       "Location": "Kamyshin",
//       "Longitude": 50.201698303200004,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12009,
//       "Airport Code [ICAO]": "XUBS",
//       "Airport Code iATA,FAA": "LNX",
//       "Airport Name": "Smolensk North Airport",
//       "Altitude (Ft)": 820,
//       "Country": "Russia",
//       "Latitude": 32.025,
//       "Location": "Smolensk",
//       "Longitude": 54.824,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12010,
//       "Airport Code [ICAO]": "XWPR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Rtishchevo Air Base",
//       "Altitude (Ft)": 673,
//       "Country": "Russia",
//       "Latitude": 43.7241,
//       "Location": "Rtishchevo",
//       "Longitude": 52.297317,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12011,
//       "Airport Code [ICAO]": "YAPH",
//       "Airport Code iATA,FAA": "ABH",
//       "Airport Name": "Alpha Airport",
//       "Altitude (Ft)": 1255,
//       "Country": "Australia",
//       "Latitude": 146.584,
//       "Location": "Alpha",
//       "Longitude": -23.646099,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12012,
//       "Airport Code [ICAO]": "YARA",
//       "Airport Code iATA,FAA": "ARY",
//       "Airport Name": "Ararat Airport",
//       "Altitude (Ft)": 1008,
//       "Country": "Australia",
//       "Latitude": 142.98899841308594,
//       "Location": "",
//       "Longitude": -37.30939865112305,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12013,
//       "Airport Code [ICAO]": "YBLA",
//       "Airport Code iATA,FAA": "BLN",
//       "Airport Name": "Benalla Airport",
//       "Altitude (Ft)": 569,
//       "Country": "Australia",
//       "Latitude": 146.0070037841797,
//       "Location": "",
//       "Longitude": -36.55189895629883,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12014,
//       "Airport Code [ICAO]": "YBRN",
//       "Airport Code iATA,FAA": "BZD",
//       "Airport Name": "Balranald Airport",
//       "Altitude (Ft)": 210,
//       "Country": "Australia",
//       "Latitude": 143.5780029296875,
//       "Location": "",
//       "Longitude": -34.623600006103516,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12015,
//       "Airport Code [ICAO]": "YBRW",
//       "Airport Code iATA,FAA": "BWQ",
//       "Airport Name": "Brewarrina Airport",
//       "Altitude (Ft)": 414,
//       "Country": "Australia",
//       "Latitude": 146.81700134277344,
//       "Location": "",
//       "Longitude": -29.973899841308594,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12016,
//       "Airport Code [ICAO]": "YCEE",
//       "Airport Code iATA,FAA": "CVC",
//       "Airport Name": "Cleve Airport",
//       "Altitude (Ft)": 589,
//       "Country": "Australia",
//       "Latitude": 136.5050048828125,
//       "Location": "",
//       "Longitude": -33.70970153808594,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12017,
//       "Airport Code [ICAO]": "YCOR",
//       "Airport Code iATA,FAA": "CWW",
//       "Airport Name": "Corowa Airport",
//       "Altitude (Ft)": 469,
//       "Country": "Australia",
//       "Latitude": 146.35699462890625,
//       "Location": "",
//       "Longitude": -35.99470138549805,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12018,
//       "Airport Code [ICAO]": "YCRG",
//       "Airport Code iATA,FAA": "CYG",
//       "Airport Name": "Corryong Airport",
//       "Altitude (Ft)": 963,
//       "Country": "Australia",
//       "Latitude": 147.88800048828125,
//       "Location": "",
//       "Longitude": -36.18280029296875,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12019,
//       "Airport Code [ICAO]": "YCTM",
//       "Airport Code iATA,FAA": "CMD",
//       "Airport Name": "Cootamundra Airport",
//       "Altitude (Ft)": 1110,
//       "Country": "Australia",
//       "Latitude": 148.0279998779297,
//       "Location": "",
//       "Longitude": -34.6239013671875,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12020,
//       "Airport Code [ICAO]": "YDBI",
//       "Airport Code iATA,FAA": "DRN",
//       "Airport Name": "Dirranbandi Airport",
//       "Altitude (Ft)": 567,
//       "Country": "Australia",
//       "Latitude": 148.2169952392578,
//       "Location": "",
//       "Longitude": -28.591699600219727,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12021,
//       "Airport Code [ICAO]": "YDLQ",
//       "Airport Code iATA,FAA": "DNQ",
//       "Airport Name": "Deniliquin Airport",
//       "Altitude (Ft)": 316,
//       "Country": "Australia",
//       "Latitude": 144.945999146,
//       "Location": "Deniliquin",
//       "Longitude": -35.5593986511,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12022,
//       "Airport Code [ICAO]": "YDYS",
//       "Airport Code iATA,FAA": "DYA",
//       "Airport Name": "Dysart Airport",
//       "Altitude (Ft)": 670,
//       "Country": "Australia",
//       "Latitude": 148.36399841308594,
//       "Location": "",
//       "Longitude": -22.62220001220703,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12023,
//       "Airport Code [ICAO]": "YECH",
//       "Airport Code iATA,FAA": "ECH",
//       "Airport Name": "Echuca Airport",
//       "Altitude (Ft)": 323,
//       "Country": "Australia",
//       "Latitude": 144.76199340820312,
//       "Location": "",
//       "Longitude": -36.15719985961914,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12024,
//       "Airport Code [ICAO]": "YFBS",
//       "Airport Code iATA,FAA": "FRB",
//       "Airport Name": "Forbes Airport",
//       "Altitude (Ft)": 760,
//       "Country": "Australia",
//       "Latitude": 147.934998,
//       "Location": "Forbes",
//       "Longitude": -33.363602,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12025,
//       "Airport Code [ICAO]": "YGDH",
//       "Airport Code iATA,FAA": "GUH",
//       "Airport Name": "Gunnedah Airport",
//       "Altitude (Ft)": 863,
//       "Country": "Australia",
//       "Latitude": 150.25100708007812,
//       "Location": "",
//       "Longitude": -30.96109962463379,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12026,
//       "Airport Code [ICAO]": "YHAY",
//       "Airport Code iATA,FAA": "HXX",
//       "Airport Name": "Hay Airport",
//       "Altitude (Ft)": 305,
//       "Country": "Australia",
//       "Latitude": 144.8300018310547,
//       "Location": "",
//       "Longitude": -34.53139877319336,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12027,
//       "Airport Code [ICAO]": "YHPN",
//       "Airport Code iATA,FAA": "HTU",
//       "Airport Name": "Hopetoun Airport",
//       "Altitude (Ft)": 256,
//       "Country": "Australia",
//       "Latitude": 142.36000061035156,
//       "Location": "",
//       "Longitude": -35.715301513671875,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12028,
//       "Airport Code [ICAO]": "YKER",
//       "Airport Code iATA,FAA": "KRA",
//       "Airport Name": "Kerang Airport",
//       "Altitude (Ft)": 254,
//       "Country": "Australia",
//       "Latitude": 143.93899536132812,
//       "Location": "",
//       "Longitude": -35.751399993896484,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12029,
//       "Airport Code [ICAO]": "YKMP",
//       "Airport Code iATA,FAA": "KPS",
//       "Airport Name": "Kempsey Airport",
//       "Altitude (Ft)": 54,
//       "Country": "Australia",
//       "Latitude": 152.77000427246094,
//       "Location": "",
//       "Longitude": -31.074399948120117,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12030,
//       "Airport Code [ICAO]": "YKRY",
//       "Airport Code iATA,FAA": "KGY",
//       "Airport Name": "Kingaroy Airport",
//       "Altitude (Ft)": 1492,
//       "Country": "Australia",
//       "Latitude": 151.84100341796875,
//       "Location": "",
//       "Longitude": -26.580799102783203,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12031,
//       "Airport Code [ICAO]": "YLTV",
//       "Airport Code iATA,FAA": "TGN",
//       "Airport Name": "Latrobe Valley Airport",
//       "Altitude (Ft)": 180,
//       "Country": "Australia",
//       "Latitude": 146.470001,
//       "Location": "Morwell",
//       "Longitude": -38.207199,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12032,
//       "Airport Code [ICAO]": "YMBA",
//       "Airport Code iATA,FAA": "MRG",
//       "Airport Name": "Mareeba Airport",
//       "Altitude (Ft)": 1560,
//       "Country": "Australia",
//       "Latitude": 145.41900634765625,
//       "Location": "",
//       "Longitude": -17.06920051574707,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12033,
//       "Airport Code [ICAO]": "YNGU",
//       "Airport Code iATA,FAA": "RPM",
//       "Airport Name": "Ngukurr Airport",
//       "Altitude (Ft)": 45,
//       "Country": "Australia",
//       "Latitude": 134.7469940185547,
//       "Location": "",
//       "Longitude": -14.722800254821777,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12034,
//       "Airport Code [ICAO]": "YNRM",
//       "Airport Code iATA,FAA": "QRM",
//       "Airport Name": "Narromine Airport",
//       "Altitude (Ft)": 782,
//       "Country": "Australia",
//       "Latitude": 148.22500610351562,
//       "Location": "",
//       "Longitude": -32.214698791503906,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12035,
//       "Airport Code [ICAO]": "YPIR",
//       "Airport Code iATA,FAA": "PPI",
//       "Airport Name": "Port Pirie Airport",
//       "Altitude (Ft)": 40,
//       "Country": "Australia",
//       "Latitude": 137.9949951171875,
//       "Location": "",
//       "Longitude": -33.23889923095703,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12036,
//       "Airport Code [ICAO]": "YSMI",
//       "Airport Code iATA,FAA": "SIO",
//       "Airport Name": "Smithton Airport",
//       "Altitude (Ft)": 31,
//       "Country": "Australia",
//       "Latitude": 145.08399963378906,
//       "Location": "",
//       "Longitude": -40.834999084472656,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12037,
//       "Airport Code [ICAO]": "YSNB",
//       "Airport Code iATA,FAA": "SNB",
//       "Airport Name": "Snake Bay Airport",
//       "Altitude (Ft)": 173,
//       "Country": "Australia",
//       "Latitude": 130.6540069580078,
//       "Location": "",
//       "Longitude": -11.422800064086914,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12038,
//       "Airport Code [ICAO]": "YSWL",
//       "Airport Code iATA,FAA": "SWC",
//       "Airport Name": "Stawell Airport",
//       "Altitude (Ft)": 807,
//       "Country": "Australia",
//       "Latitude": 142.74099731445312,
//       "Location": "",
//       "Longitude": -37.07170104980469,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12039,
//       "Airport Code [ICAO]": "YTIB",
//       "Airport Code iATA,FAA": "TYB",
//       "Airport Name": "Tibooburra Airport",
//       "Altitude (Ft)": 584,
//       "Country": "Australia",
//       "Latitude": 142.05799865722656,
//       "Location": "",
//       "Longitude": -29.451099395751953,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12040,
//       "Airport Code [ICAO]": "YTMU",
//       "Airport Code iATA,FAA": "TUM",
//       "Airport Name": "Tumut Airport",
//       "Altitude (Ft)": 878,
//       "Country": "Australia",
//       "Latitude": 148.24099731445312,
//       "Location": "",
//       "Longitude": -35.26279830932617,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12041,
//       "Airport Code [ICAO]": "YWGT",
//       "Airport Code iATA,FAA": "WGT",
//       "Airport Name": "Wangaratta Airport",
//       "Altitude (Ft)": 504,
//       "Country": "Australia",
//       "Latitude": 146.3070068359375,
//       "Location": "",
//       "Longitude": -36.41579818725586,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12042,
//       "Airport Code [ICAO]": "YWKB",
//       "Airport Code iATA,FAA": "WKB",
//       "Airport Name": "Warracknabeal Airport",
//       "Altitude (Ft)": 397,
//       "Country": "Australia",
//       "Latitude": 142.41900634765625,
//       "Location": "",
//       "Longitude": -36.32109832763672,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12043,
//       "Airport Code [ICAO]": "YWRN",
//       "Airport Code iATA,FAA": "QRR",
//       "Airport Name": "Warren Airport",
//       "Altitude (Ft)": 669,
//       "Country": "Australia",
//       "Latitude": 147.80299377441406,
//       "Location": "",
//       "Longitude": -31.733299255371094,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12044,
//       "Airport Code [ICAO]": "YWSL",
//       "Airport Code iATA,FAA": "SXE",
//       "Airport Name": "West Sale Airport",
//       "Altitude (Ft)": 72,
//       "Country": "Australia",
//       "Latitude": 146.965335,
//       "Location": "Sale",
//       "Longitude": -38.090827,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12045,
//       "Airport Code [ICAO]": "YWWL",
//       "Airport Code iATA,FAA": "WWY",
//       "Airport Name": "West Wyalong Airport",
//       "Altitude (Ft)": 859,
//       "Country": "Australia",
//       "Latitude": 147.190994263,
//       "Location": "West Wyalong",
//       "Longitude": -33.9371986389,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12046,
//       "Airport Code [ICAO]": "YYNG",
//       "Airport Code iATA,FAA": "NGA",
//       "Airport Name": "Young Airport",
//       "Altitude (Ft)": 1267,
//       "Country": "Australia",
//       "Latitude": 148.2480010986328,
//       "Location": "",
//       "Longitude": -34.25559997558594,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12047,
//       "Airport Code [ICAO]": "ZHGH",
//       "Airport Code iATA,FAA": "LHK",
//       "Airport Name": "Guangzhou MR Air Base",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 111.69499969482422,
//       "Location": "Guanghua",
//       "Longitude": 32.389400482177734,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12048,
//       "Airport Code [ICAO]": "ZHSY",
//       "Airport Code iATA,FAA": "WDS",
//       "Airport Name": "Shiyan Wudangshan Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 110.907778,
//       "Location": "Shiyan",
//       "Longitude": 32.591667,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12049,
//       "Airport Code [ICAO]": "ZKSC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sunchon Air Base",
//       "Altitude (Ft)": 141,
//       "Country": "North Korea",
//       "Latitude": 125.894997,
//       "Location": "Sunchon",
//       "Longitude": 39.415699,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12050,
//       "Airport Code [ICAO]": "ZLHX",
//       "Airport Code iATA,FAA": "HTT",
//       "Airport Name": "Huatugou Airport",
//       "Altitude (Ft)": 2945,
//       "Country": "China",
//       "Latitude": 90.841495,
//       "Location": "Mengnai",
//       "Longitude": 38.201984,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12051,
//       "Airport Code [ICAO]": "ZMBU",
//       "Airport Code iATA,FAA": "UUN",
//       "Airport Name": "Baruun Urt Airport",
//       "Altitude (Ft)": 3205,
//       "Country": "Mongolia",
//       "Latitude": 113.28500366210938,
//       "Location": "",
//       "Longitude": 46.660301208496094,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12052,
//       "Airport Code [ICAO]": "ZSBB",
//       "Airport Code iATA,FAA": "BFU",
//       "Airport Name": "Bengbu Airport",
//       "Altitude (Ft)": 100,
//       "Country": "China",
//       "Latitude": 117.320244444,
//       "Location": "Bengbu",
//       "Longitude": 32.8477333333,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12053,
//       "Airport Code [ICAO]": "ZSRG",
//       "Airport Code iATA,FAA": "RUG",
//       "Airport Name": "Rugao Air Base",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 120.50165557861328,
//       "Location": "Rugao",
//       "Longitude": 32.25788497924805,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12054,
//       "Airport Code [ICAO]": "ZSWU",
//       "Airport Code iATA,FAA": "WHU",
//       "Airport Name": "Wuhu Air Base",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 118.408997,
//       "Location": "Wuhu",
//       "Longitude": 31.3906,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12055,
//       "Airport Code [ICAO]": "ZWSS",
//       "Airport Code iATA,FAA": "SXJ",
//       "Airport Name": "Shanshan Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 90.24749755859375,
//       "Location": "Shanshan",
//       "Longitude": 42.91170120239258,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12056,
//       "Airport Code [ICAO]": "ZYYK",
//       "Airport Code iATA,FAA": "YKH",
//       "Airport Name": "Yingkou Lanqi Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 122.3586,
//       "Location": "Yingkou",
//       "Longitude": 40.542524,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12057,
//       "Airport Code [ICAO]": "ZYYY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Shenyang Dongta Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 123.49600219726562,
//       "Location": "Shenyang",
//       "Longitude": 41.784400939941406,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12058,
//       "Airport Code [ICAO]": "UHNB",
//       "Airport Code iATA,FAA": "BQG",
//       "Airport Name": "Bogorodskoye Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Russia",
//       "Latitude": 140.448,
//       "Location": "Bogorodskoe",
//       "Longitude": 52.38,
//       "GMT Offset": "11",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12059,
//       "Airport Code [ICAO]": "SSYT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Itapiranga Airport",
//       "Altitude (Ft)": 1247,
//       "Country": "Brazil",
//       "Latitude": -53.68579864501953,
//       "Location": "Itapiranga",
//       "Longitude": -27.142499923706055,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12069,
//       "Airport Code [ICAO]": "SPAT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Aguas Calientes Airport",
//       "Altitude (Ft)": 9840,
//       "Country": "Peru",
//       "Latitude": -74.68329620361328,
//       "Location": "Aguas Calientes",
//       "Longitude": -8.833330154418945,
//       "GMT Offset": "-6",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12087,
//       "Airport Code [ICAO]": "VOHS",
//       "Airport Code iATA,FAA": "HYD",
//       "Airport Name": "Rajiv Gandhi International Airport",
//       "Altitude (Ft)": 2024,
//       "Country": "India",
//       "Latitude": 78.4298553467,
//       "Location": "Hyderabad",
//       "Longitude": 17.2313175201,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12090,
//       "Airport Code [ICAO]": "CAM9",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Vancouver International Seaplane Base",
//       "Altitude (Ft)": 0,
//       "Country": "Canada",
//       "Latitude": -123.168154,
//       "Location": "Vancouver",
//       "Longitude": 49.177047,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12096,
//       "Airport Code [ICAO]": "LSZQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bressaucourt Airport",
//       "Altitude (Ft)": 1866,
//       "Country": "Switzerland",
//       "Latitude": 7.028648,
//       "Location": "Bressaucourt-Porrentruy",
//       "Longitude": 47.392677,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12104,
//       "Airport Code [ICAO]": "DBBP",
//       "Airport Code iATA,FAA": "PKO",
//       "Airport Name": "Parakou Airport",
//       "Altitude (Ft)": 1266,
//       "Country": "Benin",
//       "Latitude": 2.609679937362671,
//       "Location": "Parakou",
//       "Longitude": 9.35768985748291,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12106,
//       "Airport Code [ICAO]": "DBBK",
//       "Airport Code iATA,FAA": "KDC",
//       "Airport Name": "Kandi Airport",
//       "Altitude (Ft)": 951,
//       "Country": "Benin",
//       "Latitude": 2.940381,
//       "Location": "Kandi",
//       "Longitude": 11.14479,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12109,
//       "Airport Code [ICAO]": "DXSK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sokodé Airport",
//       "Altitude (Ft)": 1214,
//       "Country": "Togo",
//       "Latitude": 1.153004,
//       "Location": "Sokode",
//       "Longitude": 8.994275,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12110,
//       "Airport Code [ICAO]": "DXMG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sansanné-Mango Airport",
//       "Altitude (Ft)": 460,
//       "Country": "Togo",
//       "Latitude": 0.47138214111328125,
//       "Location": "Mango",
//       "Longitude": 10.373010635375977,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12133,
//       "Airport Code [ICAO]": "EDNB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Arnbruck Airport",
//       "Altitude (Ft)": 1716,
//       "Country": "Germany",
//       "Latitude": 12.985555648803711,
//       "Location": "Arnbruck",
//       "Longitude": 49.12472152709961,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12163,
//       "Airport Code [ICAO]": "SBJR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Jacarepaguá - Roberto Marinho Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Brazil",
//       "Latitude": -43.369999,
//       "Location": "Rio de Janeiro",
//       "Longitude": -22.987499,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12165,
//       "Airport Code [ICAO]": "SKAG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hacaritama Airport",
//       "Altitude (Ft)": 545,
//       "Country": "Colombia",
//       "Latitude": -73.5814,
//       "Location": "Aguachica",
//       "Longitude": 8.247,
//       "GMT Offset": "-5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12172,
//       "Airport Code [ICAO]": "YHMB",
//       "Airport Code iATA,FAA": "HMG",
//       "Airport Name": "Hermannsburg Airport",
//       "Altitude (Ft)": 593,
//       "Country": "Australia",
//       "Latitude": 132.80499267578125,
//       "Location": "Hermannsburg",
//       "Longitude": -23.93000030517578,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12182,
//       "Airport Code [ICAO]": "YMDV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mount Davies Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 129.13299560546875,
//       "Location": "Pipalyatjara",
//       "Longitude": -26.16670036315918,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12183,
//       "Airport Code [ICAO]": "YARN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Areyonga Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 132.26699829101562,
//       "Location": "Areyonga",
//       "Longitude": -24.066699981689453,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12185,
//       "Airport Code [ICAO]": "KTCY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tracy Municipal Airport",
//       "Altitude (Ft)": 193,
//       "Country": "United States",
//       "Latitude": -121.44200134277344,
//       "Location": "Tracy",
//       "Longitude": 37.68899917602539,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12217,
//       "Airport Code [ICAO]": "YMNA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mount Allan Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 132.2169952392578,
//       "Location": "Yuelamu",
//       "Longitude": -22.274999618530273,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12218,
//       "Airport Code [ICAO]": "YEVP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Everard Park Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 132.7169952392578,
//       "Location": "Mimili",
//       "Longitude": -27.016700744628906,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12219,
//       "Airport Code [ICAO]": "YWBI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Warrabri Airport",
//       "Altitude (Ft)": 383,
//       "Country": "Australia",
//       "Latitude": 134.39700317382812,
//       "Location": "Ali-Curung",
//       "Longitude": -21,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12310,
//       "Airport Code [ICAO]": "EDLK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Krefeld-Egelsberg Airport",
//       "Altitude (Ft)": 141,
//       "Country": "Germany",
//       "Latitude": 6.587778091430664,
//       "Location": "Krefeld",
//       "Longitude": 51.3849983215332,
//       "GMT Offset": "1",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12311,
//       "Airport Code [ICAO]": "HCMB",
//       "Airport Code iATA,FAA": "BIB",
//       "Airport Name": "Baidoa Airport",
//       "Altitude (Ft)": 1820,
//       "Country": "Somalia",
//       "Latitude": 43.62860107421875,
//       "Location": "Baidoa",
//       "Longitude": 3.102220058441162,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12340,
//       "Airport Code [ICAO]": "YAMJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ampilatwatja Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 135.22999572753906,
//       "Location": "Ampilatwatja",
//       "Longitude": -21.655000686645508,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12360,
//       "Airport Code [ICAO]": "YKCS",
//       "Airport Code iATA,FAA": "KCS",
//       "Airport Name": "Kings Creek Airport",
//       "Altitude (Ft)": 615,
//       "Country": "Australia",
//       "Latitude": 131.8350067138672,
//       "Location": "Petermann",
//       "Longitude": -24.42329978942871,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12361,
//       "Airport Code [ICAO]": "YHTS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Harts Range Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 134.91799926757812,
//       "Location": "Harts Range",
//       "Longitude": -22.985000610351562,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12391,
//       "Airport Code [ICAO]": "YELL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Elliott Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 133.52989196777344,
//       "Location": "Elliott",
//       "Longitude": -17.527429580688477,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12392,
//       "Airport Code [ICAO]": "YPAY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Papunya Airport",
//       "Altitude (Ft)": 620,
//       "Country": "Australia",
//       "Latitude": 131.9029998779297,
//       "Location": "Papunya",
//       "Longitude": -23.246700286865234,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12444,
//       "Airport Code [ICAO]": "YFRG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Fregon Airport",
//       "Altitude (Ft)": 528,
//       "Country": "Australia",
//       "Latitude": 132.01699829101562,
//       "Location": "Fregon",
//       "Longitude": -26.774999618530273,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12445,
//       "Airport Code [ICAO]": "YLBG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mount Liebig Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 131.25999450683594,
//       "Location": "Mt Liebig",
//       "Longitude": -23.24329948425293,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12469,
//       "Airport Code [ICAO]": "KTZR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bolton Field",
//       "Altitude (Ft)": 905,
//       "Country": "United States",
//       "Latitude": -83.13690185546875,
//       "Location": "Columbus",
//       "Longitude": 39.90119934082031,
//       "GMT Offset": "4",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12470,
//       "Airport Code [ICAO]": "EDWZ",
//       "Airport Code iATA,FAA": "BMR",
//       "Airport Name": "Baltrum Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Germany",
//       "Latitude": 7.373332977294922,
//       "Location": "Baltrum",
//       "Longitude": 53.72472381591797,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12528,
//       "Airport Code [ICAO]": "KBVS",
//       "Airport Code iATA,FAA": "MVW",
//       "Airport Name": "Skagit Regional Airport",
//       "Altitude (Ft)": 144,
//       "Country": "United States",
//       "Latitude": -122.42099762,
//       "Location": "Skagit",
//       "Longitude": 48.4709014893,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12618,
//       "Airport Code [ICAO]": "YGDI",
//       "Airport Code iATA,FAA": "GOO",
//       "Airport Name": "Goondiwindi Airport",
//       "Altitude (Ft)": 714,
//       "Country": "Australia",
//       "Latitude": 150.32000732421875,
//       "Location": "Goondiwindi",
//       "Longitude": -28.521400451660156,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12619,
//       "Airport Code [ICAO]": "UKOE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Liman Airfield",
//       "Altitude (Ft)": 0,
//       "Country": "Ukraine",
//       "Latitude": 30.703053,
//       "Location": "Odesa",
//       "Longitude": 46.57851,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12640,
//       "Airport Code [ICAO]": "LFPL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lognes Emerainville Airport",
//       "Altitude (Ft)": 359,
//       "Country": "France",
//       "Latitude": 2.625967,
//       "Location": "Lognes",
//       "Longitude": 48.821028,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12672,
//       "Airport Code [ICAO]": "KAPT",
//       "Airport Code iATA,FAA": "APT",
//       "Airport Name": "Marion County Brown Field",
//       "Altitude (Ft)": 641,
//       "Country": "United States",
//       "Latitude": -85.585297,
//       "Location": "Jasper",
//       "Longitude": 35.060699,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12673,
//       "Airport Code [ICAO]": "KGZH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Evergreen Regional Airport/Middleton Field",
//       "Altitude (Ft)": 259,
//       "Country": "United States",
//       "Latitude": -87.043999,
//       "Location": "Evergreen",
//       "Longitude": 31.4158,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12675,
//       "Airport Code [ICAO]": "KDCU",
//       "Airport Code iATA,FAA": "DCU",
//       "Airport Name": "Pryor Field Regional Airport",
//       "Altitude (Ft)": 592,
//       "Country": "United States",
//       "Latitude": -86.94539642330001,
//       "Location": "Decatur",
//       "Longitude": 34.652698516799994,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12676,
//       "Airport Code [ICAO]": "KGLW",
//       "Airport Code iATA,FAA": "GLW",
//       "Airport Name": "Glasgow Municipal Airport",
//       "Altitude (Ft)": 716,
//       "Country": "United States",
//       "Latitude": -85.9536972,
//       "Location": "Glasgow",
//       "Longitude": 37.03179932,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12796,
//       "Airport Code [ICAO]": "KLZD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Danielson Airport",
//       "Altitude (Ft)": 238,
//       "Country": "United States",
//       "Latitude": -71.9010009765625,
//       "Location": "Danielson",
//       "Longitude": 41.819698333740234,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12844,
//       "Airport Code [ICAO]": "YDVR",
//       "Airport Code iATA,FAA": "DKV",
//       "Airport Name": "Docker River Airport",
//       "Altitude (Ft)": 589,
//       "Country": "Australia",
//       "Latitude": 129.07000732421875,
//       "Location": "Docker River",
//       "Longitude": -24.860000610351562,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12856,
//       "Airport Code [ICAO]": "KFIG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Clearfield Lawrence Airport",
//       "Altitude (Ft)": 1516,
//       "Country": "United States",
//       "Latitude": -78.41310119628906,
//       "Location": "Clearfield",
//       "Longitude": 41.04859924316406,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12873,
//       "Airport Code [ICAO]": "KRZL",
//       "Airport Code iATA,FAA": "RNZ",
//       "Airport Name": "Jasper County Airport",
//       "Altitude (Ft)": 698,
//       "Country": "United States",
//       "Latitude": -87.1826019287,
//       "Location": "Rensselaer",
//       "Longitude": 40.9478988647,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12918,
//       "Airport Code [ICAO]": "YTIT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ti Tree Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 133.4199981689453,
//       "Location": "Ti Tree",
//       "Longitude": -22.13170051574707,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12919,
//       "Airport Code [ICAO]": "USDA",
//       "Airport Code iATA,FAA": "SBT",
//       "Airport Name": "Sabetta International Airport",
//       "Altitude (Ft)": 46,
//       "Country": "Russia",
//       "Latitude": 72.052222,
//       "Location": "Sabetta",
//       "Longitude": 71.219167,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12921,
//       "Airport Code [ICAO]": "ZBAL",
//       "Airport Code iATA,FAA": "AXF",
//       "Airport Name": "Alxa Left Banner Bayanhot Airport",
//       "Altitude (Ft)": 4560,
//       "Country": "China",
//       "Latitude": 105.58858,
//       "Location": "Alxa Left Banner",
//       "Longitude": 38.74831,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12931,
//       "Airport Code [ICAO]": "SVFT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "El Fuentero Airport",
//       "Altitude (Ft)": 420,
//       "Country": "Denmark",
//       "Latitude": -69.58333587646484,
//       "Location": "Svenborg",
//       "Longitude": 7.099999904632568,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12941,
//       "Airport Code [ICAO]": "EGCS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sturgate Airfield",
//       "Altitude (Ft)": 58,
//       "Country": "Hungary",
//       "Latitude": -0.6852779984474182,
//       "Location": "Eger",
//       "Longitude": 53.381099700927734,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12945,
//       "Airport Code [ICAO]": "AYSE",
//       "Airport Code iATA,FAA": "NIS",
//       "Airport Name": "Simberi Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Papua New Guinea",
//       "Latitude": 151.997777778,
//       "Location": "Simberi Island",
//       "Longitude": -2.66222222222,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12946,
//       "Airport Code [ICAO]": "AYBU",
//       "Airport Code iATA,FAA": "BUL",
//       "Airport Name": "Bulolo Airport",
//       "Altitude (Ft)": 2240,
//       "Country": "Papua New Guinea",
//       "Latitude": 146.649541855,
//       "Location": "Bulolo",
//       "Longitude": -7.216286671410001,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12948,
//       "Airport Code [ICAO]": "YKNT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kintore Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 129.38699340820312,
//       "Location": "Kintore",
//       "Longitude": -23.264999389648438,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12950,
//       "Airport Code [ICAO]": "KTBR",
//       "Airport Code iATA,FAA": "TBR",
//       "Airport Name": "Statesboro Bulloch County Airport",
//       "Altitude (Ft)": 187,
//       "Country": "United States",
//       "Latitude": -81.73690032959999,
//       "Location": "Statesboro",
//       "Longitude": 32.4827003479,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12951,
//       "Airport Code [ICAO]": "YUTP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Utopia Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 134.7899932861328,
//       "Location": "Utopia",
//       "Longitude": -22.05500030517578,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12957,
//       "Airport Code [ICAO]": "YNRR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nyrripi Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 130.56500244140625,
//       "Location": "Nyirripi",
//       "Longitude": -22.645299911499023,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12958,
//       "Airport Code [ICAO]": "YAMT",
//       "Airport Code iATA,FAA": "AMT",
//       "Airport Name": "Amata Airport",
//       "Altitude (Ft)": 695,
//       "Country": "Australia",
//       "Latitude": 131.20700073242188,
//       "Location": "Amata",
//       "Longitude": -26.108299255371094,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12961,
//       "Airport Code [ICAO]": "NZGH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Glacier Country Heliport",
//       "Altitude (Ft)": 457,
//       "Country": "New Zealand",
//       "Latitude": 170.18,
//       "Location": "Franz Josef",
//       "Longitude": -43.38722,
//       "GMT Offset": "13",
//       "Daylight Savings": "Z",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12979,
//       "Airport Code [ICAO]": "YERL",
//       "Airport Code iATA,FAA": "EDD",
//       "Airport Name": "Erldunda Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 133.253997803,
//       "Location": "Erldunda",
//       "Longitude": -25.205799102800004,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12980,
//       "Airport Code [ICAO]": "SSHH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Helisul I Heliport",
//       "Altitude (Ft)": 732,
//       "Country": "Brazil",
//       "Latitude": -54.49361038208008,
//       "Location": "Foz do Iguassu",
//       "Longitude": -25.604167938232422,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12984,
//       "Airport Code [ICAO]": "YFNE",
//       "Airport Code iATA,FAA": "FIK",
//       "Airport Name": "Finke Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 134.582992554,
//       "Location": "Finke",
//       "Longitude": -25.5946998596,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 12987,
//       "Airport Code [ICAO]": "SBJA",
//       "Airport Code iATA,FAA": "JJG",
//       "Airport Name": "Humberto Ghizzo Bortoluzzi Regional Airport",
//       "Altitude (Ft)": 120,
//       "Country": "Brazil",
//       "Latitude": -49.0596,
//       "Location": "Jaguaruna",
//       "Longitude": -28.6753,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13004,
//       "Airport Code [ICAO]": "YNYP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nypari Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 130.23300170898438,
//       "Location": "Angatja Homeland",
//       "Longitude": -26.200000762939453,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13006,
//       "Airport Code [ICAO]": "LBHT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ihtiman Airfield",
//       "Altitude (Ft)": 2113,
//       "Country": "Bulgaria",
//       "Latitude": 23.767221,
//       "Location": "Ihtiman",
//       "Longitude": 42.42189,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13009,
//       "Airport Code [ICAO]": "EDNV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Vogtareuth Airport",
//       "Altitude (Ft)": 1535,
//       "Country": "Germany",
//       "Latitude": 12.20472240447998,
//       "Location": "Vogtareuth",
//       "Longitude": 47.946109771728516,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13011,
//       "Airport Code [ICAO]": "XXXX",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "[Duplicate] Illertissen see EDMI - ED-0425 location moved out-of-the way",
//       "Altitude (Ft)": 0,
//       "Country": "New Zealand",
//       "Latitude": 0.0001,
//       "Location": "Not Specified",
//       "Longitude": 89.5,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13016,
//       "Airport Code [ICAO]": "KEVB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "New Smyrna Beach Municipal Airport",
//       "Altitude (Ft)": 10,
//       "Country": "United States",
//       "Latitude": -80.94889831542969,
//       "Location": "New Smyrna Beach",
//       "Longitude": 29.055700302124023,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13018,
//       "Airport Code [ICAO]": "NV03",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Las Vegas Helicopters Heliport",
//       "Altitude (Ft)": 2133,
//       "Country": "United States",
//       "Latitude": -115.17400360107422,
//       "Location": "Las vegas",
//       "Longitude": 36.10810089111328,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13019,
//       "Airport Code [ICAO]": "FBDT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Delta Camp Airport",
//       "Altitude (Ft)": 3140,
//       "Country": "Botswana",
//       "Latitude": 23.0925,
//       "Location": "Delta Camp",
//       "Longitude": -19.5323,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13020,
//       "Airport Code [ICAO]": "FVWT",
//       "Airport Code iATA,FAA": "WKI",
//       "Airport Name": "Hwange (Town) Airport",
//       "Altitude (Ft)": 2500,
//       "Country": "Zimbabwe",
//       "Latitude": 26.519791,
//       "Location": "Hwange",
//       "Longitude": -18.362967,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13022,
//       "Airport Code [ICAO]": "CBC7",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Harbour (Public) Heliport",
//       "Altitude (Ft)": 2,
//       "Country": "Canada",
//       "Latitude": -123.106112257,
//       "Location": "Vancouver",
//       "Longitude": 49.2868921105,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13023,
//       "Airport Code [ICAO]": "CBF7",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Victoria Harbour (Camel Point) Heliport",
//       "Altitude (Ft)": 15,
//       "Country": "Canada",
//       "Latitude": -123.38800048828125,
//       "Location": "Victoria",
//       "Longitude": 48.41809844970703,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13032,
//       "Airport Code [ICAO]": "UNAU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Shushenskoye Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Russia",
//       "Latitude": 92.04499816894531,
//       "Location": "Shushenskoye",
//       "Longitude": 53.3849983215332,
//       "GMT Offset": "8",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13066,
//       "Airport Code [ICAO]": "LOAU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Stockerau Airport",
//       "Altitude (Ft)": 686,
//       "Country": "Austria",
//       "Latitude": 16.191699981689453,
//       "Location": "Stockerau",
//       "Longitude": 48.40999984741211,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13069,
//       "Airport Code [ICAO]": "EYKL",
//       "Airport Code iATA,FAA": "KLJ",
//       "Airport Name": "Klaipėda Airport",
//       "Altitude (Ft)": 59,
//       "Country": "Lithuania",
//       "Latitude": 21.242799758900002,
//       "Location": "Klaipeda",
//       "Longitude": 55.7118988037,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13072,
//       "Airport Code [ICAO]": "KMRJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Iowa County Airport",
//       "Altitude (Ft)": 1171,
//       "Country": "United States",
//       "Latitude": -90.236198,
//       "Location": "MINERAL POINT",
//       "Longitude": 42.886799,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13074,
//       "Airport Code [ICAO]": "KETB",
//       "Airport Code iATA,FAA": "ETB",
//       "Airport Name": "West Bend Municipal Airport",
//       "Altitude (Ft)": 887,
//       "Country": "United States",
//       "Latitude": -88.1278991699,
//       "Location": "WEST BEND",
//       "Longitude": 43.4221992493,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13075,
//       "Airport Code [ICAO]": "KGLR",
//       "Airport Code iATA,FAA": "GLR",
//       "Airport Name": "Gaylord Regional Airport",
//       "Altitude (Ft)": 1328,
//       "Country": "United States",
//       "Latitude": -84.7035980225,
//       "Location": "GAYLORD",
//       "Longitude": 45.013500213600004,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13077,
//       "Airport Code [ICAO]": "KAID",
//       "Airport Code iATA,FAA": "AID",
//       "Airport Name": "Anderson Municipal Darlington Field",
//       "Altitude (Ft)": 919,
//       "Country": "United States",
//       "Latitude": -85.6129989624,
//       "Location": "ANDERSON",
//       "Longitude": 40.10860061649999,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13078,
//       "Airport Code [ICAO]": "LYNS",
//       "Airport Code iATA,FAA": "QND",
//       "Airport Name": "Cenej Airport",
//       "Altitude (Ft)": 266,
//       "Country": "Serbia",
//       "Latitude": 19.839199,
//       "Location": "Novi Sad",
//       "Longitude": 45.385799,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13079,
//       "Airport Code [ICAO]": "BIGF",
//       "Airport Code iATA,FAA": "GUU",
//       "Airport Name": "Grundarfjörður Airport",
//       "Altitude (Ft)": 17,
//       "Country": "Iceland",
//       "Latitude": -23.224700927734375,
//       "Location": "Grundarfjordur",
//       "Longitude": 64.99140167236328,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13080,
//       "Airport Code [ICAO]": "KPDC",
//       "Airport Code iATA,FAA": "PCD",
//       "Airport Name": "Prairie Du Chien Municipal Airport",
//       "Altitude (Ft)": 661,
//       "Country": "United States",
//       "Latitude": -91.12370300293,
//       "Location": "Prairie du Chien",
//       "Longitude": 43.019298553467,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13097,
//       "Airport Code [ICAO]": "WA98",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cascade Heliport",
//       "Altitude (Ft)": 860,
//       "Country": "Indonesia",
//       "Latitude": -120.488691,
//       "Location": "Kobok",
//       "Longitude": 47.512346,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13098,
//       "Airport Code [ICAO]": "2FD7",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Air Orlando Heliport",
//       "Altitude (Ft)": 120,
//       "Country": "United States",
//       "Latitude": -81.47309875488281,
//       "Location": "Orlando",
//       "Longitude": 28.43560028076172,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13106,
//       "Airport Code [ICAO]": "EKTB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tórshavn/Bodanes Heliport",
//       "Altitude (Ft)": 68,
//       "Country": "Faroe Islands",
//       "Latitude": -6.757420063018799,
//       "Location": "Torshavn",
//       "Longitude": 62.02170181274414,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13112,
//       "Airport Code [ICAO]": "FYOW",
//       "Airport Code iATA,FAA": "OTJ",
//       "Airport Name": "Otjiwarongo Airport",
//       "Altitude (Ft)": 4859,
//       "Country": "Namibia",
//       "Latitude": 16.660800933838,
//       "Location": "Otjiwarongo",
//       "Longitude": -20.434700012207,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13114,
//       "Airport Code [ICAO]": "EGOQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "RAF Mona",
//       "Altitude (Ft)": 202,
//       "Country": "United Kingdom",
//       "Latitude": -4.37355,
//       "Location": "Mona",
//       "Longitude": 53.258598,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13120,
//       "Airport Code [ICAO]": "EDCR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Rerik-Zweedorf Airport",
//       "Altitude (Ft)": 30,
//       "Country": "Germany",
//       "Latitude": 11.64916706085205,
//       "Location": "Rerik",
//       "Longitude": 54.08194351196289,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13121,
//       "Airport Code [ICAO]": "SSVV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Fazenda Vaticano Airport",
//       "Altitude (Ft)": 1050,
//       "Country": "Brazil",
//       "Latitude": -56.11861,
//       "Location": "Cruz",
//       "Longitude": -21.294443,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13123,
//       "Airport Code [ICAO]": "KVUJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Stanly County Airport",
//       "Altitude (Ft)": 609,
//       "Country": "United States",
//       "Latitude": -80.150802612305,
//       "Location": "Albemarle",
//       "Longitude": 35.416698455811,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13124,
//       "Airport Code [ICAO]": "KEXX",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Davidson County Airport",
//       "Altitude (Ft)": 733,
//       "Country": "United States",
//       "Latitude": -80.30380249023438,
//       "Location": "Lexington",
//       "Longitude": 35.78110122680664,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13126,
//       "Airport Code [ICAO]": "WV62",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Windwood Fly-In Resort Airport",
//       "Altitude (Ft)": 3210,
//       "Country": "United States",
//       "Latitude": -79.43139648440001,
//       "Location": "Davis",
//       "Longitude": 39.055099487300005,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13127,
//       "Airport Code [ICAO]": "5NC2",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lathan Strip",
//       "Altitude (Ft)": 575,
//       "Country": "United States",
//       "Latitude": -80.61920166015625,
//       "Location": "Mocksville",
//       "Longitude": 34.87630081176758,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13130,
//       "Airport Code [ICAO]": "KAEG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Double Eagle II Airport",
//       "Altitude (Ft)": 5837,
//       "Country": "United States",
//       "Latitude": -106.79499816894531,
//       "Location": "Albuquerque",
//       "Longitude": 35.145198822021484,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13131,
//       "Airport Code [ICAO]": "KONM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Socorro Municipal Airport",
//       "Altitude (Ft)": 4875,
//       "Country": "United States",
//       "Latitude": -106.90299987792969,
//       "Location": "Socorro",
//       "Longitude": 34.022499084472656,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13135,
//       "Airport Code [ICAO]": "KVBT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bentonville Municipal-Louise M Thaden Field",
//       "Altitude (Ft)": 1296,
//       "Country": "United States",
//       "Latitude": -94.21929932,
//       "Location": "Bentonville",
//       "Longitude": 36.34569931,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13137,
//       "Airport Code [ICAO]": "KMTV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Blue Ridge Airport",
//       "Altitude (Ft)": 941,
//       "Country": "United States",
//       "Latitude": -80.01830291748047,
//       "Location": "Martinsville",
//       "Longitude": 36.630699157714844,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13138,
//       "Airport Code [ICAO]": "KMWK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mount Airy Surry County Airport",
//       "Altitude (Ft)": 1249,
//       "Country": "United States",
//       "Latitude": -80.5530014,
//       "Location": "Mount Airy",
//       "Longitude": 36.45970154,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13139,
//       "Airport Code [ICAO]": "KSKX",
//       "Airport Code iATA,FAA": "TSM",
//       "Airport Name": "Taos Regional Airport",
//       "Altitude (Ft)": 7095,
//       "Country": "United States",
//       "Latitude": -105.6719971,
//       "Location": "Taos",
//       "Longitude": 36.45819855,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13142,
//       "Airport Code [ICAO]": "KRTN",
//       "Airport Code iATA,FAA": "RTN",
//       "Airport Name": "Raton Municipal-Crews Field",
//       "Altitude (Ft)": 6352,
//       "Country": "United States",
//       "Latitude": -104.5019989,
//       "Location": "Raton",
//       "Longitude": 36.74150085,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13144,
//       "Airport Code [ICAO]": "KRCX",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Rusk County Airport",
//       "Altitude (Ft)": 1238,
//       "Country": "United States",
//       "Latitude": -91.00050354003906,
//       "Location": "Ladysmith",
//       "Longitude": 45.49679946899414,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13154,
//       "Airport Code [ICAO]": "KPPA",
//       "Airport Code iATA,FAA": "PPA",
//       "Airport Name": "Perry Lefors Field",
//       "Altitude (Ft)": 3245,
//       "Country": "United States",
//       "Latitude": -100.99600219727,
//       "Location": "Pampa",
//       "Longitude": 35.612998962402,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13155,
//       "Airport Code [ICAO]": "KOWP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "William R. Pogue Municipal Airport",
//       "Altitude (Ft)": 892,
//       "Country": "United States",
//       "Latitude": -96.15180206,
//       "Location": "Sand Springs",
//       "Longitude": 36.1753006,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13156,
//       "Airport Code [ICAO]": "KFLP",
//       "Airport Code iATA,FAA": "FLP",
//       "Airport Name": "Marion County Regional Airport",
//       "Altitude (Ft)": 719,
//       "Country": "United States",
//       "Latitude": -92.59030151,
//       "Location": "Flippin",
//       "Longitude": 36.29090118,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13158,
//       "Airport Code [ICAO]": "KBGD",
//       "Airport Code iATA,FAA": "BGD",
//       "Airport Name": "Hutchinson County Airport",
//       "Altitude (Ft)": 3055,
//       "Country": "United States",
//       "Latitude": -101.393997192,
//       "Location": "Borger",
//       "Longitude": 35.700901031499995,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13176,
//       "Airport Code [ICAO]": "LHBS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Budaörs Airfield",
//       "Altitude (Ft)": 397,
//       "Country": "Hungary",
//       "Latitude": 18.9806,
//       "Location": "Budaoers",
//       "Longitude": 47.451099,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13177,
//       "Airport Code [ICAO]": "LHJK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Jakabszállás Airport",
//       "Altitude (Ft)": 348,
//       "Country": "Hungary",
//       "Latitude": 19.605600357055664,
//       "Location": "Jakabszallas",
//       "Longitude": 46.74720001220703,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13180,
//       "Airport Code [ICAO]": "FHSH",
//       "Airport Code iATA,FAA": "HLE",
//       "Airport Name": "St. Helena Airport",
//       "Altitude (Ft)": 1017,
//       "Country": "United Kingdom",
//       "Latitude": -5.645943,
//       "Location": "Longwood",
//       "Longitude": -15.957725,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13183,
//       "Airport Code [ICAO]": "EDKB",
//       "Airport Code iATA,FAA": "BNJ",
//       "Airport Name": "Bonn-Hangelar Airport",
//       "Altitude (Ft)": 197,
//       "Country": "Germany",
//       "Latitude": 7.16333293915,
//       "Location": "Sankt-Augustin",
//       "Longitude": 50.7688903809,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13188,
//       "Airport Code [ICAO]": "UHSN",
//       "Airport Code iATA,FAA": "NGK",
//       "Airport Name": "Nogliki Airport",
//       "Altitude (Ft)": 109,
//       "Country": "Russia",
//       "Latitude": 143.139008,
//       "Location": "Nogliki",
//       "Longitude": 51.780102,
//       "GMT Offset": "11",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13189,
//       "Airport Code [ICAO]": "KIZA",
//       "Airport Code iATA,FAA": "SQA",
//       "Airport Name": "Santa Ynez Airport",
//       "Altitude (Ft)": 674,
//       "Country": "United States",
//       "Latitude": -120.0759964,
//       "Location": "Santa Ynez",
//       "Longitude": 34.60680008,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13192,
//       "Airport Code [ICAO]": "ZLDL",
//       "Airport Code iATA,FAA": "HXD",
//       "Airport Name": "Delingha Airport",
//       "Altitude (Ft)": 9843,
//       "Country": "China",
//       "Latitude": 97.268658,
//       "Location": "Haixi",
//       "Longitude": 37.125286,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13193,
//       "Airport Code [ICAO]": "ZJQH",
//       "Airport Code iATA,FAA": "BAR",
//       "Airport Name": "Qionghai Bo'ao Airport",
//       "Altitude (Ft)": 30,
//       "Country": "China",
//       "Latitude": 110.454775,
//       "Location": "Qionghai",
//       "Longitude": 19.13824,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13199,
//       "Airport Code [ICAO]": "EDVN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Northeim Airport",
//       "Altitude (Ft)": 404,
//       "Country": "Germany",
//       "Latitude": 10.039722442626953,
//       "Location": "Northeim",
//       "Longitude": 51.706390380859375,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13209,
//       "Airport Code [ICAO]": "LIDE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Reggio Emilia Airport",
//       "Altitude (Ft)": 152,
//       "Country": "Italy",
//       "Latitude": 10.6628,
//       "Location": "Reggio Emilia",
//       "Longitude": 44.698299,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13210,
//       "Airport Code [ICAO]": "YBWN",
//       "Airport Code iATA,FAA": "ZBO",
//       "Airport Name": "Bowen Airport",
//       "Altitude (Ft)": 8,
//       "Country": "Australia",
//       "Latitude": 148.21499633789062,
//       "Location": "Bowen",
//       "Longitude": -20.018299102783203,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13211,
//       "Airport Code [ICAO]": "ZBUC",
//       "Airport Code iATA,FAA": "UCB",
//       "Airport Name": "Ulanqab Jining Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 113.108056,
//       "Location": "Wulanchabu",
//       "Longitude": 41.129722,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13212,
//       "Airport Code [ICAO]": "DIOD",
//       "Airport Code iATA,FAA": "KEO",
//       "Airport Name": "Odienne Airport",
//       "Altitude (Ft)": 1365,
//       "Country": "Cote d'Ivoire",
//       "Latitude": -7.566999912261963,
//       "Location": "Odienne",
//       "Longitude": 9.5,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13214,
//       "Airport Code [ICAO]": "GUSI",
//       "Airport Code iATA,FAA": "GII",
//       "Airport Name": "Siguiri Airport",
//       "Altitude (Ft)": 1296,
//       "Country": "Guinea",
//       "Latitude": -9.1669998168945,
//       "Location": "Siguiri",
//       "Longitude": 11.432999610901,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13215,
//       "Airport Code [ICAO]": "GUNZ",
//       "Airport Code iATA,FAA": "NZE",
//       "Airport Name": "Nzérékoré Airport",
//       "Altitude (Ft)": 1657,
//       "Country": "Guinea",
//       "Latitude": -8.7017974853516,
//       "Location": "Nzerekore",
//       "Longitude": 7.8060193061829,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13227,
//       "Airport Code [ICAO]": "YBGD",
//       "Airport Code iATA,FAA": "OCM",
//       "Airport Name": "Boolgeeda",
//       "Altitude (Ft)": 1871,
//       "Country": "Australia",
//       "Latitude": 117.275,
//       "Location": "Brockman",
//       "Longitude": -22.54,
//       "GMT Offset": "8",
//       "Daylight Savings": "O",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13229,
//       "Airport Code [ICAO]": "ZGSY",
//       "Airport Code iATA,FAA": "WGN",
//       "Airport Name": "Shaoyang Wugang Airport",
//       "Altitude (Ft)": 1444,
//       "Country": "China",
//       "Latitude": 110.642,
//       "Location": "Shaoyang",
//       "Longitude": 26.802,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13230,
//       "Airport Code [ICAO]": "SNTF",
//       "Airport Code iATA,FAA": "TXF",
//       "Airport Name": "9 de Maio - Teixeira de Freitas Airport",
//       "Altitude (Ft)": 344,
//       "Country": "Brazil",
//       "Latitude": -39.66849899292,
//       "Location": "Teixeira de Freitas",
//       "Longitude": -17.524499893188,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13233,
//       "Airport Code [ICAO]": "KSPA",
//       "Airport Code iATA,FAA": "SPA",
//       "Airport Name": "Spartanburg Downtown Memorial Airport",
//       "Altitude (Ft)": 801,
//       "Country": "United States",
//       "Latitude": -81.956497192383,
//       "Location": "Spartangurg",
//       "Longitude": 34.915699005127,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13243,
//       "Airport Code [ICAO]": "WATB",
//       "Airport Code iATA,FAA": "BJW",
//       "Airport Name": "Bajawa Soa Airport",
//       "Altitude (Ft)": 4326,
//       "Country": "Indonesia",
//       "Latitude": 121.057426929,
//       "Location": "Bajawa",
//       "Longitude": -8.70743498008,
//       "GMT Offset": "8",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13248,
//       "Airport Code [ICAO]": "EDOG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Torgau-Beilrode Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Germany",
//       "Latitude": 13.051641,
//       "Location": "Gransee",
//       "Longitude": 51.570907,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13249,
//       "Airport Code [ICAO]": "FGAB",
//       "Airport Code iATA,FAA": "NBN",
//       "Airport Name": "Annobón Airport",
//       "Altitude (Ft)": 82,
//       "Country": "Equatorial Guinea",
//       "Latitude": 5.621944,
//       "Location": "San Antonio de Pale",
//       "Longitude": -1.410277,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13257,
//       "Airport Code [ICAO]": "HSRN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Renk Airport",
//       "Altitude (Ft)": 1278,
//       "Country": "China",
//       "Latitude": 32.810497283900006,
//       "Location": "Huangshan",
//       "Longitude": 11.8324692723,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13260,
//       "Airport Code [ICAO]": "FCOD",
//       "Airport Code iATA,FAA": "OLL",
//       "Airport Name": "Oyo Ollombo Airport",
//       "Altitude (Ft)": 1073,
//       "Country": "Congo (Brazzaville)",
//       "Latitude": 15.91,
//       "Location": "Oyo",
//       "Longitude": -1.226666,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13263,
//       "Airport Code [ICAO]": "CAW5",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Port Hardy Seaplane Base",
//       "Altitude (Ft)": 0,
//       "Country": "Canada",
//       "Latitude": -127.48300170898438,
//       "Location": "Port Hardy",
//       "Longitude": 50.71670150756836,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13265,
//       "Airport Code [ICAO]": "KPPF",
//       "Airport Code iATA,FAA": "PPF",
//       "Airport Name": "Tri-City Airport",
//       "Altitude (Ft)": 900,
//       "Country": "United States",
//       "Latitude": -95.5062027,
//       "Location": "Parsons",
//       "Longitude": 37.32989883,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13266,
//       "Airport Code [ICAO]": "VLFL",
//       "Airport Code iATA,FAA": "PCQ",
//       "Airport Name": "Boun Neau Airport",
//       "Altitude (Ft)": 3050,
//       "Country": "Laos",
//       "Latitude": 101.9,
//       "Location": "Phongsaly",
//       "Longitude": 21.6475,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13267,
//       "Airport Code [ICAO]": "KAYS",
//       "Airport Code iATA,FAA": "AYS",
//       "Airport Name": "Waycross Ware County Airport",
//       "Altitude (Ft)": 142,
//       "Country": "United States",
//       "Latitude": -82.39550018310001,
//       "Location": "Waycross",
//       "Longitude": 31.2490997314,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13268,
//       "Airport Code [ICAO]": "GOBD",
//       "Airport Code iATA,FAA": "DSS",
//       "Airport Name": "Blaise Diagne International Airport",
//       "Altitude (Ft)": 290,
//       "Country": "Senegal",
//       "Latitude": -17.073333,
//       "Location": "Diass",
//       "Longitude": 14.67,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13269,
//       "Airport Code [ICAO]": "KPSB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mid-State Regional Airport",
//       "Altitude (Ft)": 1948,
//       "Country": "United States",
//       "Latitude": -78.087303161621,
//       "Location": "Philipsburg",
//       "Longitude": 40.884399414062,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13271,
//       "Airport Code [ICAO]": "KPMH",
//       "Airport Code iATA,FAA": "PMH",
//       "Airport Name": "Greater Portsmouth Regional Airport",
//       "Altitude (Ft)": 663,
//       "Country": "United States",
//       "Latitude": -82.84729766850002,
//       "Location": "Portsmouth",
//       "Longitude": 38.8404998779,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13306,
//       "Airport Code [ICAO]": "YCNQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Coonawarra Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 140.8000030517578,
//       "Location": "Coonawarra",
//       "Longitude": -37.28329849243164,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13307,
//       "Airport Code [ICAO]": "YNRC",
//       "Airport Code iATA,FAA": "NAC",
//       "Airport Name": "Naracoorte Airport",
//       "Altitude (Ft)": 169,
//       "Country": "Australia",
//       "Latitude": 140.72500610351562,
//       "Location": "Naracoorte",
//       "Longitude": -36.98529815673828,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13309,
//       "Airport Code [ICAO]": "KCXU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Camilla Mitchell County Airport",
//       "Altitude (Ft)": 175,
//       "Country": "United States",
//       "Latitude": -84.23680114746094,
//       "Location": "Camilla",
//       "Longitude": 31.212900161743164,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13311,
//       "Airport Code [ICAO]": "CCZ3",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Clarenville Airport",
//       "Altitude (Ft)": 199,
//       "Country": "Canada",
//       "Latitude": -53.92390060424805,
//       "Location": "Clarenville",
//       "Longitude": 48.27470016479492,
//       "GMT Offset": "-3.5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13315,
//       "Airport Code [ICAO]": "SSZW",
//       "Airport Code iATA,FAA": "PGZ",
//       "Airport Name": "Ponta Grossa Airport - Comandante Antonio Amilton Beraldo",
//       "Altitude (Ft)": 2588,
//       "Country": "Brazil",
//       "Latitude": -50.1441,
//       "Location": "Ponta Grossa",
//       "Longitude": -25.1847,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13334,
//       "Airport Code [ICAO]": "MMPQ",
//       "Airport Code iATA,FAA": "PQM",
//       "Airport Name": "Palenque International Airport",
//       "Altitude (Ft)": 200,
//       "Country": "Mexico",
//       "Latitude": -92.015484,
//       "Location": "Palenque",
//       "Longitude": 17.533153,
//       "GMT Offset": "-5",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13335,
//       "Airport Code [ICAO]": "YCDR",
//       "Airport Code iATA,FAA": "CUD",
//       "Airport Name": "Caloundra Airport",
//       "Altitude (Ft)": 12,
//       "Country": "Australia",
//       "Latitude": 153.10000610351562,
//       "Location": "Caloundra",
//       "Longitude": -26.799999237060547,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13336,
//       "Airport Code [ICAO]": "PFCL",
//       "Airport Code iATA,FAA": "CLP",
//       "Airport Name": "Clarks Point Airport",
//       "Altitude (Ft)": 80,
//       "Country": "United States",
//       "Latitude": -158.529007,
//       "Location": "Clarks Point",
//       "Longitude": 58.83369827,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13339,
//       "Airport Code [ICAO]": "CBA9",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ospika Airport",
//       "Altitude (Ft)": 2300,
//       "Country": "Canada",
//       "Latitude": -124.052001953125,
//       "Location": "Williston Lake",
//       "Longitude": 56.275001525878906,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13341,
//       "Airport Code [ICAO]": "CCH4",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Charlottetown Airport",
//       "Altitude (Ft)": 209,
//       "Country": "Canada",
//       "Latitude": -56.1156005859375,
//       "Location": "Charlottetown",
//       "Longitude": 52.76499938964844,
//       "GMT Offset": "-3.5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13342,
//       "Airport Code [ICAO]": "CDL7",
//       "Airport Code iATA,FAA": "JOJ",
//       "Airport Name": "Doris Lake",
//       "Altitude (Ft)": 50,
//       "Country": "Canada",
//       "Latitude": -106.585281372,
//       "Location": "Doris Lake",
//       "Longitude": 68.1252746582,
//       "GMT Offset": "-7",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13344,
//       "Airport Code [ICAO]": "MNCE",
//       "Airport Code iATA,FAA": "ECI",
//       "Airport Name": "Costa Esmeralda Airport",
//       "Altitude (Ft)": 84,
//       "Country": "Nicaragua",
//       "Latitude": -86.033361,
//       "Location": "Tola",
//       "Longitude": 11.427542,
//       "GMT Offset": "-6",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13346,
//       "Airport Code [ICAO]": "MDMC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Monte Cristi Airport",
//       "Altitude (Ft)": 121,
//       "Country": "Dominican Republic",
//       "Latitude": -71.6453018188,
//       "Location": "Montecristi",
//       "Longitude": 19.865299224900003,
//       "GMT Offset": "-4",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13347,
//       "Airport Code [ICAO]": "MSSM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "El Papalon Airport",
//       "Altitude (Ft)": 283,
//       "Country": "El Salvador",
//       "Latitude": -88.12699890136719,
//       "Location": "San Miguel",
//       "Longitude": 13.444100379943848,
//       "GMT Offset": "-6",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13354,
//       "Airport Code [ICAO]": "KSZT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sandpoint Airport",
//       "Altitude (Ft)": 2131,
//       "Country": "United States",
//       "Latitude": -116.55999755859,
//       "Location": "Sandpoint",
//       "Longitude": 48.299499511719,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13355,
//       "Airport Code [ICAO]": "BGAT",
//       "Airport Code iATA,FAA": "QGQ",
//       "Airport Name": "Attu Heliport",
//       "Altitude (Ft)": 32,
//       "Country": "Greenland",
//       "Latitude": -53.6218696833,
//       "Location": "Attu",
//       "Longitude": 67.9406628551,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13356,
//       "Airport Code [ICAO]": "BGKA",
//       "Airport Code iATA,FAA": "QPW",
//       "Airport Name": "Kangaatsiaq Heliport",
//       "Altitude (Ft)": 17,
//       "Country": "Greenland",
//       "Latitude": -53.4602075815,
//       "Location": "Kangaatsiaq",
//       "Longitude": 68.3126574861,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13357,
//       "Airport Code [ICAO]": "BGKT",
//       "Airport Code iATA,FAA": "QJE",
//       "Airport Name": "Kitsissuarsuit Heliport",
//       "Altitude (Ft)": 18,
//       "Country": "Greenland",
//       "Latitude": -53.123295307199996,
//       "Location": "Kitsissuarsuit",
//       "Longitude": 68.85792599759999,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13358,
//       "Airport Code [ICAO]": "BGNK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Niaqornaarsuk Heliport",
//       "Altitude (Ft)": 152,
//       "Country": "Greenland",
//       "Latitude": -52.852148,
//       "Location": "Niaqornaarsuk",
//       "Longitude": 68.236381,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13359,
//       "Airport Code [ICAO]": "BGIL",
//       "Airport Code iATA,FAA": "XIQ",
//       "Airport Name": "Ilimanaq Heliport",
//       "Altitude (Ft)": 52,
//       "Country": "Greenland",
//       "Latitude": -51.1143153906,
//       "Location": "Ilimanaq",
//       "Longitude": 69.0809150007,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13360,
//       "Airport Code [ICAO]": "BGQE",
//       "Airport Code iATA,FAA": "QQT",
//       "Airport Name": "Qeqertaq Heliport",
//       "Altitude (Ft)": 69,
//       "Country": "Greenland",
//       "Latitude": -51.3009166718,
//       "Location": "Qeqertaq",
//       "Longitude": 69.9958611087,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13361,
//       "Airport Code [ICAO]": "BGSQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Saqqaq Heliport",
//       "Altitude (Ft)": 38,
//       "Country": "Greenland",
//       "Latitude": -51.932142,
//       "Location": "Saqqaq",
//       "Longitude": 70.011448,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13363,
//       "Airport Code [ICAO]": "BGSV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Savissivik Heliport",
//       "Altitude (Ft)": 24,
//       "Country": "Greenland",
//       "Latitude": -65.1176834106,
//       "Location": "Savissivik",
//       "Longitude": 76.01861346460001,
//       "GMT Offset": "-4",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13364,
//       "Airport Code [ICAO]": "BGSI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Siorapaluk Heliport",
//       "Altitude (Ft)": 102,
//       "Country": "Greenland",
//       "Latitude": -70.6386566162,
//       "Location": "Siorapaluk",
//       "Longitude": 77.7865173475,
//       "GMT Offset": "-4",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13365,
//       "Airport Code [ICAO]": "BGAG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Aappilattoq (Qaasuitsup) Heliport",
//       "Altitude (Ft)": 42,
//       "Country": "Greenland",
//       "Latitude": -55.5962866545,
//       "Location": "Aappilattoq",
//       "Longitude": 72.8870298223,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13366,
//       "Airport Code [ICAO]": "BGIN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Innarsuit Heliport",
//       "Altitude (Ft)": 95,
//       "Country": "Greenland",
//       "Latitude": -56.0108166933,
//       "Location": "Innaarsuit",
//       "Longitude": 73.1998953377,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13367,
//       "Airport Code [ICAO]": "BGKS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kangersuatsiaq Heliport",
//       "Altitude (Ft)": 112,
//       "Country": "Greenland",
//       "Latitude": -55.53658604619999,
//       "Location": "Kangersuatsiaq",
//       "Longitude": 72.38109155069999,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13368,
//       "Airport Code [ICAO]": "BGKQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kullorsuaq Heliport",
//       "Altitude (Ft)": 148,
//       "Country": "Greenland",
//       "Latitude": -57.226828336699995,
//       "Location": "Kullorsuaq",
//       "Longitude": 74.5780498288,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13369,
//       "Airport Code [ICAO]": "BGNU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nuussuaq Heliport",
//       "Altitude (Ft)": 184,
//       "Country": "Greenland",
//       "Latitude": -57.065037488899996,
//       "Location": "Nuussuaq",
//       "Longitude": 74.1098528514,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13370,
//       "Airport Code [ICAO]": "BGTA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tasiusaq (Qaasuitsup) Heliport",
//       "Altitude (Ft)": 181,
//       "Country": "Greenland",
//       "Latitude": -56.060279846200004,
//       "Location": "Tasiusaq",
//       "Longitude": 73.37305450439999,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13371,
//       "Airport Code [ICAO]": "BGKL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Upernavik Kujalleq Heliport",
//       "Altitude (Ft)": 92,
//       "Country": "Greenland",
//       "Latitude": -55.5309855938,
//       "Location": "Upernavik Kujalleq",
//       "Longitude": 72.1527425265,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13372,
//       "Airport Code [ICAO]": "BGAQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Aappilattoq (Kujalleq) Heliport",
//       "Altitude (Ft)": 30,
//       "Country": "Greenland",
//       "Latitude": -44.286916,
//       "Location": "Aappilattoq",
//       "Longitude": 60.148357,
//       "GMT Offset": "-3",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13373,
//       "Airport Code [ICAO]": "BGTQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tasiusaq (Kujalleq) Heliport",
//       "Altitude (Ft)": 21,
//       "Country": "Greenland",
//       "Latitude": -44.8134469986,
//       "Location": "Tasiusaq",
//       "Longitude": 60.1943053097,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13374,
//       "Airport Code [ICAO]": "BGFD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Narsaq Kujalleq Heliport",
//       "Altitude (Ft)": 44,
//       "Country": "Greenland",
//       "Latitude": -44.656935,
//       "Location": "Narsaq Kujalleq",
//       "Longitude": 60.004694,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13375,
//       "Airport Code [ICAO]": "BGIT",
//       "Airport Code iATA,FAA": "QJI",
//       "Airport Name": "Ikamiut Heliport",
//       "Altitude (Ft)": 49,
//       "Country": "Greenland",
//       "Latitude": -51.8322622776,
//       "Location": "Ikamiut",
//       "Longitude": 68.6342530984,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13377,
//       "Airport Code [ICAO]": "BGAS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ammassivik Heliport",
//       "Altitude (Ft)": 71,
//       "Country": "Greenland",
//       "Latitude": -45.382445,
//       "Location": "Ammassivik",
//       "Longitude": 60.597376,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13378,
//       "Airport Code [ICAO]": "BGET",
//       "Airport Code iATA,FAA": "QFG",
//       "Airport Name": "Eqalugaarsuit Heliport",
//       "Altitude (Ft)": 9,
//       "Country": "Greenland",
//       "Latitude": -45.9140619636,
//       "Location": "Eqalugaarsuit",
//       "Longitude": 60.6197196536,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13379,
//       "Airport Code [ICAO]": "BGQT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Qassimiut Heliport",
//       "Altitude (Ft)": 29,
//       "Country": "Greenland",
//       "Latitude": -47.156249284699996,
//       "Location": "Qassimiut",
//       "Longitude": 60.7809592284,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13380,
//       "Airport Code [ICAO]": "BGTN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tiniteqilaaq Heliport",
//       "Altitude (Ft)": 15,
//       "Country": "Greenland",
//       "Latitude": -37.7834093571,
//       "Location": "Tiniteqilaaq",
//       "Longitude": 65.8920273392,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13381,
//       "Airport Code [ICAO]": "BGIS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Isortoq Heliport",
//       "Altitude (Ft)": 92,
//       "Country": "Greenland",
//       "Latitude": -38.9765524864,
//       "Location": "Isortoq",
//       "Longitude": 65.5477923903,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13382,
//       "Airport Code [ICAO]": "BGKM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kuummiut Heliport",
//       "Altitude (Ft)": 89,
//       "Country": "Greenland",
//       "Latitude": -36.9979190826,
//       "Location": "Kuummiit",
//       "Longitude": 65.8639347361,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13383,
//       "Airport Code [ICAO]": "BGSG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Sermiligaaq Heliport",
//       "Altitude (Ft)": 33,
//       "Country": "Greenland",
//       "Latitude": -36.3782536983,
//       "Location": "Sermiligaaq",
//       "Longitude": 65.9059197626,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13384,
//       "Airport Code [ICAO]": "BGIK",
//       "Airport Code iATA,FAA": "QRY",
//       "Airport Name": "Ikerassaarsuk Heliport",
//       "Altitude (Ft)": 165,
//       "Country": "Greenland",
//       "Latitude": -53.4414589405,
//       "Location": "Ikerasaarsuk",
//       "Longitude": 68.14088100629999,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13385,
//       "Airport Code [ICAO]": "BGLL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Illorsuit Heliport",
//       "Altitude (Ft)": 154,
//       "Country": "Greenland",
//       "Latitude": -53.5628771782,
//       "Location": "Illorsuit",
//       "Longitude": 71.2419440428,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13386,
//       "Airport Code [ICAO]": "BGNQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nuugaatsiaq Heliport",
//       "Altitude (Ft)": 57,
//       "Country": "Greenland",
//       "Latitude": -53.205038309100004,
//       "Location": "Nuugaatsiaq",
//       "Longitude": 71.5387687528,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13387,
//       "Airport Code [ICAO]": "BGST",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Saattut Heliport",
//       "Altitude (Ft)": 131,
//       "Country": "Greenland",
//       "Latitude": -51.6312897205,
//       "Location": "Saattut",
//       "Longitude": 70.8111719824,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13388,
//       "Airport Code [ICAO]": "BGIA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ikerasak Heliport",
//       "Altitude (Ft)": 66,
//       "Country": "Greenland",
//       "Latitude": -51.3030838966,
//       "Location": "Ikerasak",
//       "Longitude": 70.4981453548,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13389,
//       "Airport Code [ICAO]": "BGNT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Niaqornat Heliport",
//       "Altitude (Ft)": 10,
//       "Country": "Greenland",
//       "Latitude": -53.6629450321,
//       "Location": "Niaqornat",
//       "Longitude": 70.7893846882,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13390,
//       "Airport Code [ICAO]": "BGUT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ukkusissat Heliport",
//       "Altitude (Ft)": 223,
//       "Country": "Greenland",
//       "Latitude": -51.8900156021,
//       "Location": "Ukkusissat",
//       "Longitude": 71.0494384946,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13397,
//       "Airport Code [ICAO]": "SWHP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Olhos D`água Airport",
//       "Altitude (Ft)": 1506,
//       "Country": "Brazil",
//       "Latitude": -52.152222,
//       "Location": "Agua Boa",
//       "Longitude": -14.019444,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13398,
//       "Airport Code [ICAO]": "SJNP",
//       "Airport Code iATA,FAA": "NPR",
//       "Airport Name": "Novo Progresso Airport",
//       "Altitude (Ft)": 794,
//       "Country": "Brazil",
//       "Latitude": -55.400833,
//       "Location": "Novo Progresso",
//       "Longitude": -7.125833,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13399,
//       "Airport Code [ICAO]": "SBSO",
//       "Airport Code iATA,FAA": "SMT",
//       "Airport Name": "Adolino Bedin Regional Airport",
//       "Altitude (Ft)": 1266,
//       "Country": "Brazil",
//       "Latitude": -55.672341,
//       "Location": "Sorriso",
//       "Longitude": -12.479177,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13400,
//       "Airport Code [ICAO]": "SWKQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Serra da Capivara Airport",
//       "Altitude (Ft)": 1362,
//       "Country": "Brazil",
//       "Latitude": -42.644444,
//       "Location": "Sao Raimundo Nonato",
//       "Longitude": -9.082778,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13401,
//       "Airport Code [ICAO]": "SGEN",
//       "Airport Code iATA,FAA": "ENO",
//       "Airport Name": "Encarnación Airport",
//       "Altitude (Ft)": 659,
//       "Country": "Paraguay",
//       "Latitude": -55.837495,
//       "Location": "Encarnacion",
//       "Longitude": -27.227366,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13402,
//       "Airport Code [ICAO]": "SMAM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Amatopo Airstrip",
//       "Altitude (Ft)": 0,
//       "Country": "Suriname",
//       "Latitude": -57.633958,
//       "Location": "Amatopo",
//       "Longitude": 3.547885,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13403,
//       "Airport Code [ICAO]": "SMPE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Poeketi Airstrip",
//       "Altitude (Ft)": 0,
//       "Country": "Suriname",
//       "Latitude": -54.623611,
//       "Location": "Poeketi",
//       "Longitude": 4.126389,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13404,
//       "Airport Code [ICAO]": "SMGH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Godo Holo Airstrip",
//       "Altitude (Ft)": 280,
//       "Country": "Suriname",
//       "Latitude": -54.7861,
//       "Location": "Pikienkondre of Miranda",
//       "Longitude": 4.0583,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13405,
//       "Airport Code [ICAO]": "EKAT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Anholt Airport",
//       "Altitude (Ft)": 23,
//       "Country": "Denmark",
//       "Latitude": 11.5558996201,
//       "Location": "Anholt",
//       "Longitude": 56.698898315399994,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13411,
//       "Airport Code [ICAO]": "LFDP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "St Pierre d'Oléron Airfield",
//       "Altitude (Ft)": 20,
//       "Country": "France",
//       "Latitude": -1.31611,
//       "Location": "Saint Pierre d'Oleron",
//       "Longitude": 45.959202,
//       "GMT Offset": "-1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13412,
//       "Airport Code [ICAO]": "LFGQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Semur En Auxois Airfield",
//       "Altitude (Ft)": 1053,
//       "Country": "France",
//       "Latitude": 4.34417,
//       "Location": "Semur en Auxois",
//       "Longitude": 47.481899,
//       "GMT Offset": "-1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13415,
//       "Airport Code [ICAO]": "UASU",
//       "Airport Code iATA,FAA": "UZR",
//       "Airport Name": "Urzhar Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Kazakhstan",
//       "Latitude": 81.66521,
//       "Location": "Urzhar",
//       "Longitude": 47.09115,
//       "GMT Offset": "6",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13416,
//       "Airport Code [ICAO]": "ENPY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Pyramiden Heliport",
//       "Altitude (Ft)": 6,
//       "Country": "Norway",
//       "Latitude": 16.337208,
//       "Location": "Pyramiden",
//       "Longitude": 78.652322,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13421,
//       "Airport Code [ICAO]": "UMMI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lipki Air Base",
//       "Altitude (Ft)": 0,
//       "Country": "Belarus",
//       "Latitude": 27.708775,
//       "Location": "Minsk",
//       "Longitude": 53.913545,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13422,
//       "Airport Code [ICAO]": "LSEZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Zermatt  Heliport",
//       "Altitude (Ft)": 0,
//       "Country": "Switzerland",
//       "Latitude": 7.753366470336914,
//       "Location": "Zermatt",
//       "Longitude": 46.029319763183594,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13423,
//       "Airport Code [ICAO]": "UHPX",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Nikolskoye Airport",
//       "Altitude (Ft)": 49,
//       "Country": "Russia",
//       "Latitude": 166.04833984375,
//       "Location": "Nikolskoye",
//       "Longitude": 55.1783332824707,
//       "GMT Offset": "12",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13424,
//       "Airport Code [ICAO]": "UHPT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Tilichiki Airport",
//       "Altitude (Ft)": 7,
//       "Country": "Russia",
//       "Latitude": 166.0260009765625,
//       "Location": "Tilichiki",
//       "Longitude": 60.3828010559082,
//       "GMT Offset": "12",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13425,
//       "Airport Code [ICAO]": "UENN",
//       "Airport Code iATA,FAA": "NYR",
//       "Airport Name": "Nyurba Airport",
//       "Altitude (Ft)": 394,
//       "Country": "Russia",
//       "Latitude": 118.336998,
//       "Location": "Nyurba",
//       "Longitude": 63.294998,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13426,
//       "Airport Code [ICAO]": "UEBS",
//       "Airport Code iATA,FAA": "SUK",
//       "Airport Name": "Sakkyryr Airport",
//       "Altitude (Ft)": 1686,
//       "Country": "Russia",
//       "Latitude": 130.394,
//       "Location": "Batagay-Alyta",
//       "Longitude": 67.792,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13427,
//       "Airport Code [ICAO]": "UEBT",
//       "Airport Code iATA,FAA": "UKG",
//       "Airport Name": "Ust-Kuyga Airport",
//       "Altitude (Ft)": 315,
//       "Country": "Russia",
//       "Latitude": 135.64500427246,
//       "Location": "Ust-Kuyga",
//       "Longitude": 70.011001586914,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13428,
//       "Airport Code [ICAO]": "UENI",
//       "Airport Code iATA,FAA": "VHV",
//       "Airport Name": "Verkhnevilyuisk Airport",
//       "Altitude (Ft)": 411,
//       "Country": "Russia",
//       "Latitude": 120.26916503906,
//       "Location": "Verkhnevilyuysk",
//       "Longitude": 63.458057403564,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13429,
//       "Airport Code [ICAO]": "UHSM",
//       "Airport Code iATA,FAA": "DEE",
//       "Airport Name": "Mendeleyevo Airport",
//       "Altitude (Ft)": 584,
//       "Country": "Russia",
//       "Latitude": 145.682998657,
//       "Location": "Yuzhno-Kurilsk",
//       "Longitude": 43.9584007263,
//       "GMT Offset": "11",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13430,
//       "Airport Code [ICAO]": "USMQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Yamburg Airport",
//       "Altitude (Ft)": 108,
//       "Country": "Russia",
//       "Latitude": 75.099998,
//       "Location": "Yamburg",
//       "Longitude": 67.986664,
//       "GMT Offset": "5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13431,
//       "Airport Code [ICAO]": "UHSK",
//       "Airport Code iATA,FAA": "EKS",
//       "Airport Name": "Shakhtyorsk Airport",
//       "Altitude (Ft)": 50,
//       "Country": "Russia",
//       "Latitude": 142.082993,
//       "Location": "Shakhtyorsk",
//       "Longitude": 49.1903,
//       "GMT Offset": "11",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13432,
//       "Airport Code [ICAO]": "UECT",
//       "Airport Code iATA,FAA": "TLK",
//       "Airport Name": "Talakan Airport",
//       "Altitude (Ft)": 1329,
//       "Country": "Russia",
//       "Latitude": 111.044444,
//       "Location": "Talakan",
//       "Longitude": 59.876389,
//       "GMT Offset": "9",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13433,
//       "Airport Code [ICAO]": "UIKM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mama Airport",
//       "Altitude (Ft)": 659,
//       "Country": "Russia",
//       "Latitude": 112.88999938964844,
//       "Location": "Mama",
//       "Longitude": 58.313331604003906,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13435,
//       "Airport Code [ICAO]": "EKAC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Aarhus Seaplne Terminal",
//       "Altitude (Ft)": 0,
//       "Country": "Denmark",
//       "Latitude": 10.248428,
//       "Location": "Aarhus",
//       "Longitude": 56.151717,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13436,
//       "Airport Code [ICAO]": "KVLL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Oakland Troy Airport",
//       "Altitude (Ft)": 727,
//       "Country": "United States",
//       "Latitude": -83.17790222,
//       "Location": "Troy",
//       "Longitude": 42.54290009,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13437,
//       "Airport Code [ICAO]": "CYSA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Stratford Municipal Airport",
//       "Altitude (Ft)": 1215,
//       "Country": "Canada",
//       "Latitude": -80.93440246579999,
//       "Location": "Stratford",
//       "Longitude": 43.415599823,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13438,
//       "Airport Code [ICAO]": "CYES",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Edmundston Airport",
//       "Altitude (Ft)": 490,
//       "Country": "Canada",
//       "Latitude": -68.48190307617188,
//       "Location": "Edmundston",
//       "Longitude": 47.4906005859375,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13439,
//       "Airport Code [ICAO]": "PADG",
//       "Airport Code iATA,FAA": "RDB",
//       "Airport Name": "Red Dog Airport",
//       "Altitude (Ft)": 969,
//       "Country": "United States",
//       "Latitude": -162.899002075,
//       "Location": "Red Dog",
//       "Longitude": 68.03209686279999,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13447,
//       "Airport Code [ICAO]": "FCOI",
//       "Airport Code iATA,FAA": "ION",
//       "Airport Name": "Impfondo Airport",
//       "Altitude (Ft)": 1099,
//       "Country": "Congo (Brazzaville)",
//       "Latitude": 18.066999435424805,
//       "Location": "Impfondo",
//       "Longitude": 1.6169999837875366,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13448,
//       "Airport Code [ICAO]": "DAOY",
//       "Airport Code iATA,FAA": "EBH",
//       "Airport Name": "El Bayadh Airport",
//       "Altitude (Ft)": 4493,
//       "Country": "Algeria",
//       "Latitude": 1.0925,
//       "Location": "El Bayadh",
//       "Longitude": 33.721666666699996,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13451,
//       "Airport Code [ICAO]": "HLZN",
//       "Airport Code iATA,FAA": "ZIS",
//       "Airport Name": "Alzintan Airport",
//       "Altitude (Ft)": 2080,
//       "Country": "Libya",
//       "Latitude": 12.25006,
//       "Location": "Zintan",
//       "Longitude": 31.774878,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13452,
//       "Airport Code [ICAO]": "FWMY",
//       "Airport Code iATA,FAA": "MYZ",
//       "Airport Name": "Monkey Bay Airport",
//       "Altitude (Ft)": 1580,
//       "Country": "Malawi",
//       "Latitude": 34.9197006226,
//       "Location": "Monkey Bay",
//       "Longitude": -14.083600044299999,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13453,
//       "Airport Code [ICAO]": "EPSU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Suwałki Airport",
//       "Altitude (Ft)": 581,
//       "Country": "Poland",
//       "Latitude": 22.899200439453125,
//       "Location": "Suwalki",
//       "Longitude": 54.07279968261719,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13454,
//       "Airport Code [ICAO]": "DNBK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Srr Ahmadu Bello International Airport",
//       "Altitude (Ft)": 775,
//       "Country": "Nigeria",
//       "Latitude": 4.369445,
//       "Location": "Birnin Kebbi",
//       "Longitude": 12.480556,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13455,
//       "Airport Code [ICAO]": "DNBC",
//       "Airport Code iATA,FAA": "BCU",
//       "Airport Name": "Sir Abubakar Tafawa Balewa International Airport",
//       "Altitude (Ft)": 1965,
//       "Country": "Nigeria",
//       "Latitude": 9.744,
//       "Location": "Bauchi",
//       "Longitude": 10.482833,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13456,
//       "Airport Code [ICAO]": "DNGO",
//       "Airport Code iATA,FAA": "GMO",
//       "Airport Name": "Gombe Lawanti International Airport",
//       "Altitude (Ft)": 1590,
//       "Country": "Nigeria",
//       "Latitude": 10.896388888899999,
//       "Location": "Gombe",
//       "Longitude": 10.298333333299999,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13457,
//       "Airport Code [ICAO]": "DNDS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Dutse International Airport",
//       "Altitude (Ft)": 1365,
//       "Country": "Nigeria",
//       "Latitude": 9.311667,
//       "Location": "Dutse",
//       "Longitude": 11.795168,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13458,
//       "Airport Code [ICAO]": "DNJA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Jalingo Airport",
//       "Altitude (Ft)": 685,
//       "Country": "Nigeria",
//       "Latitude": 11.27946,
//       "Location": "Jalingo",
//       "Longitude": 8.900587,
//       "GMT Offset": "1",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13460,
//       "Airport Code [ICAO]": "GODK",
//       "Airport Code iATA,FAA": "KDA",
//       "Airport Name": "Kolda North Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Senegal",
//       "Latitude": -14.968099594116211,
//       "Location": "Kolda",
//       "Longitude": 12.898500442504883,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13463,
//       "Airport Code [ICAO]": "FDSK",
//       "Airport Code iATA,FAA": "SHO",
//       "Airport Name": "King Mswati III International Airport",
//       "Altitude (Ft)": 1092,
//       "Country": "Swaziland",
//       "Latitude": 31.716944,
//       "Location": "Manzini",
//       "Longitude": -26.358611,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13465,
//       "Airport Code [ICAO]": "HTKI",
//       "Airport Code iATA,FAA": "KIY",
//       "Airport Name": "Kilwa Masoko Airport",
//       "Altitude (Ft)": 50,
//       "Country": "Tanzania",
//       "Latitude": 39.508619,
//       "Location": "Kilwa Masoko",
//       "Longitude": -8.91123,
//       "GMT Offset": "3",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13466,
//       "Airport Code [ICAO]": "HTWK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "West Kilimanjaro Airport",
//       "Altitude (Ft)": 4300,
//       "Country": "Tanzania",
//       "Latitude": 37,
//       "Location": "Kilimanjaro",
//       "Longitude": -3.049999952316284,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13467,
//       "Airport Code [ICAO]": "HTMS",
//       "Airport Code iATA,FAA": "QSI",
//       "Airport Name": "Moshi Airport",
//       "Altitude (Ft)": 2801,
//       "Country": "Tanzania",
//       "Latitude": 37.326900482177734,
//       "Location": "Moshi",
//       "Longitude": -3.3633298873901367,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13468,
//       "Airport Code [ICAO]": "FLMA",
//       "Airport Code iATA,FAA": "MNS",
//       "Airport Name": "Mansa Airport",
//       "Altitude (Ft)": 4101,
//       "Country": "Zambia",
//       "Latitude": 28.872600555419922,
//       "Location": "Mansa",
//       "Longitude": -11.13700008392334,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13469,
//       "Airport Code [ICAO]": "OAGN",
//       "Airport Code iATA,FAA": "GZI",
//       "Airport Name": "Ghazni Airport",
//       "Altitude (Ft)": 7150,
//       "Country": "Afghanistan",
//       "Latitude": 68.412902832,
//       "Location": "Ghazni",
//       "Longitude": 33.5312004089,
//       "GMT Offset": "4.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13470,
//       "Airport Code [ICAO]": "ZYBA",
//       "Airport Code iATA,FAA": "DBC",
//       "Airport Name": "Baicheng Chang'an Airport",
//       "Altitude (Ft)": 480,
//       "Country": "China",
//       "Latitude": 123.019722,
//       "Location": "Baicheng",
//       "Longitude": 45.505278,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13471,
//       "Airport Code [ICAO]": "ZLLN",
//       "Airport Code iATA,FAA": "LNL",
//       "Airport Name": "Longnan Chengzhou Airport",
//       "Altitude (Ft)": 3707,
//       "Country": "China",
//       "Latitude": 105.797,
//       "Location": "Longnan",
//       "Longitude": 33.788,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13472,
//       "Airport Code [ICAO]": "ZSSR",
//       "Airport Code iATA,FAA": "SQD",
//       "Airport Name": "Shangrao Sanqingshan Airport",
//       "Altitude (Ft)": 340,
//       "Country": "China",
//       "Latitude": 117.9643,
//       "Location": "Shangrao",
//       "Longitude": 28.3797,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13473,
//       "Airport Code [ICAO]": "ZYSQ",
//       "Airport Code iATA,FAA": "YSQ",
//       "Airport Name": "Songyuan Chaganhu Airport",
//       "Altitude (Ft)": 459,
//       "Country": "China",
//       "Latitude": 124.550178,
//       "Location": "Songyuan",
//       "Longitude": 44.938114,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13474,
//       "Airport Code [ICAO]": "ZYJS",
//       "Airport Code iATA,FAA": "JSJ",
//       "Airport Name": "Jiansanjiang Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 132.660278,
//       "Location": "Jiansanjiang",
//       "Longitude": 47.11,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13476,
//       "Airport Code [ICAO]": "ZUMT",
//       "Airport Code iATA,FAA": "WMT",
//       "Airport Name": "Zunyi Maotai Airport",
//       "Altitude (Ft)": 4068,
//       "Country": "China",
//       "Latitude": 106.33268,
//       "Location": "Zunyi",
//       "Longitude": 27.81638,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13477,
//       "Airport Code [ICAO]": "ZULB",
//       "Airport Code iATA,FAA": "LLB",
//       "Airport Name": "Libo Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 107.961667,
//       "Location": "Libo",
//       "Longitude": 25.4525,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13478,
//       "Airport Code [ICAO]": "ZBCD",
//       "Airport Code iATA,FAA": "CDE",
//       "Airport Name": "Chengde Puning Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 118.073889,
//       "Location": "Chengde",
//       "Longitude": 41.1225,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13479,
//       "Airport Code [ICAO]": "ZYDU",
//       "Airport Code iATA,FAA": "DTU",
//       "Airport Name": "Wudalianchi Dedu Airport",
//       "Altitude (Ft)": 984,
//       "Country": "China",
//       "Latitude": 126.133,
//       "Location": "Wudalianchi",
//       "Longitude": 48.445,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13480,
//       "Airport Code [ICAO]": "ZBEN",
//       "Airport Code iATA,FAA": "EJN",
//       "Airport Name": "Ejin Banner-Taolai Airport",
//       "Altitude (Ft)": 3077,
//       "Country": "China",
//       "Latitude": 101.0005,
//       "Location": "Ejin Banner",
//       "Longitude": 42.0155,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13481,
//       "Airport Code [ICAO]": "ZBAR",
//       "Airport Code iATA,FAA": "RHT",
//       "Airport Name": "Alxa Right Banner Badanjilin Airport",
//       "Altitude (Ft)": 4659,
//       "Country": "China",
//       "Latitude": 101.546,
//       "Location": "Alxa Right Banner",
//       "Longitude": 39.225,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13482,
//       "Airport Code [ICAO]": "ZBHZ",
//       "Airport Code iATA,FAA": "HUO",
//       "Airport Name": "Holingol Huolinhe Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 119.407222,
//       "Location": "Holingol",
//       "Longitude": 45.487222,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13483,
//       "Airport Code [ICAO]": "ZLGL",
//       "Airport Code iATA,FAA": "GMQ",
//       "Airport Name": "Golog Maqin Airport",
//       "Altitude (Ft)": 12426,
//       "Country": "China",
//       "Latitude": 100.301144,
//       "Location": "Golog",
//       "Longitude": 34.418066,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13485,
//       "Airport Code [ICAO]": "ZWSC",
//       "Airport Code iATA,FAA": "QSZ",
//       "Airport Name": "Yeerqiang Airport",
//       "Altitude (Ft)": 4232,
//       "Country": "China",
//       "Latitude": 77.075192,
//       "Location": "Yarkant",
//       "Longitude": 38.281055,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13487,
//       "Airport Code [ICAO]": "VETJ",
//       "Airport Code iATA,FAA": "TEI",
//       "Airport Name": "Tezu Airport",
//       "Altitude (Ft)": 800,
//       "Country": "India",
//       "Latitude": 96.1343994141,
//       "Location": "Tezu",
//       "Longitude": 27.9412002563,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13488,
//       "Airport Code [ICAO]": "ZPCW",
//       "Airport Code iATA,FAA": "CWJ",
//       "Airport Name": "Cangyuan Washan Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 99.373611,
//       "Location": "Cangyuan",
//       "Longitude": 23.273889,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13489,
//       "Airport Code [ICAO]": "ZPJM",
//       "Airport Code iATA,FAA": "JMJ",
//       "Airport Name": "Lancang Jingmai Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 99.786389,
//       "Location": "Lancang Lahu",
//       "Longitude": 22.415833,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13490,
//       "Airport Code [ICAO]": "ZPNL",
//       "Airport Code iATA,FAA": "NLH",
//       "Airport Name": "Ninglang Luguhu Airport",
//       "Altitude (Ft)": 10804,
//       "Country": "China",
//       "Latitude": 100.7593,
//       "Location": "Ninglang",
//       "Longitude": 27.5403,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13491,
//       "Airport Code [ICAO]": "SWPM",
//       "Airport Code iATA,FAA": "PBQ",
//       "Airport Name": "Pimenta Bueno Airport",
//       "Altitude (Ft)": 682,
//       "Country": "Brazil",
//       "Latitude": -61.179100036621094,
//       "Location": "Pimenta Bueno",
//       "Longitude": -11.641599655151367,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13492,
//       "Airport Code [ICAO]": "SJOG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ariquemes Airport",
//       "Altitude (Ft)": 530,
//       "Country": "Brazil",
//       "Latitude": -63.04888916015625,
//       "Location": "ARIQUEMES",
//       "Longitude": -9.884721755981445,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13493,
//       "Airport Code [ICAO]": "SIXZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Fazenda Spartacus Airport",
//       "Altitude (Ft)": 2346,
//       "Country": "Brazil",
//       "Latitude": -48.608333587646484,
//       "Location": "COLNIZA",
//       "Longitude": -24,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13494,
//       "Airport Code [ICAO]": "ZBXZ",
//       "Airport Code iATA,FAA": "WUT",
//       "Airport Name": "Xinzhou Wutaishan Airport",
//       "Altitude (Ft)": 2527,
//       "Country": "China",
//       "Latitude": 112.969173,
//       "Location": "Xinzhou",
//       "Longitude": 38.597456,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13496,
//       "Airport Code [ICAO]": "ZBZL",
//       "Airport Code iATA,FAA": "NZL",
//       "Airport Name": "Chengjisihan Airport",
//       "Altitude (Ft)": 928,
//       "Country": "China",
//       "Latitude": 122.767503,
//       "Location": "Zhalantun",
//       "Longitude": 47.865833,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13497,
//       "Airport Code [ICAO]": "SJTF",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Fazenda Mequens Airport",
//       "Altitude (Ft)": 552,
//       "Country": "Brazil",
//       "Latitude": -62.25749969482422,
//       "Location": "ALTA FLORESTA D'OESTE",
//       "Longitude": -13.061944007873535,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13498,
//       "Airport Code [ICAO]": "SWYN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Prainha Airport",
//       "Altitude (Ft)": 197,
//       "Country": "Brazil",
//       "Latitude": -59.839599609375,
//       "Location": "APUI",
//       "Longitude": -7.17287015914917,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13499,
//       "Airport Code [ICAO]": "SSMT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mostardas Airport",
//       "Altitude (Ft)": 59,
//       "Country": "Brazil",
//       "Latitude": -50.910301208496094,
//       "Location": "SANTO ANTONIO DO MATUPI",
//       "Longitude": -31.103599548339844,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13500,
//       "Airport Code [ICAO]": "SCSN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Santo Domingo Airport",
//       "Altitude (Ft)": 246,
//       "Country": "Brazil",
//       "Latitude": -71.6144027709961,
//       "Location": "CONSELVAN",
//       "Longitude": -33.65639877319336,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13502,
//       "Airport Code [ICAO]": "VIAX",
//       "Airport Code iATA,FAA": "AIP",
//       "Airport Name": "Adampur Airport",
//       "Altitude (Ft)": 775,
//       "Country": "India",
//       "Latitude": 75.758797,
//       "Location": "Adampur",
//       "Longitude": 31.4338,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13511,
//       "Airport Code [ICAO]": "VOJV",
//       "Airport Code iATA,FAA": "VDY",
//       "Airport Name": "Vijayanagar Aerodrome (JSW)",
//       "Altitude (Ft)": 1670,
//       "Country": "India",
//       "Latitude": 76.6349472222,
//       "Location": "Toranagallu",
//       "Longitude": 15.1749666667,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13513,
//       "Airport Code [ICAO]": "VASD",
//       "Airport Code iATA,FAA": "SAG",
//       "Airport Name": "Shirdi Airport",
//       "Altitude (Ft)": 1926,
//       "Country": "India",
//       "Latitude": 74.378889,
//       "Location": "Shirdi",
//       "Longitude": 19.688611,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13514,
//       "Airport Code [ICAO]": "VEJP",
//       "Airport Code iATA,FAA": "PYB",
//       "Airport Name": "Jeypore Airport",
//       "Altitude (Ft)": 1952,
//       "Country": "India",
//       "Latitude": 82.552001953125,
//       "Location": "Jeypore",
//       "Longitude": 18.8799991607666,
//       "GMT Offset": "5.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13520,
//       "Airport Code [ICAO]": "WART",
//       "Airport Code iATA,FAA": "SUP",
//       "Airport Name": "Trunojoyo Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Indonesia",
//       "Latitude": 113.89023,
//       "Location": "Sumenep",
//       "Longitude": -7.0242,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13521,
//       "Airport Code [ICAO]": "WIMO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Lasondre Airport",
//       "Altitude (Ft)": 30,
//       "Country": "Indonesia",
//       "Latitude": 98.30097,
//       "Location": "Batu Islands",
//       "Longitude": -0.01924,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13525,
//       "Airport Code [ICAO]": "WIBR",
//       "Airport Code iATA,FAA": "RKO",
//       "Airport Name": "Rokot Airport",
//       "Altitude (Ft)": 1,
//       "Country": "Indonesia",
//       "Latitude": 99.70069885253906,
//       "Location": "Sipora",
//       "Longitude": -2.0991199016571045,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13529,
//       "Airport Code [ICAO]": "WIDE",
//       "Airport Code iATA,FAA": "PPR",
//       "Airport Name": "Pasir Pangaraan Airport",
//       "Altitude (Ft)": 151,
//       "Country": "Indonesia",
//       "Latitude": 100.37000274658203,
//       "Location": "Pasir Pangaraian",
//       "Longitude": 0.8454310297966003,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13530,
//       "Airport Code [ICAO]": "WIBT",
//       "Airport Code iATA,FAA": "TJB",
//       "Airport Name": "Sei Bati Airport",
//       "Altitude (Ft)": 33,
//       "Country": "Indonesia",
//       "Latitude": 103.3931,
//       "Location": "Tanjung Balai Karimun",
//       "Longitude": 1.0527,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13532,
//       "Airport Code [ICAO]": "WIPH",
//       "Airport Code iATA,FAA": "KRC",
//       "Airport Name": "Departi Parbo Airport",
//       "Altitude (Ft)": 2600,
//       "Country": "Indonesia",
//       "Latitude": 101.4683,
//       "Location": "Kerinci Regency",
//       "Longitude": -2.093,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13535,
//       "Airport Code [ICAO]": "WAPG",
//       "Airport Code iATA,FAA": "NRE",
//       "Airport Name": "Namrole Airport",
//       "Altitude (Ft)": 31,
//       "Country": "Indonesia",
//       "Latitude": 126.7012,
//       "Location": "Buru Island",
//       "Longitude": -3.8548,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13538,
//       "Airport Code [ICAO]": "WAPR",
//       "Airport Code iATA,FAA": "NAM",
//       "Airport Name": "Namlea Airport",
//       "Altitude (Ft)": 41,
//       "Country": "Indonesia",
//       "Latitude": 127.0999984741211,
//       "Location": "Namlea",
//       "Longitude": -3.235569953918457,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13540,
//       "Airport Code [ICAO]": "WAPD",
//       "Airport Code iATA,FAA": "DOB",
//       "Airport Name": "Rar Gwamar Airport",
//       "Altitude (Ft)": 61,
//       "Country": "Indonesia",
//       "Latitude": 134.212005615,
//       "Location": "Dobo",
//       "Longitude": -5.7722201347399995,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13543,
//       "Airport Code [ICAO]": "WAPN",
//       "Airport Code iATA,FAA": "SQN",
//       "Airport Name": "Emalamo Sanana Airport",
//       "Altitude (Ft)": 39,
//       "Country": "Indonesia",
//       "Latitude": 125.96700286865234,
//       "Location": "Sanana",
//       "Longitude": -2.080509901046753,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13544,
//       "Airport Code [ICAO]": "WASA",
//       "Airport Code iATA,FAA": "AYW",
//       "Airport Name": "Ayawasi Airport",
//       "Altitude (Ft)": 1560,
//       "Country": "Indonesia",
//       "Latitude": 132.4633,
//       "Location": "Ayawasi",
//       "Longitude": -1.1593,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13545,
//       "Airport Code [ICAO]": "WALV",
//       "Airport Code iATA,FAA": "BYQ",
//       "Airport Name": "Bunyu Airport",
//       "Altitude (Ft)": 118,
//       "Country": "Indonesia",
//       "Latitude": 117.866996765,
//       "Location": "Bunyu",
//       "Longitude": 3.45571994781,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13546,
//       "Airport Code [ICAO]": "WAMY",
//       "Airport Code iATA,FAA": "UOL",
//       "Airport Name": "Buol Airport",
//       "Altitude (Ft)": 27,
//       "Country": "Indonesia",
//       "Latitude": 121.4141,
//       "Location": "Buol",
//       "Longitude": 1.1027,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13548,
//       "Airport Code [ICAO]": "WAWR",
//       "Airport Code iATA,FAA": "RAQ",
//       "Airport Name": "Sugimanuru Airport",
//       "Altitude (Ft)": 132,
//       "Country": "Indonesia",
//       "Latitude": 122.569371,
//       "Location": "Raha",
//       "Longitude": -4.760557,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13550,
//       "Airport Code [ICAO]": "OIZJ",
//       "Airport Code iATA,FAA": "JSK",
//       "Airport Name": "Jask Airport",
//       "Altitude (Ft)": 19,
//       "Country": "Iran",
//       "Latitude": 57.799301,
//       "Location": "Jask",
//       "Longitude": 25.653601,
//       "GMT Offset": "3.5",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13551,
//       "Airport Code [ICAO]": "KFKS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Frankfort Dow Memorial Field",
//       "Altitude (Ft)": 633,
//       "Country": "United States",
//       "Latitude": -86.20079803466797,
//       "Location": "Frankfort",
//       "Longitude": 44.62519836425781,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13552,
//       "Airport Code [ICAO]": "KSLH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Cheboygan County Airport",
//       "Altitude (Ft)": 639,
//       "Country": "United States",
//       "Latitude": -84.51930237,
//       "Location": "Cheboygan",
//       "Longitude": 45.65370178,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13553,
//       "Airport Code [ICAO]": "RJAZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kozushima Airport",
//       "Altitude (Ft)": 452,
//       "Country": "Japan",
//       "Latitude": 139.134002686,
//       "Location": "Kozushima",
//       "Longitude": 34.189998626699996,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13561,
//       "Airport Code [ICAO]": "UAFZ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kazarman Airport",
//       "Altitude (Ft)": 4298,
//       "Country": "Kyrgyzstan",
//       "Latitude": 74.044418335,
//       "Location": "Kazarman",
//       "Longitude": 41.4102908129,
//       "GMT Offset": "6",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13562,
//       "Airport Code [ICAO]": "UAFE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kerben Airport",
//       "Altitude (Ft)": 4199,
//       "Country": "Kyrgyzstan",
//       "Latitude": 71.7337036133,
//       "Location": "Kerben",
//       "Longitude": 41.484500885,
//       "GMT Offset": "6",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13564,
//       "Airport Code [ICAO]": "UAFN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Naryn Airport",
//       "Altitude (Ft)": 6998,
//       "Country": "Kyrgyzstan",
//       "Latitude": 76.13059997559999,
//       "Location": "Naryn",
//       "Longitude": 41.44150161739999,
//       "GMT Offset": "6",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13566,
//       "Airport Code [ICAO]": "VLSB",
//       "Airport Code iATA,FAA": "ZBY",
//       "Airport Name": "Sayaboury Airport",
//       "Altitude (Ft)": 962,
//       "Country": "Laos",
//       "Latitude": 101.7093,
//       "Location": "Sainyabuli",
//       "Longitude": 19.2436,
//       "GMT Offset": "7",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13572,
//       "Airport Code [ICAO]": "VNBL",
//       "Airport Code iATA,FAA": "BGL",
//       "Airport Name": "Baglung Airport",
//       "Altitude (Ft)": 3320,
//       "Country": "Nepal",
//       "Latitude": 83.66629791259766,
//       "Location": "Baglung",
//       "Longitude": 28.212799072265625,
//       "GMT Offset": "5.75",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13573,
//       "Airport Code [ICAO]": "VNRB",
//       "Airport Code iATA,FAA": "RJB",
//       "Airport Name": "Rajbiraj Airport",
//       "Altitude (Ft)": 250,
//       "Country": "Nepal",
//       "Latitude": 86.733902,
//       "Location": "Rajbiraj",
//       "Longitude": 26.510066,
//       "GMT Offset": "5.45",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13575,
//       "Airport Code [ICAO]": "OODQ",
//       "Airport Code iATA,FAA": "DQM",
//       "Airport Name": "Duqm International Airport",
//       "Altitude (Ft)": 364,
//       "Country": "Oman",
//       "Latitude": 57.634167,
//       "Location": "Duqm",
//       "Longitude": 19.501944,
//       "GMT Offset": "4",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13580,
//       "Airport Code [ICAO]": "RPLN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Palanan Community Airport",
//       "Altitude (Ft)": 52,
//       "Country": "Philippines",
//       "Latitude": 122.427671,
//       "Location": "Palanan",
//       "Longitude": 17.065667,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13581,
//       "Airport Code [ICAO]": "RPLT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Itbayat Airport",
//       "Altitude (Ft)": 328,
//       "Country": "Philippines",
//       "Latitude": 121.809969,
//       "Location": "Itbayat",
//       "Longitude": 20.722521,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13583,
//       "Airport Code [ICAO]": "RPVY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Catbalogan Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Philippines",
//       "Latitude": 124.83,
//       "Location": "Catbalogan City",
//       "Longitude": 11.81,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13584,
//       "Airport Code [ICAO]": "RPSM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Maasin Airport",
//       "Altitude (Ft)": 328,
//       "Country": "Philippines",
//       "Latitude": 124.78199768066406,
//       "Location": "Maasin",
//       "Longitude": 10.187299728393555,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13592,
//       "Airport Code [ICAO]": "RPSB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Bantayan Airport",
//       "Altitude (Ft)": 60,
//       "Country": "Philippines",
//       "Latitude": 123.7848,
//       "Location": "Bantayan",
//       "Longitude": 11.1624,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13593,
//       "Airport Code [ICAO]": "RPVQ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Biliran Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Philippines",
//       "Latitude": 124.428985119,
//       "Location": "Biliran",
//       "Longitude": 11.5159178299,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13595,
//       "Airport Code [ICAO]": "SIEL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Fazenda Várzea Funda Airport",
//       "Altitude (Ft)": 709,
//       "Country": "Brazil",
//       "Latitude": -57.73222351074219,
//       "Location": "PRIMAVERA D'OESTE",
//       "Longitude": -16.58361053466797,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13597,
//       "Airport Code [ICAO]": "SWPY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Primavera do Leste Airport",
//       "Altitude (Ft)": 2149,
//       "Country": "Brazil",
//       "Latitude": -54.33777618408203,
//       "Location": "PRIMAVERA DO LESTE",
//       "Longitude": -15.565555572509766,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13598,
//       "Airport Code [ICAO]": "YCKI",
//       "Airport Code iATA,FAA": "CKI",
//       "Airport Name": "Croker Island Airport",
//       "Altitude (Ft)": 16,
//       "Country": "Australia",
//       "Latitude": 132.48300170898438,
//       "Location": "Croker Island",
//       "Longitude": -11.164999961853027,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13599,
//       "Airport Code [ICAO]": "YTGT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "The Granites Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 130.3470001220703,
//       "Location": "The Granites",
//       "Longitude": -20.54829978942871,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13600,
//       "Airport Code [ICAO]": "YBRY",
//       "Airport Code iATA,FAA": "BYP",
//       "Airport Name": "Barimunya Airport",
//       "Altitude (Ft)": 2082,
//       "Country": "Australia",
//       "Latitude": 119.16600036621,
//       "Location": "Barimunya",
//       "Longitude": -22.673900604248,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13601,
//       "Airport Code [ICAO]": "NFNH",
//       "Airport Code iATA,FAA": "LUC",
//       "Airport Name": "Laucala Island Airport",
//       "Altitude (Ft)": 10,
//       "Country": "Fiji",
//       "Latitude": -179.66700744628906,
//       "Location": "Laucala",
//       "Longitude": -16.74810028076172,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13602,
//       "Airport Code [ICAO]": "NFSW",
//       "Airport Code iATA,FAA": "YAS",
//       "Airport Name": "Yasawa Island Airport",
//       "Altitude (Ft)": 37,
//       "Country": "Fiji",
//       "Latitude": 177.5449981689453,
//       "Location": "Yasawa Island",
//       "Longitude": -16.758899688720703,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13606,
//       "Airport Code [ICAO]": "NZNE",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "North Shore Aerodrome",
//       "Altitude (Ft)": 210,
//       "Country": "New Zealand",
//       "Latitude": 174.65499877929688,
//       "Location": "North Shore",
//       "Longitude": -36.656700134277344,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13607,
//       "Airport Code [ICAO]": "KJWN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "John C Tune Airport",
//       "Altitude (Ft)": 495,
//       "Country": "United States",
//       "Latitude": -86.88670349121094,
//       "Location": "Nashville",
//       "Longitude": 36.18239974975586,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13608,
//       "Airport Code [ICAO]": "NZOX",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Okiwi Station Airport",
//       "Altitude (Ft)": 10,
//       "Country": "New Zealand",
//       "Latitude": 175.41900634765625,
//       "Location": "Okiwi",
//       "Longitude": -36.146400451660156,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13609,
//       "Airport Code [ICAO]": "NZKE",
//       "Airport Code iATA,FAA": "WIK",
//       "Airport Name": "Waiheke Reeve Airport",
//       "Altitude (Ft)": 226,
//       "Country": "New Zealand",
//       "Latitude": 175.08599853515625,
//       "Location": "Waiheke Island",
//       "Longitude": -36.80889892578125,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13610,
//       "Airport Code [ICAO]": "NZKM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Karamea Airport",
//       "Altitude (Ft)": 30,
//       "Country": "New Zealand",
//       "Latitude": 172.10499572753906,
//       "Location": "Karamea",
//       "Longitude": -41.236698150634766,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13621,
//       "Airport Code [ICAO]": "EPJA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Jastarnia Airport",
//       "Altitude (Ft)": 3,
//       "Country": "Poland",
//       "Latitude": 18.645280838012695,
//       "Location": "Jastarnia",
//       "Longitude": 54.71028137207031,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13622,
//       "Airport Code [ICAO]": "WAVD",
//       "Airport Code iATA,FAA": "DEX",
//       "Airport Name": "Nop Goliat Airport",
//       "Altitude (Ft)": 198,
//       "Country": "Indonesia",
//       "Latitude": 139.482006,
//       "Location": "Dekai",
//       "Longitude": -4.8557,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13623,
//       "Airport Code [ICAO]": "NTGN",
//       "Airport Code iATA,FAA": "NAU",
//       "Airport Name": "Napuka Island Airport",
//       "Altitude (Ft)": 7,
//       "Country": "French Polynesia",
//       "Latitude": -141.26699829101562,
//       "Location": "Napuka",
//       "Longitude": -14.176799774169922,
//       "GMT Offset": "-10",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13624,
//       "Airport Code [ICAO]": "NTKF",
//       "Airport Code iATA,FAA": "FAC",
//       "Airport Name": "Faaite Airport",
//       "Altitude (Ft)": 7,
//       "Country": "French Polynesia",
//       "Latitude": -145.32899475097656,
//       "Location": "Faaite",
//       "Longitude": -16.68670082092285,
//       "GMT Offset": "-10",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13625,
//       "Airport Code [ICAO]": "NTGW",
//       "Airport Code iATA,FAA": "NUK",
//       "Airport Name": "Nukutavake Airport",
//       "Altitude (Ft)": 17,
//       "Country": "French Polynesia",
//       "Latitude": -138.77200317382812,
//       "Location": "Nukutavake",
//       "Longitude": -19.28499984741211,
//       "GMT Offset": "-10",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13626,
//       "Airport Code [ICAO]": "NTUV",
//       "Airport Code iATA,FAA": "VHZ",
//       "Airport Name": "Vahitahi Airport",
//       "Altitude (Ft)": 9,
//       "Country": "French Polynesia",
//       "Latitude": -138.85299682617188,
//       "Location": "Vahitahi",
//       "Longitude": -18.780000686645508,
//       "GMT Offset": "-10",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13627,
//       "Airport Code [ICAO]": "NTGH",
//       "Airport Code iATA,FAA": "HHZ",
//       "Airport Name": "Hikueru Atoll Airport",
//       "Altitude (Ft)": 5,
//       "Country": "French Polynesia",
//       "Latitude": -142.61399841308594,
//       "Location": "Hikueru",
//       "Longitude": -17.544700622558594,
//       "GMT Offset": "-10",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13628,
//       "Airport Code [ICAO]": "NTKO",
//       "Airport Code iATA,FAA": "RRR",
//       "Airport Name": "Raroia Airport",
//       "Altitude (Ft)": 18,
//       "Country": "French Polynesia",
//       "Latitude": -142.476944,
//       "Location": "Raroia",
//       "Longitude": -16.045,
//       "GMT Offset": "-10",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13629,
//       "Airport Code [ICAO]": "NTKA",
//       "Airport Code iATA,FAA": "KHZ",
//       "Airport Name": "Kauehi Airport",
//       "Altitude (Ft)": 13,
//       "Country": "French Polynesia",
//       "Latitude": -145.12399291992188,
//       "Location": "Kauehi",
//       "Longitude": -15.780799865722656,
//       "GMT Offset": "-10",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13630,
//       "Airport Code [ICAO]": "NTGO",
//       "Airport Code iATA,FAA": "TKV",
//       "Airport Name": "Tatakoto Airport",
//       "Altitude (Ft)": 12,
//       "Country": "French Polynesia",
//       "Latitude": -138.44500732421875,
//       "Location": "Tatakoto",
//       "Longitude": -17.355300903320312,
//       "GMT Offset": "-10",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13631,
//       "Airport Code [ICAO]": "AYSS",
//       "Airport Code iATA,FAA": "TDS",
//       "Airport Name": "Sasereme Airport",
//       "Altitude (Ft)": 121,
//       "Country": "Papua New Guinea",
//       "Latitude": 142.868,
//       "Location": "Sasereme",
//       "Longitude": -7.6217,
//       "GMT Offset": "10",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13632,
//       "Airport Code [ICAO]": "WAKT",
//       "Airport Code iATA,FAA": "TMH",
//       "Airport Name": "Tanah Merah Airport",
//       "Altitude (Ft)": 57,
//       "Country": "Indonesia",
//       "Latitude": 140.29800415039062,
//       "Location": "Boven Digoel",
//       "Longitude": -6.099219799041748,
//       "GMT Offset": "9",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13635,
//       "Airport Code [ICAO]": "AK06",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Denali Airport",
//       "Altitude (Ft)": 2050,
//       "Country": "United States",
//       "Latitude": -148.79800415039062,
//       "Location": "McKinley Park",
//       "Longitude": 63.64509963989258,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13636,
//       "Airport Code [ICAO]": "SBJD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Comte. Rolim Adolfo Amaro–Jundiaí State Airport",
//       "Altitude (Ft)": 2484,
//       "Country": "Brazil",
//       "Latitude": -46.944408,
//       "Location": "Jundiai",
//       "Longitude": -23.180369,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13642,
//       "Airport Code [ICAO]": "KW35",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Potomac Airpark",
//       "Altitude (Ft)": 412,
//       "Country": "United States",
//       "Latitude": -78.16609954833984,
//       "Location": "Berkeley Springs",
//       "Longitude": 39.69260025024414,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13643,
//       "Airport Code [ICAO]": "SSHS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Helisul IV Heliport",
//       "Altitude (Ft)": 676,
//       "Country": "Brazil",
//       "Latitude": -54.39805603027344,
//       "Location": "Foz Do Iguacu",
//       "Longitude": -25.613056182861328,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13647,
//       "Airport Code [ICAO]": "FABS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Brits Airport",
//       "Altitude (Ft)": 3756,
//       "Country": "South Africa",
//       "Latitude": 27.77589988708496,
//       "Location": "Brits",
//       "Longitude": -25.53230094909668,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13648,
//       "Airport Code [ICAO]": "NZWL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "West Melton Aerodrome",
//       "Altitude (Ft)": 312,
//       "Country": "New Zealand",
//       "Latitude": 172.39700317382812,
//       "Location": "West Melton",
//       "Longitude": -43.47669982910156,
//       "GMT Offset": "12",
//       "Daylight Savings": "Z",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13649,
//       "Airport Code [ICAO]": "NZRT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Rangiora Airfield",
//       "Altitude (Ft)": 180,
//       "Country": "New Zealand",
//       "Latitude": 172.54200744628906,
//       "Location": "Rangiora",
//       "Longitude": -43.290000915527344,
//       "GMT Offset": "12",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13668,
//       "Airport Code [ICAO]": "SIDG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Fazenda Jatobasso Airport",
//       "Altitude (Ft)": 2152,
//       "Country": "Brazil",
//       "Latitude": -55.53333282470703,
//       "Location": "JARU",
//       "Longitude": -22.429166793823242,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13669,
//       "Airport Code [ICAO]": "SIMC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "FIC Heliport",
//       "Altitude (Ft)": 1985,
//       "Country": "Brazil",
//       "Latitude": -47.141109466552734,
//       "Location": "MACHADINHO D'OESTE",
//       "Longitude": -22.711389541625977,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13671,
//       "Airport Code [ICAO]": "YNKA",
//       "Airport Code iATA,FAA": "NKB",
//       "Airport Name": "Noonkanbah Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 124.851997375,
//       "Location": "Noonkanbah",
//       "Longitude": -18.4946994781,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13677,
//       "Airport Code [ICAO]": "LEAG",
//       "Airport Code iATA,FAA": "AEI",
//       "Airport Name": "Algeciras Heliport",
//       "Altitude (Ft)": 98,
//       "Country": "Spain",
//       "Latitude": -5.441118,
//       "Location": "Algeciras",
//       "Longitude": 36.12888,
//       "GMT Offset": "-2",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13681,
//       "Airport Code [ICAO]": "UKKH",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Chepelevka Airport",
//       "Altitude (Ft)": 568,
//       "Country": "Ukraine",
//       "Latitude": 30.4414005279541,
//       "Location": "Uzyn",
//       "Longitude": 49.79059982299805,
//       "GMT Offset": "2",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13683,
//       "Airport Code [ICAO]": "SWQT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Fazenda São Nicolau Airport",
//       "Altitude (Ft)": 738,
//       "Country": "Brazil",
//       "Latitude": -58.229167938232,
//       "Location": "COTRIGUACU",
//       "Longitude": -9.8644437789917,
//       "GMT Offset": "0",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13684,
//       "Airport Code [ICAO]": "HUKI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kisoro Airport",
//       "Altitude (Ft)": 6200,
//       "Country": "Uganda",
//       "Latitude": 29.7197,
//       "Location": "Kisoro",
//       "Longitude": -1.2837,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13687,
//       "Airport Code [ICAO]": "HUKS",
//       "Airport Code iATA,FAA": "KSE",
//       "Airport Name": "Kasese Airport",
//       "Altitude (Ft)": 3146,
//       "Country": "Uganda",
//       "Latitude": 30.100000381469727,
//       "Location": "Kasese",
//       "Longitude": 0.18299999833106995,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13694,
//       "Airport Code [ICAO]": "KHSA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Stennis International Airport",
//       "Altitude (Ft)": 23,
//       "Country": "United States",
//       "Latitude": -89.45459747314453,
//       "Location": "Bay St. Louis",
//       "Longitude": 30.367799758911133,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13695,
//       "Airport Code [ICAO]": "SAFS",
//       "Airport Code iATA,FAA": "NCJ",
//       "Airport Name": "Sunchales Aeroclub Airport",
//       "Altitude (Ft)": 311,
//       "Country": "Argentina",
//       "Latitude": -61.5283,
//       "Location": "Sunchales",
//       "Longitude": -30.9575,
//       "GMT Offset": "-3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13696,
//       "Airport Code [ICAO]": "LTFM",
//       "Airport Code iATA,FAA": "IST",
//       "Airport Name": "Istanbul Airport",
//       "Altitude (Ft)": 325,
//       "Country": "Turkey",
//       "Latitude": 28.751944,
//       "Location": "Istanbul",
//       "Longitude": 41.275278,
//       "GMT Offset": "3",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13707,
//       "Airport Code [ICAO]": "PASO",
//       "Airport Code iATA,FAA": "SOV",
//       "Airport Name": "Seldovia Airport",
//       "Altitude (Ft)": 29,
//       "Country": "United States",
//       "Latitude": -151.70399475098,
//       "Location": "Seldovia",
//       "Longitude": 59.442401885986,
//       "GMT Offset": "-9",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13711,
//       "Airport Code [ICAO]": "CYLK",
//       "Airport Code iATA,FAA": "YSG",
//       "Airport Name": "Lutselk'e Airport",
//       "Altitude (Ft)": 596,
//       "Country": "Canada",
//       "Latitude": -110.681998,
//       "Location": "Lutselk'e",
//       "Longitude": 62.418303,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13712,
//       "Airport Code [ICAO]": "ENEN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Engeløy Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Norway",
//       "Latitude": 14.9925,
//       "Location": "Engeløy",
//       "Longitude": 67.967222,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13713,
//       "Airport Code [ICAO]": "FWDW",
//       "Airport Code iATA,FAA": "DWA",
//       "Airport Name": "Dwangwa Airport",
//       "Altitude (Ft)": 1605,
//       "Country": "Malawi",
//       "Latitude": 34.131901,
//       "Location": "Dwangwa",
//       "Longitude": -12.5183,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13714,
//       "Airport Code [ICAO]": "HSFA",
//       "Airport Code iATA,FAA": "HGI",
//       "Airport Name": "Paloich Airport, Heliport",
//       "Altitude (Ft)": 1290,
//       "Country": "South Sudan",
//       "Latitude": 32.500556,
//       "Location": "Higleig",
//       "Longitude": 10.529167,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13715,
//       "Airport Code [ICAO]": "KJKL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Julian Carroll Airport",
//       "Altitude (Ft)": 1381,
//       "Country": "United States",
//       "Latitude": -83.31729888916016,
//       "Location": "Jackson",
//       "Longitude": 37.59389877319336,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13716,
//       "Airport Code [ICAO]": "KNBC",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Beaufort MCAS - Merritt Field",
//       "Altitude (Ft)": 37,
//       "Country": "United States",
//       "Latitude": -80.723197937,
//       "Location": "Beaufort",
//       "Longitude": 32.4774017334,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13717,
//       "Airport Code [ICAO]": "KNFG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Camp Pendleton MCAS (Munn Field) Airport",
//       "Altitude (Ft)": 78,
//       "Country": "United States",
//       "Latitude": -117.3550034,
//       "Location": "Oceanside",
//       "Longitude": 33.30130005,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13718,
//       "Airport Code [ICAO]": "LPCB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Aerodromo de Castelo Branco",
//       "Altitude (Ft)": 1300,
//       "Country": "Portugal",
//       "Latitude": -7.44111,
//       "Location": "Castelo Branco",
//       "Longitude": 39.847022,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13719,
//       "Airport Code [ICAO]": "LPSO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ponte de Sor Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Portugal",
//       "Latitude": -8.056542,
//       "Location": "Ponte de Sôr",
//       "Longitude": 39.211559,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13720,
//       "Airport Code [ICAO]": "LPVL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Vilar de Luz Airfield",
//       "Altitude (Ft)": 762,
//       "Country": "Portugal",
//       "Latitude": -8.516209,
//       "Location": "Maia",
//       "Longitude": 41.27729,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13721,
//       "Airport Code [ICAO]": "MYLR",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hard Bargain Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Bahamas",
//       "Latitude": -74.905889,
//       "Location": "",
//       "Longitude": 23.011194,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13722,
//       "Airport Code [ICAO]": "RJCS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kenebetsu JASDF Airfield",
//       "Altitude (Ft)": 0,
//       "Country": "Japan",
//       "Latitude": 144.741354,
//       "Location": "Kenebetsu",
//       "Longitude": 43.425154,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13723,
//       "Airport Code [ICAO]": "SBNT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Augusto Severo Airport",
//       "Altitude (Ft)": 169,
//       "Country": "Brazil",
//       "Latitude": -35.2476997375,
//       "Location": "Natal",
//       "Longitude": -5.91141986847,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13724,
//       "Airport Code [ICAO]": "VIKG",
//       "Airport Code iATA,FAA": "KQH",
//       "Airport Name": "Kishangarh Airport",
//       "Altitude (Ft)": 0,
//       "Country": "India",
//       "Latitude": 74.814147,
//       "Location": "Ajmer",
//       "Longitude": 26.601473,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13725,
//       "Airport Code [ICAO]": "VOKN",
//       "Airport Code iATA,FAA": "CNN",
//       "Airport Name": "Kannur International Airport",
//       "Altitude (Ft)": 330,
//       "Country": "India",
//       "Latitude": 75.547211,
//       "Location": "Kannur",
//       "Longitude": 11.918614,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13726,
//       "Airport Code [ICAO]": "ZBBB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Beijing Xijiao Airport",
//       "Altitude (Ft)": 0,
//       "Country": "China",
//       "Latitude": 116.257004,
//       "Location": "Beijing",
//       "Longitude": 39.9608,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13735,
//       "Airport Code [ICAO]": "SWFN",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Flores Airport",
//       "Altitude (Ft)": 203,
//       "Country": "Brazil",
//       "Latitude": -60.02111053466797,
//       "Location": "MANAUS",
//       "Longitude": -3.0727779865264893,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13752,
//       "Airport Code [ICAO]": "YUMU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Umuwa Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 132.0399932861328,
//       "Location": "Umuwa",
//       "Longitude": -26.4867000579834,
//       "GMT Offset": "9.5",
//       "Daylight Savings": "O",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13754,
//       "Airport Code [ICAO]": "SCQP",
//       "Airport Code iATA,FAA": "ZCO",
//       "Airport Name": "La Araucanía Airport",
//       "Altitude (Ft)": 333,
//       "Country": "Chile",
//       "Latitude": -72.6515,
//       "Location": "Temuco",
//       "Longitude": -38.9259,
//       "GMT Offset": "-3",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13756,
//       "Airport Code [ICAO]": "FACC",
//       "Airport Code iATA,FAA": "ASS",
//       "Airport Name": "Arathusa Safari Lodge Airport",
//       "Altitude (Ft)": 1200,
//       "Country": "South Africa",
//       "Latitude": 31.522499,
//       "Location": "Arathusa",
//       "Longitude": -24.744165,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13757,
//       "Airport Code [ICAO]": "KVDI",
//       "Airport Code iATA,FAA": "VDI",
//       "Airport Name": "Vidalia Regional Airport",
//       "Altitude (Ft)": 275,
//       "Country": "United States",
//       "Latitude": -82.371200561523,
//       "Location": "Vidalia",
//       "Longitude": 32.192699432373,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13758,
//       "Airport Code [ICAO]": "KGDJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Granbury Regional Airport",
//       "Altitude (Ft)": 778,
//       "Country": "United States",
//       "Latitude": -97.81690216,
//       "Location": "Granbury",
//       "Longitude": 32.44440079,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13759,
//       "Airport Code [ICAO]": "KFZY",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Oswego County Airport",
//       "Altitude (Ft)": 475,
//       "Country": "United States",
//       "Latitude": -76.38809967041016,
//       "Location": "Fulton",
//       "Longitude": 43.350799560546875,
//       "GMT Offset": "-4",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13760,
//       "Airport Code [ICAO]": "OPIS",
//       "Airport Code iATA,FAA": "ISB",
//       "Airport Name": "New Islamabad International Airport",
//       "Altitude (Ft)": 1646,
//       "Country": "Pakistan",
//       "Latitude": 72.851613,
//       "Location": "Islamabad",
//       "Longitude": 33.560713,
//       "GMT Offset": "5",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13762,
//       "Airport Code [ICAO]": "FBVM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Vumbura Airport",
//       "Altitude (Ft)": 3185,
//       "Country": "Botswana",
//       "Latitude": 22.814533,
//       "Location": "Vumburu",
//       "Longitude": -18.955395,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13763,
//       "Airport Code [ICAO]": "FYGK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Farm Whitwater East Landing Strip",
//       "Altitude (Ft)": 857,
//       "Country": "Namibia",
//       "Latitude": 15.9392,
//       "Location": "Hardap",
//       "Longitude": -24.629999,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13765,
//       "Airport Code [ICAO]": "OJ40",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Muwaffaq Salti Air Base",
//       "Altitude (Ft)": 1706,
//       "Country": "Jordan",
//       "Latitude": 36.782001495399996,
//       "Location": "AZRAQ",
//       "Longitude": 31.8255996704,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13771,
//       "Airport Code [ICAO]": "BIKL",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kirkjubæjarklaustur Airport",
//       "Altitude (Ft)": 66,
//       "Country": "Iceland",
//       "Latitude": -18.001399993896484,
//       "Location": "Kirkjubaejarklaustur ",
//       "Longitude": 63.79079818725586,
//       "GMT Offset": "0",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13772,
//       "Airport Code [ICAO]": "SWVJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Fazenda Uiapuru Airport",
//       "Altitude (Ft)": 1519,
//       "Country": "Brazil",
//       "Latitude": -56.002220153808594,
//       "Location": "COMODORO",
//       "Longitude": -13.663888931274414,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13773,
//       "Airport Code [ICAO]": "CEZ5",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Whitehorse Seaplane Base",
//       "Altitude (Ft)": 2138,
//       "Country": "Canada",
//       "Latitude": -135.03700256347656,
//       "Location": "Whitehorse",
//       "Longitude": 60.69110107421875,
//       "GMT Offset": "-8",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13777,
//       "Airport Code [ICAO]": "ULAT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Pertominsk Airport",
//       "Altitude (Ft)": 56,
//       "Country": "Russia",
//       "Latitude": 38.4199981689,
//       "Location": "Pertominsk",
//       "Longitude": 64.7900009155,
//       "GMT Offset": "4",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13792,
//       "Airport Code [ICAO]": "YMNW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mount Weld Airport",
//       "Altitude (Ft)": 0,
//       "Country": "Australia",
//       "Latitude": 122.44000244140625,
//       "Location": "Laverton",
//       "Longitude": -28.771699905395508,
//       "GMT Offset": "8",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13793,
//       "Airport Code [ICAO]": "YKID",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kidston Airport",
//       "Altitude (Ft)": 494,
//       "Country": "Australia",
//       "Latitude": 144.17300415039062,
//       "Location": "Kidston",
//       "Longitude": -18.8700008392334,
//       "GMT Offset": "10",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13802,
//       "Airport Code [ICAO]": "EKKV",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Klaksvik Heliport",
//       "Altitude (Ft)": 263,
//       "Country": "Faroe Islands",
//       "Latitude": -6.5764546394300005,
//       "Location": "Klaksvik",
//       "Longitude": 62.2178497314,
//       "GMT Offset": "0",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13803,
//       "Airport Code [ICAO]": "KMHE",
//       "Airport Code iATA,FAA": "MHE",
//       "Airport Name": "Mitchell Municipal Airport",
//       "Altitude (Ft)": 1304,
//       "Country": "United States",
//       "Latitude": -98.03859710690001,
//       "Location": "Mitchell",
//       "Longitude": 43.774799346900004,
//       "GMT Offset": "-6",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13805,
//       "Airport Code [ICAO]": "FBHU",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Hunda Airport",
//       "Altitude (Ft)": 3195,
//       "Country": "Botswana",
//       "Latitude": 22.475646,
//       "Location": "Tubu Tree Camp",
//       "Longitude": -19.2765,
//       "GMT Offset": "2",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13808,
//       "Airport Code [ICAO]": "HTRU",
//       "Airport Code iATA,FAA": "GIT",
//       "Airport Name": "Mchauru Airport",
//       "Altitude (Ft)": 3955,
//       "Country": "Tanzania",
//       "Latitude": 32.172472,
//       "Location": "Geita",
//       "Longitude": -2.813667,
//       "GMT Offset": "3",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13821,
//       "Airport Code [ICAO]": "HBBE",
//       "Airport Code iATA,FAA": "GID",
//       "Airport Name": "Gitega Airport",
//       "Altitude (Ft)": 5741,
//       "Country": "Burundi",
//       "Latitude": 29.911308,
//       "Location": "Gitega",
//       "Longitude": -3.417209,
//       "GMT Offset": "2",
//       "Daylight Savings": "U",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13829,
//       "Airport Code [ICAO]": "LEMI",
//       "Airport Code iATA,FAA": "RMU",
//       "Airport Name": "Región de Murcia International Airport",
//       "Altitude (Ft)": 632,
//       "Country": "Spain",
//       "Latitude": -1.125,
//       "Location": "Murcia",
//       "Longitude": 37.803,
//       "GMT Offset": "1",
//       "Daylight Savings": "E",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13830,
//       "Airport Code [ICAO]": "SJYD",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Fazenda Kajussol Airport",
//       "Altitude (Ft)": 636,
//       "Country": "Brazil",
//       "Latitude": -61.686668395996094,
//       "Location": "Alta Floresta D'Oeste",
//       "Longitude": -11.9647216796875,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13880,
//       "Airport Code [ICAO]": "CYPT",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Pelee Island Airport",
//       "Altitude (Ft)": 572,
//       "Country": "Canada",
//       "Latitude": -82.6780014038086,
//       "Location": "Pelee",
//       "Longitude": 41.780399322509766,
//       "GMT Offset": "-5",
//       "Daylight Savings": "A",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13881,
//       "Airport Code [ICAO]": "SWCQ",
//       "Airport Code iATA,FAA": "CQS",
//       "Airport Name": "Costa Marques Airport",
//       "Altitude (Ft)": 555,
//       "Country": "Brazil",
//       "Latitude": -64.25160217285156,
//       "Location": "COSTA MARQUES",
//       "Longitude": -12.421099662780762,
//       "GMT Offset": "-4",
//       "Daylight Savings": "S",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 13891,
//       "Airport Code [ICAO]": "YMNG",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Mangalore Airport",
//       "Altitude (Ft)": 467,
//       "Country": "Australia",
//       "Latitude": 145.1840057373047,
//       "Location": "Mangalore",
//       "Longitude": -36.888301849365234,
//       "GMT Offset": "10",
//       "Daylight Savings": "O",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 14086,
//       "Airport Code [ICAO]": "MRSI",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "San Isidro del General Airport",
//       "Altitude (Ft)": 2100,
//       "Country": "Costa Rica",
//       "Latitude": -83.71309661865234,
//       "Location": "San Isidro",
//       "Longitude": 9.352620124816895,
//       "GMT Offset": "-6",
//       "Daylight Savings": "N",
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 14087,
//       "Airport Code [ICAO]": "UBTT",
//       "Airport Code iATA,FAA": "ZXT",
//       "Airport Name": "Zabrat Airport",
//       "Altitude (Ft)": 36,
//       "Country": "Azerbaijan",
//       "Latitude": 49.9768066406,
//       "Location": "Baku",
//       "Longitude": 40.4955422161,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 14088,
//       "Airport Code [ICAO]": "LBIA",
//       "Airport Code iATA,FAA": "JAM",
//       "Airport Name": "Bezmer Air Base",
//       "Altitude (Ft)": 509,
//       "Country": "Bulgaria",
//       "Latitude": 26.3521995544,
//       "Location": "Yambol",
//       "Longitude": 42.4548988342,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 14089,
//       "Airport Code [ICAO]": "UMMO",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Osovtsy Air Base",
//       "Altitude (Ft)": 0,
//       "Country": "Belarus",
//       "Latitude": 24.884000778199997,
//       "Location": "Byaroza",
//       "Longitude": 52.5569992065,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 14090,
//       "Airport Code [ICAO]": "ZPYM",
//       "Airport Code iATA,FAA": "YUA",
//       "Airport Name": "Yuanmou Air Base",
//       "Altitude (Ft)": 3810,
//       "Country": "China",
//       "Latitude": 101.88200378418,
//       "Location": "Yuanmou",
//       "Longitude": 25.737499237061,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 14091,
//       "Airport Code [ICAO]": "ZGCS",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Changsha Datuopu Airport/AFB",
//       "Altitude (Ft)": 170,
//       "Country": "China",
//       "Latitude": 112.958000183,
//       "Location": "Changsha",
//       "Longitude": 28.0688991547,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 14092,
//       "Airport Code [ICAO]": "ZYXC",
//       "Airport Code iATA,FAA": "XEN",
//       "Airport Name": "Xingcheng Air Base",
//       "Altitude (Ft)": 30,
//       "Country": "China",
//       "Latitude": 120.697998047,
//       "Location": "",
//       "Longitude": 40.580299377399996,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 14093,
//       "Airport Code [ICAO]": "LCGK",
//       "Airport Code iATA,FAA": "GEC",
//       "Airport Name": "Lefkoniko Airport",
//       "Altitude (Ft)": 146,
//       "Country": "Cyprus",
//       "Latitude": 33.724358,
//       "Location": "Geçitkale",
//       "Longitude": 35.235947,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 14095,
//       "Airport Code [ICAO]": "HTGW",
//       "Airport Code iATA,FAA": "MBI",
//       "Airport Name": "Songwe Airport",
//       "Altitude (Ft)": 4412,
//       "Country": "Tanzania",
//       "Latitude": 33.273981,
//       "Location": "Mbeya",
//       "Longitude": -8.919942,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 14096,
//       "Airport Code [ICAO]": "LHKA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kalocsa/Foktő Airport",
//       "Altitude (Ft)": 284,
//       "Country": "Hungary",
//       "Latitude": 18.942421,
//       "Location": "Kalocsa/Foktő",
//       "Longitude": 46.549613,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 14097,
//       "Airport Code [ICAO]": "WABV",
//       "Airport Code iATA,FAA": "UGU",
//       "Airport Name": "Bilogai-Sugapa Airport",
//       "Altitude (Ft)": 7348,
//       "Country": "Indonesia",
//       "Latitude": 137.031998,
//       "Location": "Sugapa-Papua Island",
//       "Longitude": -3.73956,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 14098,
//       "Airport Code [ICAO]": "VOJK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Jakkur Aerodrome",
//       "Altitude (Ft)": 3013,
//       "Country": "India",
//       "Latitude": 77.597645,
//       "Location": "Bengaluru",
//       "Longitude": 13.07674,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 14099,
//       "Airport Code [ICAO]": "UAFJ",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Jalal-Abad Airport",
//       "Altitude (Ft)": 2591,
//       "Country": "Kyrgyzstan",
//       "Latitude": 72.977798,
//       "Location": "Jalal-Abad",
//       "Longitude": 40.944401,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 14100,
//       "Airport Code [ICAO]": "LLER",
//       "Airport Code iATA,FAA": "ETM",
//       "Airport Name": "Ramon Airport",
//       "Altitude (Ft)": 288,
//       "Country": "Israel",
//       "Latitude": 35.011416,
//       "Location": "Eilat",
//       "Longitude": 29.723694,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 14101,
//       "Airport Code [ICAO]": "OORQ",
//       "Airport Code iATA,FAA": "MNH",
//       "Airport Name": "Rustaq Airport",
//       "Altitude (Ft)": 349,
//       "Country": "Oman",
//       "Latitude": 57.4875,
//       "Location": "Al Masna'ah",
//       "Longitude": 23.640556,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 14102,
//       "Airport Code [ICAO]": "RPMY",
//       "Airport Code iATA,FAA": "CGY",
//       "Airport Name": "Laguindingan Airport",
//       "Altitude (Ft)": 190,
//       "Country": "Philippines",
//       "Latitude": 124.456496,
//       "Location": "Cagayan de Oro City",
//       "Longitude": 8.612203,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 14103,
//       "Airport Code [ICAO]": "ULPM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kostomuksha Airport",
//       "Altitude (Ft)": 681,
//       "Country": "Russia",
//       "Latitude": 30.687000274699997,
//       "Location": "Kostomuksha",
//       "Longitude": 64.61799621579999,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 14104,
//       "Airport Code [ICAO]": "XRAP",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Privolzhskiy Air Base",
//       "Altitude (Ft)": -66,
//       "Country": "Russia",
//       "Latitude": 47.893,
//       "Location": "Astrakhan",
//       "Longitude": 46.396,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 14105,
//       "Airport Code [ICAO]": "UUMB",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Kubinka Air Base",
//       "Altitude (Ft)": 614,
//       "Country": "Russia",
//       "Latitude": 36.650002,
//       "Location": "Kubinka",
//       "Longitude": 55.611695,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 14106,
//       "Airport Code [ICAO]": "ULDA",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Rogachyovo Air Base",
//       "Altitude (Ft)": 272,
//       "Country": "Russia",
//       "Latitude": 52.4782981873,
//       "Location": "Belaya",
//       "Longitude": 71.61669921880001,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 14107,
//       "Airport Code [ICAO]": "XIUW",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Ulan-Ude East Airport",
//       "Altitude (Ft)": 1670,
//       "Country": "Russia",
//       "Latitude": 107.73799896240234,
//       "Location": "Ulan Ude",
//       "Longitude": 51.849998474121094,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 14108,
//       "Airport Code [ICAO]": "ULLK",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Krechevitsy Air Base",
//       "Altitude (Ft)": 85,
//       "Country": "Russia",
//       "Latitude": 31.385000228881836,
//       "Location": "Novgorod",
//       "Longitude": 58.625,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 14109,
//       "Airport Code [ICAO]": "SCAT",
//       "Airport Code iATA,FAA": "CPO",
//       "Airport Name": "Desierto de Atacama Airport",
//       "Altitude (Ft)": 670,
//       "Country": "Chile",
//       "Latitude": -70.7791976929,
//       "Location": "Copiapo",
//       "Longitude": -27.2611999512,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     },
//     {
//       "ID": 14110,
//       "Airport Code [ICAO]": "UKDM",
//       "Airport Code iATA,FAA": null,
//       "Airport Name": "Melitopol Air Base",
//       "Altitude (Ft)": 0,
//       "Country": "Ukraine",
//       "Latitude": 35.305,
//       "Location": "Melitopol",
//       "Longitude": 46.880001,
//       "GMT Offset": null,
//       "Daylight Savings": null,
//       "Region": null,
//       "Type": "airport"
//     }
//    ];


 
