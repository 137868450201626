
export const GET_LOGIN = 'GET_LOGIN';
export const UPDATE_LOGIN = 'UPDATE_LOGIN';


// action handlers
export function getLoginState(text) {
  return { type: GET_LOGIN, text }
}

export function updateLoginState(data) {
   return { type: UPDATE_LOGIN, data }
}



//Reducer
const initialState = { login:""}
  
 export default function login(state =initialState, action) {
    switch (action.type) {
        case GET_LOGIN:          
          return {login: action.payload}  
                   
          case UPDATE_LOGIN:       
            let updatedLogin = {...action.payload};
            updatedLogin["login"] = action.payload;
            return  Object.assign({}, action.payload,{...updatedLogin});    
                 
        
        default:
          return state
      }
  }
