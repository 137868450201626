import React from "react";
import { Marker, InfoWindow } from "react-google-maps";
import { Segment, Header, Grid, Item } from "semantic-ui-react";

export default class MarkerWithInfoWindow extends React.Component {
	constructor() {
		super();
		this.state = {
			isOpen: false,
		};
		this.onToggleOpen = this.onToggleOpen.bind(this);
	}

	onToggleOpen() {
		this.setState({
			isOpen: !this.state.isOpen,
		});
	}

	render() {
		const student = this.props.content;
		const time = student[18] && student[18];
		const lastUpdate = new Date(time * 1000);
		// console.log(lastUpdate.toDateString());
		return (
			<Marker position={this.props.position} onClick={this.onToggleOpen}>
				{this.state.isOpen && (
					<InfoWindow onCloseClick={this.onToggleOpen}>
						<Segment style={{ height: "fit-content", width: "fit-content" }}>
							<Header as={"h5"} textAlign="center">
								{student[1]}
							</Header>
							<Grid columns={2}>
								<Grid.Column>
									<Item.Group>
										<Item>
											<Item.Content>
												<Item.Meta>
													<p>{"ID"}</p>
													<span className={"map_student_info"}>
														{student[0]}
													</span>
												</Item.Meta>
												<Item.Meta>
													<p>{"Email"}</p>
													<span className={"map_student_info"}>
														{student[2]}
													</span>
												</Item.Meta>
												<Item.Meta>
													<p>{"Mobile"}</p>
													<span className={"map_student_info"}>
														{student[3]}
													</span>
												</Item.Meta>
											</Item.Content>
										</Item>
									</Item.Group>
								</Grid.Column>
								<Grid.Column>
									<Item.Group>
										<Item>
											<Item.Content>
												<Item.Meta>
													<p>{"Status"}</p>
													<span className={"map_student_info"}>
														{student[16]}
													</span>
												</Item.Meta>
												<Item.Meta>
													<p>{"Location"}</p>
												</Item.Meta>
												<Item.Meta>
													<span className={"map_student_info"}>
														{"lat:" + student[17] + " long:" + student[18]}
													</span>
												</Item.Meta>
												<Item.Meta>
													<p>{"Last Update "}</p>{" "}
													<span className={"map_student_info"}>
														{lastUpdate.toDateString()}
													</span>
												</Item.Meta>
											</Item.Content>
										</Item>
									</Item.Group>
								</Grid.Column>
							</Grid>
						</Segment>
					</InfoWindow>
				)}
			</Marker>
		);
	}
}
