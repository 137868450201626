import React from "react";
import { Tab, Container } from "semantic-ui-react";
import StudentList from "./Student List/student-list";
import StudentMap from "./Student List/student-map";
import { connect } from "react-redux";
import StudentDistribution from "./Student List/student-dist-chart";
import { get } from "../functions/http";

class ListTabs extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.getStudents(this.props.school.ID);
	}
	async getStudents(schoolID) {
		let result = await get("getStudents?school=" + schoolID);
		this.setState({ studentList: result }, () => {
			console.log(this.state.studentList);
		});
	}
	render() {
		if (!this.state.studentList) return null;
		return (
			<Container className="listtab">
				<Tab
					panes={[
						{
							menuItem: "Student List",
							render: () => (
								<Tab.Pane className="tabpane">
									<StudentList
										// schoolid={this.props.school.ID}
										allStudents={this.state.studentList}
									/>
								</Tab.Pane>
							),
						},
						{
							menuItem: "Student Map",
							render: () => (
								<Tab.Pane>
									<StudentMap allStudents={this.state.studentList} />
								</Tab.Pane>
							),
						},
						{
							menuItem: "Status Distribution",
							render: () => (
								<Tab.Pane>
									<StudentDistribution allStudents={this.state.studentList} />
								</Tab.Pane>
							),
						},
					]}
					defaultActiveIndex={0}
				/>
			</Container>
		);
	}
}

export default connect((state) => ({ school: state.school }))(ListTabs);
