import React,{Component} from 'react';

let loaded = false;
let callbacks = [];

window.google.charts.load('current', {'packages':['corechart','bar']});
window.google.charts.setOnLoadCallback(()=>{
    loaded=true;
    callbacks.forEach(fn=>fn());
});

var domID = 0;

export class Chart extends Component {
    constructor(props) {
        super(props);
        this.domID = "chart"+(domID++);
    }

    render = () => <div id={this.domID} />;
    componentDidMount =  () => this.drawCharts();
    componentDidUpdate = () => this.drawCharts();
    
    drawCharts = () => {
        if(!loaded) {
            callbacks.push(this.drawCharts);
            return;
        }
        const g = window.google;
        const data = g.visualization.arrayToDataTable(this.props.data);
        const chartConstructor = g.charts[this.props.chartType] ? 
            g.charts[this.props.chartType] : g.visualization[this.props.chartType];
        const chart = new chartConstructor(document.getElementById(this.domID));
        // Convert options if there's a function for it provided by Material, otherwise pass through
        const options = (g.charts[this.props.chartType] && g.charts[this.props.chartType].convertOptions) ?
            g.charts[this.props.chartType].convertOptions(this.props.options) : this.props.options;
        chart.draw(data, options);
      }
}
