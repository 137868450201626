import React from "react";
import { Tab, Container } from "semantic-ui-react";
import ProgressReport from "./Reports/progress-report";
import SurveyQuestions from "./Reports/survey-questions";

import SurveyReportYearly from "./Reports/survey-report-yearly";

class Reports extends React.Component {
	render() {
		return (
			<Container className="listtab">
				<Tab
					panes={[
						{
							menuItem: "14 Day Progress Report",
							render: () => (
								<Tab.Pane className="tabpane">
									{/*  quarantine typeid = 60  */}
									<ProgressReport schoolid={this.props.schoolid} typeid={60} />
								</Tab.Pane>
							),
						},
						{
							menuItem: "Survey Completion",
							render: () => (
								<Tab.Pane>
									<Container>{<SurveyQuestions />}</Container>
								</Tab.Pane>
							),
						},
						{
							menuItem: "Survey Analysis Yearly",
							render: () => (
								<Tab.Pane>
									<Container>{<SurveyReportYearly />}</Container>
								</Tab.Pane>
							),
						},
					]}
					defaultActiveIndex={0}
				/>
			</Container>
		);
	}
}

export default Reports;
