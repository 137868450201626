import React from "react";
import { connect } from "react-redux";
import { Container, Grid,Table, Form,  Message, TextArea,Tab,Button} from 'semantic-ui-react'
import { get,post } from "../../functions/http.js";
import { BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Chart} from "../Reports/chart.js";
import { __RouterContext } from "react-router";

class SurveyReportYearly extends React.Component {
	
	
	constructor(props) {
		super(props);
		this.state = { ...props };	
		this.state = {
				surveyDataMap:[], 
				surveyData:[] ,
				types:[],
				data:[],			
				comments : []
			};	

				
	}

	componentDidMount(){

		this.getStudentSurveys(this.props.school.ID)
			
		let sd = [];		
		
		this.props.school.Surveys.forEach((s,i)=>{				
			s.Questions.forEach((q,z)=>{
				sd.push([i,z,q])					
			})					
		})			
		
		let types={
				1000:[[],[],[],[]],
				1001:[[],[],[],[]],
				1002:[[],[],[],[]],
				1003:[[],[],[],[]],
				1004:[[],[],[],[]],				
		};
		
		
		
		//question list 		
		this.setState({"surveyDataMap": sd ,"types":types, "activeIndex":0, surveyData: [], comments: this.props.school.Comments ? this.props.school.Comments:[]});
		
		
	}


	async setSchool(data) {
		data.Comments = this.state.comments;		
		data.ID = parseInt(data.ID)
		let result = await post("setSchool",data);		
		if (result){
		   result.ID = data.ID;
		   console.log("School Data Updated");		  
		} else {
		  console.log("changes not saved");
		}		
	  };

	  saveSchool = () =>{
		this.setSchool(this.props.school);            
	  }


	async getStudentSurveys(schoolid) {		
		let result = await get("getStudentsSurveys?school="+this.props.school.ID);		
		if (result){
			this.setState({data:result});	
			this.setState({surveyData:this.getTotals(this.state.activeIndex),activeIndex: this.state.activeIndex})		
		} 		

	}	

	getCounts(arr,val){
		var count = 0;
		arr.forEach((v) => (v === val && count++));
		return count;
	}

	getTotals(activeIndex){	

		let k = "100" + activeIndex;
		
		let questiontotal1 = []
		let questiontotal2 = []
		let questiontotal3 = []
		let questiontotal4 = []		

		
		
		this.state && this.state.data.map((r,i)=>{							
			if(parseInt(k) === parseInt(r.Type)){					
				questiontotal1.push(r.Values[0]) //q1 responses 
				questiontotal2.push(r.Values[1]) //q2 responses	
				questiontotal3.push(r.Values[2]) //q3 responses		
				questiontotal4.push(r.Values[3]) //q4 responses				
			}
		})		
		return [questiontotal1,questiontotal2,questiontotal3,questiontotal4];

		// if(this.state){
		// 	this.setState({surveyData:this.getTotals(activeIndex),activeIndex: 0})			
		// }	
	   
	}

	  commentChanged = (index,e) =>{
		
		  let c = this.state.comments;
		 
			if (!c){
		 		c.push(e.currentTarget.value)
			 } else {
				c[index] = e.currentTarget.value 
			 }
			 
			 this.setState({comments:c})
		}
	  

	handleChange = (e, data) => {			
		this.setState({surveyData:this.getTotals(data.activeIndex),activeIndex: data.activeIndex})							
	}

	dataIncludes(arr,row){		
		arr.forEach((d,z)=>{			
			if(d.includes(row[0])){					
				return true;
			}
		})
		return false
	}

	convertNumbersByType(it ,typ){
		if (typ == 1 || typ === 2){
			if(it === 20 ){
				it = 5		
			} else if(it === 15){
				it = 4
			} else if (it === 10){
				it = 3			
			} else if (it === 5){
				it = 2			
			} else if (it === 0){
				it = 1			
			}
			return it;
		}  else {
			return it
		}
	}

	
	render() {	

		
		
		//call first load here  and add to data array may have to do convert number as a fn then call again 

		const panes = [];
		let count = 0;
		let data = [];		
		let questionTypes =[];
		let filteredData = [[['Question', 'Count']],[['Question', 'Count']],[['Question', 'Count']],[['Question', 'Count']]];

		if (this.state.data.length > 0) {
		
		this.state.surveyDataMap.map((t,y)=>{
			if (t[0] === this.state.activeIndex){
				questionTypes.push(t[2].Type)					
			}
		})

		if(this.state && this.state.surveyData){					
			
				this.state.surveyData.map((c,x)=>{			
					
					 c.map((it,z)=>{
							if(it){
							//convert from points back to rating out of 5 
								switch (questionTypes[x]) {
									case 1: //sad happy	
										data.push([this.convertNumbersByType(it,1).toString(), this.getCounts(c,it),x])																		
										break;							
									case 2: //bad good
										data.push([this.convertNumbersByType(it,2).toString(), this.getCounts(c,it),x])
										break;
									case 3: //no/yes												
										data.push([this.convertNumbersByType(it,3).toString(), this.getCounts(c,it),x])
										break;							
									default:									
							}
						}	
					})
				})
		}
		
		// [['Question', 'Count'],[]]
	
				
	
	

		data.forEach((d,v)=>{
			
			if(d[2] === 0){
				if (this.dataIncludes(filteredData[0],d) === false){
					let isIn = false; 
					filteredData[0].forEach((f,z)=>{
						if(f[0].includes(d[0]) === true ){
							isIn = true;								
						} 							
					})
					if(isIn === false){
						filteredData[0].push([d[0],d[1]])
						return;
					}
					
				}
			}

			if(d[2] === 1){
				if (this.dataIncludes(filteredData[1],d) === false){
					let isIn = false; 
					filteredData[1].forEach((f,z)=>{
						if(f[0].includes(d[0]) === true ){
							isIn = true;								
						} 							
					})
					if(isIn === false){
						filteredData[1].push([d[0],d[1]])
						return;
					}
					
				}
			}

			if(d[2] === 2){
				if (this.dataIncludes(filteredData[2],d) === false){
					let isIn = false; 
					filteredData[2].forEach((f,z)=>{
						if(f[0].includes(d[0]) === true ){
							isIn = true;								
						} 							
					})
					if(isIn === false){
						filteredData[2].push([d[0],d[1]])
						return;
					}
					
				}
			}
			if(d[2] === 3){
				if (this.dataIncludes(filteredData[3],d) === false){
					let isIn = false; 
					filteredData[3].forEach((f,z)=>{
						if(f[0].includes(d[0]) === true ){
							isIn = true;								
						} 							
					})
					if(isIn === false){
						filteredData[3].push([d[0],d[1]])
						return;
					}
					
				}
			}

		})

		}

					
		Object.keys(this.state.types).map((k)=>{	
			
			panes.push(
			{ menuItem: "Survey " + (parseInt(k.substring(3))+1).toString(), render: () => <Tab.Pane>
				
				<Grid columns={"equal"}>       		
					<Grid.Column>		
					<Form>				                   
					 <Table celled>	
					 <Table.Header>	
						<Table.Row>		
						<Table.HeaderCell>
							{"Survey Question"}
						</Table.HeaderCell>		
						<Table.HeaderCell>
							{"Distribution of Answers"}
						</Table.HeaderCell>						
						</Table.Row>
					</Table.Header>								
					<Table.Body>
						
						{this.state && this.state.surveyDataMap.map((q,z)=>{		
					
						if( (1000 + parseInt(q[0])) === parseInt(k)  ){		
							//console.log(this.state.surveyData[count++]);	
																										
									return(
										<React.Fragment key={z}>									
											<Table.Row>
												<Table.Cell>
													<span className="question">
														{q[2]["Text"]} 					
													</span>
													<TextArea 
														style={{width:"200pt",height:"100pt"}} 
														onChange={(e)=>this.commentChanged(z,e)}														
														value={this.state.comments ? this.state.comments[z] : "Please enter a comment"}/>													
													
												</Table.Cell>
												<Table.Cell>
													<span className="chart">
													
													{<Chart 
														className="chart"
														chartType="PieChart"
														data={filteredData[z-q[0]*4]}
														options={{
															title: '',
															titleTextStyle: {
																fontSize: 14,
															},
															backgroundColor: { fillOpacity: .4, fill: 'white' },
															legend: { position: 'none' },												 
														}}											
														/> 
														}
													</span>											
												</Table.Cell>
											</Table.Row>																
										</React.Fragment>
									);
													
								}							
						})}		
										
					</Table.Body>  
				  </Table>
				  <Button className="save" onClick={(e)=>this.saveSchool(this.props.school)} >{"Save"}</Button>
				  </Form>
				</Grid.Column>      
				</Grid>
				</Tab.Pane>},
			)			
		});
		
		if (this.props.school.Surveys){		
				
			return (         
			  <Container class="innergrid">
				 <Tab 
				 	 onTabChange={this.handleChange}
				 	 panes={panes}
					 menu={{ fluid: true, vertical: false }}
				 	 menuPosition='left'
				 /> 						
			  </Container>			  
			); 
		 }  else {
		   return (
			<React.Fragment> 
			  <Message>{"No data received by this view"}</Message>         
			</React.Fragment> 
		   );
		 }
	}

		
}

export default connect(state=>({school:state.school}))(SurveyReportYearly);
