import React from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Lists from "./components/lists.jsx";
import Config from "./components/config/config.jsx";
// import Home from "./components/home.jsx";
import Nav from "./components/nav.jsx";
import Login from "./components/Login/login-page";
import Student from "./components/student.jsx";
import "./App.css";
import Reports from "./components/reports.jsx";
import Survey from "./components/Reports/survey.jsx";
import { Message, Button, SearchResults } from "semantic-ui-react";
import { post, get } from "./functions/http";
import QuarantinedStudents from "./components/Reports/quarantined-students.jsx";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import LocationBreach from "./components/Student/Location Breach/location-breach.jsx";

class App extends React.Component {
	constructor(props) {
		super(props);
		this.loginUserCheck();
	}

	async loginUserCheck() {
		let result = await post("checkUserLogin");
		if (result) {
			this.getSchool(result[0]);
		} else {
			console.log("user not logged in");
		}
	}

	async getSchool(schoolID) {
		//console.log(document.cookie);
		let result = await get("getSchool?school=" + schoolID);
		if (result) {
			result.ID = schoolID;
			this.props.dispatch({ type: "GET_SCHOOL", payload: result });
		} else {
			console.log("data not received");
		}
	}

	render() {
		return (
			<div className="App">
				{!this.props.school.ID ? (
					<Login />
				) : (
					<Router>
						<Nav />
						<div>
							<Switch>
								<Route
									exact
									path="/"
									render={(props) => (
										<Lists {...props} schoolid={this.props.school.ID} />
									)}
								/>
								<Route path="/config">
									<Config />
								</Route>
								<Route path="/login">
									<Login />
								</Route>
								<Route
									path="/student-info/:studentid"
									render={(props) => (
										<Student {...props} schoolid={this.props.school.ID} />
									)}
								/>
								<Route
									path="/reports"
									render={(props) => (
										<Reports {...props} schoolid={this.props.school.ID} />
									)}
								/>
								<Route
									exact
									path="/surveyview/:studentid/:surveyid/:values"
									render={(props) => (
										<Survey {...props} school={this.props.school.ID} />
									)}
								/>
								<Route
									path="/student-list/day/:noofdays"
									render={(props) => (
										<QuarantinedStudents
											{...props}
											school={this.props.school.ID}
										/>
									)}
								/>
								<Route
									path="/location-breach/:studentid"
									render={(props) => (
										<LocationBreach
											{...props}
											schoolid={this.props.school.ID}
										/>
									)}
								/>
							</Switch>
						</div>
					</Router>
				)}
			</div>
		);
	}
}

//App = connect()(App)

export default connect((state) => ({
	school: state.school,
	login: state.login,
}))(App);

let surveytestdata = [
	{
		Title: "Survey 1",
		Days: 1,
		Questions: [
			{
				Text: "How are you feeling?",
				Type: 0,
			},
			{
				Text: "Are yoy happy with your room?",
				Type: 1,
			},
			{
				Text: "How many hours sleep did you have?",
				Type: 2,
			},
			{
				Text: "Do you have an internet connection for setup with devices?",
				Type: 1,
			},
			{
				Text: "Are you happy with the food?",
				Type: 3,
			},
		],
	},
	{
		Title: "Survey 2",
		Days: 4,
		Questions: [
			{
				Text: "How are you feeling?",
				Type: 0,
			},
			{
				Text: "Are yoy happy with your room?",
				Type: 1,
			},
			{
				Text: "How many hours sleep did you have?",
				Type: 2,
			},
			{
				Text: "Do you have an internet connection for setup with devices?",
				Type: 1,
			},
			{
				Text: "Are you happy with the food?",
				Type: 3,
			},
		],
	},
	{
		Title: "Survey 3",
		Days: 8,
		Questions: [
			{
				Text: "How are you feeling?",
				Type: 0,
			},
			{
				Text: "Are yoy happy with your room?",
				Type: 1,
			},
			{
				Text: "How many hours sleep did you have?",
				Type: 2,
			},
			{
				Text: "Do you have an internet connection for setup with devices?",
				Type: 1,
			},
			{
				Text: "Are you happy with the food?",
				Type: 3,
			},
		],
	},
	{
		Title: "Survey 4",
		Days: 11,
		Questions: [
			{
				Text: "How are you feeling?",
				Type: 0,
			},
			{
				Text: "Are yoy happy with your room?",
				Type: 1,
			},
			{
				Text: "How many hours sleep did you have?",
				Type: 2,
			},
			{
				Text: "Do you have an internet connection for setup with devices?",
				Type: 1,
			},
			{
				Text: "Are you happy with the food?",
				Type: 3,
			},
		],
	},
	{
		Title: "Survey 5",
		Days: 13,
		Questions: [
			{
				Text: "How are you feeling?",
				Type: 0,
			},
			{
				Text: "Are yoy happy with your room?",
				Type: 1,
			},
			{
				Text: "How many hours sleep did you have?",
				Type: 2,
			},
			{
				Text: "Do you have an internet connection for setup with devices?",
				Type: 1,
			},
			{
				Text: "Are you happy with the food?",
				Type: 3,
			},
		],
	},
];
