import React from "react";
import { connect } from "react-redux";
import { Container, Grid,Table, Form,  Message, Progress} from 'semantic-ui-react'
import { get } from "../../functions/http.js";
import { BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";

class SurveyQuestionsApp extends React.Component {
	
	
	constructor(props) {
		super(props);
		this.state = { ...props };	
		this.state = {studentSurveyData:[],allStudents:[]};
		
		let types=[1000,1001,1002,1003,1004];
		let days = [1,4,7,11,13];
		this.getStudents(this.props.school.ID);
		types.map((t)=>(
			
				this.getStudentSurveys(this.props.school.ID,t)
					
		));	
		
	}

	async getStudentSurveys(schoolid,eventid) {
		let result = await get("getStudentsEvent?school="+this.props.school.ID+"&type="+eventid);		
		if (result){
			this.setState({["std"+eventid]: result})
			//console.log(result);
		   //this.props.dispatch({type:"GET_SCHOOL",payload:result});
		} else {
			console.log("data not received");
		}		
	}	

	async getStudents(schoolID) {
		let result = await get("getStudents?school=" + schoolID);
		this.setState({ allStudents: result }, () => {
			//console.log(this.state.allStudents);
		});
	}


	render() {

		let events=["std1000","std1001","std1002","std1003","std1004"];	//hard code for now	

		if (this.props.school.Surveys){
			let days = [1,4,8,11,13];
			// /let std1000 = this.state.std1000;						
			return (         
			  <Container>
				<Form>
				
				<Grid columns={"equal"}>       		
					<Grid.Column>					                   
					 <Table celled className={"survey-completion-report"}>	
					 <Table.Header>	
						<Table.Row>		
						<Table.HeaderCell>
							{"Student Name/Days"} 
						</Table.HeaderCell>		
						{this.props && this.props.school.Surveys.map((s,i)=>{
							if(s.Days === days[i]){   			
							return(
								<Table.HeaderCell key={i}>
									{"Day "} {s.Days} 
								</Table.HeaderCell>
							);
							} else {
								return(
									<Table.HeaderCell key={i}>
										{"Day "} {days[i]}
									</Table.HeaderCell>
								);
							}					
						})}  
						</Table.Row>
					</Table.Header>
								
					<Table.Body>
					{this.props && this.state.allStudents.map((st,e)=>{   	
						let days = [1,4,8,11,13]; // hard code for now
								
						return(	
						<Table.Row key={e}>	
							<Table.Cell>
								{st["Name"]}								
							</Table.Cell>	
							{this.props && this.props.school.Surveys.map((s,i)=>{   								
						
							if (s.Days === days[i]){//1002											
								
								return(
									<Table.Cell key={i} style={{maxWidth:"150"}}>
										{(()=>{										
											if(!this.state[events[i]]) return <span>{"Survey not completed"}</span>;
											let eList = [...this.state[events[i]].filter((s2)=>s2.StudentID === st.ID)];
											
											if(eList.length ===0 ) return null;
											let s2 = eList[eList.length-1];
											var date = new Date(s2.At * 1000);
											let totals = 0;
											let values=[]; 
											s2.Values.map((v)=>{
												totals += v
												values.push(v);
											});	

											
											let percent = totals/20*100;																				
											
											return(
												<React.Fragment>
													{s2.Text}<br/><Link to={`/surveyview/${st.ID}/${Number(events[i].substr(-1))+1}/${values}`}>{date.toDateString()}</Link>
													{percent < 50 &&
														<Progress style={{width:50}} percent={percent} color={"red"} size={"tiny"}/>
													} 
													{(percent > 50) &&
														<Progress style={{width:50}} percent={percent} color={"green"} size={"tiny"}/>
													}
												</React.Fragment>
											);

										})()}															
									</Table.Cell>
								);
							} else{
								return(
									<Table.Cell key={i} style={{maxWidth:"150"}}></Table.Cell>
								)	
							}
						})}  																
						</Table.Row>
						);							
				  	})}  
					</Table.Body>  
				  </Table>
				</Grid.Column>      
				</Grid>
				</Form>       
				
			  </Container>
			  
			); 
		 }  else {
		   return (
			<React.Fragment> 
			  <Message>{"No data received by this view"}</Message>         
			</React.Fragment> 
		   );
		 }
	}
}

export default connect(state=>({school:state.school}))(SurveyQuestionsApp);
