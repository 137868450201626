export async function get(url) {
	return apiHttp(url, "GET", null);
}

export async function post(url, data) {
	return apiHttp(url, "POST", data);
}
export async function postImage(url, data) {
	try {
		let response = await fetch("/api/" + url, {
			method: "POST",
			redirect: "follow",
			body: data,
		});
		if (response.status >= 400) throw response.body;
		return response.text();
	} catch (err) {
		console.log(err);
	}
}

async function apiHttp(url, method, data) {
	try {
		let response = await fetch("/api/" + url, {
			method: method,
			mode: "cors", // no-cors, *cors, same-origin
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			credentials: "same-origin", // include, *same-origin, omit
			headers: method !== "GET" ? { "Content-Type": "application/json" } : {},
			redirect: "follow", // manual, *follow, error
			referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
			body: data !== null ? JSON.stringify(data) : null,
		});

		if (response.status >= 400) throw response.body;
		// if url is '/getImage' for fetching student image, then return response as text else as json
		if (url.substring(0, 8) == "getImage" || url == "uploadImage") {
			return response.text();
		} else {
			return response.json();
		}
	} catch (err) {
		console.log(err);
	}
}

//https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
