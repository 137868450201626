import React from "react";
import {Airports} from "./airports";
import { post } from "../../functions/http";
import {
	Button,
	Form,
	Grid,
	Dropdown,
	GridColumn,
	FormField,
	GridRow,
	Search,
	Table,
	Label,
	Message
} from "semantic-ui-react";

export default class StudentAirports extends React.Component {
	constructor(props) {
        
	super(props);
		
	        
    let homestaymobile = this.hasWhiteSpace(props.studentinfo.HomestayMobile)
        ? props.studentinfo.HomestayMobile.replace(/[^\d]/g, "")
        : props.studentinfo.HomestayMobile;
    let agentmobile = this.hasWhiteSpace(props.studentinfo.AgentMobile)
        ? props.studentinfo.AgentMobile.replace(/[^\d]/g, "")
        : props.studentinfo.AgentMobile;
    let studentmobile = this.hasWhiteSpace(props.studentinfo.Mobile)
        ? props.studentinfo.Mobile.replace(/[^\d]/g, "")
        : props.studentinfo.Mobile;
	 
		//AirportSelectData:this.createAirportSelectData(Airports,"A",null)[1],	

		let AirportData	=[]	

		this.props.studentinfo.Airports && this.props.studentinfo.Airports.forEach((a,i)=>{
			//console.log("Search Reset")
			//if(i < 100){
				AirportData.push([{[i]:this.createAirportSelectData(Airports,i,["AKL"],a["Airport Name"] + i )[1]}])
			//}	
		})

		console.log(props.studentinfo,"original school data to pass on");

		this.state = {        
            ID: this.props.studentid,
			SchoolName: props.studentinfo.SchoolName,
			SchoolContactName: props.studentinfo.SchoolContactName,
			SchoolContactPhone: props.studentinfo.SchoolContactPhone,
			SchoolContactEmail: props.studentinfo.SchoolContactEmail,
			Name: props.studentinfo.Name,
			Email: props.studentinfo.Email,
			Mobile: parseInt(props.studentinfo.Mobile),
			QuarantineLat: parseFloat(props.studentinfo.QuarantineLat),
			QuarantineLng: parseFloat(props.studentinfo.QuarantineLng),
			FlightNumber: props.studentinfo.FlightNumber,
			FlightDate: props.studentinfo.FlightDate,
			HomestayName: props.studentinfo.HomestayName,
			HomestayAddress:props.studentinfo.HomestayAddress,
			HomestayPhone: props.studentinfo.HomestayPhone,
			HomestayMobile: parseInt(homestaymobile),
			HomestayEmail: props.studentinfo.HomestayEmail,
			ParentEmail: props.studentinfo.ParentEmail,
			ParentMobile: props.studentinfo.ParentMobile,
			AgentName: props.studentinfo.AgentName,
			AgentEmail: props.studentinfo.AgentEmail,			
			AgentMobile: parseInt(agentmobile),
			LastEventTime: props.studentinfo.LastEventTime,
			LastEventType: props.studentinfo.LastEventType,
			LastEventText: props.studentinfo.SchooLastEventTextlName,
			LastLatitude:parseFloat(props.studentinfo.LastLatitude),
			LastLongitude:parseFloat(props.studentinfo.LastLongitude),
			LastAccuracy:parseInt(props.studentinfo.LastAccuracy),
			LastValues:props.studentinfo.LastValues,
			CovidTestDate: props.studentinfo.CovidTestDate,
			CovidTestResult: props.studentinfo.CovidTestResult,	
			Airports: props.studentinfo.Airports,
			AirportsSelectData:AirportData,
			saveMessage:"",
			letters:"A"
		};

	
	}

	

    createAirportSelectData = (Airports, index , searchFilter, name) =>{

	
		let AirportsData=[];
		let AirportsDataWithLatLong = [];			

		if (searchFilter.length){
		
		var ci = new RegExp(searchFilter,'i');
		
		Airports.forEach((a,i)=>{		
			//TODO: include additional search filters ie/ Airport name first letter Uppercase & Airportcode lowercase rules add in code to value 
			if(a["Airport Code iATA,FAA"] && a["Airport Code iATA,FAA"].includes(searchFilter) ||
				 ["Airport Code iATA,FAA"] && a["Airport Code iATA,FAA"].toLowerCase().includes(searchFilter)||
				 a["Airport Code iATA,FAA"] && a["Airport Code iATA,FAA"].toLowerCase().charAt(0).toUpperCase().includes(searchFilter)
			 ){
			  	AirportsData.push({ key: a["Airport Name"] + i ,value:a["Airport Name"], text: a["Airport Code iATA,FAA"] + " " + a["Airport Name"]})
			  	AirportsDataWithLatLong.push({ key: a["Airport Name"],value:a["Airport Name"], text:a["Airport Code iATA,FAA"] + " " + a["Airport Name"], latitude: a["Latitude"],latitude: a["Longitude"]})
			} else if(a["Airport Name"] && a["Airport Name"].includes(searchFilter)
			||a["Airport Name"] && a["Airport Name"].toLowerCase().includes(searchFilter)||
			a["Airport Name"] && a["Airport Name"].toUpperCase().includes(searchFilter)||
			a["Airport Name"] && a["Airport Name"].toLowerCase().charAt(0).toUpperCase().includes(searchFilter)
			){
				AirportsData.push({ key: a["Airport Name"] + i ,value:a["Airport Name"], text: a["Airport Code iATA,FAA"] + " " + a["Airport Name"]})
				AirportsDataWithLatLong.push({ key: a["Airport Name"],value:a["Airport Name"], text:a["Airport Code iATA,FAA"] + " " + a["Airport Name"], latitude: a["Latitude"],latitude: a["Longitude"]})
			}		
		})

		}

		//console.log(AirportsData)

		//  let filteredAirports = Airports.filter((airport,i) =>{
		//  	if (airport["Airport Code iATA,FAA"].includes(searchFilter)){
		//  		return { key: airport["Airport Name"] + i ,value: airport["Airport Name"], text: airport["Airport Code iATA,FAA"] + " " + airport["Airport Name"]};
		//  	}
		// })

		// let filteredAirports2 = Airports.filter((a,i) =>{
		//  	if (a["Airport Name"].includes(searchFilter)){
		//  		return { key: a["Airport Name"],value:a["Airport Name"], text:a["Airport Code iATA,FAA"] + " " + a["Airport Name"], latitude: a["Latitude"],latitude: a["Longitude"]};
		//  	}
		// })
				
		if (!name){	//must be first load 
			return [AirportsData ,AirportsDataWithLatLong];
		} else{//must be change event and there for airport dropdown list must be updated 
			this.setState({AirportSelectData:[...AirportsData]})	
			
		}
		
	} 

    //http post
	async setStudent(data) {
		data.ID = parseInt(data.ID);
		let result = await post("setStudents", {			
			ID: parseInt(this.props.schoolid),
			Students: [data],
		});
		// console.log(result);
		if (result) {
			this.setState({
				saveMessage: "Changes saved successfully!",
				student: data,
				color:"green",				
			});
		} else {
			this.setState({
				saveMessage: "Something happened. Please try again!",
				color:'red'
			});
		}
    }
    
    handleSubmit = () => {
        
        let homestaymobile = this.hasWhiteSpace(this.state.HomestayMobile)
			? this.state.HomestayMobile.replace(/[^\d]/g, "")
			: this.state.HomestayMobile;
		let agentmobile = this.hasWhiteSpace(this.state.AgentMobile)
			? this.state.AgentMobile.replace(/[^\d]/g, "")
			: this.state.AgentMobile;
		let studentmobile = this.hasWhiteSpace(this.state.Mobile)
			? this.state.Mobile.replace(/[^\d]/g, "")
			: this.state.Mobile;

		let apchanges = [];

		 	//collect airport names
		 this.props.studentinfo.Airports.forEach((a,i) =>{
		  		apchanges.push(
		  			{
		  				"Name": this.state["Airport"+i]?this.state["Airport"+i]:a["Name"],
		  				"Lat":this.state["Airport" +i +"Lat"]?this.state["Airport" +i +"Lat"]:a["Lat"],
		  				"Long":this.state["Airport" +i +"Long"]?this.state["Airport" +i +"Long"]:a["Long"],
		  			}
		  			)		 								
		})		

	
		const data = {
            ID: this.props.studentid,
			SchoolName: this.state.SchoolName,
			SchoolContactName: this.state.SchoolContactName,
			SchoolContactPhone: this.state.SchoolContactPhone,
			SchoolContactEmail: this.state.SchoolContactEmail,
			Name: this.state.Name,
			Email: this.state.Email,
			Mobile: parseInt(studentmobile),
			QuarantineLat: parseFloat(this.state.QuarantineLat),
			QuarantineLng: parseFloat(this.state.QuarantineLng),
			FlightNumber: this.state.FlightNumber,
			FlightDate: this.state.FlightDate,
			HomestayName: this.state.HomestayName,
			HomestayAddress: this.state.HomestayAddress,
			HomestayPhone: this.state.HomestayPhone,
			HomestayMobile: parseInt(homestaymobile),
			HomestayEmail: this.state.HomestayEmail,
			ParentEmail: this.state.ParentEmail,
			ParentMobile: this.state.ParentMobile,
			AgentName: this.state.AgentName,
			AgentEmail: this.state.AgentEmail,
			AgentMobile: parseInt(agentmobile),
			LastEventTime: this.state.LastEventTime,
			LastEventType: this.state.LastEventType,
			LastEventText: this.state.SchooLastEventTextlName,
			LastLatitude :this.state.LastLatitude,  
			LastLongitude :this.state.LastLongitude, 
			LastAccuracy :this.state.LastAccuracy, //tbc 
			LastValues:this.state.LastValues,
			CovidTestDate: this.state.CovidTestDate,
			CovidTestResult: this.state.CovidTestResult,	
			Airports:apchanges,		

			//TODO: loop thrus and strip out airport codes out of airport value before sending up
				
		};

		console.log(data);

		//this.setStudent(data);
	};


  
    customSelectChange = (e,data) => {	
		console.log(e,data)
        let lat;
		let long; 
		Airports.forEach((a,i)=>{						
			if(a["Airport Name"] === data.value){		
				lat	= a["Latitude"]
				long = a["Longitude"]
			}	
		})


		//update captured value in select
		this.setState({[data.name]:data.value,[data.name+"Lat"]:lat,[data.name+"Long"]:long})
		//update airports in submit
		
	};
	
	
	 onKeyDown = (event,name) => {

		 event.persist();
		 //this.setState({ [name+"Search"]:String.fromCharCode(event.keyCode)});

		 if(String.fromCharCode(event.keyCode)){
		 	this.createAirportSelectData(Airports, parseInt(name.charAt(name.length-1)), event.target.value, name)	
		 }
	}
     
     hasWhiteSpace = (str) => {
		return /\s/g.test(str);
	};


	render() {		

		return (
			<div className="student-airports">
				{this.state.saveMessage &&
					<Message color={this.state.color}>{this.state.saveMessage}</Message>					
				}	
                <Form onSubmit={this.handleSubmit}>
				<Table celled className={"reportTable"}>
					<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Airport Name</Table.HeaderCell>
						<Table.HeaderCell>Lat</Table.HeaderCell>
						<Table.HeaderCell>Long</Table.HeaderCell>
					</Table.Row>
					</Table.Header>
					<Table.Body>
					{!this.state.Airports &&
					
						<Table.Row>						
							<Table.Cell colSpan={"3"}>{"This student does not have any airport check ins"}</Table.Cell>
						</Table.Row>
						
					}

					{this.state.Airports && this.state.Airports.map((s,i)=>{						
						return(
							<Table.Row key={i}>						
																
									{/* <Dropdown
										placeholder='Edit Airport'
										fluid
										search
										selection
										//value={this.state["Airport"+i]?this.state["Airport"+i]:this.props.studentinfo.Airports[i]["Name"]}
										options={this.state["AirportSelectData"]}
										onChange={this.customSelectChange}
										name={"Airport"+i}
										onKeyDown={(e)=>this.onKeyDown(e,"Airport"+i)}
									/> */}									 
							
								<Table.Cell>	
									<Dropdown
										button
										className='icon'
										floating
										labeled
										icon='plane'
										options={this.state["AirportSelectData"]}
										search
										text={this.state["Airport"+i]?this.state["Airport"+i]:this.props.studentinfo.Airports[i]["Name"]}
										onChange={this.customSelectChange}
										name={"Airport"+i}
										onKeyUp={(e)=>this.onKeyDown(e,"Airport"+i)}
									/>
								</Table.Cell>
								<Table.Cell>{this.state["Airport"+i+"Lat"]?this.state["Airport"+i+"Lat"]:this.props.studentinfo.Airports[i]["Lat"]}</Table.Cell>
								<Table.Cell>{this.state["Airport"+i+"Long"]?this.state["Airport"+i+"Long"]:this.props.studentinfo.Airports[i]["Long"]}</Table.Cell>
							</Table.Row>						
						)						
					})}	
			
						<Table.Row>
							<Table.HeaderCell colSpan='4'>
								<Button style={{marginLeft:"10pt",marginBottom:"10pt"}} type="submit" className="save"> Save Changes</Button>	
							</Table.HeaderCell>
						</Table.Row>
					</Table.Body>				
					</Table>		
                 </Form>
			</div>
		);
	
				
    }
}
