import React from 'react';
import { Container, Form, Button,Icon } from 'semantic-ui-react'
import { connect } from "react-redux";
import {post} from "../../functions/http.js";

class SchoolInfoForm  extends React.Component {
  
async setSchool(data) {
  data.ID = parseInt(data.ID)  
  console.log(data.ID)
  let result = await post("setSchool",data);
  console.log(data);		
  if (result){
     result.ID = data.ID;
     console.log("school data updated");
    //this.props.dispatch({type:"SET_SCHOOL",payload:result});
    //this.props.dispatch({type:"GET_SCHOOL",payload:result});
  } else {
    console.log("changes not saved");
  }		
};

valueFloatChanged = (e) =>{
  let val = parseFloat(e.currentTarget.value);
  if(val === null) return;
  this.props.dispatch({type:"UPDATE_SCHOOL",payload:[this.props.school,e.currentTarget.name,val]});    
}

valueIntChanged = (e) =>{
  let val = parseInt(e.currentTarget.value);
  if(val === null) return;
  this.props.dispatch({type:"UPDATE_SCHOOL",payload:[this.props.school,e.currentTarget.name,val]});    
}
  valueChanged = (e) =>{
    //console.log(e.currentTarget);
   this.props.dispatch({type:"UPDATE_SCHOOL",payload:[this.props.school,e.currentTarget.name,e.currentTarget.value]});    
   //this.props.dispatch({type:"UPDATE_SCHOOL",payload:[this.props.school,"Surveys",surveytestdata]}); 
  }

  saveSchool = () =>{
    this.setSchool(this.props.school);            
  }

  render(){  
     
    return (
      <Container className="content">
        
        <div >
        <Form className="schoolinfo">
              <Form.Field>
                <label>Contact Name</label>
                <input                  
                 placeholder='Contact Name' 
                 value={this.props.school.ContactName}
                 onChange={this.valueChanged} 
                 name={"ContactName"}
                />
             </Form.Field>
             <Form.Field>
                <label>Phone</label>
                <input 
                  placeholder='Phone'
                  value={this.props.school.ContactPhone}
                  onChange={this.valueChanged} 
                  name={"ContactPhone"}
                />
             </Form.Field>
             <Form.Field>
                <label>Mobile Number +</label>
                <input 
                  placeholder='Mobile Number'
                  value={this.props.school.ContactMobile}
                  onChange={this.valueIntChanged} 
                  name={"ContactMobile"}
                />
             </Form.Field>
            <Form.Field>
                <label>Email</label>
                <input placeholder='Email'
                value={this.props.school.ContactEmail}
                onChange={this.valueChanged} 
                name={"ContactEmail"}
              />              
            </Form.Field> 
            
           
            <Form.Field>
                <label>Location<Icon link name='close' name={"map marker"} 
                onClick={(e)=> window.open("https://www.google.com/maps/search/?api=1&query="+parseFloat(this.props.school.Loc.split(",")[0])+","+parseFloat(this.props.school.Loc.split(",")[1]))}/></label>
                <input style={{width:200}} placeholder='Location'
                value={this.props.school.Loc}
                onChange={this.valueChanged} 
                name={"Loc"}
              />
            </Form.Field>                           
            <Form.Field>
                <label>Orientation Url</label>
                <input placeholder='Orientation URL' 
                value={this.props.school.OrientationURL}
                name={"OrientationURL"}
                onChange={this.valueChanged} 
                />
            </Form.Field>
            <Button type='submit'  className={"save"} onClick={this.saveSchool}>Save</Button>
        </Form>
        </div>
      </Container>
    );
  }
}

export default connect(state=>({school:state.school}))(SchoolInfoForm);

/*
0 - sad/happy
1 - no/yes
2 - 12345
3 - bad/good
*/

// let surveytestdata = [
//   {	
//     "Title": "Survey 1",
//     "Days": 1,
//     "Questions": [{
//       "Text": "How are you feeling?",
//       "Type": 0
//     },{
//       "Text": "Are yoy happy with your room?",
//       "Type": 1
//     },{
//       "Text": "How many hours sleep did you have?",
//       "Type": 2
//     },
//     {
//       "Text": "Do you have an internet connection for setup with devices?",
//       "Type": 1
//     },{
//       "Text": "Are you happy with the food?",
//       "Type": 3
//     } 
//   ]
//   },
//   {	
//     "Title": "Survey 2",
//     "Days": 4,
//     "Questions": [{
//       "Text": "How are you feeling?",
//       "Type": 0
//     },{
//       "Text": "Are yoy happy with your room?",
//       "Type": 1
//     },{
//       "Text": "How many hours sleep did you have?",
//       "Type": 2
//     },
//     {
//       "Text": "Do you have an internet connection for setup with devices?",
//       "Type": 1
//     },{
//       "Text": "Are you happy with the food?",
//       "Type": 3
//     } 
//   ]
//   },{	
//     "Title": "Survey 3",
//     "Days": 8,
//     "Questions": [{
//       "Text": "How are you feeling?",
//       "Type": 0
//     },{
//       "Text": "Are yoy happy with your room?",
//       "Type": 1
//     },{
//       "Text": "How many hours sleep did you have?",
//       "Type": 2
//     },
//     {
//       "Text": "Do you have an internet connection for setup with devices?",
//       "Type": 1
//     },{
//       "Text": "Are you happy with the food?",
//       "Type": 3
//     } 
//   ]
//   },{	
//     "Title": "Survey 4",
//     "Days": 11,
//     "Questions": [{
//       "Text": "How are you feeling?",
//       "Type": 0
//     },{
//       "Text": "Are yoy happy with your room?",
//       "Type": 1
//     },{
//       "Text": "How many hours sleep did you have?",
//       "Type": 2
//     },
//     {
//       "Text": "Do you have an internet connection for setup with devices?",
//       "Type": 1
//     },{
//       "Text": "Are you happy with the food?",
//       "Type": 3
//     } 
//   ]
//   },{	
//     "Title": "Survey 5",
//     "Days": 13,
//     "Questions": [{
//       "Text": "How are you feeling?",
//       "Type": 0
//     },{
//       "Text": "Are yoy happy with your room?",
//       "Type": 1
//     },{
//       "Text": "How many hours sleep did you have?",
//       "Type": 2
//     },
//     {
//       "Text": "Do you have an internet connection for setup with devices?",
//       "Type": 1
//     },{
//       "Text": "Are you happy with the food?",
//       "Type": 3
//     } 
//   ]
//   }
// ];

