
export const GET_SCHOOL = 'GET_SCHOOL';
export const UPDATE_SCHOOL = 'UPDATE_SCHOOL';
export const SET_SCHOOL = 'SET_SCHOOL';
export const UPDATE_SURVEYS = 'UPDATE_SURVEYS';

// action handlers
export function getSchool(text) {
  return { type: GET_SCHOOL, text }
}

export function updateSchool(data) {
   return { type: UPDATE_SCHOOL, data }
}

export function setSchool(school) {
  return { type: SET_SCHOOL, school }
}
//Reducer
const initialState = {}
  
 export default function school(state =initialState, action) {
    switch (action.type) {
        case GET_SCHOOL:          
          return action.payload;       
          
          case UPDATE_SCHOOL:       
            let updatedSchool = {...action.payload[0]};
            updatedSchool[action.payload[1]] = action.payload[2];
            return  Object.assign({}, action.payload[0],{...updatedSchool});    
          
          case UPDATE_SURVEYS:       
          //if the text is updated
           console.log(action.payload); 
          let updatedSchoolSurveys = {...action.payload[0]};
          updatedSchoolSurveys["Surveys"][action.payload[1]]["Questions"][action.payload[2]][action.payload[3]]=action.payload[4]; 
        
          return  Object.assign({}, action.payload[0],{...updatedSchoolSurveys});    
                  case SET_SCHOOL:       
            return action.payload;
        
        default:
          return state
      }
  }

