import React from "react";
import { get } from "../../functions/http.js";

import { connect } from "react-redux";
import { Container, Grid,Table, Form, Button, Message,Input,Dropdown, Menu,Icon, Checkbox,Radio,Rating,Segment,Statistic} from 'semantic-ui-react'
import "./survey.css";

class Survey extends React.Component {

	async getStudentEvents(studentid, schoolid) {
		let result = await get(
			"getStudentEvents?student=" + studentid + "&school=" + schoolid
		);
		if (result){		
			this.setState({studentEvents:result})
		}	
	}



	constructor(props) {
		super(props);
		
	}

	componentDidMount(){
		this.getStudentEvents(Number(this.props.match.params.studentid), parseInt(this.props.school.ID));
	}

	
	render() {

		let types = ["1000","1001","1002","1003","1004"];
		
		if (this.props.school.Surveys && this.state){	
				let surveydata = {
					1000:[],
					1001:[],
					1002:[],
					1003:[]

				};
				let count =[0,0,0,0];

				this.state.studentEvents.forEach((s,i)=>{
					
					if(typeof s["Type"] === 'number'&& s["Type"] === 1000){						
						return(						
							//count[0]+=1							
							surveydata[1000].push(s.Values)
						)
					}
					if(typeof s["Type"] === 'number' && s["Type"] === 1001){
						return(						
							surveydata[1001].push(s.Values)
						)
					}
					if(typeof s["Type"] === 'number' && s["Type"] === 1002){
						return(						
							surveydata[1002].push(s.Values)
						)
					}
					if(typeof s["Type"] === 'number'&& s["Type"] === 1003){
						return(						
							surveydata[1003].push(s.Values)
						)
					}
					
				})
		
			
			
			return (         
			  <Container>							
				<Grid className="surveyview" stackable columns={"equal"}>					
				   {this.props && this.props.school.Surveys.map((s,i)=>{				   
					let ts = 0;
					let arr = this.props.match.params.values.split(",");
					arr.forEach((a,i)=>{
						ts+= Number(a);						
					})

				
					if (Number(this.props.match.params.surveyid-1) === i){											             
					return(
					<Grid.Column  key={i}>
					  <h3 style={{marginTop:15}}>{s.Title} - Score {ts}/{"20"} </h3>                   
					  <Table celled>
					  <Table.Header>
					  <Table.Row>
						<Table.HeaderCell>Question</Table.HeaderCell>					                  
					  </Table.Row>
					  </Table.Header>                 
						<Table.Body>						
						  {s.Questions.map((q,z)=>{								
							return(
								<Table.Row key={z} >
									<Table.Cell>
									<p>{q["Text"]}</p>						
									<Rating maxRating={5} defaultRating={this.props.match.params.values.split(",")[z]} icon='star' size='large' />										
															
									</Table.Cell>							
								</Table.Row> 
								);
								
						  	})}                  
						</Table.Body>                 
					</Table>
				
					</Grid.Column>
					);
					}
				  })}        
				</Grid>
				 
			  </Container>
					
			); 
		 }  else {
		   return (
			<React.Fragment> 
			  <Message>{"No data received by this view"}</Message>         
			</React.Fragment> 
		   );
		 }
	}
}

export default connect(state=>({school:state.school}))(Survey);
