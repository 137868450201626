import React from "react";
import {
	Button,
	Form,
	Grid,
	Message,
	Input,
	Dropdown,
	Segment,
	GridColumn,
	Placeholder,
	Icon,
	Image,
	Container,
	Menu
} from "semantic-ui-react";
import { post, postImage, get } from "../../functions/http";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getdistance } from "../../functions/distance";

import { Link } from "react-router-dom";

export default class StudentInfo extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...props.studentinfo,
			selectedTestDate: new Date(),
			saveMessage: "",
			arrivalDate: "",
			studentEvents: this.props.studentEvents,
			imageFile: null,
			outputImage: null,
			uploadFail: "",
			Status: this.setStatusDisplayValue(props.studentinfo.Status),
		};
		this.getstudentImage(
			parseInt(this.props.studentid),
			parseInt(this.props.schoolid)
		);
	}

	// eslint-disable-next-line
	componentDidMount() {
		// TODO: change this in the backend- ask Richard. loads 0001-01-01 if testdate empty

		if (this.state.CovidTestDate > 1) {
			this.setState({
				selectedTestDate: new Date(this.state.CovidTestDate * 1000),
			});
		}
		// flightDate is of type string in the datastore
		var flightDateString = this.state.FlightDate;

		var dateParts = flightDateString.split("/");

		// month is 0-based, that's why we need dataParts[1] - 1
		var flightDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
		this.setState({ arrivalDate: flightDate });
	}

	//http post
	async setStudent(data) {
		data.ID = parseInt(data.ID);
		let result = await post("setStudents", {
			ID: parseInt(this.props.schoolid),
			Students: [data],
		});
		// console.log(result);
		if (result) {
			this.props.updateStudent(data);

			this.setState({
				saveMessage: "Changes saved successfully!",
				student: data,
			});
		} else {
			this.setState({
				saveMessage: "Something happened. Please try again!",
			});
		}
	}
	fileInputRef = React.createRef();

	fileChange = (e) => {
		console.log(this.state.saveMessage);
		this.setState({ imageFile: e.target.files[0] }, () => {
			console.log("File chosen --->", this.state.imageFile);
			this.fileUpload(
				this.state.imageFile,
				this.props.schoolid,
				this.props.studentid
			);
		});
	};
	// http post for image upload
	async fileUpload(file, schoolid, studentid) {
		const formData = new FormData();
		formData.append("image", file);
		formData.append("school", parseInt(schoolid));
		formData.append("student", parseInt(studentid));

		let response = await postImage("uploadImage", formData);
		console.log(response);
		if (response == "Image Uploaded Successfully") {
			this.getstudentImage(
				parseInt(this.props.studentid),
				parseInt(this.props.schoolid)
			);
		} else {
			this.setState({ uploadFail: "Something happened. Please try again!" });
		}
	}
	// http get student image
	async getstudentImage(studentid, schoolid) {
		let result = await get(
			"getImage?student=" + studentid + "&school=" + schoolid
		);
		// console.log(result);
		let firstFourChars = result.substring(0, 4);
		let type = "";
		if (firstFourChars == "/9j/ ") {
			type = "data:image/jpeg;base64,";
		} else {
			type = "data:image/png;base64,";
		}
		this.setState({ outputImage: type + result });
	}
	// remove whitespace
	hasWhiteSpace = (str) => {
		return /\s/g.test(str);
	};
	//update and save student
	handleSubmit = () => {
		// check for whitespaces in the mobile no's, if any remove them;

		let homestaymobile = this.hasWhiteSpace(this.state.HomestayMobile)
			? this.state.HomestayMobile.replace(/[^\d]/g, "")
			: this.state.HomestayMobile;
		let agentmobile = this.hasWhiteSpace(this.state.AgentMobile)
			? this.state.AgentMobile.replace(/[^\d]/g, "")
			: this.state.AgentMobile;
		let studentmobile = this.hasWhiteSpace(this.state.Mobile)
			? this.state.Mobile.replace(/[^\d]/g, "")
			: this.state.Mobile;
		let securitymobile = this.hasWhiteSpace(this.state.SecurityMobile)
			? this.state.SecurityMobile.replace(/[^\d]/g, "")
			: this.state.SecurityMobile;

		const data = {
			ID: this.props.studentid,
			SchoolName: this.state.SchoolName,
			SchoolContactName: this.state.SchoolContactName,
			SchoolContactPhone: this.state.SchoolContactPhone,
			SchoolContactEmail: this.state.SchoolContactEmail,
			Name: this.state.Name,
			Email: this.state.Email,
			Mobile: parseInt(studentmobile),
			SecurityMobile: parseInt(securitymobile),
			QuarantineLat: parseFloat(this.state.QuarantineLat),
			QuarantineLng: parseFloat(this.state.QuarantineLng),
			FlightNumber: this.state.FlightNumber,
			FlightDate: this.state.FlightDate.toString(),
			HomestayName: this.state.HomestayName,
			HomestayAddress: this.state.HomestayAddress,
			HomestayPhone: this.state.HomestayPhone,
			HomestayMobile: parseInt(homestaymobile),
			HomestayEmail: this.state.HomestayEmail,
			ParentEmail: this.state.ParentEmail,
			ParentMobile: this.state.ParentMobile,
			AgentName: this.state.AgentName,
			AgentEmail: this.state.AgentEmail,
			AgentMobile: parseInt(agentmobile),
			LastEventTime: this.state.LastEventTime,
			LastEventType: this.state.LastEventType,
			LastEventText: this.state.LastEventText,
			LastLatitude: this.state.LastLatitude,
			LastLongitude: this.state.LastLongitude,
			LastAccuracy: 0, //tbc
			LastValues: this.state.LastValues,
			CovidTestDate: this.state.CovidTestDate,
			CovidTestResult: this.state.CovidTestResult,
			Airports: this.state.Airports,
			Status: this.setStatusSendupValue(this.state.Status),
			//these values are here because they will be wiped when a save is done even though they are not displayed or edited here
			// AirportHome: this.state.AirportHome,
			// AirportHomeLong: parseFloat(this.state.AirportHomeLong),
			// AirportHomeLat: parseFloat(this.state.AirportHomeLat),
			// AirportHub: this.state.AirportHub,
			// AirportHubLong: parseFloat(this.state.AirportHubLong),
			// AirportHubLat: parseFloat(this.state.AirportHubLat),
			// AirportTransit: this.state.AirportTransit,
			// AirportTransitLong: parseFloat(this.state.AirportTransitLong),
			// AirportTransitLat: parseFloat(this.state.AirportTransitLong),
			// AirportArrival: this.state.AirportArrival,
			// AirportArrivalLong: parseFloat(this.state.AirportArrivalLong),
			// AirportArrivalLat: parseFloat(this.state.AirportArrivalLat),
		};

		this.setStudent(data);
	};

	// input change and setState
	handleInputChange = (e) => {
		const { name, value } = e.target;

		this.setState({ [name]: value });
	};

	// change date in datepicker
	handleCovidDateChange = (e) => {
		console.log(e.getTime() / 1000);
		this.setState({
			selectedTestDate: e,
			CovidTestDate: e.getTime() / 1000,
		});
	};
	handleFlightDateChange = (e) => {
		console.log(e.toLocaleDateString());
		this.setState({
			FlightDate: e.toLocaleDateString(),
			arrivalDate: e,
		});
	};

	// setState onChange of covid test result dropdown
	handleSelectChange = (event, result) => {
		const { name, value } = result || event.target;
		this.setState({ [name]: value });
	};

	// setState onChange of covid test result dropdown
	handleStatusSelectChange = (event, result) => {
		const { name, value } = result || event.target;
		this.setState({ [name]: value });
	};

	setStatusDisplayValue = (s) => {
		let name;
		switch (s) {
			case 0:
				name = "Normal";
				return "Normal";
				break;
			case 1:
				name = "Out of Bounds";
				return "Out of Bounds";
				break;
			case 2:
				name = "Detained";
				return "Detained";
				break;
			default:
				return "Default";
				break;
		}

		return name;
	};

	setStatusSendupValue = (s) => {	
		switch (s){
			case  "Normal" :
				return 0
				break;
			case "Out of Bounds":
				return 1;
				break;
			case "Detained":
				return 2;
				break;
			default:
				return 0;
				break;
		}
	};

	setFontColor = (result) => {
		if (result === "Positive") {
			return "text-red";
		} else if (result === "Negative") {
			return "text-green";
		} else {
			return "text-orange";
		}
	};

	handleMobileNoChange = (e) => {
		const { name, value } = e.target;
		let phone = value;
		// console.log("len", value.length);
		var regx = /^[/[^0-9]+/g;
		if (!regx.test(phone)) return;
		if (phone.length > 10) return; // stop typing if length exceeds 10

		this.setState({ [name]: value });
	};

	handleSecurityMobileNoChange = (e) => {
		const { name, value } = e.target;
		let phone = value;
		// console.log("len", value.length);
		var regx = /^[/[^0-9]+/g;
		if (!regx.test(phone)) return;
		if (phone.length > 14) return; // stop typing if length exceeds 10
		this.setState({ [name]: value });
	};

	handleSearchChange = (e, { value }) => {
		this.setState({ [e.target.name]: value });
	};

	render() {
		const distance = getdistance(
			this.state.QuarantineLat,
			this.state.QuarantineLng,
			this.state.LastLatitude,
			this.state.LastLongitude
		);

		const CovidResults = [
			{
				key: 1,
				text: "Positive",
				value: "Positive",
				// color: "red",
			},
			{
				key: 2,
				text: "Negative",
				value: "Negative",
				// color: "green",
			},
			{
				key: 3,
				text: "Awaiting Result",
				value: "Awaiting Result",
			},
		];

		const StatusOptions = [
			{
				key: 0,
				text: "Normal",
				value: "Normal",
				// color: "red",
			},
			{
				key: 1,
				text: "Out of Bounds",
				value: "Out of Bounds",
				// color: "green",
			},
			{
				key: 2,
				text: "Detained",
				value: "Detained",
			},
		];

		//let st = this.setStatusDisplayValue(this.state.Status);

		return (
			<div className="student-info">
				{this.state.saveMessage && (
					<Message success content={this.state.saveMessage} />
				)}

				

				<Form onSubmit={this.handleSubmit} className="studentinfo">
					<Grid cols={2}>
						<Grid.Row>
							<Grid.Column>
								<Segment>
									<Grid columns="2">
										<Grid.Column>
											<Form.Field>
												<Form.Input
													fluid
													label="Name"
													name="Name"
													value={this.state.Name || ""}
													type="text"
													onChange={this.handleInputChange}
												/>
											</Form.Field>

											<Form.Field>
												<Form.Input
													fluid
													label="Email"
													name="Email"
													value={this.state.Email || ""}
													type="text"
													onChange={this.handleInputChange}
													style={{ maxWidth: "200pt" }}
												/>
											</Form.Field>
											<Form.Field>
												<Form.Input
													fluid
													name="Mobile"
													value={this.state.Mobile || ""}
													label="Mobile"
													type="number"
													onChange={this.handleMobileNoChange}
													style={{ maxWidth: "100pt" }}
												/>
											</Form.Field>
										</Grid.Column>

										<Grid.Column textAlign="center">
											<Form.Field className="image-container">
												<Image
													centered
													bordered
													src={
														this.state.outputImage &&
														this.state.outputImage.length <= 22
															? null
															: this.state.outputImage
													}
													style={{
														height: 170,
														width: 170,
														textAlign: "center",
														lineHeight: "150px",
													}}
													alt={"No image"}
													// circular
												/>
												{this.state.uploadFail && (
													<Message error content={this.state.uploadFail} />
												)}
											</Form.Field>

											<Form.Field>
												<Button
													type="button"
													content="Choose File"
													labelPosition="left"
													icon="file"
													onClick={() => this.fileInputRef.current.click()}
												/>
												<input
													ref={this.fileInputRef}
													type="file"
													hidden
													onChange={this.fileChange}
												/>
											</Form.Field>
										</Grid.Column>
									</Grid>

									<Form.Field>
										<label>{"Last Location: (Lat/Long)"} </label>{" "}
										{this.state.LastLatitude} {" / "} {this.state.LastLongitude}
									</Form.Field>
									<Form.Field>
										<label>{"Distance from quarantine base"}</label>
									</Form.Field>
									<Form.Field>
										{distance > 0 && Math.round(distance * 1000) + "m"}{" "}
										{/* </Form.Field>
									<Form.Field> */}
										{/*  delete/modify this alert  - talk to P*/}
										{distance > 0 && Math.round(distance * 1000) > 200 ? (
											<span className="alert_red">
												{"  Out of bounds"}
												<Link
													to={{
														pathname: `/location-breach/${this.props.studentid}`,
														state: { events: this.state.studentEvents },
													}}
												>
													{"	View on map"}
												</Link>
											</span>
										) : null}
									</Form.Field>
								</Segment>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row stretched>
							<Grid.Column width={16}>
								<Segment>
									<Form.Field>
										<label>{"Homestay/Quarantine Name"}</label>
									</Form.Field>
									<Form.Field>
										<Form.Input
											fluid
											name="HomestayName"
											value={this.state.HomestayName || ""}
											type="text"
											onChange={this.handleInputChange}
										/>
									</Form.Field>
									<Form.Field>
										<Form.Input
											fluid
											label="Homestay Email"
											name="HomestayEmail"
											value={this.state.HomestayEmail || ""}
											type="text"
											onChange={this.handleInputChange}
											style={{ maxWidth: "200pt" }}
										/>
									</Form.Field>
									<Form.Field>
										<Form.Input
											fluid
											label="Homestay Mobile"
											name="HomestayMobile"
											value={this.state.HomestayMobile || ""}
											type="number"
											onChange={this.handleMobileNoChange}
											style={{ maxWidth: "100pt" }}
										/>
									</Form.Field>
									<Form.Field>
										<Form.Input
											fluid
											label="Security Mobile"
											name="SecurityMobile"
											value={this.state.SecurityMobile || ""}
											type="number"
											onChange={this.handleSecurityMobileNoChange}
											style={{ maxWidth: "100pt" }}
										/>
									</Form.Field>
									<Form.Field>
										<label>{"Student Security Status"}</label>
									</Form.Field>
									<Form.Field>
										<Dropdown
											style={{ width: "100px" }}
											text={this.state.Status}
											options={StatusOptions}
											placeholder={"Select Student Security Status"}
											value={this.state.Status}
											fluid
											selection
											closeOnChange
											name="Status"
											onChange={this.handleStatusSelectChange}
										/>
									</Form.Field>
									<Form.Field>
										<label>{"Arrival Date"}</label>
									</Form.Field>
									<Form.Field>
										<DatePicker
											name="FlightDate"
											selected={this.state.arrivalDate}
											onChange={this.handleFlightDateChange}
											dateFormat="dd/MM/yyy"
											className={"flightdate-picker"}
											customInput={<DatePicketInput />}
										/>
									</Form.Field>
									<Form.Field>
										<label className={"std-form-label"}>
											{"Quarantine Location "}
										</label>
									</Form.Field>
									<div className="fields">
										<div className="six wide field">
											<label>{"Lattitude"}</label>
											<Form.Input
												name="QuarantineLat"
												value={this.state.QuarantineLat || ""}
												type="number"
												step="any"
												onChange={this.handleInputChange}
											/>
										</div>
										<div className="six wide field">
											<label>{"Longitude"}</label>
											<Form.Input
												name="QuarantineLng"
												value={this.state.QuarantineLng || ""}
												type="number"
												step="any"
												onChange={this.handleInputChange}
											/>
										</div>
									</div>
								</Segment>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row stretched>
							<Grid.Column width={8}>
								<Segment>
									<Form.Field>
										<Form.Input
											fluid
											label="Agent Name"
											name="AgentName"
											value={this.state.AgentName || ""}
											type="text"
											onChange={this.handleInputChange}
											// style={{ maxWidth: "200pt" }}
										/>
									</Form.Field>
									<Form.Field>
										<Form.Input
											fluid
											label="Agent Email"
											name="AgentEmail"
											value={this.state.AgentEmail || ""}
											type="text"
											onChange={this.handleInputChange}
											style={{ maxWidth: "200pt" }}
										/>
									</Form.Field>
									<Form.Field>
										<Form.Input
											fluid
											label="Agent Mobile"
											name="AgentMobile"
											value={this.state.AgentMobile || ""}
											type="number"
											onChange={this.handleMobileNoChange}
											style={{ maxWidth: "100pt" }}
										/>
									</Form.Field>
								</Segment>
							</Grid.Column>
							<GridColumn width={8}>
								<Segment className="covid">
									<Grid.Column>
										<Form.Field>
											<label style={{ color: "white" }}>
												{"COVID-19 Test Date"}
											</label>
										</Form.Field>
										<Form.Field>
											<DatePicker
												name="CovidTestDate"
												selected={this.state.selectedTestDate}
												onChange={this.handleCovidDateChange}
												dateFormat="dd/MM/yyy"
												className={"date-picker"}
												customInput={<DatePicketInput />}
											/>
										</Form.Field>
										<Form.Field>
											<label style={{ color: "white" }}>
												{"Covid Test Result"}
											</label>
										</Form.Field>
										<Form.Field>
											<Dropdown
												style={{ width: "100px" }}
												text={this.state.CovidTestResult}
												options={CovidResults}
												name="CovidTestResult"
												placeholder={"Select test result"}
												value={this.state.CovidTestResult}
												fluid
												selection
												closeOnChange
												className={this.setFontColor(
													this.state.CovidTestResult
												)}
												name="CovidTestResult"
												onChange={this.handleSelectChange}
											/>
										</Form.Field>
									</Grid.Column>
								</Segment>
							</GridColumn>
						</Grid.Row>
						<Grid.Row>
							<Button
								style={{ marginLeft: "10pt" }}
								type="submit"
								className="save"
							>
								Save Changes
							</Button>
						</Grid.Row>
					</Grid>
				</Form>
			</div>
		);
	}
}
const DatePicketInput = React.forwardRef((props, ref) => {
	return (
		<Input
			icon={"calendar alternate"}
			iconPosition={"left"}
			value={props.value}
			onChange={props.onChange}
			className={props.className}
			onClick={props.onClick}
		/>
	);
});
