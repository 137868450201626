import React from "react";
import { Container } from "semantic-ui-react";

import Map from "./student-location-on-map";

export default class StudentMap extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
		};
	}

	render() {
		return (
			<Container>
				<Map
					places={this.state.allStudents}
					center={{ lat: -41.28666552, lng: 174.772996908 }} // centered to Wellington,NZ
					zoom={5.4}
				/>
			</Container>
		);
	}
}
const data = [
	{
		ID: 1,
		Name: "abc",
		Email: "Student 1@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 1,
		LastEventTime: 1592436584,
		LastEventText: "Lost in carpark",
		LastLatitude: -31.56391,
		LastLongitude: 147.154312,
		QuarantineLat: 18,
		QuarantineLng: 19,
	},
	{
		ID: 2,
		Name: "xyz",
		Email: "Student 2@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 1,
		LastEventTime: 1592436584,
		LastEventText: "Lost in carpark",
		LastLatitude: -33.718234,
		LastLongitude: 150.363181,
		QuarantineLat: 18,
		QuarantineLng: 19,
	},
	{
		ID: 3,
		Name: "Tom",
		Email: "Student 3@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 1,
		LastEventTime: 1592436584,
		LastEventText: "Lost in carpark",
		LastLatitude: -33.727111,
		LastLongitude: 150.371124,
		QuarantineLat: 18,
		QuarantineLng: 19,
	},
	{
		ID: 4,
		Name: "john",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 1,
		LastEventTime: 1592436584,
		LastEventText: "Lost in carpark",
		LastLatitude: -8.0090935,
		LastLongitude: 113.8436013,
		QuarantineLat: 18,
		QuarantineLng: 19,
	},
	{
		ID: 5,
		Name: "Sam",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 1,
		LastEventTime: 1592436584,
		LastEventText: "Lost in carpark",
		LastLatitude: 12.0806213,
		LastLongitude: -86.0198103,
		QuarantineLat: 18,
		QuarantineLng: 19,
	},
	{
		ID: 6,
		Name: "Alex",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 1,
		LastEventTime: 1592436584,
		LastEventText: "Lost in carpark",
		LastLatitude: 52.2820161,
		LastLongitude: 36.0199456,
	},
	{
		ID: 7,
		Name: "Student 7",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 1,
		LastEventTime: 1592436584,
		LastEventText: "Lost in carpark",
		LastLatitude: 37.8334351,
		LastLongitude: 23.802873,
	},
	{
		ID: 8,
		Name: "Student 8",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 1,
		LastEventTime: 1592436584,
		LastEventText: "Lost in carpark",
		LastLatitude: 36.5503029,
		LastLongitude: 136.888602,
	},
	{
		ID: 9,
		Name: "Student 9",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 1,
		LastEventTime: 1592436584,
		LastEventText: "Lost in carpark",
		LastLatitude: 52.6057865,
		LastLongitude: 21.8949682,
	},
	{
		ID: 14,
		Name: "Student 14",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 1,
		LastEventTime: 1592436584,
		LastEventText: "Lost in carpark",
		LastLatitude: 55.888915,
		LastLongitude: 37.5584016,
	},
	{
		ID: 24,
		Name: "Student 24",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 1,
		LastEventTime: 1592436584,
		LastEventText: "Lost in carpark",
		LastLatitude: 22.183206,
		LastLongitude: 112.305145,
	},
	{
		ID: 34,
		Name: "Student 34",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 1,
		LastEventTime: 1592436584,
		LastEventText: "Lost in carpark",
		LastLatitude: 51.0888487,
		LastLongitude: 24.526718,
	},
];
