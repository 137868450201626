import React from "react";
import { Link } from "react-router-dom";

import { get } from "../../functions/http";
import { Container, Card, Grid } from "semantic-ui-react";

const report_days = 14; //14 day report
let difference_in_time = 0,
	difference_in_days = 0;
export default class ProgressReport extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			allStudents: [],
			allStudentEvents: [],
			studentsbydays: [],
		};

		// this.getallStudentEvents(props.schoolid, props.typeid);
		// this.getallStudents(props.schoolid);
	}

	async getallStudentEvents(schoolID, typeID) {
		let result = await get(
			"getStudentsEvent?school=" + schoolID + "&type=" + typeID
		);
		this.setState({ allStudentEvents: result }, () => {
			// console.log("Events", this.state.allStudentEvents);
		});
	}
	async getallStudents(schoolID) {
		let result = await get("getStudents?school=" + schoolID);
		this.setState({ allStudents: result }, () => {
			// console.log("Students", this.state.allStudents);
		});
	}
	componentDidMount() {
		let studentsbydays = [];
		[...Array(report_days)].map((e, i) =>
			this.state.allStudents.map((st, studentIndex) =>
				this.state.allStudentEvents.map((v, eventIndex) => {
					difference_in_time =
						new Date().getTime() - new Date(v.At * 1000).getTime();
					difference_in_days = parseInt(
						difference_in_time / (1000 * 3600 * 24)
					);
					if (difference_in_days === i + 1 && st.ID === v.StudentID) {
						// console.log(st.Name, difference_in_days + "days");
						studentsbydays.push([i + 1, st]);
					} else {
						return;
					}
				})
			)
		);
		studentsbydays && this.setState({ studentsbydays: studentsbydays });
	}
	render() {
		let studentlist = [];
		return (
			<Container>
				<Grid columns={7}>
					{[...Array(report_days)].map(
						(e, i) => (
							this.state.studentsbydays.map((val, index) => {
								if (i + 1 == val[0]) {
									return studentlist.push(val);
								}
								return;
							}),
							(
								<Grid.Column key={i}>
									<Card key={i}>
										<Card.Content className={"reports-card-header"}>
											<Card.Header
												as={"h1"}
												textAlign={"center"}
												style={{ fontSize: "1em" }}
											>
												<Link
													to={{
														pathname: `/student-list/day/${i + 1}`,
														state: {
															students: studentlist.filter(function (el) {
																//remove undefined or null values before passing to component
																return el != null;
															}),
														},
													}}
												>
													{"Day " + (i + 1)}
												</Link>
											</Card.Header>
										</Card.Content>
										<Card.Content>
											<Card.Description>
												{this.state.studentsbydays.map((val, index) => {
													if (i + 1 == val[0]) {
														return (
															<p key={index}>
																<Link to={`/student-info/${val[1].ID}`}>
																	{val[1].Name}
																</Link>
															</p>
														);
													}
													return;
												})}

												{/* {this.state.allStudents.map((st, studentIndex) =>
											this.state.allStudentEvents.map((v, eventIndex) => {
												difference_in_time =
													new Date().getTime() -
													new Date(v.At * 1000).getTime();
												difference_in_days = parseInt(
													difference_in_time / (1000 * 3600 * 24)
												);

												if (
													difference_in_days === i + 1 &&
													st.ID === v.StudentID
												) {
													// console.log(difference_in_days, st.Name);
													return (
														<p key={studentIndex}>
															<Link to={`/student-info/${st.ID}`}>
																{st.Name}
															</Link>
														</p>
													);
												}
											})
										)} */}
											</Card.Description>
										</Card.Content>
									</Card>
								</Grid.Column>
							)
						)
					)}
				</Grid>
			</Container>
		);
	}
}
const mockevents = [
	{
		At: 1593475200,
		Latitude: -18.619717990152775,
		Longitude: -18.26256927993376,
		StudentID: 1,
		Text: "Event 100",
	},
	{
		At: 1593129600,
		Latitude: -18.619717990152775,
		Longitude: -18.26256927993376,
		StudentID: 2,
		Text: "Event 100",
	},
	{
		At: 1592179200,
		Latitude: -18.619717990152775,
		Longitude: -18.26256927993376,
		StudentID: 3,
		Text: "Event 100",
	},
	{
		At: 1592956800,
		Latitude: -18.619717990152775,
		Longitude: -18.26256927993376,
		StudentID: 4,
		Text: "Event 100",
	},
	{
		At: 1593216000,
		Latitude: -18.619717990152775,
		Longitude: -18.26256927993376,
		StudentID: 10,
		Text: "Event 101",
	},
	{
		At: 1592524800,
		Latitude: -18.619717990152775,
		Longitude: -18.26256927993376,
		StudentID: 8,
		Text: "Event 102",
	},
	{
		At: 1593475200,
		Latitude: -18.619717990152775,
		Longitude: -18.26256927993376,
		StudentID: 24,
		Text: "Event 103",
	},
];
const data = [
	{
		ID: 1,
		Name: "Student 1",
		Email: "Student 1@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 1,
		LastEventTime: 1592524800,
		LastEventText: "Lost in carpark",
		LastLatitude: -31.56391,
		LastLongitude: 147.154312,
		QuarantineLat: 18,
		QuarantineLng: 19,
	},
	{
		ID: 2,
		Name: "Student 2",
		Email: "Student 2@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 1,
		LastEventTime: 1593129600,
		LastEventText: "Lost in carpark",
		LastLatitude: -33.718234,
		LastLongitude: 150.363181,
		QuarantineLat: 18,
		QuarantineLng: 19,
	},
	{
		ID: 3,
		Name: "Student 3",
		Email: "Student 3@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 1,
		LastEventTime: 1593129600,
		LastEventText: "Lost in carpark",
		LastLatitude: -33.727111,
		LastLongitude: 150.371124,
		QuarantineLat: 18,
		QuarantineLng: 19,
	},
	{
		ID: 4,
		Name: "Student 4",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 1,
		LastEventTime: 1593216000,
		LastEventText: "Lost in carpark",
		LastLatitude: -8.0090935,
		LastLongitude: 113.8436013,
		QuarantineLat: 18,
		QuarantineLng: 19,
	},
	{
		ID: 5,
		Name: "Student 5",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 1,
		LastEventTime: 1593475200,
		LastEventText: "Lost in carpark",
		LastLatitude: 12.0806213,
		LastLongitude: -86.0198103,
		QuarantineLat: 18,
		QuarantineLng: 19,
	},
	{
		ID: 6,
		Name: "Student 6",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 1,
		LastEventTime: 1593129600,
		LastEventText: "Lost in carpark",
		LastLatitude: 52.2820161,
		LastLongitude: 36.0199456,
	},
	{
		ID: 7,
		Name: "Student 7",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 1,
		LastEventTime: 1593475200,
		LastEventText: "Lost in carpark",
		LastLatitude: 37.8334351,
		LastLongitude: 23.802873,
	},
	{
		ID: 8,
		Name: "Student 8",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 1,
		LastEventTime: 1593475200,
		LastEventText: "Lost in carpark",
		LastLatitude: 36.5503029,
		LastLongitude: 136.888602,
	},
	{
		ID: 9,
		Name: "Student 9",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 1,
		LastEventTime: 1592956800,
		LastEventText: "Lost in carpark",
		LastLatitude: 52.6057865,
		LastLongitude: 21.8949682,
	},
	{
		ID: 14,
		Name: "Student 14",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 1,
		LastEventTime: 1593129600,
		LastEventText: "Lost in carpark",
		LastLatitude: 55.888915,
		LastLongitude: 37.5584016,
	},
	{
		ID: 24,
		Name: "Student 24",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 1,
		LastEventTime: 1593043200,
		LastEventText: "Lost in carpark",
		LastLatitude: 22.183206,
		LastLongitude: 112.305145,
	},
	{
		ID: 34,
		Name: "Student 34",
		Email: "Student 4@school.com",
		Mobile: "Mobile",
		FlightNumber: "F1234",
		FlightDate: "22/05/2021",
		HomestayName: "HomestayName",
		HomestayAddress: "HomestayAddress",
		HomestayPhone: "HomestayPhone",
		HomestayEmail: "Homestay@Email.com",
		ParentEmail: "parent@email.com",
		ParentMobile: "ParentMobile",
		AgentEmail: "agent@email.com",
		AgentMobile: "AgentMobile",
		LastEventType: 1,
		LastEventTime: 1593216000,
		LastEventText: "Lost in carpark",
		LastLatitude: 51.0888487,
		LastLongitude: 24.526718,
	},
];
