import React from "react";
import { Tab, Container } from "semantic-ui-react";
import StudentInfo from "./Student/student-info";
import TransitCheckins from "./Student/transit-checkins";
import LocationCheckins from "./Student/location-checkins";
import LocationAlerts from "./Student/location-alerts";
import StudentAirports from "./Student/student-airports";

import { get } from "../functions/http";
import LocationLogMap from "./Student/location-log-map";

class Student extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			studentid: props.match.params.studentid,
			showTab: false,
		};
		this.getStudent(this.state.studentid, parseInt(this.props.schoolid));
		this.getStudentEvents(this.state.studentid, parseInt(this.props.schoolid));
		this.getStudents(parseInt(this.props.schoolid));
	}

	async getStudent(studentid, schoolid) {
		let result = await get(
			"getStudent?student=" + studentid + "&school=" + schoolid
		);
		this.setState({ student: result });
	}
	async getStudentEvents(studentid, schoolid) {
		let result = await get(
			"getStudentEvents?student=" + studentid + "&school=" + schoolid
		);
		this.setState({ studentEvents: result }, () => {
			// if no records >= 10000, hide location tabs
			this.state.studentEvents.filter(function (e) {
				return e.Type >= 10000;
			}).length > 0 &&
				this.setState({
					showTab: true,
				});
		});
	}

	// Update view after edit/save in StudentInfo
	updateStudentView = (data) => {
		this.setState({ student: data });
	};

	async getStudents(school) {
		let result = await get("getStudents?school=" + school);
		if (result) {
			this.setState({ students: result });
			//this.props.dispatch({type:"SET_SCHOOL",payload:result});
		} else {
			console.log("Students fetch error");
		}
	}

	render() {
		if (!this.state.student || !this.state.studentEvents) return null;

		return (
			<Container className="listtab">
				<Tab
					panes={[
						{
							menuItem: "Student",
							render: () => (
								<Tab.Pane className="tabpane">
									<Container>
										<StudentInfo
											studentinfo={this.state.student}
											studentid={this.state.studentid}
											schoolid={this.props.schoolid}
											updateStudent={this.updateStudentView}
											studentEvents={this.state.studentEvents}
										/>
									</Container>
								</Tab.Pane>
							),
						},
						{
							menuItem: "Transit CheckIns",
							render: () => (
								<Tab.Pane>
									<Container>
										<TransitCheckins events={this.state.studentEvents} />
									</Container>
								</Tab.Pane>
							),
						},
						this.state.showTab
							? {
									menuItem: "Location CheckIns",

									render: () => (
										<Tab.Pane>
											<Container>
												<LocationCheckins
													events={this.state.studentEvents}
													studentinfo={this.state.student}
												/>
											</Container>
										</Tab.Pane>
									),
							  }
							: null,
						this.state.showTab
							? {
									menuItem: "Location Log Map",
									render: () => (
										<Tab.Pane>
											<Container>
												<LocationLogMap
													events={this.state.studentEvents}
													studentinfo={this.state.student}
												/>
											</Container>
										</Tab.Pane>
									),
							  }
							: null,
						this.state.showTab
							? {
									menuItem: {
										content: "Location Alerts",
									},

									render: () => (
										<Tab.Pane>
											<Container>
												<LocationAlerts
													events={this.state.studentEvents}
													studentinfo={this.state.student}
												/>
											</Container>
										</Tab.Pane>
									),
							  }
							: null,
						{
							menuItem: "Student Airports",
							render: () => (
								<Tab.Pane>
									<Container>
										{
											<StudentAirports
												studentinfo={this.state.student}
												studentid={this.state.studentid}
												schoolid={this.props.schoolid}
											/>
										}
									</Container>
								</Tab.Pane>
							),
						},

						// {
						// 	menuItem: "Survey Questions",
						// 	render: () => (
						// 		<Tab.Pane>
						// 			<Container>
						// 				{
						// 					<SurveyQuestions
						// 						events={this.state.studentEvents}
						// 						students={this.state.students}
						// 					/>
						// 				}
						// 			</Container>
						// 		</Tab.Pane>
						// 	),
						//  },//,{
						// 	menuItem: "Phone App",
						// 	render: () => (
						// 		<Tab.Pane>
						// 			<Container>
						// 				{<SurveyAnswers
						// 					events={this.state.studentEvents}

						// 				/>}
						// 			</Container>
						// 		</Tab.Pane>
						// 	),
						// },
					]}
					defaultActiveIndex={0}
				/>
			</Container>
		);
	}
}

export default Student;

// const data = [
// 	{
// 		At: 1593396782,
// 		Text: "Lost in carpark",
// 		Latitude: -36.8875028,
// 		Longitude: 174.7147572,
// 		Type: 7000,
// 		Accuracy: 65,
// 	},
// 	{
// 		At: 1592436584,
// 		Text: "Somewhere here",
// 		Latitude: -36.8843168,
// 		Longitude: 174.7012937,
// 		Type: 10000,
// 		Accuracy: 85,
// 	},
// 	{
// 		At: 1592436585,
// 		Text: "Somewhere here44",
// 		Latitude: -36.8843168,
// 		Longitude: 174.7147272,
// 		Type: 10000,
// 		Accuracy: 75,
// 	},
// 	{
// 		At: 1592436235,
// 		Text: "Somewhere here44",
// 		Latitude: -36.8875028,
// 		Longitude: 174.7147372,
// 		Type: 10000,
// 		Accuracy: 95,
// 	},
// ];
