import React from 'react';
import { Container,Grid,Button, Form, TextArea } from 'semantic-ui-react'
import { connect } from "react-redux";
import {post} from "../../functions/http.js";

class MessageSetUp  extends React.Component {

  constructor (props){
    super(props);
    this.state={start:"", end:""};
  }   

  async setSchool(data) {
    data.ID = parseInt(data.ID)
    let result = await post("setSchool",data);		
    if (result){
       result.ID = data.ID;
      //this.props.dispatch({type:"SET_SCHOOL",payload:result});
      console.log("school data saved");
    } else {
      console.log("changes not saved");
    }		
  };

  saveSchool = () =>{
    this.setSchool(this.props.school);            
  }

  valueChanged = (e) =>{   
    this.props.dispatch({type:"UPDATE_SCHOOL",payload:[this.props.school,e.currentTarget.name,e.currentTarget.value]})
    
  }

  onClick = (e) =>{  
    let s1 =  e.currentTarget.value.substring(0,e.target.selectionStart);
    let s2 = e.currentTarget.value.substring(e.target.selectionEnd);  
    this.setState({start:s1,end:s2,name:e.currentTarget.name});  
     
  }


  addTag = (tag) =>{         
    console.log(this.state);
    console.log(tag)
    this.props.dispatch({type:"UPDATE_SCHOOL",payload:[this.props.school,this.state.name, this.state.start + "" + tag + " " + this.state.end]});  
  }


  //https://www.google.com/maps/search/?api=1&query=47.5951518,-122.3316393

  render(){

    return (
      <Container>
      
        <Form> 
           <Grid columns={3}>
              <Grid.Column>      
                <TextArea
                 style={{ minHeight: 250 }}
                  placeholder='Email to Parent'
                  value={this.props.school.ParentLetter}
                  onChange={this.valueChanged} 
                  onClick={this.onClick}
                  name={"ParentLetter"}
                />
              </Grid.Column>
              <Grid.Column>
                <TextArea 
                 style={{ minHeight: 250 }}
                  placeholder='Email to Agent'
                  value={this.props.school.AgentLetter}  
                  onChange={this.valueChanged} 
                  onClick={this.onClick}
                  name={"AgentLetter"}
                />
              </Grid.Column>
              <Grid.Column>
                <TextArea 
                 style={{ minHeight: 250 }}
                  placeholder='Email to School'
                  value={this.props.school.SchoolLetter}
                  onChange={this.valueChanged}             
                  onClick={this.onClick}      
                  name={"SchoolLetter"}  
              />
              </Grid.Column>
              <Grid.Column>
                <TextArea 
                  style={{ minHeight: 250 }}
                  placeholder='SMS to Parent' 
                  value={this.props.school.ParentShortLetter}  
                  onChange={this.valueChanged} 
                  name={"ParentShortLetter"}               
                  onClick={this.onClick}   
               />
              </Grid.Column>
              <Grid.Column>
                <TextArea 
                  style={{ minHeight: 250 }}
                  placeholder='SMS to Agent'
                  value={this.props.school.AgentShortLetter}  
                  onChange={this.valueChanged}
                  name={"AgentShortLetter"}
                  onClick={this.onClick}
                />
              </Grid.Column>
              <Grid.Column>
                <textarea 
                  style={{ minHeight: 250 }}
                  placeholder='SMS to School' 
                  value={this.props.school.SchoolShortLetter}  
                  onChange={this.valueChanged} 
                  name={"SchoolShortLetter"}
                  onClick={this.onClick}
                />
              </Grid.Column>
          </Grid>
          <div className="button-box">
            <Button onClick={(e)=>this.addTag("{{PARENT}}")}>Parent</Button>
            <Button onClick={(e)=>this.addTag("{{STUDENTNAME}}")}>StudentName</Button>
            <Button onClick={(e)=>this.addTag("{{STATUS}}")}>Status</Button>
            <Button onClick={(e)=>this.addTag("{{SCHOOLNAME}}")}>SchoolName</Button>
            <Button onClick={(e)=>this.addTag("{{AGENT}}")}>Agent</Button>
            <Button onClick={(e)=>this.addTag("{{SCHOOLCONTACT}}")}>School Contact</Button>
          </div>
          <Button 
            className={"save"}
            onClick={this.saveSchool}
          >
            Save</Button>
        </Form>
      </Container>
    );
  }
}

export default connect(state=>({school:state.school}))(MessageSetUp);

// AgentLetter: "AgentLetter"
// AgentShortLetter: ""
// ContactEmail: "A Contact@email.com1a"
// ContactName: "A ContactName"
// ContactPhone: "A ContactPhone"
// ID: "1234"
// Name: "Average"
// OrientationURL: ""
// ParentLetter: "ParentLetter"
// ParentShortLetter: ""
// Password: ""
// SchoolLetter: "SchoolLetter"
// SchoolShortLetter: ""

/*
Dear {{PARENT}}

Your student {{STUDENTNAME}} has just updated their status to {{STATUS}}

More updates will be posted as they progress through their journey.

Regards
The QTrack Team on Behalf of 
{{SCHOOLNAME}}*/
